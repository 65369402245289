import { Helmet } from 'react-helmet-async';
import { useFilter, useRabbitHistoryAutoUpdate, useRabbitHistoryByFilters } from './utils';
import { usePagination } from 'hooks';
// components
import { HistoryTable, HistoryTableFilter } from 'components/Rabbit';
import { FilterButton, AutoUpdateButton } from 'components/Buttons';
// styles
import useStyles from 'styles/page';

const MessageHistory = (): JSX.Element => {
  const classes = useStyles();
  const { filter, onSave, onReset, filterState, setFilterState } = useFilter();
  const { limit, offset } = usePagination();
  const { isLoading, data, total } = useRabbitHistoryByFilters({
    ...filter,
    limit,
    offset
  });
  const { isAutoUpdate, onStart, onStop } = useRabbitHistoryAutoUpdate(10);

  return (
    <>
      <Helmet><title>RabbitMQ Message History</title></Helmet>
      <div className={ classes.toolbar }>
        <AutoUpdateButton status={ isAutoUpdate } onClick={ () => isAutoUpdate ? onStop() : onStart() }/>
        <FilterButton
          isLoading={ isLoading }
          filter={ filter }
          onSave={ onSave }
          onReset={ onReset }
        >
          <HistoryTableFilter
            isAutoUpdate={ isAutoUpdate }
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div>
      <HistoryTable
        isLoading={ isLoading }
        data={ data }
        total={ total }
      />
    </>
  );
};

export default MessageHistory;
