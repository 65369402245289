import React from 'react';

import { keyDown } from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import { stringAsUUID } from 'helpers';
import { stopPropagation } from 'utils/dom';

// components
import {
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  MenuItem,
  Input,
  Select,
  Checkbox,
  ListItemText,
  Typography,
  TextField,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ArrowDownIcon } from '../';

// styles
import contentStyles, { MenuProps } from 'styles';
import useStyles from './NetworkIdSelectStyle';

interface Props {
  changeHandler: (chosenNetworkId: string[]) => void;
  selected: string[];
  name?: string;
  disabled?: boolean;
}

type Event = React.ChangeEvent<{ value: unknown }>;

export const NetworkIdSelect = (props: Props): JSX.Element => {
  const classes = {
    ...contentStyles(),
    ...useStyles()
  };

  const [networksList, setNetworksList] = React.useState<string[]>(props.selected);

  const [networkInput, setNetworkInput] = React.useState<{ value: string }>({ value: '' });
  const [networkMask, setNetworkMask] = React.useState<string>('');

  if (props.selected.length && !networksList.length) {
    setNetworksList(props.selected);
    setNetworkMask(props.selected.join(','));
  }

  function handleAddToNetworkList(event: React.SyntheticEvent) {
    event.stopPropagation();
    if (networkMask && !networksList.includes(networkMask)) {
      setNetworksList([networkMask, ...networksList]);
      setNetworkInput({ value: '' });
      props.changeHandler([...props.selected, networkMask]);
    }
  }

  const handleSetNetworkFromInput = (event: Event) => {
    const str = (event.target.value as string).replace(/[^A-Za-z0-9]+/g, '');
    setNetworkInput({ value: str });
    setNetworkMask(stringAsUUID(str));
  };

  const handleSelectNetwork = (event: Event) => {
    const networks = event.target.value as string[];

    props.changeHandler(networks.filter(network => !!network));
  };

  const onClose = () => props.changeHandler(props.selected);
  return (
    <FormControl
      disabled={ props.disabled }
      className={ classes.formControl }
      data-testid="custom-id-select"
    >
      <InputLabel htmlFor="network-label-placeholder">
        { props.name ?? 'Position Network ID' }
      </InputLabel>
      <Select
        placeholder="Position Network ID"
        value={props.selected}
        onChange={handleSelectNetwork}
        multiple={true}
        input={<Input name="network" id="network-label-placeholder" />}
        name="network"
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={MenuProps}
        IconComponent={(prop) => (<InputAdornment {...prop} position="end">
          <ArrowDownIcon />
        </InputAdornment>)}
        onClose={onClose}
      >
        <div className={classes.dropDownInput} onKeyDown={keyDown} onClick={stopPropagation}>
          <TextField
            value={networkInput.value}
            autoFocus={true}
            placeholder="Enter network ID"
            onKeyDown={keyDown}
            onClick={stopPropagation}
            className={classes.inputFullWidth}
            onChange={handleSetNetworkFromInput}
            helperText={networkMask}
            fullWidth
          />
          <Button
            aria-label="Add"
            disabled={!networkInput}
            color="primary"
            variant="contained"
            className={classes.buttonAddInSelect}
            onKeyDown={keyDown}
            onClick={handleAddToNetworkList}
          >
            <Add />
          </Button>
        </div>
        {networksList.map((net) => (
          <MenuItem key={net} value={net} className={classes.listNetId}>
            <Checkbox checked={props.selected.includes(net)} />
            <ListItemText primary={<Typography variant="caption">{net}</Typography>} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
