import { Helmet } from 'react-helmet-async';
import { useDeviceStatesRequestParams, useDeviceStatesByQuery, useDeviceStatesExport } from './utils';

// components
import { DevicesTable, DeviceStateTableFilter } from 'components';

// styles
import useStyles from 'styles/page';

const DeviceStates = (): JSX.Element => {
  const classes = useStyles();

  const { params, filter, updateFilter } = useDeviceStatesRequestParams();
  const { isLoading, data, total } = useDeviceStatesByQuery(params);
  const { isExporting, onExport } = useDeviceStatesExport(params);

  return (
    <>
      <Helmet><title>Devices states</title></Helmet>
      <div className={ classes.toolbar } >
        <DeviceStateTableFilter
          isLoading={isLoading}
          filter={filter}
          updateFilter={updateFilter}
        />
      </div>
      <div className={ classes.table } >
        <DevicesTable
          isLoading={ isLoading }
          data={ data }
          total={ total }
          fetchReport={ onExport }
          csvFetching={ isExporting }
        />
      </div>
    </>
  );
};

export default DeviceStates;
