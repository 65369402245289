import React from 'react';
import { useSelector } from 'react-redux';
import { PERMISSIONS_CATEGORIES, PermissionsConfig, PermissionsOperation } from 'models/user-management';
import { RootState } from 'reducers';

// components
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  SwitchProps,
} from '@material-ui/core';

import { PermissionsCategoryName } from './components';

// styles
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';

/**
 * react-hook-form's Controller has different typedef for `onChange`, `onFocus`, and `onBlur`,
 * so since we can't preserve the same behaviour as <Switch /> for them,
 * we simply don't allow them to be passed.
 */
type PermissionsSwitchProps = Omit<SwitchProps, 'onChange' | 'onFocus' | 'onBlur'> & {
  categoryFieldName: PermissionsConfig;
  operation: PermissionsOperation;
};

interface PermissionsTableProps {
  className?: string;
  renderSwitch: (props: PermissionsSwitchProps) => React.ReactNode;
}

const PermissionsTable: React.FC<PermissionsTableProps> = (props) => {
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

  const categories = PERMISSIONS_CATEGORIES
    .map(category => ({
      ...category,
      operations: category.operations.filter(o => o.key !== 'e' || isAdmin),
    }))
    .filter(category => category.operations.length);

  const infoStyles = useInfoBlockStyles();

  return (
    <Grid className={props.className} container spacing={1}>
      {categories.map(category => (
        <Grid key={category.key} item xs={12} sm={12} md={6} lg={4}>
          <Paper>
            <List
              subheader={(
                <PermissionsCategoryName disableSticky>
                  {category.name}
                </PermissionsCategoryName>
              )}
              className={infoStyles.field}
            >
              {category.operations.map(operation => {
                const labelId = `${category.key}-${operation.key}`;

                return (
                  <ListItem key={operation.key}>
                    <ListItemText
                      id={labelId}
                      primary={operation.name}
                      secondary={operation.description}
                    />
                    <Box ml={1}>
                      {props.renderSwitch({
                        categoryFieldName: category.key,
                        operation: operation,

                        // props of Material UI's Switch
                        edge: 'end',
                        inputProps: { 'aria-labelledby': labelId },
                      })}
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default PermissionsTable;
