import { useFormActionLoader } from 'hooks';
import { ActivateRule, DeactivateRule } from 'actions/rabbit';
import { Rule } from 'models/rabbit';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  rule: Rule;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const ActivityRuleDialog = ({ rule, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const handleSave = () => doAction({
    action: rule.activity ? DeactivateRule(rule) : ActivateRule(rule),
    onSuccess: onClose,
  });

  return (
    <EnsureDialog
      name={ 'activity-rule' }
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleSave }
    >
      The route <b>#{ rule.id }</b> will be { rule.activity ? 'deactivated' : 'activated' }
    </EnsureDialog>
  );
};
