import React from 'react';
import { usePositions } from 'hooks/device-management';

// components
import { Skeleton } from '@material-ui/lab';

interface PositionRouteLabelProps {
  positionId: number;
}

export const PositionRouteLabel: React.FC<PositionRouteLabelProps> = ({ positionId }) => {
  const { positions, isLoading } = usePositions({ positions: [positionId] });
  const position = positions[0];

  if (!position || isLoading) {
    return <Skeleton height={ 40 } width={ 80 } />;
  }

  return <>{position.network_id}</>;
};
