import { FormErrors } from 'form';
import { WarrantyFormField, Warranty, WarrantyCreateBody } from 'models/provisioning';

export const validate = (warranty: Warranty | WarrantyCreateBody, fields: WarrantyFormField[]): FormErrors<WarrantyFormField> => {
  const errors: FormErrors<WarrantyFormField> = {};
  if (fields.includes(WarrantyFormField.name)) {
    if (!warranty.name) {
      errors.name = 'Warranty name is required';
    }
  }
  if (fields.includes(WarrantyFormField.max_keeping_in_warehouse)) {
    if (!warranty.max_keeping_in_warehouse) {
      errors.max_keeping_in_warehouse = 'Max keeping time in warehouse is required';
    }
  }
  if (fields.includes(WarrantyFormField.max_warranty_after_install)) {
    if (!warranty.max_warranty_after_install) {
      errors.max_warranty_after_install = 'Max warranty after instal is required';
    }
  }

  return errors;
};
