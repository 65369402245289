import { Filters } from '../../types';
import { createTreeBottomUp } from './createTreeBottomUp';
import {
  arePositionScopeFilters,
  areGroupScopeFilters,
  areLevelScopeFilters
} from './filters';
import { ChildrenData, StartFrom } from '../types';

export async function createEntityTypeScopedTree(
  filters: Filters
): Promise<ChildrenData> {
  switch (true) {
    case arePositionScopeFilters(filters):
      return createTreeBottomUp(StartFrom.POSITION, filters);

    case areGroupScopeFilters(filters):
      return createTreeBottomUp(StartFrom.GROUP, filters);

    case areLevelScopeFilters(filters):
      return createTreeBottomUp(StartFrom.LEVEL, filters);

    default:
      throw new Error(
        `Unknown entity-level scope filters: ${JSON.stringify(filters)}`
      );
  }
}
