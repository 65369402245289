import { useAuthUserSelector } from 'hooks';
import { ALL_PROJECTS, GetUsersParams } from 'models/user-management';

// components
import {
  SingleSelectControl,
  OwnersSingleSelectControl,
  UserPermissionSelectControl,
  ProjectSelectControl,
  PROJECT_ALL_OPTION_VALUE,
  TextControl
} from 'components/Controls';
import { StyledForm } from '../shared';


export interface FiltersShape {
  name?: GetUsersParams['name'];
  email?: string;
  permissions?: number;
  projects?: GetUsersParams['projects'];
  role?: GetUsersParams['role'];
  owner?: GetUsersParams['owner'];
}

interface Props {
  filters: FiltersShape;
  setFilters: (filters: FiltersShape) => void;
}

const USER_ROLES_OPTIONS = [
  { label: 'Admin', value: 'admin' },
  { label: 'User', value: 'user' },
];

export const FiltersForm = ({ filters, setFilters }: Props): JSX.Element => {
  const { isAdmin } = useAuthUserSelector();

  return (
    <StyledForm>
      {isAdmin && (
        <OwnersSingleSelectControl
          isClearable
          label="Owner"
          selected={ filters.owner }
          changeHandler={ owner => setFilters(({ ...filters, owner })) }
        />
      )}

      {isAdmin && (
        <SingleSelectControl
          isClearable
          label="User Role"
          name="role"
          selected={filters.role}
          values={USER_ROLES_OPTIONS}
          changeHandler={role => setFilters({ ...filters, role })}
        />
      )}

      <TextControl
        fullWidth
        label="Name"
        placeholder="Filter users by name"
        value={filters.name || ''}
        onChange={e => {
          const name = e.target.value.replace(/\s/g, c => c === ' ' ? c : '');
          setFilters({ ...filters, name });
        }}
      />

      <TextControl
        fullWidth
        label="Email"
        placeholder="Filter users by email"
        value={filters.email || ''}
        onChange={e => {
          const email = e.target.value.replace(/\s/g, '');
          setFilters({ ...filters, email });
        }}
      />

      <UserPermissionSelectControl
        isClearable
        label="Permission Name"
        selected={ filters.permissions }
        onChange={ (permissions?: number) => setFilters({ ...filters, permissions })}
        filter={ p => Boolean(p.template) }
      />

      <ProjectSelectControl
        isMulti
        isClearable
        withAllOption
        selected={ filters.projects?.map(p => p === ALL_PROJECTS ? PROJECT_ALL_OPTION_VALUE : p ) ?? [] }
        onChange={ projects => {
          const selectedValues: FiltersShape['projects'] = projects.filter(p => p !== PROJECT_ALL_OPTION_VALUE);
          projects.includes(PROJECT_ALL_OPTION_VALUE) && selectedValues.unshift(ALL_PROJECTS);
          setFilters({ ...filters, projects: selectedValues });
        }}
        filter={ p => !filters.owner || p.owner_id === filters.owner}
      />
    </StyledForm>
  );
};

export default FiltersForm;
