import { Helmet } from 'react-helmet-async';
import * as is from 'utils/form/validation/validators';
import { isAllowedToCreateZones } from 'utils/permissions';
import { DoCreateZone } from 'actions/device-management/zones';
import { FormValues } from 'components/Form/ZoneForm';
import { ZoneUpdateFields, Zone } from 'models/device-management';
import { useAuthUserSelector, useFormActionLoader } from 'hooks';
import { useDefaultProject } from 'hooks/user-managment';
import { dmZonePath } from 'routing/paths';

// components
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Form } from 'components';
import { SuccessButton } from 'components/Buttons';
import { ZoneForm } from 'components/Form/ZoneForm';

// styles
import { useStyles } from './CreateZoneStyles';

interface IZoneUpdateValues extends ZoneUpdateFields {
  owner_id?: number;
}

const getDefaultValues = (
  getDefaultProject: ReturnType<typeof useDefaultProject>['getDefaultProject'],
  zone: IZoneUpdateValues,
): IZoneUpdateValues => {
  if (!zone?.owner_id) {
    return zone;
  }
  const defaultProject = getDefaultProject(zone?.owner_id);
  return {
    ...zone,
    project_id: zone?.project_id || defaultProject?.id
  };
};


export const CreateZone = (): JSX.Element => {
  const classes = useStyles();
  const { isAdmin, data: user } = useAuthUserSelector();
  const { getDefaultProject } = useDefaultProject();
  const { doAction } = useFormActionLoader();
  const userOwner = !isAdmin ? Number(user.user_group) : undefined;

  const defaultValues = getDefaultValues(getDefaultProject, {
    name: '',
    live_status: false,
    project_id: undefined,
    owner_id: userOwner ? userOwner : undefined,
  });

  const handleSubmit = (values: ZoneUpdateFields) => doAction({
    action: DoCreateZone(values),
    onSuccess: (zone: Zone) => ({ to: dmZonePath(zone.id) })
  });

  return (
    <div>
      <Helmet>
        <title>Create zone</title>
      </Helmet>
      <Form<FormValues>
        defaultValues={ defaultValues }
        validators={ {
          name: is.required(),
          ...(isAdmin && { owner_id: is.required() }),
        } }
        onSubmit={ handleSubmit }
      >
        { form => (
          <Paper>
            <Grid item={ true } xs={ 12 } sm={ 12 } className={ classes.gridEnd }>
              <SuccessButton
                label="Save"
                pending={ form.formState.isSubmitting }
                disabled={ !form.formState.dirty }
              />
            </Grid>
            <ZoneForm
              setDefaultProjectId={(ownerId: FormValues['owner_id']) => {
                const defaultProjectId = getDefaultProject(ownerId)?.id;
                form.setValue('project_id', defaultProjectId);
              }}
              allowed={ isAllowedToCreateZones(user) }
              userOwnerId={ userOwner }
              showFields={ ['name', 'liveStatus', 'project', ...(isAdmin ? ['owner'] : [])] }
            />
          </Paper>)
        }
      </Form>
    </div>
  );
};
