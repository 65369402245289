import React from 'react';
import { useFormActionLoader } from 'hooks';
import { useForm } from 'hooks/form';
import { Level, LevelUpdateFields, } from 'models/device-management';
import { DoUpdateLevel } from 'actions/device-management/levels';

// components
import { Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { LevelForm, LevelFormField, LevelFormState } from 'components/DeviceManagement/Forms';

interface Props {
  level: Level;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (level: Level) => void;
}

export const EditLevelDialog = ({ level, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const form = useForm<LevelFormState, LevelFormField>({
    initialState: {
      [LevelFormField.name]: level.name,
      [LevelFormField.floor]: level.floor_number,
      [LevelFormField.zone]: level.zone_id,
    },
    showFields: [
      LevelFormField.name,
      LevelFormField.floor,
      LevelFormField.zone,
    ],
  });

  const handleSubmit = (): void => {
    if (!form.validate()) {
      return;
    }

    const levelUpdate: LevelUpdateFields = {
      name: String(form.state[LevelFormField.name]),
      floor_number: Number(form.state[LevelFormField.floor]),
      zone_id: Number(form.state[LevelFormField.zone]),
    };

    doAction({
      action: DoUpdateLevel(level.id, levelUpdate),
      onError: form.catchError,
      onSuccess: (level: Level) => {
        onSuccess && onSuccess(level);
        onClose();
      }
    });
  };

  return (
    <CommonDialog
      testId="dm-level-edit-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit level > ${ level.name }` }
      content={ <LevelForm { ...form } /> }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ () => handleSubmit() }>Save</Button>
        </>
      }
    />
  );
};
