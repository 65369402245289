import { Permissions, PermissionsConfig, PermissionsOperation } from 'models/user-management';
import { PermissionState } from './types';

export const getPermissionState = (permission?: Permissions): PermissionState => {
  return {
    [PermissionsConfig.dm_zone]: permission?.[PermissionsConfig.dm_zone] ?? '',
    [PermissionsConfig.dm_device]: permission?.[PermissionsConfig.dm_device] ?? '',
    [PermissionsConfig.user_management]: permission?.[PermissionsConfig.user_management] ?? '',
    [PermissionsConfig.http_sender]: permission?.[PermissionsConfig.http_sender] ?? '',
    [PermissionsConfig.rmq_sender]: permission?.[PermissionsConfig.rmq_sender] ?? '',
    [PermissionsConfig.monitoring]: permission?.[PermissionsConfig.monitoring] ?? '',
    [PermissionsConfig.raw_messages]: permission?.[PermissionsConfig.raw_messages] ?? '',
    [PermissionsConfig.devices]: permission?.[PermissionsConfig.devices] ?? '',
    [PermissionsConfig.fmw_management]: permission?.[PermissionsConfig.fmw_management] ?? '',
    [PermissionsConfig.stations]: permission?.[PermissionsConfig.stations] ?? '',
    [PermissionsConfig.provisioning]: permission?.[PermissionsConfig.provisioning] ?? '',
  };
};

export const isDisabledByRelation = (state: PermissionState, operation: PermissionsOperation): boolean => {
  if (!operation.relations) {
    return false;
  }

  for (const relation of operation.relations) {
    if (relation.type === 'lock') {
      if (state[relation.config].includes(relation.key)) {
        return true;
      }
    }
  }

  return false;
};

export const changeByRelation = (state: PermissionState, config: PermissionsConfig, operation: PermissionsOperation): PermissionState => {
  if (!operation.relations) {
    return state;
  }

  const isEnable = state[config].includes(operation.key);
  for (const relation of operation.relations) {
    if (relation.type === 'enable' && isEnable) {
      if (!state[relation.config].includes(relation.key)) {
        state[relation.config] = state[relation.config] + relation.key;
      }
    }
  }

  return state;
};
