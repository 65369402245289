// components
import PermissionsTable from '../PermissionsTable';

// styles
import { styled } from 'styles/utils';

export const StyledPermissionsTable = styled(PermissionsTable)(({ theme }) => ({
  marginLeft: -theme.spacing(3),
  marginRight: -theme.spacing(3),
  width: 'auto',
}));
