import { GetAvgConnectivityPerPositionGroupParams } from 'models/connectivity';
import { DeviceConnectivity } from 'models/connectivity/deviceConnectivity';
import { ConnectivityFilters } from 'models/connectivity/filters';
import { Group } from 'models/device-management';
import { Defined } from 'utils/models';

export const FETCH_CONNECTIVITY = 'FETCH_CONNECTIVITY';
export const FETCH_CONNECTIVITY_BY_FILTERS = 'FETCH_CONNECTIVITY_BY_FILTERS';
export const FETCH_CONNECTIVITY_SUCCESS = 'FETCH_CONNECTIVITY_SUCCESS';
export const FETCH_CONNECTIVITY_FAILURE = 'FETCH_CONNECTIVITY_FAILURE';

export const FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP = 'FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP';
export const FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_SUCCESS = 'FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_SUCCESS';
export const FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_FAILURE = 'FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_FAILURE';

export interface GetConnectivityByFilters {
  readonly type: typeof FETCH_CONNECTIVITY_BY_FILTERS;
  filters: ConnectivityFilters;
}
export interface GetConnectivitySuccess {
  readonly type: typeof FETCH_CONNECTIVITY_SUCCESS;
  readonly payload: {
    readonly foundDevicesConnectivity: DeviceConnectivity[];
    readonly devicesConnectivityByDeviceId: {
      readonly [deviceId: string]: DeviceConnectivity | null;
    };
  };
}
export interface GetConnectivityFailure {
  readonly type: typeof FETCH_CONNECTIVITY_FAILURE;
  readonly error: string;
}

export interface FetchAvgConnectivityPerPositionGroup {
  type: typeof FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP;
  filters: GetAvgConnectivityPerPositionGroupParams;
}
export interface FetchAvgConnectivityPerPositionGroupSuccess {
  type: typeof FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_SUCCESS;
  payload: {
    avgConnectivityByPositionGroupId: Record<Defined<Group['id']>, number>;
  };
}
export interface FetchAvgConnectivityPerPositionGroupFailure {
  type: typeof FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_FAILURE;
}

// @TODO move to useDevices
export const DoGetConnectivityByFilters: (filters: ConnectivityFilters) => GetConnectivityByFilters = (filters) => ({
  type: FETCH_CONNECTIVITY_BY_FILTERS,
  filters,
});
export const DoGetConnectivitySuccess: (payload: GetConnectivitySuccess['payload']) => GetConnectivitySuccess = (payload) => ({
  type: FETCH_CONNECTIVITY_SUCCESS,
  payload,
});
export const DoGetConnectivityFailure: (error: string) => GetConnectivityFailure = (error) => ({
  type: FETCH_CONNECTIVITY_FAILURE,
  error,
});
// @TODO move to useDevices

export const fetchAvgConnectivityPerPositionGroup = (
  filters: FetchAvgConnectivityPerPositionGroup['filters'],
): FetchAvgConnectivityPerPositionGroup => ({
  type: FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP,
  filters,
});

export const fetchAvgConnectivityPerPositionGroupSuccess = (
  payload: FetchAvgConnectivityPerPositionGroupSuccess['payload'],
): FetchAvgConnectivityPerPositionGroupSuccess => ({
  type: FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_SUCCESS,
  payload,
});

export const fetchAvgConnectivityPerPositionGroupFailure = (): FetchAvgConnectivityPerPositionGroupFailure => ({
  type: FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_FAILURE,
});

export type Action =
  GetConnectivityByFilters |
  GetConnectivitySuccess |
  GetConnectivityFailure |
  FetchAvgConnectivityPerPositionGroup |
  FetchAvgConnectivityPerPositionGroupSuccess |
  FetchAvgConnectivityPerPositionGroupFailure;
