import * as monitoringClient from 'clients/monitoring';
import { StatisticMetricEnum, ExtendedIncidentsTypesEnumModel } from 'models/device-monitoring';
import { getResponseData, ensureRequestSucceeded } from 'utils/clients';

import { GroupNode, ZoneNode, PositionNode } from '../types';

type SilentDevicesByNode = Record<string, number | undefined>;

export async function fetchZonesTotalSilentDevices(
  nodes: ZoneNode[]
): Promise<SilentDevicesByNode> {
  const nodeIdTotalSilentDevicesTuples = await Promise.all(
    nodes.map(async node => {
      const zoneId = node.zone.id;
      const metrics = getResponseData(
        await monitoringClient.fetchIncidents({
          metrics: [StatisticMetricEnum.SilentOk],
          zones: [zoneId],
          projects: [],
          devices: [],
        }),
      );

      return [node.id, metrics.silent_ok];
    }),
  );

  return Object.fromEntries(nodeIdTotalSilentDevicesTuples);
}

export async function fetchGroupsTotalSilentDevices(
  nodes: GroupNode[]
): Promise<SilentDevicesByNode> {
  const nodeIdTotalSilentDevicesTuples = await Promise.all(
    nodes.map(async node => {
      const groupId = node.group.id;
      const metrics = getResponseData(
        await monitoringClient.fetchIncidents({
          metrics: [StatisticMetricEnum.SilentOk],
          groups: [groupId],
          projects: [],
          zones: [],
          devices: [],
        }),
      );

      return [node.id, metrics.silent_ok];
    }),
  );

  return Object.fromEntries(nodeIdTotalSilentDevicesTuples);
}

type DeviceSilentFlagByNode = Record<string, boolean>;

export async function fetchPositionsDeviceSilent(
  nodes: PositionNode[]
): Promise<DeviceSilentFlagByNode> {
  const nodeIdTotalSilentDevicesTuples = await Promise.all(
    nodes.map(async node => {
      const networkId = node.position.network_id;

      // if position doesn't have network_id, there's no bound device,
      // so no need to make a request
      if (!networkId) {
        return [node.id, false];
      }

      const res = await monitoringClient.fetchDeviceStates({
        networkIds: [networkId],
        incidentTypes: [ExtendedIncidentsTypesEnumModel.LastMessageTimeError],
        zones: [],
        groups: [],
        devices: [],
        severity: [],
      });
      ensureRequestSucceeded(res);

      return [node.id, typeof res.total === 'number' ? res.total > 0 : undefined];
    }),
  );

  return Object.fromEntries(nodeIdTotalSilentDevicesTuples);
}
