import { useEffect, useState } from 'react';
import { useEffectOnce, useDeepCompareEffect } from 'react-use';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Dispatch } from 'redux';
import { RootState } from 'reducers';
import { usePagination } from 'hooks/usePagination';
import { useFormActionNotifier } from 'hooks';

// models
import { RawMessagesFilters, RawMessagesFiltersQuery, initRawMessagesFilters } from 'models';

// actions
import {
  DoSetRawMessagesFilters,
  DoGetRawMessagesByQuery,
  DoUpdateRawMessagesFromUrl,
} from 'actions/raw-messages';

// components
import { RawMessagesTable, RawMessagesTableFilter } from 'components';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

const mapState = (state: RootState) => ({
  rawMessages: state.rawMessages,
  filters: state.rawMessages.filters
});

const mapDispatch = (dispatch: Dispatch) => ({
  initData: () => dispatch(DoUpdateRawMessagesFromUrl()),
  fetchRawMessages: (query: RawMessagesFiltersQuery, isSync?: boolean) => dispatch(DoGetRawMessagesByQuery(query, true, isSync)),
  setFilters: (filter: RawMessagesFilters) => dispatch(DoSetRawMessagesFilters(filter, true))
});

const connector = connect(
  mapState,
  mapDispatch
);

const RawMessages = ({
  initData,
  fetchRawMessages,
  setFilters,
  rawMessages,
  filters,
}: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = useStyles();
  const { notifySuccess, notifyWarning } = useFormActionNotifier();
  const { limit, offset } = usePagination();
  const [filterState, setFilterState] = useState<RawMessagesFilters>(filters);
  const [isSync, setIsSync] = useState(false);

  // filterState sync with filters
  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  // init url params
  useEffectOnce(() => {
    initData();
  });

  useEffect(() => {
    if (isSync) {
      if (filters.timeTo) {
        notifyWarning('Time To filter is set. New messages will not be displayed');
        return;
      }
      const interval = setInterval(() => {
        fetchRawMessages({ ...filters, offset, limit }, true);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [isSync, filters, offset, limit, fetchRawMessages, notifySuccess, notifyWarning]);

  // fetch table data
  useDeepCompareEffect(() => {
    fetchRawMessages({ ...filters, offset, limit });
  }, [fetchRawMessages, filters, offset, limit]);

  const updateFilters = (filters: RawMessagesFilters) => {
    setFilters(filters);
  };
  const onSave = () => { updateFilters(filterState); };
  const onReset = () => { updateFilters(initRawMessagesFilters); };

  return (
    <>
      <Helmet>
        <title>Raw Messages</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ !!rawMessages.isFetching }
          filter={ filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <RawMessagesTableFilter
            autoUpdate={ rawMessages.autoUpdate }
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div>
      <RawMessagesTable
        data={ rawMessages.messages }
        total={ rawMessages.total }
        fetching={ rawMessages.isFetching || false }
        isSync={isSync}
        setIsSync={ () => {
          if (!isSync) {
            notifySuccess('Synchronization has been started');
          } else {
            notifySuccess('Synchronization has been stopped');
          }
          setIsSync(!isSync);
        }}
      />
    </>
  );
};

export default connector(RawMessages);
