import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteGroup } from 'actions/device-management/groups';
import { dispatchAsync } from 'utils/store';

// components
import { Button } from '@material-ui/core';
import { PositionGroupLabel, ConfirmationDialog } from 'components';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dangerTheme } from 'styles/themes';

interface DeletePositionGroupProps {
  groupId: number;
  onSuccess?: () => void;
}

const DeletePositionGroup: React.FC<DeletePositionGroupProps> = ({ groupId, onSuccess }) => {
  const dispatch = useDispatch();

  return (
    <ConfirmationDialog
      title={(
        <>
          Are you sure you want to delete group{' '}
          <strong>
            <PositionGroupLabel groupId={groupId} noLink />
          </strong>?
        </>
      )}
      description={
        'The linked positions will be deleted. ' +
        'The linked devices will be unbound.'
      }
      renderTrigger={modal => (
        <MuiThemeProvider theme={dangerTheme}>
          <Button color="primary" variant="contained" onClick={modal.open}>
            Delete
          </Button>
        </MuiThemeProvider>
      )}
      onConfirm={(modal) => {
        modal.setPending(true);

        dispatchAsync(dispatch, deleteGroup(groupId))
          .then(() => {
            modal.close();
            onSuccess?.();
          })
          .catch(() => modal.setPending(false));
      }}
    />
  );
};

export default DeletePositionGroup;
