import React, { useState } from 'react';
import { useAuthUserSelector } from 'hooks';
import { usePermissionSelector } from 'hooks/user-managment';
import { User, UserRoleEnum } from 'models/user-management';
import { isAllowedToUpdateUser } from 'utils/permissions';
// components
import { Button, Link, Paper, Typography, Box } from '@material-ui/core';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { FieldSkeleton } from 'components/Skeleton';
import PermissionsForm from './PermissionsForm';
import PermissionsReport from './PermissionsReport';
// styles
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';

interface PermissionsProps {
  user: User;
}

const PermissionsBlock: React.FC<PermissionsProps> = (props) => {
  const { user } = props;
  const infoCss = useInfoBlockStyles();
  const { data: authUser } = useAuthUserSelector();
  const { permission: permissions, isLoading } = usePermissionSelector(user?.permissions);
  const [editing, setEditing] = useState(false);

  if (user.role === UserRoleEnum.Admin) {
    return null;
  }

  const editingAllowed = permissions && isAllowedToUpdateUser(authUser);
  if (permissions && editing) {
    return (
      <PermissionsForm
        user={ user }
        userPermissions={ permissions }
        onEditComplete={ () => setEditing(false) }
      />
    );
  }

  return (
    <Paper data-testid="permissions-report">
      <BlockToolbar>
        <BlockTitle>Permissions</BlockTitle>
        { editingAllowed && (
          <Button color="inherit" onClick={ () => setEditing(true) }>
            Edit
          </Button>
        ) }
      </BlockToolbar>
      { isLoading ? <FieldSkeleton className={ infoCss.field } /> : (
        !permissions
          ? (
            <Box p={ 2 } display="flex" justifyContent="center">
              <Typography variant="body1" >
                Can’t fetch user permissions.
                Please contact us by email <Link href="mailto:support@nwave.io">support@nwave.io</Link>.
              </Typography>
            </Box>
          )
          : (
            <PermissionsReport
              permissions={ permissions }
            />
          )
      ) }
    </Paper>
  );
};

export default PermissionsBlock;
