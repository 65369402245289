import { NotificationEndpoint, NotificationMethod } from './generated';
import { StationLocation } from './StationLocation';

export interface ResponsiblePerson {
  id: number;
  first_name: string;
  last_name: string;
  owner_id: number;
  role: string;
  notify: boolean;
  notification_method: NotificationMethod;
  notification_endpoint: NotificationEndpoint;
  registered_user_id: string | null;
  locations: StationLocation[]
}

export interface GetResponsiblePersonParams {
  personIds?: number[],
  stationIds?: number[],
  locationIds?: number[],
  projectId?: number,
  ownerId?: number,
  notifyStatus?: boolean,
  limit?: number,
  offset?: number,
}
export interface CreateResponsiblePerson {
  first_name: string;
  last_name: string;
  owner_id: number;
  role: string;
  notify: boolean;
  notification_method: string;
  notification_endpoint: NotificationEndpoint;
  registered_user_id: string | null;
}

export interface UpdateResponsiblePerson extends CreateResponsiblePerson {
  rp_id: number;
}


export enum ResponsiblePersonsFilterFields {
  // stationId = 'stationId',
  role = 'role',
  user = 'user',
  owner = 'owner'
}

export interface BSResponsiblePersonsFields {
  // [ResponsiblePersonsFilterFields.stationId]?: number;
  [ResponsiblePersonsFilterFields.role]?: string;
  [ResponsiblePersonsFilterFields.user]?: string;
  [ResponsiblePersonsFilterFields.owner]?: number;
}

export enum Registered {
  user = 'User',
  notUser = 'Not User'
}