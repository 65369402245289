import { Endpoint } from 'models/rabbit';

export const DICT_ENDPOINTS_CHANGE = 'CHANGE_DICTIONARY_ENDPOINT';

export interface DictEndpointsChange {
  readonly type: typeof DICT_ENDPOINTS_CHANGE;
  endpoints: Endpoint[];
}

export const DictEndpointsChange = (endpoints: Endpoint[]): DictEndpointsChange => ({
  type: DICT_ENDPOINTS_CHANGE, endpoints
});

export type Action = DictEndpointsChange
