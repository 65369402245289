import {
  Permissions,
  PermissionsCreateParams,
  PermissionsUpdateParams,
} from 'models/user-management';

export const CREATE_PERMISSIONS = 'um/permissions/CREATE_PERMISSIONS';
export const CREATE_PERMISSIONS_SUCCESS = 'um/permissions/CREATE_PERMISSIONS_SUCCESS';

export const DELETE_PERMISSIONS = 'um/permissions/DELETE_PERMISSIONS';
export const DELETE_PERMISSIONS_SUCCESS = 'um/permissions/DELETE_PERMISSIONS_SUCCESS';

export const UPDATE_PERMISSIONS = 'um/permissions/UPDATE_PERMISSIONS';
export const UPDATE_PERMISSIONS_SUCCESS = 'um/permissions/UPDATE_PERMISSIONS_SUCCESS';

export const FETCH_PERMISSIONS = 'um/permissions/FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_SUCCESS = 'um/permissions/FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILURE = 'um/permissions/FETCH_PERMISSIONS_FAILURE';

export interface CreatePermissions {
  readonly type: typeof CREATE_PERMISSIONS;
  readonly payload: PermissionsCreateParams;
}

export type CreatePermissionsResult = Permissions;

export interface CreatePermissionsSuccess {
  readonly type: typeof CREATE_PERMISSIONS_SUCCESS;
  readonly permissions: Permissions;
}

export interface DeletePermissions {
  readonly type: typeof DELETE_PERMISSIONS;
  readonly id: Permissions['id'];
}

export type DeletePermissionsResult = void;

export interface DeletePermissionsSuccess {
  readonly type: typeof DELETE_PERMISSIONS_SUCCESS;
  readonly id: Permissions['id'];
}

export interface UpdatePermissions {
  readonly type: typeof UPDATE_PERMISSIONS;
  readonly payload: PermissionsUpdateParams;
}

export type UpdatePermissionsResult = Permissions;

export interface UpdatePermissionsSuccess {
  readonly type: typeof UPDATE_PERMISSIONS_SUCCESS;
  readonly permissions: Permissions;
}

export interface FetchPermissions {
  readonly type: typeof FETCH_PERMISSIONS;
}

export interface FetchPermissionsSuccess {
  readonly type: typeof FETCH_PERMISSIONS_SUCCESS;
  readonly payload: {
    readonly permissionsById: {
      readonly [permissionId: number]: Permissions;
    };
  };
}

export interface FetchPermissionsFailure {
  readonly type: typeof FETCH_PERMISSIONS_FAILURE;
  readonly error: string;
}

export const createPermissions = (payload: PermissionsCreateParams): CreatePermissions => ({
  type: CREATE_PERMISSIONS,
  payload,
});

export const createPermissionsSuccess = (permissions: Permissions): CreatePermissionsSuccess => ({
  type: CREATE_PERMISSIONS_SUCCESS,
  permissions,
});

export const deletePermissions = (id: Permissions['id']): DeletePermissions => ({
  type: DELETE_PERMISSIONS,
  id,
});

export const deletePermissionsSuccess = (id: Permissions['id']): DeletePermissionsSuccess => ({
  type: DELETE_PERMISSIONS_SUCCESS,
  id,
});

export const updatePermissions = (payload: PermissionsUpdateParams): UpdatePermissions => ({
  type: UPDATE_PERMISSIONS,
  payload,
});

export const updatePermissionsSuccess = (permissions: Permissions): UpdatePermissionsSuccess => ({
  type: UPDATE_PERMISSIONS_SUCCESS,
  permissions,
});

export const fetchPermissions: () => FetchPermissions = () => ({
  type: FETCH_PERMISSIONS,
});

export const fetchPermissionsSuccess: (payload: FetchPermissionsSuccess['payload']) => FetchPermissionsSuccess =
  (payload) => ({
    type: FETCH_PERMISSIONS_SUCCESS,
    payload,
  });

export const fetchPermissionsFailure: (error: string) => FetchPermissionsFailure = (error) => ({
  type: FETCH_PERMISSIONS_FAILURE,
  error,
});

export type Action = (
  | CreatePermissions
  | CreatePermissionsSuccess
  | DeletePermissions
  | DeletePermissionsSuccess
  | UpdatePermissions
  | UpdatePermissionsSuccess
  | FetchPermissions
  | FetchPermissionsSuccess
  | FetchPermissionsFailure
);
