import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import { Project } from 'models/user-management';
import * as actions from 'actions/projects';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createProject, deleteProject, editProject, fetchProjectById } from 'clients/user-management';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import { clearQueryCache } from 'utils/react-query';

type CreateAction =
  | actions.CreateProject
  | ActionWithPromise<actions.CreateProject, Project>

function* createProjectSaga(action: CreateAction) {
  const response: ApiResponse = yield call(createProject, action.params);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoCreateProjectSuccess());
    const project: Project = response.data;
    yield put(NotifySuccess('Project has been added'));
    'meta' in action && action.meta.promise.resolve(project);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(actions.DoCreateProjectFailed());
    yield put(NotifyError(`Error while creating a project: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }

  yield clearQueryCache('userManagement/project');
}

function* getProjectById(action: actions.GetProjectById) {
  const response: ApiResponse = yield call(fetchProjectById, action.id);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetProjectByIdSuccess(response.data));
  } else {
    yield put(NotifyError(`Error while fetching a project: ${response.message}`));
    yield put(actions.DoGetProjectByIdFailed());
  }
}

function* editProjectSaga(action: actions.EditProject) {
  const response: ApiResponse = yield call(editProject, action.params);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoEditProjectSuccess(response.data));
    yield put(NotifySuccess('Project information has been updated'));
  } else {
    yield put(NotifyError(`Error while editing a project: ${response.message}`));
    yield put(actions.DoEditProjectFailed());
  }

  yield clearQueryCache('userManagement/project');
}

function* deleteProjectSaga(action: actions.DeleteProject) {
  const response: ApiResponse = yield call(deleteProject, action.id);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoDeleteProjectSuccess(response.data.deleted));
    yield put(NotifySuccess(`Project has been removed`));
  } else {
    yield put(NotifyError(`Error while removing a project: ${response.message}`));
  }

  yield clearQueryCache('userManagement/project');
}


export const projectSagas = [
  takeEvery(actions.CREATE_PROJECT, createProjectSaga),
  takeEvery(actions.GET_PROJECT_BY_ID, getProjectById),
  takeEvery(actions.EDIT_RESORCE_GROUP, editProjectSaga),
  takeEvery(actions.DELETE_RESORCE_GROUP, deleteProjectSaga)
];
