/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.17.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatisticMetricEnum = 'silent_ok' | 'incidents_number' | 'not_resolved_number' | 'failures_history' | 'silent_history' | 'total_failures';

export const StatisticMetricEnum = {
    SilentOk: 'silent_ok' as StatisticMetricEnum,
    IncidentsNumber: 'incidents_number' as StatisticMetricEnum,
    NotResolvedNumber: 'not_resolved_number' as StatisticMetricEnum,
    FailuresHistory: 'failures_history' as StatisticMetricEnum,
    SilentHistory: 'silent_history' as StatisticMetricEnum,
    TotalFailures: 'total_failures' as StatisticMetricEnum
}
