import React from 'react';
import { Reason } from 'models/monitoring';
import { MonitoringSchema, useMonitoringReasonsDictionary } from 'hooks/monitoring';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & {
    filter?: (reason: Reason) => boolean;
    schema?: MonitoringSchema;
  }

export const IncidentReasonSelect = (props: Props): JSX.Element => {
  const { isLoading, reasons } = useMonitoringReasonsDictionary(props.schema ?? 'global');
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<string>[] =
    reasons
      .filter(filterCallback)
      .map(reason => ({
        value: reason.id,
        label: reason.name,
      }));

  return (
    <SelectControl
      name="reason"
      label="Reason"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ !isLoading }
      isDisabled={ !options.length || props.isDisabled }
    />
  );
};
