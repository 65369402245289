import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createStationLorawan } from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { LorawanStation } from 'models/base-station';
import { createErrorFromApiResponse } from 'utils/errors';
import { clearQueryCache } from 'utils/react-query';

type CreateAction =
  | Action.CreateStationLorawan
  | ActionWithPromise<Action.CreateStationLorawan, LorawanStation>

function* doCreateStationLora(action: CreateAction) {
  const { params } = action;

  const response: ApiResponse = yield call(createStationLorawan, params);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: LorawanStation = response.data as LorawanStation;
    yield put(NotifySuccess(`LoRaWAN station ${ newStation.eui } has been created`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearQueryCache(['lorawanBaseStation/stations']);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating LoRaWAN base station: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

export default [
  takeEvery(Action.LORAWAN_BASE_STATION_LOCATION_CREATE, doCreateStationLora),
];