import { API } from 'aws-amplify';

import { errorHandler, formatQuery } from 'clients/utils';
import { ApiResponse } from 'models';
import { GetRoutersParams, Router, CreateRouterData } from 'models/caller';

import { config } from './config';

const urls = {
  routers: '/calls/routes',
  getRouterById: (routerId: number) => `/calls/routes/${ routerId }`,
};

export const getRouters = (params: GetRoutersParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      template_suite_id: params.templateSuiteId,
      zone_id: params.zoneId,
      group_id: params.groupId,
      is_active: params.isActive,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.routers, body)
    .catch(errorHandler);
};

export const createRouter = (router: CreateRouterData): Promise<ApiResponse> => {
  const options = {
    body: {
      ...router,
      route_id: undefined, // backend don't want see identifier in body
    }
  };

  return API
    .post(config.name, urls.routers, options)
    .catch(errorHandler);
};

export const updateRouter = (router: Router): Promise<ApiResponse> => {
  const options = {
    body: {
      ...router,
      route_id: undefined, // backend don't want see identifier in body
    }
  };

  return API
    .put(config.name, urls.getRouterById(router.route_id), options)
    .catch(errorHandler);
};

export const deleteRouter = (router: Router): Promise<ApiResponse> => {
  const options = {};

  return API
    .del(config.name, urls.getRouterById(router.route_id), options)
    .catch(errorHandler);
};
