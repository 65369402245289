import { fetchLevelsTotalPositions } from '../../../api';
import { getLevelNodes } from '../../../node/utils';
import { LevelNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillLevelTotalPositions({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, LevelNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getLevelNodes(getNodes(nodes)),
    getQuery: node => node.totalPositions,
    fetchDataByNode: fetchLevelsTotalPositions,
  });
}
