import React from 'react';

// components
import { Button, Typography } from '@material-ui/core';
import { ViewHeadline, ViewModule } from '@material-ui/icons';

export enum ViewType {
  TABLE = 'table',
  CARD = 'card',
}

interface Props {
  type?: ViewType;
  onChange: (type: ViewType) => void;
}

export const ViewTypeButton = ({ type, onChange }: Props): JSX.Element => {
  if (type === ViewType.CARD) {
    return (
      <Button
        variant="text"
        color="secondary"
        startIcon={ <ViewHeadline color="action" /> }
        onClick={ () => onChange(ViewType.TABLE) }
      >
        <Typography color="textSecondary">
          Table
        </Typography>
      </Button>
    );
  }
  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={ <ViewModule color="action" /> }
      onClick={ () => onChange(ViewType.CARD) }
    >
      <Typography color="textSecondary">
        Cards
      </Typography>
    </Button>
  );
};
