import { createStyles, makeStyles } from 'styles/utils';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      overflow: 'visible',
    },
    dialogContent: {
      overflow: 'visible',
    },
    dialogListContent: {
      flex: '1 1 auto',
      overflowY: 'auto',
      minHeight: 0,
    },
    dialogTabLabel: {
      fontSize: '16px',
      textTransform: 'none',
    },
  }),
);

export default useStyles;
