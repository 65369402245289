import { call, put, takeEvery, takeLeading, all } from 'redux-saga/effects';

import { ApiResponse, ReasonEnum } from 'models';
import { createTemplateSuite, getSuiteById, getTemplateSutes, removeSuiteById, updateSuiteById, createTemplate } from 'clients/http-calls';
import * as Action from 'actions/caller';
import { DictionaryReset } from 'actions/dictionary';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ActionWithPromise } from 'utils/store';
import { TemplateSuite, TemplateSuiteUpdateFields } from 'models/caller';
import { Dictionary } from 'models/dictionary';

function* doLoadDataByParams(action: Action.LoadTemplateSuiteDataByParams) {
  yield put({ type: Action.CALLER_TEMPLATE_SUITE_DATA_FETCH_START, params: action.params });
  const response: ApiResponse = yield call(getTemplateSutes, action.params);
  yield put({ type: Action.CALLER_TEMPLATE_SUITE_DATA_FETCH_STOP, params: action.params });

  if (response.reason === ReasonEnum.Ok) {
    yield put({ type: Action.CALLER_TEMPLATE_SUITE_DATA_CHANGE, params: action.params, data: response.data });
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching caller template suites: ${ message }`));
  }
}

function* doLoadDataById(action: Action.LoadTemplateSuiteDataById) {
  yield put({ type: Action.CALLER_TEMPLATE_SUITE_DATA_FETCH_START, params: action.id });
  const response: ApiResponse = yield call(getSuiteById, action.id);
  yield put({ type: Action.CALLER_TEMPLATE_SUITE_DATA_FETCH_STOP, params: action.id });

  if (response.reason === ReasonEnum.Ok) {
    yield put({ type: Action.CALLER_TEMPLATE_SUITE_DATA_CHANGE, params: action.id, data: [response.data] });
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching a caller template suite: ${ message }`));
  }
}

type CreateAction =
  | Action.CreateTemplateSuite
  | ActionWithPromise<Action.CreateTemplateSuite, TemplateSuite>

function* doCreate(action: CreateAction) {
  const templateSuiteId = action.templateSuite.template_suite_id;
  yield put(Action.LockTemplateSuite(templateSuiteId));
  const response: ApiResponse = yield call(createTemplateSuite, action.templateSuite);
  yield put(Action.UnlockTemplateSuite(templateSuiteId));

  if (response.reason === ReasonEnum.Ok) {
    const templateSuite: TemplateSuite = response.data as TemplateSuite;
    yield put(Action.ChangeTemplateSuite(templateSuite, { create: true }));
    yield put(NotifySuccess(`Caller template suite "${ templateSuite.name }" has been created`));
    'meta' in action && action.meta.promise.resolve(templateSuite);
    yield put(DictionaryReset(Dictionary.CALLER_TEMPLATE_SUITES));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while creating caller template suite "${ action.templateSuite.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

type UpdateAction =
  | Action.UpdateTemplateSuite
  | ActionWithPromise<Action.UpdateTemplateSuite, TemplateSuite>

function* doUpdate(action: UpdateAction) {
  const templateSuiteId = action.templateSuite.template_suite_id;
  const updateFields: TemplateSuiteUpdateFields = {
    name: action.templateSuite.name,
    owner_id: action.templateSuite.owner_id,
  };

  yield put(Action.LockTemplateSuite(templateSuiteId));
  const response: ApiResponse = yield call(updateSuiteById, templateSuiteId, updateFields);
  yield put(Action.UnlockTemplateSuite(templateSuiteId));

  if (response.reason === ReasonEnum.Ok) {
    const templateSuite: TemplateSuite = response.data as TemplateSuite;
    yield put(Action.ChangeTemplateSuite(templateSuite, { update: true }));
    yield put(NotifySuccess(`Caller template suite "${ templateSuite.name }" has been updated`));
    'meta' in action && action.meta.promise.resolve(templateSuite);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while updating caller template suite "${ action.templateSuite.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

type DeleteAction =
  | Action.DeleteTemplateSuite
  | ActionWithPromise<Action.DeleteTemplateSuite, TemplateSuite>

function* doDelete(action: DeleteAction) {
  const templateSuiteId = action.templateSuite.template_suite_id;
  yield put(Action.LockTemplateSuite(templateSuiteId));
  const response: ApiResponse = yield call(removeSuiteById, templateSuiteId);
  yield put(Action.UnlockTemplateSuite(templateSuiteId));

  if (response.reason === ReasonEnum.Ok) {
    const templateSuite: TemplateSuite = action.templateSuite as TemplateSuite;
    yield put(Action.ChangeTemplateSuite(templateSuite, { delete: true }));
    yield put(NotifySuccess(`Caller template suite "${ templateSuite.name }" has been deleted`));
    'meta' in action && action.meta.promise.resolve(templateSuite);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting caller template suite "${ action.templateSuite.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

type CloneAction =
  | Action.CloneTemplateSuite
  | ActionWithPromise<Action.CloneTemplateSuite, TemplateSuite>

function* doClone(action: CloneAction) {
  const { templates, templateSuite } = action;
  const templateSuiteId = action.templateSuite.template_suite_id;
  yield put(Action.LockTemplateSuite(templateSuiteId));
  const response: ApiResponse = yield call(createTemplateSuite, templateSuite);
  yield put(Action.UnlockTemplateSuite(templateSuiteId));

  if (response.reason === ReasonEnum.Ok) {
    const templateSuite: TemplateSuite = response.data as TemplateSuite;
    yield all(templates.map(template => call(createTemplate, { ...template, template_suite_id: templateSuite.template_suite_id })));

    yield put(Action.ChangeTemplateSuite(templateSuite, { create: true }));
    yield put(NotifySuccess(`Caller template suite "${ templateSuite.name }" has been cloned`));
    'meta' in action && action.meta.promise.resolve(templateSuite);
    yield put(DictionaryReset(Dictionary.CALLER_TEMPLATE_SUITES));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while cloning caller template suite "${ action.templateSuite.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

export default [
  takeEvery(Action.CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_ID, doLoadDataById),
  takeEvery(Action.CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_PARAMS, doLoadDataByParams),
  takeLeading(Action.CALLER_TEMPLATE_SUITE_CREATE, doCreate),
  takeLeading(Action.CALLER_TEMPLATE_SUITE_UPDATE, doUpdate),
  takeLeading(Action.CALLER_TEMPLATE_SUITE_DELETE, doDelete),
  takeLeading(Action.CALLER_TEMPLATE_SUITE_CLONE, doClone)
];
