import React from 'react';

import { Device, DeviceColdData } from 'models/device-management';
import { formatDate, formatDateTime } from 'utils/datetime';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  InputAdornment,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Error as ErrorIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { FieldSkeleton } from 'components/Skeleton';
import { DateTimeControl } from 'components/Controls';

// styles
import { useInfoBlockStyles } from './InfoBlock/style';

interface Props {
  device?: Device & DeviceColdData;
  idPrefix?: string;
}

const DeviceBusinessInfo: React.FC<Props> = (props) => {
  const { device } = props;
  const infoCss = useInfoBlockStyles();

  if (!device) {
    return <FieldSkeleton className={infoCss.field} />;
  }

  const deviceCalibrated = device.calibration_status;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Device business information
      </AccordionSummary>

      <AccordionDetails>
        <FormGroup className={infoCss.fields}>
          <TextField
            className={infoCss.field}
            disabled
            label="Last calibration time"
            value={deviceCalibrated
              ? device.calibration_date_time
                ? formatDateTime(device.calibration_date_time)
                : 'Unknown'
              : 'Not calibrated'
            }
            error={!deviceCalibrated}
            InputProps={{
              endAdornment: !deviceCalibrated && (
                <InputAdornment position="end">
                  <IconButton disabled>
                    <ErrorIcon color="error" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last business info update time"
            value={device.last_update_datetime
              ? formatDateTime(device.last_update_datetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last firmware update time"
            value={device.last_fw_updatetime
              ? formatDateTime(device.last_fw_updatetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Production date"
            value={device.assembling_datetime
              ? formatDate(device.assembling_datetime)
              : ''
            }
          />

          <DateTimeControl
            className={infoCss.field}
            isDisabled
            label="Purchase time"
            onChange={ () => true }
            value={ device.purchase_datetime }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Warranty expiration date"
            value={device.expiration_datetime
              ? formatDate(device.expiration_datetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last disabling time"
            value={device.expiration_datetime
              ? formatDateTime(device.expiration_datetime)
              : ''
            }
          />

          <TextField
            className={infoCss.field}
            disabled
            label="Last disabling reason"
            multiline
            value={device.disabling_reason || ''}
          />
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default DeviceBusinessInfo;
