import { API } from 'aws-amplify';
import { config } from './config';
import { errorHandler, formatQuery } from 'clients/utils';
import {
  ResponseReceivedDevices,
  ResponseReceivedShipments,
  ResponseReceivedBoxes,
  ReceivedShipmentsFilter, ReceivedBoxesFilter, ReceivedDevicesFilter
} from 'models/provisioning';

const urls = {
  shipments: '/provisioning/received/shipments',
  boxes: '/provisioning/received/boxes',
  devices: '/provisioning/received/devices',
};

export const fetchProvisioningReceivedShipments = (filter: ReceivedShipmentsFilter): Promise<ResponseReceivedShipments> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: filter.limit,
      offset: filter.offset,
      device_id: filter.devices,
      box_id: filter.boxes,
      shipment_id: filter.shipments,
      shipper_id: filter.shippers,
      recipient_id: filter.recipients,
    }),
  };

  return API
    .get(config.name, urls.shipments, body)
    .catch(errorHandler);
};

export const fetchProvisioningReceivedBoxes = (filter: ReceivedBoxesFilter): Promise<ResponseReceivedBoxes> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: filter.limit,
      offset: filter.offset,
      device_id: filter.devices,
      box_id: filter.boxes,
      shipment_id: filter.shipments,
      shipper_id: filter.shippers,
      recipient_id: filter.recipients,
    }),
  };

  return API
    .get(config.name, urls.boxes, body)
    .catch(errorHandler);
};

export const fetchProvisioningReceivedDevices = (filter: ReceivedDevicesFilter): Promise<ResponseReceivedDevices> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: filter.limit,
      offset: filter.offset,
      device_id: filter.devices,
      box_id: filter.boxes,
      shipment_id: filter.shipments,
      shipper_id: filter.shippers,
      recipient_id: filter.recipients,
    }),
  };

  return API
    .get(config.name, urls.devices, body)
    .catch(errorHandler);
};