import { UserPayload } from './user-profile';
import { IntegrationLastTimeReceive, IntegrationFiltersParams } from 'models/lorawan';
import { ApiKey } from 'models/user-management';

export const SEND_EMAIL = 'lorawan/SEND_EMAIL';
export const GET_STATUS = 'lorawan/GET_STATUS';
export const GET_STATUS_SUCCESS = 'lorawan/GET_STATUS_SUCCESS';
export const DISABLE_INTEGRATION = 'lorawan/DISABLE_INTEGRATION';

export interface SendEmail {
  readonly type: typeof SEND_EMAIL;
  readonly user: UserPayload;
  readonly integration: string;
}
export interface GetStatus {
  readonly type: typeof GET_STATUS;
  readonly filters: IntegrationFiltersParams;
  readonly isAdmin: boolean;
}
export interface GetStatusSuccess {
  readonly type: typeof GET_STATUS_SUCCESS;
  readonly lastTimeReceive: IntegrationLastTimeReceive[];
  readonly apiKey: ApiKey[];
  readonly filters: IntegrationFiltersParams;
  readonly isAdmin: boolean;
}
export interface DisableIntegration {
  readonly type: typeof DISABLE_INTEGRATION;
  readonly apiKeyId: ApiKey['id'];
}

export const DoSendEmail: (user: UserPayload, integration: string) => SendEmail = (user, integration) => ({
  type: SEND_EMAIL, user, integration
});

export const DoGetStatus: (filters: IntegrationFiltersParams, isAdmin: boolean) => GetStatus = (filters, isAdmin) => ({
  type: GET_STATUS, filters, isAdmin
});
export const DoGetStatusSuccess: (
  lastTimeReceive: IntegrationLastTimeReceive[],
  apiKey: ApiKey[],
  filters: IntegrationFiltersParams,
  isAdmin: boolean,
) => GetStatusSuccess = (lastTimeReceive, apiKey, filters, isAdmin) => ({
  type: GET_STATUS_SUCCESS, lastTimeReceive, apiKey, filters, isAdmin
});
export const DoDisableIntegration: (apiKeyId: ApiKey['id']) => DisableIntegration = apiKeyId => ({
  type: DISABLE_INTEGRATION, apiKeyId
});

export type Action = (
  | SendEmail
  | GetStatus
  | GetStatusSuccess
  | DisableIntegration
)
