import { put, takeEvery, call } from 'redux-saga/effects';

import {
  FETCH_LEVELS,
  FetchLevels,
  DoFetchLevelsSuccess,
  DoFetchLevelsFailure,

  CREATE_LEVEL,
  DoCreateLevelSuccess,
  DoCreateLevelFailure,

  UPDATE_LEVEL,
  DoUpdateLevelSuccess,
  DoUpdateLevelFailure,

  DELETE_LEVEL,
  DoDeleteLevelSuccess,
  DoDeleteLevelFailure,

  DoChangeLevel,
} from 'actions/device-management/levels';
import { NotifyError, NotifySuccess } from 'actions/notifier';

import {
  fetchLevels,
  createLevel,
  updateLevel,
  deleteLevel,
} from 'clients/device-management';

import { ResponseLevel, ResponseLevels, ResponseReason } from 'models/device-management';
import {
  CreateLevelSagaAction,
  UpdateLevelSagaAction,
  DeleteLevelSagaAction,
} from 'models/device-management/actions';

function* fetchLevelsSaga(action: FetchLevels) {
  const response: ResponseLevels = yield call(fetchLevels, action.params);

  if (response.reason === ResponseReason.Ok && response.data) {
    yield put(DoFetchLevelsSuccess(action.params, response.data));
  } else {
    yield put(DoFetchLevelsFailure(action.params, response.message));
    yield put(NotifyError(`Error while fetching levels: ${response.message}`));
  }
}

function* createLevelSaga(action: CreateLevelSagaAction) {
  const response: ResponseLevel = yield call(createLevel, action.level);

  if (response.reason === ResponseReason.Ok) {
    const level = response.data;
    yield put(DoChangeLevel(level, { create: true }));
    yield put(DoCreateLevelSuccess(level));
    yield put(NotifySuccess('Level has been added'));
    'meta' in action && action.meta.promise.resolve(level);
  } else {
    const { message } = response;
    yield put(DoCreateLevelFailure(message));
    yield put(NotifyError(`Error while adding a level: ${message}`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

function* updateLevelSaga(action: UpdateLevelSagaAction) {
  const response: ResponseLevel = yield call(updateLevel, action.levelId, action.level);

  if (response.reason === ResponseReason.Ok) {
    const level = response.data;
    yield put(DoChangeLevel(level, { update: true }));
    yield put(DoUpdateLevelSuccess(level));
    yield put(NotifySuccess('Level has been updated'));
    'meta' in action && action.meta.promise.resolve(level);
  } else {
    const { message } = response;
    yield put(DoUpdateLevelFailure(message));
    yield put(NotifyError(`Error while updating a level: ${message}`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

function* deleteLevelSaga(action: DeleteLevelSagaAction) {
  const response: ResponseLevel = yield call(deleteLevel, action.level.id);

  if (response.reason === ResponseReason.Ok) {
    yield put(DoChangeLevel(action.level, { delete: true }));
    yield put(DoDeleteLevelSuccess(action.level));
    yield put(NotifySuccess('Level has been deleted'));
    'meta' in action && action.meta.promise.resolve();
  } else {
    const { message } = response;
    yield put(DoDeleteLevelFailure(action.level, message));
    yield put(NotifyError(`Error while deleting a level: ${message}`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

export const deviceManagementLevelsSagas = [
  takeEvery(FETCH_LEVELS, fetchLevelsSaga),
  takeEvery(CREATE_LEVEL, createLevelSaga),
  takeEvery(UPDATE_LEVEL, updateLevelSaga),
  takeEvery(DELETE_LEVEL, deleteLevelSaga),
];
