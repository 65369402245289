import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { RootState } from 'reducers';
import { GetTemplateSuitesParams } from 'models/caller';
import { DoRoutesTemplatesSuitesLoad } from 'actions/caller';
import { TemplateRouteSuite } from 'reducers/caller/routes-templates-suites';
import { FilterState, convertParamsToState, convertStateToParams, initialParams, buildParams } from 'components/Caller/TemplateSuitesFilter/Component';
import { useLocationParams } from 'hooks/location';
import { useDialog } from 'hooks';
import { useAuthUserSelector } from 'hooks';
// components
import AddButton from 'components/Buttons/AddButton';
import { TemplateSuitesFilter, RoutesTemplatesSuitesTable } from 'components/Caller';
import { FilterButton } from 'components/Buttons';
import { CreateSuiteDialog } from 'components/Caller/CreateSuiteDialog/CreateSuiteDialogComponent';
// styles
import useStyles from 'styles/page';

/* in this page used client side filtering, we fetch data from server once */
const fetchParams: GetTemplateSuitesParams = { limit: 999 };
const filterByParams = (params: GetTemplateSuitesParams, suites: TemplateRouteSuite[]): TemplateRouteSuite[] => {
  return suites.filter(suite => {
    if (params.owner !== undefined && params.owner !== suite.owner_id) {
      return false;
    }

    if (params.name !== undefined && params.name !== suite.name) {
      return false;
    }

    return true;
  });
};

const RoutesTemplatesSuitesPage = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addDialog = useDialog();
  const user = useAuthUserSelector();
  const templatesRoutesSuites = useSelector((state: RootState) => state.caller.templatesRoutesSuites);

  const [params, setParams] = useState<GetTemplateSuitesParams>({});
  const [filterState, setFilterState] = useState<FilterState>(convertParamsToState(params));

  const isLoading = templatesRoutesSuites.isFetched !== true;
  const data = filterByParams(params, templatesRoutesSuites.data);

  const applyParams = (params: GetTemplateSuitesParams) => {
    setParams(params);
    dispatch(DoRoutesTemplatesSuitesLoad(fetchParams));
  };

  const setFilterParams = useLocationParams<GetTemplateSuitesParams>(initialParams, buildParams, (params) => {
    setFilterState(convertParamsToState(params));
    applyParams(params);
  });

  const onSave = () => setFilterParams(convertStateToParams(filterState));
  const onReset = () => {
    setFilterParams(initialParams);
    setParams(initialParams);
    setFilterState(convertParamsToState(initialParams));
  };

  return (
    <div data-testid="routes-template-suites-page">
      <Helmet>
        <title>Template Suites</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ isLoading }
          filter={ params }
          onSave={ onSave }
          onReset={ onReset }
        >
          <TemplateSuitesFilter
            canViewOwner={ user.isAdmin }
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div>
      <div className={ classes.table }>
        <RoutesTemplatesSuitesTable
          isLoading={ isLoading }
          canViewOwner={ user.isAdmin }
          suites={ data }
        />
      </div>
      <AddButton
        label="Add template suite"
        isLoading={ isLoading }
        onClick={ addDialog.open }
      />
      { addDialog.isMounted && (
        <CreateSuiteDialog
          isOpen={ addDialog.isOpen }
          onClose={ addDialog.close }
          onCloseEnd={ addDialog.unmount }
        />
      ) }
    </div>
  );
};

export default RoutesTemplatesSuitesPage;
