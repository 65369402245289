import React from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormActionLoader, useFormEditToogleActions } from 'hooks/form';
import { Template } from 'models/caller';
import { RootState } from 'reducers';
import { UpdateTemplate } from 'actions/caller';
// components
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CallerTemplateForm, TemplateFormField } from 'components/Caller';

interface Props {
  template: Template;
}

export const CallerTemplateOptions = (props: Props): JSX.Element => {
  const { template } = props;
  const isLocked = useSelector((state: RootState) => state.caller.template.isLocked(template.template_id));

  const form = useForm<Template, TemplateFormField>({
    initialState: template,
    showFields: [
      TemplateFormField.url,
      TemplateFormField.method,
      TemplateFormField.headers,
      TemplateFormField.body,
    ]
  });

  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: !isLocked,
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: UpdateTemplate(form.state),
        onError: form.catchError
      });
    },
    onCancel: form.reset
  });

  return (
    <Card>
      <CardHeader title="Template options" action={ actions }/>
      <CardContent>
        <CallerTemplateForm isView={ !editMode } { ...form } />
      </CardContent>
    </Card>
  );
};
