import { User } from 'models/user-management';

export const DICT_USERS_CHANGE = 'DICT_USERS_CHANGE';

export interface DictUsersChange {
  readonly type: typeof DICT_USERS_CHANGE;
  users: User[];
}

export const DictUsersChange = (users: User[]): DictUsersChange => ({
  type: DICT_USERS_CHANGE, users
});

export type Action = DictUsersChange
