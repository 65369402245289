import { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadFirmwareDataById, LoadFirmwareDataByParams, LoadFirmwareDataDictionary } from 'actions/firmware-management';
import { Firmware, GetFirmwareParams, VIRTUAL_FIRMWARE } from 'models/firmware-management';

export interface FirmwareState {
  firmware?: Firmware;
  isLoading: boolean;
}

export function useFirmwareSelector(firmwareHash: string): FirmwareState {
  const dispatch = useDispatch();
  const { isFetched, firmware } = useSelector((state: RootState) => ({
    isFetched: state.fwm.firmware.isFetched(firmwareHash),
    firmware: state.fwm.firmware.findById(firmwareHash),
  }), shallowEqual);

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadFirmwareDataById(firmwareHash));
    }
  }, [dispatch, isFetched, firmwareHash]);

  return {
    firmware: firmware,
    isLoading: isFetched !== true
  };
}

export interface FirmwaresState {
  firmwares: Firmware[];
  isLoading: boolean;
}

export function useFirmwaresParamsSelector(params: GetFirmwareParams): FirmwaresState {
  const dispatch = useDispatch();
  const { isFetched, firmwares } = useSelector((state: RootState) => ({
    isFetched: state.fwm.firmware.isFetched(params),
    firmwares: state.fwm.firmware.findByParams(params),
  }), shallowEqual);

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadFirmwareDataByParams(params));
    }
  }, [dispatch, isFetched, params]);

  return {
    firmwares: firmwares,
    isLoading: isFetched !== true
  };
}

export function getVirtualFirmwares(firmwares: Firmware[]): Firmware[] {
  return firmwares.filter(firmware =>
    Object.values(VIRTUAL_FIRMWARE).includes(firmware.hash as VIRTUAL_FIRMWARE)
  );
}

export function useFirmwaresDictionarySelector(): FirmwaresState {
  const dispatch = useDispatch();
  const { isFetched, firmwares } = useSelector((state: RootState) => ({
    isFetched: state.fwm.firmware.isFetched({}),
    firmwares: state.fwm.firmware.findByParams({}),
  }), shallowEqual);

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadFirmwareDataDictionary());
    }
  }, [dispatch, isFetched]);

  return {
    firmwares: firmwares,
    isLoading: isFetched !== true
  };
}

