import invariant from 'tiny-invariant';

import { filterNodesDeepFlatten } from '../base/utils';
import {
  DmTreeNode,
  TreeNodeWithChildren,
  LoadingStatus,
  Query,
} from '../types';

interface ChildrenContainer {
  children: DmTreeNode[];
  childrenTotal?: Query<number> | number;
  childrenLoadStatus: LoadingStatus;
}

export interface ChildrenData {
  children: DmTreeNode[];
  childrenTotal: number;
}

export function updateChildren(
  container: ChildrenContainer,
  nextChildrenData: ChildrenData,
) {
  container.children = updateNodesPreservingState(
    container.children,
    nextChildrenData.children
  );
  container.childrenLoadStatus = 'success';

  if (typeof container.childrenTotal === 'object') {
    container.childrenTotal.data = nextChildrenData.childrenTotal;
    container.childrenTotal.status = 'success';
  } else {
    container.childrenTotal = nextChildrenData.childrenTotal;
  }
}

type NodeTuple = [string, DmTreeNode];

function updateNodesPreservingState(
  nodes: DmTreeNode[],
  nextNodes: DmTreeNode[]
): DmTreeNode[] {
  const nodeTuples = filterNodesDeepFlatten(nodes).map((n): NodeTuple => [n.id, n]);
  const originalNodesMap = new Map<string, DmTreeNode>(nodeTuples);

  function iterate(children: DmTreeNode[]) {
    return children.map((nextNode) => {
      const originalNode = originalNodesMap.has(nextNode.id)
        ? originalNodesMap.get(nextNode.id)
        : undefined;

      if (!originalNode) {
        return nextNode;
      }

      updateNodePreservingState(originalNode, nextNode);

      if (
        'children' in originalNode &&
        'children' in nextNode &&
        nextNode.childrenLoadStatus === 'success'
      ) {
        (originalNode as TreeNodeWithChildren).children = iterate(
          nextNode.children
        );
        originalNode.childrenTotal = nextNode.childrenTotal;
        originalNode.childrenFilters = nextNode.childrenFilters;
      }

      return originalNode;
    });
  }

  return iterate(nextNodes);
}

export function updateNodePreservingState(
  originalNode: DmTreeNode,
  nextNode: DmTreeNode
): void {
  if (nextNode.parentId) {
    originalNode.parentId = nextNode.parentId;
  }

  updateNodeEntity(originalNode, nextNode);
}

function updateNodeEntity(
  originalNode: DmTreeNode,
  nextNode: DmTreeNode
): void {
  switch (originalNode.type) {
    case 'zone': {
      invariant(
        nextNode.type === 'zone',
        'Expected a zone to be matched to zones'
      );
      originalNode.zone = nextNode.zone;
      break;
    }

    case 'level': {
      invariant(
        nextNode.type === 'level',
        'Expected a level to be matched to levels'
      );
      originalNode.level = nextNode.level;
      break;
    }

    case 'group': {
      invariant(
        nextNode.type === 'group',
        'Expected a group to be matched to groups'
      );
      originalNode.group = nextNode.group;
      break;
    }

    case 'position': {
      invariant(
        nextNode.type === 'position',
        'Expected a position to be matched to positions'
      );
      originalNode.position = nextNode.position;
      break;
    }

    default: {
      throw new Error(
        `Unexpected node type in ${JSON.stringify(originalNode)}`
      );
    }
  }
}
