import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formDialogPaper: {
      overflow: 'visible',
    },
    formDialogContent: {
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      marginTop: theme.spacing(2),
    },
    actionRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginLeft: theme.spacing(2),
    },
    saveBtn: {
      marginLeft: theme.spacing(1),
    }
  }),
);

export default useStyles;
