import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadDeviceEmulationSettingsDataById } from 'actions/device-emulation';
import { DeviceSettings } from 'models/device-emulation';

export interface DeviceSettingsState {
  deviceSettings?: DeviceSettings;
  isLoading: boolean;
}

export function useDeviceEmulationSettingsSelector(deviceId: string | undefined): DeviceSettingsState {
  const dispatch = useDispatch();
  const { isFetched, data } = useSelector((state: RootState) => ({
    isFetched: deviceId ? state.deviceEmulation.deviceSettings.isFetched(deviceId) : false,
    data: deviceId ? state.deviceEmulation.deviceSettings.findById(deviceId) : undefined,
  }));

  useDeepCompareEffect(() => {
    if (deviceId && isFetched === undefined) {
      dispatch(LoadDeviceEmulationSettingsDataById(deviceId));
    }
  }, [isFetched, deviceId, dispatch]);

  return {
    deviceSettings: data,
    isLoading: isFetched !== true
  };
}

export interface DevicesSettingsState {
  deviceSettings: DeviceSettings[];
  isLoading: boolean;
}
