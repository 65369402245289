import { Device, Position, DeviceColdData } from 'models/device-management';
import { RootState as State } from 'reducers';
import { isPositionId } from 'utils/models';

function getDevicesState(state: State) {
  return state.deviceManagement.devices;
}

export function areDevicesColdDataLoading(state: State): boolean {
  const devicesState = getDevicesState(state);
  return devicesState.devicesColdDataFetching;
}

export function areDevicesLoading(state: State): boolean {
  const devicesState = getDevicesState(state);
  return devicesState.devicesFetching;
}

export function getDevice(
  deviceId: Device['device_id'],
  state: State,
): Device | null | undefined {
  const devicesState = getDevicesState(state);
  return devicesState.devicesById[deviceId.toUpperCase()];
}

export function getDeviceWithColdData(
  deviceId: Device['device_id'],
  state: State,
): Device & DeviceColdData | null | undefined {
  const device = getDevice(deviceId, state);

  if (!device) {
    return device;
  }

  const deviceColdData = getDevicesState(state).devicesColdDataById[deviceId];

  return {
    ...device,
    ...deviceColdData,
  };
}

export function getPositionIdByDeviceId(
  deviceId: Device['device_id'],
  state: State,
): Position['id'] | undefined | null {
  const device = getDevice(deviceId, state);

  if (!device) {
    return device;
  }

  if (!isPositionId(device.position_id)) {
    return null;
  }

  return device.position_id;
}
