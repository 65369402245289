export enum FormField {
  messageType = 'message_type',
  endpoint = 'endpoint_id',
  group = 'group_id',
  zone = 'zone_id',
  activity = 'activity',
}

export interface FormState {
  [FormField.zone]?: number;
  [FormField.group]?: number;
  [FormField.endpoint]?: number;
  [FormField.messageType]?: string;
  [FormField.activity]?: boolean;
}