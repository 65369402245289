import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDeepCompareEffect, useLocalStorage } from 'react-use';
import { PageLoader } from 'components/Loaders';
import { useDispatch, useSelector } from 'react-redux';
import { DoGetStatus } from 'actions/lorawan';
import { RootState } from 'reducers';
import { useAuthUserSelector, useDialog } from 'hooks';
import { IntegrationFiltersParams } from 'models/lorawan';
import { useLocationParams } from 'hooks/location';
import {
  convertParamsToState,
  FilterState,
  buildParams,
  convertStateToParams,
} from 'components/Lorawan/LorawanFilter/Component';
import { usePagination } from 'hooks';

// components
import { Grid, Box, Typography } from '@material-ui/core';
import { Integration } from 'components/Lorawan/Integration';
import { FilterButton, AddButton } from 'components/Buttons';
import { LorawanFilter } from 'components/Lorawan/LorawanFilter/Component';
import { ViewTypeButton, ViewType } from 'components/Buttons';
import { AddIntegrationAdminDialog } from 'components/Lorawan/dialogs/AddIntegrationAdmin';
import { LorawanTable } from 'components/Lorawan/LorawanTable';

// styles
import useStyles from 'styles/page';

const LoRaWanPage = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useAuthUserSelector();
  const addDialog = useDialog();
  const { onChangePage } = usePagination();

  const { fetched, integrations } = useSelector((root: RootState) => root.lorawan);

  const [params, setParams] = useState<IntegrationFiltersParams>({});
  const [filterState, setFilterState] = useState<FilterState>(convertParamsToState(params));
  const [viewType, setViewType] = useLocalStorage<ViewType>('lorawan:list:view', ViewType.CARD);

  const applyParams = (params: IntegrationFiltersParams) => {
    setParams(params);
    dispatch(DoGetStatus(params, user.isAdmin));
  };

  const setFilterParams = useLocationParams<IntegrationFiltersParams>({}, buildParams, (params) => {
    setFilterState(convertParamsToState(params));
    applyParams(params);
  });

  // reset page count on filter change
  useDeepCompareEffect(() => {
    onChangePage(0);
  }, [params]);

  const onSave = () => {
    setFilterParams(convertStateToParams(filterState));
  };
  const onReset = () => {
    setFilterParams({});
    setParams({});
    setFilterState({});
  };

  return (
    <>
      <Helmet>
        <title>LoRaWAN Sources</title>
      </Helmet>
      { user.isAdmin && <div className={ classes.toolbar }>
        <ViewTypeButton type={ viewType } onChange={ setViewType } />
        <FilterButton
          isLoading={ !fetched }
          filter={ params }
          onSave={ onSave }
          onReset={ onReset }
        >
          <LorawanFilter
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div> }
      <Box mt={ 2 }>
        { fetched && !integrations.length && <Typography>No integrations</Typography> }
        { !fetched && viewType !== ViewType.TABLE ? <PageLoader /> : '' }
        {
          viewType === ViewType.TABLE
            ?
            <Box className={ classes.table }>
              <LorawanTable
                isLoading={ !fetched }
                integrations={ integrations }
                canViewOwner={ user.isAdmin }
                reload={ () => dispatch(DoGetStatus(params, user.isAdmin)) }
              />
            </Box>
            :
            <Grid container spacing={ 3 } alignItems="stretch" alignContent="stretch">
              {
                integrations.map((integration, i) =>
                  <Grid item xs={ 12 } sm={ 6 } md={ 3 } xl={ 2 } key={ i }>
                    <Integration
                      { ...integration }
                      reload={ () => dispatch(DoGetStatus(params, user.isAdmin)) }
                    />
                  </Grid>)
              }
            </Grid>
        }
      </Box>

      { user.isAdmin ? <AddButton
        label="Add integration"
        isDisabled={ !fetched }
        onClick={ addDialog.open }
      /> : '' }

      { addDialog.isMounted && (
        <AddIntegrationAdminDialog
          isOpen={ addDialog.isOpen }
          onClose={ addDialog.close }
          onCloseEnd={ addDialog.unmount }
          reload={ () => dispatch(DoGetStatus(params, user.isAdmin)) }
        />
      ) }
    </>
  );
};

export default LoRaWanPage;
