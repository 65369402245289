import { stringify } from 'query-string';
import { Filters, GroupNode, LevelNode, PositionNode, Tree, TreeNode, ZoneNode } from './types';
import { useFormActionNotifier } from 'hooks/form';
import { isNil } from 'lodash';

export const getQueryHash = (filters: Filters): string => stringify(filters, { arrayFormat: 'comma', skipNull: true });
export const getZoneId = (zoneId: number): string => `zone-${ zoneId }`;
export const getLevelId = (levelId: number): string => `level-${ levelId }`;
export const getEmptyLevelId = (zoneId: number): string => `level-empty-${ zoneId }`;
export const getGroupId = (groupId: number): string => `group-${ groupId }`;
export const getPositionId = (positionId: number): string => `position-${ positionId }`;

export const useErrorNotifier = () => {
  return useFormActionNotifier().notifyError;
};

export const findZoneNode = (tree: Tree, zoneId: number): ZoneNode | null => {
  return tree.items[getZoneId(zoneId)] ?? null;
};

export const findLevelNode = (tree: Tree, zoneId: number, levelId: number | null, create = false): LevelNode | null => {
  const zoneNode = findZoneNode(tree, zoneId);
  if (!zoneNode) {
    return null;
  }

  const levelNodeId = levelId ? getLevelId(levelId) : getEmptyLevelId(zoneId);
  if (zoneNode.children.items[levelNodeId]) {
    return zoneNode.children.items[levelNodeId];
  }

  if (levelId || !create) {
    return null;
  }

  zoneNode.children.total++;
  zoneNode.children.items[levelNodeId] = {
    id: levelNodeId,
    parent: zoneNode,
    type: 'level',
    children: {
      total: 0,
      items: {},
    }
  };

  return zoneNode.children.items[levelNodeId];
};

export const findGroupNode = (tree: Tree, zoneId: number, levelId: number, groupId: number): GroupNode | null => {
  const levelNode = findLevelNode(tree, zoneId, levelId);
  if (!levelNode) {
    return null;
  }

  return levelNode.children.items[getGroupId(groupId)] ?? null;
};

export const findPositionNode = (tree: Tree, zoneId: number, levelId: number, groupId: number, positionId: number): PositionNode | null => {
  const groupNode = findGroupNode(tree, zoneId, levelId, groupId);
  if (!groupNode) {
    return null;
  }

  return groupNode.children.items[getPositionId(positionId)] ?? null;
};

export const findGroupNodeForDevice = (tree: Tree, deviceId: string, zoneId?: number, levelId?: number, groupId?: number): GroupNode | null => {
  for (const zoneNode of Object.values(tree.items)) {
    if (!isNil(zoneId) && zoneNode.zone.id !== zoneId) {
      continue;
    }

    for (const levelNode of Object.values(zoneNode.children.items)) {
      if (!isNil(levelId) && levelNode.level?.id !== levelId) {
        continue;
      }

      for (const groupNode of Object.values(levelNode.children.items)) {
        if (!isNil(groupId) && groupNode.group.id !== groupId) {
          continue;
        }

        for (const device of Object.values(groupNode.devices.data)) {
          if (device.device_id.toUpperCase() === deviceId.toUpperCase()) {
            return groupNode;
          }
        }
      }
    }
  }

  return null;
};


export const calculateTotal = (node: TreeNode, option: 'devices' | 'children'): number => {
  switch (node.type) {
    case 'zone':
      return Object.values(node.children.items)
        .reduce((total, levelNode) => total + calculateTotal(levelNode, option), 0);
    case 'level':
      return Object.values(node.children.items)
        .reduce((total, groupNode) => total + calculateTotal(groupNode, option), 0);
    case 'group':
      return node[option].total;
    case 'position':
      return 0;
  }
};