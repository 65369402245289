import { ApiKey } from 'models/user-management';
import { Helmet } from 'react-helmet-async';
import { useApiKeySelector } from 'hooks/user-managment';
import { getClientDetailPath, getProfileClientPath } from 'routing/paths';
// components
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { PageLoader } from 'components/Loaders';
import { ApiKeyManagment, PermissionManagement } from 'components/UserManagement';
import { NotFound } from './NotFound';

interface Props {
  isProfile?: boolean;
  apiKeyId: string;
  clientId: number;
}

export const UserManagementApiKeyDetailPage = ({ isProfile, apiKeyId, clientId }: Props): JSX.Element => {
  const { isLoading, apiKey } = useApiKeySelector(apiKeyId);

  const isNotFound = !apiKey || apiKey.owner_id !== clientId;

  return (
    <Box mt={ 1 }>
      <Helmet><title>{ `API Auth Token ${ apiKey?.name || apiKeyId }` }</title></Helmet>
      {
        isLoading ? <PageLoader/> :
          isNotFound
            ? (
              <NotFound
                apiKeyId={ apiKeyId }
                clientPath={ isProfile ? getProfileClientPath() : getClientDetailPath(clientId) }
              />
            )
            : (
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                  <ApiKeyManagment apiKey={ apiKey as ApiKey }/>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                  <PermissionManagement permissionId={ (apiKey as ApiKey).permission_id }/>
                </Grid>
              </Grid>
            )
      }
    </Box>
  );
};
