import { createInstance } from '@optimizely/react-sdk';
import { defaults } from 'lodash';

import { EnvironmentStore } from './EnvironmentStore';

declare global {
  interface Window {
    __CI?: boolean;
  }
}

/**
 * Only build-time constants which are not supposed to be overridden at runtime.
 */
export const staticEnv = {
  LOCAL_DEBUG: process.env.REACT_APP_STAGE === 'debug',
  IS_PRODUCTION: process.env.REACT_APP_STAGE === 'production',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  MAILGUN_PASSWORD: process.env.REACT_APP_MAILGUN_PASSWORD,
  AUTOTEST: typeof window !== 'undefined' && Boolean(window.__CI),
  IS_PARKING_ANOMALY_ON: false, // Disable PositionParkingAnomaly - https://nwaveio.atlassian.net/browse/BNIV-1981
};

/**
 * Variables that can be overridden at runtime.
 */
export interface Environment {
  RESPONSIBLE_PERSON_ENABLED: boolean;
}

/**
 * Provide default values for the environment variables in the case if the variables aren't set.
 * If needed, also provide computed overrides based on `staticEnv` and other dynamic variables.
 *
 * @param envOverrides The accumulated result of all calls of `store.overrideEnv`
 * by Google Tag Manager (GTM) or anybody else.
 */
function finalizeEnv(envOverrides: Partial<Environment>): Environment {
  const defaultEnv = {
    RESPONSIBLE_PERSON_ENABLED: true,
  };
  const envWithDefaults = defaults({}, envOverrides, defaultEnv);

  return {
    ...envWithDefaults,

    // hardcoded overrides e.g. computed variables
    // ...
  };
}

const store = new EnvironmentStore(finalizeEnv);

export const { getEnv, subscribe, unsubscribe } = store;

const OPTIMIZELY_SDK_KEYS = {
  development: '2T1xTLfDqJLWhXeue9dw2P',
  preProduction: 'TjLXKPscjQGxPjpB3fwx9',
  production: '5aXueNRc5yx8cgSMr7QcAB',
};

function getEnvironment() {
  if (!staticEnv.IS_PRODUCTION) {
    return 'development';
  }

  if (window.location.host.includes('preprod.b.nwave.io')) {
    return 'preProduction';
  }

  return 'production';
}

export async function initEnvironmentStore() {
  const env = getEnvironment();

  const optimizely = createInstance({
    sdkKey: OPTIMIZELY_SDK_KEYS[env],
  });

  // TODO (BNIV-512): add support of customization based on client
  const userId = '';

  await optimizely.onReady();

  const enabledFeatures = Object.fromEntries(
    optimizely.getEnabledFeatures(userId).map(featureName => [featureName, true]),
  );

  store.overrideEnv(enabledFeatures);
}

export function exposeEnvironmentStore() {
  Object.assign(window, { __envStore: store });
}
