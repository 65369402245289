import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { RootState } from 'reducers';
import { LoadCallerTemplateDataByParams, LoadCallerTemplateDataDictionary } from 'actions/caller';
import { GetTemplateParams, Template } from 'models/caller';

export interface TemplatesState {
  templates: Template[];
  isLoading: boolean;
}

export function useTemplateLoadSelectorByParams(params: GetTemplateParams): TemplatesState {
  const dispatch = useDispatch();
  const { isFetched, templates } = useSelector((state: RootState) => ({
    isFetched: state.caller.template.isFetched(params),
    templates: state.caller.template.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadCallerTemplateDataByParams(params));
    }
  }, [isFetched, params, dispatch]);

  return {
    templates: templates,
    isLoading: isFetched !== true,
  };
}

export const useCallerTemplateDictionary = (): TemplatesState => {
  const dispatch = useDispatch();
  const { isFetched, templates } = useSelector((state: RootState) => ({
    isFetched: state.caller.template.isFetched({}),
    templates: state.caller.template.findByParams({}),
  }));

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadCallerTemplateDataDictionary());
    }
  }, [isFetched, dispatch]);

  return {
    templates: templates,
    isLoading: isFetched !== true,
  };
};