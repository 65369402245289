import React, { useEffect, useState } from 'react';
import { ChangeStationResponsiblePersonNotification } from 'actions/base-station';
import { useDispatch } from 'react-redux';
import { ResponsiblePerson } from 'models/base-station';

// components
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

interface Props {
  notify: boolean;
  responsiblePerson?: ResponsiblePerson;
}

export const Notification = ({ notify, responsiblePerson }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [notification, changeNotify] = useState<boolean>(notify);

  useEffect(() => {
    changeNotify(notify);
  }, [notify]);

  const handleChange = () => {
    changeNotify(!notification);
    if (responsiblePerson) {
      const action = ChangeStationResponsiblePersonNotification(responsiblePerson, !notification);
      dispatch(action);
    }
  };

  return (
    <Grid component="label" container alignItems="center" spacing={ 1 }>
      <Grid item>
        <Switch checked={ notification } onChange={ () => handleChange() } name="notify" disabled={ !responsiblePerson }/>
      </Grid>
    </Grid>
  );
};