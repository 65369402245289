export function diffObjects<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  B extends Record<string, any>
>(a: A, b: B): Partial<A & B> {
  const allKeys = [
    ...Object.keys(a),
    ...Object.keys(b),
  ];

  return allKeys.reduce<Partial<A & B>>(
    (diff, key) => {
      if (key in diff) {
        return diff;
      }

      if (a[key] !== b[key]) {
        diff[key as keyof A | keyof B] = a[key];
      }

      return diff;
    },
    {},
  );
}

export function isSubset(
  subset: Record<string, unknown>,
  superset: Record<string, unknown>,
): boolean {
  for (const key of Object.keys(subset)) {
    if (subset[key] !== superset[key]) {
      return false;
    }
  }

  return true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sortByKeys(object: Record<string, any>): Record<string, any> {
  return Object.fromEntries(
    Object.entries(object).sort(
      ([keyA], [keyB]) => keyA.localeCompare(keyB),
    ),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyObject(object: Record<string, any>): string {
  return JSON.stringify(sortByKeys(object));
}