import { useTree } from 'hooks/device-management/useTree/useTree';
import { findPositionNode } from 'hooks/device-management/useTree/utils';
import { PositionNode, QueryType, Tree } from 'hooks/device-management/useTree/types';
import { useLocationBackAction, useLocationRedirect } from 'hooks/location';
import { getDMListPath, getDMGroupDetailPath, getDMPositionDetailPath } from 'routing/paths';
import { PositionWithLevel } from 'models/device-management';

export interface PageParams {
  zoneId: string;
  levelId: string;
  groupId: string;
  positionId: string;
}

interface PageData {
  isLoading: boolean;
  isValid: boolean;
  tree: Tree;
  node?: PositionNode;
  reload: (position: PositionWithLevel) => void;
  back: () => void;
}

export const usePositionDetailPage = (props: PageParams): PageData => {
  const zoneId = parseInt(props.zoneId);
  const levelId = parseInt(props.levelId);
  const groupId = parseInt(props.groupId);
  const positionId = parseInt(props.positionId);
  const isValid = (
    !isNaN(zoneId) && zoneId > 0
    && !isNaN(levelId) && levelId >= 0
    && !isNaN(groupId) && groupId > 0
    && !isNaN(positionId) && positionId > 0
  );

  const redirectAction = useLocationRedirect(true);
  const backAction = useLocationBackAction();
  const tree = useTree({
    zones: [zoneId],
    levels: [levelId],
    groups: [groupId],
    positions: [positionId],
    offset: 0,
    limit: 1
  }, isValid);

  const node = (tree.loaded.position && findPositionNode(tree, zoneId, levelId, groupId, positionId)) || undefined;
  const isValidParent = !tree.loaded.position || (
    node?.position.zone_id === zoneId
    && (node?.position.level_id ?? 0) === levelId
    && node?.position.group_id === groupId
  );

  return {
    isLoading: !tree.loaded.zone || !tree.loaded.level || !tree.loaded.group || !tree.loaded.position,
    isValid: isValid && isValidParent,
    tree: tree,
    node: node,
    reload: (position: PositionWithLevel) => {
      if (
        position.zone_id !== node?.position.zone_id
        || position.level_id !== node?.position.level_id
        || position.group_id !== node?.position.group_id
      ) {
        redirectAction(getDMPositionDetailPath(
          position.zone_id,
          position.level_id ?? 0,
          position.group_id,
          position.id,
        ));
      }
      tree.reload(QueryType.group);
      tree.reload(QueryType.position);
      tree.reload(QueryType.device);
    },
    back: () => backAction(
      node?.position
        ? getDMGroupDetailPath(node.position.zone_id, node.position.level_id ?? 0, node?.position.group_id)
        : getDMListPath()
    ),
  };
};


