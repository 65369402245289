import blueMarker from 'images/Map/blue-ico.png';
import greenMarker from 'images/Map/green-ico.png';
import redMarker from 'images/Map/red-ico.png';
import yellowMarker from 'images/Map/yellow-ico.png';
import { PositionState } from 'models/parking';
import { MarkerColor, MarkerType } from './types';
import moment from 'moment';

export const getMarkerIcon = (color: MarkerColor): string => {
  switch (color) {
    case MarkerColor.blue:
      return blueMarker;
    case MarkerColor.green:
      return greenMarker;
    case MarkerColor.red:
      return redMarker;
    case MarkerColor.yellow:
      return yellowMarker;
    default:
      return redMarker;
  }
};

export const getMarkerColor = (type: MarkerType, positionState: PositionState): MarkerColor => {
  switch (type) {
    case MarkerType.OCCUPANCY:
      return getOccupancyMarkerColor(positionState);
    case MarkerType.DURATION:
      return getDurationMarkerColor(positionState);
  }
};

function getOccupancyMarkerColor(positionState: PositionState): MarkerColor {
  return positionState.occupied ? MarkerColor.red : MarkerColor.green;
}

function getDurationMarkerColor(positionState: PositionState): MarkerColor {
  if (!positionState.occupied) {
    return MarkerColor.blue;
  }

  const statusTime = moment(positionState.status_time);
  const duration = moment().diff(statusTime, 'minutes');

  if (duration < 30) {
    return MarkerColor.green;
  } else if (duration < 60 * 4) {
    return MarkerColor.yellow;
  } else {
    return MarkerColor.red;
  }
}
