import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useCallerTemplatePresets } from 'hooks/caller';
import { METHOD_FOR_NOT_EDITABLE_MESSAGE_TYPE, Template, TemplateMethod } from 'models/caller';
import { FormProps } from 'types/form';
import { validate } from './validator';
// components
import { Link } from '@material-ui/core';
import {
  CallerMessageTypesSelectControl,
  CallerTemplateSuitesSelectControl,
  HttpHeadersControl,
  SingleSelectControl,
  SwitchControl,
  TextControl
} from 'components/Controls';
// styles
import { useStyles } from './styles';

export enum TemplateFormField {
  name = 'name',
  templateSute = 'template_suite_id',
  messageType = 'message_type',
  method = 'method',
  url = 'url',
  headers = 'headers',
  body = 'body',
  isActive = 'is_active',
}

type CallerTemplateFormProps = (
  & FormProps<Template, TemplateFormField>
  & {
    messageTypeNotEditable?: boolean;
  }
);

export const CallerTemplateForm = (props: CallerTemplateFormProps): JSX.Element => {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate, messageTypeNotEditable } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  const presetsState = useCallerTemplatePresets();

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends TemplateFormField>(field: TField, value: Template[TField]) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  const getBodyFieldHelperText = () => {
    if (showErrors.includes(TemplateFormField.body) && errors?.body) {
      return errors?.body;
    }

    if (!messageTypeNotEditable) {
      return;
    }

    const relatedPreset = presetsState.presets?.find(preset => {
      return preset.message_type === state.message_type;
    });

    if (relatedPreset?.doc_url) {
      return (
        <>
          Not customizable for selected message type.{ ' ' }
          <Link
            href={ relatedPreset.doc_url }
            target="_blank"
            rel="noopener"
          >
            Learn the format.
          </Link>
        </>
      );
    }

    return 'Not customizable for selected message type';
  };

  return (
    <form
      className={ isView ? classes.viewMode : classes.editMode }
      noValidate
      autoComplete="off"
      data-testid="template-manual-form"
    >
      { !showFields.includes(TemplateFormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(TemplateFormField.name) }
          label="Name"
          value={ state.name }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(TemplateFormField.name, event.target.value);
          } }
          error={ showErrors.includes(TemplateFormField.name) ? errors?.name !== undefined : false }
          helperText={ showErrors.includes(TemplateFormField.name) ? errors?.name : undefined }
        />
      }
      { !showFields.includes(TemplateFormField.templateSute) ? '' :
        <CallerTemplateSuitesSelectControl
          isRequired={ !isView }
          isDisabled={ isView || disabledFields.includes(TemplateFormField.templateSute) }
          isMulti={ false }
          selected={ state.template_suite_id }
          // `suiteId as number` is valid as long as `isClearable !== true`
          onChange={ suiteId => handleChange(TemplateFormField.templateSute, suiteId as number) }
          error={ showErrors.includes(TemplateFormField.templateSute) ? errors?.template_suite_id : undefined }
        />
      }
      { !showFields.includes(TemplateFormField.messageType) ? '' :
        <CallerMessageTypesSelectControl
          isRequired={ !isView }
          isDisabled={ isView || disabledFields.includes(TemplateFormField.messageType) }
          isMulti={ false }
          selected={ state.message_type }
          // `messageType as string` is valid as long as `isClearable !== true`
          onChange={ messageType => handleChange(TemplateFormField.messageType, messageType as string) }
          error={ showErrors.includes(TemplateFormField.messageType) ? errors?.message_type : undefined }
        />
      }
      { !showFields.includes(TemplateFormField.method) ? '' :
        <SingleSelectControl
          isRequired={ !messageTypeNotEditable && !isView }
          isClearable={ false }
          isDisabled={ messageTypeNotEditable || isView || disabledFields.includes(TemplateFormField.method) }
          label="Method"
          name="method"
          values={ [
            { value: TemplateMethod.GET, label: 'GET' },
            { value: TemplateMethod.PATCH, label: 'PATCH' },
            { value: TemplateMethod.POST, label: 'POST' },
            { value: TemplateMethod.PUT, label: 'PUT' },
          ] }
          selected={ messageTypeNotEditable ? METHOD_FOR_NOT_EDITABLE_MESSAGE_TYPE : state.method }
          // `value as TemplateMethod` is valid as long as `isClearable !== true`
          changeHandler={ value => handleChange(TemplateFormField.method, value as TemplateMethod) }
          error={ showErrors.includes(TemplateFormField.method) ? errors?.method : undefined }
        />
      }
      { !showFields.includes(TemplateFormField.url) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(TemplateFormField.url) }
          type="url"
          label="URL"
          value={ state.url }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(TemplateFormField.url, event.target.value);
          } }
          error={ showErrors.includes(TemplateFormField.url) ? errors?.url !== undefined : false }
          helperText={ showErrors.includes(TemplateFormField.url) ? errors?.url : undefined }
        />
      }
      { !showFields.includes(TemplateFormField.isActive) ? '' :
        <SwitchControl
          label="Active"
          name="is_active"
          value={ state.is_active ?? false }
          isDisabled={ isView || disabledFields.includes(TemplateFormField.isActive) }
          onChange={ checked => handleChange(TemplateFormField.isActive, checked) }
        />
      }
      { !showFields.includes(TemplateFormField.headers) ? '' :
        <HttpHeadersControl
          label="Headers"
          disabled={ isView || disabledFields.includes(TemplateFormField.headers) }
          value={ state.headers || {} }
          onChange={ (value) => handleChange(TemplateFormField.headers, value) }
          error={ showErrors.includes(TemplateFormField.headers) ? errors?.headers : undefined }
        />
      }
      { !showFields.includes(TemplateFormField.body) ? '' :
        <TextControl
          multiline
          disabled={ messageTypeNotEditable || isView || disabledFields.includes(TemplateFormField.body) }
          variant="outlined"
          rows={ messageTypeNotEditable ? '1' : '30' }
          label="Body"
          value={ messageTypeNotEditable ? '' : (state.body || '') }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(TemplateFormField.body, event.target.value);
          } }
          error={ showErrors.includes(TemplateFormField.body) ? errors?.body !== undefined : false }
          helperText={ getBodyFieldHelperText() }
        />
      }
    </form>
  );
};
