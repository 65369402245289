import React from 'react';
import { useFormActionLoader } from 'hooks';
import { DeleteStationResponsiblePerson } from 'actions/base-station';
import { ResponsiblePerson } from 'models/base-station';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  person: ResponsiblePerson;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DeleteDialog = (props: Props): JSX.Element => {
  const { person, isOpen, onClose, onCloseEnd } = props;
  const { doAction } = useFormActionLoader();

  const handleDelete = () => doAction({
    action: DeleteStationResponsiblePerson(person),
    onFinally: onClose,
  });

  return (
    <EnsureDialog
      name="delete-responsible-person"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
      label="Removing confirmation"
      btnYes="Confirm"
    >
      Please confirm removing responsibility of <b>{ `${ person.first_name } ${ person.last_name }` }</b>
      { !person.locations.length ? '' : (
        <>
          &nbsp;for locations:<br/>
          { person.locations.map(l => <>{ l.country }, { l.address }<br/></>) }
        </>
      ) }
    </EnsureDialog>
  );
};
