import React from 'react';
import { Position, PositionedDeviceData } from 'models/device-management';
import emptyIcon from 'images/Map/green-bagel.svg';
import fillIcon from 'images/Map/green-circle.svg';

// components
import { InfoWindow } from 'react-google-maps';
import { Box, Typography } from '@material-ui/core';

interface Props {
  position: Position;
  device?: PositionedDeviceData;
  onClose: (position: Position) => void;
}

export const PositionMarkerInfo = ({ position, device, onClose }: Props): JSX.Element => {
  return (
    <InfoWindow onCloseClick={ () => onClose(position) }>
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Network ID:</Typography>
          <Box ml={ 1 }>
            <Typography variant="caption">
              { position.network_id ? `${ position.network_id }` : '-' }
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Device ID:</Typography>
          <Box ml={ 1 } alignItems="center">
            <Box component="span" mr={ 1 }><img src={ device ? fillIcon : emptyIcon } alt="icon"/></Box>
            <Typography variant="caption">
              { device?.device_id ?? 'Empty' }
            </Typography>
          </Box>
        </Box>
      </>
    </InfoWindow>
  );
};