import { GetUsersParams } from 'models/user-management';

export const SET_USERS_PAGE_FILTERS = 'pages/users/SET_FILTERS';
export const UPDATE_USERS_PAGE_FILTERS_FROM_URL = 'pages/users/UPDATE_FILTERS_FROM_URL';

export interface SetFilters {
  readonly type: typeof SET_USERS_PAGE_FILTERS;
  readonly filters: GetUsersParams;
  readonly pushFiltersToUrl: boolean;
}

export interface UpdateUserPageFiltersFromUrl {
  readonly type: typeof UPDATE_USERS_PAGE_FILTERS_FROM_URL;
}

export const setUsersPageFilters = (filters: GetUsersParams, pushFiltersToUrl: boolean): SetFilters => ({
  type: SET_USERS_PAGE_FILTERS,
  filters,
  pushFiltersToUrl
});

export const DoUpdateUserPageFiltersFromUrl = (): UpdateUserPageFiltersFromUrl => ({
  type: UPDATE_USERS_PAGE_FILTERS_FROM_URL
});

export type Action = (
  | SetFilters
  | UpdateUserPageFiltersFromUrl
);
