import React from 'react';

import { props as LineChartsOptions } from 'components/Charts/ChartsOptions/LineChartsOptions';
import { formatChartAxisDateTime } from 'utils/datetime';

import { MonitoringSerie } from './utils';

// components
import { Grid } from '@material-ui/core';
import { LineSvgProps, ResponsiveLine } from '@nivo/line';

import { Scrollbar } from 'components';
import { AxisTick, DateAxisTick } from 'components/Charts';
import { BlockLoader } from 'components/Loaders';

import { MonitoringChartLegend, MonitoringChartLegendProps } from './MonitoringChartLegend';

// styles
import { useStyles } from './styles';

function getChartMaxValue(dataMaxValue: number): number {
  if (dataMaxValue % 4 === 0) {
    return dataMaxValue;
  }

  return dataMaxValue + (4 - dataMaxValue % 4);
}

interface MonitoringLineChartProps extends LineSvgProps {
  maxValue?: number;
  data: MonitoringSerie[];
  isLoading?: boolean;
  legendProps?: Partial<MonitoringChartLegendProps>;
  durationDays?: number;
}

export const MonitoringLineChart = ({
  maxValue: originalMaxValue,
  isLoading,
  legendProps,
  durationDays,
  ...restProps
}: MonitoringLineChartProps) => {
  const classes = useStyles();

  const minValue = 0;
  const maxValue = getChartMaxValue(originalMaxValue || 1);
  const valueRangeQuater = (maxValue - minValue) / 4;

  const tickValues = valueRangeQuater === 0
    ? undefined
    : [
      minValue,
      minValue + valueRangeQuater,
      minValue + valueRangeQuater * 2,
      minValue + valueRangeQuater * 3,
      maxValue,
    ];

  if (isLoading) {
    return (
      <Grid container spacing={ 1 } className={ classes.chart } data-testid="monitoring-line-chart-loader">
        <Grid item xs={ 12 } sm={ 12 } style={ { height: '100%' } }>
          <BlockLoader size={ 20 } />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={ 1 }
      className={ classes.chart }
      data-testid="monitoring-line-chart"
    >
      <Grid item xs={ 12 } sm={ 9 } style={ { height: '100%' } }>
        <ResponsiveLine
          { ...LineChartsOptions }
          margin={ { top: 6, right: 16, bottom: 56, left: 55 } }
          xScale={ {
            type: 'time',
            format: '%Y-%m-%d %H:%M',
            useUTC: false,
          } }
          yScale={ {
            type: 'linear',
            max: maxValue,
            min: minValue,
          } }
          axisBottom={ {
            tickSize: 0,
            tickPadding: 12,
            format: formatChartAxisDateTime,
            renderTick: (durationDays && durationDays > 7) ? AxisTick : DateAxisTick,
          } }
          axisLeft={ {
            tickSize: 0,
            tickPadding: 15,
            tickValues,
          } }
          gridYValues={ tickValues }
          lineWidth={ 1.5 }
          pointSize={ 4 }
          xFormat="time:%Y-%m-%d %H:%M"
          enableArea={ false }
          enablePoints={ false }
          theme={ {
            axis: {
              ticks: {
                text: {
                  fontSize: '12px',
                },
              },
            },
          } }
          { ...restProps }
        />
      </Grid>

      <Grid item xs={ 12 } sm={ 3 } style={ { height: '100%', paddingTop: '6px', paddingBottom: '34px', paddingRight: '10px' } }>
        <Scrollbar style={ { height: '100%' } }>
          <MonitoringChartLegend
            entries={ restProps.data.map(serie => ({
              ...serie,
              value: serie.total,
              label: serie.label,
            })) }
            { ...legendProps }
          />
        </Scrollbar>
      </Grid>
    </Grid>
  );
};
