import { useState } from 'react';
import { Position, PositionedDeviceData } from 'models/device-management';
import { SettingsResponseStruct } from 'models/device-emulation';
import { useAuthUserSelector } from 'hooks';
import { apiRawMessagesPath } from 'routing/paths';
import { canViewOwner, isAllowedToDeleteDevice } from 'utils/permissions';
import { commonOptions } from 'utils/tables';
import { formatSendingInterval } from './utils';
import { useVirtualDeviceActivation } from 'hooks/device-management/virtual-devices';

// components
import { Box } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { CommonButton } from 'components/Buttons';
import { FirmwareName, OwnerName, PositionName } from 'components/Labels';
import { DeviceLink, SPlaceLink } from 'components/Links';
import { SendMessageDialog } from 'components/DeviceManagement/Dialogs/SendMessageDialog/SendMessageDialog';
import { LoaderSvg } from 'components/Loaders';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';
import { useStyles } from './styles';
import { SendMessageAlerts, SendMessageUnactiveDeviceDialog } from 'components/DeviceManagement/Dialogs/SendMessageDialog';

interface Props {
  devices: PositionedDeviceData[];
  deviceSettings: SettingsResponseStruct[];
  deviceSettingsLoading: boolean;
  positions: Position[];
  onDelete?: (device: PositionedDeviceData) => void;
  isLoading?: boolean;
  total: number;
}

export const VirtualDevicesTable = ({
  devices,
  deviceSettings,
  deviceSettingsLoading,
  positions,
  onDelete,
  isLoading,
  total
}: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const classes = useStyles();
  const [deviceToSendMessage, setDeviceToSendMessage] = useState<PositionedDeviceData>();

  const { onActivateDevice } = useVirtualDeviceActivation();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'deviceId',
      label: 'Device ID',
      options: {
        customBodyRenderLite: dataIndex => <DeviceLink deviceId={ devices[dataIndex].device_id }/>
      },
    },
    {
      name: 'position',
      label: 'Position',
      options: {
        customBodyRenderLite: dataIndex => {
          const device = devices[dataIndex];
          const positionId = device.position_id;

          return positionId
            ? (
              <PositionName
                id={ positionId }
                network={ positions.find(p => p.id === positionId)?.network_id ?? '' }
              />
            )
            : <SPlaceLink deviceId={ device.device_id } />;
        }
      }
    },
    {
      name: 'isActive',
      label: 'Activation Status',
      options: {
        customBodyRenderLite: dataIndex => devices[dataIndex].activation_status ? 'Active' : 'Not active'
      }
    },
    {
      name: 'software',
      label: 'Firmware',
      options: {
        customBodyRenderLite: dataIndex => <FirmwareName firmwareHash={ devices[dataIndex].firmware_hash }/>,
      }
    },
    {
      name: 'software',
      label: 'Auto-change occupancy',
      options: {
        customBodyRenderLite: dataIndex => {
          if (deviceSettingsLoading) {
            return <LoaderSvg />;
          }

          const device = devices[dataIndex];
          const setting = deviceSettings.find(i => i.device_id?.toUpperCase() === device.device_id.toUpperCase());
          return setting ? formatSendingInterval(setting) : '-';
        }
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: canViewOwner(user) ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ devices[dataIndex].owner_id }/>
      }
    },
    {
      name: '',
      label: '',
      options: {
        viewColumns: false,
        customBodyRenderLite: dataIndex => {
          const device = devices[dataIndex];

          return (
            <Box display="flex" justifyContent="flex-end">
              <SendMessageAlerts isDevicePositioned={ Boolean(device.position_id) }>
                { ({ onClick }) => (
                  <CommonButton
                    type="text"
                    icon={ <></> }
                    label="Send message"
                    onClick={ onClick ?? (() => setDeviceToSendMessage(device)) }
                  />
                ) }
              </SendMessageAlerts>
              <CommonButton
                type="text"
                icon={ <></> }
                label="View messages"
                LinkTo={ apiRawMessagesPath({
                  devices: [device.device_id]
                }) }
                ButtonProps={ { className: classes.btnViewMsg }}
              />
              { !onDelete || !isAllowedToDeleteDevice(user.data) ? '' :
                <CommonButton
                  type="text"
                  icon={ <></> }
                  label="Delete"
                  onClick={ () => onDelete(device) }
                  ButtonProps={ { className: classes.btnDelete }}
                />
              }
            </Box>
          );
        },
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    customFooter: () => <PaginationFooter count={ total } />
  };

  return (
    <>
      <MuiThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={ isLoading }>
          <MUIDataTable
            title={ null }
            data={ devices }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>
      {deviceToSendMessage ?
        deviceToSendMessage.activation_status ?
          <SendMessageDialog
            isOpen={ deviceToSendMessage?.device_id !== undefined }
            deviceId={ deviceToSendMessage?.device_id as string }
            onClose={ () => setDeviceToSendMessage(undefined) }
          />
          : <SendMessageUnactiveDeviceDialog
            deviceId={deviceToSendMessage && deviceToSendMessage.device_id}
            isActiveDevice={deviceToSendMessage && deviceToSendMessage.activation_status}
            onActivateDevice={() => onActivateDevice(deviceToSendMessage)}
            onUpdated={ () => { deviceToSendMessage && setDeviceToSendMessage({ ...deviceToSendMessage, activation_status: true }); }}
            onError={ () => { setDeviceToSendMessage(undefined); } }
            onClose={ () => { setDeviceToSendMessage(undefined); } }
          />
        : null}
    </>
  );
};
