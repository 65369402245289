import { useFormActionNotifier } from 'hooks';
import { FormField, FormState } from './types';

type ChangeSSLHandler = (state: FormState) => FormState
export const useSSLDefaultPort = (): ChangeSSLHandler => {
  const portWithoutSSL = 5672;
  const portWithSSL = 5671;

  const { notifySuccess } = useFormActionNotifier();
  const handle: ChangeSSLHandler = (state: FormState) => {
    if (state[FormField.ssl] === true && state[FormField.port] === portWithoutSSL) {
      state[FormField.port] = portWithSSL;
      notifySuccess(`Default port was set to ${portWithSSL}`);
    }

    if (state[FormField.ssl] === false && state[FormField.port] === portWithSSL) {
      state[FormField.port] = portWithoutSSL;
      notifySuccess(`Default port was set to ${portWithoutSSL}`);
    }

    return state;
  };

  return handle;
};