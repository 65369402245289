// import * as dmClient from 'clients/device-management';
// import { Metric } from 'models/device-management';
// import { getResponseData } from 'utils/clients';

import { ZoneNode, GroupNode } from '../types';

type TotalDamagedDevicesByNode = Record<string, number | undefined>;

// API /statistics/all was changed. Wait for https://nwaveio.atlassian.net/browse/BNIV-1965
export async function fetchZonesTotalDamagedDevices(
  nodes: ZoneNode[]
): Promise<TotalDamagedDevicesByNode> {
  // const statistic = getResponseData(
  //   await dmClient.fetchAllStatistics({
  //     metrics: [Metric.DamagedInstalledNumber, Metric.ByZoneIds],
  //     zones: nodes.map(node => node.zone.id),
  //     devices: [],
  //     projects: [],
  //   }),
  // );

  // const metricsByZone = statistic.by_zones;

  const totalDamagedDevicesByNode = Object.fromEntries(
    nodes.map(node => {
      // const zoneId = node.zone.id;
      // const totalDamagedDevices = metricsByZone?.[zoneId]?.devices_damaged_installed ?? 0;
      const totalDamagedDevices = 0;

      return [node.id, totalDamagedDevices];
    }),
  );

  return totalDamagedDevicesByNode;
}

// API /statistics/all was changed. Wait for https://nwaveio.atlassian.net/browse/BNIV-1965
export async function fetchGroupsTotalDamagedDevices(
  nodes: GroupNode[]
): Promise<TotalDamagedDevicesByNode> {
  // const statistic = getResponseData(
  //   await dmClient.fetchAllStatistics({
  //     metrics: [Metric.DamagedInstalledNumber, Metric.ByGroupIds],
  //     groups: nodes.map(node => node.group.id),
  //     devices: [],
  //     projects: [],
  //     zones: [],
  //   }),
  // );
  // const metricsByGroup = statistic.by_groups;
  const totalDamagedDevicesByNode = Object.fromEntries(
    nodes.map(node => {
      // const groupId = node.group.id;
      // const totalDamagedDevices = metricsByGroup?.[groupId]?.devices_damaged_installed ?? 0;
      const totalDamagedDevices = 0;
      return [node.id, totalDamagedDevices];
    }),
  );

  return totalDamagedDevicesByNode;
}
