import {
  WarrantyFiltersWithLimit,
  Warranty
} from 'models/provisioning';
import {
  Action,
  FETCH_WARRANTY_FAILURE,
  FETCH_WARRANTY,
  FETCH_WARRANTY_SUCCESS,
  FETCH_WARRANTY_BY_ID,
  FETCH_WARRANTY_BY_ID_SUCCESS,
  FETCH_WARRANTY_BY_ID_FAILURE,
  WARRANTY_CREATE,
  WARRANTY_CREATE_FAILURE,
  WARRANTY_CREATE_SUCCESS,
} from 'actions/provisioning';
import { DEFAULT_LIMIT } from 'models/common';

export interface State {
  list: Warranty[];
  isFetching: boolean;
  isFetched: boolean;
  filters: WarrantyFiltersWithLimit;
}

export const initState: State = {
  list: [],
  isFetching: false,
  isFetched: false,
  filters: {
    id: [],
    limit: DEFAULT_LIMIT,
    offset: 0,
  }
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_WARRANTY:
    case FETCH_WARRANTY_BY_ID:
    case WARRANTY_CREATE:
      return { ...state, isFetching: true };
    case FETCH_WARRANTY_BY_ID_FAILURE:
    case FETCH_WARRANTY_FAILURE:
    case WARRANTY_CREATE_FAILURE:
      return { ...state, isFetching: false };
    case FETCH_WARRANTY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        list: action.warranty,
      };
    case FETCH_WARRANTY_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: [action.warranty]
      };
    case WARRANTY_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: [action.warranty, ...state.list]
      };
    default:
      return state;
  }
};
