import React from 'react';
import { connect } from 'react-redux';

import { Device } from 'models/device-management';
import { RawMessages } from 'models/raw-messages';
import { RootState as State } from 'reducers';
import { apiRawMessagesPath } from 'routing/paths';
import { formatDateTime } from 'utils/datetime';
import { getDeviceConnectivity } from 'selectors/connectivity';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Link,
  TextField,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import { FieldSkeleton } from 'components/Skeleton';

// styles
import { useInfoBlockStyles } from './InfoBlock/style';

interface OwnProps {
  device?: Device;
}

interface StateProps {
  deviceConnectivity: ReturnType<typeof getDeviceConnectivity>;
  deviceIncidentsAmount?: number;
  rawMessage?: RawMessages;
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    deviceConnectivity: props.device && getDeviceConnectivity(props.device.device_id, state),
    rawMessage: props.device && state.rawMessages.messages.find(m => m.device_id.toUpperCase() === props.device?.device_id.toUpperCase()),
  };
}

const DeviceMonitoring: React.FC<OwnProps & StateProps> = ({
  device,
  deviceConnectivity,
  rawMessage
}) => {
  const infoCss = useInfoBlockStyles();

  if (!device) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
        Message Statistics
      </AccordionSummary>

      <AccordionDetails>
        <FormGroup className={ infoCss.fields }>
          <TextField
            className={ infoCss.field }
            disabled
            label="Last received message time"
            value={ (rawMessage?.demodulation_time && formatDateTime(rawMessage.demodulation_time)) || '' }
          />

          <TextField
            className={ infoCss.field }
            disabled
            label="Current connectivity"
            value={ deviceConnectivity ? `${deviceConnectivity.connectivity}%` : '0' }
          />

          {/* TODO: add connectivity history chart */}

          <div className={ infoCss.field }>
            <Link
              color="secondary"
              component={ RouterLink }
              to={ apiRawMessagesPath({
                devices: [device.device_id]
              }) }
              target="_blank"
            >
              View all...
            </Link>
          </div>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default connect<StateProps, Record<string, unknown>, OwnProps, State>(mapStateToProps)(DeviceMonitoring);
