import {
  RequestCalibrationBody,
  RequestRegularBody,
  RegistrationResponseStruct,
  RequestRegularCarCounter,
} from 'models/device-emulation';

export const EMULATION_MESSAGES_SEND_REGULAR = 'emulation/messages/SEND_REGULAR';
export const EMULATION_MESSAGES_SEND_CALIBRATION = 'emulation/messages/SEND_CALIBRATION';
export const EMULATION_MESSAGES_SEND_REGISTRATION = 'emulation/messages/SEND_REGISTRATION';
export const EMULATION_MESSAGES_SEND_REGULAR_CAR_COUNTER = 'emulation/messages/SEND_REGULAR_CAR_COUNTER';

export interface SendRegularMessage {
  readonly type: typeof EMULATION_MESSAGES_SEND_REGULAR;
  readonly deviceId: string;
  readonly payload: RequestRegularBody;
}

export interface SendCalibrationMessage {
  readonly type: typeof EMULATION_MESSAGES_SEND_CALIBRATION;
  readonly deviceId: string;
  readonly payload: RequestCalibrationBody;
}

export interface SendRegistrationMessage {
  readonly type: typeof EMULATION_MESSAGES_SEND_REGISTRATION;
  readonly deviceId: string;
  readonly payload: RegistrationResponseStruct;
}

export interface SendRegularCarCounterMessage {
  readonly type: typeof EMULATION_MESSAGES_SEND_REGULAR_CAR_COUNTER;
  readonly deviceId: string;
  readonly payload: RequestRegularCarCounter;
}

export const SendRegularMessage = (deviceId: string, payload: RequestRegularBody): SendRegularMessage => ({
  type: EMULATION_MESSAGES_SEND_REGULAR, deviceId, payload
});

export const SendCalibrationMessage = (deviceId: string, payload: RequestCalibrationBody): SendCalibrationMessage => ({
  type: EMULATION_MESSAGES_SEND_CALIBRATION, deviceId, payload
});

export const SendRegistrationMessage = (deviceId: string, payload: RegistrationResponseStruct): SendRegistrationMessage => ({
  type: EMULATION_MESSAGES_SEND_REGISTRATION, deviceId, payload
});

export const SendRegularCarCounterMessage = (deviceId: string, payload: RequestRegularCarCounter): SendRegularCarCounterMessage => ({
  type: EMULATION_MESSAGES_SEND_REGULAR_CAR_COUNTER, deviceId, payload
});

export type Action =
  | SendRegularMessage
  | SendCalibrationMessage
  | SendRegistrationMessage
  | SendRegularCarCounterMessage
