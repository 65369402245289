import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUser } from 'actions/user-management/users';
import { createFieldFromRegularInputWithError } from 'components/Form/Field/field-creators';
import { ALL_PROJECTS, User, UserUpdateParams } from 'models/user-management';
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';
import { RootState } from 'reducers';
import { useForm } from 'utils/form';
import { dispatchAsync } from 'utils/store';
import { assertUnreachable } from 'utils/type-checking';

import { ProjectsMode } from './shared';

// components
import { Button as MuiButton, FormControlLabel, FormGroup, Paper, Radio, RadioGroup } from '@material-ui/core';

import { Button, FormContext } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';

import SelectProjects from './SelectProjects';

// styles
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { successTheme } from 'styles/themes';

export const SelectProjectsField = createFieldFromRegularInputWithError(
  SelectProjects,
);

type ProjectsFormValues = Pick<UserUpdateParams['props'], 'projects'>;

interface ProjectsFormProps {
  user: User;
  onEditComplete: () => void;
}

const ProjectsForm: React.FC<ProjectsFormProps> = (props) => {
  const { user } = props;

  const [mode, setMode] = useState(
    props.user.projects === ALL_PROJECTS
      ? ProjectsMode.ALL
      : props.user.projects.length
        ? ProjectsMode.LIST
        : ProjectsMode.NONE
  );

  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const dispatch = useDispatch();

  const form = useForm<ProjectsFormValues>({
    defaultValues: user,
  });

  const { getValues: getFormValues, setValue: setFormValue } = form;

  const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextMode = e.target.value as ProjectsMode;

    switch (nextMode) {
      case ProjectsMode.ALL: {
        setFormValue('projects', ALL_PROJECTS);
        break;
      }
      case ProjectsMode.NONE: {
        setFormValue('projects', []);
        break;
      }
      case ProjectsMode.LIST: {
        if (getFormValues().projects === ALL_PROJECTS) {
          setFormValue('projects', []);
        }
        break;
      }
      default: assertUnreachable(nextMode);
    }

    setMode(nextMode);
  };

  const infoCss = useInfoBlockStyles();

  const handleSubmit = form.handleSubmit(values => {
    const userUpdateProps: UserUpdateParams['props'] = {
      ...user,
      ...values,
    };

    if (!isAdmin) {
      userUpdateProps.role = undefined;
    }

    return dispatchAsync(dispatch, updateUser({ sub: user.sub, props: userUpdateProps }))
      .then(() => {
        props.onEditComplete();
      });
  });

  return (
    <FormContext form={form}>
      <form onSubmit={handleSubmit}>
        <Paper>
          <BlockToolbar>
            <BlockTitle>
              Grant access to projects
            </BlockTitle>

            <MuiButton
              color="inherit"
              disabled={form.formState.isSubmitting}
              onClick={props.onEditComplete}
            >
              Cancel
            </MuiButton>

            <ThemeProvider theme={successTheme}>
              <Button
                color="primary"
                disabled={!form.formState.dirty}
                pending={form.formState.isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </ThemeProvider>
          </BlockToolbar>

          <FormGroup className={infoCss.fields}>
            <RadioGroup
              className={infoCss.field}
              name="mode"
              value={mode}
              onChange={handleModeChange}
            >
              <FormControlLabel
                label="All"
                value={ProjectsMode.ALL}
                control={<Radio />}
              />
              <FormControlLabel
                label="None"
                value={ProjectsMode.NONE}
                control={<Radio />}
              />
              <FormControlLabel
                label="List"
                value={ProjectsMode.LIST}
                control={<Radio />}
              />
            </RadioGroup>

            <div className={infoCss.field}>
              <SelectProjectsField
                label="Projects"
                name="projects"
                projectsMode={mode}
              />
            </div>
          </FormGroup>
        </Paper>
      </form>
    </FormContext>
  );
};

export default ProjectsForm;
