import React from 'react';

import { dmPositionsPath } from 'routing/paths';

// components
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import PositionsPage from 'pages/DeviceManagement/Positions/PositionsPage';

type Filters = React.ComponentProps<typeof PositionsPage>['filters'];

interface PositionsPageLinkProps {
  filters?: Filters;
}

const PositionsPageLink: React.FC<PositionsPageLinkProps> = ({ filters }) => {
  return (
    <Button
      color="primary"
      component={RouterLink}
      variant="contained"
      // RouterLink's props
      to={dmPositionsPath(filters)}
    >
      Customize filters
    </Button>
  );
};

export default PositionsPageLink;
