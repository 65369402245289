import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistoryByFilters } from './useHistoryByFilters';

// components
import { HistoryFilter, HistoryTable } from 'components/Caller';

// styles
import useStyles from 'styles/page';

const HttpCallerHistory: React.FC = () => {
  const classes = useStyles();
  const state = useHistoryByFilters();

  return (
    <>
      <Helmet>
        <title>Http Delivery History</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <HistoryFilter
          isLoading={ state.isLoading }
          filter={ state.filter }
          saveFilter={ state.saveFilter }
          resetFilter={ state.resetFilter }
        />
      </div>
      <div className={ classes.table }>
        <HistoryTable
          data={ state.data }
          isLoading={ state.isLoading }
        />
      </div>
    </>
  );
};

export default HttpCallerHistory;
