import { FormField, FormState } from './types';
import { FormErrors } from 'form';
import { useAuthUserSelector, useForm, useFormActionLoader } from 'hooks';
import { UpdateEndpoint } from 'actions/rabbit';
import { Endpoint } from 'models/rabbit';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.name)) {
    if (!state[FormField.name]?.trim()) {
      errors[FormField.name] = 'Name is required';
    }
  }

  if (fields.includes(FormField.ownerId)) {
    if (!state[FormField.ownerId]) {
      errors[FormField.ownerId] = 'Owner is required';
    }
  }

  return errors;
};

export const useManagementForm = (endpoint: Endpoint) => {
  const user = useAuthUserSelector();
  const form = useForm<FormState, FormField>({
    initialState: {
      [FormField.name]: endpoint.name ?? '',
      [FormField.ownerId]: endpoint.owner_id ?? user.clientId,
    },
    showFields: [
      FormField.name,
      ...(user.isAdmin ? [FormField.ownerId] : [])
    ]
  });
  const { doAction } = useFormActionLoader();
  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: UpdateEndpoint({
        ...endpoint,
        name: form.state[FormField.name],
        owner_id: form.state[FormField.ownerId],
      }),
      onError: form.catchError
    });
  };

  return {
    form,
    onSave: handleSave,
    onCancel: form.reset
  };
};
