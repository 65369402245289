import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchUser } from 'actions/user-management/users';
import { fetchPermissions } from 'actions/user-management/permissions';
import { RootState } from 'reducers';
import { UserRouteParams } from 'routing/routes/user-management/shared';
import { getUserBySub } from 'selectors/user-management';
import { formatUser, isNil } from 'utils/models';

// components
import { Grid, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';

import Account from './widgets/Account';
import Permissions from './widgets/Permissions';
import Projects from './widgets/Projects';

// styles
import useProjectStyles from 'styles';

const UserPage: React.FC = () => {
  const { userSub } = useParams<UserRouteParams>();

  const user = useSelector((state: RootState) => isNil(userSub)
    ? undefined
    : getUserBySub(userSub, state),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (userSub) {
      dispatch(fetchUser(userSub));
    }
  }, [dispatch, userSub]);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const projectCss = useProjectStyles();

  if (!userSub || user === null) {
    return (
      <Typography variant="h4" className={projectCss.pageNotImplemented}>
        You have requested the page of nonexistent user
      </Typography>
    );
  }

  return (
    <>
      {user && (
        <Helmet>
          <title>{formatUser(user)}</title>
        </Helmet>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Account user={user} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Projects user={user} />
        </Grid>
        { user && (
          <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
            <Permissions user={ user }/>
          </Grid>
        ) }
      </Grid>
    </>
  );
};

export default UserPage;
