export enum FormField {
  software = 'software',
  count = 'count',
  active = 'active',
  owner_id = 'owner_id',
}

export interface FormState {
  software?: string;
  count?: number;
  active?: boolean;
  owner_id?: number;
}
