import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(theme => createStyles({
  filterControl: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  tabs: {
    alignSelf: 'stretch',
  },
  tabsFlexContainer: {
    minHeight: '100%',
  },
  tab: {
    padding: '18px 15px',
    fontSize: '16px',
    textTransform: 'none',
    opacity: 0.38,
  },
  tabSelected: {
    opacity: 1,
  },
  tabIndicator: {
    top: '-5px',
    bottom: 'auto',
    height: '10px',
    borderRadius: '4px',
  },
  chart: {
    height: '262px',
  },
}));
