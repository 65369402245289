import { makeStyles, createStyles } from 'styles/utils';

export const useCommonDialogTabsStyles = makeStyles(_theme => createStyles({
  tabs: {
    marginTop: '6px',
    alignSelf: 'stretch',
    borderBottom: '1px solid #e4e7eb',
  },
  tabsFlexContainer: {
    minHeight: '100%',
  },
  tab: {
    padding: '9px 15px',
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    opacity: 1,
  },
  tabIndicator: {
    height: '3px',
  },
}));