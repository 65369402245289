import React from 'react';

import { Filters as AllFilters } from 'hooks/device-management/useDmTree/types';

// components
import { GroupedFilters } from 'components/Controls';
import { NetworkIdSelect } from 'components/Controls/NetworkIdSelect/NetworkIdSelectComponent';

// styles
import useStyles from 'styles/filters';

type Filters = Omit<AllFilters, 'limit' | 'offset' | 'topmostEntityType'>;

interface Props {
  state: Filters;
  setState: (filters: Filters) => void;
}

export const DeviceManagementAllFilter = ({
  state,
  setState,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      <GroupedFilters
        isProjectShow
        isZoneShow
        isGroupsShow
        owner={ state.owner }
        handleSelectOwner={ owner => setState({ ...state, owner }) }
        projects={ state.projects }
        handleSelectProjects={ projects => setState({ ...state, projects }) }
        zones={ state.zones }
        handleSelectZones={ zones => setState({ ...state, zones }) }
        uncheckGroups={ () => setState({ ...state, groups: [] }) }
        groups={ state.groups }
        handleSelectGroups={ groups => setState({ ...state, groups }) }
      />

      <NetworkIdSelect
        selected={ state.networkIds ?? [] }
        changeHandler={ networkIds => setState({ ...state, networkIds }) }
      />
    </form>
  );
};
