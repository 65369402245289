export const DICT_HARDWARE_TYPES_CHANGE = 'DICT_HARDWARE_TYPES_CHANGE';

export interface DictHardwareTypesChange {
  readonly type: typeof DICT_HARDWARE_TYPES_CHANGE;
  hardwareTypes: string[];
}

export const DictHardwareTypesChange = (hardwareTypes: string[]): DictHardwareTypesChange => ({
  type: DICT_HARDWARE_TYPES_CHANGE, hardwareTypes
});

export type Action = DictHardwareTypesChange;
