import { combineReducers } from 'redux';

import { reducer as positions } from 'reducers/device-management/positions';
import { reducer as positionsRepository, State as PositionsRepositoryState } from 'reducers/device-management/positions-repository';
import { reducer as devices } from 'reducers/device-management/devices';
import { reducer as deviceDownload, State as DeviceDownloadState } from './device-download';
import { reducer as groups } from 'reducers/device-management/groups';
import { reducer as zones } from 'reducers/device-management/zones';
import { reducer as levels } from 'reducers/device-management/levels';
import { reducer as virtualDevices, State as VirtualDevicesState } from 'reducers/device-management/virtual-devices';

import { DeviceState, GroupState, LevelState, PositionState, ZoneState, } from 'models/device-management/states';

export interface State {
  zones: ZoneState;
  groups: GroupState;
  devices: DeviceState;
  deviceDownload: DeviceDownloadState;
  positions: PositionState;
  positionsRepository: PositionsRepositoryState;
  levels: LevelState;
  virtualDevices: VirtualDevicesState;
}

export const reducer = combineReducers({
  positions,
  positionsRepository,
  devices,
  deviceDownload,
  groups,
  zones,
  levels,
  virtualDevices,
});
