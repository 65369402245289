import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minWidth: 350,
    '& [class*="MuiCard-root"]': {
      overflow: 'visible',
    },
    '& [class*="MuiInputBase-input][class*="Mui-disabled"]': {
      color: theme.palette.text.primary,
    },
  },
}));
