import { difference } from 'lodash';

import { CreateTemplate, UpdateTemplate } from 'actions/caller';
import { TemplateFormField } from 'components/Caller';
import { useForm, useFormActionLoader } from 'hooks';
import { useCallerMessageTypes } from 'hooks/caller';
import { METHOD_FOR_NOT_EDITABLE_MESSAGE_TYPE, Template, TemplateMethod, UpdateTemplateParams } from 'models/caller';
import { FormState } from 'types/form';

interface ManualFormHookOptions {
  type: 'create' | 'edit';
  template?: Template;
  templateSuiteId: number;
  onSuccess?: () => void;
  hiddenFields?: TemplateFormField[];
}

export type ManualForm = FormState<Template, TemplateFormField>;

interface ManualFormHookResult {
  form: ManualForm;
  submit: () => void;
  messageTypeNotEditable: boolean;
}

export const useManualForm = ({
  type,
  template,
  templateSuiteId,
  onSuccess,
  hiddenFields = [],
}: ManualFormHookOptions): ManualFormHookResult => {
  const isEdit = type === 'edit';

  const initialState = template ?? {
    template_id: 0,
    template_suite_id: templateSuiteId,
    name: '',
    message_type: 'all_direct',
    url: '',
    headers: {},
    is_active: true,
    method: TemplateMethod.POST,
  };

  const messageTypesState = useCallerMessageTypes();

  const isMessageTypeNotEditable = (formMessageType?: string): boolean => {
    const messageType = messageTypesState.messageTypes?.find(m => m.id === formMessageType);

    if (messageType && !messageType.is_editable) {
      return true;
    }

    return false;
  };

  const form = useForm<Template, TemplateFormField>({
    initialState,
    showFields: difference([
      TemplateFormField.name,
      TemplateFormField.templateSute,
      TemplateFormField.messageType,
      TemplateFormField.url,
      TemplateFormField.method,
      TemplateFormField.isActive,
      TemplateFormField.headers,
      TemplateFormField.body,
    ], hiddenFields),
  });

  const { doAction } = useFormActionLoader();

  const submit = () => {
    if (!form.validate()) {
      return false;
    }

    const messageTypeNotEditable = isMessageTypeNotEditable(form.state.message_type);

    const nextTemplate: UpdateTemplateParams = {
      ...form.state,
      method: messageTypeNotEditable ? METHOD_FOR_NOT_EDITABLE_MESSAGE_TYPE : form.state.method,
      body: messageTypeNotEditable ? '' : form.state.body,
    };

    doAction({
      action: !isEdit ? CreateTemplate(nextTemplate) : UpdateTemplate(nextTemplate),
      onSuccess,
      onError: form.catchError,
    });
  };

  return {
    form,
    submit,
    messageTypeNotEditable: isMessageTypeNotEditable(form.state.message_type),
  };
};
