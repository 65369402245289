import { useLocationItems } from 'hooks/location';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { useState, useEffect } from 'react';

interface TemplateFromUrlFiltersState {
  templateId?: number;
}

export const buildState = (items: UrlItems): number | undefined => {
  const parser = new Parser(items as TemplateFromUrlFiltersState);
  const templateId = parser.asNumber('templateId');

  return templateId;
};

interface TemplateFromUrlState {
  templateId?: number;
  isOpen: boolean;
  close: (templateId?: number) => void;
  resetTemplateId: () => void;
}

export function useTemplateFromUrl(): TemplateFromUrlState {
  const location = useLocationItems({ updateMethod: 'replace' });
  const templateId = buildState(location.items);
  const [isOpen, setOpen] = useState(templateId !== undefined);

  useEffect(() => {
    setOpen(templateId !== undefined);
  }, [templateId]);

  return {
    templateId,
    isOpen,
    close: () => setOpen(false),
    resetTemplateId: () => location.setItems(allItems => ({ ...allItems, templateId: undefined })),
  };
}
