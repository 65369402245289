import React, { useState, MouseEvent } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ConfirmAccount as ConfirmAccountAction, fetchUser } from 'actions/user-management/users';
import { dispatchAsync } from 'utils/store';
import { isAllowedToUpdateUser } from 'utils/permissions';

type Props<TUser, TAuthUser> = {
  user?: TUser;
  authUser?: TAuthUser;
};

const ConfirmAccount = <TAuthUser, TUser extends { sub: string; confirmed: boolean }>({
  user,
  authUser
}: Props<TUser, TAuthUser>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  if (!user || user.confirmed) {
    return null;
  }

  const handleClickOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    return dispatchAsync(dispatch, ConfirmAccountAction(user.sub))
      .then(() => {
        setIsModalOpen(false);
        dispatch(fetchUser(user.sub));
      });
  };

  return (<>
    <Button
      color="inherit"
      onClick={ handleClickOpen }
      // @TODO join types UserPayload(src/actions/user-profile.ts) and User(src/models/user-management/user.ts)
      disabled={ !isAllowedToUpdateUser({ ...authUser, confirmed: '' }) }
    >
      Confirm Account
    </Button>
    <Dialog
      open={ isModalOpen }
      onClose={ handleClose }
      maxWidth={ 'sm' }
    >
      <DialogTitle id="alert-dialog-title">Confirm account manually?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirm
        </Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default ConfirmAccount;
