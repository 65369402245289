import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import {
  DevicesFilterFields,
  initDeviceFilters,
  EnumDevicesParamsFields as DevicesFields,
  EmulatedStatus
} from 'models/device-management/filters';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';

export function getDevicesFiltersFromUrl(urlState: UrlItems): DevicesFilterFields {
  const parser = new Parser(urlState as DevicesFilterFields);
  return {
    owner: parser.asNumber(DevicesFields.owner),
    positions: parser.asNumbers(DevicesFields.positions) ?? [],
    level: parser.asNumbers(DevicesFields.level) ?? [],
    groups: parser.asNumbers(DevicesFields.groups) ?? [],
    devices: parser.asStrings(DevicesFields.devices) ?? [],
    isActive: parser.asBoolean(DevicesFields.isActive),
    hardwareTypes: parser.asStrings(DevicesFields.hardwareTypes) ?? [],
    isDamaged: parser.asString(DevicesFields.isDamaged),
    zones: parser.asNumbers(DevicesFields.zones) ?? [],
    isPositioned: parser.asString(DevicesFields.isPositioned),
    isEmulated: parser.asEnums(DevicesFields.isEmulated, EmulatedStatus),
    projects: parser.asNumbers(DevicesFields.projects) ?? [],
    groupInnerIds: parser.asStrings(DevicesFields.groupInnerIds) ?? [],
    firmwareId: parser.asString(DevicesFields.firmwareId),
    protocol: parser.asNumber(DevicesFields.protocol),
    networkIds: parser.asStrings(DevicesFields.networkIds) ?? [],
  };
}

export const useDevicesPageParams = () => {
  const { items, setItems } = useLocationItems();
  const [filters, setFilters] = useState<DevicesFilterFields>(initDeviceFilters);
  const { limit, offset } = usePagination();

  useEffectOnce(() => {
    delete items.limit;
    delete items.offset;
    setFilters(getDevicesFiltersFromUrl(items));
  });

  return {
    params: { ...filters, limit, offset },
    filters,
    setFilters: (filters: DevicesFilterFields) => {
      setFilters(filters);
      setItems({ ...filters, limit, offset: 0 });
    }
  };
};