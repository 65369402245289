import React from 'react';
// components
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import LensIcon from '@material-ui/icons/Lens';
// styles
import { styled } from 'styles/utils';

const StyledIcon = styled(LensIcon)(({ theme }) => ({
  fontSize: '0.5rem',
  marginRight: theme.spacing(.5),
}));

interface Props {
  status: boolean;
  iconProps?: Partial<SvgIconProps>;
}

export const ActiveStatusIcon = ({ status, iconProps }: Props) => {
  return (
    <StyledIcon { ...iconProps } color={ status ? 'secondary' : 'error' }/>
  );
};