import { GetUsersParams, Permissions, User } from 'models/user-management';
import { RootState } from 'reducers';
import { stringifyObject } from 'utils/ds/object';

export function getUserBySub(userSub: User['sub'], state: RootState) {
  return state.um.userBySub[userSub];
}

export function getUserSubs(params: GetUsersParams, state: RootState) {
  return state.um.userSubsByQuery[stringifyObject(params)];
}

export function getPermissionsById(
  id: Permissions['id'],
  state: RootState,
): Permissions | null | undefined {
  return state.um.permissionsById[id];
}

export function isUserManagementLoading(state: RootState): boolean {
  return (
    state.um.permissionsFetching ||
    state.um.usersFetching
  );
}
