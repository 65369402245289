import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { RootState } from 'reducers';
import { GetEndpointsParams, Endpoint } from 'models/rabbit';
import { LoadRabbitEndpointDataById, LoadRabbitEndpointDataByParams, LoadRabbitEndpointDataDictionary } from 'actions/rabbit';

export interface EndpointState {
  endpoint?: Endpoint;
  isLoading: boolean;
}

export function useRabbitEndpointLoadSelectorById(endpointId: number): EndpointState {
  const dispatch = useDispatch();
  const { isFetched, endpoint } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.endpoint.isFetched(endpointId),
    endpoint: state.rabbit.endpoint.findById(endpointId),
  }));

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitEndpointDataById(endpointId));
    }
  }, [endpointId, isFetched, dispatch]);

  return {
    endpoint: endpoint,
    isLoading: isFetched !== true
  };
}

export interface EndpointsState {
  endpoints: Endpoint[];
  isLoading: boolean;
}

export function useRabbitEndpointLoadSelectorByParams(params: GetEndpointsParams): EndpointsState {
  const dispatch = useDispatch();
  const { isFetched, endpoints } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.endpoint.isFetched(params),
    endpoints: state.rabbit.endpoint.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitEndpointDataByParams(params));
    }
  }, [params, isFetched, dispatch]);

  return {
    endpoints: endpoints,
    isLoading: isFetched !== true
  };
}

export function useRabbitEndpointDictionarySelector(): EndpointsState {
  const dispatch = useDispatch();
  const params: GetEndpointsParams = {};
  const { isFetched, endpoints } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.endpoint.isFetched(params),
    endpoints: state.rabbit.endpoint.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitEndpointDataDictionary());
    }
  }, [isFetched, dispatch]);

  return {
    endpoints: endpoints,
    isLoading: isFetched !== true
  };
}
