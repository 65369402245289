
import * as deviceActions from 'actions/device-management/devices';
import {
  DeviceState,
  initDeviceFilters,
} from 'models/device-management';

const initState: DeviceState = {
  data: [],
  devicesByPositionsId: [],
  total: 0,
  filters: initDeviceFilters,
  devicesColdDataById: {},
  devicesById: {},
  positionedDevicesById: {},
  isFetching: false,
  devicesColdDataFetching: false,
  devicesFetching: false,
  positionedDevicesFetching: false,
  error: undefined
};

export const reducer = (state: DeviceState = initState, action: deviceActions.Action): DeviceState => {
  switch (action.type) {
    case deviceActions.FETCH_DEVICES_BY_POSITIONS_ID:
      return { ...state, isFetching: true };
    case deviceActions.FETCH_DEVICES_SUCCESS:
      const data = action.payload.filter(device => !!device.device_id);
      const devicesById = data.reduce( (result, device) => ({ ...result, [device.device_id.toUpperCase()]: device }), {});
      return {
        ...state,
        positionedDevicesById: {
          ...state.positionedDevicesById,
          ...devicesById,
        },
        data,
        total: action.total,
        isFetching: false,
        accumulatedDataLoaded: true
      };
    case deviceActions.FETCH_DEVICES_BY_POSITIONS_ID_SUCCESS:
      return {
        ...state, devicesByPositionsId: action.devices, isFetching: false
      };

    case deviceActions.FETCH_DEVICES_COLD_DATA:
      return { ...state, devicesColdDataFetching: true };
    case deviceActions.FETCH_DEVICES_COLD_DATA_FAILURE:
      return { ...state, devicesColdDataFetching: false, error: action.error };
    case deviceActions.FETCH_DEVICES_COLD_DATA_SUCCESS:
      return {
        ...state,
        devicesColdDataById: {
          ...state.devicesColdDataById,
          ...action.payload,
        },
        devicesColdDataFetching: false,
        error: undefined,
      };

    case deviceActions.FIND_DEVICES:
      return {
        ...state,
        devicesFetching: true,
      };
    case deviceActions.FIND_DEVICES_FAILURE:
      return {
        ...state,
        devicesFetching: false,
        error: action.error,
      };
    case deviceActions.FIND_DEVICES_SUCCESS:
      return {
        ...state,
        devicesById: {
          ...state.devicesById,
          ...action.payload.devicesById,
        },
        devicesFetching: false,
        error: undefined,
      };

    case deviceActions.FIND_POSITIONED_DEVICES:
      return {
        ...state,
        positionedDevicesFetching: true,
      };
    case deviceActions.FIND_POSITIONED_DEVICES_FAILURE:
      return {
        ...state,
        positionedDevicesFetching: false,
        error: action.error,
      };
    case deviceActions.FIND_POSITIONED_DEVICES_SUCCESS:
      return {
        ...state,
        positionedDevicesById: {
          ...state.positionedDevicesById,
          ...action.payload.positionedDevicesById,
        },
        data: action.payload.foundDevices,
        positionedDevicesFetching: false,
        error: undefined,
      };

    case deviceActions.SET_DEVICE_MANAGEMENT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        },
        total: 0,
      };

    case deviceActions.FETCH_DEVICES_FAILED:
      return { ...state, isFetching: false, data: [] };
    case deviceActions.FIND_POSITIONED_DEVICES_RECURSIVE:
      return { ...state, isFetching: true, isFetched: false };
    case deviceActions.FIND_POSITIONED_DEVICES_RECURSIVE_SUCCESS:
      return {
        ...state, data: action.devices, isFetching: false, isFetched: true
      };
    case deviceActions.RECEIVED_SUCCESS_BIND_FROM_SPLACE:
      return { ...state, splaceMessage: action.message };
    case deviceActions.FETCH_DEVICES_BY_ACCUMULATED_POSITIONS_ID:
      return { ...state, accumulatedDataLoaded: false };
    default: return state;
  }
};
