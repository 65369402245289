import React from 'react';

// components
import { IconButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import PermissionsEditor, { PermissionsEditorProps } from '../PermissionsEditor';

// styles
import { green } from '@material-ui/core/colors';

export interface CreatePermissionsProps {
  defaultPermissions?: PermissionsEditorProps['defaultPermissions'];
  editedUserGroupId: number;
  onPermissionsCreate?: PermissionsEditorProps['onSuccess'];
}

const CreatePermissions: React.FC<CreatePermissionsProps> = (props) => {
  return (
    <PermissionsEditor
      renderTrigger={modal => (
        <IconButton aria-label="create" onClick={modal.open}>
          <AddIcon style={{ color: green[700] }} />
        </IconButton>
      )}
      defaultPermissions={props.defaultPermissions}
      editedUserGroupId={props.editedUserGroupId}
      onSuccess={props.onPermissionsCreate}
    />
  );
};

export default CreatePermissions;
