import React, { useState, useEffect } from 'react';
import { isEqual, isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { useDialog, useProvisioningBoxSelector, useProvisioningReceivedDevices } from 'hooks';
import { statuses, DeviceProvisioningState, Device, DeviceStatusEnum } from 'models/provisioning';
import { DoChangeDeviceStatus, DoAddDeviceToBox } from 'actions/provisioning';

// components
import { SingleSelectControl } from 'components';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import { Save, Block, WorkOff, Work } from '@material-ui/icons';
import { ShipmentBoxLink } from 'components/Links';
import { AddToBoxModal } from './AddToBoxModal';
import ShipmentName from '../Controls/ShipmentName';
import { RemoveFromBoxDialog } from './widgets/RemoveFromBoxDialog';

interface Props {
  device: Device;
}

export const DeviceProvisioningManagement = ({ device }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [state, setState] = useState<{ device: Device; shipment?: string }>({ device });
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const removeDialog = useDialog();
  const { box: currentBox } = useProvisioningBoxSelector(Number(device.box_id), !isNil(device.box_id));
  const receivedDevices = useProvisioningReceivedDevices({
    filter: { devices: [device.device_id], limit: 1 },
    enabled: isNil(currentBox?.shipment_id),
  });
  const receivedDevice = receivedDevices.devices.find(d => d.device_id?.toUpperCase() === device.device_id.toUpperCase());
  const currentShipmentId = currentBox?.shipment_id ?? receivedDevice?.shipment_id ?? '';

  const selectStatus = (status: keyof typeof DeviceProvisioningState) => setState({
    ...state,
    device: { ...state.device, status }
  });

  useEffect(() => {
    if (currentBox) {
      setState(state => ({ ...state, shipment: currentBox.shipment_id }));
    }
  }, [currentBox]);

  const reset = () => {
    setState({ device, shipment: currentBox?.shipment_id });
  };

  const save = () => {
    if (state.device.status !== device.status) {
      dispatch(DoChangeDeviceStatus(device.device_id, state.device.status));
    }

    if (state.device.box_id && state.device.box_id !== device.box_id) {
      dispatch(DoAddDeviceToBox(device.device_id, state.device.box_id));
    }
  };

  const add = () => {
    setDialogOpen(true);
  };

  const changes = isEqual(device, state.device);

  const setSelectedBox = (id: number) => {
    dispatch(DoAddDeviceToBox(device.device_id, id));
  };

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 12 }>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={ 2 } display="inline-flex">
            {
              device.box_id
                ? <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={ <WorkOff /> }
                  onClick={ removeDialog.open }
                >Remove from box</Button>
                : <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={ <Work /> }
                  onClick={ add }
                  disabled={ device.status === DeviceStatusEnum.Installed }
                >Add to box</Button>
            }
          </Box>
          <Box mr={ 2 } display="inline-flex">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={ <Block /> }
              onClick={ reset }
              disabled={ changes }
            >Cancel</Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={ <Save /> }
            disabled={ changes }
            onClick={ save }
          >Save</Button>
        </Box>
      </Grid>
      <Grid item xs={ 12 } md={ 12 }>
        <SingleSelectControl
          name="status"
          label="Status"
          isClearable={ false }
          values={ statuses }
          selected={ state.device.status }
          changeHandler={ status => selectStatus(status as keyof typeof DeviceProvisioningState) }
          isDisabled={ device.status === DeviceStatusEnum.Installed }
        />
      </Grid>
      { device.box_id && (
        <Grid item xs={ 12 } md={ 12 }>
          <Typography>
            Shipment: { currentShipmentId ? <ShipmentName withFetch id={ currentShipmentId }/> : '-' }
          </Typography>
        </Grid>
      ) }

      <Grid item xs={ 12 } md={ 12 }>
        <Typography>
          Box: { device.box_id ? <ShipmentBoxLink id={ device.box_id } /> : 'Not in box' }
        </Typography>
      </Grid>

      { isDialogOpen && <AddToBoxModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
        setSelectedBox={ setSelectedBox }
      /> }
      { removeDialog.isMounted && <RemoveFromBoxDialog
        device={ device }
        isOpen={ removeDialog.isOpen }
        onClose={ removeDialog.close }
        onCloseEnd={ removeDialog.unmount }
      /> }
    </Grid >
  );
};
