import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    actionCell: {
      width: 48,
      padding: 0,
      textAlign: 'center',
    },
    urlCell: {
      maxWidth: '50vw',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
  }),
);

export default useStyles;
