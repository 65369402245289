/**
 * Stricter subset of `keyBy` from "lodash":
 * - only iterates over array (first argument)
 * - supports only `PropertyKey` as the iteratee (second argument)
 * - array elements must contain iteratee
 * - iteratee does not have default value, so it's required
 *
 * TODO: use `keyBy` from "lodash-es" when infrastructure supports
 */
export function keyBy<
  K extends keyof V,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  V extends Record<K, PropertyKey> = any,
>(array: Array<V>, key: K): { [key: string]: V } {
  return array.reduce(
    (acc, x) => ({ ...acc, [x[key]]: x }),
    {},
  );
}