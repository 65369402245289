import React, { useEffect, useState } from 'react';
import { PERMISSIONS_CATEGORIES, PermissionsConfig, PermissionsOperation } from 'models/user-management';
import { useAuthUserSelector } from 'hooks';
import { usePermissionSelector } from 'hooks/user-managment';
import { useFormActionLoader, useFormEditToogleActions } from 'hooks/form';
import { updatePermissions } from 'actions/user-management/permissions';
import { getPermissionState, isDisabledByRelation, changeByRelation } from './utils';
// components
import { Box, Card, CardContent, CardHeader, Switch, Typography } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { ArrowDropDown, ArrowRight, Block, CheckCircleOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import { BlockLoader } from 'components/Loaders';
import { CommonButton } from 'components/Buttons';
// styles
import { useStyles } from './styles';

interface Props {
  permissionId: number;
}

export const PermissionManagement = ({ permissionId }: Props): JSX.Element => {
  const { permission } = usePermissionSelector(permissionId);
  const [permissionState, setPermissionState] = useState(getPermissionState(permission));
  useEffect(() => { setPermissionState(getPermissionState(permission)); }, [permission]);
  const togglePerissionState = (config: PermissionsConfig, operation: PermissionsOperation): void => {
    const newState = { ...permissionState };
    newState[config] = newState[config].includes(operation.key)
      ? newState[config].replace(operation.key, '')
      : newState[config] + operation.key;

    setPermissionState(changeByRelation(newState, config, operation));
  };

  const classes = useStyles();
  const user = useAuthUserSelector();
  const { doAction } = useFormActionLoader();
  const { editMode, actions } = useFormEditToogleActions({
    canEdit: user.isAdmin,
    canSave: true,
    onSave: () => {
      if (!permission) {
        return false;
      }

      doAction({
        action: updatePermissions({
          id: permission.id,
          props: {
            name: permission.name,
            template: permission.template,
            owner_id: permission.owner_id,
            ...permissionState
          }
        }),
      });
    },
    onCancel: () => setPermissionState(getPermissionState(permission))
  });

  const [expanded, setExpanded] = useState<string[]>([]);

  return (
    <Card className={ classes.cardRoot }>
      <CardHeader
        title={ `Permission ${ permission?.name || '' }` }
        action={
          <>
            <CommonButton
              type="icon"
              icon={ <ExpandMore/> }
              label={ 'Expand Tree' }
              onClick={ () => setExpanded(PERMISSIONS_CATEGORIES.map(c => c.key)) }
            />
            <CommonButton
              type="icon"
              icon={ <ExpandLess/> }
              label={ 'Сollapse Tree' }
              onClick={ () => setExpanded([]) }
            />
            { actions }
          </>
        }
      />
      <CardContent className={ classes.cardContent }>
        { !permission ? <BlockLoader/> :
          <TreeView
            defaultCollapseIcon={ <ArrowDropDown/> }
            defaultExpandIcon={ <ArrowRight/> }
            expanded={ expanded }
            onNodeToggle={ (_event: React.ChangeEvent<Record<string, unknown>>, nodeIds: string[]) => setExpanded(nodeIds) }
          >
            { PERMISSIONS_CATEGORIES.map(category => (
              <TreeItem
                key={ category.key }
                nodeId={ category.key }
                label={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1"> { category.name } </Typography>
                    <Typography variant="body1" color="inherit"> {
                      `${permissionState[category.key].length} / ${category.operations.length}`
                    } </Typography>
                  </Box>
                }
              >
                { category.operations.map(operation => (
                  <TreeItem
                    key={ `${ category.key }[${ operation.key }]` }
                    nodeId={ `${ category.key }[${ operation.key }]` }
                    label={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Typography variant="body1"> { operation.name } </Typography>
                          <Typography variant="caption" color="inherit"> { operation.description } </Typography>
                        </Box>
                        { !editMode ? '' :
                          <Box>
                            <Switch
                              size="small"
                              disabled={ isDisabledByRelation(permissionState, operation) }
                              checked={ permissionState[category.key].includes(operation.key) }
                              onChange={ () => togglePerissionState(category.key, operation) }
                            />
                          </Box>
                        }
                      </Box>
                    }
                    icon={
                      permissionState[category.key].includes(operation.key)
                        ? <CheckCircleOutline color={ 'primary' }/>
                        : <Block color={ 'error' }/>
                    }
                  />
                )) }
              </TreeItem>
            )) }
          </TreeView>
        }
      </CardContent>
    </Card>
  );
};
