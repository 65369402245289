import { API } from 'aws-amplify';

import { errorHandler, formatQuery, Primitive } from 'clients/utils';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import {
  CallerRequestDeliveryHistoryParams,
  CallerRequestDeliveryHistoryServerParams,
  CallerRequestHistoryParams,
  CallerRequestHistoryServerParams,
  CallerResponseCodeHistoryParams,
  CallerResponseCodeHistoryServerParams,
  ResponseCodes,
  SendingStatusStatisticEntry,
  TemplateRequests,
} from 'models/caller-monitoring';

export const config: ApiGConfig = {
  name: 'http-monitoring',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://callermon.prod.api.nwave.io' : 'https://callermon.dev.api.nwave.io',
};

const urls = {
  templateRequestStatistic: '/history/statistic/template_requests',
  responseCodeStatistic: '/history/statistic/response_codes',
  sendingStatusStatistic: '/history/statistic/sending_status',
};

export const getTemplateRequestStatistic = (
  params: CallerRequestHistoryParams,
): Promise<ApiResponse<TemplateRequests[]>> => {
  const reqUrlParams: CallerRequestHistoryServerParams = {
    owner_id: params.ownerId,
    project_id: params.projectIds,
    zone_id: params.zoneIds,
    group_id: params.groupIds,
    template_suite_id: params.templateSuiteId,
    template_id: params.templateIds,
    time_from: params.dateFrom,
    time_till: params.dateTo,
    group_by: params.groupBy,
  };

  const reqParams = {
    queryStringParameters: formatQuery(reqUrlParams as Record<string, Primitive | Primitive[]>),
  };

  return API
    .get(config.name, urls.templateRequestStatistic, reqParams)
    .catch(errorHandler);
};

export const getResponseCodeStatistic = (
  params: CallerResponseCodeHistoryParams,
): Promise<ApiResponse<ResponseCodes[]>> => {
  const reqUrlParams: CallerResponseCodeHistoryServerParams = {
    owner_id: params.ownerId,
    project_id: params.projectIds,
    zone_id: params.zoneIds,
    group_id: params.groupIds,
    template_suite_id: params.templateSuiteId,
    template_id: params.templateIds,
    time_from: params.dateFrom,
    time_till: params.dateTo,
    group_by: params.groupBy,
  };

  const reqParams = {
    queryStringParameters: formatQuery(reqUrlParams as Record<string, Primitive | Primitive[]>),
  };

  return API
    .get(config.name, urls.responseCodeStatistic, reqParams)
    .catch(errorHandler);
};

export const getDeliveryStatusStatistic = (
  params: CallerRequestDeliveryHistoryParams,
): Promise<ApiResponse<SendingStatusStatisticEntry[]>> => {
  const reqUrlParams: CallerRequestDeliveryHistoryServerParams = {
    owner_id: params.ownerId,
    project_id: params.projectIds,
    zone_id: params.zoneIds,
    group_id: params.groupIds,
    template_suite_id: params.templateSuiteId,
    template_id: params.templateIds,
    time_from: params.dateFrom,
    time_till: params.dateTo,
    group_by: params.groupBy,
  };

  const reqParams = {
    queryStringParameters: formatQuery(reqUrlParams as Record<string, Primitive | Primitive[]>),
  };

  return API
    .get(config.name, urls.sendingStatusStatistic, reqParams)
    .catch(errorHandler);
};
