import { Helmet } from 'react-helmet-async';
import { useLorawanStationsById } from 'hooks';
// components
import { Grid } from '@material-ui/core';
import { PageLoader } from 'components/Loaders';
import { LorawanStationInfo, BaseStationManagement } from 'components/BaseStation';
import { BaseStationNotFound } from './BaseStationNotFound';

// styles
import { useStyles } from './styles';

interface Props {
  stationId: number
}

const HelmetComponent = ({ stationId }: { stationId: number }) => (
  <Helmet><title>{ `Base Station #${stationId}` }</title></Helmet>
);

export const LorawanStationDetailPage = ({ stationId }: Props): JSX.Element => {
  const classes = useStyles();
  const { station, isLoading } = useLorawanStationsById(stationId);

  if (isLoading) {
    return (
      <>
        <HelmetComponent stationId={ stationId } />
        { isLoading ? <PageLoader /> : <BaseStationNotFound stationId={ stationId } /> }
      </>
    );
  }

  return (
    <>
      <HelmetComponent stationId={ stationId } />
      <Grid
        className={ classes.root }
        container
        spacing={ 2 }
        data-testid="base-station-page"
        data-station-id={ stationId }
      >
        <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
          <LorawanStationInfo station={ station } />
        </Grid>
        <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
          <BaseStationManagement station={ station } />
        </Grid>
      </Grid>
    </>
  );
};