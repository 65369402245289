import { initProvisioningBoxesFilters, Shipment } from 'models/provisioning';
import { isShipmentReceived, useProvisioningBoxes, useProvisioningReceivedBoxes } from 'hooks/provisioning';

interface ShipmentsDeviceCountState {
  isLoading: boolean;
  deviceCount: Record<string, number>;
}

export const useShipmentDeviceCount = (shipments: Shipment[]): ShipmentsDeviceCountState => {
  const shipmentReceivedIds: string[] = [];
  const shipmentOverIds: string[] = [];
  for (const shipment of shipments) {
    if (isShipmentReceived(shipment)) {
      shipmentReceivedIds.push(shipment.id);
    } else {
      shipmentOverIds.push(shipment.id);
    }
  }

  const shipmentBoxes = useProvisioningBoxes({
    params: {
      ...initProvisioningBoxesFilters,
      shipment_id: shipmentOverIds,
      limit: 999, // load all device
    },
    enabled: shipmentOverIds.length > 0
  });

  const receivedBoxes = useProvisioningReceivedBoxes({
    filter: {
      shipments: shipmentReceivedIds,
      limit: 999, // load all device
    },
    enabled: shipmentReceivedIds.length > 0
  });

  if (receivedBoxes.isLoading || receivedBoxes.isLoading) {
    return {
      isLoading: true,
      deviceCount: {}
    };
  }

  const result: Record<string, number> = {};
  for (const box of shipmentBoxes.boxes) {
    const shipmentId = String(box.shipment_id);
    if (result[shipmentId] === undefined) {
      result[shipmentId] = 0;
    }
    result[shipmentId] += box.device_count ?? 0;
  }

  for (const box of receivedBoxes.boxes) {
    const shipmentId = String(box.shipment_id);
    if (result[shipmentId] === undefined) {
      result[shipmentId] = 0;
    }

    result[shipmentId] += box.device_count ?? 0;
  }

  return {
    isLoading: false,
    deviceCount: result
  };
};