import { ReactNode } from 'react';
import { useDialog } from 'hooks';
import { countFilterSet } from './utils';
// components
import { Dialog } from './widgets/Dialog';
import { Button } from './widgets/Button';

interface Props<Filters> {
  isLoading: boolean;
  isValid?: boolean;
  filter: Filters;
  onSave: () => void;
  onReset: () => void;
  children: ReactNode;
  skip?: Record<string, string | number | null>;
}

export function FilterButton<Filters>(props: Props<Filters>): JSX.Element {
  const { isLoading, isValid = true, onSave, filter, onReset, children, skip } = props;
  const dialog = useDialog();

  const count = countFilterSet(filter, skip);

  return (
    <>
      <Button count={ count } onClick={ dialog.open }/>
      { dialog.isMounted && <Dialog
        isLoading={ isLoading }
        isValid={ isValid }
        isOpen={ dialog.isOpen }
        onClose={ dialog.close }
        onCloseEnd={ dialog.unmount }
        onSave={ () => {
          onSave();
          dialog.close();
        } }
        onReset={ () => {
          onReset();
          dialog.close();
        } }
      >
        { children }
      </Dialog> }
    </>
  );
}
