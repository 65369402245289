import { useEffect, useState } from 'react';
import { useAuthUserSelector }from 'hooks/user';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grecaptcha: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recaptcha: any;
  }
}

const SITE_KEY = '6Le2Pi0fAAAAAHA3HXPSYG10VlBXv6lFELYrbBLp';
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
const RECAPTCHA_BADGE_QUERY = '.grecaptcha-badge';  // grecaptcha-badge
const RECAPTCHA_CHILD_SCRIPT_URL_QUERY = 'https://www.gstatic.com/recaptcha/';

export function useRecaptcha() {
  const { signedIn } = useAuthUserSelector();
  const [isLoaded, setisLoaded] = useState(false);
  const [scriptNode, setScriptNode] = useState<HTMLElement | null>(null);

  // add reCapthca script
  useEffect(() => {
    if (!window.grecaptcha && !signedIn) {
      const script = document.createElement('script');
      script.src = RECAPTCHA_URL;
      script.addEventListener('load',
        () => {
          window.grecaptcha.ready(() => {
            setisLoaded(true);
          });
        }
      );
      setScriptNode(script);
      document.body.appendChild(script);
    }
  }, [signedIn]);

  // remove reCapthca
  // important info about setTimeout error - https://github.com/google/recaptcha/issues/269
  useEffect(() => {
    if (signedIn && isLoaded) {
      // remove badge
      const nodeBadge = document.querySelector(RECAPTCHA_BADGE_QUERY);
      if (nodeBadge && nodeBadge.parentNode) {
        document.body.removeChild(nodeBadge.parentNode);
      }
      // remove script
      if (scriptNode) {
        scriptNode.remove();
        // remove additional script
        const gstaticScritps = document.querySelectorAll('script');
        gstaticScritps.forEach(scriptNode => {
          if (scriptNode.src.includes(RECAPTCHA_CHILD_SCRIPT_URL_QUERY)) {
            scriptNode.remove();
          }
        });
        setScriptNode(null);
      }
      // remove global instance
      window.grecaptcha.reset(SITE_KEY);
      window.grecaptcha = null;
      window.recaptcha = null;
      setisLoaded(false);
    }
  }, [signedIn, isLoaded, scriptNode]);

  return () => new Promise<string>((resolve, reject) => {
    if (window.grecaptcha) {
      resolve(window.grecaptcha.execute(SITE_KEY, { action: 'auth' }));
    } else {
      reject(new Error('Recaptcha script not available, try to reload the page.'));
    }
  });
}