import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { DoFetchBoxById } from 'actions/provisioning';
import { Box, GetBoxesParams, ResponseBoxes } from 'models/provisioning';
import { useFormActionNotifier } from 'hooks/form';
import { fetchProvisioningBoxes } from 'clients/provisioning';
import { ensureRequestSucceeded } from 'utils/clients';

interface State {
  box?: Box;
  isLoading: boolean;
}

export function useProvisioningBoxSelector(boxId: number, enabled = true): State {
  const dispatch = useDispatch();
  useEffect(() => {
    enabled && dispatch(DoFetchBoxById(boxId));
  }, [dispatch, enabled, boxId]);

  const state: State = useSelector((state: RootState) => ({
    isLoading: state.provisioning.devices.isFetching,
    box: state.provisioning.boxes.boxes.find(b => b.id === boxId),
  }), shallowEqual);

  return state;
}

interface BoxesParams {
  params: GetBoxesParams; // @TODO pagination
  enabled?: boolean;
}

interface BoxesState {
  isLoading: boolean;
  isFetching: boolean;
  boxes: Box[];
  total: number;
}

export function useProvisioningBoxes({ params, enabled }: BoxesParams): BoxesState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['provisioning/boxes', params],
    queryFn: async (): Promise<ResponseBoxes> => {
      const result = await fetchProvisioningBoxes(params);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching boxes: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    isFetching: queryResult.isFetching,
    boxes: queryResult.data?.data ?? [],
    total: queryResult.data?.total ?? 0,
  };
}
