import { FormField, FormState } from './types';
import { FormErrors } from 'form';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.name)) {
    if (!state[FormField.name]?.trim()) {
      errors[FormField.name] = 'Name is required';
    }
  }

  if (fields.includes(FormField.host)) {
    if (!state[FormField.host]?.trim()) {
      errors[FormField.host] = 'Host is required';
    }
  }

  if (fields.includes(FormField.vhost)) {
    if (!state[FormField.vhost]?.trim()) {
      errors[FormField.vhost] = 'vHost is required';
    }
  }

  if (fields.includes(FormField.port)) {
    if (!state[FormField.port]) {
      errors[FormField.port] = 'Port is required';
    } else if (Number.isNaN(Number(state[FormField.port]))) {
      errors[FormField.port] = 'Port must be a number';
    }
  }

  if (fields.includes(FormField.exchange)) {
    if (!state[FormField.exchange]?.trim()) {
      errors[FormField.exchange] = 'Exchange is required';
    }
  }

  if (fields.includes(FormField.queue)) {
    if (!state[FormField.queue]?.trim()) {
      errors[FormField.queue] = 'Queue is required';
    }
  }

  if (fields.includes(FormField.login)) {
    if (!state[FormField.login]?.trim()) {
      errors[FormField.login] = 'Login is required';
    }
  }

  if (fields.includes(FormField.password)) {
    if (!state[FormField.password]?.trim()) {
      errors[FormField.password] = 'Password is required';
    } else if (state[FormField.password] !== state[FormField.passwordRepeat]) {
      errors[FormField.passwordRepeat] = 'Password is not equals';
    }
  }

  return errors;
};
