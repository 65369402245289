import { createStyles, makeStyles, Theme } from 'styles/utils';
import { colors } from 'styles/themes/outer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 1.5, 2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    itemValue: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: colors.textPrimary,
      marginTop: theme.spacing(2),
    }
  }),
);

export default useStyles;
