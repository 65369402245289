import { FC } from 'react';
import { Router as ReactRouter } from 'react-router';
import { RootState } from './reducers';
import { State as UserState } from './reducers/user-profile';
import { isUserManagementLoading } from './selectors/user-management';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { Router } from './Router';

// components
import {  Notifier } from 'components';
import { ActionLoaderProvider } from 'components/Loaders';
import AuthContainer from './components/AuthContainer';

// styles
import './App.scss';

import history from 'utils/routing/history';

// AWS Amplify
import { configureAmplify } from 'clients';
import { Auth } from 'aws-amplify';
import awsconfig from 'aws-exports';
configureAmplify(awsconfig);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__testCafeGetCurrentUserSub = async (): Promise<string> => {
  return (await Auth.currentAuthenticatedUser()).attributes.sub;
};

interface Props {
  isFetching?: boolean;
  user: UserState;
}

const mapStateToProps = (state: RootState) => ({
  isFetching:
    state.baseStation.stationStatus.isFetching() ||
    state.baseStation.stationFlash.isFetching() ||
    state.baseStation.location.isFetching() ||
    state.baseStation.responsiblePerson.isFetching() ||
    state.caller.template.isFetching() ||
    state.caller.templateStatistic.isFetching() ||
    state.caller.templateSuite.isFetching() ||
    state.callerHistory.isFetching ||
    state.callerRouters.isFetching ||
    state.rabbit.rule.isFetching() ||
    state.rabbit.endpoint.isFetching() ||
    state.rabbit.history.repository.isFetching() ||
    state.rawMessages.isFetching ||
    state.deviceEmulation.deviceSettings.isFetching() ||
    state.deviceManagement.devices.isFetching ||
    state.deviceManagement.levels.isFetching() ||
    state.deviceManagement.zones.repository.isFetching() ||
    state.deviceManagement.positionsRepository.isFetching() ||
    state.deviceManagement.positions.isFetching ||
    state.deviceManagement.positions.positionsFetching ||
    state.incidents.isFetching ||
    state.parking.positionState.isLoading ||
    state.provisioning.devices.isFetching ||
    state.provisioning.boxes.isFetching ||
    state.provisioning.shipment.isFetching ||
    isUserManagementLoading(state),
  user: state.user
});

const AppComponent = (props: Props) => (<ReactRouter history={ history }>
  <SnackbarProvider>
    <ActionLoaderProvider>
      <AuthContainer>
        <Router {...props} />
      </AuthContainer>
    </ActionLoaderProvider>
    <Notifier />
  </SnackbarProvider>
</ReactRouter>);

export const App: FC = connect(mapStateToProps)(AppComponent);
