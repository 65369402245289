import * as Actions from 'actions/base-station';
import { GetStationFlashParams, StationFlash } from 'models/base-station';
import { Repository } from 'utils/repository';

export type State = Repository<StationFlash, GetStationFlashParams, 'id'>;
const initState: State = Repository.create('id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.BASE_STATION_FLASH_DATA_LOAD_START:
      return state.setFetched(action.params, false);
    case Actions.BASE_STATION_FLASH_DATA_LOAD_FAILURE:
      return state.setFetched(action.params, true);
    case Actions.BASE_STATION_FLASH_DATA_LOAD_SUCCESS:
      return state
        .setFetched(action.params, true)
        .setData(action.params, action.data);
    case Actions.BASE_STATION_FLASH_LOCK:
      return state.setLocked(action.stationId, true);
    case Actions.BASE_STATION_FLASH_UNLOCK:
      return state.setLocked(action.stationId, false);
    case Actions.BASE_STATION_FLASH_CHANGE:
      return state.changeModel(action.stationFlash.id, action.options.delete ? undefined : action.stationFlash);
    default:
      return state;
  }
};
