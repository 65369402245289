import {
  Duration,
  FilterField,
  FilterState,
} from './types';

import { PositionStateStatus } from 'models/parking';
// components
import { NetworkIdSelect } from 'components';
import {
  CustomIdSelect,
  DateTimeControl,
  SingleSelectControl
} from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
// styles
import useStyles from 'styles/filters';

export interface Props {
  state: FilterState;
  setState: (filters: FilterState | ((filters: FilterState) => FilterState)) => void;
  errors?: Record<FilterField, string>;
}

export const ParkingStateFilter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { state, setState, errors } = props;
  return (
    <form autoComplete="off" className={ classes.gridRoot }>
      <GroupedFilters
        owner={ state.owner }
        handleSelectOwner={ owner => setState({ ...state, owner }) }
        isProjectShow={ true }
        projects={ state.projects }
        handleSelectProjects={ projects => setState(prevState => ({ ...prevState, projects: projects ?? [] })) }
        isZoneShow={ true }
        zones={ state.zones }
        handleSelectZones={ zones => setState(prevState => ({ ...prevState, zones: zones ?? [] })) }
        isGroupsShow={ true }
        groups={ state.groupIds }
        handleSelectGroups={ groupIds => setState({ ...state, groupIds: groupIds ?? [] }) }
        uncheckGroups={ () => setState({ ...state, groupIds: [] }) }
      />
      <NetworkIdSelect
        name="Network ID"
        selected={ state.networkIds }
        changeHandler={ networkIds => setState({ ...state, networkIds }) }
      />
      <CustomIdSelect
        name="Custom ID"
        selected={ state.customIds }
        changeHandler={ customIds => setState({ ...state, customIds }) }
        type="string"
      />
      <CustomIdSelect
        name="Device ID"
        selected={ state.deviceIds }
        changeHandler={ deviceIds => setState({ ...state, deviceIds }) }
        type="hex"
      />
      <SingleSelectControl
        label="State"
        name="status"
        isClearable
        selected={ state.status }
        values={ [
          { label: 'Free', value: PositionStateStatus.Free },
          { label: 'Occupied', value: PositionStateStatus.Occupied },
        ] }
        changeHandler={ status => setState({ ...state, status: status as PositionStateStatus }) }
      />
      <SingleSelectControl
        label="Duration"
        name="duration"
        selected={ state.duration }
        values={ [
          { label: 'All', value: Duration.ALL },
          { label: '0 - 30 min', value: Duration.LESS_30_MIN },
          { label: '31 min - 4 hours', value: Duration.LESS_4_HOUR },
          { label: 'More than 4 hours', value: Duration.MORE_4_HOUR },
          { label: 'Custom', value: Duration.CUSTOM },
        ] }
        changeHandler={ duration => setState({ ...state, duration: duration as Duration }) }
      />
      <DateTimeControl
        label="Time From"
        value={ state.duration === Duration.CUSTOM ? state.timeFrom : undefined }
        onChange={ timeFrom => setState({ ...state, timeFrom }) }
        maxDate={ state.timeTo }
        isDisabled={ state.duration !== Duration.CUSTOM }
        hideErrorsFromHelperText
        isClearable
        error={ errors?.[FilterField.timeFrom]}
      />
      <DateTimeControl
        label="Time To"
        value={ state.duration === Duration.CUSTOM ? state.timeTo : undefined }
        onChange={ timeTo => setState({ ...state, timeTo }) }
        minDate={ state.timeFrom }
        isDisabled={ state.duration !== Duration.CUSTOM }
        isClearable
        error={ errors?.[FilterField.timeTo]}
      />

    </form>
  );
};
