import { createUserProjectSubscription, deleteUserProjectSubscription, fetchUsersProjectsSubscriptions, updateUserProjectSubscription } from 'clients/user-management/project-health-subsciption';
import { useFormActionNotifier } from 'hooks';
import { SubscriptionParams, SubscriptionType, UserWithSubscriptionType } from 'models/user-management/projectHealthSubscriptions';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useEffectOnce } from 'react-use';
import { ensureRequestSucceeded } from 'utils/clients';

type GetUsersSubscriptionsParams = {
  projectId?: number;
  userId?: number;
  enabled?: boolean;
};

const useGetUsersSubscriptions = ({ projectId, userId, enabled = false }: GetUsersSubscriptionsParams) => {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userSubscriptionTypes', projectId],
    queryFn: async () => {
      const result = await fetchUsersProjectsSubscriptions({
        projectId,
        userId
      });
      ensureRequestSucceeded(result);
      return result;
    },
    cacheTime: Infinity,
    onError: e => notifyError(`Error while fetching Subscription Types: ${ (e as Error).message }`),
    enabled
  });
  return {
    data: queryResult.data?.data,
    refetch: queryResult.refetch,
    subscriptionsFetching: !queryResult.isFetched
  };
};

enum SubscriptionUpdateMethod {
  PUT,
  POST,
  DELETE
}

const useUpdateUserSubscriptions = () => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isUpdating, setIsUpdating] = useState(false);
  const updateUsersSubscriptions = useCallback(async (newSubscriptionTypes: ChangedSubscriptionType[]) => {
    setIsUpdating(true);
    try {
      await Promise.all(
        newSubscriptionTypes.map(async (newSub) => {
          if(newSub.method === SubscriptionUpdateMethod.PUT) {
            return await updateUserProjectSubscription({
              subscriptionId: newSub.subscriptionId,
              period: newSub.period
            });
          } else if (newSub.method === SubscriptionUpdateMethod.POST) {
            return await createUserProjectSubscription({
              projectId: newSub.projectId,
              ownerId: newSub.ownerId,
              userId: newSub.userId,
              period: newSub.period
            });
          } else if (newSub.method === SubscriptionUpdateMethod.DELETE) {
            return await deleteUserProjectSubscription({
              subscriptionId: newSub.subscriptionId,
            });
          }
        })
      );

      notifySuccess(`Subscription Types have been updated`);
    } catch(e) {
      notifyError(`Error while updating Subcription Types`);
    } finally {
      setIsUpdating(false);
    }
  }, [notifySuccess, notifyError]);

  return {
    isUpdating,
    updateUsersSubscriptions
  };
};

type ChangedSubscriptionType = {
  method: SubscriptionUpdateMethod;
  projectId?: number;
  ownerId?: number;
  userId?: number;
  subscriptionId?: number;
  period?: SubscriptionType;
}

const subsSortAlg = (a: SubscriptionParams, b: SubscriptionParams) => {
  if(a.type < b.type) { return -1;}
  else { return 1; }
};
// @TODO refactor PUT DEL POST callbacks and logics behind them BNIV-2265
// if the amount of current subscriptions remained the same - we should update existing ones with method PUT
const addPUTcallback = (curSubs: SubscriptionParams[], newSubs: SubscriptionParams[], callback: (curSub: SubscriptionParams, newSub: SubscriptionParams) => void) => {
  if((curSubs.length === newSubs.length) && (curSubs.length > 0 && newSubs.length > 0)) {
    newSubs.forEach((newSub, index) => {
      // if we have new subscription that is unique we should use method PUT
      const isNewSubUnique = curSubs.filter(curSub => curSub.type === newSub.type).length === 0;
      if(isNewSubUnique) {
        callback(curSubs[index], newSub);
      }
    });
  }
};
// if the amount of current subscriptions is lower or higher than new subscriptions we should POST new ones and DELETE old ones that might have been removed
const addDELcallback = (curSubs: SubscriptionParams[], newSubs: SubscriptionParams[], callback: (curSub: SubscriptionParams) => void) => {
  if(curSubs.length !== newSubs.length) {
    curSubs.forEach(curSub => {
    // finding unique subscriptions in current user's subscriptions that should be deleted
      const isCurSubUnique = newSubs.filter(newSub => newSub.type === curSub.type).length === 0;
      if(isCurSubUnique) {
        callback(curSub);
      }
    });
  }
};
const addPOSTcallback = (curSubs: SubscriptionParams[], newSubs: SubscriptionParams[], callback: (newSub: SubscriptionParams) => void) => {
  if(curSubs.length !== newSubs.length) {
    newSubs.forEach(newSub => {
    // finding unique new subscriptions that should be created
      const isNewSubUnique = curSubs.filter(curSub => curSub.type === newSub.type).length === 0;
      if(isNewSubUnique) {
        callback(newSub);
      }
    });
  }
};

const useSmartUsersSubscriptionsManager = () => {
  const { updateUsersSubscriptions } = useUpdateUserSubscriptions();
  const changedSubscriptions:ChangedSubscriptionType[] = [];

  const updateSubscriptions = async (curState: UserWithSubscriptionType[], newState: UserWithSubscriptionType[]) => {
    curState.forEach(curUser => {
      const newStateUser = newState.filter(u => u.id === curUser.id)[0];
      const curSubs = curUser.ProjectSubscriptions.sort(subsSortAlg);
      const newSubs = newStateUser.ProjectSubscriptions.sort(subsSortAlg);

      addPUTcallback(curSubs, newSubs, (curSub, newSub) => {
        changedSubscriptions.push({
          method: SubscriptionUpdateMethod.PUT,
          subscriptionId: curSub.subscriptionId,
          period: newSub.type
        });
      });

      addPOSTcallback(curSubs, newSubs, (newSub) => {
        changedSubscriptions.push({
          method: SubscriptionUpdateMethod.POST,
          projectId: newSub.projectId,
          ownerId: newSub.ownerId,
          userId: newSub.userId,
          period: newSub.type
        });
      });

      addDELcallback(curSubs, newSubs, (curSub) => {
        changedSubscriptions.push({
          method: SubscriptionUpdateMethod.DELETE,
          subscriptionId: curSub.subscriptionId,
        });
      });
    });

    return await updateUsersSubscriptions(changedSubscriptions);
  };


  return {
    updateSubscriptions
  };
};

export const useProjectSubscriptions = (props: GetUsersSubscriptionsParams) => {
  const { notifyError } = useFormActionNotifier();
  const [isLoading, setIsLoading] = useState(false);
  const { data, refetch, subscriptionsFetching } = useGetUsersSubscriptions(props);
  const { updateSubscriptions } =  useSmartUsersSubscriptionsManager();

  useEffectOnce(() => {
    refetch().catch((e) => {
      notifyError(`Error while fetching Subscription Types: ${ (e as Error).message }`);
    });
  });

  const handleProjectSubscriptionsSave = async (users: UserWithSubscriptionType[], newUsersState: UserWithSubscriptionType[]) => {
    setIsLoading(true);
    await updateSubscriptions(users, newUsersState);
    await refetch();
    setIsLoading(false);
  };

  return {
    data,
    refetch,
    isLoading: subscriptionsFetching || isLoading,
    onSave: handleProjectSubscriptionsSave
  };
};
