import { useLabels } from 'hooks/device-management';
import { Label } from 'models/device-management';

// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';
import { DmLabel } from 'components/DmLabels';

type Props = (
  & Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & {
    filter?: (label: Label) => boolean,
    ownerId?: number
  }
);

const LabelSelectControl = (props: Props): JSX.Element => {
  const { filter, ownerId } = props;

  const labelsQuery = useLabels({
    params: { owner: ownerId, limit: 2000, offset: 0 },
  });

  const filterCallback = filter ?? (() => (true));
  const options: Option<number>[] = (labelsQuery.labels ?? [])
    .filter(filterCallback)
    .map(label => {
      return {
        value: label.id,
        label: <DmLabel className={ 'selected-label' } label={ label }/>,
      };
    });

  return (
    <div className="hello">
      <SelectControl
        name="labelIds"
        label="Labels"
        closeMenuOnSelect={ !props.isMulti }
        { ...props }
        options={ options }
        isLoading={ labelsQuery.isLoading }
        checkSelected={ options.length > 0 }
        isDisabled={ props.isDisabled || !options.length }
      />
    </div>
  );
};

export default LabelSelectControl;

