import { ShipmentStatusEnum } from '../generated';

export enum ShipmentFiltersFields {
  device_id = 'device_id',
  box_id = 'box_id',
  shipment_id = 'shipment_id',
  shipment_status = 'shipment_status',
  owner_id = 'owner_id',
  shipper_id = 'shipper_id',
  recipient_id = 'recipient_id',
}

export interface ShipmentFilters {
  [ShipmentFiltersFields.device_id]: string[];
  [ShipmentFiltersFields.box_id]: number[];
  [ShipmentFiltersFields.shipment_id]: string[];
  [ShipmentFiltersFields.shipment_status]: ShipmentStatusEnum[];
  [ShipmentFiltersFields.owner_id]: number[];
  [ShipmentFiltersFields.shipper_id]?: number;
  [ShipmentFiltersFields.recipient_id]?: number;
}

export interface ShipmentParams extends ShipmentFilters {
  limit: number;
  offset?: number;
}

export const initProvisioningShipmentFilters: ShipmentFilters = {
  [ShipmentFiltersFields.device_id]: [],
  [ShipmentFiltersFields.box_id]: [],
  [ShipmentFiltersFields.shipment_id]: [],
  [ShipmentFiltersFields.shipment_status]: [],
  [ShipmentFiltersFields.owner_id]: [],
  [ShipmentFiltersFields.shipper_id]: undefined,
  [ShipmentFiltersFields.recipient_id]: undefined,
};
