import { useHardwareTypesDictionary } from 'utils/dictionary';
// components
import { SelectControl } from 'components/Controls/Select';

interface Props {
  onChange: (hardwareTypes: string[] | undefined) => void;
  selected: string[];
}

export const HardwareTypeSelectControl = (props: Props): JSX.Element => {
  const hardwareTypes = useHardwareTypesDictionary();

  const options = hardwareTypes.map(hardwareType => {
    return { label: hardwareType, value: hardwareType };
  });

  return (
    <SelectControl
      isClearable
      isMulti
      closeMenuOnSelect={ false }
      label="Hardware Type"
      name="hardwareTypes"
      selected={ props.selected }
      options={ options }
      onChange={ props.onChange }
    />
  );
};
