import { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  UserPayload,
  DoSetUserData,
  DoGetUserData,
  DoDestroyUserSession,
  DoStartBrowserSession,
  DoStopBrowserSession
} from 'actions/user-profile';
import { Auth, Hub, I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { Events, hideDefaultAmplifyToast, parseError } from 'utils/amplify';
import { NotifyError } from 'actions/notifier';
import { clearLocationParams } from 'utils/routing/query';
import { useAuthUserSelector }from 'hooks/user';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

// Overwrite error messages
I18n.putVocabularies({
  en: {
    '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$': 'Password requirements:' +
      '\n- 1 number at least\n- 1 special character at least\n- 1 uppercase letter at least\n- 1 lowercase letter at least\n- minimum length 8',
  },
});

type TSignUpAttributes = {
  'custom:client_access_code': string;
  'custom:first_name': string;
  'custom:last_name': string;
  'email': string;
  'custom:recaptchaToken': string;
}

type TUserCredentials = {
  username: string;
  password: string;
}

type TSignUpParams = TUserCredentials & {
  attributes: TSignUpAttributes;
}

export function useAmplifyAuth() {
  const dispatch = useDispatch();
  const signedInRef = useRef(false);
  const { signedIn } = useAuthUserSelector();

  const signInUser = (user: UserPayload) => dispatch(DoSetUserData(user));
  const getAuthUser = () => dispatch(DoGetUserData());
  const startBrowserSession = useCallback(() => dispatch(DoStartBrowserSession()), [dispatch]);
  const stopBrowserSession = () => dispatch(DoStopBrowserSession());
  const destroySession = () => dispatch(DoDestroyUserSession());
  const handleSignIn = async (formData: TUserCredentials) => Auth.signIn({
    username: formData.username.trim(),
    password: formData.password,
  });
  const handleSignUp = async (formData: TSignUpParams, recaptchaToken: string) => Auth.signUp({
    username: formData.attributes.email.toLowerCase(),
    password: formData.password,
    attributes: {
      ...formData.attributes,
      'custom:recaptchaToken': recaptchaToken,
    }
  });

  Hub.listen(/.*/, (data) => {
    const { payload } = data;
    switch (payload.event) {
      case Events.signIn:
        signInUser(payload.data.signInUserSession.idToken.payload);
        break;
      case Events.signOut:
        stopBrowserSession();
        clearLocationParams();
        destroySession();
        break;
      case Events.configured:
        hideDefaultAmplifyToast();
        break;
      case Events.authError:
        const error = parseError(payload);
        dispatch(NotifyError(error));
        break;
    }
  });

  if (!signedInRef.current) {
    getAuthUser();
    signedInRef.current = true;
  }

  useEffect(() => {
    if (signedIn) {
      startBrowserSession();
    }
  }, [startBrowserSession, signedIn]);

  return {
    signedIn,
    handleSignIn,
    handleSignUp,
  };
}
