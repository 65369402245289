import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

import { Permissions } from 'models/user-management';
import { isAllowedToCreateUser, isAllowedToDeleteUser } from 'utils/permissions';

// components
import CreatePermissions, { CreatePermissionsProps } from './CreatePermissions';
import DeletePermissions from './DeletePermissions';

interface TemplatePermissionsActionsProps {
  selectedPermissions?: Permissions;
  editedUserGroupId: number;
  renderAction?: (element: React.ReactElement, index: number) => React.ReactNode;

  permissionsCreatorDefaultPermissions?: CreatePermissionsProps['defaultPermissions'];
  onPermissionsCreate?: CreatePermissionsProps['onPermissionsCreate'];
}

const TemplatePermissionsActions: React.FC<TemplatePermissionsActionsProps> = ({
  renderAction = x => x,
  ...props
}) => {
  const allowedToCreatePermissions = useSelector((state: RootState) => isAllowedToCreateUser(state.user.data));
  const allowedToDeletePermissions = useSelector((state: RootState) => isAllowedToDeleteUser(state.user.data));

  const actions: React.ReactElement[] = [];

  if (allowedToCreatePermissions) {
    actions.push(
      <CreatePermissions
        key="create"
        defaultPermissions={props.permissionsCreatorDefaultPermissions}
        editedUserGroupId={props.editedUserGroupId}
        onPermissionsCreate={props.onPermissionsCreate}
      />,
    );
  }

  if (allowedToDeletePermissions && props.selectedPermissions) {
    actions.push(<DeletePermissions key="delete" permissions={props.selectedPermissions} />);
  }

  return (
    <>
      {actions.map(renderAction)}
    </>
  );
};

export default TemplatePermissionsActions;
