import React from 'react';
import { Helmet } from 'react-helmet-async';
import { TemplateSuiteId } from 'models/caller';
import { useTemplateSuiteSelector, useTemplateLoadSelectorByParams } from 'hooks/caller';
// componetns
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { CallerTemplateSuiteClone } from 'components/Caller';
import Navigation from './Navigation';
// styles
import { useStyles } from './styles';

interface Props {
  templateSuiteId: TemplateSuiteId;
}

const Component = ({ templateSuiteId }: Props): JSX.Element => {
  const classes = useStyles();
  const { templateSuite } = useTemplateSuiteSelector(templateSuiteId);
  const { templates } = useTemplateLoadSelectorByParams({ templateSuiteId });
  return (
    <Box className={ classes.root }>
      <Helmet><title>{ `Http Template Suite #${ templateSuiteId } ${ templateSuite && templateSuite.name } Clone` }</title></Helmet>
      <Navigation templateSuite={ templateSuite }/>
      {
        !templateSuite
          ? <Skeleton variant="rect"/>
          : <CallerTemplateSuiteClone templateSuite={ templateSuite } templates={ templates }/>
      }
    </Box>
  );
};

export default Component;
