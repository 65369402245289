import { UserGroup } from 'models/user-management';
import { FormField } from './types';
import { FormErrors } from 'form';
import { validatePhoneNumber } from 'utils/validator';

export const validate = (userGroup: UserGroup, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.name)) {
    if (!userGroup.name || !userGroup.name.trim()) {
      errors.name = 'Client name is required';
    }
  }

  if (fields.includes(FormField.phone_number)) {
    if (userGroup.phone_number && !validatePhoneNumber(userGroup.phone_number)) {
      errors.phone_number = 'Invalid phone number';
    }
  }

  return errors;
};
