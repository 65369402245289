import { DeviceConnectivity } from 'models/connectivity/deviceConnectivity';
import { RootState as State } from 'reducers';

function getConnectivityState(state: State) {
  return state.connectivity;
}

export function areDevicesConnectivityLoading(state: State): boolean {
  const devicesState = getConnectivityState(state);
  return devicesState.isFetching;
}

export function getDeviceConnectivity(
  deviceId: DeviceConnectivity['device_id'],
  state: State,
): DeviceConnectivity | null | undefined {
  const devicesState = getConnectivityState(state);
  return devicesState.devicesConnectivityByDeviceId[deviceId];
}
