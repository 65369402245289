// Ignore indentation of type parameters until the support is provided
/* eslint-disable @typescript-eslint/indent */
import React from 'react';

import { createField, createFieldFromRegularInputWithError, InputProps } from 'components/Form/Field/field-creators';
import { formatErrors } from 'components/Form/Field/utils';

// components
import { ZoneSelectControl, GroupSelectControl, LevelSelect } from 'components/Controls';
import SelectPositionGroupTypeInput from 'pages/DeviceManagement/PositionGroup/SelectPositionGroupType';

type SelectZoneInputInnerProps = (
  & React.ComponentPropsWithoutRef<typeof ZoneSelectControl>
  & InputProps
);
type SelectZoneInputProps = Omit<SelectZoneInputInnerProps, 'selected'>;

export const SelectZone = createField<SelectZoneInputProps, SelectZoneInputProps['onChange']>(props => (
  <ZoneSelectControl
    { ...props }
    selected={ props.value }
    error={ formatErrors(props.errors) }
  />
));

type SelectPositionGroupInputInnerProps = (
  & React.ComponentPropsWithoutRef<typeof GroupSelectControl>
  & InputProps
);
type SelectPositionGroupInputProps = Omit<SelectPositionGroupInputInnerProps, 'selected'>;

export const SelectPositionGroup = createField<SelectPositionGroupInputProps, SelectPositionGroupInputProps['onChange']>(props => (
  <GroupSelectControl
    { ...props }
    selected={ props.value }
    error={ formatErrors(props.errors) }
  />
));

export const SelectPositionGroupType = createFieldFromRegularInputWithError(
  SelectPositionGroupTypeInput,
);

type SelectLevelInputInnerProps = (
  & React.ComponentPropsWithoutRef<typeof LevelSelect>
  & InputProps
);
type SelectLevelInputProps = Omit<SelectLevelInputInnerProps, 'selected'>;

export const SelectLevel = createField<SelectLevelInputProps, SelectLevelInputProps['onChange']>(props => (
  <LevelSelect
    { ...props }
    selected={ props.value }
    error={ formatErrors(props.errors) }
  />
));
