/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Position group option field name
 * @export
 * @enum {string}
 */
export enum GroupField {
    GroupType = 'group_type',
    GroupName = 'group_name',
    GroupId = 'group_id',
    LevelId = 'level_id',
    LevelName = 'level_name',
    FloorNumber = 'floor_number'
}

