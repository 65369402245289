import React, { useState, FormEvent } from 'react';
import { useFormActionLoader } from 'hooks';
import { User } from 'models/user-management';
import { DoChangePasswordForAnotherUser } from 'actions/user-management/users';
import { usePasswordValidation } from 'utils/validator';

// components
import { SuccessButton } from 'components/Buttons';
import { TextControl } from 'components/Controls';
import { Dialog, DialogActions, DialogContent, Button, Box, Typography, DialogTitle, Checkbox, FormControlLabel } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
  userSub: User['sub'];
}

type Event = React.ChangeEvent<{ value: unknown }>;

export const ChangeUserPasswordModal = ({ isOpen, setDialogOpen, userSub }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const [error, setError] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatedPassword, setRepeatedPassword] = useState<string>('');
  const [permanent, setPermanent] = useState(true);

  const { errorMatchPassword, validationErrors } = usePasswordValidation(newPassword, repeatedPassword);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');

    doAction({
      action: DoChangePasswordForAnotherUser(newPassword, permanent, userSub),
      onError: error => setError(String(error)),
      onSuccess: () => setDialogOpen(false),
    });
  };

  const handleSetNewPasswordInput = (event: Event) => setNewPassword(event.target.value as string);
  const handleSetRepeatedPasswordInput = (event: Event) => setRepeatedPassword(event.target.value as string);

  return (
    <Dialog
      open={ isOpen }
      onClose={ () => setDialogOpen(false) }
      maxWidth={ 'sm' }
    >
      <DialogContent>
        <form noValidate autoComplete="off" onSubmit={ handleSubmit }>
          <DialogTitle>Change user password</DialogTitle>
          <Box mt={ 2 } ml={ 2 } mr={ 2 }>
            <TextControl
              value={ newPassword }
              autoFocus={ true }
              placeholder={ `New password` }
              onChange={ handleSetNewPasswordInput }
              error={ Boolean(errorMatchPassword || validationErrors.length) }
              type="password"
              required
            />
            <TextControl
              value={ repeatedPassword }
              placeholder={ `Repeat new password` }
              onChange={ handleSetRepeatedPasswordInput }
              error={ Boolean(errorMatchPassword || validationErrors.length) }
              type="password"
              required
            />
            <FormControlLabel
              control={
                <Checkbox checked={ permanent } onChange={ e => setPermanent(e.target.checked) } name="permanent" />
              }
              label="Permanent password"
            />
          </Box>
          <Box mt={ 2 } ml={ 2 } mr={ 2 }>
            <Typography color="error">
              { errorMatchPassword }
              { error }
            </Typography>
            { validationErrors.map((e, i) => <Typography color="error" key={ i }>{ e }</Typography>) }
          </Box>
          <DialogActions>
            <Button color="inherit" onClick={ () => setDialogOpen(false) }>
              Cancel
            </Button>
            <SuccessButton
              label="Save"
              disabled={ !newPassword || !repeatedPassword || !!errorMatchPassword || !!validationErrors.length }
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
