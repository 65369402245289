import { ComponentCreator } from '@material-ui/core/styles';
import { styled as muiStyled } from '@material-ui/core/styles'; // eslint-disable-line no-restricted-imports

/**
 * The same as Material UI's styled() but with `index: 1` option passed by default
 */
export function styled<TComponent extends React.ElementType>(
  component: TComponent,
): ComponentCreator<TComponent> {
  const componentCreator = muiStyled<TComponent>(component);

  return (styles, options) => {
    return componentCreator(styles, { index: 1, ...options });
  };
}
