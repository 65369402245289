import { API } from 'aws-amplify';
import { config } from './config';
import { errorHandler, formatQuery } from 'clients/utils';
import { WarrantyFiltersWithLimit, ResponseWarranties, ResponseWarranty, WarrantyCreateBody } from 'models/provisioning';

const urls = {
  warranties: '/provisioning/warranties',
  warrantyById: (id: string) => `/provisioning/warranties/${id}`,
  getDeviceWarrantyRules: (deviceId: string) => `/provisioning/warranties/devices/${deviceId}`,
};

export const fetchWarranties = (params: WarrantyFiltersWithLimit): Promise<ResponseWarranties> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      device_id: params.id,
    }),
  };

  return API
    .get(config.name, urls.warranties, body)
    .catch(errorHandler);
};

export const getWarrantyById = (id: string): Promise<ResponseWarranty> => {
  return API
    .get(config.name, urls.warrantyById(id), {})
    .catch(errorHandler);
};

export const createWarranty = (warranty: WarrantyCreateBody): Promise<ResponseWarranty> => {
  return API
    .post(config.name, urls.warranties, { body: warranty })
    .catch(errorHandler);
};
