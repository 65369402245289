import { FormField, FormState } from './types';
import { FormErrors } from 'types/form';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.software)) {
    if (!state.software) {
      errors.software = 'Firmware is required';
    }
  }

  return errors;
};
