import React from 'react';
import { NotificationMethod, ResponsiblePerson, StationLocation } from 'models/base-station';
import { useAuthUserSelector } from 'hooks';
import { useForm, useFormActionLoader } from 'hooks/form';
import { AddLocationForResponsiblePerson, CreateStationResponsiblePerson } from 'actions/base-station';
import { canViewOwner } from 'utils/permissions';
import { useAvailableResponsiblePerson } from '../utils';

// components
import { CommonDialog } from 'components/Dialogs';
import { ResponsiblePersonFormField, StationResponsiblePersonForm } from 'components/BaseStation';
import { Button, List, ListItem, ListItemText, Tab, Tabs } from '@material-ui/core';
// styles
import useStyles from 'styles/dialogs';
import useStylesTabs from 'styles/tabs';

interface Props {
  location: StationLocation;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

const initialPerson: ResponsiblePerson = {
  id: 0,
  first_name: '',
  last_name: '',
  owner_id: 0,
  role: '',
  notify: true,
  notification_method: NotificationMethod.Email,
  notification_endpoint: { email: '' },
  registered_user_id: null,
  locations: [],
};

enum FormTab {
  ADD,
  SET
}

export const AddPersonDialog = ({ location, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const classes = useStyles({});
  const classesTabs = useStylesTabs({});
  const { doAction } = useFormActionLoader();
  const user = useAuthUserSelector();
  const [activeTab, setActiveTab] = React.useState<FormTab>(FormTab.SET);
  const persons = useAvailableResponsiblePerson(location);
  const [selected, onSelect] = React.useState<ResponsiblePerson | undefined>(undefined);
  const form = useForm<ResponsiblePerson, ResponsiblePersonFormField>({
    initialState: { ...initialPerson, owner_id: Number(location.owner_id), locations: [location] },
    showFields: [
      ...(canViewOwner(user) ? [ResponsiblePersonFormField.ownerId] : []),
      ResponsiblePersonFormField.firstName,
      ResponsiblePersonFormField.lastName,
      ResponsiblePersonFormField.role,
      ResponsiblePersonFormField.email,
      ResponsiblePersonFormField.notify,
    ]
  });

  const handleSaveForm = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateStationResponsiblePerson(form.state),
      onSuccess: (person) => doAction({
        action: AddLocationForResponsiblePerson(location.id, person.id),
        onError: form.catchError,
        onSuccess: () => onClose(),
      }),
      onError: form.catchError,
    });
  };

  const handleSetPerson = () => {
    if (!selected) {
      return false;
    }

    if (selected.id === 0) {
      doAction({
        action: CreateStationResponsiblePerson(selected),
        onSuccess: (person) => doAction({
          action: AddLocationForResponsiblePerson(location.id, person.id),
          onSuccess: () => onClose(),
        }),
        onError: form.catchError,
      });
    } else {
      doAction({
        action: AddLocationForResponsiblePerson(location.id, selected.id),
        onSuccess: () => onClose(),
      });
    }
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ 'Add Responsible Person' }
      content={
        <>
          <Tabs
            centered
            variant="fullWidth"
            value={ activeTab }
            onChange={ (_, value) => setActiveTab(value as FormTab) }
            className={ classesTabs.root }
          >
            <Tab label="Choose from existing" value={ FormTab.SET } className={ classes.dialogTabLabel } />
            <Tab label="Add new" value={ FormTab.ADD } className={ classes.dialogTabLabel } />
          </Tabs>
          { activeTab !== FormTab.ADD ? '' :
            <StationResponsiblePersonForm { ...form } />
          }
          { activeTab !== FormTab.SET ? '' :
            <List className={ classes.dialogListContent }>
              { persons.map(person => {
                return (
                  <ListItem
                    key={ person.registered_user_id || String(person.id) }
                    button
                    selected={ selected?.registered_user_id
                      ? selected?.registered_user_id === person.registered_user_id
                      : selected?.id === person.id
                    }
                    onClick={ () => onSelect(person) }
                  >
                    <ListItemText
                      primary={
                        <>
                          {`${person.first_name} ${person.last_name}` }
                          { person.userOwner ? ` (${person.userOwner})` : '' }
                        </>
                      }
                      secondary={
                        <>
                          { person.personOwner ? <>RP Owner: { person.personOwner } <br /></> : '' }
                          { person.notification_endpoint.email }<br />
                          { person.role }
                        </>
                      }
                    />
                  </ListItem>
                );
              }) }
            </List>
          }
        </>
      }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ () => activeTab === FormTab.ADD ? handleSaveForm() : handleSetPerson() }
          >
            { activeTab === FormTab.ADD ? 'Add' : 'Set' }
          </Button>
        </>
      }
    />
  );
};
