import { API } from 'aws-amplify';

import { errorHandler, formatQuery } from 'clients/utils';
import { ApiResponse } from 'models';
import { GetHistoryParamsQuery, MessageType } from 'models/caller';

import { config } from './config';

const urls = {
  history: '/calls/history',
  messageTypes: '/calls/message_types',
};

export const getHistory = (params: GetHistoryParamsQuery): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      owner_id: params.owner,
      zone_id: params.zones,
      group_id: params.groupIds,
      device_id: params.deviceIds,
      template_id: params.templateIds,
      msg_trace_id: params.messageTraceId,
      sending_status: params.sendingStatus,
      sending_time_from: params.sendingTimeFrom?.toISOString(),
      sending_time_to: params.sendingTimeTo?.toISOString(),
      limit: params.limit ?? 200,
      offset: params.offset || 0,
    }),
  };

  return API
    .get(config.name, urls.history, body)
    .catch(errorHandler);
};

export const getMessageTypes = (): Promise<ApiResponse<MessageType[]>> => {
  return API
    .get(config.name, urls.messageTypes, {})
    .catch(errorHandler);
};
