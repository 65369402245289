import { ParkingAnomalyByZoneId, EditZoneDurationParkingAnomaly as DurationData, EditZoneParkingIgnoreAnomaly as IgnoreData } from 'models/parking-monitoring';

export const GET_BY_ZONE_ID_PARKING_ANOMALY = 'GET_BY_ZONE_ID_PARKING_ANOMALY';
export const GET_BY_ZONE_ID_PARKING_ANOMALY_SUCCESS= 'GET_BY_ZONE_ID_PARKING_ANOMALY_SUCCESS';
export const GET_BY_ZONE_ID_PARKING_ANOMALY_FAILED = 'GET_BY_ZONE_ID_PARKING_ANOMALY_FAILED';

export const SET_ZONE_PARKING_ANOMALY_DURATION = 'SET_ZONE_PARKING_ANOMALY_DURATION';
export const SET_ZONE_PARKING_ANOMALY_IGNORE = 'SET_ZONE_PARKING_ANOMALY_IGNORE';
export const SET_ZONE_PARKING_ANOMALY_SUCCESS = 'SET_ZONE_PARKING_ANOMALY_SUCCESS';

export const EDIT_ZONE_DURATION_PARKING_ANOMALY = 'EDIT_ZONE_DURATION_PARKING_ANOMALY';
export const EDIT_ZONE_DURATION_PARKING_ANOMALY_SUCCESS = 'EDIT_ZONE_DURATION_PARKING_ANOMALY_SUCCESS';

export const EDIT_ZONE_IGNORE_PARKING_ANOMALY = 'EDIT_ZONE_IGNORE_PARKING_ANOMALY';
export const EDIT_ZONE_IGNORE_PARKING_ANOMALY_SUCCESS = 'EDIT_ZONE_IGNORE_PARKING_ANOMALY_SUCCESS';

export interface GetByZoneIdParkingAnomaly {
  type: typeof GET_BY_ZONE_ID_PARKING_ANOMALY;
  id: number;
}
export interface GetByZoneIdParkingAnomalySuccess {
  type: typeof GET_BY_ZONE_ID_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByZoneId;
}
export interface GetByZoneIdParkingAnomalyFailed {
  type: typeof GET_BY_ZONE_ID_PARKING_ANOMALY_FAILED;
}

export interface SetZoneParkingAnomalyDuration {
  type: typeof SET_ZONE_PARKING_ANOMALY_DURATION;
  payload: DurationData;
}

export interface SetZoneParkingAnomalyIgnore {
  type: typeof SET_ZONE_PARKING_ANOMALY_IGNORE;
  payload: IgnoreData;
}

export interface EditZoneDurationParkingAnomaly {
  type: typeof EDIT_ZONE_DURATION_PARKING_ANOMALY;
  payload: DurationData;
}
export interface EditZoneDurationParkingAnomalySuccess {
  type: typeof EDIT_ZONE_DURATION_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByZoneId;
}

export interface EditZoneIgnoreParkingAnomaly {
  type: typeof EDIT_ZONE_IGNORE_PARKING_ANOMALY;
  payload: IgnoreData;
}
export interface EditZoneIgnoreParkingAnomalySuccess {
  type: typeof EDIT_ZONE_IGNORE_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByZoneId;
}

export const DoGetByZoneIdParkingAnomaly = (id: number): GetByZoneIdParkingAnomaly => ({
  type: GET_BY_ZONE_ID_PARKING_ANOMALY,
  id
});
export const DoGetByZoneIdParkingAnomalySuccess = (payload: ParkingAnomalyByZoneId): GetByZoneIdParkingAnomalySuccess => ({
  type: GET_BY_ZONE_ID_PARKING_ANOMALY_SUCCESS,
  payload
});
export const DoGetByZoneIdParkingAnomalyFailed = (): GetByZoneIdParkingAnomalyFailed => ({
  type: GET_BY_ZONE_ID_PARKING_ANOMALY_FAILED
});

export const DoSetZoneParkingAnomalyDuration = (payload: DurationData): SetZoneParkingAnomalyDuration => ({
  type: SET_ZONE_PARKING_ANOMALY_DURATION,
  payload
});

export const DoSetZoneParkingAnomalyIgnore = (payload: IgnoreData): SetZoneParkingAnomalyIgnore => ({
  type: SET_ZONE_PARKING_ANOMALY_IGNORE,
  payload
});

export const DoEditZoneDurationParkingAnomaly = (payload: DurationData ): EditZoneDurationParkingAnomaly => ({
  type: EDIT_ZONE_DURATION_PARKING_ANOMALY,
  payload
});
export const DoEditZoneDurationParkingAnomalySuccess = (payload: ParkingAnomalyByZoneId): EditZoneDurationParkingAnomalySuccess => ({
  type: EDIT_ZONE_DURATION_PARKING_ANOMALY_SUCCESS,
  payload
});
export const DoEditZoneIgnoreParkingAnomaly = (payload: IgnoreData ): EditZoneIgnoreParkingAnomaly => ({
  type: EDIT_ZONE_IGNORE_PARKING_ANOMALY,
  payload
});
export const DoEditZoneIgnoreParkingAnomalySuccess = (payload: ParkingAnomalyByZoneId): EditZoneIgnoreParkingAnomalySuccess => ({
  type: EDIT_ZONE_IGNORE_PARKING_ANOMALY_SUCCESS,
  payload
});

export type Action =
| GetByZoneIdParkingAnomaly
| GetByZoneIdParkingAnomalySuccess
| GetByZoneIdParkingAnomalyFailed
| SetZoneParkingAnomalyDuration
| SetZoneParkingAnomalyIgnore
| EditZoneDurationParkingAnomaly
| EditZoneDurationParkingAnomalySuccess
| EditZoneIgnoreParkingAnomaly
| EditZoneIgnoreParkingAnomalySuccess;
