import { uniq } from 'lodash';

import { Level } from 'models/device-management';
import { fetchLevels } from '../../api';
import { DmApiFilters } from '../../types';
import { GroupNode, LevelNode } from '../../types/nodes';
import { DEFAULT_CHILDREN_LIMIT } from './shared';
import { createQuery } from './queries';

export interface LevelNodesData {
  nodes: LevelNode[];
  total: number;
}

export async function createLevelNodes(
  filters: DmApiFilters,
  groupNodes: GroupNode[] = []
): Promise<LevelNodesData> {
  const groupLevelIds = uniq(
    groupNodes.flatMap((gn) => (gn.group.level_id ? [gn.group.level_id] : []))
  );
  const { levels, total } = await fetchLevels({
    ...filters,
    levels: filters.levels?.flatMap(l => typeof l === 'number' ? [l] : []),
    ...(groupLevelIds.length && { levels: groupLevelIds })
  });
  const levelNodes = levels.map((level) =>
    createLevelNode(
      level,
      groupNodes
        .filter((gn) => gn.group.level_id === level.id)
        .map((gn) => gn.group.id)
    )
  );

  const groupNodesWithoutLevel = groupNodes.filter((gn) => !gn.group.level_id);
  const noLevelNodes = createNoLevelNodes(filters, groupNodesWithoutLevel);

  return {
    nodes: [...levelNodes, ...noLevelNodes],
    total: total + noLevelNodes.length
  };
}

/**
 * TODO: don't create No Level nodes if there're no children group nodes without level
 * and `filters.levels` isn't an empty array
 */
function createNoLevelNodes(
  filters: DmApiFilters,
  groupNodesWithoutLevel: GroupNode[] = []
): LevelNode[] {

  const zoneIdsOfGroupsWithoutLevel = groupNodesWithoutLevel.map((gn) => gn.group.zone_id);
  const zoneIds = uniq([...(filters.zones ?? []), ...zoneIdsOfGroupsWithoutLevel]);

  const noLevelNodes = zoneIds.map((zoneId) =>
    createNoLevelNode(
      zoneId,
      groupNodesWithoutLevel
        .filter(gn => gn.group.zone_id === zoneId)
        .map(gn => gn.group.id)
    )
  );

  return noLevelNodes;
}

function createLevelNode(level: Level, groupIds?: number[]): LevelNode {
  return {
    id: `level-${level.id}`,
    type: 'level',
    isNoLevelNode: false,
    zoneId: level.zone_id,
    level,
    levelLoadStatus: 'success',
    childrenFilters: {
      levels: [level.id],
      ...(groupIds?.length && { groups: groupIds })
    },
    childrenLimit: groupIds?.length || DEFAULT_CHILDREN_LIMIT,
    children: [],
    childrenLoadStatus: 'idle',
    childrenMoreLoadStatus: 'idle',
    expansionStatus: 'collapsed',
    childrenTotal: createQuery(),
    connectivity: createQuery(),
    totalDevices: createQuery(),
    totalPositions: createQuery(),
  };
}

function createNoLevelNode(zoneId: number, groupIds?: number[]): LevelNode {
  return {
    id: `no-level-in-zone-${zoneId}`,
    type: 'level',
    isNoLevelNode: true,
    zoneId,
    level: undefined,
    levelLoadStatus: 'success',
    childrenFilters: {
      levels: ['NONE'],
      ...(groupIds?.length && { groups: groupIds })
    },
    childrenLimit: groupIds?.length || DEFAULT_CHILDREN_LIMIT,
    children: [],
    childrenLoadStatus: 'idle',
    childrenMoreLoadStatus: 'idle',
    expansionStatus: 'collapsed',
    childrenTotal: createQuery(),
    connectivity: createQuery(),
    totalDevices: createQuery(),
    totalPositions: createQuery(),
  };
}
