import moment, { Moment } from 'moment';
import { Datum, Serie } from '@nivo/line';

import { UptimeHistory, OnlineHistory, ConnectionHistoryElement, DateParams } from 'models/base-station-status';

export interface DataWithMaxValue {
  data: Serie[];
  maxValue: number;
}

export interface HistoryData {
  date: Moment;
  value: number | null;
}

const DATE_WITH_MINUTES = 'YYYY-MM-DD HH:mm';

type ServerHistoryItem = UptimeHistory | OnlineHistory | ConnectionHistoryElement;

export const prepareHistory = (
  data: Array<ServerHistoryItem>,
): HistoryData[] => {
  const history = data.flatMap(item => {
    const event = mapServerHistoryItemToEvent(item);
    return event ? [event] : [];
  });

  sortHistory(history);

  return history;
};

function mapServerHistoryItemToEvent(item: ServerHistoryItem): HistoryData | undefined {
  if (!item.timestamp) {
    return;
  }

  return {
    date: moment(item.timestamp),
    value: typeof item.value === 'boolean' ? Number(item.value) : item.value,
  };
}

/**
 * Sort history entries from earliest to latest date.
 */
export function sortHistory(history: HistoryData[]): void {
  history.sort((a, b) => a.date.valueOf() - b.date.valueOf());
}

interface ChartData extends DataWithMaxValue {
  minValue: number;
}

export const chartDataWithoutDecimation = (
  preparedData: HistoryData[],
  id: string,
): ChartData => {
  const chartPoints: Datum[] = preparedData.map(point => ({
    x: point.date.format(DATE_WITH_MINUTES),
    y: point.value,
  }));

  const { max: maxValue, min: minValue } = calculateChartMinMaxValues(preparedData);

  return {
    data: [{ id, data: chartPoints }],
    maxValue,
    minValue,
  };
};

export function calculateChartMinMaxValues(history: HistoryData[]) {
  const nonEmptyValues = [...new Set(
    history.flatMap(event => event.value === null ? [] : [event.value]),
  )];

  const max = nonEmptyValues.length ? Math.max(...nonEmptyValues) : 1;
  const min = nonEmptyValues.length ? Math.min(...nonEmptyValues) : 0;

  return {
    max: max === min ? max + 0.01 : max,
    min: min === max ? min - 0.01 : min,
  };
}

export function getDurationDays(params?: DateParams): number | undefined {
  if (!params) {
    return;
  }

  return moment.duration(moment(params.dateTo).diff(params.dateFrom)).asDays();
}
