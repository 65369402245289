import { fetchDevices } from 'clients/device-management';
import { ResponseReason, ResponsePositionedDevicesWithTotal } from 'models/device-management';

interface BoundDeviceIdFetcherParams {
  positionId?: number;
  networkId?: string;
}

export async function fetchBoundDeviceId(
  params: BoundDeviceIdFetcherParams,
): Promise<string | null> {
  function mapDevicesResponseToDeviceId(res: ResponsePositionedDevicesWithTotal) {
    if (res.reason === ResponseReason.Ok) {
      return res.data[0]?.device_id ?? null;
    }

    throw new Error(`Fetching the bound device's ID: ${res.message}`);
  }

  const { positionId, networkId } = params;

  if (positionId) {
    return fetchDevices({ positions: [positionId] })
      .then(mapDevicesResponseToDeviceId);
  }

  if (networkId) {
    return fetchDevices({ networkIds: [networkId] })
      .then(mapDevicesResponseToDeviceId);
  }

  throw new Error(
    `[Fetching the bound device's id] Insufficient parameters: ` +
      'Expected params to contain non-empty positionId or networkId'
  );
}
