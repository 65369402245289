import React from 'react';

import { PaginationParams } from 'models';

// components
import { PaginationFooter } from 'components/Table';

interface TreeTableFooterProps {
  total?: number;
  pagination: PaginationParams;
  onPaginationChange: (nextPagination: PaginationParams) => void;
}

export const TreeTableFooter = ({ total, pagination, onPaginationChange }: TreeTableFooterProps) => {
  return (
    <PaginationFooter
      count={ total ?? 0 }
      page={ pagination.offset / pagination.limit }
      rowsPerPage={ pagination.limit }
      rowsPerPageOptions={ [pagination.limit] }
      onChangePage={ rowsPerPage => onPaginationChange({
        limit: rowsPerPage,
        offset: pagination.offset,
      }) }
      onChangeRowsPerPage={ page => onPaginationChange({
        limit: pagination.limit,
        offset: page * pagination.limit,
      }) }
    />
  );
};
