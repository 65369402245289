import { createStyles, makeStyles } from 'styles/utils';

export const useStyles = makeStyles(() =>
  createStyles({
    subMenuLink: {
      fontWeight: 500,
      fontSize: 11,
    }
  }),
);
