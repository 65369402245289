import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { BoxFormField, Box as ProvisioningBox, BoxCreateBody } from 'models/provisioning';

// components
import { Box } from '@material-ui/core';
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';
import { ShipmentsSelector } from 'components/Provisioning/Controls/ShipmentsSelector';

// styles
import { useStyles } from './styles';

type FormValues = ProvisioningBox & BoxCreateBody;

export function BoxForm<T extends ProvisioningBox | BoxCreateBody>(props: FormProps<T, BoxFormField>): JSX.Element {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends keyof FormValues>(field: TField, value: FormValues[TField]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!onChange || (state as any)[field] === value) {
      return false;
    }

    onChange({ ...state, [field]: value }, field as BoxFormField);
  }

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(BoxFormField.id) ? '' :
        <TextControl
          required={ false }
          disabled={ isView || disabledFields.includes(BoxFormField.id) }
          label="Box id"
          name="box[id]"
          value={ (state as ProvisioningBox).id || 0 }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(BoxFormField.id, Number(event.target.value) || 0)
          }
          error={ showErrors.includes(BoxFormField.id) ? errors?.id !== undefined : false }
          helperText={ showErrors.includes(BoxFormField.id) ? errors?.id : undefined }
        />
      }
      { !showFields.includes(BoxFormField.shipment_id) ? '' :
        <Box mt={ 1 }>
          <ShipmentsSelector
            label="Shipment"
            isDisabled={ isView || disabledFields.includes(BoxFormField.shipment_id) }
            name="box[shipment_id]"
            isMulti={ false }
            selected={ (state as ProvisioningBox).shipment_id || '' }
            error={ showErrors.includes(BoxFormField.shipment_id) && errors?.shipment_id }
            onChange={ id => handleChange(BoxFormField.shipment_id, id) }
          />
        </Box>
      }
      { !showFields.includes(BoxFormField.owner_id) ? '' :
        <Box mt={ 1 }>
          <OwnersSingleSelectControl
            label="Owner"
            isRequired={ false }
            isClearable={ false }
            isDisabled={ isView || disabledFields.includes(BoxFormField.owner_id) }
            name="box[owner]"
            selected={ state.owner_id }
            error={ showErrors.includes(BoxFormField.owner_id) && errors?.owner_id }
            changeHandler={ recipient => handleChange(BoxFormField.owner_id, recipient ?? 0) }
          />
        </Box>
      }
    </form >
  );
}
