import { MessageType, SendMessageField, SendMessageState } from './widgets/SendMessageForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useDeepCompareEffect } from 'react-use';
import { LoadPositionStateData } from 'actions/parking';
import { PositionedDeviceData } from 'models/device-management';
import { DeviceType, getDeviceType } from 'models/device-emulation';
import { dispatchAsync } from 'utils/store';
import { SendCalibrationMessage, SendRegistrationMessage, SendRegularCarCounterMessage, SendRegularMessage } from 'actions/device-emulation';

export const findDeviceType = (deviceId: string, devices: PositionedDeviceData[]): DeviceType => {
  const device = devices.find(d => d.device_id.toUpperCase() === deviceId.toUpperCase());

  return getDeviceType(device);
};

export const useNextOccupancyStatus = (state: SendMessageState, devices: PositionedDeviceData[]): [string, boolean] => {
  const { isFetching, data } = useSelector((state: RootState) => state.parking.positionState);
  const occupancy = data?.find(p => p.device_id.toUpperCase() === state[SendMessageField.device]?.toUpperCase())?.occupied;

  const dispatch = useDispatch();
  const deviceIds = devices.map(d => d.device_id);

  useDeepCompareEffect(() => {
    if (deviceIds.length) {
      dispatch(LoadPositionStateData({
        params: { deviceIds, limit: deviceIds.length },
        disableNotFoundWarning: true,
      }));
    }
  }, [dispatch, deviceIds]);

  const messageType = state[SendMessageField.messageType] as MessageType;
  const occupancyMessageTypes = [MessageType.USER_REGISTRATION, MessageType.OCCUPANCY_CHANGE];
  if (!occupancyMessageTypes.includes(messageType)) {
    return ['Not applicable to the message type', false];
  }

  if (isFetching) {
    return [`Loading...`, true];
  }

  if (occupancy === undefined) {
    return ['Occupied', false];
  }

  if (state[SendMessageField.messageType] === MessageType.USER_REGISTRATION) {
    return ['Occupied', false];
  }

  if (state[SendMessageField.messageType] === MessageType.OCCUPANCY_CHANGE) {
    return [occupancy ? 'Free' : 'Occupied', false];
  }

  return ['Not applicable to the message type', false];
};

export const getShowFieldsByState = (state: SendMessageState): SendMessageField[] => {
  const fields: SendMessageField[] = [
    SendMessageField.device,
    SendMessageField.messageType,
  ];
  switch (state[SendMessageField.messageType]) {
    case MessageType.OCCUPANCY_CHANGE:
      fields.push(SendMessageField.occupancy);
      break;
    case MessageType.USER_REGISTRATION:
      fields.push(SendMessageField.occupancy, SendMessageField.tag);
      break;
    case MessageType.REGULAR_CAR_COUNTER:
      fields.push(SendMessageField.counter);
      break;
    case MessageType.CALIBRATION:
    default:
      // not additional fields
      break;
  }

  return fields;
};

export const useSendMessageAction = (devices: PositionedDeviceData[]) => {
  const dispatch = useDispatch();
  return async (state: SendMessageState): Promise<void> => {
    const deviceId = state[SendMessageField.device] as string;
    switch (state[SendMessageField.messageType]) {
      case MessageType.OCCUPANCY_CHANGE: {
        await dispatchAsync(dispatch, SendRegularMessage(deviceId, {}));
        break;
      }
      case MessageType.CALIBRATION: {
        await dispatchAsync(dispatch, SendCalibrationMessage(deviceId, {}));
        break;
      }
      case MessageType.USER_REGISTRATION: {
        await dispatchAsync(dispatch, SendRegistrationMessage(deviceId, {
          user_id: state[SendMessageField.tag] as string
        }));
        break;
      }
      case MessageType.REGULAR_CAR_COUNTER: {
        await dispatchAsync(dispatch, SendRegularCarCounterMessage(deviceId, {
          counter: state[SendMessageField.counter] as number
        }));
        break;
      }
    }

    const deviceIds = devices.map(d => d.device_id);
    dispatch(LoadPositionStateData({
      params: { deviceIds, limit: deviceIds.length },
      disableNotFoundWarning: true,
    }));
  };
};