import React from 'react';
import clsx from 'clsx';
import { ResponsiblePerson } from 'models/base-station';
import { useDialog } from 'hooks/dialog';
// components
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { DeleteDialog } from './DeleteDialog';
import { EditDialog } from './EditDialog';

// style
import { useStyles } from './TableComponentsStyle';
import { useButtons } from 'styles/themes';

interface Props {
  person: ResponsiblePerson;
}

export const Actions = ({ person }: Props): JSX.Element => {
  const classes = { ...useStyles(), ...useButtons() };
  const deleteDialog = useDialog();
  const editDialog = useDialog();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box p={ 0 }>
        <Button
          className={ clsx(classes.lightBlue, classes.buttonText) }
          onClick={ editDialog.open }
        >
          Edit
        </Button>
      </Box>
      <Box p={ 0 } ml={ 2 }>
        <Button
          className={ clsx(classes.error, classes.buttonText) }
          onClick={ deleteDialog.open }
        >
          Remove
        </Button>
      </Box>
      { deleteDialog.isMounted && <DeleteDialog
        person={ person }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
      /> }
      { editDialog.isMounted && <EditDialog
        person={ person }
        isOpen={ editDialog.isOpen }
        onClose={ editDialog.close }
        onCloseEnd={ editDialog.unmount }
      /> }
    </Box>
  );
};
