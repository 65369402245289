import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '75vh',
      maxHeight: '100%',
      padding: 0,
      ...margins(theme)
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      margin: 10,
      '& h6': {
        lineHeight: '1',
        '& span': {
          lineHeight: '1.2',
        },
      }
    },
    link: {
      textDecoration: 'none',
      fontWeight: 400,
      fontSize: 15
    },
  }),
);

export default useStyles;
