import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getFirstVisibleTab } from 'components/Tabs/utils';
import { Zone, Group } from 'models/device-management';
import { Defined } from 'utils/models';
import { buildUrlParams, parseUrlParams, UrlItems } from 'utils/routing/query';

// components
import { PageTabs, TabConfig } from 'components';

import GeneralTab from './GeneralTab';
import { LevelsTab } from './LevelsTab';
import GroupsTab from './GroupsTab';
import PositionsTab from './PositionsTab';
import MapTab from './MapTab';

interface TabContentProps {
  zone?: Zone;
  zoneId: Defined<Zone['id']>;
  groups: Group[];
  groupsFetching: boolean;
  returnUrl?: string;
}

const TabConfigs: TabConfig<TabContentProps>[] = [
  {
    label: 'General',
    render: props => <GeneralTab {...props} />,
    get id() { return this.label; },
  },
  {
    // hidden only for the Release 2022.07.29
    // TODO: remove hidden: true after Release 2022.07.29
    hidden: true,
    // hidden: !isDmZonePageLevelsTabEnabled(),
    label: 'Levels',
    render: props => (
      props.tabActive && <LevelsTab
        tabActive={ props.tabActive }
        zoneId={ props.zoneId }
      />
    ),
    get id() { return this.label; },
  },
  {
    label: 'Groups',
    render: props => props.tabActive && (
      <GroupsTab
        zoneId={props.zoneId}
        groups={props.groups}
        groupsFetching={props.groupsFetching}
      />
    ),
    get id() { return this.label; },
  },
  {
    label: 'Positions',
    render: props => props.tabActive && <PositionsTab zoneId={props.zoneId} />,
    get id() { return this.label; },
  },
  {
    label: 'Map',
    render: props => props.tabActive && <MapTab zoneId={props.zoneId} />,
    get id() { return this.label; },
  },
];

type ContentParams = UrlItems & {
  activeTabId?: string;
  returnUrl?: string;
};

const buildParams = (params: UrlItems): ContentParams => ({
  ...params,
  returnUrl: params.returnUrl && decodeURIComponent(params.returnUrl),
});

type ZonePageContentProps = TabContentProps;

const ZonePageContent: React.FC<ZonePageContentProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = parseUrlParams(location.hash, buildParams);

  return (
    <PageTabs
      activeTabId={params.activeTabId || getFirstVisibleTab(TabConfigs)?.id}
      tabs={TabConfigs}
      tabContentProps={{
        zone: props.zone,
        zoneId: props.zoneId,
        returnUrl: params.returnUrl,
        groups: props.groups,
        groupsFetching: props.groupsFetching,
      }}
      onActiveTabChange={activeTabId => {
        history.replace(
          history.createHref({
            ...location,
            hash: buildUrlParams({ ...params, activeTabId }),
          }),
        );
      }}
    />
  );
};

export default ZonePageContent;
