import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) => createStyles({
  cardRoot: {
    height: '100%',
  },
  cardContent: {
    height: '100%',
  }
}));
