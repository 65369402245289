import React from 'react';
import { RouteConfig } from 'react-router-config';
import { match as Match } from 'react-router-dom';

import { State as CurrentUserProfile } from 'reducers/user-profile';
import * as paths from 'routing/paths';
import * as permission from 'utils/permissions';
import RabbitMessageHistory from 'pages/Rabbit/MessageHistory/RabbitMessageHistoryPage';
import RabbitEndpoints from 'pages/Rabbit/Endpoints/RabbitEndpointsPage';
import { RabbitEndpointDetailPage } from 'pages/Rabbit/EndpointDetail/RabbitEndpointDetailPage';
import { RabbitEndpointName } from 'components/Labels';

const ENDPOINT_ID_PARAM_NAME = 'suitId';
const endpointParam = `:${ ENDPOINT_ID_PARAM_NAME }`;

interface Params {
  [ENDPOINT_ID_PARAM_NAME]?: string;
}

const renderWithParams = (match: Match<Params>, Page: React.ElementType): false | React.ReactNode => {
  const endpointId = Number(match.params[ENDPOINT_ID_PARAM_NAME] ?? 0);
  if (isNaN(endpointId)) {
    return false;
  }

  return <Page endpointId={ endpointId }/>;
};

export function getRabbitRoutes(userData: CurrentUserProfile) {
  const routes: RouteConfig[] = [
    {
      path: '/api/rabbitmq',
      label: 'RabbitMQ',
      icon: 'rabbit',
      hiddenOnMainMenu: !permission.canViewRabbit(userData),
      isShowByUrl: permission.canViewRabbit(userData),
      routes: [
        {
          path: paths.getRabbitHistoryPath(),
          exact: true,
          label: 'History',
          icon: 'history',
          main: RabbitMessageHistory,
        },
        {
          path: paths.getRabbitEndpointListPath(),
          icon: 'suite',
          label: 'Endpoints',
          render: ({ match }) => match.isExact && <RabbitEndpoints/>,
          routes: [
            {
              path: paths.getRabbitEndpointDetailPath(endpointParam),
              label: ({ match }) => match && renderWithParams(match, RabbitEndpointName),
              render: ({ match }) => match.isExact && renderWithParams(match, RabbitEndpointDetailPage),
              hiddenOnMainMenu: true,
            }
          ]
        },
      ]
    },
  ];
  return routes;
}

