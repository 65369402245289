import React from 'react';
import { DoDisableIntegration } from 'actions/lorawan';
import { ApiKey } from 'models/user-management';
import { useFormActionLoader } from 'hooks/form';
import { IntegrationData } from 'models/lorawan';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  integrationName: IntegrationData['title'];
  apiKey: ApiKey;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess: () => void;
}

export const DisableIntegrationDialog = (props: Props): JSX.Element => {
  const { integrationName, apiKey, isOpen, onClose, onCloseEnd, onSuccess } = props;
  const { doAction } = useFormActionLoader();

  const handleConfirm = () => doAction({
    action: DoDisableIntegration(apiKey.id),
    onSuccess: () => {
      onSuccess();
      onClose();
    }
  });

  return (
    <EnsureDialog
      name="disable-integration-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      Are you sure you want to delete Integration { integrationName }?
    </EnsureDialog>
  );
};
