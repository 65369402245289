import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    listNetId: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
);

export default useStyles;
