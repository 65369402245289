import React from 'react';

// components
import Update from '@material-ui/icons/Update';
import { Tooltip, IconButton } from '@material-ui/core';

type Props = {
  tooltipText?: string;
  disabled?: boolean;
  handleAutoUpdate: () => void;
  autoUpdate: boolean;
};

export const SyncButton = ({ handleAutoUpdate, autoUpdate, tooltipText, disabled }: Props): JSX.Element => (
  <Tooltip title={ tooltipText || 'Auto update' } onClick={ handleAutoUpdate }>
    <span>
      <IconButton disabled={disabled}>
        <Update color={ autoUpdate ? 'primary' : undefined } />
      </IconButton>
    </span>
  </Tooltip>
);
