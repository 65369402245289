import * as dmClient from 'clients/device-management';
import { Group, Level, PositionWithLevel, Zone } from 'models/device-management';

import { DmApiFilters } from '../types';
import { ensureRequestSucceeded, getResponseData } from 'utils/clients';

interface PositionsResponse {
  positions: PositionWithLevel[];
  total: number;
}

export async function fetchPositions(
  filters: DmApiFilters
): Promise<PositionsResponse> {
  const res = await dmClient.findPositions({
    ...filters,
    groupIds: filters.groups,
    levels: filters.levels?.filter(l => typeof l === 'number') as number[],
  });

  return { positions: res.data.slice(0, filters.limit), total: res.total };
}

interface GroupsResponse {
  groups: Group[];
  total: number;
}

export async function fetchGroups(
  filters: DmApiFilters
): Promise<GroupsResponse> {
  const res = await dmClient.fetchGroups({
    ...filters,
    levels: filters.levels?.filter(l => typeof l === 'number') as number[],
  });

  return { groups: res.data, total: res.total };
}

interface LevelsResponse {
  levels: Level[];
  total: number;
}

interface LevelsParams extends DmApiFilters {
  levels?: number[];
}

export async function fetchLevels(
  filters: LevelsParams
): Promise<LevelsResponse> {
  const res = await dmClient.fetchLevels(filters);
  ensureRequestSucceeded(res);
  const levels = getResponseData(res);

  return { levels, total: levels.length };
}

interface ZonesResponse {
  zones: Zone[];
  total: number;
}

export async function fetchZones(
  filters: DmApiFilters
): Promise<ZonesResponse> {
  const res = await dmClient.fetchZones({
    ...filters,
    owner: filters.owner,
  });

  return { zones: res.data, total: res.total };
}
