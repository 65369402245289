import React from 'react';
import { Notification } from 'models/notification';
import { getRandomNumber } from 'helpers';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export type ENQUEUE_SNACKBAR = typeof ENQUEUE_SNACKBAR;
export type CLOSE_SNACKBAR = typeof CLOSE_SNACKBAR;
export type REMOVE_SNACKBAR = typeof REMOVE_SNACKBAR;

export interface EnqueueSnackbar {
  readonly type: ENQUEUE_SNACKBAR;
  notification: Notification;
}

export interface CloseSnackbar {
  readonly type: CLOSE_SNACKBAR;
  dismissAll: boolean;
  key: number;
}

export interface RemoveSnackbar {
  readonly type: REMOVE_SNACKBAR;
  key: number;
}

export const NotifyError = (message: React.ReactNode): EnqueueSnackbar => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    message,
    key: getRandomNumber(),
    options: { variant: 'error', preventDuplicate: true },
  }
});

export const NotifySuccess = (message: React.ReactNode): EnqueueSnackbar => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    message: message,
    key: getRandomNumber(),
    options: { variant: 'success' },
  }
});

export const NotifyWarning: (
  message: React.ReactNode,
  persist?: boolean,
  key?: number
) => EnqueueSnackbar = (message, persist = false, key = getRandomNumber()) => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    message,
    key,
    options: {
      variant: 'warning',
      persist,
    },
  }
});

export const EnqueueSnackbar = (notification: Notification): EnqueueSnackbar => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    ...notification,
    key: getRandomNumber(),
  }
});

export const CloseSnackbar: (key?: number) => CloseSnackbar = (key = 0) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const RemoveSnackbar: (key: number) => RemoveSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export type Action = EnqueueSnackbar | CloseSnackbar | RemoveSnackbar;

