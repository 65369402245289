export enum FormField {
  ownerId = 'owner_id',
  firstName = 'first_name',
  lastName = 'last_name',
  email = 'email',
  role = 'role',
  notify = 'notify',
  user = 'user',
}

export enum UserType {
  Inner,
  Outer,
}
