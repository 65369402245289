import { SubtreeManagementOptions } from '../../types';
import { fillZoneTotalDevices } from './fillZoneTotalDevices';
import { fillLevelTotalDevices } from './fillLevelTotalDevices';
import { fillGroupTotalDevices } from './fillGroupTotalDevices';
import { fillPositionDevices } from './fillPositionDevices';

export async function fillTotalDevices(options: SubtreeManagementOptions) {
  await Promise.all([
    fillZoneTotalDevices(options),
    fillLevelTotalDevices(options),
    fillGroupTotalDevices(options),
    fillPositionDevices(options),
  ]);
}
