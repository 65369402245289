import { ChangeEvent, useState } from 'react';
import { CheckboxField, PasswordField, TextField, useAuthenticator } from '@aws-amplify/ui-react';

interface FormState {
  accessCode: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

const initialState: FormState = {
  accessCode: '',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
};

export const SignUpForm = (): JSX.Element => {
  const { validationErrors } = useAuthenticator();
  const [state, setState] = useState<FormState>(initialState);
  const [errors, setErrors] = useState<FormState>(initialState);

  const handleChange = (field: keyof FormState, value: string) => {
    if (field === 'password') {
      value = value.trim();
    }

    setState({ ...state, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };
  const validateField = (field: keyof FormState) => {
    setErrors({
      ...errors,
      [field]: state[field].trim().length ? '' : 'Field is required'
    });
  };

  return (
    <>
      <TextField
        required
        label=""
        labelHidden
        name="custom:client_access_code"
        placeholder="Access code *"
        autoComplete="off"
        value={ state.accessCode }
        onChange={ (e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value.replace(/[^A-Za-z0-9]+/g, '');
          handleChange('accessCode', value.toUpperCase());
        } }
        onBlur={ () => validateField('accessCode') }
        hasError={ Boolean(validationErrors['custom:client_access_code']) || Boolean(errors.accessCode) }
        errorMessage={ errors.accessCode }
        data-testid="sign-up-client_access_code-input"
      />
      <TextField
        required
        label=""
        labelHidden
        name="email"
        type="email"
        placeholder="Email *"
        autoComplete="username"
        value={ state.email }
        onChange={ (e: ChangeEvent<HTMLInputElement>) => handleChange('email', e.target.value) }
        onBlur={ () => validateField('email') }
        hasError={ Boolean(validationErrors['email']) || Boolean(errors.email) }
        errorMessage={ errors.email }
        data-testid="sign-up-email-input"
      />
      <TextField
        required
        label=""
        labelHidden
        name="custom:first_name"
        placeholder="First Name *"
        autoComplete="given-name"
        value={ state.firstName }
        onChange={ (e: ChangeEvent<HTMLInputElement>) => handleChange('firstName', e.target.value) }
        onBlur={ () => validateField('firstName') }
        hasError={ Boolean(validationErrors['custom:first_name']) || Boolean(errors.firstName) }
        errorMessage={ errors.firstName }
        data-testid="sign-up-first_name-input"
      />
      <TextField
        required
        label=""
        labelHidden
        name="custom:last_name"
        placeholder="Last Name *"
        autoComplete="family-name"
        value={ state.lastName }
        onChange={ (e: ChangeEvent<HTMLInputElement>) => handleChange('lastName', e.target.value) }
        onBlur={ () => validateField('lastName') }
        hasError={ Boolean(validationErrors['custom:last_name']) || Boolean(errors.lastName) }
        errorMessage={ errors.lastName }
        data-testid="sign-up-last_name-input"
      />
      <PasswordField
        required
        label=""
        labelHidden
        name="password"
        placeholder="Password *"
        autoComplete="new-password"
        value={ state.password }
        onChange={ (e: ChangeEvent<HTMLInputElement>) => handleChange('password', e.target.value) }
        onBlur={ () => validateField('password') }
        hasError={ Boolean(validationErrors['password']) || Boolean(errors.password) }
        errorMessage={ errors.password }
        data-testid="sign-up-password-input"
      />
      <CheckboxField
        name=""
        value=""
        label="Minimum length 8 characters"
        checked={ state.password.length >= 8 }
      />
      <CheckboxField
        name=""
        value=""
        label="Contains number"
        checked={ /\d/g.test(state.password) }
      />
      <CheckboxField
        name=""
        value=""
        label="Contains special character"
        checked={ /[$&+,\\/:;=?@#"|'<>.^*()%![\]{}_~`-]/g.test(state.password) }
      />
      <CheckboxField
        name=""
        value=""
        label="Contains uppercase letter"
        checked={ /[A-Z]/.test(state.password) }
      />
      <CheckboxField
        name=""
        value=""
        label="Contains lowercase letter"
        checked={ /[a-z]/.test(state.password) }
      />
    </>
  );
};