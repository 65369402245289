import { useEffect, useState } from 'react';

// components
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

// styles
import { SubscriptionType, UserWithSubscriptionType } from 'models/user-management/projectHealthSubscriptions';

interface Props {
  user: UserWithSubscriptionType;
  handleSubscriptionTypeSelect: (userId: number, value: SubscriptionType[]) => void
}

export const UserView = ({ user, handleSubscriptionTypeSelect }: Props): JSX.Element => {
  const [subs, setSubs] = useState<SubscriptionType[]>([]);

  useEffect(() => {
    setSubs(user.ProjectSubscriptions?.map(s => s.type));
  }, [user]);


  const handleChange = (checked: boolean, type: SubscriptionType) => {
    const newState = checked ? [...subs, type] : subs.filter(sub => sub !== type);
    setSubs(newState);
    handleSubscriptionTypeSelect(user?.id, newState);
  };

  return (
    <Box padding="0px 24px" paddingBottom="24px">
      <FormControlLabel value={SubscriptionType.Daily} control={
        <Checkbox
          checked={subs.includes(SubscriptionType.Daily)}
          onChange={(_e, checked) => handleChange(checked, SubscriptionType.Daily)}
        />
      } label="Daily"
      />
      <FormControlLabel value={SubscriptionType.Weekly} control={
        <Checkbox
          checked={subs.includes(SubscriptionType.Weekly)}
          onChange={(_e, checked) => handleChange(checked, SubscriptionType.Weekly)}
        />
      } label="Weekly"
      />
    </Box>
  );
};
