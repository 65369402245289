import { TemplateSuite } from 'models/caller';
import { FormErrors } from 'types/form';
import { TemplateSuiteFormField } from './CallerTemplateSuiteForm';

export const validate = (
  templateSuite: TemplateSuite,
  fields: TemplateSuiteFormField[]
): FormErrors<TemplateSuiteFormField> => {
  const errors: FormErrors<TemplateSuiteFormField> = {};

  if (fields.includes(TemplateSuiteFormField.name)) {
    if (!templateSuite.name || !templateSuite.name.trim()) {
      errors.name = 'Name is required';
    }
  }

  if (fields.includes(TemplateSuiteFormField.owner_id)) {
    if (templateSuite.owner_id === undefined) {
      errors.name = 'Owner is required';
    }
  }

  return errors;
};
