import { fetchZonesTotalDamagedDevices } from '../../../api';
import { getZoneNodes } from '../../../node/utils';
import { ZoneNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillZoneTotalDamagedDevices({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, ZoneNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getZoneNodes(getNodes(nodes)),
    getQuery: node => node.totalDamagedDevices,
    fetchDataByNode: fetchZonesTotalDamagedDevices,
  });
}
