import { Zone, Position as OriginalPosition, Group, ExistingPosition } from 'models/device-management';
import { User } from 'models/user-management';
import { ResponsiblePerson } from 'models/base-station';

export type Defined<Value> = Exclude<Value, undefined>;

export function formatZone(zoneId: number, zone?: Zone | null): string {
  return formatZoneRaw(`${zoneId}`, zone);
}

export function formatZoneRaw(rawZoneId: string, zone?: Zone | null): string {
  return zone ? `${zone.name}` : rawZoneId;
}

export function formatPosition(position: OriginalPosition): string {
  return `#${position.id}`;
}

const labelByPositionGroupType: Record<Group['type'], string> = {
  standalone_car_counters: 'Car Counters',
  marked_parking_bay: 'Marked Spaces',
  unmarked_parking_bay: 'Unmarked Bay',
};

export function formatPositionGroupType(type: Group['type']): string {
  return labelByPositionGroupType[type];
}

export function formatUser(user: User): string {
  return getUserFullName(user) || user.email;
}

export function getUserFullName({
  first_name,
  last_name,
}: Pick<User, 'first_name' | 'last_name'>): string | null {
  if (!first_name && !last_name) {
    return null;
  }

  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  }

  return first_name || last_name;
}

export function formatResponsiblePerson(person: ResponsiblePerson): string {
  return `${person.first_name} ${person.last_name}`;
}

export function isNil(x: unknown): x is (undefined | null) {
  return typeof x === 'undefined' || x === null;
}

/**
 * Determine whether the given value matches `ExistingPosition['id']`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPositionId(x: any): x is ExistingPosition['id'] {
  return typeof x === 'number';
}

export const VirtualDevicesIds = {
  min: 0x10000,
  max: 0x1FFFF
};

export function isDeviceVirtual(deviceId: string): boolean {
  const id = parseInt(deviceId, 16);

  return id >= VirtualDevicesIds.min && id <= VirtualDevicesIds.max;
}
