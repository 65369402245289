import React from 'react';
import { Template } from 'models/caller';
import { useCallerTemplateDictionary } from 'hooks/caller';

// components
import {
  CommonProps,
  MultiProps,
  Option,
  SelectControl,
  SingleProps
} from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & { filter?: (template: Template) => boolean }

const CallerTemplatesSelectControl = (props: Props): JSX.Element => {
  const { isLoading, templates } = useCallerTemplateDictionary();
  const filterCallback = props.filter ?? (() => (true));
  const options: Option<number>[] =
    templates
      .filter(filterCallback)
      .map(template => ({
        value: template.template_id,
        label: template.name ?? String(template.template_id),
      }));

  return <SelectControl
    name="templateId"
    label="Template"
    { ...props }
    options={ options }
    checkSelected={ !isLoading }
    isLoading={ isLoading }
  />;
};

export {
  CallerTemplatesSelectControl
};
