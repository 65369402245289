import { MUIDataTableOptions } from 'mui-datatables';

export const DEFAULT_LIMIT = 20;
export const DEFAULT_LIMIT_OPTIONS = [20, 50, 100];
export const DEFAULT_OFFSET = 0;

export type Pagination = {
  offset: number;
  limit: number;
  page: number;
  rowCount: number;
  onChangePage: (page: number) => void;
  onChangeRowCount: (rowCount: number) => void;
}

export type Paginator<TItem> =
  Pagination
  & {
    data: TItem[];
    total: number;
  }

export const getDatatableOptions = <Item>(paginator: Paginator<Item>): MUIDataTableOptions => ({
  serverSide: true,
  pagination: true,
  count: Number(paginator.total),
  page: paginator.page,
  rowsPerPage: paginator.rowCount,
  onChangePage: paginator.onChangePage,
  onChangeRowsPerPage: paginator.onChangeRowCount,
});
