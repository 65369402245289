import React from 'react';
import { useGroup } from 'hooks/device-management';
import { isNil } from 'utils/models';

// components
import { PageLoader } from 'components/Loaders';
import { Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';

import Content from './Content';

// styles
import useProjectStyles from 'styles';

interface PositionGroupPageProps {
  groupId: number;
}

const PositionGroupPage: React.FC<PositionGroupPageProps> = ({ groupId }) => {
  const { group, isLoading } = useGroup({ id: groupId });

  const projectCss = useProjectStyles();

  if (isLoading) {
    return <PageLoader />;
  }

  if (isNil(groupId) || isNil(group)) {
    return (
      <Typography variant="h4" className={ projectCss.pageNotImplemented }>
        You have requested the page of nonexistent group
      </Typography>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Group { '' }</title>
      </Helmet>

      <Content group={ group } groupId={ groupId } />
    </div>
  );
};

export default PositionGroupPage;
