import { Device, ExistingPosition } from 'models/device-management';

export enum MainEntity {
  DEVICE = 'device',
  POSITION = 'position',
}

export interface CoupleParams {
  deviceId?: Device['device_id'] | null;
  positionId?: ExistingPosition['id'] | null;
  networkId?: string;

  refetchDeviceId: () => void;
  refetchPositionId: () => void;
}

export interface RouteParams {
  deviceId?: string;
  positionId?: number;
  networkId?: string;
}
