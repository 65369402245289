import * as is from 'utils/form/validation/validators';
import { EditProjectParams } from 'models/projects';
import { Zone } from 'models/device-management';
import { Project } from 'models/user-management';
import { StationLocation } from 'models/base-station';
import { useAuthUserSelector, useDialog } from 'hooks';

// components
import { Form } from 'components';
import { SuccessButton } from 'components/Buttons';
import { BlockTitle, BlockToolbar } from 'components/Block';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ProjectForm, FormValues } from 'components/UserManagement';
import { DeleteDialog } from './DeleteDialog';

// styles
import { useStyles } from 'pages/Projects/ProjectsStyle';

interface Props {
  project: Project;
  zones: Zone[];
  locations: StationLocation[];
  allowedToUpdateProject: boolean;
  allowedToDeleteProject: boolean;
  apply: (params: EditProjectParams) => void;
  deleteProject: () => void;
}

export const General = ({
  project,
  zones,
  locations,
  allowedToUpdateProject,
  allowedToDeleteProject,
  deleteProject,
  apply,
}: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const dialog = useDialog();

  const classes = useStyles();

  const defaultValues = {
    name: project?.name,
    timezone: project?.timezone,
    owner_id: project?.owner_id,
  };

  const handleSubmit = (values: FormValues) => {
    apply({ ...values, id: project.id });
  };

  const handleDeleteProject = () => {
    dialog.open();
  };

  // TODO: remove this when back-end will be ready
  const isEmptyProject = !zones.length && !locations.length;

  return (<>
    <Form<FormValues>
      defaultValues={ defaultValues }
      validators={ {
        name: is.required(),
        ...(user.isAdmin && { owner_id: is.required() })
      } }
      onSubmit={ handleSubmit }
      formProps={ {
        'data-testid': 'project-information'
      } }
    >
      { form => (
        <Paper className={ classes.paper } elevation={ 3 }>
          <BlockToolbar style={{ padding:'0px 24px', minHeight: '64px' }}>
            <BlockTitle>
              Project information
            </BlockTitle>

            <Button disabled={!allowedToDeleteProject} onClick={ handleDeleteProject }>Delete</Button>

            { form.formState.dirty && (
              <Button color="inherit" onClick={ form.reset }>
                Cancel
              </Button>
            ) }

            <SuccessButton
              label="Save"
              disabled={ !form.formState.dirty }
              pending={ form.formState.isSubmitting }
              titleForTooltip={ form.formState.dirty ? '' : 'Nothing to save' }
            />

          </BlockToolbar>

          <ProjectForm
            isAdmin={ user.isAdmin }
            allowed={ allowedToUpdateProject }
          />
        </Paper>
      ) }
    </Form>

    {dialog.isMounted && <DeleteDialog
      project={project}
      isEmptyProject={ isEmptyProject }
      onYes={ deleteProject }
      isOpen={ dialog.isOpen }
      onClose={ dialog.close }
      onCloseEnd={ dialog.unmount }
    />}
  </>);
};
