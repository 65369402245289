import { createStyles, makeStyles } from 'styles/utils';

const useStyles = makeStyles(
  createStyles({
    block: {
      flex: 1,
    },
    mapContainer: {
      height: 500,
      width: '100%',
    }
  }),
);

export default useStyles;
