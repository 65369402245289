import React from 'react';
import { useDialog, useFormActionLoader } from 'hooks';
import { DoDeleteLevel } from 'actions/device-management/levels';
import { Group, Level } from 'models/device-management';

// components
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { EnsureDialog } from 'components/Dialogs';

interface DeleteLevelProps {
  level: Level;
  groups: Group[];
}

export const DeleteLevel = ({ level, groups }: DeleteLevelProps) => {
  const { doAction } = useFormActionLoader();
  const dialogWithGroup = useDialog();
  const dialogWithoutGroup = useDialog();
  const handleDelete = () => {
    const hasGroup = groups.some(g => g.level_id === level.id);
    hasGroup ? dialogWithGroup.open() : dialogWithoutGroup.open();
  };

  const handleConfirm = () => doAction({
    action: DoDeleteLevel(level),
    onFinally: () => {
      dialogWithGroup.close();
      dialogWithoutGroup.close();
    },
  });

  return (
    <>
      <IconButton aria-label="Delete" onClick={ handleDelete }>
        <DeleteIcon fontSize="small"/>
      </IconButton>
      { dialogWithGroup.isMounted && <EnsureDialog
        name="delete-level"
        label="Selected level contains position group(s)."
        isOpen={ dialogWithGroup.isOpen }
        onClose={ dialogWithGroup.close }
        onCloseEnd={ dialogWithGroup.unmount }
        onYes={ handleConfirm }
        btnYes="Confirm"
        btnNo="Cancel"
      >
        {
          `The removing action will detach the group(s) from the level. ` +
          `Please, confirm level ${ level.name } removing.`
        }
      </EnsureDialog> }
      { dialogWithoutGroup.isMounted && <EnsureDialog
        name="delete-level"
        isOpen={ dialogWithoutGroup.isOpen }
        onClose={ dialogWithoutGroup.close }
        onCloseEnd={ dialogWithoutGroup.unmount }
        onYes={ handleConfirm }
      >
          Are you sure you want to remove the Level "{ level.name }"?
      </EnsureDialog> }
    </>
  );
};
