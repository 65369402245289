import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ErrorType, Reason } from 'models/monitoring';
import { LoadMonitoringEnum } from 'actions/monitoring';

export type MonitoringSchema =
  | 'global'
  | 'incident'
  | 'deviceState';

interface ErrorTypesState {
  readonly isLoading: boolean;
  readonly errorTypes: ErrorType[];
}

export const useMonitoringErrorTypesDictionary = (schema: MonitoringSchema = 'global'): ErrorTypesState => {
  const dispatch = useDispatch();
  const { isLoaded, errorTypes } = useSelector((state: RootState) => {
    let errorTypes: ErrorType[] = [];
    switch (schema) {
      case 'incident':
        errorTypes = state.monitoring.schemaEnum.schema.forIncidents.errorTypes;
        break;
      case 'deviceState':
        errorTypes = state.monitoring.schemaEnum.schema.forDeviceStates.errorTypes;
        break;
      case 'global':
        errorTypes = state.monitoring.schemaEnum.schema.global.errorTypes;
        break;
    }

    return {
      isLoaded: state.monitoring.schemaEnum.isLoaded,
      errorTypes: errorTypes,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isLoaded === undefined) {
      dispatch(LoadMonitoringEnum());
    }
  }, [isLoaded, dispatch]);

  return {
    isLoading: isLoaded !== true,
    errorTypes: errorTypes,
  };
};

interface ReasonsState {
  readonly isLoading: boolean;
  readonly reasons: Reason[];
}

export const useMonitoringReasonsDictionary = (schema: MonitoringSchema = 'global'): ReasonsState => {
  const dispatch = useDispatch();
  const { isLoaded, reasons } = useSelector((state: RootState) => {
    let reasons: Reason[] = [];
    switch (schema) {
      case 'incident':
        reasons = state.monitoring.schemaEnum.schema.forIncidents.reasons;
        break;
      case 'deviceState':
        reasons = state.monitoring.schemaEnum.schema.forDeviceStates.reasons;
        break;
      case 'global':
        reasons = state.monitoring.schemaEnum.schema.global.reasons;
        break;
    }

    return {
      isLoaded: state.monitoring.schemaEnum.isLoaded,
      reasons: reasons,
    };
  }, shallowEqual);

  useEffect(() => {
    if (isLoaded === undefined) {
      dispatch(LoadMonitoringEnum());
    }
  }, [isLoaded, dispatch]);

  return {
    isLoading: isLoaded !== true,
    reasons: reasons,
  };
};