import React from 'react';
import { useFormActionLoader } from 'hooks';
import { DoRemoveDeviceFromBox } from 'actions/provisioning';
import { Device } from 'models/provisioning';

// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  device: Device;
  onSuccess?: (device: Device) => void;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const RemoveFromBoxDialog = ({ device, onSuccess, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const handleConfirm = () => doAction({
    action: DoRemoveDeviceFromBox(device.device_id),
    onSuccess: device => { onSuccess?.(device); },
    onFinally: () => { onClose(); },
  });

  return (
    <EnsureDialog
      name="delete-device-from-box"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      Are you sure you want to delete { device.device_id } Device from { device.box_id } Box?
    </EnsureDialog>
  );
};
