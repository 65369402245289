import * as Dict from 'actions/dictionary';
import { Zone } from 'models/device-management';
import { Endpoint, MessageType } from 'models/rabbit';
import { User } from 'models/user-management';
import { Dictionary } from 'models/dictionary';
import { sortStrings } from 'helpers';

export interface State {
  inited: Set<Dictionary>;
  zones: Zone[];
  hardwareTypes: string[];
  endpoints: Endpoint[];
  users: User[];
  messageTypes: MessageType[];
}

const initState: State = {
  inited: new Set<Dictionary>(),
  zones: [],
  hardwareTypes: [],
  endpoints: [],
  users: [],
  messageTypes: [],
};

// we need force recreate default inited set for clear him
export const reducer = (state: State = { ...initState, inited: new Set<Dictionary>() }, action: Dict.Action): State => {
  switch (action.type) {
    case Dict.DICTIONARY_RESET:
      const inited = new Set<Dictionary>(state.inited);
      inited.delete(action.dictionary);
      return { ...state, inited };

    case Dict.DICT_ZONES_CHANGE:
      return {
        ...state,
        zones: action.zones.sort((a, b) => sortStrings(a.name, b.name))
      };

    case Dict.DICT_HARDWARE_TYPES_CHANGE:
      return {
        ...state,
        hardwareTypes: action.hardwareTypes
      };

    case Dict.DICT_ENDPOINTS_CHANGE:
      return {
        ...state,
        endpoints: action.endpoints
      };

    case Dict.DICT_USERS_CHANGE:
      return {
        ...state,
        users: action.users
      };

    case Dict.DICT_MESSAGE_TYPES_CHANGE:
      return {
        ...state,
        messageTypes: action.messageTypes
      };

    default:
      return state;
  }
};
