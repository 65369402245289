import { isNil } from 'utils/models';

import { Validator } from './types';

export const required = (): Validator => (value: unknown) => {
  if (
    isNil(value) ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return 'Required';
  }

  return false;
};

