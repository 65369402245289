import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(theme => createStyles({
  root: {
    whiteSpace: 'nowrap',
  },
  compact: {
    margin: theme.spacing(-1.5),
    padding: theme.spacing(1.5),
  },
}));
