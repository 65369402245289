import { useQuery } from 'react-query';
import { DevicesStatesFiltersQuery } from 'models';
import { useFormActionNotifier } from 'hooks';
import { getResponseData } from 'utils/clients';
import {
  fetchDeviceStates,
} from 'clients/monitoring';

type Options = {
  params: DevicesStatesFiltersQuery;
}

export function useDevicesStates({ params }: Options) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/devicesStates', params],
    queryFn: async () => {
      const res = await fetchDeviceStates(params);
      return {
        data: getResponseData(res),
        total: res.total
      };
    },
    cacheTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching DevicesStates: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: queryResult.isLoading,
    data: queryResult.data?.data || [],
    total: queryResult.data?.total,
  };
}