import { Zone } from 'models/device-management';

export const DICT_ZONES_CHANGE = 'DICT_ZONES_CHANGE';

export interface DictZonesChange {
  readonly type: typeof DICT_ZONES_CHANGE;
  zones: Zone[];
}

export const DictZonesChange = (zones: Zone[]): DictZonesChange => ({
  type: DICT_ZONES_CHANGE, zones
});

export type Action = DictZonesChange;
