import { call, put, takeEvery } from 'redux-saga/effects';

import { ApiResponse, ReasonEnum } from 'models';
import { getHistory } from 'clients/http-calls';
import { NotifyError, NotifyWarning } from 'actions/notifier';
import {
  CALLER_HISTORY_DATA_LOAD_BY_PARAMS,
  LoadCallerHistoryDataByParams,
  LoadCallerHistoryDataFailure,
  LoadCallerHistoryDataSuccess,
} from 'actions/caller/history-data';

function* loadHistoryData(action: LoadCallerHistoryDataByParams) {
  const response: ApiResponse = yield call(getHistory, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadCallerHistoryDataSuccess(response.data));
    if (!response.data.length) {
      yield put(NotifyWarning('Caller history not found'));
    }
  } else {
    const message = response.message || 'Server error';
    yield put(LoadCallerHistoryDataFailure(message));
    yield put(NotifyError(`Error while fetching caller history: ${ message }`));
  }
}

export default [
  takeEvery(CALLER_HISTORY_DATA_LOAD_BY_PARAMS, loadHistoryData),
];
