import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import findLocationIcon from 'images/Map/find-location.svg';
import { StationLocation } from 'models/base-station';
import { FormProps } from 'types/form';
import { useDefaultProject } from 'hooks/user-managment';
import { Address } from 'components/BaseStation/types';
import { getAddressByLocation } from 'components/BaseStation/utils';
import { FormField } from './types';
import { validate } from './validator';

// components
import { Box, Icon } from '@material-ui/core';
import { CommonButton } from 'components/Buttons';
import { OwnersSingleSelectControl, ProjectSelectControl, TextControl } from 'components/Controls';
import { MapControl } from './widgets/MapControl';

// styles
import { useStyles } from './styles';

export {
  FormField as LocationFormField
};

type Props = FormProps<StationLocation, FormField>
export const StationLocationForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { getDefaultProject } = useDefaultProject();
  const [findAddress, setFindAddress] = useState<string>('');
  const [found, setFound] = useState<Address | undefined>(undefined);
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  const handleOwnerChange = (value: StationLocation['owner_id']): void => {
    if (onChange) {
      onChange({
        ...state,
        [FormField.owner_id]: value,
        [FormField.project]: getDefaultProject(value)?.id
      });
    }
  };

  const handleChangeAddress = (address: Address): void => {
    onChange && onChange({
      ...state, ...{
        lat: address.lat,
        lon: address.lon,
        country: address.country ?? state.country,
        city: address.city ?? state.city,
        address: `${address.building ?? ''} ${address.street ?? ''}`.trim() || state.address,
        postcode: address.postcode ?? state.postcode,
      }
    }, FormField.map);
  };

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(FormField.owner_id) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable={ true }
          isDisabled={ isView || disabledFields.includes(FormField.owner_id) }
          selected={ state.owner_id }
          changeHandler={ handleOwnerChange }
          error={ showErrors.includes(FormField.owner_id) ? errors[FormField.owner_id] : undefined }
        />
      }
      { !showFields.includes(FormField.project) ? '' :
        <ProjectSelectControl
          isDisabled={ isView || disabledFields.includes(FormField.project) }
          isMulti={ false }
          label="Project"
          filter={ project => project.owner_id === state.owner_id }
          selected={ state.project }
          onChange={ (projectId) => handleChange(FormField.project, projectId) }
          error={ showErrors.includes(FormField.project) ? errors[FormField.project] : undefined }
        />
      }
      { !showFields.includes(FormField.country) ? '' :
        <Box display="flex" alignItems="baseline">
          <TextControl
            required={ !isView }
            disabled={ isView || disabledFields.includes(FormField.country) }
            label="Country"
            name="country"
            value={ state.country }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(FormField.country, event.target.value ?? '');
            } }
            error={ showErrors.includes(FormField.country) ? errors[FormField.country] !== undefined : false }
            helperText={ showErrors.includes(FormField.country) ? errors[FormField.country] : undefined }
          />
          {
            isView || !showFields.includes(FormField.map) ? '' :
              <CommonButton
                type="icon"
                label="Find on map"
                icon={ <Icon><img src={ findLocationIcon } height={ 20 } width={ 20 } alt="Find on map" /></Icon> }
                onClick={ () => setFindAddress(`${state.address}, ${state.city} ${state.postcode}, ${state.country}`) }
              />
          }
        </Box>

      }
      { !showFields.includes(FormField.city) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.city) }
          label="City"
          name="city"
          value={ state.city }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.city, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.city) ? errors[FormField.city] !== undefined : false }
          helperText={ showErrors.includes(FormField.city) ? errors[FormField.city] : undefined }
        />
      }
      { !showFields.includes(FormField.address) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.address) }
          label="Address"
          name="Address"
          value={ state.address }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.address, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.address) ? errors[FormField.address] !== undefined : false }
          helperText={ showErrors.includes(FormField.address) ? errors[FormField.address] : undefined }
        />
      }
      { !showFields.includes(FormField.postcode) ? '' :
        <TextControl
          disabled={ isView || disabledFields.includes(FormField.postcode) }
          label="ZIP-Code"
          name="postcode"
          value={ state.postcode || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.postcode, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.postcode) ? errors[FormField.postcode] !== undefined : false }
          helperText={ showErrors.includes(FormField.postcode) ? errors[FormField.postcode] : undefined }
        />
      }
      { !showFields.includes(FormField.map) ? '' :
        <MapControl
          address={ getAddressByLocation(state) }
          onChange={ handleChangeAddress }
          findAddress={ findAddress }
          onCancel={ () => setFindAddress('') }
          found={ found }
          setFound={ setFound }
        />
      }
      { !showFields.includes(FormField.notes) ? '' :
        <TextControl
          multiline
          disabled={ isView || disabledFields.includes(FormField.notes) }
          variant="outlined"
          rows="4"
          label="Additional location info"
          name="notes"
          value={ state.notes || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.notes, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.notes) ? errors?.notes !== undefined : false }
          helperText={ showErrors.includes(FormField.notes) ? errors?.notes : undefined }
        />
      }
    </form>
  );
};

