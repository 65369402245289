/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Types of metric
 * @export
 * @enum {string}
 */
export enum Metric {
    InstalledNumber = 'installed_number',
    ReplacementNumber = 'replacement_number',
    ReplacementHistory = 'replacement_history',
    DamageHistory = 'damage_history',
    EmptyPositionsNumber = 'empty_positions_number',
    DamagedInstalledNumber = 'damaged_installed_number',
    ReplacementLogByDate = 'replacement_log_by_date',
    DamageLogByDate = 'damage_log_by_date',
    ByZoneIds = 'by_zone_ids',
    ByGroupIds = 'by_group_ids'
}

