import { UserGroupServiceAvailability, UserGroupServiceName } from 'models/user-management';

export const UM_USER_GROUP_SERVICES_GET = 'um/user_groupservices/GET';
export const UM_USER_GROUP_SERVICES_GET_SUCCESS = 'um/user_groupservices/GET_SUCCESS';
export const UM_USER_GROUP_SERVICES_GET_FAILED = 'um/user_groupservices/GET_FAILED';
export const UM_USER_GROUP_SERVICE_DEACTIVATE = 'um/user_groupservice/DEACTIVATE';
export const UM_USER_GROUP_SERVICE_ACTIVATE = 'um/user_groupservice/ACTIVATE';
export const UM_USER_GROUP_SERVICE_STATUS_CHANGE_START = 'um/user_groupservice/STATUS_CHANGE_START';
export const UM_USER_GROUP_SERVICE_STATUS_CHANGE_FINISH = 'um/user_groupservice/STATUS_CHANGE_FINISH';


export interface GetGroupServices {
  readonly type: typeof UM_USER_GROUP_SERVICES_GET;
  userGroupId: number;
}

export interface GetGroupServicesSuccess {
  readonly type: typeof UM_USER_GROUP_SERVICES_GET_SUCCESS;
  userGroupId: number;
  data: UserGroupServiceAvailability[];
}

export interface GetGroupServicesFailed {
  readonly type: typeof UM_USER_GROUP_SERVICES_GET_FAILED;
  userGroupId: number;
  data: [];
}

export interface DeactivateGroupService {
  readonly type: typeof UM_USER_GROUP_SERVICE_DEACTIVATE;
  userGroupId: number;
  serviceName: UserGroupServiceName;
}

export interface ActivateGroupService {
  readonly type: typeof UM_USER_GROUP_SERVICE_ACTIVATE;
  userGroupId: number;
  serviceName: UserGroupServiceName;
}

export interface GroupServiceStatusChangeStart {
  readonly type: typeof UM_USER_GROUP_SERVICE_STATUS_CHANGE_START;
  userGroupId: number;
}

export interface GroupServiceStatusChangeFinish {
  readonly type: typeof UM_USER_GROUP_SERVICE_STATUS_CHANGE_FINISH;
  userGroupId: number;
}


export const GetGroupServices = (userGroupId: number): GetGroupServices => ({
  type: UM_USER_GROUP_SERVICES_GET, userGroupId
});

export const DoGetGroupServicesSuccess = (userGroupId: number, data: UserGroupServiceAvailability[]): GetGroupServicesSuccess => ({
  type: UM_USER_GROUP_SERVICES_GET_SUCCESS, userGroupId, data
});

export const DoGetGroupServicesFailed = (userGroupId: number, data: []): GetGroupServicesFailed => ({
  type: UM_USER_GROUP_SERVICES_GET_FAILED, userGroupId, data
});

export const DeactivateGroupService = (userGroupId: number, serviceName: UserGroupServiceName): DeactivateGroupService => ({
  type: UM_USER_GROUP_SERVICE_DEACTIVATE, userGroupId, serviceName
});

export const ActivateGroupService = (userGroupId: number, serviceName: UserGroupServiceName): ActivateGroupService => ({
  type: UM_USER_GROUP_SERVICE_ACTIVATE, userGroupId, serviceName
});

export const GroupServiceStatusChangeStart = (userGroupId: number): GroupServiceStatusChangeStart => ({
  type: UM_USER_GROUP_SERVICE_STATUS_CHANGE_START, userGroupId
});

export const GroupServiceStatusChangeFinish = (userGroupId: number): GroupServiceStatusChangeFinish => ({
  type: UM_USER_GROUP_SERVICE_STATUS_CHANGE_FINISH, userGroupId
});

export type Action =
  | GetGroupServices
  | GetGroupServicesSuccess
  | GetGroupServicesFailed
  | DeactivateGroupService
  | ActivateGroupService
  | GroupServiceStatusChangeStart
  | GroupServiceStatusChangeFinish