import { Theme } from '@nivo/core';

export const chartTheme: Theme = {
  axis: {
    legend: {
      text: {
        fontSize: '1.2em',
        fill: '#333333'
      }
    },
    ticks: {
      line: {
        stroke: '#555555'
      },
      text: {
        fill: '#333333'
      }
    }
  }
};
