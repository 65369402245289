import React from 'react';
import { useFormActionLoader } from 'hooks';
import { deletePosition } from 'actions/device-management/positions';
import { Position, PositionedDeviceData } from 'models/device-management';

// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  position: Position;
  device?: PositionedDeviceData;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (position: Position) => void;
}

export const DeletePositionDialog = ({ position, device, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const handleConfirm = () => doAction({
    action: deletePosition(position.id),
    onFinally: onClose,
    onSuccess: onSuccess,
  });

  return (
    <EnsureDialog
      name="dm-position-delete-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
      btnYes="Delete"
      btnNo="Cancel"
      label={ <> Are you sure you want to delete position?</> }
    >
      <>The position <b>{ position.network_id ?? position.id }</b> will be deleted.</>
      { device && (
        <>The linked device <b>{ device.device_id }</b> will be unbound.</>
      ) }
    </EnsureDialog>
  );
};
