import { Rule } from 'models/rabbit';

export const RABBIT_RULE_CREATE = 'RABBIT_RULE_CREATE';
export const RABBIT_RULE_UPDATE = 'RABBIT_RULE_UPDATE';
export const RABBIT_RULE_DELETE = 'RABBIT_RULE_DELETE';
export const RABBIT_RULE_ACTIVATE = 'RABBIT_RULE_ACTIVATE';
export const RABBIT_RULE_DEACTIVATE = 'RABBIT_RULE_DEACTIVATE';
export const RABBIT_RULE_LOCK = 'RABBIT_RULE_LOCK';
export const RABBIT_RULE_UNLOCK = 'RABBIT_RULE_UNLOCK';
export const RABBIT_RULE_CHANGE = 'RABBIT_RULE_CHANGE';

export interface ChangeRule {
  readonly type: typeof RABBIT_RULE_CHANGE;
  rule: Rule;
  options: {
    update?: boolean;
    delete?: boolean;
    create?: boolean;
  };
}

export interface CreateRule {
  readonly type: typeof RABBIT_RULE_CREATE;
  rule: Rule;
}

export interface UpdateRule {
  readonly type: typeof RABBIT_RULE_UPDATE;
  rule: Rule;
}

export interface DeleteRule {
  readonly type: typeof RABBIT_RULE_DELETE;
  rule: Rule;
}

export interface ActivateRule {
  readonly type: typeof RABBIT_RULE_ACTIVATE;
  rule: Rule;
}

export interface DeactivateRule {
  readonly type: typeof RABBIT_RULE_DEACTIVATE;
  rule: Rule;
}

export interface LockRule {
  readonly type: typeof RABBIT_RULE_LOCK;
  ruleId: number;
}

export interface UnlockRule {
  readonly type: typeof RABBIT_RULE_UNLOCK;
  ruleId: number;
}

export type Action =
  CreateRule | UpdateRule | DeleteRule | ChangeRule |
  LockRule | UnlockRule | ActivateRule | DeactivateRule

export const ChangeRule = (rule: Rule, options: ChangeRule['options']): ChangeRule => ({
  type: RABBIT_RULE_CHANGE,
  rule: rule,
  options: options,
});

export const LockRule: (ruleId: number) => LockRule = (ruleId: number) => ({
  type: RABBIT_RULE_LOCK,
  ruleId: ruleId,
});
export const UnlockRule: (ruleId: number) => UnlockRule = (ruleId: number) => ({
  type: RABBIT_RULE_UNLOCK,
  ruleId: ruleId,
});

export const CreateRule: (rule: Rule) => CreateRule = (rule: Rule) => ({
  type: RABBIT_RULE_CREATE,
  rule: rule,
});

export const UpdateRule: (rule: Rule) => UpdateRule = (rule: Rule) => ({
  type: RABBIT_RULE_UPDATE,
  rule: rule,
});

export const DeleteRule: (rule: Rule) => DeleteRule = (rule: Rule) => ({
  type: RABBIT_RULE_DELETE,
  rule: rule,
});

export const ActivateRule: (rule: Rule) => ActivateRule = (rule: Rule) => ({
  type: RABBIT_RULE_ACTIVATE,
  rule: rule,
});

export const DeactivateRule: (rule: Rule) => DeactivateRule = (rule: Rule) => ({
  type: RABBIT_RULE_DEACTIVATE,
  rule: rule,
});
