import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponseWithTotal } from 'models';
import { GetPositionStateParams, PositionState } from 'models/parking';
import { errorHandler, formatQuery } from './utils';

const urls = {
  bayStatus: '/bay/{bay_id}/status',
  positionStates: '/positions/states/find',
};

export const config: ApiGConfig = {
  name: 'parking',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://parking.prod.api.nwave.io' : 'https://parking.dev.api.nwave.io',
};

export const getPositionStates = (params: GetPositionStateParams): Promise<ApiResponseWithTotal<PositionState[]>> => {
  const query = formatQuery({
    limit: params.limit,
    offset: params.offset,
    owner_id: params.owner,
    device_id: params.deviceIds,
    position_id: params.positionIds,
    group_id: params.groupIds,
    zone_id: params.zones,
    project_id: params.projects,
    network_id: params.networkIds,
    time_from: params.timeFrom?.toISOString(),
    time_to: params.timeTo?.toISOString(),
    state: params.status,
    custom_id: params.customIds,
  });

  return API
    .get(config.name, urls.positionStates, {
      queryStringParameters: query,
    })
    .catch(errorHandler);
};
