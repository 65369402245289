import moment from 'moment';

export const getTickValues = (maxData: number): number[] => {
  const modulo = maxData % 4;
  const maxValue = maxData + (modulo ? (4 - modulo) : 0);
  const step = maxValue / 4;

  return [
    0,
    step,
    step * 2,
    step * 3,
    maxValue
  ];
};

export const formatDate = (date: string| Date, showTime = false): string =>
{
  return moment(date).format(showTime ? 'dddd, D MMMM YYYY HH:mm' : 'dddd, D MMMM YYYY');
};
