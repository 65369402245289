import React, { useState } from 'react';

// components
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Skeleton from '@material-ui/lab/Skeleton';

import { BlockTitle } from 'components/Block';
import { SuccessButton } from 'components/Buttons';
import { TextControl } from 'components/Controls';

// styles
import useStyles from 'styles/filters';
import { useStyles as parkingStyles } from './ParkingAnomalyStyles';

export interface State {
  occupancyDuration?: number;
  availabilityDuration?: number;
  ignored: boolean;
}

interface Props {
  monitoring?: {
    occupy_max_interval?: number;
    availability_max_interval?: number;
    ignored?: boolean;
  };
  state: State;
  setState: (state: State) => void;
  isFetching: boolean;
  handleSubmitDuration: () => void;
  handleSubmitIgnore: () => void;
}

export const ParkingAnomaly = ({ monitoring, handleSubmitDuration, handleSubmitIgnore, state, setState, isFetching }: Props): JSX.Element => {
  const classes = {
    ...useStyles(),
    ...parkingStyles()
  };

  const [isValid, setValidation] = useState({
    occupancyDuration: true,
    availabilityDuration: true
  });
  type ValidatedFieldName = keyof typeof isValid;

  const validator = (value: number, field: ValidatedFieldName) => {
    setValidation(prevState => ({ ...prevState, [field]: value > 0 }));
  };
  const isDisabledDuration = () => {
    // can't be 0 or empty string
    if (!state.occupancyDuration || !state.availabilityDuration) {
      return true;
    }
    // can't be the same value
    if (monitoring && (state.occupancyDuration === monitoring.occupy_max_interval && state.availabilityDuration === monitoring.availability_max_interval)) {
      return true;
    }
    if (!isValid.availabilityDuration || !isValid.occupancyDuration) {
      return true;
    }
    return false;
  };
  const isDisabledIgnore = () => monitoring?.ignored === state.ignored;

  const handleChange = (value: string, field: ValidatedFieldName) => {
    const fieldValue = Math.abs(Math.trunc(Number(value))) || 0;
    setState({ ...state, [field]: fieldValue });
    validator(fieldValue, field);
  };

  const handleResetDuration = () => {
    setState({
      ...state,
      occupancyDuration: monitoring ? monitoring.occupy_max_interval : undefined,
      availabilityDuration: monitoring ? monitoring.availability_max_interval : undefined
    });
    setValidation({
      occupancyDuration: true,
      availabilityDuration: true
    });
  };

  const handleResetIgnore = () => {
    const ignored = monitoring?.ignored === undefined ? false : monitoring?.ignored;
    return setState({ ...state, ignored: monitoring ? ignored : false });
  };

  return (
    <>
      { isFetching
        ? <Skeleton />
        : <Paper className={ classes.paper }>
          <BlockTitle>Parking anomaly behaviour monitoring</BlockTitle>
          <form autoComplete="off" onSubmit={ e => { e.preventDefault(); handleSubmitDuration(); } }>
            <TextControl
              label="Max occupancy duration (hours)"
              value={ state.occupancyDuration || '' }
              onChange={ e => handleChange(e.target.value, 'occupancyDuration') }
              type="number"
              error={ !isValid.occupancyDuration }
            />
            <TextControl
              label="Max availability duration (hours)"
              value={ state.availabilityDuration || '' }
              onChange={ e => handleChange(e.target.value, 'availabilityDuration') }
              type="number"
              error={ !isValid.availabilityDuration }
              fullWidth
            />
            <div className={ classes.container }>
              <Grid item={ true } xs={ 12 } sm={ 12 } className={ classes.gridEnd }>
                <SuccessButton label="Update configuration" disabled={ isDisabledDuration() } />
                <Button color="inherit" onClick={ handleResetDuration }>
                  Cancel
                </Button>
              </Grid>
            </div>
          </form>
          <Divider />
          <div className={ classes.container }>
            <Grid item={ true } xs={ 12 } className={ classes.gridEnd }>
              <FormGroup row>
                <FormControlLabel
                  control={ <Switch checked={ state.ignored } onChange={ event => setState({ ...state, ignored: event.target.checked as boolean }) } color="primary" /> }
                  label="Enable parking anomaly monitoring"
                />
              </FormGroup>
              <SuccessButton label="Save" onClick={ e => { e.preventDefault(); handleSubmitIgnore(); } } disabled={ isDisabledIgnore() } />
              <Button color="inherit" onClick={ handleResetIgnore }>
                Cancel
              </Button>
            </Grid>
          </div>
        </Paper>
      }
    </>
  );
};
