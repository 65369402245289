import { Project } from 'models/user-management';
import { PROJECT_DEFAULT_OPTION_VALUE } from 'components/Controls';
import { ExistingZone } from './types';

/* Get available owners by selected projects */
export const getOwnersFromProjects = (projects: Project[], selected: number[]): undefined | number[] => {
  if (selected.includes(PROJECT_DEFAULT_OPTION_VALUE)) { // default exist for all owners
    return undefined;
  }

  const result = new Set<number>([]);
  for (const project of projects) {
    if (selected.includes(Number(project.id))) {
      result.add(project.owner_id);
    }
  }

  return [...result];
};

/* Get available zones by selected projects */
export const getZonesFromProjects = (zones: ExistingZone[], selected: number[]): number[] => {
  const result: number[] = [];
  for (const zone of zones) {
    if (!zone.project_id && selected.includes(PROJECT_DEFAULT_OPTION_VALUE)) {
      result.push(zone.id);
    } else if (zone.project_id && selected.includes(Number(zone.project_id))) {
      result.push(zone.id);
    }
  }

  return result;
};


