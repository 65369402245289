import * as models from 'models/base-station-status';

export const STATION_MONITORING_SYNC_START = 'page/stationMonitoring/SYNC_START';
export const STATION_MONITORING_SYNC_STOP = 'page/stationMonitoring/SYNC_STOP';
export const STATION_MONITORING_LOAD_FAILURE = 'page/stationMonitoring/LOAD_FAILURE';
export const STATION_MONITORING_STATUS_HISTORY_LOADED = 'page/stationMonitoring/STATUS_HISTORY_LOADED';
export const STATION_MONITORING_INTERNET_HISTORY_LOADED = 'page/stationMonitoring/INTERNET_HISTORY_LOADED';
export const STATION_MONITORING_NOISE_LEVEL_HISTORY_LOADED = 'page/stationMonitoring/NOISE_LEVEL_HISTORY_LOADED';
export const STATION_MONITORING_LAST_MESSAGE_LOADED = 'page/stationMonitoring/LAST_MESSAGE_LOADED';
export const STATION_MONITORING_DONGLE_LOADED = 'page/stationMonitoring/DONGLE_LOADED';
export const STATION_MONITORING_MIN_CPP_LOADED = 'page/stationMonitoring/MIN_CPP_LOADED';
export const STATION_MONITORING_VPN_HISTORY_LOADED = 'page/stationMonitoring/STATION_VPN_HISTORY_LOADED';

export interface StartStationMonitoringSync {
  readonly type: typeof STATION_MONITORING_SYNC_START;
  readonly filters: models.StationMonitoringFilters;
}

export interface StopStationMonitoringSync {
  readonly type: typeof STATION_MONITORING_SYNC_STOP;
}

export interface LoadStationMonitoringFailure {
  readonly type: typeof STATION_MONITORING_LOAD_FAILURE;
  readonly aspect: (
    | 'uptimeHistory' | 'vpnHistory' | 'internetHistory' | 'noiseLevelHistory'
    | 'lastMessage' | 'dongle' | 'minCpp'
  );
}

export interface LoadStationMonitoringStatusHistorySuccess {
  readonly type: typeof STATION_MONITORING_STATUS_HISTORY_LOADED;
  readonly payload: models.StationStatusHistory;
  readonly dateRange: models.DateParams;
}

export interface LoadStationVpnHistorySuccess {
  readonly type: typeof STATION_MONITORING_VPN_HISTORY_LOADED;
  readonly payload: models.VpnHistory;
  readonly dateRange: models.DateParams;
}

export interface LoadStationMonitoringInternetHistorySuccess {
  readonly type: typeof STATION_MONITORING_INTERNET_HISTORY_LOADED;
  readonly payload: models.ConnectionHistory;
  readonly dateRange: models.DateParams;
}

export interface LoadStationMonitoringNoiseLevelHistorySuccess {
  readonly type: typeof STATION_MONITORING_NOISE_LEVEL_HISTORY_LOADED;
  readonly payload: models.NoiseLevelHistory;
  readonly dateRange: models.DateParams;
}

export interface LoadStationMonitoringLastMessageSuccess {
  readonly type: typeof STATION_MONITORING_LAST_MESSAGE_LOADED;
  readonly payload: models.LastMessage;
}

export interface LoadStationMonitoringDongleSuccess {
  readonly type: typeof STATION_MONITORING_DONGLE_LOADED;
  readonly payload: models.DongleStatus;
}

export interface LoadStationMonitoringMinCppSuccess {
  readonly type: typeof STATION_MONITORING_MIN_CPP_LOADED;
  readonly payload: models.MinCppStatus;
}

export const StartStationMonitoringSync = (filters: models.StationMonitoringFilters): StartStationMonitoringSync => ({
  type: STATION_MONITORING_SYNC_START, filters
});

export const StopStationMonitoringSync = (): StopStationMonitoringSync => ({
  type: STATION_MONITORING_SYNC_STOP
});

export const LoadStationMonitoringFailure = (aspect: LoadStationMonitoringFailure['aspect']): LoadStationMonitoringFailure => ({
  type: STATION_MONITORING_LOAD_FAILURE, aspect
});

export const LoadStationMonitoringStatusHistorySuccess = (payload: models.StationStatusHistory, dateRange: models.DateParams): LoadStationMonitoringStatusHistorySuccess => ({
  type: STATION_MONITORING_STATUS_HISTORY_LOADED, payload, dateRange
});

export const LoadStationVpnHistorySuccess = (payload: models.VpnHistory, dateRange: models.DateParams): LoadStationVpnHistorySuccess => ({
  type: STATION_MONITORING_VPN_HISTORY_LOADED, payload, dateRange
});

export const LoadStationMonitoringInternetHistorySuccess = (payload: models.ConnectionHistory, dateRange: models.DateParams): LoadStationMonitoringInternetHistorySuccess => ({
  type: STATION_MONITORING_INTERNET_HISTORY_LOADED, payload, dateRange
});

export const LoadStationMonitoringNoiseLevelHistorySuccess = (payload: models.NoiseLevelHistory, dateRange: models.DateParams): LoadStationMonitoringNoiseLevelHistorySuccess => ({
  type: STATION_MONITORING_NOISE_LEVEL_HISTORY_LOADED, payload, dateRange
});

export const LoadStationMonitoringLastMessageSuccess = (payload: models.LastMessage): LoadStationMonitoringLastMessageSuccess => ({
  type: STATION_MONITORING_LAST_MESSAGE_LOADED, payload
});

export const LoadStationMonitoringDongleSuccess = (payload: models.DongleStatus): LoadStationMonitoringDongleSuccess => ({
  type: STATION_MONITORING_DONGLE_LOADED, payload
});

export const LoadStationMonitoringMinCppSuccess = (payload: models.MinCppStatus): LoadStationMonitoringMinCppSuccess => ({
  type: STATION_MONITORING_MIN_CPP_LOADED, payload
});

export type Action =
  | StartStationMonitoringSync
  | StopStationMonitoringSync
  | LoadStationMonitoringFailure
  | LoadStationMonitoringStatusHistorySuccess
  | LoadStationMonitoringInternetHistorySuccess
  | LoadStationMonitoringNoiseLevelHistorySuccess
  | LoadStationMonitoringLastMessageSuccess
  | LoadStationMonitoringDongleSuccess
  | LoadStationMonitoringMinCppSuccess
  | LoadStationVpnHistorySuccess
