import { MUIDataTableColumnDef } from 'mui-datatables';

import { NodeMethods, TreeNode, TreeTableProps, TreeTableRow } from '../types';
import { renderTreeNodeColumn } from './renderTreeNodeColumn';
import { renderNormalColumn } from './renderNormalColumn';

export function getMuiDataTableColumns<TNode extends TreeNode<unknown>>(
  columns: TreeTableProps<TNode>['columns'],
  rows: TreeTableRow<TNode>[],
  nodeMethods: NodeMethods<TNode>,
): MUIDataTableColumnDef[] {
  return columns.map((column, i) => ({
    name: '',
    label: column.label,
    options: {
      display: column.hidden ? 'excluded' : 'true',
      customBodyRenderLite: (dataIndex) => i === 0
        ? renderTreeNodeColumn(column, nodeMethods, rows[dataIndex])
        : renderNormalColumn(column, rows[dataIndex])
    }
  }));
}
