import { API } from 'aws-amplify';

import { errorHandler, formatQuery } from 'clients/utils';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import { GetSendingStatusStatisticParams, SendingStatusStatistic } from 'models/rabbit-monitoring';

export const config: ApiGConfig = {
  name: 'rabbit-monitoring',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://rmqmon.prod.api.nwave.io' : 'https://rmqmon.dev.api.nwave.io',
};

const urls = {
  sendingStatusStatistic: '/history/statistic/sending_status',
};

export const getSendingStatusStatistic = (params: GetSendingStatusStatisticParams): Promise<ApiResponse<SendingStatusStatistic[]>> => {
  const reqParams = {
    queryStringParameters: formatQuery({
      owner_id: params.ownerId,
      project_id: params.projectIds,
      zone_id: params.zoneIds,
      group_id: params.groupIds,
      endpoint_id: params.endpointIds,
      time_from: params.timeFrom?.toISOString(),
      time_till: params.timeTill?.toISOString(),
      group_by: params.groupBy,
    }),
  };

  return API
    .get(config.name, urls.sendingStatusStatistic, reqParams)
    .catch(errorHandler);
};
