export const ALL_PROJECTS: 'all' = 'all';

export interface User { 
    username: string;
    email: string;
    phone_number: string;
    sub: string;
    first_name: string;
    last_name: string;
    timezone: string;
    role: UserRoleEnum;
    id: number;
    user_group: number;
    permissions: number;
    projects: Array<number> | typeof ALL_PROJECTS;
    confirmed: boolean
}

export enum UserRoleEnum {
    User = 'user',
    Admin = 'admin',
}
