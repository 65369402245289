import { useState } from 'react';
import { Action } from 'redux';
import { useAuthUserSelector, useDialog } from 'hooks';
import { useForm, useFormActionLoader, useFormActionNotifier } from 'hooks/form';
import { useSetDefaultProject } from 'hooks/user-managment';
import { UpdateStationLocation } from 'actions/base-station';
import { StationLocation } from 'models/base-station';
import { canViewOwner } from 'utils/permissions';
import { canDeleteStation } from 'utils/permissions';
import { changeLocationByAddress, getAddressByLocation } from 'components/BaseStation/utils';
import { Address } from 'components/BaseStation/types';
import { DeleteLocationDialog } from 'components/BaseStation/Dialogs';
// components
import { Box, Dialog } from '@material-ui/core';
import { InfoDialog } from 'components/Dialogs';
import { LocationFormField, StationLocationForm } from 'components/BaseStation';
import { MapBlock } from './widgets/MapBlock';
import { FormBlock } from './widgets/FormBlock';

interface Props {
  location: StationLocation;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onDeleteCallback?: () => void;
  // should be passed to the component if location is attached to the station. In case of removal, locaton should be unbind
  preDeleteAction?: Action
}

export const EditLocationDialog = ({ location, isOpen, onClose, onCloseEnd, preDeleteAction, onDeleteCallback }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const { doAction } = useFormActionLoader();
  const { notifySuccess } = useFormActionNotifier();
  const foundDialog = useDialog();
  const deleteDialog = useDialog();
  const [state, setState] = useState<StationLocation>(() => ({ ...location }));

  useSetDefaultProject({ state, setState });

  const form = useForm<StationLocation, LocationFormField>({
    initialState: location,
    state: state,
    onChange: setState,
    showFields: [
      ...(canViewOwner(user) ? [LocationFormField.owner_id] : []),
      LocationFormField.project,
      LocationFormField.country,
      LocationFormField.city,
      LocationFormField.address,
      LocationFormField.postcode,
      LocationFormField.notes,
    ],
  });

  const [found, setFound] = useState<Address | undefined>(undefined);
  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    if (found) {
      foundDialog.open();
      return false;
    }

    doAction({
      action: UpdateStationLocation(form.state),
      onSuccess: (newLocation: StationLocation) => {
        notifySuccess(`Base station location #${ newLocation.id } has been updated`);
        onClose();
      },
      onError: form.catchError,
    });
  };

  return (
    <>
      <Dialog
        open={ isOpen }
        onClose={ onClose }
        onExited={ onCloseEnd }
        fullScreen
      >
        <Box display="flex">
          <MapBlock
            found={ found }
            setFound={ setFound }
            address={ getAddressByLocation(state) }
            onApply={ (address: Address) => setState(changeLocationByAddress(state, address)) }
          />
          <FormBlock
            title="Edit location"
            btnAction="Save"
            onAction={ handleSave }
            onClose={ onClose }
            onDelete = {() => { deleteDialog.open(); }}
            canDeleteStation = {canDeleteStation(user)}
          >
            <StationLocationForm { ...form } />
          </FormBlock>
        </Box>
      </Dialog>
      { deleteDialog.isMounted && <DeleteLocationDialog
        location={ location }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
        onDelete={ preDeleteAction ? preDeleteAction : undefined }
        onDeleteCallback={ onDeleteCallback }
      /> }
      { foundDialog.isMounted && <InfoDialog
        name="unconfirmed-location-changes"
        label="Unconfirmed location changes"
        isOpen={ foundDialog.isOpen }
        onClose={ foundDialog.close }
        onCloseEnd={ foundDialog.unmount }
      >
          You've chosen the new position on the map, but didn't confirm changes.
          Please, CONFIRM the new position on the map or CANCEL the changes.
      </InfoDialog> }
    </>
  );
};
