import * as Actions from 'actions/caller';
import { GetRoutersParams, Router } from '../models/caller';
import { RouterDialog } from '../actions/caller';

export interface State {
  isFetching: boolean;
  params: GetRoutersParams;
  data: Router[];
  locked: Set<number>;
  dialog: {
    open: RouterDialog;
    router?: Router;
  };
}

const initState: State = {
  isFetching: false,
  params: {},
  data: [],
  locked: new Set<number>(),
  dialog: { open: RouterDialog.NONE },
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.CALLER_ROUTER_DIALOG_CLOSE:
      return { ...state, dialog: { open: RouterDialog.NONE } };
    case Actions.CALLER_ROUTER_DIALOG_OPEN_CREATE:
      return { ...state, dialog: { open: RouterDialog.CREATE } };
    case Actions.CALLER_ROUTER_DIALOG_OPEN_UPDATE:
      return { ...state, dialog: { open: RouterDialog.UPDATE, router: action.router } };
    case Actions.CALLER_ROUTER_DIALOG_OPEN_DELETE:
      return { ...state, dialog: { open: RouterDialog.DELETE, router: action.router } };
    case Actions.CALLER_ROUTER_DIALOG_OPEN_ACTIVITY:
      return { ...state, dialog: { open: RouterDialog.ACTIVITY, router: action.router } };

    case Actions.CALLER_ROUTER_LOCK:
      state.locked.add(action.routerId);
      return { ...state, locked: new Set<number>([...state.locked]) };
    case Actions.CALLER_ROUTER_UNLOCK:
      state.locked.delete(action.routerId);
      return { ...state, locked: new Set<number>([...state.locked]) };

    case Actions.CALLER_ROUTER_DATA_FETCH_START:
      return { ...state, isFetching: true };
    case Actions.CALLER_ROUTER_DATA_FETCH_STOP:
      return { ...state, isFetching: false };
    case Actions.CALLER_ROUTER_DATA_CHANGE:
      return { ...state, params: action.params, data: action.data };
    default:
      return state;
  }
};
