import React from 'react';
// components
import Cancel from '@material-ui/icons/Cancel';
import CommonButton, { CommonButtonProps } from './CommonButton';

export type CancelButtonProps = Omit<CommonButtonProps, 'icon' | 'label' | 'type'> & {
  label?: CommonButtonProps['label'];
  type?: CommonButtonProps['type'];
};

export const CancelButton = (props: CancelButtonProps): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Cancel';
  const type = props.type !== undefined ? props.type : 'icon';
  return <CommonButton
    { ...props }
    type={ type }
    label={ label }
    icon={ type === 'text' ? undefined : <Cancel /> }
  />;
};

export default CancelButton;
