import { fetchGroupsTotalSilentDevices } from '../../../api';
import { getGroupNodes } from '../../../node/utils';
import { GroupNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillGroupTotalSilentDevices({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, GroupNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getGroupNodes(getNodes(nodes)),
    getQuery: node => node.totalSilentDevices,
    fetchDataByNode: fetchGroupsTotalSilentDevices,
  });
}
