import { StationEntity } from 'models/base-station';
import { useLoraWANStationsDictionarySelector, useStationsDictionarySelector } from 'hooks/station';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number | string>, 'options'>>
  & (SingleProps<number | string> | MultiProps<number | string>)
  & { filter?: (station: StationEntity) => boolean }

export const StationSelectControl = (props: Props): JSX.Element => {
  const { isLoading, stations } = useStationsDictionarySelector();
  const { isLoading: isLoraStationsLoading, stations: loraStations } = useLoraWANStationsDictionarySelector();
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<number | string>[] =[
    ...stations.filter(filterCallback)
      .map(station => ({
        value: station.id,
        label: String(station.id),
      })),
    ...loraStations.filter(filterCallback)
      .map(station => ({
        value: station.id,
        label: String(station.eui),
      }))];

  return (
    <SelectControl
      name="bs"
      label="Base station"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading || isLoraStationsLoading }
      checkSelected={ !isLoading && !isLoraStationsLoading }
      isDisabled={ !options.length || props.isDisabled }
    />
  );
};
