import { call, put, takeLeading } from 'redux-saga/effects';
import { createErrorFromApiResponse } from 'utils/errors';
import { fetchEnumSchemas } from 'clients/monitoring';
import { ApiResponse, ReasonEnum } from 'models';
import { NotifyError } from 'actions/notifier';

import * as Actions from 'actions/monitoring/schema-enum';
import { ErrorType, Reason, SchemaEnum } from 'models/monitoring';

function* loadMonitoringSchemaEnum() {
  try {
    yield put(Actions.LoadMonitoringEnumStart());
    const response: ApiResponse = yield call(fetchEnumSchemas);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    type Item = { id: string; value: string };
    const schema: SchemaEnum = {
      global: { errorTypes: [], reasons: [] },
      forIncidents: { errorTypes: [], reasons: [] },
      forDeviceStates: { errorTypes: [], reasons: [] },
    };

    const errorTypes: Item[] = response.data.error_types ?? [];
    const deviceErrorTypes: string[] = response.data.available_for_device_states.error_types ?? [];
    const incidentErrorTypes: string[] = response.data.available_for_incidents.error_types ?? [];
    errorTypes.forEach(item => {
      const errorType: ErrorType = { id: item.id, name: item.value };
      schema.global.errorTypes.push(errorType);
      deviceErrorTypes.includes(item.id) && schema.forDeviceStates.errorTypes.push(errorType);
      incidentErrorTypes.includes(item.id) && schema.forIncidents.errorTypes.push(errorType);
    });

    const reasons: Item[] = response.data.reasons ?? [];
    const deviceReasons: string[] = response.data.available_for_device_states.reasons ?? [];
    const incidentReasons: string[] = response.data.available_for_incidents.reasons ?? [];
    reasons.forEach(item => {
      const reason: Reason = { id: item.id, name: item.value };
      schema.global.reasons.push(reason);
      deviceReasons.includes(item.id) && schema.forDeviceStates.reasons.push(reason);
      incidentReasons.includes(item.id) && schema.forIncidents.reasons.push(reason);
    });


    yield put(Actions.LoadMonitoringEnumSuccess(schema));
  } catch (e) {
    yield put(Actions.LoadMonitoringEnumFailure((e as Error).message));
    yield put(NotifyError(`Error while fetching monitoring schema enum: ${ (e as Error).message }`));
  }
}

export default [
  takeLeading(Actions.MONITORING_SCHEMA_ENUM_LOAD, loadMonitoringSchemaEnum),
];
