import React from 'react';

// components
import { Dialog, DialogProps } from 'components';

// styles
import clsx from 'clsx';
import useDialogStyles from 'styles/dialogs';

export function StyledDialog(props: DialogProps) {
  const dialogStyles = useDialogStyles();

  return (
    <Dialog
      {...props}
      PaperProps={{
        ...props.PaperProps,
        className: clsx(dialogStyles.dialog, props.PaperProps?.className),
      }}
    />
  );
}
