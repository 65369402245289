import React from 'react';
import { PositionNode, Tree, TreeNode } from 'hooks/device-management/useTree/types';
import { calculateTotal } from 'hooks/device-management/useTree/utils';
// components
import { InlineLoader } from 'components/Loaders';

interface Props {
  tree: Tree;
  node: TreeNode;
}

export const DevicesPositionsCell = (props: Props) => {
  return <>{ renderNode(props) }</>;
};

function renderNode(props: Props) {
  switch (props.node.type) {
    case 'zone':
    case 'level':
    case 'group':
      return <TotalNodeCell { ...props }/>;
    case 'position':
      return <PositionNodeCell { ...props }/>;
    default:
      return null;
  }
}

const TotalNodeCell = ({ tree, node }: Props) => {
  return (
    <>
      { tree.loaded.device ? calculateTotal(node, 'devices') : <InlineLoader/> }
      { ' / ' }
      { tree.loaded.position ? calculateTotal(node, 'children') : <InlineLoader/> }
    </>
  );
};

const PositionNodeCell = ({ tree, node }: Props) => {
  return (
    <>
      { tree.loaded.device ? ((node as PositionNode).device?.device_id.toUpperCase() ?? 'No') : <InlineLoader/> }
    </>
  );
};