import * as Actions from 'actions/parking-monitoring/groups';
import { ParkingAnomalyByGroupId, GroupsParkingAnomaly } from 'models/parking-monitoring/groups';

export interface State {
  data: GroupsParkingAnomaly[];
  byGroupId?: ParkingAnomalyByGroupId;
  isFetching: boolean;
}

export const initState: State = {
  data: [],
  byGroupId: {},
  isFetching: false,
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.GET_GROUPS_PARKING_ANOMALY_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case Actions.GET_BY_GROUP_PARKING_ANOMALY:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.GET_BY_GROUP_PARKING_ANOMALY_SUCCESS:
      const { occupy_max_interval, availability_max_interval } = action.payload;
      return {
        ...state,
        byGroupId: {
          ...action.payload,
          occupy_max_interval: occupy_max_interval ? Math.trunc( occupy_max_interval / 3600) : undefined,
          availability_max_interval: availability_max_interval ? Math.trunc( availability_max_interval / 3600) : undefined
        },
        isFetching: false,
      };
    case Actions.GET_BY_GROUP_PARKING_ANOMALY_FAILED:
      return {
        ...state,
        byGroupId: undefined,
        isFetching: false
      };
    default:
      return state;
  }
};
