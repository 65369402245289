import { useState } from 'react';
import { useLorawanStationUpdate } from 'hooks/station';
import { LorawanStation } from 'models/base-station';
// components
import { Button } from 'components';
import { CommonDialog } from 'components/Dialogs';
import { OwnersSingleSelectControl } from 'components/Controls';
// styles

interface Props {
  station: LorawanStation;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const EditLorawanDialog = (props: Props): JSX.Element => {
  const { station, isOpen, onClose, onCloseEnd } = props;
  const [owner, setOwner] = useState<number | undefined>(() => station.owner_id);
  const { updateLorawanStation, isUpdating } = useLorawanStationUpdate();

  const handleSave = async () => {
    if (owner) {
      await updateLorawanStation({
        ...station,
        owner_id: owner
      });
    }
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit lorawan station #${ station.id }` }
      content={ <>
        <OwnersSingleSelectControl
          isRequired
          isClearable={ true }
          selected={ owner}
          changeHandler={ setOwner }
        />
      </>}
      actions={
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={ handleSave }
            pending={ isUpdating }
            disabled={ !owner }
          >
            Save
          </Button>
        </>
      }
    />
  );
};
