import moment from 'moment';
import React from 'react';

// components
import { DatumValue, ResponsiveLine as NivoResponsiveLine } from '@nivo/line';
import { Box } from '@material-ui/core';

type ResponsiveLineProps = React.ComponentPropsWithoutRef<typeof NivoResponsiveLine>;

function formatDatum(datum: DatumValue): string {
  if (datum instanceof Date) {
    return datum.toJSON();
  }

  if (typeof datum === 'string') {
    const datumMoment = moment(datum);

    if (datumMoment.isValid()) {
      return datumMoment.toJSON();
    }

    return datum;
  }

  return datum.toString();
}

export const ResponsiveLine = (props: ResponsiveLineProps) => {
  const firstXs = props.data.flatMap(serie => serie.data.length ? [serie.data[0].x] : []);
  const firstX = firstXs.length ? firstXs[0] : undefined;
  const lastXs = props.data.flatMap(serie => serie.data.length ? [serie.data[serie.data.length - 1].x] : []);
  const lastX = lastXs.length ? lastXs[0] : undefined;

  return (
    <Box
      width="100%"
      height="100%"
      data-testid="responsive-line"
      data-first-x={ firstX ? formatDatum(firstX) : firstX }
      data-last-x={ lastX ? formatDatum(lastX) : lastX }
    >
      <NivoResponsiveLine { ...props } />
    </Box>
  );
};