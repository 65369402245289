import { getUrlItems, UrlItems } from 'utils/routing/query';
import { BS_RESPONSIBLE_PERSONS_UPDATE_FROM_URL, BS_RESPONSIBLE_PERSONS_SET_FILTERS, Action } from 'actions/pages/bs-responsible-persons';
import { ResponsiblePersonsFilterFields as Fields, BSResponsiblePersonsFields } from 'models/base-station';

export interface State {
  readonly filters: BSResponsiblePersonsFields;
}

export const defaultFilters = {
  role: undefined,
  owner: undefined,
  user: undefined
};

const initialState: State = {
  filters: { ...defaultFilters },
};


function getFiltersFromUrl(urlState: UrlItems): BSResponsiblePersonsFields {
  return {
    [Fields.role]: urlState[Fields.role] ? urlState[Fields.role] : undefined,
    [Fields.owner]: urlState[Fields.owner] ? urlState[Fields.owner] : undefined,
    [Fields.user]: urlState[Fields.user] ? urlState[Fields.user] : undefined
  };
}

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case BS_RESPONSIBLE_PERSONS_UPDATE_FROM_URL:
      const urlState = getUrlItems(Object.values(Fields));
      return {
        ...state,
        filters: {
          ...state.filters,
          ...getFiltersFromUrl(urlState),
        }
      };
    case BS_RESPONSIBLE_PERSONS_SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    default:
      return state;
  }
};
