import { ApiKey } from 'models/user-management';

export const UM_API_KEY_CREATE = 'UM_API_KEY_CREATE';
export const UM_API_KEY_UPDATE = 'UM_API_KEY_UPDATE';
export const UM_API_KEY_DELETE = 'UM_API_KEY_DELETE';
export const UM_API_KEY_CHANGE = 'UM_API_KEY_CHANGE';
export const UM_API_KEY_LOCK = 'UM_API_KEY_LOCK';
export const UM_API_KEY_UNLOCK = 'UM_API_KEY_UNLOCK';
export const UM_API_KEY_REFRESH_PIN = 'UM_API_KEY_REFRESH_PIN';

export interface CreateApiKey {
  readonly type: typeof UM_API_KEY_CREATE;
  apiKey: ApiKey;
}

export interface UpdateApiKey {
  readonly type: typeof UM_API_KEY_UPDATE;
  apiKey: ApiKey;
}

export interface DeleteApiKey {
  readonly type: typeof UM_API_KEY_DELETE;
  apiKey: ApiKey;
}

export interface ChangeApiKey {
  readonly type: typeof UM_API_KEY_CHANGE;
  apiKey: ApiKey;
  options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export interface LockApiKey {
  readonly type: typeof UM_API_KEY_LOCK;
  apiKeyId: string;
}

export interface UnlockApiKey {
  readonly type: typeof UM_API_KEY_UNLOCK;
  apiKeyId: string;
}

export const CreateApiKey = (apiKey: ApiKey): CreateApiKey => ({
  type: UM_API_KEY_CREATE, apiKey
});

export const UpdateApiKey = (apiKey: ApiKey): UpdateApiKey => ({
  type: UM_API_KEY_UPDATE, apiKey
});

export const DeleteApiKey = (apiKey: ApiKey): DeleteApiKey => ({
  type: UM_API_KEY_DELETE, apiKey
});

export const ChangeApiKey = (apiKey: ApiKey, options: ChangeApiKey['options']): ChangeApiKey => ({
  type: UM_API_KEY_CHANGE, apiKey, options
});

export const LockApiKey = (apiKeyId: string): LockApiKey => ({
  type: UM_API_KEY_LOCK, apiKeyId
});

export const UnlockApiKey = (apiKeyId: string): UnlockApiKey => ({
  type: UM_API_KEY_UNLOCK, apiKeyId
});

export type Action =
  | CreateApiKey
  | UpdateApiKey
  | DeleteApiKey
  | ChangeApiKey
  | LockApiKey
  | UnlockApiKey
