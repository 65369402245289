import { fetchLevelTotalChildren } from '../../../api';
import { getLevelNodes } from '../../../node/utils';
import { LevelNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillLevelTotalChildren({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, LevelNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getLevelNodes(getNodes(nodes)).filter((node) => {
      // We don't fetch children total if it was marked successfuly fetched
      // already. It happens when we initialize tree in a bottom-up way where
      // we insert children and childrenTotal manually. In such case, fetching
      // here doesn't seem to make sense since children are basically static
      // because of bottom-up filters.
      return node.childrenTotal.status !== 'success';
    }),
    getQuery: node => node.childrenTotal,
    fetchDataByNode: fetchLevelTotalChildren,
  });
}
