import { ReactNode } from 'react';

// components
import {  Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { LoaderSvg } from 'components/Loaders';

// styles
import { useStyles } from './styles';

type Props = {
  value?: number;
  isLoading: boolean;
  title: string;
  positiveLabel: ReactNode;
  negativeLabel: ReactNode;
  noIcons?: boolean;
}

export const StatusComponent = ({
  value,
  isLoading,
  title,
  positiveLabel,
  negativeLabel,
  noIcons
}: Props): JSX.Element => {
  const classes = useStyles();

  return <Typography className={ classes.statusField } data-testid="monitoring-field">
    <span data-testid="field-label" className={ classes.statusLabel }>
      {title}
    </span>
    <span data-testid="field-value" className={ classes.statusValue }>
      {
        isLoading ? <LoaderSvg className={ classes.fieldValueIcon } /> :
          value
            ? <>{positiveLabel}  { !noIcons && <CheckCircleIcon color="primary" className={ classes.fieldValueIcon } /> }</>
            : <>{negativeLabel}  { !noIcons && <CancelIcon color="error" className={ classes.fieldValueIcon } /> }</>
      }
    </span>
  </Typography>;
};