import { makeStyles, createStyles } from 'styles/utils';

export const useTemplatePresetControlStyles = makeStyles(theme => createStyles({
  root: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  preset: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    padding: theme.spacing(1, 0.5, 1, 2),
    border: '1px solid #979797',
    borderRadius: '4px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  presetName: {
    color: theme.palette.text.primary,
    fontSize: '16px',
  },
  presetDescription: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  presetDescriptionText: {
    opacity: 0.54,
  },
  presetDocsLink: {
    fontWeight: 500,
  },
}));