import { useQuery } from 'react-query';
import { ensureRequestSucceeded } from 'utils/clients';
import { useFormActionNotifier } from 'hooks/form';
import {
  ReceivedBox,
  ReceivedBoxesFilter,
  ReceivedDevice,
  ReceivedDevicesFilter,
  ResponseReceivedBoxes,
  Shipment,
  ShipmentStatusEnum,
} from 'models/provisioning';
import { fetchProvisioningReceivedBoxes, fetchProvisioningReceivedDevices } from 'clients/provisioning';

interface ReceivedBoxesParams {
  filter: ReceivedBoxesFilter;
  enabled?: boolean;
}

interface ReceivedBoxesState {
  isLoading: boolean;
  isFetching: boolean;
  boxes: ReceivedBox[];
  total: number;
}

export function useProvisioningReceivedBoxes({ filter, enabled }: ReceivedBoxesParams): ReceivedBoxesState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['provisioning/boxes/received', filter],
    queryFn: async (): Promise<ResponseReceivedBoxes> => {
      const result = await fetchProvisioningReceivedBoxes(filter);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching received boxes: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    isFetching: queryResult.isFetching,
    boxes: queryResult.data?.data ?? [],
    total: queryResult.data?.total ?? 0,
  };
}

const receivedStatus: ShipmentStatusEnum[] = [ShipmentStatusEnum.Received, ShipmentStatusEnum.InAWarehouse];
export const isShipmentReceived = (shipment: Shipment): boolean => {
  if (!shipment.status) {
    return false;
  }

  return receivedStatus.includes(shipment.status);
};

interface ReceivedDevicesParams {
  filter: ReceivedDevicesFilter;
  enabled?: boolean;
}

interface ReceivedDevicesState {
  isLoading: boolean;
  isFetching: boolean;
  devices: ReceivedDevice[];
  total: number;
}

export function useProvisioningReceivedDevices({ filter, enabled }: ReceivedDevicesParams): ReceivedDevicesState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['provisioning/devices/received', filter],
    queryFn: async (): Promise<ResponseReceivedBoxes> => {
      const result = await fetchProvisioningReceivedDevices(filter);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching received devices: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    isFetching: queryResult.isFetching,
    devices: queryResult.data?.data ?? [],
    total: queryResult.data?.total ?? 0,
  };
}