import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import { ApiResponse, ApiResponseWithTotal, ReasonEnum } from 'models';
import { Rule } from 'models/rabbit';
import { createRule, deleteRule, getRule, getRules, patchRule } from 'clients/rabbit';
import * as Action from 'actions/rabbit/rules';
import * as DataAction from 'actions/rabbit/rule-data';
import { NotifyError, NotifySuccess } from 'actions/notifier';

type ActivateAction =
  | Action.ActivateRule
  | ActionWithPromise<Action.ActivateRule, Rule>
function* doActivateRule(action: ActivateAction) {
  yield put(Action.LockRule(Number(action.rule.id)));
  const response: ApiResponse<Rule> = yield call(patchRule, { ...action.rule, activity: true });
  yield put(Action.UnlockRule(Number(action.rule.id)));
  if (response.reason === ReasonEnum.Ok && response.data) {
    const newRule: Rule = response.data;
    yield put(Action.ChangeRule(newRule, { update: true }));
    yield put(NotifySuccess('Route has been activated'));
    'meta' in action && action.meta.promise.resolve(newRule);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while activating a route: ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type DeactivateAction =
  | Action.DeactivateRule
  | ActionWithPromise<Action.DeactivateRule, Rule>
function* doDeactivateRule(action: DeactivateAction) {
  yield put(Action.LockRule(Number(action.rule.id)));
  const response: ApiResponse<Rule> = yield call(patchRule, { ...action.rule, activity: false });
  yield put(Action.UnlockRule(Number(action.rule.id)));
  if (response.reason === ReasonEnum.Ok && response.data) {
    const newRule: Rule = response.data;
    yield put(Action.ChangeRule(newRule, { update: true }));
    yield put(NotifySuccess('Route has been deactivated'));
    'meta' in action && action.meta.promise.resolve(newRule);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deactivating a route: ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type CreateAction =
  | Action.CreateRule
  | ActionWithPromise<Action.CreateRule, Rule>
function* doCreateRule(action: CreateAction) {
  yield put(Action.LockRule(Number(action.rule.id)));
  const response: ApiResponse<Rule> = yield call(createRule, { ...action.rule });
  yield put(Action.UnlockRule(Number(action.rule.id)));
  if (response.reason === ReasonEnum.Ok && response.data) {
    const newRule: Rule = response.data;
    yield put(Action.ChangeRule(newRule, { create: true }));
    yield put(NotifySuccess('Route has been created'));
    'meta' in action && action.meta.promise.resolve(newRule);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while creating a route: ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type UpdateAction =
  | Action.UpdateRule
  | ActionWithPromise<Action.UpdateRule, Rule>
function* doUpdateRule(action: UpdateAction) {
  yield put(Action.LockRule(Number(action.rule.id)));
  const response: ApiResponse<Rule> = yield call(patchRule, { ...action.rule });
  yield put(Action.UnlockRule(Number(action.rule.id)));
  if (response.reason === ReasonEnum.Ok && response.data) {
    const newRule: Rule = response.data;
    yield put(Action.ChangeRule(newRule, { create: true }));
    yield put(NotifySuccess('Route has been updated'));
    'meta' in action && action.meta.promise.resolve(newRule);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while updating a route: ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type DeleteAction =
  | Action.DeleteRule
  | ActionWithPromise<Action.DeleteRule, Rule>
function* doDeleteRule(action: DeleteAction) {
  yield put(Action.LockRule(Number(action.rule.id)));
  const response: ApiResponse = yield call(deleteRule, action.rule);
  yield put(Action.UnlockRule(Number(action.rule.id)));
  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeRule(action.rule, { delete: true }));
    yield put(NotifySuccess('Route has been deleted'));
    'meta' in action && action.meta.promise.resolve(action.rule);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting a route: ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

function* loadRuleDataById(action: DataAction.LoadRabbitRuleDataById) {
  yield put(DataAction.LoadRabbitRuleDataStart(action.ruleId));
  const response: ApiResponse<Rule> = yield call(getRule, action.ruleId);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DataAction.LoadRabbitRuleDataSuccess(action.ruleId, [response.data], 1));
  } else {
    const message = response.message || 'Server error';
    yield put(DataAction.LoadRabbitRuleDataFailure(action.ruleId, message));
    yield put(NotifyError(`Error while fetching a route: ${ message }`));
  }
}

function* loadRuleDataByParams(action: DataAction.LoadRabbitRuleDataByParams) {
  yield put(DataAction.LoadRabbitRuleDataStart(action.params));
  const response: ApiResponseWithTotal = yield call(getRules, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(DataAction.LoadRabbitRuleDataSuccess(action.params, response.data, response.total));
  } else {
    const message = response.message || 'Server error';
    yield put(DataAction.LoadRabbitRuleDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching a route: ${ message }`));
  }
}

function* loadRuleDataDictionary(_action: DataAction.LoadRabbitRuleDataDictionary) {
  yield put(DataAction.LoadRabbitRuleDataStart({}));
  const response: ApiResponseWithTotal = yield call(getRules, { limit: 999 });
  if (response.reason === ReasonEnum.Ok) {
    yield put(DataAction.LoadRabbitRuleDataSuccess({}, response.data, response.total));
  } else {
    const message = response.message || 'Server error';
    yield put(DataAction.LoadRabbitRuleDataFailure({}, message));
    yield put(NotifyError(`Error while fetching a route: ${ message }`));
  }
}

export default [
  takeEvery(Action.RABBIT_RULE_ACTIVATE, doActivateRule),
  takeEvery(Action.RABBIT_RULE_DEACTIVATE, doDeactivateRule),
  takeEvery(Action.RABBIT_RULE_DELETE, doDeleteRule),
  takeEvery(Action.RABBIT_RULE_UPDATE, doUpdateRule),
  takeEvery(Action.RABBIT_RULE_CREATE, doCreateRule),
  takeEvery(DataAction.RABBIT_RULE_DATA_LOAD_BY_ID, loadRuleDataById),
  takeEvery(DataAction.RABBIT_RULE_DATA_LOAD_BY_PARAMS, loadRuleDataByParams),
  takeLeading(DataAction.RABBIT_RULE_DATA_LOAD_DICTIONARY, loadRuleDataDictionary),
];
