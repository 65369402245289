import React from 'react';
import { useFormActionLoader } from 'hooks';
import { RemoveSuiteAdditionalData } from './types';
import { DoRoutesTemplateDelete } from 'actions/caller';

// components
import { EnsureDialog } from 'components/Dialogs';
import { ActionLoaderProps } from 'components/Loaders';

interface Props {
  data: RemoveSuiteAdditionalData;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: ActionLoaderProps['onSuccess'];
}

export const DeleteDialog = (props: Props): JSX.Element => {
  const { data, isOpen, onClose, onCloseEnd, onSuccess } = props;
  const { doAction } = useFormActionLoader();

  const handleDelete = () => doAction({
    action: DoRoutesTemplateDelete(data.suite, data.routes),
    onSuccess: onSuccess,
    onFinally: onClose,
  });

  return (
    <EnsureDialog
      name="delete-template-suite"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
      label="Removing confirmation"
      btnYes="Confirm"
    >
      Please confirm removing Template Suite <b>{ `${data.suite.name}` }</b>
    </EnsureDialog>
  );
};
