import { isNil } from 'lodash';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useLoraWANStationsDictionarySelector, useStationLocationsDictionarySelector, useStationsDictionarySelector } from 'hooks/station';
import { FlashStation, LocationStation, StationEntity, StationType } from 'models/base-station';
import { PageData } from '../types';

import { RootState } from 'reducers';
import {
  LoadStationFlashDataByParams,
  LoadStationStatusDataByParams,
  SyncStationFlashDataStart,
  SyncStationFlashDataStop
} from 'actions/base-station';
import { FilterState, HealthyStatus, OnlineStatus } from 'components/BaseStation/StationFilter/types';

interface StationsState {
  isLoading: boolean;
  stations: StationEntity[];
}

export const useStationsByFilters = (filter: FilterState): PageData => {
  // BASE STATIONS AND FLASH
  const stationState = useStationsDictionarySelector();
  let stations: StationEntity[] = stationState.stations.map(station => ({ ...station, type: StationType.station }));
  const stationsWithFlash = useFlashStations(stations); // add data about flash-staton
  // LORAWAN STATIONS
  const lorawanStationState = useLoraWANStationsDictionarySelector();
  if (!lorawanStationState.isLoading && lorawanStationState.stations.length > 0) {
    stations = [...stationsWithFlash.stations, ...lorawanStationState.stations];
  }
  // LOCATIONS
  const freeLocationsState = useFreeLocation();
  stations = [...stations, ...freeLocationsState.freeLocations];
  // add status for only non-lorawan stations
  const stationsWithStatusState = useStationsStatus(stationState.isLoading, stations); // add statuses

  const isUserHasStations = stations.length > 0;

  // FILTER
  stations = stationsWithStatusState.stations.filter(station => isStationFiltered(station, filter));
  stations = stations.filter(station => isStatusFiltered(station, filter));

  return {
    isLoading: stationState.isLoading || lorawanStationState.isLoading || freeLocationsState.isLoading || stationsWithFlash.isLoading || stationsWithStatusState.isLoading,
    all: stations,
    total: stations.length,
    isUserHasStations
  };
};

const useFreeLocation = () => {
  const { isLoading, locations } = useStationLocationsDictionarySelector();

  const freeLocations: LocationStation[] = locations
    .filter(l => !l.station_id)
    .map(location => ({
      type: StationType.location,
      owner_id: location.owner_id,
      location: location,
    }));
  return {
    isLoading: isLoading,
    freeLocations,
  };
};

const useFlashStations = (stations: StationEntity[]): StationsState => {
  const dispatch = useDispatch();
  const stationsFlash = useSelector((state: RootState) => state.baseStation.stationFlash.findAll(), shallowEqual);

  useEffect(() => {
    dispatch(LoadStationFlashDataByParams({}));
    dispatch(SyncStationFlashDataStart());
    return () => {
      dispatch(SyncStationFlashDataStop());
    };
  }, [dispatch]);

  stationsFlash.forEach(flash => {
    const stationIndex = stations.findIndex(s => s.id === flash.id);
    if (stationIndex >= 0) {
      delete stations[stationIndex];
    }

    const flashStation: FlashStation = {
      type: StationType.flash,
      id: flash.id,
      owner_id: flash.owner_id,
      generation: flash.generation,
      receiver_type: flash.receiver_type,
      frequency: flash.frequency,
      flash: flash,
    };
    stations.push(flashStation);
  });

  return {
    isLoading: false,
    stations: stations
  };
};

const useStationsStatus = (isLoading: boolean, stations: StationEntity[]): StationsState => {
  const dispatch = useDispatch();

  const stationIds: number[] = stations
    .filter(s => s.type === 'station')
    .map(s => s.id) as number[];

  useDeepCompareEffect(() => {
    if (stationIds.length) {
      dispatch(LoadStationStatusDataByParams({ stationIds }));
    }
  }, [stationIds]); // reload on change filter or station list

  return useSelector((state: RootState) => {
    if (isLoading || stationIds.length === 0) {
      return { isLoading: false, stations: stations };
    }

    const statuses = state.baseStation.stationStatus.findByParams({ stationIds });
    return {
      isLoading: state.baseStation.stationStatus.isFetched({ stationIds }) !== true,
      stations: stations.map(station => ({
        ...station,
        status: station.type !== StationType.lorawan ? statuses.find(s => s.stationId === station.id) : station.status
      })),
    };
  }, shallowEqual);
};

const isStationFiltered = (station: StationEntity, filter: FilterState): boolean => {
  if (!isNil(filter.owner) && station.owner_id !== filter.owner) {
    return false;
  }

  // initState for filter.projects is []
  if (filter.projects.length > 0 && !(station.location?.project && filter.projects.includes(station.location.project))) {
    return false;
  }

  if (!isNil(filter.stationId) && station.id !== filter.stationId) {
    return false;
  }

  if (!isNil(filter.eui) && ((station.type !== StationType.lorawan) || (station.type === StationType.lorawan && station.eui !== filter.eui))) {
    return false;
  }

  if (!isNil(filter.country) && station.location?.country.toLocaleLowerCase() !== filter.country.toLocaleLowerCase()) {
    return false;
  }

  if (!isNil(filter.city) && station.location?.city.toLocaleLowerCase() !== filter.city.toLocaleLowerCase()) {
    return false;
  }

  // not LoRAWAN filters
  if (station.type !== StationType.lorawan) {
    if (!isNil(filter.generation) && station.generation !== filter.generation) {
      return false;
    }
  }

  return true;
};

const isStatusFiltered = (station: StationEntity, filter: FilterState): boolean => {
  if (!isNil(filter.onlineStatus)) {
    if (station.type === StationType.flash || station.type === StationType.location || !station.status) {
      return false;
    }

    if (filter.onlineStatus === OnlineStatus.yes && station.status.isOffline) {
      return false;
    }

    if (filter.onlineStatus === OnlineStatus.no && !station.status.isOffline) {
      return false;
    }
  }

  if (!isNil(filter.healthyStatus) && station.type !== StationType.lorawan) {
    if (station.type !== StationType.station || !station.status) {
      return false;
    }

    if (filter.healthyStatus === HealthyStatus.yes && station.status.hasIssues) {
      return false;
    }

    if (filter.healthyStatus === HealthyStatus.no && !station.status.hasIssues) {
      return false;
    }
  }

  return true;
};
