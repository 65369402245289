import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
      minWidth: 0,
    },
    button2: {
      marginLeft: theme.spacing(1),
      minWidth: 0,
    },
    wordBreak: {
      wordBreak: 'break-word',
    },
    error: {
      textAlign: 'center',
      color: theme.palette.error.main,
    },
    cellWithButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    root: {
      minWidth: 350,
      marginLeft: -theme.spacing(4),
      marginRight: -theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '100vw'
      }
    }
  }),
);

export default useStyles;
