import { isEqual } from 'lodash';
import { useState } from 'react';
import { Tree, TreeNode, TreeNodeType } from 'hooks/device-management/useTree/types';
import { useCustomMemo } from 'hooks';
import { TreeTableRow } from './types';

export function useTreeTableRows(tree: Tree, topNode?: TreeNode): TreeTableRow<TreeNode>[] {
  const [expandedState, setExpandedState] = useState<Record<string, boolean>>({});

  const buildRows = (items: TreeNode[], level = 0): TreeTableRow<TreeNode>[] => {
    const rows: TreeTableRow<TreeNode>[] = [];
    for (const item of items) {
      const row: TreeTableRow<TreeNode> = {
        node: item,
        level: level,
      };
      rows.push(row);
      if (item.type === 'position') {
        continue; // without children
      }

      row.childrenTotal = item.children.total;
      const expansionStatus = Boolean(expandedState[item.id]);
      if (item.children.total > 0) {
        row.expansion = {
          status: expansionStatus,
          toggle: () => setExpandedState({ ...expandedState, [item.id]: !expansionStatus }),
        };
      }

      if (expansionStatus) {
        rows.push(...buildRows(Object.values(item.children.items), level + 1));
      }
    }

    return rows;
  };

  return useCustomMemo((): TreeTableRow<TreeNode>[] => {
    switch (topNode?.type) {
      case 'zone':
      case 'level':
      case 'group':
        return buildRows(Object.values(topNode.children.items));
      default:
        return buildRows(Object.values(tree.items));
    }

  }, [tree, topNode, expandedState], isEqual);
}

export const getNameCellLabel = (topNode?: TreeNode): string => {
  const labels: Record<TreeNodeType, string> = {
    'zone': 'Zone',
    'level': 'Level',
    'group': 'Group Name',
    'position': 'Network ID',
  };

  if (!topNode) {
    return ['Project'].concat(Object.values(labels)).join('/');
  }

  const parts = [];
  for (const type of Object.keys(labels).reverse()) {
    if (type === topNode.type) {
      break;
    }

    parts.push(labels[type as TreeNodeType]);
  }

  return parts.reverse().join('/');
};


