import { VirtualDevice, FindDevicesParams, PositionedDeviceData } from 'models/device-management';

export const DM_VIRTUAL_DEVICE_DATA_LOAD_BY_PARAMS = 'dm/virtualDevices/LOAD_BY_PARAMS';
export const DM_VIRTUAL_DEVICE_DATA_LOAD_START = 'dm/virtualDevices/LOAD_START';
export const DM_VIRTUAL_DEVICE_DATA_LOAD_SUCCESS = 'dm/virtualDevices/LOAD_SUCCESS';
export const DM_VIRTUAL_DEVICE_DATA_LOAD_FAILURE = 'dm/virtualDevices/LOAD_FAILURE';

export const DM_VIRTUAL_DEVICE_CREATE = 'dm/virtualDevices/CREATE';
export const DM_VIRTUAL_DEVICE_DELETE = 'dm/virtualDevices/DELETE';

export const DM_VIRTUAL_DEVICE_ACTIVATE = 'dm/virtualDevices/ACTIVATE';
export const DM_VIRTUAL_DEVICE_ACTIVATE_SUCCESS = 'dm/virtualDevices/SUCCESS';

export interface LoadVirtualDeviceDataByParams {
  readonly type: typeof DM_VIRTUAL_DEVICE_DATA_LOAD_BY_PARAMS;
  readonly params: FindDevicesParams;
}

export interface LoadVirtualDeviceDataStart {
  readonly type: typeof DM_VIRTUAL_DEVICE_DATA_LOAD_START;
  readonly params: FindDevicesParams;
}

export interface LoadVirtualDeviceDataFailure {
  readonly type: typeof DM_VIRTUAL_DEVICE_DATA_LOAD_FAILURE;
  readonly params: FindDevicesParams;
  readonly error: string;
}

export interface LoadVirtualDeviceDataSuccess {
  readonly type: typeof DM_VIRTUAL_DEVICE_DATA_LOAD_SUCCESS;
  readonly params: FindDevicesParams;
  readonly data: PositionedDeviceData[];
}

export interface CreateVirtualDevice {
  readonly type: typeof DM_VIRTUAL_DEVICE_CREATE;
  readonly device: VirtualDevice;
  readonly count: number;
}

export interface DeleteVirtualDevice {
  readonly type: typeof DM_VIRTUAL_DEVICE_DELETE;
  readonly device: PositionedDeviceData;
}

export interface IActivateVirtualDevice {
  readonly type: typeof DM_VIRTUAL_DEVICE_ACTIVATE;
  readonly device: PositionedDeviceData;
}
export interface IActivateVirtualDeviceSuccess {
  readonly type: typeof DM_VIRTUAL_DEVICE_ACTIVATE_SUCCESS;
  readonly deviceId: PositionedDeviceData['device_id'];
}

export const LoadVirtualDeviceDataByParams = (params: FindDevicesParams): LoadVirtualDeviceDataByParams => ({
  type: DM_VIRTUAL_DEVICE_DATA_LOAD_BY_PARAMS, params
});

export const LoadVirtualDeviceDataStart = (params: FindDevicesParams): LoadVirtualDeviceDataStart => ({
  type: DM_VIRTUAL_DEVICE_DATA_LOAD_START, params
});

export const LoadVirtualDeviceDataFailure = (params: FindDevicesParams, error: string): LoadVirtualDeviceDataFailure => ({
  type: DM_VIRTUAL_DEVICE_DATA_LOAD_FAILURE, params, error
});

export const LoadVirtualDeviceDataSuccess = (params: FindDevicesParams, data: PositionedDeviceData[]): LoadVirtualDeviceDataSuccess => ({
  type: DM_VIRTUAL_DEVICE_DATA_LOAD_SUCCESS, params, data
});

export const CreateVirtualDevice = (device: VirtualDevice, count = 1): CreateVirtualDevice => ({
  type: DM_VIRTUAL_DEVICE_CREATE, device, count
});

export const DeleteVirtualDevice = (device: PositionedDeviceData): DeleteVirtualDevice => ({
  type: DM_VIRTUAL_DEVICE_DELETE, device
});

export const ActivateVirtualDevice = (device: PositionedDeviceData): IActivateVirtualDevice => ({
  type: DM_VIRTUAL_DEVICE_ACTIVATE, device
});

export const ActivateVirtualDeviceSuccess = (deviceId: PositionedDeviceData['device_id']): IActivateVirtualDeviceSuccess => ({
  type: DM_VIRTUAL_DEVICE_ACTIVATE_SUCCESS, deviceId
});

export type Action =
  | LoadVirtualDeviceDataByParams
  | LoadVirtualDeviceDataStart
  | LoadVirtualDeviceDataFailure
  | LoadVirtualDeviceDataSuccess
  | DeleteVirtualDevice
  | IActivateVirtualDevice
  | IActivateVirtualDeviceSuccess
