import React from 'react';
import { FormState } from 'form';
import { useAuthUserSelector } from 'hooks';
import { canViewOwner, isAllowedToDeletePosition, isAllowedToUpdatePosition } from 'utils/permissions';
import { PositionNode } from 'hooks/device-management/useTree/types';
import { FormField, FormState as PositionFormState } from './types';

// components
import { Box, Grid, Paper } from '@material-ui/core';
import { DeleteButton, DialogButton, EditButton, SaveButton } from 'components/Buttons';
import { TextControl } from 'components/Controls';
import { DeletePositionDialog, EditPositionLabelsDialog } from 'components/DeviceManagement';
import { DmLabels } from 'components/DmLabels';
import { PositionManagementForm } from './PositionManagementForm';

// styles
import useStyles from '../../style';

interface Props {
  node: PositionNode;
  form: FormState<PositionFormState, FormField>;
  onEdit: () => void;
  onSave: () => void;
  onDelete: () => void;
}

export const PositionManagement = ({ node, form, onEdit, onSave, onDelete }: Props): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const position = node.position;
  const zone = node.parent.parent.parent.zone;

  return (
    <Paper className={ classes.managementRoot } data-testid="dm-position-management">
      <Grid container spacing={ 1 } alignItems="center">
        <Grid item xs={ 8 }>
          <TextControl
            disabled
            label="Network ID"
            value={ position.network_id }
          />
        </Grid>
        <Grid item xs={ 4 }>
          <Box display="flex" justifyContent="flex-end">
            <DialogButton
              permission={ isAllowedToDeletePosition }
              button={ open => <DeleteButton type="text" label="Delete" onClick={ open }/> }
              dialog={ props =>
                <DeletePositionDialog
                  {... props}
                  position={ position }
                  device={ node.device }
                  onSuccess={ onDelete }
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
      <PositionManagementForm
        { ...form }
        disabled={ !isAllowedToUpdatePosition(user.data) }
        showOwner={ canViewOwner(user)}
      />
      <Grid container className={ classes.labelsRow } >
        <Grid item xs={ 8 }>
          <DmLabels labels={ position.labels ?? [] }/>
        </Grid>
        <Grid item xs={ 4 }>
          <Box display="flex" justifyContent="flex-end">
            <DialogButton
              permission={ isAllowedToUpdatePosition }
              button={ open => <EditButton type="text" label="EDIT LABELS" onClick={ open }/> }
              dialog={ props =>
                <EditPositionLabelsDialog
                  { ...props }
                  position={ position }
                  positionOwnerId={ zone.owner_id }
                  onSuccess={ () => {
                    onEdit();
                    props.onClose();
                  } }
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
      { isAllowedToUpdatePosition(user.data) && form.isChange && (
        <Box display="flex" justifyContent="flex-end">
          <SaveButton type="text" label="Save" onClick={ onSave } />
          <DeleteButton type="text" label="Cancel" onClick={ form.reset } />
        </Box>
      )}
    </Paper>
  );
};