import React from 'react';
import { Rule } from 'models/rabbit';

// components
import { ZoneLink } from 'components/Links';
import { ActiveStatusIcon } from 'components/Icons';

interface Props {
  rule: Rule;
}

export const ZoneCell = ({ rule }: Props): JSX.Element => {
  return (
    <>
      <ActiveStatusIcon status={ rule.activity ?? false }/>
      <ZoneLink zoneId={ Number(rule.zone_id) }/>
    </>
  );
};
