import {
  ArrayElement,
  Filters,
  TopmostEntityType,
  TreeNodeWithChildren,
  PositionNode,
  GroupNode,
  DmTreeNode,
  LevelNode,
  ZoneNode
} from '../../types';
import {
  createPositionNodes,
  createGroupNodes,
  createLevelNodes,
  createZoneNodes
} from '../../node/creators';
import { ChildrenData, StartFrom } from '../types';

export async function createTreeBottomUp(
  startFrom: StartFrom,
  filters: Filters
): Promise<ChildrenData> {
  const startingNodesData = await createStartingNodes(startFrom, filters);
  const nodes = await createParentNodes(startingNodesData.nodes, filters);
  expandNodesWithChildren(nodes);

  return {
    children: nodes,
    childrenTotal: startingNodesData.total
  };
}

interface NodesData {
  nodes: DmTreeNode[];
  total: number;
}

function createStartingNodes(
  startFrom: StartFrom,
  filters: Filters
): Promise<NodesData> {
  switch (startFrom) {
    case StartFrom.POSITION:
      return createPositionNodes(filters);

    case StartFrom.GROUP:
      return createGroupNodes(filters);

    case StartFrom.LEVEL:
      return createLevelNodes(filters);

    case StartFrom.ZONE:
      return createZoneNodes(filters);
  }
}

// TODO: throw an error with a readable message
// if parent entities haven't been found at any scope
async function createParentNodes(
  startingNodes: DmTreeNode[],
  filters: Filters
): Promise<DmTreeNode[]> {
  let parentNodes = startingNodes;

  if (!parentNodes.length) {
    return parentNodes;
  }

  if (filters.topmostEntityType < TopmostEntityType.GROUP) {
    return parentNodes;
  }

  // TODO: replace with `arePositions()`
  if (parentNodes[0].type === 'position') {
    parentNodes = await wrapPositionsInGroups(
      filters,
      parentNodes as PositionNode[]
    );
  }

  if (filters.topmostEntityType < TopmostEntityType.LEVEL) {
    return parentNodes;
  }

  if (parentNodes[0].type === 'group') {
    parentNodes = await wrapGroupsInLevels(filters, parentNodes as GroupNode[]);
  }

  if (filters.topmostEntityType < TopmostEntityType.ZONE) {
    return parentNodes;
  }

  if (parentNodes[0].type === 'level') {
    parentNodes = await wrapLevelsInZones(filters, parentNodes as LevelNode[]);
  }

  return parentNodes;
}

async function wrapPositionsInGroups(
  filters: Filters,
  positionNodes: PositionNode[]
): Promise<GroupNode[]> {
  const { nodes: groupNodes } = await createGroupNodes(filters, positionNodes);
  attachPositionNodesToGroupNodes(positionNodes, groupNodes);
  return groupNodes;
}

function attachPositionNodesToGroupNodes(
  positionNodes: PositionNode[],
  groupNodes: GroupNode[]
): void {
  positionNodes.forEach((positionNode) => {
    const groupId = positionNode.position.group_id;
    const groupNode = groupNodes.find((g) => g.group.id === groupId);

    if (!groupNode) {
      throw new Error(
        `Expected to find a group #${groupId} ` +
          `for position #${positionNode.position.id}, but got: ${groupNode}`
      );
    }

    attachChildToParent(positionNode, groupNode);
  });
}

async function wrapGroupsInLevels(
  filters: Filters,
  groupNodes: GroupNode[]
): Promise<LevelNode[]> {
  const { nodes: levelNodes } = await createLevelNodes(filters, groupNodes);
  attachGroupNodesToLevelNodes(groupNodes, levelNodes);
  return levelNodes;
}

function attachGroupNodesToLevelNodes(
  groupNodes: GroupNode[],
  levelNodes: LevelNode[]
): void {
  groupNodes.forEach((groupNode) => {
    const levelId = groupNode.group.level_id;
    const zoneId = groupNode.group.zone_id;
    const levelNode = levelNodes.find((ln) =>
      levelId
        ? ln.level?.id === levelId
        : ln.isNoLevelNode && zoneId === ln.zoneId
    );

    if (!levelNode) {
      throw new Error(
        `Expected to find a level ` +
          `for group #${groupNode.group.id}, but got: ${levelNode}`
      );
    }

    attachChildToParent(groupNode, levelNode);
  });
}

async function wrapLevelsInZones(
  filters: Filters,
  levelNodes: LevelNode[]
): Promise<ZoneNode[]> {
  const { nodes: zoneNodes } = await createZoneNodes(filters, levelNodes);
  attachLevelNodesToZoneNodes(zoneNodes, levelNodes);
  return zoneNodes;
}

function attachLevelNodesToZoneNodes(
  zoneNodes: ZoneNode[],
  levelNodes: LevelNode[]
): void {
  levelNodes.forEach((levelNode) => {
    const zoneId = levelNode.zoneId;
    const zoneNode = zoneNodes.find((zn) => zn.zone.id === zoneId);

    if (!zoneNode) {
      throw new Error(
        `Expected to find a zone #${zoneId} ` +
          `for level node #${levelNode.id}, but got: ${zoneNode}`
      );
    }

    attachChildToParent(levelNode, zoneNode);
  });
}

type Child<TNode extends TreeNodeWithChildren> = ArrayElement<TNode['children']>;

function attachChildToParent<TParent extends TreeNodeWithChildren>(
  child: Child<TParent>,
  parent: TParent
): void {
  child.parentId = parent.id;

  parent.children.push(child);
  parent.childrenLoadStatus = 'success';
  parent.childrenTotal.data = (parent.childrenTotal.data ?? 0) + 1;
  parent.childrenTotal.status = 'success';
}

function expandNodesWithChildren(nodes: DmTreeNode[]) {
  nodes.forEach((node) => {
    if ('children' in node && node.children.length) {
      node.expansionStatus = 'expanded';
      expandNodesWithChildren(node.children);
    }
  });
}
