import {
  ParkingAnomalyByPositionId,
  EditPositionDurationParkingAnomaly as DurationData,
  EditPositionParkingIgnoreAnomaly as IgnoreData,
  ParkingAnomalyPositionsFilters,
  PositionsParkingAnomaly
} from 'models/parking-monitoring';

export const GET_POSITIONS_PARKING_ANOMALY = 'GET_POSITIONS_PARKING_ANOMALY';
export const GET_POSITIONS_PARKING_ANOMALY_SUCCESS = 'GET_POSITIONS_PARKING_ANOMALY_SUCCESS';
export const GET_POSITIONS_PARKING_ANOMALY_FAILED = 'GET_POSITIONS_PARKING_ANOMALY_FAILED';

export const GET_BY_POSITION_ID_PARKING_ANOMALY = 'GET_BY_POSITION_ID_PARKING_ANOMALY';
export const GET_BY_POSITION_ID_PARKING_ANOMALY_SUCCESS= 'GET_BY_POSITION_ID_PARKING_ANOMALY_SUCCESS';
export const GET_BY_POSITION_ID_PARKING_ANOMALY_FAILED = 'GET_BY_POSITION_ID_PARKING_ANOMALY_FAILED';

export const SET_POSITION_PARKING_ANOMALY_DURATION = 'SET_POSITION_PARKING_ANOMALY_DURATION';
export const SET_POSITION_PARKING_ANOMALY_IGNORE = 'SET_POSITION_PARKING_ANOMALY_IGNORE';
export const SET_POSITION_PARKING_ANOMALY_SUCCESS = 'SET_POSITION_PARKING_ANOMALY_SUCCESS';

export const EDIT_POSITION_DURATION_PARKING_ANOMALY = 'EDIT_POSITION_DURATION_PARKING_ANOMALY';
export const EDIT_POSITION_DURATION_PARKING_ANOMALY_SUCCESS = 'EDIT_POSITION_DURATION_PARKING_ANOMALY_SUCCESS';

export const EDIT_POSITION_IGNORE_PARKING_ANOMALY = 'EDIT_POSITION_IGNORE_PARKING_ANOMALY';
export const EDIT_POSITION_IGNORE_PARKING_ANOMALY_SUCCESS = 'EDIT_POSITION_IGNORE_PARKING_ANOMALY_SUCCESS';

export interface GetPositionsParkingAnomaly {
  type: typeof GET_POSITIONS_PARKING_ANOMALY;
  filters: ParkingAnomalyPositionsFilters;
}

export interface GetPositionsParkingAnomalySuccess {
  type: typeof GET_POSITIONS_PARKING_ANOMALY_SUCCESS;
  data: PositionsParkingAnomaly[];
}

export interface GetPositionsParkingAnomalyFailed {
  type: typeof GET_POSITIONS_PARKING_ANOMALY_FAILED;
}

export interface GetByPositionIdParkingAnomaly {
  type: typeof GET_BY_POSITION_ID_PARKING_ANOMALY;
  id: number;
}
export interface GetByPositionIdParkingAnomalySuccess {
  type: typeof GET_BY_POSITION_ID_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByPositionId;
}
export interface GetByPositionIdParkingAnomalyFailed {
  type: typeof GET_BY_POSITION_ID_PARKING_ANOMALY_FAILED;
}

export interface SetPositionParkingAnomalyDuration {
  type: typeof SET_POSITION_PARKING_ANOMALY_DURATION;
  payload: DurationData;
}

export interface SetPositionParkingAnomalyIgnore {
  type: typeof SET_POSITION_PARKING_ANOMALY_IGNORE;
  payload: IgnoreData;
}

export interface EditPositionDurationParkingAnomaly {
  type: typeof EDIT_POSITION_DURATION_PARKING_ANOMALY;
  payload: DurationData;
}
export interface EditPositionDurationParkingAnomalySuccess {
  type: typeof EDIT_POSITION_DURATION_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByPositionId;
}

export interface EditPositionIgnoreParkingAnomaly {
  type: typeof EDIT_POSITION_IGNORE_PARKING_ANOMALY;
  payload: IgnoreData;
}
export interface EditPositionIgnoreParkingAnomalySuccess {
  type: typeof EDIT_POSITION_IGNORE_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByPositionId;
}

export const DoGetPositionsParkinganomaly = (filters: ParkingAnomalyPositionsFilters): GetPositionsParkingAnomaly => ({
  type: GET_POSITIONS_PARKING_ANOMALY,
  filters
});

export const DoGetPositionsParkinganomalySuccess = (data: PositionsParkingAnomaly[]): GetPositionsParkingAnomalySuccess => ({
  type: GET_POSITIONS_PARKING_ANOMALY_SUCCESS,
  data
});

export const DoGetPositionsParkinganomalyFailed = (): GetPositionsParkingAnomalyFailed => ({
  type: GET_POSITIONS_PARKING_ANOMALY_FAILED,
});

export const DoGetByPositionIdParkingAnomaly = (id: number): GetByPositionIdParkingAnomaly => ({
  type: GET_BY_POSITION_ID_PARKING_ANOMALY,
  id
});
export const DoGetByPositionIdParkingAnomalySuccess = (payload: ParkingAnomalyByPositionId): GetByPositionIdParkingAnomalySuccess => ({
  type: GET_BY_POSITION_ID_PARKING_ANOMALY_SUCCESS,
  payload
});
export const DoGetByPositionIdParkingAnomalyFailed = (): GetByPositionIdParkingAnomalyFailed => ({
  type: GET_BY_POSITION_ID_PARKING_ANOMALY_FAILED
});

export const DoSetPositionParkingAnomalyDuration = (payload: DurationData): SetPositionParkingAnomalyDuration => ({
  type: SET_POSITION_PARKING_ANOMALY_DURATION,
  payload
});

export const DoSetPositionParkingAnomalyIgnore = (payload: IgnoreData): SetPositionParkingAnomalyIgnore => ({
  type: SET_POSITION_PARKING_ANOMALY_IGNORE,
  payload
});

export const DoEditPositionDurationParkingAnomaly = (payload: DurationData ): EditPositionDurationParkingAnomaly => ({
  type: EDIT_POSITION_DURATION_PARKING_ANOMALY,
  payload
});
export const DoEditPositionDurationParkingAnomalySuccess = (payload: ParkingAnomalyByPositionId): EditPositionDurationParkingAnomalySuccess => ({
  type: EDIT_POSITION_DURATION_PARKING_ANOMALY_SUCCESS,
  payload
});

export const DoEditPositionIgnoreParkingAnomaly = (payload: IgnoreData ): EditPositionIgnoreParkingAnomaly => ({
  type: EDIT_POSITION_IGNORE_PARKING_ANOMALY,
  payload
});
export const DoEditPositionIgnoreParkingAnomalySuccess = (payload: ParkingAnomalyByPositionId): EditPositionIgnoreParkingAnomalySuccess => ({
  type: EDIT_POSITION_IGNORE_PARKING_ANOMALY_SUCCESS,
  payload
});

export type Action =
| GetPositionsParkingAnomaly
| GetPositionsParkingAnomalySuccess
| GetPositionsParkingAnomalyFailed
| GetByPositionIdParkingAnomaly
| GetByPositionIdParkingAnomalySuccess
| GetByPositionIdParkingAnomalyFailed
| SetPositionParkingAnomalyDuration
| SetPositionParkingAnomalyIgnore
| EditPositionDurationParkingAnomaly
| EditPositionDurationParkingAnomalySuccess
| EditPositionIgnoreParkingAnomaly
| EditPositionIgnoreParkingAnomalySuccess;
