import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      '&[class*="MuiCard-root"]': {
        overflow: 'visible',
        justifyContent: 'space-between'
      },
      '& [class*="MuiTypography-subtitle1"]': {
        whiteSpace: 'nowrap'
      }
    },
    marginRight: {
      marginRight: '10px'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    count: {
      color: theme.palette.grey[700]
    },
    count2: {
      color: theme.palette.secondary.main
    },
    count3: {
      color: theme.palette.primary.main
    },
    count4: {
      color: theme.palette.error.dark
    },
    boxDiff: {
      borderRadius: '3px',
      margin: 0,
      height: '300px'
    },
    chart: {
      height: '100%',
      '&[class*="MuiCardContent-root"]': {
        padding: 0,
        '&:last-child': {
          padding: 0
        }
      }
    },
    replacementChart: {
      background: theme.palette.grey[200]
    },
    installedChart: {
      background: theme.palette.secondary.main
    },
    brokenChart: {
      background: theme.palette.grey[400]
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    skelectonMargin: {
      margin: 0,
      transform: 'none',
    },
    cardContent: {
      padding: theme.spacing(1),
      '&:last-child': {
        padding: theme.spacing(1)
      }
    }
  }),
);

export default useStyles;
