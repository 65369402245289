import { makeStyles, createStyles } from 'styles/utils';

export const useLabelStyles = makeStyles(theme => createStyles({
  root: {
    borderRadius: 6,
    backgroundColor: '#fff',

    // overrides the theme's global override with the same selector specificity
    '& $labelContainer': {
      paddingTop: 6,
      paddingRight: 7,
      paddingBottom: 4,
      paddingLeft: 3,
    }
  },
  labelContainer: {},

  // override gray box around selected label container
  '@global': {
    '[name~="labelIds"] > div > .MuiChip-root': {
      backgroundColor: 'white',
    },
  },
  label: {
    fontWeight: 500,
  },
  count: {
    marginLeft: 4,
    marginRight: -3,
    color: theme.palette.text.primary,
  },
  icon: {
    marginLeft: 4,
    marginRight: 0,
    width: 17,
    height: 17,
  }
}));

export const useLabelsStyles = makeStyles(theme => createStyles({
  root: {
    margin: theme.spacing(-0.5),
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    margin: theme.spacing(0.5),
  },
}));
