import { ApiKey } from './user-management';

export enum Statuses {
  NotSet = 'notSet',
  UnderConstruction = 'underConstruction',
  Enabled = 'enabled',
}

export enum IntegrationsEnum {
  TheThingsInd = 'The Things Industries',
  TheThingsNetV2 = 'The Things Network v2',
  TheThingsNetV3 = 'The Things Network v3',
  WanseyKerlink = 'Kerlink Wanesy Management Center',
  ThingParkActivity = 'ThingPark by Activity',
  AWSIoTCore = 'AWS IoT Core for LoRaWAN',
}

export enum IntegrationsIdEnum {
  TheThingsInd3 = 'tti_v3',
  TheThingsNet2 = 'ttn_v2',
  TheThingsNet3 = 'ttn_v3',
  WanseyKerlink = 'kerlink_wmc',
  ThingParkActivity = 'actility_v1',
  AWSIoTCore = 'aws_iot',
}

export interface IntegrationLastTimeReceive {
  last_receive_time: Date;
  owner_id: number;
  source: IntegrationsIdEnum;
  token_id: string;
}

export interface IntegrationData {
  icon: string;
  title: IntegrationsEnum;
  status: Statuses;
  documentation: string;
  apiKey?: ApiKey;
  url?: string;
  lastReceiveTime?: IntegrationLastTimeReceive;
  ownerId?: number;
}

export interface IntegrationFiltersParams {
  type?: IntegrationsEnum;
  owner?: number;
  status?: Statuses;
}
