import React from 'react';
import { merge } from 'lodash';

// components
import OriginalSimpleBar, { Props as OriginalSimpleBarProps } from 'simplebar-react';

// styles
import 'simplebar/dist/simplebar.min.css';
import './styles.scss';

interface Props extends OriginalSimpleBarProps {
  fullHeight?: boolean;
}

export const Scrollbar = (props: Props) => {
  const { fullHeight, ...simpleBarProps } = props;
  return (
    <OriginalSimpleBar
      autoHide={ false }
      { ...simpleBarProps }
      style={ merge({}, props.style, {
        ...( fullHeight && { height: '100%' })
      }) }
    />
  );
};