import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    general: {
      width: '40%',
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      }
    },
    column: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    block: {
      margin: theme.spacing(1),
    },
    paper: {
      minHeight: theme.spacing(26),
      height: '100%'
    }
  }),
);
