import React from 'react';
import { useAuthUserSelector } from 'hooks';
import { PositionedDeviceData } from 'models/device-management';
import { useDeviceColdDataSelector } from 'hooks/device-management/devices-cold';
import { PositionNode, Tree } from 'hooks/device-management/useTree/types';
import { urlToFailuresTable, apiRawMessagesPath } from 'routing/paths';
import { formatDate, formatDateTime } from 'utils/datetime';

// components
import { Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { EditButton } from 'components/Buttons';
import { FieldSkeleton } from 'components/Skeleton';
import { ConnectivityCell, IssuesCell } from 'components/DeviceManagement/TreeTable/cells';
// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  tree: Tree;
  node: PositionNode;
  device: PositionedDeviceData;
}

export const PositionDeviceStatistic = ({ tree, node, device }: Props): JSX.Element => {
  const classes = useStyles();
  const { isAdmin } = useAuthUserSelector();
  const { isLoading, device: deviceColdData } = useDeviceColdDataSelector(device.device_id);

  return (
    <Paper className={ classes.root } data-testid="dm-position-device-statistic">
      <Grid container alignItems="center">
        <Grid item lg={ 10 }>
          <Grid container spacing={ 3 } alignItems="center">
            <Grid item xs={ 3 }>
              <Typography>Last received message time</Typography>
              <Typography className={ classes.itemValue }>No device</Typography>
            </Grid>
            <Grid item xs={ 1 }>
              <Typography>Connectivity</Typography>
              <Typography component="div" className={ classes.itemValue }>
                {
                  tree.loaded.deviceConnect
                    ? <ConnectivityCell tree={ tree } node={ node } emptyValue="-" />
                    : <FieldSkeleton height={ 24 }/>
                }
              </Typography>
            </Grid>
            <Grid item xs={ 1 }>
              <Typography>Calibrated</Typography>
              <Tooltip
                title={
                  device?.calibration_date_time
                    ? formatDateTime(device.calibration_date_time)
                    : 'No calibrated'
                }
              >
                <Typography className={ classes.itemValue }>
                  { device?.calibration_status ? 'Yes' : 'No' }
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Issues</Typography>
              <Typography component="div" className={ classes.itemValue }>
                <IssuesCell tree={ tree } node={ node } emptyValue="-" />
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Last firmware update time</Typography>
              <Typography className={ classes.itemValue }>
                {
                  isLoading
                    ? <FieldSkeleton height={ 24 }/>
                    : (
                      deviceColdData?.last_fw_updatetime
                        ? formatDateTime(deviceColdData.last_fw_updatetime)
                        : '-'
                    )
                }
              </Typography>
            </Grid>
            <Grid item xs={ 2 }>
              <Typography>Warranty expiration date</Typography>
              <Typography className={ classes.itemValue }>
                {
                  isLoading
                    ? <FieldSkeleton height={ 24 }/>
                    : (
                      deviceColdData?.expiration_datetime
                        ? formatDate(deviceColdData.expiration_datetime)
                        : '-'
                    )
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={ 2 } className={ classes.buttonBlock }>
          <EditButton
            type="text"
            label="View incidents"
            LinkTo={ urlToFailuresTable({ devices: [device.device_id] }, isAdmin) }
          />
          <EditButton
            type="text"
            label="View messages"
            LinkTo={ apiRawMessagesPath({ devices: [device.device_id] }) }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};