import React from 'react';

// components
import { DialogContent, DialogContentProps } from '@material-ui/core';

// styles
import clsx from 'clsx';
import useDialogStyles from 'styles/dialogs';

export function StyledDialogContent(props: DialogContentProps) {
  const dialogStyles = useDialogStyles();

  return (
    <DialogContent
      {...props}
      className={clsx(dialogStyles.dialogContent, props.className)}
    />
  );
}
