import { History, Location } from 'history';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { buildUrlParams, parseUrlParams } from 'utils/routing/query';

import {
  CustomLinkProps,
  PredefinedParams,
  isLinkActive,
  mapToPropToLocationDescriptorObject,
} from './utils';

// components
import { Link, LinkProps } from 'react-router-dom';

export type { CustomLinkProps };

interface GetLinkToPropOptions {
  to: CustomLinkProps['to'];
  withReturnUrl: boolean;
  location: Location;
  createHref: History['createHref'];
}

function getLinkToProp({
  withReturnUrl,
  createHref,
  location,
  to,
}: GetLinkToPropOptions): LinkProps['to'] {
  if (!withReturnUrl) {
    return to;
  }

  const targetLocation = mapToPropToLocationDescriptorObject(to, location);
  const targetHashParams = parseUrlParams(targetLocation.hash || '', params => params);

  return createHref({
    ...targetLocation,
    hash: buildUrlParams({
      ...targetHashParams,
      [PredefinedParams.RETURN_URL]: encodeURIComponent(createHref(location)),
    }),
  });
}

export const CustomLink = React.forwardRef<HTMLElement, CustomLinkProps>((
  {
    children,
    to,
    withReturnUrl = false,
    activeAsText = false,
    ...restProps
  },
  ref,
) => {
  const history = useHistory();
  const location = useLocation();

  if (activeAsText && isLinkActive({ history, location, to })) {
    /**
     * Currently, we show inactive link almost as a plain text without any styles
     * to get rid of the color of Material UI's clickable Link (it's mostly "secondary" at Nwave).
     *
     * When we need more customization capabilities, we could render Material UI's Link here
     * instead of passing `component={CustomLink}` into Material UI's Link.
     */
    return <span ref={ref}>{children}</span>;
  }

  return (
    <Link
      {...restProps}
      innerRef={ref as React.Ref<HTMLAnchorElement>}
      to={getLinkToProp({ to, withReturnUrl, location, createHref: history.createHref })}
    >
      {children}
    </Link>
  );
});
