import { createStyles, makeStyles } from 'styles/utils';

export const useStyles = makeStyles(() =>
  createStyles({
    inlineActiveButton: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    inlineButton: {
      color: '#4a4a4a',
    },
  }),
);
