import useZonePageStyles from 'pages/DeviceManagement/Zone/style';
import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useGroupPageStyles = useZonePageStyles;

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  fullWidth: {
    width: '100%'
  }
}));

