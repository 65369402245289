import { ExistingPosition } from 'models/device-management';
import { PositionsFilterFields, EnumPositionsFilterFields } from 'models/device-management/filters';

export const DELETE_POSITION = 'dm/DELETE_POSITION';
export const DELETE_LABEL = 'dm/DELETE_LABEL';

export const UPDATE_POSITION = 'dm/UPDATE_POSITION';
export const UPDATE_POSITION_SUCCESS = 'dm/UPDATE_POSITION_SUCCESS';

export const FIND_POSITIONS = 'dm/FIND_POSITIONS';
export const FIND_POSITIONS_SUCCESS = 'dm/FIND_POSITIONS_SUCCESS';
export const FIND_POSITIONS_FAILURE = 'dm/FIND_POSITIONS_FAILURE';

export const FIND_POSITIONS_BY_LOCATION = 'FIND_POSITIONS_BY_LOCATION';
export const FIND_POSITIONS_BY_LOCATION_SUCCESS = 'FIND_POSITIONS_BY_LOCATION_SUCCESS';
export const FIND_POSITIONS_BY_LOCATION_FAILED = 'FIND_POSITIONS_BY_LOCATION_FAILED';

type EnumFields = EnumPositionsFilterFields.groupIds | EnumPositionsFilterFields.positions;

export interface DeletePosition {
  type: typeof DELETE_POSITION;
  positionId: number;
}
export type DeletePositionResult = void;

export interface DeleteLabel {
  type: typeof DELETE_LABEL;
  labelId: number;
}
export type DeleteLabelResult = void;

export interface UpdatePosition {
  readonly type: typeof UPDATE_POSITION;
  readonly position: ExistingPosition;
}
export interface UpdatePositionSuccess {
  readonly type: typeof UPDATE_POSITION_SUCCESS;
  readonly position: ExistingPosition;
}
export interface FindPositions {
  readonly type: typeof FIND_POSITIONS;
  readonly params: Pick<PositionsFilterFields, EnumFields>;
}
export interface FindPositionsSuccess {
  readonly type: typeof FIND_POSITIONS_SUCCESS;
  readonly payload: { [positionId: number]: ExistingPosition | null };
}
export interface FindPositionsFailure {
  readonly type: typeof FIND_POSITIONS_FAILURE;
  readonly error: string;
}

export interface FindPositionsByLocation {
  readonly type: typeof FIND_POSITIONS_BY_LOCATION;
  readonly filters: PositionsFilterFields;
}

export interface FindPositionsByLocationSuccess {
  readonly type: typeof FIND_POSITIONS_BY_LOCATION_SUCCESS;
  readonly positions: ExistingPosition[];
}

export interface FindPositionsByLocationFailed {
  readonly type: typeof FIND_POSITIONS_BY_LOCATION_FAILED;
}

export const deletePosition = (positionId: number): DeletePosition => ({
  type: DELETE_POSITION,
  positionId,
});

export const deleteLabel = (labelId: number): DeleteLabel => ({
  type: DELETE_LABEL,
  labelId,
});

export const updatePosition: (position: UpdatePosition['position']) => UpdatePosition = (position) => ({
  type: UPDATE_POSITION,
  position,
});

export const updatePositionSuccess: (position: UpdatePositionSuccess['position']) => UpdatePositionSuccess = (position) => ({
  type: UPDATE_POSITION_SUCCESS,
  position,
});

export const findPositions: (params: Partial<FindPositions['params']>) => FindPositions = (params) => ({
  type: FIND_POSITIONS,
  params: {
    groupIds: params.groupIds || [],
    positions: params.positions || [],
  },
});

export const findPositionsSuccess: (devices: FindPositionsSuccess['payload']) => FindPositionsSuccess = (devices) => ({
  type: FIND_POSITIONS_SUCCESS,
  payload: devices,
});

export const findPositionsFailure: (error: string) => FindPositionsFailure = (error) => ({
  type: FIND_POSITIONS_FAILURE,
  error,
});

export const findPositionsByLocation: (filters: PositionsFilterFields) => FindPositionsByLocation = filters => ({
  type: FIND_POSITIONS_BY_LOCATION,
  filters
});

export const findPositionsByLocationSuccess: (positions: ExistingPosition[]) => FindPositionsByLocationSuccess = positions => ({
  type: FIND_POSITIONS_BY_LOCATION_SUCCESS,
  positions
});

export const findPositionsByLocationFailed: () => FindPositionsByLocationFailed = () => ({
  type: FIND_POSITIONS_BY_LOCATION_FAILED,
});

export type Action =
| DeletePosition
| DeleteLabel
| FindPositions
| FindPositionsSuccess
| FindPositionsFailure
| UpdatePosition
| UpdatePositionSuccess
| FindPositionsByLocation
| FindPositionsByLocationSuccess
| FindPositionsByLocationFailed;
