import React from 'react';
import { StationFrequency, StationFrequencyTitles } from 'models/base-station/StationFrequency';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<StationFrequency>, 'options'>>
  & (SingleProps<StationFrequency> | MultiProps<StationFrequency>)
  & { filter?: (Frequency: StationFrequency) => boolean }

export const StationFrequencySelectControl = (props: Props): JSX.Element => {
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<StationFrequency>[] = [];

  StationFrequencyTitles.forEach((title, frequency) => {
    if (filterCallback(frequency)) {
      options.push({
        value: frequency,
        label: title,
      });
    }
  });
  return <SelectControl
    name="frequency"
    label="Frequency"
    { ...props }
    options={ options }
    checkSelected={ StationFrequencyTitles.size > 0 }
    isDisabled={ props.isDisabled || !options.length }
  />;
};


