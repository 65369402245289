import { ArrayElement } from 'hooks/device-management/useDmTree/types';

import { TreeNode, TreeTableProps, TreeTableRow } from '../types';

export function renderNormalColumn<TNode extends TreeNode<unknown>>(
  column: ArrayElement<TreeTableProps<TNode>['columns']>,
  row: TreeTableRow<TNode>,
) {
  if (row.type === 'load-more-children') {
    return;
  }

  const renderCell = column.render || column.renderText;
  const isTopNode = row.nestingLevel === 0;

  return renderCell?.(row.node, isTopNode);
}
