import React from 'react';

import { ResolvedStatuses } from 'models';
import { DamagedStatuses, ActivationStatuses } from 'models/common';
import { Incident } from 'models/device-monitoring';

import { apiRawMessagesPath } from 'routing/paths';

// components
import { Typography, TableRow, TableCell } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import { PositionGroupLabel } from 'components/Labels';
import { CustomLink, linkStyles } from 'components/Links';

export type IncidentDetails = Incident;

interface IncidentsMoreProps {
  data: IncidentDetails;
  cols: number;
}

export const IncidentsMore = ({ data, cols }: IncidentsMoreProps) => {
  const classes = linkStyles();
  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Resolving Time: <Typography variant="caption">{ data.resolved_time ? data.resolved_time : '—' }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Resolved by User: <Typography variant="caption">{ data.user_id ? data.user_id : '—' }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Comment: <Typography variant="caption">{ data.comment ? data.comment : '—' }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Resolution Status: <Typography variant="caption">{ data.resolving_type ? data.resolving_type : ResolvedStatuses.not_resolved }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Damaged Status: <Typography variant="caption">{ data.damaged_status ? DamagedStatuses.damaged : DamagedStatuses.notDamaged }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Active Status: <Typography variant="caption">{ data.activation_status ? ActivationStatuses.active : ActivationStatuses.inactive }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Group: <Typography variant="caption">
            { data.group_id ? <PositionGroupLabel groupId={ data.group_id } name={ data.group_name } /> : '—' }
          </Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Error time: <Typography variant="caption">{ (data.state && data.state.error_datetime) ? new Date(data.state.error_datetime).toLocaleString() : '—' }</Typography>
        </Typography>
        <Typography variant="subtitle2" gutterBottom={ true }>
          Trace ID: <Typography variant="caption">{ (data.state && data.state.trace_id)
            ? <Link
              className={ classes.subMenuLink }
              component={ CustomLink }
              color="secondary"
              to={ apiRawMessagesPath({ traceid: data.state.trace_id }) }
            >
              { data.state.trace_id }
            </Link>
            : '—' }
          </Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
};
