import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { NotifyError } from 'actions/notifier';
import { Device, DeviceColdData, ExistingPosition } from 'models/device-management';
import { RootState as State } from 'reducers';
import { areDevicesConnectivityLoading } from 'selectors/connectivity';
import {
  areDevicesColdDataLoading,
  areDevicesLoading,
  getDeviceWithColdData,
} from 'selectors/device-management/devices';
import {
  arePositionsLoading,
  getPosition,
} from 'selectors/device-management/positions';
import { areDevicesStatesLoading } from 'selectors/monitoring';
import { isNil, isPositionId } from 'utils/models';

import { CoupleParams, RouteParams } from './types';
import { useDevicePositionCoupleParams } from './useDevicePositionCoupleParams';

// components
import CouplePage, { CoupleDataLoaderProps } from './data-loader';

interface Couple {
  device?: (Device & DeviceColdData) | null;
  position?: ExistingPosition | null;
}

function getCouple(coupleParams: CoupleParams, state: State): Couple {
  const { deviceId, positionId } = coupleParams;

  return {
    device: (
      isNil(deviceId)
        ? deviceId
        : getDeviceWithColdData(deviceId, state)
    ),
    position: (
      isPositionId(positionId)
        ? getPosition(positionId, state)
        : positionId
    ),
  };
}

interface StateProps {
  device: Couple['device'];
  deviceLoading: boolean;
  position: Couple['position'];
  positionLoading: boolean;
}

type DevicePositionPageProps = (
  & RouteParams
  & Omit<CoupleDataLoaderProps, keyof CoupleParams | keyof StateProps>
);

const DevicePositionPage = (props: DevicePositionPageProps) => {
  const dispatch = useDispatch();

  const coupleParams = useDevicePositionCoupleParams({
    deviceId: props.deviceId?.toUpperCase(),
    positionId: props.positionId,
    networkId: props.networkId,
    onError: e => dispatch(NotifyError(e.message)),
  });

  const stateProps = useSelector((state: State): StateProps => {
    const couple = getCouple(coupleParams, state);

    return {
      device: couple.device,
      position: couple.position,

      deviceLoading: (
        typeof coupleParams.deviceId === 'undefined' ||
        areDevicesLoading(state) ||
        areDevicesColdDataLoading(state) ||
        areDevicesConnectivityLoading(state) ||
        areDevicesStatesLoading(state)
      ),
      positionLoading: (
        typeof coupleParams.positionId === 'undefined' ||
        arePositionsLoading(state)
      ),
    };
  }, shallowEqual);

  return (
    <CouplePage
      {...props}
      {...stateProps}
      deviceId={coupleParams.deviceId}
      positionId={coupleParams.positionId}
      networkId={coupleParams.networkId}
      onDevicePositionRelationUpdate={() => {
        coupleParams.refetchDeviceId();
        coupleParams.refetchPositionId();
      }}
    />
  );
};

export default DevicePositionPage;
