import { makeStyles, createStyles, Theme } from 'styles/utils';
import { fonts } from 'styles/themes/outer';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    paddingRight: '2vw',
  },
  actionBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  header: {
    margin: theme.spacing(1),
    fontFamily: fonts.textSecondary,
    fontSize: theme.typography.pxToRem(14),
  },
  value: {
    margin: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
    '&>span': { // overide for station status
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(16),
    }
  },
  buttonMessage: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5, 3, 1, 3),
  },
  buttonEdit: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5, 3, 1, 3),
  },
  buttonDelete: {
    color: theme.palette.error.main,
    padding: theme.spacing(1.5, 3, 1, 3),
  },
}));
