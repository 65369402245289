import { StationFlashCreate } from 'models/base-station';
import { FormField } from './types';
import { FormErrors } from 'form';

export const validate = (station: StationFlashCreate, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.id)) {
    if (!station.id) {
      errors.id = 'Station ID is required';
    }
  }
  if (fields.includes(FormField.owner)) {
    if (undefined === station.ownerId) {
      errors.ownerId = 'Owner is required';
    }
  }

  if (fields.includes(FormField.pin)) {
    if (!station.pin || !station.pin.trim()) {
      errors.pin = 'Pin is required';
    }
  }

  return errors;
};
