import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(theme => createStyles({
  root: {
    padding: theme.spacing(2, 1.5),
    fontSize: 12,
  },
  icon: {
    marginRight: 8,
    display: 'inline-block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
  label: {
    fontSize: 12,
  },
  date: {
    fontWeight: 500,
  },
  valueContainer: {
    marginLeft: 30,
    display: 'inline-flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
  },
  valueFraction: {
    marginLeft: 4,
    minWidth: 28,
    fontSize: 11,
  },
  total: {
    marginLeft: 22,
    display: 'flex',
    alignItems: 'center',
  },
}));
