import moment from 'moment';
import { PositionStateStatus } from 'models/parking';
import { Duration, FilterState } from './types';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';

/*
  Time duration convert logic
  duration      => timeFrom,      timeTo
  all           => undefined,     undefined
  less 30 min   => now - 30 min,  undefined
  less 4 hour   => now - 4 hour,  now - 30 min
  more 4 hour   => undefined,     now - 4 hour
*/

const getTimeFromByDuration = (duration: Duration, timeFrom?: Date): Date | undefined => {
  switch (duration) {
    case Duration.CUSTOM:
      return timeFrom;
    case Duration.LESS_30_MIN:
      return moment().subtract(30, 'minutes').toDate();
    case Duration.LESS_4_HOUR:
      return moment().subtract(4, 'hours').toDate();
    case Duration.MORE_4_HOUR:
    case Duration.ALL:
    default:
      return undefined;
  }
};

const getTimeToByDuration = (duration: Duration, timeTo?: Date): Date | undefined => {
  switch (duration) {
    case Duration.CUSTOM:
      return timeTo;
    case Duration.LESS_4_HOUR:
      return moment().subtract(30, 'minutes').toDate();
    case Duration.MORE_4_HOUR:
      return moment().subtract(4, 'hours').toDate();
    case Duration.ALL:
    case Duration.LESS_30_MIN:
    default:
      return undefined;
  }
};

export const initialFilters: FilterState = {
  owner: undefined,
  projects: [],
  zones: [],
  groupIds: [],
  networkIds: [],
  customIds: [],
  deviceIds: [],
  status: undefined,
  duration: Duration.ALL,
  timeFrom: undefined,
  timeTo: undefined,
};

export const buildFilters = (params: UrlItems): FilterState => {
  const parser = new Parser(params as FilterState);
  const duration = parser.asEnum('duration', Duration) ?? Duration.ALL;
  return {
    ...initialFilters,
    owner: parser.asNumber('owner') ?? initialFilters.owner,
    projects: parser.asNumbers('projects') ?? initialFilters.projects,
    zones: parser.asNumbers('zones') ?? initialFilters.zones,
    groupIds: parser.asNumbers('groupIds') ?? initialFilters.groupIds,
    networkIds: parser.asStrings('networkIds') ?? initialFilters.networkIds,
    customIds: parser.asStrings('customIds') ?? initialFilters.customIds,
    deviceIds: parser.asStrings('deviceIds') ?? initialFilters.deviceIds,
    status: parser.asEnum('status', PositionStateStatus) ?? initialFilters.status,
    duration: duration,
    timeFrom: getTimeFromByDuration(duration, parser.asDate('timeFrom') ?? initialFilters.timeFrom),
    timeTo: getTimeToByDuration(duration, parser.asDate('timeTo') ?? initialFilters.timeTo),
  };
};
