import React from 'react';
import { formatErrors, InputProps } from 'components/Form/Field/utils';
import { createField } from './createField';

export type RegularInputWithErrorProps = Omit<InputProps, 'errors'> & {
  error?: React.ReactNode;
}

/**
 * Creates a field given an input component which receives `value`, `onChange`, and `error`.
 *
 * It can be used similar to `createFieldFromRegularInput`. The only difference is that it passes
 * formatted `error` instead of `errors`, so if you want to access it in an additional wrapper to
 * transform some props, you may need to extend the underlying input's props with
 * `RegularInputWithErrorProps` (in `TInputProps` type argument).
 *
 * See `createFieldFromRegularInput` for examples.
 */
export function createFieldFromRegularInputWithError<
  TInputProps extends Partial<RegularInputWithErrorProps>
>(Input: React.ComponentType<TInputProps>) {
  return createField<TInputProps, TInputProps['onChange']>(
    (props) => <Input {...props} error={formatErrors(props.errors)} />,
  );
}
