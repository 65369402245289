import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { State as UserState } from 'reducers/user-profile';

/**
 * Get current auth user state
 */
export const useAuthUserSelector = (): UserState => {
  return useSelector((state: RootState) => state.user);
};
