import { createStyles, makeStyles, Theme } from 'styles/utils';
import { colors } from 'styles/themes/outer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: colors.backgroundPaper
    },
    title: {
      flexGrow: 1,
      overflow: 'hidden',
      color: colors.backgroundPaper
    },
    breadcrumbs: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    breadcrumb: {
      display: 'inline',
      verticalAlign: 'baseline',
    },
    breadcrumbSeparator: {
      display: 'inline',
      verticalAlign: 'bottom',
    },
    mobileHeader: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      },
    },
    desktopHeader: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    }
  }),
);

export default useStyles;
