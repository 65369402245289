
export enum CreateBoxField {
  ownerId = 'ownerId',
  quantity = 'quantity',
}

export interface CreateBoxState {
  ownerId?: number;
  quantity?: number;
}
