import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

import { DoGetConnectivityByFilters } from 'actions/device-connectivity';
import * as devicesActions from 'actions/device-management/devices';
import * as positionsActions from 'actions/device-management/positions';
import { DoGetRawMessagesByQuery } from 'actions/raw-messages';
import { Device, ExistingPosition } from 'models/device-management';
import { dmPositionPath } from 'routing/paths';
import { useDataLoaderOnce } from 'utils/components';
import { isPositionId } from 'utils/models';

import { getDeviceIncidents } from './utils';

// components
import { connect } from 'react-redux';

import CouplePage, { Props as ComponentProps } from './component';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInitDevice: (deviceId: string) => {
    dispatch(devicesActions.findDevices({ deviceIds: [deviceId] }));
    dispatch(devicesActions.fetchDevicesColdData([deviceId]));
    dispatch(DoGetConnectivityByFilters({ devices: [deviceId], limit: 1 }));
    dispatch(getDeviceIncidents(deviceId));
    dispatch(DoGetRawMessagesByQuery({ devices: [deviceId], limit: 1, offset: 0, networkId:[] }, true));
  },
  onInitPosition: (positionId: number) => {
    dispatch(positionsActions.findPositions({ positions: [positionId] }));
  },
});

export type CoupleDataLoaderProps = ComponentProps & {
  deviceId?: Device['device_id'] | null;
  positionId?: ExistingPosition['id'] | null;
  networkId?: string;
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const CoupleDataLoader: React.FC<CoupleDataLoaderProps & DispatchProps> = (props) => {
  const { deviceId, positionId, networkId } = props;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (networkId && positionId) {
      history.replace(
        history.createHref({
          ...location,
          // TODO: convert to LocationDescriptorObject and merge with `location`
          // if `dmPositionPath()` returns query string or hash as well
          pathname: dmPositionPath(positionId),
        }),
      );
    }
  }, [networkId, positionId, history, location]);

  const { aboutToStartLoading: deviceAboutToStartLoading } = useDataLoaderOnce({
    key: deviceId,
    load: () => props.onInitDevice(deviceId as string),
    shouldStartLoading: !!deviceId,
  });

  const { aboutToStartLoading: positionAboutToStartLoading } = useDataLoaderOnce({
    key: positionId,
    load: () => props.onInitPosition(positionId as number),
    shouldStartLoading: isPositionId(positionId),
  });

  return (
    <CouplePage
      {...props}
      deviceLoading={props.deviceLoading || deviceAboutToStartLoading}
      positionLoading={props.positionLoading || positionAboutToStartLoading}
    />
  );
};

export default connect(null, mapDispatchToProps)(CoupleDataLoader);
