import { sumBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import { formatAsPercent } from 'utils/format';
import { formatDateRange, DateRangeFormatParams } from 'utils/datetime';

// components
import { Grid, Paper, Typography } from '@material-ui/core';

// styles
import clsx from 'clsx';
import { makeStyles, createStyles } from 'styles/utils';

const useTooltipStyles = makeStyles(_theme => createStyles({
  root: {
    padding: '14px 12px',
  },
  icon: {
    marginRight: 8,
    display: 'inline-block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
  label: {
    fontSize: 12,
  },
  date: {
    fontWeight: 500,
  },
  valueContainer: {
    marginLeft: 30,
    display: 'inline-flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
  },
  valueFraction: {
    marginLeft: 4,
    minWidth: 28,
    fontSize: 11,
  },
  total: {
    marginLeft: 22,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface TooltipDataEntry {
  id: React.ReactText;
  label?: React.ReactText;
  value: number;
  color?: string;
}

interface TotalOptions {
  label?: string;
  count?: number;
  color?: string;
}

interface MonitoringTooltipProps {
  date?: string | DateRangeFormatParams;
  showTime?: boolean;
  formattedDate?: React.ReactNode;
  entries: TooltipDataEntry[];
  noPaper?: boolean;
  total?: number | TotalOptions;
  totalHidden?: boolean;
}

export const MonitoringChartTooltip = ({
  entries,
  date,
  showTime,
  formattedDate,
  noPaper,
  totalHidden,
  total: totalProp,
}: MonitoringTooltipProps) => {
  const classes = useTooltipStyles();
  const Container = noPaper ? 'div' : Paper;

  const total = {
    label: (typeof totalProp === 'object' ? totalProp.label : undefined) ?? 'Total',
    count: (typeof totalProp === 'object' ? totalProp.count : totalProp) ?? sumBy(entries, entry => entry.value),
    color: typeof totalProp === 'object' ? totalProp.color : undefined,
  };

  const longestFractionLength = Math.max(
    ...entries.map(entry => formatAsPercent(entry.value, total.count).length),
  );

  return (
    <Container className={ classes.root } data-testid="monitoring-chart-tooltip">
      <Grid
        container
        spacing={ 1 }
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item data-testid="monitoring-chart-tooltip-date">
          <Typography variant="inherit" className={ clsx(classes.label, classes.date) }>
            { !formattedDate && date && (
              typeof date === 'string'
                ? moment(date).format(showTime ? 'dddd, D MMMM YYYY HH:mm' : 'dddd, D MMMM YYYY')
                : formatDateRange(date)
            ) }
            { formattedDate }
          </Typography>
        </Grid>

        { !totalHidden && (
          <Grid item className={ classes.total } data-testid="monitoring-chart-tooltip-total">
            { total.color && (
              <span
                className={ classes.icon }
                style={ { backgroundColor: total.color } }
              />
            ) }
            <Typography variant="inherit" className={ classes.label }>
              { total.label } { total.count }
            </Typography>
          </Grid>
        ) }
      </Grid>

      { entries.map((e, index) => (
        <Grid
          container
          spacing={ 1 }
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          key={ index }
          data-testid="monitoring-chart-tooltip-item"
        >
          <Grid item>
            <Typography variant="inherit" className={ classes.label }>
              { e.color && (
                <span
                  className={ classes.icon }
                  style={ { backgroundColor: e.color } }
                  data-testid="monitoring-chart-tooltip-item-color"
                />
              ) }
              { e.label ?? e.id }
            </Typography>
          </Grid>

          <Grid item className={ classes.valueContainer }>
            <span className={ classes.value }>
              { e.value }
            </span>

            { typeof e.value === 'number' && (
              <span
                className={ classes.valueFraction }
                style={ { minWidth: (longestFractionLength + 2) * 6 } }
              >
                { ' ' }({ formatAsPercent(e.value, total.count) })
              </span>
            ) }
          </Grid>
        </Grid>
      ))}
    </Container>
  );
};
