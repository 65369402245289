import React from 'react';
import { FormProps } from 'form';
import { v4 as uuid } from 'uuid';
import { useDeepCompareEffect } from 'react-use';
import { groupTypes } from 'models/device-management/dm';
import { validate } from './validator';
import { FormField, FormState } from './types';

// components
import { Box } from '@material-ui/core';
import { RefreshButton } from 'components/Buttons';
import { LevelSelect, SingleSelectControl, TextControl, ZoneSelectControl } from 'components/Controls';

export type GroupFormState = FormState;
export {
  FormField as GroupFormField
};

type Props =
  FormProps<FormState, FormField>
  & {
    orderFields?: FormField[];
  };

export const GroupForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const orderFields = props.orderFields ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      { orderFields.map(field =>
        <React.Fragment key={ field }>
          { field === FormField.name && (
            <TextControl
              required={ !isView }
              disabled={ isView || disabledFields.includes(FormField.name) }
              label="Group name"
              placeholder="Enter name"
              name="name"
              value={ state[FormField.name] ?? '' }
              onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(FormField.name, event.target.value ?? '');
              } }
              error={ showErrors.includes(FormField.name) ? errors[FormField.name] !== undefined : false }
              helperText={ showErrors.includes(FormField.name) ? errors[FormField.name] : undefined }
            />
          ) }
          { field === FormField.type && (
            <SingleSelectControl
              isDisabled={ isView || disabledFields.includes(FormField.type) }
              label="Group type"
              placeholder="Choose type"
              name={ FormField.type }
              selected={ state[FormField.type] }
              values={ Object.entries(groupTypes).map(([value, label]) => ({ label, value })) }
              changeHandler={ (type?: string) => handleChange(FormField.type, type) }
              error={ showErrors.includes(FormField.type) ? errors[FormField.type] : undefined }
            />
          ) }
          { field === FormField.customId && (
            <Box display="flex" alignItems="baseline">
              <TextControl
                disabled={ isView || disabledFields.includes(FormField.customId) }
                label="Custom ID"
                placeholder="Custom ID"
                name={ FormField.customId }
                value={ state[FormField.customId] ?? '' }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(FormField.customId, event.target.value ?? '');
                } }
                error={ showErrors.includes(FormField.customId) ? errors[FormField.customId] !== undefined : false }
                helperText={ showErrors.includes(FormField.customId) ? errors[FormField.customId] : undefined }
              />
              { !isView && !disabledFields.includes(FormField.customId) && (
                <RefreshButton
                  type="icon"
                  label="Generate"
                  onClick={ () => handleChange(FormField.customId, uuid()) }
                />
              ) }
            </Box>
          ) }
          { field === FormField.level && (
            <LevelSelect
              isRequired={ !isView }
              isDisabled={ isView || disabledFields.includes(FormField.level) }
              zoneId={ state[FormField.zone] }
              selected={ state[FormField.level] }
              onChange={ (levelId?: number) => handleChange(FormField.level, levelId) }
              error={ showErrors.includes(FormField.level) ? errors[FormField.level] : undefined }
            />
          ) }
          { field === FormField.zone && (
            <ZoneSelectControl
              isRequired={ !isView }
              isDisabled={ isView || disabledFields.includes(FormField.zone) }
              selected={ state[FormField.zone] }
              onChange={ (zoneId?: number) => handleChange(FormField.zone, zoneId) }
              error={ showErrors.includes(FormField.zone) ? errors[FormField.zone] : undefined }
            />
          ) }
        </React.Fragment>
      ) }
    </form>
  );
};
