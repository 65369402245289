import { MapState, plainMapOptions, ZoomArea } from 'utils/map';
import { Station } from 'models/base-station';
import { DeviceWithConnectivity } from 'models/device-management/dm';
import { BSConnectivityMapFilters } from 'pages/BaseStation/ConnectivityMap/types';
import { useState } from 'react';
import { isEqual } from 'lodash';

import { CommonMapProps } from 'components/Map/CommonMap';
import { useDeepCompareEffect } from 'react-use';

interface ConnectivityMapState {
  onIdle: CommonMapProps['onIdle'];
  onLoad: CommonMapProps['onLoad'];
  options: CommonMapProps['options'];

  stations: Station[];
  devices: DeviceWithConnectivity[];
}

export const useMapByFilters = (
  isLoading: boolean,
  filter: BSConnectivityMapFilters,
  stations: Station[],
  devices: DeviceWithConnectivity[]
): ConnectivityMapState => {

  const [mapState, setMapState] = useState<MapState>();
  const [currentFilter, setCurrentFilter] = useState<BSConnectivityMapFilters | undefined>(undefined);
  const [devicesOnMap, setDevicesOnMap] = useState<DeviceWithConnectivity[]>([]);

  /* update map center on change location */
  useDeepCompareEffect(() => {
    if (!mapState || isLoading) {
      return;
    }

    const points = getMapPointsByFilters(filter, stations, devices);
    if (!points.length) {
      return;
    }

    if (skipMapZoom(filter, currentFilter)) {
      onIdle(); // reload devices on map
      return;
    }
    setCurrentFilter(filter);

    const bounds = new google.maps.LatLngBounds();
    points.forEach(p => bounds.extend(p));
    mapState.map.fitBounds(bounds);
  }, [filter, stations, devices, mapState]);

  const onIdle = () => {
    if (!mapState) {
      return;
    }

    const zoomArea = {
      zoom: mapState.map.getZoom(),
      area: mapState.map.getBounds() ?? new mapState.google.maps.LatLngBounds(),
    };

    setDevicesOnMap(filterDeviceByScreen(devices, zoomArea));
  };

  return {
    stations: stations,
    devices: devicesOnMap,
    options: { ...plainMapOptions, disableDefaultUI: false },

    onIdle: onIdle,
    onLoad: (map, google) => setMapState({ map, google }),
  };
};

const getMapPointsByFilters = (
  filter: BSConnectivityMapFilters,
  stations: Station[],
  devices: DeviceWithConnectivity[]
): google.maps.LatLng[] => {
  const points: google.maps.LatLng[] = [];
  if (filter.zones?.length || filter.owner || filter.projects?.length) {
    devices
      .filter(d => d.lat && d.lon)
      .forEach(d => points.push(new google.maps.LatLng(Number(d.lat), Number(d.lon))));
  }

  if (!points.length || filter.owner || filter.projects?.length || filter.isHealthy || filter.isOnline) {
    stations
      .filter(s => s.location?.lat && s.location?.lon)
      .forEach(s => points.push(new google.maps.LatLng(Number(s.location?.lat), Number(s.location?.lon))));
  }

  return points;
};

const filterDeviceByScreen = (devices: DeviceWithConnectivity[], zoomArea: ZoomArea): DeviceWithConnectivity[] => {
  if (!zoomArea || zoomArea.zoom <= 10) {
    return [];
  }

  return devices.filter(device => {
    const devicePosition = new google.maps.LatLng({
      lat: device.lat ?? 0,
      lng: device.lon ?? 0
    });

    return zoomArea.area.contains(devicePosition);
  });
};

// dont change map zoom and position for some filters change
const skipMapZoom = (newFilter: BSConnectivityMapFilters, oldFilter?: BSConnectivityMapFilters): boolean => {
  if (!oldFilter) {
    return false;
  }

  return isEqual(
    { ...newFilter, connectivity: undefined },
    { ...oldFilter, connectivity: undefined },
  );
};
