import React from 'react';
import { isNil } from 'lodash';

import { DmTreeNode } from 'hooks/device-management/useDmTree/types';
import { renderQuery } from './utils';

// components
import { Box, Tooltip } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { SensorsOffIcon } from 'components/Icons';

// styles
import { colors } from 'styles/themes/outer';

export const IssuesCell = ({ node }: { node: DmTreeNode }) => {
  return (
    <>
      { renderNode(node) }
    </>
  );
};

function renderNode(node: DmTreeNode) {
  switch (node.type) {
    case 'zone':
    case 'group':
      return (
        <MultiIssuesCell
          damaged={ renderQuery(
            node.totalDamagedDevices,
            total => total || 0
          ) }
          silent={ renderQuery(
            node.totalSilentDevices,
            total => total || 0
          ) }
        />
      );
    case 'position':
      return (
        <SingleIssuesCell
          damaged={ renderQuery(
            node.device,
            device => device?.damaged_status ? true : undefined,
          ) }
          silent={ renderQuery(
            node.deviceSilent,
            deviceSilent => deviceSilent ? true : undefined,
          ) }
        />
      );
    default:
      return null;
  }
}

const MultiIssuesCell = ({ damaged, silent }: { damaged: React.ReactNode; silent: React.ReactNode }): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Tooltip title="Damaged devices">
        <Box display="flex" alignItems="center" minWidth="50%">
          <ReportProblemIcon htmlColor={ colors.lightRed }/>&nbsp;
          { damaged }
        </Box>
      </Tooltip>
      <Tooltip title="Silent devices">
        <Box display="flex" alignItems="center" minWidth="50%">
          <SensorsOffIcon/>&nbsp;{ silent }
        </Box>
      </Tooltip>
    </Box>
  );
};

const SingleIssuesCell = ({ damaged, silent }: { damaged: React.ReactNode; silent: React.ReactNode }): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      { isNil(damaged) ? '' : (
        <Tooltip title="Damaged device">
          <Box display="flex" alignItems="center" minWidth="50%">
            <ReportProblemIcon htmlColor={ colors.lightRed }/>
          </Box>
        </Tooltip>
      ) }
      { isNil(silent) ? '' : (
        <Tooltip title="Silent device">
          <Box display="flex" alignItems="center" minWidth="50%">
            <SensorsOffIcon/>
          </Box>
        </Tooltip>
      ) }
    </Box>
  );
};