import { SchemaEnum } from 'models/monitoring';
import * as Actions from 'actions/monitoring/schema-enum';

export interface State {
  isLoaded?: boolean;
  schema: SchemaEnum;
}

const initState: State = {
  isLoaded: undefined,
  schema: {
    global: { errorTypes: [], reasons: [] },
    forIncidents: { errorTypes: [], reasons: [] },
    forDeviceStates: { errorTypes: [], reasons: [] },
  },
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.MONITORING_SCHEMA_ENUM_LOAD_START:
      return { ...state, isLoaded: false };
    case Actions.MONITORING_SCHEMA_ENUM_LOAD_SUCCESS:
      return { ...state, isLoaded: true, schema: action.schema };
    case Actions.MONITORING_SCHEMA_ENUM_LOAD_FAILURE:
      return { ...state, isLoaded: false };
    default:
      return state;
  }
};