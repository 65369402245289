import { FormField, FormState } from './types';
import { FormErrors } from 'types/form';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};

  if (fields.includes(FormField.zone)) {
    if (!state[FormField.zone]) {
      errors[FormField.zone] = 'Zone is required';
    }
  }

  if (fields.includes(FormField.name)) {
    if (!state[FormField.name]) {
      errors[FormField.name] = 'Group name is required';
    }
  }

  return errors;
};
