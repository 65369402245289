import { Helmet } from 'react-helmet-async';
import { useUserGroupSelector } from 'hooks/user-managment';
import { canViewApiKeyList } from 'utils/permissions';
import { useAuthUserSelector } from 'hooks';

// components
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { UserGroupManagment, ApiKeyList } from 'components/UserManagement';
import { PageLoader } from 'components/Loaders';
import { ClientNotFound } from './ClientNotFound';
import { Services } from './Services';

// styles
import useStyles from './styles';

interface Props {
  isProfile?: boolean;
  clientId: number;
}

export const UserManagementClientDetailPage = ({ isProfile, clientId }: Props): JSX.Element => {
  const classes = useStyles();
  const { isLoading, userGroup } = useUserGroupSelector(clientId);
  const user = useAuthUserSelector();

  return (
    <Box className={ classes.root }>
      <Helmet><title>{ `Client ${ userGroup?.name || clientId }` }</title></Helmet>
      {
        !userGroup ? (isLoading ? <PageLoader/> : <ClientNotFound clientId={ clientId }/>) :
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
              <UserGroupManagment userGroup={ userGroup }/>
            </Grid>
            { !canViewApiKeyList(user) ? '' :
              <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                <ApiKeyList
                  isProfile={ isProfile }
                  userGroup={ userGroup }
                />
              </Grid>
            }
            { user.isAdmin &&
              <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                <Services
                  userGroupId={ userGroup.id }
                />
              </Grid>
            }
          </Grid>
      }
    </Box>
  );
};
