import { LineSvgProps } from '@nivo/line';
import { chartTheme } from '../ChartsStyles/ChartsStyles';
import { props as CommonOptions, dateToMonthDay } from './CommonOptions';
import { LinearScale } from '@nivo/scales';

const yScale: LinearScale = {
  type: 'linear',
  stacked: false,
  min: 0,
  max: 'auto'
};

export const props: LineSvgProps = {
  ...CommonOptions,
  xScale: {
    type: 'point',
  },
  yScale,
  curve: 'monotoneX',
  axisBottom: {
    tickRotation: -66,
    format: dateToMonthDay
  },
  axisTop: {
    format: () => '',
    legendPosition: 'middle',
    tickValues: undefined,
    tickSize: 0,
    legendOffset: -20
  },
  lineWidth: 3,
  enablePoints: true,
  pointSize: 6,
  pointColor: { from: 'color' },
  pointBorderWidth: 0,
  enableArea: true,
  areaOpacity: 0.45,
  useMesh: false,
  theme: chartTheme,
  enableSlices: 'x',
  legends: undefined
};
