import { useDialog, useForm, useFormActionLoader } from 'hooks';
import { DoCreateLevel } from 'actions/device-management/levels';
import { Level, Zone } from 'models/device-management';
// components
import { Button } from '@material-ui/core';
import { CommonDialog, EnsureDialog } from 'components/Dialogs';
import { LevelForm, LevelFormField, LevelFormState } from 'components/DeviceManagement/Forms';

interface Props {
  zone: Zone;
  zoneLevels: Level[];
  onSuccess: (level: Level) => void;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const AddZoneLevelDialog = (props: Props): JSX.Element => {
  const { zone, zoneLevels, onSuccess, isOpen, onClose, onCloseEnd } = props;
  const duplicateDialog = useDialog();
  const { doAction } = useFormActionLoader();
  const form = useForm<LevelFormState, LevelFormField>({
    initialState: {
      [LevelFormField.zone]: zone.id
    },
    showFields: [
      LevelFormField.zone,
      LevelFormField.name,
      LevelFormField.floor,
    ],
  });

  const handleAdd = (): boolean => {
    if (!form.validate()) {
      return false;
    }

    const found = zoneLevels.some(level => level.floor_number === form.state[LevelFormField.floor]);
    if (found) {
      duplicateDialog.open();
      return false;
    }

    createLevel();
    return true;
  };

  const createLevel = () => doAction({
    action: DoCreateLevel({
      zone_id: zone.id,
      name: String(form.state[LevelFormField.name]),
      floor_number: Number(form.state[LevelFormField.floor]),
    }),
    onSuccess: onSuccess,
    onError: form.catchError,
  });

  return (
    <>
      <CommonDialog
        isOpen={ isOpen }
        title={ `Add level > ${zone.name}`}
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
        content={ <LevelForm disabledFields={ [LevelFormField.zone] } { ...form } /> }
        actions={
          <>
            <Button onClick={ onClose }>Cancel</Button>
            <Button color="primary" variant="contained" onClick={ handleAdd }>Add</Button>
          </>
        }
      />
      { duplicateDialog.isMounted && (
        <EnsureDialog
          name="dm-add-level-duplicate-dialog"
          label="Floor number not uniq"
          btnNo="Cancel"
          btnYes="Add"
          isOpen={ duplicateDialog.isOpen }
          onClose={ duplicateDialog.close }
          onCloseEnd={ duplicateDialog.unmount }
          onYes={ () => createLevel() }
        >
          Level with floor number { form.state[LevelFormField.floor] } already exists in Zone { zone?.name || `#${ zone.id }` }<br/>
          Do you want to add another one?
        </EnsureDialog>
      ) }
    </>
  );
};