import { combineReducers } from 'redux';

import * as firmware from './firmware';

export interface State {
  firmware: firmware.State;
}

export const reducer = combineReducers<State>({
  firmware: firmware.reducer,
});
