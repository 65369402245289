import { GetStatParams } from 'actions/device-statistic';
import moment from 'moment';

export enum VisibleDays {
  'month' = 30
}

export enum Fields {
  zones = 'zones',
  owner = 'owner',
  devices = 'devices',
  groups = 'groups',
  timeFrom = 'timeFrom',
  timeTo = 'timeTo',
  projects = 'projects',
}

export const initStatFilters: GetStatParams = {
  [Fields.zones]: [],
  [Fields.owner]: undefined,
  [Fields.devices]: [],
  [Fields.groups]: [],
  [Fields.timeFrom]: moment().subtract(VisibleDays.month, 'days'),
  [Fields.timeTo]: moment(),
  [Fields.projects]: [],
}
