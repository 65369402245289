import React from 'react';

// components
import { Status } from 'components';

// styles
import { grey } from '@material-ui/core/colors';
import outerTheme from 'styles/themes/outer';

interface PermissionsIndicatorProps {
  granted: boolean;
}

const Colors = {
  GRANTED: outerTheme.palette.secondary.main,
  DENIED: grey[400],
};

const PermissionsIndicator: React.FC<PermissionsIndicatorProps> = ({ granted }) => {
  return (
    <Status iconColor={granted ? Colors.GRANTED : Colors.DENIED}>
      {granted ? 'Granted' : 'Denied'}
    </Status>
  );
};

export default PermissionsIndicator;
