import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      maxHeight: 300,
      overflowY: 'scroll',
      '&:last-child': {
        padding: 0
      }
    },
  }),
);
export default useStyles;
