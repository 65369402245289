import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import { buildFilters, initialFilters } from './StateFilter/utils';
import { FilterState } from './StateFilter/types';

export const usePositionStatesPageParams = () => {
  const { items, setItems } = useLocationItems();
  const [filters, setFilters] = useState<FilterState>(initialFilters);
  const { limit, offset } = usePagination();

  useEffectOnce(() => {
    delete items.limit;
    delete items.offset;
    setFilters(buildFilters(items));
  });

  return {
    params: { ...filters, limit, offset },
    filters,
    setFilters: (filters: FilterState) => {
      setFilters(filters);
      setItems({ ...filters, limit, offset: 0 });
    }
  };
};