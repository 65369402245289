export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const DESTROY_USER_SESSION = 'DESTROY_USER_SESSION';

export const START_BROWSER_SESSION = 'START_BROWSER_SESSION';
export const STOP_BROWSER_SESSION = 'STOP_BROWSER_SESSION';

export interface UserPayload {
  role?: 'admin' | 'user';

  // permissions
  'dm.zone'?: string;
  'dm.device'?: string;
  'um.user'?: string;
  fmw?: string;
  'http.sender'?: string;
  'rmq.sender'?: string;
  monitoring?: string;
  'raw.messages'?: string;
  devices?: string;
  user_group?: string;
  projects?: string;
  user_id?: string;

  [key: string]: string | undefined;
}

export interface SetUserData {
  readonly type: typeof SET_USER_DATA;
  user: UserPayload;
}

export interface GetUserData {
  readonly type: typeof GET_USER_DATA;
}

export interface DestroyUserSession {
  readonly type: typeof DESTROY_USER_SESSION;
}

export interface StartBrowserSession {
  readonly type: typeof START_BROWSER_SESSION;
}
export interface StopBrowserSession {
  readonly type: typeof STOP_BROWSER_SESSION;
}

export const DoSetUserData: (user: UserPayload) => SetUserData = (user) => ({
  type: SET_USER_DATA,
  user
});

export const DoGetUserData: () => GetUserData = () => ({
  type: GET_USER_DATA
});

export const DoDestroyUserSession: () => DestroyUserSession = () => ({
  type: DESTROY_USER_SESSION
});

export const DoStartBrowserSession: () => StartBrowserSession = () => ({
  type: START_BROWSER_SESSION
});

export const DoStopBrowserSession: () => StopBrowserSession = () => ({
  type: STOP_BROWSER_SESSION
});

export type Action = (
  | SetUserData
  | GetUserData
  | DestroyUserSession
  | StartBrowserSession
  | StopBrowserSession
);
