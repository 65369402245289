import React from 'react';
import { Endpoint } from 'models/rabbit';

// components
import { Box } from '@material-ui/core';
import { CommonButton, DeleteButton } from 'components/Buttons';

interface Props {
  endpoint: Endpoint;
  onDelete: (endpoint: Endpoint) => void;
  onAddRoute: (endpoint: Endpoint) => void;
}

export const ActionsCell = ({ endpoint, onDelete, onAddRoute }: Props): JSX.Element => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <CommonButton
        type="text"
        label="Add route"
        icon={ <></> }
        onClick={ () => onAddRoute(endpoint) }
        dataTestId="rabbit-endpoint-add-route-button"
      />
      <DeleteButton
        type="text"
        label="Delete"
        onClick={ () => onDelete(endpoint) }
        dataTestId="rabbit-endpoint-delete-button"
      />
    </Box>
  );
};
