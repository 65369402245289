import { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUserSelector } from 'hooks';
import { RootState } from 'reducers';
import { useEffectOnce } from 'react-use';
import { initProvisioningDevicesFilters } from 'models/provisioning';
import { DoFetchProvisioningDevicesByFilters, DoRemoveDeviceFromBox } from 'actions/provisioning';
import { totalStr } from '../utils';

// components
import { ListItemSkeleton } from 'components/Skeleton';
import { Button, Card, CardContent, CardHeader, IconButton, List, ListItem } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { LoaderSvg } from 'components/Loaders';
import { DeviceLink } from 'components/Links';
import { AddDevicesModal } from './AddDevicesModal';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  boxId: number;
}

export const DevicesInBoxes = ({ boxId }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin } = useAuthUserSelector();
  const devices = useSelector((state: RootState) => state.provisioning.devices);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffectOnce(() => {
    dispatch(DoFetchProvisioningDevicesByFilters({
      ...initProvisioningDevicesFilters,
      box_id: [boxId],
      limit: 9999
    }));
  });

  const addDevice = () => setDialogOpen(true);
  const removeDevice = (id: string) => dispatch(DoRemoveDeviceFromBox(id));

  return (
    <>
      <Card className={ clsx(classes.cardRoot, classes.flexCard) } data-testid="box-devices">
        <CardHeader title="Devices" subheader={ `Total: ${totalStr(devices.devices.length)}` } action={
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={ <Add /> }
            onClick={ addDevice }
            disabled={ !isAdmin && devices.devices.length >= 20 }
          >
            Add Device
          </Button>
        } />
        <CardContent className={ classes.flexScroll }>
          { devices.isFetching
            ? <ListItemSkeleton itemsCount={ 5 } />
            : <List>
              {
                devices.devices.map(device => {
                  return (
                    <ListItem key={ device.device_id } className={ classes.border }>
                      <DeviceLink deviceId={ device.device_id } />
                      {
                        !devices.isDeleting &&
                        <IconButton
                          onClick={ () => removeDevice(device.device_id) }
                          className={ classes.removeBtn }
                          data-testid="remove-device"
                        >
                          <Close />
                        </IconButton>
                      }
                      { devices.isDeleting && <LoaderSvg /> }
                    </ListItem>
                  );
                })
              }
            </List >
          }
        </CardContent>
      </Card>

      { isDialogOpen && <AddDevicesModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
        boxId={ boxId }
      /> }
    </>
  );
};
