import { DevicesFilterFields, EmulatedStatus } from 'models/device-management';

// components
import { CustomIdSelect, SingleSelectControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';
import { SelectControl } from 'components/Controls/Select';
import { HardwareTypeSelectControl } from 'components/Controls/HardwareTypeSelect/HardwareTypeSelect';

interface Props {
  state: DevicesFilterFields;
  setState: (filters: DevicesFilterFields | ((filters: DevicesFilterFields) => DevicesFilterFields)) => void;
}

const ManageDeviceTableFilter = (props: Props): JSX.Element => {
  const { state, setState } = props;

  const handleSelectTypes = (hardwareTypes: string[] | undefined) => setState({ ...state, hardwareTypes });
  const handleSelectActive = (isActive: boolean | undefined) => setState({ ...state, isActive });
  const handleSelectDamaged = (isDamaged: string | undefined) => setState({ ...state, isDamaged });
  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner });
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const handleSelectGroups = (groups: number[]) => setState({ ...state, groups });

  const activeOptions = [{ label: 'Active', value: true }, { label: 'Not Active', value: false }];
  const damagedOptions = [{ label: 'Damaged', value: 'damaged' }, { label: 'Not Damaged', value: 'notDamaged' }];
  const emulatedOptions = [{ label: 'Real', value: EmulatedStatus.real }, { label: 'Virtual', value: EmulatedStatus.emulate }];

  return (
    <form autoComplete="off">
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner }
        projects={ state.projects }
        handleSelectProjects={ handleSelectProjects }
        zones={ state.zones }
        handleSelectZones={ handleSelectZones }
        uncheckGroups={ () => setState({ ...state, groups: [] }) }
        groups={ state.groups }
        handleSelectGroups={ handleSelectGroups }
        isProjectShow
        isZoneShow
        isGroupsShow
      />
      <CustomIdSelect
        selected={ state.devices }
        changeHandler={ devices => setState({ ...state, devices }) }
        name="Device"
        type="hex"
      />
      <HardwareTypeSelectControl
        selected={ state.hardwareTypes ?? [] }
        onChange={ handleSelectTypes }
      />
      <SingleSelectControl
        selected={ state.isActive }
        label="Activation Status"
        values={ activeOptions }
        changeHandler={ handleSelectActive }
        name="active"
        isClearable
      />
      <SingleSelectControl
        selected={ state.isDamaged }
        label="Damaged Status"
        values={ damagedOptions }
        changeHandler={ handleSelectDamaged }
        name="damaged"
        isClearable
      />
      {
        <SelectControl
          isClearable
          isMulti
          label="Device Category"
          name="emulated"
          selected={ state.isEmulated ?? [] }
          options={ emulatedOptions }
          onChange={ isEmulated => setState({ ...state, isEmulated }) }
        />
      }
    </form>
  );
};

export default ManageDeviceTableFilter;
