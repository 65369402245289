import { useCallback } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadResponsiblePersonDataById, LoadResponsiblePersonDataByParams, LoadResponsiblePersonDataDictionary } from 'actions/base-station';
import { GetResponsiblePersonParams, ResponsiblePerson } from 'models/base-station';

interface ResponsiblePersonState {
  isLoading: boolean;
  responsiblePerson?: ResponsiblePerson;
}

/**
 * Load base station responsible person from storage
 *
 * @param personId Responsible person identify
 */
export const useStationResponsiblePersonSelector = (personId: number): ResponsiblePersonState => {
  const dispatch = useDispatch();

  const { isFetched, person } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.responsiblePerson.isFetched(personId),
    person: state.baseStation.responsiblePerson.findById(personId),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadResponsiblePersonDataById(personId));
    }
  }, [dispatch, isFetched, personId]);

  return {
    responsiblePerson: person,
    isLoading: isFetched !== true,
  };
};

interface ResponsiblePersonsState {
  isLoading: boolean;
  responsiblePersons: ResponsiblePerson[];
}

/**
 * Load base station responsible persons from storage
 *
 * @param params Responsible person find params
 */
export const useStationResponsiblePersonParamsSelector = (params: GetResponsiblePersonParams): ResponsiblePersonsState => {
  const dispatch = useDispatch();

  const { isFetched, persons } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.responsiblePerson.isFetched(params),
    persons: state.baseStation.responsiblePerson.findByParams(params),
  }), shallowEqual);

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadResponsiblePersonDataByParams(params));
    }
  }, [dispatch, isFetched, params]);

  return {
    responsiblePersons: persons,
    isLoading: isFetched !== true,
  };
};


/**
 * Load base station responsible persons dictionary from storage
 */
export const useStationResponsiblePersonsDictionary = (): ResponsiblePersonsState & {
  handleResponsiblePersonsDataDictionary: () => void
} => {
  const dispatch = useDispatch();

  const { isFetched, persons } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.responsiblePerson.isFetched({}),
    persons: state.baseStation.responsiblePerson.findByParams({}),
  }), shallowEqual);

  const handleResponsiblePersonsDataDictionary = useCallback(
    () => {
      dispatch(LoadResponsiblePersonDataDictionary());
    },
    [dispatch],
  );

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      handleResponsiblePersonsDataDictionary();
    }
  }, [dispatch, isFetched]);

  return {
    responsiblePersons: persons,
    isLoading: isFetched !== true,
    handleResponsiblePersonsDataDictionary,
  };
};