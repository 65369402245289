import React from 'react';

import { Registered as RegisteredEnum } from 'models/base-station';

// components
import Box from '@material-ui/core/Box';

// style
import { useStyles } from './TableComponentsStyle';

interface Props {
  registered: boolean;
}

export const Registered = ({ registered }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={ classes.fieldRegistered }>
      { registered ? RegisteredEnum.user : RegisteredEnum.notUser }
    </Box>
  );
};
