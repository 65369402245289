import { FormErrors } from 'form';
import { isNil } from 'lodash';
import { CreateBoxField, CreateBoxState } from './types';

export const validate = (state: CreateBoxState, fields: CreateBoxField[]): FormErrors<CreateBoxField> => {
  const errors: FormErrors<CreateBoxField> = {};

  if (fields.includes(CreateBoxField.ownerId)) {
    if (isNil(state.ownerId)) {
      errors[CreateBoxField.ownerId] = 'Owner is required';
    }
  }

  if (fields.includes(CreateBoxField.quantity)) {
    if (!state.quantity) {
      errors[CreateBoxField.quantity] = 'Quantity is required';
    } else if (state.quantity > 200) {
      errors[CreateBoxField.quantity] = 'Quantity must be less than or equal to 200';
    } else if (state.quantity < 1) {
      errors[CreateBoxField.quantity] = 'Quantity must be more than or equal to 1';
    }
  }

  return errors;
};
