import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) => createStyles({
  root: {
    '& [class*="MuiCard-root"]': {
      overflow: 'visible',
    },
  },
  block: {
    flex: 1,
  },
}));
