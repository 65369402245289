import { CommonProps, MultiProps, SingleProps, Option, SelectControl } from 'components/Controls/Select';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { DoFetchShipmentByParams } from 'actions/provisioning';
import { useEffectOnce } from 'react-use';
import { initProvisioningShipmentFilters } from 'models/provisioning';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)

export const ShipmentsSelector = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const shipment = useSelector((state: RootState) => state.provisioning.shipment);

  useEffectOnce(() => {
    if (!shipment.shipments.length) {
      dispatch(DoFetchShipmentByParams({
        ...initProvisioningShipmentFilters,
        limit: 9999,
      }));
    }
  });

  const options: Option<string>[] = shipment.shipments.map(shipment => ({
    value: shipment.id,
    label: shipment.name,
  }));

  return (
    <SelectControl
      name="shipmentIds"
      label="Shipment"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ shipment.isFetching }
      checkSelected={ shipment.shipments.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
