import { ApiKey } from 'models/user-management';
import { FormField } from './types';
import { FormErrors } from 'form';

export const validate = (apiKey: ApiKey, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.name)) {
    if (!apiKey.name || !apiKey.name.trim()) {
      errors.name = 'Name is required';
    }
  }

  if (fields.includes(FormField.owner_id)) {
    if (apiKey.owner_id === undefined) {
      errors.owner_id = 'Owner is required';
    }
  }

  if (fields.includes(FormField.permission_id)) {
    // `apiKey.permission_id === 0` should be ignored as well, it's just a default value
    // which means an unset field but helps to match `ApiKey` type where `permission_id: number`
    if (!apiKey.permission_id) {
      errors.permission_id = 'Permission is required';
    }
  }

  return errors;
};
