import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { RootState } from 'reducers';
import { useAuthUserSelector, useDialog } from 'hooks';
import { DoSetProjectsFilters, DoUpdateProjectsFromUrl } from 'actions/projects';
import { ProjectsFilters, initProjectsFilters } from 'models/projects';
import { Project } from 'models/user-management';
import { isAllowedToCreateUser } from 'utils/permissions';
import { useProjectsDictionarySelector } from 'hooks/user-managment';

// components
import { ProjectsTable, ProjectsFilter, CreateProjectDialog } from 'components/UserManagement/Projects';
import { AddButton, FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

const filterProjects = (filters: ProjectsFilters, projects: Project[]): Project[] => {
  return projects.filter(group => {
    if (filters.projects?.length && !filters.projects.includes(group.id)) {
      return false;
    }

    if (filters.owner !== undefined && group.owner_id !== filters.owner) {
      return false;
    }

    return true;
  });
};

export const ProjectsList = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useAuthUserSelector();
  const createDialog = useDialog();
  const { isLoading, projects } = useProjectsDictionarySelector();
  const { filters } = useSelector((state: RootState) => state.projects.listPage);
  const [state, setState] = useState<ProjectsFilters>(filters);

  useEffect(() => {
    dispatch(DoUpdateProjectsFromUrl());
  }, [dispatch]);

  const onReset = () => {
    dispatch(DoSetProjectsFilters(initProjectsFilters, true));
    setState(initProjectsFilters);
  };

  const onSave = () => {
    dispatch(DoSetProjectsFilters(state, true));
  };

  useEffect(() => {
    setState(filters);
  }, [filters]);

  const filteredData: Project[] = filterProjects(filters, projects);

  return (
    <>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      { user.isAdmin && <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ isLoading }
          filter={ filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <ProjectsFilter
            isAdmin={ user.isAdmin }
            state={ state }
            setState={ setState }
          />
        </FilterButton>
      </div> }
      <div className={ classes.table }>
        <ProjectsTable
          projects={ filteredData }
          isAdmin={ user.isAdmin }
          isFetching={ isLoading }
        />
      </div>
      { isAllowedToCreateUser(user.data) && <>
        <AddButton label="Add Project" onClick={ createDialog.open } />
        { createDialog.isMounted && <CreateProjectDialog
          isOpen={ createDialog.isOpen }
          onClose={ createDialog.close }
          onCloseEnd={ createDialog.unmount }
        /> }
      </> }
    </>
  );
};
