import React, { ReactNode, useState } from 'react';
import { GOOGLE_MAP_URL } from 'utils/map';
// components
import Box from '@material-ui/core/Box';
import { GoogleMap, GoogleMapProps, withGoogleMap, withScriptjs } from 'react-google-maps';

const MapProps = {
  googleMapURL: GOOGLE_MAP_URL, // for withScriptjs
  loadingElement: <Box height="100%" width="100%" data-testid="map-loading"/>, // for withScriptjs
  mapElement: <Box height="100%" width="100%" data-testid="map-map"/>, // for withGoogleMap
  containerElement: <Box height="100%" width="100%" data-testid="map-container"/>, // for withGoogleMap
};

export const MapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ]
};

type Google = typeof google;

export type CommonMapProps =
  GoogleMapProps
  & {
    children?: ReactNode | ReactNode[];
    onLoad?: (map: google.maps.Map, google: Google) => void;
  }

const GoogleMapWrapper = withScriptjs(withGoogleMap((props: CommonMapProps) => {
  const { children, onLoad } = props;
  const [map, setMap] = useState<GoogleMap>();
  const handleLoad = (ref: GoogleMap | null) => {
    const google = window.google;
    if (!google || !ref || ref === map) {
      return;
    }
    setMap(ref);
    // use context for take clear GoogleMap api object, not GoogleMap component from react-google-maps
    const gm = ref.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    onLoad && onLoad(gm, window.google);
  };

  return (
    <GoogleMap
      options={ MapOptions }
      { ...props }
      ref={ handleLoad }
    >
      { children }
    </GoogleMap>
  );
}));

/* Common map component, without any additional logic, please keep is clear */
export const CommonMap = (props: CommonMapProps): JSX.Element => {
  return <GoogleMapWrapper { ...MapProps } { ...props } />;
};


