import { FindDeviceSettingsParam, DeviceSettings, RequestSettingsBody } from 'models/device-emulation';

export const EMULATION_DEVICE_SETTINGS_DATA_LOAD_BY_ID = 'emulation/deviceSettings/LOAD_BY_ID';
export const EMULATION_DEVICE_SETTINGS_DATA_LOAD_START = 'emulation/deviceSettings/LOAD_START';
export const EMULATION_DEVICE_SETTINGS_DATA_LOAD_SUCCESS = 'emulation/deviceSettings/LOAD_SUCCESS';
export const EMULATION_DEVICE_SETTINGS_DATA_LOAD_FAILURE = 'emulation/deviceSettings/LOAD_FAILURE';
export const EMULATION_DEVICE_SETTINGS_CHANGE = 'emulation/deviceSettings/CHANGE';
export const EMULATION_DEVICE_SETTINGS_UPDATE = 'emulation/deviceSettings/UPDATE';

export interface LoadDeviceEmulationSettingsDataById {
  readonly type: typeof EMULATION_DEVICE_SETTINGS_DATA_LOAD_BY_ID;
  readonly deviceId: string;
}

export interface LoadDeviceEmulationSettingsDataStart {
  readonly type: typeof EMULATION_DEVICE_SETTINGS_DATA_LOAD_START;
  readonly params: FindDeviceSettingsParam | string;
}

export interface LoadDeviceEmulationSettingsDataFailure {
  readonly type: typeof EMULATION_DEVICE_SETTINGS_DATA_LOAD_FAILURE;
  readonly params: FindDeviceSettingsParam | string;
  readonly error: string;
}

export interface LoadDeviceEmulationSettingsDataSuccess {
  readonly type: typeof EMULATION_DEVICE_SETTINGS_DATA_LOAD_SUCCESS;
  readonly params: FindDeviceSettingsParam | string;
  readonly data: DeviceSettings[];
}

export interface ChangeDeviceEmulationSetting {
  readonly type: typeof EMULATION_DEVICE_SETTINGS_CHANGE;
  readonly settings: DeviceSettings;
  readonly action: 'create' | 'update' | 'delete';
}

export interface UpdateDeviceEmulationSetting {
  readonly type: typeof EMULATION_DEVICE_SETTINGS_UPDATE;
  readonly deviceId: string;
  readonly settings: RequestSettingsBody;
}

export const LoadDeviceEmulationSettingsDataById = (deviceId: string): LoadDeviceEmulationSettingsDataById => ({
  type: EMULATION_DEVICE_SETTINGS_DATA_LOAD_BY_ID, deviceId
});

export const LoadDeviceEmulationSettingsDataStart = (params: FindDeviceSettingsParam | string): LoadDeviceEmulationSettingsDataStart => ({
  type: EMULATION_DEVICE_SETTINGS_DATA_LOAD_START, params
});

export const LoadDeviceEmulationSettingsDataFailure = (params: FindDeviceSettingsParam | string, error: string): LoadDeviceEmulationSettingsDataFailure => ({
  type: EMULATION_DEVICE_SETTINGS_DATA_LOAD_FAILURE, params, error
});

export const LoadDeviceEmulationSettingsDataSuccess = (params: FindDeviceSettingsParam | string, data: DeviceSettings[]): LoadDeviceEmulationSettingsDataSuccess => ({
  type: EMULATION_DEVICE_SETTINGS_DATA_LOAD_SUCCESS, params, data
});

export const ChangeDeviceEmulationSetting = (settings: DeviceSettings, action: 'create' | 'update' | 'delete'): ChangeDeviceEmulationSetting => ({
  type: EMULATION_DEVICE_SETTINGS_CHANGE, settings, action
});

export const UpdateDeviceEmulationSetting = (deviceId: string, settings: RequestSettingsBody): UpdateDeviceEmulationSetting => ({
  type: EMULATION_DEVICE_SETTINGS_UPDATE, deviceId, settings
});

export type Action =
  | LoadDeviceEmulationSettingsDataById
  | LoadDeviceEmulationSettingsDataStart
  | LoadDeviceEmulationSettingsDataFailure
  | LoadDeviceEmulationSettingsDataSuccess
  | ChangeDeviceEmulationSetting
  | UpdateDeviceEmulationSetting
