import { GetHistoryParams } from 'models/rabbit';
import { getLocation } from 'utils/routing/query';

const endpointParam = '{endpointId}';

enum Path {
  history = '/api/rabbitmq/history',
  endpointList = '/api/rabbitmq/endpoints',
  endpointDetail = '/api/rabbitmq/endpoints/{endpointId}',
}

export const getRabbitHistoryPath = (): string => Path.history;
export const getRabbitHistoryLocation = (params: Partial<GetHistoryParams>) => getLocation(getRabbitHistoryPath(), params);
export const getRabbitEndpointListPath = (): string => Path.endpointList;
export const getRabbitEndpointDetailPath = (endpointId: string | number): string => {
  return Path.endpointDetail.replace(endpointParam, String(endpointId));
};
