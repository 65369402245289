/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Device option field name
 * @export
 * @enum {string}
 */
export enum DeviceField {
    PositionId = 'position_id',
    ActivationStatus = 'activation_status',
    FirmwareHash = 'firmware_hash',
    DamagedStatus = 'damaged_status',
    OwnerId = 'owner_id',
    EncryptionKeys = 'encryption_keys',
    HardwareType = 'hardware_type',
    HighLevelProtocolVersion = 'high_level_protocol_version',
    SoftType = 'soft_type'
}

