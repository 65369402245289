import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { usePagination } from 'hooks';
import { Helmet } from 'react-helmet-async';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'reducers';
import {
  ProvisioningDevicesFilters,
  ProvisioningDevicesFiltersLimit,
  initProvisioningShipmentFilters,
  initProvisioningDevicesFilters
} from 'models/provisioning';

// components
import { ProvisioningDevicesTableFilter, ProvisioningDevicesTable } from 'components';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';
import {
  DoSetProvisioningDevicesFilters,
  DoUpdateProvisioningDevicesFromUrl,
  DoFetchProvisioningDevices,
  DoFetchShipmentByParams
} from 'actions/provisioning';

const mapState = (state: RootState) => ({
  provisioning: state.provisioning.devices,
  isAdmin: state.user.isAdmin,
});

const mapDispatch = (dispatch: Dispatch) => ({
  fetchDevices: () => dispatch(DoFetchProvisioningDevices()),
  setFilters: (filters: ProvisioningDevicesFiltersLimit) => dispatch(DoSetProvisioningDevicesFilters(filters, true)),
  initData: () => dispatch(DoUpdateProvisioningDevicesFromUrl()),
  fetchShipments: () => dispatch(DoFetchShipmentByParams({
    ...initProvisioningShipmentFilters,
    limit: 9999
  })),
});

const connector = connect(
  mapState,
  mapDispatch
);

const ProvisioningDevices = (props: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = useStyles();
  const { initData, fetchDevices, setFilters, provisioning, isAdmin, fetchShipments } = props;
  const [isInit, setIsInit] = useState(false);
  const [filterState, setFilterState] = useState<ProvisioningDevicesFilters>(provisioning.filters);
  const devices = provisioning.devices.slice(0, provisioning.filters.limit);
  const { limit, offset } = usePagination();

  useEffect(() => {
    if (!isInit) {
      initData();
      fetchDevices();
      fetchShipments();
      setIsInit(true);
    }
  }, [initData, fetchDevices, fetchShipments, isInit]);

  // fetch table data
  useDeepCompareEffect(() => {
    if (isInit) {
      const filtersLimitOffset: ProvisioningDevicesFiltersLimit = {
        ...provisioning.filters,
        limit,
        offset,
      };
      setFilters(filtersLimitOffset);
      fetchDevices();
    }
  }, [provisioning.filters, offset, limit]);

  const updateFilters = (filters: ProvisioningDevicesFilters) => {
    const filtersLimitOffset: ProvisioningDevicesFiltersLimit = {
      ...filters,
      limit,
      offset,
    };
    setFilters(filtersLimitOffset);
  };
  const onSave = () => updateFilters(filterState);
  const onReset = () => {
    setFilterState(initProvisioningDevicesFilters);
    updateFilters(initProvisioningDevicesFilters);
  };

  return (
    <>
      <Helmet>
        <title>Devices provisioning | Devices</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ provisioning.isFetching }
          filter={ provisioning.filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <ProvisioningDevicesTableFilter
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div>
      <div className={ classes.table }>
        <ProvisioningDevicesTable
          data={ devices }
          isFetching={ provisioning.isFetching }
          total={ provisioning.total }
          isAdmin={ isAdmin }
        />
      </div>
    </>
  );
};

export default connector(ProvisioningDevices);
