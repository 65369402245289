import { createPath } from 'history';
import { getLocation } from 'utils/routing/query';
import { Template, TemplateSuite } from 'models/caller';
import { getCallerTemplateSuiteDetailPath, getCallerTemplateSuiteTemplatesPath as baseUrl } from './template-suite';
import { areRedesignedTemplateSuitesEnabled } from 'utils/feature-flags';

const templateParam = '{templateId}';

enum Path {
  list = '',
  create = '/create',
  detail = '/{templateId}',
  clone = '/{templateId}/clone',
  statistic = '/{templateId}/statistic',
}

export function getCallerTemplateListPath(suiteId: string) {
  return baseUrl(suiteId) + Path.list;
}

export function getCallerTemplateCreatePath(suiteId: string) {
  return baseUrl(suiteId) + Path.create;
}

function getCallerTemplateDetailLocationFromParams(suiteId: string, templateId: string) {
  if (areRedesignedTemplateSuitesEnabled()) {
    return getLocation(getCallerTemplateSuiteDetailPath(suiteId), { templateId });
  }

  return getLocation(
    baseUrl(suiteId) + Path.detail.replace(templateParam, templateId),
    {},
  );
}

export function getCallerTemplateDetailPath(suiteId: string, templateId: string) {
  return createPath(getCallerTemplateDetailLocationFromParams(suiteId, templateId));
}

export function getCallerTemplateClonePath(suiteId: string, templateId: string) {
  return baseUrl(suiteId) + Path.clone.replace(templateParam, templateId);
}

export function getCallerTemplateStatisticPath(suiteId: string, templateId: string) {
  return baseUrl(suiteId) + Path.statistic.replace(templateParam, templateId);
}

export function getCallerTemplateCreateLocation(suite: TemplateSuite) {
  const path = getCallerTemplateCreatePath(String(suite.template_suite_id));
  return getLocation(path, {});
}

export function getCallerTemplateDetailLocation(template: Template) {
  return getCallerTemplateDetailLocationFromParams(String(template.template_suite_id), String(template.template_id));
}

export function getCallerTemplateCloneLocation(template: Template) {
  const path = getCallerTemplateClonePath(String(template.template_suite_id), String(template.template_id));
  return getLocation(path, {});
}

export function getCallerTemplateStatisticLocation(template: Template) {
  const path = getCallerTemplateStatisticPath(String(template.template_suite_id), String(template.template_id));
  return getLocation(path, {});
}
