import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      '&[class*="MuiCard-root"]': {
        overflow: 'visible',
        justifyContent: 'space-between'
      },
      '& [class*="MuiTypography-subtitle1"]': {
        whiteSpace: 'nowrap'
      }
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    chart: {
      height: '100%',
      '&[class*="MuiCardContent-root"]': {
        padding: 0,
        '&:last-child': {
          padding: 0
        }
      }
    },
    rawChart: {
      borderRadius: '3px',
      margin: 0,
      height: '300px',
      background: theme.palette.grey[200]
    }
  }),
);

export default useStyles;
