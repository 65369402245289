import { TableCell, TableRow, Typography } from '@material-ui/core';
import { RawMessages } from 'models';

interface Props {
  cols: number;
  message: RawMessages;
}

export const MessageDetailInfo = ({ cols, message }: Props) => {
  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        <Typography variant="subtitle2">
          Actuality Type: <Typography variant="caption">{ message.actuality_type || 'None' }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Data link protocol id: <Typography variant="caption">{ message.data_link_protocol_id || 'None' }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Trace ID: <Typography variant="caption">{ message.trace_id || 'None' }</Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
};