import { createStyles, makeStyles } from 'styles/utils';

const useStyles = makeStyles(() =>
  createStyles({
    loadingLayout: {
      position: 'absolute',
      zIndex: 999,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(255,255,255,0.8)'
    },
  }),
);

export default useStyles;
