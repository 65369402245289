import React from 'react';
import { useProvisioningShipmentSelector } from 'hooks/provisioning';

interface Props {
  shipmentId: string;
}

export const ShipmentLabel = ({ shipmentId }: Props): JSX.Element => {
  const { shipment } = useProvisioningShipmentSelector(shipmentId);

  return (
    <>{ shipment ? shipment.name : shipmentId }</>
  );
};
