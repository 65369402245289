import React from 'react';
import { getDuration } from 'utils/datetime';

// components
import { Status } from 'components/Status';

// styles
import outerTheme from 'styles/themes/outer';
import useStyles from '../styles';

interface Props {
  status: boolean | undefined;
  label: string;
  lastChange?: string | Date;
}

export const StatusIcon = ({ status, label, lastChange }: Props) => {
  const classes = useStyles();
  return (
    <Status
      iconHidden={ status === undefined }
      iconColor={ status ? outerTheme.status.ok : outerTheme.status.error }
    >
      { label }
      { lastChange &&
        <span className={ classes.bsDuration }> { getDuration(lastChange) }</span>
      }
    </Status>
  );
};
