import { makeStyles, createStyles } from 'styles/utils';

export const useMuiControlStyles = makeStyles(_theme => createStyles({
  controlButton: {
    maxWidth: '100%',
    position: 'relative',
    fontSize: '14px',
    color: 'inherit',
    fontWeight: 400,
    textTransform: 'none',
  },
  controlButtonIcon: {
    position: 'relative',
    top: '-1px',
    marginLeft: '-9px',
    width: '28px',
    height: '28px',
  },
  controlButtonLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuPaper: {
    minWidth: '220px',
    maxHeight: '136px',
  },
}));
