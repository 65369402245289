import { getUrlItems, UrlItems } from 'utils/routing/query';

import {
  ShipmentFiltersFields as Fields,
  ShipmentFilters,
  Shipment
} from 'models/provisioning';
import {
  Action,
  FETCH_SHIPMENT_FAILURE,
  FETCH_SHIPMENT_SUCCESS,
  SET_SHIPMENT_FILTERS,
  FETCH_SHIPMENT_BY_ID,
  FETCH_SHIPMENT_BY_ID_SUCCESS,
  FETCH_SHIPMENT_BY_ID_FAILURE,
  SHIPMENT_UPDATE_SUCCESS,
  SHIPMENT_UPDATE_FAILURE,
  SHIPMENT_DELETE_SUCCESS,
  SHIPMENT_CREATE,
  SHIPMENT_CREATE_FAILURE,
  SHIPMENT_CREATE_SUCCESS,
  SHIPMENT_UPDATE_STATUS,
  SHIPMENT_UPDATE_STATUS_FAILURE,
  SHIPMENT_UPDATE_STATUS_SUCCESS,
  UPDATE_SHIPMENT_FILTERS_FROM_URL,
  FETCH_SHIPMENT_CSV_REPORT,
  FETCH_SHIPMENT_CSV_REPORT_FAILURE,
  FETCH_SHIPMENT_CSV_REPORT_SUCCESS,
  FETCH_SHIPMENT_BY_PARAMS,
} from 'actions/provisioning';

export interface State {
  shipments: Shipment[];
  total: number;
  isFetching: boolean;
  filters: ShipmentFilters;
  isCSVFetching: boolean;
}

function getFiltersFromUrl(urlState: UrlItems): ShipmentFilters {
  return {
    [Fields.shipment_status]: urlState[Fields.shipment_status] ? [].concat(urlState[Fields.shipment_status]) : [],
    [Fields.device_id]: urlState[Fields.device_id] ? [].concat(urlState[Fields.device_id]) : [],
    [Fields.box_id]: urlState[Fields.box_id] ? [].concat(urlState[Fields.box_id]).map(b => Number(b)) : [],
    [Fields.shipment_id]: urlState[Fields.shipment_id] ? [].concat(urlState[Fields.shipment_id]) : [],
    [Fields.recipient_id]: urlState[Fields.recipient_id] ? Number(urlState[Fields.recipient_id]) : undefined,
    [Fields.shipper_id]: urlState[Fields.shipper_id] ? Number(urlState[Fields.shipper_id]) : undefined,
    [Fields.owner_id]: urlState[Fields.owner_id] ? [].concat(urlState[Fields.owner_id]).map(b => Number(b)) : []
  };
}

export const initState: State = {
  shipments: [],
  isFetching: false,
  filters: {
    ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
  },
  total: 0,
  isCSVFetching: false
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_SHIPMENT_BY_ID:
    case SHIPMENT_CREATE:
    case SHIPMENT_UPDATE_STATUS:
    case FETCH_SHIPMENT_BY_PARAMS:
      return { ...state, isFetching: true };
    case FETCH_SHIPMENT_FAILURE:
      return { ...state, isFetching: false, shipments: [] };
    case FETCH_SHIPMENT_BY_ID_FAILURE:
    case SHIPMENT_DELETE_SUCCESS:
    case SHIPMENT_UPDATE_FAILURE:
    case SHIPMENT_CREATE_FAILURE:
    case SHIPMENT_UPDATE_STATUS_FAILURE:
      return { ...state, isFetching: false };
    case FETCH_SHIPMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shipments: action.shipments,
        total: action.total,
      };
    case SET_SHIPMENT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case SHIPMENT_UPDATE_STATUS_SUCCESS:
    case FETCH_SHIPMENT_BY_ID_SUCCESS:
    case SHIPMENT_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shipments: [action.shipment]
      };
    case SHIPMENT_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        shipments: [action.shipment, ...state.shipments]
      };
    case UPDATE_SHIPMENT_FILTERS_FROM_URL:
      return {
        ...state,
        filters: {
          ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
        }
      };
    case FETCH_SHIPMENT_CSV_REPORT:
      return { ...state, isCSVFetching: true };
    case FETCH_SHIPMENT_CSV_REPORT_FAILURE:
    case FETCH_SHIPMENT_CSV_REPORT_SUCCESS:
      return { ...state, isCSVFetching: false };
    default:
      return state;
  }
};
