import { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useFocusEffect } from 'hooks/effects';

import { RootState } from 'reducers';
import { DoGetDevicesHealthStat, GetStatParams, DoInitStatDm } from 'actions/device-statistic';
import { urlToFailuresTable, urlToFailuresInTheField, urlToSilentsInTheField, urlToTotalFailures } from '../../routing/paths';
import { initalChartData } from 'components/Charts/ChartsOptions/CommonOptions';
import { initStatFilters } from 'models/charts';

// components
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import { ReplacementsChart, ConnectivityChart, BrokenDevicesChart, FailureChart } from 'components/Charts';
import { DevicesDashboardFilter } from 'components';
import { Skeleton } from '@material-ui/lab';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from './DevicesHealthStyles';
import contentStyles from 'styles/page';

const mapState = (state: RootState) => ({
  stat: state.deviceStatistic,
  isFetching: state.deviceStatistic.isFetching,
  deviceStatFilters: state.deviceStatistic.filters,
  user: state.user,
});

const mapDispatch = (dispatch: Dispatch) => ({
  getStat: (filters: GetStatParams, quiet?: boolean) => dispatch(DoGetDevicesHealthStat(filters, true, quiet)),
  initStat: () => dispatch(DoInitStatDm()),
});

const connector = connect(
  mapState,
  mapDispatch
);

const Component = (props: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = {
    ...contentStyles(),
    ...useStyles(),
  };
  const { getStat, initStat, stat, isFetching } = props;
  const [filters, setFilters] = useState<GetStatParams>(stat.filters);

  useEffect(() => {
    setFilters(stat.filters);
  }, [stat.filters]);

  useEffect(() => {
    initStat();
  }, [initStat]);

  const fetchQuiet = useCallback(() => {
    getStat(filters, true);
  }, [filters, getStat]);
  useFocusEffect(fetchQuiet);

  const initChart = initalChartData(stat.filters.timeFrom, stat.filters.timeTo);

  const onSave = () => getStat(filters);
  const onReset = () => {
    setFilters(initStatFilters);
    getStat(initStatFilters);
  };
  return (
    <>
      <Helmet>
        <title>Devices Health</title>
      </Helmet>

      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ !!isFetching }
          filter={ filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <DevicesDashboardFilter
            filters={ filters }
            setFilters={ setFilters }
          />
        </FilterButton>
      </div>

      <Box display="flex" mt={ 1 }>
        <Grid container={ true } spacing={ 1 } alignContent="flex-start">
          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <ConnectivityChart
                        connectivity={ stat.connectivity.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        Connectivity: <span className={ classes.count2 }>{ `${stat.connectivity.total}%` }</span>
                      </Typography>
                      <Typography display="inline" className={ classes.marginRight }>
                        Installed Devices: <span className={ classes.count }>{ stat.installed }</span>
                      </Typography>
                      <Typography display="inline">
                        Empty Spaces: <span className={ classes.count }>{ stat.empty }</span>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <ReplacementsChart
                        data={ stat.replacement.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        Total replacements: <span className={ classes.count3 }>{ stat.replacement.log.reduce((prev, curr) => prev + curr.count, 0) }</span>
                      </Typography>
                      <Typography display="inline">
                        For selected period: <span className={ classes.count3 }>{ stat.replacement.total }</span>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <BrokenDevicesChart
                        damages={ stat.damage.log }
                        silents={ stat.silents.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        <span>Failures in the field: </span>
                        <Link
                          to={ urlToFailuresInTheField(stat.filters, props.user.isAdmin) }
                          className={ classes.count4 }
                        >
                          { stat.damage.total }
                        </Link>
                      </Typography>
                      <Typography display="inline" className={ classes.marginRight }>
                        <span>Silent devices: </span>
                        <Link
                          to={ urlToSilentsInTheField(stat.filters) }
                          className={ classes.count2 }
                        >
                          { stat.silents.total }
                        </Link>
                      </Typography>
                      <Typography display="inline">
                        <span>Total: </span>
                        <Link
                          to={ urlToTotalFailures(stat.filters) }
                          className={ classes.count }
                        >
                          { stat.silents.total + stat.damage.total }
                        </Link>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid item={ true } xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 }>
            <Card className={ classes.card }>
              <div className={ classes.details }>
                <Box boxShadow={ 3 } className={ clsx(classes.boxDiff, classes.replacementChart) }>
                  <CardContent className={ classes.chart }>
                    { isFetching ?
                      <Skeleton width="100%" height="100%" className={ classes.skelectonMargin } /> :
                      <FailureChart
                        failures={ stat.incidents.log }
                        chartData={ new Map(initChart) }
                      />
                    }
                  </CardContent>
                </Box>
                <CardContent className={ classes.cardContent }>
                  { isFetching ?
                    <Skeleton height={ 10 } /> :
                    <Box>
                      <Typography display="inline" className={ classes.marginRight }>
                        <span>Total failures: </span>
                        { stat.incidents.total }
                      </Typography>
                      <Typography display="inline">
                        <span>For selected period: </span>
                        <Link
                          to={ urlToFailuresTable(stat.filters, props.user.isAdmin) }
                          className={ classes.count4 }
                        >
                          { stat.incidents.log.reduce((prev, curr) => prev + curr.total, 0) }
                        </Link>
                      </Typography>
                    </Box>
                  }
                </CardContent>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const DevicesHealth = connector(Component);
