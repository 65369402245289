import { CreateTemplate, Templates } from './generated';

export interface GetTemplateParams {
  name?: string;
  body?: string;
  method?: string;
  url?: string;
  messageType?: string;
  templateSuiteId?: number;
  limit?: number;
  offset?: number;
}

export interface CreateTemplateParams extends CreateTemplate {
  template_id?: number;
}

export interface UpdateTemplateParams extends Templates {
  template_id: number;
}

export interface Template {
  template_id: number;
  template_suite_id: number;
  name: string;
  message_type: string;
  url: string;
  method: TemplateMethod;
  body?: string;
  headers?: TemplateHeaders;
  is_active?: boolean;
}

export interface TemplateHeaders {
  [key: string]: string;
}

export enum TemplateMethod {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export const METHOD_FOR_NOT_EDITABLE_MESSAGE_TYPE = 'POST';