import { StationLocation } from 'models/base-station';
import { LocationFormField } from './StationLocationForm';
import { FormErrors } from 'types/form';

export const validate = (location: StationLocation, fields: LocationFormField[]): FormErrors<LocationFormField> => {
  const errors: FormErrors<LocationFormField> = {};
  if (fields.includes(LocationFormField.owner_id)) {
    if (undefined === location.owner_id) {
      errors.owner_id = 'Owner is required';
    }
  }
  if (fields.includes(LocationFormField.country)) {
    if (!location.country || !location.country.trim()) {
      errors.country = 'Country is required';
    }
  }
  if (fields.includes(LocationFormField.city)) {
    if (!location.city || !location.city.trim()) {
      errors.city = 'City is required';
    }
  }
  if (fields.includes(LocationFormField.address)) {
    if (!location.address || !location.address.trim()) {
      errors.address = 'Address is required';
    }
  }

  return errors;
};
