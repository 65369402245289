import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { DamageReasons } from 'models';
import { IncidentReasonEnum } from 'models/device-monitoring';
import { FormProps } from 'types/form';
import { DamageState, FormField } from './types';
import { validate } from './validator';

// components
import { SelectOption, SingleSelectControl, TextControl } from 'components/Controls';

type Props = FormProps<DamageState, FormField>
export const MarkDamageForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  const reasonOptions: SelectOption[] =
    Object.entries(DamageReasons)
      .filter(([value]) => value !== IncidentReasonEnum.NoReason)
      .map(([value, label]) => ({ value, label }));

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(FormField.reason) ? '' :
        <SingleSelectControl
          isDisabled={ isView || disabledFields.includes(FormField.reason) }
          name="reason"
          label="Reason"
          isPortal
          values={ reasonOptions }
          selected={ state.reason }
          changeHandler={ reason => handleChange(FormField.reason, reason as IncidentReasonEnum) }
          error={ showErrors.includes(FormField.reason) && errors.reason }
        />
      }
      { !showFields.includes(FormField.comment) ? '' :
        <TextControl
          multiline
          disabled={ isView || disabledFields.includes(FormField.comment) }
          variant="outlined"
          rows="4"
          label="Comment"
          name="comment"
          value={ state.comment || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.comment, event.target.value ?? '')
          }
          error={ showErrors.includes(FormField.comment) ? errors?.comment !== undefined : false }
          helperText={ showErrors.includes(FormField.comment) ? errors?.comment : undefined }
        />
      }
    </form>
  );
};

