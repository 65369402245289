import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import * as dmGroupsActions from 'actions/device-management/groups';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import * as dmClient from 'clients/device-management';
import { ActionWithPromise } from 'utils/store';
import { ApiResponse, ResponseGroups, ResponseReason } from 'models/device-management';
import { clearQueryCache } from 'utils/react-query';

type DeleteGroup = dmGroupsActions.DeleteGroup;
type DeleteGroupResult = dmGroupsActions.DeleteGroupResult;
type DeleteGroupSagaAction = (
  | DeleteGroup
  | ActionWithPromise<DeleteGroup, DeleteGroupResult>
);

function* deleteGroupSaga(action: DeleteGroupSagaAction) {
  const response: ApiResponse = yield call(dmClient.deleteGroup, action.groupId);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while deleting a group: ${response.message}`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(response.message));
    }
    return;
  }

  yield put(dmGroupsActions.DoDeleteGroupSuccess(action.groupId));
  yield clearQueryCache(['deviceManagement/groups']);
  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

type CreateAction =
  | dmGroupsActions.CreateGroup
  | ActionWithPromise<dmGroupsActions.CreateGroup, dmGroupsActions.CreateGroupResult>;

function* groupCreate(action: CreateAction) {
  const response: ResponseGroups = yield call(dmClient.createGroup, action.payload);
  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while creating a group: ${ response.message }`));
    yield put(dmGroupsActions.DoCreateGroupFailed());

    'meta' in action && action.meta.promise.reject(new Error(response.message));
  } else {
    yield put(dmGroupsActions.DoCreateGroupSuccess(response.data[0].id));
    yield put(NotifySuccess('Group has been created'));
    yield clearQueryCache(['deviceManagement/groups']);
    'meta' in action && action.meta.promise.resolve(response.data[0]);
  }
}


export const deviceManagementGroupsSagas = [
  takeEvery(dmGroupsActions.DELETE_GROUP, deleteGroupSaga),
  takeEvery(dmGroupsActions.CREATE_GROUP, groupCreate)
];
