import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useAuthUserSelector, useFormActionLoader } from 'hooks';
import { useLabels } from 'hooks/device-management';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { PositionWithLevel, Label } from 'models/device-management';

import { useSetLabels } from './utils';

// components
import { Box, Button } from '@material-ui/core';

import { CommonDialog } from 'components';

import { AddLabel } from './AddLabel';
import { DialogLabels } from './DialogLabels';

interface EditPositionLabelsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  position: PositionWithLevel;
  positionOwnerId?: number;
  onSuccess?: () => void;
}

export const EditPositionLabelsDialog = ({
  isOpen,
  onClose,
  onCloseEnd,
  position,
  positionOwnerId,
  onSuccess,
}: EditPositionLabelsDialogProps) => {
  const { isAdmin } = useAuthUserSelector();

  const labelsQuery = useLabels({
    params: { owner: isAdmin ? positionOwnerId : undefined, limit: 2000, offset: 0 },
    enabled: isAdmin ? Boolean(positionOwnerId) : undefined,
  });

  const labelsLoading = !positionOwnerId || labelsQuery.isLoading;

  const userGroupsDictionary = useUserGroupsDictionarySelector();
  const positionOwner = userGroupsDictionary.userGroups.find(userGroup => userGroup.id === positionOwnerId);

  const initialPositionLabels = position.labels || [];
  const [isSelectionLabelsInit, setIsSelectionLabelsInit] = useState(false);
  const [positionLabels, setPositionLabels] = useState(initialPositionLabels);

  useDeepCompareEffect(() => {
    // init Selected Labels from props
    if(!isSelectionLabelsInit) {
      setPositionLabels(initialPositionLabels);
      setIsSelectionLabelsInit(true);
    } else {
      // check if Labels in component state exist and were not deleted
      setPositionLabels(positionLabels.filter(l => labelsQuery.labels.map(pl => pl.id).includes(l.id)));
    }
  }, [initialPositionLabels, labelsQuery.labels]);

  const { doAction } = useFormActionLoader();
  const setLabels = useSetLabels();

  const submitPositionLabels = () => {
    doAction({
      action: setLabels(
        position.id,
        initialPositionLabels.map(l => l.id),
        positionLabels.map(l => l.id),
      ),
      onSuccess: onSuccess,
    });
  };

  const handleLabelCreateSuccess = async (createdLabel: Label) => {
    if (labelsQuery.isLoading || labelsQuery.isFetching) {
      await labelsQuery.refetch();
    } else {
      labelsQuery.setLabelsLocally(prevLabels => [
        ...(prevLabels ?? []),
        createdLabel,
      ]);
    }
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      title="Edit Labels"
      content={
        <>
          <DialogLabels
            availableLabels={ labelsQuery.labels }
            availableLabelsLoading={ labelsLoading }
            positionLabels={ positionLabels }
            onChangePositionLabels={ setPositionLabels }
            positionOwnerId={ positionOwnerId }
            position={ position }
          />

          { isAdmin && (
            <Box mt={ 1 } mb={ 1 }>
              Showing only labels of position's owner
              { positionOwner && <>, <strong>{ positionOwner.name }</strong></> }
            </Box>
          ) }

          <AddLabel
            positionOwnerId={ positionOwnerId }
            onSuccess={ handleLabelCreateSuccess }
          />
        </>
      }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>
            Cancel
          </Button>

          <Button
            data-testid="edit-position-labels-submit"
            color="primary"
            variant="contained"
            onClick={ submitPositionLabels }
          >
            Save
          </Button>
        </>
      }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
    />
  );
};
