import React from 'react';

// components
import { LevelsTabTable } from './LevelsTabTable';

interface LevelsTabProps {
  tabActive: boolean;
  zoneId: number;
}

export const LevelsTab: React.FC<LevelsTabProps> = ({ tabActive, zoneId }) => {
  return (
    <LevelsTabTable
      zoneId={ zoneId }
      fetchingEnabled={ tabActive }
    />
  );
};
