import React from 'react';

import { DmTreeNode } from 'hooks/device-management/useDmTree/types';
import { isLoading, renderQuery } from './utils';

// components
import { Box } from '@material-ui/core';

import { Loader } from '../Loader';

interface DevicesPositionsCellProps {
  node: DmTreeNode;
}

export const DevicesPositionsCell = (props: DevicesPositionsCellProps) => {
  const content = renderContent(props);

  return content ? <Box display="flex">{ content }</Box> : null;
};

function renderContent({ node }: DevicesPositionsCellProps) {
  if ('totalDevices' in node && 'totalPositions' in node) {
    if (
      isLoading(node.totalDevices) &&
      node.totalDevices.data === undefined &&
      isLoading(node.totalPositions) &&
      node.totalPositions.data === undefined
    ) {
      return <Loader />;
    }

    return <>
      { renderQuery(node.totalDevices, total => total) }{ ' / ' }
      { renderQuery(node.totalPositions, total => total) }
    </>;
  }

  if ('device' in node) {
    return <>{ renderQuery(node.device, device => device?.device_id ?? 'No') }</>;
  }
}
