import React from 'react';

import { useDialog } from 'hooks';
import { RoutesState, getZoneIdsByRoutes } from 'hooks/caller';
import { TemplateSuite } from 'models/caller';

// components
import { Paper } from '@material-ui/core';

import { ConnectZoneDialog } from 'components/Caller/ConnectZoneDialog/ConnectZoneDialogComponent';

import { TemplateSuiteZonesTable } from './TemplateSuiteZonesTable';

interface TemplateSuiteZonesProps {
  templateSuite: TemplateSuite;
  routesState: RoutesState;
  className?: string;
  tableClassName?: string;
}

export const CallerTemplateSuiteZones = ({
  templateSuite,
  routesState,
  className,
  tableClassName,
}: TemplateSuiteZonesProps) => {
  const connectZoneDialog = useDialog();
  const zoneIds = getZoneIdsByRoutes(routesState.routes);

  return (
    <>
      <Paper className={ className } data-testid="connected-zones-table">
        <TemplateSuiteZonesTable
          routes={ routesState.routes }
          zoneIds={ zoneIds }
          isLoading={ routesState.isLoading }
          className={ tableClassName }
          onZoneDisconnect={ routesState.refetch }
          onZoneConnect={ connectZoneDialog.open }
        />
      </Paper>

      { connectZoneDialog.isMounted && (
        <ConnectZoneDialog
          isOpen={ connectZoneDialog.isOpen }
          data={ { suite: templateSuite, connectedZones: zoneIds } }
          onClose={ connectZoneDialog.close }
          onCloseEnd={ connectZoneDialog.unmount }
          onSuccess={ routesState.refetch }
        />
      ) }
    </>
  );
};
