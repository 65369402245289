import { DmTreeNode, TreeMethods } from '../types';

export async function collapse(
  _node: DmTreeNode,
  updateNode: TreeMethods['updateNode'],
) {
  updateNode(_node, (node) => {
    if (!('expansionStatus' in node)) {
      return;
    }

    if (node.expansionStatus !== 'expanded') {
      return;
    }

    node.expansionStatus = 'collapsed';
  });
}
