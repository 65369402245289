/* eslint-disable @typescript-eslint/indent */
import { sum } from 'lodash';
import moment from 'moment';

import { useCustomMemo } from 'hooks';
import { useCallerResponseCodeHistory } from 'hooks/caller';
import { CallerResponseCodeHistoryParams, ResponseCode } from 'models/caller-monitoring';

import { MonitoringSerie } from './utils';

type CountByDateByResponseCode = Record<ResponseCode, Map<string, number>>;

export type CountByTemplateByDateByResponseCode = Record<
  ResponseCode,
  Map<
    string, // date
    Map<
      number, // template id
      number  // count
    >
  >
>;

const ResponseCodeSerieColor: { [key in ResponseCode]: string } = {
  [ResponseCode.SUCCESS]: '#00838f',
  [ResponseCode.REDIRECT]: '#2887cc',
  [ResponseCode.VALIDATION_ERROR]: '#f9a825',
  [ResponseCode.SERVER_ERROR]: '#d84315',
  [ResponseCode.OTHER]: '#ccc',
};

interface ResponseCodeHistoryChartData {
  isLoading: boolean;
  data: MonitoringSerie[];
  maxValue?: number;
  countByTemplateByDateByResponseCode: CountByTemplateByDateByResponseCode;
}

export const useResponseCodeHistoryChartData = (params: CallerResponseCodeHistoryParams): ResponseCodeHistoryChartData => {
  const history = useCallerResponseCodeHistory(params);

  const result = useCustomMemo(() => {
    const countByTemplateByDateByResponseCode: CountByTemplateByDateByResponseCode = {
      [ResponseCode.SUCCESS]: new Map(),
      [ResponseCode.REDIRECT]: new Map(),
      [ResponseCode.VALIDATION_ERROR]: new Map(),
      [ResponseCode.SERVER_ERROR]: new Map(),
      [ResponseCode.OTHER]: new Map(),
    };

    if (history.isLoading) {
      return {
        isLoading: true,
        data: [],
        countByTemplateByDateByResponseCode,
      };
    }

    const countByDateByResponseCode: CountByDateByResponseCode = {
      [ResponseCode.SUCCESS]: new Map(),
      [ResponseCode.REDIRECT]: new Map(),
      [ResponseCode.VALIDATION_ERROR]: new Map(),
      [ResponseCode.SERVER_ERROR]: new Map(),
      [ResponseCode.OTHER]: new Map(),
    };

    for (const [templateId, templateHistory] of history.historyByTemplate.entries()) {
      for (const [responseCodeRaw, responseCodeHistory] of Object.entries(templateHistory)) {
        const responseCode = responseCodeRaw as ResponseCode;

        for (const record of responseCodeHistory) {
          const { date, count } = record;
          const countByDate = countByDateByResponseCode[responseCode];
          countByDate.set(date, (countByDate.get(date) ?? 0) + count);

          const countByTemplateByDate = countByTemplateByDateByResponseCode[responseCode];
          const countByTemplate = countByTemplateByDate.get(date) ?? new Map<number, number>();
          countByTemplate.set(templateId, (countByTemplate.get(templateId) ?? 0) + count);
          countByTemplateByDate.set(date, countByTemplate);
        }
      }
    }

    const series: MonitoringSerie[] = Object.entries(countByDateByResponseCode)
      .map(([responseCode, countByDate]) => {
        const now = moment();

        return {
          id: responseCode,
          data: [...countByDate.entries()].map(([date, count]) => ({
            x: moment(date).format('YYYY-MM-DD HH:mm'),
            y: count || (now.isBefore(date) ? null : count),
          })),
          total: sum([...countByDate.values()]),
          color: ResponseCodeSerieColor[responseCode as ResponseCode],
        };
      });

    const values = series.flatMap(serie =>
      serie.data.flatMap(datum =>
        typeof datum.y === 'number' ? [datum.y] : []
      )
    );

    return {
      isLoading: false,
      data: series,
      maxValue: values.length ? Math.max(...values) : undefined,
      countByTemplateByDateByResponseCode,
    };
  }, [history.historyByTemplate, history.isLoading]);

  return result;
};
