import { StationLoRaWANCreate } from 'models/base-station';
import { LoraFormField } from './types';
import { FormErrors } from 'form';

export const validate = (station: StationLoRaWANCreate, fields: LoraFormField[]): FormErrors<LoraFormField> => {
  const errors: FormErrors<LoraFormField> = {};
  if (fields.includes(LoraFormField.eui)) {
    if (!station.eui) {
      errors.eui = 'Gateway EUI is required';
    }
  }

  return errors;
};
