import { sumBy } from 'lodash';
import React from 'react';

import { formatAsPercent } from 'utils/format';
import { MonitoringChartLegendProps } from '../types';

// components
import { Grid, Typography } from '@material-ui/core';

// styles
import { useStyles } from './styles';


export const MonitoringChartLegend = (props: MonitoringChartLegendProps) => {
  const classes = useStyles();
  const {
    entries,
    formatLabel = entry => entry.label ?? entry.id,
    total = sumBy(entries, entry => entry.value),
  } = props;

  const longestFractionLength = Math.max(
    ...entries.map(entry => formatAsPercent(entry.value, total).length),
  );

  return (
    <div className={ classes.root } data-testid="monitoring-chart-legend">
      <Grid
        container
        spacing={ 1 }
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        data-testid="monitoring-chart-legend-total"
      >
        <Grid item>
          <Typography variant="inherit" className={ classes.totalLabel }>
            Total
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="inherit" className={ classes.totalValue }>
            { total }
          </Typography>
        </Grid>
      </Grid>

      { entries.map((e, index) => (
        <Grid
          container
          spacing={ 1 }
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          key={ index }
          data-testid="monitoring-chart-legend-item"
        >
          <Grid item className={ classes.label }>
            { e.color && (
              <span
                className={ classes.icon }
                style={ { backgroundColor: e.color } }
                data-testid="monitoring-chart-legend-item-color"
              />

            ) }
            { formatLabel(e) }
          </Grid>

          <Grid item className={ classes.valueContainer }>
            <span className={ classes.value }>
              { e.value }
            </span>

            { typeof e.value === 'number' && (
              <span
                className={ classes.valueFraction }
                style={ { minWidth: (longestFractionLength + 2) * 6 } }
              >
                { ' ' }({ formatAsPercent(e.value, total) })
              </span>
            ) }
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
