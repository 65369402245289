import { StationGeneration } from 'models/base-station';
import { StationStatusTitle } from 'models/base-station-status';
import { SelectOption } from 'components/Controls/Select';

export enum OnlineStatus {
  yes = 'yes',
  no = 'no',
}

export const onlineStatusOptions: SelectOption[] = [
  { label: StationStatusTitle.Online, value: OnlineStatus.yes },
  { label: StationStatusTitle.Offline, value: OnlineStatus.no },
];

export enum HealthyStatus {
  yes = 'yes',
  no = 'no',
}

export const healthyStatusOptions: SelectOption[] = [
  { label: StationStatusTitle.Healthy, value: HealthyStatus.yes },
  { label: StationStatusTitle.Unhealthy, value: HealthyStatus.no },
];

export type FilterState = {
  owner?: number;
  projects: number[];
  stationId?: number;
  eui?: string;
  country?: string;
  city?: string;
  generation?: StationGeneration;
  onlineStatus?: OnlineStatus;
  healthyStatus?: HealthyStatus;
}

export const initialState: FilterState = {
  projects: []
};
