import { TreeNode, TreeTableRow, TreeNodeWithChildren } from './types';
import { isNodeWithChildren } from './utils';

export function getTreeTableRows<TNode extends TreeNode<unknown>>(
  nodes: TNode[]
): TreeTableRow<TNode>[] {
  const rows = fillNestingLevel(nodes);
  const flattenedVisibleRows = getFlattenedVisibleRows(rows);

  return flattenedVisibleRows;
}

function fillNestingLevel<TNode extends TreeNode<unknown>>(
  nodes: TNode[],
  level = 0,
): TreeTableRow<TNode>[] {
  return nodes.map(node => ({
    type: 'normal',
    node,
    nestingLevel: level,
    childrenRows: isNodeWithChildren(node)
      ? (fillNestingLevel(node.children, level + 1) as TreeTableRow<TNode>[])
      : []
  }));
}

function getFlattenedVisibleRows<TNode extends TreeNode<unknown>>(
  rows: TreeTableRow<TNode>[],
): TreeTableRow<TNode>[] {
  return rows.flatMap(row => [row, ...(
    (
      row.type === 'normal' &&
      isRowForNodeWithChildren(row) &&
      row.node.expansionStatus === 'expanded'
    )
      ? getFlattenedRowChildren(row) as unknown as TreeTableRow<TNode>[]
      : []
  )]);
}

function isRowForNodeWithChildren(
  row: TreeTableRow<unknown>
): row is TreeTableRow<TreeNodeWithChildren<unknown>> {
  return isNodeWithChildren(row.node);
}

function getFlattenedRowChildren<TNode extends TreeNodeWithChildren<unknown>>(
  row: TreeTableRow<TNode>,
): TreeTableRow<TNode>[] {
  const rows = getFlattenedVisibleRows(row.childrenRows);

  if (
    row.nestingLevel > 0 &&
    row.node.childrenTotal.data &&
    row.node.childrenLimit < row.node.childrenTotal.data &&
    row.node.childrenLoadStatus === 'success'
  ) {
    rows.push(createLoadMoreChildrenRow(row));
  }

  return rows;
}

function createLoadMoreChildrenRow<TParentNode extends TreeNodeWithChildren<unknown>>(
  row: TreeTableRow<TParentNode>,
): TreeTableRow<TParentNode> {
  return {
    type: 'load-more-children',
    node: row.node,
    nestingLevel: row.nestingLevel + 1,
    childrenRows: [],
  };
}
