import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { Filters } from '../utils';
// components
import { FilterButton } from 'components/Buttons';
import { DeviceManagementAllFilter } from 'components/DeviceManagement/All';

interface Props {
  isLoading: boolean;
  filter: Filters;
  onChange: (filter: Filters) => void;
}

const EMPTY_FILTER: Filters & { offset: number } = {
  owner: undefined,
  projects: undefined,
  zones: undefined,
  levels: undefined,
  groups: undefined,
  positions: undefined,
  networkIds: undefined,
  customIds: undefined,
  innerIds: undefined,
  devices: undefined,
  labels: undefined,

  offset: 0,
};

export const PageFilter = (props: Props): JSX.Element => {
  const { isLoading, filter, onChange } = props;

  const [filterState, setFilterState] = useState<Filters>(filter);
  // Reload state from external filter
  useDeepCompareEffect(() => {
    setFilterState(filter);
  }, [filter]);

  const handleReset = (): void => {
    setFilterState(EMPTY_FILTER);
    onChange(EMPTY_FILTER);
  };

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filter }
      onSave={ () => onChange(filterState) }
      onReset={ handleReset }
    >
      <DeviceManagementAllFilter
        state={ filterState }
        setState={ setFilterState }
      />
    </FilterButton>
  );
};