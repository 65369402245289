import React from 'react';

import { CommonProps, MultiProps, SingleProps, Option, SelectControl } from 'components/Controls/Select';
import { DeviceStateSeverityEnumModel } from 'models/device-monitoring';

type Value = DeviceStateSeverityEnumModel;

type Props =
  Partial<Omit<CommonProps<Value>, 'options'>>
  & (SingleProps<Value> | MultiProps<Value>)


const options: Option<Value>[] = [
  {
    value: DeviceStateSeverityEnumModel.Critical,
    label: 'Critical',
  },
  {
    value: DeviceStateSeverityEnumModel.Warning,
    label: 'Warning',
  },
  {
    value: DeviceStateSeverityEnumModel.Info,
    label: 'Info',
  },
  {
    value: DeviceStateSeverityEnumModel.Ok,
    label: 'Ok',
  },
];

const Component = (props: Props): JSX.Element => (
  <SelectControl
    name="levels"
    label="Severity"
    closeMenuOnSelect={ !props.isMulti }
    { ...props }
    options={ options }
    isDisabled={ props.isDisabled || !options.length }
  />
);

export default Component;
