import { RouteConfig } from 'react-router-config';

import { isUUID } from 'helpers';
import { State as CurrentUserProfile } from 'reducers/user-profile';
import * as paths from 'routing/paths';
import * as permission from 'utils/permissions';
import { getParam } from 'utils/routing';
import { Parser } from 'utils/routing/parser';

// components
import { dmTestDevicesListPath } from 'routing/paths';
import { Zone, Devices as DevicesPage, DevicePositionCouple, Groups, PositionGroup } from 'pages';
import { DeviceManagementAllPage } from 'pages/DeviceManagement/All/DeviceManagementAllPage';
import { DeviceNotFoundPage } from 'pages/DeviceManagement/Devices/DeviceNotFoundPage';
import Positions from 'pages/DeviceManagement/Positions/PositionsPage';
import Zones from 'pages/DeviceManagement/Zones/ZonesPage';
import { CreateZone } from 'pages/DeviceManagement/Zone/CreateZone/CreateZoneComponent';
import { CreateGroup } from 'pages/DeviceManagement/Groups/CreateGroupPage';
import { TestDevicesPage } from 'pages/DeviceManagement/TestDevices/TestDevicesPage';
import { MainEntity as DevicePositionPageMainEntity } from 'pages/DevicePositionCouple/types';

import ZoneRouteLabel from './ZoneRouteLabel';
import PositionGroupRouteLabel from './PositionGroupRouteLabel';
import { PositionRouteLabel } from './PositionRouteLabel';
import { isDmAllPageEnabled } from 'utils/feature-flags';

const DEVICE_ID_PARAM_NAME = 'deviceId';
const POSITION_ID_PARAM_NAME = 'positionId';
const ZONE_ID_PARAM_NAME = 'zoneId';
const GROUP_ID_PARAM_NAME = 'groupId';

export interface DevicePositionCoupleRouteParams {
  [DEVICE_ID_PARAM_NAME]?: string;
  [POSITION_ID_PARAM_NAME]?: string;
}

function getDevicePositionPageParams(routeParams: DevicePositionCoupleRouteParams) {
  const { deviceId, positionId: routePositionId } = routeParams;

  if (!routePositionId) {
    return { deviceId, positionId: undefined, networkId: undefined };
  }

  if (isUUID(routePositionId)) {
    return { deviceId, positionId: undefined, networkId: routePositionId };
  }

  return {
    deviceId,
    positionId: parseInt(routePositionId, 10),
    networkId: undefined,
  };
}

export function getDeviceManagementRoutes(userData: CurrentUserProfile) {
  const { data: user } = userData;

  const routes: RouteConfig[] = [
    {
      path: '/devices/dm',
      label: 'Device Management',
      icon: 'grain',
      routes: [
        {
          path: '/devices/dm/all',
          label: 'All',
          icon: 'dmTree',
          hiddenOnMainMenu: !isDmAllPageEnabled(userData),
          isShowByUrl: isDmAllPageEnabled(userData),
          render: () => <DeviceManagementAllPage />,
        },
        {
          path: paths.dmZonesPath,
          label: 'Zones',
          icon: 'tripOrigin',
          getRouteComponent: options => options.match?.isExact ? Zones : undefined,
          hiddenOnMainMenu: !permission.isAllowedToReadZones(user),
          isShowByUrl: permission.isAllowedToReadZones(user),
          routes: [
            {
              path: paths.dmZonesCreatePath,
              label: 'Create',
              render: ({ match }) => match.isExact && <CreateZone />,
              hiddenOnMainMenu: true,
              isShowByUrl: permission.isAllowedToCreateZones(user),
            },
            {
              path: paths.dmZonePath(`:${ZONE_ID_PARAM_NAME}`),
              exact: true,
              render: ({ match }) => <Zone zoneId={Number(match.params[ZONE_ID_PARAM_NAME])} />,
              label: ({ match }) => {
                const zoneId = getParam(ZONE_ID_PARAM_NAME, match);
                return zoneId && <ZoneRouteLabel zoneIdParam={zoneId} />;
              },
              hiddenOnMainMenu: true,
              isShowByUrl: permission.isAllowedToReadZones(user),
            },
          ],
        },
        {
          path: paths.dmPositionGroupsPath,
          label: 'Groups',
          icon: 'linearScale',
          hiddenOnMainMenu: false,
          getRouteComponent: options => options.match.isExact ? Groups : undefined,
          isShowByUrl: true,
          routes: [
            {
              path: paths.dmGroupsCreatePath,
              label: 'Create Group',
              render: ({ match }) => match.isExact && <CreateGroup />,
              hiddenOnMainMenu: true,
              isShowByUrl: true,
            },
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              path: paths.dmPositionGroupPath(`:${GROUP_ID_PARAM_NAME}` as any),
              exact: true,
              render: ({ match }) => (
                <PositionGroup groupId={Number(match.params[GROUP_ID_PARAM_NAME])} />
              ),
              label: ({ match }) => {
                const groupId = getParam(GROUP_ID_PARAM_NAME, match);
                return groupId && <PositionGroupRouteLabel groupIdParam={groupId} />;
              },
              hiddenOnMainMenu: true,
              isShowByUrl: true,
            },
          ],
        },
        {
          path: paths.dmPositionsPath(),
          label: 'Positions',
          icon: 'place',
          hiddenOnMainMenu: false,
          getRouteComponent: options => options.match?.isExact ? Positions : undefined,
          routes: [
            {
              path: paths.dmPositionPath(`:${POSITION_ID_PARAM_NAME}`),
              exact: true,
              label: ({ match }) => {
                const positionId = getParam(POSITION_ID_PARAM_NAME, match);
                return positionId && <PositionRouteLabel positionId={Number(positionId)} />;
              },
              title: ({ match }) => match ? `Position ${getParam(POSITION_ID_PARAM_NAME, match)}` : undefined,
              render: (props) => (
                <DevicePositionCouple
                  mainEntity={DevicePositionPageMainEntity.POSITION}
                  {...getDevicePositionPageParams(props.match.params)}
                  {...props}
                />
              ),
              hiddenOnMainMenu: true,
            },
          ],
        },
        {
          path: paths.dmDevicesPath(),
          label: 'Devices',
          getRouteComponent: (options) => options.match.isExact ? DevicesPage : undefined,
          icon: 'flashOn',
          hiddenOnMainMenu: !permission.isAllowedToReadDevice(user),
          isShowByUrl: permission.isAllowedToReadDevice(user),
          routes: [
            {
              path: paths.dmDevicePath(`:${DEVICE_ID_PARAM_NAME}`),
              exact: true,
              label: ({ match }) => getParam(DEVICE_ID_PARAM_NAME, match)?.toUpperCase(),
              title: ({ match }) => match ? `Device ${ getParam(DEVICE_ID_PARAM_NAME, match)?.toUpperCase() }` : undefined,
              render: (props) => {
                const parser = new Parser(props.match.params as DevicePositionCoupleRouteParams);
                return (
                  !parser.asHex(DEVICE_ID_PARAM_NAME)
                    ? <DeviceNotFoundPage deviceId={ getParam(DEVICE_ID_PARAM_NAME, props.match) }/>
                    : <DevicePositionCouple
                      mainEntity={ DevicePositionPageMainEntity.DEVICE }
                      { ...getDevicePositionPageParams(props.match.params) }
                      { ...props }
                    />
                );
              },
              hiddenOnMainMenu: true,
              isShowByUrl: permission.isAllowedToUpdateDevice(user),
            },
          ]
        },
        {
          path: dmTestDevicesListPath,
          exact: true,
          label: 'Virtual Devices',
          icon: 'flashOn',
          isShowByUrl: permission.isAllowedToReadDevice(user),
          hiddenOnMainMenu: !permission.isAllowedToReadDevice(user),
          render: () => <TestDevicesPage />
        },
        {
          path: '/devices/dm/installation-log',
          exact: true,
          label: 'Installation Log',
          isShowByUrl: false,
        },
        {
          path: '/devices/dm/warranty',
          exact: true,
          label: 'Warranty',
          isShowByUrl: false,
        },
      ]
    }
  ];
  return routes;
}
