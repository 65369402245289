import { BaseStation, LorawanStation, Station, StationLocation } from 'models/base-station';

export const BASE_STATION_CREATE = 'BASE_STATION_CREATE';
export const BASE_STATION_UPDATE = 'BASE_STATION_UPDATE';
export const BASE_STATION_DELETE = 'BASE_STATION_DELETE';

export const BASE_STATION_LOCATION_ATTACH = 'BASE_STATION_LOCATION_ATACH';
export const BASE_STATION_LOCATION_DETACH = 'BASE_STATION_LOCATION_DETACH';
export const BASE_STATION_LOCATION_REPLACE = 'BASE_STATION_LOCATION_REPLACE';


export interface CreateStation {
  readonly type: typeof BASE_STATION_CREATE;
  station: Station;
}

export interface UpdateStation {
  readonly type: typeof BASE_STATION_UPDATE;
  station: Station;
}

export interface DeleteStation {
  readonly type: typeof BASE_STATION_DELETE;
  station: Station;
}

export interface AttachStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_ATTACH;
  station: BaseStation;
  location: StationLocation;
}

export interface DetachStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_DETACH;
  station: BaseStation;
}

export interface ReplaceStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_REPLACE;
  oldStation: BaseStation | LorawanStation;
  newStation: BaseStation | LorawanStation;
  location: StationLocation;
}

export const CreateStation: (station: Station) => CreateStation = (station: Station) => ({
  type: BASE_STATION_CREATE, station
});

export const UpdateStation: (station: Station) => UpdateStation = (station: Station) => ({
  type: BASE_STATION_UPDATE, station
});

export const DeleteStation: (station: Station) => DeleteStation = (station: Station) => ({
  type: BASE_STATION_DELETE, station
});

export const AttachStationLocation = (station: BaseStation, location: StationLocation): AttachStationLocation => ({
  type: BASE_STATION_LOCATION_ATTACH, station, location
});

export const DetachStationLocation = (station: BaseStation): DetachStationLocation => ({
  type: BASE_STATION_LOCATION_DETACH, station
});

export const ReplaceStationLocation = (oldStation: BaseStation | LorawanStation, newStation: BaseStation  | LorawanStation, location: StationLocation): ReplaceStationLocation => ({
  type: BASE_STATION_LOCATION_REPLACE, oldStation, newStation, location
});

export type Action =
  | CreateStation
  | UpdateStation
  | DeleteStation
  | AttachStationLocation
  | DetachStationLocation
  | ReplaceStationLocation
