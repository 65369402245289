import { useQuery } from 'react-query';
import { GetStationParams, Station, StationType } from 'models/base-station';
import { useFormActionNotifier } from 'hooks/form';
import { ensureRequestSucceeded } from 'utils/clients';
import { getStationsByParams } from 'clients/base-stations';

import { ApiResponseWithTotal } from 'models';

export interface StationState {
  station?: Station;
  isLoading: boolean;
}

export function useStationSelector(stationId: number, enabled = true): StationState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['baseStation/station', stationId],
    queryFn: async (): Promise<ApiResponseWithTotal<Station[]>> => {
      const result: ApiResponseWithTotal<Station[]> = await getStationsByParams({ stationIds: [stationId] });
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching base station #${ stationId }: ${ (e as Error).message }`),
    enabled,
  });

  return {
    station: queryResult.data?.data?.[0],
    isLoading: queryResult.isLoading,
  };
}

export interface StationsState {
  stations: Station[];
  isLoading: boolean;
  total: number;
}

export function useStationsParamsSelector(params: GetStationParams, enabled = true): StationsState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['baseStation/station', params],
    queryFn: async (): Promise<ApiResponseWithTotal<Station[]>> => {
      const result: ApiResponseWithTotal<Station[]> = await getStationsByParams(params);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching base stations: ${ (e as Error).message }`),
    enabled,
  });

  return {
    stations: queryResult.data?.data ?? [],
    isLoading: queryResult.isLoading,
    total: queryResult.data?.total ?? 0,
  };
}

export function useStationsDictionarySelector(enabled = true): StationsState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['baseStation/station/dictionary'],
    queryFn: async (): Promise<ApiResponseWithTotal<Station[]>> => {
      const result: ApiResponseWithTotal<Station[]> = await getStationsByParams({ limit: 999 });
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching base stations: ${ (e as Error).message }`),
    enabled,
  });

  return {
    stations: queryResult.data?.data ? queryResult.data?.data.map(s => ({ ...s, type: StationType.station })) : [],
    isLoading: queryResult.isLoading,
    total: queryResult.data?.total ?? 0,
  };
}