import React, { ReactNode } from 'react';

// components
import { Button, Box, Card, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { CommonButton } from 'components/Buttons';

// styles
import { useStyles } from '../styles';

interface Props {
  title: string;
  children: ReactNode;
  btnAction: string;
  onAction: () => void;
  onClose: () => void;
  onDelete?: () => void;
  canDeleteStation?: boolean;
}

export const FormBlock = ({ title, children, btnAction, onAction, onClose, onDelete, canDeleteStation  }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={ classes.formBlock }>
      <Card square className={ classes.formRoot }>
        <CardHeader
          disableTypography
          className={ classes.formHeader }
          title={
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography className={ classes.formTitle }>{ title }</Typography>
              <CommonButton
                type="icon"
                icon={ <Close /> }
                label="Close"
                onClick={ onClose }
                ButtonProps={{ color: 'inherit' }}
              />
            </Box>
          }
        >
        </CardHeader>
        <CardContent className={ classes.formContent }>
          { children }
        </CardContent>
        <CardActions className={ classes.formActions }>
          {canDeleteStation && (
            <Button className={ classes.deleteBtn } onClick={ onDelete } >
            Delete
            </Button>
          )}
          <Button color="primary" onClick={ onClose } >
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={ onAction }>
            { btnAction }
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
