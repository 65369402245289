import { Group, GroupCreateFields } from 'models/device-management';

export const DELETE_GROUP = 'dm/DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'dm/DELETE_GROUP_SUCCESS';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export interface DeleteGroup {
  type: typeof DELETE_GROUP;
  groupId: number;
}
export interface DeleteGroupSuccess {
  type: typeof DELETE_GROUP_SUCCESS;
  groupId: number;
}
export type DeleteGroupResult = void;

export interface CreateGroup {
  type: typeof CREATE_GROUP;
  payload: GroupCreateFields[];
}
export type CreateGroupResult = Group;

export interface CreateGroupSuccess {
  type: typeof CREATE_GROUP_SUCCESS;
  id: number;
}

export interface CreateGroupFailed {
  type: typeof CREATE_GROUP_FAILED;
}

export const deleteGroup = (groupId: number): DeleteGroup => ({
  type: DELETE_GROUP,
  groupId,
});
export const DoDeleteGroupSuccess = (groupId: number): DeleteGroupSuccess => ({
  type: DELETE_GROUP_SUCCESS,
  groupId,
});

export const DoCreateGroup: (payload: GroupCreateFields[]) => CreateGroup = payload => ({
  type: CREATE_GROUP,
  payload
});
export const DoCreateGroupSuccess: (id: number) => CreateGroupSuccess = id => ({
  type: CREATE_GROUP_SUCCESS,
  id
});
export const DoCreateGroupFailed: () => CreateGroupFailed = () => ({
  type: CREATE_GROUP_FAILED
});

export type Action =
  DeleteGroup |
  DeleteGroupSuccess |
  CreateGroup |
  CreateGroupSuccess |
  CreateGroupFailed;
