import React from 'react';
import { withStyles } from 'styles/utils';
import { Theme as MUITheme } from '@material-ui/core/styles';

// components
import { Tooltip } from '@material-ui/core';
import WarningSvg from 'images/icons/warning_black.svg';

type Props<TUser> = {
  user?: TUser;
}

const LightTooltip = withStyles((theme: MUITheme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#6f6f6f',
    boxShadow: '0px 4px 5px -2px rgb(222 222 222 / 20%), 0px 7px 10px 1px rgb(175 175 175 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%);',
    fontSize: 12,
    margin: '-5px 20px'
  },
}))(Tooltip);

const UserNotConfirmed = <TUser extends { confirmed: boolean }>({ user }: Props<TUser>) => {
  if (!user || user.confirmed) {
    return null;
  }

  return (
    <LightTooltip title="Account not confirmed" placement="top-start">
      <img src={ WarningSvg } alt="Not confirmed" style={{ marginLeft: '5px' }} />
    </LightTooltip>
  );
};

export default UserNotConfirmed;