import { GetStatusParam, StationStatusFlat } from 'models/base-station-status';

export const BASE_STATION_STATUS_DATA_LOAD_BY_ID = 'bs/stationStatus/LOAD_BY_ID';
export const BASE_STATION_STATUS_DATA_LOAD_BY_PARAMS = 'bs/stationStatus/LOAD_BY_PARAMS';
export const BASE_STATION_STATUS_DATA_LOAD_START = 'bs/stationStatus/LOAD_START';
export const BASE_STATION_STATUS_DATA_LOAD_FAILURE = 'bs/stationStatus/LOAD_FAILURE';
export const BASE_STATION_STATUS_DATA_LOAD_SUCCESS = 'bs/stationStatus/LOAD_SUCCESS';
export const BASE_STATION_STATUS_START_BACKGROUND_SYNC = 'bs/stationStatus/START_BACKGROUND_SYNC';
export const BASE_STATION_STATUS_STOP_BACKGROUND_SYNC = 'bs/stationStatus/STOP_BACKGROUND_SYNC';

export interface LoadStationStatusDataById {
  readonly type: typeof BASE_STATION_STATUS_DATA_LOAD_BY_ID;
  readonly stationId: number;
}

export interface LoadStationStatusDataByParams {
  readonly type: typeof BASE_STATION_STATUS_DATA_LOAD_BY_PARAMS;
  readonly params: GetStatusParam;
}

export interface LoadStationStatusDataStart {
  readonly type: typeof BASE_STATION_STATUS_DATA_LOAD_START;
  readonly params: GetStatusParam | number;
}

export interface LoadStationStatusDataFailure {
  readonly type: typeof BASE_STATION_STATUS_DATA_LOAD_FAILURE;
  readonly params: GetStatusParam | number;
  readonly error: string;
}

export interface LoadStationStatusDataSuccess {
  readonly type: typeof BASE_STATION_STATUS_DATA_LOAD_SUCCESS;
  readonly params: GetStatusParam | number;
  readonly data: StationStatusFlat[];
}

export interface StartStationDataSync {
  readonly type: typeof BASE_STATION_STATUS_START_BACKGROUND_SYNC;
  readonly id?: number;
}

export interface StopStationDataSync {
  readonly type: typeof BASE_STATION_STATUS_STOP_BACKGROUND_SYNC;
}


export const LoadStationStatusDataById = (stationId: number): LoadStationStatusDataById => ({
  type: BASE_STATION_STATUS_DATA_LOAD_BY_ID, stationId
});

export const LoadStationStatusDataByParams = (params: GetStatusParam): LoadStationStatusDataByParams => ({
  type: BASE_STATION_STATUS_DATA_LOAD_BY_PARAMS, params
});

export const LoadStationStatusDataStart = (params: GetStatusParam | number): LoadStationStatusDataStart => ({
  type: BASE_STATION_STATUS_DATA_LOAD_START, params
});

export const LoadStationStatusDataFailure = (params: GetStatusParam | number, error: string): LoadStationStatusDataFailure => ({
  type: BASE_STATION_STATUS_DATA_LOAD_FAILURE, params, error
});

export const LoadStationStatusDataSuccess = (params: GetStatusParam | number, data: StationStatusFlat[]): LoadStationStatusDataSuccess => ({
  type: BASE_STATION_STATUS_DATA_LOAD_SUCCESS, params, data
});

export const StartStationDataSync = (id?: number): StartStationDataSync => ({
  type: BASE_STATION_STATUS_START_BACKGROUND_SYNC,
  id
});

export const StopStationDataSync = (): StopStationDataSync => ({
  type: BASE_STATION_STATUS_STOP_BACKGROUND_SYNC
});

export type Action =
  | LoadStationStatusDataById
  | LoadStationStatusDataByParams
  | LoadStationStatusDataStart
  | LoadStationStatusDataFailure
  | LoadStationStatusDataSuccess
  | StartStationDataSync
  | StopStationDataSync
