import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterControl: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      maxWidth: '300px',
    },
  }),
);

export default useStyles;
