import React from 'react';
import moment from 'moment';
import { RawMessagesFilters } from 'models';

// components
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomIdSelect from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import { NetworkIdSelect } from 'components/Controls/NetworkIdSelect/NetworkIdSelectComponent';
import { DateTimeControl, SingleSelectControl, StationSelectControl, TextControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';

// styles
import contentStyles from 'styles';

interface Props {
  autoUpdate: boolean;
  state: RawMessagesFilters;
  setState: (filters: RawMessagesFilters | ((filters: RawMessagesFilters) => RawMessagesFilters)) => void;
}

const RawMessagesTableFilter = (props: Props): JSX.Element => {
  const classes = contentStyles();

  const { autoUpdate, state, setState } = props;

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked || undefined });
  };

  // TODO: get protocols from server
  const protocols = [
    {
      value: '1',
      label: '1'
    },
    {
      value: '2',
      label: '2'
    }
  ];

  const actuality = [
    {
      value: 'actual',
      label: 'Actual'
    },
    {
      value: 'old',
      label: 'Old'
    }
  ];
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const uncheckGroups = () => setState({ ...state, groups: [] });
  const handleSelectGroups = (groups: number[]) => setState({ ...state, groups });
  const handleSelectOwner = (owner?: number) => setState({ ...state, owner });

  return (
    <form autoComplete="off">
      <GroupedFilters
        owner={ state.owner }
        handleSelectOwner={ handleSelectOwner }
        isProjectShow
        projects={ state.projects }
        handleSelectProjects={ handleSelectProjects }
        isZoneShow
        zones={ state.zones }
        handleSelectZones={ handleSelectZones }
        isGroupsShow
        groups={ state.groups }
        handleSelectGroups={ handleSelectGroups }
        uncheckGroups={ uncheckGroups }
      />
      <CustomIdSelect
        selected={ state.devices }
        changeHandler={ devices => setState({ ...state, devices }) }
        name="Device"
        type="hex"
      />
      <NetworkIdSelect
        selected={ state.networkId }
        changeHandler={ networkId => setState({ ...state, networkId }) }
      />
      <StationSelectControl
        isClearable
        isMulti={ false }
        selected={ state.bsid }
        onChange={ bsid => setState({ ...state, bsid }) }
      />
      <TextControl
        label="Message Trace ID"
        value={ state.traceid }
        onChange={ event => setState({ ...state, traceid: event.target.value.trim() || undefined }) }
        type="text"
      />
      <SingleSelectControl
        name="actuality"
        label="Actuality Type"
        values={ actuality }
        changeHandler={ actuality => setState({ ...state, actuality }) }
        selected={ state.actuality || undefined }
        isClearable
      />
      <SingleSelectControl
        name="protocol"
        label="Data Link Protocol"
        values={ protocols }
        changeHandler={ protocol => setState({ ...state, protocol }) }
        selected={ state.protocol || undefined }
        isClearable
      />
      <DateTimeControl
        label="Time From"
        helperText="Message Demodulation time"
        onChange={ date => setState({ ...state, timeFrom: date ? moment(date) : null }) }
        value={ state.timeFrom ? state.timeFrom.toDate() : undefined }
        minDate={ (state.timeTo?.clone() ?? moment()).subtract(31, 'days').startOf('day').toDate() }
        maxDate={ state.timeTo?.toDate() }
        hideErrorsFromHelperText
        isClearable
      />
      <DateTimeControl
        label="Time To"
        helperText="Message Demodulation time"
        isDisabled={ autoUpdate }
        onChange={ date => setState({ ...state, timeTo: date ? moment(date) : null }) }
        value={ state.timeTo ? state.timeTo.toDate() : undefined }
        minDate={ state.timeFrom?.toDate() }
        isClearable
      />
      <FormControl className={ classes.formControl }>
        <FormControlLabel
          control={
            <Switch
              checked={ state.unique || false }
              onChange={ handleChange('unique') }
              value="unique"
              color="primary"
            />
          }
          label="Unique"
        />
      </FormControl>
    </form>
  );
};

export default RawMessagesTableFilter;
