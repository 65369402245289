import moment from 'moment';

export const formatDuration = (date: string | Date): string => {
  const dateChange = moment(date);
  const duration = moment.duration(moment().diff(dateChange));
  if (Math.trunc(duration.asDays()) > 0) {
    return `${ Math.trunc(duration.asDays()) }d`;
  }
  if (Math.trunc(duration.asHours()) > 0) {
    return `${ Math.trunc(duration.asHours()) }h`;
  }

  return `${ Math.trunc(duration.asMinutes()) }m`;
};
