import { GetApiKeyParams, ApiKey } from 'models/user-management';

export const UM_API_KEY_DATA_LOAD_BY_ID = 'UM_API_KEY_DATA_LOAD_BY_ID';
export const UM_API_KEY_DATA_LOAD_BY_PARAMS = 'UM_API_KEY_DATA_LOAD_BY_PARAMS';
export const UM_API_KEY_DATA_FETCH_START = 'UM_API_KEY_DATA_FETCH_START';
export const UM_API_KEY_DATA_FETCH_STOP = 'UM_API_KEY_DATA_FETCH_STOP';
export const UM_API_KEY_DATA_CHANGE = 'UM_API_KEY_DATA_CHANGE';

export interface LoadApiKeyDataById {
  readonly type: typeof UM_API_KEY_DATA_LOAD_BY_ID;
  apiKeyId: string;
}

export interface LoadApiKeyDataByParams {
  readonly type: typeof UM_API_KEY_DATA_LOAD_BY_PARAMS;
  params: GetApiKeyParams;
}

export interface LoadApiKeyDataStart {
  readonly type: typeof UM_API_KEY_DATA_FETCH_START;
  readonly params: GetApiKeyParams | string;
}

export interface LoadApiKeyDataStop {
  readonly type: typeof UM_API_KEY_DATA_FETCH_STOP;
  readonly params: GetApiKeyParams | string;
}

export interface ChangeApiKeyData {
  readonly type: typeof UM_API_KEY_DATA_CHANGE;
  readonly params: GetApiKeyParams | string;
  readonly data: ApiKey[];
}

export const LoadApiKeyDataById = (apiKeyId: string): LoadApiKeyDataById => ({
  type: UM_API_KEY_DATA_LOAD_BY_ID, apiKeyId
});

export const LoadApiKeyDataByParams = (params: GetApiKeyParams): LoadApiKeyDataByParams => ({
  type: UM_API_KEY_DATA_LOAD_BY_PARAMS, params
});

export const LoadApiKeyDataStart = (params: string | GetApiKeyParams): LoadApiKeyDataStart => ({
  type: UM_API_KEY_DATA_FETCH_START, params
});

export const LoadApiKeyDataStop = (params: string | GetApiKeyParams): LoadApiKeyDataStop => ({
  type: UM_API_KEY_DATA_FETCH_STOP, params
});

export const ChangeApiKeyData = (params: string | GetApiKeyParams, data: ApiKey[]): ChangeApiKeyData => ({
  type: UM_API_KEY_DATA_CHANGE, params, data
});

export type Action =
  | LoadApiKeyDataById
  | LoadApiKeyDataByParams
  | LoadApiKeyDataStart
  | LoadApiKeyDataStop
  | ChangeApiKeyData
