import { isNil } from 'lodash';
import { FormErrors } from 'types/form';
import { Shipment, ShipmentCreateBody, ShipmentFormField, ShipmentStatusEnum } from 'models/provisioning';

export const validate = (shipment: Shipment | ShipmentCreateBody, fields: ShipmentFormField[]): FormErrors<ShipmentFormField> => {
  const errors: FormErrors<ShipmentFormField> = {};
  if (fields.includes(ShipmentFormField.name)) {
    if (!shipment.name) {
      errors.name = 'Shipment name is required';
    }
  }
  if (fields.includes(ShipmentFormField.shipper_id)) {
    if (isNil(shipment.shipper_id)) {
      errors.shipper_id = 'Sender is required';
    }
  }
  if (fields.includes(ShipmentFormField.recipient_id)) {
    if (isNil(shipment.recipient_id)) {
      errors.recipient_id = 'Recipient is required';
    }
  }
  if (fields.includes(ShipmentFormField.sending_date) && 'status' in shipment && shipment.status) {
    const requiredStatus: ShipmentStatusEnum[] = [ShipmentStatusEnum.Dispatched, ShipmentStatusEnum.Received, ShipmentStatusEnum.InAWarehouse];
    if (!shipment.sending_date && requiredStatus.includes(shipment.status)) {
      errors[ShipmentFormField.sending_date] = 'Sending Time is required';
    }
  }

  if (fields.includes(ShipmentFormField.arrival_date) && 'status' in shipment && shipment.status) {
    const requiredStatus: ShipmentStatusEnum[] = [ShipmentStatusEnum.Received, ShipmentStatusEnum.InAWarehouse];
    if (!shipment.arrival_date && requiredStatus.includes(shipment.status)) {
      errors[ShipmentFormField.arrival_date] = 'Arrival Time is required';
    }
  }

  return errors;
};
