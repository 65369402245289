import React, { useState } from 'react';

import { useAuthUserSelector } from 'hooks';
import { DmTree } from 'hooks/device-management';
import { TopmostEntityType } from 'hooks/device-management/useDmTree/types';
import { PaginationParams } from 'models';

import { getColumns } from './columns';

// components
import { TreeTable } from 'components/TreeTable';

interface DmTableProps {
  tree: DmTree;
  defaultTotal?: number;
  topmostEntityType: TopmostEntityType;
  pagination: PaginationParams;
  onPaginationChange: (nextPagination: PaginationParams) => void;
  ownerVisible?: boolean;
}

/**
 * TODO: support rendering action at the top right corner of the table
 */
export const DmTable = ({
  tree,
  defaultTotal,
  topmostEntityType,
  pagination,
  onPaginationChange,
  ownerVisible,
}: DmTableProps) => {
  const { isAdmin } = useAuthUserSelector();
  const { eventHandlers } = tree;
  const [selectedPositions, setSelectedPositions] = useState<number[]>([]);

  return (
    <TreeTable
      tree={tree}
      defaultTotal={defaultTotal}
      columns={getColumns({
        topmostEntityType,
        eventHandlers,
        hideOwner: !ownerVisible || !isAdmin
      })}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      isRowSelectable={(node) => node.type === 'position'}
      selectedRows={{ position: selectedPositions }}
      onSelectedRowsChange={(rows) => setSelectedPositions(rows.position)}
    />
  );
};
