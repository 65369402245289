import { useEffect, useState } from 'react';
import { useFormActionNotifier } from 'hooks';
import { Heading, useAuthenticator } from '@aws-amplify/ui-react';

export const ConfirmSignUpHeader = (): JSX.Element => {
  const [handled, setHandled] = useState<boolean>(false);
  const { notifySuccess } = useFormActionNotifier();
  const { error, toSignIn } = useAuthenticator((context) => [
    context.error,
    context.toSignIn,
  ]);

  useEffect(() => {
    if (!handled && error === 'Incorrect username or password.') {
      setHandled(true);
      notifySuccess('Account has been confirmed. Please, sign in.');
      toSignIn();
    }
  }, [handled, error, toSignIn, notifySuccess]);

  return (
    <Heading level={ 4 }>We Sent A Code</Heading>
  );
};