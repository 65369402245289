import { ProvisioningDevicesFilters, Device as ProvisioningDevice, ProvisioningDevicesFiltersLimit } from 'models/provisioning';
import { SaveInUrl } from 'react-router-config';
import { Device, DeviceStatusEnum } from 'models/provisioning';

export const FETCH_PROVISIONING_DEVICES = 'provisioning/FETCH_DEVICES';
export const FETCH_PROVISIONING_DEVICES_BY_FILTERS = 'provisioning/FETCH_DEVICES_BY_FILTERS';
export const FETCH_PROVISIONING_DEVICES_SUCCESS = 'provisioning/FETCH_DEVICES_SUCCESS';
export const FETCH_PROVISIONING_DEVICES_FAILURE = 'provisioning/FETCH_DEVICES_FAILURE';

export const SET_PROVISIONING_DEVICES_FILTERS = 'provisioning/SET_DEVICES_FILTERS';
export const UPDATE_PROVISIONING_DEVICES_FILTERS_FROM_URL = 'provisioning/UPDATE_DEVICES_FILTERS_FROM_URL';

export const ADD_DEVICE_TO_BOX = 'provisioning/ADD_DEVICE_TO_BOX';
export const ADD_DEVICE_TO_BOX_SUCCESS = 'provisioning/ADD_DEVICE_TO_BOX_SUCCESS';
export const ADD_DEVICE_TO_BOX_FAILURE = 'provisioning/ADD_DEVICE_TO_BOX_FAILURE';

export const REMOVE_DEVICE_FROM_BOX = 'provisioning/REMOVE_DEVICE_FROM_BOX';
export const REMOVE_DEVICE_FROM_BOX_SUCCESS = 'provisioning/REMOVE_DEVICE_FROM_BOX_SUCCESS';
export const REMOVE_DEVICE_FROM_BOX_FAILURE = 'provisioning/REMOVE_DEVICE_FROM_BOX_FAILURE';

export const CHANGE_DEVICE_STATUS = 'provisioning/CHANGE_DEVICE_STATUS';
export const CHANGE_DEVICE_STATUS_SUCCESS = 'provisioning/CHANGE_DEVICE_STATUS_SUCCESS';
export const CHANGE_DEVICE_STATUS_FAILURE = 'provisioning/CHANGE_DEVICE_STATUS_FAILURE';

export const FETCH_PROVISIONING_DEVICE_BY_ID = 'provisioning/FETCH_PROVISIONING_DEVICE_BY_ID';
export const FETCH_PROVISIONING_DEVICE_BY_ID_SUCCESS = 'provisioning/FETCH_PROVISIONING_DEVICE_BY_ID_SUCCESS';
export const FETCH_PROVISIONING_DEVICE_BY_ID_FAILURE = 'provisioning/FETCH_PROVISIONING_DEVICE_BY_ID_FAILURE';

export interface FetchProvisioningDevices {
  readonly type: typeof FETCH_PROVISIONING_DEVICES;
}
export interface FetchProvisioningDevicesByFilters {
  readonly type: typeof FETCH_PROVISIONING_DEVICES_BY_FILTERS;
  readonly filters: ProvisioningDevicesFiltersLimit;
}

export interface FetchProvisioningDevicesSuccess {
  readonly type: typeof FETCH_PROVISIONING_DEVICES_SUCCESS;
  readonly devices: ProvisioningDevice[];
  readonly total: number;
}

export interface FetchProvisioningDevicesFailure {
  readonly type: typeof FETCH_PROVISIONING_DEVICES_FAILURE;
}

export interface UpdateProvisioningDevicesFromUrl {
  readonly type: typeof UPDATE_PROVISIONING_DEVICES_FILTERS_FROM_URL;
}

export interface SetProvisioningDevicesFilters extends SaveInUrl<ProvisioningDevicesFilters> {
  readonly type: typeof SET_PROVISIONING_DEVICES_FILTERS;
}

export interface AddDeviceToBox {
  readonly type: typeof ADD_DEVICE_TO_BOX;
  readonly deviceId: string;
  readonly boxId: number;
}
export interface AddDeviceToBoxSuccess {
  readonly type: typeof ADD_DEVICE_TO_BOX_SUCCESS;
  readonly device: Device;
}
export interface AddDeviceToBoxFailure {
  readonly type: typeof ADD_DEVICE_TO_BOX_FAILURE;
}

export interface RemoveDeviceFromBox {
  readonly type: typeof REMOVE_DEVICE_FROM_BOX;
  readonly deviceId: string;
}
export interface RemoveDeviceFromBoxSuccess {
  readonly type: typeof REMOVE_DEVICE_FROM_BOX_SUCCESS;
  readonly device: Device;
}
export interface RemoveDeviceFromBoxFailure {
  readonly type: typeof REMOVE_DEVICE_FROM_BOX_FAILURE;
}

export interface ChangeDeviceStatus {
  readonly type: typeof CHANGE_DEVICE_STATUS;
  readonly deviceId: string;
  readonly status: DeviceStatusEnum;
}
export interface ChangeDeviceStatusSuccess {
  readonly type: typeof CHANGE_DEVICE_STATUS_SUCCESS;
  readonly device: Device;
}
export interface ChangeDeviceStatusFailure {
  readonly type: typeof CHANGE_DEVICE_STATUS_FAILURE;
}

export interface FetchProvisioningDeviceById {
  readonly type: typeof FETCH_PROVISIONING_DEVICE_BY_ID;
  readonly deviceId: string;
}
export interface FetchProvisioningDeviceByIdSuccess {
  readonly type: typeof FETCH_PROVISIONING_DEVICE_BY_ID_SUCCESS;
  readonly device: Device;
}
export interface FetchProvisioningDeviceByIdFailure {
  readonly type: typeof FETCH_PROVISIONING_DEVICE_BY_ID_FAILURE;
}

export const DoFetchProvisioningDevices: () => FetchProvisioningDevices = () => ({
  type: FETCH_PROVISIONING_DEVICES
});

export const DoFetchProvisioningDevicesByFilters: (
  filters: ProvisioningDevicesFiltersLimit
) => FetchProvisioningDevicesByFilters = filters => ({
  type: FETCH_PROVISIONING_DEVICES_BY_FILTERS,
  filters,
});

export const DoFetchProvisioningDevicesSuccess: (
  devices: ProvisioningDevice[],
  total: number
) => FetchProvisioningDevicesSuccess = (devices, total) => ({
  type: FETCH_PROVISIONING_DEVICES_SUCCESS,
  devices,
  total,
});

export const DoFetchProvisioningDevicesFailure: () => FetchProvisioningDevicesFailure = () => ({
  type: FETCH_PROVISIONING_DEVICES_FAILURE
});

export const DoUpdateProvisioningDevicesFromUrl: () => UpdateProvisioningDevicesFromUrl = () => ({
  type: UPDATE_PROVISIONING_DEVICES_FILTERS_FROM_URL
});

export const DoSetProvisioningDevicesFilters: (
  filters: ProvisioningDevicesFilters,
  toUrl: boolean
) => SetProvisioningDevicesFilters = (filters, toUrl) => ({
  type: SET_PROVISIONING_DEVICES_FILTERS,
  filters,
  pushFiltersToUrl: toUrl
});

export const DoAddDeviceToBox: (deviceId: string, boxId: number) => AddDeviceToBox = (deviceId, boxId) => ({
  type: ADD_DEVICE_TO_BOX,
  deviceId,
  boxId
});
export const DoAddDeviceToBoxSuccess: (device: Device) => AddDeviceToBoxSuccess = device => ({
  type: ADD_DEVICE_TO_BOX_SUCCESS,
  device
});
export const DoAddDeviceToBoxFailure: () => AddDeviceToBoxFailure = () => ({
  type: ADD_DEVICE_TO_BOX_FAILURE
});

export const DoRemoveDeviceFromBox: (deviceId: string) => RemoveDeviceFromBox = deviceId => ({
  type: REMOVE_DEVICE_FROM_BOX,
  deviceId
});
export const DoRemoveDeviceFromBoxSuccess: (device: Device) => RemoveDeviceFromBoxSuccess = device => ({
  type: REMOVE_DEVICE_FROM_BOX_SUCCESS,
  device
});
export const DoRemoveDeviceFromBoxFailure: () => RemoveDeviceFromBoxFailure = () => ({
  type: REMOVE_DEVICE_FROM_BOX_FAILURE
});

export const DoChangeDeviceStatus: (deviceId: string, status: DeviceStatusEnum) => ChangeDeviceStatus = (deviceId, status) => ({
  type: CHANGE_DEVICE_STATUS,
  deviceId,
  status,
});
export const DoChangeDeviceStatusSuccess: (device: Device) => ChangeDeviceStatusSuccess = device => ({
  type: CHANGE_DEVICE_STATUS_SUCCESS,
  device
});
export const DoChangeDeviceStatusFailure: () => ChangeDeviceStatusFailure = () => ({
  type: CHANGE_DEVICE_STATUS_FAILURE
});


export const DoFetchProvisioningDeviceById: (deviceId: string) => FetchProvisioningDeviceById = deviceId => ({
  type: FETCH_PROVISIONING_DEVICE_BY_ID,
  deviceId,
});
export const DoFetchProvisioningDeviceByIdSuccess: (device: Device) => FetchProvisioningDeviceByIdSuccess = device => ({
  type: FETCH_PROVISIONING_DEVICE_BY_ID_SUCCESS,
  device
});
export const DoFetchProvisioningDeviceByIdFailure: () => FetchProvisioningDeviceByIdFailure = () => ({
  type: FETCH_PROVISIONING_DEVICE_BY_ID_FAILURE
});

export type Action = (
  | FetchProvisioningDevices
  | FetchProvisioningDevicesSuccess
  | FetchProvisioningDevicesFailure
  | UpdateProvisioningDevicesFromUrl
  | SetProvisioningDevicesFilters
  | AddDeviceToBox
  | AddDeviceToBoxSuccess
  | AddDeviceToBoxFailure
  | RemoveDeviceFromBox
  | RemoveDeviceFromBoxSuccess
  | RemoveDeviceFromBoxFailure
  | ChangeDeviceStatus
  | ChangeDeviceStatusSuccess
  | ChangeDeviceStatusFailure
  | FetchProvisioningDeviceById
  | FetchProvisioningDeviceByIdSuccess
  | FetchProvisioningDeviceByIdFailure
);
