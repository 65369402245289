import { makeStyles, createStyles, Theme } from 'styles/utils';
import { colors } from 'styles/themes/outer';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
  },
  filterControl: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  dateRangeLabel: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 500,
    color: colors.textPrimary,
    cursor: 'pointer',
  },
  blockCaptionText: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: colors.textPrimary,
  },
  blockCaption: {
    marginBottom: theme.spacing(2)
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  statusField: {
    display: 'flex',
    alignItems: 'center',
  },
  statusLabel: {
    '&::after': {
      content: '":\\00a0"',
    },
  },
  statusValue: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowDropDownIcon: {
    position: 'relative',
    top: '-2px',
    verticalAlign: 'middle',
    fontSize: 30,
  },
  fieldValueIcon: {
    marginLeft: '4px',
  },
}));
