import React from 'react';
import { Endpoint } from 'models/rabbit';
import { useFilterByLocation, useSendingStatusChartData } from './utils';

// components
import { Box, Paper } from '@material-ui/core';
import { MonitoringLineChart } from 'components/Charts';
import { ZoneSelect } from './widget/ZoneSelect';
import { DateRangeSelect } from './widget/DateRangeSelect';

interface Props {
  endpoint: Endpoint;
}

export const EndpointMonitoring = ({ endpoint }: Props): JSX.Element => {
  const { filter, setFilter } = useFilterByLocation();
  const chartData = useSendingStatusChartData(endpoint, filter);

  return (
    <Paper data-testid="rabbit-endpoint-monitoring">
      <Box pl={ 1 } pr={ 1 } display="flex" width="100%" justifyContent="flex-end" alignItems="center">
        <Box data-testid="rabbit-endpoint-monitoring-zone-select">
          <ZoneSelect
            endpoint={ endpoint }
            selected={ filter.zoneIds }
            onChange={ zoneIds => setFilter({ ...filter, zoneIds }) }
          />
        </Box>
        <Box data-testid="rabbit-endpoint-monitoring-date-range">
          <DateRangeSelect
            value={ filter.dateFrom && filter.dateTo ? { dateFrom: filter.dateFrom, dateTo: filter.dateTo } : undefined }
            onChange={ value => setFilter({
              ...filter,
              dateFrom: value?.dateFrom,
              dateTo: value?.dateTo
            }) }
            customValue="Last 24 hours"
          />
        </Box>
      </Box>
      <MonitoringLineChart { ...chartData } />
    </Paper>
  );
};
