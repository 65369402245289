import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgLoader: {
      height: theme.spacing(2)
    }
  }),
);

export default useStyles;
