import { useQuery } from 'react-query';
import { getPositionStates } from 'clients/parking';
import { useFormActionNotifier } from 'hooks/form';
import { getResponseData } from 'utils/clients';
import { GetPositionStateParams, PositionState } from 'models/parking';
import { configureQueryClient } from 'utils/react-query';
import { setIsNewForSyncDataUpdate } from 'utils/data-normalizers';

type Options = {
  params: GetPositionStateParams
  refetchInterval?: number
}

export const usePositionStates = ({ params, refetchInterval }: Options) => {
  const queryClient = configureQueryClient();
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['positions/states/find', params],
    queryFn: async () => {
      const response = await getPositionStates(params);
      let data = getResponseData(response);
      if (refetchInterval) {
        const cached = queryClient.getQueryData<{ data: PositionState[] }>(['positions/states/find', params]);
        data = cached ? setIsNewForSyncDataUpdate(data, cached.data, el => `${el.position_id}-${el.status_time}`) : [];
      }
      return {
        data,
        total: response.total
      };
    },
    cacheTime: refetchInterval,
    staleTime: refetchInterval || Infinity,
    refetchInterval,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
    onError: e => {
      notifyError(`Error while fetching positions states: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: refetchInterval ? queryResult.isLoading : queryResult.isFetching,
    positionStates: queryResult.data?.data ?? [],
    total: queryResult.data?.total ?? 0,
  };
};