import moment from 'moment';
import {
  RawMessagesWithNewFlag,
  RawMessagesFilters,
  initRawMessagesFilters,
  EnumRawMessagesFields as Fields
} from 'models';

import {
  Action,
  FETCH_RAW_MESSAGES_BY_QUERY,
  FETCH_RAW_MESSAGES_BY_QUERY_SUCCESS,
  FETCH_RAW_MESSAGES_BY_QUERY_FAILED,
  SET_RAW_MESSAGES_FILTERS,
  UPDATE_RAW_MESSAGES_FILTERS_FROM_URL,
} from 'actions/raw-messages';
import { getUrlItems, UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';

export interface State {
  messages: RawMessagesWithNewFlag[];
  total: number;
  autoUpdate: boolean;
  filters: RawMessagesFilters;
  error?: string;
  isFetching: boolean;
}

function getFiltersFromUrl(urlState: UrlItems): RawMessagesFilters {
  const parser = new Parser(urlState as RawMessagesFilters);

  return {
    [Fields.owner]:  parser.asNumber(Fields.owner),
    [Fields.projects]: parser.asNumbers(Fields.projects) ?? [],
    [Fields.zones]: parser.asNumbers(Fields.zones) ?? [],
    [Fields.groups]: parser.asNumbers(Fields.groups) ?? [],
    [Fields.devices]: parser.asStrings(Fields.devices) ?? [],
    [Fields.networkId]: parser.asStrings(Fields.networkId) ?? [],
    [Fields.unique]: parser.asBoolean(Fields.unique),
    [Fields.timeFrom]: parser.asDate(Fields.timeFrom) ? moment(parser.asDate(Fields.timeFrom)) : initRawMessagesFilters.timeFrom,
    [Fields.timeTo]: parser.asDate(Fields.timeTo) ? moment(parser.asDate(Fields.timeTo)) : null,
    [Fields.bsid]: parser.asNumber(Fields.bsid),
    [Fields.actuality]: parser.asString(Fields.actuality),
    [Fields.protocol]:  parser.asString(Fields.protocol),
    [Fields.traceid]: parser.asString(Fields.traceid),
  };
}

const initState: State = {
  messages: [],
  total: 0,
  autoUpdate: false,
  isFetching: false,
  filters: initRawMessagesFilters,
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_RAW_MESSAGES_BY_QUERY:
      return { ...state, isFetching: !action.isSync ? true : false };
    case FETCH_RAW_MESSAGES_BY_QUERY_SUCCESS:
      const { list, total } = action;
      return { ...state, isFetching: false, messages: list, total };
    case FETCH_RAW_MESSAGES_BY_QUERY_FAILED:
      return { ...state, isFetching: true };
    case SET_RAW_MESSAGES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case UPDATE_RAW_MESSAGES_FILTERS_FROM_URL:
      const urlState = getUrlItems(Object.values(Fields));

      return {
        ...state,
        filters: {
          ...state.filters,
          ...getFiltersFromUrl(urlState),
        }
      };
    default:
      return state;
  }
};

