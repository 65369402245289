import * as dmClient from 'clients/device-management';

import { ZoneNode, LevelNode, GroupNode } from '../types';
import { getResponseData } from 'utils/clients';

type TotalChildrenByNode = Record<string, number>;

export async function fetchZoneTotalChildren(
  nodes: ZoneNode[]
): Promise<TotalChildrenByNode> {
  const nodeIdLevelsTotalTuples = await Promise.all(nodes.map(async node => {
    const zoneId = node.zone.id;
    // the response doesn't have total field, so we have to load all levels for now
    const levels = getResponseData(
      await dmClient.fetchLevels({ zones: [zoneId], limit: 999 }),
    );

    // + 1 stands for "No level" level
    return [node.id, levels.length + 1];
  }));

  return Object.fromEntries(nodeIdLevelsTotalTuples);
}

export async function fetchLevelTotalChildren(
  nodes: LevelNode[]
): Promise<TotalChildrenByNode> {
  const nodeIdGroupsTotalTuples = await Promise.all(nodes.map(async node => {
    const levelId = node.level?.id;

    if (!levelId) {
      // TODO: add support of fetching groups not belonging to any level
      return [node.id, 0];
    }

    const res = await dmClient.fetchGroups({ levels: [levelId], limit: 0 });

    return [node.id, res.total];
  }));

  return Object.fromEntries(nodeIdGroupsTotalTuples);
}

export async function fetchGroupTotalChildren(
  nodes: GroupNode[]
): Promise<TotalChildrenByNode> {
  const nodeIdPositionsTotalTuples = await Promise.all(nodes.map(async node => {
    const groupId = node.group.id;
    const res = await dmClient.findPositions({ groupIds: [groupId], limit: 0 });

    return [node.id, res.total];
  }));

  return Object.fromEntries(nodeIdPositionsTotalTuples);
}
