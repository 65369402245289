import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(_theme => createStyles({
  root: {
    height: '262px',
  },
  chartBody: {
    height: '100%'
  },
  chartLegend: {
    height: '100%',
    paddingTop: '6px',
    paddingBottom: '34px',
    paddingRight: '10px',
  }
}));
