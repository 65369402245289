import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
// component
import { Box, FormControl, FormLabel, Grid, TextField } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { DeleteButton, PlusButton } from 'components/Buttons';
// styles
import clsx from 'clsx';
import contentStyles from 'styles';
import { useStyles } from './styles';

interface Header {
  name: string;
  value: string;
}

type Headers = Record<string, string>

/* Apply changes from headers object to headers array, for save order in array and dont rerender equals state */
const applyChanges = (headers: Headers, state: Header[]): boolean => {
  let hasChange = false;
  for (const [name, value] of Object.entries(headers)) {
    const header = state.find(i => i.name === name);
    if (!header) {
      state.push({ name, value });
      hasChange = true;
    } else if (header.value !== value) {
      header.value = value;
      hasChange = true;
    }
  }

  return hasChange;
};

interface Props {
  label: string;
  value: Headers;
  disabled?: boolean;
  error?: string;
  onChange: (value: Headers) => void;
}

const Control = (props: Props): JSX.Element => {
  const { label, value, disabled, onChange } = props;
  const [headers, setHeaders] = useState<Array<Header>>([]);
  const [nonUniqueHeaders, setNonUniqueHeaders] = useState<string[]>([]);
  useDeepCompareEffect(() => {
    applyChanges(value, headers) && setHeaders([...headers]);
  }, [value]);

  const classes = useStyles();
  const contentClasses = contentStyles();

  const changeHeaders = (headers: Header[]) => {
    const value: Headers = {};

    const headerNames = headers.map(header => header.name);
    const duplicates = headerNames.filter((value, index, array) => array.indexOf(value) !== index);
    setNonUniqueHeaders(duplicates);

    if (duplicates.length) {
      return;
    }

    headers.forEach((header) => {
      value[header.name] = header.value;
    });
    onChange(value);
  };

  const changeHeader = (key: number, field: 'name' | 'value', value: string) => {
    headers[key] = { ...headers[key], [field]: value };
    changeHeaders(headers);
  };

  const removeHeader = (key: number) => {
    headers.splice(key, 1);
    changeHeaders(headers);
  };

  const addHeader = () => {
    headers.push({ name: '', value: '' });
    changeHeaders(headers);
  };

  const canAddHeader = (
    nonUniqueHeaders.length === 0
    && !headers.some(h => h.name === '')
  );

  return (
    <FormControl
      className={ clsx(contentClasses.formControl, contentClasses.formControlSmall) }
      fullWidth
      data-testid="headers-control"
    >
      <FormLabel component="div" className={ classes.label }>
        <span className={ contentClasses.formLabelNonShrunk }>{ label }</span>
        { !disabled && <PlusButton
          type="icon"
          onClick={ addHeader }
          isDisabled={ !canAddHeader }
        /> }
      </FormLabel>
      <Grid container spacing={ 2 } className={ classes.controls }>
        { headers.map((header, key) => (
          <Grid item xs={ 12 } sm={ 12 } key={ key } data-testid="headers-control-header">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Grid container spacing={ 1 }>
                <Grid item md={ 4 }>
                  <TextField
                    fullWidth
                    disabled={ disabled }
                    label="Name"
                    value={ header.name }
                    onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                      changeHeader(key, 'name', event.target.value);
                    } }
                    error={ nonUniqueHeaders.includes(header.name) }
                    helperText={ nonUniqueHeaders.includes(header.name) ? 'Non unique headers' : undefined }
                  />
                </Grid>
                <Grid item md={ 8 }>
                  <TextField
                    fullWidth
                    disabled={ disabled }
                    label="Value"
                    value={ header.value }
                    onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                      changeHeader(key, 'value', event.target.value);
                    } }
                  />
                </Grid>
              </Grid>
              { !disabled && <DeleteButton
                type="icon"
                icon={ <DeleteOutline /> }
                onClick={ () => removeHeader(key) }
              /> }
            </Box>
          </Grid>
        )) }
      </Grid>
    </FormControl>
  );
};

export default Control;
