import { IntegrationFiltersParams, IntegrationsEnum } from 'models/lorawan';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';

// components
import { OwnersSingleSelectControl, IntegrationSelect } from 'components/Controls';

// styles
import useStyles from 'styles/filters';

export interface FilterState {
  type?: IntegrationsEnum;
  owner?: number;
}

export const buildParams = (params: UrlItems): IntegrationFiltersParams => {
  const parser = new Parser(params as IntegrationFiltersParams);
  const result: IntegrationFiltersParams = {
    type: parser.asString('type') as IntegrationsEnum,
    owner: parser.asNumber('owner'),
  };

  return result;
};

export const convertStateToParams = (state: FilterState): IntegrationFiltersParams => ({
  type: state.type,
  owner: state.owner,
});

export const convertParamsToState = (params: IntegrationFiltersParams): FilterState => ({
  type: params.type,
  owner: params.owner,
});

interface Props {
  state: FilterState;
  setState: (filters: FilterState) => void;
}

export const LorawanFilter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { state, setState } = props;

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      <OwnersSingleSelectControl
        selected={ state.owner }
        changeHandler={ (ownerId?: number) => setState({ ...state, owner: ownerId }) }
      />
      <IntegrationSelect
        onChange={ (type: IntegrationsEnum) => setState({ ...state, type }) }
        selected={ state.type }
      />
    </form>
  );
};
