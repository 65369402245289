import React from 'react';
import { useFormActionLoader } from 'hooks';
import { TemplateSuite } from 'models/caller';
import { useCreateForm } from './utils';
// components
import { Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { ActionLoaderProps } from 'components/Loaders';
import { CallerTemplateSuiteForm } from '../TemplateSuiteForm/CallerTemplateSuiteForm';

interface CreateSuiteDialogText {
  title?: string;
  submitButton?: string;
}

interface Props {
  isOpen: boolean;
  templateSuite?: TemplateSuite;
  text?: CreateSuiteDialogText;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: () => void;
  getSubmitAction?: (suite: TemplateSuite) => ActionLoaderProps['action'];
}

export const CreateSuiteDialog = (props: Props): JSX.Element => {
  const { isOpen, templateSuite, text, getSubmitAction, onClose, onCloseEnd, onSuccess } = props;
  const { doAction } = useFormActionLoader();
  const { form, onSubmit } = useCreateForm({ doAction, templateSuite, onSuccess, getSubmitAction });

  return (
    <CommonDialog
      isOpen={ isOpen }
      title={ text?.title ?? 'Add Template Suite' }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      content={
        <CallerTemplateSuiteForm { ...form } />
      }
      actions={
        <>
          <Button
            color="primary"
            onClick={ onClose }
          >Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ onSubmit }
          >{ text?.submitButton ?? 'Add' }</Button>
        </>
      }
    />
  );
};
