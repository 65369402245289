import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeBtn: {
      marginTop: -theme.spacing(3),
      marginBottom: -theme.spacing(3),
    },
    cardRoot: {
      height: '100%'
    },
    flexCard: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexScroll: {
      flex: 1,
      overflow: 'auto',
      maxHeight: '70vh',
    },
    border: {
      borderBottom: '1px solid #ccc'
    },
    formDialogPaper: {
      overflow: 'visible',
    },
    dialogContentRootWithTable: {
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      '&:first-child': {
        padding: 0,
      }
    },
    dialogContentRoot: {
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
export default useStyles;
