import React from 'react';
// components
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export const BlockLoader = ({ size = 60 }: { size?: number }): JSX.Element => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" height="100%">
      <CircularProgress size={ size } color="primary" />
    </Box>
  );
};
