import { Zone, Level } from 'models/device-management';

// components
import { DialogActions, DialogTitle } from '@material-ui/core';

import { DialogProps } from 'components';
import { useModalState } from 'components/Dialogs/Dialog/useModalState';

import { StyledDialog } from './Dialog/StyledDialog';
import { StyledDialogContent } from './Dialog/StyledDialogContent';
import { LevelForm } from './LevelForm';

interface CreateLevelProps {
  zone: Zone;
  allLevels: Level[];
  renderTrigger: DialogProps['renderTrigger'];
  onSuccess?: (level: Level) => void;
}

export const CreateLevel = ({
  zone,
  allLevels,
  renderTrigger,
  onSuccess,
}: CreateLevelProps) => {
  const { open, openModal, closeModal } = useModalState();

  const titleNodeId = 'level-form-title';

  return (
    <StyledDialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={open}
      renderTrigger={renderTrigger}
      scroll="body"
      onClose={closeModal}
      onOpen={openModal}
      aria-labelledby={titleNodeId}
    >
      <DialogTitle id={titleNodeId}>
        Add level to zone {zone.name}
      </DialogTitle>

      <LevelForm
        zone={zone}
        allLevels={allLevels}
        showFields={['floor_number', 'name']}
        actionsWrapper={DialogActions}
        contentWrapper={StyledDialogContent}
        submitButtonLabel="Add"
        onCancel={closeModal}
        onSuccess={level => {
          closeModal();
          onSuccess?.(level);
        }}
      />
    </StyledDialog>
  );
};
