import { createStyles, makeStyles, Theme } from 'styles/utils';
import { CSSProperties } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      display: 'flex',
      flexFlow: 'column wrap',

      '& img': {
        height: '40px'
      }
    },
    companyTitle: {
      fontSize: '18px',
      lineHeight: 1.44,
      color: '#4a4a4a',
      fontWeight: 500,
      marginLeft: theme.spacing(2),
    },
    status: {
      fontSize: '14px',
      color: '#4a4a4a',
      opacity: .5,
      display: 'flex',
      alignItems: 'flex-end',
    },
    statusWaiting: {
      opacity: 1
    },
    tableLogo: {
      height: '20px',
      marginRight: theme.spacing(2),
    },
    actionCell: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    timeText: {
      paddingLeft: theme.spacing(2),
      textAlign: 'right',
    }
  }),
);

export const iconStyle: CSSProperties = {
  fontSize: 25,
  fontWeight: 900,
  marginRight: 5,
};

export default useStyles;
