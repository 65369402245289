import { makeStyles, createStyles } from 'styles/utils';

export const useTemplateDialogStyles = makeStyles(() => createStyles({
  paper: {
    width: '37%',
    minWidth: '528px',
  },
  createDialogContent: {
    overflowY: 'scroll',
  },
}), { index: 2 }); // higher priority than CommonDialog's styles