import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    idCell: {
      maxWidth: 250,
    },
    actionCell: {
      width: 48,
      padding: 0,
      textAlign: 'center',
    },
  }),
);

export default useStyles;
