import React from 'react';
import { useAuthUserSelector, useDialog } from 'hooks';
import { useTree } from 'hooks/device-management/useTree/useTree';
import { QueryType } from 'hooks/device-management/useTree/types';
import { isAllowedToCreateZones } from 'utils/permissions';
import { useFilterFromLocation, useLocationPagination } from './utils';

// components
import { Box } from '@material-ui/core';
import { AddButton } from 'components/Buttons';
import { ProjectTreeTable } from 'components/DeviceManagement/TreeTable/ProjectTreeTable';
import { CreateZoneDialog } from 'components/DeviceManagement';
import { PageFilter } from './widgets/PageFilter';

// styles
import useStyles from 'styles/page';

export const DeviceManagementAllPage = () => {
  const classes = useStyles();
  const authUser = useAuthUserSelector();
  const addZoneDialog = useDialog();
  const filter = useFilterFromLocation();
  const pagination = useLocationPagination([20]);
  const tree = useTree({
    owner: filter.filter.owner,
    projects: filter.filter.projects,
    zones: filter.filter.zones,
    limit: pagination.limit,
    offset: pagination.offset,
  });
  const isLoading = !tree.loaded.zone;

  return (
    <>
      <Box className={ classes.toolbar }>
        <PageFilter isLoading={ isLoading } filter={ filter.filter } onChange={ filter.onChange} />
      </Box>

      <ProjectTreeTable
        isLoading={ isLoading }
        tree={ tree }
        pagination={ pagination }
      />
      { isAllowedToCreateZones(authUser.data) && (
        <>
          <AddButton
            label="Add zone"
            onClick={ addZoneDialog.open }
          />
          { addZoneDialog.isMounted && (
            <CreateZoneDialog
              isOpen={ addZoneDialog.isOpen }
              onClose={ addZoneDialog.close }
              onCloseEnd={ addZoneDialog.unmount }
              onSuccess={ () => tree.reload(QueryType.zone) }
            />
          ) }
        </>
      ) }
    </>
  );
};
