import { GetHistoryParams, History } from 'models/rabbit';

export const FETCH_RABBIT_HISTORY_START = 'FETCH_RABBIT_HISTORY_START';
export const FETCH_RABBIT_HISTORY_FAILED = 'FETCH_RABBIT_HISTORY_FAILED';
export const FETCH_RABBIT_HISTORY_SUCCESS = 'FETCH_RABBIT_HISTORY_SUCCESS';
export const SYNC_RABBIT_HISTORY_START = 'SYNC_RABBIT_HISTORY_START';
export const SYNC_RABBIT_HISTORY_STOP = 'SYNC_RABBIT_HISTORY_STOP';

export type FETCH_RABBIT_HISTORY_START = typeof FETCH_RABBIT_HISTORY_START;
export type FETCH_RABBIT_HISTORY_FAILED = typeof FETCH_RABBIT_HISTORY_FAILED;
export type FETCH_RABBIT_HISTORY_SUCCESS = typeof FETCH_RABBIT_HISTORY_SUCCESS;
export type SYNC_RABBIT_HISTORY_START = typeof SYNC_RABBIT_HISTORY_START;
export type SYNC_RABBIT_HISTORY_STOP = typeof SYNC_RABBIT_HISTORY_STOP;

export interface GetHistory {
  readonly type: FETCH_RABBIT_HISTORY_START;
  readonly params: GetHistoryParams;
}

export interface GetHistoryFailed {
  readonly type: FETCH_RABBIT_HISTORY_FAILED;
  readonly params: GetHistoryParams;
  readonly error: string;
}

export interface GetHistorySuccess {
  readonly type: FETCH_RABBIT_HISTORY_SUCCESS;
  readonly params: GetHistoryParams;
  readonly data: History[];
  readonly total: number;
}

export interface SyncHistoryStart {
  readonly type: SYNC_RABBIT_HISTORY_START;
  readonly delay: number;
}

export interface SyncHistoryStop {
  readonly type: SYNC_RABBIT_HISTORY_STOP;
}

export const FetchHistory: (params: GetHistoryParams) => GetHistory = (params) => ({
  type: FETCH_RABBIT_HISTORY_START,
  params: params
});

export const FetchHistoryFailure = (params: GetHistoryParams, error: string): GetHistoryFailed => ({
  type: FETCH_RABBIT_HISTORY_FAILED, params, error
});

export const FetchHistorySuccess = (params: GetHistoryParams, data: History[], total: number): GetHistorySuccess => ({
  type: FETCH_RABBIT_HISTORY_SUCCESS, params, data, total
});

export const SyncHistoryStart: (delay: number) => SyncHistoryStart = (delay: number) => ({
  type: SYNC_RABBIT_HISTORY_START,
  delay: delay,
});

export const SyncHistoryStop: () => SyncHistoryStop = () => ({
  type: SYNC_RABBIT_HISTORY_STOP,
});

export type Action =
  | GetHistory
  | GetHistoryFailed
  | GetHistorySuccess
  | SyncHistoryStart
  | SyncHistoryStop
