import React from 'react';
import { useApiKeySelector } from 'hooks/user-managment';
// components
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  apiKeyId: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

export const ApiKeyName = ({ apiKeyId, innerProps }: Props): JSX.Element => {
  const { apiKey, isLoading } = useApiKeySelector(apiKeyId);
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    isLoading ? <Skeleton width={ 100 }/> :
      <Typography { ...TypoProps }>{ apiKey ? apiKey.name : '-' }</Typography>
  );
};
