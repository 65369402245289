import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ExistingPosition,
  ResponsePositions,
  PositionWithLevel
} from 'models/device-management';
import { NotifyError } from 'actions/notifier';
import { clearQueryCache } from 'utils/react-query';

import * as dmPositionsActions from 'actions/device-management/positions';


import * as dmClient from 'clients/device-management';
import { ActionWithPromise } from 'utils/store';
import { ApiResponse, ResponseReason, ResponsePositionsWithTotal } from 'models/device-management';

type DeleteLabel = dmPositionsActions.DeleteLabel;
type DeleteLabelResult = dmPositionsActions.DeleteLabelResult;
type DeletePosition = dmPositionsActions.DeletePosition;
type DeletePositionResult = dmPositionsActions.DeletePositionResult;
type DeletePositionSagaAction = (
  | DeletePosition
  | ActionWithPromise<DeletePosition, DeletePositionResult>
);
type DeleteLabelSagaAction = (
  | DeleteLabel
  | ActionWithPromise<DeleteLabel, DeleteLabelResult>
)

function* deletePositionSaga(action: DeletePositionSagaAction) {
  const { positionId } = action;
  const response: ApiResponse = yield call(dmClient.deletePosition, positionId);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while deleting a position: ${response.message}`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(response.message));
    }
    return;
  }

  yield clearQueryCache(['deviceManagement/positions']);
  if ('meta' in action) {
    action.meta.promise.resolve();
  }
}

function* deleteLabelSaga(action: DeleteLabelSagaAction) {
  const { labelId } = action;
  const response: ApiResponse = yield call(dmClient.deleteLabel, labelId);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while deleting a label: ${response.message}`));

    if ('meta' in action) {
      action.meta.promise.reject(new Error(response.message));
    }
    return;
  }

  if ('meta' in action) {
    action.meta.promise.resolve();
  }
  return;
}

export function* findPositionsSaga(action: dmPositionsActions.FindPositions): SagaIterator {
  const response: ResponsePositionsWithTotal = yield call(dmClient.findPositions, action.params);

  if (response.reason !== ResponseReason.Ok) {
    yield put(dmPositionsActions.findPositionsFailure(response.message));
    yield put(NotifyError(`Error while fetching positions: ${response.message}`));
    return;
  }

  const requestedPositionsById = [
    ...response.data.map(p => p.id),
    ...(action.params.positions || []),
  ].reduce(
    (positionsById, positionId) => {
      // Since we concat what we explicitly requested and what we received,
      // we may have already processed `positionId`
      if (positionId in positionsById) {
        return positionsById;
      }

      const position = response.data.find(p => p.id === positionId);

      // Here we differenciate between `null` and `undefined`
      // in order to mark `positionId` fetched but not found
      positionsById[positionId] = position || null;

      return positionsById;
    },
    {} as { [positionId: number]: ExistingPosition | null },
  );

  yield put(dmPositionsActions.findPositionsSuccess(requestedPositionsById));
}

type UpdatePositionSagaAction = (
  | dmPositionsActions.UpdatePosition
  | ActionWithPromise<dmPositionsActions.UpdatePosition, PositionWithLevel>
);

function* updatePositionSaga(action: UpdatePositionSagaAction) {
  const response: ApiResponse = yield call(dmClient.updatePosition, action.position);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while updating a position: ${response.message}`));

    'meta' in action && action.meta.promise.reject(new Error(response.message));
    return;
  }

  yield put(dmPositionsActions.updatePositionSuccess(action.position));
  // fixme take new entity from backend response
  'meta' in action && action.meta.promise.resolve(action.position);
}

function* getPositionsByLocations(action: dmPositionsActions.FindPositionsByLocation) {
  const response: ResponsePositions = yield call(dmClient.findPositions, action.filters);
  if (response.reason === ResponseReason.Ok && response.data) {
    yield put(dmPositionsActions.findPositionsByLocationSuccess(response.data as ExistingPosition[]));
  } else {
    yield put(dmPositionsActions.findPositionsByLocationFailed());
    yield put(NotifyError(`Error while fetching positions by location: ${response.message}`));
  }
}

export const deviceManagementPositionsSaga = [
  takeEvery(dmPositionsActions.DELETE_POSITION, deletePositionSaga),
  takeEvery(dmPositionsActions.DELETE_LABEL, deleteLabelSaga),
  takeEvery(dmPositionsActions.FIND_POSITIONS, findPositionsSaga),
  takeEvery(dmPositionsActions.UPDATE_POSITION, updatePositionSaga),
  takeEvery(dmPositionsActions.FIND_POSITIONS_BY_LOCATION, getPositionsByLocations)
];
