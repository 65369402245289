import moment from 'moment';
import React from 'react';

// components
import { AxisTick, AxisTickProps } from './AxisTick';

export const DateAxisTick = (props: AxisTickProps) => {
  const dateValue = props.value instanceof Date ? moment(props.value) : undefined;
  const isStartOfDay = dateValue && dateValue.get('h') === 0 && dateValue.get('m') === 0;

  const gStyle: React.CSSProperties = { ...props.gStyle };

  if (isStartOfDay) {
    gStyle.fontWeight = 600;
  }

  return <AxisTick {...props} gStyle={gStyle} />;
};
