import { PositionsFilterFields, PositionWithLevel } from 'models/device-management';

export const POSITIONS_REPOSITORY_DATA_LOAD_BY_ID = 'dm/positionsRepository/LOAD_BY_ID';
export const POSITIONS_REPOSITORY_DATA_LOAD_BY_PARAMS = 'dm/positionsRepository/LOAD_BY_PARAMS';
export const POSITIONS_REPOSITORY_DATA_LOAD_START = 'dm/positionsRepository/LOAD_START';
export const POSITIONS_REPOSITORY_DATA_LOAD_FAILURE = 'dm/positionsRepository/LOAD_FAILURE';
export const POSITIONS_REPOSITORY_DATA_LOAD_SUCCESS = 'dm/positionsRepository/LOAD_SUCCESS';

export interface LoadPositionsRepositoryDataById {
  readonly type: typeof POSITIONS_REPOSITORY_DATA_LOAD_BY_ID;
  readonly positionId: number;
}

export interface LoadPositionsRepositoryDataByParams {
  readonly type: typeof POSITIONS_REPOSITORY_DATA_LOAD_BY_PARAMS;
  readonly params: PositionsFilterFields;
}

export interface LoadPositionsRepositoryDataStart {
  readonly type: typeof POSITIONS_REPOSITORY_DATA_LOAD_START;
  readonly params: PositionsFilterFields | number;
}

export interface LoadPositionsRepositoryDataFailure {
  readonly type: typeof POSITIONS_REPOSITORY_DATA_LOAD_FAILURE;
  readonly params: PositionsFilterFields | number;
  readonly error: string;
}

export interface LoadPositionsRepositoryDataSuccess {
  readonly type: typeof POSITIONS_REPOSITORY_DATA_LOAD_SUCCESS;
  readonly params: PositionsFilterFields | number;
  readonly data: PositionWithLevel[];
}

export const LoadPositionsRepositoryDataById = (positionId: number): LoadPositionsRepositoryDataById => ({
  type: POSITIONS_REPOSITORY_DATA_LOAD_BY_ID, positionId
});

export const LoadPositionsRepositoryDataByParams = (params: PositionsFilterFields): LoadPositionsRepositoryDataByParams => ({
  type: POSITIONS_REPOSITORY_DATA_LOAD_BY_PARAMS, params
});

export const LoadPositionsRepositoryDataStart = (params: PositionsFilterFields | number): LoadPositionsRepositoryDataStart => ({
  type: POSITIONS_REPOSITORY_DATA_LOAD_START, params
});

export const LoadPositionsRepositoryDataFailure = (params: PositionsFilterFields | number, error: string): LoadPositionsRepositoryDataFailure => ({
  type: POSITIONS_REPOSITORY_DATA_LOAD_FAILURE, params, error
});

export const LoadPositionsRepositoryDataSuccess = (params: PositionsFilterFields | number, data: PositionWithLevel[]): LoadPositionsRepositoryDataSuccess => ({
  type: POSITIONS_REPOSITORY_DATA_LOAD_SUCCESS, params, data
});

export type Action =
  | LoadPositionsRepositoryDataById
  | LoadPositionsRepositoryDataByParams
  | LoadPositionsRepositoryDataStart
  | LoadPositionsRepositoryDataFailure
  | LoadPositionsRepositoryDataSuccess
