import { RouteConfig } from 'react-router-config';
import * as paths from 'routing/paths/parking';
import CurrentStatePage from 'pages/Parking/CurrentState';

const routes: RouteConfig[] = [
  {
    path: paths.getParkingStateCurrentPath(),
    icon: 'parking',
    label: 'Occupancy',
    render: ({ match }) => match.isExact && <CurrentStatePage />,
  },
];

export default routes;
