import { useQuery } from 'react-query';
import { QueryType, Tree } from './types';
import { findGroupNodeForDevice, useErrorNotifier } from './utils';
import { getResponse } from 'utils/clients';
import { fetchConnectivity } from 'clients/connectivity';

export const useDeviceConnectsResult = (zoneIds: number[], tree: Tree, queryHash: string): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.deviceConnect],
    queryHash: `connectivity/deviceConnectivity/${ queryHash }`,
    queryFn: async () => getResponse(await fetchConnectivity({
      zones: zoneIds,
      limit: 9999,
      offset: 0,
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notify(`Error while fetching device connectivity: ${ (e as Error).message }`),
    enabled: tree.loaded.zone && zoneIds.length > 0
  });

  if (result.isSuccess) {
    tree.loaded.deviceConnect = true;
    for (const deviceConnectivity of result.data?.data ?? []) {
      const groupNode = findGroupNodeForDevice(tree, deviceConnectivity.device_id);
      if (!groupNode) {
        continue;
      }

      groupNode.deviceConnects.total++;
      groupNode.deviceConnects.data[deviceConnectivity.device_id.toUpperCase()] = deviceConnectivity;

      for (const position of Object.values(groupNode.children.items)) {
        if (position.device?.device_id.toUpperCase() === deviceConnectivity.device_id.toUpperCase()) {
          position.deviceConnect = deviceConnectivity;
        }
      }
    }
  }

  return tree;
};