import { Theme } from '@material-ui/core/styles';
import { withStyles as muiWithStyles } from '@material-ui/core/styles'; // eslint-disable-line no-restricted-imports
import {
  WithStylesOptions,
  Styles,
} from '@material-ui/styles/withStyles';

/**
 * The same as Material UI's withStyles() but with `index: 1` option passed by default
 */
export function withStyles<
  ClassKey extends string,
  Options extends WithStylesOptions<Theme> = Record<string, unknown>,
  Props extends Record<string, unknown> = Record<string, unknown>
>(
  style: Styles<Theme, Props, ClassKey>,
  options?: Options,
) {
  const componentCreator = muiWithStyles<ClassKey, Options, Props>(
    style,
    { index: 1, ...options } as Options,
  );

  return componentCreator;
}
