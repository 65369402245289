import { Project } from 'models/user-management';

export enum ProjectsFilterFields {
  projects = 'projects',
  owner = 'owner',
  name = 'name',
}

export interface ProjectsFilters {
  [ProjectsFilterFields.projects]?: number[];
  [ProjectsFilterFields.owner]?: number;
  [ProjectsFilterFields.name]?: string; 
}


export const initProjectsFilters = {
  projects: undefined,
  owner: undefined,
  name: undefined
}

export interface CreateProjectParams {
  name: string;
  timezone?: string;
  owner_id?: number;
}

export interface EditProjectParams extends CreateProjectParams {
  id: number;
}

export interface editProject {
  isFetching: boolean;
  lastEditedProject?: Project;
}

export interface DetailPage {
  byId?: Project;
  isFetchingById: boolean;
  editProject: editProject;
  isFetchingCreate: boolean;
}
export interface DeleteAction {
  deleteProjectId?: number;
}
