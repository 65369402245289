import { BarSvgProps } from '@nivo/bar';
import { props as CommonOptions, axisFormat } from './CommonOptions';

export const props: BarSvgProps = {
  ...CommonOptions,
  indexBy: 'date',
  padding: 0.1,
  maxValue: 'auto',
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    format: axisFormat
  },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -66
  },
  axisTop: {
    format: () => '',
    legendPosition: 'middle',
    tickValues: undefined,
    tickSize: 0,
    legendOffset: -20
  },
  enableLabel: false,
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
  motionStiffness: 90,
  motionDamping: 15
};
