import React from 'react';
// components
import { Typography, Chip } from '@material-ui/core';

// styles
import useStyles from 'styles/page';

interface ChipLabelProps {
  label: string;
  value: string | React.ReactNode;
}

export const SelectedFilterChip = ({ label, value }: ChipLabelProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Chip
      label={
        <Typography color="textSecondary" variant="caption">
          { `${label}:` } <Typography color="primary" component="span" variant="caption">{ value }</Typography>
        </Typography>
      }
      clickable
      variant="outlined"
      size="small"
      color="primary"
      className={ classes.selectedFilters }
    />
  );
};
