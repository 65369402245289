import { StationLocation } from 'models/base-station';
import { isNil } from 'lodash';

export const sortLocation = (locations: StationLocation[]) => (locations.sort((a, b) => {
  if (isNil(a.station_id)) {
    return 1;
  } else if (isNil(b.station_id)) {
    return -1;
  } else {
    return a.station_id - b.station_id;
  }
}));
