import { API } from 'aws-amplify';
import { Device, VirtualDevice, ApiResponse, ResponseCreatedVirtualDevice } from 'models/device-management';

import { errorHandler } from 'clients/utils';
import { config } from './others';

const urls = {
  virtualDeviceById: (deviceId: Device['device_id']) => `/devices/virtual/${ deviceId }`,
  virtualDevices: '/devices/virtual',
};

export const deleteVirtualDevice = (deviceId: Device['device_id']): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.virtualDeviceById(deviceId), {})
    .catch(errorHandler);
};

export const createVirtualDevice = (params: VirtualDevice): Promise<ResponseCreatedVirtualDevice> => {
  const body = {
    firmware_hash: params.firmware_hash,
    activation_status: params.activation_status,
    owner_id: params.owner_id
  };

  return API
    .post(config.name, urls.virtualDevices, { body })
    .catch(errorHandler);
};
