import React from 'react';
import { formatChartAxisDateTime } from 'utils/datetime';
import { State } from 'reducers/pages/station-monitoring';

import { useNoiseLevelChartData } from './hooks/useNoiseLevelChartData';
import { getDurationDays } from './utils';

// components
import { Box, Typography } from '@material-ui/core';
import { AxisTick, DateAxisTick, ResponsiveLine } from 'components/Charts';
import { props as LineChartsOptions } from 'components/Charts/ChartsOptions/LineChartsOptions';
import { Tooltip } from './components/Tooltip';
import { BlockLoader } from 'components/Loaders';

// styles
import clsx from 'clsx';
import { colors } from 'styles/themes/outer';
import { useStyles } from '../../../styles';

interface Props {
  noiseData: State['noiseLevelHistory'];
}

export const NoiseLevelChart = ({ noiseData }: Props): JSX.Element => {
  const classes = useStyles();

  const lineData = useNoiseLevelChartData({ noiseHistory: noiseData });

  const valueRangeQuater = (lineData.maxValue - lineData.minValue) / 4;
  const durationDays = getDurationDays(noiseData.dateRange);

  return (
    <>
      <Typography className={ clsx(classes.blockCaption, classes.blockCaptionText) }>
        RF Noise Level
      </Typography>
      <Box height={ 156 } data-testid="chart">
        { !noiseData.fetched ? <BlockLoader size={ 20 } /> :
          <ResponsiveLine
            { ...LineChartsOptions }
            margin={ { top: 6, right: 20, bottom: 40, left: 60 } }
            data={ lineData.data }
            colors={ colors.mainPrimary }
            yScale={ {
              type: 'linear',
              max: lineData.maxValue,
              min: lineData.minValue,
            } }
            xScale={ {
              type: 'time',
              format: '%Y-%m-%d %H:%M',
              useUTC: false,
            } }
            xFormat="time:%Y-%m-%d %H:%M"
            axisBottom={ {
              tickSize: 0,
              format: formatChartAxisDateTime,
              renderTick: (durationDays !== undefined && durationDays > 7) ? AxisTick : DateAxisTick,
            } }
            gridYValues={ valueRangeQuater === 0
              ? undefined
              : [
                lineData.minValue,
                lineData.minValue + valueRangeQuater,
                lineData.minValue + valueRangeQuater * 2,
                lineData.minValue + valueRangeQuater * 3,
                lineData.maxValue
              ]
            }
            lineWidth={ 1.5 }
            enablePoints={ false }
            curve={ 'monotoneY' }
            enableArea={ false }
            axisLeft={ {
              orient: 'left',
              tickSize: 0,
              tickPadding: 15,
              tickValues: [lineData.minValue, lineData.maxValue],
              legend: 'dB',
              legendOffset: -40,
              legendPosition: 'middle'
            } }
            sliceTooltip={ Tooltip }
            animate={ false }
          />
        }
      </Box>
    </>
  );
};
