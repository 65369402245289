import { formatChartAxisDateTime } from 'utils/datetime';
import { DataWithMaxValue } from '../utils';

// components
import { DatumValue } from '@nivo/line';
import { AxisTick, DateAxisTick, ResponsiveLine } from 'components/Charts';
import { props as LineChartsOptions } from 'components/Charts/ChartsOptions/LineChartsOptions';
import { Tooltip } from './Tooltip';

interface ChartProps {
  title: string;
  color: string;
  valueFormat: (value: DatumValue) => string | number;
  showAxisBottom?: boolean;
  durationDays?: number;
}

const axisBottomMargin = 40;

export const Chart = ({
  data,
  maxValue,
  title,
  valueFormat,
  color,
  showAxisBottom,
  durationDays,
}: DataWithMaxValue & ChartProps): JSX.Element => (
  <ResponsiveLine
    { ...LineChartsOptions }
    margin={ { top: 0, right: 20, bottom: axisBottomMargin, left: 60 } }
    data={ data }
    colors={ [color] }
    yScale={ {
      type: 'linear',
      max: maxValue + 0.5,
      min: 0,
    } }
    xScale={ {
      type: 'time',
      format: '%Y-%m-%d %H:%M',
      useUTC: false,
    } }
    xFormat="time:%Y-%m-%d %H:%M"
    yFormat={ valueFormat }
    axisBottom={ showAxisBottom ? {
      tickSize: 0,
      format: formatChartAxisDateTime,
      renderTick: (durationDays && durationDays > 7) ? AxisTick : DateAxisTick,
    } : null }
    axisLeft={ {
      orient: 'left',
      tickSize: 0,
      tickPadding: 15,
      format: _ => '',
      legend: title,
      legendOffset: -40,
      legendPosition: 'middle'
    } }
    lineWidth={ 1.5 }
    sliceTooltip={ Tooltip }
    enableGridY={ false }
    enablePoints={ false }
    areaOpacity={ .1 }
    legends={ [] }
    curve={ 'stepAfter' }
    animate={ false }
  />
);