import * as Actions from 'actions/parking-monitoring/zones';
import { ParkingAnomalyByZoneId } from 'models/parking-monitoring';

export interface State {
  byZoneId?: ParkingAnomalyByZoneId;
  isFetching: boolean;
}

export const initState: State = {
  byZoneId: {},
  isFetching: false,
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.GET_BY_ZONE_ID_PARKING_ANOMALY:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.GET_BY_ZONE_ID_PARKING_ANOMALY_SUCCESS:
      const { occupy_max_interval, availability_max_interval } = action.payload;
      return {
        ...state,
        byZoneId: {
          ...action.payload,
          occupy_max_interval: occupy_max_interval ? Math.trunc( occupy_max_interval / 3600) : undefined,
          availability_max_interval: availability_max_interval ? Math.trunc( availability_max_interval / 3600) : undefined
        },
        isFetching: false,
      };
    case Actions.GET_BY_ZONE_ID_PARKING_ANOMALY_FAILED:
      return {
        ...state,
        byZoneId: undefined,
        isFetching: false
      };
    default:
      return state;
  }
};
