import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { RootState } from 'reducers';
import { GetRulesParams, Rule } from 'models/rabbit';
import { LoadRabbitRuleDataById, LoadRabbitRuleDataByParams, LoadRabbitRuleDataDictionary } from 'actions/rabbit';

export interface RuleState {
  rule?: Rule;
  isLoading: boolean;
}

export function useRabbitRuleLoadSelectorById(ruleId: number): RuleState {
  const dispatch = useDispatch();
  const { isFetched, rule } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.rule.isFetched(ruleId),
    rule: state.rabbit.rule.findById(ruleId),
  }));

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitRuleDataById(ruleId));
    }
  }, [ruleId, isFetched, dispatch]);

  return {
    rule: rule,
    isLoading: isFetched !== true
  };
}

export interface RulesState {
  rules: Rule[];
  isLoading: boolean;
}

export function useRabbitRuleLoadSelectorByParams(params: GetRulesParams): RulesState {
  const dispatch = useDispatch();
  const { isFetched, rules } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.rule.isFetched(params),
    rules: state.rabbit.rule.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitRuleDataByParams(params));
    }
  }, [params, isFetched, dispatch]);

  return {
    rules: rules,
    isLoading: isFetched !== true
  };
}

export function useRabbitRuleDictionarySelector(): RulesState {
  const dispatch = useDispatch();
  const params: GetRulesParams = {};
  const { isFetched, rules } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.rule.isFetched(params),
    rules: state.rabbit.rule.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitRuleDataDictionary());
    }
  }, [isFetched, dispatch]);

  return {
    rules: rules,
    isLoading: isFetched !== true
  };
}
