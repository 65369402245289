export enum StatusesEnum {
  all = 'All',
  error = 'Error',
  failed = 'Failed',
  inProgress = 'InProgress',
  success = 'Success',
}

export interface HistoryFilterState {
  owner?: number;
  zones: number[];
  groupIds: number[];
  deviceIds: string[];
  templateIds: number[];
  templateSuiteIds: number[];
  messageTraceId: string;
  sendingStatus: StatusesEnum;
  sendingTimeFrom?: Date;
  sendingTimeTo?: Date;
}

export interface GetHistoryParamsQuery extends Partial<HistoryFilterState> { 
  offset?: number;
  limit?: number;
}

export interface History {
  history_item_id?: number;
  device_id?: number;
  position_id?: number;
  position_network_id?: string;
  group_id?: number;
  zone_id?: number;
  template_id?: number;
  template_name?: string;
  msg_trace_id?: string;
  req_url?: string;
  req_method?: string;
  req_headers?: string;
  req_body?: string;
  resp_code?: number;
  resp_body?: string;
  got_time?: Date;
  sending_time?: Date;
  attempt_number?: number;
  sending_status?: string;
}
