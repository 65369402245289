import { GroupType } from 'models/device-management';

export enum FormField {
  zone = 'zone_id',
  level = 'level_id',
  customId = 'custom_id',
  name = 'name',
  type = 'type',
}

export interface FormState {
  [FormField.zone]?: number;
  [FormField.level]?: number;
  [FormField.customId]?: string;
  [FormField.name]?: string;
  [FormField.type]?: GroupType;
}
