import { TemplateSuite, TemplateSuiteId, Template } from 'models/caller';

export const CALLER_TEMPLATE_SUITE_CREATE = 'CALLER_TEMPLATE_SUITE_CREATE';
export const CALLER_TEMPLATE_SUITE_UPDATE = 'CALLER_TEMPLATE_SUITE_UPDATE';
export const CALLER_TEMPLATE_SUITE_DELETE = 'CALLER_TEMPLATE_SUITE_DELETE';
export const CALLER_TEMPLATE_SUITE_LOCK = 'CALLER_TEMPLATE_SUITE_LOCK';
export const CALLER_TEMPLATE_SUITE_UNLOCK = 'CALLER_TEMPLATE_SUITE_UNLOCK';
export const CALLER_TEMPLATE_SUITE_CHANGE = 'CALLER_TEMPLATE_SUITE_CHANGE';
export const CALLER_TEMPLATE_SUITE_CLONE = 'CALLER_TEMPLATE_SUITE_CLONE';

export interface CreateTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_CREATE;
  readonly templateSuite: TemplateSuite;
}

export interface UpdateTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_UPDATE;
  readonly templateSuite: TemplateSuite;
}

export interface DeleteTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DELETE;
  readonly templateSuite: TemplateSuite;
}

export interface LockTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_LOCK;
  readonly id: TemplateSuiteId;
}

export interface UnlockTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_UNLOCK;
  readonly id: TemplateSuiteId;
}

export interface ChangeTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_CHANGE;
  readonly templateSuite: TemplateSuite;
  readonly options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export interface CloneTemplateSuite {
  readonly type: typeof CALLER_TEMPLATE_SUITE_CLONE;
  readonly templateSuite: TemplateSuite;
  readonly templates: Template[];
}

export const CreateTemplateSuite = (templateSuite: TemplateSuite): CreateTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_CREATE, templateSuite
});
export const UpdateTemplateSuite = (templateSuite: TemplateSuite): UpdateTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_UPDATE, templateSuite
});
export const DeleteTemplateSuite = (templateSuite: TemplateSuite): DeleteTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_DELETE, templateSuite
});
export const LockTemplateSuite = (id: TemplateSuiteId): LockTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_LOCK, id
});
export const UnlockTemplateSuite = (id: TemplateSuiteId): UnlockTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_UNLOCK, id
});
export const ChangeTemplateSuite = (templateSuite: TemplateSuite, options: ChangeTemplateSuite['options']): ChangeTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_CHANGE, templateSuite, options
});
export const CloneTemplateSuite = (templateSuite: TemplateSuite, templates: Template[]): CloneTemplateSuite => ({
  type: CALLER_TEMPLATE_SUITE_CLONE, templateSuite, templates
});

export type Action = (
  | CreateTemplateSuite
  | UpdateTemplateSuite
  | DeleteTemplateSuite
  | LockTemplateSuite
  | UnlockTemplateSuite
  | ChangeTemplateSuite
  | CloneTemplateSuite
);
