import React from 'react';
import { useFormActionLoader } from 'hooks';
import { DeleteEndpoint } from 'actions/rabbit';
import { Endpoint } from 'models/rabbit';
import { useRabbitRuleLoadSelectorByParams } from 'hooks/rabbit';
// components
import { ActionLoaderProps, PageLoader } from 'components/Loaders';
import { EnsureDialog, InfoDialog } from 'components/Dialogs';

interface Props {
  endpoint: Endpoint;
  isOpen: boolean;
  onSuccess?: ActionLoaderProps['onSuccess'];
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DeleteEndpointDialog = ({ endpoint, isOpen, onSuccess, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const { isLoading, rules } = useRabbitRuleLoadSelectorByParams({ endpointId: endpoint.id });
  if (isLoading) {
    return (
      <PageLoader/>
    );
  }

  if (rules.length > 0) {
    return (
      <InfoDialog
        name="delete-endpoint"
        label="Can not delete endpoint"
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
      >
        The endpoint "<b>{ endpoint.name }</b>" can not be deleted, because have routes
      </InfoDialog>
    );
  }

  const handleDelete = () => doAction({
    action: DeleteEndpoint(endpoint),
    onSuccess: onSuccess,
    onFinally: onClose,
  });

  return (
    <EnsureDialog
      name="delete-endpoint"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
    >
      The endpoint <b>{ endpoint.name }</b> will be deleted
    </EnsureDialog>
  );
};
