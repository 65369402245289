export enum StationFrequency {
  UK_B1 = 866.5,
  UK_B2 = 869.84,
  US_B1 = 916.5,
  EU_B1 = 868.8,
}

export const StationFrequencyTitles = new Map<StationFrequency, string>([
  [StationFrequency.UK_B1, '866.500 MHz (UK B1)'],
  [StationFrequency.UK_B2, '869.840 MHz (UK B2)'],
  [StationFrequency.US_B1, '916.500 MHz (US B1)'],
  [StationFrequency.EU_B1, '868.800 MHz (EU B1)'],
]);
