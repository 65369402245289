import { combineReducers } from 'redux';

import * as positionState from './position-state';

export interface State {
  positionState: positionState.State;
}

export const reducer = combineReducers<State>({
  positionState: positionState.reducer,
});
