import { ApiGConfig, ApiResponse } from 'models';
import { staticEnv } from 'env';
import { errorHandler, formatQuery } from './utils';
import { API } from 'aws-amplify';
import { GetFirmwareParams, UpdateDeviceFirmwareParams } from 'models/firmware-management';

const urls = {
  deviceFirmware: (firmwareHash: string, deviceId: string) => `/firmwares/${firmwareHash}/flash/${deviceId}`,
  firmwares: '/firmwares',
};

export const config: ApiGConfig = {
  name: 'fmw',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://fmw.prod.api.nwave.io' : 'https://fmw.dev.api.nwave.io',
};

export const updateDeviceFirmware = (params: UpdateDeviceFirmwareParams): Promise<ApiResponse> => {
  const reqParams = {
    body: params.firmware,
  };

  return API
    .post(config.name, urls.deviceFirmware(params.firmwareHash, params.deviceId), reqParams)
    .catch(errorHandler);
};

export const getFirmwaresByParams = (params: GetFirmwareParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      hash: params.hash,
      name: params.name,
      version: params.version,
      build_time_from: params.buildFrom?.getUTCSeconds(),
      build_time_to: params.buildTo?.getUTCSeconds(),
      hw_types: params.hardwareTypes,
      low_level_protocol_type: params.protocolType,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.firmwares, body)
    .catch(errorHandler);
};
