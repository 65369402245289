import { useState, useEffect } from 'react';
import { HistoryFilterState, StatusesEnum } from 'models/caller';
// components
import {
  CallerTemplatesSelectControl as TemplatesSelect,
  CallerTemplateSuitesSelectControl as TemplateSuitesSelect,
  CustomIdSelect,
  DateTimeControl,
  GroupedFilters,
  SelectOption,
  SingleSelectControl,
  TextControl
} from 'components/Controls';
import { FilterButton } from 'components/Buttons';
// styles
import useStyles from 'styles/filters';

export const statusOptions: SelectOption[] = [
  { value: StatusesEnum.all, label: 'All' },
  { value: StatusesEnum.inProgress, label: 'In Progress' },
  { value: StatusesEnum.success, label: 'Success' },
  { value: StatusesEnum.error, label: 'Error' },
  { value: StatusesEnum.failed, label: 'Failed' },
];

interface Props {
  isLoading: boolean;
  filter: HistoryFilterState;
  saveFilter: (filter: HistoryFilterState) => void;
  resetFilter: () => void;
}

const Component = ({ isLoading, filter, saveFilter, resetFilter }: Props): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState<HistoryFilterState>(filter);
  useEffect(() => { setState(filter); }, [filter]);

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filter }
      skip={{ sendingStatus: StatusesEnum.all }}
      onSave={ () => { saveFilter(state); } }
      onReset={ resetFilter }
    >
      <form className={ classes.gridRoot } autoComplete="off">
        <GroupedFilters
          isProjectShow={ false }
          isZoneShow
          isGroupsShow
          owner={ state.owner }
          handleSelectOwner={ owner => setState({ ...state, owner }) }
          zones={ state.zones }
          handleSelectZones={ zones => setState({ ...state, zones }) }
          groups={ state.groupIds }
          handleSelectGroups={ groupIds => setState({ ...state, groupIds }) }
          uncheckGroups={ () => setState({ ...state, groupIds: [] }) }
        />
        <CustomIdSelect
          selected={ state.deviceIds }
          changeHandler={ (devices: string[]) => setState({ ...state, deviceIds: devices }) }
          type="hex"
          name="Device"
        />
        <TemplateSuitesSelect
          isMulti={ true }
          isClearable={ true }
          isDisabled={ isLoading }
          selected={ state.templateSuiteIds }
          filter={ suite => !state.owner || suite.owner_id === state.owner }
          onChange={ templateSuiteIds => setState({ ...state, templateSuiteIds: templateSuiteIds ?? [] }) }
        />
        <TemplatesSelect
          isMulti={ true }
          isClearable={ true }
          isDisabled={ isLoading }
          selected={ state.templateIds }
          filter={ (template => state.templateSuiteIds.includes(template.template_suite_id)) }
          onChange={ templateIds => setState({ ...state, templateIds: templateIds ?? [] }) }
        />
        <TextControl
          name="messageTraceId"
          label="Message Trace ID"
          value={ state.messageTraceId ? state.messageTraceId : '' }
          onChange={ (event: React.ChangeEvent<{ value: string }>) => {
            const value = event.target.value.replace(/[^A-Za-z0-9-]+/g, '');
            setState({ ...state, messageTraceId: value });
          } }
        />
        <SingleSelectControl
          label="Status"
          name="status"
          selected={ state.sendingStatus }
          values={ statusOptions }
          changeHandler={ (value?: string) => setState({ ...state, sendingStatus: value as StatusesEnum }) }
          isClearable={ false }
          isDisabled={ isLoading }
        />
        <DateTimeControl
          label="Time From"
          value={ state.sendingTimeFrom }
          onChange={ (date?: Date) => setState({ ...state, sendingTimeFrom: date }) }
          maxDate={ state.sendingTimeTo }
          isDisabled={ isLoading }
          hideErrorsFromHelperText
          isClearable
        />
        <DateTimeControl
          label="Time To"
          value={ state.sendingTimeTo }
          onChange={ (date?: Date) => setState({ ...state, sendingTimeTo: date }) }
          minDate={ state.sendingTimeFrom }
          isDisabled={ isLoading }
          isClearable
        />
      </form>
    </FilterButton>
  );
};

export default Component;
