import React from 'react';
// components
import { FormControl, FormControlLabel, FormControlProps, FormHelperText, Switch, SwitchProps } from '@material-ui/core';

// styles
import clsx from 'clsx';
import contentStyles from 'styles';
import { useStyles } from './styles';

interface Props {
  name: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  isDisabled?: boolean;
  className?: string;
  error?: string;
  ControlProps?: Partial<FormControlProps>;
  SwitchProps?: Partial<SwitchProps>;
}

export const SwitchControl = (props: Props): JSX.Element => {
  const classes = useStyles();
  const contentClasses = contentStyles();

  return (
    <FormControl
      { ...props.ControlProps }
      className={ clsx(
        contentClasses.formControl,
        contentClasses.formSwitchControl,
        props.className,
      ) }
      error={ Boolean(props.error) }
    >
      <FormControlLabel
        label={ props.label }
        labelPlacement="start"
        classes={ { label: contentClasses.formLabelNonShrunk } }
        control={
          <Switch
            color="primary"
            size="small"
            className={ classes.switch }
            { ...props.SwitchProps }
            disabled={ props.isDisabled }
            checked={ props.value }
            onChange={ event => props.onChange(Boolean(event.target.checked)) }
          />
        }
      />
      { props.error && <FormHelperText error={ Boolean(props.error) }>{ props.error }</FormHelperText> }
    </FormControl>
  );
};