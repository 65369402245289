import React from 'react';
import { getDMZoneDetailPath } from 'routing/paths';

// components
import { Box, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { CommonButton } from 'components/Buttons';

interface Props {
  zoneId: string;
  levelId: string;
}

export const LevelNotFound = ({ zoneId, levelId }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="space-between"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        Level <b>{ `#${ levelId }` }</b> not found
      </Typography>

      <CommonButton
        type="button"
        label={ `Back to zone ${ zoneId }` }
        LinkTo={ getDMZoneDetailPath(zoneId) }
        icon={ <ArrowBackIcon/> }
      />
    </Box>
  );
};