type Listener<TEnvironment> = (env: TEnvironment) => void;
type Finalizer<TEnvironment> = (envOverrides: Partial<TEnvironment>) => TEnvironment;

export class EnvironmentStore<TEnvironment> {
  private envOverrides: Partial<TEnvironment> = {};
  private listeners = new Set<Listener<TEnvironment>>();

  constructor(private finalizeEnv: Finalizer<TEnvironment>) {}

  getEnv = (): TEnvironment => {
    return this.finalizeEnv(this.envOverrides);
  };

  subscribe = (listener: Listener<TEnvironment>) => {
    this.listeners.add(listener);
  };

  unsubscribe = (listener: Listener<TEnvironment>) => {
    this.listeners.delete(listener);
  };

  overrideEnv = (overrides: Partial<TEnvironment>) => {
    this.envOverrides = {
      ...this.envOverrides,
      ...overrides,
    };

    this.listeners.forEach(listener => listener(this.getEnv()));
  };
}
