import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DoGetByGroupIdParkingAnomaly,
  DoSetGroupParkingAnomalyDuration,
  DoSetGroupParkingAnomalyIgnore,
  DoEditGroupDurationParkingAnomaly,
  DoEditGroupIgnoreParkingAnomaly
} from 'actions/parking-monitoring';
import { RootState } from 'reducers';

// component
import {  ParkingAnomaly, State } from 'components/ParkingAnomaly/ParkingAnomalyComponent';

// styes
import { useStyles } from './ParkingAnomalyStyles';

interface GroupParkingMonitoringProps {
  groupId?: number;
}

export const GroupParkingMonitoring = ({ groupId }: GroupParkingMonitoringProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { byGroupId: monitoring, isFetching } = useSelector((state: RootState) => state.parkingMonitoring.groups);

  const [state, setState] = useState<State>({
    occupancyDuration: undefined,
    availabilityDuration: undefined,
    ignored: false,
  });

  useEffect(() => {
    groupId && dispatch(DoGetByGroupIdParkingAnomaly(groupId));
  }, [dispatch, groupId]);

  useEffect(() => {
    setState({
      occupancyDuration: monitoring?.occupy_max_interval || 0,
      availabilityDuration: monitoring?.availability_max_interval || 0,
      ignored: monitoring?.ignored || false,
    });
  }, [monitoring, groupId]);

  const handleSubmitDuration = () => {
    if (!groupId) {
      return false;
    }
    if (!monitoring) {
      dispatch(DoSetGroupParkingAnomalyDuration({
        occupyMaxInterval: Number(state.occupancyDuration) || undefined,
        availabilityMaxInterval: Number(state.availabilityDuration)|| undefined,
        groupId,
      }));
    } else {
      dispatch(DoEditGroupDurationParkingAnomaly({
        occupyMaxInterval: Number(state.occupancyDuration) || undefined,
        availabilityMaxInterval: Number(state.availabilityDuration) || undefined,
        groupId,
      }));
    }
  };

  const handleSubmitIgnore = () => {
    if (!groupId) {
      return false;
    }
    if(!monitoring) {
      dispatch(DoSetGroupParkingAnomalyIgnore({
        ignored: state.ignored,
        groupId,
      }));
    } else {
      dispatch(DoEditGroupIgnoreParkingAnomaly({
        ignored: state.ignored,
        groupId,
      }));
    }
  };
  return (
    <div className={ classes.smallAnomalyBox }>
      <ParkingAnomaly
        monitoring={ monitoring }
        isFetching={ isFetching }
        handleSubmitDuration={ handleSubmitDuration }
        handleSubmitIgnore={ handleSubmitIgnore }
        state={ state }
        setState={ setState}
      />
    </div>
  );
};
