import React from 'react';
import { Endpoint } from 'models/rabbit';
import { useRabbitRuleDictionarySelector } from 'hooks/rabbit';
import { styled } from 'styles/utils';

// components
import { Box } from '@material-ui/core';
import { ZoneLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';
import { ActiveStatusIcon } from 'components/Icons';

interface Props {
  endpoint: Endpoint;
}

const Chip = styled(Box)(({ theme }) => ({
  padding: theme.spacing(.5, 1),
  margin: theme.spacing(.5),
  background: theme.palette.grey[100],
  borderRadius: 3,
  display: 'inline-flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  justifyContent: 'center',
}));

export const ZonesCell = ({ endpoint }: Props): JSX.Element => {
  const { isLoading, rules } = useRabbitRuleDictionarySelector();

  if (isLoading) {
    return (
      <LoaderSvg/>
    );
  }

  const endpointRules = rules.filter(rule => rule.endpoint_id === endpoint.id && rule.zone_id);
  const zoneStatus: Map<number, {active: boolean; inactive: boolean}> = new Map();
  endpointRules.forEach(rule => {
    const zoneId = Number(rule.zone_id);
    const status = zoneStatus.get(zoneId) ?? { active: false, inactive: false };
    status.active = status.active || rule.activity === true;
    status.inactive = status.inactive || rule.activity === false;
    zoneStatus.set(zoneId, status);
  });

  return (
    <>
      { [...zoneStatus.entries()].map(([zoneId, status]) =>
        <Chip key={ zoneId }>
          { status.active && <ActiveStatusIcon status={ true } /> }
          { status.inactive && <ActiveStatusIcon status={ false } /> }
          <ZoneLink zoneId={ zoneId }/>
        </Chip>
      ) }
    </>
  );
};
