import { GetTemplateSuitesParams, TemplateSuite, TemplateSuiteId } from 'models/caller';

export const CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_ID = 'CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_ID';
export const CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_PARAMS = 'CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_PARAMS';
export const CALLER_TEMPLATE_SUITE_DATA_FETCH_START = 'CALLER_TEMPLATE_SUITE_DATA_FETCH_START';
export const CALLER_TEMPLATE_SUITE_DATA_FETCH_STOP = 'CALLER_TEMPLATE_SUITE_DATA_FETCH_STOP';
export const CALLER_TEMPLATE_SUITE_DATA_CHANGE = 'CALLER_TEMPLATE_SUITE_DATA_CHANGE';

export const GET_CALLER_SUITE_BY_ID_START = 'GET_CALLER_SUITE_BY_ID_START';
export const GET_CALLER_SUITE_BY_ID_SUCCESS = 'GET_CALLER_SUITE_BY_ID_SUCCESS';
export const GET_CALLER_SUITE_BY_ID_FAILED = 'GET_CALLER_SUITE_BY_ID_FAILED';

export interface LoadTemplateSuiteDataById {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_ID;
  readonly id: TemplateSuiteId;
}

export interface LoadTemplateSuiteDataByParams {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_PARAMS;
  readonly params: GetTemplateSuitesParams;
}

export interface LoadTemplateSuiteData {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_PARAMS;
  readonly params: GetTemplateSuitesParams;
}

export interface LoadTemplateSuiteDataStart {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DATA_FETCH_START;
  readonly params: GetTemplateSuitesParams;
}

export interface LoadTemplateSuiteDataStop {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DATA_FETCH_STOP;
  readonly params: GetTemplateSuitesParams;
}

export interface ChangeTemplateSuiteData {
  readonly type: typeof CALLER_TEMPLATE_SUITE_DATA_CHANGE;
  readonly params: GetTemplateSuitesParams;
  readonly data: TemplateSuite[];
}

export const LoadTemplateSuiteDataById = (id: TemplateSuiteId): LoadTemplateSuiteDataById => ({
  type: CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_ID, id
});
export const LoadTemplateSuiteDataByParams = (params: GetTemplateSuitesParams): LoadTemplateSuiteDataByParams => ({
  type: CALLER_TEMPLATE_SUITE_DATA_LOAD_BY_PARAMS, params
});

export type Action = (
  | LoadTemplateSuiteDataById
  | LoadTemplateSuiteDataByParams
  | LoadTemplateSuiteDataStart
  | LoadTemplateSuiteDataStop
  | ChangeTemplateSuiteData
);
