import { useReducer } from 'react';
import invariant from 'tiny-invariant';

import { DmTreeNode } from '../types';

export function useRerender(): () => void {
  const [, rerender] = useReducer((x: number) => x + 1, 0);

  return rerender;
}

export function findNode(nodeId: string, nodes: DmTreeNode[]): DmTreeNode | undefined {
  const matchingNodes = filterNodesDeepFlatten(nodes, (n) => n.id === nodeId);

  invariant(
    matchingNodes.length <= 1,
    `Expected no more than one node with id = ${JSON.stringify(nodeId)}, ` +
      `but found: ${matchingNodes.length}`
  );

  return matchingNodes[0];
}

export function filterNodesDeepFlatten(
  nodes: DmTreeNode[],
  isMatching?: (node: DmTreeNode) => boolean
): DmTreeNode[] {
  const matchingNodes: DmTreeNode[] = [];

  nodes.forEach((node) => {
    if (!isMatching || isMatching(node)) {
      matchingNodes.push(node);
    }

    if ('children' in node) {
      const nestedMatchingNodes = filterNodesDeepFlatten(
        node.children,
        isMatching
      );
      matchingNodes.push(...nestedMatchingNodes);
    }
  });

  return matchingNodes;
}
