import { sumBy } from 'lodash';
import React from 'react';

import { formatAsPercent } from 'utils/format';

// components
import { Grid, Typography } from '@material-ui/core';

// styles
import { makeStyles, createStyles } from 'styles/utils';

const useLegendStyles = makeStyles(_theme => createStyles({
  root: {
    padding: '0px 22px 0px 12px',
  },
  icon: {
    marginRight: 8,
    display: 'inline-block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
  label: {
    fontSize: 12,
  },
  totalLabel: {
    fontSize: 12,
    fontWeight: 500,
  },
  valueContainer: {
    marginLeft: 30,
    display: 'inline-flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
  },
  valueFraction: {
    marginLeft: 4,
    minWidth: 28,
    fontSize: 11,
  },
  totalValue: {
    fontSize: 12,
  },
}));

interface LegendEntry {
  id: React.ReactText;
  label?: React.ReactText;
  value: number;
  color?: string;
}

export interface MonitoringChartLegendProps {
  entries: LegendEntry[];
  getEntryLabel?: (entry: LegendEntry) => React.ReactText;
  total?: number;
}

export const MonitoringChartLegend = ({
  entries,
  getEntryLabel = e => e.label ?? e.id,
  total: totalProp,
}: MonitoringChartLegendProps) => {
  const classes = useLegendStyles();

  const total = totalProp ?? sumBy(entries, entry => entry.value);
  const longestFractionLength = Math.max(
    ...entries.map(entry => formatAsPercent(entry.value, total).length),
  );

  return (
    <div className={ classes.root } data-testid="monitoring-chart-legend">
      <Grid
        container
        spacing={ 1 }
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        data-testid="monitoring-chart-legend-total"
      >
        <Grid item>
          <Typography variant="inherit" className={ classes.totalLabel }>
            Total
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="inherit" className={ classes.totalValue }>
            { total }
          </Typography>
        </Grid>
      </Grid>

      { entries.map((e, index) => (
        <Grid
          container
          spacing={ 1 }
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          key={ index }
          data-testid="monitoring-chart-legend-item"
        >
          <Grid item className={ classes.label }>
            { e.color && (
              <span
                className={ classes.icon }
                style={ { backgroundColor: e.color } }
                data-testid="monitoring-chart-legend-item-color"
              />
            ) }
            { getEntryLabel(e) }
          </Grid>

          <Grid item className={ classes.valueContainer }>
            <span className={ classes.value }>
              { e.value }
            </span>

            { typeof e.value === 'number' && (
              <span
                className={ classes.valueFraction }
                style={ { minWidth: (longestFractionLength + 2) * 6 } }
              >
                { ' ' }({ formatAsPercent(e.value, total) })
              </span>
            ) }
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
