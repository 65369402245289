import React from 'react';
import { StationStatusFlat, StationStatusTitle } from 'models/base-station-status';
// components
import { CommonStatus, Props as CommonStatusProps } from './CommonStatus';

interface Props {
  stationStatus?: StationStatusFlat;
  variant?: CommonStatusProps['variant'];
}

export const HealthyStatus = ({ stationStatus, variant }: Props) => {
  const label = stationStatus?.hasIssues === undefined
    ? StationStatusTitle.Unhealthy
    : (stationStatus.hasIssues ? StationStatusTitle.Unhealthy : StationStatusTitle.Healthy);

  const color: CommonStatusProps['color'] = stationStatus?.hasIssues === undefined
    ? 'unknown'
    : (stationStatus.hasIssues ? 'error' : 'ok');

  return (
    <CommonStatus
      label={ label }
      duration={ stationStatus?.issueChanged }
      color={ color }
      variant={ variant }
    />
  );
};
