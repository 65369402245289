import { Box, initProvisioningBoxesFilters, Shipment } from 'models/provisioning';
import { isShipmentReceived, useProvisioningBoxes, useProvisioningReceivedBoxes } from 'hooks/provisioning';

interface ShipmentBoxesState {
  isFetching: boolean;
  boxes: Box[];
  total: number;
}

export const useShipmentBoxes = (shipment: Shipment): ShipmentBoxesState => {
  const shipmentBoxes = useProvisioningBoxes({
    params: {
      ...initProvisioningBoxesFilters,
      shipment_id: [shipment.id],
      limit: 9999, // load all boxes
    },
    enabled: !isShipmentReceived(shipment)
  });

  const receivedBoxes = useProvisioningReceivedBoxes({
    filter: {
      shipments: [shipment.id],
      limit: 9999, // load all boxes
    },
    enabled: isShipmentReceived(shipment)
  });

  if (!isShipmentReceived(shipment)) {
    return shipmentBoxes;
  }

  return {
    ...receivedBoxes,
    boxes: receivedBoxes.boxes.map((b): Box => ({
      id: b.box_id ?? 0,
      shipment_id: b.shipment_id,
      device_count: b.device_count,
    })),
  };
};