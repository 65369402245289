import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { RootState } from 'reducers';
import { LoadCallerTemplateDataById } from 'actions/caller';
import { Template } from 'models/caller';

export interface TemplateState {
  isLoading: boolean;
  isExists: boolean;
  template?: Template;
}

export function useTemplateSelector(templateId: number): TemplateState {
  const dispatch = useDispatch();
  const { isFetched, template } = useSelector((state: RootState) => ({
    isFetched: state.caller.template.isFetched(templateId),
    template: state.caller.template.findById(templateId),
  }));

  useEffectOnce(() => {
    if (isFetched === undefined) {
      dispatch(LoadCallerTemplateDataById(templateId));
    }
  });

  return {
    template: template,
    isLoading: isFetched !== true && template === undefined,
    isExists: isFetched === true && template !== undefined,
  };
}
