import React from 'react';
import { isAllowedToCreatePosition } from 'utils/permissions';
import { useOnSplacePopup } from 'hooks/splace-messages';
import { useAuthUserSelector } from 'hooks';
import { GroupNode } from 'hooks/device-management/useTree/types';

// components
import { AddButton } from 'components/Buttons';

interface Props {
  node: GroupNode;
  onSuccess: () => void;
}

export const AddGroupPositionButton = ({ node, onSuccess }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const splacePopup = useOnSplacePopup({
    query: {
      zoneId: node.group.zone_id,
      levelId: node.group.level_id,
      groupId: node.group.id,
    },
    onMessage: onSuccess
  });

  if (!isAllowedToCreatePosition(user.data)) {
    return (
      <></>
    );
  }

  return (
    <AddButton
      label="Add position"
      onClick={ splacePopup.open }/>
  );
};
