/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.17.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatisticMetricHistoryEnum = 'incidents_history' | 'silent_history';

export const StatisticMetricHistoryEnum = {
    IncidentsHistory: 'incidents_history' as StatisticMetricHistoryEnum,
    SilentHistory: 'silent_history' as StatisticMetricHistoryEnum
}
