import { useState } from 'react';
import { useDebounce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import { findDevices } from 'actions/device-management/devices';
import { Device } from 'models/device-management';
import { RootState as State } from 'reducers';
import { getDevice, areDevicesLoading } from 'selectors/device-management/devices';
import { useDataLoaderOnce } from 'utils/components';

interface DeviceInputLoaderHookResult {
  inputValue: string;
  setInputValue: (value: string) => void;

  device?: Device | null;
  deviceId: Device['device_id'];
  devicePending: boolean;
}

const useDeviceInputLoader = (): DeviceInputLoaderHookResult => {
  const [inputValue, setInputValue] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>(inputValue);

  useDebounce(
    () => setDeviceId(inputValue),
    200,
    [inputValue],
  );

  const device = useSelector((state: State) => getDevice(deviceId, state));
  const deviceLoading = useSelector(areDevicesLoading);

  const dispatch = useDispatch();

  const { aboutToStartLoading: deviceAboutToStartLoading } = useDataLoaderOnce({
    key: deviceId,
    load: () => dispatch(findDevices({ deviceIds: [deviceId] })),
    shouldStartLoading: !!deviceId && typeof device === 'undefined',
  });

  return {
    inputValue,
    setInputValue,

    device,
    deviceId,
    devicePending: inputValue !== deviceId || deviceLoading || deviceAboutToStartLoading,
  };
};

export default useDeviceInputLoader;
