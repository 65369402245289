import { Permissions, PermissionsCreateParams, PermissionsUpdateParams } from 'models/user-management';
import { ApiResponse } from 'models';
import { API } from 'aws-amplify';
import { errorHandler } from 'clients/utils';
import { config, urls } from './config';

export const fetchPermissions = (): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.permissions, { queryStringParameters: { limit: 999 } })
    .catch(errorHandler);
};

export const createPermissions = (params: PermissionsCreateParams): Promise<ApiResponse> => {
  const init = {
    body: params,
  };

  return API
    .post(config.name, urls.permissions, init)
    .catch(errorHandler);
};

export const deletePermissions = (id: Permissions['id']): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.permissionsById(id), {})
    .catch(errorHandler);
};

export const updatePermissions = (params: PermissionsUpdateParams): Promise<ApiResponse> => {
  const init = {
    body: params.props,
  };

  return API
    .put(config.name, urls.permissionsById(params.id), init)
    .catch(errorHandler);
};
