import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DoGetByPositionIdParkingAnomaly,
  DoSetPositionParkingAnomalyDuration,
  DoEditPositionDurationParkingAnomaly,
  DoEditPositionIgnoreParkingAnomaly,
  DoSetPositionParkingAnomalyIgnore
} from 'actions/parking-monitoring';
import { RootState } from 'reducers';

// component
import {  ParkingAnomaly, State } from 'components/ParkingAnomaly/ParkingAnomalyComponent';

interface PositionParkingMonitoringProps {
  positionId?: number;
}

export const PositionParkingMonitoring = ({ positionId }: PositionParkingMonitoringProps): JSX.Element => {
  const dispatch = useDispatch();
  const { byPositionId: monitoring, isFetching } = useSelector((state: RootState) => state.parkingMonitoring.positions);

  const [state, setState] = useState<State>({
    occupancyDuration: undefined,
    availabilityDuration: undefined,
    ignored: false,
  });

  useEffect(() => {
    positionId && dispatch(DoGetByPositionIdParkingAnomaly(positionId));
  }, [dispatch, positionId]);

  useEffect(() => {
    setState({
      occupancyDuration: monitoring?.occupy_max_interval || 0,
      availabilityDuration: monitoring?.availability_max_interval || 0,
      ignored: monitoring?.ignored || false,
    });
  }, [monitoring, positionId]);

  const handleSubmitDuration = () => {
    if (!positionId) {
      return false;
    }
    if (!monitoring) {
      dispatch(DoSetPositionParkingAnomalyDuration({
        occupyMaxInterval: Number(state.occupancyDuration) || undefined,
        availabilityMaxInterval: Number(state.availabilityDuration)|| undefined,
        positionId,
      }));
    } else {
      dispatch(DoEditPositionDurationParkingAnomaly({
        occupyMaxInterval: Number(state.occupancyDuration) || undefined,
        availabilityMaxInterval: Number(state.availabilityDuration) || undefined,
        positionId,
      }));
    }
  };

  const handleSubmitIgnore = () => {
    if (!positionId) {
      return false;
    }
    if(!monitoring) {
      dispatch(DoSetPositionParkingAnomalyIgnore({
        ignored: state.ignored,
        positionId,
      }));
    } else {
      dispatch(DoEditPositionIgnoreParkingAnomaly({
        ignored: state.ignored,
        positionId,
      }));
    }
  };
  return (
    <ParkingAnomaly
      monitoring={ monitoring }
      isFetching={ isFetching }
      handleSubmitDuration={ handleSubmitDuration }
      handleSubmitIgnore={ handleSubmitIgnore }
      state={ state }
      setState={ setState}
    />
  );
};
