import React from 'react';
import { useDispatch } from 'react-redux';

import { replaceDevice } from 'actions/device-management/devices';
import { NotifyError } from 'actions/notifier';
import { Device } from 'models/device-management';
import useDeviceInputLoader from 'pages/DevicePositionCouple/widgets/useDeviceInputLoader';
import { dispatchAsync } from 'utils/store';

// components
import { TextField } from '@material-ui/core';
import { Button, ConfirmationDialog, ConfirmationDialogProps } from 'components';

// styles
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';

import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';
import { warningTheme } from 'styles/themes';

interface ReplaceDeviceProps {
  deviceId: Device['device_id'];
  onSuccess?: () => void;
}

function getConfirmationDialogText(device: Device): Partial<ConfirmationDialogProps> {
  if (device && device.damaged_status) {
    return {
      title: 'Are you sure?',
      description: 'Do you really want to replace the current device with the damaged device?'
    };
  }

  return {
    title: 'Are you sure?',
    description: 'Do you want to replace the current device?',
  };
}

const ReplaceDevice: React.FC<ReplaceDeviceProps> = (props) => {
  const {
    inputValue,
    setInputValue,

    device: nextDevice,
    deviceId: nextDeviceId,
    devicePending: nextDevicePending,
  } = useDeviceInputLoader();

  const dispatch = useDispatch();
  const notifyError = (message: string) => dispatch(NotifyError(message));

  const infoCss = useInfoBlockStyles();

  return (
    <>
      <TextField
        className={infoCss.field}
        label="Device ID"
        placeholder="Enter the device ID"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />

      <ConfirmationDialog
        {...(nextDevice && getConfirmationDialogText(nextDevice))}
        renderTrigger={(modal) => (
          <ThemeProvider theme={warningTheme}>
            <Button
              CircularProgressProps={{ color: 'secondary', size: '1.1rem' }}
              className={infoCss.field}
              color="primary"
              pending={nextDevicePending}
              variant="contained"
              onClick={() => {
                if (typeof nextDevice === 'undefined') {
                  return;
                }

                if (nextDevice === null) {
                  notifyError(`Device "${nextDeviceId}" not found`);
                  return;
                }

                if (nextDeviceId === props.deviceId) {
                  notifyError(
                    'It is not possible to replace a device with itself. ' +
                    'You must specify a different device ID'
                  );
                  return;
                }

                if (nextDevice.position_id) {
                  notifyError(`Device "${nextDeviceId}" is already bound to another position`);
                  return;
                }

                modal.open();
              }}
            >
              Replace
            </Button>
          </ThemeProvider>
        )}
        onConfirm={(modal) => {
          modal.setPending(true);

          const params = {
            oldDevice: props.deviceId,
            newDevice: nextDeviceId,
          };

          dispatchAsync(dispatch, replaceDevice(params))
            .then(() => {
              modal.close();
              props.onSuccess?.();
            })
            .catch(() => modal.setPending(false));
        }}
      />
    </>
  );
};

export default ReplaceDevice;
