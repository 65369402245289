import { put, takeEvery, call, select } from 'redux-saga/effects';

import { ReasonEnum } from 'models';
import { RootState } from 'reducers';
import { NotifyError, NotifySuccess } from 'actions/notifier';

import {
  FETCH_PROVISIONING_DEVICES_BY_FILTERS,
  FETCH_PROVISIONING_DEVICES,
  DoFetchProvisioningDevicesFailure,
  DoFetchProvisioningDevicesSuccess,
  FetchProvisioningDevicesByFilters,
  CHANGE_DEVICE_STATUS,
  ADD_DEVICE_TO_BOX,
  ChangeDeviceStatus,
  AddDeviceToBox,
  DoAddDeviceToBoxSuccess,
  DoAddDeviceToBoxFailure,
  DoChangeDeviceStatusSuccess,
  DoChangeDeviceStatusFailure,
  FETCH_PROVISIONING_DEVICE_BY_ID,
  FetchProvisioningDeviceById,
  DoFetchProvisioningDeviceByIdSuccess,
  DoFetchProvisioningDeviceByIdFailure,
  REMOVE_DEVICE_FROM_BOX,
  RemoveDeviceFromBox,
  DoRemoveDeviceFromBoxSuccess,
  DoRemoveDeviceFromBoxFailure
} from 'actions/provisioning';
import {
  ResponseDevices,
  ProvisioningDevicesFiltersLimit,
  ResponseDevice,
  Device
} from 'models/provisioning';
import {
  fetchProvisioningDevices,
  addDeviceToBox,
  updateDeviceStatus,
  fetchProvisioningDeviceById as fetchDeviceById,
  removeDeviceFromBox
} from 'clients/provisioning';
import { ActionWithPromise } from 'utils/store';

function* fetchProvisioningDevicesByState() {
  const state: RootState = yield select();

  yield call(fetchProvisioningDevicesCall, state.provisioning.devices.filters);
}

function* fetchProvisioningDevicesByFilters(action: FetchProvisioningDevicesByFilters) {
  yield call(fetchProvisioningDevicesCall, action.filters);
}

function* fetchProvisioningDevicesCall(filters: ProvisioningDevicesFiltersLimit) {
  const response: ResponseDevices = yield call(fetchProvisioningDevices, filters);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoFetchProvisioningDevicesSuccess(response.data, response.total || 0));
  } else {
    yield put(DoFetchProvisioningDevicesFailure());
    yield put(NotifyError(`Error while fetching devices provisioning: ${response.message}`));
  }
}

function* fetchProvisioningDeviceById(action: FetchProvisioningDeviceById) {
  const response: ResponseDevice = yield call(fetchDeviceById, action.deviceId);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoFetchProvisioningDeviceByIdSuccess(response.data));
  } else {
    yield put(DoFetchProvisioningDeviceByIdFailure());
    yield put(NotifyError(`Error while fetching device provisioning: ${response.message}`));
  }
}

type AddDeviceToBoxAction =
  | AddDeviceToBox
  | ActionWithPromise<AddDeviceToBox, Device>
function* addDeviceToBoxSaga(action: AddDeviceToBoxAction) {
  const response: ResponseDevice = yield call(addDeviceToBox, action.deviceId, action.boxId);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoAddDeviceToBoxSuccess(response.data));
    'meta' in action && action.meta.promise.resolve(response.data);
    yield put(NotifySuccess(`Device has been added to box`));
  } else {
    yield put(DoAddDeviceToBoxFailure());
    yield put(NotifyError(`Error while adding a device to box: ${response.message}`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type RemoveDeviceFromBoxAction = ActionWithPromise<RemoveDeviceFromBox, Device>
function* removeDeviceFromBoxSaga(action: RemoveDeviceFromBoxAction) {
  const response: ResponseDevice = yield call(removeDeviceFromBox, action.deviceId);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoRemoveDeviceFromBoxSuccess(response.data));
    yield put(NotifySuccess(`Device has been removed from box`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(DoRemoveDeviceFromBoxFailure());
    yield put(NotifyError(`Error while removing a device from box: ${response.message}`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type ChangeDeviceStatusAction = ActionWithPromise<ChangeDeviceStatus, Device>
function* changeDeviceStatusSaga(action: ChangeDeviceStatusAction) {
  const response: ResponseDevice = yield call(updateDeviceStatus, action.deviceId, action.status);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoChangeDeviceStatusSuccess(response.data));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(DoChangeDeviceStatusFailure());
    yield put(NotifyError(`Error while updating device status: ${response.message}`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

export const provisioningDevicesSagas = [
  takeEvery(FETCH_PROVISIONING_DEVICES, fetchProvisioningDevicesByState),
  takeEvery(FETCH_PROVISIONING_DEVICES_BY_FILTERS, fetchProvisioningDevicesByFilters),
  takeEvery(ADD_DEVICE_TO_BOX, addDeviceToBoxSaga),
  takeEvery(CHANGE_DEVICE_STATUS, changeDeviceStatusSaga),
  takeEvery(FETCH_PROVISIONING_DEVICE_BY_ID, fetchProvisioningDeviceById),
  takeEvery(REMOVE_DEVICE_FROM_BOX, removeDeviceFromBoxSaga),
];
