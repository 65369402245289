import React from 'react';
import { IntegrationsEnum } from 'models/lorawan';

// components
import { Option, SelectControl } from 'components/Controls/Select';

interface Props {
  selected?: IntegrationsEnum;
  onChange: (value: IntegrationsEnum) => void;
  onlyAvailable?: boolean;
  disabled?: boolean;
}

export const IntegrationSelect = (props: Props): JSX.Element => {
  const { selected, onChange, onlyAvailable, disabled } = props;

  let optionsType: Option<IntegrationsEnum>[] = [
    { value: IntegrationsEnum.TheThingsInd, label: 'The Things Industries' },
    { value: IntegrationsEnum.TheThingsNetV2, label: 'The Things Network v2' },
    { value: IntegrationsEnum.TheThingsNetV3, label: 'The Things Network v3' },
    { value: IntegrationsEnum.WanseyKerlink, label: 'Kerlink Wanesy Management Center' },
  ];

  if (!onlyAvailable) {
    optionsType = optionsType.concat([
      { value: IntegrationsEnum.AWSIoTCore, label: 'AWS IoT Core for LoRaWAN' },
      { value: IntegrationsEnum.ThingParkActivity, label: 'ThingPark by Activity' },
    ]);
  }

  return (
    <SelectControl
      name="integration-type"
      label="Integration Type"
      closeMenuOnSelect
      options={ optionsType }
      onChange={ onChange }
      selected={ selected }
      isDisabled={ disabled }
      isClearable
    />
  );
};
