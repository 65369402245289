import * as Actions from 'actions/device-management/virtual-devices';
import { Repository } from 'utils/repository';
import { FindDevicesParams, PositionedDeviceData } from 'models/device-management';
import { RECEIVED_SUCCESS_BIND_FROM_SPLACE } from 'actions/device-management/devices';

export type State = Repository<PositionedDeviceData, FindDevicesParams, 'device_id'>;
const initState: State = Repository.create('device_id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.DM_VIRTUAL_DEVICE_DATA_LOAD_START:
      return state.setFetched(action.params, false);
    case Actions.DM_VIRTUAL_DEVICE_DATA_LOAD_FAILURE:
      return state.setFetched(action.params, true);
    case Actions.DM_VIRTUAL_DEVICE_DATA_LOAD_SUCCESS:
      return state
        .setFetched(action.params, true)
        .setData(action.params, action.data);
    case RECEIVED_SUCCESS_BIND_FROM_SPLACE as typeof action.type:
      return initState;
    case Actions.DM_VIRTUAL_DEVICE_ACTIVATE_SUCCESS:
      const { deviceId } = action;
      const device = state.findById(deviceId);
      if (device) {
        return state
          .changeModel(deviceId, { ...device, activation_status: true })
          // 1st arg - is object, cause fetched [key] - is always {} (see = useVirtualDevicesParamsSelector)
          .setFetched({}, true);
      }
      return state;
    default:
      return state;
  }
};
