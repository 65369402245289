import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Task as ReduxSagaTask } from '@redux-saga/types';

import * as Actions from 'actions/rabbit';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ApiResponseWithTotal, ReasonEnum } from 'models';
import { History } from 'models/rabbit';
import { getHistory } from 'clients/rabbit';
import { RootState } from 'reducers';
import { autoRunWhenVisible } from 'utils/sagas';
import { setIsNewForSyncDataUpdate } from 'utils/data-normalizers';

export function* fetchHistory(action: Actions.GetHistory, isSync = false) {
  const response: ApiResponseWithTotal<History[]> = yield call(getHistory, action.params);
  if (response.reason === ReasonEnum.Ok && response.data) {

    let newData = response.data;
    const currentData: History[] = yield select((state: RootState) => state.rabbit.history.repository.findAll());
    if (isSync) {
      newData = setIsNewForSyncDataUpdate(newData, currentData, el => el.id);
    }

    yield put(Actions.FetchHistorySuccess(action.params, newData, response.total));
  } else {
    const message = response.message || 'Server error';
    yield put(Actions.FetchHistoryFailure(action.params, message));
    yield put(NotifyError(`Error while fetching Rabbit history: ${ message }`));
  }
}

function* syncHistory() {
  while (true) {
    const startAction: Actions.SyncHistoryStart = yield take(Actions.SYNC_RABBIT_HISTORY_START);
    yield put(NotifySuccess('History synchronization has been started'));

    const syncTask: ReduxSagaTask = yield fork(function* () {
      yield call(autoRunWhenVisible, function* () {
        const state: RootState = yield select();
        const params = { ...state.rabbit.history.params, sendingTimeTo: undefined };
        yield fetchHistory(Actions.FetchHistory(params), true);
      }, (startAction.delay || 1) * 1000);
    });

    const stopAction: Actions.SyncHistoryStop | Actions.GetHistoryFailed = yield take([
      Actions.SYNC_RABBIT_HISTORY_STOP,
      Actions.FETCH_RABBIT_HISTORY_FAILED,
    ]);
    if (stopAction.type === Actions.SYNC_RABBIT_HISTORY_STOP) {
      yield put(NotifySuccess('History synchronization has been stopped'));
    } else if (stopAction.type === Actions.FETCH_RABBIT_HISTORY_FAILED) {
      yield put({ type: Actions.SYNC_RABBIT_HISTORY_STOP });
      yield put(NotifyError('History synchronization has been stopped with error: ' + stopAction.error));
    }

    yield cancel(syncTask);
  }
}

export default [
  takeEvery(Actions.FETCH_RABBIT_HISTORY_START, fetchHistory),
  fork(syncHistory),
];
