import { SagaIterator } from 'redux-saga';
import { put, call, takeLatest, select, all } from 'redux-saga/effects';

import {
  DoGetRawMessagesByQueryFailed,
  DoGetRawMessagesByQuerySuccess,
  FETCH_RAW_MESSAGES_BY_QUERY,
  GetRawMessagesByQuery
} from 'actions/raw-messages';
import { ReasonEnum } from 'models/apiResponse';
import { getData, getTotal } from 'clients/raw-messages';
import { RootState } from 'reducers';
import { NotifyError, NotifyWarning } from 'actions/notifier';
import { RawMessagesFiltersQuery, RawMessages } from 'models';
import { setIsNewForSyncDataUpdate } from 'utils/data-normalizers';

export function* fetchRawMessagesByQuery(action: GetRawMessagesByQuery) {
  yield call(fetchRawMessagesCall, action.query, action.hideNotification, action.isSync);
}

function* fetchRawMessagesCall(filters: RawMessagesFiltersQuery, hideNotification: boolean, isSync?: boolean,): SagaIterator {
  const [rawMessages, totalRawMessages] = yield all([
    call(getData, filters),
    call(getTotal, filters)
  ]);

  const rawMessagesData: { list: RawMessages[]; total: number } = {
    list: [],
    total: 0
  };

  if (rawMessages.reason === ReasonEnum.Ok && rawMessages.data) {
    const currentMessages = yield select((state: RootState) => state.rawMessages.messages);
    rawMessagesData.list = rawMessages.data;
    if (isSync) {
      rawMessagesData.list = setIsNewForSyncDataUpdate(rawMessagesData.list, currentMessages, el => `${el.device_id}-${el.trace_id}`.toUpperCase());
    }
    if (!rawMessages.data.length && !hideNotification) {
      yield put(NotifyWarning('Raw messages not found'));
    }
  } else {
    yield put(DoGetRawMessagesByQueryFailed());
    yield put(NotifyError(`Error while fetching raw messages: ${rawMessages.message || 'Server Error'}`));
  }

  if (totalRawMessages.reason === ReasonEnum.Ok) {
    rawMessagesData.total = totalRawMessages.total;
  } else {
    yield put(NotifyError(`Error while fetching raw messages total: ${totalRawMessages.message || 'Server Error'}`));
  }

  yield put(DoGetRawMessagesByQuerySuccess(rawMessagesData.list, rawMessagesData.total));
}

export const messagesSagas = [
  takeLatest(FETCH_RAW_MESSAGES_BY_QUERY, fetchRawMessagesByQuery),
];

