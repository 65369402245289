import React from 'react';
import moment from 'moment-timezone';
import * as is from 'utils/form/validation/validators';
import { useAuthUserSelector, useFormActionLoader } from 'hooks';
import { DoCreateProject } from 'actions/projects';
import { Project } from 'models/user-management';
import { getProjectPath } from 'routing/paths';
import { isAllowedToCreateUser } from 'utils/permissions';

// components
import { Form } from 'components';
import { SuccessButton } from 'components/Buttons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ProjectForm, FormValues } from '../ProjectForm';

// styles
import { useStyles } from 'styles/forms';
import { useStyles as projectsStyles } from './ProjectsCreateStyles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const CreateProjectDialog = ({ isOpen,  onClose, onCloseEnd }: Props): JSX.Element => {
  const classes = {
    ...useStyles(),
    ...projectsStyles()
  };

  const { doAction } = useFormActionLoader();
  const user = useAuthUserSelector();
  const defaultValues: FormValues = {
    name: '',
    timezone: moment.tz.guess(),
    owner_id: user.isAdmin ? undefined : Number(user.data.user_group),
  };

  const handleSubmit = (values: FormValues) => doAction({
    action: DoCreateProject(values),
    onSuccess: (project: Project) => ({ to: getProjectPath(project.id) })
  });

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      onExited={ onCloseEnd }
      maxWidth={ 'sm' }
      PaperProps={ { className: classes.formDialogPaper } }
      fullWidth
    >
      <DialogTitle>Create Project</DialogTitle>
      <DialogContent className={ classes.formDialogContent }>
        <Form<FormValues>
          defaultValues={ defaultValues }
          validators={ {
            name: is.required(),
            ...(user.isAdmin  && { owner_id: is.required() })
          } }
          onSubmit={ handleSubmit }
        >
          { form => (
            <>
              <ProjectForm isAdmin={ user.isAdmin } allowed={ isAllowedToCreateUser(user.data) } />
              <DialogActions>
                <Button color="inherit" onClick={ onClose }>
                    Cancel
                </Button>
                <SuccessButton
                  label="Add"
                  pending={ form.formState.isSubmitting }
                  disabled={ !form.formState.dirty }
                />
              </DialogActions>
            </>
          ) }
        </Form>
      </DialogContent>
    </Dialog>
  );
};
