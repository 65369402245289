import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) => createStyles({
  dialogRoot: {
    '& [class*="MuiPaper-root"]': {
      overflowY: 'visible',
    },
    '& [class*="MuiDialogContent-root"]': {
      overflowY: 'visible',
    },
  }
}));
