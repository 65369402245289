import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import * as Action from 'actions/rabbit/endpoints';
import * as DataAction from 'actions/rabbit/endpoint-data';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ApiResponse, ApiResponseWithTotal, ReasonEnum } from 'models';
import { Endpoint } from 'models/rabbit';
import { createEndpoint, deleteEndpoint, getEndpoint, getEndpoints, patchEndpoint, getEndpointTest } from 'clients/rabbit';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';

type CreateAction =
  | Action.CreateEndpoint
  | ActionWithPromise<Action.CreateEndpoint, Endpoint>
function* doCreateEndpoint(action: CreateAction) {
  yield put(Action.LockEndpoint(Number(action.endpoint.id)));
  const response: ApiResponse = yield call(createEndpoint, { ...action.endpoint });
  yield put(Action.UnlockEndpoint(Number(action.endpoint.id)));

  if (response.reason === ReasonEnum.Ok) {
    const newEndpoint: Endpoint = response.data;
    yield put(Action.ChangeEndpoint(newEndpoint, { create: true }));
    yield put(NotifySuccess(`RabbitMQ endpoint "${ newEndpoint.name }" has been created`));
    'meta' in action && action.meta.promise.resolve(newEndpoint);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while creating a RabbitMQ endpoint: ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type UpdateAction =
  | Action.UpdateEndpoint
  | ActionWithPromise<Action.UpdateEndpoint, Endpoint>
function* doUpdateEndpoint(action: UpdateAction) {
  yield put(Action.LockEndpoint(Number(action.endpoint.id)));
  const response: ApiResponse = yield call(patchEndpoint, { ...action.endpoint });
  yield put(Action.UnlockEndpoint(Number(action.endpoint.id)));

  if (response.reason === ReasonEnum.Ok) {
    const newEndpoint: Endpoint = response.data;
    yield put(Action.ChangeEndpoint(newEndpoint, { update: true }));
    yield put(NotifySuccess(`RabbitMQ endpoint "${ action.endpoint.name }" has been updated`));
    'meta' in action && action.meta.promise.resolve(newEndpoint);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while updating RabbitMQ endpoint "${ action.endpoint.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type DeleteAction =
  | Action.DeleteEndpoint
  | ActionWithPromise<Action.DeleteEndpoint, Endpoint>
function* doDeleteEndpoint(action: DeleteAction) {
  yield put(Action.LockEndpoint(Number(action.endpoint.id)));
  const response: ApiResponse = yield call(deleteEndpoint, action.endpoint);
  yield put(Action.UnlockEndpoint(Number(action.endpoint.id)));
  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeEndpoint(action.endpoint, { delete: true }));
    yield put(NotifySuccess(`RabbitMQ endpoint "${ action.endpoint.name }" has been deleted`));
    'meta' in action && action.meta.promise.resolve(action.endpoint);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting RabbitMQ endpoint "${ action.endpoint.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

type TestAction =
  | Action.TestEndpoint
  | ActionWithPromise<Action.TestEndpoint, Endpoint>

function* doTestEndpoint(action: TestAction) {
  const response: ApiResponse = yield call(getEndpointTest, action.endpoint);
  if (response.reason === ReasonEnum.Ok) {
    if (response.data.status === 'success') {
      yield put(NotifySuccess(`Test message has been success send to endpoint "${ action.endpoint.name }" `));
    } else {
      yield put(NotifyError(`Fail send test message to endpoint "${ action.endpoint.name }": ${ response.data.message }`));
    }
    'meta' in action && action.meta.promise.resolve(action.endpoint);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while test RabbitMQ endpoint "${ action.endpoint.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(createErrorFromApiResponse(response));
  }
}

function* loadEndpointDataById(action: DataAction.LoadRabbitEndpointDataById) {
  yield put(DataAction.LoadRabbitEndpointDataStart(action.endpointId));
  const response: ApiResponse<Endpoint> = yield call(getEndpoint, action.endpointId);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DataAction.LoadRabbitEndpointDataSuccess(action.endpointId, [response.data], 1));
  } else if (response.reason === ReasonEnum.NotFound) {
    yield put(DataAction.LoadRabbitEndpointDataFailure(action.endpointId, response.message));
  } else {
    const message = response.message || 'Server error';
    yield put(DataAction.LoadRabbitEndpointDataFailure(action.endpointId, message));
    yield put(NotifyError(`Error while fetching RabbitMQ endpoint: ${ message }`));
  }
}

function* loadEndpointDataByParams(action: DataAction.LoadRabbitEndpointDataByParams) {
  yield put(DataAction.LoadRabbitEndpointDataStart(action.params));
  const response: ApiResponseWithTotal = yield call(getEndpoints, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(DataAction.LoadRabbitEndpointDataSuccess(action.params, response.data, response.total));
  } else {
    const message = response.message || 'Server error';
    yield put(DataAction.LoadRabbitEndpointDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching RabbitMQ endpoints: ${ message }`));
  }
}

function* loadEndpointDataDictionary(_action: DataAction.LoadRabbitEndpointDataDictionary) {
  yield put(DataAction.LoadRabbitEndpointDataStart({}));
  const response: ApiResponseWithTotal = yield call(getEndpoints, { limit: 999 });
  if (response.reason === ReasonEnum.Ok) {
    yield put(DataAction.LoadRabbitEndpointDataSuccess({}, response.data, response.total));
  } else {
    const message = response.message || 'Server error';
    yield put(DataAction.LoadRabbitEndpointDataFailure({}, message));
    yield put(NotifyError(`Error while fetching RabbitMQ endpoints: ${ message }`));
  }
}

export default [
  takeEvery(Action.RABBIT_ENDPOINT_CREATE, doCreateEndpoint),
  takeEvery(Action.RABBIT_ENDPOINT_UPDATE, doUpdateEndpoint),
  takeEvery(Action.RABBIT_ENDPOINT_DELETE, doDeleteEndpoint),
  takeEvery(Action.RABBIT_ENDPOINT_TEST, doTestEndpoint),
  takeEvery(DataAction.RABBIT_ENDPOINT_DATA_LOAD_BY_ID, loadEndpointDataById),
  takeEvery(DataAction.RABBIT_ENDPOINT_DATA_LOAD_BY_PARAMS, loadEndpointDataByParams),
  takeLeading(DataAction.RABBIT_ENDPOINT_DATA_LOAD_DICTIONARY, loadEndpointDataDictionary),
];
