import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

import { ALL_PROJECTS, User } from 'models/user-management';
import { isAllowedToUpdateUser } from 'utils/permissions';

// components
import { Button, Paper, Typography } from '@material-ui/core';

import { BlockTitle, BlockToolbar } from 'components/Block';
import { FieldSkeleton } from 'components/Skeleton';

import ProjectsList from './ProjectsList';

// styles
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';

interface ProjectsReportProps {
  user?: User;
  onEdit: () => void;
}

const ProjectsReport: React.FC<ProjectsReportProps> = (props) => {
  const editingAllowed = useSelector((state: RootState) => isAllowedToUpdateUser(state.user.data));
  const infoCss = useInfoBlockStyles();
  const { user } = props;

  function renderContent() {
    if (!user) {
      return <FieldSkeleton className={infoCss.field} />;
    }

    const commonProps = {
      'data-testid': 'loaded-projects',
    };

    if (user.projects === ALL_PROJECTS) {
      return (
        <Typography {...commonProps} className={infoCss.field}>
          User has access to <strong>all</strong> projects.
        </Typography>
      );
    }

    if (user.projects.length === 0) {
      return (
        <Typography {...commonProps} className={infoCss.field}>
          User has access to <strong>none</strong> of the projects.
        </Typography>
      );
    }

    return <ProjectsList {...commonProps} entries={user.projects} />;
  }

  return (
    <Paper data-testid="projects-report">
      <BlockToolbar>
        <BlockTitle>
          Projects
        </BlockTitle>

        {editingAllowed && (
          <Button color="inherit" onClick={props.onEdit}>
            Edit
          </Button>
        )}
      </BlockToolbar>

      {renderContent()}
    </Paper>
  );
};

export default ProjectsReport;
