import { ManualForm } from './useManualForm';
import { PresetForm } from './usePresetForm';

export const syncPresetFormWithManualForm = (
  presetForm: PresetForm,
  manualForm: ManualForm,
) => {
  presetForm.onChange?.({
    ...presetForm.state,
    headers: manualForm.state.headers ?? presetForm.state.headers,
    is_active: manualForm.state.is_active ?? presetForm.state.is_active,
    template_suite_id: manualForm.state.template_suite_id ?? presetForm.state.template_suite_id,
    url: manualForm.state.url ?? presetForm.state.url,
  });
};

export const syncManualFormWithPresetForm = (
  manualForm: ManualForm,
  presetForm: PresetForm,
) => {
  manualForm.onChange?.({
    ...manualForm.state,
    headers: presetForm.state.headers ?? manualForm.state.headers,
    is_active: presetForm.state.is_active ?? manualForm.state.is_active,
    template_suite_id: presetForm.state.template_suite_id ?? manualForm.state.template_suite_id,
    url: presetForm.state.url ?? manualForm.state.url,
  });
};