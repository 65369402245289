import React from 'react';
import { MessageType } from 'models/rabbit';
import { useMessageTypesDictionary } from 'utils/dictionary';
// components
import {
  CommonProps,
  MultiProps,
  Option,
  SelectControl,
  SingleProps
} from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (messageType: MessageType) => boolean }

const Component = (props: Props): JSX.Element => {
  const messageTypes = useMessageTypesDictionary();
  const filterCallback = props.filter ?? (() => (true));
  const options: Option<string>[] =
    messageTypes
      .filter(filterCallback)
      .map(messageType => ({
        value: messageType.id,
        label: messageType.name ?? messageType.id,
      }));

  return <SelectControl
    name="messageType"
    label="Message type"
    { ...props }
    options={ options }
    checkSelected={ messageTypes.length > 0 }
  />;
};

export {
  Component as RabbitMessageTypesSelectControl
};
