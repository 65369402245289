import React from 'react';
import { StationReceiverType, StationReceiverTypeTitles } from 'models/base-station';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<StationReceiverType>, 'options'>>
  & (SingleProps<StationReceiverType> | MultiProps<StationReceiverType>)
  & { filter?: (receiverType: StationReceiverType) => boolean }

export const StationReceiverTypeSelectControl = (props: Props): JSX.Element => {
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<StationReceiverType>[] = [];

  StationReceiverTypeTitles.forEach((title, receiver) => {
    if (filterCallback(receiver)) {
      options.push({
        value: receiver,
        label: title,
      });
    }
  });
  return <SelectControl
    name="receiverType"
    label="Receiver"
    { ...props }
    options={ options }
    checkSelected={ StationReceiverTypeTitles.size > 0 }
    isDisabled={ props.isDisabled || !options.length }
  />;
};
