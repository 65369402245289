import {
  Action,
  FETCH_CONNECTIVITY_BY_FILTERS,
  FETCH_CONNECTIVITY_SUCCESS,
  FETCH_CONNECTIVITY_FAILURE,
  FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP,
  FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_SUCCESS,
  FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_FAILURE,
} from '../actions/device-connectivity';
import { DeviceConnectivity } from 'models/connectivity/deviceConnectivity';
import { ConnectivityFilters, DEFAULT_CONNECITIVITY_FILTERS } from 'models/connectivity/filters';
import { Group } from 'models/device-management';
import { Defined } from 'utils/models';

export interface State {
  connectivity: DeviceConnectivity[];
  readonly filters: Partial<ConnectivityFilters>;
  error?: string;
  isFetching: boolean;

  avgByPositionGroupId: Record<Defined<Group['id']>, number | undefined>;
  avgByPositionGroupIdFetching: boolean;

  readonly devicesConnectivityByDeviceId: {
    readonly [deviceId: string]: DeviceConnectivity | null | undefined;
  };
}

const initState: State = {
  connectivity:[],
  filters: DEFAULT_CONNECITIVITY_FILTERS,
  isFetching: false,

  avgByPositionGroupId: {},
  avgByPositionGroupIdFetching: false,

  devicesConnectivityByDeviceId: {},
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_CONNECTIVITY_BY_FILTERS:
      return { ...state, isFetching: true };
    case FETCH_CONNECTIVITY_FAILURE:
      return { ...state, error: action.error, isFetching: false };
    case FETCH_CONNECTIVITY_SUCCESS:
      return {
        ...state,
        connectivity: action.payload.foundDevicesConnectivity,
        devicesConnectivityByDeviceId: {
          ...state.devicesConnectivityByDeviceId,
          ...action.payload.devicesConnectivityByDeviceId,
        },
        isFetching: false,
      };

    case FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP:
      return { ...state, avgByPositionGroupIdFetching: true };

    case FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_SUCCESS:
      return {
        ...state,
        avgByPositionGroupId: {
          ...state.avgByPositionGroupId,
          ...action.payload.avgConnectivityByPositionGroupId,
        },
        avgByPositionGroupIdFetching: false,
      };

    case FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP_FAILURE:
      return { ...state, avgByPositionGroupIdFetching: false };

    default:
      return state;
  }
};
