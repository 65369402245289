import React from 'react';
import { useFormActionLoader } from 'hooks';
import { DeleteRule } from 'actions/rabbit';
import { Rule } from 'models/rabbit';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  rule: Rule;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DeleteRuleDialog = ({ rule, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const handleDelete = () => doAction({
    action: DeleteRule(rule),
    onSuccess: onClose,
  });

  return (
    <EnsureDialog
      name={ 'delete-rule' }
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
    >
      The route <b>#{ rule.id }</b> will be deleted
    </EnsureDialog>
  );
};
