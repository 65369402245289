import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadStationStatusDataById, LoadStationStatusDataByParams } from 'actions/base-station';

import { GetStatusParam, StationStatusFlat } from 'models/base-station-status';

interface StationStatusState {
  isLoading: boolean;
  stationStatus?: StationStatusFlat;
}

/**
 * Load base station status from storage
 *
 * @param stationId Base station identify
 */
export const useStationStatusSelector = (stationId: number): StationStatusState => {
  const dispatch = useDispatch();

  const { isFetched, stationStatus } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.stationStatus.isFetched(stationId),
    stationStatus: state.baseStation.stationStatus.findById(stationId),
  }), shallowEqual);

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadStationStatusDataById(stationId));
    }
  }, [dispatch, isFetched, stationId]);

  return {
    stationStatus: stationStatus,
    isLoading: isFetched !== true,
  };
};

interface StationStatusParamsState {
  isLoading: boolean;
  stationStatuses: StationStatusFlat[];
}

/**
 * Load base station status from storage by params
 *
 * @param params Base station status params
 */
export const useStationStatusParamsSelector = (params: GetStatusParam): StationStatusParamsState => {
  const dispatch = useDispatch();

  const { isFetched, statuses } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.stationStatus.isFetched(params),
    statuses: state.baseStation.stationStatus.findByParams(params),
  }), shallowEqual);

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadStationStatusDataByParams(params));
    }
  }, [dispatch, isFetched, params]);

  return {
    stationStatuses: statuses,
    isLoading: isFetched !== true,
  };
};


