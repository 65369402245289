import { ApiResponse, ApiResponseWithTotal, ReasonEnum } from 'models';
import { createErrorFromApiResponse } from 'utils/errors';

export function getResponseData<TData>(response: Partial<ApiResponse<TData>>): TData {
  ensureRequestSucceeded(response);

  if (!response.data) {
    throw new Error('No data found');
  }

  return response.data;
}

export function getResponse<TData>(response: ApiResponse<TData>): ApiResponse<TData> {
  ensureRequestSucceeded(response);

  return response;
}

export function getResponseWithTotal<TData>(response: ApiResponseWithTotal<TData>): ApiResponseWithTotal<TData> {
  ensureRequestSucceeded(response);

  return response;
}

interface PotentiallySucceededResponse {
  reason?: ApiResponse['reason'];
}

export function ensureRequestSucceeded(response: PotentiallySucceededResponse) {
  if (response.reason !== ReasonEnum.Ok) {
    throw createErrorFromApiResponse(response);
  }
}
