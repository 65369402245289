import { RawUserSubscriptionType } from 'clients/user-management/project-health-subsciption';
import { User } from 'models/user-management';
import { SubscriptionType, UserWithSubscriptionType } from 'models/user-management/projectHealthSubscriptions';

const normalizeSubscriptionType = (period: string) => {
  switch (period) {
    case 'daily':
      return SubscriptionType.Daily;
    case 'weekly':
      return SubscriptionType.Weekly;
    default:
      return SubscriptionType.Daily;
  }
};

export const normalizeUserSubscriptionsRes = (users: User[], data?: RawUserSubscriptionType[]): UserWithSubscriptionType[] => {
  if (!data) { return []; }
  const usersWithSubscriptionType: UserWithSubscriptionType[] = users.map(user => {
    const subscriptions = data.filter(rawSubscription => rawSubscription.user_id === user.id).map(rawSubscription => ({
      projectId: rawSubscription.project_id,
      ownerId: rawSubscription.owner_id,
      userId: rawSubscription.user_id,
      type: normalizeSubscriptionType(rawSubscription.period),
      subscriptionId: rawSubscription.id,
    }));
    return {
      ...user,
      ProjectSubscriptions: subscriptions.length > 0 ? subscriptions : []
    };
  });

  return usersWithSubscriptionType;
};
