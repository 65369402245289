export interface GetSendingStatusStatisticParams {
  ownerId?: number; // Filter history statistic by owner id. Only for admins
  projectIds?: number[]; // Filter history statistic by project id.
  zoneIds?: number[]; // Filter history statistic by zone id.
  groupIds?: number[]; // Filter history statistic by group id.
  endpointIds?: number[]; // Filter history statistic by template id.
  timeFrom?: Date; // Time range filter mininum. Default a week ago
  timeTill?: Date; // Time range filter maximum
  groupBy?: SendingStatusStatisticGroupByEnum; // Time range filter maximum
}

export interface SendingStatusStatistic {
  timestamp: string;
  date: Date;
  status: SendingStatusEnum;
  count: number;
}

export enum SendingStatusStatisticGroupByEnum {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year'
}

export enum SendingStatusEnum {
  Success = 'Success',
  Failure = 'Failure',
}