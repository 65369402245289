import { put, takeEvery, call, select, take, fork, cancelled, cancel } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';

import {
  GetIncidentsStartFilters,
  DoFetchReportEnd,
  GetIncidentsStart,
  DoGetIncidentsSuccess,
  DoGetIncidentsFailed,
  FetchReport,
  INCIDENTS_START_BACKGROUND_SYNC,
  INCIDENTS_STOP_BACKGROUND_SYNC,
  FETCH_INCIDENTS_START_FILTERS,
  FETCH_INCIDENTS_START,
  INCIDENTS_FETCH_REPORT_CSV
} from 'actions/http-incidents';
import * as Actions from 'actions/http-incidents';
import { ApiResponse, ReasonEnum, IncidentsFiltersState, DevicesStatesFiltersQuery } from 'models';
import {
  fetchIncidentsData,
  fetchIncidentsReport as getIncidentsReport,
  fetchDeviceStates,
  fetchDeviceStatesReport,
  createDamagedIncident
} from 'clients/monitoring';
import { RootState } from 'reducers';
import { downloadFileFromRes } from 'helpers';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import {
  DoGetDevicesStatesSuccess,
  GetDevicesStatesFilterQuery,
  FETCH_DEVICES_STATES_START_FILTERS,
  DoGetDevicesStatesFailed
} from 'actions/devices-states';
import {
  DEVICE_STATES_FETCH_REPORT_CSV,
  FetchReport as DevicesStatesFetchReport,
  DoFetchReportEnd as DevicesStatesDoFetchReportEnd
} from 'actions/devices-states';
import { findDevices } from 'actions/device-management/devices';
import { ResponseDeviceStatesV2ListBody, ResponseIncidentListBody, Incident, DeviceStatesV2 } from 'models/device-monitoring';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import { autoRunWhenVisible } from 'utils/sagas';
import { setIsNewForSyncDataUpdate } from 'utils/data-normalizers';

const SYNC_DELAY_TIMEOUT = 60000;

function* fetchIncidentsByFilters(action: GetIncidentsStartFilters) {
  yield call(fetchIncidentsCall, action.filters);
}

function* fetchIncidentsReport(action: FetchReport) {
  // @TODO migrate to ApiResponse and ResEnums
  const response: { status: number; data: string } = yield call(getIncidentsReport, action.filters);

  yield put(DoFetchReportEnd());

  if (response.status === 200) {
    downloadFileFromRes(response.data, 'monitoring-data.csv');
  } else {
    yield put(NotifyError(`Error while downloading CSV`));
  }
}

type MarkDamagedAction =
  | Actions.CreateDamagedIncident
  | ActionWithPromise<Actions.CreateDamagedIncident, Incident>

function* doCreateDamagedIncident(action: MarkDamagedAction) {
  const response: ApiResponse<Incident> = yield call(createDamagedIncident, action.deviceId, action.reason, action.comment);
  if (response.reason === ReasonEnum.Ok && response.data) {
    const incident = response.data;
    yield put(Actions.CreateDamagedIncidentSuccess(incident));
    yield put(NotifySuccess(`Device #${action.deviceId} has been marked as damaged`));
    'meta' in action && action.meta.promise.resolve(incident);

    // refetching the updated device to update the Redux state
    // instead of updating it in position in order to keep the client thin
    yield put(findDevices({ deviceIds: [action.deviceId] }));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(Actions.CreateDamagedIncidentFailed(error.message));
    yield put(NotifyError(`Error while marking device #${action.deviceId} as damaged: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

function* fetchReportDevicesStates(action: DevicesStatesFetchReport) {
  // @TODO migrate to ApiResponse and ResEnums
  const response: { status: number; data: string } = yield call(fetchDeviceStatesReport, action.filters);

  yield put(DevicesStatesDoFetchReportEnd());

  if (response.status === 200) {
    downloadFileFromRes(response.data, 'devices-states-data.csv');
  } else {
    yield put(NotifyError(`Error while downloading CSV`));
  }
}

export function* fetchIncidentsByState(_action?: GetIncidentsStart, isSync = false) {
  const state: RootState = yield select();

  yield call(fetchIncidentsCall, state.incidents.filters, isSync);
}

function* fetchDevicesStatesByQuery(action: GetDevicesStatesFilterQuery) {
  yield call(fetchDevicesStatesCall, action.query);
}

function* fetchDevicesStatesCall(filters: DevicesStatesFiltersQuery) {
  const response: ResponseDeviceStatesV2ListBody = yield call(fetchDeviceStates, filters);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoGetDevicesStatesSuccess(response.data as DeviceStatesV2[], response.total || 0));
  } else {
    yield put(DoGetDevicesStatesFailed());
    yield put(NotifyError(`Error while fetching devices states: ${response.message}`));
  }
}

function* fetchIncidentsCall(filters: IncidentsFiltersState, isSync = false) {
  const response: ResponseIncidentListBody = yield call(fetchIncidentsData, filters);

  if (response.reason === ReasonEnum.Ok) {
    let foundIncidents = response.data as Incident[];
    const incident: Incident[] = yield select((state: RootState) => state.incidents.data);
    if (isSync) {
      foundIncidents = setIsNewForSyncDataUpdate(foundIncidents, incident, el =>  el.id);
    }
    yield put(DoGetIncidentsSuccess({
      foundIncidents: foundIncidents,
      filters,
      total: response.total || 0,
    }));
  } else {
    yield put(DoGetIncidentsFailed());
    yield put(NotifyError(`Error while fetching incidents: ${response.message}`));
  }
}

export function* bgSync(): SagaIterator {
  try {
    yield call(autoRunWhenVisible, function* () {
      yield fetchIncidentsByState(Actions.DoGetIncidentsStart(), true);
    }, SYNC_DELAY_TIMEOUT);
  } finally {
    if (yield cancelled()) {
      yield put(NotifySuccess('Sync has been cancelled!'));
    }
  }
}

export function* sync(): SagaIterator {
  while (yield take(INCIDENTS_START_BACKGROUND_SYNC)) {
    const bgSyncTask = yield fork(bgSync);

    yield take(INCIDENTS_STOP_BACKGROUND_SYNC);
    yield cancel(bgSyncTask);
  }
}

export const incidentsSagas = [
  takeEvery(FETCH_INCIDENTS_START_FILTERS, fetchIncidentsByFilters),
  takeEvery(FETCH_INCIDENTS_START, fetchIncidentsByState),
  takeEvery(FETCH_DEVICES_STATES_START_FILTERS, fetchDevicesStatesByQuery),
  takeEvery(INCIDENTS_FETCH_REPORT_CSV, fetchIncidentsReport),
  takeEvery(DEVICE_STATES_FETCH_REPORT_CSV, fetchReportDevicesStates),
  takeEvery(Actions.CREATE_DAMAGED_INCIDENT_START, doCreateDamagedIncident),
  sync()
];
