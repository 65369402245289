import { useState } from 'react';
import { uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { RootState } from 'reducers';
import { LoadRabbitEndpointDataByParams } from 'actions/rabbit';
import { useLocationItems } from 'hooks/location';
import { useRabbitRuleDictionarySelector } from 'hooks/rabbit';
import { Endpoint, GetEndpointsParams, Rule } from 'models/rabbit';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { FilterState } from 'components/Rabbit/EndpointsFilter/Component';

interface PageData {
  isLoading: boolean;
  data: Endpoint[];
  total: number;
}

type Params =  FilterState & {
  limit: number
  offset: number
}

export const useEndpointsByParams = (filter: Params): PageData => {
  const dispatch = useDispatch();
  const { rules } = useRabbitRuleDictionarySelector();
  const params = buildFilterParams(filter, rules);

  const { isFetched, endpoints, total } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.endpoint.isFetched(params),
    endpoints: state.rabbit.endpoint.findByParams(params),
    total: state.rabbit.endpoint.getTotal(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadRabbitEndpointDataByParams(params));
    }
  }, [params, isFetched, dispatch]);

  return {
    isLoading: isFetched !== true,
    total: total ?? 0,
    data: endpoints,
  };
};


interface PageFilter {
  filter: FilterState;
  onSave: () => void;
  onReset: () => void;

  filterState: FilterState;
  setFilterState: (state: FilterState) => void;
}

const initialState: FilterState = {
  owner: undefined,
  zones: [],
  name: undefined,
  host: undefined,
  vhost: undefined,
  exchange: undefined,
  queue: undefined,
};
export const useFilterByLocation = (): PageFilter => {
  const location = useLocationItems({ updateMethod: 'replace' });
  const currentState = buildState(location.items);

  const [filterState, setFilterState] = useState<FilterState>(() => currentState);

  return {
    filter: currentState,
    filterState,
    setFilterState,
    onSave: () => { location.setItems(filterState); },
    onReset: () => {
      location.setItems(initialState);
      setFilterState(initialState);
    },
  };
};

const buildState = (items: UrlItems): FilterState => {
  const parser = new Parser(items as FilterState);

  return {
    ...initialState,
    owner: parser.asNumber('owner') ?? initialState.owner,
    zones: parser.asNumbers('zones') ?? initialState.zones,
    name: parser.asString('name') ?? initialState.name,
    host: parser.asString('host') ?? initialState.host,
    vhost: parser.asString('vhost') ?? initialState.vhost,
    exchange: parser.asString('exchange') ?? initialState.exchange,
    queue: parser.asString('queue') ?? initialState.queue,
  };
};

const buildFilterParams = (state: Params, rules: Rule[]): GetEndpointsParams => {
  const params: GetEndpointsParams = {
    ownerId: state.owner,
    name: state.name,
    host: state.host,
    vhost: state.vhost,
    exchange: state.exchange,
    queue: state.queue,
    limit: state.limit,
    offset: state.offset,
  };

  if (state.zones.length > 0) {
    const zoneEndpointIds = rules
      .filter(r => r.endpoint_id && r.zone_id && state.zones.includes(r.zone_id))
      .map( r => r.endpoint_id);
    const EMPTY_ZONE_ENDPOINT_ID = -1;
    params.endpointIds = [EMPTY_ZONE_ENDPOINT_ID, ...uniq(zoneEndpointIds) as number[]];
  }
  return  params;
};
