import { useState } from 'react';
import { isEmpty } from 'lodash';
import { checkTimeFromToRelation } from 'utils/datetime';
import { EnumFilterFields, IncidentsFiltersFields, IncidentsFiltersState } from 'models';

interface FilterState {
  isValid: boolean;
  errors: Record<EnumFilterFields, string>;
  state: IncidentsFiltersFields;
  setState: (state: IncidentsFiltersState | ((state: IncidentsFiltersFields) => IncidentsFiltersFields)) => void;
}

export const useFilterState = (filters: IncidentsFiltersState): FilterState => {
  const [state, setState] = useState<IncidentsFiltersFields>(filters);
  const errors = validateState(state);

  return {
    isValid: isEmpty(errors),
    errors,
    state,
    setState,
  };
};

const validateState = (state: IncidentsFiltersFields): Record<EnumFilterFields, string> => {
  const errors: Record<EnumFilterFields, string> = {} as Record<EnumFilterFields, string>;

  const resolvingTimeError = checkTimeFromToRelation(
    state[EnumFilterFields.resolvingTimeTo],
    state[EnumFilterFields.resolvingTimeFrom]
  );
  if (resolvingTimeError) {
    errors[EnumFilterFields.resolvingTimeFrom] = resolvingTimeError;
    errors[EnumFilterFields.resolvingTimeTo] = resolvingTimeError;
  }

  const appearingTimeError = checkTimeFromToRelation(
    state[EnumFilterFields.appearingTimeTo],
    state[EnumFilterFields.appearingTimeFrom]
  );
  if (appearingTimeError) {
    errors[EnumFilterFields.appearingTimeFrom] = appearingTimeError;
    errors[EnumFilterFields.appearingTimeTo] = appearingTimeError;
  }

  return errors;
};