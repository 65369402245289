import { isEqual } from 'lodash';
import moment from 'moment';

import { useLocationItems } from 'hooks/location';
import { MonitoringDateRange, INITIAL_DATE_RANGE } from 'models/base-station-status';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';

enum DateRangeType {
  LAST = 'last',
  EXACT = 'exact',
}

enum DateRangeUnit {
  DAYS = 'days',
}

export function formatDateRange(dateFrom: Date, dateTo: Date): string {
  const momentFrom = moment(dateFrom);
  const momentTo = moment(dateTo);
  let textFrom = momentFrom.format('MMM D, YYYY');
  const textTo = momentTo.format('MMM D, YYYY');
  if (momentFrom.year() === momentTo.year()) {
    textFrom = momentFrom.format('MMM D');
  }

  return `${ textFrom } - ${ textTo }`;
}

interface StationMonitoringFiltersState {
  dateRange: MonitoringDateRange;
}

interface StationMonitoringUrlItems {
  templateIds: 'all' | number[] | undefined;
  dateRangeType: 'last' | 'exact' | undefined;
  dateRangeUnit: 'days' | undefined;
  dateRangeCount: number | undefined;
  dateRangeDateFrom: Date | undefined;
  dateRangeDateTo: Date | undefined;
}

const buildState = (items: UrlItems): StationMonitoringFiltersState => {
  const parser = new Parser(items as StationMonitoringUrlItems);

  let dateRange: MonitoringDateRange;

  if (parser.asEnum('dateRangeType', DateRangeType) === DateRangeType.LAST) {
    dateRange = {
      type: 'last',
      unit: parser.asEnum('dateRangeUnit', DateRangeUnit) ?? INITIAL_DATE_RANGE.unit,
      count: parser.asNumber('dateRangeCount') ?? INITIAL_DATE_RANGE.count,
    };
  } else if (parser.asEnum('dateRangeType', DateRangeType) === DateRangeType.EXACT) {
    const dateFrom = parser.asDate('dateRangeDateFrom');
    const dateTo = parser.asDate('dateRangeDateTo');

    if (dateFrom && dateTo) {
      dateRange = {
        type: 'exact',
        dateFrom,
        dateTo,
      };
    } else {
      dateRange = INITIAL_DATE_RANGE;
    }
  } else {
    dateRange = INITIAL_DATE_RANGE;
  }

  const result: StationMonitoringFiltersState = {
    dateRange,
  };

  return result;
};

const getDateRangeUrlItems = (
  { dateRange }: Partial<StationMonitoringFiltersState>
): Omit<StationMonitoringUrlItems, 'templateIds'> => {
  // указываем все свойства явно как undefined, потому что если пропустить их,
  // они не будут сброшены в URL
  if (!dateRange || isEqual(dateRange, INITIAL_DATE_RANGE)) {
    return {
      dateRangeCount: undefined,
      dateRangeDateFrom: undefined,
      dateRangeDateTo: undefined,
      dateRangeType: undefined,
      dateRangeUnit: undefined,
    };
  }

  return {
    dateRangeType: dateRange.type,
    dateRangeCount: dateRange.type === 'last' ? dateRange.count : undefined,
    dateRangeUnit: dateRange.type === 'last' ? dateRange.unit : undefined,
    dateRangeDateFrom: dateRange.type === 'exact' ? dateRange.dateFrom : undefined,
    dateRangeDateTo: dateRange.type === 'exact' ? dateRange.dateTo : undefined,
  };
};

interface StationMonitoringFilters {
  state: StationMonitoringFiltersState;
  onChange: (nextState: Partial<StationMonitoringFiltersState>) => void;
}

export function useStationMonitoringFilters(): StationMonitoringFilters {
  const location = useLocationItems({ updateMethod: 'replace' });
  const filter = buildState(location.items);

  return {
    state: filter,
    onChange: nextFilters => location.setItems(allItems => ({
      ...allItems,
      ...getDateRangeUrlItems(nextFilters),
    })),
  };
}
