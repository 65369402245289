import React from 'react';
import { useAuthUserSelector } from 'hooks';
import { GetHistoryParams, SendingStatus, INITIAL_HISTORY_PARAMS } from 'models/rabbit';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
// components
import {
  ZoneSelectControl,
  CustomIdSelect,
  DateTimeControl,
  RabbitEndpointsSelectControl,
  RabbitMessageTypesSelectControl,
  TextControl,
  OwnersSingleSelectControl
} from 'components/Controls';
import NetworkIdSingleSelect from 'components/Controls/NetworkIdSelect/SingleSelectComponent';
import { SingleSelectControl } from 'components/Controls/Select';
// styles
import useStyles from 'styles/filters';

export interface FilterState {
  owner?: number;
  zones: number[];
  networkId?: string;
  deviceIds: string[];
  serviceName?: string;
  messageTypes: string[];
  messageTraceId?: string;
  endpointId?: number;
  sendingTimeFrom: Date;
  sendingTimeTo?: Date;
  sendingStatus?: string;
}

const initialParams = INITIAL_HISTORY_PARAMS;

export const buildState = (items: UrlItems): GetHistoryParams => {
  const parser = new Parser(items as GetHistoryParams);
  const result: GetHistoryParams = {
    ...initialParams,
    owner: parser.asNumber('owner') ?? initialParams.owner,
    zones: parser.asNumbers('zones') ?? initialParams.zones,
    groupIds: parser.asNumbers('groupIds') ?? initialParams.groupIds,
    networkIds: parser.asStrings('networkIds') ?? initialParams.networkIds,
    deviceIds: parser.asStrings('deviceIds') ?? initialParams.deviceIds,
    serviceNames: parser.asStrings('serviceNames') ?? initialParams.serviceNames,
    messageTypes: parser.asStrings('messageTypes') ?? initialParams.messageTypes,
    messageTraces: parser.asStrings('messageTraces') ?? initialParams.messageTraces,
    endpointIds: parser.asNumbers('endpointIds') ?? initialParams.endpointIds,
    sendingTimeFrom: parser.asDate('sendingTimeFrom') ?? new Date(initialParams.sendingTimeFrom),
    sendingTimeTo: parser.asDate('sendingTimeTo') ?? initialParams.sendingTimeTo,
    sendingStatus: parser.asEnums('sendingStatus', SendingStatus) ?? initialParams.sendingStatus,
  };

  return result;
};

export const convertParamsToState = (params: GetHistoryParams): FilterState => ({
  owner: params.owner,
  zones: params.zones ? params.zones : [],
  networkId: params.networkIds ? params.networkIds[0] : undefined,
  deviceIds: params.deviceIds ? params.deviceIds : [],
  serviceName: params.serviceNames ? params.serviceNames[0] : undefined,
  messageTypes: params.messageTypes ? params.messageTypes : [],
  messageTraceId: params.messageTraces ? params.messageTraces[0] : undefined,
  endpointId: params.endpointIds ? params.endpointIds[0] : undefined,
  sendingTimeFrom: params.sendingTimeFrom ? params.sendingTimeFrom : new Date(initialParams.sendingTimeFrom),
  sendingTimeTo: params.sendingTimeTo ? params.sendingTimeTo : undefined,
  sendingStatus: params.sendingStatus ? params.sendingStatus[0] : undefined,
});

export const convertStateToParams = (state: FilterState): GetHistoryParams => ({
  owner: state.owner,
  zones: state.zones.length ? state.zones : undefined,
  networkIds: state.networkId ? [state.networkId] : undefined,
  deviceIds: state.deviceIds.length ? state.deviceIds : undefined,
  serviceNames: state.serviceName ? [state.serviceName] : undefined,
  messageTypes: state.messageTypes.length ? state.messageTypes : undefined,
  messageTraces: state.messageTraceId ? [state.messageTraceId] : undefined,
  endpointIds: state.endpointId ? [state.endpointId] : undefined,
  sendingTimeFrom: state.sendingTimeFrom,
  sendingTimeTo: state.sendingTimeTo,
  sendingStatus: state.sendingStatus ? [state.sendingStatus as SendingStatus] : undefined
});

interface Props {
  isAutoUpdate: boolean;
  state: FilterState;
  setState: (filters: FilterState) => void;
}

const HistoryTableFilter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isAdmin } = useAuthUserSelector();
  const { isAutoUpdate, state, setState } = props;

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      { isAdmin && <OwnersSingleSelectControl
        selected={ state.owner }
        changeHandler={ owner => setState({ ...state, owner }) }
      /> }
      <ZoneSelectControl
        isMulti
        isClearable
        selected={ state.zones }
        onChange={ zones => setState({ ...state, zones }) }
        filter={ zone => !isAdmin || !state.owner || zone.owner_id ===  state.owner }
      />
      <NetworkIdSingleSelect
        selected={ state.networkId }
        changeHandler={ (network?: string) => setState({ ...state, networkId: network }) }
      />
      <CustomIdSelect
        selected={ state.deviceIds }
        changeHandler={ (devices: string[]) => setState({ ...state, deviceIds: devices }) }
        name="Device"
        type="hex"
      />
      <RabbitEndpointsSelectControl
        isClearable
        isMulti={ false }
        selected={ state.endpointId }
        onChange={ endpointId => setState({ ...state, endpointId }) }
        filter={ endpoint => !state.owner || state.owner === endpoint.owner_id }
      />
      { isAdmin && <TextControl
        name="serviceName"
        label="Service Name"
        value={ state.serviceName ? state.serviceName : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, serviceName: event.target.value }) }
      /> }
      <RabbitMessageTypesSelectControl
        isMulti
        isClearable
        selected={ state.messageTypes }
        onChange={ messageTypes => setState({ ...state, messageTypes: messageTypes ?? [] }) }
      />
      <TextControl
        name="messageTraceId"
        label="Message Trace ID"
        value={ state.messageTraceId ? state.messageTraceId : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => {
          const value = event.target.value.replace(/[^A-Za-z0-9]+/g, '');
          setState({ ...state, messageTraceId: value });
        } }
      />
      <SingleSelectControl
        name="sendingStatus"
        label="Sending status"
        isClearable
        selected={ state.sendingStatus ? state.sendingStatus : '' }
        values={ [
          { value: SendingStatus.PENDING, label: 'Pending' },
          { value: SendingStatus.SUCCESS, label: 'Success' },
          { value: SendingStatus.FAILURE, label: 'Failure' },
        ] }
        changeHandler={ (value?: string) => setState({ ...state, sendingStatus: value }) }
      />
      <DateTimeControl
        label={ 'Sending time from' }
        value={ state.sendingTimeFrom }
        onChange={ (date?: Date) => setState({ ...state, sendingTimeFrom: date ? date : new Date() }) }
        maxDate={ state.sendingTimeTo }
        hideErrorsFromHelperText
        isClearable
      />
      <DateTimeControl
        label={ 'Sending time to' }
        value={ isAutoUpdate ? undefined : state.sendingTimeTo }
        onChange={ (date?: Date) => setState({ ...state, sendingTimeTo: date }) }
        minDate={ state.sendingTimeFrom }
        isDisabled={ isAutoUpdate }
        isClearable
      />
    </form>
  );
};

export default HistoryTableFilter;
