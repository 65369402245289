import grey from '@material-ui/core/colors/grey';
import { makeStyles } from 'styles/utils';
// import { CSSProperties } from '@material-ui/styles/withStyles';

const useStyles = makeStyles(theme =>
  ({
    toolbar: {
      ...theme.mixins.toolbar,
      width: '130px',
      display: 'block',
      margin: '20px auto 0px'
    },
    title: {
      flexGrow: 1,
    },
    noUnderline: {
      textDecoration: 'none',
    },
    links: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
    },
    noPadding: {
      padding: 0,
    },
    logo: {
      width: '130px',
    },
    menuCategoryName: {
      marginBottom: -theme.spacing(1),
      paddingBottom: 0,
    },
    menuCategoryNameText: {
      marginBottom: 0,
      paddingBottom: 0,
      color: grey[500],
      fontSize: 14,
    },
    menuCategoryNameTextContainer: {
      marginBottom: 0,
      paddingBottom: 0,
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
    topSublist: {
      paddingLeft: theme.spacing(7),
    },
  }),
);

export default useStyles;
