import React from 'react';
import { Endpoint } from 'models/rabbit';
import { useEndpointsDictionary } from 'utils/dictionary';
// components
import {
  CommonProps,
  MultiProps,
  Option,
  SelectControl,
  SingleProps
} from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & { filter?: (endpoint: Endpoint) => boolean }

const Component = (props: Props): JSX.Element => {
  const endpoints = useEndpointsDictionary();
  const filterCallback = props.filter ?? (() => (true));
  const options: Option<number>[] =
    endpoints
      .filter(filterCallback)
      .map(endpoint => ({
        value: Number(endpoint.id),
        label: endpoint.name ?? String(endpoint.id),
      }));

  return <SelectControl
    name="endpointId"
    label="Endpoint"
    { ...props }
    options={ options }
    checkSelected={ endpoints.length > 0 }
  />;
};

export {
  Component as RabbitEndpointsSelectControl
};
