import { PaginationParams } from 'models';

import { DmTreeNodeWithChildren } from '../types';
import {
  createGroupNodes,
  createLevelNodes,
  createPositionNodes
} from './creators';

interface ChildrenNodesData<TParentNode extends DmTreeNodeWithChildren> {
  nodes: TParentNode['children'];
  total: number;
}

export async function fetchChildrenForNode<
  TNode extends DmTreeNodeWithChildren
>(node: TNode, paginationOverrides?: Partial<PaginationParams>): Promise<ChildrenNodesData<TNode>> {
  const filters = {
    ...node.childrenFilters,
    offset: paginationOverrides?.offset ?? 0,
    limit: paginationOverrides?.limit ?? node.childrenLimit,
  };

  switch (node.type) {
    case 'zone': {
      return createLevelNodes(filters);
    }

    case 'level': {
      return createGroupNodes(filters);
    }

    case 'group': {
      return createPositionNodes(filters);
    }
  }
}
