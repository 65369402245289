import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiResponse, ApiGConfig, RawMessagesFiltersQuery } from 'models';
import { errorHandler, formatQuery } from './utils';

const urls = {
  history: '/raw_messages',
  stat: '/raw_messages/statistic',
  total: '/raw_messages/total',
  onlineStatistic: '/raw_messages/statistic/online'
};

export const config: ApiGConfig = {
  name: 'raw-messages',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://rawmsg.prod.api.nwave.io' : 'https://rawmsg.dev.api.nwave.io',
};

function setupQueryParams(params: RawMessagesFiltersQuery) {
  return {
    limit: params.limit,
    offset: params.offset,
    demodulation_time_from: params.timeFrom?.toISOString(),
    demodulation_time_to: params.timeTo?.toISOString(),
    owner_id: params.owner,
    project_id: params.projects,
    group_id: params.groups,
    zone_id: params.zones,
    device_id: params.devices,
    network_id: params.networkId,
    bs_id: params.bsid,
    actuality: params.actuality,
    trace_id: params.traceid,
    protocol: params.protocol,
    unique: params.unique,
  };
}

export const getData = (params: RawMessagesFiltersQuery): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      ...setupQueryParams(params),
    }),
  };

  return API
    .get(config.name, urls.history, body)
    .catch(errorHandler);
};

export const getRawMsgStatistic = (): Promise<ApiResponse> => API
  .get(config.name, urls.stat, {})
  .catch(errorHandler);

export const getTotal = (params: RawMessagesFiltersQuery): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      ...setupQueryParams(params),
    }),
  };

  return API
    .get(config.name, urls.total, body)
    .catch(errorHandler);
};

export const getOnline = (): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.onlineStatistic, {})
    .catch(errorHandler);
};
