import React from 'react';
import { usePositionsParamsSelector } from 'hooks/device-management';
import { EnumPositionsFilterFields } from 'models/device-management';

// components
import { PositionDetailLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  positionId: number;
  name?: string;
  noLink?: boolean;
  noLoad?: boolean;
}

export const PositionLabel = (props: Props): JSX.Element => {
  const { positionId, name = '-', noLink = false, noLoad = false } = props;

  const state = usePositionsParamsSelector({
    params: { [EnumPositionsFilterFields.positions]: [positionId], limit: 1 },
    skip: noLoad
  });

  if (!noLoad && state.isLoading) {
    return (
      <LoaderSvg/>
    );
  }

  const position = state.positions.find(position => position.id === positionId);
  if (!position) {
    return (
      <>{ name }</>
    );
  }

  if (noLink) {
    return (
      <>{ position.network_id }</>
    );
  }

  return (
    <PositionDetailLink
      zoneId={ position.zone_id }
      levelId={ position.level_id ?? 0 }
      groupId={ position.group_id }
      positionId={ position.id }
      withReturnUrl
    >
      { position.network_id }
    </PositionDetailLink>
  );
};
