import { StationLocation } from 'models/base-station';
import { styled } from 'styles/utils';
// components
import { StaticMap } from 'components/Map';

export const FullSizeMap = styled(StaticMap)(() => ({
  width: '100%',
  height: '100%',
}));

interface Props {
  stationLocation: StationLocation;
  markerColor?: string;
}

export const MapLocation = ({ stationLocation, markerColor }: Props): JSX.Element => {
  return (
    <FullSizeMap
      title={ `${ stationLocation.address } ${ stationLocation.city } ${ stationLocation.country }` }
      disableAdministrativeElement
      disablePositionElement
      disableRoadIconElement
      disableTransitElement
      width={ 600 }
      heigth={ 100 }
      scale={ 2 }
      markers={ [
        {
          size: 'tiny',
          color: markerColor,
          location: {
            lat: stationLocation.lat ?? 0,
            lon: stationLocation.lon ?? 0
          }
        }
      ] }
    />
  );
};
