import { useState } from 'react';
import { sendRegularCarCounter } from 'clients/device-emulation';
import { useFormActionNotifier } from 'hooks';

export const useIncrementCarCounter = () => {
  const { notifySuccess, notifyError } = useFormActionNotifier();
  const [isUpdating, setIsUpdating] = useState(false);
  const incrementCarCounter = async (deviceId: string) => {
    setIsUpdating(true);
    try {
      await sendRegularCarCounter(deviceId, {});

      notifySuccess(`Car Counter was incremented`);
    } catch(e) {
      notifyError(`Error while incrementing Car Counter`);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isUpdating,
    incrementCarCounter
  };
};
