import { call, put, takeEvery } from 'redux-saga/effects';

import * as Action from 'actions/device-emulation/messages';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import * as backend from 'clients/device-emulation';
import { ApiResponse, ReasonEnum } from 'models';
import { createErrorFromApiResponse } from 'utils/errors';
import { ActionWithPromise } from 'utils/store';

type SendRegularAction =
  | Action.SendRegularMessage
  | ActionWithPromise<Action.SendRegularMessage, void>

function* doSendRegularMessage(action: SendRegularAction) {
  try {
    const response: ApiResponse = yield call(backend.sendRegular, action.deviceId, action.payload);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    'meta' in action && action.meta.promise.resolve();
    yield put(NotifySuccess(`Occupancy status change message has been sent`));
  } catch (e) {
    yield put(NotifyError(`Error while sending an occupancy status change message: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject((e as Error));
  }
}

type SendCalibrationAction =
  | Action.SendCalibrationMessage
  | ActionWithPromise<Action.SendCalibrationMessage, void>

function* doSendCalibrationMessage(action: SendCalibrationAction) {
  try {
    const response: ApiResponse = yield call(backend.sendCalibration, action.deviceId, action.payload);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    'meta' in action && action.meta.promise.resolve();
    yield put(NotifySuccess(`Calibration message has been sent`));
  } catch (e) {
    yield put(NotifyError(`Error while sending a calibration message: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject((e as Error));
  }
}

type SendRegistrationAction =
  | Action.SendRegistrationMessage
  | ActionWithPromise<Action.SendRegistrationMessage, void>

function* doSendRegistrationMessage(action: SendRegistrationAction) {
  try {
    const response: ApiResponse = yield call(backend.sendRegistration, action.deviceId, action.payload);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    'meta' in action && action.meta.promise.resolve();
    yield put(NotifySuccess(`User registration message has been sent`));
  } catch (e) {
    yield put(NotifyError(`Error while sending a user registration message: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject(e as Error);
  }
}

type SendRegularCarCounterAction =
  | Action.SendRegularCarCounterMessage
  | ActionWithPromise<Action.SendRegularCarCounterMessage, void>

function* doSendRegularCarCounterMessage(action: SendRegularCarCounterAction) {
  try {
    const response: ApiResponse = yield call(backend.sendRegularCarCounter, action.deviceId, action.payload);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    'meta' in action && action.meta.promise.resolve();
    yield put(NotifySuccess(`Regular car counter message has been sent`));
  } catch (e) {
    yield put(NotifyError(`Error while sending a regular car counter message: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject(e as Error);
  }
}

export default [
  takeEvery(Action.EMULATION_MESSAGES_SEND_REGULAR, doSendRegularMessage),
  takeEvery(Action.EMULATION_MESSAGES_SEND_CALIBRATION, doSendCalibrationMessage),
  takeEvery(Action.EMULATION_MESSAGES_SEND_REGISTRATION, doSendRegistrationMessage),
  takeEvery(Action.EMULATION_MESSAGES_SEND_REGULAR_CAR_COUNTER, doSendRegularCarCounterMessage),
];
