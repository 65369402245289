import React from 'react';
import { FormProps } from 'form';
import { useDeepCompareEffect } from 'react-use';
import { validate } from './validator';
import { FormField, FormState } from './types';

// components
import { OwnersSingleSelectControl, SwitchControl } from 'components/Controls';

export type DeviceFormState = FormState;
export {
  FormField as DeviceFormField
};

type Props = FormProps<FormState, FormField>
export const DeviceForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      { showFields.map(field =>
        <React.Fragment key={ field }>
          { field === FormField.owner && (
            <OwnersSingleSelectControl
              isRequired={ !isView }
              isClearable={ false }
              isDisabled={ isView || disabledFields.includes(FormField.owner) }
              label="Owner"
              placeholder="Choose owner"
              name={ FormField.owner }
              selected={ state[FormField.owner] }
              changeHandler={ (owner?: number) => handleChange(FormField.owner, owner) }
              error={ showErrors.includes(FormField.owner) ? errors[FormField.owner] : undefined }
            />
          ) }
          { field === FormField.active && (
            <SwitchControl
              label="Active"
              name={ FormField.active }
              value={ state[FormField.active] ?? false }
              onChange={ (status?: boolean) => handleChange(FormField.active, status) }
              error={ showErrors.includes(FormField.active) ? errors[FormField.active] : undefined }
            />
          ) }
          { field === FormField.damaged && (
            <SwitchControl
              label="Damaged"
              name={ FormField.damaged }
              value={ state[FormField.damaged] ?? false }
              onChange={ (status?: boolean) => handleChange(FormField.damaged, status) }
              error={ showErrors.includes(FormField.damaged) ? errors[FormField.damaged] : undefined }
            />
          ) }
        </React.Fragment>
      ) }
    </form>
  );
};
