import { State as UserState } from 'reducers/user-profile';

export function canViewApiKeyList(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('r'));
}

export function canViewApiKey(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('r'));
}

export function canCreateApiKey(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('c'));
}

export function canEditApiKey(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('u'));
}

export function canDeleteApiKey(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('d'));
}



