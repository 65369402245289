import React, { useState } from 'react';

import { Dialog } from './Dialog';
import { Template } from 'models/caller';
import { DeleteTemplate } from 'actions/caller';
import { getCallerTemplateSuiteDetailPath } from 'routing/paths/caller';
// components
import EnsureDialog from 'components/Dialogs/EnsureDialog';
import { CallerTemplateName } from 'components/Labels';
import { ActionLoader, ActionLoaderProps } from 'components/Loaders';

interface Props {
  open: Dialog;
  onClose: () => void;
  template?: Template;
}

const Component = (props: Props): JSX.Element => {
  const { open, onClose, template } = props;

  const [action, setAction] = useState<ActionLoaderProps>();
  const handleDelete = (template: Template) => setAction({
    action: DeleteTemplate(template),
    onSuccess: () => ({
      push: true,
      to: getCallerTemplateSuiteDetailPath(String(template.template_suite_id))
    }),
  });

  return (
    <>
      { action ? <ActionLoader { ...action }/> : '' }
      { !(template && open === Dialog.DELETE) ? '' :
        <EnsureDialog
          name={ 'delete-template' }
          isOpen={ true }
          onClose={ onClose }
          onYes={ () => handleDelete(template) }
        >
          The template <b><CallerTemplateName templateId={ template.template_id }/></b> will be deleted
        </EnsureDialog>
      }
    </>
  );
};

export default Component;
