import { MuiThemeProvider } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useEffectOnce } from 'react-use';
import { DoFetchProvisioningBoxesByFilters, DoFetchShipmentByParams } from 'actions/provisioning';
import { initProvisioningBoxesFilters, initProvisioningShipmentFilters } from 'models/provisioning';
import { NotifyWarning } from 'actions/notifier';
import { commonOptions } from 'utils/tables';

// components
import { SuccessButton } from 'components/Buttons';
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import { TableLoadingLayout } from 'components/Table';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';
import ShipmentName from 'components/Provisioning/Controls/ShipmentName';
import { DevicesCountInBox } from 'components/Provisioning/Controls/DevicesCountInBox';

// styles
import useStyles from 'components/Provisioning/styles';
import { getTableTheme } from 'styles/themes';

interface Props {
  isOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
  setSelectedBox: (box: number) => void;
}
enum TableFields {
  box = 'box',
  shipment = 'shipment',
  devices = 'devices',
  id = 'id'
}

export const AddToBoxModal = ({ isOpen, setDialogOpen, setSelectedBox }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const provisioning = useSelector((state: RootState) => state.provisioning.boxes);
  const shipments = useSelector((state: RootState) => state.provisioning.shipment.shipments);
  const boxes = provisioning.boxes.filter(box => (box.device_count || 0) < 20);

  let selected: number;

  useEffectOnce(() => {
    dispatch(DoFetchProvisioningBoxesByFilters({
      ...initProvisioningBoxesFilters,
      limit: 9999,
    }));
    dispatch(DoFetchShipmentByParams({
      ...initProvisioningShipmentFilters,
      limit: 9999,
    }));
  });

  const handleSubmit = () => {
    if (!selected) {
      dispatch(NotifyWarning('You have to select a box'));
      return false;
    }
    setSelectedBox(selected);
    setDialogOpen(false);
  };

  const getShipmentName = (id: string) => {
    const shipment = shipments.find(s => s.id === id);

    return shipment?.name || 'Not in shipment';
  };

  const columns = [
    {
      name: TableFields.box,
      label: 'Box',
      options: {
        filter: false,
        customBodyRender: (id: number) => `Box ID ${id}`
      },
    },
    {
      name: TableFields.shipment,
      label: 'Shipment',
      options: {
        filter: true,
        customFilterListOptions: {
          render: getShipmentName,
        },
        filterOptions: {
          renderValue: getShipmentName,
        },
        customBodyRender: (id?: string) => id ? <ShipmentName id={ id } /> : '—'
      }
    },
    {
      name: TableFields.devices,
      label: 'Devices',
      options: {
        filter: false,
        customBodyRender: (count: number) => <DevicesCountInBox count={ count } />
      }
    },
    {
      name: TableFields.id,
      options: {
        filter: false,
        display: 'excluded',
      }
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    sortOrder: {
      name: TableFields.box,
      direction: 'asc'
    },
    elevation: 0,
    tableBodyHeight: '500px',
    responsive: 'standard',
    search: true,
    selectableRows: 'single',
    selectableRowsOnClick: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customToolbarSelect: (selectedRows, displayData) => {
      const selectedData = displayData.find(d => d.dataIndex === selectedRows.data[0].dataIndex);
      selected = selectedData?.data[columns.length - 1];
      return '';
    },
  };

  return (
    <>
      <Dialog
        open={ isOpen }
        onClose={ () => setDialogOpen(false) }
        maxWidth={ 'sm' }
        PaperProps={ { className: classes.formDialogPaper } }
        fullWidth
      >
        <DialogContent className={ classes.dialogContentRootWithTable }>
          <MuiThemeProvider theme={ getTableTheme() }>
            <TableLoadingLayout isLoading={ provisioning.isFetching }>
              <MUIDataTable
                title="Boxes"
                data={ boxes.map(box => ({
                  [TableFields.box]: box.id,
                  [TableFields.shipment]: box.shipment_id,
                  [TableFields.devices]: box.device_count,
                  [TableFields.id]: box.id
                })) }
                columns={ columns as MUIDataTableColumn[] }
                options={ options }
              />
            </TableLoadingLayout>
          </MuiThemeProvider>
          <DialogActions>
            <Button color="inherit" onClick={ () => setDialogOpen(false) }>
              Cancel
            </Button>
            <SuccessButton
              label={ `Add` }
              onClick={ handleSubmit }
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
