import moment from 'moment';

import { GetStatParams } from 'actions/device-statistic';
// components
import Grid from '@material-ui/core/Grid';
import { DateControl, GroupedFilters } from 'components/Controls';

interface Props {
  filters: GetStatParams;
  setFilters: (filters: GetStatParams | ((filters: GetStatParams) => GetStatParams)) => void;
}

const DevicesDashboardFilter = (props: Props): JSX.Element => {
  const { filters, setFilters } = props;

  const handleSelectOwner = (owner: number | undefined) => setFilters({ ...filters, owner: Number(owner) || undefined });
  const handleSelectProjects = (projects: number[]) => setFilters(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setFilters(prevState => ({ ...prevState, zones }));
  const uncheckGroups = () => setFilters({ ...filters, groups: [] });
  const handleSelectGroups = (groups: number[]) => setFilters({ ...filters, groups });

  return (
    <form autoComplete="off">
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ filters.owner }
        projects={ filters.projects }
        handleSelectProjects={ handleSelectProjects }
        zones={ filters.zones }
        handleSelectZones={ handleSelectZones }
        uncheckGroups={ uncheckGroups }
        groups={ filters.groups }
        handleSelectGroups={ handleSelectGroups }
        isProjectShow
        isZoneShow
        isGroupsShow
      />
      <Grid>
        <DateControl
          isRequired
          label="Time From"
          value={ filters.timeFrom?.toDate() }
          onChange={ (date?: Date) => setFilters({ ...filters, timeFrom: date ? moment(date) : undefined }) }
          maxDate={ filters.timeTo?.toDate() }
        />
      </Grid>
      <Grid>
        <DateControl
          isRequired
          label="Time To"
          value={ filters.timeTo?.toDate() }
          onChange={ (date?: Date) => setFilters({ ...filters, timeTo: date ? moment(date) : undefined }) }
          minDate={ filters.timeFrom?.toDate() }
        />
      </Grid>
    </form>
  );
};

export default DevicesDashboardFilter;
