import React from 'react';
// components
import { EnsureDialog } from 'components/Dialogs';
import { Box } from 'models/provisioning';

interface Props {
  box: Box;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onConfirm: () => void;
}

export const RemoveFromShipmentDialog = (props: Props): JSX.Element => {
  const { box, isOpen, onClose, onCloseEnd, onConfirm } = props;

  return (
    <EnsureDialog
      name="delete-box-from-shipment"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ onConfirm }
    >
      Are you sure you want to delete Box #<b>{ box.id }</b> from the shipment?
    </EnsureDialog>
  );
};
