import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import { Endpoint, GetEndpointsParams, GetHistoryParams, GetRulesParams, Rule } from 'models/rabbit';
import { errorHandler, formatQuery } from './utils';

const urls = {
  history: '/rmq/history',
  rule: '/rmq/rule',
  ruleId: (ruleId: number) => `/rmq/rule/${ruleId}`,
  endpoint: '/rmq/endpoint',
  endpointId: (endpointId: number) => `/rmq/endpoint/${endpointId}`,
  messageType: '/rmq/message-types',
  statistic: '/rmq/history/statistic',
  endpointTest: '/rmq/endpoint/test',
};

export const config: ApiGConfig = {
  name: 'rabbit',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://rmq.prod.api.nwave.io' : 'https://rmq.dev.api.nwave.io',
};

export const deleteRule = (rule: Rule): Promise<ApiResponse> => {
  const url = urls.rule + `/${ rule.id }`;
  const options = {};

  return API
    .del(config.name, url, options)
    .catch(errorHandler);
};

export const patchRule = (rule: Rule): Promise<ApiResponse> => {
  const url = `${ urls.rule }/${ rule.id }`;
  const options = {
    body: { ...rule }
  };

  return API
    .put(config.name, url, options)
    .catch(errorHandler);
};

export const createRule = (rule: Rule): Promise<ApiResponse> => {
  const options = {
    body: { ...rule }
  };

  return API
    .post(config.name, urls.rule, options)
    .catch(errorHandler);
};

export const getRule = (ruleId: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.ruleId(ruleId), {})
    .catch(errorHandler);
};

export const getRules = (params: GetRulesParams): Promise<ApiResponse> => {
  const options = {
    queryStringParameters: formatQuery({
      owner_id: params.ownerId,
      zone_id: params.zoneId,
      group_id: params.groupId,
      endpoint_id: params.endpointId,
      message_type: params.messageType,
      activity: params.activity,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.rule, options)
    .catch(errorHandler);
};

export const getEndpoint = (endpointId: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.endpointId(endpointId), {})
    .catch(errorHandler);
};

export const getEndpoints = (params: GetEndpointsParams): Promise<ApiResponse> => {
  const options = {
    queryStringParameters: formatQuery({
      endpoint_id: params.endpointIds,
      host: params.host,
      name: params.name,
      port: params.port,
      vhost: params.vhost,
      exchange: params.exchange,
      queue: params.queue,
      login: params.login,
      exchange_durable: params.isExchangeDurable,
      owner_id: params.ownerId,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.endpoint, options)
    .catch(errorHandler);
};

export const deleteEndpoint = (endpoint: Endpoint): Promise<ApiResponse> => {
  const url = urls.endpoint + `/${ endpoint.id }`;
  const options = {};

  return API
    .del(config.name, url, options)
    .catch(errorHandler);
};

export const patchEndpoint = (endpoint: Endpoint): Promise<ApiResponse> => {
  const url = urls.endpoint + `/${ endpoint.id }`;
  const options = {
    body: { ...endpoint }
  };

  return API
    .put(config.name, url, options)
    .catch(errorHandler);
};

export const createEndpoint = (endpoint: Endpoint): Promise<ApiResponse> => {
  const options = {
    body: { ...endpoint }
  };

  return API
    .post(config.name, urls.endpoint, options)
    .catch(errorHandler);
};

export const getHistory = (params: GetHistoryParams): Promise<ApiResponse> => {
  const options = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      sending_time_from: params.sendingTimeFrom?.toISOString(),
      sending_time_to: params.sendingTimeTo?.toISOString(),
      source_device_zone_id: params.zones,
      source_group_id: params.groupIds,
      network_id: params.networkIds,
      source_device_id: params.deviceIds,
      source_service_name: params.serviceNames,
      source_message_type: params.messageTypes,
      source_message_trace_id: params.messageTraces,
      endpoint_id: params.endpointIds,
      sending_status: params.sendingStatus,
      owner_id: params.owner,
    })
  };

  return API
    .get(config.name, urls.history, options)
    .catch(errorHandler);
};

export const getMessageTypes = (): Promise<ApiResponse> => {
  const options = {};

  return API
    .get(config.name, urls.messageType, options)
    .catch(errorHandler);

};

export const getStatistic = (): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.statistic, {})
    .catch(errorHandler);
};

export const getEndpointTest = (endpoint: Endpoint): Promise<ApiResponse> => {
  const options = {
    body: { ...endpoint }
  };
  return API
    .post(config.name, urls.endpointTest, options)
    .catch(errorHandler);
};