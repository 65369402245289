import { Group } from 'models/device-management';
import { useGroups } from 'hooks/device-management';

// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & {
    zoneIds: number[];
    filter?: (group: Group) => boolean;
  }

export const GroupSelectControl = (props: Props): JSX.Element => {
  const { groups, isLoading } = useGroups({ params: { zones: props.zoneIds }, enabled: props.zoneIds.length > 0 });

  const filterCallback = props.filter ?? (() => (true));

  const options: Option<number>[] =
    groups
      .filter(filterCallback)
      .filter(group => props.zoneIds.includes(group.zone_id))
      .map(group => ({
        value: Number(group.id),
        label: group.name,
      }));

  return (
    <SelectControl
      label="Groups"
      name="groups"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ !isLoading && groups.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
