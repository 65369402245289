// tslint:disable
/**
 * Base Stations List API
 * API for managing Base Stations
 *
 * The version of the OpenAPI document: 2.0.1
 * Contact: ealeshin@nwave.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationMethod {
    Telegram = 'telegram',
    Slack = 'slack',
    Email = 'email'
}

