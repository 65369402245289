import { DmTreeNode, Filters, TopmostEntityType } from '../../types';
import {
  createGroupNodes,
  createPositionNodes,
  createZoneNodes,
  createLevelNodes
} from '../../node/creators';
import { ChildrenData } from '../types';

export async function createTreeTopDown(
  filters: Filters
): Promise<ChildrenData> {
  const nodesData = await createNodes(filters);

  return {
    children: nodesData.nodes,
    childrenTotal: nodesData.total
  };
}

interface NodesData {
  nodes: DmTreeNode[];
  total: number;
}

async function createNodes(filters: Filters): Promise<NodesData> {
  switch (filters.topmostEntityType) {
    case TopmostEntityType.ZONE:
      return createZoneNodes(filters);

    case TopmostEntityType.LEVEL:
      return createLevelNodes(filters);

    case TopmostEntityType.GROUP:
      return createGroupNodes(filters);

    case TopmostEntityType.POSITION:
      return createPositionNodes(filters);

    default:
      throw new Error(
        `Unknown topmost entity type: ${filters.topmostEntityType}`
      );
  }
}
