import { provisioningDevicesSagas } from './devices';
import { provisioningBoxesSagas } from './boxes';
import { provisioningShipmentSagas } from './shipment';
import { provisioningWarrantySagas } from './warranty';

export default [
  ...provisioningDevicesSagas,
  ...provisioningBoxesSagas,
  ...provisioningShipmentSagas,
  ...provisioningWarrantySagas,
];
