import { Helmet } from 'react-helmet-async';
import { useStationSelector, useStationStatusSelector } from 'hooks';
// components
import { Grid } from '@material-ui/core';
import { PageLoader } from 'components/Loaders';
import { BaseStationManagement, BaseStationInfo } from 'components/BaseStation';
import { BaseStationNotFound } from './BaseStationNotFound';
// styles
import { useStyles } from './styles';
import { BaseStation } from 'models/base-station';

interface Props {
  stationId: number;
}

const HelmetComponent = ({ stationId }: { stationId: number }) => (
  <Helmet><title>{ `Base Station #${stationId}` }</title></Helmet>
);

export const BaseStationDetailPage = ({ stationId }: Props): JSX.Element => {
  const classes = useStyles();
  const stationData = useStationSelector(stationId);
  const stationStatusData = useStationStatusSelector(stationId);

  const isLoading = stationData.isLoading || stationStatusData.isLoading;

  if (!stationData.station) {
    return (
      <>
        <HelmetComponent stationId={ stationId } />
        { isLoading ? <PageLoader /> : <BaseStationNotFound stationId={ stationId } /> }
      </>
    );
  }

  const stationWithStatus: BaseStation = {
    ...stationData.station,
    status: stationStatusData.stationStatus
  };

  return (
    <>
      <HelmetComponent stationId={ stationId } />
      <Grid
        className={ classes.root }
        container
        spacing={ 2 }
        data-testid="base-station-page"
        data-station-id={ stationId }
      >
        <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
          <BaseStationInfo station={ stationWithStatus } />
        </Grid>
        <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
          <BaseStationManagement station={ stationWithStatus } />
        </Grid>
      </Grid>
    </>
  );
};
