import { useQuery } from 'react-query';
import { fetchDeviceStates } from 'clients/monitoring';
import { ExtendedIncidentsTypesEnumModel } from 'models/device-monitoring';
import { getResponse } from 'utils/clients';
import { QueryType, Tree } from './types';
import { findGroupNodeForDevice, useErrorNotifier } from './utils';


export const useDeviceStatesResult = (zoneIds: number[], tree: Tree, queryHash: string): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.deviceState],
    queryHash: `deviceMonitoring/deviceStates/${ queryHash }`,
    queryFn: async () => getResponse(await fetchDeviceStates({
      zones: zoneIds,
      incidentTypes: [ExtendedIncidentsTypesEnumModel.LastMessageTimeError],
      limit: 9999,
      offset: 0,
      groups: [],
      devices: [],
      severity: [],
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notify(`Error while fetching device states: ${ (e as Error).message }`),
    enabled: tree.loaded.zone && zoneIds.length > 0
  });

  if (result.isSuccess) {
    tree.loaded.deviceState = true;
    for (const deviceState of result.data?.data ?? []) {
      const groupNode = findGroupNodeForDevice(tree, deviceState.device_id, deviceState.zone_id, undefined, deviceState.group_id);
      if (!groupNode) {
        continue;
      }

      groupNode.deviceStates.total++;
      groupNode.deviceStates.data[deviceState.device_id.toUpperCase()] = deviceState;

      for (const position of Object.values(groupNode.children.items)) {
        if (position.device?.device_id.toUpperCase() === deviceState.device_id.toUpperCase()) {
          position.deviceState = deviceState;
        }
      }
    }
  }

  return tree;
};