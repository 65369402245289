import React from 'react';
import { Link } from 'react-router-dom';
import { Template } from 'models/caller';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getCallerTemplateDetailLocation, getCallerTemplateStatisticLocation } from 'routing/paths';
import { TabsSkeleton } from 'components/Skeleton';

export enum NavigationTab {
  DETAIL,
  STATISTIC,
}

interface Props {
  template?: Template;
  active?: NavigationTab;
}

const Navigation = (props: Props) => {
  const { template, active } = props;
  if (!template) {
    return <TabsSkeleton tabsCount={ 2 }/>;
  }
  return (
    <Tabs value={ active ?? false } indicatorColor="primary" textColor="primary">
      <Tab
        component={ Link }
        to={ getCallerTemplateDetailLocation(template) }
        label="Detail"
        key={ NavigationTab.DETAIL }
        id={ `tab-${ NavigationTab.DETAIL }` }
        aria-controls={ `tabpanel-${ NavigationTab.DETAIL }` }
      />
      <Tab
        component={ Link }
        to={ getCallerTemplateStatisticLocation(template) }
        label="Statistic"
        key={ NavigationTab.STATISTIC }
        id={ `tab-${ NavigationTab.STATISTIC }` }
        aria-controls={ `tabpanel-${ NavigationTab.STATISTIC }` }
      />
    </Tabs>
  );
};

export default Navigation;
