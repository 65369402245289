import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { StationFlashCreate, StationFrequency } from 'models/base-station';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { FormField } from './types';
// components
import { OwnersSingleSelectControl, StationFrequencySelectControl, StationReceiverTypeSelectControl, TextControl } from 'components/Controls';
// styles
import { useStyles } from './styles';

export {
  FormField as StationFlashFormField
};

type Props = FormProps<StationFlashCreate, FormField>
export const StationFlashForm = (props: Props): JSX.Element => {
  const classes = useStyles({});
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends FormField>(
    field: TField,
    value: StationFlashCreate[TField],
  ) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(FormField.id) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.id) }
          label="Station ID"
          name="stationFlash[id]"
          value={ state.id || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.id, Number(event.target.value || 0))
          }
          error={ showErrors.includes(FormField.id) ? errors?.id !== undefined : false }
          helperText={ showErrors.includes(FormField.id) ? errors?.id : undefined }
        />
      }
      { !showFields.includes(FormField.pin) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.id) }
          label="PIN"
          placeholder="Enter PIN"
          name="stationFlash[pin]"
          value={ state.pin || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.pin, String(event.target.value || ''))
          }
          error={ showErrors.includes(FormField.pin) ? errors?.pin !== undefined : false }
          helperText={ showErrors.includes(FormField.pin) ? errors?.pin : 'Enter the PIN on your base station’s screen' }
        />
      }
      { !showFields.includes(FormField.owner) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(FormField.owner) }
          name="stationFlash[owner_id]"
          selected={ state.ownerId }
          error={ showErrors.includes(FormField.owner) && errors?.ownerId }
          changeHandler={ ownerId => handleChange(FormField.owner, ownerId ?? 0) }
        />
      }
      { !showFields.includes(FormField.receiverType) ? '' :
        <StationReceiverTypeSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(FormField.receiverType) }
          name="stationFlash[receiverType]"
          selected={ state.receiverType }
          error={ showErrors.includes(FormField.receiverType) ? errors?.receiverType : undefined }
          onChange={ receiverType => handleChange(FormField.receiverType, receiverType ?? state.receiverType) }
        />
      }
      { !showFields.includes(FormField.frequency) ? '' :
        <StationFrequencySelectControl
          isClearable
          isDisabled={ isView || disabledFields.includes(FormField.frequency) }
          name="stationFlash[frequency]"
          selected={ state.frequency }
          error={ showErrors.includes(FormField.frequency) ? errors?.frequency : undefined }
          // TODO (BNIV-605): consider getting rid of `as` here to reveal `undefined`
          onChange={ frequency => handleChange(FormField.frequency, frequency as StationFrequency) }
        />
      }
    </form>
  );
};

