import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useAuthUserSelector, useDialog } from 'hooks';
import { useFilterByLocation } from './utils/useFilterByLocation';
import { useStationsByFilters } from './utils/useStationsByFilters';
import { StartStationDataSync, StopStationDataSync } from 'actions/base-station';
import { useUnmount } from 'react-use';
// components
import { Box } from '@material-ui/core';
import { StationTable, BaseStationFilter, StationCreateDialog } from 'components/BaseStation';
import { AddButton } from 'components/Buttons';
import { PageLoader } from 'components/Loaders';
import { ViewTypeButton, ViewType } from 'components/Buttons';
import { EmptyStations } from './widgets/EmptyStations';
import { CardViewStations } from './widgets/CardsViewStations';
import { BsSelectedFilters } from './widgets/BsSelectedFilters';
// styles
import useStyles from 'styles/page';

export const BaseStationListPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useAuthUserSelector();
  const filter = useFilterByLocation();
  const data = useStationsByFilters(filter.filter);
  const [viewType, setViewType] = useLocalStorage<ViewType>('station:list:view', ViewType.TABLE);
  const addDialog = useDialog();
  useEffect(() => {
    dispatch(StartStationDataSync());
  }, [dispatch]);
  useUnmount(() => dispatch(StopStationDataSync()));

  return (
    <>
      <Helmet><title>Base Stations</title></Helmet>
      <div className={ classes.toolbar }>
        <BsSelectedFilters filter={ filter } />
        <ViewTypeButton type={ viewType } onChange={ setViewType } />
        <BaseStationFilter
          isLoading={ data.isLoading }
          filters={ filter.filter }
          updateFilters={ filter.updateFilters }
          resetFilters={ filter.resetFilters }
        />
      </div>
      { data.isLoading ? <PageLoader /> : !data.total && !data.isUserHasStations ? <EmptyStations onCreate={ addDialog.open } /> :
        viewType === ViewType.TABLE
          ? <Box className={ classes.table }>
            <StationTable
              isLoading={ data.isLoading }
              data={ data.all }
            />
          </Box>
          : <CardViewStations data={ data.all } isLoading={ data.isLoading } />
      }
      { user.isAdmin && <AddButton label="Add station" onClick={ addDialog.open } /> }
      { addDialog.isMounted && <StationCreateDialog
        isOpen={ addDialog.isOpen }
        onClose={ addDialog.close }
        onCloseEnd={ addDialog.unmount }
      /> }
    </>
  );
};
