import { uniq } from 'lodash';

import { Zone } from 'models/device-management';

import { fetchZones } from '../../api';
import { DmApiFilters, LevelNode, ZoneNode } from '../../types';
import { DEFAULT_CHILDREN_LIMIT } from './shared';
import { createQuery } from './queries';

export interface ZoneNodesData {
  nodes: ZoneNode[];
  total: number;
}

export async function createZoneNodes(
  filters: DmApiFilters,
  levelNodes: LevelNode[] = []
): Promise<ZoneNodesData> {
  const { zones, total } = await fetchZones({
    ...filters,
    ...(levelNodes.length && {
      zones: uniq(levelNodes.map((ln) => ln.zoneId))
    }),
  });
  const zoneNodes = zones.map((zone) =>
    createZoneNode(
      zone,
      levelNodes.flatMap(levelNode => {
        if (levelNode.zoneId === zone.id && levelNode.level?.id) {
          return [levelNode.level.id];
        }

        return [];
      })
    )
  );

  return { nodes: zoneNodes, total };
}

function createZoneNode(zone: Zone, levelIds?: number[]): ZoneNode {
  return {
    id: `zone-${zone.id}`,
    type: 'zone',
    zone,
    zoneLoadStatus: 'success',
    childrenFilters: {
      zones: [zone.id],
      ...(levelIds?.length && { levels: levelIds })
    },
    childrenLimit: levelIds?.length || DEFAULT_CHILDREN_LIMIT,
    children: [],
    childrenLoadStatus: 'idle',
    childrenMoreLoadStatus: 'idle',
    childrenTotal: createQuery(),
    expansionStatus: 'collapsed',
    connectivity: createQuery(),
    totalDevices: createQuery(),
    totalPositions: createQuery(),
    totalDamagedDevices: createQuery(),
    totalSilentDevices: createQuery(),
  };
}
