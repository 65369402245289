import * as Actions from 'actions/caller';
import { History } from 'models/caller';
export interface State {
  isFetching: boolean;
  data: History[];
}

const initState: State = {
  isFetching: false,
  data: []
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.CALLER_HISTORY_DATA_LOAD_BY_PARAMS:
      return { ...state, isFetching: true };
    case Actions.CALLER_HISTORY_DATA_LOAD_BY_PARAMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    case Actions.CALLER_HISTORY_DATA_LOAD_BY_PARAMS_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
