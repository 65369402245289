import React from 'react';
import { dmGroupPath } from 'routing/paths';

// components
import { Link } from '@material-ui/core';
import { CustomLink } from 'components/Links';

interface Props {
  groupId: number;
  groupName: string;
  levelId?: number;
  levelName?: string;
}

export const LevelAndGroupLink = (props: Props): JSX.Element => {
  const { groupId, levelId, groupName, levelName } = props;

  return (
    <Link
      color="secondary"
      component={ CustomLink }
      to={ dmGroupPath(groupId) }
      withReturnUrl
    >
      { levelId ? `${levelName} / ${groupName}` : groupName}
    </Link>
  );
};
