import { CallerHistoryParams, CallerHistoryParamsGroupBy } from './CallerHistoryParams';

export interface CallerResponseCodeHistoryServerParams {
  owner_id?: number;
  project_id?: number[];
  zone_id?: number[];
  group_id?: number[];
  template_suite_id?: number[];
  template_id?: number[];
  time_from?: string;
  time_till?: string;
  group_by?: CallerHistoryParamsGroupBy;
}

export interface CallerResponseCodeHistoryParams extends CallerHistoryParams {
  templateIds: number[];
}

export enum ResponseCode {
  SUCCESS = '2xx',
  REDIRECT = '3xx',
  VALIDATION_ERROR = '4xx',
  SERVER_ERROR = '5xx',
  OTHER = 'other',
}

export interface CallerResponseCodeHistoryEntry {
  date: string;
  responseCode: ResponseCode;
  count: number;
}
