import React from 'react';

import { FormHookResult } from 'utils/form/useForm';

// components
import { FormContext as RHFFormContext } from 'react-hook-form';

interface FormContextProps {
  children: React.ReactNode;
  // We seems not to receive any benefit from specifying the shape of form values here,
  // so it's just an extra type parameter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormHookResult<any>;
}

/**
 * A wrapper around react-hook-form's `FormContext`.
 *
 * TODO: provide a context with validators to indicate whether a field is required
 */
export const FormContext = ({ children, form }: FormContextProps): JSX.Element => {
  return (
    <RHFFormContext {...form}>
      {children}
    </RHFFormContext>
  );
};
