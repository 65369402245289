import React from 'react';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { UserGroup } from 'models/user-management';
// components
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { LoaderSvg } from 'components/Loaders';

interface ConsumerProps {
  userGroup?: UserGroup;
}

interface Props {
  children?: (consumerProps: ConsumerProps) => JSX.Element;
  ownerId?: number;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

const OwnerName = ({ children, ownerId, innerProps }: Props) => {
  const { isLoading, userGroups } = useUserGroupsDictionarySelector();
  const userGroup = userGroups.find(el => el.id === ownerId);
  const userGroupName: string = userGroup ? userGroup.name : 'Not registered client';
  const TypoProps = { ...defaultProps, ...innerProps };

  if (children) {
    return children({ userGroup });
  }

  return (
    isLoading
      ? <LoaderSvg/>
      : <Typography { ...TypoProps }>{ userGroupName }</Typography>
  );
};

export default OwnerName;
