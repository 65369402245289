import React from 'react';

import { DEFAULT_LIMIT } from 'models';
import { MUIDataTableColumnOptions } from 'utils/mui-datatables';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// styles
import { useStyles } from './styles';

interface Props {
  title?: React.ReactNode;
  options?: Partial<MUIDataTableOptions>;
  rows?: number;
  cols?: number;
}

export const TableSkeleton = (props: Props): JSX.Element => {
  const { rows = DEFAULT_LIMIT, cols = 1 } = props;
  const classes = useStyles();

  const columnOptions: MUIDataTableColumnOptions = {
    customHeadLabelRender: () => {
      return <Box mb="5px"><Skeleton variant="rect" className={ classes.headSkeleton } /></Box>;
    },
  };

  const column: MUIDataTableColumn = {
    name: '',
    label: '',
    options: {
      ...columnOptions,
      filter: false,
      sort: false,
      searchable: false,
      empty: true,
      customBodyRender: () => {
        return <Skeleton variant="rect"/>;
      },
      setCellProps: _ => {
        return {
          style: {
            maxWidth: '100vw' // overrides `maxWidth: 20vw` from MUIDataTableBodyCell style
          }
        };
      }
    },
  };

  const options: MUIDataTableOptions = {
    ...commonOptions,
    setTableProps: () => ({ 'data-testid': 'table-skeleton' }),
    elevation: props.options?.elevation ?? commonOptions.elevation,
    tableBodyHeight: props.options?.tableBodyHeight ?? commonOptions.tableBodyHeight,
    pagination: props.options?.pagination ?? commonOptions.pagination,
    customToolbar: props.options?.customToolbar ? () => <Skeleton/> : undefined,
    customFooter: props.options?.customFooter,
    serverSide: props.options?.serverSide ?? commonOptions.serverSide,
    page: props.options?.page ?? commonOptions.page,
    count: props.options?.count ?? commonOptions.count,
    rowsPerPage: props.options?.rowsPerPage ?? commonOptions.rowsPerPage,
    rowsPerPageOptions: props.options?.rowsPerPageOptions ?? commonOptions.rowsPerPageOptions,
  };

  return (
    <MUIDataTable
      title={ props.title ?? null }
      data={ Array.from({ length: rows }) }
      columns={ Array.from({ length: cols }).map(() => column) }
      options={ options }
    />
  );
};
