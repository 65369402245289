import { Router, CreateRouterData } from 'models/caller';

export const CALLER_ROUTER_CREATE = 'CALLER_ROUTER_CREATE';
export const CALLER_ROUTER_UPDATE = 'CALLER_ROUTER_UPDATE';
export const CALLER_ROUTER_DELETE = 'CALLER_ROUTER_DELETE';
export const CALLER_ROUTERS_DELETE = 'CALLER_ROUTERS_DELETE';
export const CALLER_ROUTER_ACTIVATE = 'CALLER_ROUTER_ACTIVATE';
export const CALLER_ROUTER_DEACTIVATE = 'CALLER_ROUTER_DEACTIVATE';
export const CALLER_ROUTER_LOCK = 'CALLER_ROUTER_LOCK';
export const CALLER_ROUTER_UNLOCK = 'CALLER_ROUTER_UNLOCK';
export const CALLER_ROUTER_CHANGE = 'CALLER_ROUTER_CHANGE';

export interface CreateRouter {
  readonly type: typeof CALLER_ROUTER_CREATE;
  router: CreateRouterData;
}

export interface UpdateRouter {
  readonly type: typeof CALLER_ROUTER_UPDATE;
  router: Router;
}

export interface DeleteRouter {
  readonly type: typeof CALLER_ROUTER_DELETE;
  router: Router;
}

export interface DeleteRouters {
  readonly type: typeof CALLER_ROUTERS_DELETE;
  readonly routers: Router[];
}

export interface ActivateRouter {
  readonly type: typeof CALLER_ROUTER_ACTIVATE;
  router: Router;
}

export interface DeactivateRouter {
  readonly type: typeof CALLER_ROUTER_DEACTIVATE;
  router: Router;
}

export interface LockRouter {
  readonly type: typeof CALLER_ROUTER_LOCK;
  routerId: number;
}

export interface UnlockRouter {
  readonly type: typeof CALLER_ROUTER_UNLOCK;
  routerId: number;
}

export interface ChangeRouter {
  readonly type: typeof CALLER_ROUTER_CHANGE;
  router: Router;
  options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export type Action =
  CreateRouter | UpdateRouter | DeleteRouter | DeleteRouters | ChangeRouter |
  LockRouter | UnlockRouter | ActivateRouter | DeactivateRouter

export const CreateRouter: (router: CreateRouterData) => CreateRouter = (router: CreateRouterData) => ({
  type: CALLER_ROUTER_CREATE, router
});

export const UpdateRouter: (router: Router) => UpdateRouter = (router: Router) => ({
  type: CALLER_ROUTER_UPDATE, router
});

export const DeleteRouter: (router: Router) => DeleteRouter = (router: Router) => ({
  type: CALLER_ROUTER_DELETE, router
});

export const DeleteRouters = (routers: Router[]): DeleteRouters => ({
  type: CALLER_ROUTERS_DELETE, routers
});

export const LockRouter: (routerId: number) => LockRouter = (routerId: number) => ({
  type: CALLER_ROUTER_LOCK, routerId
});
export const UnlockRouter: (routerId: number) => UnlockRouter = (routerId: number) => ({
  type: CALLER_ROUTER_UNLOCK, routerId
});

export const ActivateRouter: (router: Router) => ActivateRouter = (router: Router) => ({
  type: CALLER_ROUTER_ACTIVATE, router
});

export const DeactivateRouter: (router: Router) => DeactivateRouter = (router: Router) => ({
  type: CALLER_ROUTER_DEACTIVATE, router
});

export const ChangeRouter = (router: Router, options: ChangeRouter['options']): ChangeRouter => ({
  type: CALLER_ROUTER_CHANGE, router, options
});
