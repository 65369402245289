import { useDispatch } from 'react-redux';
import { NotifySuccess } from 'actions/notifier';
import { PositionState } from 'models/parking';
import { formatFullDuration } from 'utils/datetime';
import { commonOptions } from 'utils/tables';
import { createHiglightingNewRows } from 'utils/mui-datatables';
import { useAuthUserSelector } from 'hooks';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { ZoneLabel, OwnerName, PositionGroupLabel, ProjectName } from 'components/Labels';
import { DeviceLink, PositionLink } from 'components/Links';
import { SyncButton } from 'components/Buttons';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';
import useStyles from 'styles/page';

export enum TableColumn {
  networkId,
  deviceId,
  customId,
  state,
  duration,
  time,
  zoneId,
  groupId,
  projectId,
  ownerId,
}

type DataItem = PositionState;

export interface Props {
  isLoading?: boolean;
  data: DataItem[];
  offset?: number;
  total?: number;
  sync?: {
    handleChangeStatus: () => void;
    syncStatus: boolean;
  };
}

const Component = (props: Props): JSX.Element => {
  const { isLoading, sync, data, total, offset } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin } = useAuthUserSelector();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'network_id',
      label: 'Network ID',
      options: {
        setCellHeaderProps: () => ({ className: classes.nowrap }),
        customBodyRenderLite: dataIndex =>
          <PositionLink
            positionId={ data[dataIndex].position_id }
            positionName={ data[dataIndex].network_id }
          />,
      },
    },
    {
      name: 'device_id',
      label: 'Device ID',
      options: {
        setCellHeaderProps: () => ({ className: classes.nowrap }),
        customBodyRenderLite: dataIndex =>
          <DeviceLink deviceId={ data[dataIndex].device_id }/>,
      },
    },
    {
      name: 'custom_id',
      label: 'Custom ID',
      options: {
        setCellHeaderProps: () => ({ className: classes.nowrap }),
        customBodyRenderLite: dataIndex => data[dataIndex].custom_id ?? '-',
      },
    },
    {
      name: 'occupied',
      label: 'State',
      options: {
        customBodyRenderLite: dataIndex => data[dataIndex].occupied ? 'Occupied' : 'Free',
      },
    },
    {
      name: 'status_time',
      label: 'Duration',
      options: {
        customBodyRenderLite: dataIndex =>
          data[dataIndex].status_time ? formatFullDuration(data[dataIndex].status_time) : '-',
      },
    },
    {
      name: 'status_time',
      label: 'Date & Time',
      options: {
        customBodyRenderLite: dataIndex => {
          const time = data[dataIndex].status_time;
          return time ? new Date(time).toLocaleString() : '-';
        }
      },
    },
    {
      name: 'zone_id',
      label: 'Zone',
      options: {
        customBodyRenderLite: dataIndex => <ZoneLabel zoneId={ data[dataIndex].zone_id }/>,
      },
    },
    {
      name: 'group_id',
      label: 'Group',
      options: {
        customBodyRenderLite: dataIndex => <PositionGroupLabel groupId={ data[dataIndex].group_id }/>,
      },
    },
    {
      name: 'project_id',
      label: 'Project',
      options: {
        customBodyRenderLite: dataIndex => <ProjectName id={ data[dataIndex].project_id }/>,
      },
    },
    {
      name: 'owner_id',
      label: 'Owner',
      options: {
        display: isAdmin ? 'excluded' : 'true',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ data[dataIndex].owner_id }/>,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    ...(sync && {
      customToolbar: () => <SyncButton
        tooltipText={ offset === 0 ? undefined : 'For enable auto-update move to 1st page' }
        disabled={ offset !== 0 }
        handleAutoUpdate={ () => {
          if (offset === 0) {
            if (!sync.syncStatus) {
              dispatch(NotifySuccess(`Synchronization has been started`));
            } else {
              dispatch(NotifySuccess(`Synchronization has been stopped`));
            }
            sync.handleChangeStatus();
          }
        }}
        autoUpdate={ sync.syncStatus }
      />
    }),
    setRowProps: createHiglightingNewRows(data),
    customFooter: () => <PaginationFooter count={ total || 0 }/>,
  };

  return (
    <MuiThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ isLoading }>
        <MUIDataTable
          title={ null }
          data={ data }
          options={ options }
          columns={ columns }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};

export default Component;