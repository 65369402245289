import { call, put, takeEvery } from 'redux-saga/effects';
import { activateService, deactivateService, fetchServicesByGroupId } from 'clients/user-management';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/user-management';
import { NotifyError } from 'actions/notifier';


function* getServicesByGroupId(userGroupId: number) {
  const response: ApiResponse = yield call(fetchServicesByGroupId, userGroupId);

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.DoGetGroupServicesSuccess(userGroupId, response.data));
  } else if (response.reason !== ReasonEnum.NotFound) {
    yield put(Action.DoGetGroupServicesFailed(userGroupId, response.data));
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching services of user group #${ userGroupId }: ${ message }`));
  }
}

function* doGetServicesByGroupId(action: Action.GetGroupServices) {
  yield put(Action.GroupServiceStatusChangeStart(action.userGroupId));
  yield getServicesByGroupId(action.userGroupId);
  yield put(Action.GroupServiceStatusChangeFinish(action.userGroupId));
}

function* doDeactivateService(action: Action.DeactivateGroupService) {
  yield put(Action.GroupServiceStatusChangeStart(action.userGroupId));
  const response: ApiResponse = yield call(deactivateService, action.userGroupId, action.serviceName);

  if (response.reason === ReasonEnum.Ok) {
    yield getServicesByGroupId(action.userGroupId);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deactivating service with groupdId #${ action.userGroupId }: ${ message }`));
  }
  yield put(Action.GroupServiceStatusChangeFinish(action.userGroupId));
}

function* doActivateService(action: Action.DeactivateGroupService) {
  yield put(Action.GroupServiceStatusChangeStart(action.userGroupId));
  const response: ApiResponse = yield call(activateService, action.userGroupId, action.serviceName);

  if (response.reason === ReasonEnum.Ok) {
    yield getServicesByGroupId(action.userGroupId);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while activating service with groupdId #${ action.userGroupId }: ${ message }`));
  }
  yield put(Action.GroupServiceStatusChangeFinish(action.userGroupId));
}


export const userGroupServicesSagas = [
  takeEvery(Action.UM_USER_GROUP_SERVICES_GET, doGetServicesByGroupId),
  takeEvery(Action.UM_USER_GROUP_SERVICE_DEACTIVATE, doDeactivateService),
  takeEvery(Action.UM_USER_GROUP_SERVICE_ACTIVATE, doActivateService),
];
