import React from 'react';
import { RouteConfig, RouteLabelRendererProps, RouteTitle, matchRoutes } from 'react-router-config';
import { match as Match } from 'react-router-dom';

export * from './renderRoutes';

export const RoutesContext = React.createContext<RouteConfig[] | null>(null);

type RouteParams = Record<string, string | undefined>;

export function getParam(name: string, match: Match<Record<string, string | undefined>> | null): string | undefined {
  return (match as Match<RouteParams> | null)?.params[name];
}

function getRouteTitle(matchedRoute: RouteLabelRendererProps): RouteTitle {
  if (typeof matchedRoute.route.title === 'function') {
    return matchedRoute.route.title(matchedRoute);
  }

  return matchedRoute.route.title;
}

export function getCurrentRouteTitle(pathname: string, routes: RouteConfig[]): RouteTitle {
  const branch = matchRoutes(routes, pathname);

  return branch.reduce(
    (title, matchedRoute) => {
      const routeTitle = getRouteTitle(matchedRoute);

      if (typeof routeTitle === 'undefined') {
        return title;
      }

      return routeTitle;
    },
    undefined as RouteTitle,
  );
}

export function isImplementedRoute(route: RouteConfig): boolean {
  return !!(route.main || route.getRouteComponent || route.render);
}

export function renderRouteLabel(matchedRoute: RouteLabelRendererProps): React.ReactNode {
  if (typeof matchedRoute.route.label === 'function') {
    return matchedRoute.route.label(matchedRoute);
  }

  return matchedRoute.route.label;
}
