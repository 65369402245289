import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { ApiKey, User } from 'models/user-management';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { FormField } from './types';
// components
import Box from '@material-ui/core/Box';
import { CopyToClipboardButton } from 'components/Buttons';
import {
  OwnersSingleSelectControl,
  PROJECT_ALL_OPTION_VALUE,
  ProjectSelectControl,
  UserPermissionSelectControl,
  TextControl
} from 'components/Controls';
// styles
import { useStyles } from './styles';

export {
  FormField as ApiKeyFormField
};

type Props = FormProps<ApiKey, FormField>

export const ApiKeyForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends FormField>(field: TField, value: ApiKey[TField]) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(FormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.name) }
          label="Name"
          name="apiKey[name]"
          value={ state.name || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.name, String(event.target.value || ''))
          }
          error={ showErrors.includes(FormField.name) ? errors?.name !== undefined : false }
          helperText={ showErrors.includes(FormField.name) ? errors?.name : undefined }
        />
      }
      { !showFields.includes(FormField.key) ? '' :
        <Box display="flex" alignItems="baseline">
          <TextControl
            fullWidth
            margin="normal"
            required={ !isView }
            disabled={ isView || disabledFields.includes(FormField.key) }
            label="Key"
            name="apiKey[key]"
            value={ state.key || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(FormField.key, String(event.target.value || ''))
            }
            error={ showErrors.includes(FormField.key) ? errors?.key !== undefined : false }
            helperText={ showErrors.includes(FormField.key) ? errors?.key : undefined }
          />
          {
            !isView ? '' :
              <CopyToClipboardButton
                type="icon"
                value={ state.key }
                copied={ `Token ${ state.name } was copied` }
              />
          }
        </Box>
      }
      { !showFields.includes(FormField.owner_id) ? '' :
        <OwnersSingleSelectControl
          label="Owner"
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(FormField.owner_id) }
          name="apiKey[owner_id]"
          selected={ state.owner_id }
          error={ showErrors.includes(FormField.owner_id) && errors?.owner_id }
          // `owner_id as number` is valid as long as `isClearable !== true`
          changeHandler={ owner_id => handleChange(FormField.owner_id, owner_id as number) }
        />
      }
      { !showFields.includes(FormField.permission_id) ? '' :
        <UserPermissionSelectControl
          label="Permission"
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(FormField.permission_id) }
          isMulti={ false }
          name="apiKey[permission_id]"
          selected={ state.permission_id || undefined }
          error={ showErrors.includes(FormField.permission_id) && errors?.permission_id }
          // `permission_id as number` is valid as long as `isClearable !== true`
          onChange={ permission_id => handleChange(FormField.permission_id, permission_id as number) }
          filter={ perm => state.owner_id === perm.owner_id }
        />
      }
      { !showFields.includes(FormField.project_ids) ? '' :
        <ProjectSelectControl
          isMulti
          isClearable
          isPortal
          isDisabled={ isView || disabledFields.includes(FormField.project_ids) }
          withAllOption
          selected={ state.project_ids === 'all' ? [PROJECT_ALL_OPTION_VALUE] : state.project_ids }
          error={ showErrors.includes(FormField.project_ids) && errors?.project_ids }
          onChange={ projectIds => {
            let nextProjects: User['projects'];

            if (state.project_ids === 'all') {
              nextProjects = projectIds.filter(id => id !== PROJECT_ALL_OPTION_VALUE);
            } else if (projectIds.includes(PROJECT_ALL_OPTION_VALUE)) {
              nextProjects = 'all';
            } else {
              nextProjects = projectIds;
            }

            handleChange(FormField.project_ids, nextProjects);
          } }
          filter={ project => state.owner_id === project.owner_id }
        />
      }
    </form>
  );
};

