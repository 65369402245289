import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import {
  EditGroupDurationParkingAnomaly,
  EditGroupParkingIgnoreAnomaly,
  ParkingAnomalyPositionsFilters,
  EditPositionDurationParkingAnomaly,
  EditPositionParkingIgnoreAnomaly,
  EditZoneDurationParkingAnomaly,
  EditZoneParkingIgnoreAnomaly
} from 'models/parking-monitoring';
import { ParkingAnomalyFilters } from 'models/parking-monitoring/parkingAnomaly';
import { errorHandler, formatQuery } from './utils';

const urls = {
  // groups
  getGroupMonitoring: (id: number) => `/monitoring/settings/parking/groups/${id}`,
  getGroups: '/monitoring/settings/parking/groups/',
  createGroupMonitoringDuration: '/monitoring/settings/parking/groups',
  createGroupMonitoringIgnore: '/monitoring/settings/parking/ignore/groups',
  editGroupMonitoringDur: (id: number) => `/monitoring/settings/parking/groups/${id}`,
  editGroupMonitoringIgnore: (id: number) => `/monitoring/settings/parking/ignore/groups/${id}`,
  // positions
  getPositionsMonitoring: '/monitoring/settings/parking/positions/',
  getByPositionIdMonitoring: (id: number) => `/monitoring/settings/parking/positions/${id}`,
  createPositionMonitoringDuration: '/monitoring/settings/parking/positions',
  createPositionMonitoringIgnore: '/monitoring/settings/parking/ignore/positions',
  editPositionMonitoringDur: (id: number) => `/monitoring/settings/parking/positions/${id}`,
  editPositionMonitoringIgnore: (id: number) => `/monitoring/settings/parking/ignore/positions/${id}`,
  // zones
  getZonesMonitoring: '/monitoring/settings/parking/zones/',
  getByZoneIdMonitoring: (id: number) => `/monitoring/settings/parking/zones/${id}`,
  createZoneMonitoringDur: '/monitoring/settings/parking/zones',
  createZoneMonitoringIgnore: '/monitoring/settings/parking/ignore/zones',
  editZoneMonitoringDur: (id: number) => `/monitoring/settings/parking/zones/${id}`,
  editZoneMonitoringIgnore: (id: number) => `/monitoring/settings/parking/ignore/zones/${id}`
};

export const config: ApiGConfig = {
  name: 'MonitoringParkingSettingsApi',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://parkmonset.prod.api.nwave.io' : 'https://parkmonset.dev.api.nwave.io',
};

export const getGroupsMonitoring = (params: ParkingAnomalyFilters): Promise<ApiResponse> => {
  const queryParams = formatQuery({
    availability_max_interval__le: params.availabilityIntervalLe,
    availability_max_interval__ge: params.availabilityIntervalGe,
    occupy_max_interval__le: params.occupyMaxIntervalLe,
    occupy_max_interval__ge: params.occupyMaxIntervalGe,
    ignored: params.ignored,
    owner_id: params.owner,
    project_id: params.projects,
    zone_id: params.zones,
    group_id: params.groups,
  });
  const body = {
    queryStringParameters: queryParams,
  };

  return API
    .get(config.name, urls.getGroups, body)
    .catch(errorHandler);
};

export const getGroupMonitoring = (id: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.getGroupMonitoring(id), {})
    .catch(errorHandler);
};

export const createGroupMonitoringDuration = (params: EditGroupDurationParkingAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      occupy_max_interval: params.occupyMaxInterval ? params.occupyMaxInterval * 3600 : '',
      availability_max_interval: params.availabilityMaxInterval ? params.availabilityMaxInterval * 3600 : '',
      group_id: params.groupId,
    },
    queryStringParameters: formatQuery({
      include_positions: params.includePositions,
    })
  };
  return API
    .post(config.name, urls.createGroupMonitoringDuration, parameters)
    .catch(errorHandler);
};

export const createGroupMonitoringIgnore = (params: EditGroupParkingIgnoreAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      ignored: params.ignored,
      group_id: params.groupId,
    },
    queryStringParameters: formatQuery({
      include_positions: params.includePositions,
    })
  };
  return API
    .post(config.name, urls.createGroupMonitoringIgnore, parameters)
    .catch(errorHandler);
};

export const editGroupMonitoringDuration = (params: EditGroupDurationParkingAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      occupy_max_interval: params.occupyMaxInterval ? params.occupyMaxInterval * 3600 : '',
      availability_max_interval: params.availabilityMaxInterval ? params.availabilityMaxInterval * 3600 : '',
    },
    queryStringParameters: formatQuery({
      include_positions: params.includePositions,
    })
  };
  return API
    .put(config.name, urls.editGroupMonitoringDur(params.groupId), parameters)
    .catch(errorHandler);
};

export const editGroupMonitoringIgnore = (params: EditGroupParkingIgnoreAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      ignored: params.ignored,
    },
    queryStringParameters: formatQuery({
      include_positions: params.includePositions,
    })
  };
  return API
    .put(config.name, urls.editGroupMonitoringIgnore(params.groupId), parameters)
    .catch(errorHandler);
};


// POSITIONS

export const getPositionsMonitoring = (params: ParkingAnomalyPositionsFilters): Promise<ApiResponse> => {
  const queryParams = formatQuery({
    availability_max_interval__le: params.availabilityIntervalLe,
    availability_max_interval__ge: params.availabilityIntervalGe,
    occupy_max_interval__le: params.occupyMaxIntervalLe,
    occupy_max_interval__ge: params.occupyMaxIntervalGe,
    ignored: params.ignored,
    owner_id: params.owner,
    project_id: params.projects,
    zone_id: params.zones,
    group_id: params.groups,
    position_id: params.positions,
  });

  const body = {
    queryStringParameters: queryParams,
  };

  return API
    .get(config.name, urls.getPositionsMonitoring, body)
    .catch(errorHandler);
};

export const getPositionMonitoring = (id: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.getByPositionIdMonitoring(id), {})
    .catch(errorHandler);
};

export const createPositionMonitoringDuration = (params: EditPositionDurationParkingAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      occupy_max_interval: params.occupyMaxInterval ? params.occupyMaxInterval * 3600 : '',
      availability_max_interval: params.availabilityMaxInterval ? params.availabilityMaxInterval * 3600 : '',
      position_id: params.positionId,
    },
  };
  return API
    .post(config.name, urls.createPositionMonitoringDuration, parameters)
    .catch(errorHandler);
};

export const createPositionMonitoringIgnore = (params: EditPositionParkingIgnoreAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      ignored: params.ignored,
      position_id: params.positionId,
    },
  };
  return API
    .post(config.name, urls.createPositionMonitoringIgnore, parameters)
    .catch(errorHandler);
};

export const editPositionMonitoringDuration = (params: EditPositionDurationParkingAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      occupy_max_interval: params.occupyMaxInterval ? params.occupyMaxInterval * 3600 : '',
      availability_max_interval: params.availabilityMaxInterval ? params.availabilityMaxInterval * 3600 : '',
    },
  };
  return API
    .put(config.name, urls.editPositionMonitoringDur(params.positionId), parameters)
    .catch(errorHandler);
};

export const editPositionMonitoringIgnore = (params: EditPositionParkingIgnoreAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      ignored: params.ignored,
    },
  };
  return API
    .put(config.name, urls.editPositionMonitoringIgnore(params.positionId), parameters)
    .catch(errorHandler);
};


// ZONES

export const getZoneMonitoring = (id: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.getByZoneIdMonitoring(id), {})
    .catch(errorHandler);
};

export const createZoneMonitoringDur = (params: EditZoneDurationParkingAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      occupy_max_interval: params.occupyMaxInterval ? params.occupyMaxInterval * 3600 : '',
      availability_max_interval: params.availabilityMaxInterval ? params.availabilityMaxInterval * 3600 : '',
      zone_id: params.zoneId,
    },
    queryStringParameters: formatQuery({
      include_groups: params.includeGroups,
      include_positions: params.includePositions,
    })
  };
  return API
    .post(config.name, urls.createZoneMonitoringDur, parameters)
    .catch(errorHandler);
};

export const createZoneMonitoringIgnore = (params: EditZoneParkingIgnoreAnomaly): Promise<ApiResponse> => {
  const parameters = {
    body: {
      ignored: params.ignored,
      zone_id: params.zoneId,
    },
    queryStringParameters: formatQuery({
      include_groups: params.includeGroups,
      include_positions: params.includePositions,
    })
  };
  return API
    .post(config.name, urls.createZoneMonitoringIgnore, parameters)
    .catch(errorHandler);
};

export const editZoneMonitoringDuration = (params: EditZoneDurationParkingAnomaly): Promise<ApiResponse> => {

  const parameters = {
    body: {
      occupy_max_interval: params.occupyMaxInterval ? params.occupyMaxInterval * 3600 : '',
      availability_max_interval: params.availabilityMaxInterval ? params.availabilityMaxInterval * 3600 : '',
    },
    queryStringParameters: formatQuery({
      include_groups: params.includeGroups,
      include_positions: params.includePositions,
    })
  };

  return API
    .put(config.name, urls.editZoneMonitoringDur(params.zoneId), parameters)
    .catch(errorHandler);
};

export const editZoneMonitoringIgnore = (params: EditZoneParkingIgnoreAnomaly): Promise<ApiResponse> => {

  const parameters = {
    body: {
      ignored: params.ignored,
    },
    queryStringParameters: formatQuery({
      include_groups: params.includeGroups,
      include_positions: params.includePositions,
    })
  };
  return API
    .put(config.name, urls.editZoneMonitoringIgnore(params.zoneId), parameters)
    .catch(errorHandler);
};

