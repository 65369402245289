import { UserGroup } from 'models/user-management';
import { usePagination } from 'hooks';
import { commonOptions } from 'utils/tables';
// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { UserGroupLink } from 'components/Links';
// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';

interface Props {
  isLoading: boolean;
  data: UserGroup[];
}

export const UserGroupTable = (props: Props): JSX.Element => {
  const { page, limit } = usePagination();
  const { isLoading, data } = props;

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Client name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: id => <UserGroupLink userGroupId={ id } />,
      },
    },
    {
      name: 'phone_number',
      label: 'Phone No',
      options: {
        filter: false,
        sort: false,
        customBodyRender: phone => phone || '-',
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        filter: false,
        sort: false,
        customBodyRender: address => address || '-',
      }
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    page,
    rowsPerPage: limit,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  return (
    <MuiThemeProvider theme={ getMuiTheme }>
      <TableLoadingLayout isLoading={ isLoading }>
        <MUIDataTable
          title={ null }
          // filter to remove Unowned client
          data={ data.filter(client => client.id > 0) }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};
