import { useState } from 'react';

interface ModalStateHookAPI {
  open: boolean;

  openModal: () => void;
  closeModal: () => void;
}

export function useModalState(): ModalStateHookAPI {
  const [open, setOpen] = useState(false);

  return {
    open,

    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
  };
}
