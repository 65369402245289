import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  tableRoot: {
    '& [class*="MuiTableCell-root"]': {
      verticalAlign: 'top',
      padding: theme.spacing(2),
    },
  },
}));

export const locationCell = {
  overflow: 'visible'
};

