import { API } from 'aws-amplify';
import { config } from './config';
import { errorHandler, formatQuery } from 'clients/utils';
import { BoxCreateBody, GetBoxesParams, ResponseBox, ResponseBoxes } from 'models/provisioning';
import { ApiResponse } from 'models';

const urls = {
  boxes: '/provisioning/boxes',
  boxesBulk: '/provisioning/boxes/bulk',
  box: (boxId: number) => `/provisioning/boxes/${boxId}`,
  addBoxToShipment: (boxId: number, shipmentId: string) => `/provisioning/boxes/${boxId}/addToShipment/${shipmentId}`,
  removeBoxFromShipment: (boxId: number) => `/provisioning/boxes/${boxId}/removeFromShipment`,
};

export const fetchProvisioningBoxes = (params: GetBoxesParams): Promise<ResponseBoxes> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      device_id: params.device_id,
      box_id: params.box_id,
      shipment_id: params.shipment_id,
      empty: params.empty,
      allowed_for_shipment_only: params.allowed_for_shipment_only,
      owner_id: params.owner
    }),
  };

  return API
    .get(config.name, urls.boxes, body)
    .catch(errorHandler);
};

export const getBoxById = (id: number): Promise<ResponseBox> => {
  return API
    .get(config.name, urls.box(id), {})
    .catch(errorHandler);
};

export const removeBoxById = (id: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.box(id), {})
    .catch(errorHandler);
};

export const updateBoxById = (id: number, data: BoxCreateBody): Promise<ResponseBox> => {
  return API
    .put(config.name, urls.box(id), { body: data })
    .catch(errorHandler);
};

export const addBoxToShipment = (boxId: number, shipmentId: string): Promise<ResponseBox> => {
  return API
    .post(config.name, urls.addBoxToShipment(boxId, shipmentId), {})
    .catch(errorHandler);
};

export const removeBoxFromShipment = (boxId: number): Promise<ResponseBox> => {
  return API
    .post(config.name, urls.removeBoxFromShipment(boxId), {})
    .catch(errorHandler);
};

export const createBox = (box: BoxCreateBody): Promise<ResponseBox> => {
  return API
    .post(config.name, urls.boxes, { body: box })
    .catch(errorHandler);
};
export const createBoxBulk = (box: BoxCreateBody, quantity: number): Promise<ResponseBoxes> => {
  return API
    .post(config.name, urls.boxesBulk, {
      body: box,
      queryStringParameters: { 'quantity': quantity }
    })
    .catch(errorHandler);
};
