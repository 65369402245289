import { dmPositionGroupPath } from 'routing/paths';
import { useGroup } from 'hooks/device-management';

// components
import { Link } from '@material-ui/core';
import { CustomLink } from 'components/Links';

interface PositionGroupLabelProps {
  groupId: number;
  noLink?: boolean;
  name?: string;
  emptyName?: string;
}

export const PositionGroupLabel = (props: PositionGroupLabelProps): JSX.Element => {
  const { groupId, noLink, name, emptyName = '-' } = props;

  const { group, isLoading } = useGroup({ id: groupId });
  if ((!group && !name) || isLoading) {
    return <>{ emptyName }</>;
  }

  return (
    noLink
      ? <>{ name || group?.name }</>
      : <Link
        color="secondary"
        component={ CustomLink }
        to={ dmPositionGroupPath(groupId) }
        withReturnUrl
      >
        { name || group?.name }
      </Link>
  );
};

