import { filterNodesDeepFlatten } from '../base/utils';
import {
  DmTreeNode,
  DmTreeNodeWithChildren,
  TreeNodeWithChildren,
  ZoneNode,
  LevelNode,
  GroupNode,
  PositionNode
} from '../types';

export function isNodeExpandable(node: DmTreeNode): node is DmTreeNodeWithChildren {
  return (
    'expansionStatus' in node &&
    typeof node.childrenTotal.data === 'number' &&
    node.childrenTotal.data > 0
  );
}

export function isNodeAlreadyExpanded(node: DmTreeNode): boolean {
  return (node as TreeNodeWithChildren).expansionStatus === 'expanded';
}

export function getZoneIds(
  nodes: DmTreeNode[],
  isMatching?: (node: ZoneNode) => boolean
): number[] {
  const zoneNodes = getZoneNodes(nodes);
  const filteredZoneNodes = isMatching ? zoneNodes.filter((node) => isMatching(node)) : zoneNodes;

  return filteredZoneNodes.map((node) => node.zone.id);
}

export function getZoneNodes(nodes: DmTreeNode[]): ZoneNode[] {
  const zoneNodes = filterNodesDeepFlatten(
    nodes,
    (z) => z.type === 'zone'
  ) as ZoneNode[];

  return zoneNodes;
}

export function getLevelNodes(nodes: DmTreeNode[]): LevelNode[] {
  const levelNodes = filterNodesDeepFlatten(
    nodes,
    (z) => z.type === 'level'
  ) as LevelNode[];

  return levelNodes;
}

export function getGroupNodes(nodes: DmTreeNode[]): GroupNode[] {
  const groupNodes = filterNodesDeepFlatten(
    nodes,
    (z) => z.type === 'group'
  ) as GroupNode[];

  return groupNodes;
}

export function getPositionNodes(nodes: DmTreeNode[]): PositionNode[] {
  const positionNodes = filterNodesDeepFlatten(
    nodes,
    (z) => z.type === 'position'
  ) as PositionNode[];

  return positionNodes;
}
