import React from 'react';
import { useFormActionLoader } from 'hooks';
import { deleteGroup } from 'actions/device-management/groups';
import { Group } from 'models/device-management';

// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  group: Group;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (group: Group) => void;
}

export const DeleteGroupDialog = ({ group, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const handleConfirm = () => doAction({
    action: deleteGroup(group.id),
    onFinally: onClose,
    onSuccess: onSuccess,
  });

  return (
    <EnsureDialog
      name="dm-group-delete-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
      btnYes="Delete"
      btnNo="Cancel"
      label={ <>Are you sure you want to delete group <b>{ group.name }</b></> }
    >
      The linked positions will be deleted. The linked devices will be unbound.
    </EnsureDialog>
  );
};
