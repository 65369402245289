import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useAuthUserSelector } from 'hooks';

import { NotifyError } from 'actions/notifier';
import { fetchPermissions } from 'actions/user-management/permissions';
import { updateUser } from 'actions/user-management/users';
import { Permissions, User, UserUpdateParams } from 'models/user-management';
import { RootState } from 'reducers';
import { useForm } from 'utils/form';
import { isNil } from 'utils/models';
import { dispatchAsync } from 'utils/store';
import { PermissionsMode } from './shared';

// components
import {
  Button as MuiButton,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { Button, FormContext } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import * as Fields from 'components/Form/Field';
import { FieldSkeleton } from 'components/Skeleton';

import PermissionsEditor from './PermissionsEditor';
import PermissionsIndicator from './PermissionsIndicator';
import PermissionsTable from './PermissionsTable';
import TemplatePermissionsActions from './TemplatePermissionsActions';

// styles
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { makeUtilStyles } from 'styles';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';

const useUtilStyles = makeUtilStyles();

enum Errors {
  REQUIRED = 'Required',
}

interface PermissionsFormValues {
  permissions: UserUpdateParams['props']['permissions'] | null;
}

interface PermissionsFormProps {
  user: User;
  userPermissions: Permissions;
  onEditComplete: () => void;
}

const PermissionsForm: React.FC<PermissionsFormProps> = (props) => {
  const { user, userPermissions } = props;
  const { isAdmin } = useAuthUserSelector();

  const [mode, setMode] = useState(
    userPermissions.template
      ? PermissionsMode.TEMPLATE
      : PermissionsMode.INDIVIDUAL
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const permissions = useSelector((state: RootState) => ({
    byId: state.um.permissionsById,
    loading: state.um.permissionsFetching,
  }), shallowEqual);

  const form = useForm<PermissionsFormValues>({
    defaultValues: user,
  });

  const { setValue: setFormValue } = form;

  const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextMode = e.target.value as PermissionsMode;
    const nextTemplate = nextMode === PermissionsMode.TEMPLATE;

    if (nextTemplate === userPermissions.template) {
      setFormValue('permissions', userPermissions.id, true);
    } else {
      setFormValue('permissions', null);
    }

    setMode(nextMode);
  };

  const infoCss = useInfoBlockStyles();
  const utilCss = useUtilStyles();

  const handleSubmit = form.handleSubmit(values => {
    const { permissions } = values;

    if (isNil(permissions)) {
      if (mode === PermissionsMode.INDIVIDUAL) {
        dispatch(NotifyError('Create individual permissions in OPEN IN EDITOR to grant them'));
      } else {
        dispatch(NotifyError('Select permissions template to grant to the user'));
      }
      throw new Error('Form is invalid');
    }

    const userUpdateProps: UserUpdateParams['props'] = {
      ...user,
      ...values,
      permissions,
    };

    if (!isAdmin) {
      userUpdateProps.role = undefined;
    }

    return dispatchAsync(dispatch, updateUser({ sub: user.sub, props: userUpdateProps }))
      .then(() => {
        props.onEditComplete();
      });
  });

  const selectedPermissionsId = form.watch('permissions');
  const selectedPermissions = isNil(selectedPermissionsId)
    ? undefined
    : permissions.byId[selectedPermissionsId];

  const handlePermissionsEditorSuccess = () => handleSubmit();

  function renderSelectedPermissionsPreview() {
    if (isNil(selectedPermissionsId) && mode === PermissionsMode.TEMPLATE) {
      return (
        <Typography className={infoCss.field}>
          Select permissions template to see the detailed view.
        </Typography>
      );
    }

    if (isNil(selectedPermissionsId)) {
      const permissionsError = form.errors.permissions;

      return (
        <Grid className={infoCss.field} container>
          <PermissionsEditor
            defaultPermissions={{
              name: `${user.id}-individual`,
              template: false,
              owner_id: user.user_group,
            }}
            editedUserGroupId={user.user_group}
            renderTrigger={modal => (
              <div>
                <MuiButton color="primary" variant="contained" onClick={modal.open}>
                  Open in editor
                </MuiButton>

                {permissionsError && (
                  <FormHelperText error>
                    {permissionsError.message === Errors.REQUIRED
                      ? 'You need to configure and save the permissions in the editor first'
                      : permissionsError.message
                    }
                  </FormHelperText>
                )}
              </div>
            )}
            onSuccess={async ({ id }) => {
              form.setValue('permissions', id);
              await handlePermissionsEditorSuccess();
            }}
          />
        </Grid>
      );
    }

    if (selectedPermissions) {
      return (
        <>
          <Grid className={infoCss.field} container justify="flex-end">
            <PermissionsEditor
              editedUserGroupId={user.user_group}
              permissions={selectedPermissions}
              renderTrigger={modal => (
                <MuiButton color="primary" variant="contained" onClick={modal.open}>
                  Open in editor
                </MuiButton>
              )}
              onSuccess={handlePermissionsEditorSuccess}
            />
          </Grid>

          <PermissionsTable
            renderSwitch={({ categoryFieldName, operation }) => (
              <PermissionsIndicator
                granted={selectedPermissions[categoryFieldName]?.includes(operation.key) ?? false}
              />
            )}
          />
        </>
      );
    }

    if (permissions.loading) {
      return <FieldSkeleton className={infoCss.field} />;
    }

    return (
      <Typography className={infoCss.field}>
        Could not load permissions to show the detailed view.
      </Typography>
    );
  }

  return (
    <FormContext form={form}>
      <form onSubmit={handleSubmit}>
        <Paper className={utilCss.marginCollapsingDisabledContainer}>
          <BlockToolbar>
            <BlockTitle>
              Grant permissions
            </BlockTitle>

            <MuiButton
              color="inherit"
              disabled={form.formState.isSubmitting}
              onClick={props.onEditComplete}
            >
              Cancel
            </MuiButton>

            <ThemeProvider theme={successTheme}>
              <Button
                color="primary"
                disabled={!form.formState.dirty}
                pending={form.formState.isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </ThemeProvider>
          </BlockToolbar>

          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <FormGroup className={infoCss.fields}>
                <RadioGroup
                  className={infoCss.field}
                  name="mode"
                  value={mode}
                  onChange={handleModeChange}
                >
                  <FormControlLabel
                    label="Template permissions"
                    value={PermissionsMode.TEMPLATE}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    label="Individual permissions"
                    value={PermissionsMode.INDIVIDUAL}
                    control={<Radio />}
                  />
                </RadioGroup>

                <Grid container className={infoCss.field}>
                  <Grid item xs>
                    <Fields.SelectPermissionsTemplate
                      name="permissions"
                      label="Permissions Template"
                      permissionsMode={mode}
                      filter={ p => p.owner_id === user.user_group }
                    />
                  </Grid>

                  {mode === PermissionsMode.TEMPLATE && (
                    <TemplatePermissionsActions
                      selectedPermissions={selectedPermissions || undefined}
                      renderAction={(action, i) => <Grid key={i} item xs={1}>{action}</Grid>}
                      permissionsCreatorDefaultPermissions={{ template: true }}
                      editedUserGroupId={user.user_group}
                      onPermissionsCreate={({ id }) => {
                        form.setValue('permissions', id);
                        return handlePermissionsEditorSuccess();
                      }}
                    />
                  )}
                </Grid>
              </FormGroup>
            </Grid>
          </Grid>

          {renderSelectedPermissionsPreview()}
        </Paper>
      </form>
    </FormContext>
  );
};

export default PermissionsForm;
