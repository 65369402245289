import { isEqual, keyBy } from 'lodash';
import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { useAutoRunWhenVisible, useCustomMemo } from 'hooks';
import { TemplatesState } from 'hooks/caller';
import { formatDateRange } from 'utils/datetime';

import { TemplateSuiteMonitoringFilters } from './useTemplateMonitoringFilters';
import { getExactDateRangeParams, getRestParams, mapFiltersToParams } from './utils';

// components
import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { FilterPresetButton } from 'components/Buttons';
import { AllSelectControlMui, DateRangeControl } from 'components/Controls';

import { ResponseCodeHistoryChart } from './ResponseCodeHistoryChart';
import { RequestHistoryChart } from './RequestHistoryChart';
import { RequestDeliveryHistoryChart } from './RequestDeliveryHistoryChart';

// styles
import { useStyles } from './styles';

interface CallerTemplateSuiteMonitoringProps {
  templatesState: TemplatesState;
  filters: TemplateSuiteMonitoringFilters['state'];
  onFiltersChange: TemplateSuiteMonitoringFilters['onChange'];
}

enum MonitoringTab {
  REQUESTS = 'requests',
  RESPONSE_CODES = 'responseCodes',
  REQUEST_DELIVERY = 'requestDelivery',
}

export const CallerTemplateSuiteMonitoring = ({
  templatesState,
  filters,
  onFiltersChange,
}: CallerTemplateSuiteMonitoringProps) => {
  const classes = useStyles();

  const templateIds = templatesState.templates.map(template => template.template_id);
  const templateById = useCustomMemo(() => {
    return keyBy(templatesState.templates, 'template_id');
  }, [templatesState.templates], isEqual);

  const [activeTab, setActiveTab] = useState(MonitoringTab.REQUESTS);
  const [params, setParams] = useState(mapFiltersToParams(filters, templateIds));

  useDeepCompareEffect(() => {
    setParams(params => {
      const nextParams = {
        ...params,
        ...getRestParams(filters, templateIds),
      };

      return isEqual(nextParams, params) ? params : nextParams;
    });
  }, [filters, templateIds]);

  useDeepCompareEffect(() => {
    setParams(params => {
      const nextParams = {
        ...params,
        ...getExactDateRangeParams(filters.dateRange),
      };

      return isEqual(nextParams, params) ? params : nextParams;
    });
  }, [filters.dateRange]);

  useAutoRunWhenVisible(() => {
    setParams(params => {
      const nextParams = {
        ...params,
        ...getExactDateRangeParams(filters.dateRange),
      };
      return isEqual(nextParams, params) ? params : nextParams;
    });
  }, 15 * 60 * 1000);

  if (templatesState.isLoading && !templatesState.templates.length) {
    return (
      <Paper>
        <Box p={ 1 }>
          <Box height={ 55 }>
            <Skeleton variant="rect" height="100%" />
          </Box>
          <Box mt={ 1 } height={ 243 }>
            <Skeleton variant="rect" height="100%" />
          </Box>
        </Box>
      </Paper>
    );
  }

  const hasTemplates = Boolean(templateIds.length);

  return (
    <Paper data-testid="template-suite-monitoring">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs
          value={ activeTab }
          onChange={ (_event, value) => setActiveTab(value) }
          classes={ {
            root: classes.tabs,
            flexContainer: classes.tabsFlexContainer,
            indicator: classes.tabIndicator,
          } }
        >
          <Tab className={ classes.tab } label="Requests by template" value={ MonitoringTab.REQUESTS } />
          <Tab className={ classes.tab } label="Response codes" value={ MonitoringTab.RESPONSE_CODES } />
          <Tab className={ classes.tab } label="Request delivery statistic" value={ MonitoringTab.REQUEST_DELIVERY } />
        </Tabs>

        <Box pl={ 1 } pr={ 1 } display="flex" justifyContent="flex-end" alignItems="center">
          <Box className={ classes.filterControl } maxWidth="300px" data-testid="monitoring-template-select">
            <AllSelectControlMui<number>
              name="templates"
              allOptionLabel="All templates"
              options={ templatesState.templates.map(template => {
                return { label: template.name, value: template.template_id };
              }) }
              selected={ filters.templateIds === 'all'
                ? { type: 'all' }
                : { type: 'options', values: filters.templateIds }
              }
              onChange={ nextValue => {
                onFiltersChange(filters => ({
                  ...filters,
                  templateIds: nextValue.type === 'all' ? 'all' : nextValue.values,
                }));
              } }

            />
          </Box>
          <Box data-testid="monitoring-date-range">
            <FilterPresetButton
              label="Last 24 hours"
              icon={ <></> }
              selected={ filters.dateRange.type === 'last' }
              ButtonProps={ { className: classes.filterControl } }
              onClick={ () => {
                onFiltersChange(filters => ({
                  ...filters,
                  dateRange: { type: 'last', unit: 'days', count: 1 },
                }));
              } }

            />
          </Box>
          <DateRangeControl
            timeCustomizationEnabled
            value={ filters.dateRange.type === 'exact'
              ? { dateTimeFrom: filters.dateRange.dateFrom, dateTimeTo: filters.dateRange.dateTo }
              : undefined }
            renderTrigger={ triggerProps => (
              <FilterPresetButton
                { ...triggerProps }
                label={ filters.dateRange.type === 'exact'
                  ? formatDateRange({ ...filters.dateRange, showTime: true })
                  : 'Custom'
                }
                icon={
                  <DateRangeIcon
                    style={ {
                      marginTop: '-2px',
                      marginLeft: '-5px',
                      height: '14px'
                    } }
                  />
                }
                iconPlacement="end"
                selected={ filters.dateRange.type === 'exact' }
                selectedClickable
                ButtonProps={ { ...triggerProps, className: classes.filterControl } }
                onClick={ (_currentTarget, event) => triggerProps.onClick(event) }
              />
            ) }
            onChange={ ({ dateTimeFrom, dateTimeTo }) => {
              if (dateTimeFrom && dateTimeTo) {
                onFiltersChange(filters => ({
                  ...filters,
                  dateRange: { type: 'exact', dateFrom: dateTimeFrom, dateTo: dateTimeTo },
                }));
              } else {
                onFiltersChange(filters => ({ ...filters, dateRange: undefined }));
              }
            } }
          />
        </Box>
      </Box>

      { !hasTemplates && (
        <Box className={ classes.chart } display="flex" alignItems="center" justifyContent="center">
          <Typography>Add templates to see the chart</Typography>
        </Box>
      ) }

      { hasTemplates && activeTab === MonitoringTab.REQUESTS && (
        <RequestHistoryChart filters={ params } templateById={ templateById } />
      ) }
      { hasTemplates && activeTab === MonitoringTab.RESPONSE_CODES && (
        <ResponseCodeHistoryChart filters={ params } templateById={ templateById } />
      ) }
      { hasTemplates && activeTab === MonitoringTab.REQUEST_DELIVERY && (
        <RequestDeliveryHistoryChart filters={ params } templateById={ templateById } />
      ) }
    </Paper>
  );
};
