import { ShipmentDeviceForAws } from 'models/provisioning';
import { useDeleteDeviceFromAws } from 'hooks';
import { useDialog } from 'hooks/dialog';

// copmonents
import { Box, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dangerTheme } from 'styles/themes';
import { EnsureDialog } from 'components/Dialogs';

type Props = {
  provisionedDevices: ShipmentDeviceForAws[];
  isDevicesLoading: boolean;
}

export const DeleteWidget = ({
  provisionedDevices,
  isDevicesLoading,
}: Props) => {
  const { deleteDeviceFormAws, isDeleting, progress } = useDeleteDeviceFromAws();
  const { isOpen, open, close } = useDialog();
  const awsIds = provisionedDevices.map(d => d.aws_id).filter((id): id is string => id !== undefined);
  return (<>
    <MuiThemeProvider theme={dangerTheme}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={isDevicesLoading || provisionedDevices.length === 0 || isDeleting}
        startIcon={ <Delete /> }
        onClick={ open }
      >
        {isDeleting ? `Removing... ${ progress.toFixed(0) } / 100 %` : 'Remove from AWS' }
      </Button>
    </MuiThemeProvider>

    <EnsureDialog
      name="AwsDeleteAlertationDialog"
      testId="filters-block"
      isOpen={ isOpen }
      btnNo="Cancel"
      btnYes="Remove"
      onYes={ async () => { await deleteDeviceFormAws(awsIds); } }
      onNo={ close }
      onClose={ close }
      label="Remove devices to AWS"
    >
      <Box>
        {provisionedDevices.length} devices will be removed from AWS. Proceed?
      </Box>
    </EnsureDialog>
  </>);
};
