import React from 'react';

import { DeleteRouters } from 'actions/caller';
import { useFormActionLoader } from 'hooks';
import { Router } from 'models/caller';

// components
import { EnsureDialog } from 'components/Dialogs';

interface DisconnectZoneDialogProps {
  routes: Router[];
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: () => void;
}

export const DisconnectZoneDialog = (props: DisconnectZoneDialogProps) => {
  const { routes, isOpen, onClose, onCloseEnd, onSuccess } = props;
  const { doAction } = useFormActionLoader();

  const handleConfirm = () => doAction({
    action: DeleteRouters(routes),
    onSuccess: onSuccess,
    onFinally: onClose,
  });

  return (
    <EnsureDialog
      name="disconnect-zone-from-template-suite"
      label="Disconnect zone from template suite"
      btnNo="Cancel"
      btnYes="Disconnect"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      Are you sure?
    </EnsureDialog>
  );
};
