import React from 'react';
import { UserGroup } from 'models/user-management';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  userGroup: UserGroup;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onConfirm: () => void;
}

export const RefreshAccessCodeDialog = (props: Props): JSX.Element => {
  const { userGroup, isOpen, onClose, onCloseEnd, onConfirm } = props;
  return (
    <EnsureDialog
      name="refresh-user-group-access-code"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ onConfirm }
    >
      The client <b>{ userGroup.name }</b>'s access code will be refreshed
    </EnsureDialog>
  );
};
