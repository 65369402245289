import { TemplateSuite, Template, GetTemplateSuitesParams, Router } from 'models/caller';

export const ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS = 'caller/routes_templates_suites/LOAD_BY_PARAMS';
export const ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS_STOP = 'caller/routes_templates_suites/LOAD_BY_PARAMS_STOP';
export const ROUTES_TEMPLATE_DELETE = 'caller/routes_templates_suites/DELETE';

export interface RoutesTemplatesSuitesLoad {
  readonly type: typeof ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS;
  readonly params: GetTemplateSuitesParams;
}

export interface RoutesTemplatesSuitesLoadStop {
  readonly type: typeof ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS_STOP;
  readonly suites: TemplateSuite[];
  readonly routes: Router[];
  readonly templates: Template[];
}

export interface RoutesTemplateDelete {
  readonly type: typeof ROUTES_TEMPLATE_DELETE;
  readonly templateSuite: TemplateSuite;
  readonly routes: Router[];
}

export const DoRoutesTemplatesSuitesLoad = (params: GetTemplateSuitesParams): RoutesTemplatesSuitesLoad => ({
  type: ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS, params
});
export const DoRoutesTemplatesSuitesLoadStop = (
  suites: TemplateSuite[] = [],
  routes: Router[] = [],
  templates: Template[] = [],
): RoutesTemplatesSuitesLoadStop => ({
  type: ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS_STOP,
  suites,
  routes,
  templates,
});

export const DoRoutesTemplateDelete = (
  templateSuite: TemplateSuite,
  routes: Router[],
): RoutesTemplateDelete => ({
  type: ROUTES_TEMPLATE_DELETE,
  templateSuite,
  routes,
});

export type Action = (
  | RoutesTemplatesSuitesLoad
  | RoutesTemplatesSuitesLoadStop
  | RoutesTemplateDelete
);
