/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.17.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Types of Failures only for admins or extended user roles
 */
export type ExtendedIncidentsTypesEnumModel = 'manual' | 'hardware_error' | 'last_message_time_error' | 'anomalous_status_duration' | 'unexpected_reset_error' | 'night_light_sensor_error' | 'daylight_sensor_error' | 'magnetometer_error';

export const ExtendedIncidentsTypesEnumModel = {
    Manual: 'manual' as ExtendedIncidentsTypesEnumModel,
    HardwareError: 'hardware_error' as ExtendedIncidentsTypesEnumModel,
    LastMessageTimeError: 'last_message_time_error' as ExtendedIncidentsTypesEnumModel,
    AnomalousStatusDuration: 'anomalous_status_duration' as ExtendedIncidentsTypesEnumModel,
    UnexpectedResetError: 'unexpected_reset_error' as ExtendedIncidentsTypesEnumModel,
    NightLightSensorError: 'night_light_sensor_error' as ExtendedIncidentsTypesEnumModel,
    DaylightSensorError: 'daylight_sensor_error' as ExtendedIncidentsTypesEnumModel,
    MagnetometerError: 'magnetometer_error' as ExtendedIncidentsTypesEnumModel,
    LowBattery: 'low_battery',
    InductiveSensorError: 'inductive_error',
    RadioError: 'radio_error',

}
