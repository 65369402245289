import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { Station, StationGeneration } from 'models/base-station';
import { FormProps } from 'types/form';
import { validate } from './validator';
// components
import {
  OwnersSingleSelectControl,
  StationFrequencySelectControl,
  StationGenerationSelectControl,
  StationReceiverTypeSelectControl,
  TextControl
} from 'components/Controls';
// styles
import { useStyles } from './styles';

export enum StationFormField {
  id = 'id',
  owner = 'owner_id',
  generation = 'generation',
  frequency = 'frequency',
  receiverType = 'receiver_type',
}

type Props = FormProps<Station, StationFormField>
export const BaseStationForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends StationFormField>(
    field: TField,
    value: Station[TField],
  ) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(StationFormField.id) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(StationFormField.id) }
          label="Station ID"
          name="station[id]"
          value={ state.id || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(StationFormField.id, Number(event.target.value || 0))
          }
          error={ showErrors.includes(StationFormField.id) ? errors?.id !== undefined : false }
          helperText={ showErrors.includes(StationFormField.id) ? errors?.id : undefined }
        />
      }
      { !showFields.includes(StationFormField.owner) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(StationFormField.owner) }
          name="station[owner_id]"
          selected={ state.owner_id }
          error={ showErrors.includes(StationFormField.owner) && errors?.owner_id }
          changeHandler={ ownerId => handleChange(StationFormField.owner, ownerId ?? 0) }
        />
      }
      { !showFields.includes(StationFormField.generation) ? '' :
        <StationGenerationSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(StationFormField.generation) }
          name="station[generation]"
          selected={ state.generation }
          error={ showErrors.includes(StationFormField.generation) ? errors?.generation : undefined }
          // `generation as StationGeneration` is valid as long as `isClearable !== true`
          onChange={ generation => handleChange(StationFormField.generation, generation as StationGeneration) }
        />
      }
      { !showFields.includes(StationFormField.receiverType) ? '' :
        <StationReceiverTypeSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(StationFormField.receiverType) }
          name="stationFlash[receiverType]"
          selected={ state.receiver_type }
          error={ showErrors.includes(StationFormField.receiverType) ? errors?.receiver_type : undefined }
          onChange={ receiverType => handleChange(StationFormField.receiverType, receiverType ?? state.receiver_type) }
        />
      }
      { !showFields.includes(StationFormField.frequency) ? '' :
        <StationFrequencySelectControl
          isClearable
          isDisabled={ isView || disabledFields.includes(StationFormField.frequency) }
          name="station[frequency]"
          selected={ state.frequency }
          error={ showErrors.includes(StationFormField.frequency) ? errors?.frequency : undefined }
          onChange={ frequency => handleChange(StationFormField.frequency, frequency) }
        />
      }
    </form>
  );
};

