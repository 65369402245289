import { SubtreeManagementOptions } from '../../types';
import { fillZoneConnectivity } from './fillZoneConnectivity';
import { fillLevelConnectivity } from './fillLevelConnectivity';
import { fillGroupConnectivity } from './fillGroupConnectivity';
import { fillPositionConnectivity } from './fillPositionConnectivity';

export async function fillConnectivity(options: SubtreeManagementOptions) {
  await Promise.all([
    fillZoneConnectivity(options),
    fillLevelConnectivity(options),
    fillGroupConnectivity(options),
    fillPositionConnectivity(options)
  ]);
}
