import React from 'react';
import { isAllowedToCreateGroup } from 'utils/permissions';
import { LevelNode, QueryType, Tree } from 'hooks/device-management/useTree/types';

// components
import { DialogButton, PlusButton } from 'components/Buttons';
import { AddLevelGroupDialog } from 'components/DeviceManagement';

interface Props {
  tree: Tree;
  node: LevelNode;
}

export const AddGroupAction = ({ tree, node }: Props): JSX.Element => {
  return (
    <DialogButton
      permission={ isAllowedToCreateGroup }
      button={ handler =>
        <PlusButton
          label={ node.level ? 'Add Group' : 'Add New Group' }
          onClick={ handler }
        />
      }
      dialog={ props =>
        <AddLevelGroupDialog
          { ...props }
          zone={ node.parent.zone }
          level={ node.level }
          withDisabledParent
          onSuccess={ () => tree.reload(QueryType.group) }
        />
      }
    />
  );
};
