import { useCallback, useEffect, useRef, useState } from 'react';
import { differenceBy } from 'lodash';
import { Marker } from 'utils/map';

type Google = typeof google;
type Map = google.maps.Map;

interface MapState {
  map?: google.maps.Map;
  google?: Google;
  onLoad: (map: Map, google: Google) => void;
}

export function useMap(): MapState {
  const [map, setMap] = useState<Map>();
  const [google, setGoogle] = useState<Google>();

  return {
    map,
    google,
    onLoad: (map: Map, google: Google) => {
      setMap(map);
      setGoogle(google);
    }
  };
}

export function useAutoFitMap(markers: Marker[]): MapState {
  const state = useMap();
  const markerRef = useRef<Marker[]>([]);

  const fitBounds = useCallback((map?: Map, google?: Google) => {
    if (!markers.length || !google || !map) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => {
      if (marker.lat && marker.lon) {
        bounds.extend({ lat: marker.lat, lng: marker.lon });
      }
    });

    map.fitBounds(bounds);
  }, [markers]);

  useEffect(() => {
    const iteratee: keyof Marker = 'id';
    const diffForward = differenceBy(markerRef.current, markers, iteratee);
    const diffBackward = differenceBy(markers, markerRef.current, iteratee);

    if (diffForward.length || diffBackward.length) {
      markerRef.current = [...markers];
      fitBounds(state.map, state.google);
    }
  }, [markers, fitBounds, state]);

  return {
    ...state,
    onLoad: (map: Map, google: Google) => {
      state.onLoad(map, google);
      fitBounds(map, google);
    }
  };
}



