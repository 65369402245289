import React from 'react';
import { useFirmwaresDictionarySelector } from 'hooks/firmware-management';
// components
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  firmwareHash?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

export const FirmwareName = ({ firmwareHash, innerProps }: Props) => {
  const { isLoading, firmwares } = useFirmwaresDictionarySelector();
  const soft = firmwares.find(f => f.hash === firmwareHash);
  const name = soft ? `${ soft.name }/${ soft.version }` : '-';
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    isLoading ? <LoaderSvg/> : <Typography { ...TypoProps }>{ name }</Typography>
  );
};

