import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getBaseStationDetailPath } from 'routing/paths';
// components
import Link, { LinkProps } from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

interface Props {
  stationId: number;
  label?: string;
  LinkProps?: LinkProps<typeof RouterLink>;
}

export const BaseStationLink = (props: Props): JSX.Element => {
  const { stationId, label, LinkProps } = props;
  const path = getBaseStationDetailPath(stationId);

  return (
    <Typography>
      <Link
        color="secondary"
        { ...LinkProps }
        component={ RouterLink }
        to={ path }
      >
        { label ?? stationId }
      </Link>
    </Typography>
  );
};
