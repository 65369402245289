import { DamageState, FormField } from './types';
import { FormErrors } from 'types/form';

export const validate = (state: DamageState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.reason)) {
    if (!state.reason) {
      errors.reason = 'Reason is required';
    }
  }

  if (fields.includes(FormField.comment)) {
    if (!state.comment || !state.comment.trim()) {
      errors.comment = 'Comment is required';
    }
  }

  return errors;
};
