export interface DmApiFilters {
  owner?: number;
  projects?: number[];
  zones?: number[];
  // TODO: support finding positions without a level in server API
  levels?: (number | 'NONE')[];
  groups?: number[];
  positions?: number[];
  networkIds?: string[];
  customIds?: string[];
  innerIds?: number[];
  devices?: string[];
  labels?: number[];

  offset: number;
  limit: number;
}

interface VisibilityFilters {
  topmostEntityType: TopmostEntityType;
}

export type Filters = DmApiFilters & VisibilityFilters;

export type ChildrenFilters = Omit<DmApiFilters, 'offset' | 'limit'>;

export enum TopmostEntityType {
  POSITION = 0,
  GROUP = 1,
  LEVEL = 2,
  ZONE = 3
}
