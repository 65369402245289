/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Emulation API
 * work with virtual devices
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * is the message up-to-date
 * @export
 * @enum {string}
 */
export enum ActualityType {
    Actual = 'actual',
    Old = 'old'
}

