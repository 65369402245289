import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { createApiKey, deleteApiKey, fetchApiKeysById, fetchApiKeysByParams, updateApiKey } from 'clients/user-management';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/user-management';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ApiKey, ApiKeyCreate, ApiKeyUpdate } from 'models/user-management';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';

function* loadApiKeyDataById(action: Action.LoadApiKeyDataById) {
  yield put(Action.LoadApiKeyDataStart(action.apiKeyId));
  const response: ApiResponse = yield call(fetchApiKeysById, action.apiKeyId);
  yield put(Action.LoadApiKeyDataStop(action.apiKeyId));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeApiKeyData(action.apiKeyId, [response.data as ApiKey]));
  } else if (response.reason !== ReasonEnum.NotFound) {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching API Auth Token #${ action.apiKeyId }: ${ message }`));
  }
}

function* loadApiKeyDataByParams(action: Action.LoadApiKeyDataByParams) {
  yield put(Action.LoadApiKeyDataStart(action.params));
  const response: ApiResponse = yield call(fetchApiKeysByParams, action.params);
  yield put(Action.LoadApiKeyDataStop(action.params));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeApiKeyData(action.params, response.data as ApiKey[]));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching API Auth Tokens: ${ message }`));
  }
}

type CreateAction =
  | Action.CreateApiKey
  | ActionWithPromise<Action.CreateApiKey, ApiKey>

function* doCreateApiKey(action: CreateAction) {
  const { apiKey } = action;
  yield put(Action.LockApiKey(apiKey.id));
  const create: ApiKeyCreate = {
    name: apiKey.name,
    owner_id: apiKey.owner_id,
    permission_id: apiKey.permission_id,
    project_ids: apiKey.project_ids,
  };
  const response: ApiResponse = yield call(createApiKey, create);
  yield put(Action.UnlockApiKey(apiKey.id));

  if (response.reason === ReasonEnum.Ok) {
    const newApiKey = response.data as ApiKey;
    yield put(Action.ChangeApiKey(newApiKey, { create: true }));
    yield put(NotifySuccess(`API Auth Token ${ newApiKey.name } has been created`));
    'meta' in action && action.meta.promise.resolve(newApiKey);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating API Auth Token ${ apiKey.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type UpdateAction =
  | Action.UpdateApiKey
  | ActionWithPromise<Action.UpdateApiKey, ApiKey>

function* doUpdateApiKey(action: UpdateAction) {
  const { apiKey } = action;
  yield put(Action.LockApiKey(apiKey.id));
  const update: ApiKeyUpdate = {
    name: apiKey.name,
    owner_id: apiKey.owner_id,
    permission_id: apiKey.permission_id,
    project_ids: apiKey.project_ids,
  };
  const response: ApiResponse = yield call(updateApiKey, apiKey.id, update);
  yield put(Action.UnlockApiKey(apiKey.id));

  if (response.reason === ReasonEnum.Ok) {
    const newApiKey = response.data as ApiKey;
    yield put(Action.ChangeApiKey(newApiKey, { update: true }));
    yield put(NotifySuccess(`API Auth Token ${ newApiKey.name } has been updated`));
    'meta' in action && action.meta.promise.resolve(newApiKey);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while updating API Auth Token ${ apiKey.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

function* doDeleteApiKey(action: UpdateAction) {
  const { apiKey } = action;
  yield put(Action.LockApiKey(apiKey.id));
  const response: ApiResponse = yield call(deleteApiKey, apiKey.id);
  yield put(Action.UnlockApiKey(apiKey.id));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeApiKey(apiKey, { delete: true }));
    yield put(NotifySuccess(`API Auth Token ${ apiKey.name } has been deleted`));
    'meta' in action && action.meta.promise.resolve(apiKey);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while deleting API Auth Token ${ apiKey.name }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

export const apiKeySagas = [
  takeEvery(Action.UM_API_KEY_DATA_LOAD_BY_ID, loadApiKeyDataById),
  takeEvery(Action.UM_API_KEY_DATA_LOAD_BY_PARAMS, loadApiKeyDataByParams),
  takeLeading(Action.UM_API_KEY_UPDATE, doUpdateApiKey),
  takeLeading(Action.UM_API_KEY_DELETE, doDeleteApiKey),
  takeLeading(Action.UM_API_KEY_CREATE, doCreateApiKey),
];
