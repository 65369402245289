import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { isEmpty, isEqual } from 'lodash';
import { ValidationError } from 'utils/errors';
import { FormErrors, FormProps } from 'types/form';

interface FormParams<StateType, FieldEnum extends string> {
  initialState: StateType;
  showFields: FieldEnum[];

  // if you want control form state outside form fill this property
  state?: StateType;
  onChange?: (state: StateType, field?: FieldEnum) => void;
}

type FormState<StateType, FieldEnum extends string> =
  FormProps<StateType, FieldEnum>
  & {
    isChange: boolean;
    isValid: boolean;

    reset: () => void;
    catchError: (error: Error) => void;
    validate: () => boolean;
  }

export function useForm<StateType, FieldEnum extends string>(params: FormParams<StateType, FieldEnum>): FormState<StateType, FieldEnum> {
  const [state, setState] = useState<StateType>({ ...params.initialState });
  const [errors, setErrors] = useState<FormErrors<FieldEnum>>({});
  const [showErrors, setShowErrors] = useState<FieldEnum[]>([]);

  useDeepCompareEffect(() => {
    setState({ ...params.initialState });
  }, [params.initialState]);

  return {
    state: params.state ?? state,
    showFields: params.showFields,
    onChange: (state: StateType, field?: FieldEnum) => {
      params.state ?? setState(state);
      params.onChange && params.onChange(state, field);
      if (field && !showErrors.includes(field)) {
        setShowErrors([...showErrors, field]);
      }
    },

    errors: errors,
    showErrors: showErrors,
    onValidate: setErrors,
    catchError: (error: Error) => {
      if (error instanceof ValidationError) {
        setErrors((error as ValidationError<FieldEnum>).getFieldsSingleError());
      }
    },

    isChange: !isEqual(params.state ?? state, params.initialState),
    isValid: isEmpty(errors),

    reset: () => {
      setShowErrors([]);
      setState({ ...params.initialState });
    },

    validate: (): boolean => {
      if (isEmpty(errors)) {
        return true;
      }

      setShowErrors(params.showFields);

      return false;
    },
  };
}
