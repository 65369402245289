import React from 'react';
import { Link } from 'react-router-dom';
import { getCallerTemplateCloneLocation, getCallerTemplateDetailLocation, getCallerTemplateStatisticLocation } from 'routing/paths';
// components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ActionsButton from 'components/Buttons/ActionsButton';
import { Template } from 'models/caller';

interface Props {
  template: Template;
  isLoading: boolean;
}

const Actions = (props: Props): JSX.Element => {
  const { template, isLoading } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = (el: HTMLElement) => setAnchorEl(el);
  const closeMenu = () => setAnchorEl(null);

  const menuId = `actions-menu-${ 'asd' }`;

  return (
    <>
      <ActionsButton menuId={ menuId } isLoading={ isLoading } onClick={ openMenu }/>
      <Menu id={ menuId } anchorEl={ anchorEl } open={ anchorEl !== null } onClose={ closeMenu }>
        <MenuItem component={ Link } to={ getCallerTemplateDetailLocation(template) } onClick={ closeMenu }>Edit</MenuItem>
        <MenuItem component={ Link } to={ getCallerTemplateCloneLocation(template) } onClick={ closeMenu }>Clone</MenuItem>
        <MenuItem component={ Link } to={ getCallerTemplateStatisticLocation(template) } onClick={ closeMenu }>Statistics</MenuItem>
      </Menu>
    </>
  );
};

export default Actions;
