import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { ResponsiblePerson, StationLocation } from 'models/base-station';
import { sortLocation } from './utils';
import { RemoveLocationFromResponsiblePerson } from 'actions/base-station';
import { useDialog } from 'hooks';
import { useStationResponsiblePersonsDictionary } from 'hooks/station';
import { formatResponsiblePerson } from 'utils/models';

// components
import { Box, Typography, IconButton, Link } from '@material-ui/core';
import { IndeterminateCheckBoxOutlined, AddBoxOutlined } from '@material-ui/icons';
import { ConfirmationDialog } from 'components/Dialogs';
import { AddLocationPopover } from './AddLocationPopover';
import { BaseStationLink } from 'components/Links';

// style
import { useStyles, MARGIN } from './TableComponentsStyle';
import { EditLocationDialog } from 'components/BaseStation/Dialogs';

interface Props {
  responsiblePerson: ResponsiblePerson;
  expanded?: ResponsiblePerson['id'];
  setExpanded: (expanded?: ResponsiblePerson['id']) => void;
  availableLocations: StationLocation[];
}

interface RemoveLocationProps {
  rp: ResponsiblePerson;
  location: StationLocation;
}

interface PropsLocationId {
  location: StationLocation;
  rp: ResponsiblePerson;
  stationId?: number;
}

const RemoveLocation = ({ rp, location }: RemoveLocationProps): JSX.Element => {
  const classes = useStyles();
  const dialog = useDialog();
  const dispatch = useDispatch();

  if (!location) {
    return <></>;
  }

  const removeLocation = () => {
    dispatch(RemoveLocationFromResponsiblePerson(location.id, rp.id));
    dialog.close();
  };

  return (
    <>
      {
        location.id
        && <IconButton onClick={ dialog.open } className={ classes.margin }>
          <IndeterminateCheckBoxOutlined className={ classes.lightBlue } />
        </IconButton>
      }
      { dialog.isMounted && <ConfirmationDialog
        title={ 'Detach responsible person from location' }
        onConfirm={ removeLocation }
        onCancel={ dialog.close }
        onCloseEnd={ dialog.unmount }
        open={ dialog.isOpen }
        confirmText={ 'DETACH' }
        cancelText={ 'CANCEL' }
        description={ (
          <>
            You are detaching responsible person from the location.<br />
            { location.station_id && (
              <>
                Notifications for station <strong>#{ location.station_id }</strong>{ ' ' }
                will not be sent to <strong>{ formatResponsiblePerson(rp) }</strong>.<br />
              </>
            ) }
            Are you sure?
          </>
        ) }
      /> }
    </>
  );
};

interface AddLocationProps {
  person: ResponsiblePerson;
  locations: StationLocation[];
}

const AddLocation = ({ person, locations }: AddLocationProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const openMenu = (target: Element) => setAnchorEl(target);
  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography className={ clsx(classes.blueText, classes.blueTextDisabled) }>No location</Typography>
        <IconButton onClick={ e => openMenu(e.currentTarget) } className={ classes.margin }>
          <AddBoxOutlined className={ classes.lightBlue } />
        </IconButton>
      </Box>
      <AddLocationPopover
        anchorEl={ anchorEl }
        person={ person }
        locations={ locations }
        onClose={ closeMenu }
      />
    </>
  );
};

const LocationId = ({ rp, location }: PropsLocationId): JSX.Element => {
  const classes = useStyles();
  const dialog = useDialog();
  const label = `${location.country}, ${location.address}`;
  const { handleResponsiblePersonsDataDictionary } = useStationResponsiblePersonsDictionary();
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography noWrap className={ classes.blueText }>
          { location.station_id
            ? <BaseStationLink stationId={ location.station_id } label={ label } />
            : <Link onClick={ dialog.open }>{ label }</Link>
          }
        </Typography>
        <RemoveLocation rp={ rp } location={ location } />
      </Box>
      { dialog.isMounted && <EditLocationDialog
        location={ location }
        isOpen={ dialog.isOpen }
        onClose={ dialog.close }
        onCloseEnd={ dialog.unmount }
        onDeleteCallback={ handleResponsiblePersonsDataDictionary }
      /> }
    </>
  );
};

const LocationNumber = ({ num }: { num?: number }): JSX.Element => {
  const classes = useStyles();

  if (!num || num <= 1) {
    return <></>;
  }

  return (
    <Box display="flex" alignItems="center" position="relative" >
      <Box className={ clsx(classes.blueText, classes.counterButton) }>
        { `(${num})` }
      </Box>
    </Box>
  );
};

export const Location = ({ responsiblePerson, expanded, setExpanded, availableLocations }: Props): JSX.Element => {
  const sorted = sortLocation(responsiblePerson.locations || []);

  if (!sorted.length) {
    return (
      <AddLocation
        locations={ availableLocations }
        person={ responsiblePerson }
      />
    );
  }

  const handle = () => {
    setExpanded(expanded === responsiblePerson.id ? undefined : responsiblePerson.id);
  };

  return (
    <>
      <Box display="flex" onClick={ handle }>
        <LocationNumber num={ sorted?.length } />
        <LocationId location={ sorted[0] } rp={ responsiblePerson } />
      </Box>
      <Box display={ expanded === responsiblePerson?.id ? 'block' : 'none' }>
        { sorted.slice(1).map(location => (
          <Box key={ location.id } my={ MARGIN }>
            <LocationId location={ location } rp={ responsiblePerson } />
          </Box>
        )) }
      </Box>
    </>
  );
};
