import React from 'react';

import { PositionsFilterFields, PositionWithLevel } from 'models/device-management';
import { dmPositionPath } from 'routing/paths';
import { useZones } from 'hooks/device-management';
import { useProjectsDictionarySelector, useUserGroupsDictionarySelector } from 'hooks/user-managment';

import { hideColumns, commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { PositionName } from 'components/Labels';
import { DmLabels } from 'components/DmLabels';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PositionedDeviceName from './PositionedDeviceName';
import { CustomLink, ZoneLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';
import { LevelAndGroupLink } from 'components/Links/LevelAndGroupLink';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';
import useStyles from 'styles/page';

type PositionsColumnName = (
  | 'positionId'
  | 'networkId'
  | 'deviceid'
  | 'owner'
  | 'project'
  | 'zone'
  | 'group'
  | 'innerId'
  | 'customId'
);
export interface PositionsTableProps {
  filters: PositionsFilterFields;
  positions: PositionWithLevel[];
  total: number;
  isAdmin: boolean;
  isFetching: boolean;
  hiddenColumns?: PositionsColumnName[];
  toolbar?: React.ReactNode;
}

export const PositionsTable = (props: PositionsTableProps): JSX.Element => {
  const classes = useStyles();
  const { isAdmin, positions, filters, isFetching, total } = props;
  const { limit, offset } = filters;

  const { projects } = useProjectsDictionarySelector();
  const { zones } = useZones({ params: {} });
  const { isLoading: isOwnersLoading, userGroups } = useUserGroupsDictionarySelector();

  let columns: MUIDataTableColumn[] = [
    {
      name: 'positionId',
      label: 'Position ID',
      options: {
        customBodyRender: (positionId: number) => (
          <Link
            color="secondary"
            component={ CustomLink }
            to={ dmPositionPath(positionId) }
            withReturnUrl
          >
            { positionId }
          </Link>
        ),
      },
    },
    {
      name: 'networkId',
      label: 'Network ID',
      options: {
        customBodyRender: ([networkId, positionId]: [string, number]) => networkId ?
          <PositionName network={ networkId } id={ positionId } /> :
          <Typography variant="caption" color="textSecondary">-</Typography>
      }
    },
    {
      name: 'deviceid',
      label: 'Device ID',
      options: {
        customBodyRender: (positionId: number) => <PositionedDeviceName id={ positionId } />
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: isAdmin ? 'true' : 'excluded',
        customBodyRender: (owner: string) => isOwnersLoading ? <LoaderSvg /> : owner
      }
    },
    {
      name: 'project',
      label: 'Project',
    },
    {
      name: 'zone',
      label: 'Zone',
      options: {
        customBodyRender: (zoneId: number) => <ZoneLink zoneId={ zoneId } />
      },
    },
    {
      name: 'group',
      label: 'Level / Group Name',
      options: {
        customBodyRenderLite: dataIndex => <LevelAndGroupLink { ...data[dataIndex].group } />
      },
    },
    {
      name: 'innerId',
      label: 'Inner ID',
    },
    {
      name: 'customId',
      label: 'Custom ID',
    },
    {
      name: 'labels',
      label: 'Labels',
      options: {
        customBodyRenderLite: dataIndex => <DmLabels labels={ data[dataIndex].labels } />
      }
    }
  ];

  columns = hideColumns(columns, {
    hiddenColumns: props.hiddenColumns,
  });

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    rowsPerPage: limit,
    count: total,
    page: (typeof offset === 'number' && typeof limit === 'number')
      ? offset / limit
      : undefined,
    customToolbar: () => props.toolbar,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  const data = positions.map(position => {
    const zone = zones.find(zone => position.zone_id === zone.id);
    const project = zone && projects.find(project => project.id === zone.project_id);
    const userGroup = userGroups.find(owner => owner.id === zone?.owner_id);
    const userGroupName = userGroup ? userGroup.name : 'Not registered client';

    return {
      positionId: position.id,
      deviceid: position.id,
      networkId: [position.network_id, position.id],
      project: project ? project.name : '—',
      zone: zone?.id,
      group: {
        groupId: position.group_id,
        levelId: position.level_id,
        groupName: position.group_name,
        levelName: position.level_name,
      },
      innerId: Number(position.group_inner_id),
      customId: position.custom_id || '',
      owner: userGroupName,
      labels: position.labels ?? [],
    };
  });

  return (
    <div className={ classes.table }>
      <MuiThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={ isFetching }>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>
    </div>
  );
};
