import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useUnmount } from 'react-use';

import { FetchHistory, SyncHistoryStart, SyncHistoryStop } from 'actions/rabbit';
import { buildState, convertParamsToState, convertStateToParams, FilterState, } from 'components/Rabbit/HistoryFilter/Component';
import { useLocationItems } from 'hooks/location';
import { GetHistoryParams, History, INITIAL_HISTORY_PARAMS } from 'models/rabbit';
import { RootState } from 'reducers';

interface PageData {
  isLoading: boolean;
  data: History[];
  total?: number;
}

export function useRabbitHistoryByFilters(params: GetHistoryParams): PageData {
  const { isFetched, history, total } = useSelector((state: RootState) => ({
    isFetched: state.rabbit.history.repository.isFetched(params),
    history: state.rabbit.history.repository.findByParams(params),
    total: state.rabbit.history.repository.getTotal(params),
  }));

  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(FetchHistory(params));
  }, [params, dispatch]);

  return {
    isLoading: isFetched !== true,
    data: history,
    total,
  };
}

interface PageFilter {
  filter: Omit<GetHistoryParams, 'offset' | 'limit'>;
  onSave: () => void;
  onReset: () => void;

  filterState: FilterState;
  setFilterState: (state: FilterState) => void;
}

const initialState: FilterState = convertParamsToState(INITIAL_HISTORY_PARAMS);

export function useFilter(): PageFilter {
  const location = useLocationItems();
  const filter = buildState(location.items);
  const [state, setState] = useState<FilterState>(() => convertParamsToState(filter));

  return {
    filter: filter,
    filterState: state,
    setFilterState: setState,
    onSave: () => location.setItems(convertStateToParams(state)),
    onReset: () => {
      setState(initialState);
      location.setItems(initialState);
    },
  };
}

interface AutoSyncState {
  isAutoUpdate: boolean;
  onStart: () => void;
  onStop: () => void;
}

export const useRabbitHistoryAutoUpdate = (timeout: number): AutoSyncState => {
  const { isAutoUpdate } = useSelector((state: RootState) => ({
    isAutoUpdate: state.rabbit.history.isAutoSync,
  }), shallowEqual);

  const dispatch = useDispatch();
  useUnmount(() => {
    isAutoUpdate && dispatch(SyncHistoryStop());
  });

  return {
    isAutoUpdate,
    onStart: () => dispatch(SyncHistoryStart(timeout)),
    onStop: () => dispatch(SyncHistoryStop()),
  };
};