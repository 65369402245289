import { createPath, UrlItems } from 'utils/routing/query';

const baseUrl = '/settings';

enum Path {
  responsiblePersons = '/responsible-persons'
}

export function getResponsiblePersonsPath(params?: UrlItems): string {
  return createPath({
    pathname: baseUrl + Path.responsiblePersons,
    hashParams: params
  });
}