import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { NotifyError } from 'actions/notifier';
import { fetchZones } from 'clients/device-management';
import { Position, Zone, ZoneFiltersParams } from 'models/device-management';
import { getResponseData } from 'utils/clients';
import { useGroups } from './groups';
import { sortStrings } from 'helpers';

interface ZoneQueryResult {
  isLoading: boolean;
  zone?: Zone;
}

export const useZoneForPosition = (position: Position | undefined): ZoneQueryResult => {
  const groupId = position?.group_id;
  const groupsQuery = useGroups({
    params: { groups: groupId ? [groupId] : undefined },
    enabled: Boolean(groupId),
  });

  const zoneId = groupsQuery.groups[0]?.zone_id;
  const zonesQuery = useZones({
    params: { zones: zoneId ? [zoneId] : undefined },
    enabled: Boolean(zoneId)
  });

  return {
    isLoading: groupsQuery.isLoading || zonesQuery.isLoading,
    zone: zonesQuery.zones[0],
  };
};

interface ZonesQueryResult {
  isLoading: boolean;
  zones: Zone[];
  total: number;
}

interface GetZonesHookOptions {
  params: ZoneFiltersParams;
  enabled?: boolean;
}

export function useZones({ params, enabled }: GetZonesHookOptions): ZonesQueryResult {
  const dispatch = useDispatch();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/zones', params],
    queryFn: async () => {
      const res = await fetchZones(params);
      return {
        data: getResponseData(res),
        total: res.total
      };
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      dispatch(NotifyError(`Error while fetching zones: ${ (e as Error).message }`));
    },
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    zones: queryResult.data?.data ? queryResult?.data.data.sort((a, b) => sortStrings(a.name, b.name)) : [],
    total: queryResult.data?.total || 0,
  };
}