import {
  IntegrationLastTimeReceive,
  IntegrationData,
  IntegrationsIdEnum,
  IntegrationsEnum,
  Statuses,
  IntegrationFiltersParams,
} from 'models/lorawan';

import theThings from 'images/Lorawan/The_Things_Industrie_logo.png';
import theThingsNetwork from 'images/Lorawan/The_Things_Network.png';
import kerlink from 'images/Lorawan/kerlink_logo.png';
import thingPark from 'images/Lorawan/Actility.png';
import awsLogo from 'images/Lorawan/aws_logo.png';
import { ApiKey } from 'models/user-management';
import { groupBy } from 'lodash';
import { staticEnv } from 'env';

const availableIntegrations = [
  IntegrationsIdEnum.TheThingsInd3,
  IntegrationsIdEnum.TheThingsNet2,
  IntegrationsIdEnum.TheThingsNet3,
  IntegrationsIdEnum.WanseyKerlink,
];

const unavailableIntegrations = [
  IntegrationsIdEnum.ThingParkActivity,
  IntegrationsIdEnum.AWSIoTCore,
];

class Integration implements IntegrationData {
  icon = '';
  title = IntegrationsEnum.AWSIoTCore;
  status = Statuses.NotSet;
  documentation = '';

  public static create(
    id: IntegrationsIdEnum,
    statuses: IntegrationLastTimeReceive[],
    apiKeys: ApiKey[],
    owner?: number,
  ): IntegrationData {
    const lastReceiveTime = statuses.find(s => s.source === id);
    const apiKey = this.getApiKey(id, apiKeys);
    const ownerId = apiKey ? apiKey.owner_id : owner;

    return {
      icon: this.getIntegrationIcon(id),
      url: this.getIntegrationUrl(id),
      title: this.getIntegrationTitle(id),
      status: this.getIntegrationStatus(id, apiKeys),
      documentation: this.getIntegrationDocumentatinUrl(id),
      apiKey,
      lastReceiveTime,
      ownerId,
    };
  }

  private static getIntegrationIcon(id: IntegrationsIdEnum): string {
    switch (id) {
      case IntegrationsIdEnum.TheThingsInd3:
        return theThings;
      case IntegrationsIdEnum.TheThingsNet2:
      case IntegrationsIdEnum.TheThingsNet3:
        return theThingsNetwork;
      case IntegrationsIdEnum.ThingParkActivity:
        return thingPark;
      case IntegrationsIdEnum.WanseyKerlink:
        return kerlink;
      case IntegrationsIdEnum.AWSIoTCore:
        return awsLogo;
      default:
        return '';
    }
  }

  private static getIntegrationUrl(id: IntegrationsIdEnum): string {
    switch (id) {
      case IntegrationsIdEnum.TheThingsInd3:
        return urls[IntegrationsEnum.TheThingsInd];
      case IntegrationsIdEnum.TheThingsNet2:
        return urls[IntegrationsEnum.TheThingsNetV2];
      case IntegrationsIdEnum.TheThingsNet3:
        return urls[IntegrationsEnum.TheThingsNetV3];
      case IntegrationsIdEnum.WanseyKerlink:
        return urls[IntegrationsEnum.WanseyKerlink];
      default:
        return '';
    }
  }

  private static getIntegrationDocumentatinUrl(id: IntegrationsIdEnum): string {
    switch (id) {
      case IntegrationsIdEnum.TheThingsInd3:
        return documentationUrls[IntegrationsEnum.TheThingsInd];
      case IntegrationsIdEnum.TheThingsNet2:
        return documentationUrls[IntegrationsEnum.TheThingsNetV2];
      case IntegrationsIdEnum.TheThingsNet3:
        return documentationUrls[IntegrationsEnum.TheThingsNetV3];
      case IntegrationsIdEnum.WanseyKerlink:
        return documentationUrls[IntegrationsEnum.WanseyKerlink];
      default:
        return '';
    }
  }

  private static getIntegrationTitle(id: IntegrationsIdEnum): IntegrationsEnum {
    switch (id) {
      case IntegrationsIdEnum.TheThingsInd3:
        return IntegrationsEnum.TheThingsInd;
      case IntegrationsIdEnum.TheThingsNet2:
        return IntegrationsEnum.TheThingsNetV2;
      case IntegrationsIdEnum.TheThingsNet3:
        return IntegrationsEnum.TheThingsNetV3;
      case IntegrationsIdEnum.ThingParkActivity:
        return IntegrationsEnum.ThingParkActivity;
      case IntegrationsIdEnum.WanseyKerlink:
        return IntegrationsEnum.WanseyKerlink;
      case IntegrationsIdEnum.AWSIoTCore:
        return IntegrationsEnum.AWSIoTCore;
    }
  }

  private static getIntegrationStatus(id: IntegrationsIdEnum, apiKeys: ApiKey[]): Statuses {
    switch (id) {
      case IntegrationsIdEnum.ThingParkActivity:
      case IntegrationsIdEnum.AWSIoTCore:
        return Statuses.UnderConstruction;

      case IntegrationsIdEnum.TheThingsInd3:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.TheThingsInd))
          ? Statuses.Enabled
          : Statuses.NotSet;

      case IntegrationsIdEnum.TheThingsNet2:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.TheThingsNetV2))
          ? Statuses.Enabled
          : Statuses.NotSet;
      case IntegrationsIdEnum.TheThingsNet3:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.TheThingsNetV3))
          ? Statuses.Enabled
          : Statuses.NotSet;

      case IntegrationsIdEnum.WanseyKerlink:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.WanseyKerlink))
          ? Statuses.Enabled
          : Statuses.NotSet;

      default:
        return Statuses.NotSet;
    }
  }

  private static getApiKey(id: IntegrationsIdEnum, apiKeys: ApiKey[]): ApiKey | undefined {
    switch (id) {
      case IntegrationsIdEnum.TheThingsInd3:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.TheThingsInd));
      case IntegrationsIdEnum.TheThingsNet2:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.TheThingsNetV2));
      case IntegrationsIdEnum.TheThingsNet3:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.TheThingsNetV3));
      case IntegrationsIdEnum.WanseyKerlink:
        return apiKeys.find(key => key.name === integrationName(IntegrationsEnum.WanseyKerlink));
      default:
        return undefined;
    }
  }
}

function filterKeyByName(name: string): boolean {
  switch (name) {
    case integrationName(IntegrationsEnum.TheThingsInd):
    case integrationName(IntegrationsEnum.TheThingsNetV2):
    case integrationName(IntegrationsEnum.TheThingsNetV3):
    case integrationName(IntegrationsEnum.WanseyKerlink):
      return true;
    default:
      return false;
  }
}

export function setupIntegrations(
  statuses: IntegrationLastTimeReceive[],
  keys: ApiKey[],
  filters: IntegrationFiltersParams,
  isAdmin: boolean,
): IntegrationData[] {
  let integrations: IntegrationData[] = [];
  const integrationKeys = keys.filter(key => filterKeyByName(key.name));

  if (isAdmin) {
    const keysByOwner = groupBy<ApiKey>(integrationKeys, 'owner_id');

    for (const owner of Object.keys(keysByOwner)) {
      for (const id of availableIntegrations) {
        const integrationData = Integration.create(id, statuses, keysByOwner[owner], Number(owner));

        integrations.push(integrationData);
      }
    }
  } else {
    for (const id of availableIntegrations) {
      const integrationData = Integration.create(id, statuses, integrationKeys);

      integrations.push(integrationData);
    }

    for (const id of unavailableIntegrations) {
      const integrationData = Integration.create(id, statuses, integrationKeys);

      integrations.push(integrationData);
    }
  }


  if (isAdmin) {
    integrations = integrations.filter(i => i.status === Statuses.Enabled);
  }

  if (filters.type) {
    integrations = integrations.filter(i => i.title === filters.type);
  }

  return integrations;
}

export const integrationName = (integration: IntegrationsEnum) => `Integration ${integration}`;

const LORAWAN = staticEnv.IS_PRODUCTION ? 'lorawan' : 'devloravan';

export const urls: Record<IntegrationsEnum, string> = {
  [IntegrationsEnum.TheThingsInd]: `https://${LORAWAN}.source.nwave.io/tti/v3`,
  [IntegrationsEnum.TheThingsNetV2]: `https://${LORAWAN}.source.nwave.io/ttn/v2`,
  [IntegrationsEnum.TheThingsNetV3]: `https://${LORAWAN}.source.nwave.io/ttn/v3`,
  [IntegrationsEnum.WanseyKerlink]: `https://${LORAWAN}.source.nwave.io/kerlink/wmc/v3`,
  [IntegrationsEnum.AWSIoTCore]: '',
  [IntegrationsEnum.ThingParkActivity]: '',
};

const documentationUrls: Record<IntegrationsEnum, string> = {
  [IntegrationsEnum.TheThingsInd]: 'https://nwaveio.atlassian.net/wiki/spaces/ND/pages/2205450275',
  [IntegrationsEnum.TheThingsNetV2]: 'https://nwaveio.atlassian.net/wiki/spaces/ND/pages/2239332353',
  [IntegrationsEnum.TheThingsNetV3]: 'https://nwaveio.atlassian.net/wiki/spaces/ND/pages/2239332353',
  [IntegrationsEnum.WanseyKerlink]: 'https://nwaveio.atlassian.net/wiki/spaces/ND/pages/2258206732/Kerlink+Wanesy+Management+Center',
  [IntegrationsEnum.AWSIoTCore]: '',
  [IntegrationsEnum.ThingParkActivity]: '',
};
