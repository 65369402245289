import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Filters, QueryType, Tree } from './types';
import { getQueryHash } from './utils';
import { useZoneResult } from './useZoneResult';
import { useLevelResult } from './useLevelResult';
import { useGroupResult } from './useGroupResult';
import { usePositionResult } from './usePositionResult';
import { useDeviceResult } from './useDeviceResult';
import { useDeviceStatesResult } from './useDeviceStatesResult';
import { useDeviceConnectsResult } from './useDeviceConnectsResult';

export function useTree(filters: Filters, enabled = true): Tree {
  const [total, setTotal] = useState<number>(0);
  const queryClient = useQueryClient();
  const tree: Tree = {
    total: total,
    items: {},
    loaded: {
      zone: false,
      level: false,
      group: false,
      position: false,
      device: false,
      deviceState: false,
      deviceConnect: false,
    },
    reload: (queryType: QueryType): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.refetchQueries({
        queryKey: ['dmTree', queryType],
        active: true,
      });
    }
  };

  const queryHash = getQueryHash(filters);
  useZoneResult(filters, tree, queryHash, enabled);
  const zoneIds = Object.values(tree.items).map(item => item.zone.id);
  useLevelResult(zoneIds, tree, queryHash);
  useGroupResult(zoneIds, tree, queryHash);
  usePositionResult(zoneIds, tree, queryHash);
  useDeviceResult(zoneIds, tree, queryHash);
  useDeviceStatesResult(zoneIds, tree, queryHash);
  useDeviceConnectsResult(zoneIds, tree, queryHash);
  useEffect(() => {
    if (tree.total > 0) {
      setTotal(tree.total);
    }
  }, [tree.total]);

  return tree;
}
