import { UpdateDeviceFirmwareParams } from 'models/firmware-management';

export const DEVICE_FIRMWARE_UPDATE = 'fmw/firmware/UPDATE_DEVICE';

export interface UpdateDeviceFirmware {
  readonly type: typeof DEVICE_FIRMWARE_UPDATE;
  readonly params: UpdateDeviceFirmwareParams;
}

export const UpdateDeviceFirmware = (params: UpdateDeviceFirmwareParams): UpdateDeviceFirmware => ({
  type: DEVICE_FIRMWARE_UPDATE, params
});

export type Action =
  | UpdateDeviceFirmware
