import { SubtreeManagementOptions } from '../../types';
import { fillZoneTotalPositions } from './fillZoneTotalPositions';
import { fillLevelTotalPositions } from './fillLevelTotalPositions';
import { fillGroupTotalPositions } from './fillGroupTotalPositions';

export async function fillTotalPositions(options: SubtreeManagementOptions) {
  await Promise.all([
    fillZoneTotalPositions(options),
    fillLevelTotalPositions(options),
    fillGroupTotalPositions(options),
  ]);
}
