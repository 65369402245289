import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import { LoadRouterData } from 'actions/caller';
import { GetRoutersParams, Router } from 'models/caller';
import { RootState } from 'reducers';

export interface RoutesState {
  routes: Router[];
  isLoading: boolean;
  refetch: () => void;
}

export function useCallerRoutesByParams(params: GetRoutersParams = {}): RoutesState {
  const { data, isFetching } = useSelector((state: RootState) => state.callerRouters);
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    dispatch(LoadRouterData(params));
  }, [params]);

  return {
    routes: data,
    isLoading: isFetching,
    refetch: () => {
      dispatch(LoadRouterData(params));
    },
  };
}

export function getZoneIdsByRoutes(routes: Router[]): number[] {
  return [...new Set([
    ...routes.flatMap(route => route.is_active ? [route.zone_id] : []),
  ])];
}