import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { NotificationMethod, ResponsiblePerson } from 'models/base-station';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { FormField } from './types';
// components
import { FormControl, Switch, FormControlLabel } from '@material-ui/core';
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';
// styles
import { useStyles } from './styles';


export {
  FormField as ResponsiblePersonFormField
};

type Props = FormProps<ResponsiblePerson, FormField>
export const StationResponsiblePersonForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isView, state, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];
  const errors = props.errors ?? {};

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  const handleChangeEmail = (email: string) => {
    onChange && onChange({
      ...state,
      notification_endpoint: { ...state.notification_endpoint, email },
    }, FormField.email);
  };

  const handleChangeNotify = (method: NotificationMethod, checked: boolean) => {
    onChange && onChange({
      ...state,
      notification_method: method,
      notify: checked,
    }, FormField.notify);
  };

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(FormField.ownerId) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(FormField.ownerId) }
          selected={ state[FormField.ownerId] }
          changeHandler={ ownerId => handleChange(FormField.ownerId, ownerId) }
          error={ showErrors.includes(FormField.ownerId) ? errors[FormField.ownerId] : undefined }
        />
      }
      { !showFields.includes(FormField.firstName) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.firstName) }
          label="First Name"
          name={ FormField.firstName }
          value={ state[FormField.firstName] || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.firstName, event.target.value || '')
          }
          error={ showErrors.includes(FormField.firstName) ? errors[FormField.firstName] !== undefined : false }
          helperText={ showErrors.includes(FormField.firstName) ? errors[FormField.firstName] : undefined }
        />
      }
      { !showFields.includes(FormField.lastName) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.lastName) }
          label="Last Name"
          name={ FormField.lastName }
          value={ state[FormField.lastName] || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.lastName, event.target.value || '')
          }
          error={ showErrors.includes(FormField.lastName) ? errors[FormField.lastName] !== undefined : false }
          helperText={ showErrors.includes(FormField.lastName) ? errors[FormField.lastName] : undefined }
        />
      }
      { !showFields.includes(FormField.role) ? '' :
        <TextControl
          disabled={ isView || disabledFields.includes(FormField.role) }
          label="Role"
          name={ FormField.role }
          value={ state[FormField.role] || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.role, event.target.value || '')
          }
          error={ showErrors.includes(FormField.role) ? errors[FormField.role] !== undefined : false }
          helperText={ showErrors.includes(FormField.role) ? errors[FormField.role] : undefined }
        />
      }
      { !showFields.includes(FormField.email) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.email) }
          label="Email"
          name={ FormField.email }
          value={ state.notification_endpoint[FormField.email] || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeEmail(event.target.value || '')
          }
          error={ showErrors.includes(FormField.email) ? errors[FormField.email] !== undefined : false }
          helperText={ showErrors.includes(FormField.email) ? errors[FormField.email] : undefined }
        />
      }
      { !showFields.includes(FormField.notify) ? '' :
        <FormControl margin="normal" fullWidth>
          <FormControlLabel
            label="Notification"
            labelPlacement="start"
            className={ classes.formNotify }
            control={
              <Switch
                color="primary"
                disabled={ isView || disabledFields.includes(FormField.notify) }
                checked={ state.notify }
                onChange={ event => handleChangeNotify(NotificationMethod.Email, Boolean(event.target.checked)) }
              />
            }
          />
        </FormControl>
      }
    </form>
  );
};

