import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getFirstVisibleTab } from 'components/Tabs/utils';
import { buildUrlParams, parseUrlParams, UrlItems } from 'utils/routing/query';

// components
import { PageTabs, TabConfig } from 'components';

import GeneralTab, { GeneralTabProps } from './GeneralTab';
import MapTab, { MapTabProps } from './MapTab';
import PositionsTab, { PositionsTabProps } from './PositionsTab';

type TabContentProps = (
  & GeneralTabProps
  & MapTabProps
  & PositionsTabProps
);

const TabConfigs: TabConfig<TabContentProps>[] = [
  {
    label: 'General',
    render: props => props.tabActive && <GeneralTab {...props} />,
    get id() { return this.label; },
  },
  {
    label: 'Positions',
    render: props => props.tabActive && <PositionsTab groupId={props.groupId} />,
    get id() { return this.label; },
  },
  {
    label: 'Map',
    render: props => props.tabActive && <MapTab groupId={props.groupId} />,
    get id() { return this.label; },
  },
];

type ContentParams = UrlItems & {
  activeTabId?: string;
  returnUrl?: string;
};

const buildParams = (params: UrlItems): ContentParams => ({
  ...params,
  returnUrl: params.returnUrl && decodeURIComponent(params.returnUrl),
});

type GroupPageContentProps = TabContentProps;

const GroupPageContent: React.FC<GroupPageContentProps> = props => {
  const history = useHistory();
  const location = useLocation();
  const params = parseUrlParams(location.hash, buildParams);

  return (
    <PageTabs
      activeTabId={params.activeTabId || getFirstVisibleTab(TabConfigs)?.id}
      tabs={TabConfigs}
      tabContentProps={{
        ...props,
        returnUrl: params.returnUrl,
      }}
      onActiveTabChange={activeTabId => {
        history.replace(
          history.createHref({
            ...location,
            hash: buildUrlParams({ ...params, activeTabId }),
          }),
        );
      }}
    />
  );
};

export default GroupPageContent;
