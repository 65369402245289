import React from 'react';
import { PositionedDeviceData } from 'models/device-management';
import { DeleteVirtualDevice } from 'actions/device-management/virtual-devices';
import { useFormActionLoader } from 'hooks';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  device: PositionedDeviceData;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DeleteVirtualDeviceDialog = ({ device, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const handleConfirm = () => doAction({
    action: DeleteVirtualDevice(device),
    onFinally: onClose,
  });

  return (
    <EnsureDialog
      name="delete-virtual-device"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
      btnYes="Delete"
      btnNo="Cancel"
    >
      Are you sure you want to delete virtual device { device.device_id.toUpperCase() }?
    </EnsureDialog>
  );
};
