import { GetTemplateStatisticParams, TemplateStatistic } from 'models/caller';

export const CALLER_TEMPLATE_STATISTIC_LOAD = 'CALLER_TEMPLATE_STATISTIC_LOAD';
export const CALLER_TEMPLATE_STATISTIC_FETCH_START = 'CALLER_TEMPLATE_STATISTIC_FETCH_START';
export const CALLER_TEMPLATE_STATISTIC_FETCH_STOP = 'CALLER_TEMPLATE_STATISTIC_FETCH_STOP';
export const CALLER_TEMPLATE_STATISTIC_CHANGE = 'CALLER_TEMPLATE_STATISTIC_CHANGE';

export interface LoadTemplateStatistic {
  readonly type: typeof CALLER_TEMPLATE_STATISTIC_LOAD;
  readonly params: GetTemplateStatisticParams;
}

export interface LoadTemplateStatisticStart {
  readonly type: typeof CALLER_TEMPLATE_STATISTIC_FETCH_START;
  readonly templateId: number;
}

export interface LoadTemplateStatisticStop {
  readonly type: typeof CALLER_TEMPLATE_STATISTIC_FETCH_STOP;
  readonly templateId: number;
}

export interface ChangeTemplateStatistic {
  readonly type: typeof CALLER_TEMPLATE_STATISTIC_CHANGE;
  readonly templateId: number;
  readonly data: TemplateStatistic[];
}

export const LoadTemplateStatistic: (params: GetTemplateStatisticParams) => LoadTemplateStatistic = (params: GetTemplateStatisticParams) => ({
  type: CALLER_TEMPLATE_STATISTIC_LOAD, params
});

export type Action =
  | LoadTemplateStatistic
  | LoadTemplateStatisticStart
  | LoadTemplateStatisticStop
  | ChangeTemplateStatistic
