import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { GetStatParams } from 'actions/device-statistic';
import * as zoneActions from 'actions/device-management/zones';
import * as dataActions from 'actions/device-management/zone-data';
import * as dmClient from 'clients/device-management';
import { fetchZonesAvgConn } from 'clients/connectivity';
import { ApiResponse } from 'models';
import * as dmModels from 'models/device-management';
import { GetDmStatParams, ResponseReason, Statistic } from 'models/device-management';
import { ZoneConnectivity } from 'models/connectivity/zoneConnectivity';
import { clearQueryCache } from 'utils/react-query';

import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';

function* fetchZoneStatistics(action: zoneActions.GetZoneStatistics) {
  const dmStatParams: GetDmStatParams = {
    ...action.filters,
    devices: [],
    projects: [],
    metrics: undefined,
  };
  const response: dmModels.ResponseStatistic = yield call(
    dmClient.fetchAllStatistics,
    dmStatParams,
  );
  const connectivity: ApiResponse<ZoneConnectivity[]> = yield call(fetchZonesAvgConn, action.filters as GetStatParams);
  if (response.reason === ResponseReason.Ok && response.data && connectivity.reason === ResponseReason.Ok && connectivity.data) {
    const data = {
      statAll: response.data as Statistic,
      connectivity: connectivity.data,
    };
    yield put(zoneActions.DoGetZoneStatisticsSuccess(data));
  } else {
    yield put(zoneActions.DoGetZoneStatisticsFailed());

    if (response.reason !== ResponseReason.Ok) {
      yield put(NotifyError(`Error while fetching zone statistics: ${ response.message || 'Server Error' }`));
    }

    if (connectivity.reason !== ResponseReason.Ok) {
      yield put(NotifyError(`Error while fetching zone connectivity: ${ connectivity.message || 'Server Error' }`));
    }
  }
}

type DeleteZone = zoneActions.DeleteZone;
type DeleteZoneResult = zoneActions.DeleteZoneResult;
type DeleteZoneSagaAction = (
  | DeleteZone
  | ActionWithPromise<DeleteZone, DeleteZoneResult>
);

function* deleteZoneSaga(action: DeleteZoneSagaAction) {
  const response: ApiResponse = yield call(dmClient.deleteZone, action.zoneId);

  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while deleting a zone: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message));
    return;
  }

  yield put(dataActions.ResetDMZoneData());
  yield clearQueryCache(['deviceManagement/zones']);
  'meta' in action && action.meta.promise.resolve();
}


type UpdateZone = zoneActions.UpdateZone;
type UpdateZoneResult = zoneActions.UpdateZoneResult;
type UpdateZoneSagaAction = (
  | UpdateZone
  | ActionWithPromise<UpdateZone, UpdateZoneResult>
);

function* updateZoneSaga(action: UpdateZoneSagaAction) {
  const response: ApiResponse = yield call(dmClient.updateZone, action.payload);

  if (response.reason !== ResponseReason.Ok) {
    const message = response.message || 'Server error';

    yield put(NotifyError(`Error while updating a zone: ${ message }`));

    'meta' in action && action.meta.promise.reject(new Error(message));
    return;
  }

  yield put(zoneActions.updateZoneSuccess(action.payload));
  yield put(dataActions.ResetDMZoneData());

  'meta' in action && action.meta.promise.resolve();
}

type AddZonesToProjectAction =
  | zoneActions.AddZonesToProject
  | ActionWithPromise<zoneActions.AddZonesToProject, dmModels.Zone[]>

function* doAddZonesToProject(action: AddZonesToProjectAction) {
  const { projectId } = action;
  const updated: dmModels.Zone[] = [];
  try {
    for (const zone of action.zones) {
      const update: dmModels.ZoneUpdateParams = {
        id: zone.id,
        props: { ...zone, project_id: projectId }
      };
      const response: ApiResponse = yield call(dmClient.updateZone, update);
      if (response.reason === ResponseReason.Ok) {
        updated.push({ ...zone, project_id: projectId });
      } else {
        throw createErrorFromApiResponse(response);
      }
    }

    'meta' in action && action.meta.promise.resolve(updated);
  } catch (e) {
    yield put(NotifyError(`Error while updating a zone: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject(e as Error);
  }

  for (const zone of updated) {
    yield put(zoneActions.updateZoneSuccess({ id: zone.id, props: zone }));
  }
  yield put(dataActions.ResetDMZoneData());
}

type CreateAction =
  | zoneActions.CreateZone
  | ActionWithPromise<zoneActions.CreateZone, zoneActions.CreateZoneResult>;

function* createZone(action: CreateAction) {
  const response: dmModels.ResponseZone = yield call(dmClient.createZone, action.payload);
  if (response.reason !== ResponseReason.Ok) {
    yield put(NotifyError(`Error while creating a zone: ${ response.message }`));
    yield put(zoneActions.DoCreateZoneFailed());

    'meta' in action && action.meta.promise.reject(new Error(response.message));
    return;
  }

  yield put(zoneActions.DoCreateZoneSuccess());
  yield put(dataActions.ResetDMZoneData());
  yield put(NotifySuccess('Zone has been created'));
  yield clearQueryCache(['deviceManagement/zones']);
  'meta' in action && action.meta.promise.resolve(response.data);
}

export const deviceManagementZonesSagas = [
  takeEvery(zoneActions.CREATE_ZONE, createZone),
  takeEvery(zoneActions.DELETE_ZONE, deleteZoneSaga),
  takeEvery(zoneActions.UPDATE_ZONE, updateZoneSaga),
  takeLeading(zoneActions.DM_ZONE_ADD_TO_PROJECT, doAddZonesToProject),
  takeEvery(zoneActions.FETCH_ZONE_STATISTICS, fetchZoneStatistics),
];
