import { Box } from '@material-ui/core';
import { SubscriptionType } from 'models/user-management/projectHealthSubscriptions';
import { useStyles } from '../styles';
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<SubscriptionType>, 'options'>>
  & (SingleProps<SubscriptionType> | MultiProps<SubscriptionType>)


export const SubscriptionTypeSelectControl = (props: Props) => {
  const classes = useStyles();
  const subscriptionOptions: Option<SubscriptionType>[] = [
    { label: 'Weekly', value: SubscriptionType.Weekly },
    { label: 'Daily', value: SubscriptionType.Daily },
  ];

  return (
    <Box width="200px" >
      <SelectControl
        name="subscriptionType"
        label=""
        {...props}
        options={ subscriptionOptions }
        isClearable
        ControlProps={{
          className: classes.noMarginForm,
        }}
      />
    </Box>
  );
};
