import { createStyles, makeStyles, Theme } from 'styles/utils';
import { fonts } from 'styles/themes/outer';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '300px',
    },
    cellName: {
      fontFamily: fonts.textPrimary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.43,
    },
    cellValue: {
      fontFamily: fonts.textSecondary,
      fontSize: '14px',
      lineHeight: 1.43,
    },
  }),
);

export default useStyles;
