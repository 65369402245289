import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RawMessagesFiltersQuery } from 'models/raw-messages';
import { apiRawMessagesPath } from 'routing/paths';

// components
import Link from '@material-ui/core/Link';

interface Props {
  label: React.ReactNode;
  params: Partial<RawMessagesFiltersQuery>;
}

export const RawMessageHistoryLink = ({ label, params }: Props): JSX.Element => {
  const path = apiRawMessagesPath(params);
  return (
    <Link
      component={ RouterLink }
      color="secondary"
      to={ path }
    >
      { label }
    </Link>
  );
};



