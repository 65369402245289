import { FlashStep } from './generated';
import { StationFrequency } from './StationFrequency';
import { StationGeneration } from './StationGeneration';
import { StationReceiverType } from './StationReceiverType';

export enum FlashStatus {
  PREPARE = 'Pending',
  INSTALL = 'InProgress',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  CANCEL = 'Cancelled',
}

export interface StationFlash {
  id: number;
  owner_id: number;
  generation: StationGeneration;
  receiver_type: StationReceiverType;
  frequency?: StationFrequency;

  execution_id: string;
  execution_status: string;
  execution_steps: FlashStep[]
}

export interface GetStationFlashParams {
  executionIds?: string[];
  stationIds?: number[];
  generation?: StationGeneration;
  ownerId?: number;
  offset?: number;
  limit?: number;
}

export interface StationFlashCreate {
  id: number,
  pin: string,
  ownerId: number,
  receiverType: StationReceiverType,
  frequency: StationFrequency,
}
