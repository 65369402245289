import React, { memo } from 'react';
import { useProvisioningShipmentSelector } from 'hooks/provisioning';
import { provisioningPersonalShipmentPath } from 'routing/paths';

// components
import { Link } from '@material-ui/core';
import { CustomLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  id: string;
  withFetch?: boolean;
}

const ShipmentName = ({ id, withFetch = false }: Props) => {
  const { isLoading, shipment } = useProvisioningShipmentSelector(id, withFetch);

  if (isLoading) {
    return (
      <LoaderSvg/>
    );
  }

  if (!shipment) {
    return (
      <span>—</span>
    );
  }

  return (
    <Link
      color="secondary"
      component={ CustomLink }
      to={ provisioningPersonalShipmentPath(id) }
    >
      { shipment.name || '-' }
    </Link>
  );
};

export default memo(ShipmentName);
