import { NotificationMethod, ResponsiblePerson, StationLocation } from 'models/base-station';
import { useAuthUserSelector } from 'hooks';
import { useStationResponsiblePersonsDictionary } from 'hooks/station';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { useUsersDictionary } from 'utils/dictionary';
import { User, UserGroup, UserRoleEnum } from 'models/user-management';
import { Address } from './types';

export const formatLocationTitle = (location: StationLocation): string =>
  [location.country, location.city, location.address]
    .filter(v => !!v)
    .join(', ');

type AvailablePerson = ResponsiblePerson & {
  userOwner?: string;
  personOwner?: string;
}

export function useAvailableResponsiblePerson(location: StationLocation): AvailablePerson[] {
  const { responsiblePersons: persons } = useStationResponsiblePersonsDictionary();
  const users = useUsersDictionary();
  const authUser = useAuthUserSelector();
  const { userGroups } = useUserGroupsDictionarySelector();
  const ownerId = Number(location.owner_id);
  const availablePersons: AvailablePerson[] = authUser.isAdmin
    ? getPersonsForAdmin(persons, users, [ownerId, Number(authUser.data.user_group)])
    : getPersons(persons, users, ownerId);

  if (authUser.isAdmin) {
    addOwnerNamesToPersons(availablePersons, users, userGroups);
  }

  moveUserOnTop(String(authUser.data.sub), availablePersons);
  return availablePersons
    .filter(p => !p.locations.some(l => l.id === location.id));
}

const addOwnerNamesToPersons = (persons: AvailablePerson[], users: User[], userGroups: UserGroup[]): void => {
  persons.forEach(person => {
    person.personOwner = userGroups.find(group => group.id === person.owner_id)?.name ?? 'Unknown';
    if (person.registered_user_id) {
      const user = users.find(user => user.sub === person.registered_user_id);
      person.userOwner = userGroups.find(group => user && group.id === user.user_group)?.name ?? 'Unknown';
    }
  });
};

const moveUserOnTop = (userId: string, persons: ResponsiblePerson[]): void => {
  const authUserIndex = persons.findIndex(p => p.registered_user_id === userId);
  if (authUserIndex > 0) {
    const authUserPerson = persons[authUserIndex];
    persons.splice(authUserIndex, 1);
    persons.unshift(authUserPerson);
  }
};

export const getPersons = (persons: ResponsiblePerson[], users: User[], ownerId: number): ResponsiblePerson[] => {
  const result = [...persons];
  for (const user of users) {
    const person = persons.find(p => p.registered_user_id === user.sub);
    if (person || user.user_group !== ownerId) {
      continue;
    }

    const isAdmin = user.role === UserRoleEnum.Admin;
    result.push({
      id: 0,
      first_name: user.first_name,
      last_name: user.last_name,
      owner_id: user.user_group,
      role: isAdmin ? 'Administrator' : 'Console User',
      notify: true,
      notification_method: NotificationMethod.Email,
      notification_endpoint: { email: user.email },
      registered_user_id: user.sub,
      locations: []
    });
  }

  return result.filter(p => p.owner_id === ownerId);
};

export const getPersonsForAdmin = (persons: ResponsiblePerson[], users: User[], ownerIds: number[],): ResponsiblePerson[] => {
  const result = [...persons];
  for (const user of users) {
    const person = persons.find(p => p.registered_user_id === user.sub);
    if (person) {
      continue;
    }

    const isAdmin = user.role === UserRoleEnum.Admin;
    if (!ownerIds.includes(user.user_group) && !isAdmin) {
      continue;
    }

    result.push({
      id: 0,
      first_name: user.first_name,
      last_name: user.last_name,
      owner_id: user.user_group,
      role: isAdmin ? 'Administrator' : 'Console User',
      notify: true,
      notification_method: NotificationMethod.Email,
      notification_endpoint: { email: user.email },
      registered_user_id: user.sub,
      locations: []
    });
  }

  return result.filter(p => ownerIds.includes(p.owner_id) || isRegisteredAsAdmin(p, users));
};

const isRegisteredAsAdmin = (person: ResponsiblePerson, users: User[]): boolean => {
  if (!person.registered_user_id) {
    return false;
  }
  const user = users.find(u => u.sub === person.registered_user_id);
  if (!user) {
    return false;
  }

  return user.role === UserRoleEnum.Admin;
};

export const isEmptyLocation = (location: StationLocation): boolean => (
  location.country === ''
  && location.city === ''
  && location.address === ''
);

export const changeLocationByAddress = (location: StationLocation, address: Address): StationLocation => {
  return {
    ...location,
    lat: address.lat,
    lon: address.lon,
    country: address.country ?? location.country,
    city: address.city ?? location.city,
    address: `${ address.building ?? '' } ${ address.street ?? '' }`.trim() || location.address,
    postcode: address.postcode ?? location.postcode,
  };
};

export const getAddressByLocation = (location?: StationLocation): Address | undefined => {
  if (location?.lat === undefined || location?.lon === undefined) {
    return undefined;
  }

  return {
    id: String(location.id),
    title: [location.country, location.city, location.address].filter(Boolean).join(', '),
    lat: location.lat,
    lon: location.lon,
    country: location.country,
    city: location.city,
    street: location.address,
    postcode: location.postcode,
  };
};
