import React from 'react';
import { useForm, useFormActionLoader } from 'hooks';
import { Group, GroupType, Level, Zone } from 'models/device-management';
import { DoCreateGroup } from 'actions/device-management/groups';

// components
import { Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { GroupForm, GroupFormField, GroupFormState } from 'components/DeviceManagement/Forms';

interface Props {
  zone: Zone;
  level?: Level;
  withDisabledParent?: boolean;
  onSuccess?: (group: Group) => void;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const AddLevelGroupDialog = (props: Props): JSX.Element => {
  const { isOpen, zone, level, withDisabledParent, onSuccess, onClose, onCloseEnd } = props;
  const { doAction } = useFormActionLoader();
  const form = useForm<GroupFormState, GroupFormField>({
    initialState: {
      [GroupFormField.zone]: zone.id,
      [GroupFormField.level]: level?.id,
      [GroupFormField.type]: GroupType.MarkedParkingBay,
    },
    showFields: [
      GroupFormField.name,
      GroupFormField.type,
      GroupFormField.customId,
      ...(withDisabledParent ? [GroupFormField.zone, GroupFormField.level] : []),
    ],
  });

  const handleAdd = (): boolean => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: DoCreateGroup([
        {
          zone_id: zone.id,
          level_id: level?.id,
          name: String(form.state[GroupFormField.name]),
          type: form.state[GroupFormField.type],
          custom_id: String(form.state[GroupFormField.customId]),
        }
      ]),
      onSuccess: (group: Group) => {
        onSuccess && onSuccess(group);
        onClose();
      },
    });

    return true;
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      testId="dm-level-add-group-dialog"
      title="Add group"
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      content={
        <GroupForm
          { ...form }
          disabledFields={ withDisabledParent ? [GroupFormField.zone, GroupFormField.level] : [] }
        />
      }
      actions={
        <>
          <Button onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ handleAdd }>Add</Button>
        </>
      }
    />
  );
};