import { isEqual } from 'lodash';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

type DepsEqualFnType<TDeps extends DependencyList> = (prevDeps: TDeps, nextDeps: TDeps) => boolean;

/**
 * The same as `useCustomCompareEffect` from "react-use",
 * but it doesn't trigger `console.warn` on *every single render* if its deps are primitive.
 * It's because sometimes primitive deps are ok e.g. `undefined` while something is loading.
 */
const useCustomCompareEffect = <TDeps extends DependencyList>(
  effect: EffectCallback,
  deps: TDeps,
  depsEqual: DepsEqualFnType<TDeps>
) => {
  const ref = useRef<TDeps | undefined>(undefined);

  if (!ref.current || !depsEqual(deps, ref.current)) {
    ref.current = deps;
  }

  useEffect(effect, [ref.current, effect]);
};

/**
 * The same as `useDeepCompareEffect` from "react-use",
 * but it doesn't trigger `console.warn` on *every single render* if its deps are primitive.
 * It's because sometimes primitive deps are ok e.g. `undefined` while something is loading.
 */
export const useDeepCompareEffect = (effect: EffectCallback, deps: DependencyList) => {
  useCustomCompareEffect(effect, deps, isEqual);
};

/**
 * Call `callback` automatically once per `intervalMs`.
 *
 * - if tab isn't active (`document.hidden === true`), it doesn't call `callback`
 * - when tab becomes active again, it calls `callback` and restarts the interval
 */
export const useAutoRunWhenVisible = (callback: () => void, intervalMs = 30_000) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let intervalId: number | undefined = window.setInterval(intervalCallback, intervalMs);

    function intervalCallback() {
      if (!document.hidden) {
        callbackRef.current();
        return;
      }

      clearInterval(intervalId);
      intervalId = undefined;
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    function handleVisibilityChange() {
      if (document.hidden) {
        return;
      }

      callbackRef.current();
      intervalId = window.setInterval(intervalCallback, intervalMs);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      clearInterval(intervalId);
      intervalId = undefined;
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [intervalMs]);
};

/**
 * Call `callback` on window focus event
 */
export const useFocusEffect = (callback: () => void): void => {
  useEffect(() => {
    window.addEventListener('focus', callback);
    return () => {
      window.removeEventListener('focus', callback);
    };
  }, [callback]);
};
