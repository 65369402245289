import { useLevelsSelector } from 'hooks/device-management/levels';
import { Level } from 'models/device-management';

// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type LevelSelectProps =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & {
    zoneIds: number[];
    filter?: (level: Level) => boolean;
  }

export const LevelsFilterSelect = (props: LevelSelectProps): JSX.Element => {
  const { zoneIds } = props;
  const { levels, isLoading } = useLevelsSelector({
    params: { zones: zoneIds ? zoneIds : [] },
    skipLoading: !zoneIds,
  });

  const filterCallback = props.filter ?? (() => (true));

  const options: Option<number>[] =
    levels
      .filter(filterCallback)
      .filter(level => props.zoneIds.includes(level.zone_id))
      .map(level => ({
        value: Number(level.id),
        label: level.name,
      }));

  return (
    <SelectControl
      name="levelId"
      label={ 'Levels' }
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ levels.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
