/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteConfig, RouteConfigComponentGetter, RouteConfigComponentProps, } from 'react-router-config';

// components
import { Route, RouteProps, Switch, SwitchProps } from 'react-router-dom';
import { NoMatch } from 'pages';

function identity<T>(x: T): T {
  return x;
}

export interface Options {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraProps?: any;
  getChildRoutes?: (props: RouteConfigComponentProps) => RouteConfig[];
  getRouteComponent?: RouteConfigComponentGetter;
  getRouteProps?: (routeProps: RouteProps) => RouteProps;
  renderRouteChildren?: (routeProps: RouteConfigComponentProps) => React.ReactNode;
  routes: RouteConfig[] | undefined;
  switchProps?: SwitchProps;
  switchRendered?: boolean;
}

/**
 * It's similar to `react-router-config`'s `renderRoutes()` with a few differences:
 * - if `route.render` and route component are falsy, it renders the routes **recursively**
 * - it receives an options object instead of unnamed arguments list
 * - it supports `options.getRouteComponent` to configure the route component property
 * - it supports `options.getChildRoutes` to configure the child routes
 */
export function renderRoutes(options: Options) {
  const {
    extraProps,
    getChildRoutes = () => [],
    getRouteProps = identity,
    renderRouteChildren,
    routes,
    switchProps,
    switchRendered = true,
  } = options;

  if (!routes) {
    return null;
  }

  const Wrapper = switchRendered ? Switch : React.Fragment;

  return (
    <Wrapper { ...(switchRendered ? switchProps : {}) }>
      { routes.map((route, i) => (
        <Route
          key={ route.key || i }
          { ...getRouteProps({
            path: route.path,
            exact: route.exact,
            strict: route.strict,
            render: (props) => {
              const render = renderRouteChildren || route.render;
              // set isShowByUrl default value
              const isShowByUrl = route.isShowByUrl ?? true;
              if (render && isShowByUrl) {
                const renderResult = render({ ...props, ...extraProps, route: route });
                if (renderResult) {
                  return renderResult;
                }
              }

              const getRouteComponent = (
                route.getRouteComponent ||
                options.getRouteComponent
              );
              const Component = (
                getRouteComponent &&
                getRouteComponent({ route, ...props })
              );

              if (isShowByUrl && Component) {
                return (
                  <Component
                    { ...props }
                    { ...extraProps }
                    route={ route }
                  />
                );
              }

              if (!isShowByUrl) {
                return <NoMatch />;
              }

              const childRoutes = getChildRoutes({ route, ...props });

              return renderRoutes({
                ...options,
                routes: childRoutes,
              });
            },
          }) }
        />
      )) }
    </Wrapper>
  );
}

