import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { VirtualDevicesFiltersFields } from 'models/device-management';

// components
import { FilterButton } from 'components/Buttons';
import { VirtualDevicesFilter } from 'components/DeviceManagement/Devices/VitrualDevicesTableFilter/VirtualDevicesFilterComponent';


interface Props {
  isLoading: boolean;
  filters: VirtualDevicesFiltersFields;
  onSave: (filter: VirtualDevicesFiltersFields) => void;
  onReset: () => void;
}

export const FiltersDialog = ({
  isLoading,
  filters,
  onSave,
  onReset,
}: Props): JSX.Element => {
  const [state, setState] = useState<VirtualDevicesFiltersFields>(filters);
  useDeepCompareEffect(() => {
    setState(filters);
  }, [filters]);

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filters }
      onSave={ () => { onSave(state); }}
      onReset={ onReset }
    >
      <VirtualDevicesFilter state={ state } setState={ setState }/>
    </FilterButton>
  );
};