import { FlashStatus, StationEntity } from 'models/base-station';
import { State as UserState } from 'reducers/user-profile';
import { canEditStation } from 'utils/permissions';
import { StationStatusFlat } from 'models/base-station-status';

export function canCancelFlashStation(user: UserState, station: StationEntity): boolean {
  return (
    canEditStation(user)
    && station.type === 'flash'
    && [FlashStatus.PREPARE, FlashStatus.INSTALL, FlashStatus.FAILED].includes(station.flash.execution_status as FlashStatus)
  );
}

export function getMarkerColor(status?: StationStatusFlat) {
  if (!status) {
    return 'gray';
  }

  if (!status.isOffline && !status.hasIssues) {
    // green
    return '0x2CE804';
  }

  return 'red';
}
