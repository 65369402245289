import { BaseTree } from '../base/types';
import { DmTreeNode, Filters } from '../types';

export enum StartFrom {
  POSITION = 0,
  GROUP = 1,
  LEVEL = 2,
  ZONE = 3
}

export interface ChildrenData {
  children: DmTreeNode[];
  childrenTotal: number;
}

export interface TreeInitializationOptions {
  filters: Filters;
  getTree: () => BaseTree;
  setTree: (updater: (tree: BaseTree) => BaseTree) => void;
  updateTree: (updater: (tree: BaseTree) => void) => void;
  getSubtree: SubtreeManagementOptions['getSubtree'];
  updateSubtree: SubtreeManagementOptions['updateSubtree'];
}

interface Subtree {
  children: DmTreeNode[];
}

export interface SubtreeManagementOptions {
  getSubtree: () => Subtree | undefined;
  updateSubtree: (updater: (subtree: Subtree) => void) => void;
  getNodes?: (nodes: DmTreeNode[]) => DmTreeNode[];
}
