import React from 'react';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { checkTimeFromToRelation, DATE_WITH_MINUTES_FORMAT } from 'utils/datetime';
// components
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornment, IconButton } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';

import { staticEnv } from 'env';
// styles
import contentStyles from 'styles';
import clsx from 'clsx';

interface Props {
  label: string;
  value?: Date | null;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange: (date?: Date) => void;
  error?: string;
  helperText?: string;
  hideErrorsFromHelperText?: boolean;
  isClearable?: boolean;
}

export const DateTimeControl = (props: Props): JSX.Element => {
  const classes = contentStyles();

  const onChange = (date: MaterialUiPickersDate) => {
    props.onChange(date ? date.toDate() : undefined);
  };

  const error = props.error
    || (props.maxDate && props.value && checkTimeFromToRelation(props.maxDate, props.value))
    || (props.minDate && props.value && checkTimeFromToRelation(props.value, props.minDate));

  const Picker = staticEnv.AUTOTEST ? KeyboardDateTimePicker : DateTimePicker;

  return (
    <MuiPickersUtilsProvider utils={ MomentUtils }>
      <Picker
        data-testid="datetime-control"
        label={ props.label }
        className={ clsx(classes.formControl, props.className) }
        error={ !!error }
        helperText={ props.hideErrorsFromHelperText ? props.helperText : props.error || error || props.helperText }
        minutesStep={ 1 }
        maxDate={ props.maxDate ?? undefined }
        minDate={ props.minDate ?? undefined }
        autoOk={ false }
        disableFuture={ true }
        hideTabs={ true }
        ampm={ false }
        disabled={ props.isDisabled }
        required={ props.isRequired }
        value={ props.value ? props.value : null }
        format={ DATE_WITH_MINUTES_FORMAT }
        onChange={ onChange }
        allowKeyboardControl={ false }
        variant="dialog"
        fullWidth
        margin="normal"
        clearable={ props.isClearable }
        InputProps={ {
          endAdornment: !props.isDisabled && (
            <InputAdornment position="end">
              <IconButton>
                <DateRange />
              </IconButton>
            </InputAdornment>
          ),
        } }
      />
    </MuiPickersUtilsProvider>
  );
};
