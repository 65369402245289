/* eslint-disable @typescript-eslint/indent */
import { sum } from 'lodash';
import moment from 'moment';

import { useCustomMemo } from 'hooks';
import { useCallerRequestDeliveryHistory } from 'hooks/caller';
import { CallerRequestDeliveryHistoryParams, DeliveryStatus } from 'models/caller-monitoring';

import { MonitoringSerie } from './utils';

type CountByDateByDeliveryStatus = Record<DeliveryStatus, Map<string, number>>;

export type CountByTemplateByDateByDeliveryStatus = Record<
  DeliveryStatus,
  Map<
    string,
    Map<
      number,
      number
    >
  >
>;

const DeliveryStatusSerieColor: { [key in DeliveryStatus]: string } = {
  [DeliveryStatus.Success]: '#00838f',
  [DeliveryStatus.Failed]: '#f9a825',
  [DeliveryStatus.Error]: '#d84315',
};

const DeliveryStatusLabel: Record<DeliveryStatus, string> = {
  Error: 'Error',
  Failed: 'Not reached',
  Success: 'Success',
};

export function formatDeliveryStatus(status: string) {
  if (status in DeliveryStatusLabel) {
    return DeliveryStatusLabel[status as DeliveryStatus];
  }

  return status;
}

interface RequestDeliveryHistoryChartData {
  isLoading: boolean;
  data: MonitoringSerie[];
  maxValue?: number;
  countByTemplateByDateByDeliveryStatus: CountByTemplateByDateByDeliveryStatus;
}

export const useRequestDeliveryHistoryChartData = (params: CallerRequestDeliveryHistoryParams): RequestDeliveryHistoryChartData => {
  const history = useCallerRequestDeliveryHistory(params);

  const result = useCustomMemo(() => {
    const countByTemplateByDateByDeliveryStatus: CountByTemplateByDateByDeliveryStatus = {
      [DeliveryStatus.Success]: new Map(),
      [DeliveryStatus.Failed]: new Map(),
      [DeliveryStatus.Error]: new Map(),
    };

    if (history.isLoading) {
      return {
        isLoading: true,
        data: [],
        countByTemplateByDateByDeliveryStatus,
      };
    }

    const countByDateByDeliveryStatus: CountByDateByDeliveryStatus = {
      [DeliveryStatus.Success]: new Map(),
      [DeliveryStatus.Failed]: new Map(),
      [DeliveryStatus.Error]: new Map(),
    };

    for (const [templateId, templateHistory] of history.historyByTemplate.entries()) {
      for (const [statusRaw, statusHistory] of Object.entries(templateHistory)) {
        const deliveryStatus = statusRaw as DeliveryStatus;

        for (const record of statusHistory) {
          const { date, count } = record;
          const countByDate = countByDateByDeliveryStatus[deliveryStatus];
          countByDate.set(date, (countByDate.get(date) ?? 0) + count);

          const countByTemplateByDate = countByTemplateByDateByDeliveryStatus[deliveryStatus];
          const countByTemplate = countByTemplateByDate.get(date) ?? new Map<number, number>();
          countByTemplate.set(templateId, (countByTemplate.get(templateId) ?? 0) + count);
          countByTemplateByDate.set(date, countByTemplate);
        }
      }
    }

    const series: MonitoringSerie[] = Object.entries(countByDateByDeliveryStatus)
      .map(([deliveryStatus, countByDate]) => {
        const now = moment();

        return {
          id: deliveryStatus,
          data: [...countByDate.entries()].map(([date, count]) => ({
            x: moment(date).format('YYYY-MM-DD HH:mm'),
            y: count || (now.isBefore(date) ? null : count),
          })),
          total: sum([...countByDate.values()]),
          label: formatDeliveryStatus(deliveryStatus),
          color: DeliveryStatusSerieColor[deliveryStatus as DeliveryStatus],
        };
      });

    const values = series.flatMap(serie =>
      serie.data.flatMap(datum =>
        typeof datum.y === 'number' ? [datum.y] : []
      )
    );

    return {
      isLoading: false,
      maxValue: values.length ? Math.max(...values) : undefined,
      data: series,
      countByTemplateByDateByDeliveryStatus,
    };
  }, [history.historyByTemplate, history.isLoading]);

  return result;
};
