import { InputProps } from 'components/Form/Field/utils';
import { createField } from './createField';

export type { InputProps };

/**
 * Creates a field given an input component which receives `InputProps`.
 *
 * If you just want to wrap your underlying input, you can use the following syntax
 * without any type arguments or something:
 *
 * ```
 *  createFieldFromRegularInput(MyInput);
 * ```
 *
 * If you want to transform props which `Field` passes to the underlying input component
 * via an additional wrapper, you may need to extend the underlying input component's props
 * with `InputProps` like this:
 *
 * ```
 *  import { TextField, TextFieldProps } from '@material-ui/core';
 *  import { InputProps, createFieldFromRegularInput } from 'components/Form/Field/field-creators';
 *
 *  createFieldFromRegularInput<TextFieldProps & InputProps>(
 *  //        `InputProps` are injected by Field ^^^^^^^^^^
 *    ({ errors, ...props }) => (
 *  //   ^^^^^^ `errors` prop is injected by Field, and we access it here in a wrapper
 *      <TextField {...props} {...getMuiErrorProps(errors)} />
 *    ),
 *  );
 * ```
 *
 * Otherwise, TS will mark these injected props potentially undefined or nonexistent at all.
 */
export function createFieldFromRegularInput<
  TInputProps extends Partial<InputProps>
>(Input: React.ComponentType<TInputProps>) {
  type WrappedInputType = React.ComponentType<TInputProps & InputProps>;

  return createField<TInputProps, TInputProps['onChange']>(Input as WrappedInputType);
}
