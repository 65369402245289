/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Theme as DefaultTheme } from '@material-ui/core/styles';
import { makeStyles as muiMakeStyles } from '@material-ui/core/styles'; // eslint-disable-line no-restricted-imports
import { Styles, WithStylesOptions } from '@material-ui/styles/withStyles';
import { Omit } from '@material-ui/types';

/**
 * The same as Material UI's makeStyles() but with `index: 1` option passed by default
 */
export function makeStyles<
  Theme = DefaultTheme,
  Props extends Record<string, unknown> = Record<string, unknown>,
  ClassKey extends string = string
>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
) {
  return muiMakeStyles(styles, { index: 1, ...options });
}
