import React, { ReactNode, useState } from 'react';
import { EditButton } from 'components/Buttons';
import { EditActionParams, useFormEditActions } from './useFormEditActions';

interface EditToogleParams extends EditActionParams {
  onEdit?: () => void;
  onSave?: () => void | boolean; // return false to stay on edit moode
  onCancel?: () => void | boolean; // return false to stay on edit moode
}

interface EditToogleState {
  editMode: boolean;
  actions: ReactNode;
}

export function useFormEditToogleActions(params: EditToogleParams): EditToogleState {
  const [editMode, setEditMode] = useState<boolean>(false);
  const actions = useFormEditActions({
    ...params,
    onSave: () => {
      const closeEdit = params.onSave && params.onSave();
      setEditMode(closeEdit === undefined ? false : !closeEdit);
    },
    onCancel: () => {
      const closeEdit = params.onCancel && params.onCancel();
      setEditMode(closeEdit === undefined ? false : !closeEdit);
    },
  });

  const handleEdit = () => {
    params.onEdit && params.onEdit();
    setEditMode(true);
  };

  if (!params.canEdit) {
    return { editMode: false, actions: <></> };
  }

  return {
    editMode: editMode,
    actions: editMode
      ? actions
      : <EditButton type="icon" onClick={ handleEdit } />
  };
}

