import { API } from 'aws-amplify';
import { ApiResponse, ApiResponseWithTotal } from 'models';
import { errorHandler, formatQuery } from 'clients/utils';
import { config, urls } from './config';
import { CreateProjectParams, EditProjectParams } from 'models/projects';
import { GetProjectsParams, Project } from 'models/user-management';

export const fetchProjects = (params: GetProjectsParams): Promise<ApiResponseWithTotal<Project[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      id: params.ids,
      owner_id: params.ownerId,
      name: params.name,
      limit: params.limit,
      offset: params.offset,
    }),
  };
  return API
    .get(config.name, urls.projects, body)
    .catch(errorHandler);
};

export const fetchProjectById = (id: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.projectById(id), {})
    .catch(errorHandler);
};

export const createProject = (params: CreateProjectParams): Promise<ApiResponse> => {
  const parameters = {
    body: {
      name: params.name,
      timezone: params.timezone,
      owner_id: params.owner_id,
    },
  };
  return API
    .post(config.name, urls.projects, parameters)
    .catch(errorHandler);
};

export const editProject = (params: EditProjectParams): Promise<ApiResponse> => {
  const parameters = {
    body: {
      name: params.name,
      timezone: params.timezone,
      owner_id: params.owner_id
    },
  };
  return API
    .put(config.name, urls.projectById(params.id), parameters)
    .catch(errorHandler);
};

export const deleteProject = (id: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.projectById(id), {})
    .catch(errorHandler);
};
