import React, { useState } from 'react';

import { User } from 'models/user-management';

// components
import { FieldSkeleton } from 'components/Skeleton';

import AccountForm from './AccountForm';
import AccountReport from './AccountReport';

interface AccountProps {
  user?: User;
}

const Account: React.FC<AccountProps> = (props) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    const { user } = props;

    if (!user) {
      return <FieldSkeleton />;
    }

    return (
      <AccountForm
        {...props}
        user={user}
        onEditComplete={() => setEditing(false)}
      />
    );
  }

  return <AccountReport {...props} onEdit={() => setEditing(true)} />;
};

export default Account;
