import { combineReducers } from 'redux';

import { reducer as deviceSettingsReducer, State as DeviceSettingsState } from './device-settings';

export interface State {
  deviceSettings: DeviceSettingsState;
}

export const reducer = combineReducers({
  deviceSettings: deviceSettingsReducer
});
