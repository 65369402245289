import { FormErrors } from 'types/form';
import { validateUrlHost } from 'utils/validator';

import { Field, TemplatePresetFormState } from './CallerTemplatePresetForm';

export const validate = (state: TemplatePresetFormState, fields: Field[]): FormErrors<Field> => {
  const errors: FormErrors<Field> = {};

  if (fields.includes(Field.templateSuiteId)) {
    if (!state.template_suite_id) {
      errors.template_suite_id = 'Suite is required';
    }
  }

  // We show an error notification instead to make the error more noticeable
  // if (fields.includes(Field.presetId)) {
  //   if (!state.preset_id) {
  //     errors.preset_id = 'Preset is required';
  //   }
  // }

  if (fields.includes(Field.url)) {
    if (!state.url.trim()) {
      errors.url = 'URL is required';
    } else if (!validateUrlHost(state.url)) {
      errors.url = 'URL is not valid';
    }
  }

  return errors;
};
