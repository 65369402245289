import { RootState as State } from 'reducers';
import { ExistingPosition } from 'models/device-management';

function getPositionsState(state: State) {
  return state.deviceManagement.positions;
}

export function arePositionsLoading(state: State): boolean {
  const positionsState = getPositionsState(state);
  return positionsState.positionsFetching;
}

export function getPosition(
  positionId: ExistingPosition['id'],
  state: State,
): ExistingPosition | null | undefined {
  const positionsState = getPositionsState(state);
  return positionsState.positionsById[positionId];
}

export function getPositionsArray(state: State): ExistingPosition[] {
  return Object.values(getPositionsState(state).positionsById).filter(x => x) as ExistingPosition[];
}
