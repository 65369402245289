import React from 'react';

import { useAuthUserSelector, useDialog } from 'hooks';
import { Template } from 'models/caller';
import { isAllowedToUpdateCaller } from 'utils/permissions';

import { useManualForm } from './useManualForm';

// components
import { Button } from '@material-ui/core';

import { CallerTemplateForm } from 'components/Caller';
import { CommonDialog, EnsureDialog } from 'components/Dialogs';
import { FieldSkeleton } from 'components/Skeleton';

// styles
import { useTemplateDialogStyles } from './styles';

interface EditTemplateDialogText {
  title?: string;
  submitButton?: string;
}

interface EditTemplateDialogProps {
  template?: Template;
  templateLoading?: boolean;
  templateSuiteId: number;
  isOpen: boolean;
  text?: EditTemplateDialogText;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const EditTemplateDialog = ({
  template,
  templateLoading,
  templateSuiteId,
  isOpen,
  text,
  onClose,
  onCloseEnd,
}: EditTemplateDialogProps) => {
  const classes = useTemplateDialogStyles();
  const user = useAuthUserSelector();

  const manualForm = useManualForm({
    type: 'edit',
    template,
    templateSuiteId,
    onSuccess: onClose,
  });

  const inactiveTemplateDialog = useDialog();
  const submitForm = (inactiveTemplateConfirmed = false) => {
    if (!manualForm.form.validate()) {
      return;
    }

    if (!manualForm.form.state.is_active && !inactiveTemplateConfirmed) {
      inactiveTemplateDialog.open();
      return;
    }

    return manualForm.submit();
  };

  const editable = isAllowedToUpdateCaller(user.data);

  return (
    <>
      <CommonDialog
        PaperProps={ { className: classes.paper } }
        title={ text?.title ?? (
          editable
            ? 'Edit Template'
            : 'Template Options'
        ) }
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
        content={
          template
            ? (
              <CallerTemplateForm
                isView={ !editable }
                messageTypeNotEditable={ manualForm.messageTypeNotEditable }
                { ...manualForm.form }
              />
            )
            : templateLoading
              ? <FieldSkeleton />
              : `Template not found`
        }
        actions={
          <>
            <Button color="primary" onClick={ onClose }>
              Cancel
            </Button>

            { template && editable && (
              <Button
                data-testid="edit-template-submit"
                color="primary"
                variant="contained"
                onClick={ () => submitForm(false) }
              >
                { text?.submitButton ?? 'Save' }
              </Button>
            ) }
          </>
        }
      />

      { inactiveTemplateDialog.isMounted && (
        <EnsureDialog
          name="confirm-inactive-template"
          isOpen={ inactiveTemplateDialog.isOpen }
          onClose={ inactiveTemplateDialog.close }
          onCloseEnd={ inactiveTemplateDialog.unmount }
          onYes={ () => {
            submitForm(true);
            inactiveTemplateDialog.close();
          } }
        >
          Are you sure you want to save template as inactive?
        </EnsureDialog>
      ) }
    </>
  );
};
