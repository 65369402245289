import React, { ReactNode } from 'react';
import { Tree, TreeNode } from 'hooks/device-management/useTree/types';
// components
import { InlineLoader } from 'components/Loaders';

interface Props {
  tree: Tree;
  node: TreeNode;
  emptyValue?: ReactNode;
}

export const ConnectivityCell = ({ tree, node, emptyValue }: Props): JSX.Element => {

  if (!tree.loaded.deviceConnect) {
    return <InlineLoader/>;
  }

  const connects = calculateConnects(node);
  if (!connects.length) {
    return <>{ emptyValue }</>;
  }

  const connectivity = connects.reduce((total, value) => total + value, 0) / connects.length;
  return (
    <>{ +connectivity.toFixed(2) }%</>
  );
};

const calculateConnects = (node: TreeNode): number[] => {
  switch (node.type) {
    case 'zone':
      const zoneConn: number[] = [];
      Object.values(node.children.items)
        .forEach(levelNode => zoneConn.push(...calculateConnects(levelNode)));

      return zoneConn;
    case 'level':
      const levelConn: number[] = [];
      Object.values(node.children.items)
        .forEach(groupNode => levelConn.push(...calculateConnects(groupNode)));

      return levelConn;
    case 'group':
      return Object.values(node.deviceConnects.data).map(deviceCon => deviceCon.connectivity);
    case 'position':
      return node.deviceConnect ? [node.deviceConnect.connectivity] : [];
  }
};