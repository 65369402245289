import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Template } from 'models/caller';
import { CallerRequestDeliveryHistoryParams, DeliveryStatus } from 'models/caller-monitoring';

import {
  formatDeliveryStatus,
  useRequestDeliveryHistoryChartData,
  CountByTemplateByDateByDeliveryStatus,
} from './useRequestDeliveryHistoryChartData';
import { formatTemplate } from './utils';

// components
import { PointTooltip, PointTooltipProps } from '@nivo/line';

import { MonitoringChartTooltip } from './MonitoringChartTooltip';
import { MonitoringLineChart } from './MonitoringLineChart';

interface TooltipProps extends PointTooltipProps {
  countByTemplateByDateByDeliveryStatus?: CountByTemplateByDateByDeliveryStatus;
  templateById: Record<number, Template>;
}

const Tooltip = ({ point, countByTemplateByDateByDeliveryStatus, templateById }: TooltipProps) => {
  const deliveryStatus = point.serieId as DeliveryStatus;
  const date = (point.data.x as Date).toJSON();
  const countByTemplate = countByTemplateByDateByDeliveryStatus?.[deliveryStatus].get(date)?.entries();

  return (
    <MonitoringChartTooltip
      date={ date }
      entries={ [...(countByTemplate ?? [])].map(([templateId, count]) => ({
        id: templateId,
        value: count,
        label: formatTemplate(templateById, templateId),
      })) }
      total={ {
        label: formatDeliveryStatus(point.serieId as string),
        count: point.data.y as number,
        color: point.serieColor,
      } }
      showTime
    />
  );
};

interface RequestDeliveryHistoryChartProps {
  filters: CallerRequestDeliveryHistoryParams;
  templateById: Record<number, Template>;
}

export const RequestDeliveryHistoryChart = ({ filters, templateById }: RequestDeliveryHistoryChartProps) => {
  const data = useRequestDeliveryHistoryChartData(filters);

  const [tooltip, setTooltip] = useState<PointTooltip>(() => (props: PointTooltipProps) => (
    <Tooltip
      { ...props }
      countByTemplateByDateByDeliveryStatus={ data.countByTemplateByDateByDeliveryStatus }
      templateById={ templateById }
    />
  ));

  useEffect(() => {
    if (!data.isLoading) {
      setTooltip(() => (props: PointTooltipProps) => (
        <Tooltip
          { ...props }
          countByTemplateByDateByDeliveryStatus={ data.countByTemplateByDateByDeliveryStatus }
          templateById={ templateById }
        />
      ));
    }
  }, [data.isLoading, data.countByTemplateByDateByDeliveryStatus, templateById]);

  return (
    <MonitoringLineChart
      data={ data.data }
      isLoading={ data.isLoading }
      maxValue={ data.maxValue }
      colors={ data.data.map(serie => serie.color) }
      enableSlices={ false }
      useMesh
      tooltip={ tooltip }
      durationDays={ moment.duration(moment(filters.dateTo).diff(filters.dateFrom)).asDays() }
    />
  );
};
