import * as groupsActions from 'actions/device-management/groups';
import { GroupState } from 'models/device-management';

const initState = {
  data: [],
  groupsById: [],
  groupsByPositionIds: [],
  isFetching: false,
  filters: {
    owner: undefined,
    zones: [],
    groups: [],
    projects: [],
    groupType: undefined,
  },
};

export const reducer = (state: GroupState = initState, action: groupsActions.Action): GroupState => {
  switch (action.type) {
    case groupsActions.DELETE_GROUP_SUCCESS:
      const index = state.data.findIndex(group => group.id === action.groupId);
      const data = [...state.data];
      const groupsById = { ...state.groupsById };
      if (index >= 0) {
        data.splice(index, 1);
      }

      if (groupsById[action.groupId]) {
        delete groupsById[action.groupId];
      }

      return {
        ...state,
        data,
        groupsById
      };
    default: return state;
  }
};
