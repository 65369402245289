import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  positionsBlockTabContent: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  fullWidth: {
    width: '100%'
  }
}));

export default useStyles;
