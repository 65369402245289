import React from 'react';
import { ShipmentState, ShipmentStatusEnum } from 'models/provisioning';
import { SelectOption, SingleSelectProps } from 'components/Controls/Select/types';
import { SingleSelectControl } from 'components/Controls';

type Props = Partial<Omit<SingleSelectProps, 'changeHandler' | 'selected' | 'filter'>> & {
  selected?: ShipmentStatusEnum;
  selectStatus: (status?: ShipmentStatusEnum) => void;
};

const states: SelectOption[] = [
  {
    label: ShipmentState.created,
    value: ShipmentStatusEnum.Created
  },
  {
    label: ShipmentState.in_a_warehouse,
    value: ShipmentStatusEnum.InAWarehouse
  },
  {
    label: ShipmentState.dispatched,
    value: ShipmentStatusEnum.Dispatched
  },
  {
    label: ShipmentState.received,
    value: ShipmentStatusEnum.Received
  },
];

export const ShipmentStatusSelector = (props: Props): JSX.Element => {

  return (
    <SingleSelectControl
      name="state"
      label="State"
      values={ states }
      selected={ props.selected }
      changeHandler={ status => props.selectStatus(status as ShipmentStatusEnum) }
      { ...props }
    />
  );
};
