import { ReactNode } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

interface Props {
  isLoading?: boolean;
  className?: string;
  children: ReactNode;
}

export const TableLoadingLayout = ({ isLoading, className, children }: Props) => {
  const classes = useStyles();
  return (
    <Box position={ isLoading ? 'relative' : undefined } className={ className }>
      { isLoading && (
        <div className={ classes.loadingLayout } data-testid="table-loading-layout">
          <CircularProgress size={ 24 }/>
        </div>
      ) }
      { children }
    </Box>
  );
};