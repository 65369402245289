import { useState } from 'react';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks/usePagination';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { GroupsFilterFields, EnumGroupsFilterFields } from 'models/device-management/filters';

export function getFiltersFromUrl(urlState: UrlItems): GroupsFilterFields {
  const parser = new Parser(urlState as GroupsFilterFields);
  return {
    owner: parser.asNumber(EnumGroupsFilterFields.owner),
    projects: parser.asNumbers(EnumGroupsFilterFields.projects) ?? [],
    zones: parser.asNumbers(EnumGroupsFilterFields.zones) ?? [],
    levels: parser.asNumbers(EnumGroupsFilterFields.levels) ?? [],
    groups: parser.asNumbers(EnumGroupsFilterFields.groups) ?? [],
    groupType: parser.asString(EnumGroupsFilterFields.groupType) ?? undefined,
  };
}

export const useGroupParams = () => {
  const { items, setItems } = useLocationItems();
  const { limit, page } = usePagination();
  // filter url sync
  delete items['offset'];
  delete items['limit'];
  const [filters, setFilters] = useState<GroupsFilterFields>(getFiltersFromUrl(items));

  return {
    filters: filters,
    applyFilters: (filter: GroupsFilterFields) => {
      setItems({ ...filter, limit, offset: 0 });
      setFilters(filter);
    },
    limit,
    page,
  };
};