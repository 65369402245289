import {
  ParkingAnomalyByGroupId,
  EditGroupDurationParkingAnomaly as DurationData,
  EditGroupParkingIgnoreAnomaly as IgnoreData,
  ParkingAnomalyFilters,
  GroupsParkingAnomaly
} from 'models/parking-monitoring';

export const GET_GROUPS_PARKING_ANOMALY = 'GET_GROUPS_PARKING_ANOMALY';
export const GET_GROUPS_PARKING_ANOMALY_SUCCESS = 'GET_GROUPS_PARKING_ANOMALY_SUCCESS';
export const GET_GROUPS_PARKING_ANOMALY_FAILED = 'GET_GROUPS_PARKING_ANOMALY_FAILED';

export const GET_BY_GROUP_PARKING_ANOMALY = 'GET_BY_GROUP_PARKING_ANOMALY';
export const GET_BY_GROUP_PARKING_ANOMALY_SUCCESS= 'GET_BY_GROUP_PARKING_ANOMALY_SUCCESS';
export const GET_BY_GROUP_PARKING_ANOMALY_FAILED = 'GET_BY_GROUP_PARKING_ANOMALY_FAILED';

export const SET_GROUP_PARKING_ANOMALY_DURATION = 'SET_GROUP_PARKING_ANOMALY_DURATION';
export const SET_GROUP_PARKING_ANOMALY_IGNORE = 'SET_GROUP_PARKING_ANOMALY_IGNORE';
export const SET_GROUP_PARKING_ANOMALY_SUCCESS = 'SET_GROUP_PARKING_ANOMALY_SUCCESS';

export const EDIT_GROUP_DURATION_PARKING_ANOMALY = 'EDIT_GROUP_DURATION_PARKING_ANOMALY';
export const EDIT_GROUP_DURATION_PARKING_ANOMALY_SUCCESS = 'EDIT_GROUP_DURATION_PARKING_ANOMALY_SUCCESS';

export const EDIT_GROUP_IGNORE_PARKING_ANOMALY = 'EDIT_GROUP_IGNORE_PARKING_ANOMALY';
export const EDIT_GROUP_IGNORE_PARKING_ANOMALY_SUCCESS = 'EDIT_GROUP_IGNORE_PARKING_ANOMALY_SUCCESS';


export interface GetGroupsParkingAnomaly {
  type: typeof GET_GROUPS_PARKING_ANOMALY;
  filters: ParkingAnomalyFilters;
}

export interface GetGroupsParkingAnomalySuccess {
  type: typeof GET_GROUPS_PARKING_ANOMALY_SUCCESS;
  data: GroupsParkingAnomaly[];
}

export interface GetGroupsParkingAnomalyFailed {
  type: typeof GET_GROUPS_PARKING_ANOMALY_FAILED;
}

export interface GetByGroupParkingAnomaly {
  type: typeof GET_BY_GROUP_PARKING_ANOMALY;
  id: number;
}
export interface GetByGroupParkingAnomalySuccess {
  type: typeof GET_BY_GROUP_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByGroupId;
}
export interface GetByGroupParkingAnomalyFailed {
  type: typeof GET_BY_GROUP_PARKING_ANOMALY_FAILED;
}

export interface SetGroupParkingAnomalyDuration {
  type: typeof SET_GROUP_PARKING_ANOMALY_DURATION;
  payload: DurationData;
}

export interface SetGroupParkingAnomalyIgnore {
  type: typeof SET_GROUP_PARKING_ANOMALY_IGNORE;
  payload: IgnoreData;
}

export interface EditGroupDurationParkingAnomaly {
  type: typeof EDIT_GROUP_DURATION_PARKING_ANOMALY;
  payload: DurationData;
}
export interface EditGroupDurationParkingAnomalySuccess {
  type: typeof EDIT_GROUP_DURATION_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByGroupId;
}

export interface EditGroupIgnoreParkingAnomaly {
  type: typeof EDIT_GROUP_IGNORE_PARKING_ANOMALY;
  payload: IgnoreData;
}
export interface EditGroupIgnoreParkingAnomalySuccess {
  type: typeof EDIT_GROUP_IGNORE_PARKING_ANOMALY_SUCCESS;
  payload: ParkingAnomalyByGroupId;
}

export const DoGetGroupsParkinganomaly = (filters: ParkingAnomalyFilters): GetGroupsParkingAnomaly => ({
  type: GET_GROUPS_PARKING_ANOMALY,
  filters
});

export const DoGetGroupsParkinganomalySuccess = (data: GroupsParkingAnomaly[]): GetGroupsParkingAnomalySuccess => ({
  type: GET_GROUPS_PARKING_ANOMALY_SUCCESS,
  data
});

export const DoGetGroupsParkinganomalyFailed = (): GetGroupsParkingAnomalyFailed => ({
  type: GET_GROUPS_PARKING_ANOMALY_FAILED,
});

export const DoGetByGroupIdParkingAnomaly = (id: number): GetByGroupParkingAnomaly => ({
  type: GET_BY_GROUP_PARKING_ANOMALY,
  id
});
export const DoGetByGroupIdParkingAnomalySuccess = (payload: ParkingAnomalyByGroupId): GetByGroupParkingAnomalySuccess => ({
  type: GET_BY_GROUP_PARKING_ANOMALY_SUCCESS,
  payload
});
export const DoGetByGroupIdParkingAnomalyFailed = (): GetByGroupParkingAnomalyFailed => ({
  type: GET_BY_GROUP_PARKING_ANOMALY_FAILED
});

export const DoSetGroupParkingAnomalyDuration = (payload: DurationData): SetGroupParkingAnomalyDuration => ({
  type: SET_GROUP_PARKING_ANOMALY_DURATION,
  payload
});

export const DoSetGroupParkingAnomalyIgnore = (payload: IgnoreData): SetGroupParkingAnomalyIgnore => ({
  type: SET_GROUP_PARKING_ANOMALY_IGNORE,
  payload
});


export const DoEditGroupDurationParkingAnomaly = (payload: DurationData ): EditGroupDurationParkingAnomaly => ({
  type: EDIT_GROUP_DURATION_PARKING_ANOMALY,
  payload
});
export const DoEditGroupDurationParkingAnomalySuccess = (payload: ParkingAnomalyByGroupId): EditGroupDurationParkingAnomalySuccess => ({
  type: EDIT_GROUP_DURATION_PARKING_ANOMALY_SUCCESS,
  payload
});

export const DoEditGroupIgnoreParkingAnomaly = (payload: IgnoreData ): EditGroupIgnoreParkingAnomaly => ({
  type: EDIT_GROUP_IGNORE_PARKING_ANOMALY,
  payload
});
export const DoEditGroupIgnoreParkingAnomalySuccess = (payload: ParkingAnomalyByGroupId): EditGroupIgnoreParkingAnomalySuccess => ({
  type: EDIT_GROUP_IGNORE_PARKING_ANOMALY_SUCCESS,
  payload
});

export type Action =
| GetGroupsParkingAnomaly
| GetGroupsParkingAnomalySuccess
| GetGroupsParkingAnomalyFailed
| GetByGroupParkingAnomaly
| GetByGroupParkingAnomalySuccess
| GetByGroupParkingAnomalyFailed
| SetGroupParkingAnomalyDuration
| SetGroupParkingAnomalyIgnore
| EditGroupDurationParkingAnomaly
| EditGroupDurationParkingAnomalySuccess
| EditGroupIgnoreParkingAnomaly
| EditGroupIgnoreParkingAnomalySuccess;