import isEqual from 'lodash/isEqual';
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/base-station';
import { LoadResponsiblePersonDataFailure, LoadResponsiblePersonDataStart, LoadResponsiblePersonDataSuccess } from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { CreateResponsiblePerson, ResponsiblePerson, UpdateResponsiblePerson } from 'models/base-station';
import * as backend from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';

function* loadById(action: Action.LoadResponsiblePersonDataById) {
  yield put(LoadResponsiblePersonDataStart(action.personId));
  const response: ApiResponse = yield call(backend.getResponsiblePersonsByParams, { personIds: [action.personId] });
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadResponsiblePersonDataSuccess(action.personId, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadResponsiblePersonDataFailure(action.personId, message));
    yield put(NotifyError(`Error while fetching a base station responsible person: ${message}`));
  }
}

function* loadByParams(action: Action.LoadResponsiblePersonDataByParams) {
  yield put(LoadResponsiblePersonDataStart(action.params));
  const response: ApiResponse = yield call(backend.getResponsiblePersonsByParams, action.params);
  if (response.reason === ReasonEnum.Ok) {
    const persons = response.data;
    yield put(LoadResponsiblePersonDataSuccess(action.params, persons));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadResponsiblePersonDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching a base station responsible person: ${message}`));
  }
}

function* loadDictionary() {
  yield put(LoadResponsiblePersonDataStart({}));
  const response: ApiResponse = yield call(backend.getResponsiblePersonsByParams, { limit: 999 });
  if (response.reason === ReasonEnum.Ok) {
    const persons = response.data;
    yield put(LoadResponsiblePersonDataSuccess({}, persons));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadResponsiblePersonDataFailure({}, message));
    yield put(NotifyError(`Error while fetching a base station responsible person: ${ message }`));
  }
}

function* createPerson(person: ResponsiblePerson) {
  yield put(Action.LockStationResponsiblePerson(person.id));
  const create: CreateResponsiblePerson = {
    first_name: person.first_name,
    last_name: person.last_name,
    owner_id: person.owner_id,
    role: person.role,
    notify: person.notify,
    notification_method: person.notification_method,
    notification_endpoint: person.notification_endpoint,
    registered_user_id: person.registered_user_id
  };
  const response: ApiResponse = yield call(backend.createResponsiblePerson, create);
  yield put(Action.UnlockStationResponsiblePerson(person.id));

  if (response.reason !== ReasonEnum.Ok) {
    throw createErrorFromApiResponse(response);
  }

  let newPerson: ResponsiblePerson = response.data as ResponsiblePerson;

  for (const location of person.locations) {
    const response: ApiResponse = yield call(
      backend.addLocationToResponsiblePerson,
      newPerson.id,
      location.id
    );

    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }

    newPerson = response.data as ResponsiblePerson;
  }

  yield put(Action.ChangeStationResponsiblePerson(newPerson, { create: true }));

  return newPerson;
}

type CreateAction =
  | Action.CreateStationResponsiblePerson
  | ActionWithPromise<Action.CreateStationResponsiblePerson, ResponsiblePerson>

function* doCreatePerson(action: CreateAction) {
  const { person } = action;
  try {
    const newPerson: ResponsiblePerson = yield createPerson(person);
    yield put(NotifySuccess(`Person ${newPerson.first_name || ''} ${newPerson.last_name || ''} information has been saved`));
    'meta' in action && action.meta.promise.resolve(newPerson);
  } catch (error) {
    yield put(NotifyError(`Error while creating a base station person: ${(error as Error).message}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

function* updatePerson(person: UpdateResponsiblePerson) {

  yield put(Action.LockStationResponsiblePerson(person.rp_id));

  const response: ApiResponse = yield call(backend.updateResponsiblePerson, person);
  yield put(Action.UnlockStationResponsiblePerson(person.rp_id));

  if (response.reason !== ReasonEnum.Ok) {
    throw createErrorFromApiResponse(response);
  }

  const newPerson: ResponsiblePerson = response.data as ResponsiblePerson;
  yield put(Action.ChangeStationResponsiblePerson(newPerson, { update: true }));

  return newPerson;
}

type UpdateAction =
  | Action.UpdateStationResponsiblePerson
  | ActionWithPromise<Action.UpdateStationResponsiblePerson, ResponsiblePerson>

function* doUpdatePerson(action: UpdateAction) {
  const { person } = action;

  try {
    const newPerson: ResponsiblePerson = yield updatePerson(person);
    yield put(NotifySuccess(`Person ${newPerson.first_name || ''} ${newPerson.last_name || ''} information has been updated`));
    'meta' in action && action.meta.promise.resolve(newPerson);
  } catch (error) {
    yield put(NotifyError(`Error while updating base station person #${person.rp_id}: ${(error as Error).message}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

function* deletePerson(person: ResponsiblePerson) {
  yield put(Action.LockStationResponsiblePerson(person.id));
  const response: ApiResponse = yield call(backend.deleteResponsiblePerson, person.id);
  yield put(Action.UnlockStationResponsiblePerson(person.id));

  if (response.reason !== ReasonEnum.Ok) {
    throw createErrorFromApiResponse(response);
  }

  yield put(Action.ChangeStationResponsiblePerson(person, { delete: true }));

  return person;
}

type DeleteAction =
  | Action.DeleteStationResponsiblePerson
  | ActionWithPromise<Action.DeleteStationResponsiblePerson, ResponsiblePerson>

function* doDeletePerson(action: DeleteAction) {
  const { person } = action;
  try {
    yield deletePerson(person);
    yield put(NotifySuccess(`Person ${person.first_name || ''} ${person.last_name || ''} information has been deleted`));
    'meta' in action && action.meta.promise.resolve(person);
  } catch (error) {
    yield put(NotifyError(`Error while deleting base station person #${person.id}: ${(error as Error).message}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

type ChangeNotificationAction =
  | Action.ChangeStationResponsiblePersonNotification
  | ActionWithPromise<Action.ChangeStationResponsiblePersonNotification, ResponsiblePerson>

function* doChangePersonNotification(action: ChangeNotificationAction) {
  const { person, notification } = action;
  try {
    const newPerson: ResponsiblePerson= yield updatePerson({
      ...person,
      rp_id: person.id,
      notify: notification
    });

    const message = `Notifications has been turned ${notification ? 'on' : 'off'}`;
    yield put(NotifySuccess(message));
    'meta' in action && action.meta.promise.resolve(newPerson);
  } catch (error) {
    yield put(NotifyError(`Error while changing base station person #${ person.id }: ${ (error as Error).message }`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

type SaveAction =
  | Action.SaveStationResponsiblePersons
  | ActionWithPromise<Action.SaveStationResponsiblePersons, ResponsiblePerson[]>

function* doSavePersons(action: SaveAction) {
  const { location, persons, oldPersons } = action;

  try {
    for (const person of persons) {
      if (!person.id) {
        yield createPerson({ ...person, locations: [location] });
      } else if (!person.locations.find(l => l.id === location.id)) {
        yield attachLocation(person.id, location.id);
      }
    }

    for (const oldPerson of oldPersons) {
      const newPerson = persons.find(p => p.id === oldPerson.id);
      if (!newPerson) {
        yield detachLocation(oldPerson.id, location.id);
      } else if (!isEqual(newPerson, oldPerson)) {
        yield updatePerson({
          rp_id: newPerson.id,
          first_name: newPerson.first_name,
          last_name: newPerson.last_name,
          owner_id: newPerson.owner_id,
          role: newPerson.role,
          notify: newPerson.notify,
          notification_method: newPerson.notification_method,
          notification_endpoint: newPerson.notification_endpoint,
          registered_user_id: newPerson.registered_user_id
        });
      }
    }

    yield put(NotifySuccess(`Base station person has been saved`));
    'meta' in action && action.meta.promise.resolve(persons);
  } catch (error) {
    yield put(NotifyError(`Error while saving a base station person: ${(error as Error).message}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

function* detachLocation(personId: number, locationId: number) {
  yield put(Action.LockStationResponsiblePerson(personId));
  const response: ApiResponse = yield call(backend.removeLocationFromResponsiblePerson, personId, locationId);
  yield put(Action.UnlockStationResponsiblePerson(personId));

  if (response.reason !== ReasonEnum.Ok) {
    throw createErrorFromApiResponse(response);
  }

  const person: ResponsiblePerson = response.data as ResponsiblePerson;
  yield put(Action.ChangeStationResponsiblePerson(person, { delete: true }));

  return person;
}

function* attachLocation(personId: number, locationId: number) {
  yield put(Action.LockStationResponsiblePerson(personId));
  const response: ApiResponse = yield call(backend.addLocationToResponsiblePerson, personId, locationId);
  yield put(Action.UnlockStationResponsiblePerson(personId));

  if (response.reason !== ReasonEnum.Ok) {
    throw createErrorFromApiResponse(response);
  }

  const person: ResponsiblePerson = response.data as ResponsiblePerson;
  yield put(Action.ChangeStationResponsiblePerson(person, { delete: true }));

  return person;
}

type AttachLocationAction =
  | Action.AddLocationForResponsiblePerson
  | ActionWithPromise<Action.AddLocationForResponsiblePerson, ResponsiblePerson>

function* doAttachStationLocation(action: AttachLocationAction) {
  const { personId, locationId } = action;
  try {
    const person: ResponsiblePerson = yield attachLocation(personId, locationId);
    yield put(NotifySuccess(`Responsible person #${ person.id } has been attached to location`));
    'meta' in action && action.meta.promise.resolve(person);
  } catch (error) {
    yield put(NotifyError(`Error while attaching responsible person #${ personId }: ${(error as Error).message}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

type DetachLocationAction =
  | Action.RemoveLocationFromResponsiblePerson
  | ActionWithPromise<Action.RemoveLocationFromResponsiblePerson, ResponsiblePerson>

function* doDetachStationLocation(action: DetachLocationAction) {
  const { personId, locationId } = action;
  try {
    const person: ResponsiblePerson = yield detachLocation(personId, locationId);
    yield put(NotifySuccess(`Responsible person #${ person.id } has been detached from location`));
    'meta' in action && action.meta.promise.resolve(person);
  } catch (error) {
    yield put(NotifyError(`Error while detaching responsible person #${ personId }: ${(error as Error).message}`));
    'meta' in action && action.meta.promise.reject(error as Error);
  }
}

export default [
  takeEvery(Action.BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_ID, loadById),
  takeEvery(Action.BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_PARAMS, loadByParams),
  takeLeading(Action.BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_DICTIONARY, loadDictionary),

  takeLeading(Action.BASE_STATION_RESPONSIBLE_PERSON_CREATE, doCreatePerson),
  takeLeading(Action.BASE_STATION_RESPONSIBLE_PERSON_UPDATE, doUpdatePerson),
  takeLeading(Action.BASE_STATION_RESPONSIBLE_PERSON_DELETE, doDeletePerson),
  takeLeading(Action.BASE_STATION_RESPONSIBLE_PERSONS_SAVE, doSavePersons),
  takeLeading(Action.BASE_STATION_RESPONSIBLE_PERSON_NOTIFICATION_CHANGE, doChangePersonNotification),

  takeLeading(Action.BASE_STATION_RESPONSIBILITY_REMOVE, doDetachStationLocation),
  takeLeading(Action.BASE_STATION_RESPONSIBILITY_ADD, doAttachStationLocation),
];
