import React from 'react';
import { Level } from 'models/device-management';
import { isAllowedToDeleteLevel, isAllowedToUpdateLevel } from 'utils/permissions';
import { LevelNode } from 'hooks/device-management/useTree/types';
import { useZoneProjectName } from 'components/DeviceManagement/utils';

// components
import { Grid, Paper, Typography } from '@material-ui/core';
import { DeleteButton, DialogButton, EditButton } from 'components/Buttons';
import { FieldSkeleton } from 'components/Skeleton';
import { DeleteLevelDialog, EditLevelDialog } from 'components/DeviceManagement';

// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  level: Level;
  node: LevelNode;
  onEdit: (level: Level) => void;
  onDelete: (level: Level) => void;
}

export const LevelManagement = ({ level, node, onEdit, onDelete }: Props): JSX.Element => {
  const classes = useStyles();
  const zoneName = node.parent.zone.name;
  const projectName = useZoneProjectName(node.parent.zone);

  return (
    <Paper className={ classes.root } data-testid="dm-level-management">
      <Grid container alignItems="center">
        <Grid item lg={ 9 }>
          <Grid container spacing={ 3 } alignItems="center">
            <Grid item xs={ 3 }>
              <Typography>Level Name</Typography>
              <Typography className={ classes.itemValue }>{ level.name }</Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Floor</Typography>
              <Typography className={ classes.itemValue }>
                { level.id === 0 ? 'No floor' : level.floor_number }
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Zone Name</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { zoneName ?? <FieldSkeleton height={ 24 }/> }
              </Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Project Name</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { projectName ?? <FieldSkeleton height={ 24 }/> }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={ 3 } className={ classes.buttonBlock }>
          <DialogButton
            permission={ user => level.id !== 0 && isAllowedToUpdateLevel(user) }
            button={ open => <EditButton type="text" label="Edit" onClick={ open }/> }
            dialog={ props =>
              <EditLevelDialog
                { ...props }
                level={ level }
                onSuccess={ onEdit }
              />
            }
          />
          <DialogButton
            permission={ user => level.id !== 0 && isAllowedToDeleteLevel(user) }
            button={ open => <DeleteButton type="text" label="Delete" onClick={ open }/> }
            dialog={ props =>
              <DeleteLevelDialog
                { ...props }
                level={ level }
                onSuccess={ onDelete }
              />
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
