/* eslint-disable @typescript-eslint/indent */
import { sumBy } from 'lodash';
import moment from 'moment';

import { useCustomMemo } from 'hooks';
import { useCallerRequestHistory } from 'hooks/caller';
import { CallerRequestHistoryParams, ResponseCode } from 'models/caller-monitoring';

import { MonitoringSerie } from './utils';

export type CountByTemplateByDateByResponseCode = Record<
  ResponseCode,
  Map<
    string,
    Map<
      number,
      number
    >
  >
>;

interface RequestHistoryChartData {
  isLoading: boolean;
  data: MonitoringSerie[];
  maxValue?: number;
}

const COLORS = ['#2887cc', '#d84315', '#5ac942', '#00838f', '#f2c928', '#6a1b9a', '#9e9d24', '#f9a825', '#90a4ae', '#37474f'];

export const useRequestHistoryChartData = (params: CallerRequestHistoryParams): RequestHistoryChartData => {
  const history = useCallerRequestHistory(params);

  const result = useCustomMemo(() => {
    if (history.isLoading) {
      return {
        isLoading: true,
        data: [],
      };
    }

    const series: MonitoringSerie[] = [...history.historyByTemplate.entries()]
      .map(([templateId, requestHistory], index) => {
        const now = moment();

        return {
          id: templateId,
          data: requestHistory.map(event => ({
            x: moment(event.date).format('YYYY-MM-DD HH:mm'),
            y: event.value || (now.isBefore(event.date) ? null : event.value),
          })),
          total: sumBy(requestHistory, 'value'),
          color: COLORS[index % COLORS.length],
        };
      });

    const values = series.flatMap(serie =>
      serie.data.flatMap(datum =>
        typeof datum.y === 'number' ? [datum.y] : []
      )
    );

    return {
      isLoading: false,
      data: series,
      maxValue: values.length ? Math.max(...values) : undefined,
    };
  }, [history.historyByTemplate, history.isLoading]);

  return result;
};
