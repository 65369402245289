import { useTree } from 'hooks/device-management/useTree/useTree';
import { findGroupNode } from 'hooks/device-management/useTree/utils';
import { GroupNode, QueryType, Tree } from 'hooks/device-management/useTree/types';
import { useLocationBackAction, useLocationRedirect } from 'hooks/location';
import { getDMLevelDetailPath, getDMListPath, getDMGroupDetailPath } from 'routing/paths';
import { Group } from 'models/device-management';

interface PageData {
  isLoading: boolean;
  isValid: boolean;
  tree: Tree;
  node?: GroupNode;
  reload: (group: Group) => void;
  back: () => void;
}

export const useGroupDetailPage = (zoneIdParam: string, levelIdParam: string, groupIdParam: string,): PageData => {
  const zoneId = parseInt(zoneIdParam);
  const levelId = parseInt(levelIdParam);
  const groupId = parseInt(groupIdParam);
  const isValid = (
    !isNaN(zoneId) && zoneId > 0
    && !isNaN(levelId) && levelId >= 0
    && !isNaN(groupId) && groupId > 0
  );

  const redirectAction = useLocationRedirect(true);
  const backAction = useLocationBackAction();
  const tree = useTree({
    zones: [zoneId],
    levels: [levelId],
    groups: [groupId],
    offset: 0,
    limit: 1
  }, isValid);

  const node = (tree.loaded.group && findGroupNode(tree, zoneId, levelId, groupId)) || undefined;
  return {
    isLoading: !tree.loaded.group,
    isValid: isValid && (!tree.loaded.group || (node?.group.zone_id === zoneId && (node?.group.level_id ?? 0) === levelId)),
    tree: tree,
    node: node,
    reload: (group: Group) => {
      if (
        group.zone_id !== node?.group.zone_id
        || group.level_id !== node?.group.level_id
      ) {
        redirectAction(getDMGroupDetailPath(group.zone_id, group.level_id ?? 0, group.id));
      }
      tree.reload(QueryType.group);
      tree.reload(QueryType.position);
      tree.reload(QueryType.device);
    },
    back: () => backAction(node?.group ? getDMLevelDetailPath(node.group.zone_id, node.group.level_id ?? 0) : getDMListPath()),
  };
};



