import { SubtreeManagementOptions } from '../../types';
import { fillZoneTotalDamagedDevices } from './fillZoneTotalDamagedDevices';
import { fillGroupTotalDamagedDevices } from './fillGroupTotalDamagedDevices';

export async function fillTotalDamagedDevices(options: SubtreeManagementOptions) {
  await Promise.all([
    fillZoneTotalDamagedDevices(options),
    fillGroupTotalDamagedDevices(options),
  ]);
}
