import { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { Redirect, RedirectProps } from 'react-router-dom';
import { dispatchAsync } from 'utils/store';
// components
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { styled } from 'styles/utils';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  color: '#fff',
}));

export interface ActionLoaderProps {
  action: Action | Promise<unknown>;
  // TODO (BNIV-560): it seems best to use `unknown` here,
  // but it will require `as Something` or runtime checks,
  // so we'll skip it for now for simplicity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (result: any) => void | RedirectProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void | RedirectProps;
  onFinally?: () => void | RedirectProps;
}

export const ActionLoader = (props: ActionLoaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const { action, onSuccess, onError, onFinally } = props;
  const [inProgress, setProgress] = useState<boolean>(false);
  const redirect = useRef<RedirectProps | undefined>(undefined);
  const isMount = useRef<boolean>(true);
  useEffectOnce(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
    };
  });

  useEffect(() => {
    const actionPromise = action instanceof Promise ? action : dispatchAsync(dispatch, action);

    actionPromise
      .then((result) => {
        redirect.current = (onSuccess && onSuccess(result)) || undefined;
      })
      .catch((error) => {
        redirect.current = (onError && onError(error)) || undefined;
      })
      .finally(() => {
        redirect.current = (onFinally && onFinally()) || redirect.current;
        // dont update state if action component unmounted
        isMount.current && setProgress(false);
      });
    setProgress(true);
    redirect.current = undefined;
  }, [action, dispatch, onError, onFinally, onSuccess]);

  if (!inProgress && redirect.current) {
    return <Redirect { ...redirect.current }/> || (redirect.current = undefined);
  }

  return (
    <StyledBackdrop open={ inProgress }>
      <CircularProgress color="primary"/>
    </StyledBackdrop>
  );
};
