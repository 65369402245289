
import { TreeMethods } from '../base/types';
import { fillNodesWithAdditionalData } from '../tree/additional-data';
import { DmTreeNode, DmTreeNodeWithChildren } from '../types';
import { fetchChildrenForNode } from './fetchChildrenForNode';
import { ChildrenData, updateChildren } from './updateChildren';
import { isNodeAlreadyExpanded, isNodeExpandable } from './utils';

type TreeOptions = Pick<TreeMethods, 'getNode' | 'updateNode'>;

export async function refetchNodeChildren(
  node: DmTreeNodeWithChildren,
  treeOptions: TreeOptions
) {
  await refetchNodeChildrenEntities(node, treeOptions);
  await refetchNodeChildrenAdditionalData(node, treeOptions);
  await refetchExpandedChildrenDeep(node, treeOptions);
}

async function refetchNodeChildrenEntities(
  _node: DmTreeNodeWithChildren,
  treeOptions: TreeOptions
) {
  const { getNode, updateNode } = treeOptions;
  const node = getNode(_node);

  if (!node || node.childrenLoadStatus === 'loading') {
    return;
  }

  updateNode(node, (n) => {
    n.childrenLoadStatus = 'loading';
  });

  try {
    const { nodes, total } = await fetchChildrenForNode(node);
    const childrenData: ChildrenData = {
      children: nodes,
      childrenTotal: total,
    };

    updateNode(node, (n) => {
      updateChildren(n, childrenData);
    });
  } catch (e) {
    updateNode(node, (n) => {
      n.childrenLoadStatus = 'error';
    });
  }
}

async function refetchNodeChildrenAdditionalData(
  node: DmTreeNodeWithChildren,
  treeOptions: TreeOptions
) {
  await fillNodesWithAdditionalData({
    getSubtree: () => treeOptions.getNode(node),
    updateSubtree: (updater) => {
      treeOptions.updateNode(node, (n) => {
        updater(n);
      });
    }
  });
}

async function refetchExpandedChildrenDeep(
  node: DmTreeNodeWithChildren,
  treeOptions: TreeOptions
) {
  await Promise.all(
    (node.children as DmTreeNode[]).map((child) => {
      if (isNodeExpandable(child) && isNodeAlreadyExpanded(child)) {
        return refetchNodeChildren(child, treeOptions);
      }

      return undefined;
    })
  );
}
