import { SelectOption } from 'components/Controls';

export enum FormField {
  name = 'name',
  host = 'host',
  vhost = 'vhost',
  port = 'port',
  queue = 'queue',
  login = 'login',
  password = 'password',
  passwordRepeat = 'passwordRepeat',
  exchange = 'exchange',
  exchangeType = 'exchange_type',
  exchangeDurable = 'exchange_durable',
  ssl = 'ssl',
}

export interface FormState {
  [FormField.name]: string;
  [FormField.host]: string;
  [FormField.vhost]: string;
  [FormField.port]: number;
  [FormField.queue]: string;
  [FormField.login]: string;
  [FormField.password]: string;
  [FormField.passwordRepeat]: string;
  [FormField.exchange]: string;
  [FormField.exchangeType]: string;
  [FormField.exchangeDurable]: boolean;
  [FormField.ssl]: boolean;
}

export const ExchangeTypes: SelectOption[] = [
  { value: 'DIRECT', label: 'Direct' },
  { value: 'FANOUT', label: 'Fanout' },
  { value: 'TOPIC', label: 'Topic' },
  { value: 'HEADERS', label: 'Headers' },
];