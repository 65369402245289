import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { LoadPositionsRepositoryDataByParams } from 'actions/device-management/positions-repository';
import { PositionsFilterFields, PositionWithLevel } from 'models/device-management';
import { RootState } from 'reducers';
import { useFormActionNotifier } from 'hooks/form';
import { ensureRequestSucceeded } from 'utils/clients';

import { findPositions } from 'clients/device-management';

interface PositionsParamsSelectorHookOptions {
  params?: PositionsFilterFields;
  skip?: boolean;
}

export interface PositionsState {
  positions: PositionWithLevel[];
  isLoading: boolean;
}

export function usePositionsParamsSelector({
  params = {},
  skip = false,
}: PositionsParamsSelectorHookOptions): PositionsState {
  const dispatch = useDispatch();
  const { isFetched, positions } = useSelector((state: RootState) => ({
    isFetched: state.deviceManagement.positionsRepository.isFetched(params),
    positions: state.deviceManagement.positionsRepository.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (!skip && isFetched === undefined) {
      dispatch(LoadPositionsRepositoryDataByParams(params));
    }
  }, [skip, isFetched, params, dispatch]);

  return {
    positions,
    isLoading: isFetched !== true,
  };
}

const sortPositions = (positions: PositionWithLevel[]) => {
  return positions.sort((a, b) =>
    a.group_name.localeCompare(b.group_name, 'en', { numeric: true }) ||
    a.group_inner_id - b.group_inner_id
  );
};

export const usePositions = (params: PositionsFilterFields, enabled = true) => {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/positions', params],
    queryFn: async () => {
      const result = await findPositions(params);
      ensureRequestSucceeded(result);
      return result;
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notifyError(`Error while fetching positions: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    positions: queryResult.data ?  sortPositions(queryResult.data.data) : [],
    total: queryResult.data?.total || 0
  };
};

