import React from 'react';
import { useAuthUserSelector, useForm, useFormActionLoader } from 'hooks';
import { CreateEndpoint } from 'actions/rabbit';
import { Endpoint } from 'models/rabbit';
import { getRabbitEndpointDetailPath } from 'routing/paths';
import { FormField, FormState } from '../types';

// components
import { Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { CloneForm } from './CloneForm';

interface Props {
  testId: string;
  endpoint: Endpoint;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const CloneDialog = ({ testId, endpoint, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const { doAction } = useFormActionLoader();
  const form = useForm<FormState, FormField>({
    initialState: {
      [FormField.name]: `${endpoint.name ?? ''} CLONE`,
      [FormField.ownerId]: endpoint.owner_id ?? user.clientId,
    },
    showFields: [
      FormField.name,
      ...(user.isAdmin ? [FormField.ownerId] : [])
    ]
  });

  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateEndpoint({
        ...endpoint,
        name: form.state[FormField.name],
        owner_id: form.state[FormField.ownerId],
        id: 0
      }),
      onError: form.catchError,
      onSuccess: (endpoint: Endpoint) => {
        onClose();
        return {
          to: getRabbitEndpointDetailPath(Number(endpoint.id))
        };
      }
    });
  };

  return (
    <CommonDialog
      testId={ testId }
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title="Clone endpoint"
      content={ <CloneForm { ...form }/> }
      actions={
        <>
          <Button
            color="primary"
            onClick={ onClose }
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ handleSave }
          >
            Clone
          </Button>
        </>
      }
    />
  );
};
