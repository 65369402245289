import { noop } from 'lodash';
import React from 'react';

import { Zone, Level } from 'models/device-management';

// components
import { DialogActions, DialogTitle } from '@material-ui/core';

import { StyledDialog } from './Dialog/StyledDialog';
import { StyledDialogContent } from './Dialog/StyledDialogContent';
import { LevelForm } from './LevelForm';

interface UpdateLevelProps {
  level: Level;
  allLevels: Level[];
  zone: Zone;
  onClose: () => void;
  onSuccess?: (level: Level) => void;
}

export const UpdateLevel = ({
  level,
  allLevels,
  zone,
  onClose,
  onSuccess,
}: UpdateLevelProps) => {
  const titleNodeId = 'level-form-title';

  return (
    <StyledDialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open
      scroll="body"
      onClose={onClose}
      onOpen={noop}
      aria-labelledby={titleNodeId}
    >
      <DialogTitle id={titleNodeId}>
        Edit level {level.name}
      </DialogTitle>

      <LevelForm
        level={level}
        allLevels={allLevels}
        zone={zone}
        showFields={['zone_id', 'floor_number', 'name']}
        actionsWrapper={DialogActions}
        contentWrapper={StyledDialogContent}
        onCancel={onClose}
        onSuccess={level => {
          onClose();
          onSuccess?.(level);
        }}
      />
    </StyledDialog>
  );
};
