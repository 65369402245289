import { GetStationFlashParams, StationFlash, StationFlashCreate } from 'models/base-station';

export const BASE_STATION_FLASH_DATA_LOAD_BY_PARAMS = 'bs/stationFlash/LOAD_BY_PARAMS';
export const BASE_STATION_FLASH_DATA_LOAD_START = 'bs/stationFlash/LOAD_START';
export const BASE_STATION_FLASH_DATA_LOAD_FAILURE = 'bs/stationFlash/LOAD_FAILURE';
export const BASE_STATION_FLASH_DATA_LOAD_SUCCESS = 'bs/stationFlash/LOAD_SUCCESS';
export const BASE_STATION_FLASH_DATA_SYNC_START = 'bs/stationFlash/SYNC_START';
export const BASE_STATION_FLASH_DATA_SYNC_STOP = 'bs/stationFlash/SYNC_STOP';

export const BASE_STATION_FLASH_CHANGE = 'bs/stationFlash/CHANGE';
export const BASE_STATION_FLASH_LOCK = 'bs/stationFlash/LOCK';
export const BASE_STATION_FLASH_UNLOCK = 'bs/stationFlash/UNLOCK';
export const BASE_STATION_FLASH_CREATE = 'bs/stationFlash/CREATE';
export const BASE_STATION_FLASH_CANCEL = 'bs/stationFlash/CANCEL';

export interface LoadStationFlashDataByParams {
  readonly type: typeof BASE_STATION_FLASH_DATA_LOAD_BY_PARAMS;
  readonly params: GetStationFlashParams;
}

export interface LoadStationFlashDataStart {
  readonly type: typeof BASE_STATION_FLASH_DATA_LOAD_START;
  readonly params: GetStationFlashParams;
}

export interface LoadStationFlashDataFailure {
  readonly type: typeof BASE_STATION_FLASH_DATA_LOAD_FAILURE;
  readonly params: GetStationFlashParams;
  readonly error: string;
}

export interface LoadStationFlashDataSuccess {
  readonly type: typeof BASE_STATION_FLASH_DATA_LOAD_SUCCESS;
  readonly params: GetStationFlashParams;
  readonly data: StationFlash[];
}

export interface SyncStationFlashDataStart {
  readonly type: typeof BASE_STATION_FLASH_DATA_SYNC_START;
}

export interface SyncStationFlashDataStop {
  readonly type: typeof BASE_STATION_FLASH_DATA_SYNC_STOP;
}

export interface LockStationFlash {
  readonly type: typeof BASE_STATION_FLASH_LOCK;
  readonly stationId: number;
}

export interface UnlockStationFlash {
  readonly type: typeof BASE_STATION_FLASH_UNLOCK;
  readonly stationId: number;
}

export interface ChangeStationFlash {
  readonly type: typeof BASE_STATION_FLASH_CHANGE;
  stationFlash: StationFlash;
  options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export interface CreateStationFlash {
  readonly type: typeof BASE_STATION_FLASH_CREATE;
  readonly params: StationFlashCreate;
}

export interface CancelStationFlash {
  readonly type: typeof BASE_STATION_FLASH_CANCEL;
  readonly stationFlash: StationFlash;
}

export const LoadStationFlashDataByParams = (params: GetStationFlashParams): LoadStationFlashDataByParams => ({
  type: BASE_STATION_FLASH_DATA_LOAD_BY_PARAMS, params
});

export const LoadStationFlashDataStart = (params: GetStationFlashParams): LoadStationFlashDataStart => ({
  type: BASE_STATION_FLASH_DATA_LOAD_START, params
});

export const LoadStationFlashDataFailure = (params: GetStationFlashParams, error: string): LoadStationFlashDataFailure => ({
  type: BASE_STATION_FLASH_DATA_LOAD_FAILURE, params, error
});

export const LoadStationFlashDataSuccess = (params: GetStationFlashParams, data: StationFlash[]): LoadStationFlashDataSuccess => ({
  type: BASE_STATION_FLASH_DATA_LOAD_SUCCESS, params, data
});

export const SyncStationFlashDataStart = (): SyncStationFlashDataStart => ({
  type: BASE_STATION_FLASH_DATA_SYNC_START
});

export const SyncStationFlashDataStop = (): SyncStationFlashDataStop => ({
  type: BASE_STATION_FLASH_DATA_SYNC_STOP
});

export const LockStationFlash = (stationId: number): LockStationFlash => ({
  type: BASE_STATION_FLASH_LOCK, stationId
});

export const UnlockStationFlash = (stationId: number): UnlockStationFlash => ({
  type: BASE_STATION_FLASH_UNLOCK, stationId
});

export const ChangeStationFlash = (stationFlash: StationFlash,options: ChangeStationFlash['options']): ChangeStationFlash => ({
  type: BASE_STATION_FLASH_CHANGE, stationFlash, options
});

export const CreateStationFlash = (params: StationFlashCreate): CreateStationFlash => ({
  type: BASE_STATION_FLASH_CREATE, params
});

export const CancelStationFlash = (stationFlash: StationFlash): CancelStationFlash => ({
  type: BASE_STATION_FLASH_CANCEL, stationFlash
});

export type Action =
  | LoadStationFlashDataByParams
  | LoadStationFlashDataStart
  | LoadStationFlashDataFailure
  | LoadStationFlashDataSuccess
  | SyncStationFlashDataStart
  | SyncStationFlashDataStop
  | LockStationFlash
  | UnlockStationFlash
  | ChangeStationFlash
  | CreateStationFlash
  | CancelStationFlash
