import moment from 'moment';
import {
  ResolvedStatuses,
  ResolvedTypes,
  IncidentsFiltersFields,
  EnumFilterFields,
} from 'models/incidents';
import { ActivationStatuses, DamagedStatuses } from 'models/common';

// components
import { IncidentsSingleSelect, DateTimeControl, IncidentReasonSelect, IncidentErrorTypeSelect } from 'components/Controls';
import CustomIdSelect from 'components/Controls/CustomIdSelect/CustomIdSelectComponent';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';

// styles
import useStyles from 'styles/filters';

interface Props {
  state: IncidentsFiltersFields;
  setState: (filters: IncidentsFiltersFields | ((filters: IncidentsFiltersFields) => IncidentsFiltersFields)) => void;
  errors?: Record<EnumFilterFields, string>;
}

const IncidentsTableFilter = (props: Props): JSX.Element => {
  const classes = useStyles();

  const { state, setState, errors } = props;

  const updateResolvedStatus = (resolvingStatuses: string | undefined) => {
    setState({
      ...state,
      resolvingStatuses,
      resolvingTypes: undefined,
    });
  };
  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner: Number(owner) || undefined });
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const uncheckGroups = () => setState({ ...state, groups: [] });
  const handleSelectGroups = (groups: number[]) => setState({ ...state, groups });

  const resolutionStatus = ResolvedStatuses[state.resolvingStatuses as keyof typeof ResolvedStatuses];

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner }
        projects={ state.projects }
        handleSelectProjects={ handleSelectProjects }
        zones={ state.zones }
        handleSelectZones={ handleSelectZones }
        uncheckGroups={ uncheckGroups }
        groups={ state.groups }
        handleSelectGroups={ handleSelectGroups }
        isProjectShow
        isZoneShow
        isGroupsShow
      />
      <CustomIdSelect
        selected={ state.devices }
        changeHandler={ devices => setState({ ...state, devices }) }
        name="Device"
        type="hex"
      />
      <IncidentErrorTypeSelect
        isMulti
        isClearable
        label="Error Types"
        schema="incident"
        selected={ state.problemTypes }
        onChange={ problemTypes => setState({ ...state, problemTypes }) }
      />
      <IncidentsSingleSelect
        selected={ state.resolvingStatuses }
        changeHandler={ updateResolvedStatus }
        values={ ResolvedStatuses }
        name="resolved-status"
        label="Resolution Status"
      />
      <IncidentsSingleSelect
        selected={ state.resolvingTypes }
        changeHandler={ resolvingTypes => setState({ ...state, resolvingTypes }) }
        disabled={ resolutionStatus !== ResolvedStatuses.resolved }
        values={ ResolvedTypes }
        name="resolved-type"
        label="Resolution Type"
      />
      <IncidentsSingleSelect
        selected={ state.damagedStatuses }
        changeHandler={ damagedStatuses => setState({ ...state, damagedStatuses }) }
        values={ DamagedStatuses }
        name="damaged-status"
        label="Damaged Status"
      />
      <IncidentsSingleSelect
        selected={ state.activationStatuses }
        changeHandler={ activationStatuses => setState({ ...state, activationStatuses }) }
        values={ ActivationStatuses }
        name="activation-status"
        label="Active Status"
      />
      <IncidentReasonSelect
        isClearable
        schema="incident"
        selected={ state.reason }
        onChange={ (reason?: string) => setState({ ...state, reason }) }
      />
      <DateTimeControl
        label="Time From"
        helperText="Resolution time"
        onChange={ date => setState({ ...state, resolvingTimeFrom: date ? moment(date) : null }) }
        value={ state.resolvingTimeFrom ? state.resolvingTimeFrom.toDate() : undefined }
        maxDate={ state.resolvingTimeTo?.toDate() }
        hideErrorsFromHelperText
        isClearable
        error={ errors?.[EnumFilterFields.resolvingTimeFrom] }
      />
      <DateTimeControl
        label="Time To"
        helperText="Resolution time"
        onChange={ date => setState({ ...state, resolvingTimeTo: date ? moment(date) : null }) }
        value={ state.resolvingTimeTo ? state.resolvingTimeTo.toDate() : undefined }
        minDate={ state.resolvingTimeFrom?.toDate() }
        isClearable
        error={ errors?.[EnumFilterFields.resolvingTimeTo] }
      />
      <DateTimeControl
        label="Time From"
        helperText="Detection time"
        onChange={ date => setState({ ...state, appearingTimeFrom: date ? moment(date) : null }) }
        value={ state.appearingTimeFrom ? state.appearingTimeFrom.toDate() : undefined }
        maxDate={ state.appearingTimeTo?.toDate() }
        hideErrorsFromHelperText
        isClearable
        error={ errors?.[EnumFilterFields.appearingTimeFrom] }
      />
      <DateTimeControl
        label="Time To"
        helperText="Detection time"
        onChange={ date => setState({ ...state, appearingTimeTo: date ? moment(date) : null }) }
        value={ state.appearingTimeTo ? state.appearingTimeTo.toDate() : undefined }
        minDate={ state.appearingTimeFrom?.toDate() }
        isClearable
        error={ errors?.[EnumFilterFields.appearingTimeTo] }
      />
    </form>
  );
};

export default IncidentsTableFilter;
