import * as Actions from 'actions/firmware-management/firmware-data';
import { Firmware, GetFirmwareParams } from 'models/firmware-management';
import { Repository } from 'utils/repository';

export type State = Repository<Firmware, GetFirmwareParams, 'hash'>;
const initState: State = Repository.create('hash');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.FIRMWARE_DATA_LOAD_START:
      return state.setFetched(action.params, false);
    case Actions.FIRMWARE_DATA_LOAD_FAILURE:
      return state.setFetched(action.params, true);
    case Actions.FIRMWARE_DATA_LOAD_SUCCESS:
      return state
        .setFetched(action.params, true)
        .setData(action.params, action.data);

    default:
      return state;
  }
};
