import { ShipmentDeviceForAws } from 'models/provisioning';

// copmonents
import { Box, Card, CardHeader } from '@material-ui/core';
import { AwsProvisioningWidget } from './AwsProvisioningWidget';
import { DevicesTable } from './DevicesTable';

interface Props {
  devices: ShipmentDeviceForAws[];
  provisionedDevices: ShipmentDeviceForAws[]
  nonProvisionedDevices: ShipmentDeviceForAws[]
  isLoading: boolean;
}

export const ShipmentDevices = ({
  devices,
  provisionedDevices,
  nonProvisionedDevices,
  isLoading,
}: Props): JSX.Element => {
  return (
    <Card style={{ height: '100%' }}>
      <CardHeader title="AWS Provisioning" action={
        <Box display="flex" alignItems="center" margin={'8px 0px'}>
          <AwsProvisioningWidget
            provisionedDevices={provisionedDevices}
            nonProvisionedDevices={nonProvisionedDevices}
            isDevicesLoading={isLoading}
          />
        </Box>}
      />
      <DevicesTable
        devices={devices}
        isLoading={isLoading}
      />
    </Card>
  );
};

