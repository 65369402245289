import { DateType } from '@date-io/type';

import { DEFAULT_LIMIT } from 'models/common';

import { Metric } from './generated';

export enum EnumZoneFilterFields {
  zones = 'zones',
  owner = 'owner',
  projects = 'projects',
  liveStatus = 'liveStatus',
  limit = 'limit',
  offset = 'offset',
}

export interface ZoneFilters {
  [EnumZoneFilterFields.zones]: number[];
  [EnumZoneFilterFields.owner]?: number;
  [EnumZoneFilterFields.projects]: number[];
  [EnumZoneFilterFields.liveStatus]?: boolean;
}

export interface ZoneFiltersParams extends Partial<ZoneFilters> {
  [EnumZoneFilterFields.limit]?: number,
  [EnumZoneFilterFields.offset]?: number,
}


export const initZoneFilters: ZoneFilters = {
  [EnumZoneFilterFields.zones]: [],
  [EnumZoneFilterFields.owner]: undefined,
  [EnumZoneFilterFields.projects]: [],
  [EnumZoneFilterFields.liveStatus]: undefined,
};

export enum EnumDevicesParamsFields {
  limit = 'limit',
  offset = 'offset',
  devices = 'devices',
  isActive = 'isActive',
  hardwareTypes = 'hardwareTypes',
  isDamaged = 'isDamaged',
  zones = 'zones',
  groups = 'groups',
  owner = 'owner',
  isPositioned = 'isPositioned',
  isEmulated = 'isEmulated',
  projects = 'projects',
  positions = 'positions',
  groupInnerIds = 'groupInnerIds',
  level = 'level',
  firmwareId = 'firmwareId',
  protocol = 'protocol',
  networkIds = 'networkIds'
}

export enum PositionedStatus {
  positioned = 'Positioned',
  not_positioned = 'Not Positioned'
}

export enum EmulatedStatus {
  real = 'real',
  emulate = 'emulate'
}


export interface DevicesFilterFields {
  [EnumDevicesParamsFields.devices]: string[];
  [EnumDevicesParamsFields.isActive]?: boolean;
  [EnumDevicesParamsFields.hardwareTypes]?: string[];
  [EnumDevicesParamsFields.isDamaged]?: string;
  [EnumDevicesParamsFields.zones]: number[];
  [EnumDevicesParamsFields.groups]: number[];
  [EnumDevicesParamsFields.owner]?: number;
  [EnumDevicesParamsFields.isPositioned]?: string;
  [EnumDevicesParamsFields.isEmulated]?: EmulatedStatus[];
  [EnumDevicesParamsFields.projects]?: number[];
  [EnumDevicesParamsFields.positions]?: number[];
  [EnumDevicesParamsFields.groupInnerIds]?: string[];
  [EnumDevicesParamsFields.level]?: number[];
  [EnumDevicesParamsFields.firmwareId]?: string;
  [EnumDevicesParamsFields.protocol]?: number;
  [EnumDevicesParamsFields.networkIds]?: string[];
  force?: boolean;
  isVirtual?: boolean
}

export interface GetDevicesParams extends DevicesFilterFields {
  [EnumDevicesParamsFields.limit]?: number;
  [EnumDevicesParamsFields.offset]?: number;
}

export const initDeviceFilters: DevicesFilterFields = {
  [EnumDevicesParamsFields.devices]: [],
  [EnumDevicesParamsFields.isActive]: undefined,
  [EnumDevicesParamsFields.hardwareTypes]: [],
  [EnumDevicesParamsFields.isDamaged]: undefined,
  [EnumDevicesParamsFields.zones]: [],
  [EnumDevicesParamsFields.groups]: [],
  [EnumDevicesParamsFields.owner]: undefined,
  [EnumDevicesParamsFields.isPositioned]: undefined,
  [EnumDevicesParamsFields.isEmulated]: undefined,
  [EnumDevicesParamsFields.projects]: [],
  [EnumDevicesParamsFields.positions]: [],
  [EnumDevicesParamsFields.groupInnerIds]: [],
  [EnumDevicesParamsFields.level]: [],
  [EnumDevicesParamsFields.firmwareId]: undefined,
  [EnumDevicesParamsFields.protocol]: undefined,
  [EnumDevicesParamsFields.networkIds]: []
};

export type PositionedDevice = Omit<DevicesFilterFields, 'limit' | 'offset' | 'devices' | 'zones' | 'groups'>; 
export interface DevicesStatesFilters {
  limit: number;
  offset: number;
  devices?: string[] | string;
  incidentType?: string;
  projects?: number[];
  groups?: number[];
  zones?: number[];
  ownerId?: number;
  onlyWithIncidents?: boolean;
  download?: boolean;
};

export enum EnumPositionsFilterFields {
  limit = 'limit',
  offset = 'offset',
  positions = 'positions',
  levels = 'levels',
  labelIds = 'labelIds',
  owner = 'owner',
  groupIds = 'groupIds',
  groupInnerIds = 'groupInnerIds',
  projects = 'projects',
  zones = 'zones',
  bindingStatus = 'bindingStatus',
  networkIds = 'networkIds',
  customIds = 'customIds',
  lat = 'lat',
  lon = 'lon',
  radius = 'radius', 
}

export enum BoundStatus {
  bound = 'Bound',
  not_bound = 'Not Bound'
}

export interface PositionsFilterFields {
  [EnumPositionsFilterFields.limit]?: number;
  [EnumPositionsFilterFields.offset]?: number;
  [EnumPositionsFilterFields.positions]?: number[];
  [EnumPositionsFilterFields.levels]?: number[];
  [EnumPositionsFilterFields.labelIds]?: number[];
  [EnumPositionsFilterFields.owner]?: number;
  [EnumPositionsFilterFields.groupIds]?: number[];
  [EnumPositionsFilterFields.groupInnerIds]?: string[];
  [EnumPositionsFilterFields.projects]?: number[];
  [EnumPositionsFilterFields.zones]?: number[];
  [EnumPositionsFilterFields.bindingStatus]?: string;
  [EnumPositionsFilterFields.networkIds]?: string[];
  [EnumPositionsFilterFields.customIds]?: string[];
  [EnumPositionsFilterFields.lat]?: number;
  [EnumPositionsFilterFields.lon]?: number;
  [EnumPositionsFilterFields.radius]?: number;  
}

export const initPositionsFilters: PositionsFilterFields = {
  [EnumPositionsFilterFields.positions]: [],
  [EnumPositionsFilterFields.levels]: [],
  [EnumPositionsFilterFields.labelIds]: [],
  [EnumPositionsFilterFields.owner]: undefined,
  [EnumPositionsFilterFields.groupIds]: [],
  [EnumPositionsFilterFields.groupInnerIds]: [],
  [EnumPositionsFilterFields.projects]: [],
  [EnumPositionsFilterFields.zones]: [],
  [EnumPositionsFilterFields.bindingStatus]: undefined,
  [EnumPositionsFilterFields.networkIds]: [],
  [EnumPositionsFilterFields.customIds]: [],
  [EnumPositionsFilterFields.lat]: undefined,
  [EnumPositionsFilterFields.lon]: undefined,
  [EnumPositionsFilterFields.radius]: undefined,
};
export interface GroupsFilters {
  limit?: number;
  owner?: number;
  projectId?: number[];
  zones?: number[];
  levels?: number[];
  groups?: number[];
  groupType?: string;
}

export enum EnumGroupsFilterFields {
  limit = 'limit',
  owner = 'owner',
  zones = 'zones',
  levels = 'levels',
  groups = 'groups',
  projects = 'projects',
  groupType = 'groupType',
}

export interface GroupsFilterFields {
  [EnumGroupsFilterFields.owner]?: number;
  [EnumGroupsFilterFields.zones]?: number[];
  [EnumGroupsFilterFields.levels]?: number[];
  [EnumGroupsFilterFields.groups]?: number[];
  [EnumGroupsFilterFields.projects]?: number[];
  [EnumGroupsFilterFields.groupType]?: string;
}

export interface GroupsFilterParams extends GroupsFilterFields {
  [EnumGroupsFilterFields.limit]?: number;
}

export const initGroupsFilters: GroupsFilterFields = {
  [EnumGroupsFilterFields.owner]: undefined,
  [EnumGroupsFilterFields.zones]: [],
  [EnumGroupsFilterFields.levels]: [],
  [EnumGroupsFilterFields.groups]: [],
  [EnumGroupsFilterFields.projects]: [],
  [EnumGroupsFilterFields.groupType]: undefined,
};

export enum EnumVirtualDevicesParamsFields {
  limit = 'limit',
  offset = 'offset',
  owner = 'owner',
  isActive = 'isActive',
  firmwareHash = 'firmwareHash',
}
export interface VirtualDevicesFiltersFields {
  [EnumVirtualDevicesParamsFields.owner]?: number;
  [EnumVirtualDevicesParamsFields.isActive]?: boolean;
  [EnumVirtualDevicesParamsFields.firmwareHash]?: string;
}

export const initVirtualDevicesFilters: VirtualDevicesFiltersFields = {
  [EnumVirtualDevicesParamsFields.owner]: undefined,
  [EnumVirtualDevicesParamsFields.isActive]: undefined,
  [EnumVirtualDevicesParamsFields.firmwareHash]: undefined,
};

export interface LevelsFilters {
  limit?: number;
  offset?: number;
  zones?: number[];
  projects?: number[];
  owner?: number;
  floorNumber?: number;
  levels?: number[];
}

export interface GetDmStatParams {
  zones: number[];
  devices: string[];
  groups?: number[];
  timeFrom?: DateType;
  timeTo?: DateType;
  projects: number[];
  owner?: number;
  metrics?: Metric[];
}
