import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { RootState } from 'reducers';
import { LoadTemplateSuiteDataById } from 'actions/caller';
import { TemplateSuite, TemplateSuiteId } from 'models/caller';

export interface TemplateSuiteState {
  templateSuite?: TemplateSuite;
  isLoading: boolean;
  isExists: boolean;
}

export function useTemplateSuiteSelector(suiteId: TemplateSuiteId): TemplateSuiteState {
  const dispatch = useDispatch();
  const { isFetched, templateSuite } = useSelector((state: RootState) => ({
    isFetched: state.caller.templateSuite.isFetched(suiteId),
    templateSuite: state.caller.templateSuite.findById(suiteId),
  }));

  useEffectOnce(() => {
    if (isFetched === undefined) {
      dispatch(LoadTemplateSuiteDataById(suiteId));
    }
  });

  return {
    templateSuite: templateSuite,
    isLoading: isFetched !== true && templateSuite === undefined,
    isExists: isFetched === true && templateSuite !== undefined,
  };
}
