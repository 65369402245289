import { transformToHex } from 'helpers';
import { useDeepCompareEffect } from 'react-use';
import { StationLoRaWANCreate } from 'models/base-station';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { LoraFormField } from './types';
// components
import { TextControl, OwnersSingleSelectControl } from 'components/Controls';
// styles
import { useStyles } from './styles';

type Props = FormProps<StationLoRaWANCreate, LoraFormField> & { isAdmin: boolean };
export const StationLoRaWANForm = (props: Props): JSX.Element => {
  const classes = useStyles({});
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends LoraFormField>(
    field: TField,
    value: StationLoRaWANCreate[TField],
  ) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(LoraFormField.eui) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(LoraFormField.eui) }
          label="Gateway EUI"
          name="stationLora[eui]"
          value={ state.eui || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(LoraFormField.eui, transformToHex(event.target.value).slice(0, 16))
          }
          error={ showErrors.includes(LoraFormField.eui) ? errors?.eui !== undefined : false }
          helperText={ showErrors.includes(LoraFormField.eui) ? errors?.eui : undefined }
        />
      }
      { !props.isAdmin || !showFields.includes(LoraFormField.owner_id) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(LoraFormField.owner_id) }
          name="station[owner_id]"
          selected={ state.owner_id }
          error={ showErrors.includes(LoraFormField.owner_id) && errors?.owner_id }
          changeHandler={ ownerId => handleChange(LoraFormField.owner_id, ownerId ?? 0) }
        />
      }
    </form>
  );
};

