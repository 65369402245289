import React from 'react';

import { Column } from 'components/TreeTable';
import { DmTree } from 'hooks/device-management';
import { DmTreeNode, TopmostEntityType } from 'hooks/device-management/useDmTree/types';

// components
import { NameCell, DevicesPositionsCell, IssuesCell, LabelsCell, OwnerCell } from './cells';

interface ColumnsOptions {
  topmostEntityType: TopmostEntityType;
  eventHandlers: DmTree['eventHandlers'];
  hideOwner: boolean;
}

export function getColumns({
  topmostEntityType,
  hideOwner,
}: ColumnsOptions): Column<DmTreeNode>[] {
  return [
    {
      label: getNameColumnLabel(topmostEntityType),
      type: 'hierarchy',
      renderText: (node) => <NameCell node={ node } />
    },
    {
      label: 'Devices / Positions',
      render: (node) => <DevicesPositionsCell node={ node } />
    },
    {
      label: 'Issues',
      render: (node) => <IssuesCell node={ node } />,
    },
    {
      label: 'Connectivity',
      render: (node) => {
        const connectivity = node.connectivity.data;
        return typeof connectivity === 'number' && `${ connectivity }%`;
      }
    },
    {
      label: 'Owner',
      hidden: hideOwner,
      render: (node) => <OwnerCell node={ node } />
    },
    {
      label: 'Labels',
      render: (node) => <LabelsCell node={ node } />
    }
  ];
}

function getNameColumnLabel(topmostEntityType: TopmostEntityType): string {
  const parts = [
    topmostEntityType >= TopmostEntityType.ZONE ? 'Project' : undefined,
    topmostEntityType >= TopmostEntityType.ZONE ? 'Zone' : undefined,
    topmostEntityType >= TopmostEntityType.LEVEL ? 'Level' : undefined,
    topmostEntityType >= TopmostEntityType.GROUP ? 'Group Name' : undefined,
    topmostEntityType >= TopmostEntityType.POSITION ? 'Network ID' : undefined,
  ];

  return parts.filter(Boolean).join('/');
}
