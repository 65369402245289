/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.17.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Incident reason classification
 */
export type IncidentReasonEnum = 'missing' | 'hidden_problem' | 'not_confirmed' | 'not_specified' | 'broken_casing' | 'no_reason';

export const IncidentReasonEnum = {
    Missing: 'missing' as IncidentReasonEnum,
    HiddenProblem: 'hidden_problem' as IncidentReasonEnum,
    NotConfirmed: 'not_confirmed' as IncidentReasonEnum,
    NotSpecified: 'not_specified' as IncidentReasonEnum,
    BrokenCasing: 'broken_casing' as IncidentReasonEnum,
    NoReason: 'no_reason' as IncidentReasonEnum
}
