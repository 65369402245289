import React from 'react';
import * as muiDatatables from 'mui-datatables';

export interface MUIDataTableColumnOptions extends muiDatatables.MUIDataTableColumnOptions {
  /**
   * Function that returns a string or React component.
   * Used for creating a custom header to a column.
   * This method only affects the display in the table's header, other areas of the table (such as the View Columns popover), will use the column's label.
   *
   * Added in `mui-datatables@3.1.4`, but the types for the release are missing. Only 3.2.0's types exist.
   */
  customHeadLabelRender?: (dataIndex: number, rowIndex: number) => string | React.ReactNode;
}


export const createHiglightingNewRows = <T extends { new?: boolean; }>(data: T[]) => (_row: T[], dataIndex: number) => {
  const rowData = data[dataIndex];

  if (!rowData) {
    return {};
  }

  return {
    style: rowData.new ? { background: '#f5f2d3', transition: 'background 0.8s ease' } : {}
  };
};