import { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { GetApiKeyParams, ApiKey } from 'models/user-management';
import { LoadApiKeyDataById, LoadApiKeyDataByParams } from 'actions/user-management';

export interface ApiKeyState {
  apiKey?: ApiKey;
  isLoading: boolean;
}

export function useApiKeySelector(apiKeyId: string): ApiKeyState {
  const dispatch = useDispatch();
  const { isFetched, apiKey } = useSelector((state: RootState) => ({
    isFetched: state.userManagement.apiKey.isFetched(apiKeyId),
    apiKey: state.userManagement.apiKey.findById(apiKeyId),
  }), shallowEqual);

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadApiKeyDataById(apiKeyId));
    }
  }, [dispatch, isFetched, apiKeyId]);

  return {
    apiKey: apiKey,
    isLoading: isFetched !== true
  };
}

export interface ApiKeysState {
  apiKeys: ApiKey[];
  isLoading: boolean;
}

export function useApiKeysParamsSelector(params: GetApiKeyParams): ApiKeysState {
  const dispatch = useDispatch();
  const { isFetched, data } = useSelector((state: RootState) => ({
    isFetched: state.userManagement.apiKey.isFetched(params),
    data: state.userManagement.apiKey.findByParams(params),
  }), shallowEqual);

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadApiKeyDataByParams(params));
    }
  }, [dispatch, isFetched, params]);

  return {
    apiKeys: data,
    isLoading: isFetched !== true
  };
}
