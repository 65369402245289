import React, { useState } from 'react';
import { DoAddDeviceToBox } from 'actions/provisioning';
import { useFormActionLoader } from 'hooks';
import { transformToHex } from 'helpers';

// components
import { SuccessButton } from 'components/Buttons';
import { TextControl } from 'components/Controls';
import { Dialog, DialogActions, DialogContent, Button, Box, Typography } from '@material-ui/core';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  isOpen: boolean;
  boxId: number;
  setDialogOpen: (isOpen: boolean) => void;
}

type Event = React.ChangeEvent<{ value: unknown }>;

export const AddDevicesModal = ({ isOpen, setDialogOpen, boxId }: Props): JSX.Element => {
  const classes = useStyles();
  const { doAction } = useFormActionLoader();
  const [error, setError] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<string>('');

  const handleSubmit = () => {
    if (!selectedDevice) {
      return false;
    }

    doAction({
      action: DoAddDeviceToBox(selectedDevice, boxId),
      onError: error => setError(String(error)),
      onSuccess: () => setDialogOpen(false),
    });
  };

  const handleSetItemFromInput = (event: Event) => {
    const value = event.target.value as string;
    setSelectedDevice(transformToHex(value));
  };

  return (
    <Dialog
      open={ isOpen }
      onClose={ () => setDialogOpen(false) }
      maxWidth={ 'sm' }
      PaperProps={ { className: classes.formDialogPaper } }
    >
      <DialogContent className={ classes.dialogContentRootWithTable }>
        <Box mt={ 2 } ml={ 2 } mr={ 2 }>
          <TextControl
            value={ selectedDevice }
            autoFocus={ true }
            placeholder={ `Enter device ID` }
            onChange={ handleSetItemFromInput }
            error={ !!error }
          />
        </Box>
        <Box mt={ 2 } ml={ 2 } mr={ 2 }>
          <Typography color="error">
            { error }
          </Typography>
        </Box>
        <DialogActions>
          <Button color="inherit" onClick={ () => setDialogOpen(false) }>
            Cancel
          </Button>
          <SuccessButton
            label={ `Add` }
            onClick={ handleSubmit }
            disabled={ !selectedDevice }
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
