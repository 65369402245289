import { useState, useEffect } from 'react';
import {
  ProvisioningBoxesFilters,
  initProvisioningBoxesFilters
} from 'models/provisioning';

// components
import { FilterButton } from 'components/Buttons';
import { ProvisioningBoxesTableFilter } from 'components';


// styles
import useStyles from 'styles/page';

type Props = {
  isLoading: boolean;
  filters: ProvisioningBoxesFilters;
  updateFilters: (filters: ProvisioningBoxesFilters) => void;
}

export const BoxesFilters = ({ isLoading, updateFilters, filters }: Props): JSX.Element => {
  const classes = useStyles();

  const [filterState, setFilterState] = useState<ProvisioningBoxesFilters>(filters);

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  const onSave = () => updateFilters(filterState);
  const onReset = () => {
    setFilterState(initProvisioningBoxesFilters);
    updateFilters(initProvisioningBoxesFilters);
  };

  return (<div className={ classes.toolbar }>
    <FilterButton
      isLoading={ isLoading }
      filter={ filters }
      onSave={ onSave }
      onReset={ onReset }
    >
      <ProvisioningBoxesTableFilter
        state={ filterState }
        setState={ setFilterState }
      />
    </FilterButton>
  </div>);
};