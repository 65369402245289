import { Router } from 'models/caller';

export const CALLER_ROUTER_DIALOG_CLOSE = 'CALLER_ROUTER_DIALOG_CLOSE';
export const CALLER_ROUTER_DIALOG_OPEN_CREATE = 'CALLER_ROUTER_DIALOG_OPEN_CREATE';
export const CALLER_ROUTER_DIALOG_OPEN_UPDATE = 'CALLER_ROUTER_DIALOG_OPEN_UPDATE';
export const CALLER_ROUTER_DIALOG_OPEN_DELETE = 'CALLER_ROUTER_DIALOG_OPEN_DELETE';
export const CALLER_ROUTER_DIALOG_OPEN_ACTIVITY = 'CALLER_ROUTER_DIALOG_OPEN_ACTIVITY';

export enum RouterDialog {
  NONE,
  CREATE,
  UPDATE,
  DELETE,
  ACTIVITY,
}

export interface CloseRouterDialog {
  readonly type: typeof CALLER_ROUTER_DIALOG_CLOSE;
}

export interface OpenCreateRouterDialog {
  readonly type: typeof CALLER_ROUTER_DIALOG_OPEN_CREATE;
}

export interface OpenUpdateRouterDialog {
  readonly type: typeof CALLER_ROUTER_DIALOG_OPEN_UPDATE;
  router: Router;
}

export interface OpenDeleteRouterDialog {
  readonly type: typeof CALLER_ROUTER_DIALOG_OPEN_DELETE;
  router: Router;
}

export interface OpenActivityRouterDialog {
  readonly type: typeof CALLER_ROUTER_DIALOG_OPEN_ACTIVITY;
  router: Router;
}

export type Action =
  CloseRouterDialog | OpenCreateRouterDialog | OpenDeleteRouterDialog | OpenUpdateRouterDialog | OpenActivityRouterDialog

export const CloseRouterDialog: () => CloseRouterDialog = () => ({
  type: CALLER_ROUTER_DIALOG_CLOSE
});

export const OpenCreateRouterDialog: () => OpenCreateRouterDialog = () => ({
  type: CALLER_ROUTER_DIALOG_OPEN_CREATE
});

export const OpenUpdateRouterDialog: (router: Router) => OpenUpdateRouterDialog = (router: Router) => ({
  type: CALLER_ROUTER_DIALOG_OPEN_UPDATE, router
});

export const OpenDeleteRouterDialog: (router: Router) => OpenDeleteRouterDialog = (router: Router) => ({
  type: CALLER_ROUTER_DIALOG_OPEN_DELETE, router
});
export const OpenActivityRouterDialog: (router: Router) => OpenActivityRouterDialog = (router: Router) => ({
  type: CALLER_ROUTER_DIALOG_OPEN_ACTIVITY, router
});


