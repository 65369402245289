import { Station } from 'models/base-station';
import { StationFormField } from './BaseStationForm';
import { FormErrors } from 'form';

export const validate = (station: Station, fields: StationFormField[]): FormErrors<StationFormField> => {
  const errors: FormErrors<StationFormField> = {};
  if (fields.includes(StationFormField.id)) {
    if (!station.id) {
      errors.id = 'Station ID is required';
    }
  }
  if (fields.includes(StationFormField.owner)) {
    if (undefined === station.owner_id) {
      errors.owner_id = 'Owner is required';
    }
  }

  if (fields.includes(StationFormField.generation)) {
    if (!station.generation) {
      errors.generation = 'Generation is required';
    }
  }

  return errors;
};
