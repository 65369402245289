import { isEqual } from 'lodash';

import { useLocationItems } from 'hooks/location';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import React from 'react';

enum DateRangeType {
  LAST = 'last',
  EXACT = 'exact',
}

enum DateRangeUnit {
  DAYS = 'days',
}

interface LastDateRange {
  type: 'last';
  unit: 'days';
  count: number;
}

interface ExactDateRange {
  type: 'exact';
  dateFrom: Date;
  dateTo: Date;
}

export type MonitoringDateRange = LastDateRange | ExactDateRange;

export interface TemplateSuiteMonitoringFiltersState {
  dateRange: MonitoringDateRange;
  templateIds: 'all' | number[];
}

interface TemplateSuiteMonitoringUrlItems {
  templateIds: 'all' | number[] | undefined;
  dateRangeType: 'last' | 'exact' | undefined;
  dateRangeUnit: 'days' | undefined;
  dateRangeCount: number | undefined;
  dateRangeDateFrom: Date | undefined;
  dateRangeDateTo: Date | undefined;
}

const DEFAULT_DATE_RANGE: LastDateRange = {
  type: 'last',
  unit: 'days',
  count: 1,
};

const buildState = (items: UrlItems): TemplateSuiteMonitoringFiltersState => {
  const parser = new Parser(items as TemplateSuiteMonitoringUrlItems);

  let dateRange: MonitoringDateRange;

  if (parser.asEnum('dateRangeType', DateRangeType) === DateRangeType.LAST) {
    dateRange = {
      type: 'last',
      unit: parser.asEnum('dateRangeUnit', DateRangeUnit) ?? DEFAULT_DATE_RANGE.unit,
      count: parser.asNumber('dateRangeCount') ?? DEFAULT_DATE_RANGE.count,
    };
  } else if (parser.asEnum('dateRangeType', DateRangeType) === DateRangeType.EXACT) {
    const dateFrom = parser.asDate('dateRangeDateFrom');
    const dateTo = parser.asDate('dateRangeDateTo');

    if (dateFrom && dateTo) {
      dateRange = {
        type: 'exact',
        dateFrom,
        dateTo,
      };
    } else {
      dateRange = DEFAULT_DATE_RANGE;
    }
  } else {
    dateRange = DEFAULT_DATE_RANGE;
  }

  const result: TemplateSuiteMonitoringFiltersState = {
    templateIds: parser.asNumbers('templateIds') ?? 'all',
    dateRange,
  };

  return result;
};

const getDateRangeUrlItems = (
  { dateRange }: Partial<TemplateSuiteMonitoringFiltersState>
): Omit<TemplateSuiteMonitoringUrlItems, 'templateIds'> => {
  if (!dateRange || isEqual(dateRange, DEFAULT_DATE_RANGE)) {
    return {
      dateRangeCount: undefined,
      dateRangeDateFrom: undefined,
      dateRangeDateTo: undefined,
      dateRangeType: undefined,
      dateRangeUnit: undefined,
    };
  }

  return {
    dateRangeType: dateRange.type,
    dateRangeCount: dateRange.type === 'last' ? dateRange.count : undefined,
    dateRangeUnit: dateRange.type === 'last' ? dateRange.unit : undefined,
    dateRangeDateFrom: dateRange.type === 'exact' ? dateRange.dateFrom : undefined,
    dateRangeDateTo: dateRange.type === 'exact' ? dateRange.dateTo : undefined,
  };
};

export interface TemplateSuiteMonitoringFilters {
  state: TemplateSuiteMonitoringFiltersState;
  onChange: React.Dispatch<React.SetStateAction<Partial<TemplateSuiteMonitoringFiltersState>>>;
}

export function useTemplateSuiteMonitoringFilters(): TemplateSuiteMonitoringFilters {
  const location = useLocationItems({ updateMethod: 'replace' });
  const filter = buildState(location.items);

  return {
    state: filter,
    onChange: nextFiltersOrFn => location.setItems(allItems => {
      const nextFilters = typeof nextFiltersOrFn === 'function'
        ? nextFiltersOrFn(buildState(allItems))
        : nextFiltersOrFn;

      return {
        ...allItems,

        ...getDateRangeUrlItems(nextFilters),
        templateIds: nextFilters.templateIds === 'all' ? undefined : nextFilters.templateIds,
      };
    }),
  };
}