import { DetachStationLocation, DetachLorawanStationLocation } from 'actions/base-station';
import { useFormActionLoader } from 'hooks/form';
import { BaseStation, LorawanStation, StationType } from 'models/base-station';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  station: BaseStation | LorawanStation;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DetachLocationDialog = ({ station, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const handleDetach = () => doAction({
    action: station.type === StationType.lorawan
      ? DetachLorawanStationLocation(station)
      : DetachStationLocation(station),
    onSuccess: () => onClose(),
  });

  return (
    <EnsureDialog
      name="detach-station-location"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDetach }
    >
      Are you sure you want to detach the Station { station.id } from the attached location?
    </EnsureDialog>
  );
};
