import React from 'react';
import { RouteConfig, matchRoutes, MatchedRoute } from 'react-router-config';
import { Link } from 'react-router-dom';

// components
import { Link as MUILink, Typography } from '@material-ui/core';
import { isImplementedRoute, renderRouteLabel } from 'utils/routing';

// styles
import { colors } from 'styles/themes/outer';

interface BreadcrumbsRendererOptions {
  pathname: string;
  routes: RouteConfig[];
}

interface CrumbNodeCommonProps {
  children: React.ReactNode;
  key: number;
  variant: 'h6';
  style: Record<string, unknown>;
  'data-testid': string;
}

function branchNode(branch: MatchedRoute<Record<string, string>>[]) {
  return branch.reduce(
    (breadcrumbs, matchedRoute, i) => {
      const crumbContent = renderRouteLabel(matchedRoute);

      if (!crumbContent) {
        return breadcrumbs;
      }

      const showAsLink = (
        !matchedRoute.match.isExact &&
        isImplementedRoute(matchedRoute.route)
      );

      let crumbNode: React.ReactNode;

      // if we omit `CrumbNodeCommonProps` interface here, TS compiler reports errors
      // like `color` prop is `string` whereas `"textSecondary" | ...` expected
      // by `MUILink` and `Typography`
      const crumbNodeCommonProps: CrumbNodeCommonProps = {
        children: crumbContent,
        key: i,
        variant: 'h6',
        style: { color: colors.backgroundPaper, display: 'inline' },
        'data-testid': 'breadcrumb'
      };

      if (showAsLink) {
        crumbNode = (
          <MUILink
            { ...crumbNodeCommonProps }
            component={ Link }
            to={ matchedRoute.match.url }
          />
        );
      } else {
        crumbNode = <Typography { ...crumbNodeCommonProps } />;
      }

      breadcrumbs.push(crumbNode);

      return breadcrumbs;
    },
    [] as React.ReactNode[],
  );
}

export function renderBreadcrumbsEntries({ pathname, routes }: BreadcrumbsRendererOptions) {
  const branch = matchRoutes(routes, pathname);

  return branchNode(branch);
}

// Show only 2 latest breadcrumb
export function renderBreadcrumbsEntriesMobile({ pathname, routes }: BreadcrumbsRendererOptions) {
  const branch = matchRoutes(routes, pathname);
  const twoLatestBreadcrumbs = branch.splice(branch.length - 2, 2);

  return branchNode(twoLatestBreadcrumbs);
}
