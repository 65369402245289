import * as dmClient from 'clients/device-management';
import { ensureRequestSucceeded } from 'utils/clients';

import { GroupNode, LevelNode, ZoneNode } from '../types';

type TotalDevicesByNode = Record<string, number | undefined>;

export async function fetchZonesTotalPositions(
  nodes: ZoneNode[]
): Promise<TotalDevicesByNode> {
  const nodeIdTotalPositionsTuples = await Promise.all(
    nodes.map(async node => {
      const zoneId = node.zone.id;
      const res = await dmClient.findPositions({ zones: [zoneId], limit: 0 });
      ensureRequestSucceeded(res);

      return [node.id, res.total];
    }),
  );

  return Object.fromEntries(nodeIdTotalPositionsTuples);
}

export async function fetchLevelsTotalPositions(
  nodes: LevelNode[]
): Promise<TotalDevicesByNode> {
  const nodeIdTotalPositionsTuples = await Promise.all(
    nodes.map(async node => {
      const levelId = node.level?.id;

      // TODO: support fetching devices without level
      if (!levelId) {
        return [node.id, undefined];
      }

      const res = await dmClient.findPositions({ levels: [levelId], limit: 0 });
      ensureRequestSucceeded(res);

      return [node.id, res.total];
    }),
  );

  return Object.fromEntries(nodeIdTotalPositionsTuples);
}

export async function fetchGroupsTotalPositions(
  nodes: GroupNode[]
): Promise<TotalDevicesByNode> {
  const nodeIdTotalPositionsTuples = await Promise.all(
    nodes.map(async node => {
      const groupId = node.group.id;
      const res = await dmClient.findPositions({ groupIds: [groupId], limit: 0 });
      ensureRequestSucceeded(res);

      return [node.id, res.total];
    }),
  );

  return Object.fromEntries(nodeIdTotalPositionsTuples);
}
