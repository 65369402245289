import React from 'react';
import { RouteConfig } from 'react-router-config';
import { RootState } from 'reducers';
import { State as CurrentUserProfile } from 'reducers/user-profile';
import { useSelector } from 'react-redux';
import * as paths from 'routing/paths';
import * as permission from 'utils/permissions';
import { getParam } from 'utils/routing';

import { Skeleton } from '@material-ui/lab';
import { ProjectsList, ProjectPersonalPage } from 'pages';

const PROJECT_ID_PARAM_NAME = 'projectId';

interface ProjectLabelProps {
  id?: string;
}

const ProjectLabel = ({ id }: ProjectLabelProps): JSX.Element => {
  const label = useSelector((state: RootState) => state.projects.detailPage.byId?.id === Number(id) && state.projects.detailPage.byId?.name);

  if (!label) {
    return <Skeleton height={ 40 } width={ 80 } />;
  }
  return <>{ label }</>;
};

export function getProjectsRoutes(userData: CurrentUserProfile): RouteConfig[] {
  const { data: user } = userData;
  return [
    {
      path: paths.projectsPath,
      label: 'Projects',
      icon: 'projects',
      render: ({ match }) => match.isExact && <ProjectsList />,
      hiddenOnMainMenu: !permission.isAllowedToReadUsers(user),
      isShowByUrl: permission.isAllowedToReadUsers(user),
      routes: [
        {
          path: paths.getProjectPath(`:${PROJECT_ID_PARAM_NAME}`),
          label: ({ match }) => <ProjectLabel id={ getParam(PROJECT_ID_PARAM_NAME, match) } />,
          render: ({ match }) => {
            return <ProjectPersonalPage projectId={ Number(match.params[PROJECT_ID_PARAM_NAME]) } />;
          },
          hiddenOnMainMenu: true,
          isShowByUrl: permission.isAllowedToReadUsers(user),
        },
      ]
    }
  ];
}
