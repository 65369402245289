import React from 'react';
import { useZones } from 'hooks/device-management';
import { formatZoneRaw } from 'utils/models';

// components
import { Skeleton } from '@material-ui/lab';

interface ZoneRouteLabelProps {
  zoneIdParam: string;
}

const ZoneRouteLabel: React.FC<ZoneRouteLabelProps> = ({ zoneIdParam }) => {
  const { zones } = useZones({ params: { zones: [Number(zoneIdParam)] } });
  const zone = zones[0];
  if (typeof zone === 'undefined') {
    return <Skeleton height={40} width={80} />;
  }

  return <>{formatZoneRaw(zoneIdParam, zone)}</>;
};

export default ZoneRouteLabel;
