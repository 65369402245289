import { isNil } from 'lodash';
import moment from 'moment';

function parseNumber(value: unknown): number | undefined {
  if (isNil(value)) {
    return undefined;
  }

  const num = Number(value);
  if (isNaN(num) || String(num) !== String(value)) {
    return undefined;
  }

  return num;
}

function parseHex(value: unknown): string | undefined {
  if (isNil(value)) {
    return undefined;
  }

  const hex = String(value).toLowerCase();
  return /^[0-9a-f]+$/.test(hex) ? hex : undefined;
}

function parseEnum<EnumType>(value: unknown, enumObj: Record<string, EnumType>): EnumType | undefined {
  if (isNil(value)) {
    return undefined;
  }

  for (const enumVal of Object.values(enumObj)) {
    if (value === enumVal) {
      return value as unknown as EnumType;
    }
  }

  return undefined;
}

function parseDate(value: unknown): Date | undefined {
  if (isNil(value)) {
    return undefined;
  }

  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'number' && value > 0) {
    return moment.unix(value).toDate();
  }

  const formats = [
    moment.ISO_8601,
    moment.RFC_2822,
    moment.defaultFormat,
    moment.defaultFormatUtc,
    moment.HTML5_FMT.TIME_SECONDS
  ];
  const date = moment(String(value), formats, true);
  return date && date.isValid() ? date.toDate() : undefined;
}

function convertToArray(value: unknown): unknown[] {
  return Array.isArray(value) ? value : [value];
}

export class Parser<ParamType> {
  public constructor(
    private params: ParamType
  ) {}

  public asString(name: keyof ParamType): string | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    return String(this.params[name]);
  }

  public asStrings(name: keyof ParamType): string[] | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    const result: string[] = [];
    for (const val of convertToArray(this.params[name])) {
      result.push(String(val));
    }

    return result.length ? result : undefined;
  }

  public asNumber(name: keyof ParamType): number | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    return parseNumber(this.params[name]);
  }

  public asNumbers(name: keyof ParamType): number[] | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    const result: number[] = [];
    for (const val of convertToArray(this.params[name])) {
      const value = parseNumber(val);
      if (value !== undefined) {
        result.push(value);
      }
    }

    return result.length ? result : undefined;
  }

  public asHex(name: keyof ParamType): string | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    return parseHex(this.params[name]);
  }

  public asEnum<EnumType>(name: keyof ParamType, enumObj: Record<string, EnumType>): EnumType | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    return parseEnum(this.params[name], enumObj);
  }

  public asEnums<EnumType>(name: keyof ParamType, enumObj: Record<string, EnumType>): EnumType[] | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    const result: EnumType[] = [];
    for (const val of convertToArray(this.params[name])) {
      const value = parseEnum(val, enumObj);
      if (value !== undefined) {
        result.push(value);
      }
    }

    return result.length ? result : undefined;
  }

  public asDate(name: keyof ParamType): Date | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    return parseDate(this.params[name]);
  }

  public asBoolean(name: keyof ParamType): boolean | undefined {
    if (isNil(this.params[name])) {
      return undefined;
    }

    return Boolean(this.params[name]);
  }

}
