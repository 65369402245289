import { GetPositionStateParams, PositionState } from 'models/parking';

export const PARKING_POSITION_STATE_DATA_LOAD = 'PARKING_POSITION_STATE_DATA_LOAD';
export const PARKING_POSITION_STATE_DATA_FETCH_START = 'PARKING_POSITION_STATE_DATA_FETCH_START';
export const PARKING_POSITION_STATE_DATA_FETCH_STOP = 'PARKING_POSITION_STATE_DATA_FETCH_STOP';
export const PARKING_POSITION_STATE_DATA_LOADING = 'PARKING_POSITION_STATE_DATA_LOADING';
export const PARKING_POSITION_STATE_DATA_CHANGE = 'PARKING_POSITION_STATE_DATA_CHANGE';

export interface LoadPositionStateData {
  readonly type: typeof PARKING_POSITION_STATE_DATA_LOAD;
  readonly params: GetPositionStateParams;
  readonly disableNotFoundWarning?: boolean;
}

export interface LoadPositionStateDataStart {
  readonly type: typeof PARKING_POSITION_STATE_DATA_FETCH_START;
}

export interface LoadPositionStateDataStop {
  readonly type: typeof PARKING_POSITION_STATE_DATA_FETCH_STOP;
}

export interface LoadPositionStateDataLoading {
  readonly type: typeof PARKING_POSITION_STATE_DATA_LOADING;
  readonly status: boolean;
}

export interface ChangePositionStateData {
  readonly type: typeof PARKING_POSITION_STATE_DATA_CHANGE;
  readonly params: GetPositionStateParams;
  readonly data: PositionState[];
}

export const LoadPositionStateData = (options: Omit<LoadPositionStateData, 'type'>): LoadPositionStateData => ({
  type: PARKING_POSITION_STATE_DATA_LOAD, ...options
});

export type Action =
  | LoadPositionStateData
  | LoadPositionStateDataStart
  | LoadPositionStateDataStop
  | LoadPositionStateDataLoading
  | ChangePositionStateData