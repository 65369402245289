import { DEFAULT_LIMIT } from 'models/common';
import { SelectOption } from 'components/Controls';
import { DeviceStatusEnum } from '../generated';
import { DeviceProvisioningState } from '../common';

export enum ProvisioningDevicesFiltersFields {
  device_id = 'device_id',
  box_id = 'box_id',
  shipment_id = 'shipment_id',
  device_status = 'device_status',
  owner = 'owner',
  limit = 'limit',
  offset = 'offset'
}

export interface ProvisioningDevicesFilters {
  [ProvisioningDevicesFiltersFields.device_id]: string[];
  [ProvisioningDevicesFiltersFields.box_id]: number[];
  [ProvisioningDevicesFiltersFields.shipment_id]: string[];
  [ProvisioningDevicesFiltersFields.device_status]?: DeviceStatusEnum;
  [ProvisioningDevicesFiltersFields.owner]: number[];
}

export interface ProvisioningDevicesFiltersLimit extends ProvisioningDevicesFilters {
  [ProvisioningDevicesFiltersFields.limit]: number;
  [ProvisioningDevicesFiltersFields.offset]: number;
}

export const initProvisioningDevicesFilters: ProvisioningDevicesFiltersLimit = {
  [ProvisioningDevicesFiltersFields.device_id]: [],
  [ProvisioningDevicesFiltersFields.box_id]: [],
  [ProvisioningDevicesFiltersFields.shipment_id]: [],
  [ProvisioningDevicesFiltersFields.device_status]: undefined,
  [ProvisioningDevicesFiltersFields.owner]: [],
  [ProvisioningDevicesFiltersFields.limit]: DEFAULT_LIMIT,
  [ProvisioningDevicesFiltersFields.offset]: 0,
};

export const statuses: SelectOption[] = [
  {
    label: DeviceProvisioningState.added,
    value: DeviceStatusEnum.Added
  },
  {
    label: DeviceProvisioningState.disposed,
    value: DeviceStatusEnum.Disposed
  },
  {
    label: DeviceProvisioningState.in_a_warehouse,
    value: DeviceStatusEnum.InAWarehouse
  },
  {
    label: DeviceProvisioningState.installed,
    value: DeviceStatusEnum.Installed
  },
  {
    label: DeviceProvisioningState.dispatched,
    value: DeviceStatusEnum.Dispatched
  },
  {
    label: DeviceProvisioningState.produced,
    value: DeviceStatusEnum.Produced
  },
  {
    label: DeviceProvisioningState.received,
    value: DeviceStatusEnum.Received
  },
  {
    label: DeviceProvisioningState.rejected,
    value: DeviceStatusEnum.Rejected
  },
  {
    label: DeviceProvisioningState.restored,
    value: DeviceStatusEnum.Restored
  },
  {
    label: DeviceProvisioningState.returned_to_nwave,
    value: DeviceStatusEnum.ReturnedToNwave
  },
  {
    label: DeviceProvisioningState.tested_successfully,
    value: DeviceStatusEnum.TestedSuccessfully
  },
  {
    label: DeviceProvisioningState.uninstalled,
    value: DeviceStatusEnum.Uninstalled
  },
];
