import { ShipmentDeviceForAws } from 'models/provisioning';
import { useDialog } from 'hooks/dialog';
import { useDeviceProvision } from 'hooks';

// copmonents
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ProvisioningAlertationDialog } from './AlertationDialog';
import { ProvisioningSideMenu } from './SideMenu';


type Props = {
  provisionedDevices: ShipmentDeviceForAws[];
  nonProvisionedDevices: ShipmentDeviceForAws[];
  isDevicesLoading: boolean;
}

export const ProvisionWidget = ({
  provisionedDevices,
  nonProvisionedDevices,
  isDevicesLoading,
}: Props) => {
  const alertDialog = useDialog();
  const sideMenuDialog = useDialog();

  const { progress, bulkProvisionDeviceToAws, isProvisioning } = useDeviceProvision();
  const handleOnProvideClick = provisionedDevices.length > 0 ? alertDialog.open : sideMenuDialog.open;

  const isDisabled = isDevicesLoading || nonProvisionedDevices.length === 0 || isProvisioning;

  return (<>
    <Button
      variant="contained"
      color="primary"
      size="small"
      disabled={isDisabled}
      startIcon={ <Add /> }
      onClick={ handleOnProvideClick }
    >
      { isProvisioning ? `Provisioning... ${ progress.toFixed(0) } / 100 %` : 'Provide to AWS' }
    </Button>

    <ProvisioningAlertationDialog
      isOpen={alertDialog.isOpen}
      onProceed={ sideMenuDialog.open }
      onClose={ alertDialog.close }
      provisionedDevices={provisionedDevices}
    />

    <ProvisioningSideMenu
      isDevicesLoading={isDevicesLoading}
      isOpen={sideMenuDialog.isOpen}
      onClose={ sideMenuDialog.close }
      nonProvisionedDevices={nonProvisionedDevices}
      bulkProvisionDeviceToAws={bulkProvisionDeviceToAws}
    />
  </>);
};
