import { DEFAULT_LIMIT, IncidentsFiltersState as IncidentsFilters, } from 'models';
import { Incident, IncidentReasonEnum } from 'models/device-monitoring';
import { isNil } from 'utils/models';

export const FETCH_INCIDENTS_START = 'FETCH_INCIDENTS_START';
export const FETCH_INCIDENTS_START_FILTERS = 'FETCH_INCIDENTS_START_FILTERS';
export const FETCH_INCIDENTS_FAILED = 'FETCH_INCIDENTS_FAILED';
export const FETCH_INCIDENTS_SUCCESS = 'FETCH_INCIDENTS_SUCCESS';
export const SET_INCIDENTS_FILTERS = 'SET_INCIDENTS_FILTERS';
export const SET_INCIDENTS_LIMIT_OFFSET = 'SET_INCIDENTS_LIMIT_OFFSET';
export const INCIDENTS_FETCH_REPORT_CSV = 'INCIDENTS_FETCH_REPORT_CSV';
export const INCIDENTS_FETCH_REPORT_CSV_END = 'INCIDENTS_FETCH_REPORT_CSV_END';

export const MARK_RESOLVED_START = 'MARK_RESOLVED_START';
export const MARK_RESOLVED_FAILED = 'MARK_RESOLVED_FAILED';
export const MARK_RESOLVED_SUCCESS = 'MARK_RESOLVED_SUCCESS';

export const MARK_DAMAGED_START = 'MARK_DAMAGED_START';
export const MARK_DAMAGED_SUCCESS = 'MARK_DAMAGED_SUCCESS';
export const MARK_DAMAGED_FAILED = 'MARK_DAMAGED_FAILED';

export const CREATE_DAMAGED_INCIDENT_START = 'CREATE_DAMAGED_INCIDENT_START';
export const CREATE_DAMAGED_INCIDENT_SUCCESS = 'CREATE_DAMAGED_INCIDENT_SUCCESS';
export const CREATE_DAMAGED_INCIDENT_FAILED = 'CREATE_DAMAGED_INCIDENT_FAILED';

export const INCIDENTS_START_BACKGROUND_SYNC = 'INCIDENTS_START_BACKGROUND_SYNC';
export const INCIDENTS_STOP_BACKGROUND_SYNC = 'INCIDENTS_STOP_BACKGROUND_SYNC';
export const UPDATE_INCIDENTS_FILTERS_FROM_URL = 'UPDATE_INCIDENTS_FILTERS_FROM_URL';

export interface MarkResolvedFailed {
  readonly type: typeof MARK_RESOLVED_FAILED;
  error: string;
}
export interface MarkDamagedFailed {
  readonly type: typeof MARK_DAMAGED_FAILED;
  error: string;
}
export interface MarkResolved {
  readonly type: typeof MARK_RESOLVED_START;
  id: number;
  comment: string;
}
export interface MarkDamaged {
  readonly type: typeof MARK_DAMAGED_START;
  id: number;
  comment: string;
}
export interface MarkResolvedSuccess {
  readonly type: typeof MARK_RESOLVED_SUCCESS;
}
export interface MarkDamagedSuccess {
  readonly type: typeof MARK_DAMAGED_SUCCESS;
}

export interface CreateDamagedIncident {
  readonly type: typeof CREATE_DAMAGED_INCIDENT_START;
  readonly deviceId: string;
  readonly reason: IncidentReasonEnum;
  readonly comment: string;
}
export interface CreateDamagedIncidentSuccess {
  readonly type: typeof CREATE_DAMAGED_INCIDENT_SUCCESS;
  readonly incident: Incident;
}
export interface CreateDamagedIncidentFailed  {
  readonly type: typeof CREATE_DAMAGED_INCIDENT_FAILED;
  readonly error: string;
}

export interface GetIncidentsFailed {
  readonly type: typeof FETCH_INCIDENTS_FAILED;
}
export interface GetIncidentsStart {
  readonly type: typeof FETCH_INCIDENTS_START;
}
export interface GetIncidentsStartFilters {
  readonly type: typeof FETCH_INCIDENTS_START_FILTERS;
  filters: IncidentsFilters;
}
export interface GetIncidentsSuccess {
  readonly type: typeof FETCH_INCIDENTS_SUCCESS;
  readonly payload: {
    readonly filters: IncidentsFilters;
    readonly foundIncidents: Incident[];
    readonly total: number;
  };
}
export interface FetchReport {
  readonly type: typeof INCIDENTS_FETCH_REPORT_CSV;
  filters: IncidentsFilters;
}
export interface FetchReportEnd {
  readonly type: typeof INCIDENTS_FETCH_REPORT_CSV_END;
}
export interface SetIncidentsFilters {
  readonly type: typeof SET_INCIDENTS_FILTERS;
  filters: IncidentsFilters;
  pushFiltersToUrl: boolean;
}
export interface SetIncidentsLimitOffset {
  readonly type: typeof SET_INCIDENTS_LIMIT_OFFSET;
  limit: number;
  offset: number;
}
export interface StartSync {
  readonly type: typeof INCIDENTS_START_BACKGROUND_SYNC;
}
export interface StopSync {
  readonly type: typeof INCIDENTS_STOP_BACKGROUND_SYNC;
}
export interface UpdateIncidentsFromUrl {
  readonly type: typeof UPDATE_INCIDENTS_FILTERS_FROM_URL;
}

export const DoGetIncidentsStart: () => GetIncidentsStart = () => ({
  type: FETCH_INCIDENTS_START
});

export const DoGetIncidentsStartFilters: (filters: Partial<IncidentsFilters>) => GetIncidentsStartFilters = (filters) => ({
  type: FETCH_INCIDENTS_START_FILTERS,
  filters: {
    ...filters,
    zones: filters.zones || [],
    groups: filters.groups || [],
    devices: filters.devices || [],
    problemTypes: filters.problemTypes || [],
    resolvingTimeFrom: filters.resolvingTimeFrom || null,
    resolvingTimeTo: filters.resolvingTimeTo || null,
    appearingTimeFrom: filters.appearingTimeFrom || null,
    appearingTimeTo: filters.appearingTimeTo || null,
    limit: isNil(filters.limit) ? DEFAULT_LIMIT : filters.limit,
    offset: isNil(filters.offset) ? 0 : filters.offset,
  },
});
export const DoGetIncidentsFailed: () => GetIncidentsFailed = () => ({
  type: FETCH_INCIDENTS_FAILED
});
export const DoGetIncidentsSuccess: (payload: GetIncidentsSuccess['payload']) => GetIncidentsSuccess = (payload) => ({
  type: FETCH_INCIDENTS_SUCCESS,
  payload,
});
export const DoFetchReport: (filters: IncidentsFilters) => FetchReport = filters => ({
  type: INCIDENTS_FETCH_REPORT_CSV,
  filters
});
export const DoFetchReportEnd: () => FetchReportEnd = () => ({
  type: INCIDENTS_FETCH_REPORT_CSV_END,
});
export const DoSetIncidentsFilters: (filters: IncidentsFilters, pushFiltersToUrl: boolean) => SetIncidentsFilters = (filters, pushFiltersToUrl) => ({
  type: SET_INCIDENTS_FILTERS,
  filters,
  pushFiltersToUrl,
});
export const DoSetIncidentsLimitOffset: (limit: number, offset: number) => SetIncidentsLimitOffset = (limit, offset) => ({
  type: SET_INCIDENTS_LIMIT_OFFSET,
  limit,
  offset,
});
export const DoMarkResolved: (id: number, comment: string) => MarkResolved = (id, comment) => ({
  type: MARK_RESOLVED_START,
  id,
  comment,
});
export const DoMarkDamaged: (id: number, comment: string) => MarkDamaged = (id, comment) => ({
  type: MARK_DAMAGED_START,
  id,
  comment,
});
export const MarkResolvedFailed: (error: string) => MarkResolvedFailed = (error) => ({
  type: MARK_RESOLVED_FAILED,
  error,
});
export const MarkDamagedFailed: (error: string) => MarkDamagedFailed = (error) => ({
  type: MARK_DAMAGED_FAILED,
  error,
});
export const MarkResolvedSuccess: (data: Incident) => MarkResolvedSuccess = (data) => ({
  type: MARK_RESOLVED_SUCCESS,
  payload: data,
});
export const MarkDamagedSuccess: (data: Incident[]) => MarkDamagedSuccess = (data) => ({
  type: MARK_DAMAGED_SUCCESS,
  payload: data,
});

export const CreateDamagedIncident = (deviceId: string, reason: IncidentReasonEnum, comment: string): CreateDamagedIncident => ({
  type: CREATE_DAMAGED_INCIDENT_START, deviceId, reason, comment
});
export const CreateDamagedIncidentSuccess = (incident: Incident): CreateDamagedIncidentSuccess => ({
  type: CREATE_DAMAGED_INCIDENT_SUCCESS, incident
});
export const CreateDamagedIncidentFailed = (error: string): CreateDamagedIncidentFailed => ({
  type: CREATE_DAMAGED_INCIDENT_FAILED, error
});

export const DoStartSync: () => StartSync = () => ({
  type: INCIDENTS_START_BACKGROUND_SYNC
});
export const DoStopSync: () => StopSync = () => ({
  type: INCIDENTS_STOP_BACKGROUND_SYNC
});
export const DoUpdateIncidentsFromUrl: () => UpdateIncidentsFromUrl = () => ({
  type: UPDATE_INCIDENTS_FILTERS_FROM_URL
});

export type Action =
  GetIncidentsStart |
  GetIncidentsStartFilters |
  GetIncidentsFailed |
  GetIncidentsSuccess |
  MarkResolved |
  MarkResolvedSuccess |
  MarkResolvedFailed |
  MarkDamagedFailed |
  MarkDamagedSuccess |
  MarkDamaged |
  CreateDamagedIncident |
  CreateDamagedIncidentSuccess |
  CreateDamagedIncidentFailed |
  FetchReport |
  FetchReportEnd |
  SetIncidentsFilters |
  SetIncidentsLimitOffset |
  StartSync |
  StopSync |
  UpdateIncidentsFromUrl;
