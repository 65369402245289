import { Level } from 'models/device-management';
import { useTree } from 'hooks/device-management/useTree/useTree';
import { findLevelNode } from 'hooks/device-management/useTree/utils';
import { LevelNode, QueryType, Tree } from 'hooks/device-management/useTree/types';
import { useLocationBackAction } from 'hooks/location';
import { getDMListPath, getDMZoneDetailPath } from 'routing/paths';

interface PageData {
  isLoading: boolean;
  isValid: boolean;
  level?: Level;
  tree: Tree;
  node?: LevelNode;
  reload: () => void;
  back: () => void;
}

export const useLevelDetailPage = (zoneIdParam: string, levelIdParam: string): PageData => {
  const levelId = parseInt(levelIdParam);
  const zoneId = parseInt(zoneIdParam);
  const isValid = (
    !isNaN(zoneId) && zoneId > 0
    && !isNaN(levelId) && levelId >= 0
  );
  const backAction = useLocationBackAction();
  const tree = useTree({
    zones: [zoneId],
    levels: [levelId],
    offset: 0,
    limit: 1
  }, isValid);

  const node = (tree.loaded.level && findLevelNode(tree, zoneId, levelId)) || undefined;
  let level = node?.level;
  if (node && !level && levelIdParam === '0') {
    level = {
      id: 0,
      zone_id: parseInt(zoneIdParam),
      name: 'No level',
      floor_number: 0,
    };
  }

  return {
    isLoading: !tree.loaded.level,
    isValid: isValid && (!tree.loaded.level || level?.zone_id === zoneId),
    level: level,
    tree: tree,
    node: node,
    reload: () => {
      tree.reload(QueryType.level);
      tree.reload(QueryType.group);
    },
    back: () => backAction(level ? getDMZoneDetailPath(level.zone_id) : getDMListPath()),
  };
};



