import React from 'react';
import { StationStatusFlat } from 'models/base-station-status';
// components
import { CommonStatus, Props as CommonStatusProps } from './CommonStatus';

interface Props {
  stationStatus?: StationStatusFlat;
  variant?: CommonStatusProps['variant'];
  color?: CommonStatusProps['color'];
}

export const UptimeStatus = ({ stationStatus, color, variant }: Props) => {
  return (
    <CommonStatus
      label={ '' }
      color={ color ?? 'nomal' }
      duration={ stationStatus?.uptime }
      variant={ variant }
    />
  );
};
