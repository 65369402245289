import { ReactChild } from 'react';
import { useAmplifyAuth } from './hooks/useAmplifyAuth';
import { useRecaptcha } from './hooks/useRecaptcha';

// components
import { Authenticator } from '@aws-amplify/ui-react';
import { Box } from '@material-ui/core';
import logo from 'images/favicon.png';
import { SignUpForm } from './widget/SignUpForm';
import { ConfirmSignUpHeader } from './widget/ConfirmSignUpHeader';

// styles
import contentStyles from 'styles';
import '@aws-amplify/ui-react/styles.css';
import './style.scss';

const formFields = {
  signIn: {
    username: {
      placeholder: 'Email',
      type: 'email',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email',
    }
  }
};

interface Props {
  children: ReactChild;
}

const AuthContainer = ({ children }: Props): JSX.Element => {
  const classes = contentStyles();
  const executeRecaptcha = useRecaptcha();
  const { signedIn, handleSignIn, handleSignUp } = useAmplifyAuth();

  if (signedIn) {
    return (
      <>{ children }</>
    );
  }

  return (
    <Box className={ classes.root } justifyContent="center">
      <h1 className={ classes.logo }><img src={ logo } alt="Logo"/> Nwave Console</h1>
      <Authenticator
        services={ {
          handleSignIn: handleSignIn,
          handleSignUp: async data => {
            const token = await executeRecaptcha();
            return await handleSignUp(data, token);
          },
        } }
        components={ {
          SignUp: {
            FormFields: () => <SignUpForm/>,
          },
          ConfirmSignUp: {
            Header: () => <ConfirmSignUpHeader/>,
          }
        } }
        formFields={ formFields }
      />
    </Box>
  );
};

export default AuthContainer;
