import * as Actions from 'actions/caller';
import { GetTemplateSuitesParams, TemplateSuite } from 'models/caller';
import { Repository } from 'utils/repository';

export type State = Repository<TemplateSuite, GetTemplateSuitesParams, 'template_suite_id'>;
const initState: State = Repository.create('template_suite_id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.CALLER_TEMPLATE_SUITE_DATA_FETCH_START:
      return state.setFetched(action.params, false);
    case Actions.CALLER_TEMPLATE_SUITE_DATA_FETCH_STOP:
      return state.setFetched(action.params, true);
    case Actions.CALLER_TEMPLATE_SUITE_DATA_CHANGE:
      return state.setData(action.params, action.data);
    case Actions.CALLER_TEMPLATE_SUITE_LOCK:
      return state.setLocked(action.id, true);
    case Actions.CALLER_TEMPLATE_SUITE_UNLOCK:
      return state.setLocked(action.id, false);
    case Actions.CALLER_TEMPLATE_SUITE_CHANGE:
      return state.changeModel(action.templateSuite.template_suite_id, action.options.delete ? undefined : action.templateSuite);

    default:
      return state;
  }
};
