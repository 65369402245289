import { Helmet } from 'react-helmet-async';
import { useVirtualDevicesPageParams } from './useVirtualDevicesPageParams';
import { PositionedDeviceData } from 'models/device-management';
import { useDialogWithItem, useDialog } from 'hooks/dialog';
import { useDevices, useDeviceSettings, usePositionsParamsSelector } from 'hooks/device-management';

// components
import { AddButton } from 'components/Buttons';
import { CreateVirtualDeviceDialog, DeleteVirtualDeviceDialog, VirtualDevicesTable } from 'components/DeviceManagement';
import { DevicesNotFound } from './widgets/DevicesNotFound';
import { FiltersDialog } from './widgets/FiltersDialog';
// styles
import useStyles from 'styles/page';

export const TestDevicesPage = (): JSX.Element => {
  const classes = useStyles();
  const { filters, params, setFilters, resetFilters } = useVirtualDevicesPageParams();
  const { devices, total, isLoading } = useDevices({
    params: {
      devices: [],
      zones: [],
      groups: [],
      ...params,
      firmwareId: params.firmwareHash,
      force: true,
      isVirtual: true
    }
  });
  const { devices: allVirtualDevices } = useDevices({
    params: {
      devices: [],
      zones: [],
      groups: [],
      force: true,
      isVirtual: true
    }
  });
  const { deviceSettings, isLoading: isDeviceSettingsLoading } = useDeviceSettings({ params: { deviceIds: devices.map(d => d.device_id) } });
  const isUserHasVirtualDevices = allVirtualDevices.length > 0;

  const devicePositionIds = devices.flatMap(d => d.position_id ? [d.position_id] : []);
  const { positions } = usePositionsParamsSelector({
    params: {
      positions: devicePositionIds,
      limit: devicePositionIds.length,
    },
    skip: !devicePositionIds.length,
  });

  const createDialog = useDialog();
  const deleteDialog = useDialogWithItem<PositionedDeviceData>();

  return (
    <>
      <Helmet>
        <title>Virtual Devices</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FiltersDialog
          isLoading={ isLoading }
          filters={ filters }
          onSave={ setFilters }
          onReset={ resetFilters }
        />
      </div>
      <div
        className={ classes.table }
        data-testid="virtual-devices-table"
        data-loaded={ !isLoading }
      >
        { devices.length === 0 && !isUserHasVirtualDevices && !isLoading ?
          <DevicesNotFound onAdd={ createDialog.open }/> :
          <VirtualDevicesTable
            devices={ devices }
            deviceSettings={ deviceSettings }
            deviceSettingsLoading={ isDeviceSettingsLoading }
            positions={ positions }
            onDelete={ deleteDialog.open }
            isLoading={ isLoading }
            total={ total }
          />
        }
        <AddButton
          label="Add virtual device"
          onClick={ createDialog.open }
        />
      </div>
      { deleteDialog.isMounted && deleteDialog.item && <DeleteVirtualDeviceDialog
        device={ deleteDialog.item }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
      /> }
      { createDialog.isMounted && <CreateVirtualDeviceDialog
        isOpen={ createDialog.isOpen }
        onClose={ createDialog.close }
        onCloseEnd={ createDialog.unmount }
      /> }
    </>
  );
};
