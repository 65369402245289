import { put, takeEvery, delay, fork, take, cancel } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  SET_USER_DATA,
  GET_USER_DATA,
  START_BROWSER_SESSION,
  STOP_BROWSER_SESSION,
  UserPayload
} from 'actions/user-profile';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { EnqueueSnackbar, CloseSnackbar } from 'actions/notifier';
import action from 'components/Notifier/ReloadAction';
import { Notification } from 'models/notification';

const NotificationOptions: Notification = {
  message: 'Your browser session is expired. Please, reload page to continue...',
  options: {
    variant: 'warning',
    persist: true,
    action
  }
};

const getSession = (): Promise<CognitoUserSession | null> => {
  return Auth.currentSession().catch(() => null);
};

export default function* getUserData() {
  const session: CognitoUserSession | null = yield getSession();

  if (session) {
    const user: UserPayload = session.getIdToken().payload;
    yield put({ type: SET_USER_DATA, user });
  }
}

function* showExpiredMessage() {
  yield delay(8 * 60 * 60 * 1000); // 8 hours
  yield put(EnqueueSnackbar(NotificationOptions));
}

function* closeAllSnackbars() {
  yield put(CloseSnackbar(0));
}

export const userProfileSagas = [
  takeEvery(GET_USER_DATA, getUserData),
  takeEvery(STOP_BROWSER_SESSION, closeAllSnackbars),
  bgWaitSessionExpired()
];

function* bgWaitSessionExpired(): SagaIterator {
  while (yield take(START_BROWSER_SESSION)) {
    const bgSyncTask = yield fork(showExpiredMessage);

    yield take(STOP_BROWSER_SESSION);
    yield cancel(bgSyncTask);
  }
}
