import { updateChildren } from '../node/updateChildren';
import { fillNodesWithAdditionalData } from './additional-data';
import { fetchTreeNodes } from './structure';
import { TreeInitializationOptions } from './types';

export async function refetchTree(options: TreeInitializationOptions) {
  options.updateTree((tree) => (tree.childrenLoadStatus = 'loading'));
  try {
    const filters = options.getTree().filters;
    const treeChildrenData = await fetchTreeNodes(filters);
    options.updateTree((tree) => updateChildren(tree, treeChildrenData));

    await fillNodesWithAdditionalData(options);
  } catch (e) {
    options.updateTree((tree) => (tree.childrenLoadStatus = 'error'));
  }
}
