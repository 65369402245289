export enum PermissionsMode {
  INDIVIDUAL = 'individual',
  TEMPLATE = 'template',
}

export enum ProjectsMode {
  ALL = 'all',
  NONE = 'none',
  LIST = 'list',
}
