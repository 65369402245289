import axios, { AxiosResponse } from 'axios';
import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import { errorHandler, formatQuery } from './utils';
import { UserPayload } from 'actions/user-profile';
import { IntegrationFiltersParams } from 'models/lorawan';

const urls = {
  getStatus: '/sources/status'
};

export const config: ApiGConfig = {
  name: 'lorawan',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://lorawan.source.nwave.io' : 'https://devlorawan.source.nwave.io',
};

const mailConfig = {
  url: 'https://api.mailgun.net/v3/sandbox91474bb168894e0c8d1d80a81d6e72b1.mailgun.org/messages',
  from: 'Mailgun Sandbox <postmaster@sandbox91474bb168894e0c8d1d80a81d6e72b1.mailgun.org>',
  to: 'Nwave Admin <admin@nwave.io>',
  subject: 'Hello Nwave Admin',
  username: 'api',
  password: staticEnv.MAILGUN_PASSWORD || 'password',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendEmail = (user: UserPayload): Promise<AxiosResponse<any>> => {
  return axios({
    method: 'post',
    url: mailConfig.url,
    auth: {
      username: mailConfig.username,
      password: mailConfig.password,
    },
    params: {
      from: mailConfig.from,
      to: mailConfig.to,
      subject: mailConfig.subject,
      text: `User ${user.email} has registered interes in AWS LNS integration`
    },
  }).catch((err) => err);
};

export const getStatus = (owner: IntegrationFiltersParams['owner']): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      owner_id: owner,
    })
  };

  return API
    .get(config.name, urls.getStatus, body)
    .catch(errorHandler);
};
