import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { RouteConfigChildrenProps } from 'react-router-config';
import { RouteChildrenProps, useLocation } from 'react-router-dom';

import { routesRendererBaseProps } from '../utils';

// components
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { renderRouteLabel, renderRoutes } from 'utils/routing';
import MenuIcon from './MenuIcon';
import MenuLink from './MenuLink';

// styles
import useStyles from '../styles';

enum VisibilityControl {
  AUTO = 'auto',
  MANUAL = 'manual',
}

interface VisibilityStateHookOptions {
  match: RouteChildrenProps['match'];
}

interface VisibilityStateHookResult {
  open: boolean;
  setOpen: (nextOpen: boolean) => void;
}

const useVisibilityState = ({ match }: VisibilityStateHookOptions): VisibilityStateHookResult => {
  const [visibilityControl, setVisibilityControl] = useState(VisibilityControl.AUTO);
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setVisibilityControl(VisibilityControl.AUTO);
  }, [location.pathname]);

  const toggle = (nextOpen: boolean) => {
    setVisibilityControl(VisibilityControl.MANUAL);
    setOpen(nextOpen);
  };

  return {
    open: visibilityControl === VisibilityControl.AUTO ? !!match : open,
    setOpen: toggle,
  };
};

interface SubMenuProps extends RouteConfigChildrenProps {
  iconVisible?: boolean;
  listClassName?: string;
}

const SubMenu: React.FC<SubMenuProps> = ({
  iconVisible = true,
  listClassName,
  match,
  route,
}) => {
  const { icon, path, routes } = route;
  const classes = useStyles({});
  const { open, setOpen } = useVisibilityState({ match });

  if (!path) {
    return null;
  }

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation();
    setOpen(!open);
  }

  return (
    <div>
      <ListItem
        aria-expanded={open}
        data-testid="submenu"
        button={true}
        className={clsx((match && match.isExact) ? classes.activeLink : '', classes.links)}
        onClick={handleClick}
      >
        {iconVisible && (
          <ListItemIcon>
            <MenuIcon icon={icon} match={match} />
          </ListItemIcon>
        )}
        <ListItemText primary={renderRouteLabel({ match, route })} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List className={listClassName} component="div">
          {renderRoutes({
            ...routesRendererBaseProps,
            renderRouteChildren: (props) => {
              if (!props.route.routes || !props.route.routes.length) {
                return <MenuLink {...props} />;
              }

              return (
                <SubMenu
                  iconVisible={false}
                  listClassName={classes.nested}
                  {...props}
                />
              );
            },
            routes,
          })}
        </List>
      </Collapse>
    </div>
  );
};

export default SubMenu;
