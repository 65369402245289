import React from 'react';

import { SingleProps, SelectControl } from 'components/Controls/Select';

const options = [
  {
    label: 'All',
    value: null
  },
  {
    label: 'Empty only',
    value: true
  },
  {
    label: 'Not empty only',
    value: false
  },
];

export const ShowBoxesSelector = ({
  selected,
  onChange
}: SingleProps<typeof options[number]['value']>): JSX.Element => {
  return (
    <SelectControl
      name="empty"
      label="Show boxes"
      selected={ selected }
      onChange={ onChange }
      options={ options }
    />
  );
};
