import { call, cancel, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Task as ReduxSagaTask } from '@redux-saga/types';
import { RootState } from 'reducers';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { cancelStationFlash, createStationFlash, fetchStationsFlashByParams } from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { FlashStatus, GetStationFlashParams, StationFlash } from 'models/base-station';
import { createErrorFromApiResponse } from 'utils/errors';
import { autoRunWhenVisible } from 'utils/sagas';

function* loadStationFlashDataByParams(action: Action.LoadStationFlashDataByParams) {
  yield put(Action.LoadStationFlashDataStart(action.params));
  const response: ApiResponse = yield call(fetchStationsFlashByParams, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.LoadStationFlashDataSuccess(action.params, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(Action.LoadStationFlashDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching base stations flash status: ${ message }`));
  }
}

type CreateAction =
  | Action.CreateStationFlash
  | ActionWithPromise<Action.CreateStationFlash, StationFlash>

function* doCreateStationFlash(action: CreateAction) {
  const { params } = action;

  yield put(Action.LockStationFlash(params.id));
  const response: ApiResponse = yield call(createStationFlash, params);
  yield put(Action.UnlockStationFlash(params.id));

  if (response.reason === ReasonEnum.Ok) {
    const newStation: StationFlash = response.data as StationFlash;
    yield put(Action.ChangeStationFlash(newStation, { create: true }));
    yield put(NotifySuccess(`Task #${ newStation.execution_id } for station #${ params.id } flash has been created`));
    'meta' in action && action.meta.promise.resolve(newStation);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while flashing a base station: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type CancelAction =
  | Action.CancelStationFlash
  | ActionWithPromise<Action.CancelStationFlash, StationFlash>

function* doCancelStationFlash(action: CancelAction) {
  const { stationFlash } = action;

  yield put(Action.LockStationFlash(stationFlash.id));
  const response: ApiResponse = yield call(cancelStationFlash, stationFlash.execution_id);
  yield put(Action.UnlockStationFlash(stationFlash.id));

  if (response.reason === ReasonEnum.Ok) {
    const newStationFlash =  { ...stationFlash, execution_status: FlashStatus.CANCEL };
    yield put(Action.ChangeStationFlash(newStationFlash, { update: true }));
    yield put(NotifySuccess(`Task #${ stationFlash.execution_id } for station #${ stationFlash.id } flash has been canceled`));
    'meta' in action && action.meta.promise.resolve(stationFlash);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while canceling base station flashing: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

function* syncStationFlash() {
  while (true) {
    yield take(Action.BASE_STATION_FLASH_DATA_SYNC_START);
    const syncTask: ReduxSagaTask = yield fork(function* () {
      yield call(autoRunWhenVisible, function* () {
        const state: RootState = yield select();
        const allFlash = state.baseStation.stationFlash.findAll();
        const inProgress = allFlash.filter(s => (
          [FlashStatus.PREPARE, FlashStatus.INSTALL, FlashStatus.CANCEL].includes(s.execution_status as FlashStatus)
        ));

        if (inProgress.length) {
          const params: GetStationFlashParams = {
            executionIds: inProgress.map(s => s.execution_id)
          };
          const response: ApiResponse = yield call(fetchStationsFlashByParams, params);
          if (response.reason === ReasonEnum.Ok) {
            yield put(Action.LoadStationFlashDataSuccess(params, response.data));
          }
        } else {
          yield delay(10 * 1000); // nothing update, just wait
        }
      }, 1_000);
    });

    yield take([Action.BASE_STATION_FLASH_DATA_SYNC_STOP]);
    yield cancel(syncTask);
  }
}

export default [
  takeEvery(Action.BASE_STATION_FLASH_DATA_LOAD_BY_PARAMS, loadStationFlashDataByParams),
  takeEvery(Action.BASE_STATION_FLASH_CREATE, doCreateStationFlash),
  takeEvery(Action.BASE_STATION_FLASH_CANCEL, doCancelStationFlash),
  fork(syncStationFlash)
];
