import { useAuthUserSelector } from 'hooks';
// components
import { Box, Link, Typography } from '@material-ui/core';

interface Props {
  onCreate: () => void;
}

export const EmptyStations = ({ onCreate }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingTop="10vh"
    >
      <Typography variant="subtitle1" align="center">
        You have no Base stations yet.
        { user.isAdmin && <>
          Click <Link href="#" onClick={ onCreate }>Create</Link> to add a new one
        </>}
      </Typography>
    </Box>
  );
};
