import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { usePagination } from 'hooks';

import { fetchAvgConnectivityPerPositionGroup } from 'actions/device-connectivity';
import { useLevelsSelector } from 'hooks/device-management/levels';
import { Zone, GroupsFilterFields, Group } from 'models/device-management';
import { RootState } from 'reducers';
import { Defined } from 'utils/models';
import { dmGroupsCreatePath } from 'routing/paths';

// components
import { GroupsTable } from 'components/DeviceManagement';
import AddButton from 'components/Buttons/AddButton';
import { Link } from 'react-router-dom';

interface GroupsTabProps {
  zoneId: Defined<Zone['id']>;
  groups: Group[];
  groupsFetching: boolean;
}

const GroupsTab: React.FC<GroupsTabProps> = ({
  zoneId,
  groups,
  groupsFetching,
}) => {
  const { limit, page } = usePagination();

  const { findLevelById, isLoading: levelsLoading } = useLevelsSelector({
    params: { zones: [zoneId] },
    withFindById: true,
  });
  const connectivityByGroupId = useSelector((state: RootState) => ({
    dictionary: state.connectivity.avgByPositionGroupId,
    loading: state.connectivity.avgByPositionGroupIdFetching,
  }), shallowEqual);

  const dispatch = useDispatch();
  const filters: GroupsFilterFields = { zones: [zoneId] };
  useDeepCompareEffect(() => {
    dispatch(fetchAvgConnectivityPerPositionGroup(filters));
  }, [filters]);

  return (
    <>
      <GroupsTable
        positionGroups={ groupsFetching ? [] : groups }
        isLoading={ groupsFetching }
        findLevelById={ findLevelById }
        levelsLoading={ levelsLoading }
        connectivityByGroupId={ connectivityByGroupId }
        containsAllFields={ false }
        limit={ limit }
        page={ page }
      />

      <Link to={{
        pathname: dmGroupsCreatePath,
        state: { zoneId }
      }}>
        <AddButton label="Add group" />
      </Link>
    </>
  );
};

export default GroupsTab;
