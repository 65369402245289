import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import {
  PositionsFilterFields,
  EnumPositionsFilterFields as PositionsFields,
  initPositionsFilters,
} from 'models/device-management';
import { UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';

function getPositionsUrlFilters(urlState: UrlItems): PositionsFilterFields {
  const parser = new Parser(urlState as PositionsFilterFields);
  return {
    owner: parser.asNumber(PositionsFields.owner),
    positions: parser.asNumbers(PositionsFields.positions) ?? [],
    levels: parser.asNumbers(PositionsFields.levels) ?? [],
    labelIds: parser.asNumbers(PositionsFields.labelIds) ?? [],
    groupIds: parser.asNumbers(PositionsFields.groupIds) ?? [],
    groupInnerIds: parser.asStrings(PositionsFields.groupInnerIds) ?? [],
    projects: parser.asNumbers(PositionsFields.projects) ?? [],
    zones: parser.asNumbers(PositionsFields.zones) ?? [],
    bindingStatus: parser.asString(PositionsFields.bindingStatus),
    networkIds: parser.asStrings(PositionsFields.networkIds) ?? [],
  };
}



export const usePositionsPageParams = (defaultFilters?: PositionsFilterFields) => {
  const { items, setItems } = useLocationItems();
  const [filters, setFilters] = useState<PositionsFilterFields>(initPositionsFilters);
  const { limit, offset } = usePagination();

  useEffectOnce(() => {
    delete items.limit;
    delete items.offset;
    setFilters({ ...getPositionsUrlFilters(items), ...defaultFilters });
  });

  return {
    params: { ...filters, limit, offset },
    filters,
    setFilters: (filters: PositionsFilterFields) => {
      setFilters(filters);
      setItems({ ...filters, limit, offset: 0 });
    }
  };
};