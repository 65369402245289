import React from 'react';
import { StationReceiverType, StationReceiverTypeTitles } from 'models/base-station';

interface Props {
  receiverType: StationReceiverType;
}

export const BaseStationReceiverType = ({ receiverType }: Props): JSX.Element => {
  const label = StationReceiverTypeTitles.get(receiverType) ?? '-';

  return (
    <>{ label }</>
  );
};
