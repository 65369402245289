import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchAsync } from 'utils/store';
import { RootState } from 'reducers';
import { ActivateVirtualDevice, LoadVirtualDeviceDataByParams } from 'actions/device-management/virtual-devices';

import { FindDevicesParams, PositionedDeviceData } from 'models/device-management';

export interface VirtualDevicesState {
  devices: PositionedDeviceData[];
  isLoading: boolean;
}

export function useVirtualDevicesParamsSelector(params: FindDevicesParams): VirtualDevicesState {
  const dispatch = useDispatch();
  const { isFetched, devices } = useSelector((state: RootState) => ({
    isFetched: state.deviceManagement.virtualDevices.isFetched(params),
    devices: state.deviceManagement.virtualDevices.findByParams(params)
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadVirtualDeviceDataByParams(params));
    }
  }, [isFetched, params, dispatch]);

  return {
    devices: devices,
    isLoading: isFetched !== true
  };
}

export const useVirtualDeviceActivation = () => {
  const dispatch = useDispatch();
  const onActivateDevice = (device?: PositionedDeviceData) => {
    if (device) {
      return dispatchAsync(
        dispatch,
        ActivateVirtualDevice(device)
      );
    }
    return Promise.resolve();
  };

  return {
    onActivateDevice
  };
};
