import React from 'react';
import { Position, PositionedDeviceData } from 'models/device-management';
import { DeviceStatesV2 } from 'models/device-monitoring';
import { getMarkerIconDot } from 'utils/map';

// components
import { Marker } from 'react-google-maps';
import { InfoWindowComponent as InfoWindow } from './InfoWindow';

interface Props {
  position: Position;
  select?: number;
  deviceStates?: DeviceStatesV2[];
  aroundDevices?: PositionedDeviceData[];
  handleSelect: (select: number | undefined) => void;
}

export const MapMarker = ({ position, select, aroundDevices, deviceStates, handleSelect }: Props): JSX.Element => {
  const { id, lat, lon: lng, network_id: networkId } = position;
  const handleClick = () => handleSelect(select === id ? undefined : id);

  const positionedDevice = aroundDevices?.find(d => d.position_id === position.id);
  const isDamaged = positionedDevice ? Boolean(positionedDevice.damaged_status) : false;
  const isPositioned = positionedDevice ? Boolean(positionedDevice.position_id) : false;
  const state = positionedDevice && deviceStates?.find((state: DeviceStatesV2) => state.device_id.toLowerCase() === positionedDevice.device_id.toLowerCase());
  const icon = getMarkerIconDot(isDamaged, isPositioned, state);
  return (
    <Marker
      onClick={ handleClick }
      position={ { lat, lng } }
      icon={ icon }
      defaultZIndex={ 2 }
    >
      { select === id && (
        <InfoWindow
          onCloseClick={ () => handleSelect(undefined) }
          positionId={ id }
          networkId={ networkId }
          icon={ icon }
          deviceId={ positionedDevice?.device_id }
        />
      )}
    </Marker>
  );
};
