import { useDispatch } from 'react-redux';

import { findPositions } from 'actions/device-management/positions';
import { useDialog, useAuthUserSelector } from 'hooks';
import { PositionWithLevel } from 'models/device-management';

// components
import { CommonButton } from 'components/Buttons';
import { DmLabels } from 'components/DmLabels';
import { EditPositionLabelsDialog } from 'components/DeviceManagement';

// styles
import { makeStyles, createStyles } from 'styles/utils';
import { isAllowedToUpdatePosition } from 'utils/permissions';

const useStyles = makeStyles(_theme => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  noLabelsText: {
    alignSelf: 'center',
  },
  editButton: {
    flexShrink: 0,
  },
}));

interface PositionLabelsProps {
  position: PositionWithLevel;
  positionOwnerId?: number;
}

export const PositionLabels = ({ position, positionOwnerId }: PositionLabelsProps) => {
  const { data: user } = useAuthUserSelector();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const classes = useStyles();
  const positionLabels = position.labels ?? [];

  return (
    <div className={ classes.root }>
      { positionLabels.length
        ? <DmLabels labels={ position.labels ?? [] } />
        : <div className={ classes.noLabelsText }>No labels attached</div>
      }

      { isAllowedToUpdatePosition(user) && (
        <CommonButton
          type="text"
          label="Edit labels"
          ButtonProps={ { className: classes.editButton } }
          onClick={ dialog.open }
        />
      ) }

      { dialog.isMounted && (
        <EditPositionLabelsDialog
          position={ position }
          positionOwnerId={ positionOwnerId }
          isOpen={ dialog.isOpen }
          onClose={ dialog.close }
          onCloseEnd={ dialog.unmount }
          onSuccess={ () => {
            dialog.close();
            dispatch(findPositions({ positions: [position.id] }));
          }}
        />
      ) }
    </div>
  );
};
