import React from 'react';
import { Label } from './utils';

// components
import { DmLabel } from './DmLabel';

// styles
import { useLabelsStyles } from './styles';

interface LabelsProps {
  labels: Label[];
}

export const DmLabels = ({ labels }: LabelsProps) => {
  const classes = useLabelsStyles();

  return (
    <div className={ classes.root }>
      { labels.map(label =>
        <DmLabel className={ classes.item } label={ label } key={ label.label } />
      ) }
    </div>
  );
};
