import React from 'react';
import { StationFrequency, StationFrequencyTitles } from 'models/base-station';

interface Props {
  stationFrequency: StationFrequency;
}

export const BaseStationFrequency = ({ stationFrequency }: Props): JSX.Element => {
  const label = StationFrequencyTitles.get(stationFrequency) ?? `${ stationFrequency.toFixed(1) } MHz`;

  return (
    <>{ label }</>
  );
};
