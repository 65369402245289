import { useMemo } from 'react';

import { State } from 'reducers/pages/station-monitoring';
import { prepareHistory, chartDataWithoutDecimation } from '../utils';

interface NoiseLevelChartDataOptions {
  noiseHistory: State['noiseLevelHistory'];
}

export function useNoiseLevelChartData({ noiseHistory }: NoiseLevelChartDataOptions) {
  const lineData = useMemo(() => {
    if (!noiseHistory.dateRange) {
      return { data: [], minValue: 0, maxValue: 1 };
    }

    const noiseHistoryData = prepareHistory(noiseHistory.data);

    return chartDataWithoutDecimation(noiseHistoryData, 'dB');
  }, [noiseHistory]);

  return lineData;
}
