import React from 'react';
import { UserGroup } from 'models/user-management';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
import { CreateUserGroup } from 'actions/user-management';
import { getClientDetailPath } from 'routing/paths';
// components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { UserGroupForm, UserGroupFormField } from 'components/UserManagement';
// styles
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const UserGroupCreateDialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isOpen, onClose, onCloseEnd } = props;
  const form = useForm<UserGroup, UserGroupFormField>({
    initialState: { id: 0, name: '' },
    showFields: [
      UserGroupFormField.name,
      UserGroupFormField.address,
      UserGroupFormField.phone_number,
    ]
  });
  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    canSave: true,
    btnSave: 'Add',
    onSave: () => {
      if (!form.validate()) {
        return false;
      }
      doAction({
        action: CreateUserGroup(form.state),
        onSuccess: userGroup => ({ push: true, to: getClientDetailPath(userGroup.id) }),
        onError: form.catchError,
      });
    },
    onCancel: () => onClose(),
  });

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      onExited={ onCloseEnd }
      className={ classes.dialogRoot }
      maxWidth={ 'sm' }
      fullWidth
    >
      <DialogTitle>Add client</DialogTitle>
      <DialogContent>
        <UserGroupForm { ...form } />
      </DialogContent>
      <DialogActions> { actions } </DialogActions>
    </Dialog>
  );
};
