import { Box, ProvisioningBoxesFilters, GetBoxesParams, BoxCreateBody } from 'models/provisioning';
import history from 'utils/routing/history';

export const FETCH_BOXES = 'provisioning/FETCH_BOXES';
export const FETCH_BOXES_BY_FILTERS = 'provisioning/FETCH_BOXES_BY_FILTERS';
export const FETCH_BOXES_SUCCESS = 'provisioning/FETCH_BOXES_SUCCESS';
export const FETCH_BOXES_FAILURE = 'provisioning/FETCH_BOXES_FAILURE';

export const SET_BOXES_FILTERS = 'provisioning/SET_BOXES_FILTERS';
export const UPDATE_BOXES_FILTERS_FROM_URL = 'provisioning/UPDATE_BOXES_FILTERS_FROM_URL';

export const FETCH_BOX_BY_ID = 'provisioning/FETCH_BOX_BY_ID';
export const FETCH_BOX_BY_ID_SUCCESS = 'provisioning/FETCH_BOX_BY_ID_SUCCESS';
export const FETCH_BOX_BY_ID_FAILURE = 'provisioning/FETCH_BOX_BY_ID_FAILURE';

export const BOX_UPDATE = 'provisioning/BOX_UPDATE';
export const BOX_UPDATE_SUCCESS = 'provisioning/BOX_UPDATE_SUCCESS';
export const BOX_UPDATE_FAILURE = 'provisioning/BOX_UPDATE_FAILURE';

export const BOX_DELETE = 'provisioning/BOX_DELETE';
export const BOX_DELETE_SUCCESS = 'provisioning/BOX_DELETE_SUCCESS';
export const BOX_DELETE_FAILURE = 'provisioning/BOX_DELETE_FAILURE';

export const ADD_BOXES_TO_SHIPMENT = 'provisioning/ADD_BOXES_TO_SHIPMENT';
export const ADD_BOXES_TO_SHIPMENT_SUCCESS = 'provisioning/ADD_BOXES_TO_SHIPMENT_SUCCESS';
export const ADD_BOXES_TO_SHIPMENT_FAILURE = 'provisioning/ADD_BOXES_TO_SHIPMENT_FAILURE';

export const REMOVE_BOX_FROM_SHIPMENT = 'provisioning/REMOVE_BOX_FROM_SHIPMENT';
export const REMOVE_BOX_FROM_SHIPMENT_SUCCESS = 'provisioning/REMOVE_BOX_FROM_SHIPMENT_SUCCESS';
export const REMOVE_BOX_FROM_SHIPMENT_FAILURE = 'provisioning/REMOVE_BOX_FROM_SHIPMENT_FAILURE';

export const BOX_CREATE = 'provisioning/BOX_CREATE';
export const BOX_CREATE_SUCCESS = 'provisioning/BOX_CREATE_SUCCESS';
export const BOX_CREATE_FAILURE = 'provisioning/BOX_CREATE_FAILURE';

export const BOX_CREATE_BULK = 'provisioning/BOX_BULK_CREATE';
export const BOX_CREATE_BULK_SUCCESS = 'provisioning/BOX_CREATE_BULK_SUCCESS';
export const BOX_CREATE_BULK_FAILURE = 'provisioning/BOX_CREATE_BULK_FAILURE';

export interface FetchProvisioningBoxes {
  readonly type: typeof FETCH_BOXES;
  params: GetBoxesParams;
}
export interface FetchProvisioningBoxesByFilters {
  readonly type: typeof FETCH_BOXES_BY_FILTERS;
  readonly filters: GetBoxesParams;
}

export interface FetchProvisioningBoxesSuccess {
  readonly type: typeof FETCH_BOXES_SUCCESS;
  readonly boxes: Box[];
  readonly total: number;
}

export interface FetchProvisioningBoxesFailure {
  readonly type: typeof FETCH_BOXES_FAILURE;
}

export interface UpdateProvisioningBoxesFromUrl {
  readonly type: typeof UPDATE_BOXES_FILTERS_FROM_URL;
}

export interface SetProvisioningBoxesFilters {
  readonly type: typeof SET_BOXES_FILTERS;
  readonly filters: ProvisioningBoxesFilters;
}

export interface FetchBoxById {
  readonly type: typeof FETCH_BOX_BY_ID;
  readonly id: number;
}

export interface FetchBoxByIdSuccess {
  readonly type: typeof FETCH_BOX_BY_ID_SUCCESS;
  readonly box: Box;
}
export interface FetchBoxByIdFailure {
  readonly type: typeof FETCH_BOX_BY_ID_FAILURE;
}

export interface UpdateBox {
  readonly type: typeof BOX_UPDATE;
  readonly id: number;
  readonly data: BoxCreateBody;
}
export interface UpdateBoxSuccess {
  readonly type: typeof BOX_UPDATE_SUCCESS;
  readonly box: Box;
}
export interface UpdateBoxFailure {
  readonly type: typeof BOX_UPDATE_FAILURE;
}

export interface DeleteBox {
  readonly type: typeof BOX_DELETE;
  readonly boxId: number;
  readonly history: typeof history;
}
export interface DeleteBoxSuccess {
  readonly type: typeof BOX_DELETE_SUCCESS;
}
export interface DeleteBoxFailure {
  readonly type: typeof BOX_DELETE_FAILURE;
}

export interface AddBoxesToShipment {
  readonly type: typeof ADD_BOXES_TO_SHIPMENT;
  readonly boxes: number[];
  readonly shipmentId: string;
}
export interface AddBoxesToShipmentSuccess {
  readonly type: typeof ADD_BOXES_TO_SHIPMENT_SUCCESS;
  readonly addedBoxes: Box[];
}
export interface AddBoxesToShipmentFailure {
  readonly type: typeof ADD_BOXES_TO_SHIPMENT_FAILURE;
  readonly addedBoxes: Box[];
}

export interface RemoveBoxFromShipment {
  readonly type: typeof REMOVE_BOX_FROM_SHIPMENT;
  boxId: number;
}
export interface RemoveBoxFromShipmentSuccess {
  readonly type: typeof REMOVE_BOX_FROM_SHIPMENT_SUCCESS;
  readonly removedBoxId: number;
}
export interface RemoveBoxFromShipmentFailure {
  readonly type: typeof REMOVE_BOX_FROM_SHIPMENT_FAILURE;
}

export interface CreateBox {
  readonly type: typeof BOX_CREATE;
  readonly box: BoxCreateBody;
}
export interface CreateBoxSuccess {
  readonly type: typeof BOX_CREATE_SUCCESS;
  readonly box: Box;
}
export interface CreateBoxFailure {
  readonly type: typeof BOX_CREATE_FAILURE;
}

export interface CreateBoxBulk {
  readonly type: typeof BOX_CREATE_BULK;
  readonly box: BoxCreateBody;
  readonly quantity: number;
}
export interface CreateBoxBulkSuccess {
  readonly type: typeof BOX_CREATE_BULK_SUCCESS;
  readonly boxes: Box[];
}
export interface CreateBoxBulkFailure {
  readonly type: typeof BOX_CREATE_BULK_FAILURE;
  readonly error: string;
}

export const DoFetchProvisioningBoxes: (params: GetBoxesParams) => FetchProvisioningBoxes = (params: GetBoxesParams) => ({
  type: FETCH_BOXES,
  params,
});

export const DoFetchProvisioningBoxesByFilters: (
  filters: GetBoxesParams
) => FetchProvisioningBoxesByFilters = filters => ({
  type: FETCH_BOXES_BY_FILTERS,
  filters,
});

export const DoFetchProvisioningBoxesSuccess: (
  boxes: Box[],
  total: number
) => FetchProvisioningBoxesSuccess = (boxes, total) => ({
  type: FETCH_BOXES_SUCCESS,
  boxes,
  total,
});

export const DoFetchProvisioningBoxesFailure: () => FetchProvisioningBoxesFailure = () => ({
  type: FETCH_BOXES_FAILURE
});

export const DoUpdateProvisioningBoxesFromUrl: () => UpdateProvisioningBoxesFromUrl = () => ({
  type: UPDATE_BOXES_FILTERS_FROM_URL
});

export const DoSetProvisioningBoxesFilters: (
  filters: ProvisioningBoxesFilters
) => SetProvisioningBoxesFilters = filters => ({
  type: SET_BOXES_FILTERS,
  filters,
});

export const DoFetchBoxById: (id: number) => FetchBoxById = id => ({
  type: FETCH_BOX_BY_ID,
  id
});

export const DoFetchBoxByIdSuccess: (box: Box) => FetchBoxByIdSuccess = box => ({
  type: FETCH_BOX_BY_ID_SUCCESS,
  box
});

export const DoFetchBoxByIdFailure: () => FetchBoxByIdFailure = () => ({
  type: FETCH_BOX_BY_ID_FAILURE
});
export const DoDeleteBox: (boxId: number) => DeleteBox = boxId => ({
  type: BOX_DELETE,
  boxId,
  history,
});
export const DoDeleteBoxSuccess: () => DeleteBoxSuccess = () => ({
  type: BOX_DELETE_SUCCESS,
});
export const DoDeleteBoxFailure: () => DeleteBoxFailure = () => ({
  type: BOX_DELETE_FAILURE,
});

export const DoUpdateBox: (id: number, data: BoxCreateBody) => UpdateBox = (id, data) => ({
  type: BOX_UPDATE,
  id,
  data,
});
export const DoUpdateBoxSuccess: (box: Box) => UpdateBoxSuccess = box => ({
  type: BOX_UPDATE_SUCCESS,
  box
});
export const DoUpdateBoxFailure: () => UpdateBoxFailure = () => ({
  type: BOX_UPDATE_FAILURE,
});

export const DoAddBoxesToShipment: (boxes: number[], shipmentId: string) => AddBoxesToShipment = (boxes, shipmentId) => ({
  type: ADD_BOXES_TO_SHIPMENT,
  boxes,
  shipmentId,
});
export const DoAddBoxesToShipmentSuccess: (addedBoxes: Box[]) => AddBoxesToShipmentSuccess = addedBoxes => ({
  type: ADD_BOXES_TO_SHIPMENT_SUCCESS,
  addedBoxes,
});
export const DoAddBoxesToShipmentFailure: (addedBoxes: Box[]) => AddBoxesToShipmentFailure = addedBoxes => ({
  type: ADD_BOXES_TO_SHIPMENT_FAILURE,
  addedBoxes,
});

export const DoRemoveBoxFromShipment: (boxId: number) => RemoveBoxFromShipment = boxId => ({
  type: REMOVE_BOX_FROM_SHIPMENT,
  boxId,
});
export const DoRemoveBoxFromShipmentSuccess: (removedBoxId: number) => RemoveBoxFromShipmentSuccess = removedBoxId => ({
  type: REMOVE_BOX_FROM_SHIPMENT_SUCCESS,
  removedBoxId,
});
export const DoRemoveBoxFromShipmentFailure: () => RemoveBoxFromShipmentFailure = () => ({
  type: REMOVE_BOX_FROM_SHIPMENT_FAILURE,
});

export const DoCreateBox: (box: BoxCreateBody) => CreateBox = box => ({
  type: BOX_CREATE,
  box,
});
export const DoCreateBoxSuccess: (box: Box) => CreateBoxSuccess = box => ({
  type: BOX_CREATE_SUCCESS,
  box
});
export const DoCreateBoxFailure: () => CreateBoxFailure = () => ({
  type: BOX_CREATE_FAILURE,
});

export const DoCreateBoxBulk = (box: BoxCreateBody, quantity = 1): CreateBoxBulk  => ({
  type: BOX_CREATE_BULK, box, quantity
});

export const DoCreateBoxBulkSuccess = (boxes: Box[]): CreateBoxBulkSuccess => ({
  type: BOX_CREATE_BULK_SUCCESS, boxes
});

export const DoCreateBoxBulkFailure = (error: string): CreateBoxBulkFailure => ({
  type: BOX_CREATE_BULK_FAILURE, error
});

export type Action = (
  | FetchProvisioningBoxesByFilters
  | FetchProvisioningBoxes
  | FetchProvisioningBoxesSuccess
  | FetchProvisioningBoxesFailure
  | UpdateProvisioningBoxesFromUrl
  | SetProvisioningBoxesFilters
  | FetchBoxById
  | FetchBoxByIdSuccess
  | FetchBoxByIdFailure
  | DeleteBox
  | DeleteBoxSuccess
  | DeleteBoxFailure
  | UpdateBox
  | UpdateBoxSuccess
  | UpdateBoxFailure
  | AddBoxesToShipment
  | AddBoxesToShipmentSuccess
  | AddBoxesToShipmentFailure
  | RemoveBoxFromShipment
  | RemoveBoxFromShipmentSuccess
  | RemoveBoxFromShipmentFailure
  | CreateBox
  | CreateBoxSuccess
  | CreateBoxFailure
  | CreateBoxBulk
  | CreateBoxBulkSuccess
  | CreateBoxBulkFailure
);
