import { put, takeEvery, call, delay, takeLatest } from 'redux-saga/effects';

import { ReasonEnum, ApiResponse } from 'models';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { configureQueryClient, clearQueryCache } from 'utils/react-query';
import {
  FETCH_BOXES_BY_FILTERS,
  FETCH_BOXES,
  ADD_BOX_IDS,
  DoFetchProvisioningBoxesFailure,
  DoFetchProvisioningBoxesSuccess,
  FetchProvisioningBoxesByFilters,
  AddBoxIds,
  FetchBoxById,
  DoFetchBoxByIdSuccess,
  DoFetchBoxByIdFailure,
  DeleteBox,
  FETCH_BOX_BY_ID,
  BOX_UPDATE,
  BOX_DELETE,
  DoDeleteBoxSuccess,
  DoDeleteBoxFailure,
  UpdateBox,
  DoUpdateBoxSuccess,
  DoUpdateBoxFailure,
  ADD_BOXES_TO_SHIPMENT,
  AddBoxesToShipment,
  RemoveBoxFromShipment,
  REMOVE_BOX_FROM_SHIPMENT,
  DoAddBoxesToShipmentFailure,
  DoAddBoxesToShipmentSuccess,
  DoRemoveBoxFromShipmentSuccess,
  DoRemoveBoxFromShipmentFailure,
  BOX_CREATE,
  CreateBox,
  DoCreateBoxSuccess,
  DoCreateBoxFailure,
  BOX_CREATE_BULK,
  CreateBoxBulk,
  DoCreateBoxBulkFailure,
  DoCreateBoxBulkSuccess,
  FetchProvisioningBoxes,
} from 'actions/provisioning';
import {
  ResponseBoxes,
  GetBoxesParams,
  initProvisioningBoxesFilters,
  ResponseBox,
  Box,
} from 'models/provisioning';
import {
  fetchProvisioningBoxes,
  getBoxById,
  removeBoxById,
  updateBoxById as updateById,
  addBoxToShipment,
  removeBoxFromShipment,
  createBox,
  createBoxBulk
} from 'clients/provisioning';
import { ActionWithPromise } from 'utils/store';

// @TODO move to fetchProvisioningBoxesCall
function* fetchProvisioningBoxesByState(action: FetchProvisioningBoxes) {
  yield call(fetchProvisioningBoxesCall, action.params);
}

function* fetchProvisioningBoxesByFilters(action: FetchProvisioningBoxesByFilters) {
  yield call(fetchProvisioningBoxesCall, action.filters);
}

function* fetchProvisioningBoxesCall(params: GetBoxesParams) {
  const response: ResponseBoxes = yield call(fetchProvisioningBoxes, params);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoFetchProvisioningBoxesSuccess(response.data, response.total || 0));
  } else {
    yield put(DoFetchProvisioningBoxesFailure());
    yield put(NotifyError(`Error while fetching boxes provisioning: ${ response.message }`));
  }
}


let boxIdsAccumulator: number[] = [];
function* getBoxsWorker(action: AddBoxIds) {
  boxIdsAccumulator.push(action.id);
  yield delay(300);

  boxIdsAccumulator = Array.from(new Set<number>(boxIdsAccumulator));
  yield call(fetchProvisioningBoxesCall, {
    ...initProvisioningBoxesFilters,
    box_id: boxIdsAccumulator,
    limit: 9999
  });

  boxIdsAccumulator = [];
}

function* fetchBoxById(action: FetchBoxById) {
  const response: ResponseBox = yield call(getBoxById, action.id);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoFetchBoxByIdSuccess(response.data));
  } else {
    yield put(DoFetchBoxByIdFailure());
    yield put(NotifyError(`Error while fetching box provisioning: ${ response.message }`));
  }
}

type DeleteAction =
  | DeleteBox
  | ActionWithPromise<DeleteBox, Box>

function* deleteBoxById(action: DeleteAction) {
  const response: ApiResponse = yield call(removeBoxById, action.boxId);

  if (response.reason === ReasonEnum.Ok) {
    yield put(DoDeleteBoxSuccess());
    yield put(NotifySuccess(`Box has been deleted`));
    yield clearCache();
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(DoDeleteBoxFailure());
    yield put(NotifyError(`Error while deleting a shipment: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type UpdateAction =
  | UpdateBox
  | ActionWithPromise<UpdateBox, Box>

function* updateBoxById(action: UpdateAction) {
  const response: ResponseBox = yield call(updateById, action.id, action.data);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoUpdateBoxSuccess(response.data));
    yield put(NotifySuccess(`Box has been updated`));
    yield clearCache();
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(DoUpdateBoxFailure());
    yield put(NotifyError(`Error while updating a box: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type AddBoxesToShipmentAction =
  | AddBoxesToShipment
  | ActionWithPromise<AddBoxesToShipment, Box[]>

function* addBoxesToShipmentSaga(action: AddBoxesToShipmentAction) {
  const addedBoxes: Box[] = [];
  for (const box of action.boxes) {
    const response: ResponseBox = yield call(addBoxToShipment, box, action.shipmentId);

    if (response.reason === ReasonEnum.Ok && response.data) {
      addedBoxes.push(response.data);
    } else {
      yield put(DoAddBoxesToShipmentFailure(addedBoxes));
      yield put(NotifyError(`Error while adding box ${ box } to shipment: ${ response.message }`));
      'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
      break;
    }
  }

  if (addedBoxes.length === action.boxes.length) {
    yield put(DoAddBoxesToShipmentSuccess(addedBoxes));
    yield put(NotifySuccess(
      `${ action.boxes.length } ` +
      `${ action.boxes.length === 1 ? 'Box' : 'Boxes' } ` +
      `${ action.boxes.length === 1 ? 'has' : 'have' } been added to shipment`
    ));
    yield clearCache();
    // @TODO not obvious cache clearing :(
    // we work with box but invalidate devices cache
    yield clearQueryCache([
      'provisioning/shipment/devices',
      'provisioning/shipment/received/devices',
      'provisioning/aws/devices'
    ]);
    'meta' in action && action.meta.promise.resolve(addedBoxes);
  }
}

type removeBoxFromShipmentAction =
  | RemoveBoxFromShipment
  | ActionWithPromise<RemoveBoxFromShipment, Box['id']>

function* removeBoxFromShipmentSaga(action: removeBoxFromShipmentAction) {
  const response: ResponseBox = yield call(removeBoxFromShipment, action.boxId);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoRemoveBoxFromShipmentSuccess(action.boxId));
    yield put(NotifySuccess(`Box has been deleted from shipment`));
    yield clearCache();
    // @TODO not obvious cache clearing :(
    // we work with box but invalidate devices cache
    yield clearQueryCache([
      'provisioning/shipment/devices',
      'provisioning/shipment/received/devices',
      'provisioning/aws/devices'
    ]);
    'meta' in action && action.meta.promise.resolve(action.boxId);
  } else {
    yield put(DoRemoveBoxFromShipmentFailure());
    yield put(NotifyError(`Error while deleting a box from shipment: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type CreateAction =
  | CreateBox
  | ActionWithPromise<CreateBox, Box>

function* createBoxSaga(action: CreateAction) {
  const response: ResponseBox = yield call(createBox, action.box);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoCreateBoxSuccess(response.data));
    yield put(NotifySuccess(`Box has been created`));
    yield clearCache();
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(DoCreateBoxFailure());
    yield put(NotifyError(`Error while creating a box: ${ response.message }`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

type CreateBulkAction =
  | CreateBoxBulk
  | ActionWithPromise<CreateBoxBulk, Box[]>

function* createBoxBulkSaga(action: CreateBulkAction) {
  const response: ResponseBoxes = yield call(createBoxBulk, action.box, action.quantity);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoCreateBoxBulkSuccess(response.data));
    yield put(NotifySuccess(`Boxes have been created`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    const message = response.message || 'Server Error';
    yield put(DoCreateBoxBulkFailure(message));
    yield put(NotifyError(`Error while creating boxes: ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

function* clearCache() {
  yield configureQueryClient().refetchQueries(
    {
      predicate: query => String(query.queryKey[0] ?? '').startsWith('provisioning/boxes'),
    },
  );
}

export const provisioningBoxesSagas = [
  takeEvery(FETCH_BOXES, fetchProvisioningBoxesByState),
  takeEvery(FETCH_BOXES_BY_FILTERS, fetchProvisioningBoxesByFilters),
  takeEvery(FETCH_BOX_BY_ID, fetchBoxById),
  takeEvery(BOX_CREATE, createBoxSaga),
  takeEvery(BOX_CREATE_BULK, createBoxBulkSaga),
  takeEvery(BOX_UPDATE, updateBoxById),
  takeEvery(BOX_DELETE, deleteBoxById),
  takeEvery(ADD_BOXES_TO_SHIPMENT, addBoxesToShipmentSaga),
  takeEvery(REMOVE_BOX_FROM_SHIPMENT, removeBoxFromShipmentSaga),
  takeLatest(ADD_BOX_IDS, getBoxsWorker),
];
