import { call, put, takeEvery } from 'redux-saga/effects';

import * as Action from 'actions/device-management/positions-repository';
import { NotifyError } from 'actions/notifier';
import { findPositions } from 'clients/device-management';
import { ReasonEnum, ApiResponse } from 'models';
import { ResponsePositionsWithTotal } from 'models/device-management';
import { createErrorFromApiResponse } from 'utils/errors';

function* loadPositionDataById(action: Action.LoadPositionsRepositoryDataById) {
  yield put(Action.LoadPositionsRepositoryDataStart(action.positionId));
  try {
    const response: ResponsePositionsWithTotal = yield call(findPositions, { positions: [action.positionId] });
    if (response.reason !== ReasonEnum.Ok) {
      // TODO: fix type
      throw createErrorFromApiResponse(response as ApiResponse);
    }
    yield put(Action.LoadPositionsRepositoryDataSuccess(action.positionId, response.data));
  } catch (e) {
    yield put(Action.LoadPositionsRepositoryDataFailure(action.positionId, (e as Error).message));
    yield put(NotifyError(`Error while fetching position #${action.positionId}: ${(e as Error).message}`));
  }
}

function* loadPositionDataByParams(action: Action.LoadPositionsRepositoryDataByParams) {
  yield put(Action.LoadPositionsRepositoryDataStart(action.params));
  try {
    const response: ResponsePositionsWithTotal = yield call(findPositions, action.params);
    if (response.reason !== ReasonEnum.Ok) {
      // TODO: fix type
      throw createErrorFromApiResponse(response as ApiResponse);
    }
    yield put(Action.LoadPositionsRepositoryDataSuccess(action.params, response.data));
  } catch (e) {
    yield put(Action.LoadPositionsRepositoryDataFailure(action.params, (e as Error).message));
    yield put(NotifyError(`Error while fetching positions: ${(e as Error).message}`));
  }
}

export const deviceManagementPositionsRepositorySagas = [
  takeEvery(Action.POSITIONS_REPOSITORY_DATA_LOAD_BY_ID, loadPositionDataById),
  takeEvery(Action.POSITIONS_REPOSITORY_DATA_LOAD_BY_PARAMS, loadPositionDataByParams),
];
