export enum FormField {
  owner = 'owner_id',
  project = 'project_id',
  name = 'name',
  silent = 'silent',
}

export interface FormState {
  [FormField.owner]?: number;
  [FormField.project]?: number;
  [FormField.name]?: string;
  [FormField.silent]?: boolean;
}
