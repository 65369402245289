import React, { useState } from 'react';
import { useCopyToClipboard, useTimeoutFn } from 'react-use';
// components
import FileCopy from '@material-ui/icons/FileCopy';
import CommonButton, { CommonButtonProps } from './CommonButton';

type Props =
  Omit<CommonButtonProps, 'icon' | 'label' | 'type'>
  & {
    label?: CommonButtonProps['label'];
    help?: CommonButtonProps['help'];
    type?: CommonButtonProps['type'];
    copied?: string;
    value: string;
  };

export const CopyToClipboardButton = (props: Props): JSX.Element => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setCopied] = useState<boolean>(false);
  const [, , reset] = useTimeoutFn(() => setCopied(false), 2000);
  const type = props.type ?? 'icon';

  return <CommonButton
    { ...props }
    type={ type }
    label={ props.label ?? 'Copy' }
    help={ isCopied ? (props.copied ?? 'Was copied!') : (props.help ?? 'Copy to clipboard') }
    onClick={ () => {
      copyToClipboard(props.value);
      setCopied(true);
      reset();
    } }
    TooltipProps={ !isCopied ? undefined : {
      interactive: true,
      leaveDelay: 2000,
    } }
    icon={ type === 'text' ? undefined : <FileCopy/> }

  />;
};
