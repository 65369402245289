import { useState } from 'react';
import { useAsyncRetry, useDeepCompareEffect } from 'react-use';

import { CoupleParams, RouteParams } from './types';
import { getPageDeviceId, getPagePositionId } from './utils';

type Params = RouteParams & {
  onError?: (e: Error) => void;
};

export function useDevicePositionCoupleParams({ onError, ...routeParams }: Params): CoupleParams {
  const [params, setParams] = useState(routeParams);

  useDeepCompareEffect(() => {
    setParams(routeParams);
  }, [routeParams]);

  const propagateError = (e: Error) => {
    onError?.(e);
    throw e;
  };

  const { value: deviceId, retry: refetchDeviceId } = useAsyncRetry(
    () => getPageDeviceId(params).catch(propagateError),
    [params]
  );
  const { value: positionId, retry: refetchPositionId } = useAsyncRetry(
    () => getPagePositionId(params).catch(propagateError),
    [params],
  );

  return {
    deviceId,
    refetchDeviceId,

    positionId,
    refetchPositionId,

    networkId: params.networkId,
  };
}
