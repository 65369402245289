import { FC, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { setUsersPageFilters, DoUpdateUserPageFiltersFromUrl } from 'actions/pages/users';
import { fetchPermissions } from 'actions/user-management/permissions';
import { fetchUsers } from 'actions/user-management/users';
import { GetUsersParams } from 'models/user-management';
import { RootState } from 'reducers';
import { DEFAULT_FILTERS } from 'reducers/pages/users';
import { getUserSubs } from 'selectors/user-management';
import { useProjectsDictionarySelector, useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { ProjectsBag, UserGroupsBag } from './utils';

// components
import { Helmet } from 'react-helmet-async';

import FiltersBlock from './widgets/FiltersBlock';
import { FiltersShape } from './widgets/FiltersForm';
import UserTable from './widgets/UserTable';
// styles
import useStyles from 'styles/page';
import { usePagination } from 'hooks';

const useUserGroupsBag = (): UserGroupsBag => {
  const { userGroups, isLoading } = useUserGroupsDictionarySelector();
  return {
    byId: Object.fromEntries(userGroups.map(g => [g.id, g])),
    loading: isLoading
  };
};

const useUsersBag = (params: GetUsersParams) => {
  const data = useSelector((state: RootState) => ({
    bySub: state.um.userBySub,
    subsList: getUserSubs(params, state),
    loading: state.um.usersFetching,
    totalUsersByQuery: state.um.totalUsersByQuery
  }), shallowEqual);

  const userList = useMemo(
    // Unlike the following less readable code,
    // `.map().filter(Boolean)` produces `(User | undefined)[]` as a result
    () => data.subsList?.flatMap(sub => {
      const u = data.bySub[sub];
      // TODO: should we silently ignore the falsy user entity at all?
      // We could display it as being loaded at the moment or failed to load instead
      return u ? [u] : [];
    }),
    [data.bySub, data.subsList],
  );

  return {
    list: userList,
    loading: data.loading,
    totalUsersByQuery: data.totalUsersByQuery.query
  };
};

const UsersPage: FC = () => {
  const styles = useStyles();

  const filters = useSelector((state: RootState) => state.pages.users.filters);
  const isFetching = useSelector((state: RootState) => state.um.usersFetching);
  const dispatch = useDispatch();
  const users = useUsersBag(filters);
  const userGroups = useUserGroupsBag();
  const { limit, offset } = usePagination();

  useEffect(() => {
    dispatch(DoUpdateUserPageFiltersFromUrl());
  }, [dispatch]);

  useDeepCompareEffect(() => {
    dispatch(fetchUsers(filters));
  }, [dispatch, filters]);

  const refetchUsers = () => dispatch(fetchUsers(filters));

  const permissions = useSelector((state: RootState) => ({
    byId: state.um.permissionsById,
    loading: state.um.permissionsFetching,
  }), shallowEqual);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  //
  useDeepCompareEffect(() => {
    dispatch(setUsersPageFilters({ ...filters, limit, offset }, true));
  }, [dispatch, limit, offset]);


  const { projects: projectsData, isLoading: projectsFetching } = useProjectsDictionarySelector();
  const projects: ProjectsBag = {
    byId: Object.fromEntries(projectsData.map(p => [p.id, p])),
    loading: projectsFetching,
  };

  const handleFiltersSubmit = (state: FiltersShape) => {
    dispatch(setUsersPageFilters({
      ...state,
      limit: filters.limit,
      offset: 0,
    }, true));
  };

  const handleFiltersReset = () => {
    dispatch(setUsersPageFilters(DEFAULT_FILTERS, true));
  };

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <FiltersBlock
        isFetching={ isFetching }
        filters={ filters }
        onReset={ handleFiltersReset }
        onSubmit={ handleFiltersSubmit }
      />

      <UserTable
        className={styles.table}
        users={users}
        permissions={permissions}
        projects={projects}
        userGroups={userGroups}
        onDeleteUser={refetchUsers}
      />
    </>
  );
};

export default UsersPage;
