import React from 'react';
import clsx from 'clsx';
import { useAuthUserSelector } from 'hooks';
import { IntegrationData } from 'models/lorawan';
import { isNil } from 'lodash';

// components
import { Box, Typography, Paper } from '@material-ui/core';
import { IntegrationStatus } from './IntegrationStatus';
import { IntegrationAction } from './IntegrationAction';
import { OwnerName } from 'components/Labels';
import PersonIcon from '@material-ui/icons/Person';

// styles
import useStyles, { iconStyle } from './styles';

export const Integration = (props: IntegrationData & { reload: () => void }): JSX.Element => {
  const { icon, title, status, ownerId, lastReceiveTime } = props;
  const classes = useStyles();
  const user = useAuthUserSelector();

  return (
    <>
      <Paper className={ classes.paper }>
        <Box display="flex" alignItems="center">
          <img src={ icon } alt={ title } title={ title } />
          <Typography className={ classes.companyTitle }>{ title }</Typography>
        </Box>

        <Box mt={ 2 } display="flex" justifyContent="space-between" alignItems="center">
          <IntegrationStatus status={ status } lastReceiveTime={ lastReceiveTime } />
        </Box>

        {
          user.isAdmin && !isNil(ownerId) ?
            <Box mt={ 2 } display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={ clsx(classes.status, classes.statusWaiting) }>
                <PersonIcon style={ iconStyle } /> Owner
              </Typography>

              <Typography className={ clsx(classes.status, classes.statusWaiting) }>
                <OwnerName ownerId={ ownerId } />
              </Typography>
            </Box> : ''
        }

        <Box mt={ 'auto' } pt={ 2 } mx={ -1 }>
          <IntegrationAction { ...props } />
        </Box>
      </Paper>
    </>
  );
};
