import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getMessageTypes } from 'clients/http-calls';
import { MessageType, OldMessageTypes } from 'models/caller';
import { areRedesignedTemplateSuitesEnabled } from 'utils/feature-flags';
import { getResponseData } from 'utils/clients';

export interface CallerMessageTypesState {
  isLoading: boolean;
  messageTypes?: MessageType[];
}

export const useCallerMessageTypes = (): CallerMessageTypesState => {
  const dispatch = useDispatch();
  const shouldFetch = areRedesignedTemplateSuitesEnabled();

  const queryResult = useQuery({
    queryKey: ['caller/messageTypes'],
    queryFn: async () => getResponseData(await getMessageTypes()),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      dispatch(NotifyError(`Error while fetching message types: ${ (e as Error).message }`));
    },
    enabled: shouldFetch,
  });

  if (!shouldFetch) {
    return {
      messageTypes: OldMessageTypes,
      isLoading: false,
    };
  }

  return {
    messageTypes: queryResult.data,
    isLoading: queryResult.isLoading,
  };
};
