/**
 *  Compare 2 data array by comparisonKey and set `new` param to `true` for targetData elements,
 *  not found in originalData
 * @param targetData
 * @param originalData
 * @param comparisonKey - callable key creator for create complex keys. E.g. `el => ${el.device_id}-${el.trace_id}`
 */
export function setIsNewForSyncDataUpdate<T, Key>(
  targetData: T[],
  currentData: T[],
  comparisonKey: (el: T) => Key,
): (T & { new?: boolean })[] {
  const currentIds = currentData.map(el => comparisonKey(el));
  return targetData.map(el => currentIds.includes(comparisonKey(el)) ? el : { ...el, new: true });
}
