import { call, put, takeLeading } from 'redux-saga/effects';
import { createUserGroup, deleteUserGroup, fetchUserGroupsById, refreshUserGroupAccessCode, updateUserGroup } from 'clients/user-management';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/user-management';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { UserGroup, UserGroupCreate, UserGroupUpdate } from 'models/user-management';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import { clearQueryCache } from 'utils/react-query';

type CreateAction =
  | Action.CreateUserGroup
  | ActionWithPromise<Action.CreateUserGroup, UserGroup>

function* doCreateUserGroup(action: CreateAction) {
  const { userGroup } = action;
  const create: UserGroupCreate = {
    name: userGroup.name,
    address: userGroup.address,
    phone_number: userGroup.phone_number,
    client_access_code: userGroup.client_access_code,
  };
  const response: ApiResponse = yield call(createUserGroup, create);

  if (response.reason === ReasonEnum.Ok) {
    const newUserGroup = response.data as UserGroup;
    yield put(NotifySuccess(`Client ${ newUserGroup.name } has been created`));
    'meta' in action && action.meta.promise.resolve(newUserGroup);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating client ${ userGroup.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }

  yield clearQueryCache('userManagement/userGroup');
}

type UpdateAction =
  | Action.UpdateUserGroup
  | ActionWithPromise<Action.UpdateUserGroup, UserGroup>

function* doUpdateUserGroup(action: UpdateAction) {
  const { userGroup } = action;
  const update: UserGroupUpdate = {
    name: userGroup.name,
    address: userGroup.address,
    phone_number: userGroup.phone_number,
    client_access_code: userGroup.client_access_code,
  };
  const response: ApiResponse = yield call(updateUserGroup, userGroup.id, update);

  if (response.reason === ReasonEnum.Ok) {
    const newUserGroup = response.data as UserGroup;
    yield put(NotifySuccess(`Client ${ newUserGroup.name } has been updated`));
    'meta' in action && action.meta.promise.resolve(newUserGroup);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while updating client ${ userGroup.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }

  yield clearQueryCache('userManagement/userGroup');
}

function* doDeleteUserGroup(action: UpdateAction) {
  const { userGroup } = action;
  const response: ApiResponse = yield call(deleteUserGroup, userGroup.id);

  if (response.reason === ReasonEnum.Ok) {
    yield put(NotifySuccess(`Client ${ userGroup.name } has been deleted`));
    'meta' in action && action.meta.promise.resolve(userGroup);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while deleting client ${ userGroup.name }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }

  yield clearQueryCache('userManagement/userGroup');
}

type RefreshAccessCodeAction =
  | Action.RefreshUserGroupAccessCode
  | ActionWithPromise<Action.RefreshUserGroupAccessCode, UserGroup>

function* doRefreshUserGroupAccessCode(action: RefreshAccessCodeAction) {
  const { userGroup } = action;
  const refreshResponse: ApiResponse = yield call(refreshUserGroupAccessCode, userGroup.id);

  if (refreshResponse.reason !== ReasonEnum.Ok) {
    const error = createErrorFromApiResponse(refreshResponse);
    yield put(NotifyError(`Error while refreshing client ${ userGroup.name }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
    return;
  }

  const response: ApiResponse = yield call(fetchUserGroupsById, userGroup.id);
  if (response.reason === ReasonEnum.Ok) {
    const newUserGroup = response.data as UserGroup;
    yield put(NotifySuccess(`Client ${ newUserGroup.name }'s access code has been refreshed`));
    'meta' in action && action.meta.promise.resolve(userGroup);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching client ${ userGroup.name }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }

  yield clearQueryCache('userManagement/userGroup');
}

export const userGroupSagas = [
  takeLeading(Action.UM_USER_GROUP_UPDATE, doUpdateUserGroup),
  takeLeading(Action.UM_USER_GROUP_DELETE, doDeleteUserGroup),
  takeLeading(Action.UM_USER_GROUP_CREATE, doCreateUserGroup),
  takeLeading(Action.UM_USER_GROUP_REFRESH_ACCESS_CODE, doRefreshUserGroupAccessCode),
];
