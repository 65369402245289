import React from 'react';
import { StationGeneration, StationGenerationTitles } from 'models/base-station';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<StationGeneration>, 'options'>>
  & (SingleProps<StationGeneration> | MultiProps<StationGeneration>)
  & { filter?: (Frequency: StationGeneration) => boolean }

export const StationGenerationSelectControl = (props: Props): JSX.Element => {
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<StationGeneration>[] = [];

  StationGenerationTitles.forEach((title, generation) => {
    if (filterCallback(generation)) {
      options.push({
        value: generation,
        label: title,
      });
    }
  });

  return <SelectControl
    name="generation"
    label="Station Generation"
    { ...props }
    options={ options }
    checkSelected={ StationGenerationTitles.size > 0 }
    isDisabled={ props.isDisabled || !options.length }
  />;
};


