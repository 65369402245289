export enum DamagedStatuses {
  damaged = 'Damaged',
  notDamaged = 'Not damaged'
}

export enum ActivationStatuses {
  active = 'Active',
  inactive = 'Inactive'
}

export const DEFAULT_LIMIT = 20;
