import React from 'react';
// components
import { AddBoxOutlined }  from '@material-ui/icons';

import CommonButton, { CommonButtonProps } from './CommonButton';

type Props =
  Omit<CommonButtonProps, 'icon' | 'label' | 'type'>
  & {
    label?: CommonButtonProps['label'];
    type?: CommonButtonProps['type'];
  };

export const PlusButton = (props: Props): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Add';
  const type = props.type !== undefined ? props.type : 'icon';
  return (
    <CommonButton
      { ...props }
      type={ type }
      label={ label }
      icon={ type === 'text' ? undefined : <AddBoxOutlined /> }
    />
  );
};

export default PlusButton;
