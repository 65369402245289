import { call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';
import { Task as ReduxSagaTask } from '@redux-saga/types';
import { ApiResponseWithTotal, ReasonEnum } from 'models';
import { GetPositionStateParams, PositionState } from 'models/parking';
import { getPositionStates } from 'clients/parking';
import * as Actions from 'actions/parking';
import { NotifyError, NotifyWarning } from 'actions/notifier';

function* doLoadDataByParams(action: Actions.LoadPositionStateData) {
  const params: GetPositionStateParams = action.params;
  yield put({ type: Actions.PARKING_POSITION_STATE_DATA_LOADING, status: true });
  yield put({ type: Actions.PARKING_POSITION_STATE_DATA_FETCH_START });
  const response: ApiResponseWithTotal = yield call(getPositionStates, { ...params, limit: 500, offset: 0 });
  yield put({ type: Actions.PARKING_POSITION_STATE_DATA_FETCH_STOP });
  yield put({ type: Actions.PARKING_POSITION_STATE_DATA_LOADING, status: false });

  if (response.reason !== ReasonEnum.Ok) {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching occupancy data: ${ message }`));
    return;
  }

  if (!action.disableNotFoundWarning && !response.data.length) {
    yield put(NotifyWarning('Occupancy data not found'));
  }

  if (response.data.length >= response.total) {
    yield put({ type: Actions.PARKING_POSITION_STATE_DATA_CHANGE, params, data: response.data });
    return;
  }

  const fullData: PositionState[] = Array
    .from({ length: response.total })
    .map((_, index) => response.data[index] ?? response.data[0]);

  yield put({ type: Actions.PARKING_POSITION_STATE_DATA_CHANGE, params, data: fullData });

  const backgroudLoad: ReduxSagaTask = yield fork(function* (params: GetPositionStateParams, data: PositionState[]) {
    yield put({ type: Actions.PARKING_POSITION_STATE_DATA_LOADING, status: true });

    const limit = 2000;
    let offset = 500;
    while (data.length > offset) {
      const response: ApiResponseWithTotal = yield call(getPositionStates, { ...params, limit, offset });
      if (response.reason !== ReasonEnum.Ok || !response.data.length) {
        break;
      }

      data.splice(offset, response.data.length, ...response.data);
      yield put({ type: Actions.PARKING_POSITION_STATE_DATA_CHANGE, params, data: [...data] });
      offset += limit;
    }

    yield put({ type: Actions.PARKING_POSITION_STATE_DATA_LOADING, status: false });
  }, params, fullData);

  yield take([Actions.PARKING_POSITION_STATE_DATA_LOAD]);
  yield cancel(backgroudLoad);
}

export default [
  takeEvery(Actions.PARKING_POSITION_STATE_DATA_LOAD, doLoadDataByParams),
];
