import { Group, Level, PositionedDeviceData, PositionWithLevel, Zone } from 'models/device-management';
import { DeviceStatesV2 } from 'models/device-monitoring';
import { DeviceConnectivity } from 'models/connectivity';

export interface DmApiFilters {
  owner?: number;
  projects?: number[];
  zones?: number[];
  levels?: number[];
  groups?: number[];
  positions?: number[];
  networkIds?: string[];
  customIds?: string[];
  innerIds?: number[];
  devices?: string[];
  labels?: number[];

  offset: number;
  limit: number;
}

export type Filters = DmApiFilters;

export interface ZoneNode {
  id: string;
  type: 'zone';
  zone: Zone;
  children: {
    total: number;
    items: Record<string, LevelNode>;
  };
}

export interface LevelNode {
  id: string;
  parent: ZoneNode;
  type: 'level';
  level?: Level;
  children: {
    total: number;
    items: Record<string, GroupNode>;
  };
}

export interface GroupNode {
  id: string;
  parent: LevelNode;
  type: 'group';
  group: Group;
  children: {
    total: number;
    items: Record<string, PositionNode>;
  };
  devices: {
    data: Record<string, PositionedDeviceData>;
    total: number;
  };
  deviceStates: {
    data: Record<string, DeviceStatesV2>;
    total: number;
  };
  deviceConnects: {
    data: Record<string, DeviceConnectivity>;
    total: number;
  };
}

export interface PositionNode {
  id: string;
  parent: GroupNode;
  type: 'position';
  position: PositionWithLevel;
  device?: PositionedDeviceData;
  deviceState?: DeviceStatesV2;
  deviceConnect?: DeviceConnectivity;
}

export type TreeNodeType =
  | ZoneNode['type']
  | LevelNode['type']
  | GroupNode['type']
  | PositionNode['type'];

export enum QueryType {
  zone = 'zone',
  level = 'level',
  group = 'group',
  position = 'position',
  device = 'device',
  deviceConnect = 'device_connectivity',
  deviceState = 'device_state',
}

export type TreeNode =
  | ZoneNode
  | LevelNode
  | GroupNode
  | PositionNode;

export interface Tree {
  total: number;
  items: Record<string, ZoneNode>;
  loaded: {
    zone: boolean;
    level: boolean;
    group: boolean;
    position: boolean;
    device: boolean;
    deviceState: boolean;
    deviceConnect: boolean;
  };
  reload: (queryType: QueryType) => void;
}