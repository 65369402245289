import { call, put, takeEvery } from 'redux-saga/effects';
import { clearQueryCache } from 'utils/react-query';
import { ReasonEnum, ApiResponse } from 'models';
import { createVirtualDevice, updateDevice, getDevice, deleteVirtualDevice, fetchDevices } from 'clients/device-management';
import {
  Device,
  ResponsePositionedDevicesWithTotal,
  ApiResponse as GeneratedApiResponse,
  ResponseCreatedVirtualDevice,
  PositionedDeviceData,
} from 'models/device-management';
import * as Action from 'actions/device-management/virtual-devices';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createErrorFromApiResponse } from 'utils/errors';
import { ActionWithPromise } from 'utils/store';
import { CreateVirtualDeviceSusses } from 'components/Notifier/CreateVirtualDeviceSusses';

function* doLoadVirtualDevicesDataByParams(action: Action.LoadVirtualDeviceDataByParams) {
  yield put(Action.LoadVirtualDeviceDataStart(action.params));
  try {
    const response: ResponsePositionedDevicesWithTotal = yield call(fetchDevices, {
      ...action.params,
      firmwareId: action.params.firmwareHash,
      force: true,
      isVirtual: true
    });
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response as ApiResponse);
    }
    yield put(Action.LoadVirtualDeviceDataSuccess(action.params, response.data));
  } catch (e) {
    yield put(Action.LoadVirtualDeviceDataFailure(action.params, (e as Error).message));
    yield put(NotifyError(`Error while fetching virtual devices: ${ (e as Error).message }`));
  }
}

type DeleteAction =
  | Action.DeleteVirtualDevice
  | ActionWithPromise<Action.DeleteVirtualDevice, PositionedDeviceData>

function* doDeleteVirtualDevice(action: DeleteAction) {
  try {
    const response: GeneratedApiResponse = yield call(deleteVirtualDevice, action.device.device_id);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response as ApiResponse);
    }
    yield clearQueryCache('deviceManagement/devices');
    yield put(NotifySuccess(`Device ${ action.device.device_id.toUpperCase() } has been deleted`));
    'meta' in action && action.meta.promise.resolve(action.device);
  } catch (e) {
    yield put(NotifyError(`Error while deleting device ${ action.device.device_id.toUpperCase() }: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject((e as Error));
  }
}

type CreateAction =
  | Action.CreateVirtualDevice
  | ActionWithPromise<Action.CreateVirtualDevice, number>

function* doCreateVirtualDevices(action: CreateAction) {
  try {
    const deviceIds: string[] = [];
    for (let i = 0; i < action.count; i++) {
      const response: ResponseCreatedVirtualDevice = yield call(createVirtualDevice, action.device);
      if (response.reason !== ReasonEnum.Ok) {
        throw createErrorFromApiResponse(response as ApiResponse);
      }
      const device = response.data as Device;
      deviceIds.push(device.device_id);
    }
    yield clearQueryCache('deviceManagement/devices');
    yield put(NotifySuccess(CreateVirtualDeviceSusses({ deviceIds: deviceIds })));
    'meta' in action && action.meta.promise.resolve(action.count);

  } catch (e) {
    yield put(NotifyError(`Error while creating a virtual device: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject(e as Error);
  }
}

type TActivateAction =
  | Action.IActivateVirtualDevice
  | ActionWithPromise<Action.IActivateVirtualDevice, string>

function* doActivateVirtualDevice(action: TActivateAction) {
  const { device_id } = action.device;

  try {
    // getting actual server device data, cause user can change something on frontend
    const getDeviceResponse: ApiResponse = yield call(getDevice,  device_id);
    if (getDeviceResponse.reason !== ReasonEnum.Ok) { throw new Error(getDeviceResponse.message); }

    const { hardware_type, damaged_status, owner_id } = getDeviceResponse.data;
    const updateDeviceResponse: ApiResponse = yield call(updateDevice, {
      id: device_id,
      props: {
        hardware_type: hardware_type ? hardware_type : null,
        activation_status: true,
        damaged_status,
        owner_id
      }
    });
    if (updateDeviceResponse.reason !== ReasonEnum.Ok) { throw new Error(updateDeviceResponse.message); }

    yield put(Action.ActivateVirtualDeviceSuccess(device_id));
    yield put(NotifySuccess(`Device ${device_id} has been updated`));
    'meta' in action && action.meta.promise.resolve(device_id);
    yield clearQueryCache('deviceManagement/devices');
  } catch (err: unknown) {
    yield put(NotifyError(`Error while updating the device: ${(err as Error).message}`));
    'meta' in action && action.meta.promise.reject(err as Error);
  }
}

export default [
  takeEvery(Action.DM_VIRTUAL_DEVICE_DATA_LOAD_BY_PARAMS, doLoadVirtualDevicesDataByParams),
  takeEvery(Action.DM_VIRTUAL_DEVICE_DELETE, doDeleteVirtualDevice),
  takeEvery(Action.DM_VIRTUAL_DEVICE_CREATE, doCreateVirtualDevices),
  takeEvery(Action.DM_VIRTUAL_DEVICE_ACTIVATE, doActivateVirtualDevice)
];
