import React from 'react';
import { useSelector } from 'react-redux';

import { User, ALL_PROJECTS } from 'models/user-management';
import { PermissionsBag, ProjectsBag, UserGroupsBag } from 'pages/UserManagement/Users/utils';
import { RootState } from 'reducers';
import { getUserDetailPath } from 'routing/paths';
import { getUserFullName, isNil } from 'utils/models';
import { isAllowedToDeleteUser } from 'utils/permissions';
import { commonOptions } from 'utils/tables';

// components
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Box, Chip, Link as MuiLink } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Link } from 'react-router-dom';

import { DeleteUser } from 'components/Controls';
import { StyledProjects } from './shared';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { UserNotConfirmed } from 'components/UserManagement';

// styles
import { makeTableStyles } from 'styles';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

const useTableStyles = makeTableStyles();

interface UserTableProps {
  className?: string;
  permissions: PermissionsBag;
  projects: ProjectsBag;
  userGroups: UserGroupsBag;
  users: {
    list?: User[];
    loading: boolean;
    totalUsersByQuery?: number;
  };
  onDeleteUser?: () => void;
}

function getColumns(options: {
  isCurrentUserAdmin: boolean;
  tableStyles: ReturnType<typeof useTableStyles>;
  onDeleteUser?: () => void;
  allowedToDeleteUsers: boolean;
}
): MUIDataTableColumn[] {
  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (user: User) => {
          const userFullName = getUserFullName(user);

          if (!userFullName) {
            return '-';
          }

          return (
            <Box display="flex" alignItems="center">
              <MuiLink
                color="secondary"
                component={ Link }
                to={ getUserDetailPath(user.sub) }
              >
                { userFullName }
              </MuiLink>
              <UserNotConfirmed user={ user } />
            </Box>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (user: User) => user.email
          ? (
            <MuiLink
              color="secondary"
              component={ Link }
              to={ getUserDetailPath(user.sub) }
            >
              { user.email }
            </MuiLink>
          )
          : '-',
      },
    },
    {
      name: 'userGroup',
      label: 'User Group Name',
      options: {
        display: options.isCurrentUserAdmin ? 'true' : 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'permissions',
      label: 'Permission Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'projects',
      label: 'Projects',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        display: options.allowedToDeleteUsers ? 'true' : 'excluded',
        empty: true,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        viewColumns: false,
        download: false,
        setCellProps: () => ({ className: options.tableStyles.shrunkCell }),
        customBodyRender: (userSub: User['sub']) => (
          <div className={ options.tableStyles.actionsCellBody }>
            <DeleteUser
              userSub={ userSub }
              onSuccess={ options.onDeleteUser }
            />
          </div>
        ),
      },
    }
  ];
}

interface RowFieldsGeneratorData {
  permissions: PermissionsBag;
  projects: ProjectsBag;
  user: User;
  userGroups: UserGroupsBag;
}

const SKELETON_PROPS = {
  width: 60,
  height: 24,
};

function getPermissionsFieldValue(data: Pick<RowFieldsGeneratorData, 'permissions' | 'user'>) {
  if (isNil(data.user.permissions)) {
    return '-';
  }

  const permission = data.permissions.byId[data.user.permissions];

  if (permission) {
    if (!permission.template) {
      return 'Individual';
    }

    return permission.name;
  }

  if (data.permissions.loading) {
    return <Skeleton { ...SKELETON_PROPS } />;
  }

  return data.user.permissions;
}

function getProjectsFieldValue(
  data: Pick<RowFieldsGeneratorData, 'projects' | 'user'>,
) {
  const userProjects = data.user.projects;

  if (userProjects === ALL_PROJECTS) {
    return 'All';
  }

  if (!userProjects.length) {
    return '-';
  }

  return (
    <StyledProjects>
      { userProjects.map(projectId => {
        const project = data.projects.byId[projectId];

        if (project) {
          return <Chip label={ project.name } key={ projectId } />;
        }

        if (data.projects.loading) {
          return <Skeleton { ...SKELETON_PROPS } key={ projectId } />;
        }

        return <Chip label={ `#${projectId}` } variant="outlined" key={ projectId } />;
      }) }
    </StyledProjects>
  );
}

function getUserGroupFieldValue(data: RowFieldsGeneratorData) {
  const userGroupId = data.user.user_group;
  const userGroup = data.userGroups.byId[userGroupId];

  if (userGroup) {
    return userGroup.name;
  }

  if (data.userGroups.loading) {
    return <Skeleton width={ 40 } height={ 20 } />;
  }

  return userGroupId;
}

function getRowFields(data: RowFieldsGeneratorData) {
  return {
    name: data.user,
    email: data.user,
    permissions: getPermissionsFieldValue(data),
    projects: getProjectsFieldValue(data),
    userGroup: getUserGroupFieldValue(data),
    actions: data.user.sub,
  };
}

const UserTable: React.FC<UserTableProps> = (props) => {
  const tableStyles = useTableStyles();
  const isCurrentUserAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const allowedToDeleteUsers = useSelector((state: RootState) => isAllowedToDeleteUser(state.user.data) || false);

  const columns: MUIDataTableColumn[] = getColumns({
    isCurrentUserAdmin,
    tableStyles,
    onDeleteUser: props.onDeleteUser,
    allowedToDeleteUsers,
  });
  const options: MUIDataTableOptions = {
    ...commonOptions,
    count: props.users.totalUsersByQuery,
    serverSide: true,
    customFooter: (count) => {
      return (
        <PaginationFooter
          count={ count }
          rowsPerPageOptions={ options.rowsPerPageOptions }
        />
      );
    },
  };

  const { users } = props;
  return (
    <div className={ props.className }>
      <MuiThemeProvider theme={ truncatedCellsMuiTableTheme(columns.length) }>
        <TableLoadingLayout isLoading={ (!users.list || !users.list.length) && users.loading }>
          <MUIDataTable
            title={ null }
            columns={ columns }
            data={ users.list ? users.list.map(user => getRowFields({
              permissions: props.permissions,
              projects: props.projects,
              user,
              userGroups: props.userGroups,
            })) : [] }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>
    </div>
  );
};

export default UserTable;
