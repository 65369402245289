import { useState } from 'react';
import { useLocationParams } from 'hooks/location';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { BSConnectivityMapFilters, initMapFilters } from './types';

interface FilterState {
  filter: BSConnectivityMapFilters;
  setFilter: (filter: BSConnectivityMapFilters) => void;
}

export const useFilterFromLocation = (): FilterState => {
  const [filter, setFilter] = useState<BSConnectivityMapFilters>(initMapFilters);
  const setParams = useLocationParams(initMapFilters, buildFilter, setFilter);
  return {
    filter: filter,
    setFilter: setParams,
  };
};

const buildFilter = (items: UrlItems): BSConnectivityMapFilters => {
  const parser = new Parser(items as BSConnectivityMapFilters);
  return {
    ...initMapFilters,
    owner: parser.asNumber('owner') ?? initMapFilters.owner,
    projects: parser.asNumbers('projects') ?? initMapFilters.projects,
    zones: parser.asNumbers('zones') ?? initMapFilters.zones,
    positionGroups: parser.asNumbers('positionGroups') ?? initMapFilters.positionGroups,
    isOnline: parser.asBoolean('isOnline') ?? initMapFilters.isOnline,
    isHealthy: parser.asBoolean('isHealthy') ?? initMapFilters.isHealthy,
    connectivity: parser.asNumbers('connectivity') ?? initMapFilters.connectivity,
  };
};
