import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import { stringify } from 'query-string';
import { DoReceivedSuccessBindFromSplace } from 'actions/device-management/devices';
import { RootState } from 'reducers';
import { staticEnv } from 'env';

const url = staticEnv.IS_PRODUCTION ? '//splace.nwave.io' : '//dev.splace.nwave.io';

export function useOnSplaceMessages() {
  const dispatch = useDispatch();
  const setMessage = (msg: string) => {
    dispatch(DoReceivedSuccessBindFromSplace(msg));
  };

  const message = useSelector((state: RootState) => state.deviceManagement.devices.splaceMessage);

  return {
    message,
    setMessage,
  };
}

interface PopupProps {
  query?: {
    zoneId?: number;
    levelId?: number;
    groupId?: number;
    positionId?: number;
    deviceId?: string;
  };
  onMessage?: (message?: string) => void;
}

interface PopupState {
  open: () => void;
  href: string;
  message?: string;
}

export function useOnSplacePopup(params: PopupProps): PopupState {
  const splaceMessages = useOnSplaceMessages();
  const queryParams = stringify(params.query ?? {}, { arrayFormat: 'comma', skipNull: true });
  const href = `${ url }?${ queryParams }`;

  const eventListener = useCallback((event: MessageEvent) => {
    if (!event.origin.includes(url)) {
      return;
    }

    if (splaceMessages.message !== event.data) {
      splaceMessages.setMessage(event.data);
      params.onMessage && params.onMessage(event.data);
    }
  }, [params, splaceMessages]);

  useUnmount(() => {
    window.removeEventListener('message', eventListener);
  });

  return {
    href: href,
    message: splaceMessages.message,
    open: () => {
      window.open(href, 'popup', 'width=1024,height=768');
      window.addEventListener('message', eventListener);
    },
  };
}
