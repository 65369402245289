/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Firmwares Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SoftType {
    General = 'general',
    ParkingSensor = 'parking_sensor',
    StandaloneCarCounter = 'standalone_car_counter',
    ArrayCarCounter = 'array_car_counter'
}

