import { ZoneState } from 'models/device-management/states';
import * as zoneActions from 'actions/device-management/zones';
import * as dataActions from 'actions/device-management/zone-data';
import { Repository } from 'utils/repository';

const initState: ZoneState = {
  repository: Repository.create('id'),
  zoneStatistics: {
    statAll: {
      metrics: {}
    },
    connectivity: [],
    isFetching: false,
  },
  createZoneFetching: false,
};



export const reducer = (state: ZoneState = initState, action: zoneActions.Action | dataActions.Action): ZoneState => {
  switch (action.type) {
    case zoneActions.FETCH_ZONE_STATISTICS:
      return { ...state, zoneStatistics: { ...state.zoneStatistics, isFetching: true } };
    case zoneActions.FETCH_ZONE_STATISTICS_SUCCESS: {
      const { statAll, connectivity } = action.payload;
      return {
        ...state,
        zoneStatistics: {
          ...state.zoneStatistics,
          statAll,
          connectivity,
          isFetching: false,
        },
      };
    }
    case zoneActions.FETCH_ZONE_STATISTICS_FAILED: {
      return {
        ...state,
        zoneStatistics: {
          ...state.zoneStatistics,
          isFetching: false,
        },
      };
    }
    case zoneActions.CREATE_ZONE:
      return {
        ...state,
        createZoneFetching: true,
      };
    case zoneActions.CREATE_ZONE_SUCCESS:
      return {
        ...state,
        createZoneFetching: false,
      };
    case zoneActions.CREATE_ZONE_FAILED:
      return {
        ...state,
        createZoneFetching: false
      };
    case dataActions.DM_ZONE_DATA_RESET:
      return {
        ...state,
        repository: state.repository.reset()
      };

    default:
      return state;
  }
};

