import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...margins(theme)
    },
    incedentTableRoot: {
      marginLeft: '-16px',
      marginRight: '-16px',
    },
    button: {
      marginRight: theme.spacing(1),
      minWidth: 0,
    },
    button2: {
      marginLeft: theme.spacing(1),
      minWidth: 0,
    },
    wordBreak: {
      wordBreak: 'break-word',
    },
    error: {
      textAlign: 'center',
      color: theme.palette.error.main,
    },
    cellWithButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    autoUpdateRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableRowProblem: {
      backgroundColor: '#FDE0E1',
      alignItems: 'center',
      borderBottom: '1px solid black',
    },
    tableRow: {
      flex: 1,
      borderBottom: '1px solid black',
      alignItems: 'center',
      marginLeft: 50,
    },
    tableCell: {
      flex: 1,
      paddingLeft: '75px',
      border: 0,
      padding: '10px',
      alignItems: 'center',
    },
    contextMenuBody: {
      height: 500,
      width: 300,
      backgroundColor: 'white',
      border: '1px solid black',
    },
    contextHeader: {

    },
    menuItem: {
      fontSize: 47,
    },
    tableIcons: {
      flex: 1,
      alignSelf: 'flex-end',
    },
    iconContainer: {
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginLeft: 10,
    },
    expandableBody: {
      width: 350,
    },
    table: {
      flex: 1,
    },
    rowButtonContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centerButtons: {
      flex: 1,
      justifyContent: 'center',
      width: 350,
    },
    dialogButton: {
      flex: 1,
      marginLeft: 13,
      marginRight: 13,
    },
    textField: {
      width: '100%',
    },
    actionCell: {
      width: 48,
      padding: 0,
      textAlign: 'center',
    },
    actionIcon: {
      padding: 0,
      marginLeft: theme.spacing(1),
    },
    actionLink: {
      textDecoration: 'none',
      color: 'black',
    },
    rowButton: {
      flex: 1,
      width: 200,
    },
    dialogTextArea: {
      height: 400,
      width: 200,
    },
    link: {
      textDecoration: 'none',
      color: 'black',
    },
    contextMenuElement: {
      flex: 1,
      height: 50,
      width: 180,
      flexDirection: 'column',
      alignItems: 'center'
    },
    updateContainer: {
      flex: 1,
      flexDirection: 'row',
      width: 200,
      alignItems: 'center',
    },
    updateCheckbox: {
      paddingRight: 10,
    },
    separator: {
      width: 2,
    },
    updateText: {
    },
    columnHeader: {
      backgroundColor: 'red',
      width: 300,
      alignItems: 'flex-start',
    },
    dialogContainer: {
      width: 350,
    },
    dialogHeader: {
      alignSelf: 'center',
    },
    paperProps: {
      flex: 1,
      flexDirection: 'column',
      width: 180,
      alignItems: 'center',
      backgroundColor: '#FAFAFA',
    },
  }),
);
export default useStyles;
