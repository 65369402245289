import { DEFAULT_LIMIT } from 'models/common';

export enum WarrantyFiltersFields {
  id = 'id',
  limit = 'limit',
  offset = 'offset'
}

export interface WarrantyFilters {
  [WarrantyFiltersFields.id]: string[];
}

export interface WarrantyFiltersWithLimit extends WarrantyFilters {
  [WarrantyFiltersFields.limit]: number;
  [WarrantyFiltersFields.offset]: number;
}

export const initWarrantyFilters: WarrantyFiltersWithLimit = {
  [WarrantyFiltersFields.id]: [],
  [WarrantyFiltersFields.limit]: DEFAULT_LIMIT,
  [WarrantyFiltersFields.offset]: 0,
};
