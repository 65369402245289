import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useFormActionLoader, usePagination } from 'hooks';
import { Shipment, ShipmentStatusEnum, ShipmentUpdateBody } from 'models/provisioning';
import { Button } from '@material-ui/core';
import { DoUpdateShipmentStatus, DoUpdateShipment, DoFetchShipmentByParams } from 'actions/provisioning';
import { EnsureDialog } from 'components/Dialogs';

type Props = {
  shipment: Shipment;
};

export const AcknowledgeButton = ({ shipment }: Props): JSX.Element => {
  const { filters } = useSelector((state: RootState) => state.provisioning.shipment);
  const { limit, offset } = usePagination();
  const [confirm, showConfirm] = useState<boolean>(false);
  const { doAction } = useFormActionLoader();
  const updatedShipment: ShipmentUpdateBody = {
    ...shipment,
    arrival_date: new Date(),
  };

  const saveAction = () => doAction({
    action: DoUpdateShipmentStatus(shipment.id, ShipmentStatusEnum.Received),
    onSuccess: () => {
      doAction({
        action: DoUpdateShipment(shipment.id, updatedShipment),
        onSuccess: () => {
          doAction({
            action: DoFetchShipmentByParams({
              ...filters,
              limit,
              offset
            })
          });
        }
      });
    }
  });

  return (<>
    <Button variant="contained" color="primary" onClick={ () => showConfirm(true) }>
      confirm
    </Button>

    <EnsureDialog
      name={ 'confirm-update' }
      isOpen={ confirm }
      onClose={ () => showConfirm(false) }
      onYes={ saveAction }
    > </EnsureDialog>
  </>
  );
};

export default AcknowledgeButton;
