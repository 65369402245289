import { StationLocation } from 'models/base-station';

export const BASE_STATION_LOCATION_CREATE = 'BASE_STATION_LOCATION_CREATE';
export const BASE_STATION_LOCATION_UPDATE = 'BASE_STATION_LOCATION_UPDATE';
export const BASE_STATION_LOCATION_DELETE = 'BASE_STATION_LOCATION_DELETE';
export const BASE_STATION_LOCATION_CHANGE = 'BASE_STATION_LOCATION_CHANGE';
export const BASE_STATION_LOCATION_LOCK = 'BASE_STATION_LOCATION_LOCK';
export const BASE_STATION_LOCATION_UNLOCK = 'BASE_STATION_LOCATION_UNLOCK';
export const BASE_STATION_LOCATION_BATCH_UPDATE_PROJECT = 'bs/location/BATCH_UPDATE_PROJECT';

export interface CreateStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_CREATE;
  location: StationLocation;
}

export interface UpdateStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_UPDATE;
  location: StationLocation;
}

export interface DeleteLocation {
  readonly type: typeof BASE_STATION_LOCATION_DELETE;
  location: StationLocation;
}

export interface ChangeStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_CHANGE;
  location: StationLocation;
  options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export interface LockStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_LOCK;
  locationId: number;
}

export interface UnlockStationLocation {
  readonly type: typeof BASE_STATION_LOCATION_UNLOCK;
  locationId: number;
}

export interface UpdateProjectForStationLocations {
  readonly type: typeof BASE_STATION_LOCATION_BATCH_UPDATE_PROJECT;
  projectId: number;
  locations: StationLocation[];
}

export const CreateStationLocation = (location: StationLocation): CreateStationLocation => ({
  type: BASE_STATION_LOCATION_CREATE, location
});

export const UpdateStationLocation = (location: StationLocation): UpdateStationLocation => ({
  type: BASE_STATION_LOCATION_UPDATE, location
});

export const UpdateProjectForStationLocations = (projectId: number, locations: StationLocation[]): UpdateProjectForStationLocations => ({
  type: BASE_STATION_LOCATION_BATCH_UPDATE_PROJECT, projectId, locations
});

export const DeleteLocation = (location: StationLocation): DeleteLocation => ({
  type: BASE_STATION_LOCATION_DELETE, location
});

export const ChangeStationLocation = (location: StationLocation, options: ChangeStationLocation['options']): ChangeStationLocation => ({
  type: BASE_STATION_LOCATION_CHANGE, location, options
});

export const LockStationLocation = (locationId: number): LockStationLocation => ({
  type: BASE_STATION_LOCATION_LOCK, locationId
});

export const UnlockStationLocation = (locationId: number): UnlockStationLocation => ({
  type: BASE_STATION_LOCATION_UNLOCK, locationId
});

export type Action =
  | CreateStationLocation
  | UpdateStationLocation
  | DeleteLocation
  | ChangeStationLocation
  | LockStationLocation
  | UnlockStationLocation
