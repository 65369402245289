import { Group, GroupType, Zone } from './models/device-management';
import { v4 as uuid } from 'uuid/interfaces';

export function transformToHex(id: string): string {
  const StringFilterHex = /[^a-fA-F0-9]/g;
  return id.replace(StringFilterHex, '').toUpperCase();
}

export function transformToNumber(str: string): string {
  return parseInt(str.replace(/[^0-9.]/g, '') || '0', 10).toString();
}

/**
 * Make string like 'name1, name2' from array of zones or groups
 * @param selected selected ids
 * @param arr array of objects
 */
export function fromArrayToString<T extends Zone | Group>(selected: number[], arr: T[]): string {
  const data: string[] = [];
  for (const id of selected) {
    const finded = arr.find(el => el.id === id);

    if (finded) {
      data.push(`${ finded.id } ${ finded.name }`);
    }
  }

  return data.join(', ');
}

/**
 * Make string as '00000000-0000-0000-0000-000000000000'
 */
export function stringAsUUID(str: string): string {
  str = str.slice(0, 32);

  const strArr = [...Array(32 - str.length).fill('0'), ...str.split('')];

  str = strArr.join('');

  const arr: string[] = [];
  arr.push(str.slice(0, 8));
  arr.push(str.slice(8, 12));
  arr.push(str.slice(12, 16));
  arr.push(str.slice(16, 20));
  arr.push(str.slice(20, 32));

  return arr.join('-');
}

export function createCSVFromObject(data: Record<string, unknown>, keys: string[], separator = ','): string {
  const values = keys.map(k => {
    if (!(k in data) || data[k] === null || data[k] === undefined) {
      return '';
    }

    let cell: string = data[k] instanceof Date
      ? (data[k] as Date).toLocaleString()
      : (data[k] as string).toString().replace(/"/g, '""');
    if (cell.search(/([",\n])/g) >= 0) {
      cell = `"${ cell }"`;
    }
    return cell;
  });

  return values.join(separator);
}

export function downloadFileFromRes(data: string, name: string): void {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
  element.setAttribute('download', name);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function parseArrayAsNum(str: string[] | string): number[] {
  const arr = ([] as string[]).concat(str).map(b => Number(b));
  return arr.filter(number => number || number === 0);
}

type Filters = Record<string, unknown>;

export function isFilterSet(filters: Record<string, unknown>): boolean {
  const filtersWithoutPagination: Filters = { ...filters };
  delete filtersWithoutPagination.limit;
  delete filtersWithoutPagination.offset;
  return Object.values(filtersWithoutPagination)
    .some(value => {
      if (typeof value === 'undefined' || value === null) {
        return false;
      }
      if (Array.isArray(value)) {
        return value.length;
      }
      if (typeof value === 'object') {
        return isFilterSet(value as Filters);
      }
      if (typeof value === 'number') {
        return !isNaN(value) && value.toString();
      }
      if (typeof value === 'string') {
        return !!value;
      }
      if (typeof value === 'boolean') {
        return value.toString();
      }
      return false;
    });
}

export const isBool = (val: unknown): val is boolean => val === true || val === false;

export function isUUID(str: uuid | string): boolean {
  const regex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
  return regex.test(String(str));
}

export function showParkingAnomaly(type: string): boolean {
  return type === GroupType.MarkedParkingBay || type === GroupType.UnmarkedParkingBay;
}

export function sortStrings(str1?: string, str2?: string): number {
  if (!str1 || !str2) {
    return -1;
  }

  str1 = str1.toUpperCase();
  str2 = str2.toUpperCase();

  if (str1 < str2) {
    return -1;
  }
  if (str1 > str2) {
    return 1;
  }

  return 0;
}

export const getRandomNumber = () => Math.round(new Date().getTime() + Math.random());
