export enum FormField {
  hardware = 'hardware_type',
  active = 'activation_status',
  damaged = 'damaged_status',
  owner = 'owner_id',
}

export interface FormState {
  [FormField.owner]?: number;
  [FormField.hardware]?: string;
  [FormField.active]?: boolean;
  [FormField.damaged]?: boolean;
}
