/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.17.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Device state severity
 */
export type DeviceStateSeverityEnumModel = 'ok' | 'info' | 'warning' | 'critical';

export const DeviceStateSeverityEnumModel = {
    Ok: 'ok' as DeviceStateSeverityEnumModel,
    Info: 'info' as DeviceStateSeverityEnumModel,
    Warning: 'warning' as DeviceStateSeverityEnumModel,
    Critical: 'critical' as DeviceStateSeverityEnumModel
}
