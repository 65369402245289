import { API } from 'aws-amplify';

import { errorHandler, formatQuery } from 'clients/utils';
import { ApiResponse } from 'models';
import {
  GetTemplateParams,
  TemplatePreset,
  CreateTemplateParams,
  UpdateTemplateParams,
} from 'models/caller';

import { config } from './config';

const urls = {
  templates: '/calls/templates',
  getTemplateById: (id: number) => `/calls/templates/${ id }`,
  defaultTemplates: '/calls/default_templates',
};

export const getTemplateById = (templateId: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.getTemplateById(templateId), {})
    .catch(errorHandler);
};

export const updateTemplateById = (templateId: number, template: UpdateTemplateParams): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.getTemplateById(templateId), {
      body: {
        ...template, template_id: undefined, // backend don't want see identifier in body
      }
    })
    .catch(errorHandler);
};

export const deleteTemplateById = (templateId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.getTemplateById(templateId), {})
    .catch(errorHandler);
};

export const createTemplate = (template: CreateTemplateParams): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.templates, {
      body: {
        ...template, template_id: undefined, // backend don't want see identifier in body
      }
    })
    .catch(errorHandler);
};

export const getTemplates = (params: GetTemplateParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      name: params.name,
      body: params.body,
      method: params.method,
      url: params.url,
      message_type: params.messageType,
      template_suite_id: params.templateSuiteId,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.templates, body)
    .catch(errorHandler);
};

export const getTemplatePresets = (): Promise<ApiResponse<TemplatePreset[]>> => {
  return API
    .get(config.name, urls.defaultTemplates, {})
    .catch(errorHandler);
};