import { useUserGroupsServices } from 'hooks/user-managment';
import { UserGroupServiceName } from 'models/user-management';

// components
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { PageLoader } from 'components/Loaders';

// styles
import { useStyles } from './styles';
import { SwitchControl } from 'components/Controls';
interface Props {
  userGroupId: number
}

export const Services = ({ userGroupId }: Props): JSX.Element => {
  const { isLoading, services, onActivateService, onDeactiveateService } = useUserGroupsServices(userGroupId);
  const classes = useStyles();

  return (
    <Card className={ classes.cardRoot }>
      <CardHeader title="Services"/>
      <CardContent>
        { isLoading && <PageLoader/> }
        <SwitchControl
          name="parkingAnalytics"
          label="Parking Analytics"
          value={ services.find(service => service.name === 'analytics')?.active ?? false }
          onChange={ active => { active ? onActivateService(UserGroupServiceName.ANALYTICS) : onDeactiveateService(UserGroupServiceName.ANALYTICS); } }
        />
      </CardContent>
    </Card>
  );
};
