import { GetStationLocationParams, StationLocation } from 'models/base-station';

export const BASE_STATION_LOCATION_DATA_LOAD_BY_ID = 'bs/location/LOAD_BY_ID';
export const BASE_STATION_LOCATION_DATA_LOAD_BY_PARAMS = 'bs/location/LOAD_BY_PARAMS';
export const BASE_STATION_LOCATION_DATA_LOAD_DICTIONARY = 'bs/location/LOAD_DICTIONARY';
export const BASE_STATION_LOCATION_DATA_LOAD_START = 'bs/location/LOAD_START';
export const BASE_STATION_LOCATION_DATA_LOAD_SUCCESS = 'bs/location/LOAD_SUCCESS';
export const BASE_STATION_LOCATION_DATA_LOAD_FAILURE = 'bs/location/LOAD_FAILURE';

export interface LoadStationLocationDataById {
  readonly type: typeof BASE_STATION_LOCATION_DATA_LOAD_BY_ID;
  readonly locationId: number;
}

export interface LoadStationLocationDataByParams {
  readonly type: typeof BASE_STATION_LOCATION_DATA_LOAD_BY_PARAMS;
  readonly params: GetStationLocationParams;
}

export interface LoadStationLocationDataDictionary {
  readonly type: typeof BASE_STATION_LOCATION_DATA_LOAD_DICTIONARY;
}

export interface LoadStationLocationDataStart {
  readonly type: typeof BASE_STATION_LOCATION_DATA_LOAD_START;
  readonly params: GetStationLocationParams | number;
}

export interface LoadStationLocationDataFailure {
  readonly type: typeof BASE_STATION_LOCATION_DATA_LOAD_FAILURE;
  readonly params: GetStationLocationParams | number;
  readonly error: string;
}

export interface LoadStationLocationDataSuccess {
  readonly type: typeof BASE_STATION_LOCATION_DATA_LOAD_SUCCESS;
  readonly params: GetStationLocationParams | number;
  readonly data: StationLocation[];
}

export const LoadStationLocationDataById = (locationId: number): LoadStationLocationDataById => ({
  type: BASE_STATION_LOCATION_DATA_LOAD_BY_ID, locationId
});

export const LoadStationLocationDataByParams = (params: GetStationLocationParams): LoadStationLocationDataByParams => ({
  type: BASE_STATION_LOCATION_DATA_LOAD_BY_PARAMS, params
});

export const LoadStationLocationDataDictionary = (): LoadStationLocationDataDictionary => ({
  type: BASE_STATION_LOCATION_DATA_LOAD_DICTIONARY
});

export const LoadStationLocationDataStart = (params: GetStationLocationParams | number): LoadStationLocationDataStart => ({
  type: BASE_STATION_LOCATION_DATA_LOAD_START, params
});

export const LoadStationLocationDataFailure = (params: GetStationLocationParams | number, error: string): LoadStationLocationDataFailure => ({
  type: BASE_STATION_LOCATION_DATA_LOAD_FAILURE, params, error
});

export const LoadStationLocationDataSuccess = (params: GetStationLocationParams | number, data: StationLocation[]): LoadStationLocationDataSuccess => ({
  type: BASE_STATION_LOCATION_DATA_LOAD_SUCCESS, params, data
});

export type Action =
  | LoadStationLocationDataById
  | LoadStationLocationDataByParams
  | LoadStationLocationDataStart
  | LoadStationLocationDataFailure
  | LoadStationLocationDataSuccess
