import { BaseStation } from 'models/base-station';
import { isNil } from 'lodash';
import { BSConnectivityMapFilters } from './types';
import { useStationsDictionarySelector } from 'hooks/station';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LoadStationStatusDataByParams } from 'actions/base-station';
import { CloseSnackbar, NotifyWarning } from 'actions/notifier';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { useRef } from 'react';
import { getRandomNumber } from 'helpers';

interface StationsState {
  isLoading: boolean;
  stations: BaseStation[];
}

export const useStationsByFilters = (filter: BSConnectivityMapFilters): StationsState => {
  const dispatch = useDispatch();
  const { isLoading: isStationLoading, stations } = useStationsDictionarySelector();
  const stationIds = stations.map(station => station.id);
  const { isFetched: isStatusFetched, statuses } = useSelector((state: RootState) => {
    if (isStationLoading || stationIds.length === 0) {
      return { isFetched: true, statuses: [] };
    }

    return {
      isFetched: state.baseStation.stationStatus.isFetched({ stationIds }),
      statuses: state.baseStation.stationStatus.findByParams({ stationIds }),
    };
  }, shallowEqual);

  useDeepCompareEffect(() => {
    if (isStatusFetched === undefined) {
      dispatch(LoadStationStatusDataByParams({ stationIds }));
    }
  }, [dispatch, isStatusFetched, stationIds]);

  return {
    isLoading: isStationLoading || isStatusFetched !== true,
    stations: stations
      .map(station => ({
        ...station,
        status: statuses.find(s => s.stationId === station.id)
      }))
      .filter(station => isStationFiltered(station, filter))
  };
};

const isStationFiltered = (station: BaseStation, filter: BSConnectivityMapFilters): boolean => {
  const bsProject = station.location?.project as number;

  if (filter.projects?.length && !filter.projects.includes(bsProject)) {
    return false;
  }

  if (!isNil(filter.owner) && station.owner_id !== filter.owner) {
    return false;
  }

  if (!isNil(filter.isOnline)) {
    if (!station.status) {
      return false;
    }

    const isOnline = !station.status.isOffline;
    if (isOnline !== filter.isOnline) {
      return false;
    }
  }

  if (!isNil(filter.isHealthy)) {
    if (!station.status) {
      return false;
    }

    const isHealthy = !station.status.hasIssues;
    if (isHealthy !== filter.isHealthy) {
      return false;
    }
  }

  return true;
};

export const useStationEmtyNotify = (isLoading: boolean, stations: BaseStation[]): void => {
  const dispatch = useDispatch();
  const notifyKey = useRef<number>(getRandomNumber());
  useUnmount(() => dispatch(CloseSnackbar(notifyKey.current)));
  useDeepCompareEffect(() => {
    if (isLoading) {
      return;
    }

    const stationOnMap = stations
      .filter(station => station.location?.lat && station.location?.lon);

    if (!stationOnMap.length) {
      dispatch(NotifyWarning('Stations not found', true, notifyKey.current));
    } else {
      dispatch(CloseSnackbar(notifyKey.current));
      notifyKey.current = getRandomNumber();
    }
  }, [isLoading, stations]);
};
