import React from 'react';

import { Query } from 'hooks/device-management/useDmTree/types';

// components
import { Loader } from '../Loader';

export function renderQuery<TData>(
  query: Query<TData>,
  renderData: (data?: TData) => React.ReactNode,
) {
  if (isLoading(query)) {
    return <>{ query.data !== undefined && renderData(query.data) } <Loader /></>;
  }

  if (query.status === 'success') {
    return renderData(query.data);
  }

  if (query.status === 'error') {
    return 'Error';
  }
}

export function isLoading(query: Query<unknown>): boolean {
  return query.status === 'loading' || query.status === 'idle';
}

export function joinElements(elements: React.ReactNode[], separator: string) {
  return elements.map((element, i, { length }) =>
    <React.Fragment key={i}>
      { element }
      { i !== length - 1 ? separator : undefined }
    </React.Fragment>
  );
}
