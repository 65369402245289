import React, { ReactNode } from 'react';
import { CancelButton, SaveButton } from 'components/Buttons';
import { LinkProps } from 'react-router-dom';

export interface EditActionParams {
  canEdit: boolean;
  canSave: boolean;
  btnSave?: string;
  onSave?: () => void;
  linkSave?: LinkProps['to'];
  btnCancel?: string;
  onCancel?: () => void;
  linkCancel?: LinkProps['to'];
}

export function useFormEditActions(params: EditActionParams): ReactNode {
  if (!params.canEdit) {
    return <></>;
  }

  return (
    <>
      <CancelButton
        type="text"
        label={ params.btnCancel ?? 'Cancel' }
        ButtonProps={ { color: 'inherit' } }
        LinkTo={ params.linkCancel }
        onClick={ () => params.onCancel && params.onCancel() }
      />
      <SaveButton
        type="text"
        label={ params.btnSave ?? 'Save' }
        LinkTo={ params.linkSave }
        onClick={ () => params.onSave && params.onSave() }
        isDisabled={ !params.canSave }
      />
    </>
  );
}

