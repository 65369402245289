import moment from 'moment';
import { call, cancel, fork, put, select, take } from 'redux-saga/effects';
import { Task as ReduxSagaTask } from '@redux-saga/types';

import { RootState } from 'reducers';
import * as Action from 'actions/pages/station-monitoring';
import { ApiResponse, ReasonEnum } from 'models';
import * as backend from 'clients/base-stations-status';
import * as models from 'models/base-station-status';
import { NotifyError } from 'actions/notifier';
import { createErrorFromApiResponse } from 'utils/errors';
import { autoRunWhenVisible } from 'utils/sagas';

function* loadStatusHistory(stationId: number, timeFrom: Date, timeTo: Date) {
  const response: models.InlineResponse2001 = yield call(backend.getStationsStatusHistory, {
    stationIds: [stationId],
    timeFrom: timeFrom,
    timeTo: timeTo,
    limit: 0,
  });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.StationStatusHistory = { station_id: stationId, offline_history: [], uptime_history: [] };
    const stationStatusHistory = response.data.find(i => i.station_id === stationId);
    yield put(Action.LoadStationMonitoringStatusHistorySuccess(
      stationStatusHistory ?? emptyHistory,
      { dateFrom: timeFrom, dateTo: timeTo },
    ));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s Power history: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('uptimeHistory'));
  }
}

function* loadInternetHistory(stationId: number, timeFrom: Date, timeTo: Date) {
  const response: models.InlineResponse2004 = yield call(backend.getStationsInternetHistory, {
    stationIds: [stationId],
    timeFrom: timeFrom,
    timeTo: timeTo,
    limit: 0,
  });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.ConnectionHistory = { station_id: stationId, connection_history: [] };
    const stationInternetHistory = response.data.find(i => i.station_id === stationId);
    yield put(Action.LoadStationMonitoringInternetHistorySuccess(
      stationInternetHistory ?? emptyHistory,
      { dateFrom: timeFrom, dateTo: timeTo },
    ));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s Internet history: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('internetHistory'));
  }
}

function* loadVpnHistory(stationId: number, timeFrom: Date, timeTo: Date) {
  const response: models.InlineResponse2005 = yield call(backend.getStationsVpnHistory, {
    stationIds: [stationId],
    timeFrom: timeFrom,
    timeTo: timeTo,
    limit: 0,
  });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.VpnHistory = { station_id: stationId, vpn_history: [] };
    const stationVpnHistory = response.data.find(i => i.station_id === stationId);
    yield put(Action.LoadStationVpnHistorySuccess(
      stationVpnHistory ?? emptyHistory,
      { dateFrom: timeFrom, dateTo: timeTo },
    ));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s VPN history: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('vpnHistory'));
  }
}

function* loadNoiseLevelHistory(stationId: number, timeFrom: Date, timeTo: Date) {
  const response: ApiResponse = yield call(backend.getStationsNoiseLevelHistory, {
    stationIds: [stationId],
    timeFrom: timeFrom,
    timeTo: timeTo,
    limit: 0,
  });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.NoiseLevelHistory = { station_id: stationId, noise_level: [] };
    const stationNoiseLevelHistory = response.data.find((i: models.NoiseLevelHistory) => i.station_id === stationId);
    yield put(Action.LoadStationMonitoringNoiseLevelHistorySuccess(
      stationNoiseLevelHistory ?? emptyHistory,
      { dateFrom: timeFrom, dateTo: timeTo },
    ));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s noise level history: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('noiseLevelHistory'));
  }
}

function* loadLastMessage(stationId: number) {
  const response: models.InlineResponse2007 = yield call(backend.getStationsLastMessage, { stationIds: [stationId] });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.LastMessage = { station_id: stationId, value: null };
    const stationLastMessage = response.data.find(i => i.station_id === stationId);
    yield put(Action.LoadStationMonitoringLastMessageSuccess(stationLastMessage ?? emptyHistory));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s last message time: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('lastMessage'));
  }
}

function* loadDongle(stationId: number) {
  const response: models.InlineResponse2003 = yield call(backend.getStationsDongle, { stationIds: [stationId] });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.DongleStatus = { station_id: stationId, value: 0, last_change: null };
    const stationDongle = response.data.find(i => i.station_id === stationId);
    yield put(Action.LoadStationMonitoringDongleSuccess(stationDongle ?? emptyHistory));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s Receiver status: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('dongle'));
  }
}

function* loadMinCpp(stationId: number) {
  const response: models.InlineResponse2002 = yield call(backend.getStationsMinCpp, { stationIds: [stationId] });
  if (response.reason === ReasonEnum.Ok) {
    const emptyHistory: models.MinCppStatus = { station_id: stationId, value: 0, last_change: null };
    const stationMinCpp = response.data.find(i => i.station_id === stationId);
    yield put(Action.LoadStationMonitoringMinCppSuccess(stationMinCpp ?? emptyHistory));
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while fetching station #${ stationId }'s Software status: ${ error.message }`));
    yield put(Action.LoadStationMonitoringFailure('minCpp'));
  }
}

function* sync() {
  const SYNC_INTERVAL = 30 * 1000; // 30 sec
  while (true) {
    yield take(Action.STATION_MONITORING_SYNC_START);

    let prevDateTo: Date | undefined;

    const initialFetchTask: ReduxSagaTask = yield fork(autoRunWhenVisible, function* () {
      const state: RootState = yield select();
      const { stationId } = state.pages.stationMonitoring.filters;
      const { dateFrom, dateTo } = models.getDateParams(state.pages.stationMonitoring.filters.dateRange);

      if (prevDateTo && !moment(dateTo).isAfter(prevDateTo)) {
        return;
      }

      yield fork(loadStatusHistory, stationId, dateFrom, dateTo);
      yield fork(loadVpnHistory, stationId, dateFrom, dateTo);
      yield fork(loadInternetHistory, stationId, dateFrom, dateTo);
      yield fork(loadNoiseLevelHistory, stationId, dateFrom, dateTo);

      prevDateTo = dateTo;
    }, { intervalMs: 60_000, noInitialDelay: true });

    const syncTask: ReduxSagaTask = yield fork(autoRunWhenVisible, function* () {
      const state: RootState = yield select();
      const { stationId } = state.pages.stationMonitoring.filters;

      yield fork(loadLastMessage, stationId);
      yield fork(loadDongle, stationId);
      yield fork(loadMinCpp, stationId);
    }, { intervalMs: SYNC_INTERVAL, noInitialDelay: true });

    yield take(Action.STATION_MONITORING_SYNC_STOP);
    yield cancel([initialFetchTask, syncTask]);
  }
}

export default [
  fork(sync),
];
