import invariant from 'tiny-invariant';

import { TreeMethods } from '../base/types';
import { fillNodesWithAdditionalData } from '../tree/additional-data';
import { DmTreeNode,  LevelNode, GroupNode, PositionNode, DmTreeNodeWithChildren } from '../types';
import { DEFAULT_CHILDREN_LIMIT } from './creators/shared';
import { fetchChildrenForNode } from './fetchChildrenForNode';
import { updateNodePreservingState } from './updateChildren';

type TreeOptions = Pick<TreeMethods, 'getNode' | 'updateNode'>;

export async function loadMoreChildren(
  _node: DmTreeNode,
  treeOptions: TreeOptions
): Promise<void> {
  const node = treeOptions.getNode(_node);

  if (!node || !canLoadMoreChildren(node)) {
    return;
  }

  const newChildrenNodes = await loadEntities(node, treeOptions);
  await loadAdditionalData(node, treeOptions, newChildrenNodes);
}

function canLoadMoreChildren(node: DmTreeNode): node is DmTreeNodeWithChildren {
  if (!node || !('children' in node)) {
    return false;
  }

  if (node.childrenMoreLoadStatus === 'loading') {
    return false;
  }

  if (!node.childrenTotal.data || node.childrenTotal.data <= node.childrenLimit) {
    return false;
  }

  return true;
}

async function loadEntities(
  _node: DmTreeNodeWithChildren,
  { getNode, updateNode }: TreeOptions
): Promise<DmTreeNode[]> {
  const node = getNode(_node);

  if (!node) {
    return [];
  }

  const requestedAt = Date.now();
  const prevChildrenLimit = node.childrenLimit;

  updateNode(node, (n) => {
    n.childrenMoreLoadStatus = 'loading';
    n.childrenRequestedAt = requestedAt;
    n.childrenLimit += DEFAULT_CHILDREN_LIMIT;
  });

  try {
    const { nodes, total } = await fetchChildrenForNode(
      getNode(node) as DmTreeNodeWithChildren,
      { offset: prevChildrenLimit, limit: DEFAULT_CHILDREN_LIMIT },
    );

    saveMoreChildrenToNode({
      node,
      moreChildrenNodes: nodes,
      nextChildrenTotal: total,
      requestedAt,
      updateNode,
    });

    return nodes;
  } catch (e) {
    updateNode(node, node => {
      if (node.childrenRequestedAt && node.childrenRequestedAt > requestedAt) {
        return;
      }

      node.childrenMoreLoadStatus = 'error';
      node.childrenLimit = prevChildrenLimit;
    });

    throw e;
  }
}

interface SaveMoreChildrenToNodeOptions {
  node: DmTreeNodeWithChildren;
  moreChildrenNodes: DmTreeNode[];
  nextChildrenTotal: number;
  requestedAt: number;
  updateNode: TreeOptions['updateNode'];
}

function saveMoreChildrenToNode({
  node,
  moreChildrenNodes,
  nextChildrenTotal,
  requestedAt,
  updateNode,
}: SaveMoreChildrenToNodeOptions) {
  updateNode(node, node => {
    if (node.childrenDataRequestedAt && node.childrenDataRequestedAt > requestedAt) {
      return;
    }

    const indexByCurrentChildId = new Map<string, number>(
      (node.children as DmTreeNode[]).map((n, i) => [n.id, i]) ?? []
    );
    const overlappingNodes = moreChildrenNodes
      .filter(n => indexByCurrentChildId.has(n.id));

    for (const overlappingNode of overlappingNodes) {
      const originalNodeIndex = indexByCurrentChildId.get(overlappingNode.id) as number;
      const originalNode = node.children[originalNodeIndex];

      invariant(
        originalNode,
        `Expected original node to exist, but got: ${ originalNode }. ` +
          `ID: ${ JSON.stringify(overlappingNode.id) }. ` +
          `Index: ${ JSON.stringify(originalNodeIndex) }`
      );

      updateNodePreservingState(originalNode, overlappingNode);
    }

    const nonOverlappingNodes = moreChildrenNodes
      .filter(n => !indexByCurrentChildId.has(n.id));

    node.children = [
      ...node.children,
      ...nonOverlappingNodes,
    ] as LevelNode[] | GroupNode[] | PositionNode[];
    node.childrenTotal.data = nextChildrenTotal;
    node.childrenDataRequestedAt = requestedAt;
    node.childrenMoreLoadStatus = 'success';
  });
}

async function loadAdditionalData(
  node: DmTreeNodeWithChildren,
  treeOptions: TreeOptions,
  newChildrenNodes: DmTreeNode[],
) {
  const newChildrenNodeIds = new Set(newChildrenNodes.map(n => n.id));

  await fillNodesWithAdditionalData({
    getSubtree: () => treeOptions.getNode(node),
    updateSubtree: (updater) => {
      treeOptions.updateNode(node, (n) => {
        updater(n);
      });
    },
    getNodes: nodes => nodes.filter(n => newChildrenNodeIds.has(n.id)),
  });
}
