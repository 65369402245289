import { UpdateTemplate } from 'actions/caller';
import { useFormActionLoader } from 'hooks';
import { Template } from 'models/caller';

interface UpdateTemplateHookResult {
  update: (template: Template) => void;
}

export const useUpdateTemplate = (): UpdateTemplateHookResult => {
  const { doAction } = useFormActionLoader();

  const update = (template: Template) => doAction({
    action: UpdateTemplate(template),
  });

  return { update };
};