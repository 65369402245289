/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Provisioning API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShipmentStatusEnum {
    Created = 'created',
    Dispatched = 'dispatched',
    Received = 'received',
    InAWarehouse = 'in_a_warehouse'
}

