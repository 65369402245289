import { getChildrenForNode } from '../cache/utils';
import {
  DmTreeNode,
  DmTreeNodeWithChildren,
  TreeMethods
} from '../types';
import { refetchNodeChildren } from './refetchNodeChildren';
import { isNodeAlreadyExpanded, isNodeExpandable } from './utils';

type TreeOptions = Pick<TreeMethods, 'getNode' | 'updateNode'>;

export async function expand(
  { id: nodeId }: Readonly<DmTreeNode>,
  treeOptions: TreeOptions
) {
  const { getNode } = treeOptions;
  const node = getNode(nodeId);

  if (!node || !isNodeExpandable(node) || isNodeAlreadyExpanded(node)) {
    return;
  }

  expandNode(node, treeOptions);
  addChildrenToNodeFromCache(node, treeOptions);
  await refetchNodeChildren(node, treeOptions);
}

function expandNode(_node: DmTreeNodeWithChildren, treeOptions: TreeOptions) {
  const { updateNode } = treeOptions;

  updateNode(_node, (node) => {
    node.expansionStatus = 'expanded';
  });
}

function addChildrenToNodeFromCache(
  _node: DmTreeNodeWithChildren,
  treeOptions: TreeOptions
) {
  const { getNode, updateNode } = treeOptions;
  const node = getNode(_node);

  if (!node || node.children) {
    return;
  }

  const children = getChildrenForNode(node);

  if (children) {
    updateNode(node, (n) => {
      n.children = children;
    });
  }
}
