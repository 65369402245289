import { GetRulesParams, Rule } from 'models/rabbit';

export const RABBIT_RULE_DATA_LOAD_BY_ID = 'rabbit/rule/LOAD_BY_ID';
export const RABBIT_RULE_DATA_LOAD_BY_PARAMS = 'rabbit/rule/LOAD_BY_PARAMS';
export const RABBIT_RULE_DATA_LOAD_DICTIONARY = 'rabbit/rule//LOAD_DICTIONARY';
export const RABBIT_RULE_DATA_LOAD_START = 'rabbit/rule/LOAD_START';
export const RABBIT_RULE_DATA_LOAD_FAILURE = 'rabbit/rule/LOAD_FAILURE';
export const RABBIT_RULE_DATA_LOAD_SUCCESS = 'rabbit/rule/LOAD_SUCCESS';

export interface LoadRabbitRuleDataById {
  readonly type: typeof RABBIT_RULE_DATA_LOAD_BY_ID;
  readonly ruleId: number;
}

export interface LoadRabbitRuleDataByParams {
  readonly type: typeof RABBIT_RULE_DATA_LOAD_BY_PARAMS;
  readonly params: GetRulesParams;
}

export interface LoadRabbitRuleDataDictionary {
  readonly type: typeof RABBIT_RULE_DATA_LOAD_DICTIONARY;
}

export interface LoadRabbitRuleDataStart {
  readonly type: typeof RABBIT_RULE_DATA_LOAD_START;
  readonly params: GetRulesParams | number;
}

export interface LoadRabbitRuleDataFailure {
  readonly type: typeof RABBIT_RULE_DATA_LOAD_FAILURE;
  readonly params: GetRulesParams | number;
  readonly error: string;
}

export interface LoadRabbitRuleDataSuccess {
  readonly type: typeof RABBIT_RULE_DATA_LOAD_SUCCESS;
  readonly params: GetRulesParams | number;
  readonly data: Rule[];
  readonly total: number;
}

export const LoadRabbitRuleDataById = (ruleId: number): LoadRabbitRuleDataById => ({
  type: RABBIT_RULE_DATA_LOAD_BY_ID, ruleId
});

export const LoadRabbitRuleDataByParams = (params: GetRulesParams): LoadRabbitRuleDataByParams => ({
  type: RABBIT_RULE_DATA_LOAD_BY_PARAMS, params
});

export const LoadRabbitRuleDataDictionary = (): LoadRabbitRuleDataDictionary => ({
  type: RABBIT_RULE_DATA_LOAD_DICTIONARY
});

export const LoadRabbitRuleDataStart = (params: GetRulesParams | number): LoadRabbitRuleDataStart => ({
  type: RABBIT_RULE_DATA_LOAD_START, params
});

export const LoadRabbitRuleDataFailure = (params: GetRulesParams | number, error: string): LoadRabbitRuleDataFailure => ({
  type: RABBIT_RULE_DATA_LOAD_FAILURE, params, error
});

export const LoadRabbitRuleDataSuccess = (params: GetRulesParams | number, data: Rule[], total: number): LoadRabbitRuleDataSuccess => ({
  type: RABBIT_RULE_DATA_LOAD_SUCCESS, params, data, total
});

export type Action =
  | LoadRabbitRuleDataById
  | LoadRabbitRuleDataByParams
  | LoadRabbitRuleDataDictionary
  | LoadRabbitRuleDataStart
  | LoadRabbitRuleDataFailure
  | LoadRabbitRuleDataSuccess