import { API } from 'aws-amplify';
import { ApiResponse } from 'models';
import { GetUsersParams, User, UserUpdateParams } from 'models/user-management';
import { errorHandler, formatQuery } from 'clients/utils';
import { config, urls } from './config';

export const fetchUsers = (params: GetUsersParams): Promise<ApiResponse> => {
  const init = {
    queryStringParameters: formatQuery({
      email: params.email,
      limit: params.limit,
      offset: params.offset,
      name: params.name,
      permissions: params.permissions,
      project_id: params.projects,
      user_group: params.owner,
      role: params.role,
    }),
  };

  return API
    .get(config.name, urls.users, init)
    .catch(errorHandler);
};

export const fetchUser = (userSub: User['sub']): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.userBySub(userSub), {})
    .catch(errorHandler);
};

export const updateUser = (params: UserUpdateParams): Promise<ApiResponse> => {
  const init = {
    body: params.props,
  };

  return API
    .put(config.name, urls.userBySub(params.sub), init)
    .catch(errorHandler);
};

export const deleteUser = (userSub: User['sub']): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.userBySub(userSub), {})
    .catch(errorHandler);
};

export const changePasswordByUser = (newPassword: string, oldPassword: string): Promise<ApiResponse> => {
  const body = {
    body: formatQuery({
      password: newPassword,
      old_password: oldPassword,
    }),
  };

  return API
    .post(config.name, urls.changePassword, body)
    .catch(errorHandler);
};

export const changePasswordForAnotherUser = (userSub: User['sub'], password: string, permanent = false): Promise<ApiResponse> => {
  const body = {
    body: formatQuery({
      password,
      permanent,
    }),
  };

  return API
    .post(config.name, urls.changePasswordForAnotherUser(userSub), body)
    .catch(errorHandler);
};

export const confirmAccount = (userSub: User['sub']): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.confirmAccount(userSub), {})
    .catch(errorHandler);
};
