import React from 'react';
import { useMessageTypesDictionary } from 'utils/dictionary';

interface Props {
  messageTypeId: string;
}

const RabbitMessageTypeName = ({ messageTypeId }: Props): JSX.Element => {
  const messageTypes = useMessageTypesDictionary();
  const messageType = messageTypes.find((el) => el.id === messageTypeId);
  const messsageTypeName = messageType ? messageType.name : messageTypeId;
  return (
    <>{ messsageTypeName }</>
  );
};

export default RabbitMessageTypeName;
