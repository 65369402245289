import { FormErrors } from 'form';
import { FormField, FormState } from './types';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.ownerId)) {
    if (!state[FormField.ownerId]) {
      errors[FormField.ownerId] = 'Owner is required';
    }
  }

  if (fields.includes(FormField.groupId)) {
    if (!state[FormField.groupId]) {
      errors[FormField.groupId] = 'Group is required';
    }
  }

  return errors;
};


