import { RawOnline } from 'models';
import { HistoryStatistic } from 'models/rabbit';
import { Statistic as HttpStatistic } from 'models/caller';
import { ConnectivityChartData } from 'models/connectivity';
import { StatisticRawMessage } from './device-statistic';

export const FETCH_MAIN_PAGE_STATISTIC = 'FETCH_MAIN_PAGE_STATISTIC';

export const FETCH_RAW_ONLINE_START = 'FETCH_RAW_ONLINE_START';
export const FETCH_RAW_ONLINE_SUCCESS = 'FETCH_RAW_ONLINE_SUCCESS';
export const FETCH_RAW_ONLINE_FAILED = 'FETCH_RAW_ONLINE_FAILED';

export const FETCH_CONNECTIVITY_STATISTIC_START = 'FETCH_CONNECTIVITY_STATISTIC_START';
export const FETCH_CONNECTIVITY_STATISTIC_SUCCESS = 'FETCH_CONNECTIVITY_STATISTIC_SUCCESS';
export const FETCH_CONNECTIVITY_STATISTIC_FAILED = 'FETCH_CONNECTIVITY_STATISTIC_FAILED';

export const FETCH_STAT_RMQ_START = 'FETCH_STAT_RMQ_START';
export const FETCH_STAT_RMQ_SUCCESS = 'FETCH_STAT_RMQ_SUCCESS';
export const FETCH_STAT_RMQ_FAILED = 'FETCH_STAT_RMQ_FAILED';

export const FETCH_STAT_HTTTPCALLS_START = 'FETCH_STAT_HTTTPCALLS_START';
export const FETCH_STAT_HTTTPCALLS_SUCCESS = 'FETCH_STAT_HTTTPCALLS_SUCCESS';
export const FETCH_STAT_HTTTPCALLS_FAILED = 'FETCH_STAT_HTTTPCALLS_FAILED';

export const FETCH_STAT_RAW_MESSAGES_START = 'FETCH_STAT_RAW_MESSAGES_START';
export const FETCH_STAT_RAW_MESSAGES_SUCCESS = 'FETCH_STAT_RAW_MESSAGES_SUCCESS';
export const FETCH_STAT_RAW_MESSAGES_FAILED = 'FETCH_STAT_RAW_MESSAGES_FAILED';

export interface GetStatisticMainPage {
  readonly type: typeof FETCH_MAIN_PAGE_STATISTIC;
  readonly quiet?: boolean;
}
export interface GetStatisticRawMessages {
  readonly type: typeof FETCH_STAT_RAW_MESSAGES_START;
}
export interface GetStatisticRawMessagesSuccess {
  readonly type: typeof FETCH_STAT_RAW_MESSAGES_SUCCESS;
  payload: StatisticRawMessage[];
}
export interface GetStatisticRawMessagesFailed {
  readonly type: typeof FETCH_STAT_RAW_MESSAGES_FAILED;
}

export interface GetStatConnectivityStart {
  readonly type: typeof FETCH_CONNECTIVITY_STATISTIC_START;
}
export interface GetStatConnectivitySuccess {
  readonly type: typeof FETCH_CONNECTIVITY_STATISTIC_SUCCESS;
  payload: ConnectivityChartData[];
}
export interface GetStatConnectivityFailed {
  readonly type: typeof FETCH_CONNECTIVITY_STATISTIC_FAILED;
}

export interface GetStatRMQsender {
  readonly type: typeof FETCH_STAT_RMQ_START;
}
export interface GetStatRMQsenderSuccess {
  readonly type: typeof FETCH_STAT_RMQ_SUCCESS;
  payload: HistoryStatistic[];
}
export interface GetStatRMQsenderFailed {
  readonly type: typeof FETCH_STAT_RMQ_FAILED;
}

export interface GetStatHTTTPCalls {
  readonly type: typeof FETCH_STAT_HTTTPCALLS_START;
}
export interface GetStatHTTTPCallsSuccess {
  readonly type: typeof FETCH_STAT_HTTTPCALLS_SUCCESS;
  payload: HttpStatistic[];
}
export interface GetStatHTTTPCallsFailed {
  readonly type: typeof FETCH_STAT_HTTTPCALLS_FAILED;
}

export interface GetRawOnlineStart {
  readonly type: typeof FETCH_RAW_ONLINE_START;
}
export interface GetRawOnlineSuccess {
  readonly type: typeof FETCH_RAW_ONLINE_SUCCESS;
  payload: RawOnline[];
}
export interface GetRawOnlineFailed {
  readonly type: typeof FETCH_RAW_ONLINE_FAILED;
}

export const DoGetStatisticMainPage: (quiet?: boolean) => GetStatisticMainPage = (quiet) => ({
  type: FETCH_MAIN_PAGE_STATISTIC, quiet
});
export const DoGetStatConnectivitySuccess: (payload: ConnectivityChartData[]) => GetStatConnectivitySuccess = (payload) => ({
  type: FETCH_CONNECTIVITY_STATISTIC_SUCCESS,
  payload
});
export const DoGetStatConnectivityFailed: () => GetStatConnectivityFailed = () => ({
  type: FETCH_CONNECTIVITY_STATISTIC_FAILED
});

export const DoGetStatisticRawMessages: () => GetStatisticRawMessages = () => ({
  type: FETCH_STAT_RAW_MESSAGES_START
});
export const DoGetStatisticRawMessagesSuccess: (payload: StatisticRawMessage[]) => GetStatisticRawMessagesSuccess = (payload) => ({
  type: FETCH_STAT_RAW_MESSAGES_SUCCESS,
  payload,
});
export const DoGetStatisticRawMessagesFailed: () => GetStatisticRawMessagesFailed = () => ({
  type: FETCH_STAT_RAW_MESSAGES_FAILED
});

export const DoGetStatRMQsender: () => GetStatRMQsender = () => ({
  type: FETCH_STAT_RMQ_START
});
export const DoGetStatRMQsenderSuccess: (payload: HistoryStatistic[]) => GetStatRMQsenderSuccess = (payload) => ({
  type: FETCH_STAT_RMQ_SUCCESS,
  payload,
});
export const DoGetStatRMQsenderFailed: () => GetStatRMQsenderFailed = () => ({
  type: FETCH_STAT_RMQ_FAILED
});

export const DoGetStatHTTTPCalls: () => GetStatHTTTPCalls = () => ({
  type: FETCH_STAT_HTTTPCALLS_START
});
export const DoGetStatHTTTPCallsSuccess: (payload: HttpStatistic[]) => GetStatHTTTPCallsSuccess = (payload) => ({
  type: FETCH_STAT_HTTTPCALLS_SUCCESS,
  payload,
});
export const DoGetStatHTTTPCallsFailed: () => GetStatHTTTPCallsFailed = () => ({
  type: FETCH_STAT_HTTTPCALLS_FAILED
});

export const DoGetRawOnlineStart: () => GetRawOnlineStart = () => ({
  type: FETCH_RAW_ONLINE_START
});
export const DoGetRawOnlineSuccess: (payload: RawOnline[]) => GetRawOnlineSuccess = (payload) => ({
  type: FETCH_RAW_ONLINE_SUCCESS,
  payload
});
export const DoGetRawOnlineFailed: () => GetRawOnlineFailed = () => ({
  type: FETCH_RAW_ONLINE_FAILED
});

export type Action = (
  | GetStatisticMainPage
  | GetStatConnectivityStart
  | GetStatConnectivitySuccess
  | GetStatConnectivityFailed
  | GetStatisticRawMessages
  | GetStatisticRawMessagesSuccess
  | GetStatisticRawMessagesFailed
  | GetStatRMQsender
  | GetStatRMQsenderSuccess
  | GetStatRMQsenderFailed
  | GetStatHTTTPCalls
  | GetStatHTTTPCallsSuccess
  | GetStatHTTTPCallsFailed
  | GetRawOnlineStart
  | GetRawOnlineSuccess
  | GetRawOnlineFailed
);
