import { useQuery } from 'react-query';
import { findPositions } from 'clients/device-management';
import { getResponse } from 'utils/clients';
import { QueryType, Tree } from './types';
import { findGroupNode, getPositionId, useErrorNotifier } from './utils';

export const usePositionResult = (zoneIds: number[], tree: Tree, queryHash: string): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.position],
    queryHash: `deviceManagement/positions/${ queryHash }`,
    queryFn: async () => getResponse(await findPositions({
      zones: zoneIds,
      limit: 9999,
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notify(`Error while fetching positions: ${ (e as Error).message }`),
    enabled: tree.loaded.zone && zoneIds.length > 0
  });

  if (result.isSuccess) {
    tree.loaded.position = true;
    for (const position of result.data?.data ?? []) {
      const groupNode = findGroupNode(tree, position.zone_id, Number(position.level_id), position.group_id);
      if (!groupNode) {
        continue;
      }

      const positionId = getPositionId(position.id);
      groupNode.children.total++;
      groupNode.children.items[positionId] = {
        id: positionId,
        parent: groupNode,
        type: 'position',
        position: position,
      };
    }
  }

  return tree;
};