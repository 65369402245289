import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { buildCallback, buildUrlParams, trimQuery, parseUrlParams, UrlItems } from 'utils/routing/query';

type updateCallback<T> = (params: T) => void;

/**
 * Location params hook
 * Logic steps:
 * 1) Take location change events
 * 2) Check component hash with location hash
 * 3) if not equals set new component hash and call updateParams callback
 * 4) Create return function to update params ( set params to url and call updateParams callback )
 */
export function useLocationParams<T>(initial: T, buidParams: buildCallback<T>, updateParams: updateCallback<T>): updateCallback<T> {
  const history = useHistory();
  const locationQuery = history.location.hash.startsWith('#') ? history.location.hash.slice(1) : history.location.hash;
  const [query, setQuery] = useState<string>(locationQuery);
  if (query !== locationQuery) {
    setQuery(locationQuery);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateParams(parseUrlParams<T>(query, buidParams)), [query]);

  return (params: T) => {
    const paramsQuery = buildUrlParams(params, initial);
    if (query !== paramsQuery) {
      setQuery(paramsQuery);
      history.push({ ...history.location, hash: paramsQuery });
    }
  };
}

const parseQuery = (query: string): UrlItems => parseUrlParams<UrlItems>(query, params => params);

interface LocationItemsState {
  items: UrlItems;
  setItems: React.Dispatch<React.SetStateAction<UrlItems>>;
}

interface LocationItemsOptions {
  updateMethod?: 'push' | 'replace';
  onChange?: (params?: UrlItems) => void,
}

export const useLocationItems = ({ updateMethod = 'push', onChange }: LocationItemsOptions = {}): LocationItemsState => {
  const history = useHistory();

  const query = trimQuery(history.location.hash);
  const [items, setItems] = useState<UrlItems>(() => parseQuery(query));
  useEffect(() => {
    setItems(parseQuery(query));
  }, [query]);

  const updateItems: React.Dispatch<React.SetStateAction<UrlItems>> = (update: React.SetStateAction<UrlItems>) => {
    setItems(prevItems => {
      const newItems = typeof update === 'function' ? update(prevItems) : { ...prevItems, ...update };
      const hash = buildUrlParams(newItems);
      if (query !== trimQuery(hash)) {
        history[updateMethod]({ ...history.location, hash });
        onChange?.(newItems);
      }

      return newItems;
    });
  };

  return { items, setItems: updateItems };
};

export const useLocationRedirect = (replace = false): (redirectUrl: string, hash?: string) => void => {
  const history = useHistory();
  const [redirectUrl, setRedirectUrl] = useState('');
  useEffect(() => {
    if (redirectUrl) {
      replace ? history.replace(redirectUrl) : history.push(redirectUrl);
    }
  }, [redirectUrl, history, replace]);

  return (redirectUrl: string, hash?: string): void => {
    const newHash = hash ?? history.location.hash;
    setRedirectUrl(redirectUrl + (newHash.length > 0 ? `?#${ newHash }` : ''));
  };
};

export const useLocationBackAction = (paramName = 'returnUrl', replace = false): (backUrl: string) => void => {
  const redirect = useLocationRedirect(replace);
  const { items } = useLocationItems();

  return (defaultUrl: string): void => {
    if (paramName in items) {
      redirect(decodeURIComponent(items[paramName]), '');
    } else {
      redirect(defaultUrl);
    }
  };
};
