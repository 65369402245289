import React from 'react';
import { dmPositionPath } from 'routing/paths';

// components
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { CustomLink } from 'components/Links';

interface Props {
  id?: number;
  network: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

const PositionName = ({ id, network, innerProps }: Props) => {
  const TypoProps = { ...defaultProps, ...innerProps };
  if (id) {
    return (
      <Link
        component={ CustomLink }
        color="secondary"
        to={ dmPositionPath(id) }
        withReturnUrl
      >
        { network || id }
      </Link>
    );
  } else if (network) {
    return (
      <Link
        component={ CustomLink }
        color="secondary"
        to={ dmPositionPath(network) }
        withReturnUrl
      >
        { network }
      </Link>
    );
  } else {
    return <Typography { ...TypoProps }>-</Typography>;
  }
};

export default PositionName;
