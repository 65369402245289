import * as Actions from 'actions/pages/station-monitoring';
import * as models from 'models/base-station-status';

export interface State {
  uptimeHistory: {
    data: models.UptimeHistory[];
    fetched: boolean;
    dateRange?: models.DateParams;
  };
  internetHistory: {
    data: models.ConnectionHistoryElement[];
    fetched: boolean;
    dateRange?: models.DateParams;
  };
  onlineHistory: {
    data: models.VpnHistoryElement[];
    fetched: boolean;
    dateRange?: models.DateParams;
  };
  noiseLevelHistory: {
    data: models.NoiseLevelHistory['noise_level'];
    fetched: boolean;
    dateRange?: models.DateParams;
  };
  lastMessage?: models.LastMessage;
  dongle?: models.DongleStatus;
  minCpp?: models.MinCppStatus;
  filters: models.StationMonitoringFilters;
}

const initialState: State = {
  uptimeHistory: { data: [], fetched: false },
  onlineHistory: { data: [], fetched: false },
  internetHistory: { data: [], fetched: false },
  noiseLevelHistory: { data: [], fetched: false },
  filters: {
    stationId: 0,
    dateRange: models.INITIAL_DATE_RANGE,
  },
};

export const reducer = (state: State = initialState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.STATION_MONITORING_SYNC_START:
      return { ...initialState, filters: action.filters };
    case Actions.STATION_MONITORING_SYNC_STOP:
      return initialState;
    case Actions.STATION_MONITORING_LOAD_FAILURE:
      return {
        ...state,
        uptimeHistory: action.aspect === 'uptimeHistory'
          ? { ...state.uptimeHistory, fetched: false }
          : state.uptimeHistory,
        internetHistory: action.aspect === 'internetHistory'
          ? { ...state.internetHistory, fetched: false }
          : state.internetHistory,
        onlineHistory: action.aspect === 'vpnHistory'
          ? { ...state.onlineHistory, fetched: false }
          : state.onlineHistory,
        noiseLevelHistory: action.aspect === 'noiseLevelHistory'
          ? { ...state.noiseLevelHistory, fetched: false }
          : state.noiseLevelHistory,
        lastMessage: action.aspect === 'lastMessage'
          ? undefined
          : state.lastMessage,
        dongle: action.aspect === 'dongle'
          ? undefined
          : state.dongle,
        minCpp: action.aspect === 'minCpp'
          ? undefined
          : state.minCpp,
      };
    case Actions.STATION_MONITORING_STATUS_HISTORY_LOADED:
      return {
        ...state,
        uptimeHistory: {
          data: action.payload.uptime_history ?? [],
          fetched: true,
          dateRange: action.dateRange,
        },
      };
    case Actions.STATION_MONITORING_INTERNET_HISTORY_LOADED:

      return {
        ...state,
        internetHistory: {
          data: action.payload.connection_history ?? [],
          fetched: true,
          dateRange: action.dateRange,
        },
      };
    case Actions.STATION_MONITORING_NOISE_LEVEL_HISTORY_LOADED:
      return {
        ...state,
        noiseLevelHistory: {
          data: action.payload.noise_level ?? [],
          fetched: true,
          dateRange: action.dateRange,
        },
      };
    case Actions.STATION_MONITORING_VPN_HISTORY_LOADED:
      return {
        ...state,
        onlineHistory: {
          data: action.payload.vpn_history ?? [],
          fetched: true,
          dateRange: action.dateRange,
        },
      };
    case Actions.STATION_MONITORING_LAST_MESSAGE_LOADED:
      return {
        ...state,
        lastMessage: action.payload,
      };
    case Actions.STATION_MONITORING_DONGLE_LOADED:
      return {
        ...state,
        dongle: action.payload,
      };
    case Actions.STATION_MONITORING_MIN_CPP_LOADED:
      return {
        ...state,
        minCpp: action.payload,
      };
    default:
      return state;
  }
};
