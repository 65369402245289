import { ShipmentFilters, ShipmentParams, Shipment, ShipmentUpdateBody, ShipmentCreateBody, ShipmentStatusEnum } from 'models/provisioning';

export const FETCH_SHIPMENT_BY_PARAMS = 'provisioning/FETCH_SHIPMENT_BY_PARAMS';
export const FETCH_SHIPMENT_SUCCESS = 'provisioning/FETCH_SHIPMENT_SUCCESS';
export const FETCH_SHIPMENT_FAILURE = 'provisioning/FETCH_SHIPMENT_FAILURE';
export const ADD_BOX_IDS = 'provisioning/ADD_BOX_IDS';

export const SET_SHIPMENT_FILTERS = 'provisioning/SET_SHIPMENT_FILTERS';
export const UPDATE_SHIPMENT_FILTERS_FROM_URL = 'provisioning/UPDATE_SHIPMENT_FILTERS_FROM_URL';

export const FETCH_SHIPMENT_BY_ID = 'provisioning/FETCH_SHIPMENT_BY_ID';
export const FETCH_SHIPMENT_BY_ID_SUCCESS = 'provisioning/FETCH_SHIPMENT_BY_ID_SUCCESS';
export const FETCH_SHIPMENT_BY_ID_FAILURE = 'provisioning/FETCH_SHIPMENT_BY_ID_FAILURE';

export const SHIPMENT_UPDATE = 'provisioning/SHIPMENT_UPDATE';
export const SHIPMENT_UPDATE_SUCCESS = 'provisioning/SHIPMENT_UPDATE_SUCCESS';
export const SHIPMENT_UPDATE_FAILURE = 'provisioning/SHIPMENT_UPDATE_FAILURE';

export const SHIPMENT_DELETE = 'provisioning/SHIPMENT_DELETE';
export const SHIPMENT_DELETE_SUCCESS = 'provisioning/SHIPMENT_DELETE_SUCCESS';
export const SHIPMENT_DELETE_FAILURE = 'provisioning/SHIPMENT_DELETE_FAILURE';

export const SHIPMENT_CREATE = 'provisioning/SHIPMENT_CREATE';
export const SHIPMENT_CREATE_SUCCESS = 'provisioning/SHIPMENT_CREATE_SUCCESS';
export const SHIPMENT_CREATE_FAILURE = 'provisioning/SHIPMENT_CREATE_FAILURE';

export const SHIPMENT_UPDATE_STATUS = 'provisioning/SHIPMENT_UPDATE_STATUS';
export const SHIPMENT_UPDATE_STATUS_SUCCESS = 'provisioning/SHIPMENT_UPDATE_STATUS_SUCCESS';
export const SHIPMENT_UPDATE_STATUS_FAILURE = 'provisioning/SHIPMENT_UPDATE_STATUS_FAILURE';

export const FETCH_SHIPMENT_CSV_REPORT = 'provisioning/SHIPMENT_FETCH_CSV_REPORT';
export const FETCH_SHIPMENT_CSV_REPORT_SUCCESS  = 'provisioning/SHIPMENT_FETCH_CSV_REPORT_SUCCESS';
export const FETCH_SHIPMENT_CSV_REPORT_FAILURE = 'provisioning/SHIPMENT_FETCH_CSV_REPORT_FAILURE';

export interface FetchShipmentByParams {
  readonly type: typeof FETCH_SHIPMENT_BY_PARAMS;
  readonly params: ShipmentParams;
}
export interface FetchShipmentSuccess {
  readonly type: typeof FETCH_SHIPMENT_SUCCESS;
  readonly shipments: Shipment[];
  readonly total: number;
}
export interface FetchShipmentFailure {
  readonly type: typeof FETCH_SHIPMENT_FAILURE;
}

export interface FetchShipmentById {
  readonly type: typeof FETCH_SHIPMENT_BY_ID;
  readonly id: string;
}

export interface FetchShipmentByIdSuccess {
  readonly type: typeof FETCH_SHIPMENT_BY_ID_SUCCESS;
  readonly shipment: Shipment;
}
export interface FetchShipmentByIdFailure {
  readonly type: typeof FETCH_SHIPMENT_BY_ID_FAILURE;
}

export interface UpdateShipmentFromUrl {
  readonly type: typeof UPDATE_SHIPMENT_FILTERS_FROM_URL;
}

export interface SetShipmentFilters {
  readonly type: typeof SET_SHIPMENT_FILTERS;
  readonly filters: ShipmentFilters
}

export interface AddBoxIds {
  type: typeof ADD_BOX_IDS;
  id: number;
}

export interface UpdateShipment {
  readonly type: typeof SHIPMENT_UPDATE;
  id: string;
  data: ShipmentUpdateBody;
}
export interface UpdateShipmentSuccess {
  readonly type: typeof SHIPMENT_UPDATE_SUCCESS;
  shipment: Shipment;
}
export interface UpdateShipmentFailure {
  readonly type: typeof SHIPMENT_UPDATE_FAILURE;
}

export interface UpdateShipmentStatus {
  readonly type: typeof SHIPMENT_UPDATE_STATUS;
  id: string;
  status: ShipmentStatusEnum;
}
export interface UpdateShipmentStatusSuccess {
  readonly type: typeof SHIPMENT_UPDATE_STATUS_SUCCESS;
  shipment: Shipment;
}
export interface UpdateShipmentStatusFailure {
  readonly type: typeof SHIPMENT_UPDATE_STATUS_FAILURE;
}

export interface DeleteShipment {
  readonly type: typeof SHIPMENT_DELETE;
  shipmentId: string;
}
export interface DeleteShipmentSuccess {
  readonly type: typeof SHIPMENT_DELETE_SUCCESS;
}
export interface DeleteShipmentFailure {
  readonly type: typeof SHIPMENT_DELETE_FAILURE;
}

export interface CreateShipment {
  readonly type: typeof SHIPMENT_CREATE;
  shipment: ShipmentCreateBody;
}
export interface CreateShipmentSuccess {
  readonly type: typeof SHIPMENT_CREATE_SUCCESS;
  shipment: Shipment;
}
export interface CreateShipmentFailure {
  readonly type: typeof SHIPMENT_CREATE_FAILURE;
}

export interface FetchShipmentCSVReport {
  readonly type: typeof FETCH_SHIPMENT_CSV_REPORT;
  readonly shipment: Shipment
}

export interface FetchShipmentCSVReportFailure {
  readonly type: typeof FETCH_SHIPMENT_CSV_REPORT_FAILURE;
}

export interface FetchShipmentCSVReportSuccess {
  readonly type: typeof FETCH_SHIPMENT_CSV_REPORT_SUCCESS;
}

export const DoFetchShipmentByParams: (
  params: ShipmentParams
) => FetchShipmentByParams = params => ({
  type: FETCH_SHIPMENT_BY_PARAMS,
  params,
});

export const DoFetchShipmentSuccess: (
  shipments: Shipment[],
  total: number
) => FetchShipmentSuccess = (shipments, total) => ({
  type: FETCH_SHIPMENT_SUCCESS,
  shipments,
  total,
});

export const DoFetchShipmentFailure: () => FetchShipmentFailure = () => ({
  type: FETCH_SHIPMENT_FAILURE
});

export const DoFetchShipmentById: (id: string) => FetchShipmentById = id => ({
  type: FETCH_SHIPMENT_BY_ID,
  id
});

export const DoFetchShipmentByIdSuccess: (shipment: Shipment) => FetchShipmentByIdSuccess = shipment => ({
  type: FETCH_SHIPMENT_BY_ID_SUCCESS,
  shipment
});

export const DoFetchShipmentByIdFailure: () => FetchShipmentByIdFailure = () => ({
  type: FETCH_SHIPMENT_BY_ID_FAILURE
});

export const DoUpdateShipmentFromUrl: () => UpdateShipmentFromUrl = () => ({
  type: UPDATE_SHIPMENT_FILTERS_FROM_URL
});

export const DoSetShipmentFilters: (
  filters: ShipmentFilters
) => SetShipmentFilters = (filters) => ({
  type: SET_SHIPMENT_FILTERS,
  filters,
});

export const DoAddBoxIds = (id: number): AddBoxIds => ({
  type: ADD_BOX_IDS,
  id,
});

export const DoDeleteShipment: (shipmentId: string) => DeleteShipment = shipmentId => ({
  type: SHIPMENT_DELETE,
  shipmentId,
});
export const DoDeleteShipmentSuccess: () => DeleteShipmentSuccess = () => ({
  type: SHIPMENT_DELETE_SUCCESS,
});
export const DoDeleteShipmentFailure: () => DeleteShipmentFailure = () => ({
  type: SHIPMENT_DELETE_FAILURE,
});

export const DoUpdateShipment: (id: string, data: ShipmentUpdateBody) => UpdateShipment = (id, data) => ({
  type: SHIPMENT_UPDATE,
  id,
  data,
});
export const DoUpdateShipmentSuccess: (shipment: Shipment) => UpdateShipmentSuccess = shipment => ({
  type: SHIPMENT_UPDATE_SUCCESS,
  shipment
});
export const DoUpdateShipmentFailure: () => UpdateShipmentFailure = () => ({
  type: SHIPMENT_UPDATE_FAILURE,
});

export const DoCreateShipment: (shipment: ShipmentCreateBody) => CreateShipment = shipment => ({
  type: SHIPMENT_CREATE,
  shipment
});
export const DoCreateShipmentSuccess: (shipment: Shipment) => CreateShipmentSuccess = shipment => ({
  type: SHIPMENT_CREATE_SUCCESS,
  shipment
});
export const DoCreateShipmentFailure: () => CreateShipmentFailure = () => ({
  type: SHIPMENT_CREATE_FAILURE,
});

export const DoUpdateShipmentStatus: (id: string, status: ShipmentStatusEnum) => UpdateShipmentStatus = (id, status) => ({
  type: SHIPMENT_UPDATE_STATUS,
  id,
  status,
});
export const DoUpdateShipmentStatusSuccess: (shipment: Shipment) => UpdateShipmentStatusSuccess = shipment => ({
  type: SHIPMENT_UPDATE_STATUS_SUCCESS,
  shipment
});
export const DoUpdateShipmentStatusFailure: () => UpdateShipmentStatusFailure = () => ({
  type: SHIPMENT_UPDATE_STATUS_FAILURE,
});

export const DoFetchShipmentCSVReport = (shipment: Shipment): FetchShipmentCSVReport => ({
  type: FETCH_SHIPMENT_CSV_REPORT,
  shipment,
});

export const DoFetchShipmentCSVReportFailure = (): FetchShipmentCSVReportFailure => ({
  type: FETCH_SHIPMENT_CSV_REPORT_FAILURE,
});

export const DoFetchShipmentCSVReportSuccess = (): FetchShipmentCSVReportSuccess => ({
  type: FETCH_SHIPMENT_CSV_REPORT_SUCCESS,
});

export type Action = (
  | FetchShipmentSuccess
  | FetchShipmentFailure
  | FetchShipmentById
  | FetchShipmentByIdFailure
  | FetchShipmentByIdSuccess
  | UpdateShipmentFromUrl
  | SetShipmentFilters
  | AddBoxIds
  | DeleteShipment
  | DeleteShipmentSuccess
  | DeleteShipmentFailure
  | UpdateShipment
  | UpdateShipmentSuccess
  | UpdateShipmentFailure
  | CreateShipment
  | CreateShipmentSuccess
  | CreateShipmentFailure
  | UpdateShipmentStatus
  | UpdateShipmentStatusSuccess
  | UpdateShipmentStatusFailure
  | FetchShipmentCSVReport
  | FetchShipmentCSVReportFailure
  | FetchShipmentCSVReportSuccess
  | FetchShipmentByParams
);
