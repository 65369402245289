import { useQuery } from 'react-query';
import { fetchZones } from 'clients/device-management';
import { getResponseWithTotal } from 'utils/clients';
import { Filters, QueryType, Tree } from './types';
import { getZoneId, useErrorNotifier } from './utils';

export const useZoneResult = (filters: Filters, tree: Tree, queryHash: string, enabled = true): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.zone],
    queryHash: `deviceManagement/zones/${queryHash}`,
    queryFn: async () => getResponseWithTotal(await fetchZones({
      owner: filters.owner,
      projects: filters.projects,
      zones: filters.zones,
      limit: filters.limit,
      offset: filters.offset,
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: enabled,
    onError: e => notify(`Error while fetching zones: ${ (e as Error).message }`),
  });

  if (result.isSuccess) {
    tree.loaded.zone = true;
    tree.total = result.data?.total ?? 0;
    for (const zone of result.data?.data ?? []) {
      const zoneId = getZoneId(zone.id);
      tree.items[zoneId] = {
        id: zoneId,
        type: 'zone',
        zone: zone,
        children: {
          total: 0,
          items: {},
        }
      };
    }
  }

  return tree;
};