import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import { errorHandler, formatQuery } from './utils';
import {
  GetDongleParam,
  GetInternetHistoryParam,
  GetLastMessageParam,
  GetMinCppParam,
  GetStatusHistoryParam,
  GetStatusParam,
  InlineResponse2005
} from 'models/base-station-status';
import { ConnectionHistoryElement, StationStatus } from 'models/base-station-status';

const urls = {
  status: '/status',
  statusHistory: '/status/history',
  minCpp: '/min_cpp',
  dongle: '/dongle',
  internetHistory: '/internet/history',
  lastMessage: '/last_message',
  noiseLevelHistory: '/noise_level/history',
  vpnHistory: '/vpn/history',
  lorawanBaseStationStatus: '/base_stations_lora/status',
  lorawanBaseStationStatusHistory: '/base_stations_lora/status/history',
  lorawanBaseStationLastMessage: '/base_stations_lora/last_message'
};

export const config: ApiGConfig = {
  name: 'status',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://bsstatus.prod.api.nwave.io' : 'https://bsstatus.dev.api.nwave.io',
};

export const getStationsStatus = (param: GetStatusParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(',')
  });

  return API
    .get(config.name, urls.status, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsMinCpp = (param: GetMinCppParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
  });

  return API
    .get(config.name, urls.minCpp, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsDongle = (param: GetDongleParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
  });

  return API
    .get(config.name, urls.dongle, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsLastMessage = (param: GetLastMessageParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
  });

  return API
    .get(config.name, urls.lastMessage, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsStatusHistory = (param: GetStatusHistoryParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
    time_from: param.timeFrom?.toISOString(),
    time_till: param.timeTo?.toISOString(),
    limit: 10_000,
  });

  return API
    .get(config.name, urls.statusHistory, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsInternetHistory = (param: GetInternetHistoryParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
    time_from: param.timeFrom?.toISOString(),
    time_till: param.timeTo?.toISOString(),
    limit: 10_000,
  });

  return API
    .get(config.name, urls.internetHistory, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsNoiseLevelHistory = (param: GetInternetHistoryParam): Promise<ApiResponse> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
    time_from: param.timeFrom?.toISOString(),
    time_till: param.timeTo?.toISOString(),
    limit: 10_000,
  });

  return API
    .get(config.name, urls.noiseLevelHistory, { queryStringParameters })
    .catch(errorHandler);
};

export const getStationsVpnHistory = (param: GetStatusHistoryParam): Promise<InlineResponse2005> => {
  const queryStringParameters = formatQuery({
    ids: param.stationIds.join(','),
    time_from: param.timeFrom?.toISOString(),
    time_till: param.timeTo?.toISOString(),
    limit: 10_000,
  });

  return API
    .get(config.name, urls.vpnHistory, { queryStringParameters })
    .catch(errorHandler);
};

export const getLorawanStationStatusById = (ids: number[]): Promise<ApiResponse<StationStatus[]>> => {
  return API
    .get(config.name, urls.lorawanBaseStationStatus, { queryStringParameters: { ids: ids.join(',') } })
    .catch(errorHandler);
};

type LorawanHistoryParams = {
  id?: number
  dateFrom: string;
  dateTo: string;
}

export type LorawanStationStatusHistoryRes = {
  offline_history: ConnectionHistoryElement[]
}[]

export const getLorawanStationStatusHistoryById = ({
  id,
  dateFrom,
  dateTo
}: LorawanHistoryParams): Promise<ApiResponse<LorawanStationStatusHistoryRes>> => {
  const queryStringParameters = {
    ids: [id],
    time_from: dateFrom,
    time_till: dateTo,
  };

  return API
    .get(config.name, urls.lorawanBaseStationStatusHistory, { queryStringParameters })
    .catch(errorHandler);
};

type LorawanStationLastMessageRes = {
  station_id: number
  value: string
}

export const getLorawanStationLastMessage = (id: number): Promise<ApiResponse<LorawanStationLastMessageRes[]>> => {
  const queryStringParameters = {
    ids: [id],
  };

  return API
    .get(config.name, urls.lorawanBaseStationLastMessage, { queryStringParameters })
    .catch(errorHandler);
};

