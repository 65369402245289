import React from 'react';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  boxId: number;
  shipmentName: string;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onConfirm: (boxId: number) => void;
}

const DeleteBoxDialog = (props: Props): JSX.Element => {
  const { boxId, isOpen, onClose, onCloseEnd, onConfirm, shipmentName } = props;

  return (
    <EnsureDialog
      name="delete-box"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ () => onConfirm(boxId) }
    >
      Are you sure you want to delete <b>Box ID { boxId }</b> from shipment <b>{ shipmentName }</b>?
    </EnsureDialog>
  );
};

export default DeleteBoxDialog;
