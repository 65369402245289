import React from 'react';
import { isNil } from 'lodash';
import { Endpoint } from 'models/rabbit';
import { useRabbitRuleLoadSelectorByParams } from 'hooks/rabbit';
import { commonOptions } from 'utils/tables';
import { MUIDataTableColumnOptions } from 'utils/mui-datatables';

// components
import { Paper } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout } from 'components/Table';
import { RabbitMessageTypeName } from 'components/Labels';
import { ZoneCell } from './widget/ZoneCell';
import { ConnectZoneButton } from './widget/ConnectZoneButton';
import { ActionsCell } from './widget/ActionsCell';

// styles
import { getMuiTheme } from 'styles/themes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from './style';

interface Props {
  endpoint: Endpoint;
}

export const EndpointConnectedZones = ({ endpoint }: Props): JSX.Element => {
  const classes = useStyles();
  const { isLoading, rules } = useRabbitRuleLoadSelectorByParams({ endpointId: endpoint.id, limit: 999 });

  const tableOptions: MUIDataTableOptions = {
    ...commonOptions,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10],
    pagination: rules.length > 0,
    rowHover: rules.length > 0,
    textLabels: {
      body: {
        noMatch: 'No zone connected'
      }
    }
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'zone',
      label: 'Connected Zones',
      options: {
        customBodyRenderLite: dataIndex => <ZoneCell rule={ rules[dataIndex] }/>
      },
    },
    {
      name: 'messageType',
      label: 'Message Type',
      options: {
        customBodyRenderLite: dataIndex => {
          const messageType = rules[dataIndex].message_type;
          if (isNil(messageType)) {
            return 'All';
          }

          return <RabbitMessageTypeName messageTypeId={ messageType }/>;
        },
      },
    },
    {
      name: 'action',
      label: ' ',
      options: {
        customHeadLabelRender: () => <ConnectZoneButton endpoint={ endpoint }/>,
        customBodyRenderLite: dataIndex => <ActionsCell rule={ rules[dataIndex] }/>,
      } as MUIDataTableColumnOptions,
    },
  ];

  return (
    <Paper className={ classes.root } data-testid="rabbit-endpoint-connected-zones">
      <MuiThemeProvider theme={ getMuiTheme({ embeddedTable: true }) }>
        <TableLoadingLayout isLoading={ isLoading }>
          <MUIDataTable
            title={ null }
            data={ rules }
            columns={ columns }
            options={ tableOptions }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>
    </Paper>
  );
};
