import React from 'react';
import { useTheme } from '@nivo/core';
import { DatumValue } from '@nivo/line';

export interface AxisTickProps {
  value: DatumValue;
  format?: (value: DatumValue) => string;
  x: number;
  y: number;
  lineX: number;
  lineY: number;
  textX: number;
  textY: number;
  textBaseline: string;
  textAnchor: string;
  opacity: number;
  rotate: number;
  onClick?: (e: React.MouseEvent, value: DatumValue) => void;
  gStyle?: React.CSSProperties;
}

/**
 * A clone of original AxisTick which supports style customization via props
 * Derived from https://github.com/plouc/nivo/blob/v0.61.1/packages/axes/src/components/AxisTick.js
 */
export const AxisTick = ({
  value: _value,
  x,
  y,
  opacity,
  rotate,
  format,
  lineX,
  lineY,
  onClick,
  textX,
  textY,
  textBaseline,
  textAnchor,
  ...restProps
}: AxisTickProps) => {
  const theme = useTheme();

  let value = _value;
  if (format !== undefined) {
    value = format(value);
  }

  const gStyle: React.CSSProperties = { opacity };
  if (onClick) {
    gStyle['cursor'] = 'pointer';
  }

  return (
    <g
      transform={`translate(${x},${y})`}
      {...(onClick ? { onClick: e => onClick(e, value) } : {})}
      style={{ ...gStyle, ...restProps.gStyle }}
    >
      <line x1={0} x2={lineX} y1={0} y2={lineY} style={theme.axis.ticks.line} />
      <text
        dominantBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY}) rotate(${rotate})`}
        style={theme.axis.ticks.text}
      >
        {value}
      </text>
    </g>
  );
};
