import { isNil } from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Permissions } from 'models/user-management';
import { fetchPermissions } from 'actions/user-management/permissions';
import { getPermissionsById } from 'selectors/user-management';

interface PermissionState {
  isLoading: boolean;
  permission?: Permissions;
}

export function usePermissionSelector(permissionId: number): PermissionState {
  const dispatch = useDispatch();
  const { isLoading, permission } = useSelector((state: RootState) => ({
    isLoading: state.um.permissionsFetching,
    permission: getPermissionsById(permissionId, state),
  }), shallowEqual);

  useDeepCompareEffect(() => {
    if (permission === undefined) {
      dispatch(fetchPermissions());
    }
  }, [dispatch, permission]);

  return {
    isLoading: isLoading,
    permission: isNil(permission) ? undefined : permission,
  };
}
