import React from 'react';
import { useForm, useFormActionLoader } from 'hooks';
import { UpdateEndpoint, TestEndpoint } from 'actions/rabbit';
import { Endpoint } from 'models/rabbit';

// components
import { Box, Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { EndpointForm, EndpointFormField, EndpointFormState } from 'components/Rabbit/EndpointForm/EndpointFormComponent';
// styles
import useStyles from '../style';


interface Props {
  endpoint: Endpoint;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const EditEndpointSettingDialog = ({ endpoint, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const classes = useStyles();
  const { doAction } = useFormActionLoader();
  const form = useForm<EndpointFormState, EndpointFormField>({
    initialState: {
      [EndpointFormField.name]: endpoint.name ?? '',
      [EndpointFormField.host]: endpoint.host ?? '',
      [EndpointFormField.port]: endpoint.port ?? 5672,
      [EndpointFormField.vhost]: endpoint.vhost ?? '',
      [EndpointFormField.exchange]: endpoint.exchange ?? '',
      [EndpointFormField.exchangeDurable]: endpoint.exchange_durable ?? false,
      [EndpointFormField.exchangeType]: endpoint.exchange_type ?? 'DIRECT',
      [EndpointFormField.queue]: endpoint.queue ?? '',
      [EndpointFormField.login]: endpoint.login ?? '',
      [EndpointFormField.password]: endpoint.password ?? '',
      [EndpointFormField.passwordRepeat]: endpoint.password ?? '',
      [EndpointFormField.ssl]: endpoint.ssl ?? false,
    },
    showFields: [
      EndpointFormField.host,
      EndpointFormField.port,
      EndpointFormField.vhost,
      EndpointFormField.exchange,
      EndpointFormField.exchangeDurable,
      EndpointFormField.exchangeType,
      EndpointFormField.queue,
      EndpointFormField.login,
      EndpointFormField.password,
      EndpointFormField.passwordRepeat,
      EndpointFormField.ssl,
    ]
  });

  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: UpdateEndpoint({
        ...(form.state as Endpoint),
        id: endpoint.id,
        name: endpoint.name,
        owner_id: endpoint.owner_id,
      }),
      onSuccess: () => onClose(),
      onError: form.catchError,
    });
  };

  const handleTest = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: TestEndpoint({
        ...(form.state as Endpoint),
        id: endpoint.id,
        name: endpoint.name,
        owner_id: endpoint.owner_id,
      }),
      onError: form.catchError,
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit endpoint > ${ endpoint.name }` }
      content={ <EndpointForm { ...form } /> }
      actions={
        <Box className={ classes.actionRoot }>
          <Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={ handleTest }
            >
              Test
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={ onClose }
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={ handleSave }
              className={ classes.saveBtn }
            >
              Save
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
