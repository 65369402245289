import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteZone } from 'actions/device-management/zones';
import { dispatchAsync } from 'utils/store';

// components
import { Button } from '@material-ui/core';
import { ZoneLabel, ConfirmationDialog } from 'components';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dangerTheme } from 'styles/themes';

interface DeleteZoneProps {
  zoneId: number;
  onSuccess?: () => void;
}

const DeleteZone: React.FC<DeleteZoneProps> = ({ zoneId, onSuccess }) => {
  const dispatch = useDispatch();

  return (
    <ConfirmationDialog
      title={(
        <>
          Are you sure you want to delete zone{' '}
          <strong>
            <ZoneLabel zoneId={zoneId} noLink />
          </strong>?
        </>
      )}
      description={
        'The linked groups and positions will be deleted. ' +
        'The linked devices will be unbound.'
      }
      renderTrigger={modal => (
        <MuiThemeProvider theme={dangerTheme}>
          <Button color="primary" variant="contained" onClick={modal.open}>
            Delete
          </Button>
        </MuiThemeProvider>
      )}
      onConfirm={(modal) => {
        modal.setPending(true);

        dispatchAsync(dispatch, deleteZone(zoneId))
          .then(() => {
            modal.close();
            onSuccess?.();
          })
          .catch(() => modal.setPending(false));
      }}
    />
  );
};

export default DeleteZone;
