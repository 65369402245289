import React from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { dmDevicePath } from 'routing/paths';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface Props {
  deviceId?: string;
  deviceName?: string;
  innerProps?: TypographyProps;
  underline?: LinkProps['underline'];
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

const DeviceLink = ({ deviceId, deviceName, innerProps, underline }: Props): JSX.Element => {
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    deviceId === undefined
      ? <Typography { ...TypoProps }>-</Typography>
      : <Link
        component={ RouterLink }
        underline={ underline }
        color={ innerProps?.color ?? 'secondary' }
        to={ dmDevicePath(deviceId.toUpperCase()) }
        data-testid="device-link"
      >{ deviceName ?? deviceId.toUpperCase() }</Link>
  );
};

export default DeviceLink;
