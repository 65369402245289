import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useUniqueId = (prefix?: string) => {
  const idRef = useRef<string>();

  if (!idRef.current) {
    const id = uuidv4();
    idRef.current = prefix ? `${ prefix }-${ id }` : id;
  }

  return idRef.current;
};
