import React from 'react';
import { getProjectPath } from 'routing/paths';

// components
import Link from '@material-ui/core/Link';
import { CustomLink } from 'components/Links';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface Props {
  projectId?: number;
  name?: string;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

export const ProjectLink = ({ projectId, name, innerProps }: Props): JSX.Element => {
  const TypoProps = { ...defaultProps, ...innerProps };

  return projectId
    ? (
      <Link
        component={ CustomLink }
        color="secondary"
        to={ getProjectPath(projectId) }
        withReturnUrl
      >
        { name ?? projectId }
      </Link>
    )
    : <Typography { ...TypoProps }>-</Typography>;
};
