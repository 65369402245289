import { Validator, Validators, Values, Errors } from './types';

const assignErrorBuilder = (errors: Errors) => (
  validator: Validator,
  fieldName: string,
  fieldValues: Values,
  allFieldValues: Values,
): void => {
  const value = fieldValues[fieldName];
  const errorMessage = validator(value, fieldValues, allFieldValues);

  // TODO: support multiple errors per field
  if (errorMessage && !errors[fieldName]) {
    errors[fieldName] = errorMessage;
  }
};

/**
 * Returns form errors based on the passed form values and validators.
 *
 * TODO: support validation of nested fields e.g. field arrays
 * TODO: support asynchronous validators
 */
export const getErrors = (
  fieldValues: Values,
  fieldValidators: Validators,
  allFieldValues?: Values,
): Errors => {
  const errors: Errors = {};
  const assignError = assignErrorBuilder(errors);

  Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
    if (!validators) {
      return;
    }

    if (!Array.isArray(validators)) {
      validators = [validators];
    }

    validators.forEach((validator) =>
      assignError(validator, fieldName, fieldValues, allFieldValues ?? fieldValues),
    );
  });

  return errors;
};
