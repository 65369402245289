import { groupBy as groupByLodash, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useQueries } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getDeliveryStatusStatistic } from 'clients/http-monitoring';
import { useCustomMemo } from 'hooks';
import {
  CallerRequestDeliveryHistoryParams,
  CallerRequestDeliveryHistoryEntry,
  DeliveryStatus,
} from 'models/caller-monitoring';
import { getResponseData } from 'utils/clients';

import { getDefaultGroupBy, fillGaps } from './utils';

type HistoryByStatus = Record<DeliveryStatus, CallerRequestDeliveryHistoryEntry[]>;
type HistoryByStatusByTemplate = Map<number, HistoryByStatus>;

interface CallerRequestDeliveryHistory {
  isLoading: boolean;
  historyByTemplate: HistoryByStatusByTemplate;
}

export const useCallerRequestDeliveryHistory = ({
  templateIds,
  ...restParams
}: CallerRequestDeliveryHistoryParams): CallerRequestDeliveryHistory => {
  const dispatch = useDispatch();

  const results = useQueries(
    templateIds.map(templateId => {
      const groupBy = restParams.groupBy ?? getDefaultGroupBy(
        restParams.dateFrom,
        restParams.dateTo,
      );
      const params: CallerRequestDeliveryHistoryParams = {
        ...restParams,
        templateIds: [templateId],
        groupBy,
      };

      return {
        queryKey: ['caller/monitoring/requestDeliveryHistory', params],
        queryFn: async (): Promise<HistoryByStatus> => {
          const resData = getResponseData(await getDeliveryStatusStatistic(params));
          const historyEntries = resData.map(event => ({
            // формат даты из бекенда: 2021-03-19T17:00:00.000000+00:00
            // мы ожидаем: 2021-03-19T17:00:00.000Z
            date: new Date(event.timestamp).toJSON(),
            status: event.status,
            count: event.count,
          }));

          const historyEntriesByStatus = groupByLodash(historyEntries, e => e.status);
          const historyEntriesByStatusWithoutGaps: HistoryByStatus = {
            [DeliveryStatus.Error]: [],
            [DeliveryStatus.Failed]: [],
            [DeliveryStatus.Success]: [],
          };

          for (const [statusRaw] of Object.entries(historyEntriesByStatusWithoutGaps)) {
            const status = statusRaw as DeliveryStatus;

            historyEntriesByStatusWithoutGaps[status] = fillGaps(
              historyEntriesByStatus[status] ?? [],
              restParams.dateFrom,
              restParams.dateTo,
              groupBy,
              (date, count) => ({ date, count, status }),
            );
          }

          return historyEntriesByStatusWithoutGaps;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        onError: (e: Error) => {
          dispatch(NotifyError(`Error while fetching request delivery history: ${ e.message }`));
        },
      };
    }),
  );

  const resultsData = results.map(result => result.data);

  const result = useCustomMemo(() => {
    const templatesHistory = resultsData.map((resultData, index) => ({
      templateId: templateIds[index],
      history: resultData as HistoryByStatus | undefined,
    }));

    if (!templatesHistory.every(state => state.history)) {
      return {
        isLoading: true,
        historyByTemplate: new Map(),
      };
    }

    return {
      isLoading: false,
      historyByTemplate: templatesHistory.reduce((acc, state) => {
        acc.set(state.templateId, state.history as HistoryByStatus);
        return acc;
      }, new Map() as HistoryByStatusByTemplate),
    };
  }, [resultsData], isEqual);

  return result;
};
