import { SelectOption } from 'components/Controls/Select';

export enum FormField {
  device = 'device',
  messageType = 'messageType',
  occupancy = 'occupancy',
  tag = 'tag',
  counter = 'counter',
}

export interface FormState {
  [FormField.device]?: string;
  [FormField.messageType]?: MessageType;
  [FormField.occupancy]?: string;
  [FormField.tag]?: string;
  [FormField.counter]?: number;
}

export enum MessageType {
  OCCUPANCY_CHANGE = 'occupancy-change',
  USER_REGISTRATION = 'user-registration',
  CALIBRATION = 'calibration',
  REGULAR_CAR_COUNTER = 'regular',
}

export const PSMessageTypeOptions: SelectOption[] = [
  {
    label: 'Occupancy Status Change',
    value: MessageType.OCCUPANCY_CHANGE,
  },
  {
    label: 'User Registration',
    value: MessageType.USER_REGISTRATION,
  },
  {
    label: 'Calibration',
    value: MessageType.CALIBRATION,
  },
];

export const CCMessageTypeOptions: SelectOption[] = [
  {
    label: 'Regular',
    value: MessageType.REGULAR_CAR_COUNTER,
  },
];