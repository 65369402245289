import React from 'react';

// components
import { Box, Typography } from '@material-ui/core';
import { TruncatedLabel } from 'components/Labels';

// style
import { useStyles, SHIFT } from './TableComponentsStyle';

interface Props {
  role: string;
}


export const Role = ({ role }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" mr={ `${SHIFT}px` }>
      { !role ? <Typography className={ classes.role }>-</Typography> :
        <TruncatedLabel
          label={ role }
          maxLength={ 20 }
          innerProps={{
            className: classes.role
          }}
        />
      }
    </Box>
  );
};
