import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { DeviceColdData } from 'models/device-management';
import { fetchDevicesColdData } from 'actions/device-management/devices';

interface DeviceColdDataState {
  device?: DeviceColdData;
  isLoading: boolean;
}

export function useDeviceColdDataSelector(deviceId: string): DeviceColdDataState {
  const dispatch = useDispatch();
  const { isLoading, device } = useSelector((state: RootState) => ({
    isLoading: state.deviceManagement.devices.devicesColdDataFetching,
    device: state.deviceManagement.devices.devicesColdDataById[deviceId]
  }));

  useEffectOnce(() => {
    if (device === undefined && !isLoading) {
      dispatch(fetchDevicesColdData([deviceId]));
    }
  });

  return {
    device: device === null ? undefined : device,
    isLoading: isLoading
  };
}
