import React from 'react';
import { useAuthUserSelector, useDialog } from 'hooks';
import { Level } from 'models/device-management';
import { isAllowedToCreateLevel } from 'utils/permissions';
import { QueryType, Tree, ZoneNode } from 'hooks/device-management/useTree/types';
// components
import { PlusButton } from 'components/Buttons';
import { AddZoneLevelDialog } from 'components/DeviceManagement';

interface Props {
  tree: Tree;
  node: ZoneNode;
}

export const AddLevelAction = ({ tree, node }: Props): JSX.Element => {
  const addLevelDialog = useDialog();
  const user = useAuthUserSelector();
  if (!isAllowedToCreateLevel(user.data)) {
    return (
      <></>
    );
  }

  const levels = Object.values(node.children.items)
    .reduce((result, levelNode) => {
      levelNode.level && result.push(levelNode.level);
      return result;
    }, [] as Level[]);

  return (
    <>
      <PlusButton label="Add Level" onClick={ addLevelDialog.open }/>
      { addLevelDialog.isMounted && (
        <AddZoneLevelDialog
          zone={ node.zone }
          zoneLevels={ levels }
          isOpen={ addLevelDialog.isOpen }
          onClose={ addLevelDialog.close }
          onCloseEnd={ addLevelDialog.unmount }
          onSuccess={ () => {
            addLevelDialog.close();
            tree.reload(QueryType.level);
          } }
        />
      ) }
    </>
  );
};
