import React, { useState } from 'react';

import { useAuthUserSelector, useFormActionLoader } from 'hooks';
import { Label } from 'models/device-management';
import { KeyCode } from 'utils/dom';

import { useCreateLabel } from './utils';

// components
import { FormControl, Input, InputAdornment } from '@material-ui/core';

import { CommonButton } from 'components/Buttons';

interface AddLabelFormProps {
  ownerId: number;
  onSuccess?: (label: Label) => void;
}

export const AddLabelForm = ({ ownerId, onSuccess }: AddLabelFormProps) => {
  const { isAdmin } = useAuthUserSelector();
  const { doAction } = useFormActionLoader();
  const createLabel = useCreateLabel();

  const [labelText, setLabelText] = useState('');
  const finalLabelText = labelText.trim();

  const [submitting, setSubmitting] = useState(false);

  const submit = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    doAction({
      action: createLabel({
        label: finalLabelText,
        owner_id: isAdmin ? ownerId : undefined,
      }),
      onSuccess: label => {
        onSuccess?.(label);
        setLabelText('');
      },
      onFinally: () => setSubmitting(false),
    });
  };

  const formInvalid = !finalLabelText;
  const submitDisabled = formInvalid || submitting;

  return (
    <FormControl component="form" fullWidth>
      <Input
        value={ labelText }
        placeholder="Enter new label"
        endAdornment={
          <InputAdornment position="end">
            <CommonButton
              type="text"
              label="Add label"
              isDisabled={ submitDisabled }
              onClick={ submit }
            />
          </InputAdornment>
        }
        onChange={ e => setLabelText(e.target.value) }
        onKeyDown={ e => {
          if (!submitDisabled && e.keyCode === KeyCode.ENTER) {
            submit();
          }
        } }
      />
    </FormControl>
  );
};
