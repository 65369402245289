import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import * as models from 'models/device-emulation';
import { SettingsResponseStruct } from 'models/device-emulation';
import { errorHandler, formatQuery } from './utils';

const urls = {
  messageCalibration: (deviceId: string) => `/emulation/device/${ deviceId.toUpperCase() }/message/calibration`,
  messageRegular: (deviceId: string) => `/emulation/device/${ deviceId.toUpperCase() }/message/regular`,
  messageRegistration: (deviceId: string) => `/emulation/device/${ deviceId.toUpperCase() }/message/registration`,
  messageRegularCarCounter: (deviceId: string) => `/emulation/device/${ deviceId.toUpperCase() }/message/regular_cc`,
  deviceSettings: (deviceId: string) => `/emulation/device/${ deviceId.toUpperCase() }/settings`,
  findSettings: '/emulation/devices/settings/find',
};

export const config: ApiGConfig = {
  name: 'device-emulation',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://devemu.prod.api.nwave.io' : 'https://devemu.dev.api.nwave.io',
};

export const sendCalibration = (deviceId: string, request: models.RequestCalibrationBody): Promise<ApiResponse<models.ResponseCalibrationBody>> => {
  return API
    .post(config.name, urls.messageCalibration(deviceId), { body: request })
    .catch(errorHandler);
};

export const sendRegular = (deviceId: string, request: models.RequestRegularBody): Promise<ApiResponse<models.ResponseRegularBody>> => {
  return API
    .post(config.name, urls.messageRegular(deviceId), { body: request })
    .catch(errorHandler);
};

export const sendRegistration = (deviceId: string, request: models.RegistrationResponseStruct): Promise<ApiResponse<models.ResponseRegistrationBody>> => {
  return API
    .post(config.name, urls.messageRegistration(deviceId), { body: request })
    .catch(errorHandler);
};

export const sendRegularCarCounter = (deviceId: string, request: models.RequestRegularCarCounter): Promise<ApiResponse<models.ResponseRegularCarCounter>> => {
  return API
    .post(config.name, urls.messageRegularCarCounter(deviceId), { body: request })
    .catch(errorHandler);
};

export const getDeviceSettings = (deviceId: string): Promise<ApiResponse<models.ResponseSettingsBody>> => {
  return API
    .get(config.name, urls.deviceSettings(deviceId), {})
    .catch(errorHandler);
};

export const updateDeviceSettings = (deviceId: string, settings: models.RequestSettingsBody): Promise<ApiResponse<models.ResponseSettingsBody>> => {
  return API
    .put(config.name, urls.deviceSettings(deviceId), { body: settings })
    .catch(errorHandler);
};

export const findDeviceSettings = (param: models.FindDeviceSettingsParam): Promise<ApiResponse<SettingsResponseStruct[]>> => {
  const queryStringParameters = formatQuery({
    device_id: param.deviceIds,
  });

  return API
    .get(config.name, urls.findSettings, { queryStringParameters })
    .catch(errorHandler);
};
