import React from 'react';
import { FormProps } from 'form';
import { useDeepCompareEffect } from 'react-use';
import { validate } from './validator';
import { FormField, FormState } from './types';

// components
import { TextControl, ZoneSelectControl } from 'components/Controls';

export type LevelFormState = FormState;
export {
  FormField as LevelFormField
};

type Props = FormProps<FormState, FormField>;

export const LevelForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(FormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.name) }
          label="Level name"
          placeholder="Enter name"
          name="name"
          value={ state[FormField.name] ?? '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.name, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.name) ? errors[FormField.name] !== undefined : false }
          helperText={ showErrors.includes(FormField.name) ? errors[FormField.name] : undefined }
        />
      }
      { !showFields.includes(FormField.floor) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.floor) }
          label="Floor number"
          placeholder="Enter floor number"
          name="floor"
          type="number"
          value={ String(state[FormField.floor] ?? '') }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.floor, event.target.value ? parseInt(event.target.value) : undefined);
          } }
          error={ showErrors.includes(FormField.floor) ? errors[FormField.floor] !== undefined : false }
          helperText={ showErrors.includes(FormField.floor) ? errors[FormField.floor] : undefined }
        />
      }
      { !showFields.includes(FormField.zone) ? '' :
        <ZoneSelectControl
          isRequired={ !isView }
          isDisabled={ isView || disabledFields.includes(FormField.zone) }
          selected={ state[FormField.zone] }
          onChange={ (zoneId?: number) => handleChange(FormField.zone, zoneId) }
          error={ showErrors.includes(FormField.zone) ? errors[FormField.zone] : undefined }
        />
      }
    </form>
  );
};
