import { StationEntity } from 'models/base-station';

// components
import { Box, Grid, Typography } from '@material-ui/core';
import { StationCard } from 'components/BaseStation';

interface Props {
  data: StationEntity[];
  isLoading?: boolean;
}

export const CardViewStations = ({ data, isLoading }: Props): JSX.Element => {
  return (
    <Grid container spacing={ 2 } justify="flex-start" alignItems="flex-start">
      { data.length > 0
        ? data.map((station, index) => (
          <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } key={ index }>
            <StationCard isLoading={ isLoading } station={ station } />
          </Grid>
        ))
        : <Box
          width="100%"
          textAlign="center"
          paddingY="8px"
          marginTop="10px"
          bgcolor="#fff"
        >
          <Typography>Sorry, no matching records found</Typography>
        </Box>
      }
    </Grid>
  );
};
