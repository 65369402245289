import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(theme => createStyles({
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelText: {
    fontSize: '16px',
  },
  controls: {
    marginTop: theme.spacing(-0.5),
  },
}));