import moment from 'moment';

export enum SendingStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface GetHistoryParams {
  owner?: number;
  zones?: number[];
  networkIds?: string[];
  groupIds?: number[];
  deviceIds?: string[];
  serviceNames?: string[];
  messageTypes?: string[];
  messageTraces?: string[];
  endpointIds?: number[];
  sendingTimeFrom?: Date;
  sendingTimeTo?: Date;
  sendingStatus?: SendingStatus[]
  limit?: number;
  offset?: number;
}

interface InitialGetHistoryParams extends GetHistoryParams {
  sendingTimeFrom: Date;
}

export const INITIAL_HISTORY_PARAMS: InitialGetHistoryParams = {
  sendingTimeFrom: moment().subtract(1, 'days').toDate(),
  limit: 20,
  offset: 0,
};
