import React from 'react';
import { useOnSplacePopup } from 'hooks/splace-messages';
// components
import { AddCircleOutlined } from '@material-ui/icons';
import { Link, Tooltip } from '@material-ui/core';
// styles
import { green } from '@material-ui/core/colors';

interface Props {
  zoneId?: number;
  levelId?: number;
  groupId?: number;
  positionId?: number;
  deviceId?: string;
}

export const SPlaceLink = ({ zoneId, groupId, positionId, deviceId, levelId }: Props): JSX.Element => {
  const splacePopup = useOnSplacePopup({
    query: {
      zoneId,
      groupId,
      positionId,
      deviceId,
      levelId,
    }
  });

  return (
    <Tooltip title="Bind device">
      <Link
        data-testid="splace-link"
        target="popup"
        href={ splacePopup.href }
        onClick={ splacePopup.open }
      ><AddCircleOutlined style={ { color: green[500] } }/></Link>
    </Tooltip>
  );
};
