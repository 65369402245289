import { DmTreeNode, LoadingStatus, Query, TreeNode } from '../../types';
import { SubtreeManagementOptions } from '../types';

interface FillNodesOptions<TData, TNode> {
  fetchDataByNode: (nodes: TNode[]) => Promise<Record<string, TData | undefined>>;
  getNodes: (nodes: DmTreeNode[]) => TNode[];
  getQuery: (node: TNode) => Query<TData>;
  getSubtree: SubtreeManagementOptions['getSubtree'];
  updateSubtree: SubtreeManagementOptions['updateSubtree'];
}

export async function fillNodes<TData, TNode extends DmTreeNode>({
  fetchDataByNode,
  getNodes,
  getQuery,
  getSubtree,
  updateSubtree,
}: FillNodesOptions<TData, TNode>) {
  const subtree = getSubtree();

  if (!subtree) {
    return;
  }

  const matchingNodes = getNodes(subtree.children);

  if (!matchingNodes.length) {
    return;
  }

  const requestedAt = Date.now();

  try {
    setStatusToNodes({ status: 'loading', getNodes, getQuery, requestedAt, updateSubtree });

    const dataByNodeId = await fetchDataByNode(matchingNodes);
    setDataToNodes({ dataByNodeId, getNodes, getQuery, requestedAt, updateSubtree });
  } catch {
    setStatusToNodes({ status: 'error', getNodes, getQuery, requestedAt, updateSubtree });
  }
}

interface SetStatusToNodesOptions<TNode> {
  status: LoadingStatus;
  requestedAt: number;
  getNodes: (nodes: DmTreeNode[]) => TNode[];
  getQuery: (node: TNode) => Query<unknown>;
  updateSubtree: SubtreeManagementOptions['updateSubtree'];
}

function setStatusToNodes<TNode>({
  status,
  requestedAt,
  getNodes,
  getQuery,
  updateSubtree,
}: SetStatusToNodesOptions<TNode>) {
  updateSubtree((tree) => {
    const nodes = getNodes(tree.children);

    nodes.forEach((node) => {
      const query = getQuery(node);

      if (query.requestedAt && query.requestedAt > requestedAt) {
        return;
      }

      query.status = status;
    });
  });
}

interface SetDataToNodesOptions<TData, TNode> {
  dataByNodeId: Record<string, TData | undefined>;
  requestedAt: number;
  getNodes: (nodes: DmTreeNode[]) => TNode[];
  getQuery: (node: TNode) => Query<TData>;
  updateSubtree: SubtreeManagementOptions['updateSubtree'];
}

function setDataToNodes<TData, TNode extends TreeNode<unknown>>({
  dataByNodeId,
  requestedAt,
  getNodes,
  getQuery,
  updateSubtree,
}: SetDataToNodesOptions<TData, TNode>) {
  updateSubtree((tree) => {
    const nodes = getNodes(tree.children);

    nodes.forEach((node) => {
      const query = getQuery(node);

      if (query.dataRequestedAt && query.dataRequestedAt > requestedAt) {
        return;
      }

      query.data = dataByNodeId[node.id];
      query.status = 'success';
      query.dataRequestedAt = requestedAt;
    });
  });
}
