import moment from 'moment';
import {
  Action,
  FETCH_ALL_STAT_DM_START,
  FETCH_ALL_STAT_DM_SUCCESS,
  FETCH_ALL_STAT_DM_FAILED,
  FETCH_STAT_CONNECTIVITY_SUCCESS,
  FETCH_STAT_FAILURES_SUCCESS,
  StatisticRawMessage,
  GetStatParams,
  INIT_ALL_STAT_DM_START
} from 'actions/device-statistic';
import { ConnectivityChartData } from 'models/connectivity';
import { ReplacementLogByDate, DamageLogByDate } from 'models/device-management';
import { IncidentHistory } from 'models/device-monitoring/incidentHistory';
import { HistoryStatistic } from 'models/rabbit';
import { initStatFilters, Fields } from 'models/charts';
import { getUrlItems, UrlItems } from 'utils/routing/query';
import { parseArrayAsNum } from 'helpers';

export interface State {
  replacement: {
    log: ReplacementLogByDate[];
    total: number;
  };
  incidents: {
    log: IncidentHistory[];
    total: number;
  };
  damage: {
    log: DamageLogByDate[];
    total: number;
  };
  silents: {
    log: IncidentHistory[];
    total: number;
  };
  connectivity: {
    log: ConnectivityChartData[];
    total: number;
  };
  rawMessages: {
    log: StatisticRawMessage[];
  };
  rmq: {
    log: HistoryStatistic[];
  };
  httpCaller: {
    log: HistoryStatistic[];
  };
  filters: GetStatParams;
  installed: number;
  empty: number;
  isFetching?: boolean;
}

function getFiltersFromUrl(urlState: UrlItems): GetStatParams {
  return {
    zones: urlState[Fields.zones] ? parseArrayAsNum(urlState[Fields.zones]) : initStatFilters.zones,
    owner: urlState[Fields.owner] ? Number(urlState[Fields.owner]) : initStatFilters.owner,
    devices: urlState[Fields.devices] || initStatFilters.devices,
    groups: urlState[Fields.groups] ? parseArrayAsNum(urlState[Fields.groups]) : initStatFilters.groups,
    timeFrom: urlState[Fields.timeFrom] ? moment(new Date(urlState[Fields.timeFrom])) : initStatFilters.timeFrom,
    timeTo: urlState[Fields.timeTo] ? moment(new Date(urlState[Fields.timeTo])) : initStatFilters.timeTo,
    projects: urlState[Fields.projects] ? parseArrayAsNum(urlState[Fields.projects]) : initStatFilters.projects,
  };
}

const initState: State = {
  replacement: {
    log: [],
    total: 0,
  },
  incidents: {
    log: [],
    total: 0,
  },
  damage: {
    log: [],
    total: 0,
  },
  silents: {
    log: [],
    total: 0
  },
  connectivity: {
    total: 0,
    log: []
  },
  rawMessages: {
    log: []
  },
  rmq: {
    log: []
  },
  httpCaller: {
    log: []
  },
  installed: 0,
  empty: 0,
  filters: getFiltersFromUrl(getUrlItems(Object.values(Fields))),
  isFetching: true
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_ALL_STAT_DM_START:
      return {
        ...state,
        isFetching: !action.quiet,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case FETCH_ALL_STAT_DM_SUCCESS:
      return {
        ...state,
        installed: action.payload.metrics?.devices_installed || 0,
        replacement: {
          total: action.payload.metrics?.devices_replacements || 0,
          log: action.payload.metrics?.replacement_log_by_date || [],
        },
        damage: {
          log: action.payload.metrics?.damage_log_by_date || [],
          total: action.payload.metrics?.devices_damaged_installed || 0
        },
        empty: action.payload.metrics?.positions_empty || 0,
        isFetching: false
      };
    case FETCH_ALL_STAT_DM_FAILED:
      return {
        ...initState,
        filters: state.filters,
        isFetching: false,
      };
    case FETCH_STAT_CONNECTIVITY_SUCCESS:
      return {
        ...state,
        connectivity: {
          ...state.connectivity,
          total: action.connectivity,
          log: action.log,
        }
      };
    case FETCH_STAT_FAILURES_SUCCESS:
      return {
        ...state,
        silents: {
          total: action.total.silent_ok || 0,
          log: action.log.silent_history || []
        },
        incidents: {
          total: action.total.total_failures || 0,
          log: action.log.failures_history || [],
        }
      };
    case INIT_ALL_STAT_DM_START:
      return {
        ...state,
        isFetching: true,
        filters: {
          ...state.filters,
          ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
        }
      };
    default:
      return state;
  }
};
