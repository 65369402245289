import { put, takeEvery, call, all } from 'redux-saga/effects';

import { HistoryStatistic } from 'models/rabbit';
import { Statistic as HttpStatistic } from 'models/caller';
import { ConnectivityChartData } from 'models/connectivity';
import { getStatistic } from 'clients/rabbit';
import { getStatistic as getHttpStatistic } from 'clients/http-calls';

import { ApiResponse, ReasonEnum } from 'models';
import { NotifyError } from 'actions/notifier';
import { fetchZonesOverallHistory } from 'clients/connectivity';
import {
  FETCH_MAIN_PAGE_STATISTIC,
  FETCH_STAT_HTTTPCALLS_START,
  DoGetStatHTTTPCallsSuccess,
  DoGetStatHTTTPCallsFailed,
  DoGetStatConnectivitySuccess,
  DoGetStatConnectivityFailed,
  DoGetRawOnlineFailed,
  DoGetRawOnlineSuccess,
  DoGetStatisticRawMessagesFailed,
  DoGetStatisticRawMessagesSuccess,
  DoGetStatRMQsenderSuccess,
  DoGetStatRMQsenderFailed,
} from 'actions/main-statistic';
import { getOnline, getRawMsgStatistic } from 'clients/raw-messages';


function* fetchConnectivityStatistic() {
  const response: ApiResponse<ConnectivityChartData[]> = yield call(fetchZonesOverallHistory, {});

  if (response.reason === ReasonEnum.Ok) {
    yield put(DoGetStatConnectivitySuccess(response.data || []));
  } else {
    yield put(NotifyError(`Error while fetching Connectivity Statistic: ${response.message || 'Server Error'}`));
    yield put(DoGetStatConnectivityFailed());
  }
}

function* fetchRawMessagesStat() {
  const response: ApiResponse = yield call(getRawMsgStatistic);

  if (response.reason === ReasonEnum.Ok) {
    yield put(DoGetStatisticRawMessagesSuccess(response.data));
  } else {
    yield put(NotifyError(`Error while fetching Raw Messages Statistic: ${response.message || 'Server Error'}`));
    yield put(DoGetStatisticRawMessagesFailed());
  }
}

function* fetchRawOnline() {
  const response: ApiResponse = yield call(getOnline);

  if (response.reason === ReasonEnum.Ok) {
    yield put(DoGetRawOnlineSuccess(response.data));
  } else {
    yield put(NotifyError(`Error while fetching Devices Online: ${response.message || 'Server Error'}`));
    yield put(DoGetRawOnlineFailed());
  }
}

function* fetchRabbitStatistic() {
  const response: ApiResponse = yield call(getStatistic);

  if (response.reason === ReasonEnum.Ok) {
    yield put(DoGetStatRMQsenderSuccess(response.data as HistoryStatistic[]));
  } else {
    yield put(NotifyError(`Error while fetching RMQ Sender Statistic: ${response.message || 'Server Error'}`));
    yield put(DoGetStatRMQsenderFailed());
  }
}

function* fetchHttpStatistic() {
  const response: ApiResponse = yield call(getHttpStatistic);

  if (response.reason === ReasonEnum.Ok) {
    yield put(DoGetStatHTTTPCallsSuccess(response.data as HttpStatistic[]));
  } else {
    yield put(NotifyError(`Error while fetching Calls Statistic: ${response.message || 'Server Error'}`));
    yield put(DoGetStatHTTTPCallsFailed());
  }
}

function* init() {
  yield all([
    call(fetchRabbitStatistic),
    call(fetchConnectivityStatistic),
    call(fetchRawMessagesStat),
    call(fetchRawOnline),
    call(fetchHttpStatistic),
  ]);
}

export const mainStatisticSagas = [
  takeEvery(FETCH_MAIN_PAGE_STATISTIC, init),
  takeEvery(FETCH_STAT_HTTTPCALLS_START, fetchHttpStatistic),
];
