import React, { useMemo } from 'react';

import { Zone } from 'models/device-management';
import { Defined } from 'utils/models';
import { PositionsTabId } from 'pages/DeviceManagement/Positions/utils';

// components
import { Box, Grid, Paper } from '@material-ui/core';
import PositionsPage from 'pages/DeviceManagement/Positions/PositionsPage';
import PositionsPageLink from './PositionsPageLink';

// styles
import useZonePageStyles from './style';

type Filters = React.ComponentProps<typeof PositionsPage>['filters'];

interface MapTabProps {
  zoneId: Defined<Zone['id']>;
}

const MapTab: React.FC<MapTabProps> = ({ zoneId }) => {
  const zoneCss = useZonePageStyles();
  const filters = useMemo((): Filters => ({ zones: [zoneId] }), [zoneId]);

  return (
    <Paper>
      <Grid container justify="flex-end" spacing={0}>
        <Box m={1}>
          <PositionsPageLink filters={filters} />
        </Box>
      </Grid>

      <PositionsPage
        filters={filters}
        filtersHidden
        tabsHidden
        filtersInUrlHidden
        tabContentClassName={zoneCss.positionsBlockTabContent}
        activeTabId={PositionsTabId.MAP}
      />
    </Paper>
  );
};

export default MapTab;
