import * as actions from 'actions/lorawan';
import { IntegrationData } from 'models/lorawan';
import { setupIntegrations } from 'utils/lorawan';


export interface State {
  integrations: IntegrationData[];
  fetched: boolean;
}

const initState: State = {
  integrations: [],
  fetched: false,
};

export const reducer = (state: State = initState, action: actions.Action): State => {
  switch (action.type) {
    case actions.GET_STATUS_SUCCESS:
      return {
        ...state,
        fetched: true,
        integrations: setupIntegrations(
          action.lastTimeReceive,
          action.apiKey,
          action.filters,
          action.isAdmin
        )
      };
    case actions.GET_STATUS:
      return { ...state, fetched: false };
    default:
      return state;
  }
};
