import { FormErrors } from 'form';
import { BoxFormField, Box, BoxCreateBody } from 'models/provisioning';
import { isNil } from 'lodash';

export const validate = (box: Box | BoxCreateBody, fields: BoxFormField[]): FormErrors<BoxFormField> => {
  const errors: FormErrors<BoxFormField> = {};
  if (fields.includes(BoxFormField.id)) {
    if (!(box as Box).id) {
      errors.id = 'Box id is required';
    }
  }

  if (fields.includes(BoxFormField.owner_id)) {
    if (isNil(box.owner_id)) {
      errors.owner_id = 'Owner is required';
    }
  }

  return errors;
};
