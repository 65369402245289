import React from 'react';
// components
import { Box, Link, Typography } from '@material-ui/core';

interface Props {
  onAdd: () => void;
}

export const DevicesNotFound = ({ onAdd }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingTop="10vh"
    >
      <Typography variant="h6" align="center">
        You have no virtual devices yet.
        Click <Link href="#" onClick={ onAdd }>Add</Link> to add a new one
      </Typography>
    </Box>
  );
};
