// FIXME: remove `any` (BNIV-195)
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export type AnyChangeHandler = (...args: any[]) => void;

export type ErrorMessage = React.ReactNode;

/**
 * Props which component (Input) receives when it's wrapped with `createField`.
 */
// FIXME: remove `any` (BNIV-195)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface InputProps<TValue = any> {
  errors: ErrorMessage[];
  onBlur: () => void;
  onChange: (value: TValue) => void;
  value: TValue;
}

const isInvalid = (errors: ErrorMessage[]) => errors.length > 0;

/**
 * Transforms errors provided by the `createField` wrapper to a representation
 * which most Nwave's input components understand and indicate a valid or invalid state.
 *
 * @returns If field is valid: `undefined`. Otherwise: a multi-line text
 * where each line represents an individual field error.
 */
export const formatErrors = (errors: ErrorMessage[]): React.ReactNode | undefined => {
  if (!isInvalid(errors)) {
    return;
  }

  const [firstError, ...restErrors] = errors;

  return (
    <React.Fragment>
      {firstError}
      {restErrors.map((e, i) => (
        <React.Fragment key={i}>
          <br />{e}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

/**
 * Transforms `InputProps` to error props which are common
 * for Material UI's field components.
 */
export const getMuiErrorProps = (errors: ErrorMessage[]) => ({
  error: isInvalid(errors),
  helperText: formatErrors(errors),
});


export type SelectorInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  selected: any;
  changeHandler: AnyChangeHandler;
}

/**
 * Transforms `InputProps` to props which are common
 * for most selector input components at Nwave.
 */
export const getSelectProps = ({ value, onChange }: InputProps) => ({
  selected: value,
  changeHandler: onChange,
});
