import React from 'react';
import { nameof } from 'utils/type-checking';


// components
import FormGroup from '@material-ui/core/FormGroup';
import * as Fields from 'components/Form/Field';

// styles
import { useStyles } from 'styles/forms';
import { useStyles as projectsStyles } from './ProjectsCreate/ProjectsCreateStyles';

export interface FormValues {
  name: string;
  timezone?: string;
  owner_id?: number;
}

interface Props {
  isAdmin: boolean;
  allowed: boolean;
}
export const ProjectForm = ({ isAdmin, allowed }: Props): JSX.Element => {
  const classes = {
    ...useStyles(),
    ...projectsStyles()
  };

  return (
    <FormGroup>
      <Fields.Text
        className={ classes.field }
        label="Project Name"
        name={ 'name' }
        disabled={ !allowed }
        required
      />
      <div className={ classes.field }>
        <Fields.SelectTimezone
          label="Timezone"
          name={ nameof<FormValues>('timezone') }
          isDisabled={ !allowed }
          isSearchable
          isClearable
        />
      </div>
      { isAdmin &&
        <div className={ classes.field }>
          <Fields.SelectOwner
            name={ 'owner_id' }
            isDisabled={ !allowed }
            isRequired
          />
        </div>
      }
    </FormGroup>
  );
};
