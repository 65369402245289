import React from 'react';
import { RouteConfig } from 'react-router-config';
import { isNil } from 'lodash';
import { getClientApiKeyDetailPath, getClientDetailPath, getClientsListPath } from 'routing/paths';
import { UserManagementApiKeyDetailPage, UserManagementClientDetailPage, UserManagementClientListPage } from 'pages/UserManagement';
import { ApiKeyName, OwnerName } from 'components/Labels';

import { canViewApiKey, canViewUserGroup, canViewUserGroupList } from 'utils/permissions';
import { State as UserState } from 'reducers/user-profile';
import { UrlItems } from 'utils/routing/query';
import { isUUID } from 'helpers';

const CLIENT_ID_PARAM_NAME = 'clientId';
const API_KEY_ID_PARAM_NAME = 'apiKeyId';

function getClientId(params: UrlItems): false | number {
  if (isNil(params[CLIENT_ID_PARAM_NAME])) {
    return false;
  }

  const clientId = Number(params[CLIENT_ID_PARAM_NAME]);
  if (isNaN(clientId)) {
    return false;
  }

  return clientId;
}

function getApiKeyId(params: UrlItems): false | string {
  if (isNil(params[API_KEY_ID_PARAM_NAME])) {
    return false;
  }

  const apiKeyId = String(params[API_KEY_ID_PARAM_NAME]);
  if (!isUUID(apiKeyId)) {
    return false;
  }

  return apiKeyId;
}

const getClientListRoute = (userData: UserState): RouteConfig => ({
  path: getClientsListPath(),
  label: 'Clients',
  icon: 'users',
  hiddenOnMainMenu: !canViewUserGroupList(userData),
  isShowByUrl: canViewUserGroupList(userData),
  render: options => options.match?.isExact ? <UserManagementClientListPage/> : undefined,
});

const getClientDetailRoute = (userData: UserState): RouteConfig => ({
  path: getClientDetailPath(`:${ CLIENT_ID_PARAM_NAME }`),
  hiddenOnMainMenu: true,
  isShowByUrl: canViewUserGroup(userData),
  label: ({ match }) => {
    const clientId = getClientId(match?.params ?? {});
    if (!clientId) {
      return false;
    }

    return <OwnerName ownerId={ clientId }/>;
  },
  render: ({ match }) => {
    const clientId = getClientId(match?.params ?? {});
    if (!match?.isExact || !clientId) {
      return false;
    }

    return <UserManagementClientDetailPage clientId={ clientId }/>;
  },
});

const getApiKeyDetailRoute = (userData: UserState): RouteConfig => ({
  path: getClientApiKeyDetailPath(`:${ CLIENT_ID_PARAM_NAME }`, `:${ API_KEY_ID_PARAM_NAME }`),
  hiddenOnMainMenu: true,
  isShowByUrl: canViewApiKey(userData),
  label: ({ match }) => {
    const apiKeyId = getApiKeyId(match?.params ?? {});
    if (!apiKeyId) {
      return false;
    }

    return (
      <ApiKeyName apiKeyId={ apiKeyId }/>
    );
  },
  render: ({ match }) => {
    const clientId = getClientId(match?.params ?? {});
    const apiKeyId = getApiKeyId(match?.params ?? {});
    if (!match?.isExact || !clientId || !apiKeyId) {
      return false;
    }

    return (
      <UserManagementApiKeyDetailPage
        apiKeyId={ apiKeyId }
        clientId={ clientId }
      />
    );
  },
});

export function getUserManagementClientRoutes(userData: UserState): RouteConfig[] {
  return [
    {
      ...getClientListRoute(userData),
      routes: [
        {
          ...getClientDetailRoute(userData),
          routes: [
            {
              ...getApiKeyDetailRoute(userData)
            },
          ]
        },
      ]
    }
  ];
}
