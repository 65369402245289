import { createStyles, makeStyles } from 'styles/utils';

export const useStyles = makeStyles(() =>
  createStyles({
    formDialogPaper: {
      overflow: 'visible',
    },
    formDialogContent: {
      overflow: 'visible',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
