export enum MarkerType {
  OCCUPANCY,
  DURATION,
}

export enum MarkerColor {
  blue,
  green,
  yellow,
  red,
}
