import { EnumDeviceStateFields as Fields, initDevicesStatesFilters, DevicesStatesFiltersFields } from 'models';
import { DeviceStatesV2 } from 'models/device-monitoring';
import { Device } from 'models/device-monitoring/device';
import {
  Action,
  FETCH_DEVICES_STATES_SUCCESS,
  FETCH_DEVICES_STATES_FAILED,
  SET_DEVICES_STATES_FILTERS,
  DEVICE_STATES_FETCH_REPORT_CSV,
  DEVICE_STATES_FETCH_REPORT_CSV_END,
  FETCH_DEVICES_STATES_START_FILTERS
} from 'actions/devices-states';
import { getUrlItems, UrlItems } from 'utils/routing/query';

export interface State {
  data: DeviceStatesV2[];
  isFetching: boolean;
  csvFetching: boolean;
  filters: DevicesStatesFiltersFields;
  total: number;

  readonly devicesById: {
    readonly [deviceId: string]: Device | null | undefined;
  };
}

function getFiltersFromUrl(urlState: UrlItems): DevicesStatesFiltersFields {
  return {
    zones: urlState[Fields.zones] ? [].concat(urlState[Fields.zones]).map(z => Number(z)) : [],
    groups: urlState[Fields.groups] ? [].concat(urlState[Fields.groups]).map(g => Number(g)) : [],
    devices: urlState[Fields.devices] ? [].concat(urlState[Fields.devices]) : [],
    incidentTypes: urlState[Fields.incidentTypes] ? [].concat(urlState[Fields.incidentTypes]) : [],
    severity: urlState[Fields.severity] ? [].concat(urlState[Fields.severity]) : initDevicesStatesFilters.severity,
    projects: urlState[Fields.projects] ? [].concat(urlState[Fields.projects]) : [],
    owner: urlState[Fields.owner] ? Number(urlState[Fields.owner]) : undefined,
    reason: urlState[Fields.reason] ? urlState[Fields.reason] : undefined,
  };
}

export const initState: State = {
  data: [],
  total: 0,
  isFetching: false,
  csvFetching: false,
  filters: getFiltersFromUrl(getUrlItems(Object.values(Fields))),

  devicesById: {},
};

export const reducer = (state: State = initState, action: Action) => {
  switch (action.type) {
    case FETCH_DEVICES_STATES_FAILED:
      return { ...state, isFetching: false, data: [] };
    case FETCH_DEVICES_STATES_SUCCESS:
      return { ...state, isFetching: false, data: action.payload, total: action.total };
    case FETCH_DEVICES_STATES_START_FILTERS:
      return { ...state, isFetching: true };
    case SET_DEVICES_STATES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case DEVICE_STATES_FETCH_REPORT_CSV:
      return { ...state, csvFetching: true };
    case DEVICE_STATES_FETCH_REPORT_CSV_END:
      return { ...state, csvFetching: false };
    default:
      return state;
  }
};
