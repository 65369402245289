import { combineReducers } from 'redux';

import * as stationFlash from './station-flash';
import * as stationStatus from './station-status';
import * as location from './location';
import * as responsiblePerson from './responsible-person';

export interface State {
  stationFlash: stationFlash.State;
  stationStatus: stationStatus.State;
  location: location.State;
  responsiblePerson: responsiblePerson.State;
}

export const reducer = combineReducers<State>({
  stationFlash: stationFlash.reducer,
  stationStatus: stationStatus.reducer,
  location: location.reducer,
  responsiblePerson: responsiblePerson.reducer,
});
