import { StationStatusFlat, StationStatusTitle } from 'models/base-station-status';
// components
import { CommonStatus, Props as CommonStatusProps  } from './CommonStatus';

interface Props {
  stationStatus?: StationStatusFlat;
  variant?: CommonStatusProps['variant'];
}

export const OnlineStatus = ({ stationStatus, variant }: Props) => {
  const label = stationStatus?.isOffline === undefined
    ? StationStatusTitle.Offline
    : (stationStatus?.isOffline ? StationStatusTitle.Offline : StationStatusTitle.Online);

  const color: CommonStatusProps['color'] = stationStatus?.isOffline === undefined
    ? 'unknown'
    : (stationStatus.isOffline ? 'error' : 'ok');

  return (
    <CommonStatus
      label={ label }
      duration={ stationStatus?.offlineChanged }
      color={ color }
      variant={ variant }
    />
  );
};
