import { getUrlItems, UrlItems } from 'utils/routing/query';

import {
  Box,
  BoxesFiltersFields as Fields,
  ProvisioningBoxesFilters
} from 'models/provisioning';
import {
  Action,
  FETCH_BOXES_FAILURE,
  FETCH_BOXES,
  FETCH_BOXES_SUCCESS,
  SET_BOXES_FILTERS,
  FETCH_BOX_BY_ID,
  FETCH_BOX_BY_ID_FAILURE,
  BOX_DELETE_SUCCESS,
  BOX_UPDATE_FAILURE,
  FETCH_BOX_BY_ID_SUCCESS,
  BOX_UPDATE_SUCCESS,
  ADD_BOXES_TO_SHIPMENT_FAILURE,
  REMOVE_BOX_FROM_SHIPMENT_FAILURE,
  ADD_BOXES_TO_SHIPMENT_SUCCESS,
  REMOVE_BOX_FROM_SHIPMENT_SUCCESS,
  ADD_BOXES_TO_SHIPMENT,
  REMOVE_BOX_FROM_SHIPMENT,
  FETCH_BOXES_BY_FILTERS,
  BOX_CREATE,
  BOX_CREATE_FAILURE,
  BOX_CREATE_SUCCESS,
  BOX_CREATE_BULK,
  BOX_CREATE_BULK_FAILURE,
  BOX_CREATE_BULK_SUCCESS,
  UPDATE_BOXES_FILTERS_FROM_URL,
} from 'actions/provisioning';

export interface State {
  boxes: Box[];
  total: number;
  isFetching: boolean;
  isDeleting: boolean;
  filters: ProvisioningBoxesFilters;
}

function getFiltersFromUrl(urlState: UrlItems): ProvisioningBoxesFilters {
  return {
    [Fields.device_id]: urlState[Fields.device_id] ? [].concat(urlState[Fields.device_id]) : [],
    [Fields.box_id]: urlState[Fields.box_id] ? [].concat(urlState[Fields.box_id]).map(b => Number(b)) : [],
    [Fields.shipment_id]: urlState[Fields.shipment_id] ? [].concat(urlState[Fields.shipment_id]) : [],
    [Fields.allowed_for_shipment_only]: urlState[Fields.allowed_for_shipment_only] !== undefined ? !!urlState[Fields.allowed_for_shipment_only] : undefined,
    [Fields.empty]: urlState[Fields.empty] !== undefined ? !!urlState[Fields.empty] : null,
    [Fields.owner]: urlState[Fields.owner] ? [].concat(urlState[Fields.owner]).map(b => Number(b)) : [],
  };
}

export const initState: State = {
  boxes: [],
  isFetching: false,
  isDeleting: false,
  filters: {
    ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
  },
  total: 0
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_BOXES:
    case FETCH_BOX_BY_ID:
    case ADD_BOXES_TO_SHIPMENT:
    case FETCH_BOXES_BY_FILTERS:
    case BOX_CREATE:
    case BOX_CREATE_BULK:
      return { ...state, isFetching: true };
    case REMOVE_BOX_FROM_SHIPMENT:
      return { ...state, isDeleting: true };
    case FETCH_BOXES_FAILURE:
      return { ...state, isFetching: false, boxes: [] };
    case FETCH_BOX_BY_ID_FAILURE:
    case BOX_DELETE_SUCCESS:
    case BOX_UPDATE_FAILURE:
    case BOX_CREATE_FAILURE:
    case BOX_CREATE_BULK_FAILURE:
      return { ...state, isFetching: false };
    case REMOVE_BOX_FROM_SHIPMENT_FAILURE:
      return { ...state, isDeleting: false };
    case FETCH_BOXES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        boxes: action.boxes,
        total: action.total,
      };
    case SET_BOXES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case FETCH_BOX_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        boxes: [action.box]
      };
    case BOX_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        boxes: [action.box]
      };
    case ADD_BOXES_TO_SHIPMENT_SUCCESS:
    case ADD_BOXES_TO_SHIPMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        boxes: [
          ...state.boxes,
          ...action.addedBoxes,
        ]
      };
    case REMOVE_BOX_FROM_SHIPMENT_SUCCESS:
      const boxes = state.boxes.filter(box => box.id !== action.removedBoxId);
      return {
        ...state,
        isDeleting: false,
        boxes,
      };
    case BOX_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        boxes: [action.box, ...state.boxes]
      };
    case BOX_CREATE_BULK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        boxes: [...action.boxes, ...state.boxes]
      };
    case UPDATE_BOXES_FILTERS_FROM_URL:
      return {
        ...state,
        filters: {
          ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
        }
      };
    default:
      return state;
  }
};
