import * as Actions from 'actions/caller';
import { TemplateSuite, Template, GetRoutersParams, Router } from 'models/caller';

export type TemplateRouteSuite = TemplateSuite & {
  routes: Router[];
  templates: Template[];
};

export interface State {
  isFetched?: boolean;
  params: GetRoutersParams;
  data: TemplateRouteSuite[];
}

const initState: State = {
  isFetched: undefined,
  params: {},
  data: [],
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS:
      return { ...state, isFetched: false };
    case Actions.ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS_STOP:
      const data = action.suites.reduce((prev, curr) => {
        const routes = action.routes.filter(route => route.template_suite_id === curr.template_suite_id);
        const templates = action.templates.filter(tmpl => tmpl.template_suite_id === curr.template_suite_id);

        prev.push({
          ...curr,
          routes,
          templates,
        });

        return prev;
      }, [] as TemplateRouteSuite[]);

      return {
        ...state,
        isFetched: true,
        data,
      };
    default:
      return state;
  }
};
