import { Address, Position } from 'components/BaseStation/types';
import { MapState } from 'utils/map';

export const getDefaultPosition = (pos?: Position): Position => ({
  lat: pos?.lat || 51.5073509, // center of London
  lon: pos?.lon || -0.1277583, // center of London
});

function getAddressByGeocoderResult(results: google.maps.GeocoderResult[]): Address | undefined {
  const result = results.shift();
  if (!result) {
    return undefined;
  }

  return {
    id: result.place_id,
    title: result.formatted_address,
    lat: result.geometry.location.lat(),
    lon: result.geometry.location.lng(),
    country: result.address_components
      .find(c => c.types.includes('country'))?.long_name,
    city: result.address_components
      .find(c => c.types.includes('postal_town') || c.types.includes('locality'))?.long_name,
    street: result.address_components
      .find(c => c.types.includes('route'))?.long_name,
    building: result.address_components
      .find(c => c.types.includes('street_number'))?.long_name,
    postcode: result.address_components
      .find(c => c.types.includes('postal_code'))?.long_name,
  };
}

export function findAddressByLocation(state: MapState, lat: number, lon: number, onSuccess: (result?: Address) => void) {
  const geocoder = new state.google.maps.Geocoder();
  geocoder.geocode({ location: { lat: lat, lng: lon } }, (results, status) => {
    if (status !== 'OK') {
      onSuccess(undefined);
      return;
    }

    const address = getAddressByGeocoderResult(results.filter(r =>
      r.geometry.location_type === google.maps.GeocoderLocationType.ROOFTOP
      && (r.types.includes('street_address') || r.types.includes('premise'))
    ));
    onSuccess(address);
  });
}

export function findAddressByAddress(state: MapState, address: string, onSuccess: (result?: Address) => void) {
  const geocoder = new state.google.maps.Geocoder();
  geocoder.geocode({ address: address }, (results, status) => {
    if (status !== 'OK') {
      onSuccess(undefined);
      return;
    }

    const address = getAddressByGeocoderResult(results);
    onSuccess(address);
  });
}

export function setMapCenterByAddress(state: MapState, addresses: Address[]) {
  if (!addresses.length) {
    return;
  }

  const bounds = new state.google.maps.LatLngBounds();
  addresses.forEach(a => bounds.extend({ lat: a.lat, lng: a.lon }));
  state.map.setCenter(bounds.getCenter());
}
