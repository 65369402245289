import React from 'react';
import { MessageType } from 'models/caller';
import { useCallerMessageTypes } from 'hooks/caller';
// components
import {
  CommonProps,
  MultiProps,
  Option,
  SelectControl,
  SingleProps
} from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (messageType: MessageType) => boolean }

const Component = (props: Props): JSX.Element => {
  const messageTypesState = useCallerMessageTypes();
  const messageTypes = messageTypesState.messageTypes ?? [];

  const filterCallback = props.filter ?? (() => (true));

  const options: Option<string>[] =
    messageTypes
      .filter(filterCallback)
      .map(messageType => ({
        value: messageType.id,
        label: messageType.name ?? String(messageType.id),
      }));

  return <SelectControl
    name="messageType"
    label="Message type"
    { ...props }
    options={ options }
    isLoading={ messageTypesState.isLoading }
    checkSelected={ messageTypes.length > 0 }
  />;
};

export {
  Component as CallerMessageTypesSelectControl
};
