import { createStyles, makeStyles } from 'styles/utils';
import { colors } from 'styles/themes/outer';

const useStyles = makeStyles(() =>
  createStyles({
    gridRoot: {
      flexGrow: 1,
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%',
    },
    gridEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    gridCell: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    fullWidth: {
      width: '100%'
    },
    formInput: {
      margin: 0,
      flex: 1,
    },
    buttonText: {
      color: colors.backgroundPaper
    }
  })
);

export default useStyles;
