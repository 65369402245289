import { useAuthUserSelector } from 'hooks';
import { useForm } from 'hooks/form';
import { FormProps } from 'types/form';
import { TemplateSuite } from 'models/caller';
import { CreateTemplateSuite } from 'actions/caller';
import { ActionLoaderProps } from 'components/Loaders/ActionLoader';
// components
import { TemplateSuiteFormField } from '../TemplateSuiteForm/CallerTemplateSuiteForm';
import { getCallerTemplateSuiteDetailPath } from 'routing/paths';


interface PropsAction {
  doAction: (props: ActionLoaderProps) => void;
  templateSuite?: TemplateSuite;
  onSuccess?: () => void;
  getSubmitAction?: (suite: TemplateSuite) => ActionLoaderProps['action'];
}

interface FormState<StateType, FieldEnum extends string> {
  form: FormProps<StateType, FieldEnum>;
  onSubmit: () => boolean;
  onReset: () => void;
}

export const useCreateForm = ({ doAction, templateSuite, getSubmitAction, onSuccess }: PropsAction): FormState<TemplateSuite, TemplateSuiteFormField> => {
  const user = useAuthUserSelector();
  const form = useForm<TemplateSuite, TemplateSuiteFormField>({
    initialState: templateSuite ?? {
      template_suite_id: 0,
      name: '',
      owner_id: Number(user.data.user_group) || 0
    },
    showFields: [
      ...(user.isAdmin ? [TemplateSuiteFormField.owner_id] : []),
      TemplateSuiteFormField.name
    ]
  });

  const handleSubmit = () => {
    if (form.validate()) {
      doAction({
        action: getSubmitAction?.(form.state) ?? CreateTemplateSuite(form.state),
        onSuccess: (result) => {
          onSuccess?.();
          return {
            push: true,
            to: getCallerTemplateSuiteDetailPath(result.template_suite_id),
          };
        },
        onError: form.catchError,
      });
    }

    return true;
  };

  return {
    form,
    onReset: form.reset,
    onSubmit: handleSubmit,
  };
};
