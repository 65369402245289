import React from 'react';
import { TemplateSuite } from 'models/caller';
import { useTemplateSuitesDictionary } from 'utils/dictionary';
// components
import {
  CommonProps,
  MultiProps,
  Option,
  SelectControl,
  SingleProps
} from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & { filter?: (templateSuite: TemplateSuite) => boolean }

const CallerTemplateSuitesSelectControl = (props: Props): JSX.Element => {
  const templateSuites = useTemplateSuitesDictionary();
  const filterCallback = props.filter ?? (() => (true));
  const options: Option<number>[] =
    templateSuites
      .filter(filterCallback)
      .map(template => ({
        value: template.template_suite_id,
        label: template.name ?? String(template.template_suite_id),
      }));

  return <SelectControl
    name="templateSuiteId"
    label="Template Suite"
    { ...props }
    options={ options }
    checkSelected={ templateSuites.length > 0 }
  />;
};

export {
  CallerTemplateSuitesSelectControl
};
