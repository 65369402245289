import React from 'react';
import { StationLocation } from 'models/base-station';
import { useStationLocationsDictionarySelector } from 'hooks/station';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (location: StationLocation) => boolean }

export const StationCountrySelectControl = (props: Props): JSX.Element => {
  const { isLoading, locations } = useStationLocationsDictionarySelector();
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<string>[] = [];
  locations
    .filter(filterCallback)
    .reduce((countries, location) => countries.add(location.country), new Set<string>())
    .forEach(country => options.push({
      value: country,
      label: country,
    }));

  return (
    <SelectControl
      name="country"
      label="Country"
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ !isLoading }
    />
  );
};


