import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';

const usePositionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 350,
      ...margins(theme)
    },
    filter: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
      '&[class*="Mui-expanded"]': {
        marginTop: theme.spacing(1),
        marginBottom: 0,
      }
    },
    navigation: {},
    content: {},
  }),
);

export default usePositionsStyles;
