import React from 'react';

import { useDmTree } from 'hooks/device-management';
import { Filters, TopmostEntityType } from 'hooks/device-management/useDmTree/types';
import { PaginationParams } from 'models';

// components
import { DmTable } from 'components/DeviceManagement/DmTable/DmTable';

interface LevelPageTableProps {
  zoneId: number;
  filters?: Omit<Filters, keyof PaginationParams>;
  fetchingEnabled?: boolean;
}

export const LevelsTabTable = ({
  zoneId,
  filters,
  fetchingEnabled = true,
}: LevelPageTableProps) => {
  const pagination = { limit: 20, offset: 0 };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onPaginationChange = () => {};

  const topmostEntityType = TopmostEntityType.LEVEL;
  const tree = useDmTree({
    filters: {
      ...filters,
      ...pagination,
      zones: [zoneId],
      topmostEntityType,
    },
    enabled: fetchingEnabled,
  });

  return (
    <DmTable
      tree={ tree }
      topmostEntityType={ topmostEntityType }
      pagination={ pagination }
      onPaginationChange={ onPaginationChange }
    />
  );
};
