import { ActionLoaderProps } from 'components/Loaders';
import { TemplateSuite, CreateRouterData } from 'models/caller';
import { FormProps, FormErrors } from 'form';
import { FormField } from './ConnectZoneForm';
import { useForm } from 'hooks';
import { CreateRouter } from 'actions/caller';


interface PropsAction {
  doAction: (props: ActionLoaderProps) => void;
  onSuccess: () => void;
  templateSuite: TemplateSuite;
}

interface FormState<StateType, FieldEnum extends string> {
  form: FormProps<StateType, FieldEnum>;
  onSubmit: () => boolean;
  onReset: () => void;
}

export const useConnectForm = ({ doAction, onSuccess, templateSuite }: PropsAction): FormState<CreateRouterData, FormField> => {
  const form = useForm<CreateRouterData, FormField>({
    initialState: {
      template_suite_id: templateSuite.template_suite_id,
      zone_id: 0,
      is_active: true,
      group_id: 0,
    },
    showFields: [
      FormField.zone,
    ],
  });

  const handleSubmit = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: CreateRouter(form.state),
      onSuccess: () => {
        form.reset();
        onSuccess();
      },
      onError: form.catchError
    });

    return true;
  };

  return {
    form,
    onReset: form.reset,
    onSubmit: handleSubmit,
  };
};

export const validate = (form: FormProps<CreateRouterData, FormField>, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.zone)) {
    if (!form.state.zone_id) {
      errors.zone_id = 'Zone is required';
    }
  }

  return errors;
};
