import React from 'react';
import { useDeepCompareEffect } from 'react-use';

import { CallerTemplatePresetsState } from 'hooks/caller';
import { Template } from 'models/caller';
import { FormProps } from 'types/form';

import { validate } from './validator';

// compomnents
import {
  CallerTemplateSuitesSelectControl,
  HttpHeadersControl,
  SwitchControl,
  TextControl
} from 'components/Controls';

// styles
import { TemplatePresetControl } from './TemplatePresetControl';

export enum Field {
  url = 'url',
  templateSuiteId = 'template_suite_id',
  headers = 'headers',
  presetIds = 'preset_ids',
  isActive = 'is_active'
}

export interface TemplatePresetFormState {
  template_suite_id: number;
  url: Template['url'];
  headers: Template['headers'];
  preset_ids: number[];
  is_active: boolean;
}

type CallerTemplatePresetFormProps =
  & FormProps<TemplatePresetFormState, Field>
  & {
    presetsState: CallerTemplatePresetsState;
  };

export const CallerTemplatePresetForm = ({
  showFields,
  disabledFields = [],
  showErrors = showFields,
  presetsState,
  state,
  errors,
  onChange,
  onValidate,
}: CallerTemplatePresetFormProps) => {
  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends Field>(field: TField, value: TemplatePresetFormState[TField]) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form data-testid="template-preset-form">
      { showFields.includes(Field.templateSuiteId) &&
        <CallerTemplateSuitesSelectControl
          isRequired
          isDisabled={ disabledFields.includes(Field.templateSuiteId) }
          isMulti={ false }
          selected={ state.template_suite_id }
          // `suiteId as number` is valid as long as `isClearable !== true`
          onChange={ suiteId => handleChange(Field.templateSuiteId, suiteId as number) }
          error={ showErrors.includes(Field.templateSuiteId) ? errors?.template_suite_id : undefined }
        />
      }
      { showFields.includes(Field.url) &&
        <TextControl
          required
          disabled={ disabledFields.includes(Field.url) }
          type="url"
          label="URL"
          value={ state.url }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(Field.url, event.target.value);
          } }
          error={ showErrors.includes(Field.url) ? errors?.url !== undefined : false }
          helperText={ showErrors.includes(Field.url) ? errors?.url : undefined }
        />
      }
      { showFields.includes(Field.isActive) &&
        <SwitchControl
          label="Active"
          name="is_active"
          value={ state.is_active }
          isDisabled={ disabledFields.includes(Field.isActive) }
          onChange={ checked => handleChange(Field.isActive, checked) }
        />
      }
      { showFields.includes(Field.headers) &&
        <HttpHeadersControl
          label="Headers"
          disabled={ disabledFields.includes(Field.headers) }
          value={ state.headers || {} }
          onChange={ (value) => handleChange(Field.headers, value) }
          error={ showErrors.includes(Field.headers) ? errors?.headers : undefined }
        />
      }
      { showFields.includes(Field.presetIds) &&
        <TemplatePresetControl
          presetsState={ presetsState }
          value={ state.preset_ids }
          onChange={ presetIds => handleChange(Field.presetIds, presetIds) }
        />
      }
    </form>
  );
};
