import { SchemaEnum } from 'models/monitoring';

export const MONITORING_SCHEMA_ENUM_LOAD = 'monitoring/schemaEnums/LOAD';
export const MONITORING_SCHEMA_ENUM_LOAD_START = 'monitoring/schemaEnums/LOAD_START';
export const MONITORING_SCHEMA_ENUM_LOAD_SUCCESS = 'monitoring/schemaEnums/LOAD_SUCCESS';
export const MONITORING_SCHEMA_ENUM_LOAD_FAILURE = 'monitoring/schemaEnums/LOAD_FAILURE';

export interface LoadMonitoringSchemaEnum {
  readonly type: typeof MONITORING_SCHEMA_ENUM_LOAD;
}

export interface LoadMonitoringSchemaEnumStart {
  readonly type: typeof MONITORING_SCHEMA_ENUM_LOAD_START;
}

export interface LoadMonitoringSchemaEnumSuccess {
  readonly type: typeof MONITORING_SCHEMA_ENUM_LOAD_SUCCESS;
  readonly schema: SchemaEnum;
}

export interface LoadMonitoringSchemaEnumFailure {
  readonly type: typeof MONITORING_SCHEMA_ENUM_LOAD_FAILURE;
  readonly error: string;
}

export const LoadMonitoringEnum = (): LoadMonitoringSchemaEnum => ({
  type: MONITORING_SCHEMA_ENUM_LOAD
});

export const LoadMonitoringEnumStart = (): LoadMonitoringSchemaEnumStart => ({
  type: MONITORING_SCHEMA_ENUM_LOAD_START
});

export const LoadMonitoringEnumSuccess = (schema: SchemaEnum): LoadMonitoringSchemaEnumSuccess => ({
  type: MONITORING_SCHEMA_ENUM_LOAD_SUCCESS, schema
});

export const LoadMonitoringEnumFailure = (error: string): LoadMonitoringSchemaEnumFailure => ({
  type: MONITORING_SCHEMA_ENUM_LOAD_FAILURE, error
});

export type Action =
  | LoadMonitoringSchemaEnum
  | LoadMonitoringSchemaEnumStart
  | LoadMonitoringSchemaEnumSuccess
  | LoadMonitoringSchemaEnumFailure
