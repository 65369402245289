import * as Action from 'actions/firmware-management';
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import { getFirmwaresByParams, updateDeviceFirmware } from 'clients/firmware-management';
import { Firmware } from 'models/firmware-management';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';

type UpdateAction =
  | Action.UpdateDeviceFirmware
  | ActionWithPromise<Action.UpdateDeviceFirmware, void>

function* doUpdateDeviceFirmware(action: UpdateAction) {
  const { deviceId } = action.params;
  try {
    const response: ApiResponse = yield call(updateDeviceFirmware, action.params);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    yield put(NotifySuccess(`Device #${ deviceId }'s firmware have been updated`));
    'meta' in action && action.meta.promise.resolve();
  } catch (e) {
    yield put(NotifyError(`Error while updating device #${ deviceId }'s firmware: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject(e as Error);
  }
}

function* loadFirmwareDataById(action: Action.LoadFirmwareDataById) {
  yield put(Action.LoadFirmwareDataStart(action.firmwareHash));
  const response: ApiResponse = yield call(getFirmwaresByParams, { hash: action.firmwareHash });
  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.LoadFirmwareDataSuccess(action.firmwareHash, response.data as Firmware[]));
  } else {
    const message = response.message || 'Server error';
    yield put(Action.LoadFirmwareDataFailure(action.firmwareHash, message));
    yield put(NotifyError(`Error while fetching firmware #${ action.firmwareHash }: ${ message }`));
  }
}

function* loadFirmwareDataByParams(action: Action.LoadFirmwareDataByParams) {
  yield put(Action.LoadFirmwareDataStart(action.params));
  const response: ApiResponse = yield call(getFirmwaresByParams, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.LoadFirmwareDataSuccess(action.params, response.data as Firmware[]));
  } else {
    const message = response.message || 'Server error';
    yield put(Action.LoadFirmwareDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching firmwares: ${ message }`));
  }
}

function* loadFirmwareDataDictionary(_action: Action.LoadFirmwareDataDictionary) {
  yield put(Action.LoadFirmwareDataStart({}));
  const response: ApiResponse = yield call(getFirmwaresByParams, { limit: 999 });
  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.LoadFirmwareDataSuccess({}, response.data as Firmware[]));
  } else {
    const message = response.message || 'Server error';
    yield put(Action.LoadFirmwareDataFailure({}, message));
    yield put(NotifyError(`Error while fetching firmwares: ${ message }`));
  }
}

export default [
  takeEvery(Action.FIRMWARE_DATA_LOAD_BY_ID, loadFirmwareDataById),
  takeEvery(Action.FIRMWARE_DATA_LOAD_BY_PARAMS, loadFirmwareDataByParams),
  takeLeading(Action.FIRMWARE_DATA_LOAD_DICTIONARY, loadFirmwareDataDictionary),
  takeEvery(Action.DEVICE_FIRMWARE_UPDATE, doUpdateDeviceFirmware),
];
