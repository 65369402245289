import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTemplateSelector } from './useTemplateSelector';
// componetns
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import { CallerTemplateClone } from 'components/Caller';
import Navigation from './Navigation';
// styles
import { useStyles } from './styles';

interface Props {
  templateId: number;
}

const TemplateClonePage = ({ templateId }: Props) => {
  const classes = useStyles();
  const { template } = useTemplateSelector(templateId);

  return (
    <>
      <Helmet><title>{ `Http Template #${ templateId } ${ template && template.name } Clone` }</title></Helmet>
      <Navigation template={ template }/>
      <Paper className={ classes.root }>
        { !template
          ? <Skeleton variant="rect"/>
          : <CallerTemplateClone template={ template }/>
        }
      </Paper>
    </>
  );
};

export default TemplateClonePage;
