
import { put, takeEvery, call } from 'redux-saga/effects';

import { DeviceConnectivity } from 'models/connectivity/deviceConnectivity';

import {
  FETCH_CONNECTIVITY_BY_FILTERS,
  DoGetConnectivitySuccess,
  DoGetConnectivityFailure,
  GetConnectivityByFilters,

  FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP,
  FetchAvgConnectivityPerPositionGroup,
  fetchAvgConnectivityPerPositionGroupSuccess,
  fetchAvgConnectivityPerPositionGroupFailure,
} from 'actions/device-connectivity';
import { fetchConnectivity, fetchGroupsAvgConn } from 'clients/connectivity';
import { NotifyError } from 'actions/notifier';
import { ApiResponse, ReasonEnum } from 'models';
import { ConnectivityFilters } from 'models/connectivity/filters';
import { GroupConnectivity } from 'models/connectivity';

function* fetchConnectivityByFilters(action: GetConnectivityByFilters) {
  yield call(fetchConnectivityCall, action.filters);
}

function* fetchConnectivityCall(filters: ConnectivityFilters) {
  // TODO: replace `ApiResponse` with more precise typedefs (w/o `data: any`)
  const response: ApiResponse = yield call(fetchConnectivity, filters);
  if (response.reason !== ReasonEnum.Ok) {
    yield put(DoGetConnectivityFailure(response.message));
    yield put(NotifyError(`Error while fetching devices connectivity: ${response.message}`));
    return;
  }

  const foundDevicesConnectivity: DeviceConnectivity[] = response.data;

  const devicesConnectivityByDeviceId = [
    ...(filters.devices || []),
    ...foundDevicesConnectivity.map(c => c.device_id),
  ].reduce(
    (devicesConnectivityByDeviceId, deviceId) => {
      // Since we concat what we explicitly requested and what we received,
      // we may have already processed `deviceId`
      if (deviceId in devicesConnectivityByDeviceId) {
        return devicesConnectivityByDeviceId;
      }

      const deviceConnectivity = foundDevicesConnectivity.find(
        p => p.device_id === deviceId,
      );

      // Here we differenciate between `null` and `undefined`
      // in order to mark `deviceId` fetched but not found
      devicesConnectivityByDeviceId[deviceId] = deviceConnectivity || null;

      return devicesConnectivityByDeviceId;
    },
    {} as { [deviceId: string]: DeviceConnectivity | null },
  );

  yield put(DoGetConnectivitySuccess({
    devicesConnectivityByDeviceId,
    foundDevicesConnectivity,
  }));
}

function* fetchAvgConnectivityPerPositionGroupSaga(action: FetchAvgConnectivityPerPositionGroup) {
  const response: ApiResponse = yield call(fetchGroupsAvgConn, action.filters);

  if (response.reason !== ReasonEnum.Ok) {
    yield put(fetchAvgConnectivityPerPositionGroupFailure());
    yield put(NotifyError(`Error while fetching connectivity per group: ${response.message}`));
    return;
  }

  const connectivityPerGroup: GroupConnectivity[] = response.data;
  const avgConnectivityByPositionGroupId = Object.fromEntries(
    connectivityPerGroup.map(c => [c.group_id, c.connectivity]),
  );

  yield put(fetchAvgConnectivityPerPositionGroupSuccess({ avgConnectivityByPositionGroupId }));
}

export const deviceConnectivitySagas = [
  takeEvery(FETCH_CONNECTIVITY_BY_FILTERS, fetchConnectivityByFilters),
  takeEvery(FETCH_AVG_CONNECTIVITY_PER_POSITION_GROUP, fetchAvgConnectivityPerPositionGroupSaga),
];
