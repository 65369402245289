import * as Actions from 'actions/user-management';
import { Repository } from 'utils/repository';
import { GetApiKeyParams, ApiKey } from 'models/user-management';

export type State = Repository<ApiKey, GetApiKeyParams, 'id'>;
const initState: State = Repository.create('id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.UM_API_KEY_DATA_FETCH_START:
      return state.setFetched(action.params, false);
    case Actions.UM_API_KEY_DATA_FETCH_STOP:
      return state.setFetched(action.params, true);
    case Actions.UM_API_KEY_DATA_CHANGE:
      return state.setData(action.params, action.data);
    case Actions.UM_API_KEY_LOCK:
      return state.setLocked(action.apiKeyId, true);
    case Actions.UM_API_KEY_UNLOCK:
      return state.setLocked(action.apiKeyId, false);
    case Actions.UM_API_KEY_CHANGE:
      return state.changeModel(
        action.apiKey.id,
        action.options.delete ? undefined : action.apiKey
      );

    default:
      return state;
  }
};
