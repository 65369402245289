import { CreateTemplateParams, Template, UpdateTemplateParams } from 'models/caller';

export const CALLER_TEMPLATE_CREATE = 'CALLER_TEMPLATE_CREATE';
export const CALLER_TEMPLATE_CREATE_MANY = 'CALLER_TEMPLATE_CREATE_MANY';
export const CALLER_TEMPLATE_UPDATE = 'CALLER_TEMPLATE_UPDATE';
export const CALLER_TEMPLATE_DELETE = 'CALLER_TEMPLATE_DELETE';
export const CALLER_TEMPLATE_CHANGE = 'CALLER_TEMPLATE_CHANGE';
export const CALLER_TEMPLATE_CHANGE_MANY = 'CALLER_TEMPLATE_CHANGE_MANY';
export const CALLER_TEMPLATE_LOCK = 'CALLER_TEMPLATE_LOCK';
export const CALLER_TEMPLATE_UNLOCK = 'CALLER_TEMPLATE_UNLOCK';

export interface CreateTemplate {
  readonly type: typeof CALLER_TEMPLATE_CREATE;
  template: CreateTemplateParams;
}

export interface CreateManyTemplates {
  readonly type: typeof CALLER_TEMPLATE_CREATE_MANY;
  readonly templates: CreateTemplateParams[];
}

export interface UpdateTemplate {
  readonly type: typeof CALLER_TEMPLATE_UPDATE;
  template: UpdateTemplateParams;
}

export interface DeleteTemplate {
  readonly type: typeof CALLER_TEMPLATE_DELETE;
  template: Template;
}

interface TemplateChangeParams {
  template: Template;
  options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export interface ChangeTemplate extends TemplateChangeParams {
  readonly type: typeof CALLER_TEMPLATE_CHANGE;
}

export interface ChangeManyTemplates {
  readonly type: typeof CALLER_TEMPLATE_CHANGE_MANY;
  templateParams: TemplateChangeParams[];
}


export interface LockTemplate {
  readonly type: typeof CALLER_TEMPLATE_LOCK;
  templateId: number;
}

export interface UnlockTemplate {
  readonly type: typeof CALLER_TEMPLATE_UNLOCK;
  templateId: number;
}

export const CreateTemplate = (template: CreateTemplateParams): CreateTemplate => ({
  type: CALLER_TEMPLATE_CREATE, template
});

export const CreateManyTemplates = (templates: CreateTemplateParams[]): CreateManyTemplates => ({
  type: CALLER_TEMPLATE_CREATE_MANY, templates
});

export const UpdateTemplate = (template: UpdateTemplateParams): UpdateTemplate => ({
  type: CALLER_TEMPLATE_UPDATE, template
});

export const DeleteTemplate: (template: Template) => DeleteTemplate = (template: Template) => ({
  type: CALLER_TEMPLATE_DELETE, template
});

export const ChangeTemplate = (template: Template, options: ChangeTemplate['options']): ChangeTemplate => ({
  type: CALLER_TEMPLATE_CHANGE, template, options
});

export const ChangeManyTemplates = (templateParams: TemplateChangeParams[]): ChangeManyTemplates => ({
  type: CALLER_TEMPLATE_CHANGE_MANY, templateParams
});

export const LockTemplate: (templateId: number) => LockTemplate = (templateId: number) => ({
  type: CALLER_TEMPLATE_LOCK, templateId
});
export const UnlockTemplate: (templateId: number) => UnlockTemplate = (templateId: number) => ({
  type: CALLER_TEMPLATE_UNLOCK, templateId
});

export type Action =
  | CreateTemplate
  | CreateManyTemplates
  | UpdateTemplate
  | DeleteTemplate
  | ChangeTemplate
  | ChangeManyTemplates
  | LockTemplate
  | UnlockTemplate
