import React from 'react';
import { dmZonePath, getDMZoneDetailPath } from 'routing/paths';
import { useZones } from 'hooks/device-management';

// components
import { Link } from '@material-ui/core';
import { CustomLink } from 'components/Links';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface Props {
  zoneId: number;
  defaultName?: string;
  withReturnUrl?: boolean;
  innerProps?: TypographyProps;
  redesign?: boolean;
}

const defaultProps: TypographyProps = {
  variant: 'caption',
  color: 'textSecondary',
};

export const ZoneLink = (props: Props): JSX.Element => {
  const { zoneId, defaultName, withReturnUrl = true } = props;
  const { zones } = useZones({ params: {} });
  const name = zones?.find(z => z.id === zoneId)?.name ?? defaultName;

  if (!name) {
    const TypoProps = { ...defaultProps, ...props.innerProps };
    return (
      <Typography { ...TypoProps }>-</Typography>
    );
  }

  const link = props.redesign ? getDMZoneDetailPath(zoneId) : dmZonePath(zoneId);

  return (
    <Link
      color="secondary"
      component={ CustomLink }
      to={ link }
      withReturnUrl={ withReturnUrl }
    >
      { name }
    </Link>
  );
};
