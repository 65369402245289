import { useState, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';

export function useExpandableRows<T>(data: T[] = []) {
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([]);

  const prevValues = usePrevious(data);

  useEffect(() => {
    if (!prevValues || isEqual(data, prevValues)) {
      return;
    }

    setRowsExpanded([]);
  }, [data, prevValues]);

  const onRowExpansionChange = (
    _currentRow: { index: number }[],
    expandedRows: { index: number }[]
  ) => {
    setRowsExpanded(expandedRows.map(row => row.index));
  };

  const closeRows = () => {
    setRowsExpanded([]);
  };

  return {
    rowsExpanded,
    onRowExpansionChange,
    closeRows,
  };
}
