import { useState } from 'react';
import { ResponsiblePerson, Station, StationLocation } from 'models/base-station';
import { User } from 'models/user-management';
import { commonOptions } from 'utils/tables';
import { usePagination } from 'hooks';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Actions, Location, Notification, Registered, Role, Station as StationId } from './TableComponents';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';

// styles
import { locationCell } from './style';
import { truncatedCellsMuiTableTheme } from 'styles/themes';
import { MuiThemeProvider } from '@material-ui/core/styles';

interface Props {
  responsiblePersons: ResponsiblePerson[];
  users: User[];
  stations: Station[];
  locations: StationLocation[];
  isLoadingPersons: boolean;
}

enum TableFields {
  Registered = 'registered',
  User = 'user',
  Email = 'email',
  Role = 'role',
  Location = 'location',
  Station = 'station',
  Notification = 'notification',
  Actions = 'actions',
}

interface TableData {
  [TableFields.Registered]: boolean;
  [TableFields.User]: string;
  [TableFields.Email]: string;
  [TableFields.Role]: [string, number];
  [TableFields.Location]: ResponsiblePerson;
  [TableFields.Station]: [StationLocation[] | undefined, number];
  [TableFields.Notification]: [boolean, number | undefined];
  [TableFields.Actions]: [ResponsiblePerson];
}

function fillTableData(responsible: ResponsiblePerson[]): TableData[] {
  return responsible.map(user => ({
    registered: !!user.registered_user_id,
    user: `${user.first_name} ${user.last_name}`,
    email: user.notification_endpoint.email ?? '',
    role: [user.role, user.id],
    location: user,
    station: [user.locations, user.id],
    notification: [!!user.notify, user.id],
    actions: [user]
  }));
}

export const ResponsiblePersonsTable = (props: Props): JSX.Element => {
  const { responsiblePersons, locations, isLoadingPersons } = props;
  const { page, limit } = usePagination();

  const [expanded, setExpanded] = useState<ResponsiblePerson['id']>();

  const currentPerson = (id: number) => responsiblePersons.find(person => person.id === id) as ResponsiblePerson;

  const columns: MUIDataTableColumn[] = [
    {
      name: TableFields.Registered,
      label: 'Registered',
      options: {
        customBodyRender: registered => <Registered registered={ registered } />
      },
    },
    {
      name: TableFields.User,
      label: 'Name',
    },
    {
      name: TableFields.Email,
      label: 'Email',
    },
    {
      name: TableFields.Role,
      label: 'Role',
      options: {
        customBodyRenderLite: dataIndex => <Role role={ responsiblePersons[dataIndex].role } />
      }
    },
    {
      name: TableFields.Location,
      label: 'Location',
      options: {
        customBodyRender: (responsiblePerson: TableData[TableFields.Location]) =>
          <Location
            responsiblePerson={ responsiblePerson }
            expanded={ expanded }
            setExpanded={ setExpanded }
            availableLocations={ locations.filter(l => l.owner_id === responsiblePerson.owner_id) }
          />,
        setCellProps: () => ({ style: locationCell })
      }
    },
    {
      name: TableFields.Station,
      label: 'Station ID',
      options: {
        customBodyRender: ([locations, rpId]: TableData[TableFields.Station]) =>
          <StationId
            locations={ locations }
            expanded={ expanded }
            rpId={ rpId }
          />
      }
    },
    {
      name: TableFields.Notification,
      label: 'Notification',
      options: {
        customBodyRender: ([notify, id]: TableData[TableFields.Notification]) =>
          <Notification notify={ notify } responsiblePerson={ id ? currentPerson(id) : undefined } />
      }
    },
    {
      name: TableFields.Actions,
      label: ' ',
      options: {
        empty: true,
        viewColumns: false,
        customBodyRender: ([user]: TableData[TableFields.Actions]) => <Actions person={ user } />
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    responsive: 'simple',
    page,
    rowsPerPage: limit,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  const data: TableData[] = fillTableData(responsiblePersons);

  return (
    <MuiThemeProvider theme={ truncatedCellsMuiTableTheme(data.length) }>
      <TableLoadingLayout isLoading={ isLoadingPersons }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};
