export interface PositionState {
  device_id: string;
  position_id: number;
  network_id: string;
  group_id: number;
  zone_id: number;
  owner_id: number;
  custom_id?: string;
  project_id: number;
  occupied: boolean;
  status_time: string;
  lat: number;
  lon: number;
  new?: boolean;
}

export interface GetPositionStateParams {
  limit?: number
  offset?: number
  owner?: number
  deviceIds?: string[]
  positionIds?: number[]
  groupIds?: number[]
  zones?: number[]
  projects?: number[]
  networkIds?: string[]
  timeFrom?: Date
  timeTo?: Date
  status?: PositionStateStatus
  customIds?: string[]
}

export enum PositionStateStatus {
  Occupied = 'Occupied',
  Free = 'Free'
}
