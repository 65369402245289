import { getUrlItems, UrlItems } from 'utils/routing/query';

import {
  Device as ProvisioningDevice,
  ProvisioningDevicesFiltersFields as Fields,
  ProvisioningDevicesFiltersLimit
} from 'models/provisioning';
import {
  Action,
  FETCH_PROVISIONING_DEVICES_FAILURE,
  FETCH_PROVISIONING_DEVICES,
  FETCH_PROVISIONING_DEVICES_SUCCESS,
  SET_PROVISIONING_DEVICES_FILTERS,
  ADD_DEVICE_TO_BOX_FAILURE,
  CHANGE_DEVICE_STATUS_FAILURE,
  ADD_DEVICE_TO_BOX,
  CHANGE_DEVICE_STATUS,
  ADD_DEVICE_TO_BOX_SUCCESS,
  CHANGE_DEVICE_STATUS_SUCCESS,
  FETCH_PROVISIONING_DEVICE_BY_ID_FAILURE,
  FETCH_PROVISIONING_DEVICE_BY_ID,
  FETCH_PROVISIONING_DEVICE_BY_ID_SUCCESS,
  REMOVE_DEVICE_FROM_BOX,
  REMOVE_DEVICE_FROM_BOX_FAILURE,
  REMOVE_DEVICE_FROM_BOX_SUCCESS,
  UPDATE_PROVISIONING_DEVICES_FILTERS_FROM_URL,
} from 'actions/provisioning';
import { DEFAULT_LIMIT } from 'models/common';

export interface State {
  devices: ProvisioningDevice[];
  total: number;
  isFetching: boolean;
  isDeleting: boolean;
  filters: ProvisioningDevicesFiltersLimit;
}

function getFiltersFromUrl(urlState: UrlItems): ProvisioningDevicesFiltersLimit {
  return {
    [Fields.device_id]: urlState[Fields.device_id] ? [].concat(urlState[Fields.device_id]) : [],
    [Fields.box_id]: urlState[Fields.box_id] ? [].concat(urlState[Fields.box_id]).map(b => Number(b)) : [],
    [Fields.shipment_id]: urlState[Fields.shipment_id] ? [].concat(urlState[Fields.shipment_id]) : [],
    [Fields.device_status]: urlState[Fields.device_status] ? urlState[Fields.device_status] : undefined,
    [Fields.owner]: urlState[Fields.owner] ? [].concat(urlState[Fields.owner]).map(b => Number(b)) : [],
    [Fields.limit]: urlState[Fields.limit] ? Number(urlState[Fields.limit]) : DEFAULT_LIMIT,
    [Fields.offset]: urlState[Fields.offset] ? Number(urlState[Fields.offset]) : 0
  };
}

export const initState: State = {
  devices: [],
  isFetching: false,
  isDeleting: false,
  filters: {
    ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
  },
  total: 0
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_PROVISIONING_DEVICES:
    case ADD_DEVICE_TO_BOX:
    case CHANGE_DEVICE_STATUS:
    case FETCH_PROVISIONING_DEVICE_BY_ID:
      return { ...state, isFetching: true };
    case REMOVE_DEVICE_FROM_BOX:
      return { ...state, isDeleting: true };
    case FETCH_PROVISIONING_DEVICES_FAILURE:
      return { ...state, isFetching: false, devices: [] };
    case ADD_DEVICE_TO_BOX_FAILURE:
    case CHANGE_DEVICE_STATUS_FAILURE:
    case FETCH_PROVISIONING_DEVICE_BY_ID_FAILURE:
      return { ...state, isFetching: false };
    case REMOVE_DEVICE_FROM_BOX_FAILURE:
      return { ...state, isDeleting: false };
    case FETCH_PROVISIONING_DEVICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        devices: action.devices,
        total: action.total,
      };
    case SET_PROVISIONING_DEVICES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case CHANGE_DEVICE_STATUS_SUCCESS:
    case FETCH_PROVISIONING_DEVICE_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        devices: [action.device]
      };
    case ADD_DEVICE_TO_BOX_SUCCESS:
      return {
        ...state,
        isFetching: false,
        devices: [
          action.device,
          ...state.devices,
        ]
      };
    case REMOVE_DEVICE_FROM_BOX_SUCCESS:
      const index = state.devices.findIndex(device => device.device_id === action.device.device_id);
      const devices = [...state.devices];
      if (index >= 0) {
        devices.splice(index, 1);
      }

      return {
        ...state,
        isDeleting: false,
        devices
      };
    case UPDATE_PROVISIONING_DEVICES_FILTERS_FROM_URL:
      return {
        ...state,
        filters: {
          ...getFiltersFromUrl(getUrlItems(Object.values(Fields)))
        }
      };
    default:
      return state;
  }
};
