export function formatAsPercent(value: number, total: number): string {
  if (!value && !total) {
    return '0%';
  }

  return `${((value / total) * 100).toFixed(0)}%`;
}

export function formatLongText(text: string, length: number): string {
  if (text.length <= length) {
    return text;
  }

  return `${ text.substring(0, length - 3) }...`;
}