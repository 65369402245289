import * as dmClient from 'clients/device-management';
import { Device } from 'models/device-management';
import { getResponseData } from 'utils/clients';

import { ZoneNode, LevelNode, GroupNode, PositionNode } from '../types';

type TotalDevicesByNode = Record<string, number | undefined>;

export async function fetchZonesTotalDevices(
  nodes: ZoneNode[]
): Promise<TotalDevicesByNode> {
  const nodeIdTotalDevicesTuples = await Promise.all(
    nodes.map(async node => {
      const zoneId = node.zone.id;
      const res = await dmClient.fetchDevices({ zones: [zoneId], limit: 0 });

      return [node.id, res.total];
    }),
  );

  return Object.fromEntries(nodeIdTotalDevicesTuples);
}

export async function fetchLevelsTotalDevices(
  nodes: LevelNode[]
): Promise<TotalDevicesByNode> {
  const nodeIdTotalDevicesTuples = await Promise.all(
    nodes.map(async node => {
      const levelId = node.level?.id;

      // TODO: support fetching devices without level
      if (!levelId) {
        return [node.id, undefined];
      }

      const res = await dmClient.fetchDevices({ levels: [levelId], limit: 0 });

      return [node.id, res.total];
    }),
  );

  return Object.fromEntries(nodeIdTotalDevicesTuples);
}

export async function fetchGroupsTotalDevices(
  nodes: GroupNode[]
): Promise<TotalDevicesByNode> {
  const nodeIdTotalDevicesTuples = await Promise.all(
    nodes.map(async node => {
      const groupId = node.group.id;
      const res = await dmClient.fetchDevices({ groups: [groupId], limit: 0 });

      return [node.id, res.total];
    }),
  );

  return Object.fromEntries(nodeIdTotalDevicesTuples);
}

type DeviceByNode = Record<string, Device | undefined>;

export async function fetchPositionsDevices(
  nodes: PositionNode[]
): Promise<DeviceByNode> {
  const devices = getResponseData(
    await dmClient.fetchDevices({
      positions: nodes.map(node => node.position.id),
      limit: nodes.length,
    })
  );

  const deviceByPosition = Object.fromEntries(
    devices.map(device => [device.position_id, device]),
  );

  const deviceByNode = Object.fromEntries(
    nodes.map(node => {
      const positionId = node.position.id;
      const device = deviceByPosition[positionId];

      return [node.id, device];
    }),
  );

  return deviceByNode;
}
