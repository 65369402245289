import { fetchPositionsDeviceSilent } from '../../../api';
import { getPositionNodes } from '../../../node/utils';
import { PositionNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillPositionDeviceSilent({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<boolean, PositionNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getPositionNodes(getNodes(nodes)),
    getQuery: node => node.deviceSilent,
    fetchDataByNode: fetchPositionsDeviceSilent,
  });
}
