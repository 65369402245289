import { useLorawanStationsById } from 'hooks';

interface Props {
  stationId: number;
}

export const LorawanStationName = ({ stationId }: Props): JSX.Element => {
  const { station, isLoading } = useLorawanStationsById(stationId);
  return (
    <>{ !isLoading ? station.eui : '' }</>
  );
};
