import { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Shipment, ShipmentStatusEnum } from 'models/provisioning';
import { DoAddBoxesToShipment, DoFetchShipmentCSVReport, DoRemoveBoxFromShipment } from 'actions/provisioning';
import { useDialogWithItem } from 'hooks';
import { useShipmentBoxes } from './utils';

// components
import { ListItemSkeleton } from 'components/Skeleton';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, List, ListItem, Tooltip } from '@material-ui/core';
import { Add, Close, SaveAlt } from '@material-ui/icons';
import { AddBoxModal } from '../AddBoxModal';
import { LoaderSvg } from 'components/Loaders';
import { ShipmentBoxLink } from 'components/Links';
import DeleteBoxDialog from '../DeleteBoxDialog';

// styles
import useStyles from 'components/Provisioning/styles';

interface Props {
  shipment: Shipment;
}

export const ShipmentBoxes = ({ shipment }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deleteDialog = useDialogWithItem<{ boxId: number; shipmentName: string }>();
  const { isDeleting } = useSelector((state: RootState) => state.provisioning.boxes);
  const { isCSVFetching } = useSelector((state: RootState) => state.provisioning.shipment);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isFetching, boxes, total } = useShipmentBoxes(shipment);
  const canAdd = shipment.status !== ShipmentStatusEnum.Received;
  const canDelete = shipment.status !== ShipmentStatusEnum.Received;

  const addBox = (boxIds: number[]) => { dispatch(DoAddBoxesToShipment(boxIds, shipment.id)); };
  const removeBox = (id: number) => dispatch(DoRemoveBoxFromShipment(id));
  const handleSaveAsCSV = () => { dispatch(DoFetchShipmentCSVReport(shipment)); };

  return (
    <>
      <Card className={ clsx(classes.cardRoot, classes.flexCard) } data-testid="boxes-container">
        <CardHeader title="Boxes" subheader={ `Total: ${ total }` } action={
          <Box display="flex" alignItems="center">
            <Box mr={ 1 }>
              <Tooltip title="Save as CSV">
                <IconButton onClick={ handleSaveAsCSV }>
                  { isCSVFetching ? <CircularProgress size={ 20 }/> : <SaveAlt/> }
                </IconButton>
              </Tooltip>
            </Box>
            { canAdd && (
              <Box mr={ 1 }>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={ <Add/> }
                  onClick={ () => { setDialogOpen(true); } }
                >Add Box</Button>
              </Box>
            ) }
          </Box>
        }/>
        <CardContent className={ classes.flexScroll }>
          { isFetching
            ? <ListItemSkeleton itemsCount={ 5 }/>
            : <List>
              {
                boxes.map(box => {
                  return (
                    <ListItem key={ box.id } className={ classes.border } data-testid="box-item">
                      <ShipmentBoxLink id={ box.id }/>
                      { canDelete && (
                        <>
                          <IconButton
                            disabled={ isDeleting }
                            onClick={ () => deleteDialog.open({ boxId: box.id, shipmentName: shipment.name }) }
                            className={ classes.removeBtn }
                          ><Close/></IconButton>
                          { isDeleting && <LoaderSvg/> }
                        </>
                      ) }
                    </ListItem>
                  );
                })
              }
            </List>
          }
        </CardContent>
      </Card>

      { deleteDialog.isMounted && deleteDialog.item && <DeleteBoxDialog
        boxId={ deleteDialog.item.boxId }
        shipmentName={ deleteDialog.item.shipmentName }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
        onConfirm={ removeBox }
      /> }

      { isDialogOpen && <AddBoxModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
        addBox={ addBox }
      /> }
    </>
  );
};
