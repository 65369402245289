import * as umPermissionsActions from 'actions/user-management/permissions';
import * as umUsersActions from 'actions/user-management/users';
import { Permissions, User } from 'models/user-management';
import { stringifyObject } from 'utils/ds/object';

export interface State {
  readonly data: User[];
  readonly permissionsById: {
    readonly [permissionsId: number]: Permissions | null | undefined;
  };
  readonly permissionsFetching: boolean;

  readonly userBySub: {
    readonly [sub: string]: User | null | undefined;
  };
  readonly userSubsByQuery: {
    readonly [query: string]: User['sub'][] | undefined;
  };
  readonly usersFetching: boolean;
  readonly totalUsersByQuery: {
    query: number;
  };
}

const initialState: State = {
  data: [],
  permissionsById: {},
  permissionsFetching: false,

  userBySub: {},
  userSubsByQuery: {},
  usersFetching: false,
  totalUsersByQuery: {
    query: 0
  },
};

type Action = (
  | umPermissionsActions.Action
  | umUsersActions.Action
);



export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case umPermissionsActions.DELETE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsById: {
          ...state.permissionsById,
          [action.id]: null,
        },
      };

    case umPermissionsActions.CREATE_PERMISSIONS_SUCCESS:
    case umPermissionsActions.UPDATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsById: {
          ...state.permissionsById,
          [action.permissions.id]: action.permissions,
        },
      };

    case umPermissionsActions.FETCH_PERMISSIONS:
      return { ...state, permissionsFetching: true };

    case umPermissionsActions.FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsById: {
          ...state.permissionsById,
          ...action.payload.permissionsById,
        },
        permissionsFetching: false,
      };

    case umPermissionsActions.FETCH_PERMISSIONS_FAILURE:
      return { ...state, permissionsFetching: false };

    case umUsersActions.DELETE_USER_SUCCESS: {
      const { userSub } = action;

      return {
        ...state,
        userBySub: {
          ...state.userBySub,
          [action.userSub]: null,
        },
        userSubsByQuery: Object.fromEntries(
          Object.entries(state.userSubsByQuery)
            .map(([_, userSubs]) => [_, userSubs?.filter(s => s !== userSub)])
        ),
      };
    }

    case umUsersActions.FETCH_USER:
    case umUsersActions.FETCH_USERS:
      return { ...state, usersFetching: true };

    case umUsersActions.FETCH_USER_SUCCESS:
      return {
        ...state,
        userBySub: {
          ...state.userBySub,
          [action.payload.userSub]: action.payload.user,
        },
        usersFetching: false,
      };

    case umUsersActions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.users,
        userBySub: {
          ...state.userBySub,
          ...Object.fromEntries(action.payload.users.map(u => [u.sub, u])),
        },
        userSubsByQuery: {
          ...state.userSubsByQuery,
          [stringifyObject(action.payload.params)]: action.payload.users.map(u => u.sub),
        },
        usersFetching: false,
        totalUsersByQuery: { query: action.payload.total }
      };

    case umUsersActions.FETCH_USER_FAILURE:
    case umUsersActions.FETCH_USERS_FAILURE:
      return { ...state, usersFetching: false };

    case umUsersActions.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        userBySub: {
          ...state.userBySub,
          [action.payload.sub]: action.payload.user,
        },
      };
    }
    case umUsersActions.CONFIRM_ACCOUNT_FETCH:
      return state;
    default:
      return state;
  }
};
