import { createStyles, makeStyles, Theme } from 'styles/utils';

const contentStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexFlow: 'column',
    flex: 1
  },
  toolbar: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  formControl: {
    '&[class*="MuiFormControl-root"]': {
      margin: '9px 0',
      width: '100%',
      minHeight: '51px',
    },
    '& [class*="MuiInput-root"]': {
      minHeight: '35px',
    },
    '& [class*="MuiInputLabel-root"]': {
      letterSpacing: 'normal',
    },
    '& [class*="MuiInputLabel-shrink"]': {
      fontWeight: 500,
    },
    flex: 1,
  },
  formControlSmall: {
    '&[class*="MuiFormControl-root"]': {
      minHeight: 'auto',
    },
  },
  formSwitchControl: {
    '&[class*="MuiFormControl-root"]': {
      minHeight: 'auto',
    },
    '& [class*="MuiFormControlLabel-root"]': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  formLabelNonShrunk: {
    fontSize: '16px',
  },
  dropDownInput: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    outline: 'none'
  },
  inputFullWidth: {
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    flex: 1
  },
  buttonAddInSelect: {
    minWidth: '30px',
    padding: 0,
    marginLeft: theme.spacing(1),
    maxHeight: '30px'
  },
  pageNotImplemented: {
    color: theme.palette.grey[900],
    display: 'flex',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    margin: '0 auto 30px',
    fontSize: '20px',
    color: theme.palette.grey[800],
    '& img': {
      verticalAlign: 'text-bottom',
      height: '30px',
    }
  },
  hidden: {
    display: 'none !important',
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

export const makeUtilStyles = () => makeStyles(() => createStyles({
  marginCollapsingDisabledContainer: {
    // https://stackoverflow.com/a/19719427/7472822
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const makeTableStyles = () => makeStyles((theme: Theme) =>
  createStyles({
    actionsIcon: {
      padding: 0,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    shrunkCell: {
      padding: 0,
      // shrinks the cell dynamically w/o introducing "fixed" pixel values,
      // although `width: 1px` seems working properly at least in the case of user table too
      // https://stackoverflow.com/a/43615091/7472822
      width: '0.1%',
      whiteSpace: 'nowrap',
    },
    actionsCellBody: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      textAlign: 'right',
    }
  })
);

export const MenuProps = {
  PaperProps: {
    style: {
      width: 350
    }
  }
};

export default contentStyles;

export const LegendStyles = makeStyles((theme: Theme) => createStyles({
  legendBox: {
    margin: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'baseline'
  },
  help: {
    marginLeft: 'auto',
    display: 'flex'
  }
}));
