import React from 'react';
import { Address } from 'components/BaseStation/types';
import locationIcon from 'images/Map/found-location.svg';
// components
import { InfoWindow, Marker } from 'react-google-maps';
import { Box, Divider, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// styles
import { useStyles } from './styles';
import { colors } from 'styles/themes/outer';

interface Props {
  address: Address;
  onSelect?: (address?: Address) => void;
}

export const AddressMarker = (props: Props) => {
  const { address, onSelect } = props;
  const classes = useStyles();

  let locationInfo = <Typography variant="subtitle1">{ address.title }</Typography>;
  if (address.id === 'notFound') {
    locationInfo = (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box ml={ 1 } mr={ 2 }>
          <ErrorOutlineIcon fontSize="large" htmlColor={ colors.errordark }/>
        </Box>
        <Typography variant="subtitle1">Can not find the address on map, please choose manually</Typography>
      </Box>
    );
  } else if (address.id === 'clickPoint') {
    locationInfo = (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box ml={ 1 } mr={ 2 }>
          <ErrorOutlineIcon fontSize="large" htmlColor={ colors.errordark }/>
        </Box>
        <Typography variant="subtitle1">Can not find the address on map, set current location</Typography>
      </Box>
    );
  }

  return (
    <Marker
      key={ address.id }
      position={ { lat: address.lat, lng: address.lon } }
      icon={ locationIcon }
    >
      <InfoWindow>
        <Box>
          { locationInfo }
          { !onSelect ? '' :
            <>
              <Divider variant="middle"/>
              <Box display="flex" justifyContent="flex-end">
                {/* Google Maps Api bug: InfoWindow focuses automatically when rendered https://github.com/angular/components/issues/23829 */}
                <Box className={`${classes.boxBtn} ${classes.btnCancel}`} onClick={ () => onSelect(undefined) }>CANCEL</Box>
                <Box className={`${classes.boxBtn} ${classes.btnSubmit}`} onClick={ () => onSelect(address) }>CONFIRM</Box>
              </Box>
            </>
          }
        </Box>
      </InfoWindow>
    </Marker>
  );
};
