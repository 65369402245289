import React from 'react';
import { styled } from 'styles/utils';
import Button from '@material-ui/core/Button';
import { KeyboardArrowDown } from '@material-ui/icons';

const ArrowDown = styled(Button)(props => ({
  padding: 0,
  pointerEvents: 'none',
  minWidth: '20px',
  marginLeft: props.theme.spacing(1),
  marginTop: 'calc(50% + 12px)',
}));

export default function StyledComponents() {
  return <ArrowDown color="primary">
    <KeyboardArrowDown />
  </ArrowDown>;
}
