import { Options as RoutesRendererOptions } from 'utils/routing';

export const routesRendererBaseProps: Partial<RoutesRendererOptions> = {
  getChildRoutes: ({ route }) => route.routes || [],
  getRouteProps: (routeProps) => ({
    ...routeProps,
    children: routeProps.render,
    render: undefined,
  }),
  switchRendered: false,
};
