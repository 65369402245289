import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { GetUserGroupParams, UserGroup, UserGroupServiceName } from 'models/user-management';
import { ActivateGroupService, DeactivateGroupService, GetGroupServices } from 'actions/user-management';
import { useFormActionNotifier } from 'hooks/form';
import { ApiResponse } from 'models';
import { ensureRequestSucceeded } from 'utils/clients';
import { fetchUserGroupsById, fetchUserGroupsByParams } from 'clients/user-management';

export interface UserGroupState {
  userGroup?: UserGroup;
  isLoading: boolean;
}

export function useUserGroupSelector(userGroupId: number, enabled = true): UserGroupState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userManagement/userGroup', userGroupId],
    queryFn: async (): Promise<ApiResponse<UserGroup>> => {
      const result: ApiResponse<UserGroup> = await fetchUserGroupsById(userGroupId);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching user group #${ userGroupId }: ${ (e as Error).message }`),
    enabled,
  });

  return {
    isLoading: queryResult.isLoading,
    userGroup: queryResult.data?.data,
  };
}

export interface UserGroupsState {
  userGroups: UserGroup[];
  userGroupById: Record<string, UserGroup | undefined>;
  isLoading: boolean;
}

export function useUserGroupsParamsSelector(params: GetUserGroupParams, enabled = true): UserGroupsState {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['userManagement/userGroup', params],
    queryFn: async (): Promise<ApiResponse<UserGroup[]>> => {
      const result: ApiResponse<UserGroup[]> = await fetchUserGroupsByParams(params);
      ensureRequestSucceeded(result);

      return result;
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    onError: e => notifyError(`Error while fetching user groups: ${ (e as Error).message }`),
    enabled,
  });

  const userGroups = queryResult.data?.data ?? [];
  return {
    isLoading: queryResult.isLoading,
    userGroups: userGroups,
    userGroupById: Object.fromEntries(userGroups.map(g => [g.id, g])),
  };
}

export function useUserGroupsDictionarySelector(enabled = true): UserGroupsState {
  return useUserGroupsParamsSelector({ limit: 999 }, enabled);
}

export function useUserGroupsServices(userGroupId: UserGroup['id']) {
  const dispatch = useDispatch();
  const { isFetching, data } = useSelector((state: RootState) => ({
    isFetching: state.userManagement.userGroupServices.isFetching,
    data: state.userManagement.userGroupServices.data,
  }), shallowEqual);

  useEffect(() => {
    dispatch(GetGroupServices(userGroupId));
  }, [dispatch, userGroupId]);

  return {
    isLoading: isFetching,
    services: data,

    getServiceByName: (serviceName: UserGroupServiceName) => data.find(s => s.name === serviceName),
    onActivateService: (serviceName: UserGroupServiceName) => {dispatch(ActivateGroupService(userGroupId, serviceName));},
    onDeactiveateService: (serviceName: UserGroupServiceName) => {dispatch(DeactivateGroupService(userGroupId, serviceName));},
  };
}
