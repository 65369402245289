import { HubPayload } from '@aws-amplify/core';

export enum Events {
  configured = 'configured',
  signIn = 'signIn',
  signUp = 'signUp',
  signOut = 'signOut',
  authError = 'ToastAuthError',
  stateChange = 'AuthStateChange'
}

export function hideDefaultAmplifyToast() {
  const amplifyAuthenticator = document.querySelector('amplify-authenticator');
  const style = document.createElement('style');
  style.innerHTML = 'amplify-toast { display: none; }';
  amplifyAuthenticator?.shadowRoot?.appendChild(style);
}

export function parseError(payload: HubPayload): string {
  const msg: string = (payload.message ?? '')
    .replace('custom:first_name', 'First Name')
    .replace('custom:last_name', 'Last Name')
    .replace('custom:client_access_code', 'Access code')
    .replace('null', 'empty')
    .replace('Username', 'Email')
    .replace('/client id combination', '')
    .replace(/\./g, '')
    .replace('Password did not conform with policy: ', '');

  if (msg.includes('Reason:')) {
    return msg.split('Reason:')[1];
  }

  if (msg.includes('detected:')) {
    return msg.split('detected:')[1];
  }

  if (msg.includes('lambda trigger is not configured')) {
    return 'Password cannot be empty';
  }

  return msg;
}
