import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  statusOutlined: {
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(1.5),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
  },
  statusNormal: {
    color: theme.palette.text.primary,
  },
  statusUnknown: {
    color: theme.status.unknown,
  },
  statusOk: {
    color: theme.status.ok,
  },
  statusError: {
    color: theme.status.error,
  },
}));
