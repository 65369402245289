import { createMuiTheme, fade } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette';
import { CSSProperties } from 'react';

declare module '@material-ui/core/styles/createMuiTheme' {

  interface Theme {
    drawerWidth: number;
    palette: Palette;
    status: {
      ok: string;
      danger: string;
      error: string;
      success: string;
      unknown: string;
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    drawerWidth?: number;
    palette?: PaletteOptions;
    status?: {
      ok: CSSProperties['color'];
      danger: CSSProperties['color'];
      error: CSSProperties['color'];
      success: CSSProperties['color'];
      unknown: CSSProperties['color'];
    };
  }
}

type commonOverrides = { [key: string]: Record<string, unknown> };

export const colors = {
  textPrimary: '#4a4a4a',
  textSecondary: '#292a2c',
  // for some reason, #bbbbbb leads to invisible text in <input> in Safari, so we reverted it
  textDisabled: 'rgba(0, 0, 0, 0.38)',

  backgroundPaper: '#ffffff',
  backgroundDefault: '#fafafa',

  mainPrimary: '#2887cc',
  lightPrimary: '#69b7ff',
  darkPrimary: '#005b9b',

  errordark: '#d20127',

  darkgrey: '#2e2e2e',
  lightGrey: '#bbbbbb',

  mainOrange: '#f5a623',
  lightOrange: '#f7b74f',
  darkOrange: '#ab7418',

  lightRed: '#d20127',
  lightBlue: '#2887cc',
};

export const reactSelectOverrides: commonOverrides = {
  ReactSelect: {
    dropDown: {
      color: colors.textPrimary,
    },
    optionFocused: {
      backgroundColor: fade(colors.mainPrimary, 0.54),
    },
    option: {
      '&:hover': {
        backgroundColor: fade(colors.mainPrimary, 0.54),
      }
    },
  },
};

const originalTheme = createMuiTheme();

const outerTheme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.mainPrimary,
      light: colors.lightPrimary,
      dark: colors.darkPrimary,
    },
    secondary: {
      main: colors.mainPrimary,
      light: colors.lightPrimary,
      dark: colors.darkPrimary,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
      hint: '#bbbbbb',
    },
  },
  status: {
    ok: colors.mainPrimary,
    danger: orange[500],
    error: colors.errordark,
    success: colors.mainPrimary,
    unknown: fade(colors.textPrimary, 0.8),
  },
  overrides: {
    MuiChip: {
      root: {
        margin: originalTheme.spacing(0.1),
        borderRadius: 3,
        height: 'auto',

        '& $label': {
          padding: 4,
        }
      },
    },
    MuiTablePagination: {
      input: {
        marginRight: 8
      },
      actions: {
        marginLeft: 8
      }
    },
    MuiContainer: {
      root: {
        [originalTheme.breakpoints.down('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
        }
      }
    },
    MuiGrid: {
      'grid-xs-12': {
        maxWidth: '100vw'
      }
    },
    MuiLink: {
      root: {
        fontWeight: 500,
      }
    },
    ...reactSelectOverrides,
  }
});

export const fontFamilySecondary = `"Roboto-Regular", ${ outerTheme.typography.fontFamily }`;

export const fonts = {
  textPrimary: `"Roboto-Medium", ${ outerTheme.typography.fontFamily }`,
  textSecondary: `"Roboto-Regular", ${ outerTheme.typography.fontFamily }`,
};

export default outerTheme;
