import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  cardHeader: {
    paddingBottom: theme.spacing(0.5),
  },
  cardContent: {
    height: 200,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  },
  cardAction: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  stationFrequency: {
    fontFamily: `"Roboto-Regular", ${ theme.typography.fontFamily }`
  },
  stationAddress: {
    marginTop: theme.spacing(1),
  },
  stationHeader: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  flashIcon: {
    marginRight: theme.spacing(1),
  }
}));
