import { Permissions, PermissionsConfig, PERMISSIONS_CATEGORIES, PermissionsUpdateParams, PermissionsOperation } from 'models/user-management';
import { FormHookResult } from 'utils/form';

type Switches = Record<string, boolean>;

export type PermissionsEditorFormValues = {
  name: PermissionsUpdateParams['props']['name'];
  template: PermissionsUpdateParams['props']['template'];
  owner_id?: number;
  // permissions configs
  [PermissionsConfig.dm_zone]: Switches;
  [PermissionsConfig.dm_device]: Switches;
  [PermissionsConfig.user_management]: Switches;
  [PermissionsConfig.http_sender]: Switches;
  [PermissionsConfig.rmq_sender]: Switches;
  [PermissionsConfig.monitoring]: Switches;
  [PermissionsConfig.raw_messages]: Switches;
  [PermissionsConfig.devices]: Switches;
  [PermissionsConfig.fmw_management]: Switches;
  [PermissionsConfig.stations]: Switches;
  [PermissionsConfig.provisioning]: Switches;
}

function mapOperationsToSwitches(
  supportedOperations: string,
  allowedOperations: string,
): Switches {
  return Object.fromEntries(
    supportedOperations
      .split('')
      .map(operationKey => [operationKey, allowedOperations.includes(operationKey)])
  );
}

type SwitchesByFieldNameTuple = [PermissionsConfig, Switches];

export function mapPermissionsOperationsToSwitchesEntries(
  permissions: Partial<Permissions>,
): SwitchesByFieldNameTuple[] {
  return PERMISSIONS_CATEGORIES
    .map(category => [
      category.key,
      mapOperationsToSwitches(
        category.operations.map(o => o.key).join(''),
        permissions[category.key] || '',
      ),
    ]);
}

export function mapPermissionsOperationsToSwitches(permissions: Partial<Permissions>) {
  return Object.fromEntries(
    mapPermissionsOperationsToSwitchesEntries(permissions),
  ) as Record<SwitchesByFieldNameTuple['0'], SwitchesByFieldNameTuple['1']>;
}

function mapSwitchesToOperations(value: Record<string, boolean>) {
  return Object.entries(value)
    .filter(([_, checked]) => checked)
    .map(([operationKey]) => operationKey)
    .join('');
}

export function mapPermissionsToFormValues(permissions: Partial<Permissions>): PermissionsEditorFormValues {
  return {
    name: permissions.name || '',
    template: permissions.template || false,
    owner_id: permissions.owner_id,
    ...mapPermissionsOperationsToSwitches(permissions),
  };
}

interface DefaultValuesOptions {
  defaultPermissions?: Partial<Permissions>;
  permissions?: Permissions;
  isAdmin: boolean;
  editedUserGroupId: number;
}

export function getDefaultValues({
  defaultPermissions,
  permissions,
  isAdmin,
  editedUserGroupId,
}: DefaultValuesOptions): Partial<PermissionsEditorFormValues> {
  const defaultValues: Partial<PermissionsEditorFormValues> = {
    ...(defaultPermissions && mapPermissionsToFormValues(defaultPermissions)),
    ...(permissions && mapPermissionsToFormValues(permissions)),
  };

  if (defaultValues.owner_id === undefined && !isAdmin) {
    defaultValues.owner_id = editedUserGroupId;
  }

  return defaultValues;
}

type OperationsByFieldNameTuple = [PermissionsConfig, string];

export function mapFormValuesToPermissionsOperations(formValues: PermissionsEditorFormValues) {
  const operationsByFieldName: OperationsByFieldNameTuple[] = PERMISSIONS_CATEGORIES
    .map(category => [
      category.key,
      mapSwitchesToOperations(formValues[category.key]),
    ]);

  return Object.fromEntries(
    operationsByFieldName,
  ) as Record<OperationsByFieldNameTuple['0'], OperationsByFieldNameTuple['1']>;
}

export const isDisabledByRelation = (
  form: FormHookResult<PermissionsEditorFormValues>,
  operation: PermissionsOperation
): boolean => {
  if (!operation.relations) {
    return false;
  }

  for (const relation of operation.relations) {
    if (relation.type === 'lock') {
      const value = form.watch(`${ relation.config }.${ relation.key }`);
      if (value) {
        return true;
      }
    }
  }

  return false;
};

export const changeByRelation = (
  form: FormHookResult<PermissionsEditorFormValues>,
  operation: PermissionsOperation,
  value: boolean
): void => {
  if (!operation.relations) {
    return;
  }
  const values = form.getValues({ nest: true });
  for (const relation of operation.relations) {
    if (relation.type === 'enable' && value) {
      const value = values[relation.config];
      if (!value[relation.key]) {
        form.setValue(`${ relation.config }.${ relation.key }`, true);
      }
    }
  }
};
