import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { uniqBy } from 'lodash';
import { RootState } from 'reducers';
import { LoadPositionStateData } from 'actions/parking';
import { Marker } from 'utils/map';
import { useAutoFitMap } from 'hooks/map';
import { FilterState } from '../StateFilter/types';
import { MarkerType } from './types';
import { getMarkerColor } from './utils';

// components
import Box from '@material-ui/core/Box';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { BlockLoader } from 'components/Loaders';
import { CommonMap } from 'components/Map';
import { PositionStateMarker } from './Marker';

// Styles
import { useStyles } from './styles';

interface Props {
  filter: FilterState;
  markerType: MarkerType;
}

const Component = (props: Props): JSX.Element => {
  const { markerType, filter } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isFetching, data } = useSelector((state: RootState) => state.parking.positionState);

  useDeepCompareEffect(() => {
    dispatch(LoadPositionStateData({ params: filter }));
  }, [filter]);

  const [selectPosition, setSelectedPosition] = useState<string | undefined>(undefined);

  const positions = uniqBy(data, 'position_id');
  const markers = useMemo(() => positions.map((position): Marker => ({
    id: position.position_id,
    lat: position.lat ?? 0,
    lon: position.lon ?? 0
  })), [positions]);

  const { onLoad } = useAutoFitMap(markers);

  return (
    <Box className={ classes.root }>
      { isFetching ? <BlockLoader/> :
        <CommonMap
          defaultZoom={ 17 }
          defaultCenter={ { lat: 53.723865424172, lng: -1.8550449998982 } }
          onLoad={ onLoad }
        >
          <MarkerClusterer
            averageCenter
            ignoreHidden
            maxZoom={ 17 }
            gridSize={ 90 }
          >
            {
              positions.map(positionState =>
                <div key={ positionState.position_id } data-testid="map-marker">
                  <PositionStateMarker
                    positionState={ positionState }
                    color={ getMarkerColor(markerType, positionState) }
                    open={ selectPosition }
                    handleSelect={ setSelectedPosition }
                  />
                </div>
              )
            }
          </MarkerClusterer>
        </CommonMap>
      }
    </Box>
  );
};

export {
  Component as ParkingPositionStateMap,
  MarkerType as ParkingPositionStateMapMarker,
};
