import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';

const usePositionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      backgroundColor: 'transparent',
      marginBottom: 0,
      '& [class*="MuiTabs-scrollButtons"]': {
        width: 0,
      },
      ...margins(theme)
    },
  }),
);

export default usePositionsStyles;
