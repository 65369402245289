import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(theme => createStyles({
  templateName: {
    maxWidth: '200px',

    [theme.breakpoints.up('xl')]: {
      maxWidth: '350px',
    },
  },
}));
