import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) => createStyles({
  viewMode: {
  },
  editMode: {
    '& [class*="MuiCard-root"]': {
      overflow: 'visible',
    },
  }
}));
