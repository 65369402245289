import React from 'react';

import { Helmet } from 'react-helmet-async';
import { TemplateSuiteId } from 'models/caller';
import { useTemplateSuiteSelector } from 'hooks/caller';
import { getCallerTemplateCreateLocation } from 'routing/paths/caller';
// components
import Navigation, { NavigationTab } from './Navigation';
import Paper from '@material-ui/core/Paper';
import { TemplatesTable } from 'components/Caller';
import AddButton from 'components/Buttons/AddButton';
import { PageLoader } from 'components/Loaders';

interface Props {
  templateSuiteId: TemplateSuiteId;
}

const Component = ({ templateSuiteId }: Props): JSX.Element => {
  const { templateSuite } = useTemplateSuiteSelector(templateSuiteId);

  return (
    <>
      <Helmet><title>{ `Http Template Suite #${ templateSuiteId } ${ templateSuite && templateSuite.name } Templates` }</title></Helmet>
      <Navigation active={ NavigationTab.TEMPLATES } templateSuite={ templateSuite }/>
      <Paper>
        { !templateSuite
          ? <PageLoader />
          : <TemplatesTable params={ { templateSuiteId: templateSuite.template_suite_id } }/>
        }
        <AddButton LinkTo={ templateSuite && getCallerTemplateCreateLocation(templateSuite) } isDisabled={ !templateSuite }/>
      </Paper>
    </>
  );
};

export default Component;
