import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { DevicesFilterFields } from 'models/device-management';
import { CancelDeviceDownload, StartDeviceDownload } from 'actions/device-management/device-download';
import { useFirmwaresDictionarySelector } from 'hooks/firmware-management';

export interface State {
  total: number;
  ready: number;
  readyPercent: number;
  inProgress: boolean;

  download: (filter: DevicesFilterFields) => void;
  cancel: () => void;
}

export function useDeviceDownloadCSV(): State {
  const dispatch = useDispatch();
  const [inProgress, setProgressStatus] = useState<boolean>(false);
  const { isFetching, total, ready } = useSelector((state: RootState) => ({
    isFetching: state.deviceManagement.deviceDownload.isFetching,
    total: state.deviceManagement.deviceDownload.total,
    ready: state.deviceManagement.deviceDownload.ready,
  }));

  const { firmwares } = useFirmwaresDictionarySelector();

  useEffect(() => {
    inProgress !== isFetching && setProgressStatus(isFetching);
  }, [inProgress, isFetching]);

  const handleDownload = useCallback((filter: DevicesFilterFields) => {
    if (!isFetching) {
      dispatch(StartDeviceDownload(filter, firmwares));
      setProgressStatus(true);
    }
  }, [firmwares, dispatch, isFetching]);

  const handleCancel = useCallback(() => {
    if (isFetching) {
      dispatch(CancelDeviceDownload());
    }
  }, [dispatch, isFetching]);

  return {
    total,
    ready,
    inProgress: inProgress ?? true,
    readyPercent: Math.round((ready / (total || 1)) * 100),
    download: handleDownload,
    cancel: handleCancel,
  };
}