import * as connectivityClient from 'clients/connectivity';

import { ZoneNode, LevelNode, GroupNode, PositionNode } from '../types';
import { getResponseData } from 'utils/clients';

type ConnectivityByNode = Record<string, number | undefined>;

export async function fetchZoneConnectivity(
  nodes: ZoneNode[]
): Promise<ConnectivityByNode> {
  const zoneConnectivityEntries = getResponseData(
    await connectivityClient.fetchZonesAvgConn({
      zones: nodes.map(node => node.zone.id),
      devices: [],
      projects: [],
    }),
  );

  const connectivityByZone = Object.fromEntries(
    zoneConnectivityEntries.map(entry => [entry.zone_id, entry.connectivity])
  );

  const connectivityByNode = Object.fromEntries(
    nodes.map(node => {
      const zoneId = node.zone.id;
      const connectivity = connectivityByZone[zoneId];

      return [node.id, connectivity];
    }),
  );

  return connectivityByNode;
}

export async function fetchLevelConnectivity(
  nodes: LevelNode[]
): Promise<ConnectivityByNode> {
  const levelConnectivityEntries = getResponseData(
    await connectivityClient.fetchLevelsAvgConn({
      levels: nodes.flatMap(node => node.level ? [node.level.id] : []),
    }),
  );

  const connectivityByLevel = Object.fromEntries(
    levelConnectivityEntries.map(entry => [entry.level_id, entry.connectivity])
  );

  const connectivityByNode = Object.fromEntries(
    nodes.map(node => {
      if (!node.level) {
        return [node.id, undefined];
      }

      const levelId = node.level.id;
      const connectivity = connectivityByLevel[levelId];

      return [node.id, connectivity];
    }),
  );

  return connectivityByNode;
}

export async function fetchGroupConnectivity(
  nodes: GroupNode[]
): Promise<ConnectivityByNode> {
  const groupConnectivityEntries = getResponseData(
    await connectivityClient.fetchGroupsAvgConn({
      groups: nodes.map(node => node.group.id),
    }),
  );

  const connectivityByGroup = Object.fromEntries(
    groupConnectivityEntries.map(entry => [entry.group_id, entry.connectivity])
  );

  const connectivityByNode = Object.fromEntries(
    nodes.map(node => {
      const groupId = node.group.id;
      const connectivity = connectivityByGroup[groupId];

      return [node.id, connectivity];
    }),
  );

  return connectivityByNode;
}

export async function fetchPositionConnectivity(
  nodes: PositionNode[]
): Promise<ConnectivityByNode> {
  const positionConnectivityEntries = getResponseData(
    await connectivityClient.fetchPositionsConn({
      positions: nodes.map(node => node.position.id),
    }),
  );

  const connectivityByPosition = Object.fromEntries(
    positionConnectivityEntries.map(entry => [entry.position_id, entry.connectivity])
  );

  const connectivityByNode = Object.fromEntries(
    nodes.map(node => {
      const positionId = node.position.id;
      const connectivity = connectivityByPosition[positionId];

      return [node.id, connectivity];
    }),
  );

  return connectivityByNode;
}
