import { DevicesStatesFiltersFields, DevicesStatesFiltersQuery } from 'models';
import { DeviceStatesV2 } from 'models/device-monitoring';

export const FETCH_DEVICES_STATES_START_FILTERS = 'FETCH_DEVICES_STATES_START_FILTERS';
export const FETCH_DEVICES_STATES_FAILED = 'FETCH_DEVICES_STATES_FAILED';
export const FETCH_DEVICES_STATES_SUCCESS = 'FETCH_DEVICES_STATES_SUCCESS';

export const SET_DEVICES_STATES_FILTERS = 'SET_DEVICES_STATES_FILTERS';

export const DEVICE_STATES_FETCH_REPORT_CSV = 'DEVICE_STATES_FETCH_REPORT_CSV';
export const DEVICE_STATES_FETCH_REPORT_CSV_END = 'DEVICE_STATES_FETCH_REPORT_CSV_END';

export interface GetDevicesStatesFilterQuery {
  readonly type: typeof FETCH_DEVICES_STATES_START_FILTERS;
  readonly query: DevicesStatesFiltersQuery;
}
export interface GetDevicesStatesSuccess {
  readonly type: typeof FETCH_DEVICES_STATES_SUCCESS;
  payload: DeviceStatesV2[];
  total: number;
}
export interface GetDevicesStatesFailed {
  readonly type: typeof FETCH_DEVICES_STATES_FAILED;
}

export interface SetDeviceStatesFilters {
  readonly type: typeof SET_DEVICES_STATES_FILTERS;
  filters: DevicesStatesFiltersFields;
  pushFiltersToUrl: boolean;
}

export interface FetchReport {
  readonly type: typeof DEVICE_STATES_FETCH_REPORT_CSV;
  filters: DevicesStatesFiltersFields;
}
export interface FetchReportEnd {
  readonly type: typeof DEVICE_STATES_FETCH_REPORT_CSV_END;
}

export const DoGetDevicesStatesFilters: (query: DevicesStatesFiltersQuery) => GetDevicesStatesFilterQuery = (query) => ({
  type: FETCH_DEVICES_STATES_START_FILTERS,
  query
});
export const DoGetDevicesStatesSuccess: (payload: DeviceStatesV2[], total: number) => GetDevicesStatesSuccess = (payload, total) => ({
  type: FETCH_DEVICES_STATES_SUCCESS,
  payload,
  total,
});
export const DoGetDevicesStatesFailed: () => GetDevicesStatesFailed = () => ({
  type: FETCH_DEVICES_STATES_FAILED
});

// zamenit'
export const DoSetDeviceStatesFilters = (filters: DevicesStatesFiltersFields, pushFiltersToUrl: boolean): SetDeviceStatesFilters => ({
  type: SET_DEVICES_STATES_FILTERS,
  filters,
  pushFiltersToUrl,
});

export const DoFetchReport: (filters: DevicesStatesFiltersFields) => FetchReport = (filters) => ({
  type: DEVICE_STATES_FETCH_REPORT_CSV,
  filters
});
export const DoFetchReportEnd: () => FetchReportEnd = () => ({
  type: DEVICE_STATES_FETCH_REPORT_CSV_END,
});

export type Action =
  GetDevicesStatesSuccess |
  GetDevicesStatesFilterQuery |
  GetDevicesStatesFailed |
  SetDeviceStatesFilters |
  FetchReportEnd |
  FetchReport;
