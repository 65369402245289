import { API } from 'aws-amplify';
import { ApiResponse } from 'models';
import { GetUserGroupParams, UserGroupCreate, UserGroupServiceName, UserGroupUpdate } from 'models/user-management';
import { errorHandler, formatQuery } from 'clients/utils';
import { config, urls } from './config';

export const fetchUserGroupsByParams = (params: GetUserGroupParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.userGroups, body)
    .catch(errorHandler);
};

export const fetchUserGroupsById = (userGrouId: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.userGroupById(userGrouId), {})
    .catch(errorHandler);
};

export const createUserGroup = (userGroup: UserGroupCreate): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.userGroups, { body: userGroup })
    .catch(errorHandler);
};

export const updateUserGroup = (userGroupId: number, userGroup: UserGroupUpdate): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.userGroupById(userGroupId), { body: userGroup })
    .catch(errorHandler);
};

export const deleteUserGroup = (userGroupId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.userGroupById(userGroupId), {})
    .catch(errorHandler);
};

export const refreshUserGroupAccessCode = (userGroupId: number): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.userGroupAccessCodeRefresh(userGroupId), {})
    .catch(errorHandler);
};

export const fetchServicesByGroupId = (userGrouId: number): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.servicesByGroupId(userGrouId), {})
    .catch(errorHandler);
};

export const deactivateService = (userGrouId: number, serviceName: UserGroupServiceName): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.deactivateUserGroupService(userGrouId, serviceName), {})
    .catch(errorHandler);
};

export const activateService = (userGrouId: number, serviceName: UserGroupServiceName): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.activateUserGroupService(userGrouId, serviceName), {})
    .catch(errorHandler);
};
