import { Device, PositionedDeviceData, SoftType } from '../device-management';

export enum DeviceType {
  CAR_COUNTER = 'car-counter',
  PARKING_SENSOR = 'parking-sensor',
}

export const getDeviceType = (device?: Device | PositionedDeviceData): DeviceType => {
  if (!device) {
    return DeviceType.PARKING_SENSOR;
  }

  if (
    device.soft_type === SoftType.StandaloneCarCounter
    && device.high_level_protocol_version === '1.0'
  ) {
    return DeviceType.CAR_COUNTER;
  }

  return DeviceType.PARKING_SENSOR;
};