import { useProjectsDictionarySelector, useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { Zone } from 'models/device-management';

export const useZoneProjectName = (zone: Zone): string | undefined => {
  const { isLoading, projects } = useProjectsDictionarySelector();
  if (!zone.project_id) {
    return 'Default';
  }

  if (isLoading) {
    return undefined;
  }

  const project = projects.find(project => project.id === zone.project_id);

  return project?.name;
};

export const useOwnerName = (ownerId: number): string | undefined => {
  const { isLoading, userGroups } = useUserGroupsDictionarySelector();
  if (isLoading) {
    return undefined;
  }

  const userGroup = userGroups.find(userGroup => userGroup.id === ownerId);

  return userGroup?.name;
};