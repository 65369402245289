import { useState, useEffect } from 'react';

import { PositionsFilterFields, initPositionsFilters } from 'models/device-management/filters';
import { PositionsTableFilter } from 'components/DeviceManagement';

// components
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

type Props = {
  isLoading: boolean;
  filters: PositionsFilterFields;
  updateFilters: (filters: PositionsFilterFields) => void;
}

export const PositionsFilter = ({ isLoading, filters, updateFilters }: Props): JSX.Element => {
  const classes = useStyles();
  const [filterState, setFilterState] = useState<PositionsFilterFields>(filters);
  const onSave = () => updateFilters(filterState);
  const onReset = () => {
    setFilterState(initPositionsFilters);
    updateFilters(initPositionsFilters);
  };

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  return (<div className={ classes.toolbar }>
    <FilterButton
      isLoading={ isLoading }
      filter={ filters }
      onSave={ onSave }
      onReset={ onReset }
    >
      <PositionsTableFilter
        state={ filterState }
        setState={ setFilterState }
      />
    </FilterButton>
  </div>);
};
