import { VirtualDevicesFiltersFields } from 'models/device-management/filters';

// components
import { FirmwareSelectControl, SingleSelectControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';

// styles
import useStyles from 'styles/filters';
import { VIRTUAL_FIRMWARE } from 'models/firmware-management';

interface Props {
  state: VirtualDevicesFiltersFields;
  setState: (filters: VirtualDevicesFiltersFields) => void;
}

export const VirtualDevicesFilter = ({ state, setState }: Props): JSX.Element => {
  const classes = useStyles();

  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner });
  const handleSelectActive = (isActive: boolean | undefined) => setState({ ...state, isActive });
  const handleSelectFirmware = (firmwareHash: string | undefined) => setState({ ...state, firmwareHash });

  const activeOptions = [{ label: 'Active', value: true }, { label: 'Not Active', value: false }];
  return (
    <form autoComplete="off" className={ classes.gridRoot }>
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner }
        isProjectShow={ false }
        isZoneShow={ false }
        isGroupsShow={ false }
      />
      <SingleSelectControl
        selected={ state.isActive }
        label="Activation Status"
        values={ activeOptions }
        changeHandler={ handleSelectActive }
        name="active"
        isClearable
      />
      <FirmwareSelectControl
        label="Firmware"
        selected={ state.firmwareHash }
        onChange={ handleSelectFirmware }
        filter={ firmware => Object.values(VIRTUAL_FIRMWARE).includes(firmware.hash as VIRTUAL_FIRMWARE)}
        isClearable
      />
    </form>
  );
};
