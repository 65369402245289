import { Action, SET_USER_DATA, UserPayload } from 'actions/user-profile';

export const ADMIN_ROLE = 'admin';

export interface State {
  data: UserPayload;
  isAdmin: boolean;
  clientId: number;
  signedIn: boolean;
}

const initState: State = {
  data: {},
  isAdmin: false,
  clientId: 0,
  signedIn: false
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        data: action.user,
        isAdmin: action.user.role === ADMIN_ROLE,
        clientId: Number(action.user.user_group),
        signedIn: true
      };
    default:
      return state;
  }
};
