import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallAnomalyBox: {
      width: '100%',
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      }
    },
    anomalyBox: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(4),
    },
    container: {
      padding: '20px 0',
      '& [class*="MuiGrid-root"]': {
        alignItems: 'center'
      }
    },
    dialog: {
      height: '100%',
    }
  }),
);
