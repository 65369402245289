import React from 'react';
// components
import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface Props {
  tabsCount: number;
  SkeletonProps?: Partial<SkeletonProps>;
}

export default (props: Props): JSX.Element => {
  const { tabsCount, SkeletonProps } = props;
  const tabs = Array.from({ length: tabsCount }, (_value, index) => (
    <Tab key={ index } value={ index } label={ <Skeleton variant="rect" width="80%"  { ...SkeletonProps }/> }/>
  ));

  return (
    <Tabs value={ false } indicatorColor="secondary" textColor="primary">{ tabs }</Tabs>
  );
};
