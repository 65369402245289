import { UserGroup } from 'models/user-management';

export const UM_USER_GROUP_CREATE = 'UM_USER_GROUP_CREATE';
export const UM_USER_GROUP_UPDATE = 'UM_USER_GROUP_UPDATE';
export const UM_USER_GROUP_DELETE = 'UM_USER_GROUP_DELETE';
export const UM_USER_GROUP_REFRESH_ACCESS_CODE = 'UM_USER_GROUP_REFRESH_ACCESS_CODE';

export interface CreateUserGroup {
  readonly type: typeof UM_USER_GROUP_CREATE;
  userGroup: UserGroup;
}

export interface UpdateUserGroup {
  readonly type: typeof UM_USER_GROUP_UPDATE;
  userGroup: UserGroup;
}

export interface DeleteUserGroup {
  readonly type: typeof UM_USER_GROUP_DELETE;
  userGroup: UserGroup;
}

export interface RefreshUserGroupAccessCode {
  readonly type: typeof UM_USER_GROUP_REFRESH_ACCESS_CODE;
  userGroup: UserGroup;
}

export const CreateUserGroup = (userGroup: UserGroup): CreateUserGroup => ({
  type: UM_USER_GROUP_CREATE, userGroup
});

export const UpdateUserGroup = (userGroup: UserGroup): UpdateUserGroup => ({
  type: UM_USER_GROUP_UPDATE, userGroup
});

export const DeleteUserGroup = (userGroup: UserGroup): DeleteUserGroup => ({
  type: UM_USER_GROUP_DELETE, userGroup
});


export const RefreshUserGroupAccessCode = (userGroup: UserGroup): RefreshUserGroupAccessCode => ({
  type: UM_USER_GROUP_REFRESH_ACCESS_CODE, userGroup
});

export type Action =
  | CreateUserGroup
  | UpdateUserGroup
  | DeleteUserGroup
  | RefreshUserGroupAccessCode
