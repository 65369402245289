import * as Actions from 'actions/caller';
import { TemplateStatistic } from 'models/caller';
import { Repository } from 'utils/repository';

export type State = Repository<TemplateStatistic, Record<string, unknown>, 'template_id'>;
const initState: State = Repository.create('template_id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.CALLER_TEMPLATE_STATISTIC_FETCH_START:
      return state.setFetched(action.templateId, false);
    case Actions.CALLER_TEMPLATE_STATISTIC_FETCH_STOP:
      return state.setFetched(action.templateId, true);
    case Actions.CALLER_TEMPLATE_STATISTIC_CHANGE:
      return state.setData(action.templateId, action.data);

    default:
      return state;
  }
};
