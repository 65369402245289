import { useQuery } from 'react-query';
import { PositionedDeviceData } from 'models/device-management';
import { DevicesFilterFields } from 'models/device-management/filters';
import { useFormActionNotifier } from 'hooks';
import { getResponseData } from 'utils/clients';
import { fetchDevices } from 'clients/device-management';

type Options = {
  params: DevicesFilterFields;
}

export function useDevices({ params }: Options) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/devices', params],
    queryFn: async () => {
      const res = await fetchDevices(params);
      return {
        data: getResponseData(res),
        total: res.total
      };
    },
    cacheTime: 10 * 60 * 1000, // 10 min,
    staleTime: 10 * 60 * 1000, // 10 min,
    refetchInterval: 10 * 60 * 1000, // 10 min,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
    onError: e => {
      notifyError(`Error while fetching devices: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: queryResult.isLoading,
    devices: queryResult.data?.data || [],
    total: queryResult.data?.total || 0
  };
}

type GetDevicesByIdBulk = {
  devicesIds: string[]
  enabled: boolean
};

const CHUNK_SIZE = 10;
export const useGetDevicesByIdBulk = ({
  devicesIds,
  enabled = true,
}: GetDevicesByIdBulk) => {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['deviceManagement/devices-bulk', devicesIds],
    queryFn: async () => {
      let cursor = 0;
      const reqFn = async (ids: string[]): Promise<PositionedDeviceData[]> => {
        cursor = cursor + CHUNK_SIZE;
        const res = getResponseData(await fetchDevices({ devices: ids, keysEnabled: true }));
        const nextChunkIds = devicesIds.slice(cursor, cursor + CHUNK_SIZE);
        return nextChunkIds.length > 0 ? [ ...res, ...await reqFn(nextChunkIds) ] : res;
      };
      return reqFn(devicesIds.slice(cursor, cursor + CHUNK_SIZE));
    },
    onError: e => {
      notifyError(`Error while fetching devices: ${ (e as Error).message }`);
    },
    enabled: enabled && devicesIds.length > 0
  });

  return {
    isDevicesBulkLoading: queryResult.isLoading,
    devices: queryResult.data || [],
  };
};