import { ZoneFilters } from 'models/device-management/filters';

// components
import { LogicalSelectControl } from 'components/Controls';
import GroupedFilters from 'components/Controls/GroupedFilters/GroupedFiltersComponent';

// styles
import useStyles from 'styles/filters';

interface Props {
  state: ZoneFilters;
  setState: (filters: ZoneFilters) => void;
}

const ZonesFilter = ({ state, setState }: Props): JSX.Element => {
  const classes = useStyles();

  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner });
  const handleSelectProjects = (projects: number[]) => setState({ ...state, projects });

  return (
    <form autoComplete="off" className={ classes.gridRoot }>
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner }
        projects={ state.projects }
        handleSelectProjects={ handleSelectProjects }
        isProjectShow
        isZoneShow={ false }
        isGroupsShow={ false }
      />
      <LogicalSelectControl
        isClearable
        name="live"
        label="Live Status"
        labelYes="Live"
        labelNo="Not Live"
        selected={ state.liveStatus }
        onChange={ liveStatus => setState({ ...state, liveStatus }) }
      />
    </form>
  );
};

export default ZonesFilter;
