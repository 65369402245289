import { useDispatch } from 'react-redux';
import { NotifyError, NotifySuccess, NotifyWarning } from 'actions/notifier';

interface NotifierState {
  notifySuccess: (message: string) => void;
  notifyWarning: (message: string) => void;
  notifyError: (message: string) => void;
}

export function useFormActionNotifier(): NotifierState {
  const dispatch = useDispatch();
  return {
    notifyError: (message: string) => dispatch(NotifyError(message)),
    notifyWarning: (message: string) => dispatch(NotifyWarning(message)),
    notifySuccess: (message: string) => dispatch(NotifySuccess(message)),
  };
}
