import React from 'react';
import { Endpoint } from 'models/rabbit';
import { useDialog } from 'hooks';
import { getRabbitEndpointListPath } from 'routing/paths';
import { useManagementForm } from './utils';

// components
import { Box, Paper } from '@material-ui/core';
import { CloneButton, DeleteButton, SaveButton } from 'components/Buttons';
import { EnsureDialog } from 'components/Dialogs';
import { DeleteEndpointDialog } from 'components/Rabbit/Dialogs';
import { ManagementForm } from './widget/ManagementForm';
import { CloneDialog } from './widget/CloneDialog';
// styles
import useStyles from './style';

interface Props {
  endpoint: Endpoint;
}

export const EndpointManagement = ({ endpoint }: Props): JSX.Element => {
  const classes = useStyles();
  const { form, onSave, onCancel } = useManagementForm(endpoint);
  const ensureCloneDialog = useDialog();
  const cloneDialog = useDialog();
  const deleteDialog = useDialog();

  return (
    <Paper className={ classes.root } data-testid="rabbit-endpoint-management">
      <ManagementForm { ...form } />
      <Box>
        { form.isChange
          ? (
            <>
              <SaveButton type="text" label="Save" onClick={ onSave } />
              <DeleteButton type="text" label="Cancel" onClick={ onCancel } />
            </>
          ) : (
            <>
              <CloneButton type="text" onClick={ ensureCloneDialog.open } />
              <DeleteButton type="text" onClick={ deleteDialog.open } />
            </>
          ) }
      </Box>
      { deleteDialog.isMounted && (
        <DeleteEndpointDialog
          endpoint={endpoint}
          isOpen={ deleteDialog.isOpen }
          onClose={ deleteDialog.close }
          onCloseEnd={ ensureCloneDialog.unmount }
          onSuccess={ () => ({
            push: true,
            to: getRabbitEndpointListPath(),
          }) }
        />
      )}
      { ensureCloneDialog.isMounted && (
        <EnsureDialog
          name="rabbit-endpoint-clone-ensure-dialog"
          isOpen={ ensureCloneDialog.isOpen }
          onClose={ ensureCloneDialog.close }
          onCloseEnd={ ensureCloneDialog.unmount }
          onYes={ cloneDialog.open}
        >
            Are you sure you want to make a copy of endpoint “{endpoint.name}"?
        </EnsureDialog>
      ) }
      { cloneDialog.isMounted && (
        <CloneDialog
          testId="rabbit-endpoint-clone-dialog"
          endpoint={ endpoint }
          isOpen={ cloneDialog.isOpen }
          onClose={ cloneDialog.close }
          onCloseEnd={ cloneDialog.unmount }
        />
      ) }
    </Paper>
  );
};
