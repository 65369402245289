import React from 'react';
import { isString } from 'lodash';
import { stringify } from 'query-string';
import { GOOGLE_STATIC_MAP_URL } from 'utils/map';
import { UrlItems } from 'utils/routing/query';
// components
import CardMedia from '@material-ui/core/CardMedia';

// @see https://developers.google.com/maps/documentation/maps-static/dev-guide
interface StaticMapProps {
  title?: string;
  className?: string;
  center?: string | { lat: number; lon: number };
  markers?: Marker[];
  heigth?: number;
  width?: number;
  zoom?: number;
  scale?: 1 | 2 | 4;
  type?: 'roadmap' | 'satellite' | 'terrain' | 'hybrid';
  format?: 'png' | 'gif' | 'jpg';
  language?: string;
  region?: string;

  // styling map
  disablePositionElement?: boolean;
  disableAdministrativeElement?: boolean;
  disableRoadIconElement?: boolean;
  disableTransitElement?: boolean;
}

interface Marker {
  label?: string;
  size?: 'tiny' | 'mid' | 'small';
  color?: string | 'black' | 'brown' | 'green' | 'purple' | 'yellow' | 'blue' | 'gray' | 'orange' | 'red' | 'white';
  location: string | { lat: number; lon: number };
}

const defaultProps: StaticMapProps = {
  title: 'Map',
  heigth: 600,
  width: 600,
  zoom: 15,
  scale: 1,
  format: 'png',
  type: 'roadmap',
  language: 'en-GB',
  region: 'GB',
};

export const StaticMap = (props: StaticMapProps): JSX.Element => {
  const params: UrlItems = {
    size: `${ props.width ?? defaultProps.width }x${ props.heigth ?? defaultProps.heigth }`,
    center: props.center !== undefined
      ? isString(props.center) ? props.center : `${ props.center.lat },${ props.center.lon }`
      : '',
    zoom: props.zoom ?? defaultProps.zoom,
    scale: props.scale ?? defaultProps.scale,
    format: props.format ?? defaultProps.format,
    maptype: props.type ?? defaultProps.type,
    language: props.language ?? defaultProps.language,
    region: props.region ?? defaultProps.region,
    markers: !props.markers ? undefined : props.markers.map(marker => {
      let result = '';
      if (marker.label) {
        result += `size:${ marker.label }|`;
      }
      if (marker.size) {
        result += `size:${ marker.size }|`;
      }
      if (marker.color) {
        result += `color:${ marker.color }|`;
      }
      result += isString(marker.location) ? marker.location : `${ marker.location.lat },${ marker.location.lon }`;
      return result;
    }),
    style: [
      ...(props.disablePositionElement ? ['feature:poi|visibility:off'] : []),
      ...(props.disableAdministrativeElement ? ['feature:administrative|visibility:off'] : []),
      ...(props.disableRoadIconElement ? ['feature:road|element:labels.icon|visibility:off'] : []),
      ...(props.disableTransitElement ? ['feature:transit|visibility:off'] : []),
    ],
  };

  return (
    <CardMedia
      className={ props.className }
      image={ GOOGLE_STATIC_MAP_URL + stringify(params, { arrayFormat: 'none', skipNull: true }) }
      title={ props.title ?? defaultProps.title }
    />
  );
};


