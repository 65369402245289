import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { PositionedDeviceData, PositionsFilterFields } from 'models/device-management';
import { DoFetchDevicesByAccumulatedPosition, DoFindPositionedDevicesRecursive } from 'actions/device-management/devices';

interface State {
  devices: PositionedDeviceData[];
  isLoading: boolean;
}

export function usePositionedDevicesParamsSelector(params: PositionsFilterFields): State {
  const dispatch = useDispatch();
  const { isFetched, devices } = useSelector((state: RootState) => ({
    isFetched: state.deviceManagement.devices.isFetched,
    devices: state.deviceManagement.devices.data,
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(DoFindPositionedDevicesRecursive({
        projects: params.projects,
        zones: params.zones || [],
        owner: params.owner,
        groupIds: params.groupIds || [],
      }));
    }
  }, [isFetched, params, dispatch]);

  return {
    devices: devices,
    isLoading: isFetched !== true
  };
}

interface PositionedDeviceState {
  device?: PositionedDeviceData;
  isLoading: boolean;
}
export function usePositionedDeviceSelector(positionId: number): PositionedDeviceState {
  const dispatch = useDispatch();
  const { isFetched, device } = useSelector((state: RootState) => ({
    isFetched: state.deviceManagement.devices.accumulatedDataLoaded,
    device: Object.values(state.deviceManagement.devices.positionedDevicesById)
      .find(device => device && device.position_id === positionId) as PositionedDeviceData | undefined
  }));

  useEffectOnce(() => {
    if (!device) {
      dispatch(DoFetchDevicesByAccumulatedPosition(positionId));
    }
  });

  return {
    device: device,
    isLoading: isFetched !== true
  };
}
