import { RawOnline } from 'models';
import { HistoryStatistic } from 'models/rabbit';
import { Statistic as HttpStatistic } from 'models/caller';
import { ConnectivityChartData } from 'models/connectivity';
import { StatisticRawMessage } from 'actions/device-statistic';

import {
  Action,
  FETCH_CONNECTIVITY_STATISTIC_START,
  FETCH_CONNECTIVITY_STATISTIC_SUCCESS,
  FETCH_CONNECTIVITY_STATISTIC_FAILED,
  FETCH_RAW_ONLINE_START,
  FETCH_RAW_ONLINE_SUCCESS,
  FETCH_RAW_ONLINE_FAILED,
  FETCH_STAT_RMQ_START,
  FETCH_STAT_RMQ_SUCCESS,
  FETCH_STAT_RMQ_FAILED,
  FETCH_STAT_HTTTPCALLS_SUCCESS,
  FETCH_STAT_HTTTPCALLS_START,
  FETCH_STAT_HTTTPCALLS_FAILED,
  FETCH_STAT_RAW_MESSAGES_START,
  FETCH_STAT_RAW_MESSAGES_SUCCESS,
  FETCH_STAT_RAW_MESSAGES_FAILED,
  FETCH_MAIN_PAGE_STATISTIC
} from 'actions/main-statistic';

export interface State {
  connectivity: {
    data: ConnectivityChartData[];
    fetching: boolean;
  };
  rawOnline: {
    data: RawOnline[];
    fetching: boolean;
  };
  caller: {
    data: HistoryStatistic[];
    fetching: boolean;
  };
  rawStatistic: {
    data: StatisticRawMessage[];
    fetching: boolean;
  };
  rmqSender: {
    data: HistoryStatistic[];
    fetching: boolean;
  };
  httpCalls: {
    data: HttpStatistic[];
    fetching: boolean;
  };
}

const initState: State = {
  connectivity: {
    data: [],
    fetching: true,
  },
  rawOnline: {
    data: [],
    fetching: true,
  },
  caller: {
    data: [],
    fetching: true,
  },
  rawStatistic: {
    data: [],
    fetching: true,
  },
  rmqSender: {
    data: [],
    fetching: true,
  },
  httpCalls: {
    data: [],
    fetching: true,
  },
};

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case FETCH_RAW_ONLINE_START:
      return {
        ...state,
        rawOnline: {
          ...state.rawOnline,
          fetching: true
        }
      };
    case FETCH_RAW_ONLINE_SUCCESS:
      return {
        ...state,
        rawOnline: {
          data: action.payload,
          fetching: false
        }
      };
    case FETCH_RAW_ONLINE_FAILED:
      return {
        ...state,
        rawOnline: {
          data: [],
          fetching: false
        }
      };

    case FETCH_CONNECTIVITY_STATISTIC_START:
      return {
        ...state,
        connectivity: {
          ...state.connectivity,
          fetching: true
        }
      };
    case FETCH_CONNECTIVITY_STATISTIC_SUCCESS:
      return {
        ...state,
        connectivity: {
          data: action.payload,
          fetching: false
        }
      };
    case FETCH_CONNECTIVITY_STATISTIC_FAILED:
      return {
        ...state,
        connectivity: {
          data: [],
          fetching: false
        }
      };


    case FETCH_STAT_RMQ_START:
      return {
        ...state,
        rmqSender: {
          ...state.rmqSender,
          fetching: true
        }
      };
    case FETCH_STAT_RMQ_SUCCESS:
      return {
        ...state,
        rmqSender: {
          data: action.payload,
          fetching: false
        }
      };
    case FETCH_STAT_RMQ_FAILED:
      return {
        ...state,
        rmqSender: {
          data: [],
          fetching: false
        }
      };

    case FETCH_STAT_HTTTPCALLS_START:
      return {
        ...state,
        httpCalls: {
          ...state.httpCalls,
          fetching: true
        }
      };
    case FETCH_STAT_HTTTPCALLS_SUCCESS:
      return {
        ...state,
        httpCalls: {
          data: action.payload,
          fetching: false
        }
      };
    case FETCH_STAT_HTTTPCALLS_FAILED:
      return {
        ...state,
        httpCalls: {
          data: [],
          fetching: false
        }
      };


    case FETCH_STAT_RAW_MESSAGES_START:
      return {
        ...state,
        rawStatistic: {
          ...state.rawStatistic,
          fetching: true
        }
      };
    case FETCH_STAT_RAW_MESSAGES_SUCCESS:
      return {
        ...state,
        rawStatistic: {
          data: action.payload,
          fetching: false
        }
      };
    case FETCH_STAT_RAW_MESSAGES_FAILED:
      return {
        ...state,
        rawStatistic: {
          data: [],
          fetching: false
        }
      };
    case FETCH_MAIN_PAGE_STATISTIC:
      return {
        ...state,
        rawStatistic: {
          ...state.rawStatistic,
          fetching: !action.quiet
        },
        rawOnline: {
          ...state.rawOnline,
          fetching: !action.quiet
        },
        httpCalls: {
          ...state.httpCalls,
          fetching: !action.quiet
        },
        rmqSender: {
          ...state.rmqSender,
          fetching: !action.quiet
        },
        connectivity: {
          ...state.connectivity,
          fetching: !action.quiet
        }
      };
    default:
      return state;
  }
};

