import { FormField, FormState } from './types';
import { FormErrors } from 'types/form';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};

  if (fields.includes(FormField.zone)) {
    if (!state[FormField.zone]) {
      errors[FormField.zone] = 'Zone is required';
    }
  }

  if (fields.includes(FormField.name)) {
    if (!state[FormField.name]) {
      errors[FormField.name] = 'Label name is required';
    } else {
      const name = String(state[FormField.name]);
      if (name.length > 32) {
        errors[FormField.name] = 'Must not contain more than 32 symbols';
      }
    }
  }

  if (fields.includes(FormField.floor)) {
    if (!state[FormField.floor]) {
      errors[FormField.floor] = 'Floor number is required';
    } else {
      const floor = parseInt(String(state[FormField.floor]));
      if (!Number.isInteger(floor)) {
        errors[FormField.floor] = 'Must be an integer';
      } else if (floor < -200) {
        errors[FormField.floor] = 'Must be greater or equal to -200';
      } else if (floor > 200) {
        errors[FormField.floor] = 'Must be lower or equal to 200';
      }
    }
  }

  return errors;
};
