import { BaseStation, LorawanStation, StationType } from 'models/base-station';

// components
import { Grid, Paper } from '@material-ui/core';
import { LocationBlock } from './LocationBlock';
import { PersonBlock } from './PersonBlock';
import { StationMonitoring } from '../StationMonitoring/StationMonitoringComponent';

// styles
import useStyles from './style';

interface Props {
  readonly station: BaseStation | LorawanStation;
}

export const BaseStationManagement = ({ station }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={ classes.root }>
      <Grid container spacing={ 0 }>
        <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
          <LocationBlock station={ station } />
          { station.location && <PersonBlock
            stationLabel={ station.type === StationType.lorawan ? station.eui : String(station.id) }
            location={ station.location }
          /> }
        </Grid>
        <Grid item xs={ 12 } sm={ 12 } md={ 8 }>
          <StationMonitoring station={ station } />
        </Grid>
      </Grid>
    </Paper>
  );
};
