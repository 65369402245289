import React from 'react';

// components
import { FiberManualRecord } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

// styles
import { styled } from 'styles/utils';

interface StatusProps {
  children?: React.ReactNode;
  iconHidden?: boolean;
  iconColor?: string;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

export const Status: React.FC<StatusProps> = ({ children, iconColor, iconHidden }) => {
  return (
    <Container>
      {!iconHidden && (
        <FiberManualRecord
          style={{ color: iconColor }}
          fontSize="inherit"
        />
      )}
      {children && (
        <Typography variant="caption">
          {children}
        </Typography>
      )}
    </Container>
  );
};
