import * as Actions from 'actions/parking';
import { GetPositionStateParams, PositionState } from 'models/parking';

export interface State {
  isFetching: boolean;
  isLoading: boolean;
  params: GetPositionStateParams;
  data: PositionState[];
}

const initState: State = {
  isFetching: false,
  isLoading: false,
  params: {},
  data: [],
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.PARKING_POSITION_STATE_DATA_FETCH_START:
      return { ...state, isFetching: true };
    case Actions.PARKING_POSITION_STATE_DATA_FETCH_STOP:
      return { ...state, isFetching: false };
    case Actions.PARKING_POSITION_STATE_DATA_LOADING:
      return { ...state, isLoading: action.status };
    case Actions.PARKING_POSITION_STATE_DATA_CHANGE:
      return { ...state, params: action.params, data: action.data };
    default:
      return state;
  }
};
