import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import { LocationUpdate, StationLocation } from 'models/base-station';
import * as Action from 'actions/base-station';
import { LoadStationLocationDataFailure, LoadStationLocationDataStart, LoadStationLocationDataSuccess } from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createLocation, deleteLocation, getLocationsByParams, updateLocation } from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import { clearQueryCache } from 'utils/react-query';

function* loadLocationDataById(action: Action.LoadStationLocationDataById) {
  yield put(LoadStationLocationDataStart(action.locationId));
  const response: ApiResponse = yield call(getLocationsByParams, { locationIds: [action.locationId] });
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadStationLocationDataSuccess(action.locationId, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadStationLocationDataFailure(action.locationId, message));
    yield put(NotifyError(`Error while fetching base station location: ${ message }`));
  }
}

function* loadLocationDataByParams(action: Action.LoadStationLocationDataByParams) {
  yield put(LoadStationLocationDataStart(action.params));
  const response: ApiResponse = yield call(getLocationsByParams, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadStationLocationDataSuccess(action.params, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadStationLocationDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching base station locations: ${ message }`));
  }
}

function* loadLocationDataDictionary(_action: Action.LoadStationLocationDataDictionary) {
  yield put(LoadStationLocationDataStart({}));
  const response: ApiResponse = yield call(getLocationsByParams, {});
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadStationLocationDataSuccess({}, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadStationLocationDataFailure({}, message));
    yield put(NotifyError(`Error while fetching base station locations: ${ message }`));
  }
}

type UpdateAction =
  | Action.UpdateStationLocation
  | ActionWithPromise<Action.UpdateStationLocation, StationLocation>

function* doUpdateLocation(action: UpdateAction) {
  const { location } = action;
  yield put(Action.LockStationLocation(location.id));
  const update: LocationUpdate = {
    ownerId: location.owner_id,
    projectId: location.project,
    country: location.country,
    city: location.city,
    address: location.address,
    postcode: location.postcode,
    notes: location.notes,
    lat: location.lat,
    lon: location.lon,
  };
  const response: ApiResponse = yield call(updateLocation, location.id, update);
  yield put(Action.UnlockStationLocation(location.id));

  if (response.reason === ReasonEnum.Ok) {
    const newLocation: StationLocation = response.data as StationLocation;
    yield put(Action.ChangeStationLocation(newLocation, { update: true }));
    'meta' in action && action.meta.promise.resolve(newLocation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while updating base station location #${ location.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type CreateAction =
  | Action.CreateStationLocation
  | ActionWithPromise<Action.CreateStationLocation, StationLocation>

function* doCreateLocation(action: CreateAction) {
  const { location } = action;
  const create: LocationUpdate = {
    ownerId: location.owner_id,
    projectId: location.project,
    country: location.country,
    city: location.city,
    address: location.address,
    postcode: location.postcode,
    notes: location.notes,
    lat: location.lat,
    lon: location.lon,
  };
  const response: ApiResponse = yield call(createLocation, create);

  if (response.reason === ReasonEnum.Ok) {
    const newLocation: StationLocation = response.data as StationLocation;
    yield put(NotifySuccess(`Base station location #${ newLocation.id } has been created`));
    'meta' in action && action.meta.promise.resolve(newLocation);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating a base station location: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DeleteAction =
  | Action.DeleteLocation
  | ActionWithPromise<Action.DeleteLocation, StationLocation>

function* doDeleteLocation(action: DeleteAction) {
  const { location } = action;

  yield put(Action.LockStationLocation(location.id));
  const response: ApiResponse = yield call(deleteLocation, location.id);
  yield put(Action.UnlockStationLocation(location.id));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeStationLocation(location, { delete: true }));
    yield put(NotifySuccess(`Base station location #${ location.id } has been deleted`));
    'meta' in action && action.meta.promise.resolve(location);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while deleting base station location #${ location.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type BatchUpdateProjectAction =
  | Action.UpdateProjectForStationLocations
  | ActionWithPromise<Action.UpdateProjectForStationLocations, StationLocation[]>

function* doBatchUpdateProject(action: BatchUpdateProjectAction) {
  const { projectId } = action;
  const updated: StationLocation[] = [];
  try {
    for (const location of action.locations) {
      const update: LocationUpdate = {
        ownerId: location.owner_id,
        projectId: projectId,
        country: location.country,
        city: location.city,
        address: location.address,
        postcode: location.postcode,
        notes: location.notes,
        lat: location.lat,
        lon: location.lon,
      };

      const response: ApiResponse<StationLocation> = yield call(updateLocation, location.id, update);
      if (response.reason === ReasonEnum.Ok && response.data) {
        updated.push(response.data);
      } else {
        throw createErrorFromApiResponse(response);
      }
    }

    'meta' in action && action.meta.promise.resolve(updated);
  } catch (error: unknown) {
    'meta' in action && action.meta.promise.reject(error as Error);
  }

  for (const location of updated) {
    yield put(Action.ChangeStationLocation(location, { update: true }));
  }
  yield clearCache();
}

function* clearCache() {
  yield clearQueryCache([
    'baseStation/location',
    'baseStation/station',
  ]);
}

export default [
  takeEvery(Action.BASE_STATION_LOCATION_DATA_LOAD_BY_ID, loadLocationDataById),
  takeEvery(Action.BASE_STATION_LOCATION_DATA_LOAD_BY_PARAMS, loadLocationDataByParams),
  takeLeading(Action.BASE_STATION_LOCATION_DATA_LOAD_DICTIONARY, loadLocationDataDictionary),

  takeEvery(Action.BASE_STATION_LOCATION_UPDATE, doUpdateLocation),
  takeEvery(Action.BASE_STATION_LOCATION_CREATE, doCreateLocation),
  takeEvery(Action.BASE_STATION_LOCATION_DELETE, doDeleteLocation),
  takeEvery(Action.BASE_STATION_LOCATION_BATCH_UPDATE_PROJECT, doBatchUpdateProject),
];
