import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from 'actions/parking-monitoring';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ApiResponse, ReasonEnum } from 'models/apiResponse';

import {
  getGroupsMonitoring,
  getGroupMonitoring,
  createGroupMonitoringDuration,
  createGroupMonitoringIgnore,
  editGroupMonitoringDuration,
  editGroupMonitoringIgnore
} from 'clients/parking-monitoring';

function* getGroupsParkingAnomaly (action: actions.GetGroupsParkingAnomaly) {
  const response: ApiResponse = yield call(getGroupsMonitoring, action.filters);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetGroupsParkinganomalySuccess(response.data));
  } else {
    yield put(actions.DoGetGroupsParkinganomalyFailed());
    yield put(NotifyError(`Error while fetching positions parking anomaly: ${response.message}`));
  }
}

function* getMonitoringByGroupId (action: actions.GetByGroupParkingAnomaly) {
  const response: ApiResponse = yield call(getGroupMonitoring, action.id);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByGroupIdParkingAnomalySuccess(response.data));
  } else if (response.reason === ReasonEnum.NotFound) {
    yield put(actions.DoGetByGroupIdParkingAnomalyFailed());
  } else {
    yield put(actions.DoGetByGroupIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while fetching group parking anomaly: ${response.message}`));
  }
}

function* setGroupParkingAnomalyDuration (action: actions.SetGroupParkingAnomalyDuration) {
  const response: ApiResponse = yield call(createGroupMonitoringDuration, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByGroupIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Monitoring has been set'));
  } else {
    yield put(actions.DoGetByGroupIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while setting group parking anomaly monitoring: ${response.message}`));
  }
}

function* setGroupParkingAnomalyIgnore (action: actions.SetGroupParkingAnomalyIgnore) {
  const response: ApiResponse = yield call(createGroupMonitoringIgnore, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByGroupIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Monitoring has been set'));
  } else {
    yield put(actions.DoGetByGroupIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while setting group parking anomaly monitoring: ${response.message}`));
  }
}

function* editGroupDurationParkingAnomaly (action: actions.EditGroupDurationParkingAnomaly) {
  const response: ApiResponse = yield call(editGroupMonitoringDuration, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByGroupIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Configuration has been updated'));
  } else {
    yield put(actions.DoGetByGroupIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while updating group parking anomaly monitoring: ${response.message}`));
  }
}

function* editGroupIgnoreParkingAnomaly (action: actions.EditGroupIgnoreParkingAnomaly) {
  const response: ApiResponse = yield call(editGroupMonitoringIgnore, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByGroupIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Parking anomaly monitoring has been updated'));
  } else {
    yield put(actions.DoGetByGroupIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while updating group parking anomaly monitoring: ${response.message}`));
  }
}


export const parkingMonitoringGroupsSagas = [
  takeEvery(actions.GET_BY_GROUP_PARKING_ANOMALY, getMonitoringByGroupId),
  takeEvery(actions.SET_GROUP_PARKING_ANOMALY_DURATION, setGroupParkingAnomalyDuration),
  takeEvery(actions.SET_GROUP_PARKING_ANOMALY_IGNORE, setGroupParkingAnomalyIgnore),
  takeEvery(actions.EDIT_GROUP_DURATION_PARKING_ANOMALY , editGroupDurationParkingAnomaly),
  takeEvery(actions.EDIT_GROUP_IGNORE_PARKING_ANOMALY , editGroupIgnoreParkingAnomaly),
  takeEvery(actions.GET_GROUPS_PARKING_ANOMALY, getGroupsParkingAnomaly)
];
