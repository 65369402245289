import { useState, useCallback } from 'react';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useLocationItems } from 'hooks/location';
import { RootState } from 'reducers';
import {
  ProvisioningBoxesFilters,
  GetBoxesParams,
  initProvisioningShipmentFilters,
} from 'models/provisioning';
import {
  DoSetProvisioningBoxesFilters,
  DoUpdateProvisioningBoxesFromUrl,
  DoFetchProvisioningBoxes,
  DoFetchShipmentByParams
} from 'actions/provisioning';
import { usePagination } from 'hooks';
import { useAuthUserSelector }from 'hooks/user';

// components
import { ProvisioningBoxesTable } from 'components';
import { AddButton } from 'components/Buttons';
import { CreateBoxModal } from 'components/Provisioning/CreateBoxModal/CreateBoxModal';
import { BoxesFilters } from './widgets/BoxesFilter';

// styles
import useStyles from 'styles/page';
import { canCreateProvisioning } from 'utils/permissions/provisioning';

const ProvisioningBoxes = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const provisioning = useSelector((state: RootState) => state.provisioning.boxes);
  const user = useAuthUserSelector();
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const { limit, offset, onChangePage } = usePagination();
  const { setItems } = useLocationItems();

  const boxes = provisioning.boxes;

  const fetchBoxes = useCallback((params: GetBoxesParams) => dispatch(DoFetchProvisioningBoxes(params)), [dispatch]);
  const fetchShipments = useCallback(() => dispatch(DoFetchShipmentByParams({
    ...initProvisioningShipmentFilters,
    limit: 9999
  })), [dispatch]);
  const setFilters = (filters: ProvisioningBoxesFilters) => dispatch(DoSetProvisioningBoxesFilters(filters));
  const updateFiltersFromUrl = useCallback(() => dispatch(DoUpdateProvisioningBoxesFromUrl()), [dispatch]);

  // init
  useEffectOnce(() => {
    updateFiltersFromUrl();
    fetchShipments();
  });

  // fetch on mount or filters / pagination change
  useDeepCompareEffect(() => {
    fetchBoxes({ ...provisioning.filters, offset, limit });
  }, [fetchBoxes, provisioning.filters, offset, limit]);

  const updateFilters = (filters: ProvisioningBoxesFilters) => {
    setFilters(filters);
    setItems(filters);
    onChangePage(0);
  };

  return (
    <>
      <Helmet>
        <title>Devices provisioning | Boxes</title>
      </Helmet>
      <BoxesFilters
        isLoading={ provisioning.isFetching }
        filters={ provisioning.filters }
        updateFilters={updateFilters}
      />
      <div className={ classes.table }>
        <ProvisioningBoxesTable
          data={ boxes }
          isFetching={ provisioning.isFetching }
          limit={ limit }
          offset={ offset }
          total={ provisioning.total }
          isAdmin={ user.isAdmin }
        />
      </div>
      {
        canCreateProvisioning(user.data) && <AddButton
          label="Create"
          onClick={ () => setDialogOpen(true) }
        />
      }
      { isDialogOpen && <CreateBoxModal
        isOpen={ isDialogOpen }
        setDialogOpen={ setDialogOpen }
      /> }
    </>
  );
};

export default ProvisioningBoxes;
