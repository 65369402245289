import { ResponsiblePerson } from 'models/base-station';
import { FormField } from './types';
import { FormErrors } from 'form';
import { validateEmail } from 'utils/validator';

export const validate = (person: ResponsiblePerson, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};

  if (fields.includes(FormField.email)) {
    const email = person.notification_endpoint.email ?? '';
    if (!email.trim()) {
      errors[FormField.email] = 'Email is required';
    }
    else if (!validateEmail(email)) {
      errors[FormField.email] = 'Invalid email';
    }
  }

  if (fields.includes(FormField.firstName)) {
    if (!person[FormField.firstName] || !person[FormField.firstName].trim()) {
      errors[FormField.firstName] = 'First Name is required';
    }
  }

  if (fields.includes(FormField.lastName)) {
    if (!person[FormField.lastName] || !person[FormField.lastName].trim()) {
      errors[FormField.lastName] = 'Last Name is required';
    }
  }

  return errors;
};
