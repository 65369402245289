import { SoftType, GroupType, PositionedDeviceData } from './generated';
import { DeviceConnectivity } from 'models/connectivity';

export const groupTypes = {
  [GroupType.MarkedParkingBay]: 'Marked Spaces',
  [GroupType.UnmarkedParkingBay]: 'Unmarked Bay',
  [GroupType.StandaloneCarCounters]: 'Car Counters',
};

export interface DeviceWithConnectivity extends PositionedDeviceData {
  connectivity?: DeviceConnectivity;
}

export const LEVEL_NO_LEVEL_OPTION_VALUE = 0;
