import {
  DevicesFilterFields,
  PositionedDeviceData,
  Device,
  DeviceColdData,
  DeviceUpdateParams,
  PositionsFilterFields,
  ExistingPosition
} from 'models/device-management';

export const FETCH_DEVICES_SUCCESS = 'dm/devices/FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAILED = 'dm/devices/FETCH_DEVICES_FAILED';

export const FETCH_DEVICES_BY_POSITIONS_ID = 'FETCH_DEVICES_BY_POSITIONS_ID';
export const FETCH_DEVICES_BY_POSITIONS_ID_SUCCESS = 'FETCH_DEVICES_BY_POSITIONS_ID_SUCCESS';
export const FETCH_DEVICES_BY_POSITIONS_ID_FAILED = 'FETCH_DEVICES_BY_POSITIONS_ID_FAILED';

export const FETCH_DEVICES_COLD_DATA = 'dm/FETCH_DEVICES_COLD_DATA';
export const FETCH_DEVICES_COLD_DATA_SUCCESS = 'dm/FETCH_DEVICES_COLD_DATA_SUCCESS';
export const FETCH_DEVICES_COLD_DATA_FAILURE = 'dm/FETCH_DEVICES_COLD_DATA_FAILURE';

export const FIND_DEVICES = 'dm/FIND_DEVICES';
export const FIND_DEVICES_SUCCESS = 'dm/FIND_DEVICES_SUCCESS';
export const FIND_DEVICES_FAILURE = 'dm/FIND_DEVICES_FAILURE';

export const FIND_POSITIONED_DEVICES = 'dm/FIND_POSITIONED_DEVICES';
export const FIND_POSITIONED_DEVICES_SUCCESS = 'dm/FIND_POSITIONED_DEVICES_SUCCESS';
export const FIND_POSITIONED_DEVICES_FAILURE = 'dm/FIND_POSITIONED_DEVICES_FAILURE';

export const FIND_POSITIONED_DEVICES_RECURSIVE = 'dm/FIND_POSITIONED_DEVICES_RECURSIVE';
export const FIND_POSITIONED_DEVICES_RECURSIVE_SUCCESS = 'dm/FIND_POSITIONED_DEVICES_RECURSIVE_SUCCESS';

export const BIND_DEVICE_TO_POSITION = 'dm/BIND_DEVICE_TO_POSITION';
export const BIND_DEVICE_TO_POSITION_SUCCESS = 'dm/BIND_DEVICE_TO_POSITION_SUCCESS';

export const UNBIND_DEVICE_FROM_POSITION = 'dm/UNBIND_DEVICE_FROM_POSITION';
export const UNBIND_DEVICE_FROM_POSITION_SUCCESS = 'dm/UNBIND_DEVICE_FROM_POSITION_SUCCESS';

export const REPLACE_DEVICE = 'dm/REPLACE_DEVICE';
export const REPLACE_DEVICE_SUCCESS = 'dm/REPLACE_DEVICE_SUCCESS';

export const UPDATE_DEVICE = 'dm/UPDATE_DEVICE';

export const SET_DEVICE_MANAGEMENT_FILTERS = 'SET_DEVICE_MANAGEMENT_FILTERS';

export const RECEIVED_SUCCESS_BIND_FROM_SPLACE = 'dm/RECEIVED_SUCCESS_BIND_FROM_SPLACE';

export const FETCH_DEVICES_BY_ACCUMULATED_POSITIONS_ID = 'dm/FETCH_DEVICES_BY_ACCUMULATED_POSITIONS_ID';

interface FindDevicesParams {
  deviceIds?: Device['device_id'][];
  networkIds?: string[];
}
export interface FindPositionedDevicesParams {
  devices?: Device['device_id'][];
  positions?: ExistingPosition['id'][];
  networkIds?: string[];
  force?: boolean;
  limit?: number;
  offset?: number;
  lat?: number;
  lon?: number;
  radius?: number;
}

export interface GetDevicesSuccess {
  readonly type: typeof FETCH_DEVICES_SUCCESS;
  payload: PositionedDeviceData[];
  total: number;
}
export interface GetDevicesFailed {
  readonly type: typeof FETCH_DEVICES_FAILED;
}

export interface FetchDevicesByPositionsId {
  readonly type: typeof FETCH_DEVICES_BY_POSITIONS_ID;
  filters: DevicesFilterFields;
}

export interface FetchDevicesByPositionsIdSuccsess {
  readonly type: typeof FETCH_DEVICES_BY_POSITIONS_ID_SUCCESS;
  devices: PositionedDeviceData[];
}

export interface FetchDevicesByPositionsIdFailed {
  readonly type: typeof FETCH_DEVICES_BY_POSITIONS_ID_FAILED;
}

export interface FetchDevicesColdData {
  readonly type: typeof FETCH_DEVICES_COLD_DATA;
  readonly deviceIds: Device['device_id'][];
}
export interface FetchDevicesColdDataSuccess {
  readonly type: typeof FETCH_DEVICES_COLD_DATA_SUCCESS;
  readonly payload: {
    readonly [deviceId: string]: DeviceColdData | null;
  };
}
export interface FetchDevicesColdDataFailure {
  readonly type: typeof FETCH_DEVICES_COLD_DATA_FAILURE;
  readonly error: string;
}

export interface FindDevices {
  readonly type: typeof FIND_DEVICES;
  readonly params: {
    readonly deviceIds: Device['device_id'][];
    readonly networkIds: string[];
  };
}
export interface FindDevicesSuccess {
  readonly type: typeof FIND_DEVICES_SUCCESS;
  readonly payload: {
    devicesById: { [deviceId: string]: Device | null };
    foundDevices: Device[];
    requestParams: FindDevices['params'];
  };
}
export interface FindDevicesFailure {
  readonly type: typeof FIND_DEVICES_FAILURE;
  readonly error: string;
}
export interface FindPositionedDevices {
  readonly type: typeof FIND_POSITIONED_DEVICES;
  readonly params: FindPositionedDevicesParams;
}
export interface FindPositionedDevicesRecursive {
  readonly type: typeof FIND_POSITIONED_DEVICES_RECURSIVE;
  readonly filters: DevicesFilterFields;
}
export interface FindPositionedDevicesRecursiveSuccess {
  readonly type: typeof FIND_POSITIONED_DEVICES_RECURSIVE_SUCCESS;
  readonly devices: PositionedDeviceData[];
}
export interface FindPositionedDevicesSuccess {
  readonly type: typeof FIND_POSITIONED_DEVICES_SUCCESS;
  readonly payload: {
    foundDevices: PositionedDeviceData[];
    positionedDevicesById: { [deviceId: string]: PositionedDeviceData | null };
    requestParams: FindPositionedDevices['params'];
  };
}
export interface FindPositionedDevicesFailure {
  readonly type: typeof FIND_POSITIONED_DEVICES_FAILURE;
  readonly error: string;
}

export interface UpdateDevice {
  type: typeof UPDATE_DEVICE;
  payload: DeviceUpdateParams;
}

export interface SetDeviceManagementFilters {
  readonly type: typeof SET_DEVICE_MANAGEMENT_FILTERS;
  filters: DevicesFilterFields;
}

export interface ReceivedSuccessBindFromSplace {
  readonly type: typeof RECEIVED_SUCCESS_BIND_FROM_SPLACE;
  message: string;
}

export interface FetchDevicesByAccumulatedPosition {
  readonly type: typeof FETCH_DEVICES_BY_ACCUMULATED_POSITIONS_ID;
  positionId: number;
}

export const DoGetDevicesFailed: () => GetDevicesFailed = () => ({
  type: FETCH_DEVICES_FAILED
});

export const DoGetDevicesSuccess: (data: PositionedDeviceData[], total: number) => GetDevicesSuccess = (data, total = 0) => ({
  type: FETCH_DEVICES_SUCCESS,
  payload: data,
  total,
});

export const DoGetDevicesByPositionsId: (filters: DevicesFilterFields) => FetchDevicesByPositionsId = (filters) => ({
  type: FETCH_DEVICES_BY_POSITIONS_ID,
  filters,
});

export const DoGetDevicesByPositionsIdSuccess: (devices: PositionedDeviceData[]) => FetchDevicesByPositionsIdSuccsess = devices => ({
  type: FETCH_DEVICES_BY_POSITIONS_ID_SUCCESS,
  devices,
});

export const DoGetDevicesByPositionsIdFailed: () => FetchDevicesByPositionsIdFailed = () => ({
  type: FETCH_DEVICES_BY_POSITIONS_ID_FAILED
});

export const fetchDevicesColdData: (deviceIds: Device['device_id'][]) => FetchDevicesColdData = (deviceIds) => {
  return {
    type: FETCH_DEVICES_COLD_DATA,
    deviceIds,
  };
};

export function fetchDevicesColdDataSuccess(
  devicesColdDataById: FetchDevicesColdDataSuccess['payload'],
): FetchDevicesColdDataSuccess {
  return {
    type: FETCH_DEVICES_COLD_DATA_SUCCESS,
    payload: devicesColdDataById,
  };
}

export interface BindDeviceToPosition {
  readonly type: typeof BIND_DEVICE_TO_POSITION;
  readonly params: {
    readonly deviceId: Device['device_id'];
    readonly positionId: ExistingPosition['id'];
  };
}
export type BindDeviceToPositionResult = void;
export type BindDeviceToPositionSuccess = (
  & Pick<BindDeviceToPosition, 'params'>
  & { readonly type: typeof BIND_DEVICE_TO_POSITION_SUCCESS }
);

export interface UnbindDeviceFromPosition {
  readonly type: typeof UNBIND_DEVICE_FROM_POSITION;
  readonly params: {
    readonly deviceId: Device['device_id'];
    readonly positionId: ExistingPosition['id'];
  };
}
export type UnbindDeviceFromPositionResult = void;
export type UnbindDeviceFromPositionSuccess = (
  & Pick<UnbindDeviceFromPosition, 'params'>
  & { readonly type: typeof UNBIND_DEVICE_FROM_POSITION_SUCCESS }
);
export interface ReplaceDevice {
  readonly type: typeof REPLACE_DEVICE;
  readonly params: {
    oldDevice: Device['device_id'];
    newDevice: Device['device_id'];
  };
}
export type ReplaceDeviceResult = void;
export type ReplaceDeviceSuccess = (
  & Pick<ReplaceDevice, 'params'>
  & { readonly type: typeof REPLACE_DEVICE_SUCCESS }
);


export const fetchDevicesColdDataFailure: (error: string) => FetchDevicesColdDataFailure = (error) => ({
  type: FETCH_DEVICES_COLD_DATA_FAILURE,
  error,
});

export const findDevices: (params: FindDevicesParams) => FindDevices = (params) => {
  return {
    type: FIND_DEVICES,
    params: {
      deviceIds: params.deviceIds || [],
      networkIds: params.networkIds || [],
    },
  };
};

export const findDevicesSuccess: (devices: FindDevicesSuccess['payload']) => FindDevicesSuccess = (devices) => ({
  type: FIND_DEVICES_SUCCESS,
  payload: devices,
});

export const findDevicesFailure: (error: string) => FindDevicesFailure = (error) => ({
  type: FIND_DEVICES_FAILURE,
  error,
});

export const findPositionedDevices: (params: FindPositionedDevicesParams) => FindPositionedDevices = (params) => ({
  type: FIND_POSITIONED_DEVICES,
  params: {
    ...params,
    devices: params.devices || [],
    positions: params.positions || [],
    networkIds: params.networkIds || [],
    force: params.force ?? true,
    limit: params.limit || 2000,
    offset: params.offset || 0,
  }
});

export const DoFindPositionedDevicesRecursive: (filters: PositionsFilterFields) => FindPositionedDevicesRecursive = filters => ({
  type: FIND_POSITIONED_DEVICES_RECURSIVE,
  filters: {
    zones: filters.zones || [],
    groups: filters.groupIds || [],
    owner: filters.owner,
    projects: filters.projects,
    devices: [],
    force: false,
    limit: 0,
    offset: 0
  }
});

export const DoFindPositionedDevicesRecursiveSuccess: (
  devices: PositionedDeviceData[]
) => FindPositionedDevicesRecursiveSuccess = (devices) => ({
  type: FIND_POSITIONED_DEVICES_RECURSIVE_SUCCESS, devices
});

export function findPositionedDevicesSuccess(
  positionedDevices: FindPositionedDevicesSuccess['payload'],
): FindPositionedDevicesSuccess {
  return {
    type: FIND_POSITIONED_DEVICES_SUCCESS,
    payload: positionedDevices,
  };
}

export const findPositionedDevicesFailure: (error: string) => FindPositionedDevicesFailure = (error) => ({
  type: FIND_POSITIONED_DEVICES_FAILURE,
  error,
});
export function bindDeviceToPosition(params: BindDeviceToPosition['params']): BindDeviceToPosition {
  return {
    type: BIND_DEVICE_TO_POSITION,
    params,
  };
}

export function bindDeviceToPositionSuccess(
  params: BindDeviceToPositionSuccess['params'],
): BindDeviceToPositionSuccess {
  return {
    type: BIND_DEVICE_TO_POSITION_SUCCESS,
    params,
  };
}

export function unbindDeviceFromPosition(
  params: UnbindDeviceFromPosition['params'],
): UnbindDeviceFromPosition {
  return {
    type: UNBIND_DEVICE_FROM_POSITION,
    params,
  };
}

export function unbindDeviceFromPositionSuccess(
  params: UnbindDeviceFromPositionSuccess['params'],
): UnbindDeviceFromPositionSuccess {
  return {
    type: UNBIND_DEVICE_FROM_POSITION_SUCCESS,
    params,
  };
}
export function replaceDevice(params: ReplaceDevice['params']): ReplaceDevice {
  return {
    type: REPLACE_DEVICE,
    params,
  };
}

export function replaceDeviceSucess(
  params: ReplaceDeviceSuccess['params'],
): ReplaceDeviceSuccess {
  return {
    type: REPLACE_DEVICE_SUCCESS,
    params,
  };
}

export const updateDevice = (params: UpdateDevice['payload']): UpdateDevice => ({
  type: UPDATE_DEVICE,
  payload: params,
});

export const DoSetDeviceManagementFilters: (filters: DevicesFilterFields) => SetDeviceManagementFilters = filters => ({
  type: SET_DEVICE_MANAGEMENT_FILTERS,
  filters
});

export const DoReceivedSuccessBindFromSplace: (message: string) => ReceivedSuccessBindFromSplace = message => ({
  type: RECEIVED_SUCCESS_BIND_FROM_SPLACE, message
});

export const DoFetchDevicesByAccumulatedPosition: (positionId: number) => FetchDevicesByAccumulatedPosition = positionId => ({
  type: FETCH_DEVICES_BY_ACCUMULATED_POSITIONS_ID, positionId
});

export type Action =
  GetDevicesFailed |
  GetDevicesSuccess |
  FetchDevicesByPositionsId |
  FetchDevicesByPositionsIdSuccsess |
  FetchDevicesByPositionsIdFailed |
  FetchDevicesColdData |
  FetchDevicesColdDataSuccess |
  FetchDevicesColdDataFailure |
  FindDevices |
  FindDevicesSuccess |
  FindDevicesFailure |
  FindPositionedDevices |
  FindPositionedDevicesSuccess |
  FindPositionedDevicesFailure |
  BindDeviceToPositionSuccess |
  UnbindDeviceFromPositionSuccess |
  ReplaceDeviceSuccess |
  UpdateDevice |
  SetDeviceManagementFilters |
  FindPositionedDevicesRecursive |
  FindPositionedDevicesRecursiveSuccess |
  ReceivedSuccessBindFromSplace |
  FetchDevicesByAccumulatedPosition;
