import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DoFetchLevels } from 'actions/device-management/levels';
import { Level } from 'models/device-management';
import { LevelsFilters } from 'models/device-management/filters';
import { RootState } from 'reducers';

interface LevelsState<TWithFindById> {
  isLoading: boolean;
  levels: Level[];
  findLevelById: TWithFindById extends true
    ? (levelId: number) => Level | undefined
    : undefined;
  refetch: () => void;
}

interface LevelsSelectorHookOptions<TWithFindById> {
  params: LevelsFilters;
  withFindById?: TWithFindById;
  skipLoading?: boolean;
}

export function useLevelsSelector<TWithFindById extends boolean>({
  params,
  withFindById,
  skipLoading = false,
}: LevelsSelectorHookOptions<TWithFindById>): LevelsState<TWithFindById> {
  const dispatch = useDispatch();

  const { isFetched, levels, findLevelById } = useSelector((state: RootState) => ({
    isFetched: state.deviceManagement.levels.isFetched(params),
    levels: state.deviceManagement.levels.findByParams(params),
    findLevelById: withFindById ? state.deviceManagement.levels.findById : undefined,
  }), isEqual);

  useEffect(() => {
    if (skipLoading) {
      return;
    }

    if (isFetched === undefined) {
      dispatch(DoFetchLevels(params));
    }
  }, [dispatch, params, isFetched, skipLoading]);

  return {
    isLoading: isFetched !== true,
    levels,
    findLevelById: findLevelById as LevelsState<TWithFindById>['findLevelById'],
    refetch: () => dispatch(DoFetchLevels(params)),
  };
}
