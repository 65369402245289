import React from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { SliceTooltip } from '@nivo/line';

export const CustomSliceTooltip: SliceTooltip = (prop) => {
  const data = prop['slice']['points'];

  return (
    <Paper>
      <Box p={ 2 }>
        <Typography component="p" variant="subtitle1" noWrap={ true }>
          { data[0].data.xFormatted }:
        </Typography>
        { data.map((e, index) => <div key={ index }>
          <Box width={ 14 } height={ 14 } display="inline-block" bgcolor={ e.serieColor } mr={ 2 }></Box>
          <Typography component="p" noWrap={ true } display="inline">
            { e.serieId }: <Typography component="span" variant="button" noWrap={ true }>{ e.data.yFormatted }</Typography>
          </Typography>
        </div>
        ) }
      </Box>
    </Paper>
  );
};
