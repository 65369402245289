import { useDeepCompareEffect } from 'react-use';

import { PositionWithLevel, Group, Level, Zone } from 'models/device-management';

import { BaseTree } from './base/types';
import { useBaseTree } from './base/useBaseTree';
import { collapse, expand, loadMoreChildren } from './node';
import { initializeTree, refetchTree } from './tree';
import { Filters, DmTreeNode } from './types';

interface DmTreeOptions {
  filters: Filters;
  enabled?: boolean;
}

export interface DmTree extends BaseTree {
  loading: boolean;

  expand(node: DmTreeNode): void;
  collapse(node: DmTreeNode): void;
  loadMoreChildren(node: DmTreeNode): void;

  eventHandlers: DmTreeEventHandlers;
}

interface DmTreeEventHandlers {
  onZoneCreateSuccess?: (zone: Zone) => void;
  onLevelCreateSuccess?: (level: Level) => void;
  onGroupCreateSuccess?: (group: Group) => void;
  onPositionCreateSuccess: (position: PositionWithLevel) => void;
  onPositionDeviceBindSuccess: (
    position: PositionWithLevel,
    devicePrevPosition: PositionWithLevel
  ) => void;
  onPositionsLabelsRelationChangeSuccess: (
    updatedPositions: PositionWithLevel[]
  ) => void;
}

export function useDmTree({ filters, enabled = true }: DmTreeOptions): DmTree {
  const [
    tree,
    { getTree, setTree, updateTree, getNode, updateNode }
  ] = useBaseTree(filters);

  useDeepCompareEffect(() => {
    if (enabled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initializeTree({
        filters: getTree().filters,
        getTree,
        setTree,
        updateTree,
        getSubtree: getTree,
        updateSubtree: updateTree
      });
    }
  }, [getTree, setTree, updateTree, enabled]);

  const refetch = () =>
    refetchTree({
      filters: getTree().filters,
      getTree,
      setTree,
      updateTree,
      getSubtree: getTree,
      updateSubtree: updateTree
    });

  return {
    ...tree,

    loading: tree.childrenLoadStatus === 'loading',

    expand: (node) => expand(node, { getNode, updateNode }),
    collapse: (node) => collapse(node, updateNode),
    loadMoreChildren: (node) => loadMoreChildren(node, { getNode, updateNode }),

    eventHandlers: {
      onZoneCreateSuccess: refetch,
      onLevelCreateSuccess: refetch,
      onGroupCreateSuccess: refetch,
      onPositionCreateSuccess: refetch,
      onPositionDeviceBindSuccess: refetch,
      onPositionsLabelsRelationChangeSuccess: refetch
    }
  };
}
