import React from 'react';
import { ProvisioningDevicesFilters, DeviceProvisioningState, statuses } from 'models/provisioning';

// components
import { CustomIdSelect, GroupedFilters, SingleSelectControl } from 'components/Controls';

// styles
import useStyles from 'styles/filters';
import { ShipmentsSelector } from './Controls/ShipmentsSelector';

interface Props {
  state: ProvisioningDevicesFilters;
  setState: (filters: ProvisioningDevicesFilters) => void;
}

const ProvisioningDevicesTableFilter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { state, setState } = props;

  const handleSelectOwner = (id: number | undefined) => setState({ ...state, owner: id === undefined ? [] : [id] });
  const selectStatus = (status?: keyof typeof DeviceProvisioningState) => setState({ ...state, device_status: status });

  return (
    <form className={ classes.gridRoot }>
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner[0] }
        isZoneShow={ false }
        isGroupsShow={ false }
        isProjectShow={ false }
      />
      <CustomIdSelect
        selected={ state.device_id }
        changeHandler={ device_id => setState({ ...state, device_id }) }
        name="Device"
        type="hex"
      />
      <CustomIdSelect
        selected={ state.box_id.map(id => String(id)) }
        changeHandler={ box_id => setState({ ...state, box_id: box_id.map(id => Number(id)) }) }
        name="Box"
        type="number"
      />
      <ShipmentsSelector
        isMulti
        isClearable
        selected={ state.shipment_id }
        onChange={ shipment_id => setState({ ...state, shipment_id }) }
      />
      <SingleSelectControl
        name="status"
        label="Status"
        isClearable
        values={ statuses }
        selected={ state.device_status }
        changeHandler={ status => selectStatus(status as keyof typeof DeviceProvisioningState) }
      />
    </form >
  );
};

export default ProvisioningDevicesTableFilter;
