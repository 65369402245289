import React from 'react';
// components
import { CommonProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<boolean>, 'options'>>
  & SingleProps<boolean>
  & {
    labelYes?: string;
    labelNo?: string;
  }

export const LogicalSelectControl = (props: Props): JSX.Element => {
  const options: Option<boolean>[] = [
    { label: props.labelYes ?? 'Yes', value: true },
    { label: props.labelNo ?? 'No', value: false },
  ];

  return (
    <SelectControl
      name="logic"
      label="Yes/No"
      { ...props }
      options={ options }
      checkSelected={ true }
      isDisabled={ props.isDisabled }
    />
  );
};
