import React, { useState } from 'react';
import { useFormActionLoader } from 'hooks/form';
import { DoChangeDeviceStatus } from 'actions/provisioning';
import { Device, DeviceStatusEnum, statuses } from 'models/provisioning';

// components
import { Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { SingleSelectControl } from 'components/Controls/Select';

interface Props {
  title?: string;
  device: Device;
  onSuccess?: (device: Device) => void;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const ChangeDeviceStatusDialog = ({ device, onSuccess, isOpen, onClose, onCloseEnd, ...props }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const [status, setStatus] = useState<DeviceStatusEnum>(() => device.status);

  const handleSave = () => {
    if (status === device.status) {
      return;
    }

    doAction({
      action: DoChangeDeviceStatus(device.device_id, status),
      onSuccess: (device) => onSuccess?.(device),
      onFinally: onClose
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ props.title ?? 'Change device status' }
      content={
        <SingleSelectControl
          name="status"
          label="Status"
          isClearable={ false }
          values={ statuses }
          selected={ status }
          changeHandler={ status => setStatus(status as DeviceStatusEnum) }
          isDisabled={ device.status === DeviceStatusEnum.Installed }
        />
      }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ handleSave }>
            Save
          </Button>
        </>
      }
    />
  );
};
