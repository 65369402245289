import { SubtreeManagementOptions } from '../types';
import { fillConnectivity } from './connectivity/fillConnectivity';
import { fillTotalChildren } from './total-children/fillTotalChildren';
import { fillTotalDevices } from './total-devices/fillTotalDevices';
import { fillTotalPositions } from './total-positions/fillTotalPositions';
import { fillTotalDamagedDevices } from './total-damaged-devices/fillTotalDamagedDevices';
import { fillTotalSilentDevices } from './total-silent-devices/fillTotalSilentDevices';

export async function fillNodesWithAdditionalData(
  options: SubtreeManagementOptions
) {
  await Promise.all([
    fillTotalChildren(options),
    fillConnectivity(options),
    fillTotalDevices(options),
    fillTotalPositions(options),
    fillTotalDamagedDevices(options),
    fillTotalSilentDevices(options),
  ]);
}
