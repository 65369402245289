import moment from 'moment';
import { Statuses } from 'models/lorawan';

export function getStatusText(status: Statuses): string {
  switch (status) {
    case Statuses.UnderConstruction:
      return 'Under construction';
    case Statuses.Enabled:
      return 'Enabled';
    default:
      return 'Not set';
  }
}

export function humanReadableTime(dateTime: Date | string) {
  return moment(dateTime).fromNow();
}
