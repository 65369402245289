import React, { ReactNode } from 'react';

// components
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  name: string;
  label?: string;
  children: ReactNode;
  btnLabel?: string;
  isOpen: boolean;
  onClick?: () => void;
  onClose: () => void;
  onCloseEnd?: () => void;
  DialogProps?: Partial<DialogProps>;
}

const Component = (props: Props): JSX.Element => {
  const handleOk = () => {
    props.onClick && props.onClick();
    props.onClose();
  };

  return (
    <Dialog
      open={ props.isOpen }
      onClose={ props.onClose }
      onExited={ props.onCloseEnd }
      aria-labelledby={ `dialog-${ props.name }-label` }
      aria-describedby={ `dialog-${ props.name }-description` }
      { ...props.DialogProps }
    >
      <DialogTitle id={ `dialog-${ props.name }-label` }>
        { props.label || 'For your information' }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={ `dialog-${ props.name }-description` }>
          { props.children }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleOk } color="primary" autoFocus>{ props.btnLabel || 'Ok' }</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
