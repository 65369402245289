import React from 'react';
import { useFormActionLoader } from 'hooks';
import { DoDeleteLevel } from 'actions/device-management/levels';
import { Level } from 'models/device-management';

// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  level: Level;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (level: Level) => void;
}

export const DeleteLevelDialog = ({ level, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const handleConfirm = () => doAction({
    action: DoDeleteLevel(level),
    onFinally: onClose,
    onSuccess: onSuccess,
  });

  return (
    <EnsureDialog
      name="dm-level-delete-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
      btnYes="Delete"
      btnNo="Cancel"
      label={ <>Are you sure you want to delete level <b>{ level.name }</b></> }
    >
      The linked groups and positions will be deleted. The linked devices will be unbound.
    </EnsureDialog>
  );
};
