export enum FormField {
  zone = 'zone_id',
  name = 'name',
  floor = 'floor_number',
}

export interface FormState {
  zone_id?: number;
  name?: string;
  floor_number?: number;
}
