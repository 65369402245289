import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useInfoBlockStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: '1px',
    },
    actions: {
      alignSelf: 'flex-end',
    },
    button: {
      margin: theme.spacing(1),
    },
    field: {
      margin: theme.spacing(1),
      width: 'auto',
      flex: 1,
      '&[class*="MuiFormControl-root"]': {
        margin: theme.spacing(1),
        width: 'auto',
      }
    },
    field_type_switch: {
      justifyContent: 'space-between',
    },
    fieldGroup: {
      margin: theme.spacing(1.5, 0),

      '&:first-child': {
        marginTop: 0,
      },
    },
    fields: {
      flexGrow: 1
    },
    switch: {
      marginLeft: 0,
    },
  }),
);
