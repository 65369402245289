import React, { useMemo } from 'react';

import { PositionsTabId } from 'pages/DeviceManagement/Positions/utils';

// components
import PositionsPage from 'pages/DeviceManagement/Positions/PositionsPage';
import PositionsPageLink from 'pages/DeviceManagement/Zone/PositionsPageLink';

// styles
import { useGroupPageStyles } from './style';

type Filters = React.ComponentProps<typeof PositionsPage>['filters'];

export interface MapTabProps {
  groupId: number;
}

const MapTab: React.FC<MapTabProps> = ({ groupId }) => {
  const groupCss = useGroupPageStyles();
  const filters = useMemo((): Filters => ({ groupIds: [groupId] }), [groupId]);

  return (
    <PositionsPage
      filters={filters}
      filtersHidden
      tabsHidden
      tabContentClassName={groupCss.positionsBlockTabContent}
      activeTabId={PositionsTabId.MAP}
      tableProps={{
        hiddenColumns: ['group'],
        toolbar: <PositionsPageLink filters={filters} />,
      }}
    />
  );
};

export default MapTab;
