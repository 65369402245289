import React from 'react';

// styles
import clsx from 'clsx';
import useContentStyles from 'styles/content';

interface LoadMoreButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

export const LoadMoreButton = ({ disabled, onClick }: LoadMoreButtonProps) => {
  const classes = useContentStyles();

  return (
    <span
      className={ clsx(classes.link, disabled && classes.linkDisabled) }
      role="button"
      onClick={ disabled ? undefined : onClick }
    >
      Load more
    </span>
  );
};
