import { useEffect } from 'react';
import { FormContextValues } from 'react-hook-form';
import { shallowEqual } from 'react-redux';
import { usePrevious } from 'react-use';

import { diffObjects } from 'utils/ds/object';
import { isNil } from 'utils/models';

export * from './useForm';

interface FormWatcherOptions<FormValues> {
  form: FormContextValues<FormValues>;
  disabled?: boolean;
  onChange: (diff: Partial<FormValues>, values: FormValues, prevValues?: FormValues) => void;
}

export function useFormWatcher<FormValues>(options: FormWatcherOptions<FormValues>): void {
  const values = options.form.watch();
  const prevValues = usePrevious(options.disabled ? undefined : values);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => {
      if (options.disabled || !prevValues || shallowEqual(values, prevValues)) {
        return;
      }

      const diff = isNil(prevValues) ? values : diffObjects(values, prevValues);
      options.onChange(diff, values, prevValues);
    },
  );
}

export function mapPossibleOptionToValue<TValue>(option: { value: TValue } | null | undefined) {
  return typeof option === 'object' && option !== null
    ? option.value
    : option;
}
