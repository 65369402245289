import { uniq } from 'lodash';

import { Group } from 'models/device-management';

import { fetchGroups } from '../../api';
import { DmApiFilters, GroupNode, PositionNode } from '../../types';
import { DEFAULT_CHILDREN_LIMIT } from './shared';
import { createQuery } from './queries';

export interface GroupNodesData {
  nodes: GroupNode[];
  total: number;
}

export async function createGroupNodes(
  filters: DmApiFilters,
  positionNodes: PositionNode[] = []
): Promise<GroupNodesData> {
  const { groups, total } = await fetchGroups({
    ...filters,
    ...(positionNodes.length && {
      groups: uniq(positionNodes.map((pn) => pn.position.group_id))
    })
  });
  const groupNodes = groups.map((group) =>
    createGroupNode(
      group,
      positionNodes
        .filter((pn) => pn.position.group_id === group.id)
        .map((pn) => pn.position.id)
    )
  );

  return { nodes: groupNodes, total };
}

function createGroupNode(group: Group, positionIds?: number[]): GroupNode {
  return {
    id: `group-${group.id}`,
    type: 'group',
    group,
    groupLoadStatus: 'success',
    childrenFilters: {
      groups: [group.id],
      ...(positionIds?.length && { positions: positionIds })
    },
    childrenLimit: positionIds?.length || DEFAULT_CHILDREN_LIMIT,
    children: [],
    childrenLoadStatus: 'idle',
    childrenMoreLoadStatus: 'idle',
    expansionStatus: 'collapsed',
    childrenTotal: createQuery(),
    connectivity: createQuery(),
    totalDevices: createQuery(),
    totalPositions: createQuery(),
    totalDamagedDevices: createQuery(),
    totalSilentDevices: createQuery(),
  };
}
