import React, { useState } from 'react';
import { Position, PositionedDeviceData } from 'models/device-management';
import { plainMapOptions } from 'utils/map';

// components
import { Paper } from '@material-ui/core';
import { CommonMap } from 'components/Map';
import { PositionMarker } from './PostionMarker';
import { PositionNeighborMarkers } from './PositionNeighborMarkers';

// styles
import useStyles from '../../style';

interface Props {
  position: Position;
  device?: PositionedDeviceData;
  coordinate: {
    lat: number;
    lng: number;
  };
  onChangePosition: (lat: number, lon: number) => void;
}

export const PositionMap = ({ position, device, coordinate, onChangePosition }: Props): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState<number | undefined>(position.id);

  return (
    <Paper className={ classes.mapRoot } data-testid="dm-position-map">
      <CommonMap
        defaultZoom={ 16 }
        defaultCenter={ coordinate }
        options={ { ...plainMapOptions } }
      >
        <PositionNeighborMarkers
          position={ position }
          coordinate={ coordinate }
          openInfo={ open }
          onClick={ position => setOpen(position?.id) }
        />
        <PositionMarker
          position={ position }
          device={ device }
          coordinate={ coordinate }
          onDragEnd={ onChangePosition }
          openInfo={ open }
          onClick={ position => setOpen(position?.id) }
        />
      </CommonMap>
    </Paper>
  );
};