import { GetResponsiblePersonParams, ResponsiblePerson } from 'models/base-station';

export const BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_ID = 'bs/responsiblePerson/LOAD_BY_ID';
export const BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_PARAMS = 'bs/responsiblePerson/LOAD_BY_PARAMS';
export const BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_DICTIONARY = 'bs/responsiblePerson/LOAD_DICTIONARY';
export const BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_START = 'bs/responsiblePerson/LOAD_START';
export const BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_FAILURE = 'bs/responsiblePerson/LOAD_FAILURE';
export const BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_SUCCESS = 'bs/responsiblePerson/LOAD_SUCCESS';

export interface LoadResponsiblePersonDataById {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_ID;
  readonly personId: number;
}

export interface LoadResponsiblePersonDataByParams {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_PARAMS;
  readonly params: GetResponsiblePersonParams;
}

export interface LoadResponsiblePersonDataDictionary {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_DICTIONARY;
}

export interface LoadResponsiblePersonDataStart {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_START;
  readonly params: GetResponsiblePersonParams | number;
}

export interface LoadResponsiblePersonDataFailure {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_FAILURE;
  readonly params: GetResponsiblePersonParams | number;
  readonly error: string;
}

export interface LoadResponsiblePersonDataSuccess {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_SUCCESS;
  readonly params: GetResponsiblePersonParams | number;
  readonly data: ResponsiblePerson[];
}

export const LoadResponsiblePersonDataById = (personId: number): LoadResponsiblePersonDataById => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_ID, personId
});

export const LoadResponsiblePersonDataByParams = (params: GetResponsiblePersonParams): LoadResponsiblePersonDataByParams => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_BY_PARAMS, params
});

export const LoadResponsiblePersonDataDictionary = (): LoadResponsiblePersonDataDictionary => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_DICTIONARY
});

export const LoadResponsiblePersonDataStart = (params: GetResponsiblePersonParams | number): LoadResponsiblePersonDataStart => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_START, params
});

export const LoadResponsiblePersonDataFailure = (params: GetResponsiblePersonParams | number, error: string): LoadResponsiblePersonDataFailure => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_FAILURE, params, error
});

export const LoadResponsiblePersonDataSuccess = (params: GetResponsiblePersonParams | number, data: ResponsiblePerson[]): LoadResponsiblePersonDataSuccess => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DATA_LOAD_SUCCESS, params, data
});

export type Action =
  | LoadResponsiblePersonDataById
  | LoadResponsiblePersonDataByParams
  | LoadResponsiblePersonDataDictionary
  | LoadResponsiblePersonDataStart
  | LoadResponsiblePersonDataFailure
  | LoadResponsiblePersonDataSuccess
