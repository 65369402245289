import { combineReducers } from 'redux';

import * as userGroupServices from './user-group-services';
import * as apiKey from './api-key';

export interface State {
  apiKey: apiKey.State;
  userGroupServices: userGroupServices.State
}

export const reducer = combineReducers<State>({
  apiKey: apiKey.reducer,
  userGroupServices: userGroupServices.reducer,
});
