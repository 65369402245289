import { combineReducers } from 'redux';

import { reducer as devicesReducer, State as devicesState } from './devices';
import { reducer as boxesReducer, State as boxesState } from './boxes';
import { reducer as shipmentReducer, State as shipmentState } from './shipment';
import { reducer as warrantyReducer, State as warrantyState } from './warranty';

export interface State {
  devices: devicesState;
  boxes: boxesState;
  shipment: shipmentState;
  warranty: warrantyState;
}

export const reducer = combineReducers<State>({
  devices: devicesReducer,
  boxes: boxesReducer,
  shipment: shipmentReducer,
  warranty: warrantyReducer,
});
