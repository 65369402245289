import * as Sentry from '@sentry/browser';
import { Action, Middleware } from 'redux';
import { setUrlParams, UrlItems } from 'utils/routing/query';
import { SaveInUrl } from 'react-router-config';

const urlSyncMiddleware: Middleware =  _ => next => (action: Action & Partial<SaveInUrl<UrlItems>>) => {
  Sentry.addBreadcrumb({
    category: 'redux',
    data: { type: action.type },
    level: Sentry.Severity.Info
  });

  if (action.pushFiltersToUrl && action.filters) {
    setUrlParams({
      params: action.filters,
      extendCurrentParams: action.extendCurrentParams,
    });
  }

  return next(action);
};

export default urlSyncMiddleware;
