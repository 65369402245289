import historySaga from './history';
import routersSaga from './routers';
import templateSuitesSaga from './template-suites';
import routesTemplatesSuitesSaga from './routes-templates-suites';
import templatesSaga from './templates';

export default [
  ...historySaga,
  ...routersSaga,
  ...templateSuitesSaga,
  ...templatesSaga,
  ...routesTemplatesSuitesSaga,
];
