import { Device } from 'models/device-management';

import { fetchPositionsDevices } from '../../../api';
import { getPositionNodes } from '../../../node/utils';
import { PositionNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillPositionDevices({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<Device, PositionNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getPositionNodes(getNodes(nodes)),
    getQuery: node => node.device,
    fetchDataByNode: fetchPositionsDevices,
  });
}
