import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from 'actions/parking-monitoring';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ApiResponse, ReasonEnum } from 'models/apiResponse';

import { getZoneMonitoring, createZoneMonitoringDur, editZoneMonitoringDuration, editZoneMonitoringIgnore, createZoneMonitoringIgnore } from 'clients/parking-monitoring';

function* getMonitoringByZoneId (action: actions.GetByZoneIdParkingAnomaly) {
  const response: ApiResponse = yield call(getZoneMonitoring, action.id);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByZoneIdParkingAnomalySuccess(response.data));
  } else if (response.reason === ReasonEnum.NotFound) {
    yield put(actions.DoGetByZoneIdParkingAnomalyFailed());
  } else {
    yield put(actions.DoGetByZoneIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while fetching zone parking anomaly: ${response.message}`));
  }
}

function* setZoneParkingAnomalyDur (action: actions.SetZoneParkingAnomalyDuration) {
  const response: ApiResponse = yield call(createZoneMonitoringDur, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByZoneIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Monitoring has been set'));
  } else {
    yield put(actions.DoGetByZoneIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while setting zone parking anomaly monitoring: ${response.message}`));
  }
}
function* setZoneParkingAnomalyIgnore (action: actions.SetZoneParkingAnomalyIgnore) {
  const response: ApiResponse = yield call(createZoneMonitoringIgnore, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByZoneIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Monitoring has been set'));
  } else {
    yield put(actions.DoGetByZoneIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while setting zone parking anomaly monitoring: ${response.message}`));
  }
}

function* editZoneDurationParkingAnomaly (action: actions.EditZoneDurationParkingAnomaly) {
  const response: ApiResponse = yield call(editZoneMonitoringDuration, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByZoneIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Configuration has been updated'));
  } else {
    yield put(NotifyError(`Error while updating zone parking anomaly monitoring: ${response.message}`));
  }
}

function* editZoneIgnoreParkingAnomaly (action: actions.EditZoneIgnoreParkingAnomaly) {
  const response: ApiResponse = yield call(editZoneMonitoringIgnore, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByZoneIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Parking anomaly monitoring has been updated'));
  } else {
    yield put(NotifyError(`Error while updating zone parking anomaly monitoring: ${response.message}`));
  }
}

export const parkingMonitoringZonesSagas = [
  takeEvery(actions.GET_BY_ZONE_ID_PARKING_ANOMALY, getMonitoringByZoneId),
  takeEvery(actions.SET_ZONE_PARKING_ANOMALY_DURATION, setZoneParkingAnomalyDur),
  takeEvery(actions.SET_ZONE_PARKING_ANOMALY_IGNORE, setZoneParkingAnomalyIgnore),
  takeEvery(actions.EDIT_ZONE_DURATION_PARKING_ANOMALY , editZoneDurationParkingAnomaly),
  takeEvery(actions.EDIT_ZONE_IGNORE_PARKING_ANOMALY , editZoneIgnoreParkingAnomaly),
];
