import { UserPayload } from 'actions/user-profile';
import { ADMIN_ROLE } from 'reducers/user-profile';
import { PermissionsConfig } from 'models/user-management';

export const canViewProvisioning = (user: UserPayload): boolean =>
  user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('r'));

export const canCreateProvisioning = (user: UserPayload): boolean =>
  user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('c'));

export const canEditProvisioning = (user: UserPayload): boolean =>
  user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('u'));

export const canDeleteProvisioning = (user: UserPayload): boolean =>
  user.role === ADMIN_ROLE || Boolean(user[PermissionsConfig.provisioning]?.includes('d'));


