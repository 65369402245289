// FIXME: remove `any` (BNIV-195)
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { SelectorInputProps, formatErrors, getSelectProps } from 'components/Form/Field/utils';
import { createField } from './createField';

export type SelectorInputWithErrorProps = Omit<SelectorInputProps, 'errors'> & {
  error?: React.ReactNode;
};

/**
 * Creates a field given a component which receives `selected`, `changeHandler`, and `error`.
 *
 * It can be used similar to `createFieldFromRegularInput`. The only difference is that it passes
 * `SelectorInputWithErrorProps` instead `InputProps`, so if you want to access these injected
 * props in an additional wrapper to transform some props, you may need to extend the underlying
 * input's props with `SelectorInputWithErrorProps` (in `TInputProps` type argument).
 *
 * See `createFieldFromRegularInput` for examples.
 */
export function createFieldFromSelectorWithError<
  TInputProps extends Partial<SelectorInputWithErrorProps>
>(Input: React.ComponentType<TInputProps>) {
  type FinalInputProps = Omit<TInputProps, 'selected' | 'changeHandler'>;
  type ChangeHandler = TInputProps['changeHandler'];

  return createField<FinalInputProps, ChangeHandler>((props) =>
    <Input
      {...props}
      {...getSelectProps(props) as any}
      error={formatErrors(props.errors)}
    />,
  );
}
