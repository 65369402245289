import * as Sentry from '@sentry/browser';
import React, { useState, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createPermissions, updatePermissions } from 'actions/user-management/permissions';
import { useAuthUserSelector } from 'hooks';
import { Permissions, PermissionsUpdateParams } from 'models/user-management';
import { RootState } from 'reducers';
import { getPermissionsById } from 'selectors/user-management';
import { useForm } from 'utils/form';
import * as is from 'utils/form/validation/validators';
import { isNil } from 'utils/models';
import { dispatchAsync } from 'utils/store';

import {
  PermissionsEditorFormValues,
  mapFormValuesToPermissionsOperations,
  mapPermissionsOperationsToSwitchesEntries,
  isDisabledByRelation,
  changeByRelation,
  getDefaultValues,
} from './utils';

// components
import { Button as MuiButton, Grid } from '@material-ui/core';

import { Button, FormContext } from 'components';
import * as Fields from 'components/Form/Field';

import SelectPermissionsTemplate from '../SelectPermissionsTemplate';
import { StyledPermissionsTable } from './components';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';

import { successTheme } from 'styles/themes';

import { PermissionsMode } from '../shared';
import usePermissionsEditorStyles from './styles';

export interface PermissionsEditorFormProps {
  permissions?: Permissions;
  onDirtyChange?: (formDirty: boolean) => void;
  onSubmitBegin?: () => void;
  onSubmitEnd?: () => void;
  onCancel?: (formDirty: boolean) => void;
  onSuccess?: (permissions: Permissions) => void;

  defaultPermissions?: Partial<Permissions>;
  editedUserGroupId: number;

  actionsWrapper: React.ComponentType;
  contentWrapper: React.ComponentType;
}

const PermissionsEditorForm: React.FC<PermissionsEditorFormProps> = (props) => {
  const { permissions } = props;

  const creatingNewPermissions = !permissions;

  const { isAdmin } = useAuthUserSelector();

  const form = useForm<PermissionsEditorFormValues>({
    defaultValues: getDefaultValues({
      defaultPermissions: props.defaultPermissions,
      permissions,
      isAdmin,
      editedUserGroupId: props.editedUserGroupId,
    }),
    validators: {
      name: is.required(),
      owner_id: is.required(),
    },
  });
  const { onDirtyChange } = props;

  useEffect(() => {
    onDirtyChange?.(form.formState.dirty);
  }, [onDirtyChange, form.formState.dirty]);

  const dispatch = useDispatch();

  const handleSubmit = form.handleSubmit(values => {
    props.onSubmitBegin?.();
    const permissionsUpdateProps: PermissionsUpdateParams['props'] = {
      ...permissions,
      ...values,
      ...mapFormValuesToPermissionsOperations(values),
    };

    const action = permissions
      ? updatePermissions({ id: permissions.id, props: permissionsUpdateProps })
      : createPermissions(permissionsUpdateProps);

    return dispatchAsync(dispatch, action)
      .then((updatedPermissions) => {
        props.onSuccess?.(updatedPermissions);
      })
      .finally(props.onSubmitEnd);
  });

  const [permissionsIdToImport, setPermissionsIdToImport] = useState<Permissions['id'] | null>(null);
  const store = useStore<RootState>();

  const importPermissionsTemplate = () => {
    if (isNil(permissionsIdToImport)) {
      return;
    }

    const permissionsToImport = getPermissionsById(permissionsIdToImport, store.getState());

    if (isNil(permissionsToImport)) {
      const message = 'Importing permissions: the requested permission has not been found.';

      dispatch(NotifyError(
        `${message} ` +
        'This is probably an error on our side. In the meanwhile, try again ' +
        'or refresh the page.'
      ));
      Sentry.captureMessage(`${message} Got \`${permissionsToImport}\`.`);
      return;
    }

    const switchesByCategory = mapPermissionsOperationsToSwitchesEntries(permissionsToImport);

    for (const [category, switchByOperation] of switchesByCategory) {
      for (const [operationKey, selected] of Object.entries(switchByOperation)) {
        form.setValue(`${category}.${operationKey}`, selected);
      }
    }

    dispatch(NotifySuccess(`"${permissionsToImport.name}" permissions template has been imported.`));
  };

  const convertToTemplate = () => {
    form.setValue('template', true);
    form.setValue('name', '');
  };

  const styles = usePermissionsEditorStyles();

  const template = form.watch('template');

  return (
    <FormContext form={form}>
      <form
        onSubmit={e => {
          e.stopPropagation();
          handleSubmit(e); // eslint-disable-line @typescript-eslint/no-floating-promises
        }}
      >
        <props.contentWrapper>
          <input hidden name="template" ref={form.register} type="checkbox" />

          {!template && (
            <MuiButton
              className={clsx(styles.row, styles.whiteText)}
              color="secondary"
              variant="contained"
              onClick={convertToTemplate}
            >
              Convert to template
            </MuiButton>
          )}

          <Grid className={clsx(styles.row, !template && styles.hidden)} container>
            <Grid item xs={10} sm={10} md={5}>
              <Fields.Text
                fullWidth
                label="Permissions name"
                name="name"
              />
            </Grid>
          </Grid>

          <Grid className={styles.row} container alignItems="center" spacing={2}>
            <Grid item xs={6} sm={6} md={5}>
              <SelectPermissionsTemplate
                name="permissionsIdToImport"
                label="Imported permissions template (optional)"
                isClearable
                permissionsMode={PermissionsMode.TEMPLATE}
                mapOptions={permissions && (options => options.filter(o => o.value !== permissions.id))}
                value={permissionsIdToImport}
                onChange={option => setPermissionsIdToImport(option)}
              />
            </Grid>


            <Grid item xs md={2}>
              <MuiButton
                disabled={isNil(permissionsIdToImport)}
                color="primary"
                variant="contained"
                onClick={importPermissionsTemplate}
              >
                Import template
              </MuiButton>
            </Grid>
          </Grid>

          <Grid className={clsx(styles.row, (!template || !isAdmin) && styles.hidden)} container>
            <Grid item={ true } xs={ 12 } sm={ 12 } md={ 3 }>
              <Fields.SelectOwner
                name="owner_id"
                isClearable={ false }
              />
            </Grid>
          </Grid>

          <StyledPermissionsTable
            className={styles.row}
            renderSwitch={ ({ categoryFieldName, operation, ...switchProps }) => {
              const name = `${ categoryFieldName }.${ operation.key }`;
              return (
                <Fields.Switch
                  name={ name }
                  disabled={ isDisabledByRelation(form, operation) }
                  onChange={ () => {
                    const newValue = !form.getValues(name);
                    form.setValue(name, newValue);
                    changeByRelation(form, operation, newValue);
                  } }
                  { ...switchProps }
                />
              );
            } }
          />
        </props.contentWrapper>

        <props.actionsWrapper>
          {props.onCancel && (
            <MuiButton color="inherit" onClick={() => props.onCancel?.(form.formState.dirty)}>
              Cancel
            </MuiButton>
          )}
          <MuiThemeProvider theme={successTheme}>
            <Button
              color="primary"
              disabled={!form.formState.dirty && !creatingNewPermissions}
              pending={form.formState.isSubmitting}
              type="submit"
            >
              Save
            </Button>
          </MuiThemeProvider>
        </props.actionsWrapper>
      </form>
    </FormContext>
  );
};

export default PermissionsEditorForm;
