import React, { useContext, useEffect, useState } from 'react';

import { Environment, getEnv, subscribe, unsubscribe } from './common';

export const EnvironmentContext = React.createContext<Environment>(getEnv());

interface EnvironmentProviderProps {
  children?: React.ReactNode;
}

export const EnvironmentProvider = ({ children }: EnvironmentProviderProps): JSX.Element => {
  const [currentEnv, setEnv] = useState(getEnv());

  useEffect(() => {
    subscribe(setEnv);
    return () => unsubscribe(setEnv);
  }, []);

  return (
    <EnvironmentContext.Provider value={currentEnv}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => useContext(EnvironmentContext);
