import React from 'react';

import { Typography, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from 'styles/utils';
import { yellow, green, red, grey } from '@material-ui/core/colors';
import { totalStr } from '../utils';

interface Props {
  count: number;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  low: {
    background: grey[300],
  },
  middle: {
    background: yellow[600],
  },
  full: {
    background: green[500],
    color: theme.palette.common.white
  },
  overflow: {
    background: red[200],
  },
}));

const getColor = (count: number) => {
  if (count === 0) {
    return 'low';
  } else if (count < 20) {
    return 'middle';
  } else if (count > 20) {
    return 'overflow';
  } else {
    return 'full';
  }
};

export const DevicesCountInBox: React.FC<Props> = ({ count }) => {
  const classes = useStyles();
  const level = getColor(count);

  return (
    <Box className={ classes[level] } maxWidth={ 100 }>
      <Typography variant="caption" align="center" component="p">
        { `${totalStr(count)}` }
      </Typography>
    </Box>
  );
};
