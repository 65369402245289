import { Action as EndpointsAction } from './endpoints';
import { Action as ZonesAction } from './zones';
import { Action as HardwareTypesAction } from './hardwareTypes';
import { Action as UsersAction } from './users';
import { Action as MessageTypesAction } from './message-types';
import { Dictionary } from 'models/dictionary';

export * from './zones';
export * from './hardwareTypes';
export * from './endpoints';
export * from './users';
export * from './message-types';

export const DICTIONARY_LOAD = 'DICTIONARY_LOAD';
export const DICTIONARY_RESET = 'DICTIONARY_RESET';

export interface DictionaryLoad {
  readonly type: typeof DICTIONARY_LOAD;
  readonly dictionary: Dictionary;
}

export interface DictionaryReset {
  readonly type: typeof DICTIONARY_RESET;
  readonly dictionary: Dictionary;
}

export const DictionaryLoad: (dictionary: Dictionary) => DictionaryLoad = (dictionary) => ({
  type: DICTIONARY_LOAD, dictionary
});

export const DictionaryReset: (dictionary: Dictionary) => DictionaryReset = (dictionary) => ({
  type: DICTIONARY_RESET, dictionary
});

export type Action = (
  | EndpointsAction
  | UsersAction
  | MessageTypesAction
  | ZonesAction
  | HardwareTypesAction
  | DictionaryLoad
  | DictionaryReset
);
