import React from 'react';

// components
import CommonButton, { CommonButtonProps } from './CommonButton';

// styles
import clsx from 'clsx';

import { colors } from 'styles/themes/outer';
import { makeStyles, createStyles } from 'styles/utils';

const useStyles = makeStyles(() => createStyles({
  root: {
    color: `${colors.textPrimary} !important`,
    fontWeight: 400,
    textTransform: 'none',
    letterSpacing: 'normal',
    fontSize: 14,
  },
  selected: {
    color: `${colors.mainPrimary} !important`,
    fontWeight: 500,
  },
}));

interface IFilterPresetButton extends Omit<CommonButtonProps, 'type'>  {
  selected?: boolean;
  selectedClickable?: boolean;
}

export const FilterPresetButton = ({
  selected,
  selectedClickable,
  ...restProps
}: IFilterPresetButton): JSX.Element => {
  const filterClasses = useStyles();

  return (
    <CommonButton
      {...restProps}
      type="text"
      isDisabled={selected && !selectedClickable}
      ButtonProps={{
        ...restProps.ButtonProps,
        className: clsx(
          filterClasses.root,
          selected && filterClasses.selected,
          restProps.ButtonProps?.className,
        ),
        'data-selected': selected,
      }}
    />
  );
};
