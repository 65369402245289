import React from 'react';
import { useFormActionLoader, useAuthUserSelector } from 'hooks';
import { FormTab } from './types';
import { useAddForm, useInstallForm, useLoRaWANForm } from './utils';
// components
import { Button, Tabs, Tab } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { BaseStationForm, StationFlashForm } from 'components/BaseStation';
import { StationLoRaWANForm } from '../StationLoRaWANForm/StationLoRaWANFormComponent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const StationCreateDialog = (props: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const { isOpen, onClose, onCloseEnd } = props;
  const [activeTab, setActiveTab] = React.useState<FormTab>(FormTab.INSTALL);
  const { doAction } = useFormActionLoader();
  const { form: addForm, onReset: onAddReset, onSubmit: onAddSubmit } = useAddForm({ doAction, onSuccess: onClose });
  const { form: installForm, onReset: onInstallReset, onSubmit: onInstallSubmit } = useInstallForm({ doAction, onSuccess: onClose });
  const { form: LorawanForm, onReset: onLorawanImportReset, onSubmit: onLorawanImportSubmit } = useLoRaWANForm({ doAction, onSuccess: onClose });

  const onReset = () => {
    switch (activeTab) {
      case FormTab.INSTALL:
        onInstallReset();
        break;
      case FormTab.ADD:
        onAddReset();
        break;
      case FormTab.LORAWAN_IMPORT:
        onLorawanImportReset();
        break;
    }
  };

  const onSubmit = () => {
    switch (activeTab) {
      case FormTab.INSTALL:
        onInstallSubmit();
        break;
      case FormTab.ADD:
        onAddSubmit();
        break;
      case FormTab.LORAWAN_IMPORT:
        onLorawanImportSubmit();
        break;
    }
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ 'Create Base station' }
      content={
        <>
          <Tabs centered variant={ 'scrollable' } scrollButtons="auto" value={ activeTab } onChange={ (_, value) => setActiveTab(value as FormTab) }>
            <Tab label="Create" value={ FormTab.INSTALL }/>
            <Tab label="Add" value={ FormTab.ADD }/>
            <Tab label="Import LoRaWAN" value={ FormTab.LORAWAN_IMPORT }/>
          </Tabs>
          { activeTab !== FormTab.INSTALL ? '' :
            <StationFlashForm { ...installForm } />
          }
          { activeTab !== FormTab.ADD ? '' :
            <BaseStationForm { ...addForm } />
          }
          { activeTab !== FormTab.LORAWAN_IMPORT ? '' :
            <StationLoRaWANForm isAdmin={user.isAdmin}  { ...LorawanForm } />
          }
        </>
      }
      actions={
        <>
          <Button
            color="primary"
            onClick={ () => onReset() }
          >
            Reset
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ () => onSubmit() }
            disabled={activeTab === FormTab.LORAWAN_IMPORT && !LorawanForm.state.eui}
          >
            {activeTab === FormTab.LORAWAN_IMPORT ? 'Import' : 'Add'}
          </Button>
        </>
      }
    />
  );
};
