import * as Actions from 'actions/caller';
import { GetTemplateParams, Template } from 'models/caller';
import { Repository } from 'utils/repository';

export type State = Repository<Template, GetTemplateParams, 'template_id'>;
const initState: State = Repository.create('template_id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.CALLER_TEMPLATE_DATA_LOAD_START:
      return state.setFetched(action.params, false);
    case Actions.CALLER_TEMPLATE_DATA_LOAD_FAILURE:
      return state.setFetched(action.params, true);
    case Actions.CALLER_TEMPLATE_DATA_LOAD_SUCCESS:
      return state
        .setFetched(action.params, true)
        .setData(action.params, action.data);
    case Actions.CALLER_TEMPLATE_LOCK:
      return state.setLocked(action.templateId, true);
    case Actions.CALLER_TEMPLATE_UNLOCK:
      return state.setLocked(action.templateId, false);
    case Actions.CALLER_TEMPLATE_CHANGE:
      return state.changeModel(action.template.template_id, action.options.delete ? undefined : action.template);
    case Actions.CALLER_TEMPLATE_CHANGE_MANY: {
      for (const templateParam of action.templateParams) {
        state = state.changeModel(
          templateParam.template.template_id,
          templateParam.options.delete ? undefined : templateParam.template,
        );
      }
      return state;
    }

    default:
      return state;
  }
};
