import { fetchGroupsTotalDamagedDevices } from '../../../api';
import { getGroupNodes } from '../../../node/utils';
import { GroupNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillGroupTotalDamagedDevices({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, GroupNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getGroupNodes(getNodes(nodes)),
    getQuery: node => node.totalDamagedDevices,
    fetchDataByNode: fetchGroupsTotalDamagedDevices,
  });
}
