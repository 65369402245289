import { useQuery } from 'react-query';
import { FindDeviceSettingsParam } from 'models/device-emulation';
import { useFormActionNotifier } from 'hooks';
import { getResponseData } from 'utils/clients';
import { findDeviceSettings } from 'clients/device-emulation';

type Options = {
  params: FindDeviceSettingsParam;
}

export function useDeviceSettings({ params }: Options) {
  const { notifyError } = useFormActionNotifier();

  const queryResult = useQuery({
    queryKey: ['device-emulation/settings', params],
    queryFn: async () => getResponseData(await findDeviceSettings(params)),
    cacheTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      notifyError(`Error while fetching device settings: ${ (e as Error).message }`);
    },
  });

  return {
    isLoading: queryResult.isLoading,
    deviceSettings: queryResult.data || [],
  };
}