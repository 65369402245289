import { GetTemplateSuitesParams } from 'models/caller';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
// components
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';
// styles
import useStyles from 'styles/filters';

export interface FilterState {
  name: string;
  owner?: number;
}

export const initialParams: GetTemplateSuitesParams = {
  limit: 200,
};

export const buildParams = (params: UrlItems): GetTemplateSuitesParams => {
  const parser = new Parser(params as GetTemplateSuitesParams);
  const result: GetTemplateSuitesParams = {
    ...initialParams,
    name: parser.asString('name') ?? initialParams.name,
    owner: parser.asNumber('owner') ?? initialParams.owner,
  };

  return result;
};

export const convertStateToParams = (state: FilterState): GetTemplateSuitesParams => ({
  ...initialParams,
  name: state.name.length ? state.name : undefined,
  owner: state.owner,
});

export const convertParamsToState = (params: GetTemplateSuitesParams): FilterState => ({
  name: params.name || '',
  owner: params.owner,
});

interface Props {
  canViewOwner: boolean;
  state: FilterState;
  setState: (filters: FilterState) => void;
}

const Component = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { canViewOwner, state, setState } = props;

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      { !canViewOwner ? '' :
        <OwnersSingleSelectControl
          selected={ state.owner }
          changeHandler={ (owner?: number) => setState({ ...state, owner }) }
        />
      }
      <TextControl
        name="name"
        label="Name"
        value={ state.name ? state.name : '' }
        onChange={ (event: React.ChangeEvent<{ value: string }>) => setState({ ...state, name: event.target.value }) }
      />
    </form>
  );
};

export default Component;
