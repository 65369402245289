import * as actions from 'actions/device-management/device-download';

export interface State {
  isFetching: boolean;
  total: number;
  ready: number;
}

const initState: State = {
  isFetching: false,
  total: 0,
  ready: 0,
};

export const reducer = (state: State = initState, action: actions.Action): State => {
  switch (action.type) {
    case actions.DEVICE_DOWNLOAD_START:
      return { ...state, isFetching: true, total: 0, ready: 0 };
    case actions.DEVICE_DOWNLOAD_SUCCESS:
      return { ...state, total: action.total, ready: action.ready };
    case actions.DEVICE_DOWNLOAD_FAILURE:
      return { ...state, isFetching: false };
    case actions.DEVICE_DOWNLOAD_COMPLETE:
      return { ...state, isFetching: false };
    case actions.DEVICE_DOWNLOAD_CANCEL:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
