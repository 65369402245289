import React from 'react';
import { dmDevicesPath } from 'routing/paths';

// components
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Statistic } from 'models/device-management';


interface Props {
  devicesByZone?: Statistic['by_zones'];
  id: number;
}
const BoundedDevices = ({ devicesByZone, id }: Props) => {
  const boundedNumber = devicesByZone?.[id]?.devices_installed || 0;
  const params = {
    zones: [id],
  };
  return (
    <Link component={ RouterLink } color="secondary" to={ dmDevicesPath(params) }>
      { boundedNumber }
    </Link>
  );
};

export default BoundedDevices;
