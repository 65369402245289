import React from 'react';

import { ArrayElement } from 'hooks/device-management/useDmTree/types';

import { NodeMethods, TreeNodeWithChildren, TreeNode, TreeTableProps, TreeTableRow } from '../types';
import { isNodeWithChildren } from '../utils';

// components
import { Box, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { LoaderSvg } from 'components/Loaders';

import { LoadMoreButton } from './LoadMoreButton';

export function renderTreeNodeColumn<TNode extends TreeNode<unknown>>(
  column: ArrayElement<TreeTableProps<TNode>['columns']>,
  nodeMethods: NodeMethods<TNode>,
  row: TreeTableRow<TNode>,
): React.ReactNode {
  return (
    <Box paddingLeft={ row.nestingLevel * 2 }>
      { row.type === 'normal'
        ? renderNormalNodeContent(column, nodeMethods, row)
        : renderLoadMoreChildrenNodeContent(nodeMethods, row)
      }
    </Box>
  );
}

function renderNormalNodeContent<TNode extends TreeNode<unknown>>(
  column: ArrayElement<TreeTableProps<TNode>['columns']>,
  nodeMethods: NodeMethods<TNode>,
  row: TreeTableRow<TNode>,
): React.ReactNode {
  const isTopNode = row.nestingLevel === 0;
  return (
    <>
      { column.renderText?.(row.node, isTopNode) }
      { isNodeWithChildren(row.node) && renderChildrenTotal(row.node) }
      { isNodeWithChildren(row.node) && renderExpansionButton(row.node, nodeMethods) }
      { isNodeWithChildren(row.node) && renderNormalNodeLoadingIndicator(row.node) }
    </>
  );
}

function renderLoadMoreChildrenNodeContent<TNode extends TreeNode<unknown>>(
  nodeMethods: NodeMethods<TNode>,
  row: TreeTableRow<TNode>,
): React.ReactNode {
  return (
    <>
      { isNodeWithChildren(row.node) && renderLoadMoreChildrenNodeContentText(row.node, nodeMethods) }
      { isNodeWithChildren(row.node) && renderLoadMoreChildrenNodeLoadingIndicator(row.node) }
    </>
  );
}

function renderLoadMoreChildrenNodeContentText<TNode extends TreeNodeWithChildren>(
  node: TNode,
  { loadMoreChildren }: NodeMethods<TNode>,
) {
  if (node.childrenLoadStatus === 'loading') {
    return;
  }

  return (
    <LoadMoreButton
      disabled={ node.childrenMoreLoadStatus === 'loading' }
      onClick={ () => loadMoreChildren(node) }
    />
  );
}

function renderChildrenTotal(node: TreeNodeWithChildren) {
  const childrenTotal = node.childrenTotal.data;

  return (
    <Box component="span" ml={ 0.5 }>
      { typeof childrenTotal === 'number' && `(${ childrenTotal })` }
    </Box>
  );
}

function renderNormalNodeLoadingIndicator(node: TreeNodeWithChildren) {
  if (node.childrenTotal.status === 'loading' || node.childrenLoadStatus === 'loading') {
    return <LoadingIcon />;
  }

  return null;
}

function renderLoadMoreChildrenNodeLoadingIndicator(node: TreeNodeWithChildren) {
  if (node.childrenMoreLoadStatus === 'loading') {
    return <LoadingIcon />;
  }

  return null;
}

function LoadingIcon() {
  return (
    <Box component="span" ml={ 0.5 }>
      <LoaderSvg />
    </Box>
  );
}

function renderExpansionButton<TNode extends TreeNodeWithChildren>(
  node: TNode,
  { collapse, expand }: NodeMethods<TNode>
) {
  if (!node.childrenTotal.data) {
    return;
  }

  function toggleExpansion() {
    if (node.expansionStatus === 'expanded') {
      collapse(node);
    } else {
      expand(node);
    }
  }

  return (
    <IconButton onClick={ toggleExpansion }>
      { node.expansionStatus === 'expanded' ? <ExpandLess /> : <ExpandMore /> }
    </IconButton>
  );
}
