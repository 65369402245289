import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import * as connectivityClient from 'clients/connectivity';
import * as dmClient from 'clients/device-management';
import { ApiResponse, ReasonEnum } from 'models';
import { fetchIncidents, fetchIncidentsLog } from 'clients/monitoring';
import { NotifyError } from 'actions/notifier';
import {
  GetDevicesHealthStat,
  FETCH_ALL_STAT_DM_START,
  DoGetDevicesHealthStatFailed,
  DoGetDevicesHealthStatSuccess,
  DoGetConnectivitySuccess,
  DoGetFailuresSuccess,
  GetStatParams,
  INIT_ALL_STAT_DM_START,
} from 'actions/device-statistic';
import { ConnectivityChartData } from 'models/connectivity';
import { IncidentStatisticHistory, IncidentStatistic } from 'models/device-monitoring';
import { ConnectivityHistoryFilters } from 'models/connectivity/filters';
import { RootState } from 'reducers';
import { Statistic } from 'models/device-management';

function setupFiltersForConnectivity(filters: GetStatParams): ConnectivityHistoryFilters {
  return {
    ...(filters.projects.length && { project_id: filters.projects }),
    ...(filters.groups?.length && { group_id: filters.groups }),
    ...(filters.zones.length && { zones_id: filters.zones }),
    ...(filters.timeFrom && { start_time: filters.timeFrom.toISOString() }),
    ...(filters.timeTo && { end_time: filters.timeTo.toISOString() })
  };
}

function* fetchDeviceStatisticAction(action: GetDevicesHealthStat) {
  yield call(fetchDeviceStatistic, action.filters);
}

function* fetchDeviceStatistic(filters: GetStatParams) {
  const callConnectivityAvg: ApiResponse = yield call(connectivityClient.fetchZonesOverall, setupFiltersForConnectivity(filters));
  const callConnectivityLog: ApiResponse<ConnectivityChartData[]> = yield call(connectivityClient.fetchZonesOverallHistory, setupFiltersForConnectivity(filters));

  const [connectivityAvg, connectivityLog, dmStat, monitoring, monitoringLog]: ApiResponse[] = yield all([
    callConnectivityAvg,
    callConnectivityLog,
    call(dmClient.fetchAllStatistics, { ...filters, metrics: undefined }),
    call(fetchIncidents, filters),
    call(fetchIncidentsLog, filters)
  ]);

  const monitoringData: { log: IncidentStatisticHistory; stat: IncidentStatistic } = {
    log: {
      failures_history: [],
      silent_history: []
    },
    stat: {
      silent_ok: 0,
      total_failures: 0,
      not_resolved_number: 0
    }
  };

  if (monitoringLog.reason === ReasonEnum.Ok) {
    const { silent_history } = monitoringLog.data;
    monitoringData.log.silent_history = silent_history || [];
  } else {
    yield put(NotifyError(`Error while fetching monitoring silent history: ${monitoring.message}`));
  }

  if (monitoring.reason === ReasonEnum.Ok) {
    const {
      failures_history,
      silent_ok,
      total_failures,
      not_resolved_number
    } = monitoring.data;

    monitoringData.log.failures_history = failures_history;
    monitoringData.stat = {
      silent_ok,
      total_failures,
      not_resolved_number
    };
  } else {
    yield put(NotifyError(`Error while fetching monitoring log: ${monitoring.message}`));
  }

  yield put(DoGetFailuresSuccess(monitoringData.stat, monitoringData.log));

  const dmStatData = dmStat.data as Statistic;

  if (dmStat.reason === ReasonEnum.Ok) {
    yield put(DoGetDevicesHealthStatSuccess(dmStatData));
  } else {
    yield put(DoGetDevicesHealthStatFailed());
    yield put(NotifyError(`Error while fetching Device Management statistic: ${dmStat.message}`));
  }

  const connectivityData: { connectivity: number; log: ConnectivityChartData[] } = {
    connectivity: 0,
    log: []
  };

  if (connectivityAvg.reason === ReasonEnum.Ok) {
    connectivityData.connectivity = connectivityAvg.data.connectivity as number;
  } else {
    yield put(NotifyError(`Error while fetching connectivity statistic: ${connectivityAvg.message}`));
  }

  if (connectivityLog.reason === ReasonEnum.Ok) {
    yield put(DoGetConnectivitySuccess(
      connectivityData.connectivity,
      connectivityLog.data,
      dmStatData
    ));
  } else {
    yield put(NotifyError(`Error while fetching connectivity logs: ${connectivityLog.message}`));
  }
}

function* initDeviceStatistic() {
  const state: RootState = yield select();
  yield call(fetchDeviceStatistic, state.deviceStatistic.filters);
}


export const deviceStatisitcSagas = [
  takeEvery(FETCH_ALL_STAT_DM_START, fetchDeviceStatisticAction),
  takeEvery(INIT_ALL_STAT_DM_START, initDeviceStatistic),
];
