import { MessageType } from 'models/rabbit';

export const DICT_MESSAGE_TYPES_CHANGE = 'DICT_MESSAGE_TYPES_CHANGE';

export interface DictMessageTypesChange {
  readonly type: typeof DICT_MESSAGE_TYPES_CHANGE;
  messageTypes: MessageType[];
}

export const DictMessageTypesChange = (messageTypes: MessageType[]): DictMessageTypesChange => ({
  type: DICT_MESSAGE_TYPES_CHANGE, messageTypes
});

export type Action = DictMessageTypesChange
