import React from 'react';
import { useCallerTemplateDictionary } from 'hooks/caller';

// components
import { CallerTemplateNameDumb, CallerTemplateNameDumbProps } from './CallerTemplateNameDumb';

interface Props extends CallerTemplateNameDumbProps {
  templateId: number;
}

const CallerTemplateName = (props: Props): JSX.Element => {
  const { templateId } = props;
  const { templates } = useCallerTemplateDictionary();
  const template = templates.find((el) => el.template_id === templateId);

  return <CallerTemplateNameDumb template={ template } { ...props } />;
};

export default CallerTemplateName;
