import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useAuthUserSelector } from 'hooks';
import { StationEntity } from 'models/base-station';
import { canViewOwner } from 'utils/permissions';
import { FilterState, HealthyStatus, healthyStatusOptions, OnlineStatus, onlineStatusOptions } from './types';
// components
import { FilterButton } from 'components/Buttons';
import { SingleSelectControl } from 'components/Controls/Select';
import {
  OwnersSingleSelectControl,
  StationCitySelectControl,
  StationCountrySelectControl,
  StationGenerationSelectControl,
  StationSelectControl,
  ProjectSelectControl
} from 'components/Controls';
// styles
import useStyles from 'styles/filters';

export type {
  FilterState as BaseStationFilterState
};

interface Props {
  isLoading: boolean;
  filters: FilterState;
  updateFilters: (filters: FilterState) => void;
  resetFilters: () => void;
}

// @TODO think about reuse isStationFiltered (./BaseStation/ListPage/utils/useStationsByFilters.ts)
const stationSelectFilter = (state: FilterState) => (station: StationEntity) => {
  if (state.owner && state.owner !== station.owner_id)  {
    return false;
  }

  if (state.projects.length > 0 && (!station.location?.project || !state.projects.includes(station.location.project))) {
    return false;
  }

  return true;
};

export const BaseStationFilter = (props: Props) => {
  const { isLoading, filters, updateFilters, resetFilters } = props;
  const classes = useStyles();
  const user = useAuthUserSelector();
  const [state, setState] = useState<FilterState>(filters);
  useDeepCompareEffect(() => { setState(filters); }, [filters]);

  const onStationSelectChange = (stationIdentificator?: number | string) => {
    // LoRaWAN Eui is 16 lenght id, Usual StationId is only 4 character long
    setState(String(stationIdentificator).length < 16
      ? { ...state, stationId: Number(stationIdentificator), eui: undefined }
      : { ...state, eui: String(stationIdentificator), stationId: undefined }
    );
    if(!stationIdentificator) {
      setState({ ...state, eui: undefined, stationId: undefined });
    }
  };

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filters }
      onSave={ () => updateFilters(state) }
      onReset={ resetFilters }
    >
      <form className={ classes.gridRoot } autoComplete="off">
        { !canViewOwner(user) ? '' :
          <OwnersSingleSelectControl
            isClearable
            selected={ state.owner }
            changeHandler={ owner => setState({ ...state, owner }) }
          />
        }
        <ProjectSelectControl
          isMulti
          isClearable
          selected={ state.projects }
          onChange={ (projects: number[]) => setState({ ...state, projects }) }
          filter={ project => !state.owner || state.owner === project.owner_id }
        />
        <StationSelectControl
          isClearable
          isMulti={ false }
          selected={ state.stationId ?? state.eui }
          filter={ stationSelectFilter(state) }
          onChange={ onStationSelectChange }
        />
        <StationCountrySelectControl
          isClearable
          isMulti={ false }
          selected={ state.country }
          onChange={ country => setState({ ...state, country }) }
        />
        <StationCitySelectControl
          isClearable
          isMulti={ false }
          selected={ state.city }
          filter={ location => location.country === state.country }
          onChange={ city => setState({ ...state, city }) }
        />
        <StationGenerationSelectControl
          isClearable
          selected={ state.generation }
          onChange={ generation => setState({ ...state, generation }) }
        />
        <SingleSelectControl
          isClearable
          name="onlineStatus"
          label="Online Status"
          selected={ state.onlineStatus }
          values={ onlineStatusOptions }
          changeHandler={ selected => setState({ ...state, onlineStatus: selected as OnlineStatus }) }
        />
        <SingleSelectControl
          isClearable
          name="healthyStatus"
          label="Health status"
          selected={ state.healthyStatus }
          values={ healthyStatusOptions }
          changeHandler={ selected => setState({ ...state, healthyStatus: selected as HealthyStatus }) }
        />
      </form>
    </FilterButton>
  );
};
