import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRabbitEndpointLoadSelectorById } from 'hooks/rabbit';

// components
import { Box, Grid } from '@material-ui/core';
import { EndpointManagement, EndpointSetting, EndpointConnectedZones, EndpointMonitoring } from 'components/Rabbit';
import { EndpointNotFound } from './widget/EndpointNotFound';
import { EndpointSkeleton } from './widget/EndpointSkeleton';
// styles
import useStyles from 'styles/page';

interface Props {
  endpointId: number;
}
export const RabbitEndpointDetailPage = ({ endpointId }: Props): JSX.Element => {
  const { isLoading, endpoint } = useRabbitEndpointLoadSelectorById(endpointId);
  const classes = useStyles();


  return (
    <Box className={ classes.root } data-testid="page-rabbit-endpoint-detail">
      <Helmet><title>RabbitMQ Endpoint { endpoint?.name ?? `#${endpointId}` } </title></Helmet>
      { !endpoint && isLoading  && <EndpointSkeleton /> }
      { !endpoint && !isLoading && <EndpointNotFound endpointId={ endpointId } /> }
      { endpoint && (
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 } sm={ 12 }>
            <EndpointManagement endpoint={ endpoint }/>
          </Grid>
          <Grid item xs={ 12 } sm={ 12 } container spacing={ 1 }>
            <Grid item xs={ 12 } md={ 4 }>
              <EndpointSetting endpoint={ endpoint } />
            </Grid>
            <Grid item xs={ 12 } md={ 8 }>
              <EndpointConnectedZones endpoint={ endpoint } />
            </Grid>
          </Grid>
          <Grid item xs={ 12 } sm={ 12 }>
            <EndpointMonitoring endpoint={ endpoint } />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

