import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RootState } from 'reducers';

import { ApiResponse, ReasonEnum } from 'models';
import { GetRoutersParams, Router } from 'models/caller';
import { createRouter, deleteRouter, getRouters, updateRouter } from 'clients/http-calls';
import * as Action from 'actions/caller';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ActionWithPromise } from 'utils/store';

function* loadRouterData(action: Action.LoadRouterData) {
  yield put({ type: Action.CALLER_ROUTER_DATA_FETCH_START });
  const response: ApiResponse = yield call(getRouters, action.params);
  yield put({ type: Action.CALLER_ROUTER_DATA_FETCH_STOP });

  if (response.reason === ReasonEnum.Ok) {
    yield put({ type: Action.CALLER_ROUTER_DATA_CHANGE, params: action.params, data: response.data });
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching caller routers: ${ message }`));
  }
}

type CreateAction =
  | Action.CreateRouter
  | ActionWithPromise<Action.CreateRouter, Router>
function* doCreateRouter(action: CreateAction) {
  yield put(Action.LockRouter(0));
  const response: ApiResponse = yield call(createRouter, action.router);
  yield put(Action.UnlockRouter(0));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeRouter(response.data, { create: true }));
    yield put(NotifySuccess('Caller router has been created'));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while creating a caller router: ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

function* doUpdateRouter(action: Action.UpdateRouter) {
  yield put(Action.LockRouter(action.router.route_id));
  const response: ApiResponse = yield call(updateRouter, action.router);
  yield put(Action.UnlockRouter(action.router.route_id));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeRouter(response.data, { update: true }));
    yield put(NotifySuccess(`Caller router #${ action.router.route_id } has been updated`));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while updating caller router #${ action.router.route_id }: ${ message }`));
  }
}

function* doDeleteRouter(action: Action.DeleteRouter) {
  yield put(Action.LockRouter(action.router.route_id));
  const response: ApiResponse = yield call(deleteRouter, action.router);
  yield put(Action.UnlockRouter(action.router.route_id));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeRouter(action.router, { delete: true }));
    yield put(NotifySuccess(`Caller router #${ action.router.route_id } has been deleted`));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting caller router #${ action.router.route_id }: ${ message }`));
  }
}

type DeleteRouters = (
  | ActionWithPromise<Action.DeleteRouters, void>
  | Action.DeleteRouters
);

function* doDeleteRouters(action: DeleteRouters) {
  for (const router of action.routers) {
    const response: ApiResponse = yield call(deleteRouter, router);

    if (response.reason !== ReasonEnum.Ok) {
      const message = response.message || 'Server error';
      yield put(NotifyError(`Error while disconnecting a zone: ${ message }`));
      'meta' in action && action.meta.promise.reject(new Error(message));
      return;
    }
  }

  yield put(NotifySuccess('Zone has been disconnected from template suite'));
  'meta' in action && action.meta.promise.resolve();
}

function* doActivateRouter(action: Action.ActivateRouter) {
  yield put(Action.LockRouter(action.router.route_id));
  const response: ApiResponse = yield call(updateRouter, {
    ...action.router,
    is_active: true
  });
  yield put(Action.UnlockRouter(action.router.route_id));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeRouter(response.data, { update: true }));
    yield put(NotifySuccess(`Caller router #${ action.router.route_id } has been activated`));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while activating caller router #${ action.router.route_id }: ${ message }`));
  }
}

function* doDeactivateRouter(action: Action.DeactivateRouter) {
  yield put(Action.LockRouter(action.router.route_id));
  const response: ApiResponse = yield call(updateRouter, {
    ...action.router,
    is_active: false
  });
  yield put(Action.UnlockRouter(action.router.route_id));

  if (response.reason === ReasonEnum.Ok) {
    yield put(Action.ChangeRouter(response.data, { update: true }));
    yield put(NotifySuccess(`Caller router #${ action.router.route_id } has been deactivated`));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deactivating caller router #${ action.router.route_id }: ${ message }`));
  }
}

function* doChangeRouter(action: Action.ChangeRouter) {
  const state: RootState = yield select();
  const params: GetRoutersParams = state.callerRouters.params;
  const routers: Router[] = state.callerRouters.data;
  const index = routers.findIndex((router: Router) => router.route_id === action.router.route_id);
  if (action.options.create && index === -1) {
    routers.push(action.router);
  } else if (action.options.update && index !== -1) {
    routers[index] = action.router;
  } else if (action.options.delete && index !== -1) {
    routers.splice(index, 1);
  }

  const data = routers.filter((router: Router) => {
    if (params.templateSuiteId !== undefined && params.templateSuiteId !== router.template_suite_id) {
      return false;
    }

    if (params.zoneId !== undefined && params.zoneId !== router.zone_id) {
      return false;
    }

    if (params.groupId !== undefined && params.groupId !== router.group_id) {
      return false;
    }

    if (params.isActive !== undefined && params.isActive !== router.is_active) {
      return false;
    }

    return true;
  });

  yield put({
    type: Action.CALLER_ROUTER_DATA_CHANGE,
    params: state.callerRouters.params,
    data: data
  });
}

export default [
  takeEvery(Action.CALLER_ROUTER_DATA_LOAD, loadRouterData),
  takeEvery(Action.CALLER_ROUTER_CREATE, doCreateRouter),
  takeEvery(Action.CALLER_ROUTER_UPDATE, doUpdateRouter),
  takeEvery(Action.CALLER_ROUTER_DELETE, doDeleteRouter),
  takeEvery(Action.CALLER_ROUTERS_DELETE, doDeleteRouters),
  takeEvery(Action.CALLER_ROUTER_ACTIVATE, doActivateRouter),
  takeEvery(Action.CALLER_ROUTER_DEACTIVATE, doDeactivateRouter),
  takeEvery(Action.CALLER_ROUTER_CHANGE, doChangeRouter),
];
