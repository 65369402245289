import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Template } from 'models/caller';
import { CallerResponseCodeHistoryParams } from 'models/caller-monitoring';

import { useRequestHistoryChartData } from './useRequestHistoryChartData';
import { formatTemplate } from './utils';

// components
import { SliceTooltip, SliceTooltipProps } from '@nivo/line';

import { MonitoringChartTooltip } from './MonitoringChartTooltip';
import { MonitoringLineChart } from './MonitoringLineChart';

interface TooltipProps extends SliceTooltipProps {
  templateById: Record<number, Template>;
}

const Tooltip = ({ slice, templateById }: TooltipProps) => {
  const date = (slice.points[0]?.data.x as Date | undefined)?.toJSON();

  if (!date) {
    return null;
  }

  return (
    <MonitoringChartTooltip
      date={ date }
      entries={ [...slice.points].reverse().map(point => ({
        id: point.serieId,
        color: point.serieColor,
        value: point.data.y as number,
        label: templateById[point.serieId as number]?.name ?? `#${ point.serieId }`,
      })) }
      showTime
    />
  );
};

interface RequestHistoryChartProps {
  filters: CallerResponseCodeHistoryParams;
  templateById: Record<number, Template>;
}

export const RequestHistoryChart = ({ filters, templateById }: RequestHistoryChartProps) => {
  const data = useRequestHistoryChartData(filters);

  const [tooltip, setTooltip] = useState<SliceTooltip>(() => (props: SliceTooltipProps) => (
    <Tooltip { ...props } templateById={ templateById } />
  ));

  useEffect(() => {
    setTooltip(() => (props: SliceTooltipProps) => (
      <Tooltip { ...props } templateById={ templateById } />
    ));
  }, [templateById]);

  return (
    <MonitoringLineChart
      data={ data.data }
      isLoading={ data.isLoading }
      maxValue={ data.maxValue }
      colors={ data.data.map(serie => serie.color) }
      sliceTooltip={ tooltip }
      durationDays={ moment.duration(moment(filters.dateTo).diff(filters.dateFrom)).asDays() }
      legendProps={ {
        getEntryLabel: entry => formatTemplate(templateById, entry.id as number),
      } }
    />
  );
};
