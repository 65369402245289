import { identity } from 'lodash';
import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { TemplateSuite } from 'models/caller';
import { validate } from './validator';
// components
import { OwnersSingleSelectControl, TextControl } from 'components/Controls';
// styles
import clsx from 'clsx';
import { useStyles } from './styles';

export enum TemplateSuiteFormField {
  name = 'name',
  owner_id = 'owner_id',
}

type CallerTemplateSuiteFormProps = (
  & FormProps<TemplateSuite, TemplateSuiteFormField>
  & {
    className?: string;
    renderFields?: (fieldsNode: React.ReactNode) => React.ReactNode;
    renderField?: (fieldNode: React.ReactNode) => React.ReactNode;
  }
);

export const CallerTemplateSuiteForm = (props: CallerTemplateSuiteFormProps): JSX.Element => {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate, renderFields = identity, renderField = identity } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends TemplateSuiteFormField>(
    field: TField,
    value: TemplateSuite[TField],
  ) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form
      className={ clsx(isView ? classes.viewMode : classes.editMode, props.className) }
      noValidate
      autoComplete="off"
    >
      { renderFields(
        <>
          { !showFields.includes(TemplateSuiteFormField.name) ? '' :
            renderField(
              <TextControl
                required={ !isView }
                disabled={ isView || disabledFields.includes(TemplateSuiteFormField.name) }
                label="Name"
                value={ state.name || '' }
                error={ showErrors.includes(TemplateSuiteFormField.name) ? errors?.name !== undefined : false }
                helperText={ showErrors.includes(TemplateSuiteFormField.name) ? errors?.name : undefined }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(TemplateSuiteFormField.name, event.target.value);
                } }
              />
            )
          }
          { !showFields.includes(TemplateSuiteFormField.owner_id) ? '' :
            renderField(
              <OwnersSingleSelectControl
                isRequired={ !isView }
                isDisabled={ isView || disabledFields.includes(TemplateSuiteFormField.owner_id) }
                isClearable={ false }
                selected={ state.owner_id }
                error={ showErrors.includes(TemplateSuiteFormField.owner_id) ? errors?.owner_id : undefined }
                // `owner as number` is valid as long as `isClearable !== true`
                changeHandler={ (owner?: number) => handleChange(TemplateSuiteFormField.owner_id, owner as number) }
              />
            )
          }
        </>
      ) }
    </form>
  );
};

