import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    managementRoot: {
      height: '60vh',
      minHeight: '550px',
      padding: theme.spacing(2),
    },
    mapRoot: {
      height: '60vh',
      minHeight: '550px',
    },
    labelsRow: {
      alignItems: 'center',
      marginTop: theme.spacing(2),
    }
  }),
);

export default useStyles;
