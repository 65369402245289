import { Template } from 'models/caller';
import { FormErrors } from 'types/form';
import { validateUrlHost } from 'utils/validator';
import { TemplateFormField } from './CallerTemplateForm';

export const validate = (template: Template, fields: TemplateFormField[]): FormErrors<TemplateFormField> => {
  const errors: FormErrors<TemplateFormField> = {};
  if (fields.includes(TemplateFormField.name)) {
    if (!template.name.trim()) {
      errors.name = 'Name is required';
    }
  }

  if (fields.includes(TemplateFormField.templateSute)) {
    if (!template.template_suite_id) {
      errors.template_suite_id = 'Suite is required';
    }
  }

  if (fields.includes(TemplateFormField.messageType)) {
    if (!template.message_type) {
      errors.message_type = 'Message type is required';
    }
  }

  if (fields.includes(TemplateFormField.url)) {
    if (!template.url.trim()) {
      errors.url = 'URL is required';
    } else if (!validateUrlHost(template.url)) {
      errors.url = 'URL is not valid';
    }
  }

  return errors;
};
