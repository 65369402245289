import { API } from 'aws-amplify';

import { ApiResponse } from 'models';
import {
  GetTemplateSuitesParams,
  TemplateSuite,
  TemplateSuiteId,
  TemplateSuiteUpdateFields,
} from 'models/caller';
import { errorHandler, formatQuery } from 'clients/utils';

import { config } from './config';

const urls = {
  templateSuites: '/calls/template_suites',
  getSuitById: (id: TemplateSuiteId) => `/calls/template_suites/${ id }`,
};

export const getTemplateSutes = (params: GetTemplateSuitesParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      name: params.name,
      owner_id: params.owner,
      limit: params.limit ?? 2000,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.templateSuites, body)
    .catch(errorHandler);
};

export const createTemplateSuite = (templateSuite: TemplateSuite): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.templateSuites, {
      body: {
        ...templateSuite, template_suite_id: undefined, // backend don't want see identifier in body
      }
    })
    .catch(errorHandler);
};

export const getSuiteById = (id: TemplateSuiteId): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.getSuitById(id), {})
    .catch(errorHandler);
};

export const updateSuiteById = (id: TemplateSuiteId, fields: TemplateSuiteUpdateFields): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.getSuitById(id), { body: fields })
    .catch(errorHandler);
};

export const removeSuiteById = (id: TemplateSuiteId): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.getSuitById(id), {})
    .catch(errorHandler);
};
