import React from 'react';
import { Firmware } from 'models/firmware-management';
import { useFirmwaresDictionarySelector } from 'hooks/firmware-management';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (firmware: Firmware) => boolean }

export const FirmwareSelectControl = (props: Props): JSX.Element => {
  const { isLoading, firmwares } = useFirmwaresDictionarySelector();
  const filterCallback = props.filter ?? (() => (true));

  const options: Option<string>[] =
    firmwares
      .filter(filterCallback)
      .map(firmware => ({
        value: firmware.hash,
        label: `${ firmware.name }/${ firmware.version }`,
      }));

  return (
    <SelectControl
      name="firmware"
      label="Firmwares"
      isClearable
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ !isLoading }
      isDisabled={ !options.length || props.isDisabled }
    />
  );
};
