export const DM_ZONE_DATA_RESET = 'dm/zone/RESET';

export interface ResetDMZoneData {
  readonly type: typeof DM_ZONE_DATA_RESET;
}

export const ResetDMZoneData = (): ResetDMZoneData => ({
  type: DM_ZONE_DATA_RESET
});

export type Action = ResetDMZoneData
