import React from 'react';

// styles
import clsx from 'clsx';
import useStyles from './styles';

// Sometimes the external image was downloaded after the data, so it seemed better to inline
// the image in order to indicate that some parts of the page are loading.
// It's a minified and recolored version of removed 'images/Loader/loader.svg':
// eslint-disable-next-line max-len
const svgSrc = `<svg width="26" height="26" viewBox="0 0 15.7 3.812" xmlns="http://www.w3.org/2000/svg"><circle cx="1.844" cy="1.895" r="1.794" fill="#B0B0B0"><animate fill="remove" accumulate="none" additive="replace" attributeName="opacity" begin=".1" calcMode="linear" dur="1s" repeatCount="indefinite" restart="always" values="0;1;0"/></circle><circle cx="7.823" cy="1.895" r="1.794" fill="#B0B0B0"><animate fill="remove" accumulate="none" additive="replace" attributeName="opacity" begin=".2" calcMode="linear" dur="1s" repeatCount="indefinite" restart="always" values="0;1;0"/></circle><circle cx="13.802" cy="1.895" r="1.794" fill="#B0B0B0"><animate fill="remove" accumulate="none" additive="replace" attributeName="opacity" begin=".3" calcMode="linear" dur="1s" repeatCount="indefinite" restart="always" values="0;1;0"/></circle></svg>`;

const imgSrc = `data:image/svg+xml;utf8,${encodeURIComponent(svgSrc)}`;

export const LoaderSvg = (props: React.ComponentPropsWithoutRef<'img'>): JSX.Element => {
  const classes = useStyles();
  return (
    <img
      { ...props }
      className={ clsx(classes.svgLoader, props.className) }
      src={ imgSrc }
      alt={ props.alt || 'Loading...' }
    />
  );
};
