import { groupBy as groupByLodash } from 'lodash';
import moment from 'moment';

import { CallerHistoryParamsGroupBy, ResponseCode } from 'models/caller-monitoring';

export const getDefaultGroupBy = (dateFrom: string, dateTo: string): CallerHistoryParamsGroupBy => {
  const diff = moment.duration(moment(dateTo).diff(dateFrom));

  if (diff.asDays() > 14) {
    return CallerHistoryParamsGroupBy.Day;
  }
  if (diff.asHours() > 3) {
    return CallerHistoryParamsGroupBy.Hour;
  }
  return CallerHistoryParamsGroupBy.Minute;
};

interface ChartDatesOptions {
  dateFrom: string;
  dateTo: string;
  stepUnit?: CallerHistoryParamsGroupBy;

  /**
   * Должна ли первая дата быть не раньше `dateFrom`.
   * По умолчанию `false`.
   *
   * Примеры (для простоты указано только время):
   *
   * Первая дата для `dateFrom` = 00:20 и `stepUnit` = 'hour':
   * - Для `true`: 01:00
   * - Для `false`: 00:00
   *
   * Первая дата для `dateFrom` = 00:00 и `stepUnit` = 'hour':
   * - Для `true`: 00:00
   * - Для `false`: 00:00
   */
  firstDateAfterOrEqualToDateFrom?: boolean;

  /**
   * Устанавливать ли начало дней и более крупных `stepUnit` по UTC или по местному времени.
   * По умолчанию `true`.
   *
   * Пример даты для местного времени UTC+7 и `stepUnit` = 'day':
   * - Для `true`:  2020-01-01T00:00:00Z (00:00 по UTC)
   * - Для `false`: 2020-12-31T17:00:00Z (00:00 по UTC+7)
   */
  alignToUTC?: boolean;
}

export function generateChartDates({
  dateFrom,
  dateTo,
  stepUnit,
  alignToUTC = true,
  firstDateAfterOrEqualToDateFrom = false,
}: ChartDatesOptions): string[] {
  const dates: string[] = [];

  const dateFromMoment = moment(dateFrom);
  const dateToMoment = moment(dateTo);

  if (!dateFromMoment.isValid() || !dateToMoment.isValid()) {
    throw new Error(`dateFrom or/and dateTo are invalid dates: ${ dateFrom }, ${ dateTo }`);
  }

  if (!stepUnit) {
    stepUnit = getDefaultGroupBy(dateFrom, dateTo);
  }

  let dateIterator = dateFromMoment.clone().startOf(stepUnit);

  if (
    alignToUTC && (
      stepUnit === CallerHistoryParamsGroupBy.Day ||
      stepUnit === CallerHistoryParamsGroupBy.Week ||
      stepUnit === CallerHistoryParamsGroupBy.Month ||
      stepUnit === CallerHistoryParamsGroupBy.Quarter ||
      stepUnit === CallerHistoryParamsGroupBy.Year
    )
  ) {
    dateIterator = moveStartOfDayToUTC(dateIterator);
  }

  while (firstDateAfterOrEqualToDateFrom && dateIterator.isBefore(dateFromMoment)) {
    dateIterator = dateIterator.clone().add(1, stepUnit);
  }

  while (!dateIterator.isAfter(dateToMoment)) {
    dates.push(dateIterator.toJSON());
    dateIterator = dateIterator.clone().add(1, stepUnit);
  }

  return dates;
}

interface HistoryEntry {
  date: string;
}

export function fillGaps<THistoryEntry extends HistoryEntry>(
  entries: THistoryEntry[],
  dateFrom: string,
  dateTo: string,
  groupBy: CallerHistoryParamsGroupBy,
  getEntry: (date: string, count: number) => THistoryEntry,
): THistoryEntry[] {
  const dates = generateChartDates({ dateFrom, dateTo, stepUnit: groupBy, alignToUTC: true });
  const finalEntriesDictionary = groupByLodash(entries, 'date');

  for (const date of dates) {
    if (!finalEntriesDictionary[date]?.length) {
      finalEntriesDictionary[date] = [getEntry(date, 0)];
    }
  }

  const finalEntries = [...Object.values(finalEntriesDictionary)]
    .flatMap(dateHistoryEntries => dateHistoryEntries)
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());

  return finalEntries;
}

export function moveStartOfDayToUTC(date: moment.Moment): moment.Moment {
  return date.clone().utc(true);
}

export function mapNumberToResponseCode(value: number | null): ResponseCode | undefined {
  if (typeof value !== 'number') {
    return;
  }
  if (value >= 200 && value < 300) {
    return ResponseCode.SUCCESS;
  }
  if (value >= 300 && value < 400) {
    return ResponseCode.REDIRECT;
  }
  if (value >= 400 && value < 500) {
    return ResponseCode.VALIDATION_ERROR;
  }
  if (value >= 500 && value < 600) {
    return ResponseCode.SERVER_ERROR;
  }
  return ResponseCode.OTHER;
}
