import React from 'react';

import { Label } from 'models/device-management';

// components
import { AddLabelForm } from './AddLabelForm';
import { isAllowedToCreateLabels } from 'utils/permissions';
import { Box } from '@material-ui/core';
import { FieldSkeleton } from 'components/Skeleton';
import { useAuthUserSelector } from 'hooks';

interface AddLabelProps {
  positionOwnerId?: number;
  onSuccess: (createdLabel: Label) => void;
}

export const AddLabel = ({ positionOwnerId, onSuccess }: AddLabelProps) => {
  const { data: user } = useAuthUserSelector();

  if (!isAllowedToCreateLabels(user)) {
    return null;
  }

  if (!positionOwnerId) {
    return <FieldSkeleton />;
  }

  return (
    <Box mt={ 2 } mb={ 1 }>
      <AddLabelForm
        ownerId={ positionOwnerId }
        onSuccess={ onSuccess }
      />
    </Box>
  );
};
