import { sumBy } from 'lodash';
import React from 'react';

import { formatAsPercent } from 'utils/format';
import { MonitoringTooltipProps } from '../types';

// components
import { Grid, Paper, Typography } from '@material-ui/core';

// styles
import clsx from 'clsx';
import { useStyles } from './styles';

export const MonitoringChartTooltip = (props: MonitoringTooltipProps) => {
  const classes = useStyles();
  const {
    title,
    entries,
    formatLabel = entry => entry.label ?? entry.id,
    total = { label: 'Total', count: sumBy(entries, entry => entry.value) },
  } = props;

  const longestFractionLength = Math.max(
    ...entries.map(entry => formatAsPercent(entry.value, total.count ?? 0).length),
  );

  return (
    <Paper className={ classes.root } data-testid="monitoring-chart-tooltip">
      <Grid
        container
        spacing={ 1 }
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item data-testid="monitoring-chart-tooltip-date">
          <Typography variant="inherit" className={ clsx(classes.label, classes.date) }>
            { title }
          </Typography>
        </Grid>

        { total && (
          <Grid item className={ classes.total } data-testid="monitoring-chart-tooltip-total">
            { total.color && (
              <span
                className={ classes.icon }
                style={ { backgroundColor: total.color } }
              />
            ) }
            <Typography variant="inherit" className={ classes.label }>
              { total.label } { total.count }
            </Typography>
          </Grid>
        ) }
      </Grid>

      { entries.map((e, index) => (
        <Grid
          container
          spacing={ 1 }
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          key={ index }
          data-testid="monitoring-chart-tooltip-item"
        >
          <Grid item>
            <Typography variant="inherit" className={ classes.label }>
              { e.color && (
                <span
                  className={ classes.icon }
                  style={ { backgroundColor: e.color } }
                  data-testid="monitoring-chart-tooltip-item-color"
                />
              ) }
              { formatLabel(e) }
            </Typography>
          </Grid>

          <Grid item className={ classes.valueContainer }>
            <span className={ classes.value }>
              { e.value }
            </span>

            { typeof e.value === 'number' && (
              <span
                className={ classes.valueFraction }
                style={ { minWidth: (longestFractionLength + 2) * 6 } }
              >
                { ' ' }({ formatAsPercent(e.value, total.count) })
              </span>
            ) }
          </Grid>
        </Grid>
      )) }
    </Paper>
  );
};
