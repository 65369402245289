import { DEFAULT_LIMIT } from 'models/common';

export enum BoxesFiltersFields {
  device_id = 'device_id',
  box_id = 'box_id',
  shipment_id = 'shipment_id',
  allowed_for_shipment_only = 'allowed_for_shipment_only',
  empty = 'empty',
  owner = 'owner',
  limit = 'limit',
  offset = 'offset'
}

export interface ProvisioningBoxesFilters {
  [BoxesFiltersFields.device_id]: string[];
  [BoxesFiltersFields.box_id]: number[];
  [BoxesFiltersFields.shipment_id]: string[];
  [BoxesFiltersFields.allowed_for_shipment_only]?: boolean;
  [BoxesFiltersFields.empty]?: boolean | null;
  [BoxesFiltersFields.owner]: number[];
}

export interface GetBoxesParams extends ProvisioningBoxesFilters {
  [BoxesFiltersFields.limit]: number;
  [BoxesFiltersFields.offset]: number;
}

export const initProvisioningBoxesFilters: GetBoxesParams = {
  [BoxesFiltersFields.device_id]: [],
  [BoxesFiltersFields.box_id]: [],
  [BoxesFiltersFields.shipment_id]: [],
  [BoxesFiltersFields.allowed_for_shipment_only]: undefined,
  [BoxesFiltersFields.empty]: undefined,
  [BoxesFiltersFields.owner]: [],
  [BoxesFiltersFields.limit]: DEFAULT_LIMIT,
  [BoxesFiltersFields.offset]: 0,
};
