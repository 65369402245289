import React from 'react';
import clsx from 'clsx';
import { Statuses, IntegrationLastTimeReceive } from 'models/lorawan';
import { humanReadableTime } from './utils';

// components
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// styles
import useStyles, { iconStyle } from './styles';

interface Props {
  status: Statuses;
  lastReceiveTime?: IntegrationLastTimeReceive;
}

export const IntegrationStatus = ({ status, lastReceiveTime }: Props): JSX.Element => {
  const classes = useStyles();

  switch (status) {
    case Statuses.NotSet:
      return (
        <Typography className={ classes.status }>
          <CloseIcon style={ iconStyle } /> Not set
        </Typography>
      );
    case Statuses.UnderConstruction:
      return (
        <Typography className={ classes.status }>
          <CloseIcon style={ iconStyle } /> Under construction
        </Typography>
      );
    case Statuses.Enabled:
      return (
        <>
          <Typography className={ clsx(classes.status, classes.statusWaiting) }>
            <CheckIcon style={ iconStyle } color="primary" /> Enabled
          </Typography>
          <Typography className={ clsx(classes.status, classes.timeText) }>
            {
              !lastReceiveTime
                ? <><AccessTimeIcon style={ iconStyle } /> Waiting for messages</>
                : `last message ${humanReadableTime(lastReceiveTime.last_receive_time)}`
            }
          </Typography>
        </>
      );

    default:
      return <></>;
  }
};
