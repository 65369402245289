import { LorawanStation, StationType } from 'models/base-station';
import { StationStatus } from 'models/base-station-status';
import { LorawanStationStatusHistoryRes } from 'clients/base-stations-status';

export const normalizeStations = (data?: Omit<LorawanStation, 'type'>[]) => data ? data.map(station => ({
  ...station,
  type: StationType.lorawan
} as LorawanStation)) : [];

export const normalizeLorawanStationStatusHistoryRes = (data?: LorawanStationStatusHistoryRes) => {
  if (!data) { return []; }
  return data[0] && data[0].offline_history ? data[0].offline_history : [];
};

export const getStatuses = (data?: StationStatus[]) => {
  const statuses = data && data[0] && data[0].statuses;
  // check offline only for Lorawan stations
  const offlineStatus = statuses?.find(s => s.name === 'offline');
  if (data && data[0] && offlineStatus) {
    return {
      stationId: data[0].id,
      isOffline: Boolean(offlineStatus.value),
      offlineChanged: offlineStatus.last_change !== null ? new Date(offlineStatus.last_change) : undefined,
    };
  }
  return undefined;
};

export const mapStatusToStation = (stations: LorawanStation[], statuses?: StationStatus[]) => {
  return stations.map(s => {
    const stationStatus = statuses?.find(status => status.id === s.id);
    return {
      ...s,
      status: stationStatus && getStatuses([stationStatus]),
    };
  });
};