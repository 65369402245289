import React from 'react';
import { TreeNode } from 'hooks/device-management/useTree/types';
import { LabelCount } from 'models/device-management';
// components
import { DmLabels } from 'components/DmLabels';

interface Props {
  node: TreeNode;
}

export const LabelsCell = ({ node }: Props) => {
  const labels = node.type !== 'position' ? calculateLabels(node) : node.position.labels ?? [];
  return (
    <DmLabels labels={ labels }/>
  );
};

const calculateLabels = (node: TreeNode): LabelCount[] => {
  switch (node.type) {
    case 'zone':
    case 'level':
    case 'group':
      const labels: Record<number, LabelCount> = {};
      Object.values(node.children.items).forEach(children => {
        calculateLabels(children).forEach(label => {
          const labelId = label.id ?? 0;
          labels[labelId] ? labels[labelId].count++ : labels[labelId] = label;
        });
      });

      return Object.values(labels);
    case 'position':
      return (node.position.labels ?? []).map(label => ({ ...label, count: 1 }));
  }
};