import { State } from 'reducers/pages/station-monitoring';
import { getDurationDays } from './utils';
import { useStatusChartData } from './hooks/useStatusChartData';

// components
import { Box } from '@material-ui/core';
import { BlockLoader } from 'components/Loaders';
import { Chart } from './components/Chart';

// styles
import { colors } from 'styles/themes/outer';

interface Props {
  online: State['onlineHistory'];
}


export const OnlineHistoryChart = ({ online }: Props): JSX.Element => {
  const onlineHistory = useStatusChartData({ statusHistory: online, chartName: 'VPN' });

  return (
    <Box height={ 100 } mt={ '-40px' } data-testid="chart">
      { !online.fetched ? <BlockLoader size={ 20 } /> :
        <Chart
          data={ onlineHistory.data }
          maxValue={ onlineHistory.maxValue }
          title={ 'VPN' }
          color={ colors.lightBlue }
          valueFormat={ value => value ? 'Available' : 'Unavailable' }
          showAxisBottom
          durationDays={ getDurationDays(online.dateRange) }
        />
      }
    </Box>
  );
};
