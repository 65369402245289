export interface BSConnectivityMapFilters {
  owner?: number;
  projects?: number[];
  zones?: number[];
  positionGroups?: number[];
  isOnline?: boolean;
  isHealthy?: boolean;
  connectivity: number[];
}

export const initMapFilters: BSConnectivityMapFilters = {
  connectivity: [0, 100],
  isHealthy: undefined,
  isOnline: undefined,
  owner: undefined,
  projects: [],
  zones: [],
  positionGroups: []
};

