import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocationItems } from 'hooks/location';
import { usePagination } from 'hooks';
import { initVirtualDevicesFilters, VirtualDevicesFiltersFields } from 'models/device-management';

export const useVirtualDevicesPageParams = () => {
  const { items, setItems } = useLocationItems();
  const [filters, setFilters] = useState<VirtualDevicesFiltersFields>(initVirtualDevicesFilters);
  const { limit, offset } = usePagination();

  useEffectOnce(() => {
    delete items.limit;
    delete items.offset;
    setFilters(items);
  });

  return {
    params: { ...filters, limit, offset },
    filters,
    setFilters: (filters: VirtualDevicesFiltersFields) => {
      setFilters(filters);
      setItems({ ...filters, limit, offset: 0 });
    },
    resetFilters: () => {
      setFilters(initVirtualDevicesFilters);
      setItems({ ...initVirtualDevicesFilters, limit, offset: 0 });
    }
  };
};