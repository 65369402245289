import React from 'react';
import { Tree, TreeNode } from 'hooks/device-management/useTree/types';
import { commonOptions } from 'utils/tables';
import { getNameCellLabel, useTreeTableRows } from './utils';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout } from 'components/Table';
import * as cells from './cells';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

interface Props {
  tree: Tree;
  node: TreeNode;
  loaded: boolean;
  embedded?: boolean;
}

export const NodeTreeTable = ({ tree, node, loaded, embedded = false }: Props) => {
  const data = useTreeTableRows(tree, node);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: getNameCellLabel(node),
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.NameCell
            tree={ tree }
            row={ data[dataIndex] }
          />
        )
      },
    },
    {
      name: 'devices',
      label: node.type === 'group' ? 'Devices' : 'Devices / Positions',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.DevicesPositionsCell
            tree={ tree }
            node={ data[dataIndex].node }
          />
        )
      },
    },
    {
      name: 'issue',
      label: 'Issues',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.IssuesCell
            tree={ tree }
            node={ data[dataIndex].node }
          />
        )
      },
    },
    {
      name: 'conn',
      label: 'Connectivity',
      options: {
        customBodyRenderLite: dataIndex => (
          <cells.ConnectivityCell
            tree={ tree }
            node={ data[dataIndex].node }
          />
        )
      },
    },
    {
      name: 'label',
      label: 'Labels',
      options: {
        customBodyRenderLite: i => (
          <cells.LabelsCell node={ data[i].node }/>
        )
      }
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    pagination: false,
  };

  return (
    <MuiThemeProvider theme={ truncatedCellsMuiTableTheme(data.length, { embeddedTable: embedded }) }>
      <TableLoadingLayout isLoading={ !loaded }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};
