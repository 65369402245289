import { fetchPositionConnectivity } from '../../../api';
import { getPositionNodes } from '../../../node/utils';
import { PositionNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillPositionConnectivity({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, PositionNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getPositionNodes(getNodes(nodes)),
    getQuery: node => node.connectivity,
    fetchDataByNode: fetchPositionConnectivity
  });
}
