import { RouteConfig } from 'react-router-config';
import { match as Match } from 'react-router-dom';
import { State as UserState } from 'reducers/user-profile';
import { isLorawan } from 'utils/isLorawan';
import { BaseStationDetailPage, LorawanStationDetailPage, BaseStationListPage } from 'pages';
import { BaseStationName, LorawanStationName } from 'components/Labels';
import * as paths from 'routing/paths/base-station';
import { canViewStation, canViewStationList } from 'utils/permissions';

const STATION_ID_PARAM_NAME = 'suitId';
const STATION_ID_PARAM = `:${ STATION_ID_PARAM_NAME }`;

interface Params {
  [STATION_ID_PARAM_NAME]?: string;
}

const renderWithParams = (match: Match<Params>, Base: React.ElementType, Lora: React.ElementType): false | React.ReactNode => {
  const stationId = Number(match.params[STATION_ID_PARAM_NAME] ?? 0);
  if (isNaN(stationId)) {
    return false;
  }

  if (isLorawan(stationId)) {
    return <Lora { ...{ stationId } }/>;
  }

  return <Base { ...{ stationId } }/>;
};

export const getBaseStationRouters = (userData: UserState): RouteConfig[] => {
  return [
    {
      path: paths.getBaseStationListPath(),
      label: 'Base Stations',
      icon: 'router',
      render: ({ match }) => match.isExact && <BaseStationListPage/>,
      hiddenOnMainMenu: !canViewStationList(userData),
      isShowByUrl: canViewStationList(userData),
      routes: [
        {
          path: paths.getBaseStationDetailPath(STATION_ID_PARAM),
          label: ({ match }) => match && renderWithParams(match, BaseStationName, LorawanStationName),
          render: ({ match }) => match.isExact && renderWithParams(match, BaseStationDetailPage, LorawanStationDetailPage),
          hiddenOnMainMenu: true,
          isShowByUrl: canViewStation(userData),
        },
      ],
    },
    {
      path: '/network/bs-coverage-map',
      exact: true,
      label: 'BS Coverage Map',
    },
  ];
};
