import React from 'react';
import { RouteConfig } from 'react-router-config';
import { State as UserState } from 'reducers/user-profile';
import { getProfileApiKeyDetailPath, getProfileClientPath, getProfileUserPath } from 'routing/paths';
import { UserManagementApiKeyDetailPage, UserManagementClientDetailPage } from 'pages/UserManagement';
import { canViewApiKey } from 'utils/permissions';
import { ApiKeyName } from 'components/Labels';
import { UrlItems } from 'utils/routing/query';
import { isNil } from 'lodash';
import { isUUID } from 'helpers';

const API_KEY_ID_PARAM_NAME = 'apiKeyId';

function getApiKeyId(params: UrlItems): false | string {
  if (isNil(params[API_KEY_ID_PARAM_NAME])) {
    return false;
  }

  const apiKeyId = String(params[API_KEY_ID_PARAM_NAME]);
  if (!isUUID(apiKeyId)) {
    return false;
  }

  return apiKeyId;
}

export function getProfileRoutes(user: UserState): RouteConfig[] {
  return [
    {
      path: '/profile',
      label: 'Profile',
      hiddenOnMainMenu: true,
      routes: [
        {
          path: getProfileUserPath(),
          label: 'User',
          main: undefined, // no implemented
        },
        {
          path: getProfileClientPath(),
          label: 'Company Info',
          hiddenOnMainMenu: true,
          render: ({ match }) => (
            match?.isExact && (
              <UserManagementClientDetailPage
                isProfile
                clientId={ Number(user.data['user_group']) }
              />
            )
          ),
          routes: [
            {
              path: getProfileApiKeyDetailPath(`:${ API_KEY_ID_PARAM_NAME }`),
              hiddenOnMainMenu: true,
              isShowByUrl: canViewApiKey(user),
              label: ({ match }) => {
                const apiKeyId = getApiKeyId(match?.params ?? {});
                if (!apiKeyId) {
                  return false;
                }

                return (
                  <ApiKeyName apiKeyId={ apiKeyId }/>
                );
              },
              render: ({ match }) => {
                const apiKeyId = getApiKeyId(match?.params ?? {});
                if (!match?.isExact || !apiKeyId) {
                  return false;
                }

                return (
                  <UserManagementApiKeyDetailPage
                    isProfile
                    apiKeyId={ apiKeyId }
                    clientId={ Number(user.data['user_group']) }
                  />
                );
              },
            }
          ]
        },
      ]
    },
  ];
}
