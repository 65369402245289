import { createStyles, makeStyles } from 'styles/utils';

const useDisabledSwitchStyles = makeStyles(theme =>
  createStyles({
    root: {
      opacity: 0.75,
    },
    switchBase: {
      '&$checked + $track': {
        opacity: 0.5,
      },
    },
    track: {
      opacity: theme.palette.type === 'light' ? 0.38 : 0.3,
    },
    colorPrimary: {
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    colorSecondary: {
      '&$checked': {
        color: theme.palette.secondary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    checked: {},
  }),
);

export default useDisabledSwitchStyles;
