import { call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { Task as ReduxSagaTask } from '@redux-saga/types';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/base-station';
import { LoadStationStatusDataFailure, LoadStationStatusDataStart, LoadStationStatusDataSuccess } from 'actions/base-station';
import { NotifyError } from 'actions/notifier';
import { getStationsStatus } from 'clients/base-stations-status';
import { StationStatusFlat } from 'models/base-station-status';
import { isNil } from 'utils/models';
import { RootState } from 'reducers';
import { autoRunWhenVisible } from 'utils/sagas';

const SYNC_DELAY_TIMEOUT = 30000;

interface ResponseData {
  id: number;
  statuses: Array<{
    name: 'offline' | 'has_issues' | 'uptime';
    value?: string | boolean;
    last_change: string;
  }>;
}

function buildStationStatusFromResponse(response: ApiResponse): Map<number, StationStatusFlat> {
  const result = new Map<number, StationStatusFlat>();
  for (const data of response.data as ResponseData[]) {
    const stationStatus: StationStatusFlat = { stationId: Number(data.id) };
    result.set(stationStatus.stationId, stationStatus);

    for (const status of data.statuses) {
      if (isNil(status.value)) {
        continue;
      }

      switch (status.name) {
        case 'has_issues':
          stationStatus.hasIssues = Boolean(status.value);
          stationStatus.issueChanged = new Date(status.last_change);
          break;
        case 'offline':
          stationStatus.isOffline = Boolean(status.value);
          stationStatus.offlineChanged = new Date(status.last_change);
          break;
        case 'uptime':
          stationStatus.uptime = new Date(String(status.value));
          stationStatus.uptimeChanged = new Date(status.last_change);
          break;
      }
    }
  }

  return result;
}

function* loadStationStatusDataById(action: Action.LoadStationStatusDataById) {
  yield put(LoadStationStatusDataStart(action.stationId));
  const response: ApiResponse = yield call(getStationsStatus, { stationIds: [action.stationId] });
  if (response.reason === ReasonEnum.Ok) {
    const data = buildStationStatusFromResponse(response);
    yield put(LoadStationStatusDataSuccess(action.stationId, [...data.values()]));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadStationStatusDataFailure(action.stationId, message));
    yield put(NotifyError(`Error while fetching base stations status #${action.stationId}: ${message}`));
  }
}

function* loadStationStatusDataByParams(action: Action.LoadStationStatusDataByParams) {
  yield put(LoadStationStatusDataStart(action.params));
  const response: ApiResponse = yield call(getStationsStatus, { stationIds: action.params.stationIds });
  if (response.reason === ReasonEnum.Ok) {
    const data = buildStationStatusFromResponse(response);
    yield put(LoadStationStatusDataSuccess(action.params, [...data.values()]));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadStationStatusDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching base stations status: ${message}`));
  }
}

function* autoSyncStationsStatuses(id?: number) {
  let stationIds = [];
  if (!id) {
    const state: RootState = yield select();
    const statuses = state.baseStation.stationStatus.findAll();
    stationIds = statuses.map(status => status.stationId);
  } else {
    stationIds = [id];
  }

  if (!stationIds.length) {
    return;
  }

  const response: ApiResponse = yield call(getStationsStatus, { stationIds });
  if (response.reason === ReasonEnum.Ok) {
    const data = buildStationStatusFromResponse(response);
    yield put(LoadStationStatusDataSuccess({ stationIds }, [...data.values()]));
  } else {
    yield put(Action.StopStationDataSync());
  }
}

function* backgroundSync(id: number) {
  yield call(autoRunWhenVisible, function* () {
    yield call(autoSyncStationsStatuses, id);
  }, SYNC_DELAY_TIMEOUT);
}

function* sync() {
  while (true) {
    const action: { id: number } = yield take(Action.BASE_STATION_STATUS_START_BACKGROUND_SYNC);
    const bgSyncTask: ReduxSagaTask = yield fork(backgroundSync, action.id);

    yield take(Action.BASE_STATION_STATUS_STOP_BACKGROUND_SYNC);
    yield cancel(bgSyncTask);
  }
}

export default [
  fork(sync),
  takeEvery(Action.BASE_STATION_STATUS_DATA_LOAD_BY_ID, loadStationStatusDataById),
  takeEvery(Action.BASE_STATION_STATUS_DATA_LOAD_BY_PARAMS, loadStationStatusDataByParams),
];
