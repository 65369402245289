import { Helmet } from 'react-helmet-async';
import { useGroupDetailPage } from './utils';

// components
import { Box, Grid } from '@material-ui/core';
import { NodeTreeTable } from 'components/DeviceManagement/TreeTable/NodeTreeTable';
import { GroupNotFound } from './widget/GroupNotFound';
import { GroupSkeleton } from './widget/GroupSkeleton';
import { GroupManagement } from './widget/GroupManagement';
import { GroupStatistic } from './widget/GroupStatistic';
import { AddGroupPositionButton } from './widget/AddGroupPositionButton';

// styles
import useStyles from 'styles/page';

interface Params {
  zoneId: string;
  levelId: string;
  groupId: string;
}

export const GroupDetailPage = ({ zoneId, levelId, groupId }: Params) => {
  const classes = useStyles();
  const { isLoading, isValid, tree, node, reload, back } = useGroupDetailPage(zoneId, levelId, groupId);

  return (
    <Box className={ classes.root } data-testid="page-dm-group-detail">
      <Helmet><title>Group { node?.group.name ?? `#${ groupId }` }</title></Helmet>
      { !isValid && <GroupNotFound zoneId={ zoneId } levelId={ levelId } groupId={ groupId }/> }
      { isValid && isLoading && <GroupSkeleton/> }
      { isValid && node && (
        <>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 12 }>
              <GroupManagement
                node={ node }
                onEdit={ reload }
                onDelete={ () => back() }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <GroupStatistic tree={ tree } node={ node }/>
            </Grid>
            <Grid item xs={ 12 }>
              <NodeTreeTable
                embedded
                tree={ tree }
                node={ node }
                loaded={ tree.loaded.position }
              />
            </Grid>
          </Grid>
          <AddGroupPositionButton
            node={ node }
            onSuccess={ () => reload(node.group) }
          />
        </>
      ) }
    </Box>
  );
};
