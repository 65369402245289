import {
  RawMessagesFilters,
  RawMessagesFiltersQuery,
  RawMessagesWithNewFlag
} from 'models';

export const SET_RAW_MESSAGES_FILTERS = 'SET_RAW_MESSAGES_FILTERS';
export const UPDATE_RAW_MESSAGES_FILTERS_FROM_URL = 'UPDATE_RAW_MESSAGES_FILTERS_FROM_URL';

export const FETCH_RAW_MESSAGES_BY_QUERY = 'FETCH_RAW_MESSAGES_BY_QUERY';
export const FETCH_RAW_MESSAGES_BY_QUERY_FAILED = 'FETCH_RAW_MESSAGES_BY_QUERY_FAILED';
export const FETCH_RAW_MESSAGES_BY_QUERY_SUCCESS = 'FETCH_RAW_MESSAGES_BY_QUERY_SUCCESS';

export interface SetRawMessagesFilters {
  readonly type: typeof SET_RAW_MESSAGES_FILTERS;
  filters: RawMessagesFilters;
  pushFiltersToUrl: boolean;
}

export interface UpdateRawMessagesFromUrl {
  readonly type: typeof UPDATE_RAW_MESSAGES_FILTERS_FROM_URL;
}

export interface GetRawMessagesByQuery {
  readonly type: typeof FETCH_RAW_MESSAGES_BY_QUERY;
  readonly query: RawMessagesFiltersQuery;
  readonly hideNotification: boolean;
  readonly isSync?: boolean;
}

export interface GetRawMessagesByQuerySuccess {
  readonly type: typeof FETCH_RAW_MESSAGES_BY_QUERY_SUCCESS;
  readonly list: RawMessagesWithNewFlag[];
  readonly total: number;
}

export interface GetRawMessagesByQueryFailed {
  readonly type: typeof FETCH_RAW_MESSAGES_BY_QUERY_FAILED;
}

export const DoSetRawMessagesFilters = (filters: RawMessagesFilters, pushFiltersToUrl: boolean): SetRawMessagesFilters => ({
  type: SET_RAW_MESSAGES_FILTERS,
  filters,
  pushFiltersToUrl,
});

export const DoUpdateRawMessagesFromUrl =(): UpdateRawMessagesFromUrl => ({
  type: UPDATE_RAW_MESSAGES_FILTERS_FROM_URL
});

export const DoGetRawMessagesByQuery = (query: RawMessagesFiltersQuery, hideNotification: boolean, isSync?: boolean): GetRawMessagesByQuery => ({
  type: FETCH_RAW_MESSAGES_BY_QUERY,
  query,
  hideNotification,
  isSync
});

export const DoGetRawMessagesByQuerySuccess = (list: RawMessagesWithNewFlag[], total: number): GetRawMessagesByQuerySuccess => ({
  type: FETCH_RAW_MESSAGES_BY_QUERY_SUCCESS,
  list,
  total
});

export const DoGetRawMessagesByQueryFailed = (): GetRawMessagesByQueryFailed => ({
  type: FETCH_RAW_MESSAGES_BY_QUERY_FAILED
});

export type Action = (
  | SetRawMessagesFilters
  | UpdateRawMessagesFromUrl
  | GetRawMessagesByQuery
  | GetRawMessagesByQuerySuccess
  | GetRawMessagesByQueryFailed
);
