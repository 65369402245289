import { LorawanStation, StationLocation } from 'models/base-station';
import { isLorawan } from 'utils/isLorawan';

export const getAttached = (lorawanStations: LorawanStation[], location: StationLocation) => {
  let id: string | number | null | undefined = location.station_id;
  if (id && isLorawan(id)) {
    const lorawanStation = lorawanStations.find(s => s.id === id);
    id = lorawanStation?.eui || id;
  }
  return id ? `#${ id }` : 'Free';
};