import { ShipmentDeviceForAws, DeviceProvisioningState } from 'models/provisioning';

// copmonents
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout } from 'components/Table';
import DeviceLink from 'components/Links/DeviceLink';
import { commonOptions } from 'utils/tables';
import { AwsIcon } from './AwsIcon';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';

const theme = {
  overrides: {
    MUIDataTableToolbar: {
      root: { display: 'none' }
    },
    MuiPaper: {
      elevation4: { boxShadow: 'none' }
    },
  }
};

interface Props {
  isLoading: boolean;
  devices: ShipmentDeviceForAws[];
}

export const DevicesTable = ({ devices, isLoading }: Props): JSX.Element => {

  const columns: MUIDataTableColumn[] = [
    {
      name: 'device_id',
      label: 'Device ID',
      options: {
        customBodyRenderLite: dataIndex => <DeviceLink deviceId={ devices[dataIndex].device_id }/>
      },
    },
    {
      name: 'box_id',
      label: 'Box ID',
      options: {
        customBodyRenderLite: dataIndex => devices[dataIndex].box_id ?? '—'
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRenderLite: dataIndex => DeviceProvisioningState[devices[dataIndex].status],
      }
    },
    {
      name: 'isAws',
      label: 'AWS',
      options: {
        customBodyRenderLite: dataIndex => devices[dataIndex].dev_eui ? <AwsIcon /> : '',
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 100]
  };

  return (
    <MuiThemeProvider theme={ theme }>
      <TableLoadingLayout isLoading={ isLoading } >
        <MUIDataTable
          title="Devices"
          data={ devices }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};

