import { API } from 'aws-amplify';
import { config } from './config';
import { errorHandler, formatQuery } from 'clients/utils';
import { ProvisioningDevicesFiltersLimit, ResponseDevices, ResponseDevice, DeviceStatusEnum } from 'models/provisioning';

const urls = {
  getProvisioningDevices: '/provisioning/devices',
  getProvisioningDeviceById: (id: string) => `/provisioning/devices/${id}`,
  updateDeviceStatusById: (id: string) => `/provisioning/devices/status/${id}`,
  addDeviceToBox: (deviceId: string, boxId: number) => `/provisioning/devices/${deviceId}/addToBox/${boxId}`,
  removeDeviceFromBox: (deviceId: string) => `/provisioning/devices/${deviceId}/removeFromBox`,
};

export const fetchProvisioningDevices = (params: Partial<ProvisioningDevicesFiltersLimit>): Promise<ResponseDevices> => {
  const body = {
    queryStringParameters: formatQuery({
      limit: params.limit,
      offset: params.offset,
      device_id: params.device_id,
      box_id: params.box_id,
      shipment_id: params.shipment_id,
      status: params.device_status,
      owner_id: params.owner
    }),
  };

  return API
    .get(config.name, urls.getProvisioningDevices, body)
    .catch(errorHandler);
};

export const fetchProvisioningDeviceById = (id: string): Promise<ResponseDevice> => {
  return API
    .get(config.name, urls.getProvisioningDeviceById(id), {})
    .catch(errorHandler);
};

export const updateDeviceStatus = (deviceId: string, status: DeviceStatusEnum): Promise<ResponseDevice> => {
  return API
    .put(config.name, urls.updateDeviceStatusById(deviceId), { body: { status } })
    .catch(errorHandler);
};

export const addDeviceToBox = (deviceId: string, boxId: number): Promise<ResponseDevice> => {
  return API
    .post(config.name, urls.addDeviceToBox(deviceId, boxId), {})
    .catch(errorHandler);
};

export const removeDeviceFromBox = (deviceId: string): Promise<ResponseDevice> => {
  return API
    .post(config.name, urls.removeDeviceFromBox(deviceId), {})
    .catch(errorHandler);
};
