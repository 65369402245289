import { staticEnv } from 'env';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PositionGroupUpdateParams, Group } from 'models/device-management';
import { dmPositionGroupsPath } from 'routing/paths';
import { isAllowedToDeleteGroup, isAllowedToUpdateGroup } from 'utils/permissions';
import { useUpdateGroup } from 'hooks/device-management';
import { formatDateTime } from 'utils/datetime';
import * as is from 'utils/form/validation/validators';
import { nameof } from 'utils/type-checking';
import { showParkingAnomaly } from 'helpers';

// components
import {
  Button as MuiButton,
  Grid,
  FormGroup,
  Paper,
  TextField,
} from '@material-ui/core';

import { Form } from 'components';
import { SuccessButton } from 'components/Buttons';
import { BlockTitle, BlockToolbar } from 'components/Block';
import * as Fields from 'components/Form/Field';
import { FieldSkeleton } from 'components/Skeleton';

import DeletePositionGroup from './DeletePositionGroup';
import { GroupParkingAnomaly } from 'components/ParkingAnomaly';

// styles
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';
import { useStyles } from './style';
import { RootState } from 'reducers';

type PositionGroupFormValues = PositionGroupUpdateParams['props'];

export interface GeneralTabProps {
  group?: Group;
  returnUrl?: string;
}

const getDefaultValues = (group?: Group): Partial<PositionGroupFormValues> => ({
  ...group,
  custom_id: group?.custom_id ?? '',
  level_id: group?.level_id ?? undefined,
});

const GeneralTab: React.FC<GeneralTabProps> = ({ group, returnUrl = dmPositionGroupsPath }) => {
  const allowedToDeletePositionGroup = useSelector((state: RootState) => isAllowedToDeleteGroup(state.user.data));
  const allowedToUpdatePositionGroup = useSelector((state: RootState) => isAllowedToUpdateGroup(state.user.data));
  const classes = useStyles();

  const { updateGroup } = useUpdateGroup();

  const handleSubmit = async (values: PositionGroupFormValues) => {
    const groupUpdateProps = {
      ...group,
      ...values,
    };
    await updateGroup({
      id: group?.id as number,
      props: groupUpdateProps,
    });
  };

  const history = useHistory();
  const infoCss = useInfoBlockStyles();

  if (!group) {
    return (
      <Paper>
        <FieldSkeleton className={ infoCss.field } />
      </Paper>
    );
  }

  return (
    <div className={ classes.root }>
      <div className={ classes.fullWidth }>
        <Form<PositionGroupFormValues>
          defaultValues={ getDefaultValues(group) }
          validators={ {
            name: is.required(),
            type: is.required(),
          } }
          onSubmit={ handleSubmit }
        >
          { form => (
            <Paper>
              <BlockToolbar>
                <BlockTitle>
                  Group information
                </BlockTitle>

                { allowedToDeletePositionGroup && (
                  <DeletePositionGroup
                    groupId={ group.id as number }
                    onSuccess={ () => history.push(returnUrl) }
                  />
                ) }

                { allowedToUpdatePositionGroup && form.formState.dirty && (
                  <MuiButton color="inherit" onClick={ form.reset }>
                    Cancel
                  </MuiButton>
                ) }

                { allowedToUpdatePositionGroup && (
                  <SuccessButton
                    disabled={ !form.formState.dirty }
                    pending={ form.formState.isSubmitting }
                    titleForTooltip={ form.formState.dirty ? '' : 'Nothing to save' }
                  />
                ) }
              </BlockToolbar>

              <FormGroup className={ infoCss.fields }>
                <TextField
                  className={ infoCss.field }
                  disabled
                  label="Group ID"
                  value={ group.id || '' }
                />

                <Fields.Text
                  className={ infoCss.field }
                  disabled={ !allowedToUpdatePositionGroup }
                  label="Group Name"
                  name={ nameof<PositionGroupFormValues>('name') }
                />

                <div className={ infoCss.field }>
                  <Fields.SelectPositionGroupType
                    name={ nameof<PositionGroupFormValues>('type') }
                    label="Group Type"
                    isDisabled={ !allowedToUpdatePositionGroup }
                  />
                </div>

                <div className={ infoCss.field }>
                  <Fields.SelectZone
                    name={ nameof<PositionGroupFormValues>('zone_id') }
                    isDisabled={ !allowedToUpdatePositionGroup }
                    onChange={ () => form.setValue('level_id', null) }
                  />
                </div>

                <div className={ infoCss.field }>
                  <Fields.SelectLevel
                    name={ nameof<PositionGroupFormValues>('level_id') }
                    isDisabled={ !allowedToUpdatePositionGroup || !form.watch('zone_id') }
                    zoneId={ form.watch('zone_id') }
                  />
                </div>

                <TextField
                  className={ infoCss.field }
                  disabled
                  label="Creation time"
                  value={ group.creation_date ? formatDateTime(group.creation_date) : '' }
                />

                <Grid className={ infoCss.field } container>
                  <Grid item xs>
                    <Fields.Text
                      name={ nameof<PositionGroupFormValues>('custom_id') }
                      label="Custom ID"
                      disabled={ !allowedToUpdatePositionGroup }
                      fullWidth
                    />
                  </Grid>

                  { allowedToUpdatePositionGroup && (
                    <MuiButton onClick={ () => form.setValue('custom_id', uuidv4()) }>
                      Generate
                    </MuiButton>
                  ) }
                </Grid>
              </FormGroup>
            </Paper>
          ) }
        </Form>
      </div>
      { staticEnv.IS_PARKING_ANOMALY_ON && (group && showParkingAnomaly(group.type) && <GroupParkingAnomaly groupId={ group.id } />) }
    </div>
  );
};

export default GeneralTab;
