import { Query } from '../../types';

export function createQuery<TData>(data?: TData): Query<TData> {
  return {
    status: 'idle',
    data,
    requestedAt: undefined,
    dataRequestedAt: undefined,
    error: undefined,
  };
}
