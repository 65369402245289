import React from 'react';

import { useAuthUserSelector, useDialogWithItem } from 'hooks';
import { useCallerMessageTypes } from 'hooks/caller';
import { Template } from 'models/caller';
import { MUIDataTableColumnOptions } from 'utils/mui-datatables';
import { isAllowedToCreateCaller, isAllowedToDeleteCaller, isAllowedToUpdateCaller } from 'utils/permissions';
import { commonOptions } from 'utils/tables';
import { useUpdateTemplate } from './useUpdateTemplate';

// components
import { Box } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

import { CloneButton, CommonButton, DeleteButton, PlusButton } from 'components/Buttons';
import { CallerTemplateNameDumb } from 'components/Labels';
import { LoaderSvg } from 'components/Loaders';
import { TableLoadingLayout } from 'components/Table';

import { CreateTemplateDialog, EditTemplateDialog, DeleteTemplateDialog } from './template-dialogs';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getTemplateSuitePageTableTheme } from 'pages/Caller/TemplateSuitePageNew/styles';
import { useStyles } from './styles';

interface TemplateSuiteTemplatesTableProps {
  templateSuiteId: number;
  templates: Template[];
  isLoading: boolean;
  className?: string;
  onTemplateCreateInitiated?: () => void;
  onTemplateDelete?: (deletedTemplateId: number) => void;
}

export const TemplateSuiteTemplatesTable = ({
  templateSuiteId,
  templates,
  isLoading,
  className,
  onTemplateCreateInitiated,
  onTemplateDelete,
}: TemplateSuiteTemplatesTableProps) => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const templateDialog = useDialogWithItem<Template>();
  const cloneTemplateDialog = useDialogWithItem<Template>();
  const deleteTemplateDialog = useDialogWithItem<Template>();
  const { update: updateTemplate } = useUpdateTemplate();
  const messageTypesState = useCallerMessageTypes();

  const actionsColumnOptions: MUIDataTableColumnOptions = {
    customHeadLabelRender: () => {
      return !isAllowedToCreateCaller(user.data)
        ? null
        : (
          <Box display="flex" justifyContent="flex-end">
            <PlusButton type="icon" label="Add Template" compact onClick={ onTemplateCreateInitiated } />
          </Box>
        );
    }
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'template',
      label: 'Templates',
      options: {
        customBodyRenderLite: dataIndex => {
          const template = templates[dataIndex];
          return (
            <CallerTemplateNameDumb
              template={ template }
              templateId={ template.template_id }
              statusVisible
              rootProps={ {
                className: classes.templateName,
                title: template.name,
                onClick: () => templateDialog.open(template),
              } }
            />
          );
        },
      },
    },
    {
      name: 'messageType',
      label: 'Message Type',
      options: {
        customBodyRenderLite: dataIndex => {
          const template = templates[dataIndex];
          const type = template.message_type;
          const messageType = messageTypesState.messageTypes?.find(m => m.id === type);

          if (messageType?.name) {
            return messageType.name;
          }

          if (messageTypesState.isLoading) {
            return <LoaderSvg />;
          }

          return type;
        },
      },
    },
    {
      name: 'method',
      label: 'Method',
      options: {
        customBodyRenderLite: dataIndex => templates[dataIndex].method,
      },
    },
    {
      name: 'url',
      label: 'URL',
      options: {
        customBodyRenderLite: dataIndex => templates[dataIndex].url,
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        ...actionsColumnOptions,
        customBodyRenderLite: dataIndex => {
          const template = templates[dataIndex];
          return (
            <Box display="flex" justifyContent="flex-end" position="relative" right="-7px">
              { isAllowedToUpdateCaller(user.data) && (
                <CommonButton
                  type="text"
                  label={ template.is_active ? 'Deactivate' : 'Activate' }
                  onClick={ () => updateTemplate({ ...template, is_active: !template.is_active }) }
                />
              ) }
              { isAllowedToCreateCaller(user.data) && (
                <CloneButton
                  type="text"
                  onClick={ () => cloneTemplateDialog.open(template) }
                />
              ) }
              { isAllowedToDeleteCaller(user.data) && (
                <DeleteButton
                  type="text"
                  onClick={ () => deleteTemplateDialog.open(template) }
                />
              ) }
            </Box>
          );
        },
      },
    },
  ];

  const rowsPerPage = 5;

  const tableOptions: MUIDataTableOptions = {
    ...commonOptions,
    elevation: 0,
    rowsPerPage,
    rowsPerPageOptions: [rowsPerPage],
    textLabels: {
      body: {
        noMatch: 'No template added',
      },
    },
  };

  return (
    <>
      <MuiThemeProvider
        theme={ getTemplateSuitePageTableTheme({
          empty: !templates.length,
          loading: isLoading,
        }) }
      >
        <TableLoadingLayout isLoading={ isLoading } className={ className } >
          <MUIDataTable
            title={ null }
            options={ tableOptions }
            columns={ columns }
            data={ templates }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>

      { templateDialog.isMounted && templateDialog.item && (
        <EditTemplateDialog
          template={ templateDialog.item }
          templateSuiteId={ templateSuiteId }
          isOpen={ templateDialog.isOpen }
          onClose={ templateDialog.close }
          onCloseEnd={ templateDialog.unmount }
        />
      ) }

      { cloneTemplateDialog.isMounted && cloneTemplateDialog.item && (
        <CreateTemplateDialog
          template={ {
            ...cloneTemplateDialog.item,
            template_id: 0,
            name: `CLONE ${ cloneTemplateDialog.item.name }`,
          } }
          text={ {
            title: 'Clone Template',
            submitButton: 'Clone',
          } }
          templateSuiteId={ templateSuiteId }
          templateSuiteFieldVisible
          isOpen={ cloneTemplateDialog.isOpen }
          onClose={ cloneTemplateDialog.close }
          onCloseEnd={ templateDialog.unmount }
        />
      ) }

      { deleteTemplateDialog.isMounted && deleteTemplateDialog.item && (
        <DeleteTemplateDialog
          template={ deleteTemplateDialog.item }
          isOpen={ deleteTemplateDialog.isOpen }
          onClose={ deleteTemplateDialog.close }
          onCloseEnd={ templateDialog.unmount }
          onSuccess={ () => deleteTemplateDialog.item && onTemplateDelete?.(deleteTemplateDialog.item.template_id) }
        />
      ) }
    </>
  );
};
