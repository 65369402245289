import { put, takeEvery, call, select, } from 'redux-saga/effects';

import { ReasonEnum } from 'models';
import { RootState } from 'reducers';
import { NotifyError, NotifySuccess } from 'actions/notifier';

import {
  FETCH_WARRANTY,
  DoFetchWarrantyFailure,
  DoFetchWarrantySuccess,
  FetchWarrantyById,
  FETCH_WARRANTY_BY_ID,
  DoFetchWarrantyByIdSuccess,
  DoFetchWarrantyByIdFailure,
  WARRANTY_CREATE,
  CreateWarranty,
  DoCreateWarrantySuccess,
  DoCreateWarrantyFailure,
} from 'actions/provisioning';
import {
  WarrantyFiltersWithLimit,
  ResponseWarranty,
  Warranty,
  ResponseWarranties
} from 'models/provisioning';
import {
  fetchWarranties,
  getWarrantyById,
  createWarranty,
} from 'clients/provisioning';
import { ActionWithPromise } from 'utils/store';

function* fetchWarrantyByState() {
  const state: RootState = yield select();

  yield call(fetchWarrantyCall, state.provisioning.warranty.filters);
}

function* fetchWarrantyCall(filters: WarrantyFiltersWithLimit) {
  const response: ResponseWarranties = yield call(fetchWarranties, filters);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoFetchWarrantySuccess(response.data));
  } else {
    yield put(DoFetchWarrantyFailure());
    yield put(NotifyError(`Error while fetching warranty provisioning: ${response.message}`));
  }
}

function* fetchWarrantyById(action: FetchWarrantyById) {
  const response: ResponseWarranty = yield call(getWarrantyById, action.id);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoFetchWarrantyByIdSuccess(response.data));
  } else {
    yield put(DoFetchWarrantyByIdFailure());
    yield put(NotifyError(`Error while fetching warranty provisioning: ${response.message}`));
  }
}

type CreateAction =
  | CreateWarranty
  | ActionWithPromise<CreateWarranty, Warranty>
function* createWarrantySaga(action: CreateAction) {
  const response: ResponseWarranty = yield call(createWarranty, action.warranty);

  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(DoCreateWarrantySuccess(response.data));
    yield put(NotifySuccess(`Warranty has been created`));
    'meta' in action && action.meta.promise.resolve(response.data);
  } else {
    yield put(DoCreateWarrantyFailure());
    yield put(NotifyError(`Error while creating a warranty: ${response.message}`));
    'meta' in action && action.meta.promise.reject(new Error(response.message || 'Server Error'));
  }
}

export const provisioningWarrantySagas = [
  takeEvery(FETCH_WARRANTY, fetchWarrantyByState),
  takeEvery(FETCH_WARRANTY_BY_ID, fetchWarrantyById),
  takeEvery(WARRANTY_CREATE, createWarrantySaga),
];
