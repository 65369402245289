import { makeStyles, createStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) => createStyles({
  boxBtn:{
    padding: '12px',
    fontSize: '0.75rem',
    borderRadius: '4px',
    fontWeight: 'bold',
    transition: '0.2s',
  },
  btnCancel:{
    color: '#4a4a4a',
    '&:hover': {
      backgroundColor: 'rgba(74, 74, 74, 0.04)'
    }
  },
  btnSubmit:{
    color: '#2887cc',
    '&:hover': {
      backgroundColor: 'rgba(40, 135, 204, 0.04)'
    }
  }
}));
