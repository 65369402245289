import { combineReducers } from 'redux';

import * as schemaEnum from './schema-enum';

export interface State {
  schemaEnum: schemaEnum.State;
}

export const reducer = combineReducers<State>({
  schemaEnum: schemaEnum.reducer,
});
