import React from 'react';
import { RouteConfig } from 'react-router-config';
import { match as Match } from 'react-router-dom';
import { State as CurrentUserProfile } from 'reducers/user-profile';
import * as paths from 'routing/paths/caller';
import * as permission from 'utils/permissions';
import HttpCallerHistory from 'pages/Caller/History/HttpCallerHistoryPage';
import { TemplateSuiteClonePage, TemplateSuiteDetailPage, TemplateSuiteStatisticPage, TemplateSuiteTemplatesPage } from 'pages/Caller/TemplateSuitePage';
import { TemplateSuiteDetailPageNew } from 'pages/Caller/TemplateSuitePageNew';
import { TemplateClonePage, TemplateCreatePage, TemplateDetailPage, TemplateStatisticPage } from 'pages/Caller/TemplatePage';
import { CallerTemplateName, CallerTemplateSuiteName } from 'components/Labels';
import RoutesTemplatesSuitesPage from 'pages/Caller/RoutesTemplatesSuitesPage/RoutesTemplatesSuitesPage';
import { areRedesignedTemplateSuitesEnabled } from 'utils/feature-flags';

const SUITE_ID_PARAM_NAME = 'suitId';
const TEMPLATE_ID_PARAM_NAME = 'templateId';
const suiteParam = `:${SUITE_ID_PARAM_NAME}`;
const templateParam = `:${TEMPLATE_ID_PARAM_NAME}`;

interface Params {
  [SUITE_ID_PARAM_NAME]?: string;
  [TEMPLATE_ID_PARAM_NAME]?: string;
}

const renderWithParams = (match: Match<Params>, Page: React.ElementType): false | React.ReactNode => {
  const templateSuiteId = Number(match.params[SUITE_ID_PARAM_NAME] ?? 0);
  if (isNaN(templateSuiteId)) {
    return false;
  }

  const templateId = Number(match.params[TEMPLATE_ID_PARAM_NAME] ?? 0);
  if (isNaN(templateId)) {
    return false;
  }

  return <Page { ...{ templateSuiteId, templateId } } />;
};

const HistoryRoute: RouteConfig = {
  path: paths.getCallerHistoryPath(),
  exact: true,
  label: 'History',
  icon: 'history',
  main: HttpCallerHistory,
};

const TemplateSuitesRouteNew: RouteConfig = {
  path: paths.getCallerTemplateSuiteListPath(),
  label: 'Template Suites',
  icon: 'suite',
  render: ({ match }) => match.isExact && <RoutesTemplatesSuitesPage />,
};

const DetailSuiteRoute: RouteConfig = {
  path: paths.getCallerTemplateSuiteDetailPath(suiteParam),
  label: ({ match }) => match && renderWithParams(match, CallerTemplateSuiteName),
  render: ({ match }) => match.isExact && renderWithParams(
    match,
    areRedesignedTemplateSuitesEnabled() ? TemplateSuiteDetailPageNew : TemplateSuiteDetailPage
  ),
  hiddenOnMainMenu: true,
};

const CloneSuiteRoute: RouteConfig = {
  path: paths.getCallerTemplateSuiteClonePath(suiteParam),
  label: 'Clone',
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateSuiteClonePage),
  hiddenOnMainMenu: true,
};

const StatisticSuiteRoute: RouteConfig = {
  path: paths.getCallerTemplateSuiteStatisticPath(suiteParam),
  label: 'Statistics',
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateSuiteStatisticPage),
  hiddenOnMainMenu: true,
};

const TemplatesSuiteRoute: RouteConfig = {
  path: paths.getCallerTemplateSuiteTemplatesPath(suiteParam),
  label: 'Templates',
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateSuiteTemplatesPage),
  hiddenOnMainMenu: true,
};

const CreateTemplateRoute: RouteConfig = {
  path: paths.getCallerTemplateCreatePath(suiteParam),
  label: 'Create',
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateCreatePage),
  hiddenOnMainMenu: true,
};

const DetailTemplateRoute: RouteConfig = {
  path: paths.getCallerTemplateDetailPath(suiteParam, templateParam),
  label: ({ match }) => match && renderWithParams(match, CallerTemplateName),
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateDetailPage),
  hiddenOnMainMenu: true,
};

const CloneTemplateRoute: RouteConfig = {
  path: paths.getCallerTemplateClonePath(suiteParam, templateParam),
  label: 'Clone',
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateClonePage),
  hiddenOnMainMenu: true,
};

const StatisticTemplateRoute: RouteConfig = {
  path: paths.getCallerTemplateStatisticPath(suiteParam, templateParam),
  label: 'Statistics',
  render: ({ match }) => match.isExact && renderWithParams(match, TemplateStatisticPage),
  hiddenOnMainMenu: true,
};

const oldCallerRoutes = [
  {
    path: '/api/http/templates',
    label: 'Templates',
  },
  {
    routes: [
      {
        ...DetailSuiteRoute,
        routes: [
          CloneSuiteRoute,
          StatisticSuiteRoute,
          {
            ...TemplatesSuiteRoute,
            routes: [
              CreateTemplateRoute,
              {
                ...DetailTemplateRoute,
                routes: [
                  CloneTemplateRoute,
                  StatisticTemplateRoute
                ]
              }
            ]
          }
        ]
      },
    ]
  }
];

const newCallerRoutes = [
  {
    ...TemplateSuitesRouteNew,
    routes: [DetailSuiteRoute],
  }
];

export function getCallerRoutes(userData: CurrentUserProfile) {
  const { data: user } = userData;
  const routes: RouteConfig[] = [
    {
      path: '/api/http',
      label: 'HTTP',
      icon: 'queue',
      hiddenOnMainMenu: !permission.isAllowedToReadCaller(user),
      isShowByUrl: permission.isAllowedToReadCaller(user),
      routes: [
        HistoryRoute,
        ...(areRedesignedTemplateSuitesEnabled() ? newCallerRoutes : oldCallerRoutes)
      ],
    },
  ];
  return routes;
}
