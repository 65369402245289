import produce from 'immer';
import { useCallback, useRef, useEffect } from 'react';

import { DmTreeNode, Filters, TreeNode } from '../types';
import { BaseTree, TreeMethods } from './types';
import { findNode, useRerender } from './utils';

type TreeByParams = Record<string, BaseTree | undefined>;

export function useBaseTree(filters: Filters): [BaseTree, TreeMethods] {
  const rerender = useRerender();
  const treesRef = useRef<TreeByParams>({});

  const filtersString = JSON.stringify(filters);
  const currentFiltersStringRef = useRef<string>(filtersString);

  useEffect(() => {
    currentFiltersStringRef.current = filtersString;
  }, [filtersString]);

  if (!treesRef.current[filtersString]) {
    treesRef.current[filtersString] = {
      filters,
      children: [],
      childrenLoadStatus: 'idle'
    };
  }

  const getTree = useCallback((): BaseTree => {
    return treesRef.current[filtersString] as BaseTree;
  }, [filtersString]);

  const setTree = useCallback(
    (updater: (tree: BaseTree) => BaseTree) => {
      const prevTree = treesRef.current[filtersString] as BaseTree;
      const nextTree = updater(prevTree);

      if (nextTree === prevTree) {
        return;
      }

      treesRef.current[filtersString] = nextTree;

      if (filtersString === currentFiltersStringRef.current) {
        rerender();
      }
    },
    [rerender, filtersString]
  );

  const updateTree = useCallback(
    (updater: (tree: BaseTree) => void): void => {
      setTree((tree) =>
        produce(tree, (t) => {
          updater(t);
        })
      );
    },
    [setTree]
  );

  const getNode = useCallback(
    (nodeOrNodeId: TreeNode<unknown> | string) => {
      const nodeId = getNodeId(nodeOrNodeId);
      return findNode(nodeId, getTree().children);
    },
    [getTree]
  );

  const updateNode = useCallback(
    (nodeOrNodeId: TreeNode<unknown> | string, updater: (node: DmTreeNode) => void): void => {
      updateTree((tree) => {
        const nodeId = getNodeId(nodeOrNodeId);
        const node = findNode(nodeId, tree.children);

        if (node) {
          updater(node);
        }
      });
    },
    [updateTree]
  );

  const treeMethods: TreeMethods = {
    getTree,
    setTree,
    updateTree,
    getNode: getNode as TreeMethods['getNode'],
    updateNode: updateNode as TreeMethods['updateNode'],
  };

  return [getTree(), treeMethods];
}

function getNodeId(nodeOrNodeId: TreeNode<unknown> | string): string {
  return typeof nodeOrNodeId === 'string' ? nodeOrNodeId : nodeOrNodeId.id;
}
