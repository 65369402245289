import { BSResponsiblePersonsFields } from 'models/base-station';

export const BS_RESPONSIBLE_PERSONS_UPDATE_FROM_URL = 'BS_RESPONSIBLE_PERSONS_UPDATE_FROM_URL';
export const BS_RESPONSIBLE_PERSONS_SET_FILTERS = 'BS_RESPONSIBLE_PERSONS_SET_FILTERS';

export interface UpdateFiltersFromUrl {
  readonly type: typeof BS_RESPONSIBLE_PERSONS_UPDATE_FROM_URL;
}

export interface SetFilters {
  readonly type: typeof BS_RESPONSIBLE_PERSONS_SET_FILTERS;
  filters: BSResponsiblePersonsFields;
}

export const DoUpdateFiltersFromUrl: () => UpdateFiltersFromUrl = () => ({
  type: BS_RESPONSIBLE_PERSONS_UPDATE_FROM_URL
});
export const DoSetFilters: (filters: BSResponsiblePersonsFields) => SetFilters = (filters) => ({
  type: BS_RESPONSIBLE_PERSONS_SET_FILTERS,
  filters
});

export type Action =
  | UpdateFiltersFromUrl
  | SetFilters