import React, { useMemo } from 'react';

import { Zone } from 'models/device-management';
import { Defined } from 'utils/models';
import { PositionsTabId } from 'pages/DeviceManagement/Positions/utils';

// components
import PositionsPage from 'pages/DeviceManagement/Positions/PositionsPage';
import PositionsPageLink from './PositionsPageLink';

// styles
import useZonePageStyles from './style';

type Filters = React.ComponentProps<typeof PositionsPage>['filters'];

interface PositionsTabProps {
  zoneId: Defined<Zone['id']>;
}

const PositionsTab: React.FC<PositionsTabProps> = ({ zoneId }) => {
  const zoneCss = useZonePageStyles();
  const filters = useMemo((): Filters => ({ zones: [zoneId] }), [zoneId]);
  return (
    <PositionsPage
      filters={filters}
      filtersHidden
      tabsHidden
      filtersInUrlHidden
      tabContentClassName={zoneCss.positionsBlockTabContent}
      activeTabId={PositionsTabId.TABLE}
      tableProps={{
        hiddenColumns: ['zone'],
        toolbar: <PositionsPageLink filters={filters} />,
      }}
    />
  );
};

export default PositionsTab;
