import React from 'react';
import { isNil } from 'lodash';
import { TextFieldProps } from '@material-ui/core/TextField';
import MaterialUiPhoneNumber from 'material-ui-phone-number';

type Props =
  Partial<Omit<TextFieldProps, 'onChange' | 'error'>>
  & {
    onChange: (value?: string) => void;
    error?: string;
  }

export const PhoneNumberControl = (props: Props): JSX.Element => {
  const handleChange = (value?: string) => {
    if (!value || value === '+') {
      value = undefined;
    } else if (value.substr(0, 1) !== '+') {
      value = '+' + value;
    }

    props.onChange(value);
  };

  return (
    <MaterialUiPhoneNumber
      disableDropdown
      disableAreaCodes
      disableCountryCode={ isNil(props.value) }
      defaultCountry="us"
      { ...props }
      value={ props.value ?? '' }
      onFocus={ () => true } // need to show placeholder in text input, dont work without
      onChange={ handleChange }
      error={ props.error !== undefined }
      helperText={ props.error }
    />
  );
};

