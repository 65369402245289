import React from 'react';
import { useAuthUserSelector, useFormActionLoader } from 'hooks';
import { useForm } from 'hooks/form';
import { canViewOwner } from 'utils/permissions';
import { Zone, ZoneUpdateFields } from 'models/device-management';
import { updateZone } from 'actions/device-management/zones';

// components
import { Button } from '@material-ui/core';
import { CommonDialog } from 'components/Dialogs';
import { ZoneForm, ZoneFormField, ZoneFormState } from 'components/DeviceManagement/Forms';

interface Props {
  zone: Zone;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (zone: Zone) => void;
}

export const EditZoneDialog = ({ zone, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const user = useAuthUserSelector();
  const form = useForm<ZoneFormState, ZoneFormField>({
    initialState: {
      [ZoneFormField.name]: zone.name,
      [ZoneFormField.project]: zone.project_id,
      [ZoneFormField.silent]: zone.live_status,
      [ZoneFormField.owner]: zone.owner_id,
    },
    showFields: [
      ZoneFormField.name,
      ZoneFormField.project,
      ZoneFormField.silent,
      ...(canViewOwner(user) ? [ZoneFormField.owner] : []),
    ],
  });

  const handleSubmit = (): void => {
    if (!form.validate()) {
      return;
    }

    const zoneUpdate: ZoneUpdateFields = {
      project_id: form.state[ZoneFormField.project],
      name: String(form.state[ZoneFormField.name]),
      live_status: form.state[ZoneFormField.silent] ?? false,
    };

    doAction({
      action: updateZone({ id: zone.id, props: zoneUpdate }),
      onError: form.catchError,
      onSuccess: (zone: Zone) => {
        onSuccess && onSuccess(zone);
        onClose();
      }
    });
  };

  return (
    <CommonDialog
      testId="dm-zone-edit-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit zone > ${zone.name}` }
      content={ <ZoneForm { ...form } /> }
      actions={
        <>
          <Button color="primary" onClick={ onClose }>Cancel</Button>
          <Button color="primary" variant="contained" onClick={ () => handleSubmit() }>Save</Button>
        </>
      }
    />
  );
};
