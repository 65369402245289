import React, { createContext, ReactNode, useState } from 'react';
import { ActionLoader, ActionLoaderProps } from './ActionLoader';

export interface ActionLoaderState {
  doAction: (props: ActionLoaderProps) => void;
}

export const ActionLoaderContext = createContext<ActionLoaderState>({
  doAction: () => { return; },
});

interface Props {
  children: ReactNode;
}

export const ActionLoaderProvider = ({ children }: Props): JSX.Element => {
  const [action, setAction] = useState<ActionLoaderProps | undefined>(undefined);

  return (
    <ActionLoaderContext.Provider value={ { doAction: setAction } }>
      { children }
      { action ? <ActionLoader { ...action } /> : '' }
    </ActionLoaderContext.Provider>
  );
};
