import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { ZoneFilters } from 'models/device-management';

// components
import { FilterButton } from 'components/Buttons';
import ZonesFilter from 'components/DeviceManagement/Zones/ZonesFilter/ZonesFilterComponent';


interface Props {
  isLoading: boolean;
  filter: ZoneFilters;
  onSave: (filter: ZoneFilters) => void;
  onReset: () => void;
}

export const FilterDialog = ({
  isLoading,
  filter,
  onSave,
  onReset,
}: Props): JSX.Element => {
  const [state, setState] = useState<ZoneFilters>(filter);
  useDeepCompareEffect(() => {
    setState(filter);
  }, [filter]);

  return (
    <FilterButton
      isLoading={ isLoading }
      filter={ filter }
      onSave={ () => { onSave(state); }}
      onReset={ onReset }
    >
      <ZonesFilter state={ state } setState={ setState }/>
    </FilterButton>
  );
};