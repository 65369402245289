import { commonOptions } from 'utils/tables';
import { provisioningPersonalBoxPath } from 'routing/paths';
import { useProvisioningReceivedBoxes } from 'hooks/provisioning';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Box } from 'models/provisioning';
import { OwnerName } from 'components';
import { DevicesCountInBox } from './Controls/DevicesCountInBox';
import ShipmentName from './Controls/ShipmentName';
import { Link } from '@material-ui/core';
import { CustomLink } from 'components/Links';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';

// styles
import { getMuiTheme } from 'styles/themes';

interface Props {
  data: Box[];
  isFetching: boolean;
  limit: number;
  offset: number;
  total: number;
  isAdmin: boolean;
}

const BoxesTable = (props: Props): JSX.Element => {
  const { isFetching, data, total, isAdmin } = props;

  const receivedBoxes = useProvisioningReceivedBoxes({
    filter: { boxes: data.map(b => b.id) },
    enabled: data.length > 0
  });

  const columns: MUIDataTableColumn[] = [
    {
      name: 'box',
      label: 'Box',
      options: {
        customBodyRenderLite: dataIndex => {
          const box = data[dataIndex];
          return (
            <Link
              color="secondary"
              component={ CustomLink }
              to={ provisioningPersonalBoxPath(box.id) }
              withReturnUrl
            >{ box.id }</Link>
          );
        },
      }
    },
    {
      name: 'shipment',
      label: 'Shipment',
      options: {
        customBodyRenderLite: dataIndex => {
          const box = data[dataIndex];
          const receivedBox = receivedBoxes.boxes.find(b => b.box_id === box.id);
          const shipmentId = box.shipment_id ?? receivedBox?.shipment_id;
          return shipmentId ? <ShipmentName id={ shipmentId }/> : '—';
        },
      }
    },
    {
      name: 'devices',
      label: 'Devices count',
      options: {
        customBodyRenderLite: dataIndex => <DevicesCountInBox count={ data[dataIndex].device_count ?? 0 }/>,
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ data[dataIndex].owner_id }/>,
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    count: total,
    serverSide: true,
    customFooter: count => <PaginationFooter count={ count } />
  };

  return (
    <MuiThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ isFetching }>
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};

export default BoxesTable;
