import { isEqual } from 'lodash';

import {
  INITIAL_DATE_RANGE,
  INITIAL_DATE_RANGE_LABEL,
  MonitoringDateRange,
} from 'models/base-station-status';

import { formatDateRange } from './utils';

// components
import { Box } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';

import { FilterPresetButton } from 'components/Buttons';
import { DateRangeControl } from 'components/Controls';

// styles
import { useStyles } from './styles';

interface DateFiltersProps {
  dateRange: MonitoringDateRange;
  onChange: (dateRange: MonitoringDateRange) => void;
}

export const DateFilters = ({ dateRange, onChange }: DateFiltersProps) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      data-testid="monitoring-date-range"
    >
      <FilterPresetButton
        label={ INITIAL_DATE_RANGE_LABEL }
        icon={ <></> }
        selected={ isEqual(dateRange, INITIAL_DATE_RANGE) }
        ButtonProps={ { className: classes.filterControl } }
        onClick={ () => {
          onChange(INITIAL_DATE_RANGE);
        } }
      />

      <DateRangeControl
        timeCustomizationEnabled
        value={ dateRange.type === 'exact'
          ? { dateTimeFrom: dateRange.dateFrom, dateTimeTo: dateRange.dateTo }
          : undefined }
        renderTrigger={ triggerProps => (
          <FilterPresetButton
            { ...triggerProps }
            label={ dateRange.type === 'exact'
              ? formatDateRange(dateRange.dateFrom, dateRange.dateTo)
              : 'Custom'
            }
            icon={
              <DateRangeIcon
                style={ {
                  marginTop: '-2px',
                  marginLeft: '-5px',
                  height: '14px'
                } }
              />
            }
            iconPlacement="end"
            selected={ dateRange.type === 'exact' }
            selectedClickable
            dataTestId="date-range-control"
            ButtonProps={ {
              ...triggerProps,
              className: classes.filterControl,
              'data-date-from': dateRange.type === 'exact' ? dateRange.dateFrom.toJSON() : undefined,
              'data-date-to': dateRange.type === 'exact' ? dateRange.dateTo.toJSON() : undefined,
            } }
            onClick={ (_currentTarget, event) => triggerProps.onClick(event) }
          />
        ) }
        onChange={ ({ dateTimeFrom, dateTimeTo }) => {
          if (dateTimeFrom && dateTimeTo) {
            onChange({ type: 'exact', dateFrom: dateTimeFrom, dateTo: dateTimeTo });
          } else {
            onChange(INITIAL_DATE_RANGE);
          }
        } }
      />
    </Box>
  );
};
