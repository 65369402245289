import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Permissions, User } from 'models/user-management';
import { RootState } from 'reducers';
import { mapPossibleOptionToValue } from 'utils/form';
import { isNil } from 'utils/models';

import { PermissionsMode } from './shared';

// components
import { ReactSelect, SelectOption, SingleValueType } from 'components/Controls/Select';
import { SelectProps } from 'components/Controls/Select/ReactSelect';
import { FieldSkeleton } from 'components/Skeleton';

interface Option {
  label: string;
  value: Permissions['id'];
}

interface CustomProps {
  name: string;
  permissionsMode: PermissionsMode;
  mapOptions?: (options: Option[]) => Option[];
  filter?: (permissions: Permissions) => boolean;
  value: User['permissions'] | null;
  onChange: (value: User['permissions'] | null) => void;
}

type SelectPermissionsTemplateProps = (
  & Omit<SelectProps<SelectOption<number>, false>, 'isMulti' | 'options' | keyof CustomProps>
  & CustomProps
);

/**
 * Permissions template selector to be used specifically on the user page
 * since it mustn't be rendered if `props.permissionsMode !== PermissionsMode.TEMPLATE`
 */
const SelectPermissionsTemplate: React.FC<SelectPermissionsTemplateProps> = (props) => {
  const permissions = useSelector((state: RootState) => ({
    byId: state.um.permissionsById,
    loading: state.um.permissionsFetching,
  }), shallowEqual);

  const { value } = props;

  if (props.permissionsMode !== PermissionsMode.TEMPLATE) {
    return null;
  }

  let options = Object.values(permissions.byId)
    // `.filter` doesn't change the array type but `.flatMap` does
    .flatMap(p => (isNil(p) || !p.template) ? [] : [p])
    .filter(props.filter ?? (() => true))
    .map(p => ({ label: p.name, value: p.id }));

  if (props.mapOptions) {
    options = props.mapOptions(options);
  }

  if (!options.length && permissions.loading) {
    return <FieldSkeleton />;
  }

  return (
    <ReactSelect
      {...props}
      isMulti={false}
      options={options}
      value={options.find(o => o.value === value)}
      onChange={possibleOption => {
        // `possibleOption as SingleValueType<Option>` is valid as long as `isMulti !== true`
        props.onChange(mapPossibleOptionToValue(possibleOption as SingleValueType<Option, false>) ?? null);
      }}
    />
  );
};

export default SelectPermissionsTemplate;
