import { createStyles, makeStyles } from 'styles/utils';

export const useStyles = makeStyles(() =>
  createStyles({
    gridEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
