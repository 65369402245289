import { Project } from 'models/user-management';
import { commonOptions } from 'utils/tables';
import { usePagination } from 'hooks';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { OwnerName } from 'components/Labels';
import { ProjectLink } from 'components/Links';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { truncatedCellsMuiTableTheme } from 'styles/themes';

interface Props {
  projects: Project[];
  isAdmin: boolean;
  isFetching: boolean;
}
export const ProjectsTable = ({ projects, isAdmin, isFetching }: Props): JSX.Element => {
  const { page, limit } = usePagination();
  const columns: MUIDataTableColumn[] = [
    {
      name: 'project',
      label: 'Project Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: ([id, name]: [number, string]) => <ProjectLink projectId={ id } name={ name } />
      }
    },
    {
      name: 'timezone',
      label: 'Timezone',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (timezone?: string) => timezone || '-',
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        filter: false,
        sort: false,
        display: isAdmin ? 'true' : 'excluded',
        customBodyRender: (ownerId: number) => <OwnerName ownerId={ ownerId } />
      },
    }
  ];
  const options: MUIDataTableOptions = {
    ...commonOptions,
    page,
    rowsPerPage: limit,
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  return (
    <MuiThemeProvider theme={ truncatedCellsMuiTableTheme(projects.length) }>
      <TableLoadingLayout isLoading={ isFetching }>
        <MUIDataTable
          title={ null }
          data={ projects.map(project => ({
            project: [project.id, project.name],
            owner: project.owner_id,
            timezone: project.timezone,
          }))}
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};
