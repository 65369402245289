import React, { useState } from 'react';

import { useLevelsSelector } from 'hooks/device-management/levels';
import { Zone, Level, Group } from 'models/device-management';
import { nameof } from 'utils/type-checking';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { Typography } from '@material-ui/core';

import { AddButton } from 'components/Buttons';
import { TableLoadingLayout } from 'components/Table';

import { CreateLevel } from './CreateLevel';
import { DeleteLevel } from './DeleteLevel';
import { UpdateLevel } from './UpdateLevel';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeTableStyles } from 'styles';
import { getMuiTheme } from 'styles/themes';

import { useStyles } from './styles';

const useTableStyles = makeTableStyles();

interface LevelsTableProps {
  zone: Zone;
  groups: Group[];
  allowedToUpdateLevel: boolean;
  allowedToCreateLevel: boolean;
  allowedToDeleteLevel: boolean
}

const ROWS_PER_PAGE = 10;

export const LevelsTable = ({ zone, groups, allowedToUpdateLevel, allowedToCreateLevel, allowedToDeleteLevel }: LevelsTableProps) => {
  const levelFilters = { limit: 2_000 };
  const { levels: allLevels, isLoading } = useLevelsSelector({ params: levelFilters });
  const visibleLevels = allLevels.filter(l => l.zone_id === zone.id);

  const [activeLevelId, setActiveLevelId] = useState<number | undefined>();
  const styles = useStyles();
  const tableStyles = useTableStyles();

  const columns: MUIDataTableColumn[] = [
    {
      name: nameof<Level>('floor_number'),
      label: 'Floor',
    },
    {
      name: nameof<Level>('name'),
      label: 'Name',
      options: {
        customBodyRenderLite: dataIndex => (
          <Typography
            className={ allowedToUpdateLevel ? styles.inlineActiveButton : styles.inlineButton }
            color="secondary"
            component="span"
            onClick={ allowedToUpdateLevel ? () => { setActiveLevelId(visibleLevels[dataIndex].id); } : undefined }
          >
            { visibleLevels[dataIndex].name }
          </Typography>
        ),
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        setCellProps: () => ({ className: tableStyles.shrunkCell }),
        customBodyRenderLite: dataIndex => (
          <div className={tableStyles.actionsCellBody}>
            {allowedToDeleteLevel && (
              <DeleteLevel
              // className={tableStyles.actionsIcon}
                level={ visibleLevels[dataIndex] }
                groups={ groups }
              />
            )}
          </div>
        ),
      },
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    elevation: 0,
    rowsPerPage: ROWS_PER_PAGE,
    rowsPerPageOptions: [ROWS_PER_PAGE],
    sortOrder: {
      name: 'floor_number',
      direction: 'asc',
    },
    customToolbar: allowedToCreateLevel ? (() => (
      <CreateLevel
        zone={ zone }
        allLevels={ allLevels }
        renderTrigger={ modal => (
          <AddButton
            label="Add level"
            type="icon"
            onClick={ modal.open }
          />
        ) }
      />
    )) : undefined
  };

  const title = 'Levels';

  return (
    <>
      <MuiThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={ isLoading && !visibleLevels.length }>
          <MUIDataTable
            title={ title }
            data={ visibleLevels }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>

      { !!activeLevelId && (
        <UpdateLevel
          level={ allLevels.find(l => l.id === activeLevelId) as Level }
          allLevels={ allLevels }
          zone={ zone }
          onClose={ () => setActiveLevelId(undefined) }
        />
      ) }
    </>
  );
};
