import { combineReducers } from 'redux';
import { reducer as groups, State as StateGroupParkingAnomaly } from 'reducers/parking-monitoring/groups';
import { reducer as positions, State as StatePositionParkingAnomaly } from 'reducers/parking-monitoring/positions';
import { reducer as zones, State as StateZoneParkingAnomaly } from 'reducers/parking-monitoring/zones';


export interface State {
  groups: StateGroupParkingAnomaly;
  positions: StatePositionParkingAnomaly;
  zones: StateZoneParkingAnomaly;
}

export const reducer = combineReducers({
  groups,
  positions,
  zones
});
