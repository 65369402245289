/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Http response reason
 * @export
 * @enum {string}
 */
export enum ResponseReason {
    Ok = 'ok',
    NotFound = 'not_found',
    ServerError = 'server_error',
    InvalidField = 'invalid_field',
    Created = 'created',
    NoData = 'no_data',
    NotImplemented = 'not_implemented',
    Restricted = 'restricted',
    Forbidden = 'forbidden'
}

