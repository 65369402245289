import { PositionStateStatus } from 'models/parking';

export enum Duration {
  ALL = 'all',
  LESS_30_MIN = 'l30m',
  LESS_4_HOUR = 'l4h',
  MORE_4_HOUR = 'm4h',
  CUSTOM = 'custom',
}

export interface FilterState {
  owner?: number;
  projects: number[];
  zones: number[];
  groupIds: number[];
  networkIds: string[];
  customIds: string[];
  deviceIds: string[];
  status?: PositionStateStatus;
  duration: Duration;
  timeFrom?: Date;
  timeTo?: Date;
}

export enum FilterField {
  ownerId,
  projectIds,
  zoneIds,
  groupIds,
  networkIds,
  customIds,
  deviceIds,
  status,
  duration,
  timeFrom,
  timeTo,
}
