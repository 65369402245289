import React from 'react';

import { ALL_PROJECTS, Project, User } from 'models/user-management';

import { ProjectsMode } from './shared';

// components
import { ProjectSelectControl } from 'components/Controls';

type SelectProjectsProps = Omit<typeof ProjectSelectControl, 'selected' | 'onChange'> & {
  projectsMode: ProjectsMode;
  label: string;
  value: User['projects'];
  onChange: (value: Project['id'][]) => void;
};

/**
 * Projects selector to be used specifically on the user page
 * since it mustn't be rendered if `props.projectsMode !== ProjectsMode.LIST`
 */
const UserPageSelectProjects: React.FC<SelectProjectsProps> = ({
  projectsMode,
  value,
  ...restProps
}) => {
  if (
    projectsMode !== ProjectsMode.LIST ||
    value === ALL_PROJECTS
  ) {
    return null;
  }

  return (
    <ProjectSelectControl
      {...restProps}
      isMulti
      isMultiLine
      isClearable
      selected={ value }
    />
  );
};

export default UserPageSelectProjects;
