import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from 'actions/parking-monitoring';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ApiResponse, ReasonEnum } from 'models/apiResponse';

import {
  getPositionsMonitoring,
  getPositionMonitoring,
  createPositionMonitoringDuration,
  createPositionMonitoringIgnore,
  editPositionMonitoringDuration,
  editPositionMonitoringIgnore
} from 'clients/parking-monitoring';


function* getPositionsParkingAnomaly (action: actions.GetPositionsParkingAnomaly) {
  const response: ApiResponse = yield call(getPositionsMonitoring, action.filters);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetPositionsParkinganomalySuccess(response.data));
  } else {
    yield put(actions.DoGetPositionsParkinganomalyFailed());
    yield put(NotifyError(`Error while fetching positions parking anomaly: ${response.message}`));
  }
}

function* getMonitoringByPositionId (action: actions.GetByPositionIdParkingAnomaly) {
  const response: ApiResponse = yield call(getPositionMonitoring, action.id);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByPositionIdParkingAnomalySuccess(response.data));
  } else if (response.reason === ReasonEnum.NotFound) {
    yield put(actions.DoGetByPositionIdParkingAnomalyFailed());
  } else {
    yield put(actions.DoGetByPositionIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while fetching position parking anomaly: ${response.message}`));
  }
}

function* setPositionParkingAnomalyDuration (action: actions.SetPositionParkingAnomalyDuration) {
  const response: ApiResponse = yield call(createPositionMonitoringDuration, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByPositionIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Monitoring has been set'));
  } else {
    yield put(actions.DoGetByPositionIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while setting position parking anomaly monitoring: ${response.message}`));
  }
}

function* setPositionParkingAnomalyIgnore (action: actions.SetPositionParkingAnomalyIgnore) {
  const response: ApiResponse = yield call(createPositionMonitoringIgnore, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByPositionIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Monitoring has been set'));
  } else {
    yield put(actions.DoGetByPositionIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while setting position parking anomaly monitoring: ${response.message}`));
  }
}

function* editPositionDurationParkingAnomaly (action: actions.EditPositionDurationParkingAnomaly) {
  const response: ApiResponse = yield call(editPositionMonitoringDuration, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByPositionIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Configuration has been updated'));
  } else {
    yield put(actions.DoGetByPositionIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while updating position parking anomaly monitoring: ${response.message}`));
  }
}

function* editPositionIgnoreParkingAnomaly (action: actions.EditPositionIgnoreParkingAnomaly) {
  const response: ApiResponse = yield call(editPositionMonitoringIgnore, action.payload);
  if (response.reason === ReasonEnum.Ok && response.data) {
    yield put(actions.DoGetByPositionIdParkingAnomalySuccess(response.data));
    yield put(NotifySuccess('Parking anomaly monitoring has been updated'));
  } else {
    yield put(actions.DoGetByPositionIdParkingAnomalyFailed());
    yield put(NotifyError(`Error while updating position parking anomaly monitoring: ${response.message}`));
  }
}


export const parkingMonitoringPositionsSagas = [
  takeEvery(actions.GET_BY_POSITION_ID_PARKING_ANOMALY, getMonitoringByPositionId),
  takeEvery(actions.SET_POSITION_PARKING_ANOMALY_DURATION, setPositionParkingAnomalyDuration),
  takeEvery(actions.SET_POSITION_PARKING_ANOMALY_IGNORE, setPositionParkingAnomalyIgnore),
  takeEvery(actions.EDIT_POSITION_DURATION_PARKING_ANOMALY, editPositionDurationParkingAnomaly),
  takeEvery(actions.EDIT_POSITION_IGNORE_PARKING_ANOMALY, editPositionIgnoreParkingAnomaly),
  takeEvery(actions.GET_POSITIONS_PARKING_ANOMALY, getPositionsParkingAnomaly)
];
