import { State as UserState } from 'reducers/user-profile';

export function canViewRabbit(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('r'));
}

export function canViewEndpointList(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('r'));
}

export function canViewEndpointDetail(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('r'));
}

export function canCreateEndpoint(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('c'));
}
export function canEditEndpoint(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('u'));
}
export function canDeleteEndpoint(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('d'));
}


export function canCreateRule(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('c'));
}
export function canEditRule(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('u'));
}
export function canDeleteRule(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['rmq.sender']?.includes('d'));
}

