export enum FormField {
  ownerId = 'owner_id',
  projectId = 'project_id',
  zoneId = 'zone_id',
  levelId = 'level_id',
  groupId = 'group_id',
  innerId = 'inner_id',
  customId = 'custom_id',
  latitude = 'latitude',
  longitude = 'longitude',
}

export interface FormState {
  [FormField.ownerId]?: number;
  [FormField.projectId]?: number;
  [FormField.zoneId]?: number;
  [FormField.levelId]?: number;
  [FormField.groupId]?: number;
  [FormField.innerId]?: number;
  [FormField.customId]?: string;
  [FormField.latitude]?: number;
  [FormField.longitude]?: number;
}