import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { RootState } from 'reducers';
import { Helmet } from 'react-helmet-async';
import { useStationLocationsDictionarySelector, useStationResponsiblePersonsDictionary, useStationsDictionarySelector } from 'hooks/station';
import { DoSetFilters, DoUpdateFiltersFromUrl } from 'actions/pages/bs-responsible-persons';
import { useUsersDictionary } from 'utils/dictionary';
import { usePagination } from 'hooks';
import { BSResponsiblePersonsFields, Registered, ResponsiblePerson } from 'models/base-station';
import { defaultFilters } from 'reducers/pages/bs-responsible-persons';
// components
import { ResponsiblePersonsFilter, ResponsiblePersonsTable } from 'components/BaseStation';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

const filterData = (filters: BSResponsiblePersonsFields, persons: ResponsiblePerson[]) => persons.filter(p => {
  if (filters.owner !== undefined && p.owner_id !== filters.owner) {
    return false;
  }
  if ((filters.user === Registered.user && !p.registered_user_id) || ( filters.user === Registered.notUser && p.registered_user_id)) {
    return false;
  }
  if ( filters.role !== undefined && filters.role.toLowerCase() !== p.role.toLowerCase()) {
    return false;
  }
  return true;
});


export const ResponsiblePersons = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const users = useUsersDictionary();
  const { onChangePage } = usePagination();
  const { locations } = useStationLocationsDictionarySelector();
  const { stations } = useStationsDictionarySelector();

  const { filters } = useSelector((state: RootState) => state.pages.bsResponsiblePersons);
  const [state, setState] = useState<BSResponsiblePersonsFields>(filters);
  const { isLoading: isLoadingPersons, responsiblePersons } = useStationResponsiblePersonsDictionary();

  useEffect(() => {
    dispatch(DoUpdateFiltersFromUrl());
  }, [dispatch]);

  useDeepCompareEffect(() => setState(filters), [filters]);

  const onReset = () => {
    dispatch(DoSetFilters(defaultFilters));
    setState(defaultFilters);
    onChangePage(0);
  };

  const onSave = () => {
    dispatch(DoSetFilters(state));
    onChangePage(0);
  };

  const filteredPersonData = filterData(filters, responsiblePersons);
  return (
    <>
      <Helmet>
        <title>Responsible Persons</title>
      </Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ isLoadingPersons }
          filter={ filters }
          onSave={ onSave }
          onReset={ onReset }
        >
          <ResponsiblePersonsFilter
            responsiblePersons={ responsiblePersons }
            state={ state }
            setState={ setState }
          />
        </FilterButton>
      </div>
      <div className={ classes.table }>
        <ResponsiblePersonsTable
          responsiblePersons={ filteredPersonData }
          isLoadingPersons={ isLoadingPersons }
          users={ users }
          stations={ stations }
          locations={ locations }
        />
      </div>
    </>
  );
};
