import React from 'react';
import { ResponsiblePerson, StationLocation, Registered } from 'models/base-station';
import { useAuthUserSelector, useDialog, useDialogWithItem, useStationResponsiblePersonParamsSelector } from 'hooks';
import { canEditStation } from 'utils/permissions';

// components
import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { ListItemSkeleton } from 'components/Skeleton';
import { EditButton, MinusButton, PlusButton } from 'components/Buttons';
import { AddPersonDialog, DeletePersonDialog, EditPersonDialog } from 'components/BaseStation/Dialogs';
// styles
import useStyles from './style';

interface Props {
  readonly stationLabel: string;
  readonly location: StationLocation;
}

export const PersonBlock = ({ stationLabel, location }: Props): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const addDialog = useDialog();
  const editDialog = useDialogWithItem<ResponsiblePerson>();
  const deleteDialog = useDialogWithItem<ResponsiblePerson>();

  const { isLoading, responsiblePersons } = useStationResponsiblePersonParamsSelector({
    locationIds: [location.id],
  });

  return (
    <Box>
      <Box className={ classes.blockPersonHeaderRoot }>
        <Typography className={ classes.headerPersonTitle }>
          Add responsible person
        </Typography>
        { canEditStation(user) && <PlusButton type="icon" label="Add" onClick={ addDialog.open } /> }
      </Box>
      <Box className={ classes.blockPersonContentRoot }>
        {
          isLoading
            ? <ListItemSkeleton ListProps={ { className: classes.personsList } } itemsCount={ 5 }/>
            : (
              !responsiblePersons.length
                ? <Box className={ classes.emptyPersons }>There is no responsible person for this location</Box>
                : (
                  <List className={ classes.personsList }>
                    { responsiblePersons.map(person => {
                      return (
                        <ListItem key={ person.id } className={ classes.person } >
                          <ListItemText
                            primary={
                              <>
                                { `${ person.first_name } ${ person.last_name }` }
                                <Typography
                                  className={ classes.textOutlined }
                                  component="span"
                                  color="primary"
                                >
                                  { person.registered_user_id ? Registered.user : Registered.notUser }
                                </Typography>
                                <Typography
                                  className={ classes.textOutlined }
                                  component="span"
                                  color="primary"
                                >
                                  Notification { person.notify ? 'ON' : 'OFF' }
                                </Typography>
                              </>
                            }
                            primaryTypographyProps={{
                              component: 'p',
                              gutterBottom: true,
                            }}
                            secondary={ person.role }
                          />
                          <ListItemSecondaryAction>
                            <EditButton
                              type="icon"
                              label="Edit"
                              onClick={() => editDialog.open(person)}
                            />
                            <MinusButton
                              type="icon"
                              label="Detach"
                              onClick={() => deleteDialog.open(person)}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    }) }
                  </List>
                )
            )
        }
      </Box>
      { addDialog.isMounted && <AddPersonDialog
        location={ location }
        isOpen={ addDialog.isOpen }
        onClose={ addDialog.close }
        onCloseEnd={ addDialog.unmount }
      /> }
      { deleteDialog.isMounted && deleteDialog.item && <DeletePersonDialog
        stationLabel={stationLabel}
        location={ location }
        person={ deleteDialog.item }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
      /> }
      { editDialog.isMounted && editDialog.item && <EditPersonDialog
        person={ editDialog.item }
        isOpen={ editDialog.isOpen }
        onClose={ editDialog.close }
        onCloseEnd={ editDialog.unmount }
      /> }
    </Box>
  );
};
