import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDeepCompareEffect } from 'react-use';
import { useFilterFromLocation } from './useFilterFromLocation';
import { useStationEmtyNotify, useStationsByFilters } from './useStationsByFilters';
import { useDevicesByFilters } from './useDevicesByFilters';
import { BSConnectivityMapFilters, initMapFilters } from './types';

// components
import { StationConnectivityMap } from 'components/BaseStation';
import { BaseStationsConnectivityMapFilters } from 'components';
import { PageLoader } from 'components/Loaders';
import { FilterButton } from 'components/Buttons';

// styles
import useStyles from 'styles/page';

export const BaseStationConnectivityMap = () => {
  const classes = useStyles();

  const { filter, setFilter } = useFilterFromLocation();
  const { isLoading: isStationLoading, stations } = useStationsByFilters(filter);
  const { isLoading: isDeviceLoading, devices } = useDevicesByFilters(filter);
  useStationEmtyNotify(isStationLoading, stations);

  const [filterState, setFilterState] = useState<BSConnectivityMapFilters>(filter);
  useDeepCompareEffect(() => setFilterState(filter), [filter]);

  const onSave = () => setFilter({ ...filterState });
  const onReset = () => {
    setFilterState(initMapFilters);
    setFilter({ ...initMapFilters });
  };
  return (
    <>
      <Helmet><title>Connectivity Map</title></Helmet>
      <div className={ classes.toolbar }>
        <FilterButton
          isLoading={ isStationLoading }
          filter={ filter }
          onSave={ onSave }
          onReset={ onReset }
        >
          <BaseStationsConnectivityMapFilters
            state={ filterState }
            setState={ setFilterState }
          />
        </FilterButton>
      </div>
      { isStationLoading ? <PageLoader /> :
        <StationConnectivityMap
          isLoading={ isStationLoading || isDeviceLoading }
          filter={ filter }
          stations={ stations.filter(s => !!s.status && s.location?.lat && s.location.lon) }
          devices={ devices }
        />
      }
    </>
  );
};

