import { API } from 'aws-amplify';

import { errorHandler } from 'clients/utils';
import { ApiResponse } from 'models';

import { config } from './config';

const urls = {
  statistic: '/calls/history/statistic',
};

export const getStatistic = (): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.statistic, {})
    .catch(errorHandler);
};
