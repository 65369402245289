import { parse, stringify } from 'query-string';
import moment, { Moment } from 'moment';
import { Location, LocationDescriptorObject } from 'history';

export type buildCallback<T> = (params: UrlItems) => T;

export interface UrlItems {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

interface PathCreatorOptions {
  pathname?: string;
  hashParams?: UrlItems;
}

export function createPath({ pathname = '', hashParams }: PathCreatorOptions): string {
  let path = pathname;

  if (hashParams) {
    const hash = buildUrlParams(hashParams);

    if (hash) {
      path += `#${hash}`;
    }
  }

  return path;
}

export function getLocation(path: string, params: UrlItems): LocationDescriptorObject {
  return {
    pathname: path,
    hash: buildUrlParams(params)
  };
}

export function convertParamToMoment(date: string): Moment {
  return moment(decodeURIComponent(date));
}

export const trimQuery = (query: string): string =>
  query.startsWith('#?')
    ? query.slice(2)
    : (
      query.startsWith('?')
        ? query.slice(1)
        : query
    );

export function parseUrlParams<T>(query: string, buildParams: buildCallback<T>): T {
  const queryString = trimQuery(query);
  if (!queryString.length) {
    return buildParams({});
  }

  const queryParams = parse(queryString, { arrayFormat: 'comma', parseBooleans: true }) as UrlItems;
  // add Object.prototype for @react-use -> useDeepCompareEffect -> fast-deep-equal https://github.com/epoberezkin/fast-deep-equal/issues/111
  return buildParams({ ...queryParams });
}

export function buildUrlParams(params: UrlItems, initial?: UrlItems): string {
  const queryParams: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(params)) {
    if (value === '') {
      continue;
    }

    if (initial && initial[key] === value) {
      continue;
    }

    if (value instanceof Date || value instanceof moment) {
      queryParams[key] = (value as Date | Moment).toISOString();
    } else {
      queryParams[key] = value;
    }
  }

  const query = stringify(queryParams, { arrayFormat: 'comma', skipNull: true });
  return query.length ? `?${query}` : '';
}

interface SetUrlParamsOptions {
  params: UrlItems;
  initial?: UrlItems;
  /**
   * Preserve currently active params if they aren't specified in `params`.
   *
   * It's recommended since your block might be not the only one that stores params in URL
   * at the moment.
   *
   * Example: table of positions on the positions tab of zone page.
   *
   * - zone page stores "activeTabId" param to determine currently active tab
   * - table of positions stores its pagination state as URL params
   *
   * If we don't preserve "activeTabId" while changing the page of table, we'll end up
   * losing the currently active tab and transition to another tab. (BNIV-217)
   */
  extendCurrentParams?: boolean;
}

export function setUrlParams({
  params,
  initial,
  extendCurrentParams = false,
}: SetUrlParamsOptions): void {
  if (extendCurrentParams) {
    params = {
      ...parseUrlParams(window.location.hash, locationParams => locationParams),
      ...params,
    };
  }

  const query = buildUrlParams(params, initial);

  if (query.length) {
    window.location.hash = query;
  } else if (window.location.hash.length) {
    window.location.hash = query;
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
  }
}

export function getUrlItems(keys: string[]): UrlItems {
  return parseUrlParams<UrlItems>(window.location.hash, (params: UrlItems): UrlItems => {
    const queryParams: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(params)) {
      if (keys.includes(key)) {
        queryParams[key] = value;
      }
    }

    return queryParams;
  });
}

/**
 * Clear location hash params
 * @param reloadPage
 */
export function clearLocationParams(reloadPage?: boolean): void {
  window.location.hash = '';

  if (reloadPage) {
    window.location.reload();
  }
}

/**
 * get RETURN_URL to return back
 */
type ContentParams = {
  returnUrl?: string;
};
const buildParams = (params: UrlItems): ContentParams => ({
  returnUrl: params.returnUrl && decodeURIComponent(params.returnUrl),
});
export function qetReturnUrlParam(location: Location<any>): ContentParams {
  return parseUrlParams(location.hash, buildParams);
}
