import React from 'react';
import { useDispatch } from 'react-redux';

import { DoCreateLevel, DoUpdateLevel } from 'actions/device-management/levels';
import { Zone, Level, LevelUpdateFields } from 'models/device-management';
import {
  CreateLevelResult,
  CreateLevelSagaAction,
  UpdateLevelResult,
  UpdateLevelSagaAction,
} from 'models/device-management/actions';
import * as is from 'utils/form/validation/validators';
import { dispatchAsync } from 'utils/store';
import { nameof } from 'utils/type-checking';
import { useZones } from 'hooks/device-management';

// components
import { FormGroup } from '@material-ui/core';

import { Button, Form } from 'components';
import { SuccessButton } from 'components/Buttons';
import { ConfirmationDialog } from 'components/Dialogs';
import * as Fields from 'components/Form/Field';

// styles
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';

interface LevelFormValues {
  zone_id: number;
  floor_number: string;
  name: string;
}

interface LevelFormProps {
  level?: Level;
  allLevels: Level[];
  zone: Zone;
  showFields: (keyof LevelFormValues)[];

  onCancel?: () => void;
  onSuccess?: (level: Level) => void;

  actionsWrapper: React.ComponentType;
  contentWrapper: React.ComponentType;
  submitButtonLabel?: string;
}

export const LevelForm = ({
  level,
  allLevels,
  zone,
  showFields,

  onCancel,
  onSuccess,

  actionsWrapper: ActionsWrapper,
  contentWrapper: ContentWrapper,
  submitButtonLabel,
}: LevelFormProps) => {
  const { zones } = useZones({ params: {} });
  const dispatch = useDispatch();
  const infoCss = useInfoBlockStyles();

  const defaultValues: LevelFormValues = { // @TODO defaultValues not working
    zone_id: zone.id,
    name: level?.name ?? '',
    floor_number: level ? `${level.floor_number}` : '',
  };

  const getConfirmationDialogTitle = (floorNumber: string, zoneId: number) => {
    const zone = zones.find(z => z.id === zoneId);

    return `Level with floor number ${floorNumber} ` +
      `already exists in Zone ${zone?.name || `#${zoneId}`}`;
  };

  return (
    <Form<LevelFormValues>
      defaultValues={defaultValues}
      validators={{
        floor_number: [
          is.required(),
          value => {
            if (!value) {
              return;
            }

            const asNumber = parseFloat(value);

            if (!Number.isInteger(asNumber)) {
              return 'Must be an integer';
            }
            if (asNumber < -200) {
              return 'Must be greater or equal to -200';
            }
            if (asNumber > 200) {
              return 'Must be lower or equal to 200';
            }
          },
        ],
        name: [
          is.required(),
          value => {
            if (value && value.length > 32) {
              return 'Must not contain more than 32 symbols';
            }
          }
        ],
      }}
      onSubmit={values => {
        const nextLevel: LevelUpdateFields = {
          ...values,
          zone_id: values.zone_id || defaultValues.zone_id,
          floor_number: Number(values.floor_number),
        };

        const action = level ? DoUpdateLevel(level.id, nextLevel) : DoCreateLevel(nextLevel);

        type Action = CreateLevelSagaAction | UpdateLevelSagaAction;
        type ActionResult = CreateLevelResult | UpdateLevelResult;

        return dispatchAsync<Action, ActionResult>(dispatch, action)
          .then(onSuccess);
      }}
    >
      {form => (
        <>
          <ContentWrapper>
            <FormGroup className={infoCss.fields}>
              <div
                className={infoCss.field}
                hidden={!showFields?.includes('name')}
              >
                <Fields.Text
                  fullWidth
                  label="Name"
                  name={nameof<LevelFormValues>('name')}
                />
              </div>

              <div
                className={infoCss.field}
                hidden={!showFields?.includes('floor_number')}
              >
                <Fields.Text
                  fullWidth
                  label="Floor number"
                  name={nameof<LevelFormValues>('floor_number')}
                />
              </div>

              <div
                className={infoCss.field}
                hidden={!showFields?.includes('zone_id')}
              >
                <Fields.SelectZone
                  label="Zone"
                  name={nameof<LevelFormValues>('zone_id')}
                />
              </div>
            </FormGroup>
          </ContentWrapper>

          <ActionsWrapper>
            {onCancel && (
              <Button color="inherit" onClick={onCancel}>
                Cancel
              </Button>
            )}

            <ConfirmationDialog
              title={getConfirmationDialogTitle(
                form.watch('floor_number'),
                form.watch('zone_id'),
              )}
              description="Do you want to add another one?"
              renderTrigger={modal => (
                <SuccessButton
                  disabled={!form.formState.dirty}
                  pending={form.formState.isSubmitting}
                  label={submitButtonLabel}
                  onClick={e => {
                    e.preventDefault();

                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    form.triggerValidation().then(async valid => {
                      if (!valid) {
                        return;
                      }

                      const values = form.getValues();

                      if (
                        defaultValues.floor_number === values.floor_number &&
                        defaultValues.zone_id === values.zone_id
                      ) {
                        await form.submit();
                        return;
                      }

                      const selectedZoneId = values.zone_id;
                      const editedLevelId = level?.id;
                      const floorNumber = Number(values.floor_number);
                      const sameFloorExists = allLevels.some(l => {
                        if (l.zone_id !== selectedZoneId) {
                          return false;
                        }

                        if (editedLevelId === l.id) {
                          return false;
                        }

                        return l.floor_number === floorNumber;
                      });

                      if (sameFloorExists) {
                        modal.open();
                      } else {
                        await form.submit();
                      }
                    });
                  }}
                />
              )}
              onConfirm={async modal => {
                modal.close();
                await form.submit();
              }}
            />
          </ActionsWrapper>
        </>
      )}
    </Form>
  );
};
