import { RemoveLocationFromResponsiblePerson } from 'actions/base-station';
import { useFormActionLoader } from 'hooks/form';
import { ResponsiblePerson, StationLocation } from 'models/base-station';
import { formatResponsiblePerson } from 'utils/models';

// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  stationLabel: string;
  location: StationLocation;
  person: ResponsiblePerson;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DeletePersonDialog = ({ stationLabel, location, person, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();

  const handleConfirm = () => doAction({
    action: RemoveLocationFromResponsiblePerson(location.id, person.id),
    onSuccess: () => onClose(),
  });

  return (
    <EnsureDialog
      name="delete-responsible-person"
      label="Detach responsible person from location"
      btnNo="Cancel"
      btnYes="Detach"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      You are detaching responsible person from the location.<br />
      { location.station_id && (
        <>
          Notifications for station <strong>#{ stationLabel }</strong>{ ' ' }
          will not be sent to <strong>{ formatResponsiblePerson(person) }</strong>.<br />
        </>
      ) }
      Are you sure?
    </EnsureDialog>
  );
};
