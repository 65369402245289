import { DevicesFilterFields } from 'models/device-management';
import { Firmware } from 'models/firmware-management';

export const DEVICE_DOWNLOAD_START = 'dm/deviceDownload/START';
export const DEVICE_DOWNLOAD_SUCCESS = 'dm/deviceDownload/SUCCESS';
export const DEVICE_DOWNLOAD_FAILURE = 'dm/deviceDownload/FAILURE';
export const DEVICE_DOWNLOAD_COMPLETE = 'dm/deviceDownload/COMPLETE';
export const DEVICE_DOWNLOAD_CANCEL = 'dm/deviceDownload/CANCEL';

export interface StartDeviceDownload {
  readonly type: typeof DEVICE_DOWNLOAD_START;
  readonly filter: DevicesFilterFields;
  readonly firmwares: Firmware[];
}

export interface SuccessDeviceDownload {
  readonly type: typeof DEVICE_DOWNLOAD_SUCCESS;
  readonly ready: number;
  readonly total: number;
}

export interface FailureDeviceDownload {
  readonly type: typeof DEVICE_DOWNLOAD_FAILURE;
  readonly error: string;
}

export interface CompleteDeviceDownload {
  readonly type: typeof DEVICE_DOWNLOAD_COMPLETE;
}

export interface CancelDeviceDownload {
  readonly type: typeof DEVICE_DOWNLOAD_CANCEL;
}

export const StartDeviceDownload = (filter: DevicesFilterFields, firmwares: Firmware[]): StartDeviceDownload => ({
  type: DEVICE_DOWNLOAD_START, filter, firmwares
});
export const SuccessDeviceDownload = (ready: number, total: number): SuccessDeviceDownload => ({
  type: DEVICE_DOWNLOAD_SUCCESS, ready, total
});
export const FailureDeviceDownload = (error: string): FailureDeviceDownload => ({
  type: DEVICE_DOWNLOAD_FAILURE, error
});
export const CompleteDeviceDownload = (): CompleteDeviceDownload => ({
  type: DEVICE_DOWNLOAD_COMPLETE
});
export const CancelDeviceDownload = (): CancelDeviceDownload => ({
  type: DEVICE_DOWNLOAD_CANCEL
});

export type Action =
  | StartDeviceDownload
  | SuccessDeviceDownload
  | FailureDeviceDownload
  | CompleteDeviceDownload
  | CancelDeviceDownload