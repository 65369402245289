import React from 'react';

import { DmTreeNode } from 'hooks/device-management/useDmTree/types';

// components
import { DmLabels } from 'components/DmLabels';

interface LabelsCellProps {
  node: DmTreeNode;
}

export const LabelsCell = ({ node }: LabelsCellProps) => {
  return <DmLabels labels={ getNodeLabels(node) } />;
};

function getNodeLabels(node: DmTreeNode) {
  switch (node.type) {
    case 'zone': return node.zone.labels ?? [];
    case 'level': return node.level?.labels ?? [];
    case 'group': return node.group.labels ?? [];
    case 'position': return node.position.labels ?? [];
  }
}
