/**
 * Monitoring API
 * Monitoring API for working with device errors
 *
 * The version of the OpenAPI document: 1.17.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Types of Failures
 */
export type IncidentsTypesEnumModel = 'manual' | 'hardware_error' | 'last_message_time_error' | 'anomalous_status_duration';

export const IncidentsTypesEnumModel = {
    Manual: 'manual' as IncidentsTypesEnumModel,
    HardwareError: 'hardware_error' as IncidentsTypesEnumModel,
    LastMessageTimeError: 'last_message_time_error' as IncidentsTypesEnumModel,
    AnomalousStatusDuration: 'anomalous_status_duration' as IncidentsTypesEnumModel
}
