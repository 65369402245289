import React, { ReactNode } from 'react';

import { PositionNode, Tree, TreeNode } from 'hooks/device-management/useTree/types';

// components
import { Box, Tooltip } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { SensorsOffIcon } from 'components/Icons';
import { InlineLoader } from 'components/Loaders';
// styles
import { colors } from 'styles/themes/outer';

interface Props {
  tree: Tree;
  node: TreeNode;
  emptyValue?: ReactNode;
}

export const IssuesCell = (props: Props) => {
  return <> { renderNode(props) } </>;
};

function renderNode(props: Props) {
  switch (props.node.type) {
    case 'zone':
    case 'group':
    case 'level':
      return <MultiIssuesCell { ...props } />;
    case 'position':
      return <SingleIssuesCell { ...props } />;
    default:
      return null;
  }
}

const MultiIssuesCell = ({ tree, node }: Props): JSX.Element => {

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Tooltip title="Damaged devices">
        <Box display="flex" alignItems="center" minWidth="50%">
          <ReportProblemIcon htmlColor={ colors.lightRed }/>&nbsp;
          { tree.loaded.device ? calculateDamaged(node) : <InlineLoader/> }
        </Box>
      </Tooltip>
      <Tooltip title="Silent devices">
        <Box display="flex" alignItems="center" minWidth="50%">
          <SensorsOffIcon/>&nbsp;
          { tree.loaded.deviceState ? calculateSilents(node) : <InlineLoader/> }
        </Box>
      </Tooltip>
    </Box>
  );
};

const SingleIssuesCell = (props: Props): JSX.Element => {
  const isDamagedLoaded = props.tree.loaded.device;
  const isSilentLoaded = props.tree.loaded.deviceState;
  const node = props.node as PositionNode;
  return (
    <Box display="flex" alignItems="center" width="100%">
      { !isDamagedLoaded ? <InlineLoader/> : node.device?.damaged_status && (
        <Tooltip title="Damaged device">
          <Box display="flex" alignItems="center" minWidth="50%">
            <ReportProblemIcon htmlColor={ colors.lightRed }/>
          </Box>
        </Tooltip>
      ) }
      { !isSilentLoaded ? <InlineLoader/> : node.deviceState && (
        <Tooltip title="Silent device">
          <Box display="flex" alignItems="center" minWidth="50%">
            <SensorsOffIcon/>
          </Box>
        </Tooltip>
      ) }
      { isDamagedLoaded && !node.device?.damaged_status && isSilentLoaded && !node.deviceState && props.emptyValue }
    </Box>
  );
};

const calculateDamaged = (node: TreeNode): number => {
  switch (node.type) {
    case 'zone':
      return Object.values(node.children.items)
        .reduce((total, levelNode) => total + calculateDamaged(levelNode), 0);
    case 'level':
      return Object.values(node.children.items)
        .reduce((total, groupNode) => total + calculateDamaged(groupNode), 0);
    case 'group':
      return Object.values(node.devices.data)
        .reduce((total, device) => total + (device.damaged_status ? 1 : 0), 0);
    case 'position':
      return 0;
  }
};

const calculateSilents = (node: TreeNode): number => {
  switch (node.type) {
    case 'zone':
      return Object.values(node.children.items)
        .reduce((total, levelNode) => total + calculateSilents(levelNode), 0);
    case 'level':
      return Object.values(node.children.items)
        .reduce((total, groupNode) => total + calculateSilents(groupNode), 0);
    case 'group':
      return node.deviceStates.total;
    case 'position':
      return 0;
  }
};
