import { API } from 'aws-amplify';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse, ApiResponseWithTotal } from 'models';
import { errorHandler, formatQuery } from './utils';
import {
  CreateResponsiblePerson,
  GetResponsiblePersonParams,
  GetStationFlashParams,
  GetStationLocationParams,
  GetStationParams,
  LocationUpdate,
  LorawanStation,
  LoRaWANStationUpdate,
  Station,
  StationCreate,
  StationFlashCreate,
  StationLoRaWANCreate,
  StationUpdate,
  UpdateResponsiblePerson,
} from 'models/base-station';

const urls = {
  baseStations: '/base_stations',
  getStationById: (id: number) => `/base_stations/${ id }`,
  baseStationFlash: '/base_stations/flash',
  baseStationFlashCancel: (id: string) => `/base_stations/flash/cancel/${ id }`,
  locations: '/locations',
  getLocationById: (id: number) => `/locations/${ id }`,
  responsiblePersons: '/responsible_persons',
  getResponsiblePersonById: (id: number) => `/responsible_persons/${ id }`,
  addResponsibility: (rpId: number, locId: number) => `/responsible_persons/${rpId}/add_responsibility/${locId}`,
  removeResponsibility: (rpId: number, locId: number) => `/responsible_persons/${rpId}/remove_responsibility/${locId}`,
  lorawanBaseStations: '/base_stations_lora',
  lorawanBaseStationById:  (id: number) => `/base_stations_lora/${ id }`,
};

export const config: ApiGConfig = {
  name: 'bs',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://bslist.prod.api.nwave.io' : 'https://bslist.dev.api.nwave.io',
};

export const fetchStationsFlashByParams = (params: GetStationFlashParams): Promise<ApiResponse> => {
  const init = {
    queryStringParameters: formatQuery({
      'limit': params.limit,
      'offset': params.offset,
      'execution_ids': params.executionIds,
      'ids': params.stationIds,
      'generation': params.generation,
      'owner_id': params.ownerId,
    }),
  };

  return API
    .get(config.name, urls.baseStationFlash, init)
    .catch(errorHandler);
};

export const createStationFlash = (station: StationFlashCreate): Promise<ApiResponse> => {
  const init = {
    body: {
      'id': station.id,
      'pin': station.pin,
      'frequency': station.frequency,
      'receiver_type': station.receiverType,
      'owner_id': station.ownerId,
    },
  };
  return API
    .post(config.name, urls.baseStationFlash, init)
    .catch(errorHandler);
};

export const cancelStationFlash = (execId: string): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.baseStationFlashCancel(execId), {})
    .catch(errorHandler);
};

export const createStationLorawan = (station: StationLoRaWANCreate): Promise<ApiResponse> => {
  const body = {
    body: {
      ...station
    },
  };
  return API
    .post(config.name, urls.lorawanBaseStations, body)
    .catch(errorHandler);
};

export const getLocationsByParams = (params: GetStationLocationParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      ids: params.locationIds,
      owner_id: params.ownerId,
      project: params.projectId,
    }),
  };

  return API
    .get(config.name, urls.locations, body)
    .catch(errorHandler);
};

export const createLocation = (location: LocationUpdate): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.locations, {
      body: {
        'owner_id': location.ownerId,
        'project': location.projectId,
        'country': location.country,
        'city': location.city,
        'address': location.address,
        'postcode': location.postcode,
        'notes': location.notes,
        'lat': location.lat,
        'lon': location.lon,
      }
    })
    .catch(errorHandler);
};

export const updateLocation = (locationId: number, location: LocationUpdate): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.getLocationById(locationId), {
      body: {
        'owner_id': location.ownerId,
        'project': location.projectId,
        'country': location.country,
        'city': location.city,
        'address': location.address,
        'postcode': location.postcode,
        'notes': location.notes,
        'lat': location.lat,
        'lon': location.lon,
      }
    })
    .catch(errorHandler);
};
export const deleteLocation = (stationId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.getLocationById(stationId), {})
    .catch(errorHandler);
};

export const getStationsByParams = (params: GetStationParams): Promise<ApiResponseWithTotal<Station[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      ids: params.stationIds,
      owner_id: params.ownerId,
      city: params.city,
      country: params.country,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.baseStations, body)
    .catch(errorHandler);
};

export const createStation = (station: StationCreate): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.baseStations, {
      body: {
        'id': station.id,
        'owner_id': station.ownerId,
        'generation': station.generation,
        'receiver_type': station.receiverType,
        'frequency': station.frequency,
      }
    })
    .catch(errorHandler);
};

export const updateStation = (stationId: number, station: StationUpdate): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.getStationById(stationId), {
      body: {
        'owner_id': station.ownerId,
        'generation': station.generation,
        'receiver_type': station.receiverType,
        'frequency': station.frequency,
        'location_id': station.locationId,
      }
    })
    .catch(errorHandler);
};
export const deleteStation = (stationId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.getStationById(stationId), {})
    .catch(errorHandler);
};

export const getResponsiblePersonsByParams = (params: GetResponsiblePersonParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      'id': params.personIds,
      'station_id': params.stationIds,
      'location_id': params.locationIds,
      'project': params.projectId,
      'owner_id': params.ownerId,
      'notify': params.notifyStatus,
      'limit': params.limit,
      'offset': params.offset,
    }),
  };

  return API
    .get(config.name, urls.responsiblePersons, body)
    .catch(errorHandler);
};

export const createResponsiblePerson = (person: CreateResponsiblePerson): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.responsiblePersons, {
      body: {
        'first_name': person.first_name,
        'last_name': person.last_name,
        'owner_id': person.owner_id,
        'role': person.role,
        'notify': Boolean(person.notify),
        'notification_method': person.notification_method,
        'notification_endpoint': person.notification_endpoint,
        'registered_user_id': person.registered_user_id ?? null,
      }
    })
    .catch(errorHandler);
};

export const updateResponsiblePerson = (person: UpdateResponsiblePerson): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.getResponsiblePersonById(person.rp_id), {
      body: {
        'first_name': person.first_name,
        'last_name': person.last_name,
        'owner_id': person.owner_id,
        'role': person.role,
        'notify': Boolean(person.notify),
        'notification_method': person.notification_method,
        'notification_endpoint': person.notification_endpoint,
        'registered_user_id': person.registered_user_id ?? null,
      }
    })
    .catch(errorHandler);
};

export const deleteResponsiblePerson = (personId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.getResponsiblePersonById(personId), {})
    .catch(errorHandler);
};

export const addLocationToResponsiblePerson = (personId: number, locationId: number,): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.addResponsibility(personId, locationId), {})
    .catch(errorHandler);
};

export const removeLocationFromResponsiblePerson = (personId: number, locationId: number,): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.removeResponsibility(personId, locationId), {})
    .catch(errorHandler);
};

export const getLorawanStationsByParams = (params: GetStationParams): Promise<ApiResponseWithTotal<LorawanStation[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      ids: params.stationIds,
      owner_id: params.ownerId,
      city: params.city,
      country: params.country,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.lorawanBaseStations, body)
    .catch(errorHandler);
};

export const getLorawanStationsById = (id: number): Promise<ApiResponseWithTotal<LorawanStation[]>> => {
  return API
    .get(config.name, urls.lorawanBaseStations, { queryStringParameters: { id } })
    .catch(errorHandler);
};

export const updateLoraStation = (stationId: number, station: LoRaWANStationUpdate): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.lorawanBaseStationById(stationId), {
      body: {
        'owner_id': station.owner_id,
        'location_id': station.locationId,
        'aws_id': station.aws_id,
        'eui': station.eui,
        'rf_region': station.rf_region,
        'name': station.name,
      }
    })
    .catch(errorHandler);
};
export const deleteLoraStation = (stationId: number): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.lorawanBaseStationById(stationId), {})
    .catch(errorHandler);
};
