import { isNil } from 'lodash';
import { FormErrors } from 'types/form';
import { FormField, FormState } from './types';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.device)) {
    if (!state[FormField.device]) {
      errors[FormField.device] = 'Device is required';
    }
  }
  if (fields.includes(FormField.messageType)) {
    if (!state[FormField.messageType]) {
      errors[FormField.messageType] = 'Message type is required';
    }
  }
  if (fields.includes(FormField.tag)) {
    if (!state[FormField.tag]) {
      errors[FormField.tag] = 'SDI Tag ID is required';
    } else if (state[FormField.tag]?.length !== 8) {
      errors[FormField.tag] = 'Expected to contain exactly 8 characters';
    }
  }

  if (fields.includes(FormField.counter)) {
    if (isNil(state[FormField.counter])) {
      errors[FormField.counter] = 'Counter is required';
    } else {
      const counterValue = Number(state[FormField.counter]);
      if (counterValue < 0) {
        errors[FormField.counter] = 'Counter must be greater than zero';
      } else if (counterValue > 1024) {
        errors[FormField.counter] = 'Counter must be less than 1024';
      }
    }
  }

  return errors;
};
