import { Zone, ZoneUpdateParams, Statistic, ZoneUpdateFields } from 'models/device-management';
import { ZoneConnectivity } from 'models/connectivity/zoneConnectivity';
import history from 'utils/routing/history';

export const DELETE_ZONE = 'dm/DELETE_ZONE';

export const UPDATE_ZONE = 'dm/UPDATE_ZONE';
export const UPDATE_ZONE_SUCCESS = 'dm/UPDATE_ZONE_SUCCESS';
export const DM_ZONE_ADD_TO_PROJECT = 'dm/zone/ADD_TO_PROJECT';

export const FETCH_ZONE_STATISTICS = 'FETCH_ZONE_STATISTICS';
export const FETCH_ZONE_STATISTICS_SUCCESS = 'FETCH_ZONE_STATISTICS_SUCCESS';
export const FETCH_ZONE_STATISTICS_FAILED = 'FETCH_ZONE_STATISTICS_FAILED';

export const CREATE_ZONE = 'CREATE_ZONE';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAILED = 'CREATE_ZONE_FAILED';

export interface DeleteZone {
  type: typeof DELETE_ZONE;
  zoneId: number;
}
export type DeleteZoneResult = void;

export interface UpdateZone {
  type: typeof UPDATE_ZONE;
  payload: ZoneUpdateParams;
}
export type UpdateZoneResult = void;
export interface UpdateZoneSuccess {
  type: typeof UPDATE_ZONE_SUCCESS;
  payload: ZoneUpdateParams;
}

export interface AddZonesToProject {
  readonly type: typeof DM_ZONE_ADD_TO_PROJECT;
  readonly projectId: number;
  readonly zones: Zone[];
}

export interface CreateZone {
  type: typeof CREATE_ZONE;
  payload: ZoneUpdateFields;
  history: typeof history;
}
export type CreateZoneResult = Zone;
export interface CreateZoneSuccess {
  type: typeof CREATE_ZONE_SUCCESS;
}

export interface CreateZoneFailed {
  type: typeof CREATE_ZONE_FAILED;
}

export interface GetZoneStatistics {
  filters: {
    zones: number[];
  };
  type: typeof FETCH_ZONE_STATISTICS;
}

export interface GetZoneStatisticsSuccess {
  type: typeof FETCH_ZONE_STATISTICS_SUCCESS;
  payload: {
    statAll: Statistic;
    connectivity: ZoneConnectivity[];
  };
}

export interface GetZoneStatisticsFailed {
  type: typeof FETCH_ZONE_STATISTICS_FAILED;
}

export const deleteZone = (zoneId: number): DeleteZone => ({
  type: DELETE_ZONE,
  zoneId,
});

export const updateZone = (params: UpdateZone['payload']): UpdateZone => ({
  type: UPDATE_ZONE,
  payload: params,
});

export const addZonesToProject = (projectId: number, zones: Zone[]): AddZonesToProject => ({
  type: DM_ZONE_ADD_TO_PROJECT, projectId, zones
});

export const updateZoneSuccess = (payload: UpdateZoneSuccess['payload']): UpdateZoneSuccess => ({
  type: UPDATE_ZONE_SUCCESS,
  payload,
});

export const DoCreateZone: (payload: ZoneUpdateFields) => CreateZone = payload => ({
  type: CREATE_ZONE,
  payload,
  history
});
export const DoCreateZoneSuccess: () => CreateZoneSuccess = () => ({
  type: CREATE_ZONE_SUCCESS
});
export const DoCreateZoneFailed: () => CreateZoneFailed = () => ({
  type: CREATE_ZONE_FAILED
});

export const DoGetZoneStatistics = (filters: GetZoneStatistics['filters']): GetZoneStatistics => ({
  type: FETCH_ZONE_STATISTICS,
  filters
});
export const DoGetZoneStatisticsSuccess = (payload: GetZoneStatisticsSuccess['payload']): GetZoneStatisticsSuccess => ({
  type: FETCH_ZONE_STATISTICS_SUCCESS,
  payload
});
export const DoGetZoneStatisticsFailed = (): GetZoneStatisticsFailed => ({
  type: FETCH_ZONE_STATISTICS_FAILED,
});

export type Action =
  DeleteZone |
  UpdateZone |
  UpdateZoneSuccess |
  GetZoneStatistics |
  GetZoneStatisticsSuccess |
  GetZoneStatisticsFailed |
  CreateZone |
  CreateZoneSuccess |
  CreateZoneFailed;
