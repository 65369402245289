import React from 'react';

// components
import { Box } from '@material-ui/core';
import { LoaderSvg } from './LoaderSvg';

export const InlineLoader = () => {
  return (
    <Box component="span" ml={ 0.5 } mr={ 0.5 }>
      <LoaderSvg />
    </Box>
  );
};
