import * as RHF from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

import { getErrors } from './validation/getErrors';
import { Errors, Validator } from './validation/types';

function mapErrorsToRHFErrors<TValues>(errors: Errors): RHF.NestDataObject<TValues, RHF.FieldError> {
  return Object.fromEntries(
    Object.entries(errors).map(([fieldName, message]) => {
      return [fieldName, { message, type: message }];
    }),
    // FIXME: remove `any` (BNIV-195)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
}

type MapValuesToValidators<TValues, TAllValues = TValues> = {
  [K in keyof TValues]?: (
    | Validator<TValues[K], TValues, TAllValues>
    | Validator<TValues[K], TValues, TAllValues>[]
  );
};

interface ValidationContext<TValues> {
  validators?: MapValuesToValidators<TValues>;
}

function validationResolver<TFormValues>(
  values: TFormValues,
  context?: ValidationContext<TFormValues>,
) {
  // FIXME: remove `any` (BNIV-195)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors = getErrors(values, context?.validators ?? {} as any);

  return {
    errors: mapErrorsToRHFErrors<TFormValues>(errors),
    values: Object.keys(errors).length > 0 ? {} : values,
  };
}

export type FormHookOptions<
  FormValues extends RHF.FieldValues = RHF.FieldValues
> = Pick<RHF.UseFormOptions<FormValues>, 'defaultValues'> & {
  validators?: MapValuesToValidators<FormValues>;
};

export type FormHookResult<TValues> = Omit<RHF.FormContextValues<TValues>, 'reset'> & {
  reset: () => void;
};

// by using Required<T>, we ensure that the whole state is preserved
const formStateKeysToPreserve: Required<RHF.OmitResetState> = {
  errors: true,
  dirty: true,
  dirtyFields: true,
  isSubmitted: true,
  isValid: true,
  submitCount: true,
  touched: true,
};

/**
 * Wrapper around react-hook-form's `useForm`. What it does:
 *
 * - it provides desirable yet missing features out of the box to avoid code duplication:
 *    - synchronization of `defaultValues`
 *    - custom schema validation via `validators` option
 *    - reset of form state (excluding form values) when submit succeeds
 *
 * - it provides good project-wide defaults
 *    - validation mode (`onBlur`)
 *
 * - TODO: it prevents the usage of harmful API
 * - TODO: it limits the API surface area of react-hook-form's `useForm`
 */
export function useForm<TFormValues extends RHF.FieldValues = RHF.FieldValues>(
  { defaultValues, validators, ...restOptions }: FormHookOptions<TFormValues>,
): FormHookResult<TFormValues> {
  const form = RHF.useForm<TFormValues, ValidationContext<TFormValues>>({
    ...restOptions,
    defaultValues,
    validationResolver,
    validationContext: { validators },
    mode: 'onBlur',
  });

  // since `defaultValues` is handy not to memoize before passing to `useForm` in components
  // (otherwise, memoization code would be duplicated in each component with `defaultValues`),
  // and we don't want to trigger reset on each render, we check deep equality here
  // TODO: update only those fields which aren't dirty yet (all fields are reset now)
  // TODO: update _default_ value of those fields which are already dirty
  useDeepCompareEffect(() => {
    form.reset(defaultValues, formStateKeysToPreserve);
  }, [defaultValues]);

  /**
   * When the form has been submitted successfully, in most cases,
   * the form state should be reset (i.e. no more dirty) and the values should be preserved.
   */
  const resetFormState = () => {
    form.reset(form.getValues({ nest: true }));
  };

  // TODO: expose as a memoized callback, just like react-hook-form's useForm?
  const resetToDefaultValues = () => {
    form.reset(defaultValues);
  };

  return {
    ...form,
    reset: resetToDefaultValues,

    handleSubmit: (callback) => {
      const submit = form.handleSubmit(callback);

      return (...args) => {
        return submit(...args).then(() => {
          if (form.formState.isValid) {
            resetFormState();
          }
        });
      };
    },
  };
}