import { createStyles, makeStyles, Theme } from 'styles/utils';
import { margins } from 'styles/page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...margins(theme)
    },
    zoneCell: {
      maxWidth: '40vw',
    },
  }),
);

export default useStyles;
