import { ApiResponse, ReasonEnum } from 'models/apiResponse';

type Fields<TField extends string = string> = Record<TField, string[]>;

export class ValidationError<TField extends string = string> extends Error {
  private readonly fields: Fields<TField>;

  constructor(message: string, fields: Fields<TField>) {
    super(message);
    this.fields = fields;

    Object.setPrototypeOf(this, ValidationError.prototype);
  }

  public getFieldsSingleError() {
    const errors: { [key in TField]?: string } = {};
    for (const [field, value] of Object.entries(this.fields)) {
      errors[field as TField] = Array.isArray(value) ? value[0] : value;
    }
    return errors;
  }
}

export function createErrorFromApiResponse(response: Partial<ApiResponse>): Error {
  switch (response.reason) {
    case ReasonEnum.InvalidField:
      return new ValidationError(response.message || 'Validation error', response.data ?? {});
    default:
      return new Error(response.message || 'Server error');
  }
}
