import { useEffect, useState } from 'react';
import parsePhoneNumber from 'libphonenumber-js/max';

// ip regexp based on ip-regex lib: https://github.com/sindresorhus/ip-regex/blob/master/index.js
// other regexp based on dist: https://gist.github.com/dperini/729294
export function validateUrlHost(url: string): boolean {
  const protocol = '(?:(?:(?:https?|http):)?\\/\\/)';
  const auth = '(?:\\S+(?::\\S*)?@)?';
  const ip = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
  const host = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)';
  const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
  const tld = `(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?`;
  const port = '(?::\\d{2,5})?';
  const path = '(?:[/?#][^\\s"]*)?';
  const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${ip}|${host}${domain}${tld})${port}${path}`;

  const pattern = new RegExp(regex, 'ig');

  return pattern.test(url);
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  return parsePhoneNumber(phoneNumber)?.isValid() ?? false;
}

export async function validateHttpsCertificate(url: string): Promise<boolean> {
  if (!validateUrlHost(url) || !url.startsWith('https')) {
    return false;
  }

  let result = false;
  // try send request and set false if it has network error, dont check responce status
  await fetch(url, { credentials: 'omit', mode: 'no-cors' })
    .then(() => result = true)
    .catch(() => result = false);

  return result;
}

export function validateEmail(email: string): boolean {
  const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  return pattern.test(email);
}


/**
 * PASSWORD VALIDATION
 */
const MINIMUM_LENGTH = 8;

interface ValidationErrors {
  lowercase: string;
  uppercase: string;
  numeric: string;
  specialCharacter: string;
  length: string;
}

export function validateUserPassword(password: string): ValidationErrors {
  const lowercase = new RegExp('^(?=.*[a-z])');
  const uppercase = new RegExp('^(?=.*[A-Z])');
  const numeric = new RegExp('^(?=.*[0-9])');
  const specialCharacter = new RegExp('^(?=.*[!@#$%^&*])');
  const length = new RegExp(`^(?=.{${MINIMUM_LENGTH},})`);

  return {
    lowercase: lowercase.test(password) ? '' : 'Password must contain at least 1 lowercase alphabetical character',
    uppercase: uppercase.test(password) ? '' : 'Password must contain at least 1 uppercase alphabetical character',
    numeric: numeric.test(password) ? '' : 'Password must contain at least 1 numeric character',
    specialCharacter: specialCharacter.test(password) ? '' : 'Password must contain at least one special character',
    length: length.test(password) ? '' : `Password must be ${MINIMUM_LENGTH} characters or longer`,
  };
}

export const usePasswordValidation = (newPassword: string, repeatedPassword: string) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [errorMatchPassword, setErrorMatchPassword] = useState<string>();

  useEffect(() => {
    if (!newPassword) {
      return;
    }

    const validation = validateUserPassword(newPassword);
    setValidationErrors(Object.values(validation).filter(error => !!error));

    if (newPassword !== repeatedPassword) {
      setErrorMatchPassword('Passwords do not match');
    } else {
      setErrorMatchPassword('');
    }
  }, [newPassword, repeatedPassword]);

  return { errorMatchPassword, validationErrors };
};
