export enum CallerHistoryParamsGroupBy {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year'
}

export interface CallerHistoryParams {
  ownerId?: number;
  projectIds?: number[];
  zoneIds?: number[];
  groupIds?: number[];
  templateSuiteId?: number[];
  templateIds?: number[];
  dateFrom: string;
  dateTo: string;
  groupBy?: CallerHistoryParamsGroupBy;
}