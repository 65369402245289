import { createStyles, makeStyles } from 'styles/utils';

const useStyles = makeStyles(() =>
  createStyles({
    /**
     * Это необходимо только для случаев, когда контент таба больше чем высота блока табов,
     * и когда кнопки табов занимают две строки и более (например при малых разрешениях экрана).
     *
     * Иначе будет отображаться только первая строка кнопки таба
     */
    root: {
      minHeight: 'auto',
      overflow: 'visible',
    },
  }),
);

export default useStyles;
