import { GetRoutersParams, Router } from 'models/caller';

export const CALLER_ROUTER_DATA_LOAD = 'CALLER_ROUTER_DATA_LOAD';
export const CALLER_ROUTER_DATA_FETCH_START = 'CALLER_ROUTER_DATA_FETCH_START';
export const CALLER_ROUTER_DATA_FETCH_STOP = 'CALLER_ROUTER_DATA_FETCH_STOP';
export const CALLER_ROUTER_DATA_CHANGE = 'CALLER_ROUTER_DATA_CHANGE';

export interface LoadRouterData {
  readonly type: typeof CALLER_ROUTER_DATA_LOAD;
  params: GetRoutersParams;
}

export interface LoadRouterDataStart {
  readonly type: typeof CALLER_ROUTER_DATA_FETCH_START;
}

export interface LoadRouterDataStop {
  readonly type: typeof CALLER_ROUTER_DATA_FETCH_STOP;
}

export interface ChangeRouterData {
  readonly type: typeof CALLER_ROUTER_DATA_CHANGE;
  params: GetRoutersParams;
  data: Router[];
}

export type Action =
  LoadRouterData | LoadRouterDataStart | LoadRouterDataStop | ChangeRouterData

export const LoadRouterData: (params: GetRoutersParams) => LoadRouterData = (params: GetRoutersParams) => ({
  type: CALLER_ROUTER_DATA_LOAD, params
});

