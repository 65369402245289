import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 30,
    },
    cardRoot: {
      height: '100%'
    }
  }),
);

export default useStyles;
