import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 1.5, 2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      flex: 1,
    },
  }),
);

export default useStyles;
