import React from 'react';
import { RedirectProps } from 'react-router-dom';
import { ApiKey } from 'models/user-management';
import { useFormActionLoader } from 'hooks/form';
import { DeleteApiKey } from 'actions/user-management';
// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  apiKey: ApiKey;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSucces?: () => void | RedirectProps;
}

export const ApiKeyDeleteDialog = (props: Props): JSX.Element => {
  const { apiKey, isOpen, onClose, onCloseEnd, onSucces } = props;
  const { doAction } = useFormActionLoader();
  const handleDelete = () => doAction({
    action: DeleteApiKey(apiKey),
    onSuccess: onSucces,
    onFinally: !onSucces ? onClose : undefined,
  });

  return (
    <EnsureDialog
      name="delete-api-key"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
    >
      The API Auth Token <b>{ apiKey.name }</b> will be deleted
    </EnsureDialog>
  );
};
