import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useEffectOnce } from 'react-use';
import { isNil } from 'utils/models';
import { Permissions } from 'models/user-management';
import { fetchPermissions } from 'actions/user-management/permissions';
import { sortStrings } from 'helpers';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & { filter?: (permission: Permissions) => boolean }

export const UserPermissionSelectControl = (props: Props): JSX.Element => {
  const { isLoading, permissions } = useSelector((state: RootState) => ({
    isLoading: state.um.permissionsFetching,
    permissions: Object
      .values(state.um.permissionsById)
      .flatMap(p => isNil(p) ? [] : [p])
  }), shallowEqual);

  const dispatch = useDispatch();
  useEffectOnce(() => {
    if (!permissions.length) {
      dispatch(fetchPermissions());
    }
  });

  const filterCallback = props.filter ?? (() => (true));
  const options: Option<number>[] =
    permissions
      .filter(filterCallback)
      .map(perm => ({
        value: perm.id,
        label: perm.name
      }))
      .sort((a, b) => sortStrings(
        a?.label,
        b?.label
      ));

  return (
    <SelectControl
      name="permissionId"
      label="Permission"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ isLoading }
      checkSelected={ permissions.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
