import clsx from 'clsx';
import { useLoraWANStationsDictionarySelector } from 'hooks/station';
import { StationLocation, ResponsiblePerson } from 'models/base-station';
import { sortLocation } from './utils';

// components
import { Typography, Box } from '@material-ui/core';
import { BaseStationLink } from 'components/Links';

// styles
import { useStyles, MARGIN } from './TableComponentsStyle';


interface Props {
  locations?: StationLocation[];
  expanded?: ResponsiblePerson['id'];
  rpId: ResponsiblePerson['id'];
}

const StationId = ({
  stationId,
  stationLabel
}: { stationId?: number, stationLabel?: string | number }): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      {
        stationId
          ? <BaseStationLink stationId={ stationId } label={ stationLabel ? `${ stationLabel }` : '-' } />
          : <Typography className={ clsx(classes.blueText, classes.blueTextDisabled) }>No station</Typography>
      }
    </>
  );
};

export const Station = ({ locations, expanded, rpId }: Props): JSX.Element => {

  const { stations: loraStations } = useLoraWANStationsDictionarySelector();
  const sortedLocation = sortLocation(locations || []).map(l => {
    const lorawanStation = loraStations.find(s => s.id === l.station_id);
    if (lorawanStation) {
      return { ...l, station_label: lorawanStation.eui };
    }
    return { ...l, station_label: l.station_id };
  });

  return (
    <>
      <StationId
        stationLabel={ sortedLocation[0]?.station_label || undefined }
        stationId={ sortedLocation[0]?.station_id || undefined }
      />
      <Box overflow="hidden" height={ expanded === rpId ? 'auto' : '0px' } >
        { sortedLocation.slice(1).map(station => (
          <Box key={ station.id } my={ MARGIN }>
            <StationId
              stationLabel={ station.station_label || undefined }
              stationId={ station.station_id || undefined }
            />
          </Box>
        )) }
      </Box>

    </>
  );
};
