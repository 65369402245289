import moment from 'moment';
import { Serie } from '@nivo/line';

import { Template } from 'models/caller';

export interface MonitoringSerie extends Serie {
  label?: string;
  total: number;
  color: string;
}

export function formatTemplate(templateById: Record<number, Template>, templateId: number): string {
  return templateById[templateId as number]?.name ?? `#${ templateId }`;
}

interface LastDateRange {
  type: 'last';
  unit: 'days';
  count: number;
}

interface ExactDateRange {
  type: 'exact';
  dateFrom: Date;
  dateTo: Date;
}

export type MonitoringDateRange = LastDateRange | ExactDateRange;

interface DateRangeParams {
  dateFrom: string;
  dateTo: string;
}

export const getExactDateRangeParams = (dateRange: MonitoringDateRange): DateRangeParams => {
  if (dateRange.type === 'exact') {
    return {
      dateFrom: dateRange.dateFrom.toJSON(),
      dateTo: dateRange.dateTo.toJSON(),
    };
  }

  const dateTo = moment();
  const dateFrom = dateTo.clone().subtract(dateRange.count, dateRange.unit);

  return {
    dateFrom: dateFrom.toJSON(),
    dateTo: dateTo.toJSON(),
  };
};

export interface Filters {
  dateRange: MonitoringDateRange;
  templateIds: 'all' | number[];
}

export const getRestParams = (filters: Filters, templateIds: number[]) => {
  return {
    templateIds: filters.templateIds === 'all'
      ? templateIds
      : filters.templateIds,
  };
};

export const mapFiltersToParams = (filters: Filters, templateIds: number[]) => {
  return {
    ...getExactDateRangeParams(filters.dateRange),
    ...getRestParams(filters, templateIds),
  };
};