import { API } from 'aws-amplify';

import { errorHandler, formatQuery } from 'clients/utils';
import { staticEnv } from 'env';
import { ApiGConfig, ApiResponse } from 'models';
import { SubscriptionType } from 'models/user-management/projectHealthSubscriptions';

export const config: ApiGConfig = {
  name: 'subscriptions',
  endpoint: staticEnv.IS_PRODUCTION ? 'https://subnotif.prod.api.nwave.io' : 'https://subnotif.dev.api.nwave.io',
};

const urls = {
  subscriptions: '/subscriptions',
  subscriptionsById: (id: number) => `/subscriptions/${ id }`,
};


export interface SubscriptionsQueryParams {
  projectId?: number;
  userId?: number;
  ownerId?: number;
  period?: SubscriptionType
  subscriptionId?: number;
}

export interface RawUserSubscriptionType {
  project_id: number;
  owner_id: number;
  user_id: number;
  period: SubscriptionType;
  id: number;
}

export const fetchUsersProjectsSubscriptions = (params: SubscriptionsQueryParams): Promise<ApiResponse<RawUserSubscriptionType[]>> => {
  const body = {
    queryStringParameters: formatQuery({
      project_id: params.projectId,
      user_id: params.userId,
    }),
  };

  return API
    .get(config.name, urls.subscriptions, body)
    .catch(errorHandler);
};

export const createUserProjectSubscription = (params: SubscriptionsQueryParams): Promise<ApiResponse> => {
  const parameters = {
    body: {
      project_id: params.projectId,
      owner_id: params.ownerId,
      user_id: params.userId,
      period: params.period
    },
  };

  return API
    .post(config.name, urls.subscriptions, parameters)
    .catch(errorHandler);
};

export const updateUserProjectSubscription = (params: SubscriptionsQueryParams): Promise<ApiResponse> => {
  const parameters = {
    body: {
      period: params.period
    },
  };

  return API
    .put(config.name, urls.subscriptionsById(Number(params.subscriptionId)), parameters)
    .catch(errorHandler);
};

export const deleteUserProjectSubscription = (params: SubscriptionsQueryParams): Promise<ApiResponse> => {

  return API
    .del(config.name, urls.subscriptionsById(Number(params.subscriptionId)), {})
    .catch(errorHandler);
};
