import React from 'react';

// components
import { Dialog as MaterialUIDialog } from '@material-ui/core';
import { DialogProps as MaterialUIDialogProps } from '@material-ui/core/Dialog';

interface TriggerRendererOptions {
  open: () => void;
}

export interface DialogProps extends MaterialUIDialogProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;

  renderTrigger?: (options: TriggerRendererOptions) => React.ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({
  onOpen,
  renderTrigger,
  ...restProps
}) => {
  return (
    <>
      {renderTrigger?.({ open: onOpen })}

      <MaterialUIDialog {...restProps} />
    </>
  );
};
