import moment from 'moment';

export interface DateParams {
  dateFrom: Date;
  dateTo: Date;
}

export interface LastDateRange {
  type: 'last';
  unit: 'days';
  count: number;
}

export interface ExactDateRange extends DateParams {
  type: 'exact';
}

export type MonitoringDateRange = LastDateRange | ExactDateRange;

export interface StationMonitoringFilters {
  stationId: number;
  dateRange: MonitoringDateRange;
}

export const INITIAL_DATE_RANGE: LastDateRange = {
  type: 'last',
  unit: 'days',
  count: 7,
};

export const INITIAL_DATE_RANGE_LABEL = 'Last 7 days';

export function getDateParams(dateRange: MonitoringDateRange): DateParams {
  if (dateRange.type === 'exact') {
    return {
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
    };
  }

  const dateTo = moment().milliseconds(0);
  const dateFrom = dateTo.clone().subtract(dateRange.count, dateRange.unit);

  return {
    dateFrom: dateFrom.toDate(),
    dateTo: dateTo.toDate(),
  };
}
