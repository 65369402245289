import { Level, LevelUpdateFields } from 'models/device-management';
import { LevelsFilters } from 'models/device-management/filters';

export const FETCH_LEVELS = 'FETCH_LEVELS';
export const FETCH_LEVELS_FAILURE = 'FETCH_LEVELS_FAILURE';
export const FETCH_LEVELS_SUCCESS = 'FETCH_LEVELS_SUCCESS';

export const CREATE_LEVEL = 'CREATE_LEVEL';
export const CREATE_LEVEL_FAILURE = 'CREATE_LEVEL_FAILURE';
export const CREATE_LEVEL_SUCCESS = 'CREATE_LEVEL_SUCCESS';

export const UPDATE_LEVEL = 'UPDATE_LEVEL';
export const UPDATE_LEVEL_FAILURE = 'UPDATE_LEVEL_FAILURE';
export const UPDATE_LEVEL_SUCCESS = 'UPDATE_LEVEL_SUCCESS';

export const DELETE_LEVEL = 'DELETE_LEVEL';
export const DELETE_LEVEL_FAILURE = 'DELETE_LEVEL_FAILURE';
export const DELETE_LEVEL_SUCCESS = 'DELETE_LEVEL_SUCCESS';

export const CHANGE_LEVEL = 'CHANGE_LEVEL';

export interface FetchLevels {
  readonly type: typeof FETCH_LEVELS;
  readonly params: LevelsFilters;
}

export interface FetchLevelsFailure {
  readonly type: typeof FETCH_LEVELS_FAILURE;
  readonly params: LevelsFilters;
  readonly error: string;
}

export interface FetchLevelsSuccess {
  readonly type: typeof FETCH_LEVELS_SUCCESS;
  readonly params: LevelsFilters;
  readonly levels: Level[];
}

export interface CreateLevel {
  readonly type: typeof CREATE_LEVEL;
  readonly level: LevelUpdateFields;
}

export interface CreateLevelFailure {
  readonly type: typeof CREATE_LEVEL_FAILURE;
  readonly error: string;
}

export interface CreateLevelSuccess {
  readonly type: typeof CREATE_LEVEL_SUCCESS;
  readonly level: Level;
}

export interface UpdateLevel {
  readonly type: typeof UPDATE_LEVEL;
  readonly level: LevelUpdateFields;
  readonly levelId: number;
}

export interface UpdateLevelFailure {
  readonly type: typeof UPDATE_LEVEL_FAILURE;
  readonly error: string;
}

export interface UpdateLevelSuccess {
  readonly type: typeof UPDATE_LEVEL_SUCCESS;
  readonly level: Level;
}

export interface DeleteLevel {
  readonly type: typeof DELETE_LEVEL;
  readonly level: Level;
}

export interface DeleteLevelFailure {
  readonly type: typeof DELETE_LEVEL_FAILURE;
  readonly level: Level;
  readonly error: string;
}

export interface DeleteLevelSuccess {
  readonly type: typeof DELETE_LEVEL_SUCCESS;
  readonly level: Level;
}

export interface ChangeLevel {
  readonly type: typeof CHANGE_LEVEL;
  readonly level: Level;
  readonly options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export const DoFetchLevels = (params: LevelsFilters): FetchLevels => ({
  type: FETCH_LEVELS,
  params,
});

export const DoFetchLevelsSuccess = (params: LevelsFilters, levels: Level[]): FetchLevelsSuccess => ({
  type: FETCH_LEVELS_SUCCESS,
  params,
  levels,
});

export const DoFetchLevelsFailure = (params: LevelsFilters, error: string): FetchLevelsFailure => ({
  type: FETCH_LEVELS_FAILURE,
  params,
  error,
});

export const DoCreateLevel = (level: LevelUpdateFields): CreateLevel => ({
  type: CREATE_LEVEL,
  level,
});

export const DoCreateLevelFailure = (error: string): CreateLevelFailure => ({
  type: CREATE_LEVEL_FAILURE,
  error,
});

export const DoCreateLevelSuccess = (level: Level): CreateLevelSuccess => ({
  type: CREATE_LEVEL_SUCCESS,
  level,
});

export const DoUpdateLevel = (levelId: number, level: LevelUpdateFields): UpdateLevel => ({
  type: UPDATE_LEVEL,
  level,
  levelId,
});

export const DoUpdateLevelFailure = (error: string): UpdateLevelFailure => ({
  type: UPDATE_LEVEL_FAILURE,
  error,
});

export const DoUpdateLevelSuccess = (level: Level): UpdateLevelSuccess => ({
  type: UPDATE_LEVEL_SUCCESS,
  level,
});

export const DoDeleteLevel = (level: Level): DeleteLevel => ({
  type: DELETE_LEVEL,
  level,
});

export const DoDeleteLevelFailure = (level: Level, error: string): DeleteLevelFailure => ({
  type: DELETE_LEVEL_FAILURE,
  level,
  error,
});

export const DoDeleteLevelSuccess = (level: Level): DeleteLevelSuccess => ({
  type: DELETE_LEVEL_SUCCESS,
  level,
});

export const DoChangeLevel = (level: Level, options: ChangeLevel['options']): ChangeLevel => ({
  type: CHANGE_LEVEL,
  level,
  options,
});

export type Action = (
  | FetchLevels
  | FetchLevelsSuccess
  | FetchLevelsFailure
  | CreateLevel
  | CreateLevelFailure
  | CreateLevelSuccess
  | UpdateLevel
  | UpdateLevelFailure
  | UpdateLevelSuccess
  | DeleteLevel
  | DeleteLevelFailure
  | DeleteLevelSuccess
  | ChangeLevel
);
