import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Device, Position } from 'models/device-management';
import { DeviceStatesV2 } from 'models/device-monitoring';
import { getMarkerIcon, getMarkerIconDot } from 'utils/map';

import { findPositionsByLocation } from 'actions/device-management/positions';
import { DoGetDevicesStatesFilters } from 'actions/devices-states';
import { findPositionedDevices } from 'actions/device-management/devices';
import { useDeepCompareEffect } from 'react-use';

import { RootState } from 'reducers';
import { initDevicesStatesFilters } from 'models';

// components
import { Marker } from 'react-google-maps';
import { CommonMap } from 'components/Map';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';
import { MapMarker as NeighborMarker } from './MapMarker';
import { InfoWindowComponent as InfoWindow } from './InfoWindow';

// styles
import useStyles from 'pages/DevicePositionCouple/styles';

interface Props {
  position: Position;
  device?: Device;
  mainMarkerPosition?: {
    lat?: number;
    lng?: number;
  };
  handlePosition: (mainMarkerPosition: { lat: number; lng: number }) => void;
}

export const Map = ({ position, device, mainMarkerPosition, handlePosition }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const aroundPositions = useSelector((state: RootState) => state.deviceManagement.positions.positionsByLocation.data);
  const aroundDevices = useSelector((state: RootState) => state.deviceManagement.devices.data.filter(d => {
    const aroundPositionsIds = aroundPositions.map(p => p.id);
    return d.position_id && aroundPositionsIds.includes(d.position_id);
  }));
  const { data: deviceStates, isFetching: deviceStatesFetching } = useSelector((state: RootState) => state.deviceStates);

  const [select, handleSelect] = useState<number | undefined>(undefined);
  const [isOpen, handleOpen] = useState<boolean>(true);

  const isDamage = device ? Boolean(device.damaged_status) : false;
  const deviceStateByMainPosition = deviceStates.find((state: DeviceStatesV2) => state.device_id.toLowerCase() === device?.device_id.toLowerCase());
  const isPositioned = !!device?.position_id;
  const icon = getMarkerIcon(isDamage, isPositioned, deviceStateByMainPosition);
  const dotIcon = getMarkerIconDot(isDamage, isPositioned, deviceStateByMainPosition);

  useEffect(() => {
    position && dispatch(findPositionsByLocation({
      lat: position.lat,
      lon: position.lon,
      radius: 100,
    }));
  }, [position, dispatch]);

  useEffect(() => {
    position && dispatch(findPositionedDevices({
      lat: position.lat,
      lon: position.lon,
      radius: 100,
      force: false
    }));
  }, [position, dispatch]);

  useDeepCompareEffect(() => {
    const aroundGroups = new Set<number>(aroundDevices.map(device => device.group_id ?? 0));
    aroundGroups.size && dispatch(DoGetDevicesStatesFilters({
      limit: 2000,
      offset: 0,
      devices: [],
      zones: [],
      groups: [...aroundGroups],
      incidentTypes: [],
      severity: initDevicesStatesFilters.severity,
    }));
  }, [dispatch, aroundDevices, position]);

  const isLoading = deviceStates.length === 0 && deviceStatesFetching;

  return (
    <Box className={ classes.mapContainer }>
      { isLoading ? <Skeleton variant="rect" height="100%" /> :
        <CommonMap
          defaultZoom={ 18 }
          defaultCenter={ {
            lat: Number(mainMarkerPosition?.lat) || position.lat,
            lng: Number(mainMarkerPosition?.lng) || position.lon
          } }
        >
          <Marker
            position={ {
              lat: mainMarkerPosition?.lat ? parseFloat(String(mainMarkerPosition.lat)) : position.lat,
              lng: mainMarkerPosition?.lng ? parseFloat(String(mainMarkerPosition.lng)) : position.lon
            } }
            icon={ icon }
            onClick={ () => handleOpen(true) }
            defaultZIndex={ 200 }
            draggable
            onDragEnd={ (e) => handlePosition({ lat: e.latLng.lat(), lng: e.latLng.lng() }) }
          >
            { isOpen && (
              <InfoWindow
                onCloseClick={ () => handleOpen(false) }
                positionId={ position.id }
                networkId={ position.network_id }
                icon={ dotIcon }
                deviceId={ device?.device_id }
              />
            ) }
          </Marker>
          { Boolean(aroundDevices.length) && aroundPositions.map(neighborPlace => {
            return (
              <div key={ neighborPlace?.id }>
                <NeighborMarker
                  select={ select }
                  handleSelect={ handleSelect }
                  position={ neighborPlace }
                  aroundDevices={ aroundDevices }
                  deviceStates={ deviceStates }
                />
              </div>
            );
          }) }
        </CommonMap>
      }
    </Box>
  );
};
