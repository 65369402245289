import { uniq } from 'lodash';
import { Device as ProvisioningDevice, DeviceProvisioningState, initProvisioningBoxesFilters } from 'models/provisioning';
import { useProvisioningBoxes, useProvisioningReceivedBoxes } from 'hooks/provisioning';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import DeviceLink from 'components/Links/DeviceLink';
import { OwnerName } from 'components';
import ShipmentName from './Controls/ShipmentName';

// styles
import { getMuiTheme } from 'styles/themes';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';


interface Props {
  data: ProvisioningDevice[];
  isFetching: boolean;
  total: number;
  isAdmin: boolean;
}

const ProvisioningDevicesTable = (props: Props): JSX.Element => {
  const { isFetching, data, total, isAdmin } = props;

  const boxes = useProvisioningBoxes({
    params: {
      ...initProvisioningBoxesFilters,
      box_id: uniq(data.map(d => d.box_id ?? 0)),
    },
    enabled: data.length > 0
  });
  const receivedBoxes = useProvisioningReceivedBoxes({
    filter: { boxes: uniq(data.map(d => d.box_id ?? 0)) },
    enabled: data.length > 0
  });

  const columns: MUIDataTableColumn[] = [
    {
      name: 'device',
      label: 'Device ID',
      options: {
        customBodyRenderLite: dataIndex => <DeviceLink deviceId={ data[dataIndex].device_id }/>
      },
    },
    {
      name: 'box',
      label: 'Box',
      options: {
        customBodyRenderLite: dataIndex => data[dataIndex].box_id ?? '—'
      },
    },
    {
      name: 'shipment',
      label: 'Shipment',
      options: {
        customBodyRenderLite: dataIndex => {
          const device = data[dataIndex];
          const box = boxes.boxes.find(b => b.id === device.box_id);
          const receivedBox = receivedBoxes.boxes.find(b => b.box_id === device.box_id);
          const shipmentId = box?.shipment_id ?? receivedBox?.shipment_id;
          return shipmentId ? <ShipmentName id={ shipmentId }/> : '—';
        },
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRenderLite: dataIndex => DeviceProvisioningState[data[dataIndex].status],
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ data[dataIndex].owner_id }/>,
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    count: total,
    customFooter: (count) => {
      return (
        <PaginationFooter
          count={ count }
        />
      );
    }
  };

  return (
    <MuiThemeProvider theme={ getMuiTheme() }>
      <TableLoadingLayout isLoading={ isFetching } >
        <MUIDataTable
          title={ null }
          data={ data }
          columns={ columns }
          options={ options }
        />
      </TableLoadingLayout>
    </MuiThemeProvider>
  );
};

export default ProvisioningDevicesTable;
