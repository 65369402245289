import { colors } from 'styles/themes/outer';

import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lightBlue: {
      color: colors.mainPrimary,
    },
    error: {
      color: colors.errordark
    },
    edit: {
      color: colors.mainPrimary,
      fontWeight: 500
    },
    remove: {
      color: colors.errordark,
      fontWeight: 500
    },
    registered: {
      width: 50,
      height: 20,
      borderRadius: 4,
      border: `1px solid ${colors.mainPrimary}`,
      textAlign: 'center',
    },
    registeredText: {
      display: 'inline-block',
      color: colors.mainPrimary,
      fontSize: 10,
    },
    inputContainer: {
      position: 'relative'
    },
    role: {
      marginRight: 10,
      whiteSpace: 'pre-wrap',
    },
    roleSelect: {
      width: 250
    },
    iconContainer: {
      cursor: 'pointer'
    },
    paper: {
      overflowX: 'visible',
      overflowY: 'visible'
    },
    fieldRegistered: {
      width: '50px',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 10,
      color: colors.mainPrimary,
      padding: theme.spacing(0.4),
      borderRadius: 4,
      border: `1px solid ${colors.mainPrimary}`,
    },
    buttonText: {
      fontSize: 14
    },
    form: {
      display: 'flex',
      minHeight: '100%',
    },
    flexGrow: {
      flex: '1 0 auto'
    },
    footer: {
      flex: '0 0 auto',
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    counterButton: {
      position: 'absolute',
      right: SHIFT,
    },
    // TODO: make it by theme
    blueText: {
      color: colors.mainPrimary,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      cursor: 'pointer'
    },
    blueTextDisabled: {
      opacity: .5,
      cursor: 'auto'
    },
    margin: {
      marginTop: -theme.spacing(2),
      marginBottom: -theme.spacing(2),
    },
    popover: {
      width: '100%',
      background: '#fff',
      padding: theme.spacing(1),
      maxHeight: '200px',
      overflowY: 'scroll',
    }
  })
);

export const SHIFT = 10;
export const MARGIN = 3;
