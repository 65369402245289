import { useQuery } from 'react-query';
import { fetchGroups } from 'clients/device-management';
import { getResponseWithTotal } from 'utils/clients';
import { QueryType, Tree } from './types';
import { findLevelNode, getGroupId, useErrorNotifier } from './utils';

export const useGroupResult = (zoneIds: number[], tree: Tree, queryHash: string): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.group],
    queryHash: `deviceManagement/groups/${ queryHash }`,
    queryFn: async () => getResponseWithTotal(await fetchGroups({
      zones: zoneIds,
      limit: 9999,
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notify(`Error while fetching groups: ${ (e as Error).message }`),
    enabled: tree.loaded.zone && zoneIds.length > 0
  });

  if (result.isSuccess) {
    tree.loaded.group = true;
    for (const group of result.data?.data ?? []) {
      const levelNode = findLevelNode(tree, group.zone_id, Number(group.level_id), true);
      if (!levelNode) {
        continue;
      }

      levelNode.children.total++;
      const groupId = getGroupId(group.id);
      levelNode.children.items[groupId] = {
        id: groupId,
        parent: levelNode,
        type: 'group',
        group: group,
        children: {
          total: 0,
          items: {},
        },
        devices: { total: 0, data: {} },
        deviceStates: { total: 0, data: {} },
        deviceConnects: { total: 0, data: {} },
      };
    }
  }

  return tree;
};