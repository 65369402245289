import React from 'react';
import { useAuthUserSelector } from 'hooks';
import { useForm, useFormEditActions, useFormActionLoader } from 'hooks/form';
import { canViewOwner } from 'utils/permissions';
import { ResponsiblePerson } from 'models/base-station';
// components
import { CommonDialog } from 'components/Dialogs';
import { UpdateStationResponsiblePerson } from 'actions/base-station';
import { ResponsiblePersonFormField, StationResponsiblePersonForm } from 'components/BaseStation';

interface Props {
  person: ResponsiblePerson;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const EditDialog = (props: Props): JSX.Element => {
  const { person, isOpen, onClose, onCloseEnd } = props;

  const user = useAuthUserSelector();
  const form = useForm<ResponsiblePerson, ResponsiblePersonFormField>({
    initialState: person,
    showFields: [
      ...(canViewOwner(user) ? [ResponsiblePersonFormField.ownerId] : []),
      ResponsiblePersonFormField.firstName,
      ResponsiblePersonFormField.lastName,
      ResponsiblePersonFormField.role,
      ResponsiblePersonFormField.email,
      ResponsiblePersonFormField.notify,
    ]
  });
  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    canSave: true,
    btnCancel: 'Reset',
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: UpdateStationResponsiblePerson({
          ...form.state,
          rp_id: form.state.id
        }),
        onError: form.catchError,
        onSuccess: onClose,
      });
    },
    onCancel: form.reset,
  });

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title="Edit person"
      content={ <StationResponsiblePersonForm { ...form } /> }
      actions={ actions }
    />
  );
};

