import { IncidentReasonEnum } from 'models/device-monitoring';

export enum FormField {
  reason = 'reason',
  comment = 'comment',
}

export interface DamageState {
  reason: IncidentReasonEnum;
  comment: string;
}