import React from 'react';
import { GroupNode, QueryType, Tree } from 'hooks/device-management/useTree/types';
import { useOnSplacePopup } from 'hooks/splace-messages';
// components
import Link from '@material-ui/core/Link';
import { PlusButton } from 'components/Buttons';

interface Props {
  tree: Tree;
  node: GroupNode;
}

export const AddPositionAction = ({ tree, node }: Props): JSX.Element => {
  const splacePopup = useOnSplacePopup({
    query: {
      zoneId: node.group.zone_id,
      levelId: node.group.level_id,
      groupId: node.group.id,
    },
    onMessage: () => {
      tree.reload(QueryType.position);
      tree.reload(QueryType.device);
    }
  });

  return (
    <>
      <Link
        data-testid="splace-link"
        target="popup"
        href={ splacePopup.href }
        onClick={ splacePopup.open }
      >
        <PlusButton
          label="Add Position"
          onClick={ splacePopup.open }
        />
      </Link>
    </>
  );
};