// components
import { Grid } from '@material-ui/core';

// styles
import { styled } from 'styles/utils';

export const Column = styled(Grid)({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
});

export const ActionsColumn = styled(Column)({
  justifyContent: 'flex-end',
});

export const StyledForm = styled('form')({
  flexGrow: 1,
});

export const StyledProjects = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(-0.4)}px 0`,
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    margin: theme.spacing(0.5),
  },
}));