import { fetchGroupsTotalDevices } from '../../../api';
import { getGroupNodes } from '../../../node/utils';
import { GroupNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillGroupTotalDevices({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, GroupNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getGroupNodes(getNodes(nodes)),
    getQuery: node => node.totalDevices,
    fetchDataByNode: fetchGroupsTotalDevices,
  });
}
