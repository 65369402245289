import { useQuery } from 'react-query';
import { fetchLevels } from 'clients/device-management';
import { QueryType, Tree } from './types';
import { findZoneNode, getLevelId, useErrorNotifier } from './utils';
import { getResponse } from 'utils/clients';

export const useLevelResult = (zoneIds: number[], tree: Tree, queryHash: string): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.level],
    queryHash: `deviceManagement/level/${ queryHash }`,
    queryFn: async () => getResponse(await fetchLevels({
      zones: zoneIds,
      limit: 9999,
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notify(`Error while fetching levels: ${ (e as Error).message }`),
    enabled: tree.loaded.zone && zoneIds.length > 0
  });

  if (result.isSuccess) {
    tree.loaded.level = true;
    for (const level of result.data?.data ?? []) {
      const zoneNode = findZoneNode(tree, level.zone_id);
      if (!zoneNode) {
        continue;
      }

      zoneNode.children.total++;
      const levelId = getLevelId(level.id);
      zoneNode.children.items[levelId] = {
        id: levelId,
        parent: zoneNode,
        type: 'level',
        level: level,
        children: {
          total: 0,
          items: {},
        }
      };
    }
  }

  return tree;
};