import { defaults, difference } from 'lodash';
import { useDispatch } from 'react-redux';

import { CreateManyTemplates } from 'actions/caller';
import { NotifyError } from 'actions/notifier';
import { TemplatePresetFormField, TemplatePresetFormState } from 'components/Caller';
import { useForm, useFormActionLoader } from 'hooks';
import { Template, TemplateMethod, TemplatePreset } from 'models/caller';
import { FormState } from 'types/form';

export type PresetFormState = TemplatePresetFormState;

interface PresetFormHookOptions {
  template?: Template;
  templateSuiteId: number;
  presetById?: Record<number, TemplatePreset | undefined>;
  hiddenFields?: TemplatePresetFormField[];
  onSuccess?: () => void;
}

export type PresetForm = FormState<PresetFormState, TemplatePresetFormField>;

interface PresetFormHookResult {
  form: PresetForm;
  submit: () => void;
}

export const usePresetForm = ({
  template,
  templateSuiteId,
  presetById,
  hiddenFields = [],
  onSuccess,
}: PresetFormHookOptions): PresetFormHookResult => {
  const dispatch = useDispatch();

  const initialState: PresetFormState = defaults({}, template, {
    template_suite_id: templateSuiteId,
    name: '',
    url: '',
    is_active: true,
    headers: {},
    preset_ids: [],
  });

  const form = useForm<PresetFormState, TemplatePresetFormField>({
    initialState,
    showFields: difference([
      TemplatePresetFormField.url,
      TemplatePresetFormField.templateSuiteId,
      TemplatePresetFormField.isActive,
      TemplatePresetFormField.headers,
      TemplatePresetFormField.presetIds,
    ], hiddenFields),
  });

  const { doAction } = useFormActionLoader();

  const submit = () => {
    if (!form.validate()) {
      return false;
    }

    if (!form.state.preset_ids.length) {
      dispatch(NotifyError(`Error while creating a template: Preset hasn't been chosen`));
      return false;
    }

    const presets = form.state.preset_ids.map(presetId => presetById?.[presetId]);

    if (presets.some(preset => !preset)) {
      dispatch(NotifyError(`Error while creating a template: Presets haven't been loaded`));
      return false;
    }

    const newTemplates: Template[] = (presets as TemplatePreset[]).map(preset => ({
      message_type: preset.message_type as string,
      method: preset.method as TemplateMethod,
      body: preset.body,
      template_id: 0,
      name: preset.name as string,
      template_suite_id: form.state.template_suite_id,
      url: form.state.url,
      is_active: form.state.is_active,
      headers: form.state.headers,
    }));

    onSuccess?.();

    doAction({
      action: CreateManyTemplates(newTemplates),
      onSuccess,
      onError: form.catchError,
    });
  };

  return { form, submit };
};
