import { createStyles, makeStyles, Theme } from 'styles/utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    field: {
      margin: theme.spacing(1),
      width: 'auto',
      flex: 1,
    },
    field_type_switch: {
      justifyContent: 'space-between',
    },
    fields: {
      flexGrow: 1
    },
    container: {
      padding: theme.spacing(2)
    },
  })
);

export default useStyles;
