import { keyBy } from 'lodash';
import React from 'react';

import { useCallerTemplatePresets } from 'hooks/caller';
import { Template } from 'models/caller';

import { useManualForm } from './useManualForm';
import { usePresetForm } from './usePresetForm';
import { syncManualFormWithPresetForm, syncPresetFormWithManualForm } from './utils';

// components
import { Box, Button, Tab, Tabs } from '@material-ui/core';

import {
  CallerTemplateForm,
  CallerTemplatePresetForm,
  TemplateFormField,
  TemplatePresetFormField,
} from 'components/Caller';
import { CommonDialog, useCommonDialogTabsStyles, EnsureDialog } from 'components/Dialogs';

// styles
import { useTemplateDialogStyles } from './styles';
import { useDialog } from 'hooks';

enum FormTab {
  PRESET = 'preset',
  MANUAL = 'manual',
}

interface CreateTemplateDialogText {
  title?: string;
  submitButton?: string;
}

interface CreateTemplateDialogProps {
  template?: Template;
  templateSuiteId: number;
  isOpen: boolean;
  text?: CreateTemplateDialogText;
  templateSuiteFieldVisible?: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const CreateTemplateDialog = ({
  template,
  templateSuiteId,
  isOpen,
  text,
  templateSuiteFieldVisible = false,
  onClose,
  onCloseEnd,
}: CreateTemplateDialogProps) => {
  const classes = useTemplateDialogStyles();
  const tabsClasses = useCommonDialogTabsStyles();

  const manualForm = useManualForm({
    type: 'create',
    template,
    templateSuiteId,
    hiddenFields: templateSuiteFieldVisible ? undefined : [TemplateFormField.templateSute],
    onSuccess: onClose,
  });

  const presetsState = useCallerTemplatePresets();
  const presetById = presetsState.presets ? keyBy(presetsState.presets, 'template_id') : undefined;

  const presetForm = usePresetForm({
    template,
    templateSuiteId,
    presetById,
    hiddenFields: templateSuiteFieldVisible ? undefined : [TemplatePresetFormField.templateSuiteId],
    onSuccess: onClose,
  });

  const inactiveTemplateDialog = useDialog();
  const submitForm = (inactiveTemplateConfirmed = false) => {
    const form = activeTab === FormTab.PRESET ? presetForm : manualForm;

    if (!form.form.validate()) {
      return;
    }

    if (!form.form.state.is_active && !inactiveTemplateConfirmed) {
      inactiveTemplateDialog.open();
      return;
    }

    return form.submit();
  };

  const [activeTab, setActiveTab] = React.useState(template ? FormTab.MANUAL : FormTab.PRESET);

  const updateTab = (nextTab: FormTab) => {
    if (nextTab === activeTab) {
      return;
    }

    if (nextTab === FormTab.MANUAL) {
      syncManualFormWithPresetForm(manualForm.form, presetForm.form);
    } else {
      syncPresetFormWithManualForm(presetForm.form, manualForm.form);
    }

    setActiveTab(nextTab);
  };

  return (
    <>
      <CommonDialog
        PaperProps={ { className: classes.paper } }
        title={ text?.title ?? 'Add Template' }
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
        content={
          <>
            <Tabs
              centered
              variant={ 'fullWidth' }
              value={ activeTab }
              classes={ {
                root: tabsClasses.tabs,
                flexContainer: tabsClasses.tabsFlexContainer,
                indicator: tabsClasses.tabIndicator,
              } }
              onChange={ (_, value) => updateTab(value as FormTab) }
            >
              <Tab className={ tabsClasses.tab } label="Choose from presets" value={ FormTab.PRESET } />
              <Tab className={ tabsClasses.tab } label="Create manually" value={ FormTab.MANUAL } />
            </Tabs>

            <Box mt={ 2 }>
              { activeTab === FormTab.PRESET &&
                <CallerTemplatePresetForm { ...presetForm.form } presetsState={ presetsState } />
              }
              { activeTab === FormTab.MANUAL &&
                <CallerTemplateForm
                  messageTypeNotEditable={ manualForm.messageTypeNotEditable }
                  { ...manualForm.form }
                />
              }
            </Box>
          </>
        }
        contentClassName={ classes.createDialogContent }
        actions={
          <>
            <Button color="primary" onClick={ onClose }>
              Cancel
            </Button>

            <Button
              data-testid="create-template-submit"
              color="primary"
              variant="contained"
              onClick={ () => submitForm(false) }
            >
              { text?.submitButton ?? 'Add' }
            </Button>
          </>
        }
      />

      { inactiveTemplateDialog.isMounted && (
        <EnsureDialog
          name="confirm-inactive-template"
          isOpen={ inactiveTemplateDialog.isOpen }
          onClose={ inactiveTemplateDialog.close }
          onCloseEnd={ inactiveTemplateDialog.unmount }
          onYes={ () => {
            submitForm(true);
            inactiveTemplateDialog.close();
          } }
        >
          Are you sure you want to create an inactive template?
        </EnsureDialog>
      ) }
    </>
  );
};
