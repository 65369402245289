import { Endpoint, GetEndpointsParams } from 'models/rabbit';
import * as Actions from 'actions/rabbit';
import { EndpointData, Endpoints } from 'actions/rabbit';
import { Repository } from 'utils/repository';

export type State = Repository<Endpoint, GetEndpointsParams, 'id'>;

const initState: State = Repository.create('id');

type Action =
  | Endpoints.Action
  | EndpointData.Action;

export const reducer = (state: State = initState, action: Action): State => {
  switch (action.type) {
    case Actions.RABBIT_ENDPOINT_DATA_LOAD_START:
      return state.setFetched(action.params, false);
    case Actions.RABBIT_ENDPOINT_DATA_LOAD_FAILURE:
      return state.setFetched(action.params, true);
    case Actions.RABBIT_ENDPOINT_DATA_LOAD_SUCCESS:
      return state
        .setFetched(action.params, true)
        .setTotal(action.params, action.total)
        .setData(action.params, action.data);
    case Actions.RABBIT_ENDPOINT_CHANGE:
      return state.changeModel(action.endpoint.id, action.options.delete ? undefined : action.endpoint);

    default:
      return state;
  }
};
