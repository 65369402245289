import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { RootState } from 'reducers';
import { useDevicesPageParams } from './useDevicesPageParams';
import { useDevices, useDevicesStates, useDeviceConnectivity } from 'hooks/device-management';

// components
import { DevicesTable } from 'components/DeviceManagement';
import { DevicesFilter } from './DevicesFilter';

// styles
import useStyles from 'styles/page';

type Props = ConnectedProps<typeof connector> & { isAdmin: boolean };

const mapState = (state: RootState) => {
  return {
    isAdmin: state.user.isAdmin,
  };
};
const connector = connect(
  mapState,
);

const Devices = (props: Props) => {
  const { isAdmin } = props;
  const classes = useStyles();

  const { params, filters, setFilters } = useDevicesPageParams();
  const { devices, total, isLoading } = useDevices({ params });
  const devicesStatesQuery = useDevicesStates({ params: { ...params, incidentTypes: [], severity: [] } });
  const deviceConnectivityQuery = useDeviceConnectivity({
    params: { ...params, devices: devices.map(d => d.device_id) },
    enabled: devices.length > 0
  });

  return (
    <>
      <Helmet>
        <title>Devices</title>
      </Helmet>

      <div className={ classes.toolbar }>
        <DevicesFilter
          isFetching={ isLoading }
          filters={ filters }
          onApply={ setFilters }
        />
      </div>
      <div className={ classes.table }>
        <DevicesTable
          data={ devices }
          total={ total }
          filters={ filters }
          devicesStates={ devicesStatesQuery }
          connectivity={ deviceConnectivityQuery.connectivity }
          isAdmin={ isAdmin }
          isFetching={ isLoading }
          fetchConnectivity={ deviceConnectivityQuery.isLoading }
        />
      </div>
    </>
  );
};
export default connector(Devices);
