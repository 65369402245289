import { Project } from 'models/user-management';
import { ProjectsFilters, CreateProjectParams, EditProjectParams } from 'models/projects';
import { SaveInUrl } from 'react-router-config';

export const SET_PROJECTS_FILTERS = 'SET_PROJECTS_FILTERS';
export const UPDATE_PROJECTS_FILTERS_FROM_URL = 'UPDATE_PROJECTS_FILTERS_FROM_URL';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';

export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID';
export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_BY_ID_FAILED = 'GET_PROJECT_BY_ID_FAILED';

export const EDIT_RESORCE_GROUP = 'EDIT_RESORCE_GROUP';
export const EDIT_RESORCE_GROUP_SUCCESS = 'EDIT_RESORCE_GROUP_SUCCESS';
export const EDIT_RESORCE_GROUP_FAILED = 'EDIT_RESORCE_GROUP_FAILED';

export const DELETE_RESORCE_GROUP = 'DELETE_RESORCE_GROUP';
export const DELETE_RESORCE_GROUP_SUCCESS = 'DELETE_RESORCE_GROUP_SUCCESS';
export const RESET_DELETED_PROJECT_ID = 'RESET_DELETED_PROJECT_ID';

export interface CreateProject {
  readonly type: typeof CREATE_PROJECT;
  readonly params: CreateProjectParams;
}
export interface CreateProjectSuccess {
  readonly type: typeof CREATE_PROJECT_SUCCESS;
}
export interface CreateProjectFailed {
  readonly type: typeof CREATE_PROJECT_FAILED;
}
export interface SetProjectsFilters extends SaveInUrl<ProjectsFilters> {
  readonly type: typeof SET_PROJECTS_FILTERS;
}
export interface UpdateProjectsFromUrl {
  readonly type: typeof UPDATE_PROJECTS_FILTERS_FROM_URL;
}

export interface GetProjectById {
  readonly type: typeof GET_PROJECT_BY_ID;
  id: number;
}
export interface GetProjectByIdSuccess {
  readonly type: typeof GET_PROJECT_BY_ID_SUCCESS;
  byId: Project;
}
export interface GetProjectByIdFailed {
  readonly type: typeof GET_PROJECT_BY_ID_FAILED;
}

export interface EditProject {
  readonly type: typeof EDIT_RESORCE_GROUP;
  params: EditProjectParams;
}
export interface EditProjectSuccess {
  readonly type: typeof EDIT_RESORCE_GROUP_SUCCESS;
  project: Project;
}
export interface EditProjectFailed {
  readonly type: typeof EDIT_RESORCE_GROUP_FAILED;
}

export interface DeleteProject {
  readonly type: typeof DELETE_RESORCE_GROUP;
  id: number;
}

export interface DeleteProjectSuccess {
  readonly type: typeof DELETE_RESORCE_GROUP_SUCCESS;
  id: number;
}

export interface ResetDeletedGroupId {
  readonly type: typeof RESET_DELETED_PROJECT_ID;
}


export const DoSetProjectsFilters: (
  filters: ProjectsFilters,
  toUrl: boolean
) => SetProjectsFilters = (filters, toUrl) => ({
  type: SET_PROJECTS_FILTERS,
  filters,
  pushFiltersToUrl: toUrl
});

export const DoUpdateProjectsFromUrl: () => UpdateProjectsFromUrl = () => ({
  type: UPDATE_PROJECTS_FILTERS_FROM_URL
});

export const DoCreateProject: (params: CreateProjectParams) => CreateProject = params => ({
  type: CREATE_PROJECT,
  params
});

export const DoCreateProjectSuccess: () => CreateProjectSuccess = () => ({
  type: CREATE_PROJECT_SUCCESS,
});

export const DoCreateProjectFailed: () => CreateProjectFailed = () => ({
  type: CREATE_PROJECT_FAILED,
});

export const DoGetProjectById: (id: number) => GetProjectById = id => ({
  type: GET_PROJECT_BY_ID,
  id
});
export const DoGetProjectByIdSuccess: (byId: Project) => GetProjectByIdSuccess = byId => ({
  type: GET_PROJECT_BY_ID_SUCCESS,
  byId
});
export const DoGetProjectByIdFailed: () => GetProjectByIdFailed = () => ({
  type: GET_PROJECT_BY_ID_FAILED
});
export const DoEditProject: (params: EditProjectParams) => EditProject = params => ({
  type: EDIT_RESORCE_GROUP,
  params
});
export const DoEditProjectSuccess: (project: Project) => EditProjectSuccess = project => ({
  type: EDIT_RESORCE_GROUP_SUCCESS,
  project
});
export const DoEditProjectFailed: () => EditProjectFailed = () => ({
  type: EDIT_RESORCE_GROUP_FAILED,
});
export const DoDeleteProject: (id: number) => DeleteProject = id => ({
  type: DELETE_RESORCE_GROUP,
  id
});
export const DoDeleteProjectSuccess: (id: number) => DeleteProjectSuccess = id => ({
  type: DELETE_RESORCE_GROUP_SUCCESS,
  id
});
export const DoResetDeletedGroupId: () => ResetDeletedGroupId = () => ({
  type: RESET_DELETED_PROJECT_ID
});

export type Action = (
  | SetProjectsFilters
  | UpdateProjectsFromUrl
  | CreateProject
  | CreateProjectSuccess
  | CreateProjectFailed
  | GetProjectById
  | GetProjectByIdSuccess
  | GetProjectByIdFailed
  | EditProject
  | EditProjectSuccess
  | EditProjectFailed
  | DeleteProject
  | DeleteProjectSuccess
  | ResetDeletedGroupId
)
