import { uniq, isEqual } from 'lodash';
import { useAuthUserSelector, useCustomMemo } from 'hooks';
import { BSResponsiblePersonsFields, Registered, ResponsiblePerson } from 'models/base-station';
import { canViewOwner } from 'utils/permissions';

// components
import { Option, SelectControl } from 'components/Controls/Select';
import { OwnersSingleSelectControl } from 'components/Controls';

// styles
import useStyles from 'styles/filters';

interface Props {
  responsiblePersons: ResponsiblePerson[];
  state: BSResponsiblePersonsFields;
  setState: (filters: BSResponsiblePersonsFields) => void;
}

function createRoleOptions(responsiblePersons: ResponsiblePerson[]): Option<string>[] {
  const roles = uniq(responsiblePersons.map(person => person.role));
  return [
    { value: 'None', label: 'None' },
    ...roles.filter(role => role).map(role => ({ value: role, label: role }))
  ];
}

const userOptions = [
  { value: Registered.user, label: Registered.user },
  { value: Registered.notUser, label: Registered.notUser },
];

export const ResponsiblePersonsFilter = ({ state, setState, responsiblePersons }: Props) => {
  const classes = useStyles();
  const user = useAuthUserSelector();

  const roleOptions = useCustomMemo(() => createRoleOptions(responsiblePersons), [responsiblePersons], isEqual);

  return (
    <form className={ classes.gridRoot } autoComplete="off">
      { canViewOwner(user) && (
        <OwnersSingleSelectControl
          isClearable
          selected={ state.owner }
          changeHandler={ owner => setState({ ...state, owner })}
        />
      ) }
      <SelectControl
        isMulti={ false }
        selected={ state.role  }
        options={ roleOptions }
        onChange={ (role) => setState({ ...state, role }) }
        label="Role"
        name="role"
        isClearable
      />
      <SelectControl
        isMulti={ false }
        selected={ state.user }
        options={ userOptions }
        onChange={ (user) => setState({ ...state, user }) }
        label="Registered"
        name="registered"
        isClearable
      />
    </form>
  );
};
