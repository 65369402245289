import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '300px',
    },
  }),
);

export default useStyles;
