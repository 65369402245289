import { useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { GetStationLocationParams, StationLocation } from 'models/base-station';
import { LoadStationLocationDataByParams, LoadStationLocationDataDictionary } from 'actions/base-station';

export interface LocationsState {
  locations: StationLocation[];
  isLoading: boolean;
}

export function useStationLocationsParamsSelector(params: GetStationLocationParams): LocationsState {
  const dispatch = useDispatch();
  const { isFetched, locations } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.location.isFetched(params),
    locations: state.baseStation.location.findByParams(params),
  }));

  useDeepCompareEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadStationLocationDataByParams(params));
    }
  }, [params, isFetched]);

  return {
    locations: locations,
    isLoading: isFetched !== true
  };
}

// Move to hooks
export function useStationLocationsDictionarySelector(): LocationsState {
  const dispatch = useDispatch();
  const { isFetched, locations } = useSelector((state: RootState) => ({
    isFetched: state.baseStation.location.isFetched({}),
    locations: state.baseStation.location.findByParams({}),
  }), shallowEqual);

  useEffect(() => {
    if (isFetched === undefined) {
      dispatch(LoadStationLocationDataDictionary());
    }
  }, [dispatch, isFetched]);

  return {
    locations: locations,
    isLoading: isFetched !== true
  };
}
