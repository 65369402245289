import { useMemo } from 'react';

import { State } from 'reducers/pages/station-monitoring';

import { chartDataWithoutDecimation, prepareHistory } from '../utils';

type StatusHistory = (
  | State['uptimeHistory']
  | State['internetHistory']
  | State['onlineHistory']
);

interface StatusChartDataOptions {
  statusHistory: StatusHistory;
  chartName: string;
}

export function useStatusChartData({ statusHistory, chartName }: StatusChartDataOptions) {
  const lineData = useMemo(() => {
    if (!statusHistory.dateRange) {
      return { data: [], maxValue: 1 };
    }

    const statusHistoryData = prepareHistory(statusHistory.data);

    return chartDataWithoutDecimation(statusHistoryData, chartName);
  }, [statusHistory, chartName]);

  return lineData;
}
