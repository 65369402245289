import { getLocation } from 'utils/routing/query';
import { GetHistoryParamsQuery, GetRoutersParams } from 'models/caller';

export * from './template';
export * from './template-suite';

export function getCallerHistoryPath() {
  return '/api/http/history';
}

export function getCallerRouterListPath() {
  return '/api/http/routers';
}

export function getCallerHistoryLocation(params: GetHistoryParamsQuery) {
  return getLocation(getCallerHistoryPath(), params);
}

export function getCallerRouterListLocation(params: GetRoutersParams) {
  return getLocation(getCallerRouterListPath(), params);
}
