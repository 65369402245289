import React from 'react';
import { useGroups } from 'hooks/device-management';

// components
import { GroupLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  groupId: number;
  name?: string;
  noLink?: boolean;
  noLoad?: boolean;
}

export const GroupLabel = (props: Props): JSX.Element => {
  const { groupId, name = '-', noLink = false, noLoad = false } = props;

  const state = useGroups({
    params: { groups: [groupId], limit: 1 },
    enabled: !noLoad
  });

  if (!noLoad && state.isLoading) {
    return (
      <LoaderSvg/>
    );
  }

  const group = state.groups.find(group => group.id === groupId);
  if (!group) {
    return (
      <>{ name }</>
    );
  }

  if (noLink) {
    return (
      <>{ group.name }</>
    );
  }

  return (
    <GroupLink
      zoneId={ group.zone_id }
      levelId={ group.level_id ?? 0 }
      groupId={ group.id }
      withReturnUrl
    >
      { group.name }
    </GroupLink>
  );
};
