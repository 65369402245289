import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useFormActionLoader } from 'hooks/form';
import { dmPositionGroupPath } from 'routing/paths';
import * as is from 'utils/form/validation/validators';
import { GroupCreateFields, GroupType, Group } from 'models/device-management';
import { groupTypes } from 'models/device-management/dm';
import { DoCreateGroup } from 'actions/device-management/groups';

// components
import { Form } from 'components';
import { SuccessButton } from 'components/Buttons';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import * as Fields from 'components/Form/Field';

// styles
import { useStyles } from 'styles/forms';

type FormValues = Omit<GroupCreateFields, 'zone_id'> & { zone_id?: number }

export const CreateGroup = (): JSX.Element => {
  const { state } = useLocation<{ zoneId: FormValues['zone_id'] } | undefined>();
  const zoneId = state?.zoneId;
  const classes = useStyles();
  const { doAction } = useFormActionLoader();
  const defaultValues: FormValues = {
    name: '',
    type: undefined,
    custom_id: undefined,
    zone_id: zoneId || undefined,
  };

  const handleSubmit = (values: FormValues) =>  {
    const valuesWithType = {
      ...values,
      // `values.zone_id as number` is valid as long as there's `is.required` validator
      zone_id: values.zone_id as number,
      type: values.type || GroupType.MarkedParkingBay,
    };

    doAction({
      action: DoCreateGroup([valuesWithType]),
      onSuccess: (group: Group) => ({ to: dmPositionGroupPath(group.id) })
    });
  };

  return (
    <div>
      <Helmet>
        <title>Create group</title>
      </Helmet>
      <Form<FormValues>
        defaultValues={ defaultValues }
        validators={{
          name: is.required(),
          zone_id: is.required()
        }}
        onSubmit={ handleSubmit }
      >
        { form => (
          <Paper className={classes.container}>
            <Grid item={ true } xs={ 12 } sm={ 12 } className={ classes.gridEnd }>
              <SuccessButton
                label="Save"
                pending={ form.formState.isSubmitting }
                disabled={ !form.formState.dirty }
              />
            </Grid>
            <FormGroup className={ classes.fields }>
              <Fields.Text
                className={ classes.field }
                label="Group Name"
                name={ 'name' }
              />
              <Grid item={ true } xs={ 12 } sm={ 12 } className={ classes.field }>
                <Fields.SelectSingle
                  label="Group Type"
                  name={ 'type' }
                  values={ Object.entries(groupTypes)
                    .map(el => ({
                      label: el[1],
                      value: el[0]
                    }))
                  }
                />
              </Grid>
              <Fields.Text
                name={ 'custom_id' }
                label="Custom ID"
                fullWidth
                className={ classes.field }
              />
              <div className={ classes.field }>
                <Fields.SelectZone
                  name={ 'zone_id' }
                  isDisabled={ !!zoneId }
                  onChange={ () => form.setValue('level_id', undefined) }
                />
              </div>
              <div className={ classes.field }>
                <Fields.SelectLevel
                  name={ 'level_id' }
                  zoneId={ form.watch('zone_id') }
                  isDisabled={ !form.watch('zone_id') }
                />
              </div>
            </FormGroup>
          </Paper>
        )}
      </Form>
    </div>
  );
};
