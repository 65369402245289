import React from 'react';
import { useGroups } from 'hooks/device-management';

// components
import { Skeleton } from '@material-ui/lab';

interface PositionGroupRouteLabelProps {
  groupIdParam: string;
}

const PositionGroupRouteLabel: React.FC<PositionGroupRouteLabelProps> = ({ groupIdParam }) => {
  const { groups, isLoading } = useGroups({ params: { groups: [Number(groupIdParam)] } });
  const group = groups[0];

  if (typeof group === 'undefined' || isLoading) {
    return <Skeleton height={ 40 } width={ 80 } />;
  }

  return <>{ group?.name || `Group` }</>;
};

export default PositionGroupRouteLabel;
