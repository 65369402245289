import React from 'react';
import { FormProps } from 'form';
import { useDeepCompareEffect } from 'react-use';
import { VIRTUAL_FIRMWARE } from 'models/firmware-management';
import { validate } from './validator';
import { FormField, FormState } from './types';

// components
import { FirmwareSelectControl, SingleSelectControl, SwitchControl, OwnersSingleSelectControl } from 'components/Controls';

export type CreateVirtualDeviceState = FormState;
export {
  FormField as CreateVirtualDeviceField
};

type Props = FormProps<FormState, FormField> & {
  maxCount: number;
}

export const CreateVirtualDeviceForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(FormField.software) ? '' :
        <FirmwareSelectControl
          isRequired={ !isView }
          isDisabled={ isView || disabledFields.includes(FormField.software) }
          label="Firmware"
          placeholder="Choose firmware"
          selected={ state.software }
          onChange={ (software?: string) => handleChange(FormField.software, software) }
          error={ showErrors.includes(FormField.software) ? errors[FormField.software] : undefined }
          filter={ firmware => Object.values(VIRTUAL_FIRMWARE).includes(firmware.hash as VIRTUAL_FIRMWARE)}
        />
      }
      { !showFields.includes(FormField.count) ? '' :
        <SingleSelectControl
          isDisabled={ isView || disabledFields.includes(FormField.count) }
          label="Number of devices"
          name="count"
          values={
            Array
              .from({ length: props.maxCount })
              .map((_, i) => ({ value: i + 1, label: String(i + 1) }))
          }
          selected={ state.count }
          changeHandler={ count => handleChange(FormField.count, Number(count ?? 1)) }
          error={ showErrors.includes(FormField.count) ? errors[FormField.count] : undefined }
        />
      }
      { !showFields.includes(FormField.owner_id) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable
          isDisabled={ isView || disabledFields.includes(FormField.owner_id) }
          selected={ state.owner_id }
          changeHandler={ ownerId => handleChange(FormField.owner_id, ownerId) }
          error={ showErrors.includes(FormField.owner_id) ? errors[FormField.owner_id] : undefined }
        />
      }
      { !showFields.includes(FormField.active) ? '' :
        <SwitchControl
          isDisabled={ isView || disabledFields.includes(FormField.active) }
          name="active"
          label="Active"
          value={ state.active ?? false }
          onChange={ active => handleChange(FormField.active, active) }
          error={ showErrors.includes(FormField.active) ? errors[FormField.active] : undefined }
        />
      }
    </form>
  );
};
