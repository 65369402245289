import React from 'react';
import { CloneTemplateSuite } from 'actions/caller';
import { Template, TemplateSuite } from 'models/caller';
import { getCallerTemplateSuiteDetailPath } from 'routing/paths/caller';
import { useAuthUserSelector, useForm, useFormActionLoader, useFormEditActions } from 'hooks';
// components
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CallerTemplateSuiteForm, TemplateSuiteFormField } from 'components/Caller';

interface Props {
  templateSuite: TemplateSuite;
  templates: Template[];
}

export const CallerTemplateSuiteClone = ({ templateSuite, templates }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const form = useForm<TemplateSuite, TemplateSuiteFormField>({
    initialState: { ...templateSuite, template_suite_id: 0, name: `CLONE ${ templateSuite.name }` },
    showFields: [
      ...(user.isAdmin ? [TemplateSuiteFormField.owner_id] : []),
      TemplateSuiteFormField.name
    ]
  });

  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    canSave: true,
    btnSave: 'Clone',
    btnCancel: 'Reset',
    onSave: () => {
      if (!form.validate()) {
        return;
      }

      doAction({
        action: CloneTemplateSuite(form.state, templates),
        onSuccess: (result) => ({
          push: true,
          to: getCallerTemplateSuiteDetailPath(result.template_suite_id),
        }),
        onError: form.catchError
      });
    },
    onCancel: form.reset
  });

  return (
    <Card>
      <CardHeader title="Clone Template Suite" action={ actions }/>
      <CardContent>
        <CallerTemplateSuiteForm { ...form }  />
      </CardContent>
    </Card>
  );
};
