import { Level } from 'models/device-management';
import { useTree } from 'hooks/device-management/useTree/useTree';
import { findZoneNode } from 'hooks/device-management/useTree/utils';
import { QueryType, Tree, ZoneNode } from 'hooks/device-management/useTree/types';
import { useLocationBackAction, } from 'hooks/location';
import { getDMListPath } from 'routing/paths';

interface PageData {
  isLoading: boolean;
  isValid: boolean;
  tree: Tree;
  node?: ZoneNode;
  levels: Level[];
  reload: () => void;
  back: () => void;
}

export const useZoneDetailPage = (zoneIdParam: string): PageData => {
  const zoneId = parseInt(zoneIdParam);
  const isValid = !isNaN(zoneId) && zoneId > 0;
  const backAction = useLocationBackAction();
  const tree = useTree({
    zones: [zoneId],
    offset: 0,
    limit: 1
  }, isValid);

  const node = (tree.loaded.zone && findZoneNode(tree, zoneId)) || undefined;
  return {
    isLoading: !tree.loaded.zone,
    isValid: isValid && (!tree.loaded.zone || node !== undefined),
    tree: tree,
    node: node,
    levels: fetchZoneLevels(tree, node),
    reload: () => {
      tree.reload(QueryType.zone);
      tree.reload(QueryType.level);
    },
    back: () => backAction(getDMListPath()),
  };
};

const fetchZoneLevels = (tree: Tree, node?: ZoneNode): Level[] => {
  if (!node || !tree.loaded.level) {
    return [];
  }

  const levels: Level[] = [];
  for (const levelNode of Object.values(node.children.items)) {
    if (levelNode.level) {
      levels.push(levelNode.level);
    }
  }

  return levels;
};
