import { GetHistoryParamsQuery, History } from 'models/caller';

export const CALLER_HISTORY_DATA_LOAD_BY_PARAMS = 'caller/history/LOAD_BY_PARAMS';
export const CALLER_HISTORY_DATA_LOAD_BY_PARAMS_SUCCESS = 'caller/history/LOAD_BY_PARAMS_SUCCESS';
export const CALLER_HISTORY_DATA_LOAD_BY_PARAMS_FAILURE = 'caller/history/LOAD_BY_PARAMS_FAILURE';

export interface LoadCallerHistoryDataByParams {
  readonly type: typeof CALLER_HISTORY_DATA_LOAD_BY_PARAMS;
  readonly params: GetHistoryParamsQuery;
}

export interface LoadCallerHistoryDataSuccess {
  readonly type: typeof CALLER_HISTORY_DATA_LOAD_BY_PARAMS_SUCCESS;
  readonly data: History[];
}

export interface LoadCallerHistoryDataFailure {
  readonly type: typeof CALLER_HISTORY_DATA_LOAD_BY_PARAMS_FAILURE;
  readonly error: string;
}

export const LoadCallerHistoryDataByParams = (params: GetHistoryParamsQuery): LoadCallerHistoryDataByParams => ({
  type: CALLER_HISTORY_DATA_LOAD_BY_PARAMS, params
});

export const LoadCallerHistoryDataSuccess = (data: History[]): LoadCallerHistoryDataSuccess => ({
  type: CALLER_HISTORY_DATA_LOAD_BY_PARAMS_SUCCESS, data
});

export const LoadCallerHistoryDataFailure = (error: string): LoadCallerHistoryDataFailure => ({
  type: CALLER_HISTORY_DATA_LOAD_BY_PARAMS_FAILURE, error
});

export type Action =
  | LoadCallerHistoryDataByParams
  | LoadCallerHistoryDataSuccess
  | LoadCallerHistoryDataFailure