import { Location } from 'history';
import { RouteConfig } from 'react-router-config';
import { Parser } from 'utils/routing/parser';
import { parseUrlParams } from 'utils/routing/query';

const getPathParams = (query: string) => {
  const parsed = new Parser(parseUrlParams(query, p => p));
  return {
    owner: parsed.asString('owner'),
    projects: parsed.asStrings('projects'),
    zones: parsed.asStrings('zones'),
  };
};

// add pathParam form common-filters https://nwaveio.atlassian.net/browse/BNIV-1955
/**
 * Add filters
 * @param routes
 */
export const withCommonFilterQuery = (routes: RouteConfig[], location: Location): RouteConfig[] => {
  const addPathParam = (route: RouteConfig): RouteConfig => {
    return {
      ...route,
      pathParams: getPathParams(location.hash),
      routes: route.routes?.map(addPathParam)
    };
  };
  return routes.map(addPathParam);
};