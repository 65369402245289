import React from 'react';

import { useDialog } from 'hooks';

// components
import { InfoDialog } from 'components/Dialogs';

interface SendMessageButtonChildrenProps {
  onClick?: () => void;
}

interface SendMessageButtonProps {
  children?: (props: SendMessageButtonChildrenProps) => React.ReactNode;
  isDevicePositioned: boolean;
}

export const SendMessageAlerts = ({ children, isDevicePositioned }: SendMessageButtonProps) => {
  const dialog = useDialog();
  let handleClick: (() => void) | undefined;

  if (!isDevicePositioned) {
    handleClick = dialog.open;
  }

  return (
    <>
      { children?.({ onClick: handleClick }) }

      { dialog.isMounted && <InfoDialog
        name="device-cant-send-messages"
        label="This device can't send messages"
        isOpen={ dialog.isOpen }
        onClose={ dialog.close }
        onCloseEnd={ dialog.unmount }
      >
        Device must be positioned in order to send messages
      </InfoDialog> }
    </>
  );
};