import { isNil } from 'utils/models';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { DEFAULT_LIMIT, DEFAULT_LIMIT_OPTIONS } from './paginator';

export const commonOptions: MUIDataTableOptions = {
  download: false,
  filter: false,
  print: false,
  search: false,
  sort: false,
  viewColumns: false,
  serverSide: false,
  rowsPerPage: DEFAULT_LIMIT,
  rowsPerPageOptions: DEFAULT_LIMIT_OPTIONS,
  expandableRows: false,
  selectableRows: 'none',
  responsive: 'vertical',
  filterType: 'dropdown',
};

// TODO: match with mui-datatables' sort direction typedef (BNIV-82)
export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

function compareOneTypeValues<T extends number | string>(x: T, y: T, order: Order) {
  if (x === y) {
    return 0;
  }

  const orderFactor = order === Order.ASC ? 1 : -1;

  return (x < y ? -1 : 1) * orderFactor;
}

export function defaultCompare(x: unknown, y: unknown, order: Order) {
  if (isNil(x) && isNil(y)) {
    return 0;
  }

  if (isNil(x)) {
    return 1;
  }

  if (isNil(y)) {
    return -1;
  }

  if (typeof x === 'number' && typeof y === 'number') {
    return compareOneTypeValues(x, y, order);
  }

  const stringifiedX = `${x}`.toLowerCase();
  const stringifiedY = `${y}`.toLowerCase();

  return compareOneTypeValues<string>(stringifiedX, stringifiedY, order);
}

/**
 * Sort `mui-datatables` rows to display N/A (null/undefined) after valid values.
 */
export const defaultSort: MUIDataTableOptions['customSort'] = (data, colIndex, order) => {
  return data.sort((rowA, rowB) => defaultCompare(
    rowA.data[colIndex],
    rowB.data[colIndex],
    order as Order,
  ));
};

export interface Column {
  name: string;
  label?: string;
  options?: {
    display?: string;
    [key: string]: unknown;
  };
}

interface HideColumnsOptions<TColumn extends MUIDataTableColumn> {
  hiddenColumns?: TColumn['name'][];
  visibleColumns?: TColumn['name'][];
}

export function hideColumns<TColumn extends MUIDataTableColumn>(
  columns: TColumn[],
  { hiddenColumns, visibleColumns }: HideColumnsOptions<TColumn>,
): TColumn[] {
  return columns.map(column => {
    if (visibleColumns) {
      if (visibleColumns.includes(column.name)) {
        return column;
      }
    } else if (!hiddenColumns?.includes(column.name)) {
      return column;
    }

    return {
      ...column,
      options: {
        ...column.options,
        display: 'excluded',
      },
    };
  });
}
