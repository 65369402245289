import { Filters } from '../../types';
import { ChildrenData, TreeInitializationOptions } from '../types';
import { createEntityTypeScopedTree } from './createEntityTypeScopedTree';
import { createTreeTopDown } from './createTreeTopDown';
import { areEntityTypeScopeFilters } from './filters';

export async function createTreeNodes({
  filters,
  setTree
}: TreeInitializationOptions) {
  setTree((tree) => ({ ...tree, childrenLoadStatus: 'loading' }));

  try {
    const { children, childrenTotal } = await fetchTreeNodes(filters);

    setTree((tree) => ({
      ...tree,
      children,
      childrenTotal: childrenTotal,
      childrenLoadStatus: 'success'
    }));
  } catch (e) {
    setTree((tree) => ({ ...tree, childrenLoadStatus: 'error' }));
    throw e;
  }
}

export async function fetchTreeNodes(filters: Filters): Promise<ChildrenData> {
  let childrenData: ChildrenData;

  if (areEntityTypeScopeFilters(filters)) {
    childrenData = await createEntityTypeScopedTree(filters);
  } else {
    childrenData = await createTreeTopDown(filters);
  }

  return childrenData;
}
