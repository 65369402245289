import { Statistic } from 'models/device-management';
import { DateType } from '@date-io/type';
import { IncidentStatistic, IncidentStatisticHistory, StatisticMetricEnum } from 'models/device-monitoring';
import { ConnectivityChartData } from 'models/connectivity';
import { SaveInUrl } from 'react-router-config';

export const FETCH_ALL_STAT_DM_START = 'FETCH_ALL_STAT_DM_START';
export const INIT_ALL_STAT_DM_START = 'INIT_ALL_STAT_DM_START';
export const FETCH_ALL_STAT_DM_SUCCESS = 'FETCH_ALL_STAT_DM_SUCCESS';
export const FETCH_ALL_STAT_DM_FAILED = 'FETCH_ALL_STAT_DM_FAILED';
export const FETCH_STAT_CONNECTIVITY_SUCCESS = 'FETCH_STAT_CONNECTIVITY_SUCCESS';
export const FETCH_STAT_FAILURES_SUCCESS = 'FETCH_STAT_FAILURES_SUCCESS';

export const UPDATE_CHARTS_FILTERS_FROM_URL = 'UPDATE_CHARTS_FILTERS_FROM_URL';

export interface StatisticRawMessage {
  demodulation_date: string;
  total: number;
  total_unique: number;
}

export interface GetStatParams {
  zones: number[];
  devices: string[];
  groups?: number[];
  timeFrom?: DateType;
  timeTo?: DateType;
  projects: number[];
  owner?: number;
  metrics?: StatisticMetricEnum[];
}

export interface GetDevicesHealthStat extends SaveInUrl<GetStatParams> {
  readonly type: typeof FETCH_ALL_STAT_DM_START;
  readonly quiet?: boolean;
}
export interface InitStatDm {
  readonly type: typeof INIT_ALL_STAT_DM_START;
}
export interface GetDevicesHealthStatSuccess {
  readonly type: typeof FETCH_ALL_STAT_DM_SUCCESS;
  payload: Statistic;
}
export interface GetDevicesHealthStatFailed {
  readonly type: typeof FETCH_ALL_STAT_DM_FAILED;
}
export interface GetConnectivitySuccess {
  readonly type: typeof FETCH_STAT_CONNECTIVITY_SUCCESS;
  connectivity: number;
  log: ConnectivityChartData[];
  statistic: Statistic;
}
export interface GetFailuresSuccess {
  readonly type: typeof FETCH_STAT_FAILURES_SUCCESS;
  total: IncidentStatistic;
  log: IncidentStatisticHistory;
}
export interface UpdateChartsFromUrl {
  readonly type: typeof UPDATE_CHARTS_FILTERS_FROM_URL;
}

export const DoGetDevicesHealthStat: (
  filters: GetStatParams,
  saveToUrl: boolean,
  quiet?: boolean,
) => GetDevicesHealthStat = (filters, saveToUrl, quiet) => ({
  type: FETCH_ALL_STAT_DM_START, pushFiltersToUrl: saveToUrl, filters, quiet
});
export const DoInitStatDm: () => InitStatDm = () => ({
  type: INIT_ALL_STAT_DM_START
});
export const DoGetDevicesHealthStatSuccess: (payload: Statistic) => GetDevicesHealthStatSuccess = (payload) => ({
  type: FETCH_ALL_STAT_DM_SUCCESS,
  payload,
});
export const DoGetDevicesHealthStatFailed: () => GetDevicesHealthStatFailed = () => ({
  type: FETCH_ALL_STAT_DM_FAILED
});

export const DoGetConnectivitySuccess: (
  connectivity: number,
  log: ConnectivityChartData[],
  statistic: Statistic
) => GetConnectivitySuccess = (connectivity, log, statistic) => ({
  type: FETCH_STAT_CONNECTIVITY_SUCCESS,
  connectivity,
  log,
  statistic
});
export const DoGetFailuresSuccess: (total: IncidentStatistic, log: IncidentStatisticHistory) => GetFailuresSuccess = (total, log) => ({
  type: FETCH_STAT_FAILURES_SUCCESS,
  total,
  log
});
export const DoUpdateChartsFromUrl: () => UpdateChartsFromUrl = () => ({
  type: UPDATE_CHARTS_FILTERS_FROM_URL
});

export type Action = (
  | GetDevicesHealthStat
  | GetDevicesHealthStatSuccess
  | GetDevicesHealthStatFailed
  | GetConnectivitySuccess
  | GetFailuresSuccess
  | UpdateChartsFromUrl
  | InitStatDm
);
