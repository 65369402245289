import { IncidentsFiltersState as IncidentsFilters } from 'models';
import { Incident } from 'models/device-monitoring';
import { Device } from 'models/device-monitoring/device';
import { RootState as State } from 'reducers';
import { stringifyObject } from 'utils/ds/object';
import { Defined } from 'utils/models';

function getDevicesMonitoringState(state: State) {
  return state.deviceStates;
}

export function areDevicesStatesLoading(state: State): boolean {
  return !!getDevicesMonitoringState(state).isFetching;
}

export function getDeviceState(
  deviceId: Device['device_id'],
  state: State,
): Device | null | undefined {
  return getDevicesMonitoringState(state).devicesById[deviceId];
}

export function getIncidentIds(
  filters: IncidentsFilters,
  state: State,
): Defined<Incident['id']>[] | undefined {
  return state.incidents.incidentIdsByQuery[stringifyObject(filters)];
}

export function getTotalIncidents(filters: IncidentsFilters, state: State): number | undefined {
  return state.incidents.totalByQuery[stringifyObject(filters)];
}
