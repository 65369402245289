import { isNil } from 'lodash';
import React from 'react';
import { Label, getIconForLabel } from './utils';

// components
import { Chip } from '@material-ui/core';

// styles
import clsx from 'clsx';
import { useLabelStyles } from './styles';

interface LabelProps {
  className?: string;
  label: Label;
}

export const DmLabel = ({ className, label }: LabelProps) => {
  const classes = useLabelStyles();
  const Icon = getIconForLabel(label.label);

  return (
    <Chip
      classes={ {
        root: clsx(classes.root, className),
        label: classes.labelContainer,
      } }
      label={
        <>
          <span className={ classes.label }>{ label.label }</span>
          { !isNil(label.count) && <span className={ classes.count }>{ label.count }</span> }
        </>
      }
      variant="outlined"
      color="primary"
      icon={ <Icon className={ classes.icon } /> }
    />
  );
};
