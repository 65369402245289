import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getTemplatePresets } from 'clients/http-calls';
import { TemplatePreset } from 'models/caller';
import { getResponseData } from 'utils/clients';

export interface CallerTemplatePresetsState {
  isLoading: boolean;
  presets?: TemplatePreset[];
}

export const useCallerTemplatePresets = (): CallerTemplatePresetsState => {
  const dispatch = useDispatch();

  const queryResult = useQuery({
    queryKey: ['caller/templatePresets'],
    queryFn: async () => getResponseData(await getTemplatePresets()),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      dispatch(NotifyError(`Error while fetching template presets: ${ (e as Error).message }`));
    },
  });

  return {
    presets: queryResult.data,
    isLoading: queryResult.isLoading,
  };
};
