import { Filters } from '../../types';

export function areEntityTypeScopeFilters(filters: Filters): boolean {
  return (
    arePositionScopeFilters(filters) ||
    areGroupScopeFilters(filters) ||
    areLevelScopeFilters(filters)
  );
}

export function arePositionScopeFilters(filters: Filters): boolean {
  // TODO: add "bound" filter
  return (
    (filters.networkIds ?? []).length > 0 ||
    (filters.customIds ?? []).length > 0 ||
    (filters.innerIds ?? []).length > 0 ||
    (filters.devices ?? []).length > 0
  );
}

export function areGroupScopeFilters(filters: Filters): boolean {
  // TODO: support group types filter
  return (filters.groups ?? []).length > 0;
}

export function areLevelScopeFilters(filters: Filters): boolean {
  return (filters.levels ?? []).length > 0;
}
