import { useState } from 'react';
import clsx from 'clsx';
import { Zone } from 'models/device-management';
import { formatDateTime } from 'utils/datetime';
import { combineIds } from 'utils/ids';
import { nameof } from 'utils/type-checking';

// components
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Fields from 'components/Form/Field';

// styles
import { useStyles } from './ZoneFormStyles';

export type FormValues = {
  id?: number;
  name: string;
  live_status: boolean;
  owner_id?: number;
  project_id?: number;
  creation_date?: string;
}

interface Props {
  allowed: boolean;
  zone?: Zone;
  showFields: string[];
  idPrefix?: string;
  userOwnerId?: number;
  setDefaultProjectId: (owner?: Zone['owner_id']) => void;
}

export const ZoneForm = ({
  setDefaultProjectId,
  allowed,
  zone,
  showFields,
  idPrefix,
  userOwnerId
}: Props): JSX.Element => {
  const classes = useStyles();
  const [selectedOwner, setSelectedOwner] = useState<number | undefined>(zone?.owner_id || userOwnerId);

  const handleSetSelectedOwner = (owner: FormValues['owner_id']) => {
    setSelectedOwner(owner);
    setDefaultProjectId(owner);
  };

  return (
    <FormGroup className={ classes.fields }>
      { showFields.includes('id') &&
        <TextField
          className={ classes.field }
          disabled
          label="Zone ID"
          value={ zone?.id }
        />
      }

      { showFields.includes('name') &&
        <Fields.Text
          className={ classes.field }
          disabled={ !allowed }
          label="Zone Name"
          id={ combineIds(idPrefix, 'name') }
          name={ nameof<FormValues>('name') }
        />
      }

      { showFields.includes('creationDate') &&
        <TextField
          className={ classes.field }
          disabled
          label="Creation time"
          value={ zone?.creation_date ? formatDateTime(zone?.creation_date) : '' }
        />
      }

      { showFields.includes('liveStatus') &&
        <FormControlLabel
          className={ clsx(classes.field, classes.field_type_switch) }
          control={ <Fields.Switch name={ nameof<FormValues>('live_status') } /> }
          disabled={ !allowed }
          label="Live status"
          labelPlacement="start"
        />
      }

      { showFields.includes('owner') && (
        <div className={ classes.field }>
          <Fields.SelectOwner
            name={ nameof<FormValues>('owner_id') }
            isDisabled={ !allowed }
            onChange={ handleSetSelectedOwner }
          />
        </div>
      ) }

      { showFields.includes('project') &&
        <div className={ classes.field }>
          <Fields.SelectProject
            label="Project"
            name={ nameof<FormValues>('project_id') }
            isDisabled={ !allowed }
            filter={ project => project.owner_id === selectedOwner }
          />
        </div>
      }
    </FormGroup>
  );
};
