import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuthUserSelector, useDialog } from 'hooks';
import { isAllowedToCreateLevel } from 'utils/permissions';
import { useZoneDetailPage } from './utils';

// components
import { Box, Grid } from '@material-ui/core';
import { AddButton } from 'components/Buttons';
import { AddZoneLevelDialog } from 'components/DeviceManagement';
import { NodeTreeTable } from 'components/DeviceManagement/TreeTable/NodeTreeTable';
import { ZoneNotFound } from './widget/ZoneNotFound';
import { ZoneSkeleton } from './widget/ZoneSkeleton';
import { ZoneManagement } from './widget/ZoneManagement';
import { ZoneStatistic } from './widget/ZoneStatistic';
// styles
import useStyles from 'styles/page';

interface Params {
  zoneId: string;
}

export const ZoneDetailPage = ({ zoneId }: Params) => {
  const classes = useStyles();
  const { isLoading, isValid, tree, node, levels, reload, back } = useZoneDetailPage(zoneId);
  const addZoneLevelDialog = useDialog();
  const authUser = useAuthUserSelector();

  return (
    <Box className={ classes.root } data-testid="page-dm-zone-detail">
      <Helmet><title>Zone! { node?.zone.name ?? `#${zoneId}` }</title></Helmet>
      { !isValid && <ZoneNotFound zoneId={ zoneId }/> }
      { isValid && isLoading && <ZoneSkeleton /> }
      { isValid && node && (
        <>
          <Grid container spacing={ 1 } >
            <Grid item xs={ 12 }>
              <ZoneManagement
                zone={ node.zone }
                onEdit={ reload }
                onDelete={ back }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <ZoneStatistic zone={ node.zone } tree={ tree } />
            </Grid>
            <Grid item xs={ 12 }>
              <NodeTreeTable
                embedded
                tree={ tree }
                node={ node }
                loaded={ tree.loaded.level }
              />
            </Grid>
          </Grid>
          { isAllowedToCreateLevel(authUser.data) && (
            <>
              <AddButton
                label="Add level"
                onClick={ addZoneLevelDialog.open }
              />
              { addZoneLevelDialog.isMounted && (
                <AddZoneLevelDialog
                  zone={ node.zone }
                  zoneLevels={ levels }
                  isOpen={ addZoneLevelDialog.isOpen }
                  onClose={ addZoneLevelDialog.close }
                  onCloseEnd={ addZoneLevelDialog.unmount }
                  onSuccess={ () => {
                    addZoneLevelDialog.close();
                    reload();
                  } }
                />
              ) }
            </>
          ) }
        </>
      )}
    </Box>
  );
};
