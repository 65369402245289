import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { ExchangeTypes, FormField, FormState } from './types';
import { useSSLDefaultPort } from './utils';

// components
import { SingleSelectControl, SwitchControl, TextControl } from 'components/Controls';

export type EndpointFormState = FormState;
export {
  FormField as EndpointFormField,
};
const digitalRegExp = /[^0-9]/g;
type Props = FormProps<FormState, FormField>
export const EndpointForm = (props: Props): JSX.Element => {
  const { state, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];
  const errors = props.errors ?? {};

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChangeSSL = useSSLDefaultPort();
  const handleChange = (field: FormField, value: unknown): void => {
    let newState = { ...state, [field]: value };
    if (field === FormField.ssl) {
      newState = handleChangeSSL(newState);
    }
    onChange && onChange(newState, field);
  };

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(FormField.name) ? '' :
        <TextControl
          required
          disabled={ disabledFields.includes(FormField.name) }
          name="name"
          label="Endpoint name"
          placeholder="Enter name"
          value={ state.name }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.name, event.target.value) }
          error={ showErrors.includes(FormField.name) ? errors[FormField.name] !== undefined : false }
          helperText={ showErrors.includes(FormField.name) ? errors[FormField.name] : undefined }
        />
      }
      { !showFields.includes(FormField.host) ? '' :
        <TextControl
          required
          disabled={ disabledFields.includes(FormField.host) }
          name="host"
          label="Host"
          placeholder="Enter host"
          value={ state.host }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.host, event.target.value) }
          error={ showErrors.includes(FormField.host) ? errors[FormField.host] !== undefined : false }
          helperText={ showErrors.includes(FormField.host) ? errors[FormField.host] : undefined }
        />
      }
      { !showFields.includes(FormField.vhost) ? '' :
        <TextControl
          required
          disabled={ disabledFields.includes(FormField.vhost) }
          name="vhost"
          label="vHost"
          placeholder="Enter vHost"
          value={ state.vhost }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.vhost, event.target.value) }
          error={ showErrors.includes(FormField.vhost) ? errors[FormField.vhost] !== undefined : false }
          helperText={ showErrors.includes(FormField.vhost) ? errors[FormField.vhost] : undefined }
        />
      }
      { !showFields.includes(FormField.ssl) ? '' :
        <SwitchControl
          isDisabled={ disabledFields.includes(FormField.ssl) }
          name="ssl"
          label="Use SSL"
          value={ state.ssl }
          onChange={ (value: boolean) => handleChange(FormField.ssl, value) }
          error={ showErrors.includes(FormField.ssl) ? errors[FormField.ssl] : undefined }
        />
      }
      { !showFields.includes(FormField.port) ? '' :
        <TextControl
          required
          disabled={ disabledFields.includes(FormField.port) }
          name="port"
          label="Port"
          placeholder="Enter port"
          type="number"
          value={ state.port }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            handleChange(FormField.port, value.replace(digitalRegExp, ''));
          } }
          error={ showErrors.includes(FormField.port) ? errors[FormField.port] !== undefined : false }
          helperText={ showErrors.includes(FormField.port) ? errors[FormField.port] : undefined }
        />
      }
      { !showFields.includes(FormField.exchange) ? '' :
        <TextControl
          required
          name="exchange"
          label="Exchange"
          placeholder="Enter exchange"
          value={ state.exchange }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.exchange, event.target.value) }
          error={ showErrors.includes(FormField.exchange) ? errors[FormField.exchange] !== undefined : false }
          helperText={ showErrors.includes(FormField.exchange) ? errors[FormField.exchange] : undefined }
        />
      }
      { !showFields.includes(FormField.exchangeDurable) ? '' :
        <SwitchControl
          isDisabled={ disabledFields.includes(FormField.exchangeDurable) }
          name="exchangeDurable"
          label="Exchange is durable"
          value={ state[FormField.exchangeDurable] }
          onChange={ (value: boolean) => handleChange(FormField.exchangeDurable, value) }
          error={ showErrors.includes(FormField.exchangeDurable) ? errors[FormField.exchangeDurable] : undefined }
        />
      }
      { !showFields.includes(FormField.exchangeType) ? '' :
        <SingleSelectControl
          name="exchangeType"
          label="Exchange type"
          selected={ state[FormField.exchangeType] }
          values={ ExchangeTypes }
          changeHandler={ (value?: string) => handleChange(FormField.exchangeType, String(value)) }
          error={ showErrors.includes(FormField.exchangeType) ? errors[FormField.exchangeType] : undefined }
        />
      }
      { !showFields.includes(FormField.queue) ? '' :
        <TextControl
          required
          name="queue"
          label="Routing key"
          placeholder="Enter routing key"
          value={ state.queue }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => (handleChange(FormField.queue, event.target.value)) }
          error={ showErrors.includes(FormField.queue) ? errors[FormField.queue] !== undefined : false }
          helperText={ showErrors.includes(FormField.queue) ? errors[FormField.queue] : undefined }
        />
      }
      { !showFields.includes(FormField.login) ? '' :
        <TextControl
          required
          name="login"
          label="Login"
          placeholder="Enter login"
          value={ state.login }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.login, event.target.value) }
          error={ showErrors.includes(FormField.login) ? errors[FormField.login] !== undefined : false }
          helperText={ showErrors.includes(FormField.login) ? errors[FormField.login] : undefined }
        />
      }
      { !showFields.includes(FormField.password) ? '' :
        <TextControl
          required
          name="password"
          label="Password"
          placeholder="Enter password"
          type="password"
          value={ state.password }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.password, event.target.value) }
          error={ showErrors.includes(FormField.password) ? errors[FormField.password] !== undefined : false }
          helperText={ showErrors.includes(FormField.password) ? errors[FormField.password] : undefined }
        />
      }
      { !showFields.includes(FormField.passwordRepeat) ? '' :
        <TextControl
          required
          name="passwordRepeat"
          label="Repeat password"
          placeholder="Enter password"
          type="password"
          value={ state.passwordRepeat }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => handleChange(FormField.passwordRepeat, event.target.value) }
          margin="normal"
          error={ showErrors.includes(FormField.passwordRepeat) ? errors[FormField.passwordRepeat] !== undefined : false }
          helperText={ showErrors.includes(FormField.passwordRepeat) ? errors[FormField.passwordRepeat] : undefined }
        />
      }
    </form>
  );
};
