import { GetHistoryParams, History, INITIAL_HISTORY_PARAMS } from 'models/rabbit';
import * as Actions from 'actions/rabbit';
import { Repository } from 'utils/repository';

export interface State {
  isAutoSync: boolean;
  params: GetHistoryParams;
  repository: Repository<History, GetHistoryParams, 'id'>;
}

const initState: State = {
  isAutoSync: false,
  params: INITIAL_HISTORY_PARAMS,
  repository: Repository.create('id'),
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.FETCH_RABBIT_HISTORY_START:
      return {
        ...state,
        params: action.params,
        repository: state.repository.setFetched(action.params, false),
      };
    case Actions.FETCH_RABBIT_HISTORY_FAILED:
      return { ...state, repository: state.repository.setFetched(action.params, true) };
    case Actions.FETCH_RABBIT_HISTORY_SUCCESS:
      return {
        ...state,
        repository: state.repository
          .setFetched(action.params, true)
          .setTotal(action.params, action.total)
          .setData(action.params, action.data)
      };
    case Actions.SYNC_RABBIT_HISTORY_START:
      return { ...state, isAutoSync: true };
    case Actions.SYNC_RABBIT_HISTORY_STOP:
      return { ...state, isAutoSync: false };
    default:
      return state;
  }
};
