import * as positionsActions from 'actions/device-management/positions';

import {
  PositionState,
  initPositionsFilters,
} from 'models/device-management';

const initState: PositionState = {
  filters: initPositionsFilters,
  positionsById: {},
  positionsByLocation: {
    data: [],
    isFetching: false,
  },
  isFetching: false,
  positionsFetching: false,
  error: undefined,
};

export const reducer = (state: PositionState = initState, action: positionsActions.Action): PositionState => {
  switch (action.type) {
    case positionsActions.UPDATE_POSITION_SUCCESS:
      return {
        ...state,
        positionsById: {
          ...state.positionsById,
          [action.position.id]: action.position,
        },
      };
    case positionsActions.FIND_POSITIONS:
      return { ...state, positionsFetching: true };
    case positionsActions.FIND_POSITIONS_FAILURE:
      return { ...state, positionsFetching: false, error: action.error };
    case positionsActions.FIND_POSITIONS_SUCCESS:
      return {
        ...state,
        positionsById: {
          ...state.positionsById,
          ...action.payload,
        },
        positionsFetching: false,
        error: undefined,
      };
    case positionsActions.FIND_POSITIONS_BY_LOCATION:
      return {
        ...state,
        positionsByLocation: {
          ...state.positionsByLocation,
          isFetching: true,
        }
      };
    case positionsActions.FIND_POSITIONS_BY_LOCATION_SUCCESS:
      return {
        ...state,
        positionsByLocation: {
          ...state.positionsByLocation,
          data: action.positions,
          isFetching: false
        }
      };
    case positionsActions.FIND_POSITIONS_BY_LOCATION_FAILED:
      return {
        ...state,
        positionsByLocation: {
          ...state.positionsByLocation,
          isFetching: false
        }
      };

    default: return state;
  }
};
