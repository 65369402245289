import { Amplify, Auth } from 'aws-amplify';
import { ApiGConfig } from 'models';

import { config as callsConfig } from './http-calls/config';
import { config as callerMonitoringConfig } from './http-monitoring';
import { config as rabbitConfig } from './rabbit';
import { config as rabbitMonitoringConfig } from './rabbit-monitoring';
import { config as deviceConfig } from './device-management';
import { config as rawMessagesConfig } from './raw-messages';
import { config as monitoringConfig } from './monitoring';
import { config as connectivityConfig } from './connectivity';
import { config as userManagementConfig } from './user-management';
import { config as baseStationsConfig } from './base-stations';
import { config as statusConfig } from './base-stations-status';
import { config as parkingConfig } from './parking';
import { config as parkingMonitoringConfig } from './parking-monitoring';
import { config as provisioningConfig } from './provisioning/config';
import { config as fwmConfig } from './firmware-management';
import { config as deviceEmulation } from './device-emulation';
import { config as lorawan } from './lorawan';
import { config as projectSubscriptions } from './user-management/project-health-subsciption';

const addAuthHeader = (config: ApiGConfig) => {
  return {
    name: config.name,
    endpoint: config.endpoint,
    custom_header: async () => {
      /** for cognito login param: */
      return {
        'X-Api-Key': (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken,
        'Content-Type': 'application/json',
      };
    },
  };
};

const clientOptions = {
  API: {
    endpoints: [
      addAuthHeader(monitoringConfig),
      addAuthHeader(callsConfig),
      addAuthHeader(callerMonitoringConfig),
      addAuthHeader(rabbitConfig),
      addAuthHeader(rabbitMonitoringConfig),
      addAuthHeader(deviceConfig),
      addAuthHeader(rawMessagesConfig),
      addAuthHeader(connectivityConfig),
      addAuthHeader(userManagementConfig),
      addAuthHeader(baseStationsConfig),
      addAuthHeader(statusConfig),
      addAuthHeader(parkingConfig),
      addAuthHeader(parkingMonitoringConfig),
      addAuthHeader(provisioningConfig),
      addAuthHeader(fwmConfig),
      addAuthHeader(deviceEmulation),
      addAuthHeader(lorawan),
      addAuthHeader(projectSubscriptions),
    ],
  },
};

export function configureAmplify(awsConfig: Record<string, unknown>) {
  Amplify.configure({
    ...awsConfig,
    ...clientOptions,
    Auth: {
      mandatorySignIn: true,
    }
  });
}
