import { shallowEqual } from 'react-redux';

import { GetUsersParams } from 'models/user-management';
import * as usersPageActions from 'actions/pages/users';
import { getUrlItems, UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';


export interface State {
  readonly filters: GetUsersParams;
}

export const DEFAULT_FILTERS = {
  email: undefined,
  limit: 20,
  offset: 0,
  permissions: undefined,
  projects: undefined,
  owner: undefined,
};

const initialState: State = {
  filters: { ...DEFAULT_FILTERS },
};

function getFiltersFromUrl(urlState: UrlItems): Partial<GetUsersParams> {
  const parser = new Parser(urlState as GetUsersParams);
  return {
    owner: parser.asNumber('owner') || undefined,
    projects: parser.asNumbers('projects') || [],
  };
}

export const reducer = (state: State = initialState, action: usersPageActions.Action): State => {
  switch (action.type) {
    case usersPageActions.SET_USERS_PAGE_FILTERS: {
      if (shallowEqual(state.filters, action.filters)) {
        return state;
      }

      return { ...state, filters: action.filters };
    }

    case usersPageActions.UPDATE_USERS_PAGE_FILTERS_FROM_URL: {
      const urlFilters = getUrlItems(Object.keys(state.filters));
      if (shallowEqual(state.filters, urlFilters)) {
        return state;
      }
      return { ...state, filters: { ...state.filters, ...getFiltersFromUrl(urlFilters) } };
    }
    default:
      return state;
  }
};
