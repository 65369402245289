import moment from 'moment';
import { groupBy } from 'lodash';
import { useCustomMemo } from 'hooks';

import { Endpoint } from 'models/rabbit';
import {
  GetSendingStatusStatisticParams,
  SendingStatusEnum,
  SendingStatusStatistic,
  SendingStatusStatisticGroupByEnum
} from 'models/rabbit-monitoring';
import { useLocationItems } from 'hooks/location';
import { useRabbitSendingStatusStatistic } from 'hooks/rabbit';
import { createDateRange, DateStep } from 'utils/datetime';
import { UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';
import { MonitoringSerie } from 'components/Charts';
import { FilterState } from './types';


interface MonitoringFilterState {
  filter: FilterState;
  setFilter: (state: FilterState) => void;
}

export const useFilterByLocation = (): MonitoringFilterState => {
  const location = useLocationItems();

  return {
    filter: buildState(location.items),
    setFilter: (state: FilterState) => location.setItems(state),
  };
};

const initialState: FilterState = {};
const buildState = (items: UrlItems): FilterState => {
  const parser = new Parser(items as FilterState);
  return {
    ...initialState,
    zoneIds: parser.asNumbers('zoneIds') ?? initialState.zoneIds,
    dateFrom: parser.asDate('dateFrom') ?? initialState.dateFrom,
    dateTo: parser.asDate('dateTo') ?? initialState.dateTo,
  };
};

interface DurationState {
  days: number;
  step: SendingStatusStatisticGroupByEnum;
}

export const calculateDuration = (dateFrom: Date, dateTo: Date = new Date()): DurationState => {
  const diff = moment.duration(moment(dateTo).diff(dateFrom));
  const result: DurationState = {
    days: diff.asDays(),
    step: SendingStatusStatisticGroupByEnum.Minute
  };
  switch (true) {
    case diff.asWeeks() > 10:
      result.step = SendingStatusStatisticGroupByEnum.Week;
      break;
    case diff.asDays() > 14:
      result.step = SendingStatusStatisticGroupByEnum.Day;
      break;
    case diff.asHours() > 3:
      result.step = SendingStatusStatisticGroupByEnum.Hour;
      break;
  }

  return result;
};

interface ChartSerie {
  total: MonitoringSerie['total'];
  data: MonitoringSerie['data'];
  maxValue: number;
}

export const generateChartSerie = (statistic: SendingStatusStatistic[], dates: Date[]): ChartSerie => {
  const result: ChartSerie = {
    data: [],
    total: 0,
    maxValue: 0,
  };

  for (const date of dates) {
    const stat = statistic.find(s => s.date.getTime() === date.getTime());
    const count = stat?.count ?? 0;
    result.total += count;
    result.maxValue = Math.max(result.maxValue, count);
    result.data?.push({
      x: moment(date).format('YYYY-MM-DD HH:mm'),
      y: count,
    });
  }

  return result;
};

interface ChartData {
  isLoading: boolean;
  data: MonitoringSerie[];
  maxValue: number;
  durationDays: number;
  durationStep: DateStep;
}

export const useSendingStatusChartData = (endpoint: Endpoint, filter: FilterState): ChartData => {
  const queryParams: GetSendingStatusStatisticParams = {
    endpointIds: [Number(endpoint.id)],
    zoneIds: filter.zoneIds,
    timeFrom: filter.dateFrom ?? moment().subtract(1, 'day').seconds(0).milliseconds(0).toDate(),
    timeTill: filter.dateTo,
  };
  const { days, step } = calculateDuration(queryParams.timeFrom as Date, queryParams.timeTill);
  queryParams.groupBy = step;

  const statistic = useRabbitSendingStatusStatistic(queryParams);
  const lineData = useCustomMemo(() => {

    const resultByStatus = groupBy(statistic.statistic, 'status');
    const chartDates = createDateRange(queryParams.timeFrom as Date, queryParams.timeTill, step);

    return {
      [SendingStatusEnum.Success]: generateChartSerie(resultByStatus[SendingStatusEnum.Success] ?? [], chartDates),
      [SendingStatusEnum.Failure]: generateChartSerie(resultByStatus[SendingStatusEnum.Failure] ?? [], chartDates),
    };
  }, [statistic.statistic, statistic.isLoading]);

  return {
    isLoading: statistic.isLoading,
    durationDays: days,
    durationStep: step,
    maxValue: Math.max(lineData[SendingStatusEnum.Success].maxValue, lineData[SendingStatusEnum.Failure].maxValue),
    data: [
      {
        id: SendingStatusEnum.Success,
        label: 'SUCCESS',
        color: '#00838f',
        ...lineData[SendingStatusEnum.Success],
      },
      {
        id: SendingStatusEnum.Failure,
        label: 'ERROR',
        color: '#d84315',
        ...lineData[SendingStatusEnum.Failure],
      }
    ]
  };
};