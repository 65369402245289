import { Warranty, WarrantyCreateBody } from 'models/provisioning';

export const FETCH_WARRANTY = 'provisioning/FETCH_WARRANTY';
export const FETCH_WARRANTY_SUCCESS = 'provisioning/FETCH_WARRANTY_SUCCESS';
export const FETCH_WARRANTY_FAILURE = 'provisioning/FETCH_WARRANTY_FAILURE';

export const FETCH_WARRANTY_BY_ID = 'provisioning/FETCH_WARRANTY_BY_ID';
export const FETCH_WARRANTY_BY_ID_SUCCESS = 'provisioning/FETCH_WARRANTY_BY_ID_SUCCESS';
export const FETCH_WARRANTY_BY_ID_FAILURE = 'provisioning/FETCH_WARRANTY_BY_ID_FAILURE';

export const WARRANTY_CREATE = 'provisioning/WARRANTY_CREATE';
export const WARRANTY_CREATE_SUCCESS = 'provisioning/WARRANTY_CREATE_SUCCESS';
export const WARRANTY_CREATE_FAILURE = 'provisioning/WARRANTY_CREATE_FAILURE';

export interface FetchWarranty {
  readonly type: typeof FETCH_WARRANTY;
}
export interface FetchWarrantySuccess {
  readonly type: typeof FETCH_WARRANTY_SUCCESS;
  readonly warranty: Warranty[];
}
export interface FetchWarrantyFailure {
  readonly type: typeof FETCH_WARRANTY_FAILURE;
}

export interface FetchWarrantyById {
  readonly type: typeof FETCH_WARRANTY_BY_ID;
  readonly id: string;
}
export interface FetchWarrantyByIdSuccess {
  readonly type: typeof FETCH_WARRANTY_BY_ID_SUCCESS;
  readonly warranty: Warranty;
}
export interface FetchWarrantyByIdFailure {
  readonly type: typeof FETCH_WARRANTY_BY_ID_FAILURE;
}

export interface CreateWarranty {
  readonly type: typeof WARRANTY_CREATE;
  warranty: WarrantyCreateBody;
}
export interface CreateWarrantySuccess {
  readonly type: typeof WARRANTY_CREATE_SUCCESS;
  warranty: Warranty;
}
export interface CreateWarrantyFailure {
  readonly type: typeof WARRANTY_CREATE_FAILURE;
}

export const DoFetchWarranty: () => FetchWarranty = () => ({
  type: FETCH_WARRANTY
});
export const DoFetchWarrantySuccess: (warranty: Warranty[]) => FetchWarrantySuccess = warranty => ({
  type: FETCH_WARRANTY_SUCCESS,
  warranty,
});

export const DoFetchWarrantyFailure: () => FetchWarrantyFailure = () => ({
  type: FETCH_WARRANTY_FAILURE
});

export const DoFetchWarrantyById: (id: string) => FetchWarrantyById = id => ({
  type: FETCH_WARRANTY_BY_ID,
  id
});

export const DoFetchWarrantyByIdSuccess: (warranty: Warranty) => FetchWarrantyByIdSuccess = warranty => ({
  type: FETCH_WARRANTY_BY_ID_SUCCESS,
  warranty
});

export const DoFetchWarrantyByIdFailure: () => FetchWarrantyByIdFailure = () => ({
  type: FETCH_WARRANTY_BY_ID_FAILURE
});


export const DoCreateWarranty: (warranty: WarrantyCreateBody) => CreateWarranty = warranty => ({
  type: WARRANTY_CREATE,
  warranty
});
export const DoCreateWarrantySuccess: (warranty: Warranty) => CreateWarrantySuccess = warranty => ({
  type: WARRANTY_CREATE_SUCCESS,
  warranty
});
export const DoCreateWarrantyFailure: () => CreateWarrantyFailure = () => ({
  type: WARRANTY_CREATE_FAILURE,
});


export type Action = (
  | FetchWarranty
  | FetchWarrantySuccess
  | FetchWarrantyFailure
  | FetchWarrantyById
  | FetchWarrantyByIdFailure
  | FetchWarrantyByIdSuccess
  | CreateWarranty
  | CreateWarrantySuccess
  | CreateWarrantyFailure
);
