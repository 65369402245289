import { useEffect, useState, useMemo } from 'react';
import { useLocationItems } from 'hooks/location';
import { Parser } from 'utils/routing/parser';
import { DEFAULT_LIMIT, DEFAULT_OFFSET, Pagination } from 'utils/paginator';
import { isNil } from 'utils/models';

type PaginationAPI = Omit<Pagination, 'rowCount'> & {
  maxReachedCount: number;
  onChangeMaxReachedCount: (maxCount: number) => void;
}

export const usePagination = (): PaginationAPI => {
  const { items, setItems } = useLocationItems({ updateMethod: 'replace' });
  const [maxCount, setMaxCount] = useState(0);

  const parser = useMemo(() => new Parser(items as { limit: number; offset: number, reset?: boolean }), [items]);
  const limit = parser.asNumber('limit') ?? DEFAULT_LIMIT;
  const offset = Math.abs(parser.asNumber('offset') || DEFAULT_OFFSET);
  const page = Math.trunc(offset / limit);

  const onChangePage = (newPage: number) => {
    if (page !== newPage) {
      setItems(prevItems => {
        return ({
          ...prevItems,
          offset: newPage * prevItems.limit
        });
      });
    }
  };

  const onChangeRowCount = (newLimit: number) => {
    if (limit === newLimit) {
      return;
    }
    // save correct offset. ex. (limit=50 offset=150) => (limit=20 offset=140)
    const _page = Math.floor(offset / newLimit);
    setItems(prevItems => ({
      ...prevItems,
      limit: newLimit,
      offset: _page * newLimit
    }));
  };

  // set default pagination options
  useEffect(() => {
    if (isNil(parser.asNumber('offset'))) {
      setItems(prevItems => ({ ...prevItems, offset }));
    }
    if (isNil(parser.asNumber('limit'))) {
      setItems(prevItems => ({ ...prevItems, limit }));
    }

    // reset max reached count after update filters @TODO try to refactor using ref
    if (isNil(parser.asNumber('offset')) && isNil(parser.asNumber('limit'))) {
      setMaxCount(0);
    }
  }, [offset, limit, maxCount, setItems, parser]);

  return {
    offset: offset,
    limit: limit,
    page: page,
    onChangePage,
    onChangeRowCount,
    maxReachedCount: maxCount,
    onChangeMaxReachedCount: setMaxCount,
  };
};