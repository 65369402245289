import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useTemplateSuiteMonitoringFilters } from 'components/Caller';
import {
  useCallerRoutesByParams,
  useTemplateLoadSelectorByParams,
  useTemplateSuiteSelector,
} from 'hooks/caller';
import { TemplateSuite, TemplateSuiteId } from 'models/caller';

// component
import { Box, Grid } from '@material-ui/core';

import {
  CallerTemplateSuiteZones,
  CallerTemplateSuiteTemplates,
  CallerTemplateSuiteMonitoring,
  CallerTemplateSuiteManagmentNew,
} from 'components/Caller';
import { PageLoader } from 'components/Loaders';

import { TemplateSuiteNotFound } from './NotFound';

// styles
import { useStyles } from './styles';

interface PageMetadataProps {
  templateSuiteId: TemplateSuiteId;
  templateSuite?: TemplateSuite;
}

const PageMetadata = ({ templateSuiteId, templateSuite }: PageMetadataProps) => {
  const templateSuiteLabel = templateSuite?.name
    ? `#${templateSuiteId} ${templateSuite?.name}`
    : `#${templateSuiteId}`;

  return (
    <Helmet>
      <title>
        { `Http Template Suite ${templateSuiteLabel} Detail` }
      </title>
    </Helmet>
  );
};

interface Props {
  templateSuiteId: TemplateSuiteId;
}

export const TemplateSuiteDetailPageNew = ({ templateSuiteId }: Props): JSX.Element => {
  const { templateSuite, isLoading } = useTemplateSuiteSelector(templateSuiteId);
  const routes = useCallerRoutesByParams({ templateSuiteId, limit: 1000 });
  const templates = useTemplateLoadSelectorByParams({ templateSuiteId, limit: 1000 });
  const monitoringFilters = useTemplateSuiteMonitoringFilters();
  const classes = useStyles();

  if (!templateSuite) {
    return (
      <>
        <PageMetadata templateSuiteId={ templateSuiteId } templateSuite={ templateSuite } />
        { isLoading
          ? <PageLoader />
          : <TemplateSuiteNotFound templateSuiteId={ templateSuiteId } />
        }
      </>
    );
  }

  return (
    <div data-testid="template-suite-detail-page">
      <PageMetadata templateSuiteId={ templateSuiteId } templateSuite={ templateSuite } />
      <Grid container spacing={ 1 }>
        <Grid item xs={ 12 } sm={ 12 }>
          <CallerTemplateSuiteManagmentNew
            templateSuite={ templateSuite }
            routesState={ routes }
            templatesState={ templates }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 } container>
          <Grid item xs={ 12 } md={ 3 }>
            <Box className={ classes.embedTableContainer } mr={ 0.5 }>
              <CallerTemplateSuiteZones
                className={ classes.embedTable }
                tableClassName={ classes.embedTableInner }
                templateSuite={ templateSuite }
                routesState={ routes }
              />
            </Box>
          </Grid>
          <Grid item xs={ 12 } md={ 9 }>
            <Box className={ classes.embedTableContainer } ml={ 0.5 }>
              <CallerTemplateSuiteTemplates
                className={ classes.embedTable }
                tableClassName={ classes.embedTableInner }
                templateSuiteId={ templateSuiteId }
                templatesState={ templates }
                onTemplateDelete={ deletedTemplateId => monitoringFilters.onChange(filters => {
                  if (Array.isArray(filters.templateIds) && filters.templateIds.includes(deletedTemplateId)) {
                    return {
                      ...filters,
                      templateIds: filters.templateIds.filter(templateId => {
                        return templateId !== deletedTemplateId;
                      }),
                    };
                  }
                  return filters;
                }) }
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={ 12 } sm={ 12 }>
          <CallerTemplateSuiteMonitoring
            templatesState={ templates }
            filters={ monitoringFilters.state }
            onFiltersChange={ monitoringFilters.onChange }
          />
        </Grid>
      </Grid>
    </div>
  );
};
