import React from 'react';
import { useProjectsDictionarySelector } from 'hooks/user-managment';
// components
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  id?: number;
  innerProps?: TypographyProps;
}

const defaultProps: TypographyProps = {
  variant: 'inherit',
};

const ProjectName = ({ id, innerProps }: Props) => {
  const { isLoading, projects } = useProjectsDictionarySelector();

  const project = projects.find(el => el.id === id);
  const projectName = project?.name || String(id ?? '-');
  const TypoProps = { ...defaultProps, ...innerProps };

  return (
    isLoading
      ? <LoaderSvg/>
      : <Typography { ...TypoProps }>{ projectName }</Typography>
  );
};

export default ProjectName;
