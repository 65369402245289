import { useDialog } from 'hooks';

// components
import { EnsureDialog } from 'components/Dialogs';

type TProps = {
  isActiveDevice?: boolean
  deviceId?: string
  onActivateDevice: () => Promise<unknown>
  onUpdated: () => void
  onError?: () => void
  onClose?: () => void
}

export const SendMessageUnactiveDeviceDialog = ({
  isActiveDevice,
  deviceId,
  onActivateDevice,
  onUpdated,
  onError,
  onClose,
}: TProps) => {
  const dialog = useDialog();

  const handleOk = () => {
    onActivateDevice().then(() => {
      onUpdated();
    }).catch(() => {
      onError && onError();
    });
  };

  const handleClose = () => {
    onClose && onClose();
    dialog.close();
  };

  if (!deviceId) {
    return null;
  }

  return (!isActiveDevice ?
    <EnsureDialog
      name="device-cant-send-messages"
      label="This device can't send messages"
      btnYes="Activate and proceed"
      isOpen={ true }
      onYes={ handleOk }
      onNo={ handleClose }
      onClose={ handleClose }
      onCloseEnd={ dialog.unmount }
    >
      {`Only active virtual devices are able to send messages. Activate device ${deviceId}?`}
    </EnsureDialog>
    : null);
};
