import React from 'react';
import { ResponsiblePerson, StationLocation } from 'models/base-station';
import { useDispatch } from 'react-redux';
import { AddLocationForResponsiblePerson } from 'actions/base-station';

// components
import { Box, IconButton, Popover } from '@material-ui/core';
import { AddBoxOutlined } from '@material-ui/icons';

// style
import { useStyles } from './TableComponentsStyle';

interface Props {
  anchorEl: Element | null;
  person: ResponsiblePerson;
  locations: StationLocation[];
  onClose: () => void;
}

export const AddLocationPopover = ({ anchorEl, person, locations, onClose }: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const set = (id: StationLocation['id']) => {
    onClose();
    dispatch(AddLocationForResponsiblePerson(id, person.id));
  };

  return (
    <Popover
      id={ `location-popover-${person.id}` }
      open={ anchorEl !== null }
      anchorEl={ anchorEl }
      onClose={ onClose }
      anchorOrigin={ {
        vertical: 'bottom',
        horizontal: 'center',
      } }
      transformOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
    >
      <Box className={ classes.popover }>
        {/*
        код добавления локации Set Location, если понадобится есть по ссылке
        https://bitbucket.org/nwaveio/http-caller-history/commits/338540dc4910cca26761fbbb616d2a5d21ccdabb
        номер коммита: 338540d
      */ }
        {
          locations.map(location => (
            <Box key={ location.id } display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" title={ `${ location.country }, ${ location.address }` }>
                { `${ location.country }, ${ location.address }` }
              </Box>
              <IconButton onClick={ () => set(location.id) }>
                <AddBoxOutlined className={ classes.lightBlue }/>
              </IconButton>
            </Box>
          ))
        }
      </Box>
    </Popover>
  );
};
