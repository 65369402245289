/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * HTTP Monitoring API
 * Provides access to HTTP caller hisotry statistics
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SendingStatus
 */
export interface SendingStatus {
    /**
     * @type {string}
     * @memberof SendingStatus
     */
    timestamp: string;
    /**
     * @type {string}
     * @memberof SendingStatus
     */
    status: SendingStatusStatusEnum;
    /**
     * @type {number}
     * @memberof SendingStatus
     */
    count: number;
}

/**
 * @export
 * @enum {string}
 */
export enum SendingStatusStatusEnum {
    Success = 'Success',
    Error = 'Error',
    Failed = 'Failed'
}

