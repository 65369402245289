import { RootState as State } from '../../reducers';
import { areDevicesLoading } from './devices';
import { arePositionsLoading } from './positions';
import { showParkingAnomaly } from 'helpers';

export function isFetching(state: State): boolean {
  return areDevicesLoading(state) || arePositionsLoading(state);
}

export function isShowParkingAnomaly(state: State): boolean {
  const type = state.deviceManagement.groups.data.find(group => showParkingAnomaly(group.type));
  return !!type;
}
