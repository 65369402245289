import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { ShipmentFormField, Shipment, ShipmentCreateBody, ShipmentStatusEnum } from 'models/provisioning';

// components
import { DateControl, OwnersSingleSelectControl, TextControl } from 'components/Controls';
import { Box } from '@material-ui/core';
import { ShipmentStatusSelector } from 'components/Provisioning/Controls/ShipmentStatusSelector';
import { WarrantyCustomSelect } from 'components/Provisioning/WarrantyCustomSelect';

// styles
import { useStyles } from './styles';

type FormValues = Shipment & ShipmentCreateBody;

export function ShipmentForm<T extends Shipment | ShipmentCreateBody>(props: FormProps<T, ShipmentFormField>): JSX.Element {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends ShipmentFormField>(
    field: TField,
    value: FormValues[TField],
  ) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  const arrivalDateLocked = showFields.includes(ShipmentFormField.status)
    && (state as Shipment).status === ShipmentStatusEnum.Dispatched;

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(ShipmentFormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(ShipmentFormField.name) }
          label="Shipment"
          name="shipment[name]"
          value={ state.name || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(ShipmentFormField.name, event.target.value)
          }
          error={ showErrors.includes(ShipmentFormField.name) ? errors?.name !== undefined : false }
          helperText={ showErrors.includes(ShipmentFormField.name) ? errors?.name : undefined }
        />
      }
      { !showFields.includes(ShipmentFormField.shipper_id) ? '' :
        <Box mt={ 1 }>
          <OwnersSingleSelectControl
            label="Sender"
            isRequired={ false }
            isClearable
            isDisabled={ isView || disabledFields.includes(ShipmentFormField.shipper_id) }
            name="shipment[sender]"
            selected={ state.shipper_id }
            error={ showErrors.includes(ShipmentFormField.shipper_id) && errors?.shipper_id }
            changeHandler={ sender => handleChange(ShipmentFormField.shipper_id, sender ?? 0) }
          />
        </Box>
      }
      { !showFields.includes(ShipmentFormField.recipient_id) ? '' :
        <Box mt={ 1 }>
          <OwnersSingleSelectControl
            label="Recipient"
            isRequired={ false }
            isClearable
            isDisabled={ isView || disabledFields.includes(ShipmentFormField.recipient_id) }
            name="shipment[recipient]"
            selected={ state.recipient_id }
            error={ showErrors.includes(ShipmentFormField.recipient_id) && errors?.recipient_id }
            changeHandler={ recipient => handleChange(ShipmentFormField.recipient_id, recipient ?? 0) }
          />
        </Box>
      }

      { !showFields.includes(ShipmentFormField.status) ? '' :
        <Box mt={ 1 }>
          <ShipmentStatusSelector
            selected={ (state as Shipment).status }
            isRequired
            isDisabled={ isView || disabledFields.includes(ShipmentFormField.recipient_id) }
            error={ showErrors.includes(ShipmentFormField.status) && errors?.status }
            selectStatus={ status => handleChange(ShipmentFormField.status, status) }
          />
        </Box>
      }

      { !showFields.includes(ShipmentFormField.sending_date) ? '' :
        <Box mt={ 1 }>
          <DateControl
            label="Sending Time"
            value={ state.sending_date }
            onChange={ (date?: Date) => handleChange(ShipmentFormField.sending_date, date) }
            isDisabled={ isView || disabledFields.includes(ShipmentFormField.sending_date) }
            error={ showErrors.includes(ShipmentFormField.sending_date) && errors?.sending_date }
          />
        </Box>
      }

      { !showFields.includes(ShipmentFormField.arrival_date) ? '' :
        <Box mt={ 1 }>
          <DateControl
            label="Arrival Time"
            value={ state.arrival_date }
            onChange={ (date?: Date) => handleChange(ShipmentFormField.arrival_date, date) }
            isDisabled={ isView || disabledFields.includes(ShipmentFormField.arrival_date) || arrivalDateLocked }
            error={ showErrors.includes(ShipmentFormField.arrival_date) && errors?.arrival_date }
            minDate={ state.sending_date }
          />
        </Box>
      }

      { !showFields.includes(ShipmentFormField.warranty) ? '' :
        <Box mt={ 2 }>
          <WarrantyCustomSelect
            selected={ (state as Shipment).warranty_id }
            isDisabled={ isView || disabledFields.includes(ShipmentFormField.shipper_id) }
            selectWarranty={ warranty => handleChange(ShipmentFormField.warranty, warranty) }
          />
        </Box>
      }
    </form>
  );
}

