import { userManagementSagas } from './user-management';
import { userGroupSagas } from './user-group';
import { userGroupServicesSagas } from './user-group-services';
import { apiKeySagas } from './api-key';
import { projectSagas } from './project';

export default [
  ...userManagementSagas,
  ...userGroupServicesSagas,
  ...userGroupSagas,
  ...apiKeySagas,
  ...projectSagas,
];
