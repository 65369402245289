import { TemplateSuite } from 'models/caller';
import { getLocation } from 'utils/routing/query';

const baseUrl = '/api/http/template-suites';
const suiteParam = '{suiteId}';

enum Path {
  list = '',
  create = '/create',
  detail = '/{suiteId}',
  clone = '/{suiteId}/clone',
  statistic = '/{suiteId}/statistic',
  templates = '/{suiteId}/templates',
}

export function getCallerTemplateSuiteListPath() {
  return baseUrl + Path.list;
}

export function getCallerTemplateSuiteCreatePath() {
  return baseUrl + Path.create;
}

export function getCallerTemplateSuiteDetailPath(suiteId: string) {
  return baseUrl + Path.detail.replace(suiteParam, suiteId);
}

export function getCallerTemplateSuiteClonePath(suiteId: string) {
  return baseUrl + Path.clone.replace(suiteParam, suiteId);
}

export function getCallerTemplateSuiteStatisticPath(suiteId: string) {
  return baseUrl + Path.statistic.replace(suiteParam, suiteId);
}

export function getCallerTemplateSuiteTemplatesPath(suiteId: string) {
  return baseUrl + Path.templates.replace(suiteParam, suiteId);
}

export function getCallerTemplateSuiteDetailLocation(templateSuiteId: TemplateSuite['template_suite_id']) {
  const path = getCallerTemplateSuiteDetailPath(String(templateSuiteId));
  return getLocation(path, {});
}
