/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Base Stations Status API
 * API for retireving Base Stations status
 *
 * The version of the OpenAPI document: 1.0.3
 * Contact: ealeshin@nwave.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Reason {
    Ok = 'ok',
    Created = 'created',
    InvalidField = 'invalid_field',
    NoData = 'no_data',
    NotFound = 'not_found',
    NotImplemented = 'not_implemented',
    Restricted = 'restricted',
    ServerError = 'server_error'
}

