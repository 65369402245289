import { MessageTypes } from './generated';

export type MessageType = MessageTypes;

export const OldMessageTypes: MessageType[] = [
  { id: 'all', name: 'All' },
  { id: 'regular', name: 'Status Change' },
  { id: 'heartbeat', name: 'Heartbeat' },
  { id: 'calibration', name: 'Calibration' },
  { id: 'registration', name: 'User registration' },
  { id: 'error', name: 'Error' },
  { id: 'general', name: 'General' },
];
