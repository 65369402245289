import * as Actions from 'actions/device-emulation/device-settings';
import { Repository } from 'utils/repository';
import { DeviceSettings, FindDeviceSettingsParam } from 'models/device-emulation';

export type State = Repository<DeviceSettings, FindDeviceSettingsParam, 'device_id'>;
const initState: State = Repository.create('device_id');

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.EMULATION_DEVICE_SETTINGS_DATA_LOAD_START:
      return state.setFetched(action.params, false);
    case Actions.EMULATION_DEVICE_SETTINGS_DATA_LOAD_FAILURE:
      return state.setFetched(action.params, true);
    case Actions.EMULATION_DEVICE_SETTINGS_DATA_LOAD_SUCCESS:
      return state
        .setFetched(action.params, true)
        .setData(action.params, action.data);
    case Actions.EMULATION_DEVICE_SETTINGS_CHANGE:
      return state.changeModel(
        action.settings.device_id,
        action.action === 'delete' ? undefined : action.settings
      );

    default:
      return state;
  }
};
