import { eventChannel } from 'redux-saga';
import { call, take, delay } from 'redux-saga/effects';

const createVisibilityChangeChannel = () => eventChannel<boolean>(emit => {
  const handleVisibilityChange = () => {
    emit(!document.hidden);
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
});

interface AutoRunOptions {
  intervalMs: number;
  noInitialDelay?: boolean;
}

export function* autoRunWhenVisible(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: () => Generator<any, any, any>,
  intervalMsOrOptions: number | AutoRunOptions,
) {
  const options: AutoRunOptions = typeof intervalMsOrOptions === 'object'
    ? intervalMsOrOptions
    : { intervalMs: intervalMsOrOptions };

  if (options.noInitialDelay) {
    yield call(callback);
  }

  const visibilityChangeChannel = createVisibilityChangeChannel();

  while (true) {
    if (options.intervalMs) {
      yield delay(options.intervalMs);
    }

    if (!document.hidden) {
      yield call(callback);
    } else {
      yield take(visibilityChangeChannel);
      yield call(callback);
    }
  }
}
