import { API } from 'aws-amplify';
import { ApiResponse } from 'models';
import { GetApiKeyParams, ApiKeyCreate, ApiKeyUpdate } from 'models/user-management';
import { errorHandler, formatQuery } from 'clients/utils';
import { config, urls } from './config';

export const fetchApiKeysByParams = (params: GetApiKeyParams): Promise<ApiResponse> => {
  const body = {
    queryStringParameters: formatQuery({
      name: params.name,
      owner_id: params.owner_id,
      permission_ids: params.permission_ids,
      limit: params.limit,
      offset: params.offset,
    }),
  };

  return API
    .get(config.name, urls.apiKeys, body)
    .catch(errorHandler);
};

export const fetchApiKeysById = (apiKeyId: string): Promise<ApiResponse> => {
  return API
    .get(config.name, urls.apiKeyById(apiKeyId), {})
    .catch(errorHandler);
};

export const createApiKey = (apiKey: ApiKeyCreate): Promise<ApiResponse> => {
  return API
    .post(config.name, urls.apiKeys, { body: apiKey })
    .catch(errorHandler);
};

export const updateApiKey = (apiKeyId: string, apiKey: ApiKeyUpdate): Promise<ApiResponse> => {
  return API
    .put(config.name, urls.apiKeyById(apiKeyId), { body: apiKey })
    .catch(errorHandler);
};

export const deleteApiKey = (apiKeyId: string): Promise<ApiResponse> => {
  return API
    .del(config.name, urls.apiKeyById(apiKeyId), {})
    .catch(errorHandler);
};
