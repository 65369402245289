import { difference } from 'lodash';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import { NotifyError } from 'actions/notifier';
import {
  createLabel,
  attachLabelsToPositions,
  detachLabelsFromPositions,
} from 'clients/device-management';
import { ApiResponse, ReasonEnum } from 'models';
import { LabelCreateFields, Label } from 'models/device-management';
import { createErrorFromApiResponse } from 'utils/errors';

export function useSetLabels() {
  const attachMutation = useMutation(throwIfNotOk(attachLabelsToPositions));
  const detachMutation = useMutation(throwIfNotOk(detachLabelsFromPositions));

  const dispatch = useDispatch();

  return async (positionId: number, prevLabelIds: number[], nextLabelIds: number[]) => {
    const labelsToAttach = getLabelsToAttach(prevLabelIds, nextLabelIds);
    const labelsToDetach = getLabelsToDetach(prevLabelIds, nextLabelIds);

    try {
      if (labelsToDetach.length) {
        await detachMutation.mutateAsync({ labels: labelsToDetach, positions: [positionId] });
      }
      if (labelsToAttach.length) {
        await attachMutation.mutateAsync({ labels: labelsToAttach, positions: [positionId] });
      }
    } catch (e) {
      dispatch(NotifyError(`Error while applying labels: ${ (e as Error).message }`));
      throw e;
    }
  };
}

function throwIfNotOk<
  TArgs extends unknown[],
  TApiResponse extends ApiResponse,
>(fn: (...args: TArgs) => Promise<TApiResponse>) {
  return async (...args: TArgs) => {
    const res = await fn(...args);

    if (res.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(res);
    }

    return res;
  };
}

export function getLabelsToAttach(prevLabelIds: number[], nextLabelIds: number[]): number[] {
  return difference(nextLabelIds, prevLabelIds);
}

export function getLabelsToDetach(prevLabelIds: number[], nextLabelIds: number[]): number[] {
  return difference(prevLabelIds, nextLabelIds);
}

export function useCreateLabel() {
  const createLabelMutation = useMutation(throwIfNotOk(createLabel));
  const dispatch = useDispatch();

  return async (params: LabelCreateFields): Promise<Label> => {
    try {
      const res = await createLabelMutation.mutateAsync(params);
      const label = res.data;

      return label;
    } catch (e) {
      dispatch(NotifyError(`Error while creating a label: ${ (e as Error).message }`));
      throw e;
    }
  };
}
