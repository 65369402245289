import React from 'react';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// components
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornment, IconButton } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
// styles
import clsx from 'clsx';
import contentStyles from 'styles';

interface Props {
  label: string;
  value?: Date | null;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange: (date?: Date) => void;
  error?: React.ReactNode;
  helperText?: React.ReactNode;
  isClearable?: boolean;
}

export const DateControl = (props: Props): JSX.Element => {
  const classes = contentStyles();

  const onChange = (date: MaterialUiPickersDate) => {
    props.onChange(date ? date.toDate() : undefined);
  };

  return (
    <MuiPickersUtilsProvider utils={ MomentUtils }>
      <DatePicker
        data-testid="date-control"
        label={ props.label }
        className={ clsx(classes.formControl, props.className) }
        error={ Boolean(props.error) }
        helperText={ props.error ?? props.helperText }
        maxDate={ props.maxDate ?? undefined }
        minDate={ props.minDate ?? undefined }
        autoOk={ false }
        disableFuture={ true }
        disabled={ props.isDisabled }
        required={ props.isRequired }
        value={ props.value ? props.value : null }
        format="YYYY/MM/DD"
        onChange={ onChange }
        allowKeyboardControl={ false }
        variant="dialog"
        fullWidth
        margin="normal"
        clearable={ props.isClearable }
        InputProps={ {
          endAdornment: !props.isDisabled && (
            <InputAdornment position="end">
              <IconButton>
                <DateRange />
              </IconButton>
            </InputAdornment>
          ),
        } }
      />
    </MuiPickersUtilsProvider>
  );
};
