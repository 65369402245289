import { History, Location, LocationDescriptor, LocationDescriptorObject } from 'history';
import { parse as parseQueryString } from 'query-string';
import parseUrl from 'url-parse';

import { buildUrlParams, parseUrlParams } from 'utils/routing/query';

// components
import { LinkProps } from 'react-router-dom';

export enum PredefinedParams {
  RETURN_URL = 'returnUrl',
}

export interface CustomLinkProps extends LinkProps {
  /**
   * If `true`, the component adds "returnUrl" hash parameter to its (target) path.
   * "returnUrl" parameter is equal to the current page's path and kept in sync with it.
   *
   * It's useful when we'd like to have backward navigation supporting link sharing
   * and opening a page in a new tab.
   */
  withReturnUrl?: boolean;
  activeAsText?: boolean;
}

function mapLocationDescriptorToObject(descriptor: LocationDescriptor): LocationDescriptorObject {
  if (typeof descriptor === 'object') {
    return descriptor;
  }

  return parseUrl(descriptor, parseQueryString);
}

export function mapToPropToLocationDescriptorObject(
  to: CustomLinkProps['to'],
  currentLocation: Location,
): LocationDescriptorObject {
  if (typeof to !== 'function') {
    return mapLocationDescriptorToObject(to);
  }

  return mapLocationDescriptorToObject(to(currentLocation));
}

function filterCurrentPageParams<T extends LocationDescriptorObject>(location: T): T {
  return {
    ...location,
    hash: buildUrlParams({
      ...parseUrlParams(location.hash || '', params => params),
      // the return url isn't about the current page, it's all about the previous page
      [PredefinedParams.RETURN_URL]: undefined,
    }),
  };
}

interface IsActiveLinkOptions {
  history: History;
  location: Location;
  to: CustomLinkProps['to'];
}

export function isLinkActive({ history, location, to }: IsActiveLinkOptions): boolean {
  // Our goal here is to determine whether link targets to the current page with its current state,
  // so we need to ignore all details representing previous pages, such as return url
  const currentLocation = filterCurrentPageParams(location);
  const targetLocation = filterCurrentPageParams(
    mapToPropToLocationDescriptorObject(to, currentLocation),
  );

  const currentHref = history.createHref(currentLocation);
  const targetHref = history.createHref(targetLocation);

  return currentHref === targetHref;
}
