import { combineReducers } from 'redux';

import * as template from './template';
import * as templateStatistic from './template-statistic';
import * as templatesRoutesSuites from './routes-templates-suites';
import * as templateSuite from './template-suite';

export interface State {
  template: template.State;
  templateStatistic: templateStatistic.State;
  templateSuite: templateSuite.State;
  templatesRoutesSuites: templatesRoutesSuites.State;
}

export const reducer = combineReducers<State>({
  template: template.reducer,
  templateStatistic: templateStatistic.reducer,
  templateSuite: templateSuite.reducer,
  templatesRoutesSuites: templatesRoutesSuites.reducer,
});
