import Cookies from 'js-cookie';
import { State as CurrentUserProfile } from 'reducers/user-profile';

function isFlagEnabled(flagName: string): boolean | undefined {
  const value = Cookies.get(`flags.${flagName}`);

  if (!value) {
    return undefined;
  }

  return value === '1' || value === 'true';
}

export function areRedesignedTemplateSuitesEnabled(): boolean {
  return isFlagEnabled('templateSuitesRedesign') ?? true;
}

export function isDmAllPageEnabled(userData: CurrentUserProfile): boolean {
  return userData.isAdmin || (isFlagEnabled('dmAllPage') ?? false);
}

export function isDmZonePageLevelsTabEnabled(): boolean {
  return isFlagEnabled('dmZonePageLevelsTab') ?? true;
}
