import React from 'react';
import { RouteConfig } from 'react-router-config';
import { State as CurrentUserProfile } from 'reducers/user-profile';
import { isDmAllPageEnabled } from 'utils/feature-flags';
import * as paths from 'routing/paths';
import { DeviceManagementAllPage, ZoneDetailPage, LevelDetailPage, GroupDetailPage, PositionDetailPage } from 'pages/DeviceManagement';
import { ZoneLabel, LevelLabel, GroupLabel, PositionLabel } from 'components/Labels';
import { getParam } from 'utils/routing';

enum Params {
  zoneId = 'zoneId',
  levelId = 'levelId',
  groupId = 'groupId',
  positionId = 'positionId',
}

export const getDeviceManagementRedesignRoutes = (userData: CurrentUserProfile): RouteConfig[] => {
  return [
    {
      path: paths.getDMListPath(),
      label: 'Device Management',
      hiddenOnMainMenu: true,
      isShowByUrl: isDmAllPageEnabled(userData),
      render: ({ match }) => match.isExact && <DeviceManagementAllPage/>,
      routes: [
        {
          path: paths.getDMZoneDetailPath(`:${ Params.zoneId }`),
          label: ({ match }) => match && (
            <ZoneLabel
              noLink
              zoneId={ Number(getParam(Params.zoneId, match)) }
              emptyName={ `#${ getParam(Params.zoneId, match) }` }
            />
          ),
          render: ({ match }) => match.isExact && (
            <ZoneDetailPage
              zoneId={ match.params[Params.zoneId] }
            />
          ),
          hiddenOnMainMenu: true,
          isShowByUrl: isDmAllPageEnabled(userData),
          routes: [
            {
              path: paths.getDMLevelDetailPath(`:${ Params.zoneId }`, `:${ Params.levelId }`),
              label: ({ match }) => match && (
                <LevelLabel
                  noLink
                  levelId={ Number(getParam(Params.levelId, match)) }
                  name={ getParam(Params.levelId, match) === '0' ? 'no level' : `#${ getParam(Params.levelId, match) }` }
                />
              ),
              render: ({ match }) => match.isExact && (
                <LevelDetailPage
                  zoneId={ match.params[Params.zoneId] }
                  levelId={ match.params[Params.levelId] }
                />
              ),
              hiddenOnMainMenu: true,
              isShowByUrl: isDmAllPageEnabled(userData),
              routes: [
                {
                  path: paths.getDMGroupDetailPath(`:${ Params.zoneId }`, `:${ Params.levelId }`, `:${ Params.groupId }`),
                  label: ({ match }) => match && (
                    <GroupLabel
                      noLink
                      groupId={ Number(getParam(Params.groupId, match)) }
                      name={ `#${ getParam(Params.groupId, match) }` }
                    />
                  ),
                  render: ({ match }) => match.isExact && (
                    <GroupDetailPage
                      zoneId={ match.params[Params.zoneId] }
                      levelId={ match.params[Params.levelId] }
                      groupId={ match.params[Params.groupId] }
                    />
                  ),
                  hiddenOnMainMenu: true,
                  isShowByUrl: isDmAllPageEnabled(userData),
                  routes: [
                    {
                      path: paths.getDMPositionDetailPath(
                        `:${ Params.zoneId }`,
                        `:${ Params.levelId }`,
                        `:${ Params.groupId }`,
                        `:${ Params.positionId }`,
                      ),
                      label: ({ match }) => match && (
                        <PositionLabel
                          noLink
                          positionId={ Number(getParam(Params.positionId, match)) }
                          name={ `#${ getParam(Params.positionId, match) }` }
                        />
                      ),
                      render: ({ match }) => match.isExact && (
                        <PositionDetailPage
                          zoneId={ match.params[Params.zoneId] }
                          levelId={ match.params[Params.levelId] }
                          groupId={ match.params[Params.groupId] }
                          positionId={ match.params[Params.positionId] }
                        />
                      ),
                      hiddenOnMainMenu: true,
                      isShowByUrl: isDmAllPageEnabled(userData),
                    },
                  ]
                },
              ]
            },
          ]
        },
      ]
    }
  ];
};
