import React from 'react';
// components
import { Typography } from '@material-ui/core';
import { DeviceLink } from 'components/Links';

interface Props {
  deviceIds: string[];
}

export const CreateVirtualDeviceSusses = ({ deviceIds }: Props): JSX.Element => {
  return (
    <Typography>
        You have created&nbsp;
      {
        deviceIds
          .map((deviceId): React.ReactNode => {
            return (
              <DeviceLink
                key={ deviceId }
                deviceId={ deviceId }
                innerProps={ { color: 'inherit' } }
                underline="always"
              />
            );
          })
          .reduce((prev, curr) => [prev, ', ', curr])
      }
      &nbsp;virtual { deviceIds.length === 1 ? 'device' : 'devices' }
    </Typography>
  );
};
