import { Project } from 'models/user-management';
import { useProjectsDictionarySelector } from 'hooks/user-managment';

// components
import { InfoDialog, EnsureDialog } from 'components/Dialogs';

interface Props {
  project: Project;
  isEmptyProject: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onYes: () => void;
}

export const DeleteDialog = ({
  project,
  isEmptyProject,
  isOpen,
  onClose,
  onCloseEnd,
  onYes,
}: Props): JSX.Element => {
  const { projects, isLoading } = useProjectsDictionarySelector();
  const isLastProject = projects.filter(p => p.owner_id === project?.owner_id).length === 1;

  if (isLoading) {
    return <InfoDialog
      name="projects-does-not-load"
      label="Projects not loaded"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
    >
      Wait for a few seconds or try to reload page.
    </InfoDialog>;
  }

  if (!isEmptyProject) {
    return (<InfoDialog
      name="delete-empty-project"
      label="Project is not empty"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
    >
      Project is not empty. Please, move or delete all included station locations and zones before deletion.
    </InfoDialog>);
  }

  if (isLastProject) {
    return (<InfoDialog
      name="delete-last-project"
      label="Last project"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
    >
      Can not delete last project.
    </InfoDialog>);
  }

  return (<EnsureDialog
    name="delete-project"
    label="Delete project"
    onYes={ onYes }
    isOpen={ isOpen }
    onClose={ onClose }
    onCloseEnd={ onCloseEnd }
  >
    Are you sure you want to delete project <b>{project.name}</b>?
  </EnsureDialog>);
};
