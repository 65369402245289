import { ReactNode } from 'react';
import { DoCreateBoxBulk } from 'actions/provisioning';
import { useForm, useFormActionLoader, useFormEditActions } from 'hooks/form';
import { Box } from 'models/provisioning';
import { FormProps } from 'types/form';
import { CreateBoxField, CreateBoxState } from './types';

interface Props {
  onCancel: () => void;
  onSuccess: (boxes: Box[]) => void;
}

interface State {
  form: FormProps<CreateBoxState, CreateBoxField>;
  actions: ReactNode;
}

const defaultValues: CreateBoxState = {
  ownerId: undefined,
  quantity: 1,
};

export const useCreateBoxForm = ({ onSuccess, onCancel }: Props): State => {
  const form = useForm<CreateBoxState, CreateBoxField>({
    initialState: defaultValues,
    showFields: [
      CreateBoxField.ownerId,
      CreateBoxField.quantity,
    ],
  });

  const { doAction } = useFormActionLoader();
  const actions = useFormEditActions({
    canEdit: true,
    btnSave: 'Create',
    canSave: form.isChange,
    onSave: () => {
      if (!form.validate()) {
        return false;
      }

      doAction({
        action: DoCreateBoxBulk({ owner_id: form.state.ownerId }, form.state.quantity),
        onError: form.catchError,
        onSuccess: onSuccess,
      });
    },
    onCancel: () => {
      form.reset();
      onCancel();
    },
  });

  return { form, actions };
};
