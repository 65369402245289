import { merge } from 'lodash';
import { fade, Theme } from '@material-ui/core/styles';

import { colors } from 'styles/themes/outer';
import { createStyles, makeStyles } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '425px',
    },
    headerLocationTitle: {
      fontSize: '16px',
      textTransform: 'none',
    },
    blockLocationContentRoot: {
      height: '340px',
    },
    headerPersonTitle: {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase'
    },
    stationLocationRoot: {
      paddingLeft: '14px',
      paddingRight: '14px',
    },
    stationLocationEmptyRoot: {
      paddingLeft: '14px',
      paddingRight: '14px',
      height: '100%',
    },
    stationLocationActions: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    stationManagementHeader: {
      ...headerStyles(theme, false),
      padding: 0,
      paddingTop: '8px',
      marginBottom: '6px',
      borderBottom: '1px solid #979797',
    },
    blockPersonHeaderRoot: {
      ...headerStyles(theme, false),
      paddingBottom: 0,
    },
    blockPersonContentRoot: {
      overflow: 'hidden',
    },
    dialogTabLabel: {
      fontSize: '16px',
      textTransform: 'none',
    },
    personsList: {
      marginTop: '-2px',
      paddingTop: 0,
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    person: {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
    emptyPersons: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2),
      fontSize: '14px',
      color: colors.textDisabled,
    },
    textOutlined: {
      padding: '3px 7px',
      marginLeft: '10px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: colors.mainPrimary,
      fontSize: '10px'
    },
    buttonUnbind: {
      color: theme.palette.primary.main,
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '15px',
      paddingBottom: '11px',
      textTransform: 'uppercase',
      wordWrap: 'normal',
      fontSize: '14px',
    },
    buttonDelete: {
      color: theme.palette.error.main,
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '15px',
      paddingBottom: '11px',
      textTransform: 'uppercase',
      wordWrap: 'normal',
      fontSize: '14px',
    },
    buttonSet: {
      padding: theme.spacing(1.5, 3, 1, 3),
      textTransform: 'uppercase',
      wordWrap: 'normal',
    },
  }),
);

export default useStyles;

const headerStyles = (theme: Theme, showBorder = true) => {
  return merge(
    showBorder ? leftBorder('17px', '33px') : undefined,
    {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    showBorder ? ({
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: fade('#979797', 0.23),
    } as const) : undefined,
  );
};

const leftBorder = (top: string, height: string) => {
  const styles = {
    content: '""',
    left: 0,
    top: top,
    height: height,
    position: 'absolute',
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: fade('#979797', 0.23),
  };

  return {
    position: 'relative',
    '&:before': styles,
  } as const;
};
