import { merge } from 'lodash';

import { getMuiTheme } from 'styles/themes';
import { makeStyles, createStyles } from 'styles/utils';

export const useStyles = makeStyles(_theme => createStyles({
  embedTableContainer: {
    height: '100%',
    minHeight: '368px',
  },
  embedTable: {
    height: '100%',
  },
  embedTableInner: {
    height: '100%',
  },
}));

interface TableThemeOptions {
  loading: boolean;
  empty: boolean;
}

export const getTemplateSuitePageTableTheme = ({ loading, empty }: TableThemeOptions) => {
  const theme = getMuiTheme({ embeddedTable: true });

  const emptyTableOptions = {
    overrides: {
      MuiTableRow: {
        hover: {
          '&:hover': {
            backgroundColor: '#fff !important',
          },
        }
      },
      MUIDataTableBodyCell: {
        root: {
          borderBottom: 'none',
        },
      },
      MUIDataTableFooter: {
        root: {
          display: 'none',
        },
      },
      MUIDataTableBody: {
        emptyTitle: {
          paddingTop: '6px',
          paddingBottom: '6px',
          fontSize: '14px',
          fontWeight: 500,
          color: theme.palette.primary.main,
          opacity: '54%',
          textAlign: 'left',
        }
      },
    },
  };

  return merge(
    {},
    theme,
    !loading && empty && emptyTableOptions,
  );
};
