import { ProvisioningBoxesFilters } from 'models/provisioning';

// components
import { CustomIdSelect, GroupedFilters } from 'components/Controls';

// styles
import useStyles from 'styles/filters';
import { ShipmentsSelector } from './Controls/ShipmentsSelector';
import { ShowBoxesSelector } from './Controls/ShowBoxesSelector';

interface Props {
  state: ProvisioningBoxesFilters;
  setState: (filters: ProvisioningBoxesFilters) => void;
}

const BoxesTableFilter = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { state, setState } = props;

  const handleSelectOwner = (id?: number) => setState({ ...state, owner: Number.isInteger(id) ? [id as number] : [] });

  return (
    <form className={ classes.gridRoot }>
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner[0] }
        isZoneShow={ false }
        isGroupsShow={ false }
        isProjectShow={ false }
      />
      <CustomIdSelect
        selected={ state.device_id }
        changeHandler={ device_id => setState({ ...state, device_id }) }
        name="Device"
        type="hex"
      />
      <CustomIdSelect
        selected={ state.box_id.map(id => String(id)) }
        changeHandler={ box_id => setState({ ...state, box_id: box_id.map(id => Number(id)) }) }
        name="Box"
        type="number"
      />
      <ShipmentsSelector
        isMulti
        isClearable
        selected={ state.shipment_id }
        onChange={ shipment_id => setState({ ...state, shipment_id }) }
      />
      <ShowBoxesSelector
        selected={ state.empty }
        onChange={ empty => setState({ ...state, empty }) }
      />
    </form >
  );
};

export default BoxesTableFilter;
