import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FilterState } from './types';
import { isEmpty } from 'lodash';
import { initialFilters } from './utils';
import { checkTimeFromToRelation } from 'utils/datetime';
import { Duration } from './types';

// components
import { FilterField } from './types';
import { ParkingStateFilter } from './ParkingStateFilter';
import { FilterButton } from 'components/Buttons';
// styles
import usePositionsStyles from '../style';
import useStyles from 'styles/page';

const validateState = (state: FilterState): Record<FilterField, string> => {
  const errors: Record<FilterField, string> = {} as Record<FilterField, string>;
  if (state.duration === Duration.CUSTOM) {
    if (!state.timeFrom && !state.timeTo) {
      errors[FilterField.timeTo] = 'Time From/To required for custom duration';
      errors[FilterField.timeFrom] = 'Time From/To required for custom duration';
    } else if (state.timeFrom && state.timeTo) {
      const notValidRelation = checkTimeFromToRelation(state.timeTo, state.timeFrom);
      if (notValidRelation) {
        errors[FilterField.timeTo] = notValidRelation;
        errors[FilterField.timeFrom] = notValidRelation;
      }
    }
  }

  return errors;
};

type Props = {
  isFetching: boolean
  filter: FilterState
  onApply?: (filter: FilterState) => void
}

export const OccupancyPageFilter = ({
  isFetching,
  filter,
  onApply,
}: Props) => {
  const classes = { ...useStyles(), ...usePositionsStyles() };

  const [state, setState] = useState<FilterState>(filter);
  const errors = validateState(state);
  const onSave = () => { onApply && onApply(state); };
  const onReset = () => {
    onApply && onApply(initialFilters);
    setState(initialFilters);
  };

  // filter sync
  useDeepCompareEffect(() => {
    setState(filter);
  }, [filter]);

  return (<div className={ classes.toolbar }>
    <FilterButton
      isLoading={ isFetching }
      isValid={ isEmpty(errors) }
      filter={ state }
      onSave={ onSave }
      onReset={ onReset }
    >
      <ParkingStateFilter
        errors={ errors }
        state={ state }
        setState={ setState }
      />
    </FilterButton>
  </div>);
};
