import React from 'react';
// components
import Save from '@material-ui/icons/Save';
import CommonButton, { CommonButtonProps } from './CommonButton';

export type SaveButtonProps = Omit<CommonButtonProps, 'icon' | 'label' | 'type'> & {
  label?: CommonButtonProps['label'];
  type?: CommonButtonProps['type'];
};

export const SaveButton = (props: SaveButtonProps): JSX.Element => {
  const label = props.label !== undefined ? props.label : 'Save';
  const type = props.type !== undefined ? props.type : 'icon';
  return <CommonButton
    { ...props }
    type={ type }
    label={ label }
    icon={ type === 'text' ? undefined : <Save /> }
  />;
};

export default SaveButton;
