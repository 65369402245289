import React from 'react';

// components
import { Grid, Paper } from '@material-ui/core';
import { FieldSkeleton, TableSkeleton } from 'components/Skeleton';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';

export const LevelSkeleton = (): JSX.Element => {
  return (
    <Grid container spacing={ 1 } >
      <Grid item xs={ 12 }>
        <Paper>
          <FieldSkeleton/>
        </Paper>
      </Grid>
      <Grid item xs={ 12 }>
        <Paper>
          <FieldSkeleton/>
        </Paper>
      </Grid>
      <Grid item xs={ 12 }>
        <Paper>
          <MuiThemeProvider theme={ getMuiTheme({ embeddedTable: true }) }>
            <TableSkeleton
              rows={ 5 }
              cols={ 1 }
              options={ {
                elevation: 0,
                pagination: false,
              } }
            />
          </MuiThemeProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};