import MomentUtils from '@date-io/moment';
import React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// components
import { KeyboardTimePicker, MuiPickersUtilsProvider, KeyboardTimePickerProps } from '@material-ui/pickers';
import { Schedule as ScheduleIcon } from '@material-ui/icons';

interface Props {
  className?: string;
  label: string;
  value?: Date | string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date | undefined, textValue?: string) => void;
  onBlur?: () => void;
  error?: string;
  helperText?: string;
  timePickerProps?: Partial<KeyboardTimePickerProps>;
  visualTimePickerDisabled?: boolean;
}

export const TimeControl = (props: Props): JSX.Element => {
  const onChange = (date: MaterialUiPickersDate, textValue: string | null | undefined) => {
    props.onChange(
      date?.isValid() ? date.clone().set({ ms: 0 }).toDate() : undefined,
      textValue ?? undefined,
    );
  };

  return (
    <MuiPickersUtilsProvider utils={ MomentUtils }>
      <KeyboardTimePicker
        data-testid="time-control"
        className={ props.className }
        label={ props.label }
        error={ Boolean(props.error) }
        helperText={ props.error ?? props.helperText }
        minutesStep={ 1 }
        autoOk={ false }
        ampm={ false }
        disabled={ props.isDisabled }
        required={ props.isRequired }
        value={ props.value ? props.value : null }
        format="HH:mm"
        onChange={ onChange }
        variant="inline"
        fullWidth
        margin="normal"
        onBlur={ props.onBlur }
        keyboardIcon={ <ScheduleIcon /> }
        KeyboardButtonProps={
          (props.isDisabled || props.visualTimePickerDisabled)
            ? { style: { display: 'none' } }
            : undefined
        }
        { ...props.timePickerProps }
      />
    </MuiPickersUtilsProvider>
  );
};
