import React, { useState } from 'react';
import { useFormActionLoader } from 'hooks';
import { CreateApiKey } from 'actions/user-management';
import { ApiKey, Permissions } from 'models/user-management';
import { UserPayload } from 'actions/user-profile';
import { parseArrayAsNum } from 'helpers';
import { integrationName } from 'utils/lorawan';
import { createPermissions } from 'actions/user-management/permissions';
import { IntegrationData } from 'models/lorawan';

// components
import { CommonDialog } from 'components/Dialogs';
import { Button, Typography, Box, Link } from '@material-ui/core';
import { ReadOnlyInputCopyControl } from 'components/Controls';

interface Props {
  integration: IntegrationData['title'];
  documentation: IntegrationData['documentation'];
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  user: UserPayload;
  preSets?: {
    url?: IntegrationData['url'];
    apiKey?: IntegrationData['apiKey'];
  };
  reload: () => void;
}

export const AddIntegrationDialog = (props: Props): JSX.Element => {
  const { integration, isOpen, onClose, onCloseEnd, preSets, user, reload, documentation } = props;
  const { doAction } = useFormActionLoader();
  const [generatedUrl, setGeneratedUrl] = useState<string>(preSets?.url || '');
  const [generatedKey, setGeneratedKey] = useState<ApiKey['key']>(preSets?.apiKey?.key || '');

  const permission: Permissions = {
    name: integrationName(integration),
    owner_id: Number(user.user_group),
    id: 0,
  };

  const handleGenerate = () => {
    doAction({
      action: createPermissions(permission),
      onSuccess: (res: Permissions) => {
        const newApiKey: ApiKey = {
          id: '',
          key: '',
          name: integrationName(integration),
          owner_id: Number(user.user_group),
          permission_id: res.id,
          project_ids: user.projects === 'all' ? 'all' : parseArrayAsNum(user.projects || []),
          creation_time: '',
        };
        doAction({
          action: CreateApiKey(newApiKey),
          onSuccess: (result: ApiKey) => {
            setGeneratedKey(result.key);
            setGeneratedUrl(preSets?.url || '');
            reload();
          },
        });
      },
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ integration }
      content={
        <>
          <ReadOnlyInputCopyControl
            value={ generatedKey ? generatedUrl : '' }
            label="URL"
          />
          <ReadOnlyInputCopyControl
            value={ generatedKey }
            label="X-Auth-Token"
          />

          <Box mt={ 2 } mb={ 4 } display="block" ml={ -1 }>
            <Button
              onClick={ handleGenerate }
              color="primary"
              disabled={ !!generatedKey }
            >Generate Credentials</Button>
          </Box>

          <Typography>
            Copy the URL and X-Auth-Token fields and follow the steps
            in the instructions <Link href={ documentation } color="primary" target="_blank">View instructions</Link>
          </Typography>
        </>
      }
      actions={ <Button color="primary" onClick={ onClose }>close</Button> }
    />
  );
};
