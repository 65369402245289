import { Endpoint } from 'models/rabbit';

export const RABBIT_ENDPOINT_CREATE = 'RABBIT_ENDPOINT_CREATE';
export const RABBIT_ENDPOINT_UPDATE = 'RABBIT_ENDPOINT_UPDATE';
export const RABBIT_ENDPOINT_DELETE = 'RABBIT_ENDPOINT_DELETE';
export const RABBIT_ENDPOINT_TEST = 'RABBIT_ENDPOINT_TEST';
export const RABBIT_ENDPOINT_LOCK = 'RABBIT_ENDPOINT_LOCK';
export const RABBIT_ENDPOINT_UNLOCK = 'RABBIT_ENDPOINT_UNLOCK';
export const RABBIT_ENDPOINT_CHANGE = 'RABBIT_ENDPOINT_CHANGE';

export interface CreateEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_CREATE;
  endpoint: Endpoint;
}

export interface UpdateEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_UPDATE;
  endpoint: Endpoint;
}

export interface DeleteEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_DELETE;
  endpoint: Endpoint;
}

export interface TestEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_TEST;
  endpoint: Endpoint;
}

export interface LockEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_LOCK;
  endpointId: number;
}

export interface UnlockEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_UNLOCK;
  endpointId: number;
}

export interface ChangeEndpoint {
  readonly type: typeof RABBIT_ENDPOINT_CHANGE;
  endpoint: Endpoint;
  options: {
    update?: boolean;
    delete?: boolean;
    create?: boolean;
  };
}

export type Action =
  CreateEndpoint | UpdateEndpoint | DeleteEndpoint |
  LockEndpoint | UnlockEndpoint | ChangeEndpoint

export const CreateEndpoint: (endpoint: Endpoint) => CreateEndpoint = (endpoint: Endpoint) => ({
  type: RABBIT_ENDPOINT_CREATE, endpoint
});

export const UpdateEndpoint: (endpoint: Endpoint) => UpdateEndpoint = (endpoint: Endpoint) => ({
  type: RABBIT_ENDPOINT_UPDATE, endpoint
});

export const DeleteEndpoint: (endpoint: Endpoint) => DeleteEndpoint = (endpoint: Endpoint) => ({
  type: RABBIT_ENDPOINT_DELETE, endpoint
});

export const TestEndpoint = (endpoint: Endpoint): TestEndpoint => ({
  type: RABBIT_ENDPOINT_TEST, endpoint
});

export const LockEndpoint: (endpointId: number) => LockEndpoint = (endpointId: number) => ({
  type: RABBIT_ENDPOINT_LOCK, endpointId
});

export const UnlockEndpoint: (endpointId: number) => UnlockEndpoint = (endpointId: number) => ({
  type: RABBIT_ENDPOINT_UNLOCK, endpointId
});

export const ChangeEndpoint = (endpoint: Endpoint, options: ChangeEndpoint['options']): ChangeEndpoint => ({
  type: RABBIT_ENDPOINT_CHANGE, endpoint, options
});
