import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    daysGreen: {
      color: theme.status.ok
    },
    daysYellow: {
      color: theme.status.danger
    },
    daysRed: {
      color: theme.status.error
    }
  }),
);

export default useStyles;
