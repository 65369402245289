import { createStyles, makeStyles } from 'styles/utils';

export const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      cursor: 'pointer',
      transition: 'all 0.2s linear',
      color: 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        color: '#f44336',
      },
    },
  }),
);
