import * as Redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer, { RootState } from '../reducers';
import rootSaga from '../sagas';
import urlSync from '../urlSync';

interface PromiseResolverMeta<Result, Reason = Error> {
  promise: {
    resolve: (value: Result | PromiseLike<Result>) => void;
    reject: (reason: Reason) => void;
  };
}

export type ActionWithPromise<Action, Result> = Action & {
  meta: Action extends Record<'meta', Record<string, unknown>>
    ? Action['meta'] & PromiseResolverMeta<Result>
    : PromiseResolverMeta<Result>;
};

export function dispatchAsync<
  Action extends Redux.AnyAction,
  // the user chooses whether to care about the result type or not
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Result = any,
>(
  dispatch: Redux.Dispatch,
  action: Action,
): Promise<Result> {
  return new Promise((resolve, reject) => {
    const actionWithPromise: ActionWithPromise<Action, Result> = {
      ...action,
      meta: {
        ...action.meta,
        promise: { resolve, reject },
      },
    };

    dispatch(actionWithPromise);
  });
}

export type Store = Redux.Store<RootState>;

export function configureStore(): Store {
  const sagaMiddleware = createSagaMiddleware();
  const store = Redux.createStore(
    rootReducer,
    composeWithDevTools(Redux.applyMiddleware(urlSync, sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
