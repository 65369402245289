import { useState } from 'react';
import { PositionGroupLabel } from 'components/Labels';
import { PositionLink } from 'components/Links';

// components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  list?: number[];
  name: string;
  handleSelect: (name: string, value: number[]) => void;
}

export const DialogList = ({ list, name, handleSelect }: Props ): JSX.Element => {
  const [checked, setChecked] = useState<number[]>([]);

  const handleToggle = (id: number) => {
    const uniqueId = new Set(checked);
    uniqueId.has(id) ? uniqueId.delete(id) : uniqueId.add(id);
    setChecked(Array.from(uniqueId));
    handleSelect(name, Array.from(uniqueId));
  };

  const listItem = list?.length && list?.map(id => {
    return id && (
      <ListItem
        key={ id }
        dense
        button
        onClick={ () => handleToggle(id) }
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={ checked.indexOf(id) !== -1 }
            tabIndex={ -1 }
            disableRipple
            inputProps={{ 'aria-labelledby': String(id) }}
          />
        </ListItemIcon>
        <ListItemText>
          { name === 'groups'
            ? <PositionGroupLabel groupId={ id } />
            : <PositionLink positionId={ id } />
          }
        </ListItemText>
      </ListItem>
    );
  });
  return (
    <List>{ listItem }</List>
  );
};
