import { ActionTypes } from 'react-select';
import { SubscriptionParams, SubscriptionType, UserWithSubscriptionType } from 'models/user-management/projectHealthSubscriptions';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { SubscriptionTypeSelectControl } from './components/SubscriptionTypeSelectControl';
import { UserLink } from 'components/Links';
import { MuiThemeProvider } from '@material-ui/core/styles';

// styles
import { getTableTheme } from 'styles/themes';

interface Props {
  usersState: UserWithSubscriptionType[];
  handleSubscriptionTypeSelect: (userId: number, value: SubscriptionType[], actionType?: ActionTypes) => void
}

export const AdminView = ({ usersState, handleSubscriptionTypeSelect }: Props): JSX.Element => {

  const columns: MUIDataTableColumn[] = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: ([email, sub]: string[]) => <UserLink firstName={ email } lastName={ '' } sub={ sub } />,
      }
    },
    {
      name: 'name',
      label: 'User name',
    },
    {
      name: 'projectSubsription',
      label: 'Subscription type',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (subscriptions: SubscriptionParams[], tableMeta) => {
          const user = usersState.filter(user => user.id === data[tableMeta.rowIndex].userId)[0];
          return <SubscriptionTypeSelectControl
            closeMenuOnSelect={ false }
            isMulti
            selected={ subscriptions?.map(s => s.type) || [] }
            onChange={(value, action) => handleSubscriptionTypeSelect(user.id, value, action?.action) }
          />;
        },
      }
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    responsive: 'simple',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
  };

  const data = usersState.map(user => ({
    userId: user.id,
    email: [user.email, user.sub],
    name: `${user.first_name} ${user.last_name}`,
    projectSubsription: user.ProjectSubscriptions,
  }));

  return (
    <MuiThemeProvider theme={ getTableTheme() }>
      <MUIDataTable
        title={''}
        data={ data }
        columns={ columns }
        options={ options }
      />
    </MuiThemeProvider>
  );
};
