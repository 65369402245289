import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Query } from 'react-query/types/core/query';

const queryClient = new QueryClient();

export const configureQueryClient = (): QueryClient => queryClient;

export const clearQueryCache = async (cachePrefix: string | string[]): Promise<void> => {
  const client = configureQueryClient();
  const predicate = (query: Query): boolean => {
    const queryKey = String(query.queryKey[0] ?? '');
    const cacheKeys = typeof cachePrefix === 'string' ? [cachePrefix] : cachePrefix;
    for (const cacheKey of cacheKeys) {
      if (queryKey.startsWith(cacheKey)) {
        return true;
      }
    }
    return false;
  };
  // inactive query do not invalidate and do not refetch after activate, need research this case
  client.removeQueries({ inactive: true, predicate });
  return client.invalidateQueries({ predicate });
};

// get all keys by prefix only (ex: skip params in ['deviceManagement/groups', params])
export const getRelatedCacheKeys = (queryClient: QueryClient, targetKey: string) => {
  return queryClient.getQueryCache().getAll()
    .map(query => query.queryKey)
    .filter(key => Array.isArray(key) ? key.includes(targetKey) : key === targetKey);
};

interface Params {
  children: ReactNode;
}

export const ReactQueryProvider = ({ children }: Params): JSX.Element => {
  return (
    <QueryClientProvider client={ configureQueryClient() }>
      { children }
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;