import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import availableTimezones from './timezones.json';

// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

const getLabel = (timezone: string) => `(GMT${moment.tz(timezone).format('ZZ').slice(0, 3) + ':' + moment.tz(timezone).format('ZZ').slice(3)}) ${timezone.split('/').join(' - ')}`;

type Props =
  Partial<Omit<CommonProps<string>, 'options'>>
  & (SingleProps<string> | MultiProps<string>)
  & { filter?: (timezone: string) => boolean }

export const TimezoneSelectControl = (props: Props): JSX.Element => {
  const timezones = moment.tz.names();
  const filterCallback = props.filter ?? (() => (true));
  const options: Option<string>[] =
    timezones
      .filter(filterCallback)
      .filter(timezone => availableTimezones.includes(timezone))
      .sort((a, b) => Number(moment.tz(a).format('ZZ')) - Number(moment.tz(b).format('ZZ')))
      .map(timezone => ({
        value: timezone,
        label: getLabel(timezone)
      }));

  return (
    <SelectControl
      name="timezone"
      label="Timezone"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      onMenuOpen={() => {
        setTimeout(() => {
          if(props.selected) {
            const node = document.getElementsByClassName('ReactSelect-root__menu-list')[0];
            const dropdownMenu = ReactDOM.findDOMNode(node);
            (dropdownMenu as Element).scrollTo(0, options.findIndex(option => option.value === props.selected ) * 32);
          }
        },0);
      }}
      options={ options }
      isLoading={ timezones.length === 0 }
      checkSelected={ timezones.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
