import { Group, ExistingPosition } from 'models/device-management';

const __PositionFormFieldNames = {
  GEO_LAT: 'lat',
  GROUP: 'group_id',
  ZONE: 'zone_id',
  ID: 'id',
};

export const PositionFormFieldNames: Readonly<typeof __PositionFormFieldNames> = (
  __PositionFormFieldNames
);

export type PositionFormValues = (
  & Partial<Omit<ExistingPosition, 'group_id' | 'group_inner_id' | 'floor_number' | 'lat' | 'lon'>>
  & {
    zone_id?: Group['zone_id'];
    group_id?: ExistingPosition['group_id'];
    group_inner_id: ExistingPosition['group_inner_id'] | '';

    lat: number | string;
    lon: number | string;
    floor_number: number | string;
  }
);
