import { useLocationItems } from 'hooks/location';
import { Parser } from 'utils/routing/parser';
import { UrlItems } from 'utils/routing/query';
import { EnumZoneFilterFields as Fields, Zone, ZoneFilters, ZoneFiltersParams } from 'models/device-management';
import { useZones } from 'hooks/device-management';

interface PageData {
  isLoading: boolean;
  zones: Zone[];
  total: number;
}

export const useZonesByFilters = (params: ZoneFiltersParams): PageData => {
  const { zones, isLoading, total } = useZones({ params });

  return {
    isLoading: isLoading,
    zones,
    total,
  };
};

interface ZonePageFilter {
  filter: ZoneFilters;
  onChange: (state: ZoneFilters) => void;
}

export const useFilterByLocation = (): ZonePageFilter => {
  const location = useLocationItems();
  const filter = buildState(location.items);

  return {
    filter,
    onChange: (state: ZoneFilters) => location.setItems(state),
  };
};

const buildState = (items: UrlItems): ZoneFilters => {
  const parser = new Parser(items as ZoneFilters);

  return {
    owner: parser.asNumber(Fields.owner),
    zones: parser.asNumbers(Fields.zones) ?? [],
    projects: parser.asNumbers(Fields.projects) ?? [],
    liveStatus: parser.asBoolean(Fields.liveStatus),
  };
};
