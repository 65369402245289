import { combineReducers } from 'redux';

import * as rule from './rule';
import * as endpoint from './endpoint';
import * as history from './history';

export interface State {
  rule: rule.State;
  endpoint: endpoint.State;
  history: history.State;
}

export const reducer = combineReducers<State>({
  rule: rule.reducer,
  endpoint: endpoint.reducer,
  history: history.reducer,
});
