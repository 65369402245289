import { useQuery } from 'react-query';
import { fetchDevices } from 'clients/device-management';
import { Tree, QueryType } from './types';
import { findGroupNode, getPositionId, useErrorNotifier } from './utils';
import { getResponse } from 'utils/clients';

export const useDeviceResult = (zoneIds: number[], tree: Tree, queryHash: string): Tree => {
  const notify = useErrorNotifier();
  const result = useQuery({
    queryKey: ['dmTree', QueryType.device],
    queryHash: `deviceManagement/devices/${ queryHash }`,
    queryFn: async () => getResponse(await fetchDevices({
      zones: zoneIds,
      force: false,
      limit: 9999,
    })),
    cacheTime: 10 * 60 * 1000, // 10 min
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => notify(`Error while fetching devices: ${ (e as Error).message }`),
    enabled: tree.loaded.zone && zoneIds.length > 0
  });

  if (result.isSuccess) {
    tree.loaded.device = true;
    for (const device of result.data?.data ?? []) {
      const groupNode = findGroupNode(tree, Number(device.zone_id), Number(device.level_id), Number(device.group_id));
      if (!groupNode) {
        continue;
      }

      groupNode.devices.total++;
      groupNode.devices.data[device.device_id.toUpperCase()] = device;

      if (device.position_id) {
        const positionId = getPositionId(device.position_id);
        const positionNode = groupNode.children.items[positionId] ?? null;
        if (positionNode) {
          positionNode.device = device;
        }
      }
    }
  }

  return tree;
};