import { ShipmentStatusEnum, DeviceStatusEnum, Device } from './generated';

export const ShipmentState: { [key in ShipmentStatusEnum]: string } = {
  created: 'Created',
  received: 'Delivered',
  dispatched: 'Dispatched',
  in_a_warehouse: 'In a warehouse',
}

export const DeviceProvisioningState: { [key in DeviceStatusEnum]: string } = {
  produced: 'Produced',
  tested_successfully: 'Tested Successfully',
  rejected:'Rejected',
  added: 'Added',
  dispatched: 'Dispatched',
  in_a_warehouse: 'In a warehouse',
  received: 'Received',
  installed: 'Installed',
  uninstalled: 'Uninstalled',
  returned_to_nwave: 'Returned',
  disposed: 'Disposed',
  restored: 'Restored'
}

export type AwsDevice = {
  id: string;
  device_id: string;
  dev_eui: string;
}

export type ShipmentDeviceForAws = Device & Omit<Partial<AwsDevice>, "id"> & { aws_id?: string }

export type AwsDeviceProfile = { id: string; name: string }

export type AwsProvisioningDevice = {
  device_id: string
  device_profile_id: string
  service_profile_id: string
  otaav1_0_x: {
    app_key?: string
    app_eui: string
  }
}

export enum ShipmentFormField {
  name = 'name',
  sending_date = 'sending_date',
  source = 'source',
  arrival_date = 'arrival_date',
  destination = 'destination',
  shipper_id = 'shipper_id',
  recipient_id = 'recipient_id',
  status = 'status',
  warranty = 'warranty_id',
}

export enum BoxFormField {
  shipment_id = 'shipment_id',
  owner_id = 'owner_id',
  id = 'id',
}

export enum WarrantyFormField {
  name = 'name',
  max_keeping_in_warehouse = 'max_keeping_in_warehouse',
  max_warranty_after_install = 'max_warranty_after_install',
}
