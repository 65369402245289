import { shallowEqual, useSelector } from 'react-redux';

import { Incident } from 'models/device-monitoring';
import { getDeviceIncidents } from 'pages/DevicePositionCouple/utils';
import { RootState } from 'reducers';
import { getIncidentIds, getTotalIncidents } from 'selectors/monitoring';

interface DeviceIncidentsHookOptions {
  deviceId?: string;
}

export const DEVICE_INCIDENTS_TO_SHOW = 5;

interface DeviceIncidents {
  recentIncidents?: Incident[];
  totalIncidents?: number;
}

export function useDeviceIncidents({ deviceId }: DeviceIncidentsHookOptions): DeviceIncidents {
  const filters = deviceId ? getDeviceIncidents(deviceId).filters : undefined;

  const recentIncidents = useSelector((state: RootState) => {
    if (!filters) {
      return;
    }

    const incidentIds = getIncidentIds(filters, state);
    const recentIncidents = incidentIds
      ?.flatMap(id => {
        const incident = state.incidents.incidentsById[id];
        return incident ? [incident] : [];
      })
      .slice(0, DEVICE_INCIDENTS_TO_SHOW);

    return recentIncidents;
  }, shallowEqual);

  const totalIncidents = useSelector((state: RootState) => {
    return filters && getTotalIncidents(filters, state);
  });

  return {
    recentIncidents,
    totalIncidents,
  };
}
