import { call, put, takeEvery } from 'redux-saga/effects';

import { ApiResponse, ReasonEnum } from 'models';
import { getTemplateSutes, getRouters, getTemplates, removeSuiteById, deleteRouter } from 'clients/http-calls';
import * as Action from 'actions/caller';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { TemplateSuite, Router, Template } from 'models/caller';
import { DoRoutesTemplatesSuitesLoadStop } from 'actions/caller';
import { ActionWithPromise } from 'utils/store';

function* doLoadRoutesAndTemplateSuitesByParams(action: Action.RoutesTemplatesSuitesLoad) {
  const suites: ApiResponse = yield call(getTemplateSutes, action.params);
  if (suites.reason !== ReasonEnum.Ok) {
    const message = suites.message || 'Server error';
    yield put(NotifyError(`Error while fetching caller template suites: ${message}`));
    yield put(DoRoutesTemplatesSuitesLoadStop());
    return;
  }

  const routers: ApiResponse = yield call(getRouters, { isActive: true });
  if (routers.reason !== ReasonEnum.Ok) {
    const message = routers.message || 'Server error';
    yield put(NotifyError(`Error while fetching caller routers: ${message}`));
  }

  const templates: ApiResponse = yield call(getTemplates, { limit: 9999 });
  if (templates.reason !== ReasonEnum.Ok) {
    const message = templates.message || 'Server error';
    yield put(NotifyError(`Error while fetching caller templates: ${message}`));
  }

  const suitesData: TemplateSuite[] = suites.data || [];
  const routersData: Router[] = routers.data || [];
  const templateData: Template[] = templates.data || [];

  yield put(DoRoutesTemplatesSuitesLoadStop(
    suitesData,
    routersData,
    templateData,
  ));
}

type DeleteAction =
  | Action.RoutesTemplateDelete
  | ActionWithPromise<Action.RoutesTemplateDelete, TemplateSuite>

function* doDelete(action: DeleteAction) {
  const templateSuiteId = action.templateSuite.template_suite_id;
  for (const router of action.routes) {
    const response: ApiResponse = yield call(deleteRouter, router);

    if (response.reason !== ReasonEnum.Ok) {
      const message = response.message || 'Server error';
      yield put(NotifyError(`Error while deleting Template Suite: ${message}`));
      'meta' in action && action.meta.promise.reject(new Error(message));
      return;
    }
  }

  const response: ApiResponse = yield call(removeSuiteById, templateSuiteId);

  if (response.reason === ReasonEnum.Ok) {
    yield put(NotifySuccess(`Caller template suite "${ action.templateSuite.name }" has been deleted`));
    'meta' in action && action.meta.promise.resolve(response.data);
    yield put(Action.ChangeTemplateSuite(action.templateSuite, { delete: true }));
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting caller template suite "${ action.templateSuite.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

export default [
  takeEvery(Action.ROUTES_TEMPLATE_SUITES_DATA_LOAD_BY_PARAMS, doLoadRoutesAndTemplateSuitesByParams),
  takeEvery(Action.ROUTES_TEMPLATE_DELETE, doDelete),
];
