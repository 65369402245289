import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { FormField, FormState } from './types';
// components
import {
  GroupSelectControl,
  RabbitEndpointsSelectControl,
  RabbitMessageTypesSelectControl,
  SwitchControl,
  ZoneSelectControl
} from 'components/Controls';

export type RoutingRuleFormState = FormState;
export {
  FormField as RoutingRuleFormField,
};

type Props = FormProps<FormState, FormField>
export const RoutingRuleForm = (props: Props): JSX.Element => {
  const { state, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];
  const errors = props.errors ?? {};

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(FormField.zone) ? '' :
        <ZoneSelectControl
          isRequired
          isDisabled={ disabledFields.includes(FormField.zone) }
          label="Zone name"
          placeholder="Choose zone"
          selected={ state[FormField.zone] }
          onChange={ (zone?: number) => handleChange(FormField.zone, zone) }
          error={ showErrors.includes(FormField.zone) ? errors[FormField.zone] : undefined }
        />
      }
      { !showFields.includes(FormField.group) ? '' :
        <GroupSelectControl
          isDisabled={ disabledFields.includes(FormField.group) }
          selected={ state[FormField.group] }
          zoneIds={ state[FormField.zone] ? [Number(state[FormField.zone])] : [] }
          onChange={ (group?: number) => handleChange(FormField.group, group) }
          error={ showErrors.includes(FormField.group) ? errors[FormField.group] : undefined }
        />
      }
      { !showFields.includes(FormField.endpoint) ? '' :
        <RabbitEndpointsSelectControl
          isRequired
          isDisabled={ disabledFields.includes(FormField.endpoint) }
          selected={ state[FormField.endpoint] }
          onChange={ (group?: number) => handleChange(FormField.endpoint, group) }
          error={ showErrors.includes(FormField.endpoint) ? errors[FormField.endpoint] : undefined }
        />
      }
      { !showFields.includes(FormField.messageType) ? '' :
        <RabbitMessageTypesSelectControl
          isRequired
          isDisabled={ disabledFields.includes(FormField.messageType) }
          placeholder="Choose message type"
          selected={ state[FormField.messageType] }
          onChange={ (value?: string) => handleChange(FormField.messageType, value) }
          error={ showErrors.includes(FormField.messageType) ? errors[FormField.messageType] : undefined }
        />
      }
      { !showFields.includes(FormField.activity) ? '' :
        <SwitchControl
          isDisabled={ disabledFields.includes(FormField.activity) }
          name="activity"
          label="Active"
          value={ state[FormField.activity] ?? false }
          onChange={ (value: boolean) => handleChange(FormField.activity, value) }
          error={ showErrors.includes(FormField.activity) ? errors[FormField.activity] : undefined }
        />
      }
    </form>
  );
};
