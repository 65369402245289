import { DatumValue } from '@nivo/line';
import moment, { Moment } from 'moment';
import 'moment-duration-format'; // setup moment inside module

export const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const DATE_WITH_MINUTES_FORMAT = 'YYYY/MM/DD HH:mm';
export const DATE_FORMAT = 'YYYY/MM/DD';

type CommonDate = string | Date | Moment;

export function formatDateTime(dateTime: CommonDate) {
  return moment(dateTime).format(DATETIME_FORMAT);
}

export function formatDate(dateTime: CommonDate) {
  return moment(dateTime).format('YYYY-MM-DD');
}

export function getOptionalDateInstance(dateTimeString?: Date | string) {
  return dateTimeString ? new Date(dateTimeString) : undefined;
}

export const formatFullDuration = (lastChange: string | Date) => {
  const dateChange = moment(lastChange);
  const duration = moment.duration(moment().diff(dateChange));
  return duration.format({
    template: 'y[y] M[m] d[d] h[h] m[min]',
    trim: 'both mid'
  });
};

export const getDuration = (lastChange?: string | Date) => {
  const today = moment();
  const diff = today.diff(lastChange);
  const difference = diff < 0 ? 0 : diff;
  const duration = moment.duration(difference);
  const lessThanHour = difference < 3600000;
  if (lessThanHour) {
    return duration.format({ template: 'm[min]' });
  } else {
    return duration.format({
      template: 'y[y] M[m] d[d] h[h]',
      trim: 'both mid' // trim 0 in date string: "1d 0h 6min" => "1d 6min"
    });
  }
};

export type DateStep =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

export const createDateRange = (dateFrom: Date, dateTo: Date = new Date(), step: DateStep) => {
  const end = moment(dateTo);
  const date = moment(dateFrom).utc(false).startOf(step);
  const dates: Date[] = [];
  for (; date.diff(end, step) <= 0; date.add(1, step)) {
    dates.push(date.toDate());
  }

  return dates;
};

export function formatAutoChangeOccupancyPeriod(periodMinutes: number): string {
  const duration = moment.duration(periodMinutes, 'minutes');

  return duration.format({
    template: 'd [days] h [hours] m [minutes]',
    trim: 'both mid',
  });
}

export function formatChartAxisDateTime(date: DatumValue) {
  const dateMoment = moment(date);

  if (!dateMoment.get('h') && !dateMoment.get('m')) {
    return dateMoment.format('DD/MM');
  }

  return dateMoment.format('HH:mm');
}

export interface DateRange {
  dateFrom: Date;
  dateTo: Date;
}

export interface DateRangeFormatParams {
  dateFrom: Date | string;
  dateTo: Date | string;
  showTime?: boolean;
}

export function formatDateRange({ dateFrom, dateTo }: DateRangeFormatParams): string {
  const dateFromMoment = moment(dateFrom);
  const dateToMoment = moment(dateTo);

  let dateFromFormatted: string;
  let dateToFormatted: string;

  if (dateFromMoment.format('HH:mm:ss.SSS') === '00:00:00.000') {
    dateFromFormatted = formatDate(dateFromMoment);
  } else {
    dateFromFormatted = dateFromMoment.format(`${DATE_FORMAT} / HH:mm`);
  }

  if (dateToMoment.format('HH:mm:ss.SSS') === '23:59:59.999') {
    dateToFormatted = formatDate(dateToMoment);
  } else {
    dateToFormatted = dateToMoment.format(`${DATE_FORMAT} / HH:mm`);
  }

  if (dateFromFormatted === dateToFormatted) {
    return dateFromFormatted;
  }

  if (dateFromMoment.isSame(dateToMoment, 'day')) {
    return `${dateFromFormatted} – ${dateToMoment.format(`HH:mm`)}`;
  }

  return `${dateFromFormatted} – ${dateToFormatted}`;
}

export function checkTimeFromToRelation(timeTo?: CommonDate | null, timeFrom?: CommonDate | null): string | undefined {
  if (!timeTo || !timeFrom) {
    return;
  }
  return (
    moment(timeTo).isBefore(moment(timeFrom))
      ? 'Time To is earlier than Time From'
      : undefined
  );
}
