import { Theme, fade } from '@material-ui/core/styles';
import { StylesConfig } from 'react-select/src/styles';

import outerTheme from 'styles/themes/outer';
import { createStyles, makeStyles } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      padding: 0,
      height: 35,
      cursor: 'pointer',
      margin: 0,
      '&[class*="Mui-disabled"]': {
        color: theme.palette.text.primary,
      },
      '&[class*="Mui-disabled"] $indicatorContainer': {
        display: 'none'
      }
    },
    multiLineInput: {
      minHeight: 35,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    multiLineValueContainer: {
      flexWrap: 'wrap',
    },
    indicatorContainer: {
    },
    label: {
      border: '1px solid'
    },
    chipFocused: {
      backgroundColor: theme.palette.background.default,
    },
    optionFocused: {
      backgroundColor: theme.palette.secondary.main,
    },
    option: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      }
    },
    optionMulti: {
      padding: '0px 2px',
    },
    optionMultiWithHelperText: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {},
    placeholder: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: fade(theme.palette.text.secondary, .38),
    },
    paper: {
      position: 'absolute',
      zIndex: 200,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    dropDown: {
      pointerEvents: 'none',
      minWidth: '20px',
    },
    divider: {
      height: theme.spacing(2),
    },
  }),
{ name: 'ReactSelect' });

export function customTheme<TValue, IsMulti extends boolean>(): StylesConfig<TValue, IsMulti> {
  return {
    menuPortal: (base: Record<string, unknown>) => ({
      ...base,
      zIndex: outerTheme.zIndex.modal + 1,
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      backgroundColor: 'transparent',
      padding: '0 2px',
      color: outerTheme.palette.text.primary,
      fontSize: 13,
      '&:hover': {
        backgroundColor: fade(outerTheme.palette.primary.main, 0.54),
      }
    })
  };
}

export default useStyles;
