import React from 'react';
import { useFormActionLoader } from 'hooks';
import { deleteZone } from 'actions/device-management/zones';
import { Zone } from 'models/device-management';

// components
import { EnsureDialog } from 'components/Dialogs';

interface Props {
  zone: Zone;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: (zone: Zone) => void;
}

export const DeleteZoneDialog = ({ zone, isOpen, onClose, onCloseEnd, onSuccess }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const handleConfirm = () => doAction({
    action: deleteZone(zone.id),
    onFinally: onClose,
    onSuccess: onSuccess,
  });

  return (
    <EnsureDialog
      name="dm-zone-delete-dialog"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
      btnYes="Delete"
      btnNo="Cancel"
      label={ <> Are you sure you want to delete zone <b>{ zone.name }</b></> }
    >
      The linked groups and positions will be deleted. The linked devices will be unbound.
    </EnsureDialog>
  );
};
