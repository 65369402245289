import { PositionWithLevel } from 'models/device-management';

import { fetchPositions } from '../../api';
import { DmApiFilters, PositionNode } from '../../types';
import { createQuery } from './queries';

export interface PositionNodesData {
  nodes: PositionNode[];
  total: number;
}

export async function createPositionNodes(
  filters: DmApiFilters
): Promise<PositionNodesData> {
  const { positions, total } = await fetchPositions(filters);
  const nodes = positions.map((p) => createPositionNode(p));

  return { nodes, total };
}

function createPositionNode(position: PositionWithLevel): PositionNode {
  return {
    id: `position-${position.id}`,
    type: 'position',
    position: position,
    positionLoadStatus: 'success',
    connectivity: createQuery(),
    device: createQuery(),
    deviceSilent: createQuery(),
  };
}
