import React from 'react';
import { formatDate } from 'utils/datetime';
// components
import { LineSvgProps, ResponsiveLine, Serie } from '@nivo/line';
import { LinearScale } from '@nivo/scales';
import { props as LineChartsOptions } from './ChartsOptions/LineChartsOptions';
import { CustomSliceTooltip } from './CustomSliceTooltip';
import { axisFormat } from './ChartsOptions/CommonOptions';

export interface Point {
  date: Date;
  value: number;
}

export interface Line {
  name: string;
  points: Point[];
  color: string;
}

interface Props {
  title: string;
  lines: Line[];
  options?: Partial<LineSvgProps>;
}

export const LinearAreaChart = (props: Props): JSX.Element => {
  const { title, lines, options } = props;
  const chartOptions: LineSvgProps = { ...LineChartsOptions, ...options };

  let maxValue = 0;
  const series: Serie[] = [];
  for (const line of lines) {
    series.push({
      id: line.name,
      data: line.points.map(point => ({
        x: formatDate(point.date),
        y: point.value <= 0 ? 0 : point.value
      }))
    });
    maxValue = line.points.reduce(
      (value, point) => point.value > value ? point.value : value,
      maxValue
    );
  }

  return (
    <ResponsiveLine
      { ...chartOptions }
      data={ series }
      colors={ lines.map(l => l.color) }
      sliceTooltip={ CustomSliceTooltip }
      yScale={ {
        ...chartOptions.yScale as LinearScale,
        max: maxValue === 0 ? 100 : 'auto'
      } }
      axisLeft={ { format: axisFormat, ...chartOptions.axisLeft } }
      axisTop={ { legend: title, ...chartOptions.axisTop } }
    />
  );
};

export default LinearAreaChart;
