import { Zone } from 'models/device-management';
import { useZones } from 'hooks/device-management';
// components
import { CommonProps, MultiProps, Option, SelectControl, SingleProps } from 'components/Controls/Select';

type Props =
  Partial<Omit<CommonProps<number>, 'options'>>
  & (SingleProps<number> | MultiProps<number>)
  & { filter?: (zone: Zone) => boolean }

export const ZoneSelectControl = (props: Props): JSX.Element => {
  const { zones } = useZones({ params: {} });
  const filterCallback = props.filter ?? (() => (true));
  const options: Option<number>[] =
    zones
      .filter(filterCallback)
      .map(zone => ({
        value: Number(zone.id),
        label: zone.name
      }));

  return (
    <SelectControl
      name="zoneId"
      label="Zone"
      closeMenuOnSelect={ !props.isMulti }
      { ...props }
      options={ options }
      isLoading={ zones.length === 0 }
      checkSelected={ zones.length > 0 }
      isDisabled={ props.isDisabled || !options.length }
    />
  );
};
