import React from 'react';
import { Endpoint, Rule } from 'models/rabbit';
import { useForm, useFormActionLoader } from 'hooks';
import { CreateRule } from 'actions/rabbit';
// components
import { Button } from '@material-ui/core';
import { CommonDialog, EnsureDialog } from 'components/Dialogs';
import { RoutingRuleForm, RoutingRuleFormField, RoutingRuleFormState } from 'components/Rabbit';

interface Props {
  endpoint: Endpoint;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const AddRuleDialog = ({ endpoint, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const [confirm, setConfirm] = React.useState<string | undefined>(undefined);
  const form = useForm<RoutingRuleFormState, RoutingRuleFormField>({
    initialState: {
      [RoutingRuleFormField.activity]: true,
    },
    showFields: [
      RoutingRuleFormField.zone,
      RoutingRuleFormField.messageType,
      RoutingRuleFormField.activity,
    ]
  });

  const handleSave = (agree: boolean) => {
    if (!form.validate()) {
      return false;
    }

    const rule: Rule = {
      id: 0,
      endpoint_id: endpoint.id,
      zone_id: form.state[RoutingRuleFormField.zone],
      message_type: form.state[RoutingRuleFormField.messageType],
      activity: form.state[RoutingRuleFormField.activity],
    };

    if (!ensureConfirm(rule, agree)) {
      return;
    }

    doAction({
      action: CreateRule(rule),
      onSuccess: () => onClose(),
      onError: form.catchError,
    });
  };

  const ensureConfirm = (rule: Rule, agree: boolean): boolean => {
    if (!rule.activity && !agree) {
      setConfirm('Are you sure you want to add an inactive route?');

      return false;
    }

    return true;
  };

  return (
    <>
      { confirm && (
        <EnsureDialog
          name={ 'ensure-create-rule' }
          isOpen={ true }
          onClose={ () => setConfirm(undefined) }
          onYes={ () => handleSave(true) }
          onNo={ () => handleSave(false) }
        > { confirm } </EnsureDialog>
      ) }
      <CommonDialog
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
        title={`Add new route > ${endpoint.name}` }
        content={ <RoutingRuleForm { ...form }/> }
        actions={
          <>
            <Button
              color="primary"
              onClick={ onClose }
            >
            Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={ () => handleSave(false) }
            >
            Add
            </Button>
          </>
        }
      />
    </>
  );
};
