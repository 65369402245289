import { memo } from 'react';
import { useSelector } from 'react-redux';
import { usePositionedDeviceSelector } from 'hooks/device-management';
import { Position, PositionedDeviceData } from 'models/device-management';
import { DeviceStatesV2 } from 'models/device-monitoring';

import { dmPositionPath } from 'routing/paths';
import { getMarkerIconDot } from 'utils/map';
import { RootState } from 'reducers';

// components
import { InfoWindow, Marker } from 'react-google-maps';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import PositionGroupName from 'components/DeviceManagement/Positions/PositionsTable/PositionGroupName';
import { ZoneLabel } from 'components';
import { CustomLink } from 'components/Links';

// styles
import useStyles from './MapStyle';

interface Props {
  position: Position;
  positionedDevice?: PositionedDeviceData;
  deviceState?: DeviceStatesV2;
  select?: number;
  handleSelect: (select: number | undefined) => void;
}
const MarkerMap = (props: Props) => {
  const classes = useStyles();

  const { position, select, handleSelect } = props;
  const { id, lat, lon: lng, group_inner_id: innerId, network_id: networkId, custom_id: customId } = position;

  const { device: positionedDevice } = usePositionedDeviceSelector(id);
  const deviceStates = useSelector((state: RootState) => state.deviceStates.data);
  const deviceState = positionedDevice && deviceStates.find((state: DeviceStatesV2) => state.device_id.toLowerCase() === positionedDevice.device_id.toLowerCase());
  const isDamaged = positionedDevice ? Boolean(positionedDevice.damaged_status) : false;
  const isPositioned = positionedDevice ? Boolean(positionedDevice.position_id) : false;
  const icon = getMarkerIconDot(isDamaged, isPositioned, deviceState);
  const handleClick = () => handleSelect(select === id ? undefined : id);

  return (
    <Marker
      onClick={ handleClick }
      position={ { lat, lng } }
      icon={ icon }
    >
      { select === id && (
        <InfoWindow onCloseClick={ () => handleSelect(undefined) }>
          <Box lineHeight={ 1 } className={ classes.content }>
            <Typography variant="subtitle2">
              Group: <Typography variant="caption">
                <PositionGroupName id={ position.group_id } />
              </Typography>
            </Typography>
            <Typography variant="subtitle2">
              Zone: <Typography variant="caption">
                <ZoneLabel zoneId={ position.zone_id } />
              </Typography>
            </Typography>
            <Typography variant="subtitle2">
              Inner ID: <Typography variant="caption">{ `${innerId}` || '-' }</Typography>
            </Typography>
            <Typography variant="subtitle2">
              Network ID: <Typography variant="caption">{ `${networkId}` || '-' }</Typography>
            </Typography>
            <Typography variant="subtitle2">
              Custom ID: <Typography variant="caption">{ customId && `${customId}` }</Typography>
            </Typography>
            <Typography variant="subtitle2">
              Device ID: <img src={ icon } alt="icon" /> <Typography variant="caption">
                { (positionedDevice && positionedDevice.device_id) || 'Position is free' }
              </Typography>
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              { id &&
                <Link
                  className={ classes.link }
                  component={ CustomLink }
                  color="primary"
                  to={ dmPositionPath(id) }
                  withReturnUrl
                >
                  Edit
                </Link>
              }
            </Box>
          </Box>
        </InfoWindow>
      ) }
    </Marker>
  );
};

export default memo(MarkerMap);
