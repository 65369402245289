import React, { useRef, useState } from 'react';

import { transformToHex, transformToNumber } from 'helpers';
import { KeyCode, stopPropagation } from 'utils/dom';

// components
import { Button, Checkbox, ListItemText, Menu, MenuItem, TextField, } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { SelectControl } from 'components/Controls/Select';
// styles
import contentStyles from 'styles';

interface Props {
  disabled?: boolean;
  changeHandler: (item: string[]) => void;
  selected: string[];
  type: 'hex' | 'string' | 'number';
  name: string;
  mapInputValue?: (value: string) => string;
}

type Event = React.ChangeEvent<{ value: unknown }>;

export const keyDown = (event: React.KeyboardEvent) => {
  if (event.keyCode === KeyCode.ESC) {
    return;
  }

  stopPropagation(event);
};

const CustomIdSelect = (props: Props): JSX.Element => {
  const classesBase = contentStyles();

  const { selected, name, type, mapInputValue } = props;
  const htmlName = name.replace(/ /g, '-').toLowerCase();

  const [input, setInputValue] = useState<{ value: string }>({ value: '' });
  const [list, setList] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLInputElement>(null);

  const textInput = useRef<HTMLInputElement>(null);

  // Init value from url
  if (selected.length && !list.length) {
    setList(selected);
  }

  function handleAddList(event: React.SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (input.value && !list.includes(input.value)) {
      setList([input.value, ...list]);
      setInputValue({ value: '' });
      props.changeHandler([...selected, input.value]);

      inputFocus();
    }
  }

  const inputFocus = () => {
    setTimeout(() => {
      if (textInput.current) {
        textInput.current.focus();
      }
    });
  };

  inputFocus();

  const handleSelectItem = (value: string, select: boolean) => {
    const values = select ? [...selected, value] : selected.filter(item => item !== value);
    props.changeHandler(values);
  };

  const setValueFormat = (value: string) => {
    if (type === 'hex') {
      return transformToHex(value as string);
    }
    if (type === 'string') {
      return value;
    }
    return transformToNumber(value as string);
  };

  const handleSetItemFromInput = (event: Event) => {
    const value = event.target.value as string;
    const mappedValue = mapInputValue ? mapInputValue(value) : value;
    setInputValue({ value: setValueFormat(mappedValue) as string });
  };

  return (
    <>
      <SelectControl
        isMulti
        isClearable
        isDisabled={ props.disabled }
        hideMenu
        name={ htmlName }
        label={ name }
        selected={ selected }
        options={ list.map((label) => ({ value: label, label })) }
        onChange={ values => props.changeHandler(values) }
        onMenuOpen={ () => setOpen(true) }
        dataTestId="custom-id-select"
        ControlProps={ { ref: anchorRef } }
      />
      <Menu
        open={ open }
        anchorEl={ anchorRef.current }
        onClose={ () => setOpen(false) }
        PaperProps={ { style: { minWidth: anchorRef?.current?.offsetWidth ?? 0 } } }
      >
        <form
          className={ classesBase.dropDownInput }
          onKeyDown={ keyDown }
          onClick={ stopPropagation }
          onSubmit={ handleAddList }
        >
          <TextField
            value={ input.value }
            autoFocus={ true }
            placeholder={ `Enter ${ name.replace(/\s+ID/, '') } ID` }
            onKeyDown={ keyDown }
            onClick={ stopPropagation }
            className={ classesBase.inputFullWidth }
            onChange={ handleSetItemFromInput }
            inputRef={ textInput }
          />
          <Button
            aria-label="Add"
            type="submit"
            disabled={ !input }
            color="primary"
            variant="contained"
            className={ classesBase.buttonAddInSelect }
            onKeyDown={ keyDown }
            onClick={ handleAddList }
          >
            <Add/>
          </Button>
        </form>
        { list.map((label, key) => (
          <MenuItem
            key={ key }
            value={ label }
            onClick={ () => handleSelectItem(label, !selected.includes(label)) }
          >
            <Checkbox checked={ selected.includes(label) }/>
            <ListItemText primary={ label }/>
          </MenuItem>
        )) }
      </Menu>
    </>
  );
};

export default CustomIdSelect;
