import React from 'react';
import { StationGeneration, StationGenerationTitles } from 'models/base-station';

interface Props {
  stationGeneration: StationGeneration;
}

export const BaseStationGeneration = ({ stationGeneration }: Props): JSX.Element => {
  const label = StationGenerationTitles.get(stationGeneration) ?? '-';

  return (
    <>{ label }</>
  );
};
