import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { DoFindPositionedDevicesRecursive } from 'actions/device-management/devices';
import { DoGetConnectivityByFilters } from 'actions/device-connectivity';
import { RootState } from 'reducers';
import { DeviceWithConnectivity } from 'models/device-management/dm';
import { PROJECT_DEFAULT_OPTION_VALUE } from 'components/Controls';
import { BSConnectivityMapFilters } from './types';

interface DevicessState {
  isLoading: boolean;
  devices: DeviceWithConnectivity[];
}

export const useDevicesByFilters = (filter: BSConnectivityMapFilters): DevicessState => {
  const dispatch = useDispatch();
  useEffectOnce(() => {
    dispatch(DoFindPositionedDevicesRecursive({ /* dm filters */ }));
    dispatch(DoGetConnectivityByFilters({ limit: 30000 }));
  });

  const { isLoading, devices } = useSelector((state: RootState) => ({
    isLoading: state.deviceManagement.devices.isFetching,
    devices: state.deviceManagement.devices.data,
  }));
  const devicesConnectivity = useSelector((state: RootState) => state.connectivity.connectivity);

  return {
    isLoading: isLoading,
    devices: devices
      .map(device => ({
        ...device,
        connectivity: devicesConnectivity.find(c => c.device_id === device.device_id)
      }))
      .filter(device => isDeviceFiltered(device, filter))
  };
};

const isDeviceFiltered = (device: DeviceWithConnectivity, filter: BSConnectivityMapFilters): boolean => {
  if (
    !isNil(filter.owner)
    && filter.owner !== device.owner_id
  ) {
    return false;
  }

  if (
    filter.zones?.length
    && (!device.zone_id || !filter.zones.includes(device.zone_id))
  ) {
    return false;
  }

  if (
    filter.positionGroups?.length
    && (!device.group_id || !filter.positionGroups.includes(device.group_id))
  ) {
    return false;
  }

  if (
    filter.projects?.length
    && !filter.projects.includes(device.project_id ?? PROJECT_DEFAULT_OPTION_VALUE)
  ) {
    return false;
  }

  if (filter.connectivity.length) {
    const [min, max] = filter.connectivity;

    // when connectivity is undefined we should count it as zero
    const connectivity = device.connectivity?.connectivity || 0;

    if (connectivity < min) {
      return false;
    }

    if (connectivity > max) {
      return false;
    }
  }

  return true;
};

