import React from 'react';
import { FormProps } from 'types/form';
import { useDeepCompareEffect } from 'react-use';
import { CreateRouterData, TemplateSuite } from 'models/caller';
import { validate } from './utils';

// components
import {
  ZoneSelectControl,
  TextControl
} from 'components/Controls';

export enum FormField {
  zone = 'zone_id',
}

type Props = FormProps<CreateRouterData, FormField> &
{ templateSuite: TemplateSuite; connectedZones: number[] };
export const ConnectZoneForm = (props: Props): JSX.Element => {
  const { state, showFields, onChange, templateSuite, connectedZones, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(props, showFields));
  }, [state, onValidate, showFields]);

  function handleZone(id?: number) {
    if (!id) {
      return;
    }

    onChange && onChange({ ...state, zone_id: id }, FormField.zone);
  }

  return (
    <form noValidate autoComplete="off">
      <TextControl
        disabled
        label="Template Suite"
        name="templateSuite"
        value={ `#${templateSuite.template_suite_id} / ${templateSuite.name}` }
      />
      { !showFields.includes(FormField.zone) ? '' :
        <ZoneSelectControl
          isRequired
          isClearable={ false }
          name="zone"
          selected={ state.zone_id }
          onChange={ (zoneId?: number) => handleZone(zoneId) }
          filter={ zone => !connectedZones.includes(zone.id) }
          error={ showErrors.includes(FormField.zone) ? errors[FormField.zone] : undefined }
        />
      }
    </form>
  );
};

