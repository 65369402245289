import { deviceManagementPositionsSaga } from './positions';
import { deviceManagementPositionsRepositorySagas } from './positions-repository';
import { deviceManagementDevicesSagas } from './devices';
import { deviceManagementGroupsSagas } from './groups';
import { deviceManagementZonesSagas } from './zones';
import { deviceManagementLevelsSagas } from './levels';
import dmVirtualDevicesSagas from './virtual-devices';
import deviceDownloadSagas from './device-download';

export default [
  ...deviceManagementPositionsSaga,
  ...deviceManagementPositionsRepositorySagas,
  ...deviceManagementDevicesSagas,
  ...deviceManagementGroupsSagas,
  ...deviceManagementZonesSagas,
  ...deviceManagementLevelsSagas,
  ...dmVirtualDevicesSagas,
  ...deviceDownloadSagas,
];
