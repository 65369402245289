import { useRef } from 'react';
import { shallowEqual } from 'react-redux';

/**
 * An alternative to `React.useMemo`:
 * - supports a custom deps equality function
 * - guarantees that the memo function will only be called if deps have changed
 * `React.useMemo` cannot be relied on to do this, since it is only a performance
 * optimization (see https://reactjs.org/docs/hooks-reference.html#usememo).
 */
export function useCustomMemo<TValue, TDeps extends unknown[]>(
  memoFn: () => TValue,
  deps: TDeps,
  areDepsEqual: (a: TDeps, b: TDeps) => boolean = shallowEqual,
) {
  const ref = useRef<{ deps: TDeps; value: TValue }>();

  if (!ref.current || !areDepsEqual(deps, ref.current.deps)) {
    ref.current = { deps, value: memoFn() };
  }

  return ref.current.value;
}