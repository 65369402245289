import React, { useState } from 'react';

import { User, UserRoleEnum } from 'models/user-management';

// components
import { FieldSkeleton } from 'components/Skeleton';

import ProjectsForm from './ProjectsForm';
import ProjectsReport from './ProjectsReport';

interface ProjectsProps {
  user?: User;
}

const Projects: React.FC<ProjectsProps> = (props) => {
  const [editing, setEditing] = useState(false);

  const { user } = props;

  if (user?.role === UserRoleEnum.Admin) {
    return null;
  }

  if (editing) {
    if (!user) {
      return <FieldSkeleton />;
    }

    return (
      <ProjectsForm
        {...props}
        user={user}
        onEditComplete={() => setEditing(false)}
      />
    );
  }

  return <ProjectsReport {...props} onEdit={() => setEditing(true)} />;
};

export default Projects;
