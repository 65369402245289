import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getLabels } from 'clients/device-management';
import { Label, GetLabelsParams } from 'models/device-management';
import { getResponseData } from 'utils/clients';

interface LabelsState {
  isLoading: boolean;
  isFetching: boolean;
  labels: Label[];
  refetch: () => Promise<unknown>;
  setLabelsLocally: (updateLabels: (prevLabels?: Label[]) => Label[]) => void;
}

interface GetLabelsHookOptions {
  params: GetLabelsParams;
  enabled?: boolean;
}

export function useLabels({ params, enabled }: GetLabelsHookOptions): LabelsState {
  const dispatch = useDispatch();

  const queryKey = ['deviceManagement/labels', params];
  const queryResult = useQuery({
    queryKey,
    queryFn: async () => getResponseData(await getLabels(params)),
    cacheTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      dispatch(NotifyError(`Error while fetching labels: ${ (e as Error).message }`));
    },
    enabled,
  });

  const queryClient = useQueryClient();

  return {
    isLoading: queryResult.isLoading,
    isFetching: queryResult.isFetching,
    labels: queryResult.data ?? [],
    refetch: () => queryResult.refetch({ cancelRefetch: true }),
    setLabelsLocally: (updateLabels) => {
      queryClient.setQueryData(queryKey, updateLabels);
    },
  };
}
