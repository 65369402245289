import { SubtreeManagementOptions } from '../../types';
import { fillZoneTotalChildren } from './fillZoneTotalChildren';
import { fillLevelTotalChildren } from './fillLevelTotalChildren';
import { fillGroupTotalChildren } from './fillGroupTotalChildren';

export async function fillTotalChildren(options: SubtreeManagementOptions) {
  await Promise.all([
    fillZoneTotalChildren(options),
    fillLevelTotalChildren(options),
    fillGroupTotalChildren(options),
  ]);
}
