import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { ActionTypes } from 'react-select';
import { User } from 'models/user-management';
import { SubscriptionType, UserWithSubscriptionType } from 'models/user-management/projectHealthSubscriptions';
import { normalizeUserSubscriptionsRes } from './hooks/normalizers';
import { useProjectSubscriptions } from './hooks/useProjectSubscriptions';

// components
import { Box, Button, Paper } from '@material-ui/core';
import { AdminView } from './AdminView';
import { UserView } from './UserView';
import { TableLoadingLayout } from 'components/Table';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { useDeepCompareEffect } from 'react-use';

interface Props {
  allowedToUpdateUsers: boolean;
  projectId: number;
  ownerId: number;
  usersFetching: boolean;
  users: User[];
  activeUserId?: number,
}

export const EmailSubscription = ({ allowedToUpdateUsers, projectId, ownerId, usersFetching, users, activeUserId }: Props): JSX.Element => {
  const { data, isLoading, onSave } = useProjectSubscriptions({ projectId });

  const usersWithSubscriptions = normalizeUserSubscriptionsRes(users, data);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [usersState, setUsersState] = useState<UserWithSubscriptionType[]>(usersWithSubscriptions);

  useDeepCompareEffect(() => {
    setUsersState(usersWithSubscriptions);
  }, [users, data]);

  useEffect(() => {
    setIsFormDirty(!isEqual(
      usersState.map(u => u.ProjectSubscriptions?.map(s => s.type)),
      usersWithSubscriptions.map(u => u.ProjectSubscriptions?.map(s => s.type))
    ));

    if(!usersFetching && !isLoading) {
      setIsInit(false);
    }
  }, [usersState, usersWithSubscriptions, isLoading, usersFetching]);

  const handleSubscriptionTypeSelect = (userId: number, value: SubscriptionType[], actionType?: ActionTypes) => {
    // adding new subscriptions to the target user's state
    const newState = usersState.map(u => {
      if(u.id === userId) {
        return {
          ...u,
          ProjectSubscriptions: actionType === 'clear'
            ? []
            : value.map(v => ({ projectId, ownerId, userId, type: v }))
        };
      } else {
        return u;
      }
    });
    setUsersState(newState);
  };

  return (
    <TableLoadingLayout isLoading={ usersFetching || isLoading }>
      <Paper>
        <BlockToolbar style={{ padding:'0px 24px', minHeight: '64px' }}>
          <BlockTitle>
            <Box component="span" display="flex" alignItems="start">
                Project health email subscription
            </Box>
          </BlockTitle>

          <Button
            color="inherit"
            disabled={ !isFormDirty }
            onClick={ () => setUsersState(usersWithSubscriptions) }
          >
            Cancel
          </Button>

          <Button
            style={{ 'color': isFormDirty ? '#02D15C' : '#00000042' }}
            disabled={ !isFormDirty }
            onClick={ () => onSave(usersWithSubscriptions, usersState) }
          >
            Save
          </Button>
        </BlockToolbar>

        {!isInit && (
          allowedToUpdateUsers
            ? (
              <AdminView usersState={usersState} handleSubscriptionTypeSelect={handleSubscriptionTypeSelect} />
            ) : (
              <UserView user={usersState.filter(u => u.id === activeUserId)[0]} handleSubscriptionTypeSelect={handleSubscriptionTypeSelect} />
            )
        )}

      </Paper>
    </TableLoadingLayout>
  );
};
