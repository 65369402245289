import { staticEnv } from 'env';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { updateZone } from 'actions/device-management/zones';
import { Zone, ZoneUpdateParams, Group } from 'models/device-management';
import { RootState } from 'reducers';
import { dmZonesPath } from 'routing/paths';
import * as is from 'utils/form/validation/validators';
import { useDefaultProject } from 'hooks/user-managment';
import { Defined } from 'utils/models';
import { dispatchAsync } from 'utils/store';
import { isAllowedToUpdateZones, isAllowedToDeleteZones, isAllowedToCreateZones } from 'utils/permissions';
import { isShowParkingAnomaly } from 'selectors/device-management';

// components
import {
  Box,
  Button as MuiButton,
  Grid,
  Paper,
} from '@material-ui/core';

import { Form } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { FieldSkeleton } from 'components/Skeleton';
import { SuccessButton } from 'components/Buttons';
import { LevelsTable } from 'components/Levels';

import { ZoneForm } from 'components/Form/ZoneForm';
import { ZoneParkingAnomaly } from 'components/ParkingAnomaly';
import DeleteZone from './DeleteZone';

// styles
import { useInfoBlockStyles } from 'pages/DevicePositionCouple/widgets/InfoBlock/style';

type TZoneFormValues = ZoneUpdateParams['props'] & {
  owner_id?: number;
}

interface GeneralTabProps {
  zone?: Zone;
  groups: Group[];
  idPrefix?: string;
  returnUrl?: string;
}

const getDefaultValues = (
  getDefaultProject: ReturnType<typeof useDefaultProject>['getDefaultProject'],
  zone?: Zone,
): Partial<TZoneFormValues> => {
  const defaultProject = getDefaultProject(zone?.owner_id);
  return {
    ...zone,
    live_status: zone?.live_status ?? false,
    name: zone?.name ?? '',
    project_id: zone?.project_id || defaultProject?.id
  };
};

const GeneralTab: React.FC<GeneralTabProps> = ({
  zone,
  groups,
  idPrefix,
  returnUrl = dmZonesPath,
}) => {
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const dispatch = useDispatch();
  const allowedToDeleteZone = useSelector((state: RootState) => isAllowedToDeleteZones(state.user.data));
  const allowedToUpdateZone = useSelector((state: RootState) => isAllowedToUpdateZones(state.user.data));
  const allowedToCreateZone = useSelector((state: RootState) => isAllowedToCreateZones(state.user.data));
  const showParkingAnomaly = useSelector((state: RootState) => isShowParkingAnomaly(state));
  const { getDefaultProject } = useDefaultProject();

  const handleSubmit = (values: TZoneFormValues) => {
    const zoneUpdateProps = {
      ...zone,
      ...values,
    };

    if (!isAdmin) {
      zoneUpdateProps.owner_id = undefined;
    }

    if (!zoneUpdateProps.project_id) {
      zoneUpdateProps.project_id = undefined;
    }

    return dispatchAsync(dispatch, updateZone({
      id: zone?.id as Defined<Zone['id']>,
      props: zoneUpdateProps,
    }));
  };

  const history = useHistory();
  const infoCss = useInfoBlockStyles();

  const defaultValues = getDefaultValues(getDefaultProject, zone);

  if (!zone) {
    return (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
          <Paper>
            <FieldSkeleton className={ infoCss.field } />
          </Paper>
        </Grid>
        <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
          <Paper>
            <FieldSkeleton className={ infoCss.field } />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const zoneInfoBlockTitle = 'Zone information';
  const zoneId = zone.id as number;

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
        <Form<TZoneFormValues>
          defaultValues={ defaultValues }
          validators={ { name: is.required() } }
          formProps={ { 'aria-label': zoneInfoBlockTitle } }
          onSubmit={ handleSubmit }
        >
          { form => (
            <Paper>
              <BlockToolbar>
                <BlockTitle>
                  { zoneInfoBlockTitle }
                </BlockTitle>

                { allowedToDeleteZone && (
                  <DeleteZone
                    zoneId={ zoneId }
                    onSuccess={ () => history.push(returnUrl) }
                  />
                ) }

                { allowedToUpdateZone && form.formState.dirty && (
                  <MuiButton color="inherit" onClick={ form.reset }>
                    Cancel
                  </MuiButton>
                ) }

                { allowedToUpdateZone && (
                  <SuccessButton
                    label="Save"
                    disabled={ !form.formState.dirty }
                    pending={ form.formState.isSubmitting }
                    titleForTooltip={ form.formState.dirty ? '' : 'Nothing to save' }
                  />
                ) }
              </BlockToolbar>

              <ZoneForm
                setDefaultProjectId={(ownerId: TZoneFormValues['owner_id']) => {
                  const defaultProjectId = getDefaultProject(ownerId)?.id;
                  form.setValue('project_id', defaultProjectId);
                }}
                allowed={ allowedToUpdateZone }
                zone={ zone }
                idPrefix={ idPrefix }
                showFields={ ['id', 'name', 'creationDate', 'liveStatus', 'project', ...(isAdmin ? ['owner'] : [])] }
              />
            </Paper>
          ) }
        </Form>
      </Grid>
      <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
        <Box mb={1}>
          <Paper>
            <LevelsTable
              zone={ zone }
              groups={ groups }
              allowedToUpdateLevel={ allowedToUpdateZone }
              allowedToCreateLevel={ allowedToCreateZone }
              allowedToDeleteLevel={ allowedToDeleteZone }
            />
          </Paper>
        </Box>
        { staticEnv.IS_PARKING_ANOMALY_ON && (showParkingAnomaly && <ZoneParkingAnomaly zoneId={ zoneId } />) }
      </Grid>
    </Grid>
  );
};

export default GeneralTab;
