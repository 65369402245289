import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { createDateRange, formatDate } from 'utils/datetime';
import { createErrorFromApiResponse } from 'utils/errors';
import { ApiResponse, ReasonEnum } from 'models';
import { GetHistoryParamsQuery, GetTemplateParams, History, Statistic, StatusesEnum, Template } from 'models/caller';

import { createTemplate, deleteTemplateById, getHistory, getTemplateById, getTemplates, updateTemplateById } from 'clients/http-calls';
import * as Action from 'actions/caller';
import { LoadCallerTemplateDataFailure, LoadCallerTemplateDataStart, LoadCallerTemplateDataSuccess } from 'actions/caller';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { ActionWithPromise } from 'utils/store';

function* loadTemplateDataById(action: Action.LoadCallerTemplateDataById) {
  yield put(LoadCallerTemplateDataStart(action.templateId));
  const response: ApiResponse = yield call(getTemplateById, action.templateId);
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadCallerTemplateDataSuccess(action.templateId, [response.data]));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadCallerTemplateDataFailure(action.templateId, message));
    yield put(NotifyError(`Error while fetching caller template #${ action.templateId }: ${ message }`));
  }
}

function* loadTemplateDataByParams(action: Action.LoadCallerTemplateDataByParams) {
  yield put(LoadCallerTemplateDataStart(action.params));
  const response: ApiResponse = yield call(getTemplates, action.params);
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadCallerTemplateDataSuccess(action.params, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadCallerTemplateDataFailure(action.params, message));
    yield put(NotifyError(`Error while fetching caller templates: ${ message }`));
  }
}

function* loadTemplateDataDictionary() {
  const params: GetTemplateParams = {};
  yield put(LoadCallerTemplateDataStart(params));
  const response: ApiResponse = yield call(getTemplates, { limit: 999 });
  if (response.reason === ReasonEnum.Ok) {
    yield put(LoadCallerTemplateDataSuccess(params, response.data));
  } else {
    const message = response.message || 'Server error';
    yield put(LoadCallerTemplateDataFailure(params, message));
    yield put(NotifyError(`Error while fetching caller templates dictionary: ${ message }`));
  }
}

function buildStatisticFromHistory(data: History[], dateFrom: Date, dateTo?: Date): Statistic[] {
  const dates = createDateRange(dateFrom, dateTo, 'day');
  const statistic: Map<string, Statistic> = new Map(dates.map( date => [
    formatDate(date),
    {
      date: formatDate(date),
      failures: 0,
      successful: 0
    }
  ]));
  for (const history of data) {
    if (!history.sending_time) {
      continue;
    }

    const date = formatDate(history.sending_time);
    const dateStat = statistic.get(date);
    if (!dateStat) {
      continue;
    }

    if (history.sending_status === StatusesEnum.success) {
      ++dateStat.successful;
    } else if (history.sending_status === StatusesEnum.error || history.sending_status === StatusesEnum.failed) {
      ++dateStat.failures;
    }
  }

  return [...statistic.values()];
}

function* loadTemplateStatisticByParams(action: Action.LoadTemplateStatistic) {
  const params: GetHistoryParamsQuery = {
    templateIds: [action.params.templateId],
    sendingTimeFrom: action.params.dateFrom,
    sendingTimeTo: action.params.dateTo,
    limit: 5000,
    offset: 0,
  };
  yield put({ type: Action.CALLER_TEMPLATE_STATISTIC_FETCH_START, templateId: action.params.templateId });
  const response: ApiResponse = yield call(getHistory, params);
  yield put({ type: Action.CALLER_TEMPLATE_STATISTIC_FETCH_STOP, templateId: action.params.templateId });

  if (response.reason === ReasonEnum.Ok) {
    const statistic = buildStatisticFromHistory(response.data, action.params.dateFrom, action.params.dateTo);
    yield put({
      type: Action.CALLER_TEMPLATE_STATISTIC_CHANGE,
      templateId: action.params.templateId,
      data: [{ template_id: action.params.templateId, statistic }]
    });
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while fetching caller statistic: ${ message }`));
  }
}

type CreateAction =
  | Action.CreateTemplate
  | ActionWithPromise<Action.CreateTemplate, Template>

function* doCreateTemplate(action: CreateAction) {
  const templateId = action.template.template_id;

  if (templateId !== undefined) {
    yield put(Action.LockTemplate(templateId));
  }

  const response: ApiResponse = yield call(createTemplate, action.template);

  if (templateId !== undefined) {
    yield put(Action.UnlockTemplate(templateId));
  }

  if (response.reason === ReasonEnum.Ok) {
    const template: Template = response.data as Template;
    yield put(Action.ChangeTemplate(template, { create: true }));
    yield put(NotifySuccess(`Caller template "${ template.name }" has been created`));
    'meta' in action && action.meta.promise.resolve(template);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating caller template "${ action.template.name }": ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type CreateManyAction =
  | Action.CreateManyTemplates
  | ActionWithPromise<Action.CreateManyTemplates, Template[]>;

function* doCreateManyTemplates(action: CreateManyAction) {
  const templateCount = action.templates.length;
  const templates: Template[] = [];

  function* flushCreatedTemplates() {
    yield put(Action.ChangeManyTemplates(
      templates.map(template => ({ template, options: { create: true } })),
    ));
  }

  try {
    for (const templateParams of action.templates) {
      const response: ApiResponse = yield call(createTemplate, templateParams);

      if (response.reason !== ReasonEnum.Ok) {
        throw createErrorFromApiResponse(response as ApiResponse);
      }

      const template = response.data as Template;
      templates.push(template);
    }

    yield call(flushCreatedTemplates);
    yield put(NotifySuccess(
      `${ templateCount } ${ templateCount === 1 ? 'template has' : 'templates have' } been added`
    ));
    'meta' in action && action.meta.promise.resolve(templates);
  } catch (e) {
    yield call(flushCreatedTemplates);
    yield put(NotifyError(`Error while creating templates: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject((e as Error));
  }
}

type UpdateAction =
  | Action.UpdateTemplate
  | ActionWithPromise<Action.UpdateTemplate, Template>

function* doUpdateTemplate(action: UpdateAction) {
  const templateId = action.template.template_id;
  yield put(Action.LockTemplate(templateId));
  const response: ApiResponse = yield call(updateTemplateById, templateId, action.template);
  yield put(Action.UnlockTemplate(templateId));

  if (response.reason === ReasonEnum.Ok) {
    const template: Template = response.data as Template;
    yield put(Action.ChangeTemplate(template, { update: true }));
    yield put(NotifySuccess(`Caller template "${ template.name }" has been updated`));
    'meta' in action && action.meta.promise.resolve(template);
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while updating caller template "${ action.template.name }": ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DeleteAction =
  | Action.DeleteTemplate
  | ActionWithPromise<Action.DeleteTemplate, Template>

function* doDeleteTemplate(action: DeleteAction) {
  const templateId = action.template.template_id;
  yield put(Action.LockTemplate(templateId));
  const response: ApiResponse = yield call(deleteTemplateById, templateId);
  yield put(Action.UnlockTemplate(templateId));

  if (response.reason === ReasonEnum.Ok) {
    const template: Template = action.template;
    yield put(Action.ChangeTemplate(template, { delete: true }));
    yield put(NotifySuccess(`Caller template "${ template.name }" has been deleted`));
    'meta' in action && action.meta.promise.resolve(template);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting caller template "${ action.template.name }": ${ message }`));
    'meta' in action && action.meta.promise.reject(new Error(message));
  }
}

export default [
  takeEvery(Action.CALLER_TEMPLATE_DATA_LOAD_BY_ID, loadTemplateDataById),
  takeEvery(Action.CALLER_TEMPLATE_DATA_LOAD_BY_PARAMS, loadTemplateDataByParams),
  takeLeading(Action.CALLER_TEMPLATE_DATA_LOAD_DICTIONARY, loadTemplateDataDictionary),
  takeEvery(Action.CALLER_TEMPLATE_STATISTIC_LOAD, loadTemplateStatisticByParams),
  takeEvery(Action.CALLER_TEMPLATE_CREATE, doCreateTemplate),
  takeEvery(Action.CALLER_TEMPLATE_CREATE_MANY, doCreateManyTemplates),
  takeEvery(Action.CALLER_TEMPLATE_UPDATE, doUpdateTemplate),
  takeEvery(Action.CALLER_TEMPLATE_DELETE, doDeleteTemplate),
];
