import { SettingsResponseStruct } from 'models/device-emulation';
import { formatAutoChangeOccupancyPeriod } from 'utils/datetime';

export function formatSendingInterval(setting: SettingsResponseStruct): string {
  if (!setting.auto_sending || !setting.sending_period_min) {
    return 'Disabled';
  }

  const period = formatAutoChangeOccupancyPeriod(setting.sending_period_min);

  return `Every ${period}`;
}
