import { makeStyles, createStyles, Theme } from 'styles/utils';
import { colors } from 'styles/themes/outer';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  btnViewMsg: {
    marginLeft: theme.spacing(1),
  },
  btnDelete: {
    color: colors.errordark,
    marginLeft: theme.spacing(1),
  },
}));
