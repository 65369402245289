import { State as UserState } from 'reducers/user-profile';

export function canViewUserGroupList(user: UserState): boolean {
  return user.isAdmin;
}

export function canViewUserGroup(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('r'));
}

export function canCreateUserGroup(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('c'));
}

export function canEditUserGroup(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('u'));
}

export function canDeleteUserGroup(user: UserState): boolean {
  return user.isAdmin || Boolean(user.data['um.user']?.includes('d'));
}

export function canRefreshUserGroupAccessCode(user: UserState): boolean {
  return user.isAdmin;
}

