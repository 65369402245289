import { FilterState } from 'pages/Parking/CurrentState/StateFilter/types';
import { getLocation } from 'utils/routing/query';

const baseUrl = '/occupancy';

enum Path {
  states = '/states',
}

export function getParkingStateCurrentPath() {
  return baseUrl + Path.states;
}

export function getParkingStateCurrentLocation(params: FilterState) {
  return getLocation(getParkingStateCurrentPath(), params);
}