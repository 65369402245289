import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customChip: {
      padding: theme.spacing(.5, 1),
      margin: theme.spacing(.5),
      background: theme.palette.grey[100],
      borderRadius: 3,
      display: 'inline-flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      justifyContent: 'center',
    },
    templateIconStatus: {
      fontSize: '0.5rem',
      marginRight: theme.spacing(.5),
    }
  }),
);

export default useStyles;
