import { ResponsiblePerson, StationLocation, UpdateResponsiblePerson } from 'models/base-station';

export const BASE_STATION_RESPONSIBLE_PERSON_CREATE = 'BASE_STATION_RESPONSIBLE_PERSON_CREATE';
export const BASE_STATION_RESPONSIBLE_PERSON_UPDATE = 'BASE_STATION_RESPONSIBLE_PERSON_UPDATE';
export const BASE_STATION_RESPONSIBLE_PERSON_DELETE = 'BASE_STATION_RESPONSIBLE_PERSON_DELETE';
export const BASE_STATION_RESPONSIBLE_PERSONS_SAVE = 'BASE_STATION_RESPONSIBLE_PERSONS_SAVE';
export const BASE_STATION_RESPONSIBLE_PERSON_CHANGE = 'BASE_STATION_RESPONSIBLE_PERSON_CHANGE';
export const BASE_STATION_RESPONSIBLE_PERSON_LOCK = 'BASE_STATION_RESPONSIBLE_PERSON_LOCK';
export const BASE_STATION_RESPONSIBLE_PERSON_UNLOCK = 'BASE_STATION_RESPONSIBLE_PERSON_UNLOCK';
export const BASE_STATION_RESPONSIBLE_PERSON_NOTIFICATION_CHANGE = 'BASE_STATION_RESPONSIBLE_PERSON_NOTIFICATION_CHANGE';

export const BASE_STATION_RESPONSIBILITY_REMOVE = 'BASE_STATION_RESPONSIBILITY_REMOVE';
export const BASE_STATION_RESPONSIBILITY_REMOVE_SUCCESS = 'BASE_STATION_RESPONSIBILITY_REMOVE_SUCCESS';
export const BASE_STATION_RESPONSIBILITY_REMOVE_FAILED = 'BASE_STATION_RESPONSIBILITY_REMOVE_FAILED';

export const BASE_STATION_RESPONSIBILITY_ADD = 'BASE_STATION_RESPONSIBILITY_ADD';
export const BASE_STATION_RESPONSIBILITY_ADD_SUCCESS = 'BASE_STATION_RESPONSIBILITY_ADD_SUCCESS';
export const BASE_STATION_RESPONSIBILITY_ADD_FAILED = 'BASE_STATION_RESPONSIBILITY_ADD_FAILED';

export interface CreateStationResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_CREATE;
  person: ResponsiblePerson;
}

export interface UpdateStationResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_UPDATE;
  person: UpdateResponsiblePerson;
}

export interface DeleteStationResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_DELETE;
  person: ResponsiblePerson;
}

export interface SaveStationResponsiblePersons {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSONS_SAVE;
  location: StationLocation;
  persons: ResponsiblePerson[];
  oldPersons: ResponsiblePerson[];
}

export interface ChangeStationResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_CHANGE;
  person: ResponsiblePerson;
  options: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
  };
}

export interface LockStationResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_LOCK;
  personId: number;
}

export interface UnlockStationResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_UNLOCK;
  personId: number;
}

export interface ChangeStationResponsiblePersonNotification {
  readonly type: typeof BASE_STATION_RESPONSIBLE_PERSON_NOTIFICATION_CHANGE;
  person: ResponsiblePerson;
  notification: boolean;
}

export interface RemoveLocationFromResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBILITY_REMOVE;
  locationId: number;
  personId: number;
}

export interface AddLocationForResponsiblePerson {
  readonly type: typeof BASE_STATION_RESPONSIBILITY_ADD;
  locationId: number;
  personId: number;
}

export interface RemoveLocationFromResponsiblePersonFailed {
  readonly type: typeof BASE_STATION_RESPONSIBILITY_REMOVE_FAILED;
}

export const CreateStationResponsiblePerson = (person: ResponsiblePerson): CreateStationResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_CREATE, person
});

export const UpdateStationResponsiblePerson = (person: UpdateResponsiblePerson): UpdateStationResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_UPDATE, person
});

export const DeleteStationResponsiblePerson = (person: ResponsiblePerson): DeleteStationResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_DELETE, person
});

export const SaveStationResponsiblePersons = (location: StationLocation, persons: ResponsiblePerson[], oldPersons: ResponsiblePerson[]): SaveStationResponsiblePersons => ({
  type: BASE_STATION_RESPONSIBLE_PERSONS_SAVE, location, persons, oldPersons
});

export const ChangeStationResponsiblePerson = (person: ResponsiblePerson, options: ChangeStationResponsiblePerson['options']): ChangeStationResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_CHANGE, person, options
});

export const LockStationResponsiblePerson = (personId: number): LockStationResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_LOCK, personId
});

export const UnlockStationResponsiblePerson = (personId: number): UnlockStationResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_UNLOCK, personId
});

export const ChangeStationResponsiblePersonNotification = (person: ResponsiblePerson, notification: boolean): ChangeStationResponsiblePersonNotification => ({
  type: BASE_STATION_RESPONSIBLE_PERSON_NOTIFICATION_CHANGE, person, notification
});

export const RemoveLocationFromResponsiblePerson = (locationId: number, personId: number): RemoveLocationFromResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBILITY_REMOVE,
  locationId, personId
});

export const AddLocationForResponsiblePerson = (locationId: number, personId: number): AddLocationForResponsiblePerson => ({
  type: BASE_STATION_RESPONSIBILITY_ADD,
  locationId, personId
});

export const RemoveLocationFromResponsiblePersonFailed = (): RemoveLocationFromResponsiblePersonFailed => ({
  type: BASE_STATION_RESPONSIBILITY_REMOVE_FAILED,
});

export type Action =
  | CreateStationResponsiblePerson
  | UpdateStationResponsiblePerson
  | DeleteStationResponsiblePerson
  | SaveStationResponsiblePersons
  | ChangeStationResponsiblePerson
  | LockStationResponsiblePerson
  | UnlockStationResponsiblePerson
  | ChangeStationResponsiblePersonNotification
  | RemoveLocationFromResponsiblePerson
  | RemoveLocationFromResponsiblePersonFailed
  | AddLocationForResponsiblePerson
