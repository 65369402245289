import React from 'react';
import { useTemplateSuitesDictionary } from 'utils/dictionary';

interface Props {
  templateSuiteId: number;
}

const CallerTemplateSuiteName = ({ templateSuiteId }: Props): JSX.Element => {
  const templateSuites = useTemplateSuitesDictionary();
  const templateSuite = templateSuites.find((el) => el.template_suite_id === templateSuiteId);
  const templateSuiteName = templateSuite ? `${ templateSuite.name }` : `#${ templateSuiteId }`;
  return (
    <>{ templateSuiteName }</>
  );
};

export default CallerTemplateSuiteName;
