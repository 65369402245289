import { createStyles, makeStyles, Theme } from 'styles/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: 'none',
    },
    row: {
      marginBottom: theme.spacing(1),

      '&:last-child': {
        marginBottom: 0,
      },
    },
    whiteText: {
      color: '#fff',
    }
  }),
);

export default useStyles;
