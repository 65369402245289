import { fetchGroupTotalChildren } from '../../../api';
import { getGroupNodes } from '../../../node/utils';
import { GroupNode } from '../../../types';
import { SubtreeManagementOptions } from '../../types';
import { fillNodes } from '../fillNodes';

export async function fillGroupTotalChildren({
  getSubtree,
  updateSubtree,
  getNodes = nodes => nodes,
}: SubtreeManagementOptions) {
  await fillNodes<number, GroupNode>({
    getSubtree,
    updateSubtree,
    getNodes: nodes => getGroupNodes(getNodes(nodes)).filter((node) => {
      // We don't fetch children total if it was marked successfuly fetched
      // already. It happens when we initialize tree in a bottom-up way where
      // we insert children and childrenTotal manually. In such case, fetching
      // here doesn't seem to make sense since children are basically static
      // because of bottom-up filters.
      return node.childrenTotal.status !== 'success';
    }),
    getQuery: node => node.childrenTotal,
    fetchDataByNode: fetchGroupTotalChildren,
  });
}
