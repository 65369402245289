import { Endpoint } from 'models/rabbit';
import { useAuthUserSelector, useDialogWithItem } from 'hooks';
import { commonOptions } from 'utils/tables';
import { usePagination } from 'hooks';

// components
import { Box } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout, PaginationFooter } from 'components/Table';
import { OwnerName } from 'components/Labels';
import { RabbitEndpointLink } from 'components/Links';
import { AddRuleDialog, DeleteEndpointDialog } from 'components/Rabbit/Dialogs';
import { ActionsCell } from './widgets/ActionsCell';
import { ZonesCell } from './widgets/ZonesCell';
// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';
import useStyles from './Style';


interface Props {
  isLoading?: boolean;
  data: Endpoint[];
  total: number;
}

const Component = ({ isLoading, data, total }: Props): JSX.Element => {
  const { page, limit } = usePagination();
  const classes = useStyles();
  const { isAdmin } = useAuthUserSelector();
  const deleteDialog = useDialogWithItem<Endpoint>();
  const addRouteDialog = useDialogWithItem<Endpoint>();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Endpoint name',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => (
          <RabbitEndpointLink
            endpointId={ Number(data[dataIndex].id) }
            name={ data[dataIndex].name }
          />
        )
      },
    },
    {
      name: 'zone',
      label: 'Zone',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ className: classes.zoneCell }),
        customBodyRenderLite: dataIndex => <ZonesCell endpoint={ data[dataIndex] }/>
      },
    },
    {
      name: 'owner_id',
      label: 'Owner',
      options: {
        filter: false,
        display: isAdmin ? 'true' : 'excluded',
        customBodyRenderLite: dataIndex => <OwnerName ownerId={ data[dataIndex].owner_id } />,
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        empty: true,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
        customBodyRenderLite: dataIndex => (
          <ActionsCell
            endpoint={ data[dataIndex] }
            onAddRoute={ addRouteDialog.open }
            onDelete={ deleteDialog.open }
          />
        ),
      },
    }
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    elevation: 0,
    page,
    rowsPerPage: limit,
    setRowProps: (_rowData, dataIndex) => ({ 'data-endpointid': data[dataIndex].id }),
    // https://github.com/mui/material-ui/issues/15616
    count: total,
    customFooter: (count) => {
      return (
        <PaginationFooter
          count={ count }
        />
      );
    },
  };

  return (
    <Box className={ classes.root }>
      <MuiThemeProvider theme={ getMuiTheme() }>
        <TableLoadingLayout isLoading={ isLoading }>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>
      { deleteDialog.isMounted && deleteDialog.item && <DeleteEndpointDialog
        endpoint={ deleteDialog.item }
        isOpen={ deleteDialog.isOpen }
        onClose={ deleteDialog.close }
        onCloseEnd={ deleteDialog.unmount }
      /> }
      { addRouteDialog.isMounted && addRouteDialog.item && <AddRuleDialog
        endpoint={ addRouteDialog.item }
        isOpen={ addRouteDialog.isOpen }
        onClose={ addRouteDialog.close }
        onCloseEnd={ addRouteDialog.unmount }
      /> }
    </Box>
  );
};

export default Component;
