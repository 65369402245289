import { useDialog } from 'hooks';
import { StationLocation } from 'models/base-station';
// components
import { EditButton } from 'components/Buttons';
import { EditLocationDialog } from 'components/BaseStation/Dialogs';

interface Props {
  location: StationLocation;
}

export const EditLocationButton = ({ location }: Props): JSX.Element => {
  const dialog = useDialog();
  return (
    <>
      <EditButton
        type="icon"
        label="Edit"
        onClick={ dialog.open }
        ButtonProps={ {
          size: 'small'
        } }
      />
      { dialog.isMounted && <EditLocationDialog
        location={ location }
        isOpen={ dialog.isOpen }
        onClose={ dialog.close }
        onCloseEnd={ dialog.unmount }
      /> }
    </>
  );
};
