import { useCallback, useRef, useState } from 'react';

type StateUpdaterFunction<T> = (nextState: T) => void;

interface ControlledStateHookOptions<T> {
  controlled: boolean;
  state: T;
  onChange?: StateUpdaterFunction<T>;
}

export function useControlledState<T>({
  controlled,
  state,
  onChange = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
}: ControlledStateHookOptions<T>): [T, StateUpdaterFunction<T>] {
  const [uncontrolledState, setUncontrolledState] = useState<T>(state);

  const handleUncontrolledChange = useCallback(
    (nextState: T) => {
      setUncontrolledState(nextState);
      onChange(nextState);
    },
    [onChange],
  );

  // controlled / uncontrolled is built not reassignable regardless of subsequent changes,
  // so it must be selected on startup
  const controlledModeEnabled = useRef(controlled);

  if (controlledModeEnabled.current) {
    return [state, onChange];
  }

  return [uncontrolledState, handleUncontrolledChange];
}
