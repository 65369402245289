import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';

import { HistoryFilterState, GetHistoryParamsQuery, History, StatusesEnum, Template } from 'models/caller';

import { RootState } from 'reducers';
import { State } from 'reducers/caller-history';
import { LoadCallerHistoryDataByParams } from 'actions/caller';

import { usePagination } from 'hooks/usePagination';
import { useCallerTemplateDictionary } from 'hooks/caller';
import { useLocationItems } from 'hooks/location';

interface HistoryState {
  isLoading: boolean;
  data: History[];
  filter: HistoryFilterState;
  saveFilter: (newFilter: HistoryFilterState) => void;
  resetFilter: () => void;
  setFilter: (state: HistoryFilterState) => void;
}

export const useHistoryByFilters = (): HistoryState => {
  const dispatch = useDispatch();
  const { isLoading: isTemplateLoading, templates } = useCallerTemplateDictionary();
  const { items: location, setItems: setLocation } = useLocationItems({ updateMethod: 'replace' });
  const { data, isFetching } = useSelector((state: RootState): State => state.callerHistory);
  const [isInit, setIsInit] = useState(false);
  const [filterState, setFilterState] = useState<HistoryFilterState>(initialFilterState);
  const { limit, offset } = usePagination();

  // fetch data on change filter or pagination
  const isWaitTemplatesLoading = getTemplatesLoading(isTemplateLoading, filterState);
  useDeepCompareEffect(() => {
    if (!isWaitTemplatesLoading) {
      // startup actions
      if (!isInit) {
        const locationFilterQuery = parseLocationQuery(location);
        setFilterState(locationFilterQuery);
        setLocation(prev => ({ ...prev, ...locationFilterQuery }));
        setIsInit(true);
      } else {
        const currentFilterQuery = normalizeQuery({ ...filterState, limit, offset }, templates);
        dispatch(LoadCallerHistoryDataByParams(currentFilterQuery));
      }
    }
  }, [dispatch, isWaitTemplatesLoading, filterState, limit, offset, isInit]);

  const saveFilter = (newFilter: HistoryFilterState) => {
    setFilterState(newFilter);
    setLocation(newFilter); // for reset pagination and max reached count we delete pagination option from location
  };

  const resetFilter = () => {
    setFilterState(initialFilterState);
    setLocation(initialFilterState); // for reset pagination and max reached count we delete pagination option from location
  };

  return {
    isLoading: isFetching || isWaitTemplatesLoading,
    data: data,
    filter: filterState,
    saveFilter,
    resetFilter,
    setFilter: setFilterState,

  };
};

export const initialTimeFrom = moment().subtract(1, 'days').toDate();
export const initialFilterState: HistoryFilterState = {
  owner: undefined,
  zones: [],
  groupIds: [],
  deviceIds: [],
  templateSuiteIds: [],
  templateIds: [],
  messageTraceId: '',
  sendingStatus: StatusesEnum.all,
  sendingTimeFrom: initialTimeFrom,
  sendingTimeTo: undefined,
};

const parseLocationQuery = (items: UrlItems) => {
  const parser = new Parser(items as GetHistoryParamsQuery);
  return {
    ...initialFilterState,
    owner: parser.asNumber('owner') ?? initialFilterState.owner,
    zones: parser.asNumbers('zones') ?? initialFilterState.zones,
    groupIds: parser.asNumbers('groupIds') ?? initialFilterState.groupIds,
    deviceIds: parser.asStrings('deviceIds') ?? initialFilterState.deviceIds,
    templateSuiteIds: parser.asNumbers('templateSuiteIds') ?? initialFilterState.templateSuiteIds,
    templateIds: parser.asNumbers('templateIds') ?? initialFilterState.templateIds,
    messageTraceId: parser.asString('messageTraceId') ?? initialFilterState.messageTraceId,
    sendingStatus: parser.asEnum('sendingStatus', StatusesEnum) ?? initialFilterState.sendingStatus,
    sendingTimeFrom: parser.asDate('sendingTimeFrom') ?? new Date(initialTimeFrom),
    sendingTimeTo: parser.asDate('sendingTimeTo') ?? initialFilterState.sendingTimeTo,
  };
};

const normalizeQuery = (state: GetHistoryParamsQuery, templates: Template[]) => {
  const templateIds = templates
    .filter(template => {
      if (state.templateIds && state.templateIds.length > 0) {
        return state.templateIds.includes(template.template_id);
      }

      if (state.templateSuiteIds && state.templateSuiteIds.length > 0) {
        return state.templateSuiteIds.includes(template.template_suite_id);
      }

      return false;
    })
    .map(template => template.template_id);

  const params = {
    ...state,
    templateIds: templateIds,
  };

  return params;
};

export const getTemplatesLoading = (isLoading: boolean, filter: HistoryFilterState): boolean => {
  return filter.templateIds.length || filter.templateSuiteIds.length ? isLoading : false;
};
