import React from 'react';
import {
  MarkerColor,
  MarkerType
} from './types';
import { getMarkerIcon } from './utils';
// components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Icon } from '@material-ui/core';
// styles
import { LegendStyles } from 'styles';

interface Props {
  markerType: MarkerType;
}

const Component = ({ markerType }: Props) => {
  const classes = LegendStyles();
  return (
    <Box lineHeight={ 1 } justifyContent="center" className={ classes.legendBox }>
      { markerType !== MarkerType.OCCUPANCY ? '' :
        <>
          <div className={ classes.iconContainer }>
            <Icon><img src={ getMarkerIcon(MarkerColor.green) } alt="Green marker"/></Icon>
            <Typography variant="subtitle2" align="center">position is free</Typography>
          </div>

          <div className={ classes.iconContainer }>
            <Icon><img src={ getMarkerIcon(MarkerColor.red) } alt="Red marker"/></Icon>
            <Typography variant="subtitle2" align="center">position is occupied</Typography>
          </div>
        </>
      }
      {
        markerType !== MarkerType.DURATION ? '' :
          <>
            <div className={ classes.iconContainer }>
              <Icon><img src={ getMarkerIcon(MarkerColor.blue) } alt="Blue marker"/></Icon>
              <Typography variant="subtitle2" align="center">position is free</Typography>
            </div>

            <div className={ classes.iconContainer }>
              <Icon><img src={ getMarkerIcon(MarkerColor.green) } alt="Green marker"/></Icon>
              <Typography variant="subtitle2" align="center">is occupied 0-30 min</Typography>
            </div>

            <div className={ classes.iconContainer }>
              <Icon><img src={ getMarkerIcon(MarkerColor.yellow) } alt="Yellow marker"/></Icon>
              <Typography variant="subtitle2" align="center">is occupied 31 min - 4 hours</Typography>
            </div>

            <div className={ classes.iconContainer }>
              <Icon><img src={ getMarkerIcon(MarkerColor.red) } alt="Red marker"/></Icon>
              <Typography variant="subtitle2" align="center">is occupied longer than 4 hours</Typography>
            </div>
          </>
      }
    </Box>
  );
};

export { Component as PositionStateLegend };
