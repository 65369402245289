import React from 'react';

import { DmTreeNode } from 'hooks/device-management/useDmTree/types';

// components
import { OwnerName } from 'components/Labels';

interface Props {
  node: DmTreeNode;
}

export const OwnerCell = ({ node }: Props): JSX.Element => {
  return (
    node.type === 'zone'
      ? <OwnerName ownerId={ node.zone.owner_id }/>
      : <></>
  );
};
