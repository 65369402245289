import React from 'react';
import { RouteConfigChildrenProps } from 'react-router-config';

import { routesRendererBaseProps } from '../utils';

// components
import {
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { renderRouteLabel, renderRoutes } from 'utils/routing';

import MainMenuLink from './MainMenuLink';
import SubMenu from './SubMenu';

// styles
import useStyles from '../styles';

const MenuCategory: React.FC<RouteConfigChildrenProps> = ({ match, route }) => {
  const {  path, routes } = route;
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);

  if (!path) {
    return null;
  }

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation();
    setOpen(!open);
  }

  return (
    <div>
      <ListItem className={classes.menuCategoryName} onClick={handleClick}>
        <ListItemText
          classes={{
            primary: classes.menuCategoryNameText,
            root: classes.menuCategoryNameTextContainer,
          }}
          primary={renderRouteLabel({ match, route })}
        />
      </ListItem>
      <List component="div">
        {renderRoutes({
          ...routesRendererBaseProps,
          renderRouteChildren: (props) => {
            if (!props.route.routes || !props.route.routes.length) {
              return <MainMenuLink {...props} />;
            }

            return (
              <SubMenu
                listClassName={classes.topSublist}
                {...props}
              />
            );
          },
          routes,
        })}
      </List>
    </div>
  );
};

export default MenuCategory;
