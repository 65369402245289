import { FormField, FormState } from './types';
import { FormErrors } from 'form';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};
  if (fields.includes(FormField.zone)) {
    if (!state[FormField.zone]) {
      errors[FormField.zone] = 'Zone is required';
    }
  }

  if (fields.includes(FormField.endpoint)) {
    if (!state[FormField.endpoint]) {
      errors[FormField.endpoint] = 'Endpoint is required';
    }
  }

  if (fields.includes(FormField.messageType)) {
    if (!state[FormField.messageType]) {
      errors[FormField.messageType] = 'Message type is required';
    }
  }

  return errors;
};
