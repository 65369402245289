import { groupBy as groupByLodash, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useQueries } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getResponseCodeStatistic } from 'clients/http-monitoring';
import { useCustomMemo } from 'hooks';
import {
  CallerResponseCodeHistoryParams,
  CallerResponseCodeHistoryEntry,
  ResponseCode,
} from 'models/caller-monitoring';
import { getResponseData } from 'utils/clients';

import { getDefaultGroupBy, fillGaps, mapNumberToResponseCode } from './utils';


type HistoryByResponseCode = Record<ResponseCode, CallerResponseCodeHistoryEntry[]>;
type HistoryByResponseCodeByTemplate = Map<number, HistoryByResponseCode>;

interface CallerResponseCodeHistory {
  isLoading: boolean;
  historyByTemplate: HistoryByResponseCodeByTemplate;
}

export const useCallerResponseCodeHistory = ({
  templateIds,
  ...restParams
}: CallerResponseCodeHistoryParams): CallerResponseCodeHistory => {
  const dispatch = useDispatch();

  const results = useQueries(
    templateIds.map(templateId => {
      const groupBy = restParams.groupBy ?? getDefaultGroupBy(
        restParams.dateFrom,
        restParams.dateTo,
      );
      const params: CallerResponseCodeHistoryParams = {
        ...restParams,
        templateIds: [templateId],
        groupBy,
      };

      return {
        queryKey: ['caller/monitoring/responseCodeHistory', params],
        queryFn: async (): Promise<HistoryByResponseCode> => {
          const resData = getResponseData(await getResponseCodeStatistic(params));
          const historyEntries = resData.flatMap(event => {
            const responseCode = mapNumberToResponseCode(event.response_code);

            if (!responseCode) {
              return [];
            }

            return [{
              // формат даты из бекенда: 2021-03-19T17:00:00.000000+00:00
              // мы ожидаем: 2021-03-19T17:00:00.000Z
              date: new Date(event.timestamp).toJSON(),
              responseCode: responseCode,
              count: event.count,
            }];
          });

          const historyEntriesByResponseCode = groupByLodash(historyEntries, e => e.responseCode);
          const historyEntriesByResponseCodeWithoutGaps: HistoryByResponseCode = {
            [ResponseCode.SUCCESS]: [],
            [ResponseCode.REDIRECT]: [],
            [ResponseCode.VALIDATION_ERROR]: [],
            [ResponseCode.SERVER_ERROR]: [],
            [ResponseCode.OTHER]: [],
          };

          for (const [responseCodeRaw] of Object.entries(historyEntriesByResponseCodeWithoutGaps)) {
            const responseCode = responseCodeRaw as ResponseCode;

            historyEntriesByResponseCodeWithoutGaps[responseCode] = fillGaps(
              historyEntriesByResponseCode[responseCode] ?? [],
              restParams.dateFrom,
              restParams.dateTo,
              groupBy,
              (date, count) => ({ date, count, responseCode }),
            );
          }

          return historyEntriesByResponseCodeWithoutGaps;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        onError: (e: Error) => {
          dispatch(NotifyError(`Error while fetching response code history: ${ e.message }`));
        },
      };
    }),
  );

  const resultsData = results.map(result => result.data);

  const result = useCustomMemo(() => {
    const templatesHistory = resultsData.map((resultData, index) => ({
      templateId: templateIds[index],
      history: resultData as HistoryByResponseCode | undefined,
    }));

    if (!templatesHistory.every(state => state.history)) {
      return {
        isLoading: true,
        historyByTemplate: new Map(),
      };
    }

    return {
      isLoading: false,
      historyByTemplate: templatesHistory.reduce((acc, state) => {
        acc.set(state.templateId, state.history as HistoryByResponseCode);
        return acc;
      }, new Map() as HistoryByResponseCodeByTemplate),
    };
  }, [resultsData], isEqual);

  return result;
};
