import { FormField, FormState } from './types';
import { FormErrors } from 'types/form';

export const validate = (state: FormState, fields: FormField[]): FormErrors<FormField> => {
  const errors: FormErrors<FormField> = {};

  if (fields.includes(FormField.name)) {
    if (!state[FormField.name]) {
      errors[FormField.name] = 'Zone name is required';
    }
  }

  if (fields.includes(FormField.owner)) {
    if (!state[FormField.owner]) {
      errors[FormField.owner] = 'Owner is required';
    }
  }

  if (fields.includes(FormField.project)) {
    if (!state[FormField.project]) {
      errors[FormField.project] = 'Project is required';
    }
  }

  return errors;
};
