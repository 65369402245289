import Cookies from 'js-cookie';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import * as actions from 'actions/lorawan';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { sendEmail, getStatus } from 'clients/lorawan';
import { getCookiesLorawanIntegration } from 'utils/cookies';
import { ActionWithPromise } from 'utils/store';
import { ReasonEnum, ApiResponse } from 'models';
import { fetchApiKeysByParams, deleteApiKey } from 'clients/user-management';
import { integrationName } from 'utils/lorawan';

type SendEmailAction =
  | actions.SendEmail
  | ActionWithPromise<actions.SendEmail, boolean>
function* sendEmailSaga(action: SendEmailAction) {
  const response: { status: number; statusText: string } = yield call(sendEmail, action.user);

  if (response.status === 200) {
    const cookie = getCookiesLorawanIntegration(action.integration, action.user.email);
    Cookies.set(cookie.key, cookie.value);
    yield put(NotifySuccess(`Your interest has been registered`));
    'meta' in action && action.meta.promise.resolve(true);
  } else {
    yield put(NotifyError(`Error while sendind request to integration: ${response.statusText}`));
    'meta' in action && action.meta.promise.reject(new Error());
  }
}

function* getStatusSaga(action: actions.GetStatus) {
  const [statuses, keys]: ApiResponse[] = yield all([
    call(getStatus, action.filters.owner),
    call(fetchApiKeysByParams, {
      owner_id: action.filters.owner,
      name: action.filters.type ? integrationName(action.filters.type) : undefined
    })
  ]);

  let statusesData = [];
  let keysData = [];

  if (statuses.reason === ReasonEnum.Ok) {
    statusesData = statuses.data || [];
  } else {
    const message = statuses.message || 'Server error';
    yield put(NotifyError(`Failed getting LoRaWAN integrations statuses: ${message}`));
  }

  if (keys.reason === ReasonEnum.Ok) {
    keysData = keys.data;
  } else {
    const message = keys.message || 'Server error';
    yield put(NotifyError(`Error while fetching API Auth Tokens: ${message}`));
  }

  yield put(actions.DoGetStatusSuccess(statusesData, keysData, action.filters, action.isAdmin));
}

type DisableIntegrationAction =
  | actions.DisableIntegration
  | ActionWithPromise<actions.DisableIntegration, boolean>
function* disableIntegrationSaga(action: DisableIntegrationAction) {
  const response: ApiResponse = yield call(deleteApiKey, action.apiKeyId);

  if (response.reason === ReasonEnum.Ok) {
    yield put(NotifySuccess(`Integration has been deleted`));
    'meta' in action && action.meta.promise.resolve(true);
  } else {
    const message = response.message || 'Server error';
    yield put(NotifyError(`Error while deleting integration: ${message}`));
    'meta' in action && action.meta.promise.reject(new Error());
  }
}

export const lorawanSagas = [
  takeEvery(actions.SEND_EMAIL, sendEmailSaga),
  takeEvery(actions.GET_STATUS, getStatusSaga),
  takeEvery(actions.DISABLE_INTEGRATION, disableIntegrationSaga),
];
