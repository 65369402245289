import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useUserGroupsDictionarySelector } from 'hooks/user-managment';
import { useAuthUserSelector, useDialog } from 'hooks';
import { canCreateUserGroup } from 'utils/permissions';
// components
import { UserGroupCreateDialog, UserGroupTable } from 'components/UserManagement';
import AddButton from 'components/Buttons/AddButton';
import Box from '@material-ui/core/Box';
// styles
import useStyles from 'styles/page';
import { UserNotPermittedComponent } from 'components/UserNotPermitted';


export const UserManagementClientListPage = (): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const createDialog = useDialog();
  const { isLoading, userGroups } = useUserGroupsDictionarySelector();

  if (!user.isAdmin) {
    return <UserNotPermittedComponent pageTitle="Clients" />;
  }

  return (
    <Box className={ classes.root }>
      <Helmet><title>Clients</title></Helmet>
      <Box className={ classes.table } data-testid="clients-list">
        <UserGroupTable
          isLoading={ isLoading }
          data={ userGroups }
        />
      </Box>
      <AddButton label="Add client" isDisabled={ !canCreateUserGroup(user) } onClick={ createDialog.open } />
      { createDialog.isMounted && <UserGroupCreateDialog
        isOpen={ createDialog.isOpen }
        onClose={ createDialog.close }
        onCloseEnd={ createDialog.unmount }
      /> }
    </Box>
  );
};
