import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useQueries } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getTemplateRequestStatistic } from 'clients/http-monitoring';
import { useCustomMemo } from 'hooks';
import {
  CallerRequestHistoryParams,
  CallerRequestHistoryEntry,
} from 'models/caller-monitoring';
import { getResponseData } from 'utils/clients';

import { getDefaultGroupBy, fillGaps } from './utils';

type HistoryByTemplate = Map<number, CallerRequestHistoryEntry[]>;

interface CallerRequestHistory {
  isLoading: boolean;
  historyByTemplate: HistoryByTemplate;
}

export const useCallerRequestHistory = ({
  templateIds,
  ...restParams
}: CallerRequestHistoryParams): CallerRequestHistory => {
  const dispatch = useDispatch();

  const results = useQueries(
    templateIds.map(templateId => {
      const groupBy = restParams.groupBy ?? getDefaultGroupBy(
        restParams.dateFrom,
        restParams.dateTo,
      );
      const params: CallerRequestHistoryParams = {
        ...restParams,
        templateIds: [templateId],
        groupBy,
      };

      return {
        queryKey: ['caller/monitoring/requestHistory', params],
        queryFn: async (): Promise<CallerRequestHistoryEntry[]> => {
          const resData = getResponseData(await getTemplateRequestStatistic(params));
          const historyEntries = resData.map(request => ({
            // формат даты из бекенда: 2021-03-19T17:00:00.000000+00:00
            // мы ожидаем: 2021-03-19T17:00:00.000Z
            date: new Date(request.timestamp).toJSON(),
            value: request.count,
          }));
          const entriesWithoutGaps = fillGaps(
            historyEntries,
            restParams.dateFrom,
            restParams.dateTo,
            groupBy,
            (date, count) => ({ date, value: count }),
          );
          return entriesWithoutGaps;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        onError: (e: Error) => {
          dispatch(NotifyError(`Error while fetching request history: ${ e.message }`));
        },
      };
    }),
  );

  const resultsData = results.map(result => result.data);

  const result = useCustomMemo(() => {
    const templatesHistory = resultsData.map((resultData, index) => ({
      templateId: templateIds[index],
      history: resultData as CallerRequestHistoryEntry[] | undefined,
    }));

    if (!templatesHistory.every(state => state.history)) {
      return {
        isLoading: true,
        historyByTemplate: new Map(),
      };
    }

    return {
      isLoading: false,
      historyByTemplate: templatesHistory.reduce((acc, state) => {
        acc.set(state.templateId, state.history as CallerRequestHistoryEntry[]);
        return acc;
      }, new Map() as HistoryByTemplate),
    };
  }, [resultsData], isEqual);

  return result;
};
