import React, { useState } from 'react';

import { Dialog } from './Dialog';
import { TemplateSuite } from 'models/caller';
import { DeleteTemplateSuite } from 'actions/caller';
import { getCallerTemplateSuiteListPath } from 'routing/paths/caller';
import { useCallerTemplateDictionary } from 'hooks/caller';

// components
import { EnsureDialog, InfoDialog } from 'components/Dialogs';
import { ActionLoader, ActionLoaderProps } from 'components/Loaders';

interface Props {
  open: Dialog;
  onClose: () => void;
  templateSuite?: TemplateSuite;
}

const Component = (props: Props): JSX.Element => {
  const { open, onClose, templateSuite } = props;
  const { templates } = useCallerTemplateDictionary();
  const suiteTemplates = templates.filter(t => t.template_suite_id === templateSuite?.template_suite_id);

  const [action, setAction] = useState<ActionLoaderProps>();
  const handleDelete = (templateSuite: TemplateSuite) => setAction({
    action: DeleteTemplateSuite(templateSuite),
    onSuccess: () => ({
      push: true,
      to: getCallerTemplateSuiteListPath()
    }),
  });

  return (
    <>
      { action ? <ActionLoader { ...action }/> : '' }
      { !(templateSuite && open === Dialog.DELETE) ? '' :
        suiteTemplates.length > 0
          ? (
            <InfoDialog
              name={ 'delete-template-suite' }
              label="Can not delete template suite"
              isOpen={ true }
              onClose={ onClose }
            >
              The template suite <b>"{ templateSuite.name }"</b> can not be deleted, because have templates
            </InfoDialog>
          )
          : (
            <EnsureDialog
              name={ 'delete-template-suite' }
              isOpen={ true }
              onClose={ onClose }
              onYes={ () => handleDelete(templateSuite) }
            >
              The template suite <b>"{ templateSuite.name }"</b> will be deleted
            </EnsureDialog>
          )
      }
    </>
  );
};

export default Component;
