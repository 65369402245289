import { GetFirmwareParams, Firmware } from 'models/firmware-management';

export const FIRMWARE_DATA_LOAD_BY_ID = 'fmw/firmware/LOAD_BY_ID';
export const FIRMWARE_DATA_LOAD_BY_PARAMS = 'fmw/firmware/LOAD_BY_PARAMS';
export const FIRMWARE_DATA_LOAD_DICTIONARY = 'fmw/firmware/LOAD_DICTIONARY';
export const FIRMWARE_DATA_LOAD_START = 'fmw/firmware/LOAD_START';
export const FIRMWARE_DATA_LOAD_FAILURE = 'fmw/firmware/LOAD_FAILURE';
export const FIRMWARE_DATA_LOAD_SUCCESS = 'fmw/firmware/LOAD_SUCCESS';

export interface LoadFirmwareDataById {
  readonly type: typeof FIRMWARE_DATA_LOAD_BY_ID;
  readonly firmwareHash: string;
}

export interface LoadFirmwareDataByParams {
  readonly type: typeof FIRMWARE_DATA_LOAD_BY_PARAMS;
  readonly params: GetFirmwareParams;
}

export interface LoadFirmwareDataDictionary {
  readonly type: typeof FIRMWARE_DATA_LOAD_DICTIONARY;
}

export interface LoadFirmwareDataStart {
  readonly type: typeof FIRMWARE_DATA_LOAD_START;
  readonly params: GetFirmwareParams | string;
}

export interface LoadFirmwareDataFailure {
  readonly type: typeof FIRMWARE_DATA_LOAD_FAILURE;
  readonly params: GetFirmwareParams | string;
  readonly error: string;
}

export interface LoadFirmwareDataSuccess {
  readonly type: typeof FIRMWARE_DATA_LOAD_SUCCESS;
  readonly params: GetFirmwareParams | string;
  readonly data: Firmware[];
}

export const LoadFirmwareDataById = (firmwareHash: string): LoadFirmwareDataById => ({
  type: FIRMWARE_DATA_LOAD_BY_ID, firmwareHash
});

export const LoadFirmwareDataByParams = (params: GetFirmwareParams): LoadFirmwareDataByParams => ({
  type: FIRMWARE_DATA_LOAD_BY_PARAMS, params
});

export const LoadFirmwareDataDictionary = (): LoadFirmwareDataDictionary => ({
  type: FIRMWARE_DATA_LOAD_DICTIONARY
});

export const LoadFirmwareDataStart = (params: GetFirmwareParams | string): LoadFirmwareDataStart => ({
  type: FIRMWARE_DATA_LOAD_START, params
});

export const LoadFirmwareDataFailure = (params: GetFirmwareParams | string, error: string): LoadFirmwareDataFailure => ({
  type: FIRMWARE_DATA_LOAD_FAILURE, params, error
});

export const LoadFirmwareDataSuccess = (params: GetFirmwareParams | string, data: Firmware[]): LoadFirmwareDataSuccess => ({
  type: FIRMWARE_DATA_LOAD_SUCCESS, params, data
});


export type Action =
  | LoadFirmwareDataById
  | LoadFirmwareDataByParams
  | LoadFirmwareDataDictionary
  | LoadFirmwareDataStart
  | LoadFirmwareDataFailure
  | LoadFirmwareDataSuccess
