import { State } from 'reducers/pages/station-monitoring';
import { getDurationDays } from './utils';
import { useStatusChartData } from './hooks/useStatusChartData';

// components
import { Box } from '@material-ui/core';
import { BlockLoader } from 'components/Loaders';
import { Chart } from './components/Chart';

// styles
import { colors } from 'styles/themes/outer';

interface Props {
  uptime: State['uptimeHistory'];
}


export const UptimeHistoryChart = ({ uptime }: Props): JSX.Element => {
  const uptimeHistory = useStatusChartData({ statusHistory: uptime, chartName: 'Power' });

  return (<Box height={ 100 } data-testid="chart">
    { !uptime.fetched ? <BlockLoader size={ 20 } /> :
      <Chart
        data={ uptimeHistory.data }
        maxValue={ uptimeHistory.maxValue }
        title={ 'Power' }
        color={ colors.lightRed }
        valueFormat={ value => value ? 'On' : 'Off' }
        durationDays={ getDurationDays(uptime.dateRange) }
      />
    }
  </Box>);
};
