
export enum PermissionsConfig {
  dm_zone = 'dm_zone',
  dm_device = 'dm_device',
  user_management = 'user_management',
  http_sender = 'http_sender',
  rmq_sender = 'rmq_sender',
  monitoring = 'monitoring',
  raw_messages = 'raw_messages',
  devices = 'devices',
  fmw_management = 'fmw_management',
  stations = 'stations',
  provisioning = 'provisioning',
}

export interface Permissions {
  id: number;
  owner_id: number;
  name: string;
  template?: boolean;

  // permissions configs
  [PermissionsConfig.dm_zone]?: string;
  [PermissionsConfig.dm_device]?: string;
  [PermissionsConfig.user_management]?: string;
  [PermissionsConfig.http_sender]?: string;
  [PermissionsConfig.rmq_sender]?: string;
  [PermissionsConfig.monitoring]?: string;
  [PermissionsConfig.raw_messages]?: string;
  [PermissionsConfig.devices]?: string;
  [PermissionsConfig.fmw_management]?: string;
  [PermissionsConfig.stations]?: string;
  [PermissionsConfig.provisioning]?: string;
}

export interface PermissionRelation {
  type: 'lock' | 'enable';
  config: PermissionsConfig;
  key: string;
}

export interface PermissionsOperation {
  key: string;
  name: string;
  description?: string;
  relations?: PermissionRelation[];
}

export interface PermissionsCategory {
  key: PermissionsConfig;
  name: string;
  operations: PermissionsOperation[];
}

export const PERMISSIONS_CATEGORIES: PermissionsCategory[] = [
  {
    key: PermissionsConfig.dm_zone,
    name: 'Device Management Zones',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create zones, groups and positions inside',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read zones, groups and positions inside',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update zones, groups and positions inside',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete zones, groups and positions inside',
      },
    ],
  },
  {
    key: PermissionsConfig.dm_device,
    name: 'Device Management Devices',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create devices',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read devices',
        relations: [
          { type: 'lock', config: PermissionsConfig.devices, key: 'f' },
        ]
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update devices',
        relations: [
          { type: 'lock', config: PermissionsConfig.devices, key: 'f' },
        ]
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete devices',
      },
      {
        key: 'b',
        name: 'Bind',
        description: 'Bind/unbind devices to positions',
      }
    ],
  },
  {
    key: PermissionsConfig.user_management,
    name: 'User Management',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create users, permissions, user groups and projects',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read users, permissions, user groups and projects',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update users, permissions, user groups and projects',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete users, permissions, user groups and projects',
      },
    ],
  },
  {
    key: PermissionsConfig.fmw_management,
    name: 'Firmware Management',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create firmwares',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read firmwares',
        relations: [
          { type: 'lock', config: PermissionsConfig.devices, key: 'f' },
        ]
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update firmwares',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete firmwares',
      },
    ],
  },
  {
    key: PermissionsConfig.http_sender,
    name: 'HTTP Sender',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create rules, templates and template suites',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read rules, templates and template suites',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update rules, templates and template suites',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete rules, templates and template suites',
      },
      {
        key: 'h',
        name: 'History',
        description: 'Read history events'
      },
    ],
  },
  {
    key: PermissionsConfig.rmq_sender,
    name: 'RabbitMQ Sender',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create rules and endpoints',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read rules and endpoints',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update rules and endpoints',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete rules and endpoints',
      },
      {
        key: 'h',
        name: 'History',
        description: 'Read history events'
      },
    ],
  },
  {
    key: PermissionsConfig.monitoring,
    name: 'Monitoring',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create incidents (by Replacement app)',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read incidents and devices with incidents',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update incidents (resolving)',
      },
      {
        key: 'e',
        name: 'Extended',
        description: 'Show hardware errors by type',
      },
    ],
  },
  {
    key: PermissionsConfig.raw_messages,
    name: 'Raw Messages',
    operations: [
      {
        key: 'r',
        name: 'Read',
        description: 'Read raw messages',
      },
    ],
  },
  {
    key: PermissionsConfig.devices,
    name: 'Devices',
    operations: [
      {
        key: 'o',
        name: 'Record',
      },
      {
        key: 'v',
        name: 'Reset device',
        relations: [
          { type: 'lock', config: PermissionsConfig.devices, key: 'f' },
        ]
      },
      {
        key: 'a',
        name: 'Calibration',
      },
      {
        key: 'f',
        name: 'Update Firmware',
        relations: [
          { type: 'enable', config: PermissionsConfig.devices, key: 'v' },
          { type: 'enable', config: PermissionsConfig.dm_device, key: 'r' },
          { type: 'enable', config: PermissionsConfig.dm_device, key: 'u' },
          { type: 'enable', config: PermissionsConfig.fmw_management, key: 'r' },
        ]
      },
    ],
  },
  {
    key: PermissionsConfig.stations,
    name: 'Base Stations',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create base stations, locations and responsible persons',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Read base stations, locations and responsible persons',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update base stations, locations and responsible persons, bind and unbind stations to/from locations, bind/unbind responsible persons',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Update base stations, locations and responsible persons, bind and unbind stations to/from locations, bind/unbind responsible persons',
      },
    ],
  },
  {
    key: PermissionsConfig.provisioning,
    name: 'Device Provisioning',
    operations: [
      {
        key: 'c',
        name: 'Create',
        description: 'Create boxes',
      },
      {
        key: 'r',
        name: 'Read',
        description: 'Get devices, boxes, shipments',
      },
      {
        key: 'u',
        name: 'Update',
        description: 'Update device status, add a device to box, remove a device from the box, add box to shipment, remove a box from the shipment, update shipments fields and status',
      },
      {
        key: 'd',
        name: 'Delete',
        description: 'Delete not empty boxes',
      },
    ],
  },
];
