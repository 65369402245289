import { GetTemplateParams, Template } from 'models/caller';

export const CALLER_TEMPLATE_DATA_LOAD_BY_ID = 'caller/template/LOAD_BY_ID';
export const CALLER_TEMPLATE_DATA_LOAD_BY_PARAMS = 'caller/template/LOAD_BY_PARAMS';
export const CALLER_TEMPLATE_DATA_LOAD_DICTIONARY = 'caller/template/LOAD_DICTIONARY';
export const CALLER_TEMPLATE_DATA_LOAD_START = 'caller/template/LOAD_START';
export const CALLER_TEMPLATE_DATA_LOAD_SUCCESS = 'caller/template/LOAD_SUCCESS';
export const CALLER_TEMPLATE_DATA_LOAD_FAILURE = 'caller/template/LOAD_FAILURE';

export interface LoadCallerTemplateDataById {
  readonly type: typeof CALLER_TEMPLATE_DATA_LOAD_BY_ID;
  readonly templateId: number;
}

export interface LoadCallerTemplateDataByParams {
  readonly type: typeof CALLER_TEMPLATE_DATA_LOAD_BY_PARAMS;
  readonly params: GetTemplateParams;
}

export interface LoadCallerTemplateDataDictionary {
  readonly type: typeof CALLER_TEMPLATE_DATA_LOAD_DICTIONARY;
}

export interface LoadCallerTemplateDataStart {
  readonly type: typeof CALLER_TEMPLATE_DATA_LOAD_START;
  readonly params: GetTemplateParams | number;
}

export interface LoadCallerTemplateDataSuccess {
  readonly type: typeof CALLER_TEMPLATE_DATA_LOAD_SUCCESS;
  readonly params: GetTemplateParams | number;
  readonly data: Template[];
}

export interface LoadCallerTemplateDataFailure {
  readonly type: typeof CALLER_TEMPLATE_DATA_LOAD_FAILURE;
  readonly params: GetTemplateParams | number;
  readonly error: string;
}

export const LoadCallerTemplateDataById = (templateId: number): LoadCallerTemplateDataById => ({
  type: CALLER_TEMPLATE_DATA_LOAD_BY_ID, templateId
});

export const LoadCallerTemplateDataByParams = (params: GetTemplateParams): LoadCallerTemplateDataByParams => ({
  type: CALLER_TEMPLATE_DATA_LOAD_BY_PARAMS, params
});

export const LoadCallerTemplateDataDictionary = (): LoadCallerTemplateDataDictionary => ({
  type: CALLER_TEMPLATE_DATA_LOAD_DICTIONARY
});

export const LoadCallerTemplateDataStart = (params: GetTemplateParams | number): LoadCallerTemplateDataStart => ({
  type: CALLER_TEMPLATE_DATA_LOAD_START, params
});

export const LoadCallerTemplateDataSuccess = (params: GetTemplateParams | number, data: Template[]): LoadCallerTemplateDataSuccess => ({
  type: CALLER_TEMPLATE_DATA_LOAD_SUCCESS, params, data
});

export const LoadCallerTemplateDataFailure = (params: GetTemplateParams | number, error: string): LoadCallerTemplateDataFailure => ({
  type: CALLER_TEMPLATE_DATA_LOAD_FAILURE, params, error
});

export type Action =
  | LoadCallerTemplateDataById
  | LoadCallerTemplateDataByParams
  | LoadCallerTemplateDataDictionary
  | LoadCallerTemplateDataStart
  | LoadCallerTemplateDataSuccess
  | LoadCallerTemplateDataFailure


