import { Endpoint, GetEndpointsParams } from 'models/rabbit';

export const RABBIT_ENDPOINT_DATA_LOAD_BY_ID = 'rabbit/endpoint/LOAD_BY_ID';
export const RABBIT_ENDPOINT_DATA_LOAD_BY_PARAMS = 'rabbit/endpoint/LOAD_BY_PARAMS';
export const RABBIT_ENDPOINT_DATA_LOAD_DICTIONARY = 'rabbit/endpoint//LOAD_DICTIONARY';
export const RABBIT_ENDPOINT_DATA_LOAD_START = 'rabbit/endpoint/LOAD_START';
export const RABBIT_ENDPOINT_DATA_LOAD_FAILURE = 'rabbit/endpoint/LOAD_FAILURE';
export const RABBIT_ENDPOINT_DATA_LOAD_SUCCESS = 'rabbit/endpoint/LOAD_SUCCESS';

export interface LoadRabbitEndpointDataById {
  readonly type: typeof RABBIT_ENDPOINT_DATA_LOAD_BY_ID;
  readonly endpointId: number;
}

export interface LoadRabbitEndpointDataByParams {
  readonly type: typeof RABBIT_ENDPOINT_DATA_LOAD_BY_PARAMS;
  readonly params: GetEndpointsParams;
}

export interface LoadRabbitEndpointDataDictionary {
  readonly type: typeof RABBIT_ENDPOINT_DATA_LOAD_DICTIONARY;
}

export interface LoadRabbitEndpointDataStart {
  readonly type: typeof RABBIT_ENDPOINT_DATA_LOAD_START;
  readonly params: GetEndpointsParams | number;
}

export interface LoadRabbitEndpointDataFailure {
  readonly type: typeof RABBIT_ENDPOINT_DATA_LOAD_FAILURE;
  readonly params: GetEndpointsParams | number;
  readonly error: string;
}

export interface LoadRabbitEndpointDataSuccess {
  readonly type: typeof RABBIT_ENDPOINT_DATA_LOAD_SUCCESS;
  readonly params: GetEndpointsParams | number;
  readonly data: Endpoint[];
  readonly total: number;
}

export const LoadRabbitEndpointDataById = (endpointId: number): LoadRabbitEndpointDataById => ({
  type: RABBIT_ENDPOINT_DATA_LOAD_BY_ID, endpointId
});

export const LoadRabbitEndpointDataByParams = (params: GetEndpointsParams): LoadRabbitEndpointDataByParams => ({
  type: RABBIT_ENDPOINT_DATA_LOAD_BY_PARAMS, params
});

export const LoadRabbitEndpointDataDictionary = (): LoadRabbitEndpointDataDictionary => ({
  type: RABBIT_ENDPOINT_DATA_LOAD_DICTIONARY
});

export const LoadRabbitEndpointDataStart = (params: GetEndpointsParams | number): LoadRabbitEndpointDataStart => ({
  type: RABBIT_ENDPOINT_DATA_LOAD_START, params
});

export const LoadRabbitEndpointDataFailure = (params: GetEndpointsParams | number, error: string): LoadRabbitEndpointDataFailure => ({
  type: RABBIT_ENDPOINT_DATA_LOAD_FAILURE, params, error
});

export const LoadRabbitEndpointDataSuccess = (params: GetEndpointsParams | number, data: Endpoint[], total: number): LoadRabbitEndpointDataSuccess => ({
  type: RABBIT_ENDPOINT_DATA_LOAD_SUCCESS, params, data, total
});

export type Action =
  | LoadRabbitEndpointDataById
  | LoadRabbitEndpointDataByParams
  | LoadRabbitEndpointDataDictionary
  | LoadRabbitEndpointDataStart
  | LoadRabbitEndpointDataFailure
  | LoadRabbitEndpointDataSuccess