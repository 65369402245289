import { Filters as AllFilters } from 'hooks/device-management/useDmTree/types';
import { useLocationItems } from 'hooks/location';
import { UrlItems } from 'utils/routing/query';
import { Parser } from 'utils/routing/parser';
import { useEffectOnce } from 'react-use';
import { DEFAULT_LIMIT, DEFAULT_LIMIT_OPTIONS, DEFAULT_OFFSET, Pagination } from 'utils/paginator';

export type Filters = Omit<AllFilters, 'limit' | 'offset' | 'topmostEntityType'>;

interface DeviceManagementLocationFilter {
  filter: Filters;
  onChange: (filter: Filters) => void;
}

/**
 * DEPRECATED
 * for pagination use in AllDevices only
 */
export const useLocationPagination = (
  options: number[] = DEFAULT_LIMIT_OPTIONS
): Pagination => {
  const { items, setItems } = useLocationItems();

  const parser = new Parser(items as { limit: number; offset: number });
  let limit = parser.asNumber('limit') ?? DEFAULT_LIMIT;

  if (!options.includes(limit)) {
    limit = DEFAULT_LIMIT;
    setItems(prevItems => ({ ...prevItems, limit }));
  }

  let offset = Math.abs(parser.asNumber('offset') || DEFAULT_OFFSET);
  const page = Math.trunc(offset / limit);
  if (page * limit !== offset) {
    offset = page * limit;
    setItems(prevItems => ({ ...prevItems, offset }));
  }

  useEffectOnce(() => {
    if (!parser.asNumber('offset')) {
      setItems(prevItems => ({ ...prevItems, offset }));
    }
    if (!parser.asNumber('limit')) {
      setItems(prevItems => ({ ...prevItems, limit }));
    }
  });

  return {
    offset: offset,
    limit: limit,
    page: page,
    rowCount: limit,
    onChangePage: page => setItems(prevItems => ({ ...prevItems, offset: page * limit })),
    onChangeRowCount: rowCount => setItems(prevItems => ({ ...prevItems, limit: rowCount, offset: undefined })),
  };
};


export const useFilterFromLocation = (): DeviceManagementLocationFilter => {
  const location = useLocationItems();
  const filters = buildState(location.items);

  return {
    filter: filters,
    onChange: newFilter => location.setItems(newFilter),
  };
};

function buildState(items: UrlItems): Filters {
  const parser = new Parser<Filters>(items);
  const state: Filters = {
    owner: parser.asNumber('owner'),
    projects: parser.asNumbers('projects'),
    zones: parser.asNumbers('zones'),
    levels: parser.asNumbers('levels'),
    groups: parser.asNumbers('groups'),
    positions: parser.asNumbers('positions'),
    networkIds: parser.asStrings('networkIds'),
    customIds: parser.asStrings('customIds'),
    innerIds: parser.asNumbers('innerIds'),
    devices: parser.asStrings('devices'),
    labels: parser.asNumbers('labels'),
  };

  return state;
}
