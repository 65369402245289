import {
  ProjectsFilters,
  initProjectsFilters,
  ProjectsFilterFields as Fields,
  DetailPage,
  DeleteAction
} from 'models/projects';
import { Parser } from 'utils/routing/parser';
import * as actions from 'actions/projects';
import { getUrlItems, UrlItems } from 'utils/routing/query';

export interface State {
  listPage: {
    filters: ProjectsFilters;
  };
  detailPage: DetailPage;
  deleteAction: DeleteAction;
}

const initState: State = {
  listPage: {
    filters: initProjectsFilters,
  },
  detailPage: {
    byId: undefined,
    isFetchingById: false,
    editProject: {
      isFetching: false,
      lastEditedProject: undefined,
    },
    isFetchingCreate: false,
  },
  deleteAction: {
    deleteProjectId: undefined,
  }
};

function getFiltersFromUrl(urlState: UrlItems): ProjectsFilters {
  const parser = new Parser(urlState as ProjectsFilters);
  return {
    owner: parser.asNumber(Fields.owner) || undefined,
    projects: parser.asNumbers(Fields.projects) || [],
  };
}

export const reducer = (state: State = initState, action: actions.Action): State => {
  switch (action.type) {
    case actions.SET_PROJECTS_FILTERS:
      return {
        ...state,
        listPage: {
          ...state.listPage,
          filters: action.filters,
        }
      };
    case actions.UPDATE_PROJECTS_FILTERS_FROM_URL:
      const urlFilters = getUrlItems(Object.values(Fields));
      return {
        ...state,
        listPage: {
          ...state.listPage,
          filters: {
            ...state.listPage.filters,
            ...getFiltersFromUrl(urlFilters)
          },
        }
      };
    case actions.CREATE_PROJECT:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          isFetchingCreate: true,
        }
      };
    case actions.CREATE_PROJECT_SUCCESS:
    case actions.CREATE_PROJECT_FAILED:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          isFetchingCreate: false,
        }
      };
    case actions.GET_PROJECT_BY_ID:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          isFetchingById: true
        }
      };
    case actions.GET_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          byId: action.byId,
          isFetchingById: false
        }
      };
    case actions.GET_PROJECT_BY_ID_FAILED:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          isFetchingById: false
        }
      };
    case actions.EDIT_RESORCE_GROUP:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          editProject: {
            ...state.detailPage.editProject,
            isFetching: true
          },
        }
      };
    case actions.EDIT_RESORCE_GROUP_SUCCESS:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          editProject: {
            ...state.detailPage.editProject,
            lastEditedProject: action.project,
            isFetching: false,
          },
        }
      };
    case actions.EDIT_RESORCE_GROUP_FAILED:
      return {
        ...state,
        detailPage: {
          ...state.detailPage,
          editProject: {
            ...state.detailPage.editProject,
            isFetching: false
          },
        }
      };
    case actions.DELETE_RESORCE_GROUP_SUCCESS:
      return {
        ...state,
        deleteAction: {
          ...state.deleteAction,
          deleteProjectId: action.id,
        }
      };
    case actions.RESET_DELETED_PROJECT_ID:
      return {
        ...state,
        deleteAction: {
          ...state.deleteAction,
          deleteProjectId: undefined,
        }
      };
    default:
      return state;
  }
};
