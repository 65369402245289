import React from 'react';
import { useFormActionLoader } from 'hooks';
import { DeleteStation } from 'actions/base-station';
import { Station } from 'models/base-station';
import { getBaseStationListPath } from 'routing/paths';
// components
import { EnsureDialog, InfoDialog } from 'components/Dialogs';

interface Props {
  station: Station;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const DeleteDialog = (props: Props): JSX.Element => {
  const { station, isOpen, onClose, onCloseEnd } = props;
  const { doAction } = useFormActionLoader();

  if (station.location) {
    return (
      <InfoDialog
        name="delete-station"
        label="Can not delete station"
        isOpen={ isOpen }
        onClose={ onClose }
        onCloseEnd={ onCloseEnd }
      >
        The station <b>{ station.id }</b> can not be deleted.<br/>
        First detach the station from the location.
      </InfoDialog>
    );
  }

  const handleDelete = () => doAction({
    action: DeleteStation(station),
    onSuccess: () => ({ to: getBaseStationListPath() }),
  });

  return (
    <EnsureDialog
      name="delete-station"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
    >
      The station <b>{ station.id }</b> will be deleted.
    </EnsureDialog>
  );
};
