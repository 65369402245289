import React from 'react';
import { User } from 'models/user-management';
import { Project } from 'models/user-management';
import { useDialog } from 'hooks';
import { commonOptions } from 'utils/tables';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableLoadingLayout } from 'components/Table';
import { UserLink } from 'components/Links';
import { Button } from '@material-ui/core';
import { AddUserModal } from 'components/UserManagement/Projects';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getTableTheme } from 'styles/themes';

interface Props {
  users: User[];
  isFetching: boolean;
  project: Project;
  allowedToAddUsers: boolean;
}

export const AllowedUsers = ({ users, isFetching, project, allowedToAddUsers }: Props): JSX.Element => {
  const addDialog = useDialog();

  const columns: MUIDataTableColumn[] = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: ([email, sub]: string[]) => <UserLink firstName={ email } lastName={ '' } sub={ sub } />
      }
    },
    {
      name: 'name',
      label: 'User Name',
    },
  ];

  const data = users.map(user => ({
    email: [user.email, user.sub],
    name: `${user.first_name} ${user.last_name}`,
  }));

  const options: MUIDataTableOptions = {
    ...commonOptions,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    customToolbar: () => <Button color="primary" onClick={ addDialog.open } disabled={ !allowedToAddUsers }>Add</Button>,
  };

  return (
    <>
      <MuiThemeProvider theme={ getTableTheme() }>
        <TableLoadingLayout isLoading={ isFetching }>
          <MUIDataTable
            title={ 'Allowed users' }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>
      { addDialog.isMounted && <AddUserModal
        users={ users }
        isOpen={ addDialog.isOpen }
        onClose={ addDialog.close }
        onCloseEnd={ addDialog.unmount }
        project={ project }
      /> }
    </>
  );
};
