import React from 'react';
import { styled } from 'styles/utils';
import { Fade, LinearProgress } from '@material-ui/core';

const Progress = styled(LinearProgress)({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999999,
  width: '100%',
});

interface MainLoaderProps {
  in?: boolean;
}

export default function StyledComponents(props: MainLoaderProps) {
  return (
    <Fade
      in={props.in}
      timeout={ { appear: 0, exit: 1000 } }
      mountOnEnter
      unmountOnExit
    >
      <Progress aria-label="The website's main progress bar" color="primary" />
    </Fade>
  );
}
