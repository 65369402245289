import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { WarrantyFormField, Warranty, WarrantyCreateBody } from 'models/provisioning';

// components
import { TextControl } from 'components/Controls';

// styles
import { useStyles } from './styles';

type FormValues = Warranty & WarrantyCreateBody;

export function WarrantyForm<T extends Warranty | WarrantyCreateBody>(props: FormProps<T, WarrantyFormField>): JSX.Element {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => { // need use useDeepCompare because showFields is array and change every render
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  function handleChange<TField extends WarrantyFormField>(
    field: TField,
    value: FormValues[TField],
  ) {
    onChange && onChange({ ...state, [field]: value }, field);
  }

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(WarrantyFormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(WarrantyFormField.name) }
          label="Name"
          name="warranty[name]"
          value={ state.name || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(WarrantyFormField.name, event.target.value)
          }
          error={ showErrors.includes(WarrantyFormField.name) ? errors?.name !== undefined : false }
          helperText={ showErrors.includes(WarrantyFormField.name) ? errors?.name : undefined }
        />
      }

      { !showFields.includes(WarrantyFormField.max_keeping_in_warehouse) ? '' :
        <TextControl
          type="number"
          helperText="Months"
          required={ !isView }
          disabled={ isView || disabledFields.includes(WarrantyFormField.max_keeping_in_warehouse) }
          label="Max keeping in warehouse"
          name="warranty[max_keeping]"
          value={ state.max_keeping_in_warehouse || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(WarrantyFormField.max_keeping_in_warehouse, Number(event.target.value) || 0)
          }
          error={ showErrors.includes(WarrantyFormField.max_keeping_in_warehouse) ? errors?.max_keeping_in_warehouse !== undefined : false }
        />
      }

      { !showFields.includes(WarrantyFormField.max_warranty_after_install) ? '' :
        <TextControl
          type="number"
          helperText="Months"
          required={ !isView }
          disabled={ isView || disabledFields.includes(WarrantyFormField.max_warranty_after_install) }
          label="Max warranty after install"
          name="warranty[max_warranty]"
          value={ state.max_warranty_after_install || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(WarrantyFormField.max_warranty_after_install, Number(event.target.value) || 0)
          }
          error={ showErrors.includes(WarrantyFormField.max_warranty_after_install) ? errors?.max_warranty_after_install !== undefined : false }
        />
      }
    </form>
  );
}

