import { SubtreeManagementOptions } from '../../types';
import { fillZoneTotalSilentDevices } from './fillZoneTotalSilentDevices';
import { fillGroupTotalSilentDevices } from './fillGroupTotalSilentDevices';
import { fillPositionDeviceSilent } from './fillPositionDeviceSilent';

export async function fillTotalSilentDevices(options: SubtreeManagementOptions) {
  await Promise.all([
    fillZoneTotalSilentDevices(options),
    fillGroupTotalSilentDevices(options),
    fillPositionDeviceSilent(options),
  ]);
}
