import React from 'react';
import { Button } from '@material-ui/core';
import { clearLocationParams } from 'utils/routing/query';

const action = () => (
  <Button color="default" onClick={ () => clearLocationParams(true) }>
    Reload
  </Button>
);

export default action;
