import React from 'react';
import { useLevelsSelector } from 'hooks/device-management';

// components
import { LevelLink } from 'components/Links';
import { LoaderSvg } from 'components/Loaders';

interface Props {
  levelId: number;
  name?: string;
  noLink?: boolean;
  noLoad?: boolean;
}

export const LevelLabel = (props: Props): JSX.Element => {
  const { levelId, name = '-', noLink = false, noLoad = false } = props;

  const levelsState = useLevelsSelector({
    params: { levels: [levelId] },
    withFindById: true,
    skipLoading: noLoad
  });

  if (!noLoad && levelsState.isLoading) {
    return (
      <LoaderSvg/>
    );
  }

  const level = levelsState.findLevelById(levelId);
  if (!level) {
    return (
      <>{ name }</>
    );
  }

  if (noLink) {
    return (
      <>{ level.name }</>
    );
  }

  return (
    <LevelLink
      zoneId={ level.zone_id }
      levelId={ level.id }
      withReturnUrl
    >
      { level.name }
    </LevelLink>
  );
};
