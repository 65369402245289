import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { NotifyError } from 'actions/notifier';
import { getResponseData } from 'utils/clients';
import { GetSendingStatusStatisticParams, SendingStatusStatistic } from 'models/rabbit-monitoring';
import { getSendingStatusStatistic } from 'clients/rabbit-monitoring';

interface SendingStatusStatisticState {
  isLoading: boolean;
  statistic: SendingStatusStatistic[];
}

export const useRabbitSendingStatusStatistic = (params: GetSendingStatusStatisticParams): SendingStatusStatisticState => {
  const dispatch = useDispatch();
  const queryResult = useQuery({
    queryKey: ['rabbit/SendingStatusStatistic', params],
    queryFn: async () => getResponseData(await getSendingStatusStatistic(params)),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: e => {
      dispatch(NotifyError(`Error while fetching sending status statistic: ${ (e as Error).message }`));
    },
  });

  return {
    // statistic: queryResult.data,
    isLoading: queryResult.isLoading,
    statistic: queryResult.data?.map(s => ({
      ...s,
      date: moment(s.timestamp).toDate(),
    })) ?? [],
  };
};

