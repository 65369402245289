import * as Actions from 'actions/user-management';
import { UserGroupServiceAvailability } from 'models/user-management';

export interface State {
  isFetching: boolean;
  userGroupId: number | null;
  data: UserGroupServiceAvailability[];
}

const initState: State = {
  isFetching: false,
  userGroupId: null,
  data: [],
};

export const reducer = (state: State = initState, action: Actions.Action): State => {
  switch (action.type) {
    case Actions.UM_USER_GROUP_SERVICE_STATUS_CHANGE_START:
      return { ...state, isFetching: true };
    case Actions.UM_USER_GROUP_SERVICE_STATUS_CHANGE_FINISH:
      return { ...state, isFetching: false };
    case Actions.UM_USER_GROUP_SERVICES_GET_SUCCESS:
      return {
        userGroupId: action.userGroupId,
        isFetching: true,
        data: action.data,
      };
    case Actions.UM_USER_GROUP_SERVICES_GET_FAILED:
      return {
        userGroupId: null,
        isFetching: false,
        data: [],
      };
    default:
      return state;
  }
};
