import { useEffect, useState } from 'react';

interface DataLoaderHookOptions {
  /**
   * If it changes, the loader will be reset, then being able to load again.
   * Think of it just like "key" React prop resets the state of element
   * (in fact, "key" React prop unmounts and then mounts the element from scratch).
   */
  key?: number | string | null;
  load: () => void;
  shouldStartLoading: boolean;
}

interface DataLoaderHookResult {
  /**
   * We haven't initiated the loading yet but should start soon.
   */
  aboutToStartLoading: boolean;
  loadInitiatedOnce: boolean;
}

export function useDataLoaderOnce(options: DataLoaderHookOptions): DataLoaderHookResult {
  const [loadInitiatedOnce, setLoadInitiatedOnce] = useState(false);

  useEffect(
    () => setLoadInitiatedOnce(false),
    [options.key]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => {
      if (loadInitiatedOnce) {
        return;
      }

      if (options.shouldStartLoading) {
        options.load();
        setLoadInitiatedOnce(true);
      }
    },
  );

  return {
    aboutToStartLoading: !loadInitiatedOnce && options.shouldStartLoading,
    loadInitiatedOnce,
  };
}
