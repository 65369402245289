import { isNil } from 'lodash';

export function countFilterSet<Filters>(filters: Filters, skip?: Record<string, string | number | null>): number {
  let count = 0;
  for (const [name, value] of Object.entries(filters)) {
    if (name === 'limit' || name === 'offset' || isNil(value)) {
      continue;
    }

    if (skip && (skip[name] === null || skip[name] === value)) {
      continue;
    }

    if (name === 'connectivity') {
      Array.isArray(value) && (value[0] !== 0 || value[1] !== 100) && count++;
    } else if (Array.isArray(value)) {
      value.length > 0 && count++;
    } else if (typeof value === 'string') {
      value !== '' && count++;
    } else if (typeof value === 'number') {
      !isNaN(value) && count++;
    } else {
      count++;
    }
  }
  return count;
}
