import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { UserGroup } from 'models/user-management';
import { FormProps } from 'types/form';
import { validate } from './validator';
import { FormField } from './types';
// components
import Box from '@material-ui/core/Box';
import { CopyToClipboardButton, RefreshButton } from 'components/Buttons';
import { PhoneNumberControl, TextControl } from 'components/Controls';

// styles
import { useStyles } from './styles';

export {
  FormField as UserGroupFormField
};

type Props = FormProps<UserGroup, FormField> & {
  onRefreshAccessCode?: () => void;
}

export const UserGroupForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { isView, state, errors, showFields, onChange, onValidate, onRefreshAccessCode } = props;
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = <TField extends FormField>(field: TField, value: UserGroup[TField]) => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form className={ isView ? classes.viewMode : classes.editMode } noValidate autoComplete="off">
      { !showFields.includes(FormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.name) }
          label="Name"
          name="userGroup[name]"
          value={ state.name || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.name, String(event.target.value || ''))
          }
          error={ showErrors.includes(FormField.name) ? errors?.name !== undefined : false }
          helperText={ showErrors.includes(FormField.name) ? errors?.name : undefined }
        />
      }
      { !showFields.includes(FormField.address) ? '' :
        <TextControl
          disabled={ isView || disabledFields.includes(FormField.address) }
          label="Address"
          name="userGroup[address]"
          value={ state.address || '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(FormField.address, String(event.target.value || ''))
          }
          error={ showErrors.includes(FormField.address) ? errors?.address !== undefined : false }
          helperText={ showErrors.includes(FormField.address) ? errors?.address : undefined }
        />
      }
      { !showFields.includes(FormField.phone_number) ? '' :
        <PhoneNumberControl
          fullWidth
          margin="normal"
          label="Phone Number"
          name="userGroup[phone]"
          disabled={ isView || disabledFields.includes(FormField.phone_number) }
          value={ state.phone_number }
          onChange={ phone => handleChange(FormField.phone_number, phone) }
          error={ showErrors.includes(FormField.phone_number) ? errors?.phone_number : undefined }
        />
      }
      { !showFields.includes(FormField.client_access_code) ? '' :
        <Box display="flex" alignItems="baseline">
          <TextControl
            disabled={ isView || disabledFields.includes(FormField.client_access_code) }
            label="Access code"
            name="userGroup[client_access_code]"
            value={ state.client_access_code || '' }
            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(FormField.client_access_code, Number(event.target.value) || undefined)
            }
            error={ showErrors.includes(FormField.client_access_code) ? errors?.client_access_code !== undefined : false }
            helperText={ showErrors.includes(FormField.client_access_code) ? errors?.client_access_code : undefined }
          />
          <CopyToClipboardButton
            type="icon"
            value={ String(state.client_access_code ?? '') }
            copied={ `Access code was copied` }
          />
          { !onRefreshAccessCode ? '' :
            <RefreshButton type="icon" label="Refresh Code" onClick={ onRefreshAccessCode }/>
          }
        </Box>
      }
    </form>
  );
};

