import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, ReasonEnum } from 'models';
import * as Action from 'actions/base-station';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createStation, deleteStation, updateLoraStation, updateStation } from 'clients/base-stations';
import { ActionWithPromise } from 'utils/store';
import { createErrorFromApiResponse } from 'utils/errors';
import {
  Station,
  StationCreate,
  StationUpdate,
  StationLocation,
  LoRaWANStationUpdate,
  LorawanStation,
  BaseStation,
  StationType
} from 'models/base-station';
import { clearQueryCache } from 'utils/react-query';

type CreateAction =
  | Action.CreateStation
  | ActionWithPromise<Action.CreateStation, Station>

function* doCreateStation(action: CreateAction) {
  const { station } = action;
  const create: StationCreate = {
    id: station.id,
    ownerId: station.owner_id,
    generation: station.generation,
    receiverType: station.receiver_type,
    frequency: station.frequency,
  };
  const response: ApiResponse = yield call(createStation, create);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: Station = response.data as Station;
    yield put(NotifySuccess(`Base station #${ newStation.id } has been created`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while creating a base station: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type UpdateAction =
  | Action.UpdateStation
  | ActionWithPromise<Action.UpdateStation, Station>

function* doUpdateStation(action: UpdateAction) {
  const { station } = action;
  const update: StationUpdate = {
    ownerId: station.owner_id,
    generation: station.generation,
    receiverType: station.receiver_type,
    frequency: station.frequency,
    locationId: station.location?.id,
  };
  const response: ApiResponse = yield call(updateStation, station.id, update);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: Station = response.data as Station;
    yield put(NotifySuccess(`Base station #${ newStation.id } has been updated`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while updating base station #${ station.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DeleteAction =
  | Action.DeleteStation
  | ActionWithPromise<Action.DeleteStation, Station>

function* doDeleteStation(action: DeleteAction) {
  const { station } = action;
  const response: ApiResponse = yield call(deleteStation, station.id);

  if (response.reason === ReasonEnum.Ok) {
    yield put(NotifySuccess(`Base station #${ station.id } has been deleted`));
    'meta' in action && action.meta.promise.resolve(station);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while deleting base station #${ station.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type DetachLocationAction =
  | Action.DetachStationLocation
  | ActionWithPromise<Action.DetachStationLocation, BaseStation>

function* doDetachStationLocation(action: DetachLocationAction) {
  const { station } = action;
  const update: StationUpdate = {
    ownerId: station.owner_id,
    generation: station.generation,
    receiverType: station.receiver_type,
    frequency: station.frequency,
    locationId: null,
  };

  const response: ApiResponse = yield call(updateStation, station.id, update);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: Station = response.data as Station;
    yield put(Action.ChangeStationLocation(
      { ...station.location as StationLocation, station_id: null },
      { update: true }
    ));
    yield put(NotifySuccess(`The location has been unbound from the station #${ newStation.id }`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while unbinding location from the station #${ station.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type AttachLoraLocationAction =
  | Action.AttachLoraStationLocation
  | ActionWithPromise<Action.AttachLoraStationLocation, LorawanStation>

type AttachLocationAction =
  | Action.AttachStationLocation
  | ActionWithPromise<Action.AttachStationLocation, Station>

function* doAttachStationLocation(action: AttachLocationAction) {
  const { station, location } = action;
  const update: StationUpdate = {
    ownerId: station.owner_id,
    generation: station.generation,
    receiverType: station.receiver_type,
    frequency: station.frequency,
    locationId: location.id,
  };

  const response: ApiResponse = yield call(updateStation, station.id, update);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: Station = response.data as Station;
    yield put(Action.ChangeStationLocation(
      newStation.location as StationLocation,
      { update: true }
    ));
    yield put(NotifySuccess(`Base station #${ newStation.id } has been attached to location`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while attaching base station #${ station.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}
function* doAttachLoraStationLocation(action: AttachLoraLocationAction) {
  const { station, location } = action;
  const update: LoRaWANStationUpdate = {
    owner_id: station.owner_id,
    locationId: location.id,
    aws_id: station.aws_id,
    eui: station.eui,
    rf_region: station.rf_region,
    name: station.name,
  };

  const response: ApiResponse = yield call(updateLoraStation, station.id, update);

  if (response.reason === ReasonEnum.Ok) {
    const newStation: LorawanStation = response.data as LorawanStation;
    yield put(Action.ChangeStationLocation(
      newStation.location as StationLocation,
      { update: true }
    ));
    yield put(NotifySuccess(`Base station #${ newStation.eui } has been attached to location`));
    'meta' in action && action.meta.promise.resolve(newStation);
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while attaching base station #${ station.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

type ReplaceLocationAction =
  | Action.ReplaceStationLocation
  | ActionWithPromise<Action.ReplaceStationLocation, Station>
function* doReplaceStationLocation(action: ReplaceLocationAction) {
  const { oldStation, newStation, location } = action;

  // DETACHING
  let detachResponse: ApiResponse;
  if (oldStation.type === StationType.lorawan) { // IS LORAWAN
    const detachUpdate: LoRaWANStationUpdate = {
      owner_id: oldStation.owner_id,
      aws_id: oldStation.aws_id,
      eui: oldStation.eui,
      rf_region: oldStation.rf_region,
      name: oldStation.name,
      locationId: null,
    };
    detachResponse = yield call(updateLoraStation, oldStation.id, detachUpdate);
  } else { // IS BASE STATION
    const detachUpdate: StationUpdate = {
      ownerId: oldStation.owner_id,
      generation: oldStation.generation,
      receiverType: oldStation.receiver_type,
      frequency: oldStation.frequency,
      locationId: null,
    };
    detachResponse = yield call(updateStation, oldStation.id, detachUpdate);
  }

  if (detachResponse.reason === ReasonEnum.Ok) {
    action.oldStation = detachResponse.data as Station;
  } else {
    const error = createErrorFromApiResponse(detachResponse);
    yield put(NotifyError(`Error while replacing station #${ oldStation.type === StationType.lorawan ? oldStation.eui : oldStation.id }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
    return;
  }

  // ATTACHING
  let attachResponse: ApiResponse;
  if (newStation.type === StationType.lorawan) { // IS LORAWAN
    const attachUpdate: LoRaWANStationUpdate = {
      owner_id: newStation.owner_id,
      aws_id: newStation.aws_id,
      eui: newStation.eui,
      rf_region: newStation.rf_region,
      name: newStation.name,
      locationId: location.id,
    };
    attachResponse = yield call(updateLoraStation, newStation.id, attachUpdate);
  } else { // IS BASE STATION
    const attachUpdate: StationUpdate = {
      ownerId: newStation.owner_id,
      generation: newStation.generation,
      receiverType: newStation.receiver_type,
      frequency: newStation.frequency,
      locationId: location.id,
    };
    attachResponse = yield call(updateStation, newStation.id, attachUpdate);
  }
  if (attachResponse.reason === ReasonEnum.Ok) {
    action.newStation = attachResponse.data as LorawanStation;
  } else {
    const error = createErrorFromApiResponse(attachResponse);
    yield put(NotifyError(`Error while replacing base station #${ oldStation.type === StationType.lorawan ? oldStation.eui : oldStation.id }: ${ error.message } : ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
    return;
  }
  yield put(Action.ChangeStationLocation(
    { ...action.oldStation.location as StationLocation, station_id: null },
    { update: true }
  ));

  yield put(Action.ChangeStationLocation(
    action.newStation.location as StationLocation,
    { update: true }
  ));

  yield put(NotifySuccess(`Base station #${ newStation.type === StationType.lorawan ? newStation.eui : newStation.id } has been attached to location`));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore @TODO https://nwaveio.atlassian.net/browse/BNIV-2229
  'meta' in action && action.meta.promise.resolve(newStation);
  yield clearCache();
}

// @TODO переделать на hook и единый метод updateLorawanStation для detach/ attach / owner update
type DetachLorawanStationLocation =
  | Action.DetachLorawanStationLocation
  | ActionWithPromise<Action.DetachLorawanStationLocation, LorawanStation>
function* doDetachLorawanStationLocation(action: DetachLorawanStationLocation) {
  const { station } = action;
  const detachUpdate = {
    ...station,
    locationId: null,
  };

  const response: ApiResponse<LorawanStation> = yield call(updateLoraStation, station.id, detachUpdate);

  if (response.reason === ReasonEnum.Ok) {
    const newStation = response.data;
    if (newStation)  {
      yield put(NotifySuccess(`The location has been unbound from the lorawan station #${ newStation.eui }`));
      'meta' in action && action.meta.promise.resolve(newStation);
    }
    yield clearCache();
  } else {
    const error = createErrorFromApiResponse(response);
    yield put(NotifyError(`Error while unbinding location from the lorawan station #${ station.eui }: ${ error.message }`));
    'meta' in action && action.meta.promise.reject(error);
  }
}

function* clearCache() {
  yield clearQueryCache([
    'baseStation/station',
    'lorawanBaseStation/station',
  ]);
}

export default [
  takeEvery(Action.BASE_STATION_CREATE, doCreateStation),
  takeEvery(Action.BASE_STATION_UPDATE, doUpdateStation),
  takeEvery(Action.BASE_STATION_DELETE, doDeleteStation),
  takeEvery(Action.BASE_STATION_LOCATION_DETACH, doDetachStationLocation),
  takeEvery(Action.BASE_STATION_LOCATION_ATTACH, doAttachStationLocation),
  takeEvery(Action.LORAWAN_BASE_STATION_LOCATION_ATTACH, doAttachLoraStationLocation),
  takeEvery(Action.BASE_STATION_LOCATION_REPLACE, doReplaceStationLocation),
  takeEvery(Action.LORAWAN_BASE_STATION_LOCATION_DETACH, doDetachLorawanStationLocation),
];
