import React from 'react';
import { useDialog } from 'hooks/dialog';

// components
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import { InfoDialog } from 'components/Dialogs';

interface Props {
  total: number;
  isLoading: boolean;
  onClick: () => void;
}

export const SaveCSVButton = ({ total, isLoading, onClick }: Props): JSX.Element => {
  const toBigDialog = useDialog();
  const handleSave = () => total > 3000 ? toBigDialog.open() : onClick();

  return (
    <>
      { isLoading ? <CircularProgress size={ 16 }/> :
        <Tooltip title="Save as CSV">
          <span>
            <IconButton onClick={ handleSave }>
              <SaveAlt/>
            </IconButton>
          </span>
        </Tooltip>
      }
      { toBigDialog.isMounted && <InfoDialog
        name="download-csv-limit"
        label="CSV file is too big"
        isOpen={ toBigDialog.isOpen }
        onClose={ toBigDialog.close }
        onCloseEnd={ toBigDialog.unmount }
      >
          CSV export is limited to 3000 incidents.
      </InfoDialog> }
    </>
  );
};