import { DevicesFilterFields, PositionsFilterFields } from 'models/device-management';
import { createPath } from 'utils/routing/query';

type PathParam = number | string;

enum Path {
  list = '/device-management',
  zone = '/device-management/zones/{zoneId}',
  level = '/device-management/zones/{zoneId}/levels/{levelId}',
  group = '/device-management/zones/{zoneId}/levels/{levelId}/groups/{groupId}',
  position = '/device-management/zones/{zoneId}/levels/{levelId}/groups/{groupId}/positions/{positionId}',
}

export const getDMListPath = () => Path.list;
export const getDMZoneDetailPath = (zoneId: PathParam): string => {
  return Path.zone.replace('{zoneId}', String(zoneId));
};
export const getDMLevelDetailPath = (zoneId: PathParam, levelId: PathParam): string => {
  return Path.level
    .replace('{zoneId}', String(zoneId))
    .replace('{levelId}', String(levelId));
};
export const getDMGroupDetailPath = (zoneId: PathParam, levelId: PathParam, groupId: PathParam): string => {
  return Path.group
    .replace('{zoneId}', String(zoneId))
    .replace('{levelId}', String(levelId))
    .replace('{groupId}', String(groupId));
};
export const getDMPositionDetailPath = (zoneId: PathParam, levelId: PathParam, groupId: PathParam, positionId: PathParam): string => {
  return Path.position
    .replace('{zoneId}', String(zoneId))
    .replace('{levelId}', String(levelId))
    .replace('{groupId}', String(groupId))
    .replace('{positionId}', String(positionId));
};

export const dmDevicePath = (deviceId: string) => `/devices/dm/devices/${deviceId}`;
export const dmPositionPath = (positionId: number | string) => `/devices/dm/positions/${positionId}`;
export const dmPositionGroupsPath = `/devices/dm/groups`;
export const dmPositionGroupPath = (groupId: number) => `${dmPositionGroupsPath}/${groupId}`;
export const dmZonesPath = `/devices/dm/zones`;
export const dmZonesCreatePath = `/devices/dm/zones/create`;
export const dmZonePath = (zoneId: number | string) => `${dmZonesPath}/${zoneId}`;
export const dmDevicesPath = (filters?: Partial<DevicesFilterFields>) => createPath({
  pathname: '/devices/dm/devices',
  hashParams: filters,
});
export const dmPositionsPath = (filters?: Partial<PositionsFilterFields>) => createPath({
  pathname: `/devices/dm/positions`,
  hashParams: filters,
});
export const dmGroupsPath = `/devices/dm/groups`;
export const dmGroupsCreatePath = `${dmGroupsPath}/create`;
export const dmTestDevicesListPath = `/devices/dm/virtual-devices`;
export const dmGroupPath = (id: number) => `${dmGroupsPath}/${id}`;
