import React from 'react';

import { DeleteTemplate } from 'actions/caller';
import { useFormActionLoader } from 'hooks';
import { Template } from 'models/caller';

// components
import { EnsureDialog } from 'components/Dialogs';

interface DeleteTemplateDialogProps {
  template: Template;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSuccess?: () => void;
}

export const DeleteTemplateDialog = ({
  template,
  isOpen,
  onClose,
  onCloseEnd,
  onSuccess,
}: DeleteTemplateDialogProps) => {
  const { doAction } = useFormActionLoader();

  const handleConfirm = () => doAction({
    action: DeleteTemplate(template),
    onSuccess: onSuccess,
    onFinally: onClose,
  });

  return (
    <EnsureDialog
      name="delete-template"
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleConfirm }
    >
      The template <b>{ template.name }</b> will be deleted
    </EnsureDialog>
  );
};
