import React from 'react';
import { useSelector } from 'react-redux';

import { User } from 'models/user-management';
import { RootState } from 'reducers';
import { getUserBySub } from 'selectors/user-management';
import { formatUser } from 'utils/models';

// components
import { Skeleton } from '@material-ui/lab';

interface UserRouteLabelProps {
  userSub: User['sub'];
}

const UserRouteLabel: React.FC<UserRouteLabelProps> = (props) => {
  const { userSub } = props;
  const user = useSelector((state: RootState) => getUserBySub(userSub, state));

  if (typeof user === 'undefined') {
    return <Skeleton height={40} width={80} />;
  }

  if (user === null) {
    return <>{userSub}</>;
  }

  return <>{formatUser(user)}</>;
};

export default UserRouteLabel;
