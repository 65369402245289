import { stringify } from 'query-string';

type Id = string | number;
type Query = string;

export class Repository<T, P, K extends keyof T> {
  /**
   * Create instance only by Repository::create method
   */
  private constructor(
    private key: K,
    private data: Record<Id, T>,
    private query: Record<Query, Id[]>,
    private fetched: Record<Query, boolean>,
    private locked: Record<Id, boolean>,
    private total: Record<Query, number>,
  ) {
  }

  static create<T, P, K extends keyof T>(key: K): Repository<T, P, K> {
    return new this(key, {}, {}, {}, {}, {});
  }

  /**
   * Convert input query params or identify to Query type
   */
  private getQuery(params: P | T[K]): Query {
    const query = params instanceof Object ? params : { [this.key]: params };
    return stringify(query, { arrayFormat: 'comma', skipNull: true });
  }

  /**
   * Convert identify to Id type
   */
  private getId(id: T[K]): Id {
    return typeof id === 'number' ? id : String(id);
  }

  private clone(): Repository<T, P, K> {
    return new Repository<T, P, K>(
      this.key,
      { ...this.data },
      { ...this.query },
      { ...this.fetched },
      { ...this.locked },
      { ...this.total },
    );
  }

  public getLocked(): T[K][] {
    const locked = new Set<T[K]>();
    for (const [key, lock] of Object.entries(this.locked)) {
      if (lock) {
        locked.add(key as unknown as T[K]);
      }
    }

    return [...locked];
  }

  public setLocked(id: T[K], status: boolean): Repository<T, P, K> {
    const self = this.clone();
    const key = this.getId(id);
    self.locked[key] = status;

    return self;
  }

  public isLocked(id: T[K]): boolean {
    const key = this.getId(id);
    return key in this.locked ? this.locked[key] : false;
  }

  public setFetched(params: P | T[K], status: boolean): Repository<T, P, K> {
    const self = this.clone();
    const query = this.getQuery(params);
    self.fetched[query] = status;

    return self;
  }

  public isFetched(params: P | T[K]): boolean | undefined {
    const query = this.getQuery(params);

    return query in this.fetched ? this.fetched[query] : undefined;
  }

  public isFetching(): boolean {
    for (const status of Object.values(this.fetched)) {
      if (status === false) {
        return true;
      }
    }

    return false;
  }

  public reset(): Repository<T, P, K> {
    const self = this.clone();

    self.fetched = {}; // reset all loaded data
    self.total = {}; // reset all loaded data

    return self;
  }

  public changeModel(id: T[K], model?: T): Repository<T, P, K> {
    const self = this.reset();
    const key = this.getId(id);

    if (model === undefined) {
      delete self.data[key];
    } else {
      self.data[key] = model;
    }

    return self;
  }

  public setData(params: P | T[K], models: T[]): Repository<T, P, K> {
    const self = this.clone();
    const query = this.getQuery(params);
    const mapping = new Set<Id>();
    for (const model of models) {
      const id = this.getId(model[this.key]);
      self.data[id] = model;
      mapping.add(id);
    }
    self.query[query] = [...mapping];

    return self;
  }

  public findAll(): T[] {
    return Object.values(this.data);
  }

  findById = (id: T[K]): T | undefined => {
    const key = this.getId(id);
    return key in this.data ? this.data[key] : undefined;
  };

  public findByParams(params: P): T[] {
    const query = this.getQuery(params);
    const mapping = query in this.query ? this.query[query] : [];
    const result: T[] = [];
    for (const id of mapping) {
      if (id in this.data) {
        result.push(this.data[id]);
      }
    }

    return result;
  }

  public setTotal(params: P | T[K], total: number): Repository<T, P, K> {
    const self = this.clone();
    const query = this.getQuery(params);
    self.total[query] = total;

    return self;
  }

  public getTotal(params: P | T[K]): number | undefined {
    const query = this.getQuery(params);

    return query in this.total ? this.total[query] : undefined;
  }
}
