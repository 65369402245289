import { call, put, takeEvery } from 'redux-saga/effects';
import { ReasonEnum } from 'models';
import * as Action from 'actions/device-emulation/device-settings';
import * as backend from 'clients/device-emulation';
import { NotifyError, NotifySuccess } from 'actions/notifier';
import { createErrorFromApiResponse } from 'utils/errors';
import { ApiResponse } from 'models';
import { DeviceSettings, ResponseSettingsBody } from 'models/device-emulation';
import { ActionWithPromise } from 'utils/store';

function* doLoadDeviceEmulationSettingsDataById(action: Action.LoadDeviceEmulationSettingsDataById) {
  yield put(Action.LoadDeviceEmulationSettingsDataStart(action.deviceId));
  try {
    const response: ApiResponse<DeviceSettings> = yield call(backend.getDeviceSettings, action.deviceId);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    if (response.data) {
      yield put(Action.LoadDeviceEmulationSettingsDataSuccess(action.deviceId, [response.data]));
    }
  } catch (e) {
    yield put(Action.LoadDeviceEmulationSettingsDataFailure(action.deviceId, (e as Error).message));
    yield put(NotifyError(`Error while fetching virtual device settings: ${ (e as Error).message }`));
  }
}

type UpdateAction =
  | Action.UpdateDeviceEmulationSetting
  | ActionWithPromise<Action.UpdateDeviceEmulationSetting, DeviceSettings>

function* doUpdateDeviceEmulationSetting(action: UpdateAction) {
  const { deviceId, settings } = action;
  try {
    const response: ResponseSettingsBody = yield call(backend.updateDeviceSettings, deviceId, settings);
    if (response.reason !== ReasonEnum.Ok) {
      throw createErrorFromApiResponse(response);
    }
    const newSettings = response.data as DeviceSettings;
    yield put(Action.ChangeDeviceEmulationSetting(newSettings, 'update'));
    yield put(NotifySuccess(`Device #${ newSettings.device_id }'s emulation settings have been updated`));
    'meta' in action && action.meta.promise.resolve(newSettings);
  } catch (e) {
    yield put(NotifyError(`Error while updating device #${ deviceId }'s emulation settings: ${ (e as Error).message }`));
    'meta' in action && action.meta.promise.reject((e as Error));
  }
}

export default [
  takeEvery(Action.EMULATION_DEVICE_SETTINGS_DATA_LOAD_BY_ID, doLoadDeviceEmulationSettingsDataById),
  takeEvery(Action.EMULATION_DEVICE_SETTINGS_UPDATE, doUpdateDeviceEmulationSetting),
];
