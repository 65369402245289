import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Template } from 'models/caller';
import { CallerResponseCodeHistoryParams, ResponseCode } from 'models/caller-monitoring';

import {
  useResponseCodeHistoryChartData,
  CountByTemplateByDateByResponseCode,
} from './useResponseCodeHistoryChartData';
import { formatTemplate } from './utils';

// components
import { PointTooltip, PointTooltipProps } from '@nivo/line';

import { MonitoringChartTooltip } from './MonitoringChartTooltip';
import { MonitoringLineChart } from './MonitoringLineChart';

interface TooltipProps extends PointTooltipProps {
  countByTemplateByDateByResponseCode?: CountByTemplateByDateByResponseCode;
  templateById: Record<number, Template>;
}

const Tooltip = ({ point, countByTemplateByDateByResponseCode, templateById }: TooltipProps) => {
  const responseCode = point.serieId as ResponseCode;
  const date = (point.data.x as Date).toJSON();
  const countByTemplate = countByTemplateByDateByResponseCode?.[responseCode].get(date)?.entries();

  return (
    <MonitoringChartTooltip
      date={ date }
      entries={ [...(countByTemplate ?? [])].map(([templateId, count]) => ({
        id: templateId,
        value: count,
        label: formatTemplate(templateById, templateId),
      })) }
      total={ {
        label: point.serieId as string,
        count: point.data.y as number,
        color: point.serieColor,
      } }
      showTime
    />
  );
};

interface ResponseCodeHistoryChartProps {
  filters: CallerResponseCodeHistoryParams;
  templateById: Record<number, Template>;
}

export const ResponseCodeHistoryChart = ({ filters, templateById }: ResponseCodeHistoryChartProps) => {
  const data = useResponseCodeHistoryChartData(filters);

  const [tooltip, setTooltip] = useState<PointTooltip>(() => (props: PointTooltipProps) => (
    <Tooltip
      { ...props }
      countByTemplateByDateByResponseCode={ data.countByTemplateByDateByResponseCode }
      templateById={ templateById }
    />
  ));

  useEffect(() => {
    if (!data.isLoading) {
      setTooltip(() => (props: PointTooltipProps) => (
        <Tooltip
          { ...props }
          countByTemplateByDateByResponseCode={ data.countByTemplateByDateByResponseCode }
          templateById={ templateById }
        />
      ));
    }
  }, [data.isLoading, data.countByTemplateByDateByResponseCode, templateById]);

  return (
    <MonitoringLineChart
      data={ data.data }
      isLoading={ data.isLoading }
      maxValue={ data.maxValue }
      colors={ data.data.map(serie => serie.color) }
      enableSlices={ false }
      useMesh
      tooltip={ tooltip }
      durationDays={ moment.duration(moment(filters.dateTo).diff(filters.dateFrom)).asDays() }
    />
  );
};
