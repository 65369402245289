import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Dispatch } from 'redux';

// components
import Grid from '@material-ui/core/Grid';
import { PageLoader } from 'components/Loaders';
import { BoxDetailPageNotFound } from './BoxDetailPageNotFound';
// styles
import { useStyles } from './styles';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'reducers';
import { DoFetchBoxById } from 'actions/provisioning';
import { BoxManagment, DevicesInBoxes } from 'components/Provisioning/BoxManagment';

const mapState = (state: RootState, ownProps: { id: string }) => ({
  isLoading: state.provisioning.boxes.isFetching,
  boxes: state.provisioning.boxes.boxes,
  boxId: Number(ownProps.id)
});

const mapDispatch = (dispatch: Dispatch) => ({
  fetchBox: (id: number) => dispatch(DoFetchBoxById(id))
});

const connector = connect(
  mapState,
  mapDispatch
);

const BoxDetailPage = (props: ConnectedProps<typeof connector>): JSX.Element => {
  const classes = useStyles();
  const { boxes, isLoading, boxId, fetchBox } = props;

  const current = boxes.find(box => box.id === boxId);

  useEffect(() => {
    fetchBox(boxId);
  }, [boxId, fetchBox]);

  return (
    <>
      <Helmet><title>{ `Box #${boxId}` }</title></Helmet>
      {
        !current ? (isLoading ? <PageLoader /> : <BoxDetailPageNotFound />) :
          <Grid className={ classes.root } container spacing={ 2 }>
            <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
              <BoxManagment box={ current } />
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
              <DevicesInBoxes boxId={ current.id } />
            </Grid>
          </Grid>
      }
    </>
  );
};

export default connector(BoxDetailPage);

