import React from 'react';
import { FormProps } from 'form';
import { useDeepCompareEffect } from 'react-use';
import { validate } from './validator';
import { FormField, FormState } from './types';

// components
import { OwnersSingleSelectControl, ProjectSelectControl, SwitchControl, TextControl } from 'components/Controls';

export type ZoneFormState = FormState;
export {
  FormField as ZoneFormField
};

type Props = FormProps<FormState, FormField>;

export const ZoneForm = (props: Props): JSX.Element => {
  const { isView, state, showFields, onChange, onValidate } = props;
  const errors = props.errors ?? {};
  const showErrors = props.showErrors ?? showFields;
  const disabledFields = props.disabledFields ?? [];

  useDeepCompareEffect(() => {
    onValidate && onValidate(validate(state, showFields));
  }, [state, onValidate, showFields]);

  const handleChange = (field: FormField, value: unknown): void => {
    onChange && onChange({ ...state, [field]: value }, field);
  };

  return (
    <form noValidate autoComplete="off">
      { !showFields.includes(FormField.name) ? '' :
        <TextControl
          required={ !isView }
          disabled={ isView || disabledFields.includes(FormField.name) }
          label="Zone name"
          placeholder="Enter name"
          name="name"
          value={ state[FormField.name] ?? '' }
          onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(FormField.name, event.target.value ?? '');
          } }
          error={ showErrors.includes(FormField.name) ? errors[FormField.name] !== undefined : false }
          helperText={ showErrors.includes(FormField.name) ? errors[FormField.name] : undefined }
        />
      }
      { !showFields.includes(FormField.owner) ? '' :
        <OwnersSingleSelectControl
          isRequired={ !isView }
          isClearable={ false }
          isDisabled={ isView || disabledFields.includes(FormField.owner) }
          label="Owner"
          placeholder="Choose owner"
          name={ FormField.owner }
          selected={ state[FormField.owner] }
          changeHandler={ (owner?: number) => handleChange(FormField.owner, owner) }
          error={ showErrors.includes(FormField.owner) ? errors[FormField.owner] : undefined }
        />
      }
      { !showFields.includes(FormField.project) ? '' :
        <ProjectSelectControl
          isRequired={ !isView }
          isDisabled={ isView || disabledFields.includes(FormField.project) }
          label="Project"
          placeholder="Choose project"
          name={ FormField.project }
          selected={ state[FormField.project] }
          filter={ project => project.owner_id === state[FormField.owner] }
          onChange={ (levelId?: number) => handleChange(FormField.project, levelId) }
          error={ showErrors.includes(FormField.project) ? errors[FormField.project] : undefined }
        />
      }
      { !showFields.includes(FormField.silent) ? '' :
        <SwitchControl
          label="Silent sensors monitoring"
          name={ FormField.silent }
          value={ state[FormField.silent] ?? false }
          onChange={ (status?: boolean) => handleChange(FormField.silent, status) }
          error={ showErrors.includes(FormField.silent) ? errors[FormField.silent] : undefined }
        />
      }
    </form>
  );
};
