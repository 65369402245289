import React from 'react';
import { ResponsiblePerson } from 'models/base-station';
import { useAuthUserSelector } from 'hooks';
import { useFormActionLoader, useForm } from 'hooks/form';
import { UpdateStationResponsiblePerson } from 'actions/base-station';
import { canViewOwner } from 'utils/permissions';

// components
import { CommonDialog } from 'components/Dialogs';
import { ResponsiblePersonFormField, StationResponsiblePersonForm } from 'components/BaseStation';
import { Button } from '@material-ui/core';

interface Props {
  person: ResponsiblePerson;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd: () => void;
}

export const EditPersonDialog = ({ person, isOpen, onClose, onCloseEnd }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const { doAction } = useFormActionLoader();
  const form = useForm<ResponsiblePerson, ResponsiblePersonFormField>({
    initialState: person,
    showFields: [
      ...(canViewOwner(user) ? [ResponsiblePersonFormField.ownerId] : []),
      ResponsiblePersonFormField.firstName,
      ResponsiblePersonFormField.lastName,
      ResponsiblePersonFormField.role,
      ResponsiblePersonFormField.email,
      ResponsiblePersonFormField.notify,
    ]
  });

  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    const person: ResponsiblePerson = form.state;
    doAction({
      action: UpdateStationResponsiblePerson({
        rp_id: person.id,
        first_name: person.first_name,
        last_name: person.last_name,
        owner_id: person.owner_id,
        role: person.role,
        notify: person.notify,
        notification_method: person.notification_method,
        notification_endpoint: person.notification_endpoint,
        registered_user_id: person.registered_user_id,
      }),
      onSuccess: () => onClose(),
      onError: form.catchError,
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ 'Edit Responsible Person' }
      content={ <StationResponsiblePersonForm { ...form }/> }
      actions={
        <>
          <Button
            color="primary"
            onClick={ onClose }
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={ handleSave }
          >
            Save
          </Button>
        </>
      }
    />
  );
};
