import React from 'react';
import { RouteConfig } from 'react-router-config';
import { match as Match } from 'react-router-dom';

import {
  ProvisioningDevices,
  ProvisioningBoxes,
  ProvisioningShipments,
} from 'pages';
import {
  provisioningDevicesPath,
  provisioningPath,
  provisioningBoxesPath,
  provisioningShipmentsPath,
  provisioningPersonalShipmentPath,
  provisioningPersonalBoxPath,
} from 'routing/paths';
import ShipmentDetailPage from 'pages/Provisioning/ShipmentDetailPage/ShipmentDetailPage';
import BoxDetailPage from 'pages/Provisioning/BoxDetailPage/BoxDetailPage';
import * as permission from 'utils/permissions';
import { State as CurrentUserProfile } from 'reducers/user-profile';
import { ShipmentLabel } from 'components/Provisioning/ShipmentLabel';

const SHIPMENT_ID_PARAM_NAME = 'shipmentId';
const SHIPMENT_ID_PARAM = `:${SHIPMENT_ID_PARAM_NAME}`;
const BOX_ID_PARAM_NAME = 'boxId';
const BOX_ID_PARAM = `:${BOX_ID_PARAM_NAME}`;

type ParamNames = typeof SHIPMENT_ID_PARAM_NAME | typeof BOX_ID_PARAM_NAME;

interface Params {
  [SHIPMENT_ID_PARAM_NAME]?: string;
  [BOX_ID_PARAM_NAME]?: string;
}

const renderWithParams = (match: Match<Params>, Page: React.ElementType, param: ParamNames): false | React.ReactNode => {
  const id = match.params[param];
  if (!id) {
    return false;
  }

  return <Page { ...{ id } } />;
};

const idLabel = ({ id }: { id: string }): JSX.Element => {
  return (
    <>{ id }</>
  );
};

export function getProvisioningRoutes({ isAdmin, data: user }: CurrentUserProfile): RouteConfig[] {
  if (!isAdmin) {
    return [];
  }

  return [
    {
      path: provisioningPath,
      label: 'Provisioning',
      icon: 'shipment',
      routes: [
        {
          path: provisioningDevicesPath,
          label: 'Devices',
          icon: 'flashOn',
          hiddenOnMainMenu: !permission.isAllowedToReadProvisioning(user),
          isShowByUrl: permission.isAllowedToReadProvisioning(user),
          getRouteComponent: options => options.match.isExact ? ProvisioningDevices : undefined,
        },
        {
          path: provisioningBoxesPath,
          label: 'Boxes',
          icon: 'archive',
          hiddenOnMainMenu: !permission.isAllowedToReadProvisioning(user),
          isShowByUrl: permission.isAllowedToReadProvisioning(user),
          render: ({ match }) => match.isExact && <ProvisioningBoxes />,
          routes: [
            {
              exact: true,
              path: provisioningPersonalBoxPath(BOX_ID_PARAM),
              label: ({ match }) => match && renderWithParams(match, idLabel, BOX_ID_PARAM_NAME),
              render: ({ match }) => renderWithParams(match, BoxDetailPage, BOX_ID_PARAM_NAME),
              hiddenOnMainMenu: true,
            },
          ]
        },
        {
          path: provisioningShipmentsPath,
          label: 'Shipments',
          icon: 'nextWeek',
          hiddenOnMainMenu: !permission.isAllowedToReadProvisioning(user),
          isShowByUrl: permission.isAllowedToReadProvisioning(user),
          render: ({ match }) => match.isExact && <ProvisioningShipments />,
          routes: [
            {
              exact: true,
              path: provisioningPersonalShipmentPath(SHIPMENT_ID_PARAM),
              label: ({ match }) => {
                if (!match) {
                  return false;
                }

                const params = (match as Match<Params>).params;
                if (params[SHIPMENT_ID_PARAM_NAME]) {
                  return <ShipmentLabel shipmentId={ String(params[SHIPMENT_ID_PARAM_NAME]) }/>;
                }

                return false;
              },
              render: ({ match }) => renderWithParams(match, ShipmentDetailPage, SHIPMENT_ID_PARAM_NAME),
              hiddenOnMainMenu: true,
            },
          ]
        },
      ]
    }
  ];
}
