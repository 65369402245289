import React from 'react';
import { RedirectProps } from 'react-router-dom';
import { StationFlash } from 'models/base-station';
import { useFormActionLoader } from 'hooks/form';
import { CancelStationFlash } from 'actions/base-station';

// components
import { EnsureDialog } from 'components/Dialogs';
import { Typography } from '@material-ui/core';

interface Props {
  stationFlash: StationFlash;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
  onSucces?: () => void | RedirectProps;
}

export const CancelFlashDialog = (props: Props): JSX.Element => {
  const { stationFlash, isOpen, onClose, onCloseEnd, onSucces } = props;
  const { doAction } = useFormActionLoader();
  const handleDelete = () => doAction({
    action: CancelStationFlash(stationFlash),
    onSuccess: onSucces,
    onFinally: !onSucces ? onClose : undefined,
  });

  return (
    <EnsureDialog
      name={ `cancel-flash-station-${ stationFlash.id }` }
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      onYes={ handleDelete }
    >
      <Typography component="span" color="textPrimary">
        The flash station <b>{ stationFlash.id }</b> will be canceled
      </Typography>
    </EnsureDialog>
  );
};
